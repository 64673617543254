import type {
	Currencies,
	EditorSaveEditOrReviewRequestEditPolicyEnum,
	EditorSaveEditOrReviewRequestPortfolioSavingModeEnum,
	EditorSaveEditOrReviewResponse,
	EditorSaveNewResponse,
	ReviewTicker,
} from "$root/api/api-gen";
import { EntityEditorControllerApiFactory } from "$root/api/api-gen";
import { getApiGen } from "$root/api/factory";
import type { InstrumentEditorEntity } from "$root/functional-areas/instruments-editor/const";
import { axiosExtract } from "$root/third-party-integrations/axios";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import type { SubmitManualCreationPayload } from "../Portfolios/ManualCreation/spawn-createPortfolio";

export const manualCreationSaveFactory = {
	createInvestment: (params: {
		form: SubmitManualCreationPayload;
		composition: Array<ReviewTicker>;
	}): Promise<EditorSaveNewResponse> => {
		const { form, composition } = params;
		const entityEditorApi = getApiGen(EntityEditorControllerApiFactory);
		return axiosExtract(
			entityEditorApi.saveEditorNewEntity("INVESTMENT", {
				name: form.name,
				benchmarkIdentifier: form.primaryBenchmark?.benchmarkIdentifier,
				currency: form.baseCurrency,
				tickerComposition: composition,
				investmentReference: form.investmentReference,
				portfolioSavingMode: form.portfolioSavingMode,
			}),
		);
	},
	createInvestmentDraft: (params: {
		form: SubmitManualCreationPayload;
		composition: Array<ReviewTicker>;
	}): Promise<EditorSaveNewResponse> => {
		const { form, composition } = params;
		const entityEditorApi = getApiGen(EntityEditorControllerApiFactory);
		return axiosExtract(
			entityEditorApi.saveEditorNewEntityAsDraft("INVESTMENT", {
				name: form.name,
				benchmarkIdentifier: form.primaryBenchmark?.benchmarkIdentifier,
				currency: form.baseCurrency,
				tickerComposition: composition,
				investmentReference: form.investmentReference,
				portfolioSavingMode: form.portfolioSavingMode,
			}),
		);
	},
	createBenchmark: (params: {
		form: { name: string };
		composition: Array<ReviewTicker>;
	}): Promise<EditorSaveNewResponse> => {
		const { form, composition } = params;
		const entityEditorApi = getApiGen(EntityEditorControllerApiFactory);
		return axiosExtract(
			entityEditorApi.saveEditorNewEntity("BENCHMARK", {
				name: form.name,
				tickerComposition: composition,
			}),
		);
	},
	createTargetInvestment: (params: {
		form: { name: string; baseCurrency: Currencies };
		composition: Array<ReviewTicker>;
	}): Promise<EditorSaveNewResponse> => {
		const { form, composition } = params;
		const entityEditorApi = getApiGen(EntityEditorControllerApiFactory);
		return axiosExtract(
			entityEditorApi.saveEditorNewEntity("TARGET_INVESTMENT", {
				name: form.name,
				currency: form.baseCurrency,
				tickerComposition: composition,
			}),
		);
	},
	createUniverse: (params: {
		form: { name: string; portfolioSavingMode?: EditorSaveEditOrReviewRequestPortfolioSavingModeEnum };
		composition: Array<ReviewTicker>;
	}): Promise<EditorSaveNewResponse> => {
		const { form, composition } = params;
		const entityEditorApi = getApiGen(EntityEditorControllerApiFactory);
		return axiosExtract(
			entityEditorApi.saveEditorNewEntity("UNIVERSE", {
				name: form.name,
				tickerComposition: composition,
				portfolioSavingMode: form.portfolioSavingMode,
			}),
		);
	},
} satisfies Record<string, (...params: any[]) => MaybePromise<EditorSaveNewResponse>>;

export const manualCreationDraftFactory = {
	createInvestment: (params: {
		form: SubmitManualCreationPayload;
		composition: Array<ReviewTicker>;
		uuid: string;
	}): Promise<EditorSaveNewResponse> => {
		const { form, composition, uuid } = params;
		const entityEditorApi = getApiGen(EntityEditorControllerApiFactory);
		return axiosExtract(
			entityEditorApi.saveEditorEditEntity("INVESTMENT_DRAFT", {
				name: form.name,
				benchmarkIdentifier: form.primaryBenchmark?.benchmarkIdentifier,
				currency: form.baseCurrency,
				tickerComposition: composition,
				investmentReference: form.investmentReference,
				portfolioSavingMode: form.portfolioSavingMode,
				identifier: uuid,
			}),
		);
	},
	updateInvestmentDraft: (params: {
		form: SubmitManualCreationPayload;
		composition: Array<ReviewTicker>;
		uuid: string;
	}): Promise<EditorSaveNewResponse> => {
		const { form, composition, uuid } = params;
		const entityEditorApi = getApiGen(EntityEditorControllerApiFactory);
		return axiosExtract(
			entityEditorApi.saveEditorEditEntityAsDraft("INVESTMENT_DRAFT", {
				name: form.name,
				benchmarkIdentifier: form.primaryBenchmark?.benchmarkIdentifier,
				currency: form.baseCurrency,
				tickerComposition: composition,
				investmentReference: form.investmentReference,
				portfolioSavingMode: form.portfolioSavingMode,
				identifier: uuid,
			}),
		);
	},
} satisfies Record<string, (...params: any[]) => MaybePromise<EditorSaveNewResponse>>;

export const manualSaveEditFactory = {
	saveInvestmentEnhancement: (
		uuid: string,
		params: {
			form: Pick<SubmitManualCreationPayload, "portfolioSavingMode"> & {
				editPolicy?: EditorSaveEditOrReviewRequestEditPolicyEnum;
			};
			composition: Array<ReviewTicker>;
		},
	): Promise<EditorSaveEditOrReviewResponse> => {
		const { form, composition } = params;
		const entityEditorApi = getApiGen(EntityEditorControllerApiFactory);
		return axiosExtract(
			entityEditorApi.saveEditorEditEntity("INVESTMENT_ENHANCEMENT", {
				identifier: uuid,
				tickerComposition: composition,
				portfolioSavingMode: form.portfolioSavingMode,
				editPolicy: form.editPolicy,
			}),
		);
	},
	saveInvestment: (
		uuid: string,
		params: {
			form: Pick<SubmitManualCreationPayload, "portfolioSavingMode">;
			composition: Array<ReviewTicker>;
		},
	): Promise<EditorSaveEditOrReviewResponse> => {
		const { form, composition } = params;
		const entityEditorApi = getApiGen(EntityEditorControllerApiFactory);
		return axiosExtract(
			entityEditorApi.saveEditorEditEntity("INVESTMENT", {
				identifier: uuid,
				tickerComposition: composition,
				portfolioSavingMode: form.portfolioSavingMode,
			}),
		);
	},
	saveBenchmark: (
		uuid: string,
		params: { composition: Array<ReviewTicker> },
	): Promise<EditorSaveEditOrReviewResponse> => {
		const { composition } = params;
		const entityEditorApi = getApiGen(EntityEditorControllerApiFactory);
		return axiosExtract(
			entityEditorApi.saveEditorEditEntity("BENCHMARK", {
				identifier: uuid,
				tickerComposition: composition,
			}),
		);
	},
	saveTargetInvestment: (
		uuid: string,
		params: { composition: Array<ReviewTicker> },
	): Promise<EditorSaveEditOrReviewResponse> => {
		const { composition } = params;
		const entityEditorApi = getApiGen(EntityEditorControllerApiFactory);
		return axiosExtract(
			entityEditorApi.saveEditorEditEntity("TARGET_INVESTMENT", {
				identifier: uuid,
				tickerComposition: composition,
			}),
		);
	},
	saveUniverse: (
		uuid: string,
		params: {
			form: { portfolioSavingMode: EditorSaveEditOrReviewRequestPortfolioSavingModeEnum };
			composition: Array<ReviewTicker>;
		},
	): Promise<EditorSaveEditOrReviewResponse> => {
		const { composition, form } = params;
		const entityEditorApi = getApiGen(EntityEditorControllerApiFactory);
		return axiosExtract(
			entityEditorApi.saveEditorEditEntity("UNIVERSE", {
				identifier: uuid,
				tickerComposition: composition,
				portfolioSavingMode: form.portfolioSavingMode,
			}),
		);
	},
} satisfies Record<string, (...params: any[]) => MaybePromise<EditorSaveNewResponse>>;

//create const file
export const errorMessageNameRequired: Record<InstrumentEditorEntity, string> = {
	INVESTMENT: "Please provide a name for your portfolio",
	INVESTMENT_DRAFT: "Please provide a name for your portfolio",
	INVESTMENT_ENHANCEMENT: "Please provide a name for your portfolio",
	BENCHMARK: "Please provide a name for your benchmark",
	TARGET_INVESTMENT: "Please provide a name for your portfolio",
	UNIVERSE: "Please provide a name for your universe",
};
