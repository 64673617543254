import type { AutoTooltipProps, DataAttributesProps } from "@mdotm/mdotui/components";
import {
	AutoTooltip,
	Controller,
	TooltipContent,
	type InnerRefProps,
	type StylableProps,
} from "@mdotm/mdotui/components";
import { Switch, toClassListRecord, toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { switchExpr } from "@mdotm/mdotui/utils";
import type { ReactNode } from "react";

export type UserCirclePalette = "light" | "dark" | "dashed" | "majestyLight" | "majestyDark";

export type UserCircleSize = "medium" | "small";

export type UserCircleProps = InnerRefProps<SVGSVGElement> &
	StylableProps & DataAttributesProps & { palette: UserCirclePalette; size?: UserCircleSize } & (
		| ({ variant: "add" } & {
				firstName?: string;
				lastName?: string;
				count?: number;
		  })
		| ({ variant: "more" } & {
				firstName?: string;
				lastName?: string;
				count: number;
		  })
		| ({ variant: "name" } & {
				firstName: string;
				lastName: string;
				count?: number;
		  })
	);

export function UserCircle({
	palette,
	size = "medium",
	variant,
	firstName,
	lastName,
	innerRef,
	style,
	classList,
	count,
	...dataProps
}: UserCircleProps): JSX.Element {
	return (
		<svg
			ref={innerRef}
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				...switchExpr(size, {
					medium: () => ({ width: 32, height: 32 }),
					small: () => ({ width: 24, height: 24 }),
				}),
				...style,
			}}
			{...dataProps}
			className={toClassName(classList)}
		>
			<Switch
				case={palette}
				match={{
					light: () => (
						<>
							<rect width="32" height="32" rx="16" fill="white" />
							<rect width="30.5" height="30.5" rx="15.25" x="0.5" y="0.5" strokeWidth="1" stroke="#C3C9D5" />
						</>
					),
					dark: () => <rect width="32" height="32" rx="16" fill="#E1E4EA" />,
					majestyLight: () => (
						<>
							<rect width="32" height="32" rx="16" fill="white" />
							<rect
								width="30.5"
								height="30.5"
								rx="15.25"
								x="0.5"
								y="0.5"
								strokeWidth="1"
								stroke={themeCSSVars.palette_graph_B500}
							/>
						</>
					),
					majestyDark: () => <rect width="32" height="32" rx="16" fill={themeCSSVars.palette_graph_B500} />,
					dashed: () => (
						<>
							<rect width="32" height="32" rx="16" fill="white" />
							<rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#C3C9D5" strokeDasharray="4 3" />
						</>
					),
				}}
			/>

			<Switch
				case={variant}
				match={{
					add: () => (
						<>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M15.1805 15.1666V9.29239C15.1805 8.84693 15.5411 8.48633 15.9936 8.48633C16.4462 8.48633 16.8068 8.84693 16.8068 9.30653V15.1666H22.6808C23.1263 15.1666 23.4869 15.5272 23.4869 15.9797C23.4869 16.4323 23.1263 16.7929 22.6667 16.7929H16.8068V22.6671C16.8068 23.1126 16.4391 23.4732 15.9936 23.4732C15.5482 23.4732 15.1805 23.1126 15.1805 22.6671V16.7929H9.30606C8.86061 16.7929 8.5 16.4252 8.5 15.9797C8.5 15.5343 8.86061 15.1666 9.30606 15.1666H15.1805Z"
								fill="#8792AB"
							/>
						</>
					),
					more: () => (
						<>
							<text
								textAnchor="middle"
								x={16}
								y={21}
								fontWeight={700}
								fontSize={14}
								className="transition-[fill]"
								fill="#697796"
							>{`+${count}`}</text>
						</>
					),
					name: () => (
						<>
							<text
								textAnchor="middle"
								x={16}
								y={21}
								fontWeight={700}
								fontSize={14}
								className="transition-[fill]"
								fill={
									palette === "majestyLight"
										? themeCSSVars.palette_graph_B500
										: palette === "majestyDark"
										  ? "#E1E4EA"
										  : "#697796"
								}
							>{`${(firstName ?? "-")[0]?.toUpperCase()}${(lastName ?? "-")[0]?.toUpperCase()}`}</text>
						</>
					),
				}}
			/>
		</svg>
	);
}

export function UserCircleWithTooltip({
	position,
	classList,
	palette = "light",
	hoverPalette = palette,
	firstName,
	lastName,
	children,
	...forward
}: Omit<UserCircleProps, "palette"> & {
	position?: AutoTooltipProps<HTMLDivElement>["position"];
	palette?: UserCircleProps["palette"];
	hoverPalette?: UserCircleProps["palette"];
	children?: ReactNode;
}): JSX.Element {
	return (
		<AutoTooltip
			position={position}
			overrideColor={switchExpr(palette, {
				dark: () => themeCSSVars.palette_N200,
				light: () => themeCSSVars.palette_N200,
				majestyDark: () => themeCSSVars.palette_graph_B500,
				majestyLight: () => themeCSSVars.palette_graph_B500,
				dashed: () => themeCSSVars.palette_N200,
			})}
			closeOnScroll
			disabled={(!firstName || !lastName) && !children}
			trigger={({ innerRef }) => (
				<Controller value={false}>
					{({ value: mouseOver, onChange: setMouseOver }) => (
						<div ref={innerRef} onMouseEnter={() => setMouseOver(true)} onMouseLeave={() => setMouseOver(false)}>
							<UserCircle
								classList={{ "pointer-events-none": true, ...toClassListRecord(classList) }} // prevents SVG pointer-events quirks
								firstName={firstName}
								lastName={lastName}
								{...(forward as UserCircleProps)}
								palette={mouseOver ? hoverPalette : palette}
							/>
						</div>
					)}
				</Controller>
			)}
		>
			<TooltipContent>{children || `${firstName ?? ""} ${lastName ?? ""}`}</TooltipContent>
		</AutoTooltip>
	);
}
