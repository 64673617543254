import type { InvestmentCompositionResponse, InvestmentSummary } from "$root/api/api-gen";
import {
	InvestmentEnhancementExportControllerApiFactory,
	InvestmentEnhancementReportsControllerApiFactory,
	InvestmentExportControllerApiFactory,
	InvestmentReportsControllerApiFactory,
	PortfolioStudioPreferencesApiFactory,
	TickerControllerV1ApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { DebouncedSearchInput } from "$root/components/DebouncedSearchInput";
import { retrieveUserInstrumentsClassifications } from "$root/functional-areas/instruments-editor/builder";
import type { FrankensteinInstrument } from "$root/functional-areas/instruments-editor/const";
import type { UserColumnMetadata } from "$root/functional-areas/instruments/hooks";
import {
	hideNameColumnMetadata,
	useGroupedInstrumentsColumn,
	useInstrumentColumnPreference,
	useInstrumentsColumn,
	useSearchableInstrumentTable,
} from "$root/functional-areas/instruments/hooks";
import { usePortfolioStudioTableSettings } from "$root/pages/PortfoliosStudio/portfolio-studio-table-settings";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { downloadContentDisposition, preventSubmitOnPressEnter, useQueryNoRefetch } from "$root/utils";
import type {
	ActionWithOptionalGroup,
	TableColumn,
	TableWithGroupsColumn,
	TableWithGroupsProps,
} from "@mdotm/mdotui/components";
import {
	AutoSortHScrollTable,
	AutoSortHScrollTableWithGroups,
	Checkbox,
	DropdownMenu,
	FormField,
	Icon,
	Select,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";
import type { AxiosResponse } from "axios";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PortfolioQueryWidgetBase, portfolioWidgetMissingDataReason, WidgetStatus } from "../PortfolioWidgetStatus";
import {
	createTagGroupedColumn,
	useCompositionColumns,
	useGroupedCompositionColumn,
	useGroupedCompositionRows,
} from "./columns";

type PortfolioCompositionProps = {
	portfolio: InvestmentSummary;
	enhanced: boolean;
	reportExcutionCounter: number;
};

export const PortfolioComposition = (props: PortfolioCompositionProps): JSX.Element => {
	const { enhanced, portfolio, reportExcutionCounter } = props;
	const uuid = props.portfolio?.uuid;

	const investmentReportApi = useApiGen(InvestmentReportsControllerApiFactory);
	const investmentEnhancementReportApi = useApiGen(InvestmentEnhancementReportsControllerApiFactory);
	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);
	const exportApi = useApiGen(InvestmentExportControllerApiFactory);
	const exportEnhancedApi = useApiGen(InvestmentEnhancementExportControllerApiFactory);
	const tickerApi = useApiGen(TickerControllerV1ApiFactory);

	const { t } = useTranslation();

	const querySummary = useQueryNoRefetch(["queryInvestmentSummary", uuid], {
		enabled: uuid !== undefined,
		queryFn: () => axiosExtract(investmentReportApi.getInvestmentSummary(uuid!)),
	});

	const { currentlyContainsNestedPortfolios } = querySummary.data ?? {};
	const [portfolioNested, setPortfolioNested] = useState(currentlyContainsNestedPortfolios ?? false);
	const [groupByClassificationUuid, setGroupByClassificationUuid] = useState<string | null>(null);

	const query = useQueryNoRefetch(
		[
			"compositionProvider",
			uuid,
			enhanced,
			portfolio?.status,
			reportExcutionCounter,
			portfolioNested,
			currentlyContainsNestedPortfolios,
		],
		{
			enabled: currentlyContainsNestedPortfolios !== undefined,
			queryFn: async () => {
				let data: InvestmentCompositionResponse | undefined = undefined;
				if (!uuid) {
					return {
						data: undefined,
						widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "Composition"),
					};
				}

				if (currentlyContainsNestedPortfolios && !portfolioNested) {
					data = await axiosExtract(
						props.enhanced
							? investmentEnhancementReportApi.getInvestmentEnhancementAggregateComposition(uuid)
							: investmentReportApi.getInvestmentAggregateComposition(uuid),
					);
				} else {
					data = await axiosExtract(
						props.enhanced
							? investmentEnhancementReportApi.getInvestmentComposition1(uuid)
							: investmentReportApi.getInvestmentComposition(uuid),
					);
				}

				// PROPOSAL: we can either remap the response here or ask the BE to give us the
				// appropriate data status (letting us delete portfolioWidgetMissingDataReason)
				if (data.composition) {
					const { userInstrumentsMap } = await retrieveUserInstrumentsClassifications(data.composition ?? []);
					return {
						data: {
							composition: data.composition.map((reviewTicker) => {
								const userInstrument = userInstrumentsMap.get(reviewTicker.ticker!) ?? {};
								return {
									...reviewTicker,
									...userInstrument,
									name: userInstrument.name ?? reviewTicker?.instrument ?? "",
									alias: userInstrument.alias ?? reviewTicker.identifier,
								} as FrankensteinInstrument;
							}),
						},
						widgetStatus: WidgetStatus.READY,
					};
				}

				return {
					data: undefined,
					widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "Composition"),
				};
			},
		},
	);

	const { changeTableLayout, columnsMetadata } = useInstrumentColumnPreference({
		async applyColumnPreferenceApi(userInstrumentsColumnPreferences) {
			//todo handle enhance columns
			await portfolioStudioPreferencesApi.setUserInstrumentsColumnPreferences({
				userInstrumentsColumnPreferences,
			});
		},
		mapColumnMetadataFn: hideNameColumnMetadata,
	});

	const {
		filtered,
		setQuery: setSearchInstrument,
		query: searchInstrument,
	} = useSearchableInstrumentTable(query?.data?.data?.composition ?? [], {
		mode: "keyword",
	});

	const exportPortfolioComposition = useCallback(
		async (downloadTarget: "composition" | "trades") => {
			let response: AxiosResponse<any, any> | undefined = undefined;
			if (portfolioNested) {
				response = enhanced
					? downloadTarget === "composition"
						? await exportEnhancedApi.exportEnhancedComposition(portfolio!.uuid!, "FULL_COMPOSITION", {
								responseType: "blob",
						  })
						: await exportEnhancedApi.exportEnhancedComposition(portfolio!.uuid!, "TRADES_ONLY", {
								responseType: "blob",
						  })
					: await exportApi.exportComposition(portfolio!.uuid!, { responseType: "blob" });
			} else {
				response = enhanced
					? downloadTarget === "composition"
						? await exportEnhancedApi.exportEnhancedAggregateComposition(portfolio!.uuid!, "FULL_COMPOSITION", {
								responseType: "blob",
						  })
						: await exportEnhancedApi.exportEnhancedComposition(portfolio!.uuid!, "TRADES_ONLY", {
								responseType: "blob",
						  })
					: await exportApi.exportAggregateComposition(portfolio!.uuid!, { responseType: "blob" });
			}

			trackMixPanelEvent("Portfolio", {
				Type: "Export",
				Area: downloadTarget,
				ID: portfolio!.uuid!,
			});
			downloadContentDisposition(response);
		},
		[enhanced, exportApi, exportEnhancedApi, portfolio, portfolioNested],
	);

	const dropdownMenuActions = useMemo<ActionWithOptionalGroup<string>[]>(
		() => [
			{
				icon: "xls",
				onClickAsync: () => exportPortfolioComposition("composition"),
				label: t("COMPOSITION.DOWNLOAD_TITLE"),
			},
			portfolio?.status === "PROPOSAL_READY" &&
				enhanced && {
					icon: "xls",
					onClickAsync: () => exportPortfolioComposition("trades"),
					label: t("COMPOSITION.DOWNLOAD_TRADES_TITLE"),
				},
		],
		[enhanced, exportPortfolioComposition, portfolio?.status, t],
	);

	const classificationOptions = useMemo(
		() => [
			{ label: "Ungrouped", value: null },
			...columnsMetadata.flatMap((x) =>
				x.fieldType === "TAG" ? [{ label: x.name, value: x.classificationUuid! }] : [],
			),
		],
		[columnsMetadata],
	);

	return (
		<div className="rounded bg-white min-h-0 flex flex-col p-4 h-[calc(100dvh_-_246px)] ">
			<PortfolioQueryWidgetBase query={query}>
				{() => (
					<>
						<div className="flex justify-between mb-6">
							<DebouncedSearchInput
								query={searchInstrument}
								onChange={setSearchInstrument}
								name="search"
								maxLength={60}
								placeholder={t("PORTFOLIOS.CONSTRAINTS_TARGETS.PORTFOLIO_LIST")}
								onKeyDown={preventSubmitOnPressEnter}
								style={{ width: 692 }}
							/>
							<div className="flex space-x-4 items-end">
								<FormField label="Group by">
									{(formFieldProps) => (
										<Select
											{...formFieldProps}
											size="x-small"
											value={groupByClassificationUuid}
											onChange={setGroupByClassificationUuid}
											options={classificationOptions}
										/>
									)}
								</FormField>
								{currentlyContainsNestedPortfolios && (
									<Checkbox
										switchType="switch"
										checked={!portfolioNested}
										onChange={() => setPortfolioNested((prev) => !prev)}
									>
										Expand nested portfolios
									</Checkbox>
								)}
								<DropdownMenu
									trigger={({ innerRef, open, ...forward }) => (
										<button ref={innerRef} aria-expanded={open} type="button" {...forward}>
											<Icon icon="Dowload" color={themeCSSVars.MessageSeverity_success} size={20} />
										</button>
									)}
									actions={dropdownMenuActions}
								/>
							</div>
						</div>
						<div className="min-h-0 grow flex flex-col">
							<PortfolioCompositionInner
								groupByClassificationUuid={groupByClassificationUuid}
								ctx={props}
								filtered={filtered}
								columnsMetadata={columnsMetadata}
								onAsyncChangeColumnPreferences={changeTableLayout}
							/>
						</div>
					</>
				)}
			</PortfolioQueryWidgetBase>
		</div>
	);
};

function PortfolioCompositionInner(props: {
	ctx: PortfolioCompositionProps;
	filtered: FrankensteinInstrument[];
	groupByClassificationUuid: string | null;
	columnsMetadata: UserColumnMetadata[];
	onAsyncChangeColumnPreferences: (newColumnPreferences: Array<UserColumnMetadata>) => MaybePromise<void>;
}) {
	return (
		<>
			{props.groupByClassificationUuid ? (
				<PortfolioCompositionInnerGrouped {...props} groupByClassificationUuid={props.groupByClassificationUuid} />
			) : (
				<PortfolioCompositionInnerFlat {...props} />
			)}
		</>
	);
}

export function PortfolioCompositionInnerGrouped(
	props: Parameters<typeof PortfolioCompositionInner>[0] & { groupByClassificationUuid: string },
): JSX.Element {
	const { columnsMetadata, onAsyncChangeColumnPreferences } = props;
	const { enhanced } = props.ctx;
	const {
		portfolioCompositionOrderByName,
		setPortfolioCompositionOrderByName,
		portfolioCompositionEnhanceOrderByName,
		setPortfolioCompositionEnhanceOrderByName,
	} = usePortfolioStudioTableSettings();

	const groupedRows = useGroupedCompositionRows(props.filtered, props.groupByClassificationUuid);
	const reviewColumns = useGroupedCompositionColumn();

	const {
		customizableColumns: instrumentColumns,
		tools,
		name,
	} = useGroupedInstrumentsColumn({
		allColumns: columnsMetadata,
		mode: "VIEW",
		changeTableLayout: onAsyncChangeColumnPreferences,
	});

	const selectedClassification = useMemo(
		() => columnsMetadata.find((x) => x.classificationUuid === props.groupByClassificationUuid)!,
		[columnsMetadata, props.groupByClassificationUuid],
	);

	const groupedCompositionColumns = useCallback(
		({
			expandColumn,
		}: Parameters<TableWithGroupsProps<(typeof groupedRows)[number]>["columns"]>[0]): TableWithGroupsColumn<
			(typeof groupedRows)[number]
		>[] => {
			const columnsWithoutSelectedClassification = instrumentColumns.flatMap((column) => {
				if (column.name === selectedClassification.classificationUuid) {
					return [];
				}

				return [column];
			});

			const { groupContent } = createTagGroupedColumn(selectedClassification);

			const nameColumn = {
				...name,
				minWidth: 460,
				maxWidth: 560,
				groupContent,
				sortFn: builtInSortFnFor("groupKey"),
				orderable: true,
			} satisfies TableWithGroupsColumn<(typeof groupedRows)[number]>;

			return props.ctx.enhanced
				? [
						expandColumn,
						nameColumn,
						...columnsWithoutSelectedClassification,
						reviewColumns.CURRENT_WEIGHT(),
						reviewColumns.ENHANCED_WEIGHT(),
						reviewColumns.DIFFERENCE(),
						tools,
				  ]
				: [expandColumn, nameColumn, ...columnsWithoutSelectedClassification, reviewColumns.WEIGHT(), tools];
		},
		[instrumentColumns, name, props.ctx.enhanced, reviewColumns, selectedClassification, tools],
	);

	return (
		<AutoSortHScrollTableWithGroups
			data-qualifier="Composition/GroupedTable"
			groupedRows={groupedRows}
			groupRowKey={(x) => x.groupKey ?? ""}
			columns={groupedCompositionColumns}
			pinnedColumns={[
				{ name: name.name, side: "left" },
				{ name: reviewColumns.WEIGHT().name, side: "right" },
				{ name: tools.name, side: "right" },
			]}
			orderBy={enhanced ? portfolioCompositionEnhanceOrderByName : portfolioCompositionOrderByName}
			onOrderByChange={enhanced ? setPortfolioCompositionEnhanceOrderByName : setPortfolioCompositionOrderByName}
		/>
	);
}

export function PortfolioCompositionInnerFlat(props: Parameters<typeof PortfolioCompositionInner>[0]): JSX.Element {
	const { columnsMetadata, onAsyncChangeColumnPreferences } = props;
	const { enhanced } = props.ctx;
	const {
		portfolioCompositionOrderByName,
		setPortfolioCompositionOrderByName,
		portfolioCompositionEnhanceOrderByName,
		setPortfolioCompositionEnhanceOrderByName,
	} = usePortfolioStudioTableSettings();

	const {
		customizableColumns: instrumentColumns,
		tools,
		name,
	} = useInstrumentsColumn({
		allColumns: columnsMetadata,
		mode: "VIEW",
		changeTableLayout: onAsyncChangeColumnPreferences,
	});
	const reviewColumns = useCompositionColumns();

	const columns = useMemo<TableColumn<FrankensteinInstrument>[]>(() => {
		const nameColumn = {
			...name,
			minWidth: 460,
			maxWidth: 560,
		} satisfies TableColumn<FrankensteinInstrument>;

		return props.ctx.enhanced
			? [
					nameColumn,
					...instrumentColumns,
					reviewColumns.CURRENT_WEIGHT(),
					reviewColumns.ENHANCED_WEIGHT(),
					reviewColumns.DIFFERENCE(),
					tools,
			  ]
			: [nameColumn, ...instrumentColumns, reviewColumns.WEIGHT(), tools];
	}, [name, props.ctx.enhanced, instrumentColumns, reviewColumns, tools]);

	const pinnedColumns = useMemo(
		(): Array<{
			name: string;
			side: "left" | "right";
		}> =>
			props.ctx.enhanced
				? [
						{
							name: name.name,
							side: "left",
						},
				  ]
				: [
						{
							name: name.name,
							side: "left",
						},
						{ name: reviewColumns.WEIGHT().name, side: "right" },
				  ],
		[name.name, props.ctx.enhanced, reviewColumns],
	);

	return (
		<AutoSortHScrollTable
			data-qualifier="Composition/FlatTable"
			columns={columns}
			rows={props.filtered}
			pinnedColumns={pinnedColumns}
			orderBy={enhanced ? portfolioCompositionEnhanceOrderByName : portfolioCompositionOrderByName}
			onOrderByChange={enhanced ? setPortfolioCompositionEnhanceOrderByName : setPortfolioCompositionOrderByName}
		/>
	);
}
