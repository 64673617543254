import { sumArrayLike } from "$root/utils";
import type { StylableProps } from "@mdotm/mdotui/components";
import { ComputedSizeContainer, Svg } from "@mdotm/mdotui/components";
import { ForEach, toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { alwaysArray, mapBetweenRanges, type MaybeArray } from "@mdotm/mdotui/utils";
import type { SyntheticEvent } from "react";

export type DonutChartItem = {
	/** Label shown when selected */
	label: string;
	/** Control the position along the main axis */
	value: number;
	/** Control the color.  */
	color: string;
};

export type DonutChartSvgProps = StylableProps & {
	sectors: DonutChartItem[];
	innerText?: MaybeArray<{ text: string; className?: string }>;
	selectedSectorIndex?: number | null;
	hoveringSectorIndex?: number | null;
	onSectorMouseEnter?(sector: DonutChartItem, index: number, e: SyntheticEvent): void;
	onSectorMouseLeave?(sector: DonutChartItem, index: number, e: SyntheticEvent): void;
	onSectorClick?(sector: DonutChartItem, index: number, e: SyntheticEvent): void;
};

export function DonutChartSvg({
	sectors,
	innerText = [],
	onSectorMouseEnter,
	onSectorMouseLeave,
	onSectorClick,
	selectedSectorIndex,
	hoveringSectorIndex,
	...stylableProps
}: DonutChartSvgProps): JSX.Element {
	const sumOfAllValues = sumArrayLike(sectors, (s) => s.value);
	const innerTextLines = alwaysArray(innerText);
	return (
		<ComputedSizeContainer {...stylableProps}>
			{({ htmlEl: _htmlEl, ...rect }) => {
				const centerX = rect.width / 2;
				const centerY = rect.height / 2;

				const externalDiameter = Math.min(260, rect.width - 50);
				const externalRadius = externalDiameter / 2;
				const internalDiameter = Math.min(190, rect.width - 50);
				const internalRadius = internalDiameter / 2;

				let sumOfPreviousValues = 0;
				return (
					<Svg viewBox={rect} classList="pointer-events-none">
						<ForEach collection={innerTextLines}>
							{({ item: { text, className }, index }) => (
								<text
									fill={themeCSSVars.palette_N500}
									fontSize={12}
									textAnchor="middle"
									className={toClassName({ "whitespace-nowrap": true, [className ?? ""]: !!className })}
									x={centerX}
									y={centerY + 20 + 10 - 20 * innerTextLines.length + 20 * index}
								>
									{text}
								</text>
							)}
						</ForEach>
						<ForEach collection={sectors}>
							{({ item, index }) => {
								const startAngle =
									mapBetweenRanges(sumOfPreviousValues, 0, sumOfAllValues, 0, 2 * Math.PI) +
									(3 * Math.PI) / 2 +
									Math.PI / 500;
								const endAngle =
									mapBetweenRanges(sumOfPreviousValues + item.value, 0, sumOfAllValues, 0, 2 * Math.PI) +
									(3 * Math.PI) / 2 -
									Math.PI / 500;

								const isLargeAngle = endAngle - startAngle > Math.PI;

								const hoverScaler = index === selectedSectorIndex || index === hoveringSectorIndex ? 1.1 : 1;

								sumOfPreviousValues += item.value;
								return (
									<>
										<path
											fill={item.color}
											className={toClassName({
												"transition-[d] pointer-events-none": true,
												"opacity-40": selectedSectorIndex === null || index !== selectedSectorIndex,
											})}
											d={`
												M ${centerX + externalRadius * hoverScaler * Math.cos(startAngle)} ${
													centerY + externalRadius * hoverScaler * Math.sin(startAngle)
												}
												A ${externalRadius * hoverScaler} ${externalRadius * hoverScaler} 0 ${isLargeAngle ? 1 : 0} 1 ${
													centerX + externalRadius * hoverScaler * Math.cos(endAngle)
												} ${centerY + externalRadius * hoverScaler * Math.sin(endAngle)}
												L ${centerX + internalRadius * hoverScaler * Math.cos(endAngle)} ${
													centerY + internalRadius * hoverScaler * Math.sin(endAngle)
												}
												A ${internalRadius * hoverScaler} ${internalRadius * hoverScaler} 0 ${isLargeAngle ? 1 : 0} 0 ${
													centerX + internalRadius * hoverScaler * Math.cos(startAngle)
												} ${centerY + internalRadius * hoverScaler * Math.sin(startAngle)}`}
										/>
										{/* Interaction path */}
										<path
											onMouseEnter={(e) => onSectorMouseEnter?.(item, index, e)}
											onMouseLeave={(e) => onSectorMouseLeave?.(item, index, e)}
											onClick={(e) => onSectorClick?.(item, index, e)}
											fill="transparent"
											className="pointer-events-auto"
											d={`
												M ${centerX + externalRadius * hoverScaler * Math.cos(startAngle)} ${
													centerY + externalRadius * hoverScaler * Math.sin(startAngle)
												}
												A ${externalRadius * hoverScaler} ${externalRadius * hoverScaler} 0 ${isLargeAngle ? 1 : 0} 1 ${
													centerX + externalRadius * hoverScaler * Math.cos(endAngle)
												} ${centerY + externalRadius * hoverScaler * Math.sin(endAngle)}
												L ${centerX + internalRadius * Math.cos(endAngle)} ${centerY + internalRadius * Math.sin(endAngle)}
												A ${internalRadius} ${internalRadius} 0 ${isLargeAngle ? 1 : 0} 0 ${centerX + internalRadius * Math.cos(startAngle)} ${
													centerY + internalRadius * Math.sin(startAngle)
												}`}
										/>
									</>
								);
							}}
						</ForEach>
					</Svg>
				);
			}}
		</ComputedSizeContainer>
	);
}
