import { CustomerControllerV3ApiFactory } from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import SPHEREIconWhite from "$root/assets/images/sphere-logo-white.svg";
import SPHERELargeWhite from "$root/assets/images/sphere-white.svg";
import env from "$root/env";
import { UserCircle } from "$root/functional-areas/acl/UserCircle";
import { NotificationCenterSidebarContent } from "$root/functional-areas/notification-center";
import { useNotificationValue } from "$root/functional-areas/notification-center/NotificationStore";
import { SphereAISidebarContent } from "$root/functional-areas/sphere-ai/SphereAISidebarContent";
import { guestUser, useUserState, useUserValue } from "$root/functional-areas/user";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { Intercom } from "$root/third-party-integrations/initIntercom";
import { createPersistentAtom } from "$root/third-party-integrations/zustand";
import { qualifier } from "$root/utils";
import type { StylableProps } from "@mdotm/mdotui/components";
import {
	ActionText,
	CircularProgressBar,
	ClickableDiv,
	CollapsibleBase,
	Column,
	Icon,
	Row,
	Sandwich,
	StackingContext,
	Text,
	Transition,
} from "@mdotm/mdotui/components";
import { ForEach, overrideClassName, useWindowSize } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { MouseEvent, ReactNode } from "react";
import { createContext, useCallback, useContext, useMemo, useRef, useState } from "react";
import { createPortal, flushSync } from "react-dom";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useEventEmitterListener } from "../third-party-integrations/event-emitter3";
import { collapsedDrawerWidth, drawerWidth } from "./AppDrawer-shared";
import AuthorizationGuard, { hasAccess } from "./AuthorizationGuard";
import { generateImageObjectUrlFromFileResponse } from "./EvolvedPrint/configuration/utils";
import { extractPath, extractSearchParams, useTypedNavigation } from "./PlatformRouter/RoutesDef";
import { sidebarEntry, useSidebarEntries, type SidebarEntry, type SidebarSubmenuEntry } from "./PlatformRouter/sidebar";
import { ReactQueryWrapperBase } from "./ReactQueryWrapper";
import type { SidebarPositions } from "./Sidebar";
import { Sidebar } from "./Sidebar";
import { queryClient } from "$root/third-party-integrations/react-query";

export const appDrawerZIndexOffset = {
	closed: 1,
	open: 2,
};

const isSameRoute = (route: string, current: string) => {
	if (!extractPath(current).startsWith(extractPath(route))) {
		return false;
	}

	const routeParams = extractSearchParams(route);
	// Note: even if current contains extra params we consider
	// route and current the same route.
	const currentParams = extractSearchParams(current);

	for (const [key, value] of routeParams) {
		if (currentParams.get(key) !== value) {
			return false;
		}
	}

	return true;
};

const useMainSidebarPosition = createPersistentAtom<SidebarPositions>("right", "useMainSidebarPosition");
const useAppDrawerUserExpandStore = createPersistentAtom<boolean>(true, "useAppDrawerUserExpandStore");

export function useAppDrawerExpand(): { expand: boolean; canToggleExpand: boolean } {
	const { value: userExpand } = useAppDrawerUserExpandStore();
	const { width } = useWindowSize();
	const canToggleExpand = width >= 1572; /* TODO: what's the actual threshold? */
	const expand = userExpand && canToggleExpand;
	return { expand, canToggleExpand };
}

export function useAppDrawerWidth(): number {
	const { expand } = useAppDrawerExpand();
	return expand ? drawerWidth : collapsedDrawerWidth;
}

const useAppDrawerEntryExpandMapStore = createPersistentAtom<Partial<Record<string, boolean>>>(
	{},
	"useAppDrawerEntryExpandMapStore",
);

const AppDrawerCtx = createContext<{
	showSidebar: "chat" | "notifications" | null;
	setShowSidebar(v: "chat" | "notifications" | null): void;
	mountSphereAIChat: boolean;
	setMountSphereAIChat(v: boolean): void;
	companyLogosQuery: UseQueryResult<{
		large: string;
		small: string;
		backgroundColor: string;
		arrowColor: string;
	}>;
} | null>(null);

export function AppDrawer(): JSX.Element {
	const { set: setUserExpand } = useAppDrawerUserExpandStore();
	const { expand, canToggleExpand } = useAppDrawerExpand();
	const sidebarEntries = useSidebarEntries();

	const props = useMemo(
		() => ({ sidebarEntries, toggleExpand: !canToggleExpand ? undefined : () => setUserExpand((e) => !e) }),
		[canToggleExpand, setUserExpand, sidebarEntries],
	);
	const { value: sidebarPosition, set: setSidebarPosition } = useMainSidebarPosition();
	const [showSidebar, setShowSidebar] = useState<null | "chat" | "notifications">(null);
	const [mountSphereAIChat, setMountSphereAIChat] = useState(false);

	const user = useUserValue();
	const customerApi = useApiGen(CustomerControllerV3ApiFactory);

	const companyLogosQuery = useQuery({
		queryKey: [user.customerId, "clientLogo"],
		queryFn: async () => {
			const { primaryLogo, submarkLogo } = await axiosExtract(customerApi.getCustomerById(user.customerId!)).catch(
				// must not fail otherwise we can't show the expand/collapse button
				(err) => {
					reportPlatformError(err, "WARN", "global", {
						message: "fetch customer logo",
						payload: JSON.stringify(user.customerId),
					});
					return { primaryLogo: null, submarkLogo: null };
				},
			);
			return !primaryLogo || !submarkLogo
				? {
						large: SPHERELargeWhite,
						small: SPHEREIconWhite,
						backgroundColor: themeCSSVars.palette_P500,
						arrowColor: "white",
				  }
				: {
						large: generateImageObjectUrlFromFileResponse(primaryLogo),
						small: generateImageObjectUrlFromFileResponse(submarkLogo),
						backgroundColor: themeCSSVars.palette_N0,
						arrowColor: themeCSSVars.palette_N800,
				  };
		},
	});

	return (
		<AppDrawerCtx.Provider
			value={{
				showSidebar,
				setShowSidebar,
				mountSphereAIChat,
				setMountSphereAIChat,
				companyLogosQuery,
			}}
		>
			<StackingContext.Consumer>
				{({ zIndex }) => (
					<Sandwich
						position="sticky"
						style={{
							minWidth: expand ? drawerWidth : collapsedDrawerWidth,
							maxWidth: expand ? drawerWidth : collapsedDrawerWidth,
							width: expand ? drawerWidth : collapsedDrawerWidth,
							backgroundColor: themeCSSVars.palette_N700,
							top: 0,
							left: 0,
							maxHeight: "100dvh",
							overflowX: "hidden",
							zIndex: zIndex + 1,
							borderRightWidth: 1,
							borderRightColor: themeCSSVars.palette_N200,
						}}
						classList="transition-all"
					>
						<Transition
							duration={50}
							in={!expand}
							style={{
								transitionDuration: "50ms",
								minWidth: collapsedDrawerWidth,
								width: collapsedDrawerWidth,
								maxWidth: collapsedDrawerWidth,
							}}
							classList="inset-y-0 left-0 transition-opacity"
							enterFromClassList="opacity-50"
							enterToClassList="opacity-100"
							unmountOnExit
						>
							{({ classList, style }) => <AppDrawerCollapsed {...props} classList={classList} style={style} />}
						</Transition>
						<Transition
							in={expand}
							style={{
								transitionDuration: "50ms",
								minWidth: drawerWidth,
								width: drawerWidth,
								maxWidth: drawerWidth,
							}}
							classList="inset-y-0 left-0 transition-opacity"
							enterFromClassList="opacity-50"
							enterToClassList="opacity-100"
							unmountOnExit
						>
							{({ classList, style }) => <AppDrawerExpanded {...props} classList={classList} style={style} />}
						</Transition>
					</Sandwich>
				)}
			</StackingContext.Consumer>
			<AuthorizationGuard requiredService="ASK_SPHERE">
				{() => (
					<>
						{env.featureFlags.sphereAI.enabled && mountSphereAIChat && (
							<Sidebar
								position={sidebarPosition}
								onChangePosition={setSidebarPosition}
								open={showSidebar === "chat"}
								onClose={() => setShowSidebar(null)}
								width={440}
							>
								{(forward) => <SphereAISidebarContent {...forward} />}
							</Sidebar>
						)}
					</>
				)}
			</AuthorizationGuard>
			<AuthorizationGuard requiredService="NOTIFICATION_CENTER">
				{() => (
					<>
						{env.featureFlags.notificationCenter.enabled && (
							<Sidebar
								position={sidebarPosition}
								onChangePosition={setSidebarPosition}
								open={showSidebar === "notifications"}
								onClose={() => setShowSidebar(null)}
								width={440}
							>
								{(forward) => <NotificationCenterSidebarContent {...forward} />}
							</Sidebar>
						)}
					</>
				)}
			</AuthorizationGuard>
		</AppDrawerCtx.Provider>
	);
}

type SubmenuData<SubName extends string> = {
	parentTitle: string;
	parentName: string;
	entries: Array<SidebarSubmenuEntry<SubName>>;
};

function AppDrawerCollapsed<Name extends string, SubName extends string>({
	sidebarEntries,
	toggleExpand,
	classList,
	style,
}: {
	sidebarEntries: Array<SidebarEntry<Name, SubName>>;
	toggleExpand?(): void;
} & StylableProps) {
	const [submenuData, setSubmenuData] = useState<
		| ({
				left: number;
				top?: number;
				bottom?: number;
		  } & SubmenuData<string>)
		| null
	>(null);

	const closeSubmenuTimeoutDataRef = useRef<ReturnType<typeof setTimeout> | null>(null);

	const keepSubmenuOpen = () => {
		if (closeSubmenuTimeoutDataRef.current != null) {
			clearTimeout(closeSubmenuTimeoutDataRef.current);
			closeSubmenuTimeoutDataRef.current = null;
		}
	};
	const showSubmenuFor = useCallback(<OpenSubName extends string>(e: MouseEvent, submenu: SubmenuData<OpenSubName>) => {
		keepSubmenuOpen();
		const rect = (e?.currentTarget as HTMLElement)?.getBoundingClientRect();
		setSubmenuData({
			left: rect.right,
			// TODO: improve check by measuring actual space available
			...(rect.top < innerHeight / 2
				? {
						top: rect.top,
				  }
				: {
						bottom: innerHeight - rect.bottom,
				  }),
			...submenu,
		});
	}, []);
	const scheduleCloseSubmenu = useCallback(() => {
		if (closeSubmenuTimeoutDataRef.current != null) {
			clearTimeout(closeSubmenuTimeoutDataRef.current);
		}
		closeSubmenuTimeoutDataRef.current = setTimeout(() => {
			setSubmenuData(null);
		}, 100);
	}, []);

	// useEventListener(window, "scroll", () => setSubmenuData(null), { passive: true });

	const location = useLocation();
	const pathAndQuery = location.pathname + location.search;

	const { profileChildren, bottomSidebarEntries } = useSidebarBottomPartial({
		expand: false,
		onScheduleCloseSubmenu: scheduleCloseSubmenu,
		onShowSubmenu: showSubmenuFor,
	});

	return (
		<Column classList={classList} style={style}>
			{submenuData &&
				createPortal(
					<StackingContext.Consumer>
						{({ zIndex }) => (
							<Column
								style={{
									zIndex: zIndex + 1,
									position: "fixed",
									top: submenuData.top,
									bottom: submenuData.bottom,
									left: submenuData.left - 1 /* AppDrawer border */,
									backgroundColor: themeCSSVars.palette_N800,
								}}
								onMouseEnter={() => keepSubmenuOpen()}
								onMouseLeave={scheduleCloseSubmenu}
								classList="rounded-r-[4px] overflow-hidden pb-1.5"
							>
								<Text as="div" classList="pt-2.5 pb-1 pl-2 pr-3" type="Body/M/Bold" color={themeCSSVars.palette_N0}>
									{submenuData.parentTitle}
								</Text>
								<ForEach collection={submenuData.entries}>
									{({ item }) => {
										const Tag = item.url ? "a" : ClickableDiv;
										const isCurrent =
											item.activeOverride ?? item.matchingUrls?.some((url) => isSameRoute(url, pathAndQuery));
										return (
											<Tag
												className={`block py-1.5 pl-2 pr-3 transition-colors hover:bg-[${themeCSSVars.palette_N900}] [&:not(:hover)]:aria-[current="true"]:bg-[${themeCSSVars.palette_N900}]`}
												aria-current={isCurrent}
												data-qualifier={`appDrawer/drawerItem(${submenuData.parentName})/subItem(${item.label})`}
												{...(item.url
													? {
															href: item.url,
															onClick: item.onClick,
													  }
													: { onClick: item.onClick })}
											>
												<Text
													type={isCurrent ? "Body/M/Medium" : "Body/M/Book"}
													color={isCurrent ? themeCSSVars.palette_N0 : themeCSSVars.palette_N200}
												>
													{item.label}
												</Text>
											</Tag>
										);
									}}
								</ForEach>
							</Column>
						)}
					</StackingContext.Consumer>,
					document.body,
				)}
			<LogoAndToggleExpand toggleExpand={toggleExpand} expand={false} />

			<Column classList="pt-4 w-full overflow-y-auto min-h-0" childrenShrink={0} onClick={toggleExpand}>
				<ForEach collection={sidebarEntries}>
					{({ item }) => (
						<IconItem
							{...item}
							style={{
								backgroundColor: submenuData?.parentName === item.name ? themeCSSVars.palette_N800 : undefined,
							}}
							onShowSubmenu={(e) =>
								showSubmenuFor(e, {
									entries: item.submenuEntries ?? [],
									parentName: item.name,
									parentTitle: item.title,
								})
							}
							onScheduleCloseSubmenu={scheduleCloseSubmenu}
						/>
					)}
				</ForEach>
			</Column>

			<Column classList="mt-auto w-full" flexGrow={0}>
				<ForEach collection={bottomSidebarEntries}>
					{({ item }) => (
						<IconItem
							{...item}
							onShowSubmenu={(e) =>
								showSubmenuFor(e, {
									entries: item.submenuEntries ?? [],
									parentName: item.name,
									parentTitle: item.title,
								})
							}
							onScheduleCloseSubmenu={scheduleCloseSubmenu}
						/>
					)}
				</ForEach>
				{profileChildren}
			</Column>
		</Column>
	);
}

function IconItem<Name extends string, SubName extends string>(
	props: {
		onShowSubmenu(e: MouseEvent): void;
		onScheduleCloseSubmenu(): void;
	} & SidebarEntry<Name, SubName> &
		StylableProps,
) {
	const Tag = props.submenuEntries || props.onClick ? ClickableDiv : "a";
	const location = useLocation();
	const pathAndQuery = location.pathname + location.search;
	const isCurrentRoute =
		props.activeOverride ?? props.matchingUrls?.some((url) => isSameRoute(url, pathAndQuery)) ?? false;

	const classString = overrideClassName(
		`flex items-center justify-center w-full h-full px-2 hover:bg-[${themeCSSVars.palette_N900}] [&:not(:hover)]:aria-[current="true"]:bg-[${themeCSSVars.palette_N800}]`,
		props.classList,
	);

	return (
		<Sandwich classList="h-9">
			<Tag
				aria-current={isCurrentRoute}
				data-qualifier={`appDrawer/drawerItem(${props.name})`}
				style={props.style}
				{...(props.submenuEntries
					? {
							onMouseEnter: props.onShowSubmenu,
							onMouseLeave: props.onScheduleCloseSubmenu,
							className: classString,
					  }
					: props.onClick
					  ? {
								onClick: props.onClick,
								classList: classString,
					    }
					  : { href: props.url, className: classString })}
			>
				{typeof props.icon === "string" ? (
					<Icon
						icon={props.icon}
						color={isCurrentRoute ? themeCSSVars.palette_N0 : themeCSSVars.palette_N200}
						size={20}
					/>
				) : (
					props.icon({ size: 20, color: isCurrentRoute ? themeCSSVars.palette_N0 : themeCSSVars.palette_N200 })
				)}
			</Tag>
			<div
				className="absolute left-0 inset-y-0 pointer-events-none"
				style={{
					borderLeftWidth: 4,
					borderLeftColor: isCurrentRoute ? "white" : "transparent",
				}}
			/>
		</Sandwich>
	);
}

function LargeItem<Name extends string, SubName extends string>(props: SidebarEntry<Name, SubName>) {
	const Tag = props.submenuEntries || props.onClick ? ClickableDiv : "a";
	const location = useLocation();
	const pathAndQuery = location.pathname + location.search;

	const classString = "flex items-center justify-center w-full h-full";

	const collapsibleTransitionDuration = 250;
	const isCurrentRoute =
		props.activeOverride ?? props.matchingUrls?.some((url) => isSameRoute(url, pathAndQuery)) ?? false;

	const map = useAppDrawerEntryExpandMapStore();
	const expand = map.value[props.name] ?? false;
	const onChangeExpand = (newExpand: boolean) => map.set((cur) => ({ ...cur, [props.name]: newExpand }));

	return (
		<>
			<Sandwich classList="h-9">
				<Tag
					className={`flex items-center justify-center w-full h-full hover:bg-[${themeCSSVars.palette_N900}] [&:not(:hover)]:aria-[current="true"]:bg-[${themeCSSVars.palette_N800}]`}
					aria-current={isCurrentRoute}
					data-qualifier={`appDrawer/drawerItem(${props.name})`}
					{...(props.submenuEntries
						? {
								onClick: () => onChangeExpand(!expand),
								classList: classString,
						  }
						: props.onClick
						  ? {
									onClick: props.onClick,
									classList: classString,
						    }
						  : { href: props.url, className: classString })}
				>
					<Row gap={6} alignItems="center" classList="pr-4">
						<Row flexGrow={0} flexShrink={0} classList="ml-5 mr-1">
							{typeof props.icon === "string" ? (
								<Icon
									icon={props.icon}
									color={isCurrentRoute ? themeCSSVars.palette_N0 : themeCSSVars.palette_N200}
									size={20}
								/>
							) : (
								props.icon({
									size: 20,
									color: isCurrentRoute ? themeCSSVars.palette_N0 : themeCSSVars.palette_N200,
								})
							)}
						</Row>
						<Text
							type="Body/M/Bold"
							classList="grow min-w-0 truncate w-0"
							title={props.title}
							color={isCurrentRoute ? themeCSSVars.palette_N0 : themeCSSVars.palette_N200}
						>
							{props.title}
						</Text>
						{props.submenuEntries && (
							<div>
								<div
									style={{
										transitionDuration: `${collapsibleTransitionDuration}ms`,
									}}
									className="transition-transform aria-[expanded=true]:[transform:rotateX(180deg)] flex"
									aria-expanded={expand}
								>
									<Icon icon="Down" size={16} color={themeCSSVars.palette_N200} />
								</div>
							</div>
						)}
					</Row>
				</Tag>
				<div
					className="absolute left-0 inset-y-0 pointer-events-none"
					style={{
						borderLeftWidth: 4,
						borderLeftColor: isCurrentRoute ? "white" : "transparent",
					}}
				/>
			</Sandwich>
			{props.submenuEntries && (
				<CollapsibleBase expand={expand} transitionDuration={collapsibleTransitionDuration}>
					<Column
						style={{
							backgroundColor: isCurrentRoute ? themeCSSVars.palette_N800 : undefined,
						}}
					>
						<ForEach collection={props.submenuEntries!}>
							{({ item }) => {
								const EntryTag = item.onClick ? ClickableDiv : "a";
								const isCurrent =
									item.activeOverride ?? item.matchingUrls?.some((url) => isSameRoute(url, pathAndQuery));
								return (
									<EntryTag
										className={`block py-1.5 pl-12 pr-3 transition-colors hover:bg-[${themeCSSVars.palette_N900}] [&:not(:hover)]:aria-[current="true"]:bg-[${themeCSSVars.palette_N900}]`}
										aria-current={isCurrent}
										data-qualifier={`appDrawer/drawerItem(${props.name})/subItem(${item.label})`}
										tabIndex={expand ? 0 : -1}
										{...(item.onClick ? { onClick: item.onClick } : { href: item.url })}
									>
										<Text
											type={isCurrent ? "Body/M/Medium" : "Body/M/Book"}
											color={isCurrent ? themeCSSVars.palette_N0 : themeCSSVars.palette_N200}
										>
											{item.label}
										</Text>
									</EntryTag>
								);
							}}
						</ForEach>
					</Column>
				</CollapsibleBase>
			)}
		</>
	);
}

function AppDrawerExpanded<Name extends string, SubName extends string>({
	sidebarEntries,
	toggleExpand,
	classList,
	style,
}: {
	sidebarEntries: Array<SidebarEntry<Name, SubName>>;
	toggleExpand?(): void;
} & StylableProps) {
	const { profileChildren, bottomSidebarEntries } = useSidebarBottomPartial({
		expand: true,
	});

	return (
		<Column classList={classList} style={style}>
			<LogoAndToggleExpand toggleExpand={toggleExpand} expand />

			<Column classList="pt-4 w-full overflow-y-auto min-h-0" childrenShrink={0}>
				<ForEach collection={sidebarEntries}>{({ item }) => <LargeItem {...item} />}</ForEach>
			</Column>

			<Column classList="mt-auto w-full" flexGrow={0}>
				<ForEach collection={bottomSidebarEntries}>{({ item }) => <LargeItem {...item} />}</ForEach>
				{profileChildren}
			</Column>
		</Column>
	);
}

function LogoAndToggleExpand({ expand, toggleExpand }: { toggleExpand?: () => void; expand: boolean }) {
	const { companyLogosQuery } = useContext(AppDrawerCtx)!;
	const children = ({
		image,
		backgroundColor,
		arrowColor,
	}: {
		image: ReactNode;
		backgroundColor: string;
		arrowColor: string;
	}) => (
		<Sandwich classList="sticky top-0 z-10">
			<Link data-qualifier="appDrawer/sphere" to="/" className="relative block w-full">
				<Row
					justifyContent={expand ? "start" : "center"}
					alignItems="center"
					classList="px-4 py-2"
					style={{ backgroundColor }}
				>
					{image}
				</Row>
			</Link>
			{toggleExpand && (
				<ClickableDiv
					data-qualifier="appDrawer/toggleExpand"
					onClick={toggleExpand}
					classList={{
						"flex justify-center items-center inset-y-0 pl-2 absolute right-0 rounded-r-[4px] overflow-hidden": true,
						"!cursor-e-resize": !expand,
						"!cursor-w-resize": expand,
					}}
				>
					{expand ? (
						<Icon icon="Left" color={arrowColor} size={12} />
					) : (
						<Icon icon="Right" color={arrowColor} size={12} />
					)}
				</ClickableDiv>
			)}
		</Sandwich>
	);
	return (
		<ReactQueryWrapperBase
			query={companyLogosQuery}
			loadingFallback={children({
				image: <CircularProgressBar value="indeterminate" outerDiameter={24} />,
				backgroundColor: "transparent",
				arrowColor: "white",
			})}
			errorFallback={null}
		>
			{({ large, small, backgroundColor, arrowColor }) =>
				children({
					image: <img alt="company logo" src={expand ? large : small} className="h-[36px] w-auto max-w-none" />,
					backgroundColor,
					arrowColor,
				})
			}
		</ReactQueryWrapperBase>
	);
}

function useSidebarBottomPartial(
	params:
		| {
				expand: true;
		  }
		| {
				expand: false;
				onShowSubmenu<OpenSubMenuName extends string>(e: MouseEvent, submenuData: SubmenuData<OpenSubMenuName>): void;
				onScheduleCloseSubmenu(): void;
		  },
) {
	const notificationCenter = useNotificationValue();
	const { user, setUser } = useUserState();
	const { email, name, surname } = user;

	const emailAsUser = email?.substring(0, email.indexOf("@"));
	const username = `${user.name ?? ""} ${user.surname ?? ""}`;

	const resetUser = () => setUser(guestUser);
	const dismissAll = () => toast.dismiss();
	const { push } = useTypedNavigation();

	const handleSignOut = () => {
		queryClient.clear();
		resetUser();
		dismissAll();
		push("Login", {});
	};

	const [isIntercomOpen, setIsIntercomOpen] = useState(false);

	useEventEmitterListener(Intercom.eventEmitter, "show", () => setIsIntercomOpen(true));
	useEventEmitterListener(Intercom.eventEmitter, "hide", () => setIsIntercomOpen(false));

	const { showSidebar, setShowSidebar, mountSphereAIChat, setMountSphereAIChat } = useContext(AppDrawerCtx)!;

	const bottomSidebarEntries = useMemo(
		() =>
			[
				sidebarEntry({
					enabled: env.featureFlags.sphereAI.enabled && hasAccess(user, { requiredService: "ASK_SPHERE" }),
					icon: "sphere-ai",
					name: "Ask sphere",
					title: "Ask sphere",
					activeOverride: showSidebar === "chat",
					onClick() {
						if (!mountSphereAIChat) {
							flushSync(() => {
								setMountSphereAIChat(true);
							});
						}
						setShowSidebar(showSidebar === "chat" ? null : "chat");
					},
				}),
				sidebarEntry({
					enabled:
						env.featureFlags.notificationCenter.enabled && hasAccess(user, { requiredService: "NOTIFICATION_CENTER" }),
					activeOverride: showSidebar === "notifications",
					icon: ({ color, size }) => (
						<Sandwich style={{ width: size, height: size }}>
							<Icon icon="Property-1Notifications" size={size} color={color} />

							{!notificationCenter.markAsRead && (
								<span className="flex absolute h-2 w-2 top-0 right-0 -mr-0.5">
									<span
										className={`animate-ping absolute inline-flex h-full w-full rounded-full bg-[color:${themeCSSVars.palette_D500}] opacity-75`}
									/>
									<span
										className={`relative inline-flex rounded-full h-2 w-2 bg-[color:${themeCSSVars.palette_D500}]`}
									/>
								</span>
							)}
						</Sandwich>
					),
					name: "Notifications",
					title: "Notifications",
					onClick() {
						setShowSidebar(showSidebar === "notifications" ? null : "notifications");
					},
				}),
				sidebarEntry({
					enabled: hasAccess(user, { requiredService: "HELP_CENTER" }),
					icon: "Tooltip-questionmark",
					name: "Help",
					title: "Help",
					activeOverride: isIntercomOpen,
					onClick() {
						if (isIntercomOpen) {
							Intercom.hide();
						} else {
							Intercom.showHelp();
						}
					},
				}),
			].filter((x) => x != null),
		[
			isIntercomOpen,
			mountSphereAIChat,
			notificationCenter.markAsRead,
			setMountSphereAIChat,
			setShowSidebar,
			showSidebar,
			user,
		],
	);

	return {
		bottomSidebarEntries,
		profileChildren: (
			<>
				<Row
					flexGrow={0}
					style={{ backgroundColor: themeCSSVars.palette_N800 }}
					classList="p-2"
					gap={8}
					alignItems="center"
					justifyContent={params.expand ? "start" : "center"}
					onMouseEnter={
						params.expand
							? undefined
							: (e) =>
									params.onShowSubmenu(e, {
										parentName: "User",
										parentTitle: username,
										entries: [{ label: "Logout", onClick: handleSignOut, name: "Logout" }],
									})
					}
					onMouseLeave={params.expand ? undefined : params.onScheduleCloseSubmenu}
				>
					<UserCircle
						variant="name"
						palette="dark"
						firstName={name ?? emailAsUser}
						lastName={surname ?? ""}
						data-qualifier={qualifier.drawer.profilePhoto}
					/>
					{params.expand && (
						<Column classList="min-w-0">
							<Row>
								<Text
									color="white"
									type="Body/M/Bold"
									classList="truncate w-0 grow"
									data-qualifier={qualifier.drawer.profilePhoto}
								>
									{username}
								</Text>
							</Row>
							<Row>
								<ActionText
									as="div"
									onClick={handleSignOut}
									color="white"
									type="Body/S/Link"
									classList="truncate w-0 grow"
									data-qualifier={qualifier.drawer.logout}
								>
									Logout
								</ActionText>
							</Row>
						</Column>
					)}
				</Row>
			</>
		),
	};
}

/* TODO: move to PageLayout? <AuthorizationGuard requiredRole="ROOT">
	{() => (
		<>
			<DataDisplayOverlay classList="mr-4" dataSource="User Data" data={user} />
			<div className={`h-full border-r mx-3 border-r-[${themeCSSVars.palette_N50}]`} />
			<Select
				unstyled
				classList="flex items-center truncate"
				strategy="fixed"
				options={[
					{ value: "MAINTENANCE", label: "Maintenance" },
					{ value: "DEFAULT", label: "Default" },
					{ value: "READONLY", label: "ReadOnly" },
				]}
				value={systemStatus.systemMode}
				i18n={{ triggerPlaceholder: () => "System Mode" }}
				onChange={(newMode: SystemMode) => {
					if (newMode === "MAINTENANCE" && !hasAccess(user, { requiredRole: "ROOT" })) {
						handleSignOut();
					}
					setSystemStatus({ ...systemStatus, systemMode: newMode });
				}}
			/>
		</>
	)}
</AuthorizationGuard> */
