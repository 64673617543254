/** @deprecated */
export const pxToRem = (pixels: number): string => {
	const ONE_REM = 0.0625;
	return `${pixels * ONE_REM}rem`;
};

/** @deprecated */
export const remToPx = (rem: number | string): number | string => {
	const ONE_REM = 0.0625;
	if (typeof rem === "string") {
		const rm = parseFloat(rem);
		if (rm) {
			return `${rm / ONE_REM}px`;
		}
		return 0;
	}
	return rem / ONE_REM;
};
