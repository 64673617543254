import type { TagValue, UserInstrumentsColumnOrdering } from "$root/api/api-gen";
import {
	InstrumentsClassificationsControllerV1ApiFactory,
	PortfolioStudioPreferencesApiFactory,
	type Quality,
	type Score,
	type Tag,
	type UserInstrumentClassificationDto,
	type UserInstrumentDto,
	type UserInstrumentsColumnPreference,
} from "$root/api/api-gen";
import { runWithErrorReporting } from "$root/api/error-reporting/report";
import { useApiGen } from "$root/api/hooks";
import { hasAccess } from "$root/components/AuthorizationGuard";
import { CopyableText } from "$root/components/CopyableText";
import { CustomLabels } from "$root/components/CustomLabels";
import { spawnCustomizeColumnsDialog } from "$root/components/tables-extra/CustomizeColumns";
import type { SpreadsheetCtx } from "$root/components/tables-extra/spreadsheet/spreadsheet-ctx";
import { useLocaleFormatters } from "$root/localization/hooks";
import { axiosExtract } from "$root/third-party-integrations/axios";
import {
	builtInCaseInsensitiveSort,
	builtInCaseInsensitiveSortFor,
	countIf,
	noRefetchDefaults,
	objectTextSearchMatchFns,
	qualifier,
} from "$root/utils";
import type { ActionWithOptionalGroup, IconName, TableColumn, TableWithGroupsColumn } from "@mdotm/mdotui/components";
import { defaultRowHeight, Icon, TableDataCell, TableHeadCellWithDropdown } from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { useSearchable } from "@mdotm/mdotui/headless";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { SortFn } from "@mdotm/mdotui/utils";
import { builtInSort, builtInSortFnFor, noop } from "@mdotm/mdotui/utils";
import type { UseQueryResult } from "@tanstack/react-query";
import { useQueries } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { StoreApi } from "zustand";
import { useUserValue } from "../user";
import type { InstrumentSpreadsheetCellType, InstrumentSpreadsheetCellTypeMap } from "./Instrument-customisation";
import type { CreateClassification } from "./Instrument-customisation/SidePanelColumnsAdder";
import { colorMap, spawnInstrumentCustomizationSidePanel } from "./Instrument-customisation/SidePanelColumnsAdder";
import type { MinimumViableInstrument } from "./InstrumentsSelectorTable";
import CopyableTextCell from "./table-cells/CopyableTextCell";
import DescriptionCell from "./table-cells/DescriptionCell";
import { InstrumentNameFormCell } from "./table-cells/InstrumentNameFormCell";
import QualityCell from "./table-cells/QualityCell";
import SpreadsheetDescriptionCell from "./table-cells/SpreadsheetDescriptionCell";
import SpreadsheetNameCell from "./table-cells/SpreadsheetNameCell";
import SpreadsheetQualityCell from "./table-cells/SpreadsheetQualityCell";
import SpreadsheetRiskModelCell, { proxiesToTags } from "./table-cells/SpreadsheetRiskModelCell";
import SpreadsheetScoreCell from "./table-cells/SpreadsheetScoreCell";
import { SpreadsheetTagDataCell, TagDataCell } from "./tags-v2/table-cell";
import type { InstrumentTagMap, InstrumentTagWeightMap } from "./tags-v2/types";
import { spawnYesNoDialog } from "$root/components/spawnable/yes-no-dialog";

/** @deprecated */
export type CommonInstrumentColumnKey =
	| "instrument"
	| "isin"
	| "assetClass"
	| "microAssetClass"
	| "alias"
	| "identifier";

export const instrumentColumnsMetadataByKey = {
	instrument: {
		name: "instrument",
		sortFn: builtInCaseInsensitiveSortFor("instrument"),
	},
	identifier: {
		name: "identifier",
		sortFn: builtInCaseInsensitiveSortFor("identifier"),
	},
	isin: {
		name: "isin",
		sortFn: builtInCaseInsensitiveSortFor("isin"),
	},
	assetClass: {
		name: "assetClass",
		sortFn: builtInCaseInsensitiveSortFor("assetClass"),
	},
	microAssetClass: {
		name: "microAssetClass",
		sortFn: builtInCaseInsensitiveSortFor("microAssetClass"),
	},
	alias: {
		name: "alias",
		sortFn: builtInCaseInsensitiveSortFor("alias"),
	},
	needsCustomProxy: {
		name: "needsCustomProxy",
		sortFn: builtInSortFnFor("needsCustomProxy"),
	},
	linkedPortfolios: {
		name: "linkedPortfolios",
		sortFn: builtInSortFnFor("linkedPortfolios"),
	},
} satisfies Record<string, { sortFn: SortFn<MinimumViableInstrument>; name: string }>;
export const instrumentColumnsMetadata = Object.values(instrumentColumnsMetadataByKey);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useInstrumentColumnsTableV2(qualfier?: string) {
	const { t } = useTranslation();
	const { formatNumber } = useLocaleFormatters();
	const user = useUserValue();

	function concatQualifier(q: string, parentQualifier?: string) {
		return parentQualifier ? `${parentQualifier}/Column(${q})` : `Column(${q})`;
	}

	return useMemo(
		() =>
			({
				// TODO: translate
				instrument(params?: { onSubmitAsync?(ticker: string, newInstrumentName: string): MaybePromise<void> }) {
					return {
						header: "Instrument name",
						content: (row, props) => (
							<InstrumentNameFormCell
								{...props}
								onSubmitAsync={params?.onSubmitAsync && ((newName) => params?.onSubmitAsync?.(row.ticker!, newName))}
								value={row.instrument}
								data-qualifier={concatQualifier("Instrument", qualfier)}
							/>
						),
						minWidth: 244,
						...instrumentColumnsMetadataByKey.instrument,
					};
				},
				identifier: {
					header: "Identifier",
					content: (row, props) => (
						<CopyableText
							{...props}
							textAppearance={{ classList: "line-clamp-2 overflow-hidden" }}
							onClick={(e) => e.stopPropagation()}
							text={row.identifier ?? ""}
							qualifier={concatQualifier("Instrument", qualfier)}
						/>
					),
					minWidth: 244,
					...instrumentColumnsMetadataByKey.identifier,
				},
				isin: {
					header: "Identifier",
					content: (row, props) => (
						<CopyableText
							{...props}
							textAppearance={{ classList: "line-clamp-2 overflow-hidden" }}
							onClick={(e) => e.stopPropagation()}
							text={row.isin ?? ""}
							qualifier={concatQualifier("Instrument", qualfier)}
						/>
					),
					minWidth: 128,
					...instrumentColumnsMetadataByKey.isin,
				},
				assetClass: {
					header: "Asset class",
					content: (row, props) => (
						<TableDataCell {...props} data-qualifier={concatQualifier("AssetClass", qualfier)}>
							{row.assetClass}
						</TableDataCell>
					),
					minWidth: 144,
					...instrumentColumnsMetadataByKey.assetClass,
				},
				microAssetClass: {
					header: "Micro AC",
					content: (row, props) => (
						<TableDataCell {...props} data-qualifier={concatQualifier("MicroAssetClass", qualfier)}>
							{row.microAssetClass}
						</TableDataCell>
					),
					minWidth: 144,
					...instrumentColumnsMetadataByKey.microAssetClass,
				},
				alias: {
					header: "Identifier",
					content: (row, props) => (
						<CopyableText
							{...props}
							textAppearance={{ classList: "line-clamp-2 overflow-hidden" }}
							onClick={(e) => e.stopPropagation()}
							text={row.alias ?? ""}
							qualifier={concatQualifier("Instrument", qualfier)}
						/>
					),
					minWidth: 144,
					...instrumentColumnsMetadataByKey.alias,
				},
				customLabels(params?: { universeIdentifier?: string }) {
					return {
						header: (headProps) => (
							<TableHeadCellWithDropdown {...headProps}>
								{params?.universeIdentifier ? (
									<CustomLabels labelKey={`${params.universeIdentifier}_score1`} fallback={t("SCORE")} />
								) : (
									t("TABLE.HEADERS.SCORE")
								)}
							</TableHeadCellWithDropdown>
						),
						content: (row) => (row.score ? formatNumber(row.score) : ""),
						sortFn: builtInSortFnFor("score"),
						name: "score",
						hidden: !hasAccess(user, { requiredService: "CUSTOM_QUALITIES" }),
						cellClassList: "tabular-nums",
						width: 186,
					};
				},
			}) satisfies Record<
				string,
				TableColumn<MinimumViableInstrument> | ((...params: any[]) => TableColumn<MinimumViableInstrument>)
			>,
		[formatNumber, qualfier, t, user],
	);
}

export function useSearchableInstrumentTable<T extends MinimumViableInstrument>(
	collection: T[],
	options?: { mode?: "keyword" | "substring"; query?: string },
): {
	query: string;
	setQuery(newQuery: string): void;
	filtered: T[];
	debouncedQuery: string;
} {
	const { filtered, query, setQuery, debouncedQuery } = useSearchable<T>({
		collection,
		matchFn: options?.mode === "keyword" ? objectTextSearchMatchFns.keyword : objectTextSearchMatchFns.substring,
		query: options?.query,
	});

	const memo = useMemo(
		() => ({
			query,
			setQuery,
			filtered,
			debouncedQuery,
		}),
		[filtered, query, setQuery, debouncedQuery],
	);

	return memo;
}

//------------------------------------------------//
export type InstrumentSpreadsheetCtx = {
	scoreSpreadsheet: { [tickerName: string]: Record<string, Score> };
	tagSpreadsheet: { [tickerName: string]: Record<string, Tag> };
	qualitySpreadsheet: { [tickerName: string]: Record<string, Quality> };
};

export type ColumnMode = "EDIT" | "VIEW";

export type UseInstrumentsColumn = {
	updateUserColumn?(columnMetadata: UserInstrumentClassificationDto): MaybePromise<void>;
	createUserColumn?(columnMetadata: UserInstrumentClassificationDto): MaybePromise<void>;
	changeTableLayout?(columnPreferences: Array<UserColumnMetadata>): MaybePromise<void>; // TODO
	deleteUserColumn?(classificationUuid: string): MaybePromise<void>;
} & (
	| {
			mode: Extract<ColumnMode, "EDIT">;
			allColumns: Array<UserColumnMetadata>;
			spreadsheetCtx: StoreApi<SpreadsheetCtx<Record<string, InstrumentSpreadsheetCellType>>>;
			requestAiCommentary?(row: UserInstrumentDto): Promise<void>;
	  }
	| {
			mode: Extract<ColumnMode, "VIEW">;
			allColumns: Array<UserColumnMetadata>;
	  }
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useTableHeaderActions() {
	return useMemo(
		() =>
			({
				editClassificationColumn(params: {
					classification: UserColumnMetadata;
					updateUserColumn(params: UserInstrumentClassificationDto): MaybePromise<void>;
					columnsMetadata: UserColumnMetadata[];
				}) {
					return {
						icon: "Edit" satisfies IconName,
						label: "Edit column",
						onClick: (e) => {
							e.stopPropagation();
							spawnInstrumentCustomizationSidePanel({
								mode: "edit",
								classification: params.classification,
								onSubmitAsync: params.updateUserColumn,
								existingColumnNames: params.columnsMetadata.map((x) => x.name!),
							}).catch(noop);
						},
						group: {
							id: "edit",
						},
					};
				},
				hideClassificationColumn(params: { hideColumn(): MaybePromise<void> }) {
					return {
						icon: "Hide" satisfies IconName,
						label: "Hide column",
						onClickAsync: async (e) => {
							e.stopPropagation();
							await params.hideColumn();
						},
						group: {
							id: "edit",
						},
					};
				},
				deleteClassificationColumn(params: { deleteColumn(): MaybePromise<void> }) {
					return {
						icon: "Delete" satisfies IconName,
						label: "Delete column",
						onClick: (e) => {
							e.stopPropagation();
							spawnYesNoDialog({
								onSubmitAsync: () => params.deleteColumn(),
								children:
									"Deleting this column will remove its content from all views and features across Sphere where it is used.",
							}).catch(noop);
						},
						group: {
							id: "delete",
						},
					};
				},
			}) satisfies Record<
				string,
				ActionWithOptionalGroup<string> | ((...params: any[]) => ActionWithOptionalGroup<string>)
			>,
		[],
	);
}

export function findUserClassificationCell<T extends Tag | Score | Quality>(
	classificationUuidToMatch: string,
	classifications?: T[],
): T | undefined {
	if (!classifications) {
		return undefined;
	}

	return classifications.find((x) => x.classificationUuid === classificationUuidToMatch);
}

export function generateTagMapByClassification(classification: UserInstrumentClassificationDto): InstrumentTagMap {
	const { options } = classification;
	if (!options || options.length === 0) {
		return {};
	}

	return options.reduce<Record<string, { id: string; label: string; badgeColor: string; chartColor: string }>>(
		(optionMap, opt) => {
			if (opt.option) {
				optionMap[opt.option] = {
					badgeColor: opt.color ? colorMap[opt.color].badgeColor() : colorMap.GRAY.badgeColor(),
					chartColor: opt.color ? colorMap[opt.color].chartColor() : colorMap.GRAY.chartColor(),
					id: opt.option,
					label: opt.option,
				};
			}
			return optionMap;
		},
		{},
	);
}

export function generateTagWeightByTag(tags: TagValue[]): InstrumentTagWeightMap {
	return Object.fromEntries(
		tags
			.filter(({ weight }) => weight != null)
			.map(({ option, weight }) => [option, weight] as const)
			.sort(builtInSortFnFor("1", "desc")),
	);
}

export const SphereColumn = {
	name: "NAME",
	description: "DESCRIPTION",
	identifier: "IDENTIFIER",
	riskModel: "RISK_MODEL",
	linkedPortfolios: "LINKED_ENTITIES",
} as const;

export type SphereColumn = (typeof SphereColumn)[keyof typeof SphereColumn];

export type UserColumnMetadata = Omit<UserInstrumentClassificationDto & UserInstrumentsColumnPreference, "name"> & {
	name: string;
	hidden?: boolean;
};

const MAX_CUSTOM_COLUMNS = 15;

function dataAttributesProvider<T extends object>(config: T, qualifierName: string): T {
	return { ...config, "data-qualifier": qualifierName };
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useBaseInstrumentColumns(columnsMetadata: UserColumnMetadata[]) {
	const tableHeaderActions = useTableHeaderActions();
	const user = useUserValue();

	return useMemo(
		() =>
			({
				description(
					params: Pick<CommonColumnParams, "hideUserColumn" | "columnMetadata" | "columnsMetadata"> &
						(
							| {
									mode: Extract<ColumnMode, "EDIT">;
									spreadsheetCtx: StoreApi<
										SpreadsheetCtx<Record<string, InstrumentSpreadsheetCellTypeMap["description"]>>
									>;
									requestAiCommentary?(row: UserInstrumentDto): Promise<void>;
							  }
							| {
									mode: Extract<ColumnMode, "VIEW">;
							  }
						),
				) {
					return {
						header(headProps) {
							return (
								<TableHeadCellWithDropdown {...headProps} actions={commonUserColumnActions(params, tableHeaderActions)}>
									{params.columnMetadata.name ?? "..."}
								</TableHeadCellWithDropdown>
							);
						},
						content: (row, cellProps) =>
							params.mode === "VIEW" ? (
								<DescriptionCell
									{...dataAttributesProvider(
										cellProps,
										qualifier.component.selectableCell(`${row.tickerName}/${params.columnMetadata.name}`),
									)}
									description={row.description}
									classList={` overflow-hidden`}
									style={{ ...(cellProps.style ?? {}), maxHeight: `${defaultRowHeight}px` }}
								/>
							) : (
								<SpreadsheetDescriptionCell
									cellProps={{
										...dataAttributesProvider(
											cellProps,
											qualifier.component.selectableCell(`${row.tickerName}/${params.columnMetadata.name}`),
										),
									}}
									column="DESCRIPTION"
									spreadsheetCtx={params.spreadsheetCtx}
									rowId={row.tickerName!}
									requestAiCommentary={params.requestAiCommentary && (() => params.requestAiCommentary!(row))}
								/>
							),
						name: SphereColumn.description,
						sortFn: (a, b) => builtInSort(a.description?.date ?? "", b.description?.date ?? ""),
						orderable: true,
						minWidth: 250,
						maxWidth: 250,
						width: 250,
					};
				},
				score(
					params: CommonColumnParams &
						(
							| {
									mode: Extract<ColumnMode, "EDIT">;
									spreadsheetCtx: StoreApi<SpreadsheetCtx<Record<string, InstrumentSpreadsheetCellTypeMap["score"]>>>;
							  }
							| {
									mode: Extract<ColumnMode, "VIEW">;
							  }
						),
				) {
					return {
						header(headProps) {
							return (
								<TableHeadCellWithDropdown
									{...headProps}
									data-qualifier={qualifier.component.table.header(
										params.columnMetadata.preferenceType?.classificationUuid ?? "score",
									)}
									actions={commonUserColumnActions(params, tableHeaderActions)}
								>
									{params.columnMetadata.name ?? "..."}
								</TableHeadCellWithDropdown>
							);
						},
						content: (row, cellProps) =>
							//TODO: optimize view phase
							params.mode === "VIEW" ? (
								findUserClassificationCell(params.columnMetadata.classificationUuid!, row.scores)?.value
							) : (
								<SpreadsheetScoreCell
									cellProps={{
										...dataAttributesProvider(
											cellProps,
											qualifier.component.selectableCell(`${row.tickerName}/${params.columnMetadata.name}`),
										),
									}}
									column={params.columnMetadata.classificationUuid!}
									spreadsheetCtx={params.spreadsheetCtx}
									rowId={row.tickerName!}
								/>
							),
						cellClassList: "tabular-nums",
						align: "end",
						name: params.columnMetadata.classificationUuid!,
						orderable: true,
						minWidth: 124,
						maxWidth: 124,
						width: 124,
						sortFn: (a, b) =>
							builtInSort(
								findUserClassificationCell(params.columnMetadata.classificationUuid!, a.scores)?.value ?? -Infinity,
								findUserClassificationCell(params.columnMetadata.classificationUuid!, b.scores)?.value ?? -Infinity,
							),
					};
				},
				quality(
					params: CommonColumnParams &
						(
							| {
									mode: Extract<ColumnMode, "EDIT">;
									spreadsheetCtx: StoreApi<SpreadsheetCtx<Record<string, InstrumentSpreadsheetCellTypeMap["quality"]>>>;
							  }
							| {
									mode: Extract<ColumnMode, "VIEW">;
							  }
						),
				) {
					return {
						header(headProps) {
							return (
								<TableHeadCellWithDropdown
									{...headProps}
									actions={commonUserColumnActions(params, tableHeaderActions)}
									data-qualifier={qualifier.component.table.header(
										params.columnMetadata.preferenceType?.classificationUuid ?? "quality",
									)}
								>
									{params.columnMetadata.name ?? "..."}
								</TableHeadCellWithDropdown>
							);
						},
						content: (row, cellProps) =>
							params.mode === "VIEW" ? (
								<QualityCell
									quality={findUserClassificationCell(params.columnMetadata.classificationUuid!, row.qualities)?.value}
									{...cellProps}
								/>
							) : (
								<SpreadsheetQualityCell
									cellProps={{
										...dataAttributesProvider(
											cellProps,
											qualifier.component.selectableCell(`${row.tickerName}/${params.columnMetadata.name}`),
										),
									}}
									column={params.columnMetadata.classificationUuid!}
									spreadsheetCtx={params.spreadsheetCtx}
									rowId={row.tickerName!}
								/>
							),
						name: params.columnMetadata.classificationUuid!,
						orderable: true,
						minWidth: 250,
						maxWidth: 250,
						width: 250,
						sortFn: (a, b) =>
							builtInCaseInsensitiveSort(
								findUserClassificationCell(params.columnMetadata.classificationUuid!, a.qualities)?.value,
								findUserClassificationCell(params.columnMetadata.classificationUuid!, b.qualities)?.value,
							),
					};
				},
				name(
					params: Pick<CommonColumnParams, "hideUserColumn" | "columnMetadata" | "columnsMetadata"> &
						(
							| {
									mode: Extract<ColumnMode, "EDIT">;
									spreadsheetCtx: StoreApi<SpreadsheetCtx<Record<string, InstrumentSpreadsheetCellTypeMap["name"]>>>;
							  }
							| {
									mode: Extract<ColumnMode, "VIEW">;
							  }
						),
				) {
					return {
						header(headProps) {
							return (
								<TableHeadCellWithDropdown {...headProps} actions={commonUserColumnActions(params, tableHeaderActions)}>
									{params.columnMetadata.name ?? "..."}
								</TableHeadCellWithDropdown>
							);
						},
						content: (row, cellProps) =>
							params.mode === "VIEW" ? (
								row.name ? (
									<CopyableTextCell text={row.name} cellProps={cellProps} />
								) : (
									row.name
								)
							) : (
								<SpreadsheetNameCell
									cellProps={{
										...dataAttributesProvider(
											cellProps,
											qualifier.component.selectableCell(`${row.tickerName}/${params.columnMetadata.name}`),
										),
									}}
									column="NAME"
									spreadsheetCtx={params.spreadsheetCtx}
									rowId={row.tickerName!}
								/>
							),
						name: SphereColumn.name,
						sortFn: builtInSortFnFor("name"),
						orderable: true,
						minWidth: 250,
						maxWidth: 250,
						width: 250,
					};
				},
				identifier(
					params: Pick<CommonColumnParams, "columnMetadata" | "columnsMetadata" | "hideUserColumn"> &
						(
							| {
									mode: Extract<ColumnMode, "EDIT">;
									spreadsheetCtx: StoreApi<
										SpreadsheetCtx<Record<string, InstrumentSpreadsheetCellTypeMap["identifier"]>>
									>;
							  }
							| {
									mode: Extract<ColumnMode, "VIEW">;
							  }
						),
				) {
					return {
						header(headProps) {
							return (
								<TableHeadCellWithDropdown {...headProps} actions={commonUserColumnActions(params, tableHeaderActions)}>
									{params.columnMetadata.name ?? "..."}
								</TableHeadCellWithDropdown>
							);
						},
						content: (row, cellProps) =>
							row.alias ? <CopyableTextCell text={row.alias} cellProps={cellProps} /> : row.alias,
						name: SphereColumn.identifier,
						sortFn: builtInSortFnFor("alias"),
						orderable: true,
						minWidth: 250,
						maxWidth: 250,
						width: 250,
					};
				},
				tag(
					params: CommonColumnParams &
						(
							| {
									mode: Extract<ColumnMode, "EDIT">;
									spreadsheetCtx: StoreApi<
										SpreadsheetCtx<Record<string, InstrumentSpreadsheetCellTypeMap["tagWeightMap"]>>
									>;
							  }
							| {
									mode: Extract<ColumnMode, "VIEW">;
							  }
						),
				) {
					return {
						header(headProps) {
							return (
								<TableHeadCellWithDropdown
									{...headProps}
									actions={commonUserColumnActions(params, tableHeaderActions)}
									data-qualifier={qualifier.component.table.header(
										params.columnMetadata.preferenceType?.classificationUuid ?? "quality",
									)}
								>
									{params.columnMetadata.name ?? "..."}
								</TableHeadCellWithDropdown>
							);
						},
						content: (row, cellProps) => {
							const tagMap = generateTagMapByClassification(params.columnMetadata);

							if (params.mode === "VIEW") {
								const tagCell = findUserClassificationCell(params.columnMetadata.classificationUuid!, row.tags);
								const tagWeightMap = generateTagWeightByTag(tagCell?.values ?? []);

								return (
									<TagDataCell
										tagMap={tagMap}
										tagWeightMap={tagWeightMap}
										viewMode={params.columnMetadata.dataVisualization ?? "BAR_CHART"}
										{...cellProps}
									/>
								);
							}

							return (
								<SpreadsheetTagDataCell
									{...dataAttributesProvider(
										cellProps,
										qualifier.component.selectableCell(`${row.tickerName}/${params.columnMetadata.name}`),
									)}
									column={params.columnMetadata.classificationUuid!}
									spreadsheetCtx={params.spreadsheetCtx}
									viewMode={params.columnMetadata.dataVisualization ?? "BAR_CHART"}
									rowId={row.tickerName!}
									tagMap={tagMap}
								/>
							);
						},
						name: params.columnMetadata.classificationUuid!,
						orderable: true,
						minWidth: 250,
						maxWidth: 250,
						width: 250,
						sortFn: (a, b) =>
							builtInCaseInsensitiveSort(
								findUserClassificationCell(params.columnMetadata.classificationUuid!, a.tags)
									?.values?.slice()
									// TODO: remove alphabetic sorting (the tags will come from BE already sorted)
									.sort(builtInCaseInsensitiveSortFor("option"))?.[0]?.option,
								findUserClassificationCell(params.columnMetadata.classificationUuid!, b.tags)
									?.values?.slice()
									// TODO: remove alphabetic sorting (the tags will come from BE already sorted)
									.sort(builtInCaseInsensitiveSortFor("option"))?.[0]?.option,
							),
					};
				},
				tools(params: {
					createUserColumn?(data: CreateClassification): MaybePromise<void>;
					changeTableLayout?(cols: Array<UserColumnMetadata>): MaybePromise<void>;
					tableLayout: Array<UserColumnMetadata>;
				}) {
					return {
						header: (headerProps) => (
							<TableHeadCellWithDropdown
								{...headerProps}
								data-qualifier={qualifier.component.table.header("tools")}
								actions={() => {
									const columnLimitReached =
										countIf(columnsMetadata, (x) => !(x.name in SphereColumn) && x.fieldType != null) >=
										MAX_CUSTOM_COLUMNS;
									return [
										params.createUserColumn
											? {
													icon: "Outline1" satisfies IconName,
													label: columnLimitReached ? "Add new column (limit reached)" : "Add new column",
													disabled: columnLimitReached,
													onClick: (e) => {
														e.stopPropagation();
														spawnInstrumentCustomizationSidePanel({
															mode: "creation",
															onSubmitAsync: params.createUserColumn!,
															existingColumnNames: columnsMetadata.map((x) => x.name!),
														}).catch(noop);
													},
													"data-qualifier": qualifier.instrumentsCustomisation.columnTools.actions("CreateColumn"),
											  }
											: null,
										params.changeTableLayout
											? {
													icon: "regime-breakdown" satisfies IconName,
													label: "Order and show column",
													onClick: (e) => {
														e.stopPropagation();
														spawnCustomizeColumnsDialog({
															onSubmitAsync: (cols) =>
																params.changeTableLayout!(
																	cols.flatMap((c) => ({
																		...params.tableLayout.find(
																			(original) =>
																				(original.classificationUuid && original.classificationUuid === c.id) ||
																				(original.preferenceType?.classificationUuid &&
																					original.preferenceType?.classificationUuid === c.id),
																		)!,
																		enabled: c.visible,
																	})),
																),
															columns: params.tableLayout.flatMap((x) => {
																const classificationUuid = x.classificationUuid || x.preferenceType?.classificationUuid;
																return classificationUuid
																	? [
																			{
																				id: classificationUuid,
																				label: x.preferenceType?.label ?? "untitled",
																				visible: x.enabled ?? false,
																				hidden: x.hidden ?? false,
																			},
																	  ]
																	: [];
															}),
														});
													},
													"data-qualifier":
														qualifier.instrumentsCustomisation.columnTools.actions("UpdateColumnPreferences"),
											  }
											: null,
									];
								}}
							>
								<Icon icon="Settings" size={21} color={themeCSSVars.palette_P500} />
							</TableHeadCellWithDropdown>
						),
						name: "tools",
						content: () => null,
						minWidth: 40,
						width: 40,
						maxWidth: 40,
						align: "end" as const,
					};
				},
				riskModel(
					params: Pick<CommonColumnParams, "hideUserColumn" | "columnMetadata" | "columnsMetadata"> &
						(
							| {
									mode: Extract<ColumnMode, "EDIT">;
									spreadsheetCtx: StoreApi<
										SpreadsheetCtx<Record<string, InstrumentSpreadsheetCellTypeMap["riskModel"]>>
									>;
							  }
							| {
									mode: Extract<ColumnMode, "VIEW">;
							  }
						),
				) {
					return {
						header: (headProps) => (
							<TableHeadCellWithDropdown {...headProps} actions={commonUserColumnActions(params, tableHeaderActions)}>
								Risk Model
							</TableHeadCellWithDropdown>
						),
						name: SphereColumn.riskModel,
						minWidth: 250,
						maxWidth: 250,
						width: 250,
						content:
							params.mode === "VIEW"
								? (row, cellProps) => {
										const { tagMap, tagWeightMap } = proxiesToTags(row.riskModel);

										return (
											<TagDataCell
												tagMap={tagMap}
												tagWeightMap={tagWeightMap}
												viewMode={params.columnMetadata.dataVisualization ?? "BAR_CHART"}
												{...dataAttributesProvider(
													cellProps,
													qualifier.component.selectableCell(`${row.tickerName}/${params.columnMetadata.name}`),
												)}
											/>
										);
								  }
								: (row, cellProps) => (
										<SpreadsheetRiskModelCell
											cellProps={{
												...dataAttributesProvider(
													cellProps,
													qualifier.component.selectableCell(`${row.tickerName}/${params.columnMetadata.name}`),
												),
											}}
											spreadsheetCtx={params.spreadsheetCtx}
											column="RISK_MODEL"
											rowId={row.tickerName!}
										/>
								  ),
						hidden: !hasAccess(user, { requiredService: "RISK_MODEL_COLUMN" }),
					};
				},
				linkedPortfolios() {
					return {
						header: "Linked entities",
						name: "LinkedPortfolios",
						minWidth: 92,
						maxWidth: 92,
						width: 92,
						cellClassList: "tabular-nums",
						align: "end",
						content: (row) => row.linkedPortfolios,
						sortFn: builtInSortFnFor("linkedPortfolios"),
					};
				},
			}) satisfies Record<
				string,
				TableColumn<UserInstrumentDto> | ((...params: any[]) => TableColumn<UserInstrumentDto>)
			>,
		[columnsMetadata, tableHeaderActions, user],
	);
}

type CommonColumnParams = {
	hideUserColumn?(classificationUuid: string): MaybePromise<void>;
	deleteUserColumn?(classificationUuid: string): MaybePromise<void>;
	updateUserColumn?(data: UserInstrumentClassificationDto): MaybePromise<void>;
	columnMetadata: UserColumnMetadata;
	columnsMetadata: UserColumnMetadata[];
};

function commonUserColumnActions(
	{
		hideUserColumn,
		deleteUserColumn,
		updateUserColumn,
		columnMetadata,
		columnsMetadata,
	}: {
		hideUserColumn?(classificationUuid: string): MaybePromise<void>;
		deleteUserColumn?(classificationUuid: string): MaybePromise<void>;
		updateUserColumn?(data: UserInstrumentClassificationDto): MaybePromise<void>;
		columnMetadata: UserColumnMetadata;
		columnsMetadata: UserColumnMetadata[];
	},
	tableHeaderActions: ReturnType<typeof useTableHeaderActions>,
): ((sortingActions: ActionWithOptionalGroup<"sort">[]) => ActionWithOptionalGroup<string>[]) | undefined {
	return (sortingActions) => [
		...(!updateUserColumn
			? []
			: [
					tableHeaderActions.editClassificationColumn({
						classification: columnMetadata,
						updateUserColumn,
						columnsMetadata,
					}),
			  ]),
		...(!hideUserColumn
			? []
			: [
					tableHeaderActions.hideClassificationColumn({
						hideColumn: () => hideUserColumn(columnMetadata.classificationUuid!),
					}),
			  ]),
		...sortingActions,
		...(!deleteUserColumn
			? []
			: [
					tableHeaderActions.deleteClassificationColumn({
						deleteColumn: () => deleteUserColumn(columnMetadata.classificationUuid!),
					}),
			  ]),
	];
}

function exhaustiveMatchingGuard(_: never): never {
	throw new Error("switch case has an unhandled match");
}

export function useInstrumentsColumn(params: UseInstrumentsColumn): {
	customizableColumns: Array<TableColumn<UserInstrumentDto>>;
	name: TableColumn<UserInstrumentDto>;
	tools: TableColumn<UserInstrumentDto>;
} {
	const baseInstrumentsColumns = useBaseInstrumentColumns(params.allColumns);

	return useMemo(
		() => ({
			customizableColumns: params.allColumns
				.filter((x) => x.enabled)
				.flatMap((column): Array<TableColumn<UserInstrumentDto>> => {
					switch (column.preferenceType?.classificationUuid) {
						case SphereColumn.linkedPortfolios: {
							return [baseInstrumentsColumns.linkedPortfolios()];
						}
						case SphereColumn.riskModel: {
							return [
								params.mode === "EDIT"
									? baseInstrumentsColumns.riskModel({
											mode: "EDIT",
											spreadsheetCtx: params.spreadsheetCtx as StoreApi<
												SpreadsheetCtx<Record<string, InstrumentSpreadsheetCellTypeMap["riskModel"]>>
											>,
											columnMetadata: column,
											columnsMetadata: params.allColumns,
											hideUserColumn: makeHideColumnFn(params, column),
									  })
									: baseInstrumentsColumns.riskModel({
											mode: "VIEW",
											columnMetadata: column,
											columnsMetadata: params.allColumns,
											hideUserColumn: makeHideColumnFn(params, column),
									  }),
							];
						}
						case SphereColumn.description: {
							return [
								params.mode === "EDIT"
									? baseInstrumentsColumns.description({
											mode: "EDIT",
											spreadsheetCtx: params.spreadsheetCtx as StoreApi<
												SpreadsheetCtx<Record<string, InstrumentSpreadsheetCellTypeMap["description"]>>
											>,
											requestAiCommentary: params.requestAiCommentary,
											columnMetadata: column,
											columnsMetadata: params.allColumns,
											hideUserColumn: makeHideColumnFn(params, column),
									  })
									: baseInstrumentsColumns.description({
											mode: "VIEW",
											columnMetadata: column,
											columnsMetadata: params.allColumns,
											hideUserColumn: makeHideColumnFn(params, column),
									  }),
							];
						}
						case SphereColumn.name: {
							// handled separately
							return [];
						}
						case SphereColumn.identifier: {
							return [
								params.mode === "EDIT"
									? baseInstrumentsColumns.identifier({
											mode: "EDIT",
											spreadsheetCtx: params.spreadsheetCtx as StoreApi<
												SpreadsheetCtx<Record<string, InstrumentSpreadsheetCellTypeMap["identifier"]>>
											>,
											columnMetadata: column,
											columnsMetadata: params.allColumns,
											hideUserColumn: makeHideColumnFn(params, column),
									  })
									: baseInstrumentsColumns.identifier({
											mode: "VIEW",
											columnMetadata: column,
											columnsMetadata: params.allColumns,
											hideUserColumn: makeHideColumnFn(params, column),
									  }),
							];
						}
						default: {
							if (!column.fieldType) {
								console.warn("missing field type on non-standard column. Ignored", column);
								return [];
							}
							switch (column.fieldType) {
								case "QUALITY":
									return [
										params.mode === "EDIT"
											? baseInstrumentsColumns.quality({
													mode: "EDIT",
													spreadsheetCtx: params.spreadsheetCtx as StoreApi<
														SpreadsheetCtx<Record<string, InstrumentSpreadsheetCellTypeMap["quality"]>>
													>,
													columnMetadata: column,
													columnsMetadata: params.allColumns,
													hideUserColumn: makeHideColumnFn(params, column),
													deleteUserColumn: makeDeleteColumnFn(params, column),
													updateUserColumn: params.updateUserColumn,
											  })
											: baseInstrumentsColumns.quality({
													mode: "VIEW",
													columnMetadata: column,
													columnsMetadata: params.allColumns,
													hideUserColumn: makeHideColumnFn(params, column),
													deleteUserColumn: makeDeleteColumnFn(params, column),
													updateUserColumn: params.updateUserColumn,
											  }),
									];
								case "SCORE":
									return [
										params.mode === "EDIT"
											? baseInstrumentsColumns.score({
													mode: "EDIT",
													spreadsheetCtx: params.spreadsheetCtx as StoreApi<
														SpreadsheetCtx<Record<string, InstrumentSpreadsheetCellTypeMap["score"]>>
													>,
													columnMetadata: column,
													columnsMetadata: params.allColumns,
													hideUserColumn: makeHideColumnFn(params, column),
													deleteUserColumn: makeDeleteColumnFn(params, column),
													updateUserColumn: params.updateUserColumn,
											  })
											: baseInstrumentsColumns.score({
													mode: "VIEW",
													columnMetadata: column,
													columnsMetadata: params.allColumns,
													hideUserColumn: makeHideColumnFn(params, column),
													deleteUserColumn: makeDeleteColumnFn(params, column),
													updateUserColumn: params.updateUserColumn,
											  }),
									];
								case "TAG":
									return [
										params.mode === "EDIT"
											? baseInstrumentsColumns.tag({
													mode: "EDIT",
													spreadsheetCtx: params.spreadsheetCtx as StoreApi<
														SpreadsheetCtx<Record<string, InstrumentSpreadsheetCellTypeMap["tagWeightMap"]>>
													>,
													columnMetadata: column,
													columnsMetadata: params.allColumns,
													hideUserColumn: makeHideColumnFn(params, column),
													deleteUserColumn: makeDeleteColumnFn(params, column),
													updateUserColumn: params.updateUserColumn,
											  })
											: baseInstrumentsColumns.tag({
													mode: "VIEW",
													columnMetadata: column,
													columnsMetadata: params.allColumns,
													hideUserColumn: makeHideColumnFn(params, column),
													deleteUserColumn: makeDeleteColumnFn(params, column),
													updateUserColumn: params.updateUserColumn,
											  }),
									];
								default:
									return exhaustiveMatchingGuard(column.fieldType);
							}
						}
					}
				}),
			name: (() => {
				const nameColumn = params.allColumns.find(
					(x) => x.preferenceType?.classificationUuid === SphereColumn.name,
				) ?? {
					name: "name",
					preferenceType: { classificationUuid: SphereColumn.name, label: "name" },
				};

				return params.mode === "EDIT"
					? baseInstrumentsColumns.name({
							mode: "EDIT",
							spreadsheetCtx: params.spreadsheetCtx as StoreApi<
								SpreadsheetCtx<Record<string, InstrumentSpreadsheetCellTypeMap["name"]>>
							>,
							columnMetadata: nameColumn,
							columnsMetadata: params.allColumns,
							hideUserColumn: makeHideColumnFn(params, nameColumn),
					  })
					: baseInstrumentsColumns.name({
							mode: "VIEW",
							columnMetadata: nameColumn,
							columnsMetadata: params.allColumns,
							hideUserColumn: makeHideColumnFn(params, nameColumn),
					  });
			})(),
			tools: baseInstrumentsColumns.tools({
				createUserColumn: params.createUserColumn,
				changeTableLayout: params.changeTableLayout,
				tableLayout: params.allColumns,
			}),
		}),
		[baseInstrumentsColumns, params],
	);
}

function makeDeleteColumnFn(params: UseInstrumentsColumn, column: UserColumnMetadata) {
	const deleteColumn = params.deleteUserColumn;
	if (!deleteColumn) {
		return undefined;
	}
	return () => deleteColumn((column.classificationUuid ?? column.preferenceType?.classificationUuid)!);
}

function makeHideColumnFn(params: UseInstrumentsColumn, column: UserColumnMetadata) {
	const changeTableLayout = params.changeTableLayout;
	if (!changeTableLayout) {
		return undefined;
	}
	return () =>
		changeTableLayout(
			params.allColumns.map((c) => ({
				...c,
				enabled:
					(column.classificationUuid && column.classificationUuid === c.classificationUuid) ||
					(column.preferenceType?.classificationUuid &&
						column.preferenceType?.classificationUuid === c.preferenceType?.classificationUuid)
						? false
						: c.enabled ?? false,
			})),
		);
}

export function useGroupedInstrumentsColumn<T extends UserInstrumentDto>(
	params: UseInstrumentsColumn,
): {
	customizableColumns: Array<TableWithGroupsColumn<{ rows: T[] }>>;
	name: TableWithGroupsColumn<{ rows: T[] }>;
	tools: TableWithGroupsColumn<{ rows: T[] }>;
} {
	const baseInstrumentsColumns = useBaseInstrumentColumns(params.allColumns);

	return useMemo(
		() => ({
			customizableColumns: params.allColumns
				.filter((x) => x.enabled)
				.flatMap((column): Array<TableWithGroupsColumn<{ rows: T[] }>> => {
					switch (column.preferenceType?.classificationUuid) {
						case SphereColumn.linkedPortfolios: {
							return [
								{
									...baseInstrumentsColumns.linkedPortfolios(),
									groupContent: () => null,
									sortFn: undefined,
									orderable: false,
								},
							];
						}
						case SphereColumn.description: {
							return [
								{
									...(params.mode === "EDIT"
										? baseInstrumentsColumns.description({
												mode: "EDIT",
												spreadsheetCtx: params.spreadsheetCtx as StoreApi<
													SpreadsheetCtx<Record<string, InstrumentSpreadsheetCellTypeMap["description"]>>
												>,
												requestAiCommentary: params.requestAiCommentary,
												columnMetadata: column,
												columnsMetadata: params.allColumns,
												hideUserColumn: makeHideColumnFn(params, column),
										  })
										: baseInstrumentsColumns.description({
												mode: "VIEW",
												columnMetadata: column,
												columnsMetadata: params.allColumns,
												hideUserColumn: makeHideColumnFn(params, column),
										  })),
									groupContent: () => null,
									sortFn: undefined,
									orderable: false,
								},
							];
						}

						case SphereColumn.name: {
							// handled separately
							return [];
						}

						case SphereColumn.identifier: {
							return [
								{
									...(params.mode === "EDIT"
										? baseInstrumentsColumns.identifier({
												mode: "EDIT",
												spreadsheetCtx: params.spreadsheetCtx as StoreApi<
													SpreadsheetCtx<Record<string, InstrumentSpreadsheetCellTypeMap["identifier"]>>
												>,
												columnMetadata: column,
												columnsMetadata: params.allColumns,
												hideUserColumn: makeHideColumnFn(params, column),
										  })
										: baseInstrumentsColumns.identifier({
												mode: "VIEW",
												columnMetadata: column,
												columnsMetadata: params.allColumns,
												hideUserColumn: makeHideColumnFn(params, column),
										  })),
									sortFn: undefined,
									orderable: false,
									groupContent: () => null,
								},
							];
						}

						case SphereColumn.riskModel: {
							return [
								{
									...(params.mode === "EDIT"
										? baseInstrumentsColumns.riskModel({
												mode: "EDIT",
												spreadsheetCtx: params.spreadsheetCtx as StoreApi<
													SpreadsheetCtx<Record<string, InstrumentSpreadsheetCellTypeMap["riskModel"]>>
												>,
												columnMetadata: column,
												columnsMetadata: params.allColumns,
												hideUserColumn: makeHideColumnFn(params, column),
										  })
										: baseInstrumentsColumns.riskModel({
												mode: "VIEW",
												columnMetadata: column,
												columnsMetadata: params.allColumns,
												hideUserColumn: makeHideColumnFn(params, column),
										  })),
									groupContent: () => null,
									sortFn: undefined,
									orderable: false,
								},
							];
						}
						default: {
							if (!column.fieldType) {
								console.warn("missing field type on non-standard column. Ignored", column);
								return [];
							}
							switch (column.fieldType) {
								case "QUALITY":
									return [
										{
											...(params.mode === "EDIT"
												? baseInstrumentsColumns.quality({
														mode: "EDIT",
														spreadsheetCtx: params.spreadsheetCtx as StoreApi<
															SpreadsheetCtx<Record<string, InstrumentSpreadsheetCellTypeMap["quality"]>>
														>,
														columnMetadata: column,
														columnsMetadata: params.allColumns,
														hideUserColumn: makeHideColumnFn(params, column),
														deleteUserColumn: makeDeleteColumnFn(params, column),
														updateUserColumn: params.updateUserColumn,
												  })
												: baseInstrumentsColumns.quality({
														mode: "VIEW",
														columnMetadata: column,
														columnsMetadata: params.allColumns,
														hideUserColumn: makeHideColumnFn(params, column),
														deleteUserColumn: makeDeleteColumnFn(params, column),
														updateUserColumn: params.updateUserColumn,
												  })),
											groupContent: () => null,
											sortFn: undefined,
											orderable: false,
										},
									];
								case "SCORE":
									return [
										{
											...(params.mode === "EDIT"
												? baseInstrumentsColumns.score({
														mode: "EDIT",
														spreadsheetCtx: params.spreadsheetCtx as StoreApi<
															SpreadsheetCtx<Record<string, InstrumentSpreadsheetCellTypeMap["score"]>>
														>,
														columnMetadata: column,
														columnsMetadata: params.allColumns,
														hideUserColumn: makeHideColumnFn(params, column),
														deleteUserColumn: makeDeleteColumnFn(params, column),
														updateUserColumn: params.updateUserColumn,
												  })
												: baseInstrumentsColumns.score({
														mode: "VIEW",
														columnMetadata: column,
														columnsMetadata: params.allColumns,
														hideUserColumn: makeHideColumnFn(params, column),
														deleteUserColumn: makeDeleteColumnFn(params, column),
														updateUserColumn: params.updateUserColumn,
												  })),
											groupContent: () => null,
											sortFn: undefined,
											orderable: false,
										},
									];
								case "TAG":
									return [
										{
											...(params.mode === "EDIT"
												? baseInstrumentsColumns.tag({
														mode: "EDIT",
														spreadsheetCtx: params.spreadsheetCtx as StoreApi<
															SpreadsheetCtx<Record<string, InstrumentSpreadsheetCellTypeMap["tagWeightMap"]>>
														>,
														columnMetadata: column,
														columnsMetadata: params.allColumns,
														hideUserColumn: makeHideColumnFn(params, column),
														deleteUserColumn: makeDeleteColumnFn(params, column),
														updateUserColumn: params.updateUserColumn,
												  })
												: baseInstrumentsColumns.tag({
														mode: "VIEW",
														columnMetadata: column,
														columnsMetadata: params.allColumns,
														hideUserColumn: makeHideColumnFn(params, column),
														deleteUserColumn: makeDeleteColumnFn(params, column),
														updateUserColumn: params.updateUserColumn,
												  })),
											groupContent: () => null,
											sortFn: undefined,
											orderable: false,
										},
									];
								default:
									return exhaustiveMatchingGuard(column.fieldType);
							}
						}
					}
				}),
			name: (() => {
				const nameColumn = params.allColumns.find(
					(x) => x.preferenceType?.classificationUuid === SphereColumn.name,
				) ?? {
					name: "name",
					preferenceType: { classificationUuid: SphereColumn.name, label: "name" },
				};

				return {
					...(params.mode === "EDIT"
						? baseInstrumentsColumns.name({
								mode: "EDIT",
								spreadsheetCtx: params.spreadsheetCtx as StoreApi<
									SpreadsheetCtx<Record<string, InstrumentSpreadsheetCellTypeMap["name"]>>
								>,
								columnMetadata: nameColumn,
								columnsMetadata: params.allColumns,
								hideUserColumn: makeHideColumnFn(params, nameColumn),
						  })
						: baseInstrumentsColumns.name({
								mode: "VIEW",
								columnMetadata: nameColumn,
								columnsMetadata: params.allColumns,
								hideUserColumn: makeHideColumnFn(params, nameColumn),
						  })),
					sortFn: undefined,
					orderable: false,
					groupContent: () => null,
				};
			})(),
			tools: {
				...baseInstrumentsColumns.tools({
					createUserColumn: params.createUserColumn,
					changeTableLayout: params.changeTableLayout,
					tableLayout: params.allColumns,
				}),
				groupContent: () => null,
			},
		}),
		[baseInstrumentsColumns, params],
	);
}

export function hideNameColumnMetadata(columnMetadata: UserColumnMetadata): UserColumnMetadata {
	if (columnMetadata.preferenceType?.classificationUuid === SphereColumn.name) {
		return {
			...columnMetadata,
			hidden: true,
		};
	}

	return columnMetadata;
}

export type useInstrumentColumnPreferenceProps = {
	applyColumnPreferenceApi(newPreference: UserInstrumentsColumnPreference[]): MaybePromise<void>;
	mapColumnMetadataFn?(columnMetadata: UserColumnMetadata): UserColumnMetadata;
};

export type useInstrumentColumnPreferenceResultProps = {
	columnsMetadata: UserColumnMetadata[];
	changeTableLayout: (newColumnPreferences: Array<UserColumnMetadata>) => MaybePromise<void>;
	queryInstrumentsColumn: [
		UseQueryResult<UserInstrumentsColumnOrdering>,
		UseQueryResult<UserInstrumentClassificationDto[]>,
	];
};

export function useInstrumentColumnPreference(
	params: useInstrumentColumnPreferenceProps,
): useInstrumentColumnPreferenceResultProps {
	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);
	const userValue = useUserValue();
	const instrumentsClassificationsControllerV1Api = useApiGen(InstrumentsClassificationsControllerV1ApiFactory);
	const queryInstrumentsColumn = useQueries({
		queries: [
			{
				queryKey: ["instrumentCustomizationColumnPreferences"],
				queryFn: () => axiosExtract(portfolioStudioPreferencesApi.getUserInstrumentsColumnPreferences()),
				...noRefetchDefaults,
			},
			{
				queryKey: ["instrumentCustomizationUserClassification"],
				queryFn: () => axiosExtract(instrumentsClassificationsControllerV1Api.retrieveAllClassifications()),
				...noRefetchDefaults,
			},
		],
	});

	const [queryInstrumentsColumnPreferences, { data: userClassifications }] = queryInstrumentsColumn;

	const changeTableLayout = useCallback(
		async (newColumnPreferences: Array<UserColumnMetadata>) => {
			await runWithErrorReporting(
				async () => {
					const newInstrumentColumnPreferences = newColumnPreferences.map(
						(x): UserInstrumentsColumnPreference => ({
							enabled: x.enabled ?? false,
							preferenceType: x.preferenceType
								? x.preferenceType
								: {
										classificationUuid: x.classificationUuid,
										label: x.name,
								  },
						}),
					);

					await params.applyColumnPreferenceApi(newInstrumentColumnPreferences);
					await queryInstrumentsColumnPreferences.refetch();
				},
				{
					area: "instrument-customisation",
					attemptedOperation: { message: "change classification preferences" },
				},
			);
		},
		[params, queryInstrumentsColumnPreferences],
	);

	const columnsMetadata = useMemo<Array<UserColumnMetadata>>(() => {
		const { userInstrumentsColumnPreferences } = queryInstrumentsColumnPreferences.data ?? {};
		if (!userInstrumentsColumnPreferences || !userClassifications) {
			return [];
		}
		return (
			userInstrumentsColumnPreferences.map((x) => {
				const classification = userClassifications.find(
					(user) => user.classificationUuid === x.preferenceType?.classificationUuid,
				);

				if (params.mapColumnMetadataFn) {
					const userColumnMetadataEntry = {
						...x,
						...classification,
						name: classification?.name ?? x.preferenceType?.label ?? "Untitled",
					};
					return {
						...params.mapColumnMetadataFn(userColumnMetadataEntry),
						hidden:
							x.preferenceType?.classificationUuid === SphereColumn.riskModel &&
							!hasAccess(userValue, { requiredService: "RISK_MODEL_COLUMN" }),
					};
				}

				return {
					...x,
					...classification,
					hidden:
						x.preferenceType?.classificationUuid === SphereColumn.riskModel &&
						!hasAccess(userValue, { requiredService: "RISK_MODEL_COLUMN" }),
					name: classification?.name ?? x.preferenceType?.label ?? "Untitled",
				};
			}) ?? []
		);
	}, [params, queryInstrumentsColumnPreferences.data, userClassifications, userValue]);

	return { columnsMetadata, changeTableLayout, queryInstrumentsColumn };
}
