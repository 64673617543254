import {
	InvestmentControllerV4ApiFactory,
	type FieldKey,
	type ListInvestmentsDTO,
	type SyncInfoDto,
	type UserPortfolioColumnPreference,
	type UserPortfolioColumnPreferencePreferencesTypeEnum,
} from "$root/api/api-gen";
import { typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";
import { sharingWithColumn } from "$root/functional-areas/acl/table-columns";
import UserShareIcon from "$root/functional-areas/acl/UserShareIcon";
import { aclToUsersListEntries } from "$root/functional-areas/acl/UsersList";
import type { CompareDataItem } from "$root/functional-areas/compare-portfolio/CompareOverlay";
import type { PortfolioAlert } from "$root/functional-areas/portfolio/alerts";
import { mapAlert } from "$root/functional-areas/portfolio/alerts";
import { ReviewEntity } from "$root/functional-areas/proxies/helpers";
import { useUserValue } from "$root/functional-areas/user";
import { useLocaleFormatters } from "$root/localization/hooks";
import { PortfolioDetailsTabs } from "$root/pages/PortfolioDetails/portfolio-details-tabs";
import { builtInCaseInsensitiveSortFor } from "$root/utils/collections";
import AlertTooltip from "$root/widgets-architecture/layout/WidgetsMapper/AlertTooltip";
import type { TableColumn } from "@mdotm/mdotui/components";
import { ActionText, AutoTooltip, Icon, Row, TableDataCell, Text, TooltipContent } from "@mdotm/mdotui/components";
import { overrideClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";
import { addMinutes, format } from "date-fns";
import { t } from "i18next";
import { useMemo } from "react";
import { statusIconMap } from "../statusIconMap";
import ReactQueryWrapper, { ReactQueriesWrapper } from "$root/components/ReactQueryWrapper";
import { axiosExtract } from "$root/third-party-integrations/axios";
import PortfolioExposureSummary from "$root/functional-areas/compare-portfolio/PortfolioExposureSummary";
import { minutes } from "$root/utils";
import { useApiGen } from "$root/api/hooks";

export function usePortfolioColumn({
	comparablePortfolios,
	toggleComparePortfolio,
	columnsPreferences,
	userSyncInfo,
}: {
	comparablePortfolios?: CompareDataItem[];
	toggleComparePortfolio?: (portfolio: ListInvestmentsDTO, enhanced?: boolean) => void;
	columnsPreferences?: UserPortfolioColumnPreference[];
	userSyncInfo?: SyncInfoDto;
}): TableColumn<ListInvestmentsDTO, FieldKey>[] {
	const allColumns = usePortfolioColumns();

	const columns = useMemo(
		() =>
			columnsPreferences
				? columnsPreferences.flatMap((columnMeta) =>
						columnMeta.preferencesType
							? [
									{
										...allColumns[columnMeta.preferencesType]({
											comparablePortfolios,
											toggleComparePortfolio,
											userSyncInfo,
										}),
										hidden: !columnMeta.enabled,
									},
							  ]
							: [],
				  )
				: [],
		[columnsPreferences, allColumns, comparablePortfolios, toggleComparePortfolio, userSyncInfo],
	);

	return columns;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function usePortfolioColumns() {
	const formatters = useLocaleFormatters();
	const { formatDate, formatNumber } = formatters;
	const user = useUserValue();

	const investmentApi = useApiGen(InvestmentControllerV4ApiFactory);
	return useMemo(
		() =>
			({
				NAME: (params?: {
					comparablePortfolios?: CompareDataItem[];
					toggleComparePortfolio?: (portfolio: ListInvestmentsDTO, enhanced?: boolean) => void;
				}) => ({
					header: t(`TABLE.HEADERS.NAME`),
					content: (row, props) => {
						const { uuid, name, richAcl, status, action } = row;
						// const exposureComposition = macroAssetClassExposure?.map((x) => ({
						// 	quality: x.firstQualityLevel,
						// 	weight: x.weight,
						// }));

						// const exposureEnhancedComposition = macroAssetClassExposureEnhanced?.map((x) => ({
						// 	quality: x.firstQualityLevel,
						// 	weight: x.weight,
						// }));

						const isManualCreation = status === "DRAFT" && action === "UPLOAD";
						const isPipelineCreation = status === "DRAFT" && action === "CREATION";

						const selected = params?.comparablePortfolios?.find((x) => x.uuid === uuid && !x.enhanced) !== undefined;

						const selectedEnhanced =
							params?.comparablePortfolios?.find((x) => x.uuid === uuid && x.enhanced) !== undefined;

						return (
							<AutoTooltip
								severity="info"
								position="right"
								disabled={
									status === "ERROR" ||
									status === "CALCULATING" ||
									status === "REVIEW" ||
									status === "DRAFT" ||
									status === "RETRIEVING_DATA"
								}
								openDelay={700}
								trigger={({ innerRef }) => (
									<div style={props.style} className={overrideClassName(props.classList, "items-center flex")}>
										<ActionText
											classList="truncate mr-1"
											color={themeCSSVars.palette_N900}
											href={
												isManualCreation
													? typedUrlForRoute("Portfolios/ManualPortfolioCreation", { uuid })
													: isPipelineCreation
													  ? typedUrlForRoute("Portfolios/Draft", { portfolioUid: uuid! })
													  : status === "REVIEW"
													    ? typedUrlForRoute("ReviewInstruments", { uuid: uuid!, entity: ReviewEntity.PORTFOLIO })
													    : typedUrlForRoute("PortfolioDetails", { portfolioUid: uuid! })
											}
											title={name}
											innerRef={innerRef}
											data-qualifier="PortfolioStudio/PortfolioList/Table/Column(Name)"
										>
											{name}
										</ActionText>
										<UserShareIcon
											entity="INVESTMENT"
											userId={user.id}
											permission={richAcl?.currentUserPermissions}
											color={themeCSSVars.palette_N300}
											acl={richAcl?.acl}
										/>
									</div>
								)}
							>
								<TooltipContent>
									<div className="w-[280px] overflow-hidden space-y-2">
										<ReactQueryWrapper
											queryKey={["portfolioExposure", row.uuid!, row.action!]}
											cacheTime={minutes(10)}
											staleTime={Infinity}
											queryFn={() => axiosExtract(investmentApi.getExposureCompare([row.uuid!])).then((x) => x[0])}
										>
											{({ macroAssetClassExposure, macroAssetClassExposureEnhanced }) => (
												<>
													<PortfolioExposureSummary
														compared={selected}
														subtitle="current"
														composition={macroAssetClassExposure ?? []}
														onCompare={(e) => {
															e.stopPropagation();
															params?.toggleComparePortfolio?.(row);
														}}
														title={name ?? "-"}
														// note={lastActionNote}
														// action={action}
													/>
													{macroAssetClassExposureEnhanced && row.status === "PROPOSAL_READY" && (
														<PortfolioExposureSummary
															compared={selectedEnhanced}
															subtitle="proposal"
															subtitleClassList={`!text-[color:${themeCSSVars.palette_S400}]`}
															composition={macroAssetClassExposureEnhanced ?? []}
															onCompare={(e) => {
																e.stopPropagation();
																params?.toggleComparePortfolio?.(row, true);
															}}
															action={action}
														/>
													)}
												</>
											)}
										</ReactQueryWrapper>
									</div>
								</TooltipContent>
							</AutoTooltip>
						);
					},
					sortFn: builtInCaseInsensitiveSortFor("name"),
					name: "NAME",
					width: 320,
				}),

				SHARING_VIEWS: (params?: unknown) => ({
					...sharingWithColumn({
						users: ({ richAcl }) => aclToUsersListEntries("portfolio", richAcl?.acl ?? []),
					}),
					header: t(`TABLE.HEADERS.SHARING_VIEWS`),
					name: "SHARING_VIEWS",
				}),

				INCEPTION_DATE: (params?: unknown) => ({
					header: t(`TABLE.HEADERS.INCEPTION_DATE`),
					content: ({ creationTime }) => {
						const sanitizedCreationTime = creationTime ? formatDate(new Date(creationTime)) : "-";
						return sanitizedCreationTime;
					},
					sortFn: ({ creationTime: a }, { creationTime: b }) => {
						const rowA = new Date(a ?? "").getTime();
						const rowB = new Date(b ?? "").getTime();
						return rowA > rowB ? 1 : rowA < rowB ? -1 : 0;
					},
					name: "INCEPTION_DATE",
					cellClassList: "tabular-nums",
					align: "end",
					minWidth: 128,
				}),

				CURRENT_ACTION: (params?: unknown) => ({
					header: t(`TABLE.HEADERS.CURRENT_ACTION`),
					content: ({ action }) => {
						const translatedAction = action ? t(`PORTFOLIO_ACTION_STATUS.${action}`) : "-";
						return translatedAction;
					},
					sortFn: builtInCaseInsensitiveSortFor("action"),
					name: "CURRENT_ACTION",
					minWidth: 92,
				}),

				STATUS: (params?: unknown) => ({
					header: t(`TABLE.HEADERS.STATUS`),
					content: ({ status }, cellProps) => {
						const sanitizedStatus = status ? statusIconMap[status] : "-";
						if (sanitizedStatus === "-") {
							return (
								<TableDataCell {...cellProps} data-qualifier="PortfolioStudio/PortfolioList/Table/Column(Status)">
									{sanitizedStatus}
								</TableDataCell>
							);
						}
						return (
							<Row
								{...cellProps}
								title={sanitizedStatus.title}
								alignItems="center"
								data-qualifier="PortfolioStudio/PortfolioList/Table/Column(Status)"
							>
								<Icon
									classList="mr-1"
									icon={sanitizedStatus.icon}
									color={themeCSSVars.palette_N800} // changed
									size={sanitizedStatus.size}
								/>
								<Text type="Body/M/Book" classList="line-clamp-2">
									{sanitizedStatus.title}
								</Text>
							</Row>
						);
					},
					sortFn: builtInCaseInsensitiveSortFor("status"),
					name: "STATUS",
					minWidth: 128,
				}),

				LAST_STATUS_UPDATE: (params?: unknown) => ({
					header: t(`TABLE.HEADERS.LAST_STATUS_UPDATE`),
					content: ({ modificationTime }) => {
						const sanitizedModificationTime = modificationTime ? formatDate(new Date(modificationTime)) : "-";
						return sanitizedModificationTime;
					},
					sortFn: ({ modificationTime: a }, { modificationTime: b }) => {
						const rowA = new Date(a ?? "").getTime();
						const rowB = new Date(b ?? "").getTime();
						return rowA > rowB ? 1 : rowA < rowB ? -1 : 0;
					},
					name: "LAST_STATUS_UPDATE",
					cellClassList: "tabular-nums",
					align: "end",
					minWidth: 92,
				}),

				WARNINGS: (params?: unknown) => ({
					header: "W.",
					align: "end",
					content: ({ alerts }, props) => {
						if (!alerts || alerts.length === 0) {
							return null;
						}
						return (
							<TableDataCell {...props} onClick={(e) => e.stopPropagation()}>
								<AlertTooltip
									alerts={alerts
										.filter((a) => Boolean(a.type))
										.map((a) => mapAlert({ t, ...formatters }, a as PortfolioAlert))}
									summary
								/>
							</TableDataCell>
						);
					},
					cellClassList: "grow",
					sortFn: builtInSortFnFor("alerts"),
					name: "WARNINGS",
					minWidth: 64,
					maxWidth: 64,
				}),

				AUTO_SYNC: (params?: { userSyncInfo?: SyncInfoDto }) => ({
					header: t(`TABLE.HEADERS.AUTO_SYNC`),
					content: (row, props) => {
						if (
							row.importedExternally &&
							user.automaticImport &&
							params?.userSyncInfo &&
							params.userSyncInfo.lastExecutionTime &&
							params.userSyncInfo.frequency
						) {
							const lastAutoImportSync = new Date(params.userSyncInfo.lastExecutionTime);
							const nextAutoImportSync = addMinutes(lastAutoImportSync, params.userSyncInfo.frequency);
							return (
								<TableDataCell style={props.style} classList={props.classList} onClick={props.onClick}>
									<AutoTooltip
										position="top"
										overrideColor={themeCSSVars.palette_N400}
										trigger={({ innerRef }) => (
											<Icon icon="Outline" innerRef={innerRef} color={themeCSSVars.palette_N400} size={20} />
										)}
									>
										<TooltipContent>
											LAST CHECK: {format(lastAutoImportSync, "KK:mm")} - NEXT CHECK:{" "}
											{format(nextAutoImportSync, "KK:mm")}
										</TooltipContent>
									</AutoTooltip>
								</TableDataCell>
							);
						}

						return null;
					},
					minWidth: 128,
					sortFn: builtInSortFnFor("importedExternally"),
					name: "AUTO_SYNC",
				}),

				MARKET_VIEW_NAME: (params?: unknown) => ({
					header: t(`TABLE.HEADERS.MARKET_VIEW_NAME`),
					content: (row, props) => {
						if (!row.marketView) {
							return null;
						}

						const { marketViewType, scenarioName, scenarioIdentifier, forecastDates } = row.marketView;
						return (
							<TableDataCell {...props}>
								<ActionText
									classList="line-clamp-2"
									href={
										scenarioIdentifier === "CUSTOM_PORTFOLIO_MARKET_VIEW"
											? `${typedUrlForRoute("PortfolioDetails", {
													// redirect to portfolio settings
													portfolioUid: row?.uuid ?? "",
											  })}?tab=${PortfolioDetailsTabs.PORTFOLIO_STUDIO_SETTINGS}#marketView`
											: scenarioIdentifier && marketViewType
											  ? typedUrlForRoute("MarketViewWorkSpace", {
														uuid: scenarioIdentifier,
														isCustom: String(row.marketView.custom ?? false),
														action: "view",
														type: String(marketViewType),
											    })
											  : ""
									}
									title={!scenarioName ? `Market view ${formatDate(forecastDates?.from)}` : scenarioName}
								>
									{!scenarioName ? `Market view ${formatDate(forecastDates?.from)}` : scenarioName}
								</ActionText>
							</TableDataCell>
						);
					},
					minWidth: 192,
					name: "MARKET_VIEW_NAME",
					sortFn: builtInSortFnFor("marketView"),
				}),

				CURRENCY_NAME: (params?: unknown) => ({
					header: t(`TABLE.HEADERS.CURRENCY_NAME`),
					content: (row) => row.currencyName ?? "-",
					minWidth: 92,
					name: "CURRENCY_NAME",
					sortFn: builtInCaseInsensitiveSortFor("currencyName"),
				}),

				BENCHMARK_NAME: (params?: unknown) => ({
					header: t(`TABLE.HEADERS.BENCHMARK_NAME`),
					content: (row) => row.benchmarkName,
					minWidth: 192,
					name: "BENCHMARK_NAME",
					sortFn: builtInCaseInsensitiveSortFor("benchmarkName"),
				}),

				UNIVERSE_NAME: (params?: unknown) => ({
					header: t(`TABLE.HEADERS.UNIVERSE_NAME`),
					content: (row) => row.universeName,
					minWidth: 192,
					name: "UNIVERSE_NAME",
					sortFn: builtInCaseInsensitiveSortFor("universeName"),
				}),

				COMMENTARY_TEMPLATE: (params?: unknown) => ({
					header: t(`TABLE.HEADERS.COMMENTARY_TEMPLATE`),
					content: (row) => row.commentaryTemplate ?? "",
					minWidth: 192,
					name: "COMMENTARY_TEMPLATE",
					sortFn: builtInCaseInsensitiveSortFor("commentaryTemplate"),
				}),

				// benchmark eff ratio
				BENCHMARK_EFFICIENCY_RATIO_1M: (params?: unknown) => ({
					header: t(`TABLE.HEADERS.BENCHMARK_EFFICIENCY_RATIO_1M`),
					content: (row) => formatNumber(row.benchmarkEfficiencyRatio1M ?? 0),
					sortFn: builtInSortFnFor("benchmarkEfficiencyRatio1M"),
					name: "EFFICIENCY_RATIO_1M",
					cellClassList: "tabular-nums",
					align: "end",
					minWidth: 128,
				}),

				// benchmark max drawdown
				BENCHMARK_MAX_DRAWDOWN_1M: (params?: unknown) => ({
					header: t(`TABLE.HEADERS.BENCHMARK_MAX_DRAWDOWN_1M`),
					content: (row) => `${formatNumber(row.benchmarkMaxDrawdown1M ?? 0)}%`,
					sortFn: builtInSortFnFor("benchmarkMaxDrawdown1M"),
					name: "BENCHMARK_MAX_DRAWDOWN_1M",
					cellClassList: "tabular-nums",
					align: "end",
					minWidth: 92,
				}),

				// benchmark performance
				BENCHMARK_PERFORMANCE_1M: (params?: unknown) => ({
					header: t(`TABLE.HEADERS.BENCHMARK_PERFORMANCE_1M`),
					content: (row) => `${formatNumber(row.benchmarkPerformance1M ?? 0)}%`,
					sortFn: builtInSortFnFor("benchmarkPerformance1M"),
					cellClassList: "tabular-nums",
					align: "end",
					name: "BENCHMARK_PERFORMANCE_1M",
					minWidth: 128,
				}),

				// benchmark sortino
				BENCHMARK_SORTINO_1M: (params?: unknown) => ({
					header: t(`TABLE.HEADERS.BENCHMARK_SORTINO_1M`),
					content: (row) => `${formatNumber(row.benchmarkSortino1M ?? 0)}%`,
					sortFn: builtInSortFnFor("benchmarkSortino1M"),
					cellClassList: "tabular-nums",
					align: "end",
					name: "SORTINO_1M",
					minWidth: 128,
				}),

				//benchmark volatility
				BENCHMARK_VOLATILITY_1M: (params?: unknown) => ({
					header: t(`TABLE.HEADERS.BENCHMARK_VOLATILITY_1M`),
					content: (row) => `${formatNumber(row.benchmarkVolatility1M ?? 0)}%`,
					sortFn: builtInSortFnFor("benchmarkVolatility1M"),
					cellClassList: "tabular-nums",
					align: "end",
					name: "BENCHMARK_VOLATILITY_1M",
					minWidth: 92,
				}),

				//efficiency ratio volatility
				EFFICIENCY_RATIO_1M: (params?: unknown) => ({
					header: t(`TABLE.HEADERS.EFFICIENCY_RATIO_1M`),
					content: (row) => `${formatNumber(row.efficiencyRatio1M ?? 0)}`,
					sortFn: builtInSortFnFor("efficiencyRatio1M"),
					cellClassList: "tabular-nums",
					align: "end",
					name: "EFFICIENCY_RATIO_1M",
					minWidth: 92,
				}),

				// max drawdown
				MAX_DRAWDOWN_1M: (params?: unknown) => ({
					header: t(`TABLE.HEADERS.MAX_DRAWDOWN_1M`),
					content: (row) => `${formatNumber(row.maxDrawdown1M ?? 0)}%`,
					sortFn: builtInSortFnFor("maxDrawdown1M"),
					cellClassList: "tabular-nums",
					align: "end",
					name: "MAX_DRAWDOWN_1M",
					minWidth: 128,
				}),

				// performance
				PERFORMANCE_1M: (params?: unknown) => ({
					header: t(`TABLE.HEADERS.PERFORMANCE_1M`),
					content: (row) => `${formatNumber(row.performance1M ?? 0)}%`,
					sortFn: builtInSortFnFor("performance1M"),
					cellClassList: "tabular-nums",
					align: "end",
					name: "PERFORMANCE_1M",
					minWidth: 128,
				}),

				// Sortino 1Y
				SORTINO_1M: (params?: unknown) => ({
					header: t(`TABLE.HEADERS.SORTINO_1M`),
					content: (row) => `${formatNumber(row.sortino1M ?? 0)}`,
					sortFn: builtInSortFnFor("sortino1M"),
					cellClassList: "tabular-nums",
					align: "end",
					name: "SORTINO_1M",
					minWidth: 64,
				}),

				// Volatility
				VOLATILITY_1M: (params?: unknown) => ({
					header: t(`TABLE.HEADERS.VOLATILITY_1M`),
					content: (row) => `${formatNumber(row.volatility1M ?? 0)}`,
					sortFn: builtInSortFnFor("volatility1M"),
					cellClassList: "tabular-nums",
					align: "end",
					name: "VOLATILITY_1M",
					minWidth: 92,
				}),

				AVERAGE_SCORE: (params?: unknown) => ({
					// this column is deprecated maintained by waiting for be migration
					header: t(`TABLE.HEADERS.AVERAGE_SCORE`),
					content: () => <div />,
					name: "SCORE_AVERAGE",
					align: "end",
					cellClassList: "tabular-nums",
					minWidth: 92,
				}),
			}) satisfies Record<
				UserPortfolioColumnPreferencePreferencesTypeEnum,
				(...args: any[]) => TableColumn<ListInvestmentsDTO, FieldKey>
			>,
		[formatDate, formatNumber, formatters, user.automaticImport, user.id],
	);
}
