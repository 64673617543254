import type {
	SpreadsheetCellApi,
	SpreadsheetPropsValidator,
} from "$root/components/tables-extra/spreadsheet/spreadsheet-cell";
import { useSpreadsheetCell } from "$root/components/tables-extra/spreadsheet/spreadsheet-cell";
import { qualifier } from "$root/utils";
import type { TableDataCellProps } from "@mdotm/mdotui/components";
import { NullableNumberInputOverlayEditor, TableDataCell } from "@mdotm/mdotui/components";
import type { InstrumentSpreadsheetCellTypeMap } from "../Instrument-customisation";
import SelectableCell from "./SelectableCell";

type SpreadsheetScoreCellProps = {
	cellProps: Omit<TableDataCellProps, "children">;
};

function SpreadsheetScoreCell<Row, K extends keyof Row & string>({
	column,
	rowId,
	spreadsheetCtx,
	cellProps,
	...forward
}: SpreadsheetPropsValidator<
	Row,
	K,
	InstrumentSpreadsheetCellTypeMap["score"],
	SpreadsheetScoreCellProps
>): JSX.Element {
	const cellApi = useSpreadsheetCell(spreadsheetCtx, rowId, column) as SpreadsheetCellApi<
		InstrumentSpreadsheetCellTypeMap["score"]
	>;

	return (
		<SelectableCell spreadsheetCtx={spreadsheetCtx} {...cellProps} {...forward} rowId={rowId} column={column}>
			{({ style }) => (
				<NullableNumberInputOverlayEditor
					onClickAway={cellApi.deactivate}
					onBlur={cellApi.deactivate}
					value={cellApi.data?.value ?? null}
					onChange={(newValue) =>
						cellApi.update({
							...cellApi.data,
							kind: "score",
							value: newValue ?? undefined,
						})
					}
					isOpen={cellApi.active}
					trigger={({ innerRef }) => (
						<TableDataCell innerRef={innerRef} classList="min-w-0 flex-1 tabular-nums" align="end" style={style}>
							{cellApi.data?.value}
						</TableDataCell>
					)}
					data-qualifier={qualifier.component.input.nullableInputOverlay}
				/>
			)}
		</SelectableCell>
	);
}

export default SpreadsheetScoreCell;
