import {
	InvestmentBulkBulkStaticConfigurationControllerV1ApiFactory,
	InvestmentBulkEnhancementConfigurationControllerV4ApiFactory,
	StepAvailabilityStatus,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { axiosExtract } from "$root/third-party-integrations/axios";
import type { ContextContent } from "$root/utils";
import { parallelize, useHashState, useQueryNoRefetch } from "$root/utils";
import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import { renderNodeOrFn, useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import type { SetterOrUpdater } from "@mdotm/mdotui/utils";
import { maybeCall, switchExpr } from "@mdotm/mdotui/utils";
import type { MutableRefObject } from "react";
import { createContext, useCallback, useMemo, useRef, useState } from "react";
import type { MultiStepFormV2Props, StepMetadata } from "../../MultiStepFormV2/MultiStepFormV2";
import { MultiStepFormV2 } from "../../MultiStepFormV2/MultiStepFormV2";
import { Step06_MarketView, getBulkMarketViewStepData } from "./Steps/06-MarketView";
import { makeStepToRequestMapper, responseToStepMapper } from "./requests-multi";
import { makeUnsavedDataHandler } from "./shared";
import type { EditPortfolioMultiV4StepName, EditPortfolioMultiV4StepPayloadMap } from "./shared-multi";
import {
	defaultEditPortfolioMultiV4StepData,
	editPortfolioMultiV4InitialStepsMetadataByMode,
	editPortfolioMultiV4OrderedSteps,
} from "./shared-multi";

export type EditPortfolioMultiArea = "create-bulk-proposal" | "edit-bulk-settings";

export type EditMulti_SubmittersRef = MutableRefObject<
	Partial<Record<keyof EditPortfolioMultiV4StepPayloadMap, () => Promise<void>>>
>;

export type EditPortfolioMultiV4Props = {
	area:
		| {
				name: "create-bulk-proposal";
				bulkUid?: undefined;
				proposalUuid: string;
				editable: boolean;
				edit: boolean;
		  }
		| {
				name: "edit-bulk-settings";
				bulkUid: string;
				proposalUuid?: undefined;
				editable: boolean;
				edit: boolean;
		  };
	currentStep: EditPortfolioMultiV4StepName;
	submittersRef: EditMulti_SubmittersRef;
	setCurrentStep(stepName: EditPortfolioMultiV4StepName): void;
	stepsMetadata: { [K in EditPortfolioMultiV4StepName]: StepMetadata };
	setStepsMetadata: SetterOrUpdater<{ [K in EditPortfolioMultiV4StepName]: StepMetadata }>;
	stepsData: EditPortfolioMultiV4StepPayloadMap;
	setStepsData(stepsData: EditPortfolioMultiV4StepPayloadMap): void;
	saveHandlers: {
		[K in keyof EditPortfolioMultiV4StepPayloadMap]: (
			data: EditPortfolioMultiV4StepPayloadMap[K],
		) => Promise<EditPortfolioMultiV4StepPayloadMap[K]>;
	};
};

export type EditMulti_MultiStepPortfolioContext = {
	area: EditPortfolioMultiV4Props["area"];
	saveHandlers: EditPortfolioMultiV4Props["saveHandlers"];
	submittersRef: EditPortfolioMultiV4Props["submittersRef"];
};

const TypedMultiStepper = (
	props: MultiStepFormV2Props<typeof defaultEditPortfolioMultiV4StepData, EditMulti_MultiStepPortfolioContext>,
) => (
	<MultiStepFormV2
		unsavedDataHandler={makeUnsavedDataHandler({
			currentStep: props.currentStep,
			setStepsMetadata: props.setStepsMetadata,
			submittersRef: props.context!.submittersRef,
		})}
		{...props}
	/>
);

const EditPortfolioMultiV4Context = createContext<
	| null
	| ({ canSubmit: boolean } & Omit<EditPortfolioMultiV4Props, "stepsData"> & {
				stepsData: EditPortfolioMultiV4Props["stepsData"];
			})
>(null);

export type EditPortfolioMultiV4ContextType = NonNullable<ContextContent<typeof EditPortfolioMultiV4Context>>;

export function EditPortfolioMultiV4ContextProvider({
	area: propsArea,
	children,
}: {
	area: EditPortfolioMultiV4Props["area"];
	children: NodeOrFn<EditPortfolioMultiV4ContextType>;
}): JSX.Element {
	const area = useMemo(
		() => ({
			...propsArea,
			editable: true,
		}),
		[propsArea],
	);

	const defaultStepsMetadata = editPortfolioMultiV4InitialStepsMetadataByMode[area.name];
	const [editedStepsMetadata, _setEditedStepsMetadata] = useState<null | EditPortfolioMultiV4Props["stepsMetadata"]>(
		null,
	);
	const defaultStepsMetadataRef = useUnsafeUpdatedRef(defaultStepsMetadata);
	const setEditedStepsMetadata = useCallback<SetterOrUpdater<EditPortfolioMultiV4Props["stepsMetadata"]>>(
		(x) => _setEditedStepsMetadata((prev) => maybeCall(x, prev ?? defaultStepsMetadataRef.current)),
		[defaultStepsMetadataRef],
	);

	const stepsMetadata = editedStepsMetadata ?? defaultStepsMetadata;
	const [nullableCurrentStep, setCurrentStep] = useHashState({
		allowedValues: editPortfolioMultiV4OrderedSteps,
	});
	const currentStep = nullableCurrentStep ?? "marketView";
	const [stepsData, setStepsData] = useState<EditPortfolioMultiV4Props["stepsData"]>(
		defaultEditPortfolioMultiV4StepData,
	);

	const bulkEnhancementApi = useApiGen(InvestmentBulkEnhancementConfigurationControllerV4ApiFactory);
	const investmentBulkBulkStaticV1Api = useApiGen(InvestmentBulkBulkStaticConfigurationControllerV1ApiFactory);

	useQueryNoRefetch({
		queryKey: ["loadProposalSteps", area.proposalUuid, area.bulkUid],
		enabled: area.proposalUuid !== undefined || area.bulkUid !== undefined,
		queryFn: async () => {
			const stepData = await parallelize(
				{
					marketView: () => getBulkMarketViewStepData(bulkEnhancementApi, investmentBulkBulkStaticV1Api, area),
				},
				{ concurrency: 2 },
			);

			const { availabilityMap } = await axiosExtract(
				area.name === "create-bulk-proposal"
					? bulkEnhancementApi.getEnhancementConfigurationStepsAvailability1(
							(area as typeof area & { proposalUuid: string }).proposalUuid,
					  )
					: investmentBulkBulkStaticV1Api.getEnhancementConfigurationStepsAvailability2(area.bulkUid),
			);

			return {
				stepData,
				availabilityMap,
			};
		},
		onSuccess({ availabilityMap, stepData }) {
			setStepsData(stepData);
			setEditedStepsMetadata(mergeStepsMetadata(defaultStepsMetadata, availabilityMap));
		},
	});

	const mergeStepMetadata = useCallback(
		(metadata: StepMetadata, availabilityStatus: StepAvailabilityStatus) => ({
			...metadata,
			dirty: false, // if we get here, it means that the user successfully saved the step
			completed: availabilityStatus === StepAvailabilityStatus.Ready, // !empty
			hasErrors: availabilityStatus === StepAvailabilityStatus.ReviewRequired,
			processing: availabilityStatus === StepAvailabilityStatus.Calculating,
		}),
		[],
	);

	const mergeStepsMetadata = useCallback(
		(
			curStepsMetadata: EditPortfolioMultiV4Props["stepsMetadata"],
			availabilityStatusMap?: Partial<Record<"MARKET_VIEW" | "STRATEGY_CONSTRAINTS", StepAvailabilityStatus>>,
		) => {
			if (!availabilityStatusMap) {
				return curStepsMetadata;
			}
			const next = { ...curStepsMetadata };

			// next.portfolioStrategy = !availabilityStatusMap.STRATEGY_CONSTRAINTS
			// 	? next.portfolioStrategy
			// 	: mergeStepMetadata(next.portfolioStrategy, availabilityStatusMap.STRATEGY_CONSTRAINTS);
			next.marketView = !availabilityStatusMap.MARKET_VIEW
				? next.marketView // TODO: this doesn't sound right, maybe a copy-paste error?
				: mergeStepMetadata(next.marketView, availabilityStatusMap.MARKET_VIEW);

			return next;
		},
		[mergeStepMetadata],
	);

	const mapper = useMemo(() => makeStepToRequestMapper(area.proposalUuid), [area.proposalUuid]);

	const saveHandlers = useMemo<EditPortfolioMultiV4ContextType["saveHandlers"]>(
		() =>
			({
				// portfolioStrategy: async (values) => {
				// 	const data = await axiosExtract(
				// 		bulkEnhancementApi.setEnhancementConfigurationStrategyConstraints1(mapper.portfolioStrategy(values)),
				// 	);
				// 	setEditedStepsMetadata(mergeStepsMetadata(stepsMetadata, data.stepsAvailability));
				// 	return responseToStepMapper.portfolioStrategy(data, area);
				// },
				marketView: async (values) => {
					if (area.name === "edit-bulk-settings") {
						const data = await axiosExtract(
							investmentBulkBulkStaticV1Api.setStaticConfigurationMarketView1({
								...mapper.marketView(values),
								bulkUUID: area.bulkUid,
							}),
						);
						setEditedStepsMetadata(mergeStepsMetadata(stepsMetadata, data.stepsAvailability));
						return responseToStepMapper.marketView(data, area);
					}

					const data = await axiosExtract(
						bulkEnhancementApi.setEnhancementConfigurationMarketView1(mapper.marketView(values)),
					);
					setEditedStepsMetadata(mergeStepsMetadata(stepsMetadata, data.stepsAvailability));
					return responseToStepMapper.marketView(data, area);
				},
			}) satisfies EditPortfolioMultiV4ContextType["saveHandlers"] as EditPortfolioMultiV4ContextType["saveHandlers"],
		[
			area,
			bulkEnhancementApi,
			investmentBulkBulkStaticV1Api,
			mapper,
			mergeStepsMetadata,
			setEditedStepsMetadata,
			stepsMetadata,
		],
	);

	const canSubmit = useMemo(() => {
		const metadata = Object.values(stepsMetadata);
		// Disable if
		// - there is a non-optional step that hasn't been completed
		// - there is a step containing errors
		// - there is a step asynchronously validating on the server
		// - there is a step marked as dirty
		return !metadata.some(
			(m) => (!m.optional && !m.completed) || m.hasErrors || m.processing || m.dirty || m.persisting,
		);
	}, [stepsMetadata]);

	const submittersRef: EditMulti_SubmittersRef = useRef({});

	const contextValue = useMemo<EditPortfolioMultiV4ContextType>(
		() => ({
			saveHandlers,
			submittersRef,
			currentStep,
			setCurrentStep,
			setStepsMetadata: setEditedStepsMetadata,
			stepsMetadata: editedStepsMetadata ?? defaultStepsMetadata,
			canSubmit,
			setStepsData,
			area,
			stepsData,
		}),
		[
			saveHandlers,
			currentStep,
			setEditedStepsMetadata,
			editedStepsMetadata,
			defaultStepsMetadata,
			canSubmit,
			setCurrentStep,
			area,
			stepsData,
		],
	);

	return (
		<EditPortfolioMultiV4Context.Provider value={contextValue}>
			{renderNodeOrFn(children, contextValue)}
		</EditPortfolioMultiV4Context.Provider>
	);
}

export function EditPortfolioMultiV4InContext(): JSX.Element {
	return (
		<EditPortfolioMultiV4Context.Consumer>
			{(data) => <>{data && <EditPortfolioMultiV4 {...data} />}</>}
		</EditPortfolioMultiV4Context.Consumer>
	);
}

export function EditPortfolioMultiV4({
	area,
	currentStep,
	setCurrentStep,
	setStepsData,
	setStepsMetadata,
	stepsData,
	stepsMetadata,
	saveHandlers,
	submittersRef,
}: EditPortfolioMultiV4Props): JSX.Element {
	return (
		<TypedMultiStepper
			context={{ area, saveHandlers, submittersRef }}
			currentStep={currentStep}
			setCurrentStep={setCurrentStep}
			stepsMetadata={stepsMetadata}
			setStepsMetadata={setStepsMetadata}
			stepsData={stepsData}
			setStepsData={setStepsData}
			mode={switchExpr(area.name, {
				"create-bulk-proposal": () => "new" as const,
				"edit-bulk-settings": () => "edit" as const,
			})}
			orderedStepNames={editPortfolioMultiV4OrderedSteps}
			componentsByStep={{
				marketView: Step06_MarketView,
			}}
		/>
	);
}
