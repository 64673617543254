import type { IndexTicker, RichTicker } from "$root/api/api-gen";
import {
	BenchmarksControllerApiFactory,
	PortfolioStudioPreferencesApiFactory,
	TickerControllerV1ApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { IconWalls } from "$root/components/IconWall";
import { ReactQueriesWrapperBase } from "$root/components/ReactQueryWrapper";
import { useEventBus } from "$root/event-bus";
import { retrieveUserInstrumentsClassifications } from "$root/functional-areas/instruments-editor/builder";
import type { PartialUserInstrument } from "$root/functional-areas/instruments-editor/const";
import type { UserColumnMetadata } from "$root/functional-areas/instruments/hooks";
import {
	hideNameColumnMetadata,
	useGroupedInstrumentsColumn,
	useInstrumentColumnPreference,
	useInstrumentsColumn,
} from "$root/functional-areas/instruments/hooks";
import {
	createTagGroupedColumn,
	useCompositionColumns,
	useGroupedCompositionColumn,
	useGroupedCompositionRows,
} from "$root/pages/PortfolioDetails/PortfolioComposition/columns";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { downloadContentDisposition, objMatchFn, useQueryNoRefetch } from "$root/utils";
import { BenchmarkContext } from "$root/widgets-architecture/contexts/benchmark";
import { InfoTooltip } from "$root/widgets-architecture/layout/WidgetsMapper/InfoTooltip";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import type { StylableProps, TableColumn, TableWithGroupsColumn, TableWithGroupsProps } from "@mdotm/mdotui/components";
import {
	AutoSortHScrollTable,
	AutoSortHScrollTableWithGroups,
	DropdownMenu,
	FormField,
	Icon,
	Row,
	Searchable,
	Select,
	TextInput,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import type { ContextContent } from "@mdotm/mdotui/react-extensions";
import { useUnsafeUpdatedRef, withContext } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor, noop } from "@mdotm/mdotui/utils";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

type MixedBenchmarkInstrument = RichTicker & PartialUserInstrument;

const BenchmarkComposition = (props: ContextContent<typeof BenchmarkContext>) => {
	const { benchmarkId } = props;
	const benchmarkV4Api = useApiGen(BenchmarksControllerApiFactory);
	const tickerApi = useApiGen(TickerControllerV1ApiFactory);
	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);

	const { t } = useTranslation();
	const { changeTableLayout, columnsMetadata, queryInstrumentsColumn } = useInstrumentColumnPreference({
		async applyColumnPreferenceApi(userInstrumentsColumnPreferences) {
			await portfolioStudioPreferencesApi.setUserInstrumentsColumnPreferences({
				userInstrumentsColumnPreferences,
			});
		},
		mapColumnMetadataFn: hideNameColumnMetadata,
	});

	const { data, isFetching, isError, refetch } = useQueryNoRefetch(["getBenchmarkComposition", benchmarkId], {
		queryFn: async () => {
			if (!benchmarkId) {
				throw new Error("unable to find benchmarkId of undefined");
			}
			const benchmarkComposition = await axiosExtract(benchmarkV4Api.getBenchmarkComposition(benchmarkId));
			const benchmarkSummary = await axiosExtract(benchmarkV4Api.getBenchmarkSummary(benchmarkId));
			const { userInstrumentsMap } = await retrieveUserInstrumentsClassifications(benchmarkComposition ?? []);

			const composition = benchmarkComposition.map((instrument) => {
				const { type: _type, ...instrumentParams } = instrument;
				const userInstrument = userInstrumentsMap.get(instrument.ticker!) ?? {};
				return {
					...instrumentParams,
					...userInstrument,
					name: userInstrument.name ?? instrument.instrument ?? "",
					alias: userInstrument.alias ?? instrumentParams.identifier,
				} satisfies MixedBenchmarkInstrument;
			});
			return { composition, summary: benchmarkSummary };
		},
	});

	const [groupByClassificationUuid, setGroupByClassificationUuid] = useState<string | null>(null);

	useEventBus("benchmark-update", {
		filter: objMatchFn({ uuid: benchmarkId }),
		listener: () => {
			refetch().catch(noop);
		},
	});

	const { composition } = data ?? {};

	const handleDownloadErrorReport = async () => {
		try {
			if (!benchmarkId) {
				throw new Error("unable to find benchmarkId of undefined");
			}
			const response = await benchmarkV4Api.exportComposition1(benchmarkId, { responseType: "blob" });
			downloadContentDisposition(response);

			trackMixPanelEvent("Benchmark", {
				Type: "Export",
				Action: "save",
				ID: benchmarkId,
			});
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const matchFnMemo = useCallback((item: IndexTicker, query: string): boolean => {
		if (query.length === 0) {
			return true;
		}
		return JSON.stringify(item).toLowerCase().includes(query.toLowerCase());
	}, []);

	const handleDownloadErrorReportRef = useUnsafeUpdatedRef(handleDownloadErrorReport);

	useWidgetOptions(
		() => ({
			title: "Composition",
			actionHeader: function Download() {
				return (
					<Row alignItems="end" classList="space-x-4">
						{" "}
						<FormField label="Group by">
							{(formFieldProps) => (
								<Select
									{...formFieldProps}
									size="x-small"
									value={groupByClassificationUuid}
									onChange={setGroupByClassificationUuid}
									options={[
										{ label: "Ungrouped", value: null },
										...columnsMetadata.flatMap((x) =>
											x.fieldType === "TAG" ? [{ label: x.name, value: x.classificationUuid! }] : [],
										),
									]}
								/>
							)}
						</FormField>
						<DropdownMenu
							trigger={({ innerRef, open, ...forward }) => (
								<button ref={innerRef} aria-expanded={open} type="button" {...forward}>
									<Icon icon="Dowload" color={themeCSSVars.MessageSeverity_success} size={20} />
								</button>
							)}
							actions={[
								{
									icon: "xls",
									disabled: data?.summary.status === "CALCULATING" || data === undefined,
									onClickAsync: async () => {
										await handleDownloadErrorReportRef.current();
									},
									label: "Benchmark composition",
								},
							]}
						/>
						<InfoTooltip>{t("COMPOSITION.TOOLTIP")}</InfoTooltip>
					</Row>
				);
			},
		}),
		[columnsMetadata, data, groupByClassificationUuid, handleDownloadErrorReportRef, t],
	);

	if (isError) {
		return <IconWalls.ErrorData />;
	}
	if (isFetching) {
		return <IconWalls.LoadingData />;
	}

	if (composition === undefined) {
		return <IconWalls.HistoricalDataNotAvailable />;
	}

	return (
		<Searchable matchFn={matchFnMemo} collection={composition ?? []}>
			{({ filtered, query, setQuery }) => (
				<div className="min-h-0 grow flex flex-col gap-4">
					<TextInput
						value={query}
						placeholder="Search for instruments name, identifier o asset class"
						onChangeText={setQuery}
						leftContent={<Icon icon="Search" />}
					/>

					<ReactQueriesWrapperBase queries={queryInstrumentsColumn}>
						{() => {
							return groupByClassificationUuid ? (
								<BenchmarkCompositionInnerGrouped
									rows={filtered}
									columnsMetadata={columnsMetadata}
									groupByClassificationUuid={groupByClassificationUuid}
									changeTableLayout={changeTableLayout}
								/>
							) : (
								<BenchmarkCompositionInnerFlat
									rows={filtered}
									columnsMetadata={columnsMetadata}
									changeTableLayout={changeTableLayout}
								/>
							);
						}}
					</ReactQueriesWrapperBase>
				</div>
			)}
		</Searchable>
	);
};

function BenchmarkCompositionInnerGrouped({
	rows,
	groupByClassificationUuid,
	columnsMetadata,
	changeTableLayout,
	...stylable
}: {
	rows: MixedBenchmarkInstrument[];
	columnsMetadata: UserColumnMetadata[];
	groupByClassificationUuid: string;
	changeTableLayout(columnPreferences: Array<UserColumnMetadata>): MaybePromise<void>;
} & StylableProps) {
	const groupedColumns = useGroupedCompositionColumn();
	const groupedRows = useGroupedCompositionRows(rows, groupByClassificationUuid);
	const {
		customizableColumns: instrumentColumns,
		tools,
		name,
	} = useGroupedInstrumentsColumn({
		allColumns: columnsMetadata,
		mode: "VIEW",
		changeTableLayout,
	});

	const selectedClassification = useMemo(
		() => columnsMetadata.find((x) => x.classificationUuid === groupByClassificationUuid)!,
		[columnsMetadata, groupByClassificationUuid],
	);

	const groupedCompositionColumns = useCallback(
		({
			expandColumn,
		}: Parameters<TableWithGroupsProps<(typeof groupedRows)[number]>["columns"]>[0]): TableWithGroupsColumn<
			(typeof groupedRows)[number]
		>[] => {
			const columnsWithoutSelectedClassification = instrumentColumns.flatMap((column) => {
				if (column.name === selectedClassification.classificationUuid) {
					return [];
				}

				return [column];
			});

			const { groupContent } = createTagGroupedColumn(selectedClassification);

			const nameColumn = {
				...name,
				minWidth: 460,
				maxWidth: 560,
				groupContent,
				sortFn: builtInSortFnFor("groupKey"),
				orderable: true,
			} satisfies TableWithGroupsColumn<(typeof groupedRows)[number]>;
			return [expandColumn, nameColumn, ...columnsWithoutSelectedClassification, groupedColumns.WEIGHT(), tools];
		},
		[instrumentColumns, selectedClassification, name, groupedColumns, tools],
	);

	return (
		<AutoSortHScrollTableWithGroups
			data-qualifier="BenchmarkComposition/GroupedTable"
			{...stylable}
			groupedRows={groupedRows}
			groupRowKey={(x) => x.groupKey ?? ""}
			pinnedColumns={[
				{ name: name.name, side: "left" },
				{ name: groupedColumns.WEIGHT().name, side: "right" },
				{ name: tools.name, side: "right" },
			]}
			columns={groupedCompositionColumns}
		/>
	);
}
function BenchmarkCompositionInnerFlat({
	rows,
	columnsMetadata,
	changeTableLayout,
	...stylable
}: {
	rows: MixedBenchmarkInstrument[];
	columnsMetadata: UserColumnMetadata[];
	changeTableLayout(columnPreferences: Array<UserColumnMetadata>): MaybePromise<void>;
} & StylableProps) {
	const compositionColumns = useCompositionColumns();
	const {
		customizableColumns: instrumentColumns,
		tools,
		name,
	} = useInstrumentsColumn({
		allColumns: columnsMetadata,
		mode: "VIEW",
		changeTableLayout,
	});

	const columns = useMemo<TableColumn<MixedBenchmarkInstrument>[]>(() => {
		const nameColumn = {
			...name,
			minWidth: 460,
			maxWidth: 560,
		} satisfies TableColumn<MixedBenchmarkInstrument>;

		return [nameColumn, ...instrumentColumns, compositionColumns.WEIGHT(), tools];
	}, [instrumentColumns, compositionColumns, name, tools]);

	return (
		<AutoSortHScrollTable
			data-qualifier="BenchmarkComposition/FlatTable"
			{...stylable}
			columns={columns}
			rows={rows}
			pinnedColumns={[
				{ name: "defaultColumn-name", side: "left" },
				{ name: compositionColumns.WEIGHT().name, side: "right" },
				{ name: tools.name, side: "right" },
			]}
		/>
	);
}

export default withContext(BenchmarkContext)(BenchmarkComposition);
