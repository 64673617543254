// TODO: if Design agrees on maintaining a consistent UX/UI for all import dialogs, consider
// making a single component that takes the APIs as props and is otherwise the same for every import procedure

import type {
	IntegrationAction,
	InvestmentConversionIdentifierItems,
	InvestmentIdentifierConversion,
	InvestmentIdentifierImport,
	InvestmentImportConverterType,
	InvestmentImportResponse,
	TemplateImportType,
} from "$root/api/api-gen";
import { IntegrationsControllerV3ApiFactory } from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import { DropzoneArea } from "$root/components/DropzoneArea";
import type { Converted } from "$root/components/Upload/BaseDropzoneBlock";
import BaseDropzoneBlock from "$root/components/Upload/BaseDropzoneBlock";
import UploadButton from "$root/components/UploadButton";
import { platformToast } from "$root/notification-system/toast";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { capitalizeString, customObjectValuesFn, downloadContentDisposition, parallelize, parallelizeWithAllSettled } from "$root/utils";
import type { DialogProps, FormProps, Option, TableColumn } from "@mdotm/mdotui/components";
import {
	ActionText,
	AutoSortTable,
	Banner,
	Button,
	Checkbox,
	Collapsible,
	DefaultCollapsibleHeader,
	Dialog,
	DialogFooter,
	FormField,
	Icon,
	Row,
	Select,
	SubmitButton,
	Text,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import {
	adaptAnimatedNodeProvider,
	ForEach,
	generateUniqueDOMId,
	spawn,
	useUnsafeUpdatedRef,
} from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { unpromisify } from "@mdotm/mdotui/utils";
import { Set } from "immutable";
import type { ReactNode } from "react";
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import XLSX from "xlsx";
import EmptyImportBox from "../instruments-editor/spawn/box/EmptyImportBox";
import ErrorImportBox from "../instruments-editor/spawn/box/ErrorImportBox";
import SuccessImportBox from "../instruments-editor/spawn/box/SuccessImportBox";

const ACCEPTED_FILE = "*";
const concurrency = 32;
type DropzoneState = "empty" | "error" | "success" | "loading";
type Id<T> = T & { id: string };

const commonDropzoneClassList = {
	empty: `bg-[color:${themeCSSVars.palette_N50}] border-dashed border-[color:${themeCSSVars.palette_N500}]`,
	success: `bg-[color:${themeCSSVars.palette_N0}] border-[color:${themeCSSVars.palette_P600}]`,
	error: `bg-[color:${themeCSSVars.palette_N0}] border-[color:${themeCSSVars.palette_D500}]`,
	loading: `bg-[color:${themeCSSVars.palette_N50}] border-dashed border-[color:${themeCSSVars.palette_N500}]`,
} satisfies Record<DropzoneState, string>;

const drawIcon = {
	empty: <EmptyImportBox />,
	error: <ErrorImportBox />,
	success: <SuccessImportBox />,
	loading: <EmptyImportBox />,
} satisfies Record<DropzoneState, ReactNode>;

function readFileText(file: File): Promise<string> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = (e) => {
			if (e.target?.result && typeof e.target?.result === "string") {
				resolve(e.target.result);
			}
		};

		reader.onerror = (e) => {
			reject(e);
		};

		reader.readAsText(file);
	});
}

const separetor = "6b22a815-c996-45e8-b8ed-63349fad005a";
function removeBrakeSpaces(text: string) {
	return text.replace(/\n/g, "");
}

const coupleFormat = {
	composition: /^(?:\d{2})(?:(0[1-9]|1[0-2]))(?:(0[1-9]|[12]\d|3[01]))_Portafoglio_MDOTM$/gim,
	constraint: /^(?:\d{2})(?:(0[1-9]|1[0-2]))(?:(0[1-9]|[12]\d|3[01]))_Constraints_MDOTM$/gim,
};
// check if the date of the file are equals
function CoupleConversionBlock({
	disabled,
	onChange,
	onDelete,
	status = "empty",
}: {
	onChange(files: File[] | null): MaybePromise<void>;
	onDelete?(): void;
	disabled: boolean;
	status?: DropzoneState;
}) {
	const [selectedFiles, _setSelectedFiles] = useState<{
		composition: {
			file: null | File;
			error?: "INVALID_NAME" | "REQUIRED";
		};
		constraints: {
			file: null | File;
			error?: "INVALID_NAME";
		};
	}>({ composition: { file: null }, constraints: { file: null } });
	const latestSelectedFilesRef = useRef(selectedFiles);

	function setSelectedFiles(type: "composition" | "constraints", file: File | null) {
		_setSelectedFiles((prev) => {
			const clonedLatestSelectedFile = { ...prev };
			if (file) {
				const [name] = file.name.split("."); // ex name.constraint.csv
				if (type === "constraints" && !name.match(coupleFormat.constraint)) {
					clonedLatestSelectedFile[type] = { file: null, error: "INVALID_NAME" };
					return clonedLatestSelectedFile;
				}

				if (type === "composition" && !name.match(coupleFormat.composition)) {
					clonedLatestSelectedFile[type] = { file: null, error: "INVALID_NAME" };
					return clonedLatestSelectedFile;
				}
			}

			clonedLatestSelectedFile[type] = { file };
			latestSelectedFilesRef.current = clonedLatestSelectedFile;
			return clonedLatestSelectedFile;
		});

		if (
			latestSelectedFilesRef.current.composition.file !== null &&
			latestSelectedFilesRef.current.constraints.file !== null
		) {
			const files = customObjectValuesFn(latestSelectedFilesRef.current).flatMap((x) => (x.file ? [x.file] : []));
			unpromisify(async () => {
				const textFiles = await parallelize(files.map((x) => () => readFileText(x)));
				const csvResults = textFiles.map(removeBrakeSpaces).join(`\n${separetor}\n`);
				const blob = new Blob([csvResults], { type: "text/csv;charset=utf-8," });
				await onChange([
					new File(
						[blob],
						`${latestSelectedFilesRef.current.composition.file!.name}${
							latestSelectedFilesRef.current.constraints.file!.name
								? "," + latestSelectedFilesRef.current.constraints.file!.name
								: ""
						}`,
					),
				]);
			})();
		} else {
			unpromisify(() => onChange(null))();
		}
	}

	const { t } = useTranslation();

	return (
		<>
			<div className="flex gap-2">
				<DropzoneArea
					disabled={disabled}
					onChange={(file) => setSelectedFiles("composition", file)}
					accept={ACCEPTED_FILE}
					classList="flex-1"
					childrenWrapperAppearance={{
						classList: {
							[`relative rounded flex p-2 gap-4 mb-5 justify-between items-center border-2`]: true,
							[status === "success" && !selectedFiles.composition.file?.name
								? commonDropzoneClassList["empty"]
								: commonDropzoneClassList[status]]: true,
						},
					}}
				>
					<div className="flex gap-2 items-center flex-1">
						{drawIcon[status]}
						<p className="font-semibold max-w-[160px] break-words">
							{selectedFiles.composition.file?.name
								? selectedFiles.composition.file.name
								: "Select the composition file or drag and drop here"}
						</p>
					</div>

					{selectedFiles.composition.file?.name ? (
						<Button
							unstyled
							size="small"
							onClick={() => {
								onDelete?.();
								setSelectedFiles("composition", null);
							}}
						>
							<Icon icon="Delete" size={20} color={themeCSSVars.palette_N500} />
						</Button>
					) : (
						<UploadButton
							loading={status === "loading"}
							size="small"
							label={t("BUTTON.SELECT")}
							onChange={(file) => setSelectedFiles("composition", file)}
							accept={ACCEPTED_FILE}
						/>
					)}
				</DropzoneArea>
				<DropzoneArea
					disabled={disabled || !selectedFiles.composition.file?.name}
					onChange={(file) => setSelectedFiles("constraints", file)}
					accept={ACCEPTED_FILE}
					classList="flex-1"
					childrenWrapperAppearance={{
						classList: {
							[`relative rounded flex p-2 gap-4 mb-5 justify-between items-center border-2`]: true,
							[status === "success" && !selectedFiles.constraints.file?.name
								? commonDropzoneClassList["empty"]
								: commonDropzoneClassList[status]]: true,
						},
					}}
				>
					<div className="flex gap-2 items-center flex-1">
						{drawIcon[status]}
						<p className="font-semibold max-w-[160px] break-words">
							{selectedFiles.constraints.file?.name
								? selectedFiles.constraints.file.name
								: "Select the constraint file or drag and drop here"}
						</p>
					</div>

					{selectedFiles.constraints.file?.name ? (
						<Button
							unstyled
							size="small"
							onClick={() => {
								onDelete?.();
								setSelectedFiles("constraints", null);
							}}
						>
							<Icon icon="Delete" size={20} color={themeCSSVars.palette_N500} />
						</Button>
					) : (
						<UploadButton
							loading={status === "loading"}
							size="small"
							label={t("BUTTON.SELECT")}
							onChange={(file) => setSelectedFiles("constraints", file)}
							accept={ACCEPTED_FILE}
							disabled={!selectedFiles.composition.file?.name}
						/>
					)}
				</DropzoneArea>
			</div>
			{selectedFiles.composition.file === null && (
				<div className="mb-4">
					<Banner severity="info" title="Required file">
						Please upload portfolio composition before proceeding.
					</Banner>
				</div>
			)}
			{selectedFiles.composition.error === "INVALID_NAME" && (
				<div className="my-4">
					<Banner severity="error" title="Invalid composition file name">
						Please create a file with the following naming convention: &apos;aammgg_Portafoglio_MDOTM.csv&apos;.
					</Banner>
				</div>
			)}
			{selectedFiles.constraints.error === "INVALID_NAME" && (
				<div className="my-4">
					<Banner severity="error" title="Invalid constraints file name">
						Please create a file with the following naming convention: &apos;aammgg_Constraints_MDOTM.csv&apos;.
					</Banner>
				</div>
			)}
		</>
	);
}

function ConversionBlock({
	converter,
	status,
	converted,
	onSubmit,
	onChangeStatus,
}: {
	status: DropzoneState;
	onChangeStatus(status: DropzoneState): void;
	converter: InvestmentImportConverterType;
	onSubmit(conversions: Array<Converted<InvestmentConversionIdentifierItems>>): void;
	converted: Array<Converted<InvestmentConversionIdentifierItems>>;
}) {
	const { t } = useTranslation();
	const integrationsApiV3 = useApiGen(IntegrationsControllerV3ApiFactory);
	const reset = useCallback(() => {
		onChangeStatus("empty");
		onSubmit([]);
	}, [onChangeStatus, onSubmit]);

	const onChange = useCallback(
		async (files: File[], type: InvestmentImportConverterType) => {
			try {
				reset();
				onChangeStatus("loading");
				const filesToConverter = files.map(
					(file) => () =>
						axiosExtract(integrationsApiV3.convertCheckFrom(type, file)).then(async (response) => ({
							form: response,
							items: response,
						})),
				);

				const responseStack = await parallelizeWithAllSettled(filesToConverter, { concurrency });
				const assocFileWithConversion = files.map((file, i) => ({
					filename: file.name,
					conversion: responseStack[i].status === "fulfilled" ? responseStack[i].value.items : undefined,
				}));

				const succededResponses = assocFileWithConversion.filter(
					({ conversion }) => conversion !== undefined,
				) as Converted<InvestmentConversionIdentifierItems>[];
				const failedResponses = assocFileWithConversion.filter(
					({ conversion }) => conversion === undefined,
				) as Converted<undefined>[];

				if (type === "AMUNDI_CONVERTER" && responseStack[0]?.status === "fulfilled") {
					const { form, items } = responseStack[0].value;
					if (items.errors && items.errors?.length > 0) {
						const compositionErrorsMap = new Map(form.errors?.map((x) => [x.errorMessage!, x.investmentName!]) ?? []);
						const constraintErrors = items.errors?.filter((x) => {
							const compositionError = compositionErrorsMap.get(x.errorMessage!);
							return compositionError !== x.investmentName;
						});
						const file = files[0]!;
						const [compositionName, constraintName] = file.name.split(",");
						const conversions = [
							{
								filename: compositionName,
								conversion: {
									errors: form.errors,
									conversions: items.conversions,
								} satisfies InvestmentConversionIdentifierItems,
							},
							{
								filename: constraintName,
								conversion: {
									errors: constraintErrors,
									conversions: items.conversions,
								} satisfies InvestmentConversionIdentifierItems,
							},
						];

						return onSubmit(conversions);
					}
				}

				if (failedResponses.length > 0) {
					failedResponses.map((x) =>
						platformToast({
							children: `Failed to convert ${x.filename ?? "-"}`,
							severity: "error",
							icon: "Portfolio",
						}),
					);
				}

				if (succededResponses.length > 0) {
					onSubmit(succededResponses);
				}

				if (failedResponses.length === succededResponses.length) {
					onChangeStatus("error");
				} else {
					onChangeStatus("success");
				}
			} catch (error) {
				onChangeStatus("error");

				throw error;
			}
		},
		[integrationsApiV3, onChangeStatus, onSubmit, reset],
	);

	const failedConversion = useMemo(() => converted.filter(({ conversion }) => conversion === undefined), [converted]);

	return (
		<>
			{match(converter)
				.with("AMUNDI_CONVERTER", () => (
					<CoupleConversionBlock
						disabled={status === "loading"}
						onChange={(x) => {
							if (x === null) {
								return reset();
							}

							unpromisify(() => onChange(x, converter))();
						}}
						status={status}
						onDelete={reset}
					/>
				))
				.otherwise(() => (
					<BaseDropzoneBlock
						converted={converted}
						disabled={status === "loading"}
						onDelete={reset}
						status={status}
						onChange={(x) => {
							if (x === null) {
								return reset();
							}

							unpromisify(() => onChange(x, converter))();
						}}
					/>
				))}
			{status === "loading" && (
				<div className="my-4">
					<Banner severity="info">{t("PORTFOLIOS.UPLOAD_DURATION_LIMIT")}</Banner>
				</div>
			)}
			{failedConversion.length > 0 && (
				<div className="mb-4">
					<Banner severity="warning" title="Failed to convert these files">
						{failedConversion.map(({ filename }) => filename).join(", ")}
					</Banner>
				</div>
			)}
		</>
	);
}

function OverrideTable({
	selected,
	onChange,
	rows,
}: {
	rows: Array<InvestmentIdentifierConversion & { id: string }>;
	onChange(value: Set<string>): void;
	// selected: MutableRefObject<Set<string>>;
	selected: Set<string>;
}) {
	const onChangeRef = useUnsafeUpdatedRef(onChange);
	const rowsId = useMemo(() => rows.map(({ id }) => id), [rows]);
	const columns = useMemo<TableColumn<Id<InvestmentIdentifierConversion>>[]>(
		() => [
			{
				name: "name",
				header: "",
				content: (row) => row.investmentImport?.name ?? "-",
				minWidth: 200,
			},
			{
				name: "overwrite",
				header: (headerProps) => (
					<Row {...headerProps} gap={8} alignItems="center">
						<Checkbox
							checked={
								selected.size === 0
									? true
									: selected.size === rowsId.length
									  ? false
									  : selected.size > 0
									    ? "indeterminate"
									    : selected.size === 0
							}
							onChange={() => onChangeRef.current(Set())}
						>
							<Text type="Body/S/BOLD-UPPERCASE" color={themeCSSVars.palette_N500} classList="truncate">
								Overwrite
							</Text>
						</Checkbox>
					</Row>
				),
				content: ({ id }, cellProps) => (
					<Row {...cellProps} alignItems="center">
						<Checkbox checked={!selected.has(id)} onChange={() => onChangeRef.current(selected.remove(id))} />
					</Row>
				),
				width: 120,
			},
			{
				name: "ignore",
				header: (headerProps) => (
					<Row {...headerProps} gap={8} alignItems="center">
						<Checkbox
							checked={
								selected.size > 0 && selected.size < rowsId.length ? "indeterminate" : selected.size === rowsId.length
							}
							onChange={() => onChangeRef.current(Set(rowsId))}
						>
							<Text type="Body/S/BOLD-UPPERCASE" color={themeCSSVars.palette_N500} classList="truncate">
								Ignore
							</Text>
						</Checkbox>
					</Row>
				),
				content: ({ id }, cellProps) => (
					<Row {...cellProps} alignItems="center">
						<Checkbox
							checked={selected.has(id)}
							onChange={() => {
								onChangeRef.current(selected.add(id));
								console.log("ignore");
							}}
						/>
					</Row>
				),
				width: 120,
			},
		],
		[onChangeRef, rowsId, selected],
	);

	return (
		<AutoSortTable
			rows={rows}
			columns={columns}
			classList="!bg-transparent max-h-[200px]"
			rowClassList="!bg-transparent !border-[#A0A7B6]"
			style={{ backgroundColor: "transparent" }}
		/>
	);
}

const converterToTemplateType = {
	AMUNDI_CONVERTER: "AMUNDI_PORTFOLIO_IMPORT",
	ANALYSIS_CONVERTER: "ANALYSIS_PORTFOLIO_IMPORT",
	BLOOMBERG_CONVERTER: "BLOOMBERG_PORTFOLIO_IMPORT",
	GUARDIAN_CONVERTER: "GUARDIAN_PORTFOLIO_IMPORT",
	SPHERE_TEMPLATE_CONVERTER: "SPHERE_PORTFOLIO_IMPORT",
} satisfies { [key in InvestmentImportConverterType]: TemplateImportType };

type InvestmentImportDialogPropsV2 = {
	converters: Array<InvestmentImportConverterType>;
	onImportFinished(params: {
		imported: Array<InvestmentImportResponse>;
		failed: Array<{ investment: InvestmentIdentifierImport; err: unknown }>;
	}): void;
	qualifier?: string;
	show: boolean;
	onClose(): void;
	onAnimationStateChange?: DialogProps["onAnimationStateChange"];
};

function InvestmentImportDialogV2({
	converters,
	onImportFinished,
	show,
	onClose,
	onAnimationStateChange,
}: InvestmentImportDialogPropsV2): JSX.Element {
	const { t } = useTranslation();
	const options = useMemo(
		() =>
			converters.map(
				(converter): Option<InvestmentImportConverterType> => ({
					label: capitalizeString(t(`IMPORT.${converter}`).toLowerCase()),
					value: converter,
				}),
			),
		[converters, t],
	);

	const [selectedConverter, setSelectedConverter] = useState(converters[0]);
	const [converted, setConverted] = useState<Array<Converted<InvestmentConversionIdentifierItems>>>([]);
	const [dropzoneStatus, setDropzoneStatus] = useState<DropzoneState>("empty");

	const [investmentToIgnore, _setInvestmentToIgnore] = useState(Set<string>());
	const latestInvestmentToIgnore = useRef(investmentToIgnore);
	function setInvestmentToIgnore(investments: Set<string>) {
		_setInvestmentToIgnore(investments);
		latestInvestmentToIgnore.current = investments;
	}

	const flatConversion = useMemo(
		() => converted.flatMap((x) => x.conversion.conversions ?? []).map((x) => ({ ...x, id: generateUniqueDOMId() })),
		[converted],
	);
	const existingInvestments = useMemo(() => {
		return flatConversion.filter(
			({ integrationAction }) => integrationAction === "ENHANCEMENT" || integrationAction === "EDIT_COMPOSITION",
		);
	}, [flatConversion]);

	const { overridables, unconsistent } = useMemo(
		() =>
			existingInvestments.reduce<{
				overridables: Array<Id<InvestmentIdentifierConversion>>;
				unconsistent: Array<Id<InvestmentIdentifierConversion>>;
			}>(
				(acc, el) => {
					if (el.integrationStatus === "READY_FOR_ACTIONS") {
						acc.overridables.push(el);
						return acc;
					}
					acc.unconsistent.push(el);
					return acc;
				},
				{
					overridables: [],
					unconsistent: [],
				},
			),
		[existingInvestments],
	);

	const conversionWithErrors = useMemo(
		() => converted.filter((x) => x.conversion.errors && x.conversion.errors.length > 0),
		[converted],
	);

	const cleanConvertion = useMemo(
		() =>
			conversionWithErrors.length === 0 &&
			overridables.length === 0 &&
			unconsistent.length === 0 &&
			converted.length > 0,
		[conversionWithErrors.length, converted.length, overridables.length, unconsistent.length],
	);

	const disabled = useMemo(() => {
		const common = conversionWithErrors.length > 0 || converted.length === 0;
		return common && (unconsistent.length === 0 || unconsistent.length === flatConversion.length);
	}, [conversionWithErrors.length, converted.length, flatConversion.length, unconsistent.length]);

	const integrationsApiV3 = useApiGen(IntegrationsControllerV3ApiFactory);

	const onSubmitAsync = useCallback<FormProps<void>["onSubmitAsync"]>(
		async (_, { progressCb }) => {
			const fileToSubmit = flatConversion.flatMap(
				({
					id,
					...investments
				}): Array<{
					integrationAction: IntegrationAction;
					investment: InvestmentIdentifierImport;
				}> => {
					const investment = investments.investmentImport;
					const integrationAction = investments.integrationAction;
					if (investmentToIgnore.has(id) || !investment || !integrationAction) {
						return [];
					}

					return [{ integrationAction, investment }];
				},
			);

			if (fileToSubmit.length === 0) {
				platformToast({
					children: "No portfolio imported",
					severity: "info",
					icon: "Dowload",
				});
				onImportFinished({ failed: [], imported: [] });
				return;
			}

			let progress = 0;
			const failed: { investment: InvestmentIdentifierImport; err: unknown }[] = [];
			const draft: { investment: InvestmentIdentifierImport; msg: unknown }[] = [];

			const imported: InvestmentImportResponse[] = [];
			const callStack = fileToSubmit.map(
				({ integrationAction, investment }) =>
					() =>
						integrationsApiV3
							.importInvestmentAsync(integrationAction, investment)
							.then((resp) => {
								if (resp.data.status === "ERROR") {
									failed.push({ investment, err: resp.data.message });
									return;
								}

								if (resp.data.status === "DRAFT") {
									draft.push({ investment, msg: "Portfolio is being import in draft status" });
								}

								imported.push(resp.data);
							})
							.catch((err) => failed.push({ investment, err }))
							.finally(() => {
								progress++;
								progressCb(progress / callStack.length);
							}),
			);
			await parallelize(callStack, { concurrency });

			if (failed.length === 0) {
				platformToast({
					children: "Sphere has taken over your request",
					severity: "info",
					icon: "Dowload",
				});
			} else if (failed.length === callStack.length) {
				platformToast({
					children: "No portfolio imported",
					severity: "error",
					icon: "Dowload",
				});
			} else {
				if (draft.length > 0) {
					platformToast({
						children: `Imported ${draft.length} in draft status`,
						severity: "warning",
						icon: "Dowload",
					});
				}

				platformToast({
					children: `Imported ${callStack.length - failed.length} out of ${callStack.length} portfolios`,
					severity: "warning",
					icon: "Dowload",
				});
			}

			onImportFinished({ imported, failed });
		},
		[flatConversion, integrationsApiV3, investmentToIgnore, onImportFinished],
	);

	const handleDownloadErrorReport = useCallback((report: Array<Converted<InvestmentConversionIdentifierItems>>) => {
		const xlsSheet = report.flatMap(
			({ conversion, filename }) =>
				conversion.errors?.map(({ errorMessage, investmentName }) => ({
					File: filename,
					Portfolio: investmentName,
					Message: errorMessage,
				})) ?? [],
		);

		const reportWS = XLSX.utils.json_to_sheet(xlsSheet);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, reportWS, "Report Errors");
		XLSX.writeFile(wb, "Errors file.xlsx");
	}, []);

	const handleDownloadTemplate = useCallback(
		(convertTo: InvestmentImportConverterType) =>
			integrationsApiV3
				.getUploadTemplate(converterToTemplateType[convertTo], {
					responseType: "blob",
				})
				.then(downloadContentDisposition)
				.catch((e) => {
					platformToast({ children: "Oops, Something went wrong, pleas re-try", severity: "error", icon: "Portfolio" });
					reportPlatformError(e, "ERROR", "portfolio", { message: "unable to download template" });
					throw e;
				}),
		[integrationsApiV3],
	);

	return (
		<Dialog
			size="large"
			show={show}
			onClose={onClose}
			onSubmitAsync={onSubmitAsync}
			onAnimationStateChange={onAnimationStateChange}
			header="Import file"
			classList="max-h-[830px]"
			footer={
				<DialogFooter
					primaryAction={
						<SubmitButton palette="primary" disabled={disabled}>
							{t("BUTTON.SAVE")}
						</SubmitButton>
					}
					neutralAction={
						<Button palette="tertiary" onClick={onClose}>
							{t("BUTTON.CANCEL")}
						</Button>
					}
				/>
			}
		>
			<div className="mb-4">
				<FormField label="File format">
					{(forward) => (
						<Select
							{...forward}
							options={options}
							value={selectedConverter}
							onChange={(converter) => {
								setConverted([]);
								setDropzoneStatus("empty");
								if (converter) {
									setSelectedConverter(converter);
								}
							}}
							classList="w-[300px]"
						/>
					)}
				</FormField>
			</div>
			<ol className="list-decimal ml-4 mb-4">
				<li>
					Download the <ActionText onClickAsync={() => handleDownloadTemplate(selectedConverter)}>template</ActionText>
				</li>
				<li>Insert your portfolio composition</li>
				<li>Upload the edited template. Only files formatted like the template will be accepted.</li>
			</ol>
			<ConversionBlock
				converted={converted}
				onSubmit={setConverted}
				status={dropzoneStatus}
				onChangeStatus={setDropzoneStatus}
				converter={selectedConverter}
			/>

			{cleanConvertion && dropzoneStatus === "success" && (
				<div className="mb-4">
					<Banner severity="success" title="File uploaded correctly">
						Your portfolios has been converted by Sphere. Click &quot;
						<strong>Save</strong>&quot; to start analyzing and processing your instruments.
					</Banner>
				</div>
			)}

			{overridables.length > 0 && (
				<div className="mb-4 space-y-4">
					<Banner severity="warning" title="Update existing portfolios">
						<p className="mb-2">Some portfolios already exist. Would you like to overwrite them?</p>
					</Banner>
					<OverrideTable rows={overridables} selected={investmentToIgnore} onChange={setInvestmentToIgnore} />
				</div>
			)}

			{unconsistent && unconsistent.length > 0 && (
				<div className="mb-4">
					<Banner severity="error" title="Some portfolios cannot be updated in this state.">
						Import{" "}
						<strong>
							{unconsistent
								.map((portfolios) => portfolios.investmentImport?.name)
								.join(", ")
								.toString()}{" "}
						</strong>
						had a ongoing process. You must edit your portfolios before proceeding with the update.
					</Banner>
				</div>
			)}
			{conversionWithErrors.length > 0 && (
				<Banner severity="error" title="Please review the template format and upload the file again" classList="mb-4">
					<p>The uploaded files have some formatting errors</p>
					{"\n"}
					<ActionText as="button" onClick={() => handleDownloadErrorReport(conversionWithErrors)}>
						Download errors file
					</ActionText>
				</Banner>
			)}
			<ForEach collection={conversionWithErrors}>
				{({ item }) => (
					<Collapsible
						header={(params) => (
							<DefaultCollapsibleHeader
								{...params}
								classList={{
									[`!bg-transparent min-h-[40px] flex items-center border-b border-[${themeCSSVars.palette_N200}]`]: true,
								}}
							>
								{item.filename}
							</DefaultCollapsibleHeader>
						)}
						classList="mb-2"
					>
						<ForEach collection={item.conversion.errors ?? []}>
							{({ item: e }) => (
								<div className="h-[40px] border-b border-[#eeeef1] transition-[background] data-[forceHover]:bg-[#EFF0F3] hover:bg-[#EFF0F3] flex space-x-2 items-center px-2">
									<p className="w-14 truncate shrink-0">{e.investmentName}</p>
									<p className="p-2">{e.errorMessage}</p>
								</div>
							)}
						</ForEach>
					</Collapsible>
				)}
			</ForEach>
		</Dialog>
	);
}

type SpawnInvestmentImportDialogParamsV2 = Omit<InvestmentImportDialogPropsV2, "onClose" | "show">;
export function spawnInvestmentImportDialogV2(params: SpawnInvestmentImportDialogParamsV2): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ resolve, show, onHidden }) => (
			<InvestmentImportDialogV2
				{...params}
				show={show}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
				onClose={() => resolve()}
				onImportFinished={(...forward) => {
					resolve();
					params.onImportFinished(...forward);
				}}
			/>
		)),
	);
}
export default InvestmentImportDialogV2;
