import { useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import type EventEmitter from "eventemitter3";
import { useLayoutEffect } from "react";

export type EventEmitterEvents<T extends EventEmitter<any>> = T extends EventEmitter<infer EventMap>
	? EventMap extends string
		? {
				[K in EventMap]: [void];
		  }
		: EventMap
	: never;

export function useEventEmitterListener<Target extends EventEmitter<any>, K extends keyof EventEmitterEvents<Target>>(
	target: Target | null | undefined,
	name: K,
	callback: (e: EventEmitterEvents<Target>[K]) => void,
	opts?: { disabled?: boolean } | undefined,
): void {
	const callbackRef = useUnsafeUpdatedRef(callback);
	useLayoutEffect(() => {
		const currentTarget = target;
		const currentOptions = opts ?? {};
		if (!currentTarget) {
			return;
		}
		if (currentOptions.disabled) {
			return;
		}
		const wrappedCb: typeof callback = (e) => {
			callbackRef.current(e);
		};
		currentTarget.addListener(name, wrappedCb as (e: Event) => void);
		return () => {
			currentTarget.removeListener(name, wrappedCb as (e: Event) => void);
		};
	}, [callbackRef, name, opts, target]);
}
