import type { InvestmentExportConverterType, InvestmentsReportTemplate } from "$root/api/api-gen";
import {
	IntegrationsControllerV3ApiFactory,
	InvestmentBulkBulkStaticConfigurationControllerV1ApiFactory,
	InvestmentBulkEnhancementConfigurationControllerV4ApiFactory,
	InvestmentControllerV4ApiFactory,
	InvestmentEnhancementControllerV4ApiFactory,
	PdfControllerApiFactory,
	type InvestmentListEntry,
	type InvestmentSummary,
} from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { runWithErrorReporting } from "$root/api/error-reporting/report";
import { getApiGen } from "$root/api/factory";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { aclByArea } from "$root/functional-areas/acl/checkers/all";
import { START_DATE_CREATE_PROPOSAL_COMMENTARY } from "$root/functional-areas/const";
import { generateReportBuilderTemplateName } from "$root/functional-areas/pdf";
import type {
	TemplateChooserUserPreferenceMap,
	TemplateChooserUserPreferenceValue,
} from "$root/functional-areas/portfolio-studio/dialogs/TemplateChooserDialog";
import { TemplateChooserTable } from "$root/functional-areas/portfolio-studio/dialogs/TemplateChooserDialog";
import { isDefaultReportTemplate } from "$root/functional-areas/reports/default-templates";
import { useUserStore } from "$root/functional-areas/user";
import { platformToast, ToastContent } from "$root/notification-system/toast";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { queryClient, queryClientKeys, typedQueryClient } from "$root/third-party-integrations/react-query";
import { WithRwZustandStore } from "$root/third-party-integrations/zustand";
import {
	diffrenceISODate,
	downloadBlob,
	downloadContentDisposition,
	parallelize,
	parallelizeWithAllSettled,
	ToastableError,
	typeAssertion,
	UserCancelledError,
} from "$root/utils";
import { Column, Radio, RadioGroup, Row, Text } from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import { noop, switchExpr } from "@mdotm/mdotui/utils";
import { Map } from "immutable";
import { useCallback, useMemo } from "react";
import type { StoreApi } from "zustand";
import { multipleEntityDelete } from "../common";
import { PortfolioStudioTab } from "../portfolio-studio-tabs";
import type { SpawnBulkAnalysisReviewDialogParams } from "./BulkAnalysisReviewDialog";
import { BulkAnalysisReviewSections, spawnBulkAnalysisReviewDialog } from "./BulkAnalysisReviewDialog";

export const PortfolioActionUnavailableReason = {
	ACL_REQUIRED: "ACL_REQUIRED",
	USED_AS_NESTED: "USED_AS_NESTED",
	ERROR: "ERROR",
	CALCULATING: "CALCULATING",
	REVIEW: "REVIEW",
	RETRIEVING_DATA: "RETRIEVING_DATA",
	DRAFT: "DRAFT",
	CANNOT_BULK_ENHANCE: "CANNOT_BULK_ENHANCE",
	MISSING_UNIVERSE: "MISSING_UNIVERSE",
	PROPOSAL_READY: "PROPOSAL_READY",
	CANNOT_MIX: "CANNOT_MIX",
	TOO_OLD: "TOO_OLD",
	ACCEPTED: "ACCEPTED",
	READY: "READY",
} as const;

export type PortfolioActionUnavailableReason =
	(typeof PortfolioActionUnavailableReason)[keyof typeof PortfolioActionUnavailableReason];

export const PortfolioListAction = {
	delete: "delete",
	downloadReport: "downloadReport",
	downloadComposition: "downloadComposition",
	compare: "compare",
	createProposal: "createProposal",
	acceptProposal: "acceptProposal",
	rejectProposal: "rejectProposal",
	editSettings: "editSettings",
	generateCommentary: "generateCommentary",
	useAsMixedPortfolio: "useAsMixedPortfolio",
} as const;

export type PortfolioListAction = (typeof PortfolioListAction)[keyof typeof PortfolioListAction];

type AcceptedOrReadyValidationResult =
	| [true, undefined]
	| [false, "ERROR"]
	| [false, "CALCULATING"]
	| [false, "REVIEW"]
	| [false, "RETRIEVING_DATA"]
	| [false, "PROPOSAL_READY"]
	| [false, "DRAFT"];

type ProposalValidationResult =
	| [true, undefined]
	| [false, "ACCEPTED"]
	| [false, "READY"]
	| [false, "ERROR"]
	| [false, "CALCULATING"]
	| [false, "RETRIEVING_DATA"]
	| [false, "REVIEW"]
	| [false, "DRAFT"];

function proposalReady(investment: InvestmentSummary): ProposalValidationResult {
	// making sure we cover all states. if the openapi.json enum changes, this line will cause a compilation error
	// allowing us to evaluate the appropriate adjustments.
	typeAssertion<"PROPOSAL_READY" | ProposalValidationResult[1]>(investment.status!);
	for (const forbiddenStatus of [
		"ACCEPTED",
		"READY",
		"ERROR",
		"CALCULATING",
		"REVIEW",
		"RETRIEVING_DATA",
		"DRAFT",
	] as const) {
		if (investment?.status === forbiddenStatus) {
			return [false, forbiddenStatus];
		}
	}
	return [true, undefined];
}

function acceptedOrReady(investment: InvestmentSummary): AcceptedOrReadyValidationResult {
	// making sure we cover all states. if the openapi.json enum changes, this line will cause a compilation error
	// allowing us to evaluate the appropriate adjustments.
	typeAssertion<"ACCEPTED" | "READY" | AcceptedOrReadyValidationResult[1]>(investment.status!);
	for (const forbiddenStatus of [
		"ERROR",
		"CALCULATING",
		"REVIEW",
		"RETRIEVING_DATA",
		"PROPOSAL_READY",
		"DRAFT",
	] as const) {
		if (investment?.status === forbiddenStatus) {
			return [false, forbiddenStatus];
		}
	}
	return [true, undefined];
}

export const portfolioListActionValidators = {
	delete(portfolio: InvestmentSummary): [true, undefined] | [false, "ACL_REQUIRED"] | [false, "USED_AS_NESTED"] {
		const user = useUserStore.getState().value;

		if (!aclByArea.portfolio.canDelete(user.id, portfolio?.richAcl?.acl ?? [])) {
			return [false, "ACL_REQUIRED"];
		}
		if ((portfolio?.nofUsagesAsNested ?? 0) > 0) {
			return [false, "USED_AS_NESTED"];
		}
		return [true, undefined];
	},
	downloadReport(investment: InvestmentSummary): AcceptedOrReadyValidationResult {
		return acceptedOrReady(investment);
	},
	downloadComposition(investment: InvestmentSummary): AcceptedOrReadyValidationResult {
		return acceptedOrReady(investment);
	},
	compare(investment: InvestmentSummary): AcceptedOrReadyValidationResult {
		return acceptedOrReady(investment);
	},
	createProposal(
		investment: InvestmentListEntry,
		opts: { isBulk: boolean },
	):
		| AcceptedOrReadyValidationResult
		| [false, "CANNOT_BULK_ENHANCE"]
		| [false, "MISSING_UNIVERSE"]
		| [false, "ACL_REQUIRED"] {
		const user = useUserStore.getState().value;

		const result = acceptedOrReady(investment);
		if (!result[0]) {
			return result;
		}
		if (!investment.universeIdentifier) {
			return [false, "MISSING_UNIVERSE"];
		}
		if (opts.isBulk && !investment.canBulkEnhance) {
			return [false, "CANNOT_BULK_ENHANCE"];
		}
		if (!aclByArea.portfolio.canCreateProposal(user.id, investment.richAcl?.acl ?? [])) {
			return [false, "ACL_REQUIRED"];
		}

		return [true, undefined];
	},
	acceptProposal(investment: InvestmentListEntry): ProposalValidationResult | [false, "ACL_REQUIRED"] {
		const user = useUserStore.getState().value;

		if (!aclByArea.portfolio.canEditComposition(user.id, investment.richAcl?.acl ?? [])) {
			return [false, "ACL_REQUIRED"];
		}

		const result = proposalReady(investment);
		if (!result[0]) {
			return result;
		}

		return [true, undefined];
	},
	rejectProposal(investment: InvestmentListEntry): ProposalValidationResult | [false, "ACL_REQUIRED"] {
		const user = useUserStore.getState().value;

		if (!aclByArea.portfolio.canEditComposition(user.id, investment.richAcl?.acl ?? [])) {
			return [false, "ACL_REQUIRED"];
		}

		const result = proposalReady(investment);
		if (!result[0]) {
			return result;
		}

		return [true, undefined];
	},
	editSettings(investment: InvestmentSummary): AcceptedOrReadyValidationResult | [false, "ACL_REQUIRED"] {
		const user = useUserStore.getState().value;

		const result = acceptedOrReady(investment);
		if (!result[0]) {
			return result;
		}

		if (!aclByArea.portfolio.canEditSettings(user.id, investment.richAcl?.acl ?? [])) {
			return [false, "ACL_REQUIRED"];
		}

		return [true, undefined];
	},
	generateCommentary(
		investment: InvestmentListEntry,
	): AcceptedOrReadyValidationResult | [false, "ACL_REQUIRED"] | [false, "TOO_OLD"] {
		const user = useUserStore.getState().value;

		if (
			investment?.status === "PROPOSAL_READY" &&
			diffrenceISODate(investment.modificationTime, START_DATE_CREATE_PROPOSAL_COMMENTARY) <= 0
		) {
			return [false, "TOO_OLD"];
		}

		const result = acceptedOrReady(investment);
		if (!result[0]) {
			return result;
		}

		// TODO: is this correct or a typo?
		if (!aclByArea.portfolio.canEditComposition(user.id, investment.richAcl?.acl ?? [])) {
			return [false, "ACL_REQUIRED"];
		}

		return [true, undefined];
	},
	useAsMixedPortfolio(investment: InvestmentListEntry): AcceptedOrReadyValidationResult | [false, "CANNOT_MIX"] {
		const result = acceptedOrReady(investment);
		if (!result[0]) {
			return result;
		}

		if (!investment.canUseAsMixedPortfolio) {
			return [false, "CANNOT_MIX"];
		}

		return [true, undefined];
	},
} satisfies Record<
	PortfolioListAction,
	(...args: any[]) => [true, undefined] | [false, PortfolioActionUnavailableReason]
>;

export type PortfolioBulkActionAvailabilityResult<Investment extends InvestmentSummary | InvestmentListEntry> = [
	Investment[],
	Record<PortfolioActionUnavailableReason, Investment[]> | null,
];

export function checkPortfolioBulkActionAvailability<Investment extends InvestmentSummary | InvestmentListEntry>(
	action: PortfolioListAction,
	investments: Investment[],
): PortfolioBulkActionAvailabilityResult<Investment> {
	const available: Investment[] = [];
	const unavailable = Object.fromEntries(
		Object.keys(PortfolioActionUnavailableReason).map((k) => [k, new Array<Investment>()]),
	) as Record<PortfolioActionUnavailableReason, Investment[]>;

	for (const investment of investments) {
		const validator = portfolioListActionValidators[action];
		const [valid, reason] = validator(investment, { isBulk: investments.length > 1 });
		if (valid) {
			available.push(investment);
		} else {
			unavailable[reason].push(investment);
		}
	}

	if (available.length === investments.length) {
		return [available, null];
	}

	return [available, unavailable];
}

export type BulkAnalysisActions = ReturnType<typeof useBulkAnalysisActions>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useBulkAnalysisActions<Investment extends InvestmentSummary | InvestmentListEntry>(
	investmentsToValidateParam: Array<Investment>,
	ctx: {
		currentlyComparedInvestments: string[];
		setManualSelection(instrumentUuids: string[]): void;
		setCompareMultipleInvestments(instruments: TemplateChooserUserPreferenceMap): void;
		resetMultiselectCtx(): void;
	},
) {
	const { push } = useTypedNavigation();

	const ctxRef = useUnsafeUpdatedRef({
		investmentsToValidateParam,
		setManualSelection: ctx.setManualSelection,
		setCompareMultipleInvestments: ctx.setCompareMultipleInvestments,
		resetMultiselectCtx: ctx.resetMultiselectCtx,
	});

	const onReview = useCallback(
		(unavailable: Record<PortfolioActionUnavailableReason, Investment[]>, reviewMode: "current" | "blank") => {
			switch (reviewMode) {
				case "blank":
					window.open(
						typedUrlForRoute("PortfoliosStudio", {
							tab: PortfolioStudioTab.Portfolios,
							preselectedInvestments: Object.values(unavailable)
								.flatMap((investments) => investments.map((investment) => investment.uuid!))
								.join("|"),
						}),
						"_blank",
					);
					break;

				case "current":
					ctxRef.current.setManualSelection(
						Object.values(unavailable).flatMap((investments) => investments.map((investment) => investment.uuid!)),
					);
					break;
			}
		},
		[ctxRef],
	);

	// Toast demo
	// useEffect(() => {
	// 	onActionStarted("Merge started", {
	// 		ACL_REQUIRED: [],
	// 		USED_AS_NESTED: [],
	// 		ERROR: [],
	// 		CALCULATING: [],
	// 		REVIEW: [],
	// 		RETRIEVING_DATA: [],
	// 		DRAFT: [],
	// 		CANNOT_BULK_ENHANCE: [],
	// 		PROPOSAL_READY: [],
	// 		CANNOT_MIX: [{ uuid: "test" }],
	// 		TOO_OLD: [],
	// 	});
	// 	platformToast({
	// 		children: "TEST",
	// 		severity: "success",
	// 		icon: "Portfolio",
	// 	});
	// }, []);

	const onActionStarted = useCallback(
		({
			toastTitle,
			toastSubtitle = "Sphere has taken over your request",
			unavailable,
			reviewMode,
		}: {
			toastTitle: string;
			toastSubtitle?: string | null;
			unavailable: Record<PortfolioActionUnavailableReason, Investment[]> | null;
			reviewMode: null | "current" | null;
		}) => {
			platformToast({
				children: (
					<ToastContent alignIcon={toastSubtitle ? "top" : "center"}>
						<Column gap={8} flexGrow={0}>
							<div>
								<Text as="div" type="Body/L/Bold">
									{toastTitle}
								</Text>
								<div>{toastSubtitle}</div>
							</div>
							{reviewMode && unavailable && (
								<Row gap={16}>
									<button
										type="button"
										className="underline"
										onClick={(e) => {
											e.stopPropagation();
											onReview(unavailable, reviewMode);
										}}
									>
										Review unsuitable
									</button>
								</Row>
							)}
						</Column>
					</ToastContent>
				),
				icon: "Portfolio",
				severity: "success",
			});
			ctxRef.current.resetMultiselectCtx();
		},
		[ctxRef, onReview],
	);

	const spawnCustomizedBulkAnalysisReviewDialogWithException = useCallback(
		async function <T>(
			params: SpawnBulkAnalysisReviewDialogParams<T> & { onContinue(value: T): MaybePromise<void> },
			unavailable: Record<PortfolioActionUnavailableReason, Investment[]> | null,
		): Promise<T> {
			const dialogResult = await spawnBulkAnalysisReviewDialog(params);
			switch (dialogResult.kind) {
				case "review":
					onReview(unavailable!, "current");
				// fallthrough
				case "cancel":
					throw new UserCancelledError();
				case "continue":
					return dialogResult.result;
			}
		},
		[onReview],
	);

	return useMemo(() => {
		const refetchPortfolios = () =>
			typedQueryClient().invalidateQueries([
				queryClientKeys.portfolioStudioInvestmentList,
				queryClientKeys.portfolioStudioProjectedInvestmentList,
				queryClientKeys.portfolioStudioKeyMetric,
			]);

		return {
			acceptProposal() {
				const investmentsToValidate = ctxRef.current.investmentsToValidateParam;
				const availabilityResult = checkPortfolioBulkActionAvailability("acceptProposal", investmentsToValidate);
				const [available, unavailable] = availabilityResult;
				spawnCustomizedBulkAnalysisReviewDialogWithException(
					{
						initialState: null,
						header: "Accept proposal: review and confirm",
						children: <BulkAnalysisReviewSections bulkActionAvailabilityResult={availabilityResult} />,
						canConfirm: available.length > 0,
						onContinue: () =>
							runWithErrorReporting(
								async () => {
									onActionStarted({
										toastTitle: "Accept proposal",
										unavailable,
										reviewMode: null,
									});
									const failed: Array<{ err: unknown; portfolioUuid: string }> = [];
									const investmentsProposalToAccept = available.map(
										({ uuid }) =>
											() =>
												getApiGen(InvestmentEnhancementControllerV4ApiFactory)
													.acceptEnhancement(uuid!)
													.catch((err) => failed.push({ err, portfolioUuid: uuid! })),
									);

									await parallelizeWithAllSettled(investmentsProposalToAccept, { concurrency: 3 });

									if (failed.length < investmentsProposalToAccept.length) {
										refetchPortfolios().catch(noop);
									}

									if (failed.length > 0) {
										const errorMsg =
											failed.length === 1
												? `Unable to accept ${failed.length} proposal`
												: `Unable to accept ${failed.length} proposals`;
										reportPlatformError(new Error(errorMsg), "ERROR", "portfolio", {
											message: "try to accept proposal",
											payload: JSON.stringify({ failed }),
										});
										platformToast({
											children: errorMsg,
											icon: "Portfolio",
											severity: "error",
										});
									}
								},
								{
									area: "portfolio",
									attemptedOperation: {
										message: "try to accept proposal",
										payload: JSON.stringify({ available }),
									},
								},
							),
					},
					unavailable,
				).catch(noop);
			},
			rejectProposal() {
				const investmentsToValidate = ctxRef.current.investmentsToValidateParam;
				const availabilityResult = checkPortfolioBulkActionAvailability("rejectProposal", investmentsToValidate);
				const [available, unavailable] = availabilityResult;
				spawnCustomizedBulkAnalysisReviewDialogWithException(
					{
						initialState: null,
						header: "Reject proposal: review and confirm",
						children: <BulkAnalysisReviewSections bulkActionAvailabilityResult={availabilityResult} />,
						canConfirm: available.length > 0,
						onContinue: () =>
							runWithErrorReporting(
								async () => {
									onActionStarted({ toastTitle: "Reject proposal", unavailable, reviewMode: null });
									const failed: Array<{ err: unknown; portfolioUuid: string }> = [];
									const investmentsProposalToReject = available.map(
										({ uuid }) =>
											() =>
												getApiGen(InvestmentEnhancementControllerV4ApiFactory)
													.rejectEnhancement(uuid!)
													.catch((err) => failed.push({ err, portfolioUuid: uuid! })),
									);

									await parallelizeWithAllSettled(investmentsProposalToReject, { concurrency: 3 });

									if (failed.length < investmentsProposalToReject.length) {
										refetchPortfolios().catch(noop);
									}

									if (failed.length > 0) {
										const errorMsg =
											failed.length === 1
												? `Unable to reject ${failed.length} proposal`
												: `Unable to reject ${failed.length} proposals`;

										reportPlatformError(new Error(errorMsg), "ERROR", "portfolio", {
											message: "try to reject proposal",
											payload: JSON.stringify({ failed }),
										});
										platformToast({
											children: errorMsg,
											icon: "Portfolio",
											severity: "error",
										});
									}
								},
								{
									area: "portfolio",
									attemptedOperation: {
										message: "try to reject proposal",
										payload: JSON.stringify({ available }),
									},
								},
							),
					},
					unavailable,
				).catch(noop);
			},
			createProposal() {
				const investmentsToValidate = ctxRef.current.investmentsToValidateParam;
				const availabilityResult = checkPortfolioBulkActionAvailability("createProposal", investmentsToValidate);
				const [available, unavailable] = availabilityResult;

				if (investmentsToValidate.length === 1 && available.length === 1) {
					// operation on single entry, no modal
					push("Portfolios/EditPortfolio", { portfolioUid: available[0].uuid! });
					ctxRef.current.resetMultiselectCtx();
				} else {
					spawnCustomizedBulkAnalysisReviewDialogWithException(
						{
							initialState: null,
							header: "Create proposal: review and confirm",
							children: <BulkAnalysisReviewSections bulkActionAvailabilityResult={availabilityResult} />,
							canConfirm: available.length > 0,
							onContinue: () =>
								runWithErrorReporting(
									async () => {
										if (available.length === 1) {
											push("Portfolios/EditPortfolio", { portfolioUid: available[0].uuid! });
											ctxRef.current.resetMultiselectCtx();
										} else {
											try {
												const { uuid } = await axiosExtract(
													getApiGen(
														InvestmentBulkEnhancementConfigurationControllerV4ApiFactory,
													).createDraftBulkEnhancement(available.map((investment) => investment.uuid!)),
												);
												push("Portfolios/EditPortfolioMulti", { proposalUuid: uuid ?? "" });
												onActionStarted({
													toastTitle: "Create proposal",
													toastSubtitle: null,
													unavailable,
													reviewMode: null,
												});
											} catch (error) {
												throw new ToastableError("Unable to create a proposal", { cause: error, icon: "Portfolio" });
											}
										}
									},
									{ area: "portfolio", attemptedOperation: { message: "try to create bulk enhancement" } },
								),
						},
						unavailable,
					).catch(noop);
				}
			},
			async downloadReport(template: InvestmentsReportTemplate) {
				const investmentsToValidate = ctxRef.current.investmentsToValidateParam;
				const availabilityResult = checkPortfolioBulkActionAvailability("downloadReport", investmentsToValidate);
				const [available, unavailable, proposalReadyInvestments] = splitProposalReady(availabilityResult);

				if (investmentsToValidate.length === 1 && available.length === 1) {
					const page = {
						variant: "current",
						objectId: available[0].uuid,
						templateId: isDefaultReportTemplate(template) ? template.id : template.uuid!,
						name: generateReportBuilderTemplateName(
							{ choice: "current", uuid: available[0].uuid!, name: available[0].name! },
							template.templateName ?? "-",
						),
					};
					const pdf = await axiosExtract(
						getApiGen(PdfControllerApiFactory).generateCustomReportPdf(page.name, page, {
							responseType: "blob",
						}),
					);
					downloadBlob(pdf as Blob, {
						fileName: page.name ?? "untitled",
					});
					ctxRef.current.resetMultiselectCtx();
					return;
				}

				spawnCustomizedBulkAnalysisReviewDialogWithException(
					{
						initialState: "CURRENT" as "CURRENT" | "PROPOSAL" | "BOTH",
						header: "Download: review and confirm",
						children: (stateStore) => (
							<BulkAnalysisReviewSections
								bulkActionAvailabilityResult={availabilityResult}
								midSections={proposalMidSections(proposalReadyInvestments.length, stateStore)}
							/>
						),
						canConfirm: available.length > 0 || proposalReadyInvestments.length > 0,
						onContinue: (applyTo) =>
							runWithErrorReporting(
								async () => {
									const pages = switchExpr(applyTo, {
										CURRENT: () =>
											available.map(({ uuid, name }) => ({
												variant: "current",
												objectId: uuid,
												templateId: isDefaultReportTemplate(template) ? template.id : template.uuid!,
												name: generateReportBuilderTemplateName(
													{ choice: "current", uuid: uuid!, name },
													template.templateName ?? "-",
												),
											})),
										PROPOSAL: () =>
											proposalReadyInvestments.map(({ uuid, name }) => ({
												variant: "proposal",
												objectId: uuid,
												templateId: isDefaultReportTemplate(template) ? template.id : template.uuid!,
												name: generateReportBuilderTemplateName(
													{ choice: "enhance", uuid: uuid!, name },
													template.templateName ?? "-",
												),
											})),
										BOTH: () => [
											...available.map(({ uuid, name }) => ({
												variant: "current",
												objectId: uuid,
												templateId: isDefaultReportTemplate(template) ? template.id : template.uuid!,
												name: generateReportBuilderTemplateName(
													{ choice: "current", uuid: uuid!, name },
													template.templateName ?? "-",
												),
											})),
											...proposalReadyInvestments.flatMap(({ uuid, name }) => [
												{
													variant: "current",
													objectId: uuid,
													templateId: isDefaultReportTemplate(template) ? template.id : template.uuid!,
													name: generateReportBuilderTemplateName(
														{ choice: "current", uuid: uuid!, name },
														template.templateName ?? "-",
													),
												},
												{
													variant: "proposal",
													objectId: uuid,
													templateId: isDefaultReportTemplate(template) ? template.id : template.uuid!,
													name: generateReportBuilderTemplateName(
														{ choice: "enhance", uuid: uuid!, name },
														template.templateName ?? "-",
													),
												},
											]),
										],
									});
									try {
										if (pages.length === 1) {
											onActionStarted({ toastTitle: "Download", unavailable, reviewMode: null });
											const [page] = pages;
											const pdf = await axiosExtract(
												getApiGen(PdfControllerApiFactory).generateCustomReportPdf(page.name, page, {
													responseType: "blob",
												}),
											);
											downloadBlob(pdf as Blob, {
												fileName: page.name ?? "untitled",
											});
										} else {
											await getApiGen(PdfControllerApiFactory).generateCustomReportsPdf({
												pages,
												templateType: "TPLPortraitNoMargin",
											});
											onActionStarted({ toastTitle: "Download", unavailable, reviewMode: null });
										}
									} catch (error) {
										throw new ToastableError("Unable to download reports", {
											cause: error,
											icon: "Portfolio",
										});
									}
								},
								{ area: "portfolio", attemptedOperation: { message: "try to download report" } },
							),
					},
					unavailable,
				).catch(noop);
			},
			downloadComposition(converter: InvestmentExportConverterType) {
				const investmentsToValidate = ctxRef.current.investmentsToValidateParam;
				const availabilityResult = checkPortfolioBulkActionAvailability("downloadComposition", investmentsToValidate);
				const [available, unavailable, proposalReadyInvestments] = splitProposalReady(availabilityResult);

				spawnCustomizedBulkAnalysisReviewDialogWithException(
					{
						initialState: "CURRENT" as "CURRENT" | "PROPOSAL" | "BOTH",
						header: "Download: review and confirm",
						children: (stateStore) => (
							<BulkAnalysisReviewSections
								bulkActionAvailabilityResult={availabilityResult}
								midSections={proposalMidSections(proposalReadyInvestments.length, stateStore)}
							/>
						),
						canConfirm: available.length > 0 || proposalReadyInvestments.length > 0,
						onContinue: async (applyTo) => {
							const investments = switchExpr(applyTo, {
								CURRENT: () =>
									available.map(({ uuid }) => ({
										investmentUUID: uuid,
										proposal: false,
									})),
								PROPOSAL: () =>
									proposalReadyInvestments.map(({ uuid }) => ({
										investmentUUID: uuid,
										proposal: true,
									})),
								BOTH: () => [
									...available.map(({ uuid }) => ({
										investmentUUID: uuid,
										proposal: false,
									})),
									...proposalReadyInvestments.flatMap(({ uuid }) => [
										{
											investmentUUID: uuid,
											proposal: false,
										},
										{
											investmentUUID: uuid,
											proposal: true,
										},
									]),
								],
							});

							await runWithErrorReporting(
								async () => {
									const integrationsApi = getApiGen(IntegrationsControllerV3ApiFactory);

									try {
										const compositions = await parallelize(
											investments.map(
												({ investmentUUID, proposal }) =>
													() =>
														axiosExtract(integrationsApi.exportInvestment(investmentUUID!, proposal)),
											),
											{ concurrency: 3 },
										);

										if (investments.length > 1) {
											await axiosExtract(integrationsApi.convertTo(converter, compositions, true));
											onActionStarted({
												toastTitle: "Download",
												unavailable,
												reviewMode: null,
											});
										} else {
											downloadContentDisposition(
												await integrationsApi.convertTo(converter, compositions, false, { responseType: "blob" }),
											);
											ctxRef.current.resetMultiselectCtx();
										}
									} catch (error) {
										throw new ToastableError("Unable to export investments", {
											cause: error,
											icon: "Portfolio",
										});
									}
								},
								{
									area: "portfolio",
									attemptedOperation: {
										message: "download multiple portfolio compositions",
										payload: JSON.stringify({
											investments,
										}),
									},
								},
							);
						},
					},
					unavailable,
				).catch(noop);
			},
			generateCommentary(templateUUID: string) {
				const investmentsToValidate = ctxRef.current.investmentsToValidateParam;
				const availabilityResult = checkPortfolioBulkActionAvailability("generateCommentary", investmentsToValidate);
				const [available, unavailable, proposalReadyInvestments] = splitProposalReady(availabilityResult);

				spawnCustomizedBulkAnalysisReviewDialogWithException(
					{
						initialState: "CURRENT" as "CURRENT" | "PROPOSAL" | "BOTH",
						header: "Generate commentary: review and confirm",
						children: (stateStore) => (
							<BulkAnalysisReviewSections
								bulkActionAvailabilityResult={availabilityResult}
								midSections={proposalMidSections(proposalReadyInvestments.length, stateStore)}
							/>
						),
						canConfirm: available.length > 0 || proposalReadyInvestments.length > 0,
						onContinue: (applyTo) =>
							runWithErrorReporting(
								async () => {
									try {
										await axiosExtract(
											getApiGen(InvestmentControllerV4ApiFactory).createCommentaryBulkFromTemplate(
												switchExpr(applyTo, {
													CURRENT: () =>
														available.map(({ uuid }) => ({
															investmentUUID: uuid,
															templateUUID,
															proposal: false,
														})),
													PROPOSAL: () =>
														proposalReadyInvestments.map(({ uuid }) => ({
															investmentUUID: uuid,
															templateUUID,
															proposal: true,
														})),
													BOTH: () => [
														...available.map(({ uuid }) => ({
															investmentUUID: uuid,
															templateUUID,
															proposal: false,
														})),
														...proposalReadyInvestments.flatMap(({ uuid }) => [
															{
																investmentUUID: uuid,
																templateUUID,
																proposal: false,
															},
															{
																investmentUUID: uuid,
																templateUUID,
																proposal: true,
															},
														]),
													],
												}),
											),
										);
										onActionStarted({
											toastTitle: "Generate commentary",
											unavailable,
											reviewMode: null,
										});
									} catch (error) {
										throw new ToastableError("Unable to generate commentaries", { cause: error, icon: "Portfolio" });
									}
								},
								{ area: "portfolio", attemptedOperation: { message: "try to create commentary" } },
							),
					},
					unavailable,
				).catch(noop);
			},
			useAsMixedPortfolio() {
				const investmentsToValidate = ctxRef.current.investmentsToValidateParam;
				const availabilityResult = checkPortfolioBulkActionAvailability("useAsMixedPortfolio", investmentsToValidate);
				const [available, unavailable] = availabilityResult;
				spawnCustomizedBulkAnalysisReviewDialogWithException(
					{
						initialState: null,
						header: "Merge: review and confirm",
						children: <BulkAnalysisReviewSections bulkActionAvailabilityResult={availabilityResult} />,
						canConfirm: available.length > 0,
						onContinue: () => {
							push("Portfolios/ManualPortfolioCreation", {
								preselectedInvestments: available.map(({ uuid }) => uuid).join(","),
							});
							ctxRef.current.resetMultiselectCtx();
						},
					},
					unavailable,
				).catch(noop);
			},
			compare() {
				const investmentsToValidate = ctxRef.current.investmentsToValidateParam;
				const availabilityResult = checkPortfolioBulkActionAvailability("compare", investmentsToValidate);
				const [available, unavailable, proposalReadyInvestments] = splitProposalReady(availabilityResult);

				spawnCustomizedBulkAnalysisReviewDialogWithException(
					{
						initialState: Map</* uuid */ string, TemplateChooserUserPreferenceValue>(),
						header: "Compare: review and confirm",
						children: (stateStore) => (
							<BulkAnalysisReviewSections
								bulkActionAvailabilityResult={availabilityResult}
								midSections={[
									...(!proposalReadyInvestments.length
										? []
										: [
												{
													count: proposalReadyInvestments.length,
													title:
														proposalReadyInvestments.length === 1
															? "Portfolio has a pending proposal. Apply the action to:"
															: "Portfolios have a pending proposal. Apply the action to:",
													children: (
														<WithRwZustandStore store={stateStore}>
															{([state, setState]) => (
																<TemplateChooserTable
																	proposalReadyInvestments={proposalReadyInvestments}
																	userPreference={state}
																	onChangePreference={setState}
																/>
															)}
														</WithRwZustandStore>
													),
												},
										  ]),
								]}
							/>
						),
						canConfirm: (state) =>
							(proposalReadyInvestments.length > 0 && state.some((x) => x.current || x.enhance)) ||
							available.length > 0,
						onContinue: (proposalOrCurrentMap) => {
							ctxRef.current.setCompareMultipleInvestments(
								proposalOrCurrentMap.merge(available.map(({ uuid }) => [uuid!, { current: true, enhance: false }])),
							);
							ctxRef.current.resetMultiselectCtx();
						},
					},
					unavailable,
				).catch(noop);
			},
			delete() {
				const investmentsToValidate = ctxRef.current.investmentsToValidateParam;
				const availabilityResult = checkPortfolioBulkActionAvailability("delete", investmentsToValidate);
				const [available, unavailable] = availabilityResult;
				spawnCustomizedBulkAnalysisReviewDialogWithException(
					{
						initialState: null,
						header: "Delete: review and confirm",
						children: <BulkAnalysisReviewSections bulkActionAvailabilityResult={availabilityResult} />,
						canConfirm: available.length > 0,
						onContinue: () =>
							runWithErrorReporting(
								async () => {
									const { failed, succeded } = await multipleEntityDelete(
										{ area: "Portfolios", items: available },
										false,
									);

									if (failed.length < available.length) {
										refetchPortfolios().catch(noop);
									}

									if (succeded.length > 0) {
										onActionStarted({
											toastTitle: "Delete",
											toastSubtitle: `You have successfully deleted ${succeded.length} portfolios`,
											unavailable,
											reviewMode: null,
										});
									}
									if (failed.length > 0 && available.length === failed.length) {
										throw new ToastableError(`Unable to delete ${failed.length} portfolios`, {
											icon: "Portfolio",
										});
									}

									if (failed.length > 0) {
										platformToast({
											icon: "Portfolio",
											children: `Unable to delete ${failed.length} portfolios`,
											severity: "error",
										});
									}
								},
								{
									area: "portfolio",
									attemptedOperation: {
										message: "delete multiple portfolios",
										payload: JSON.stringify({
											investments: available,
										}),
									},
								},
							),
					},
					unavailable,
				).catch(noop);
			},
			editSettings() {
				const investmentsToValidate = ctxRef.current.investmentsToValidateParam;
				const availabilityResult = checkPortfolioBulkActionAvailability("editSettings", investmentsToValidate);
				const [available, unavailable] = availabilityResult;

				if (investmentsToValidate.length === 1 && available.length === 1) {
					push("Portfolios/EditPortfolio", {
						portfolioUid: available[0].uuid!,
					});
				} else {
					spawnCustomizedBulkAnalysisReviewDialogWithException(
						{
							initialState: null,
							header: "Edit market view: review and confirm",
							children: <BulkAnalysisReviewSections bulkActionAvailabilityResult={availabilityResult} />,
							canConfirm: available.length > 0,
							onContinue: async () => {
								if (available.length === 1) {
									push("Portfolios/EditPortfolio", {
										portfolioUid: available[0].uuid!,
									});
									ctxRef.current.resetMultiselectCtx();
								} else {
									await runWithErrorReporting(
										async () => {
											try {
												const { uuid } = await axiosExtract(
													getApiGen(
														InvestmentBulkBulkStaticConfigurationControllerV1ApiFactory,
													).createDraftConfiguration(available.map((x) => x.uuid!)),
												);
												push("Portfolios/SettingsPortfolioMulti", { bulkUid: uuid ?? "" });
												ctxRef.current.resetMultiselectCtx();
											} catch (error) {
												throw new ToastableError("Unable to edit portfolio settings", {
													cause: error,
													icon: "Portfolio",
												});
											}
										},
										{ area: "portfolio", attemptedOperation: { message: "try to create bulk edit settings" } },
									);
								}
							},
						},
						unavailable,
					).catch(noop);
				}
			},
		} satisfies Record<PortfolioListAction, (...args: any[]) => MaybePromise<void>>;
	}, [ctxRef, push, spawnCustomizedBulkAnalysisReviewDialogWithException, onActionStarted]);
}

function proposalMidSections(
	nOfProposalReadyInvestments: number,
	stateStore: StoreApi<"CURRENT" | "PROPOSAL" | "BOTH">,
) {
	return [
		...(!nOfProposalReadyInvestments
			? []
			: [
					{
						count: nOfProposalReadyInvestments,
						title:
							nOfProposalReadyInvestments === 1
								? "Portfolio has a pending proposal. Apply the action to:"
								: "Portfolios have a pending proposal. Apply the action to:",
						children: (
							<WithRwZustandStore store={stateStore}>
								{([state, setState]) => (
									<RadioGroup value={state} onChange={setState}>
										<Column flexGrow={0} gap={8}>
											<Radio value="CURRENT">Only current</Radio>
											<Radio value="PROPOSAL">Only proposal</Radio>
											<Radio value="BOTH">Both</Radio>
										</Column>
									</RadioGroup>
								)}
							</WithRwZustandStore>
						),
					},
			  ]),
	];
}

function splitProposalReady<T extends InvestmentSummary | InvestmentListEntry>(
	availabilityResult: PortfolioBulkActionAvailabilityResult<T>,
) {
	const [available] = availabilityResult;

	const proposalReadyInvestments = availabilityResult[1]?.PROPOSAL_READY ?? [];
	if (availabilityResult[1]) {
		availabilityResult[1].PROPOSAL_READY = [];
		if (Object.values(availabilityResult[1]).every((list) => list.length === 0)) {
			availabilityResult[1] = null;
		}
	}
	const unavailable = availabilityResult[1];

	return [available, unavailable, proposalReadyInvestments] as const;
}
