import type {
	OptionModelDto,
	StandardClassificationId,
	StandardInstrumentsClassificationDto,
	UserInstrumentClassificationDto,
} from "$root/api/api-gen";
import {
	Color,
	DataVisualization,
	InstrumentFieldType,
	InstrumentsClassificationsControllerV1ApiFactory,
	TagType,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { FormSidebarContent, spawnSidePanel } from "$root/components/SidePanel/SidePanel";
import { useDebouncedNameUniquenessChecker } from "$root/functional-areas/named-entities/uniqueness";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { FormController } from "$root/third-party-integrations/react-hook-form";
import { FormFields } from "$root/ui-lib/form/FormFields";
import { getThemeCssVars, lazy, preventSubmitOnPressEnter, qualifier, useQueryNoRefetch } from "$root/utils";
import { InfoTooltip } from "$root/widgets-architecture/layout/WidgetsMapper/InfoTooltip";
import { zodResolver } from "@hookform/resolvers/zod";
import type { Option, OptionWithOptionalGroup } from "@mdotm/mdotui/components";
import {
	Banner,
	Button,
	ButtonGroupRadio,
	CircularProgressBar,
	ClickableDiv,
	Column,
	DraggableList,
	FloatingContent,
	FormField,
	Icon,
	Label,
	Row,
	ScrollWrapper,
	Text,
	TextInput,
	TinyIconButton,
	TooltipContent,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { ForEach, toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { typedObjectValues } from "@mdotm/mdotui/utils";
import { Map } from "immutable";
import { useCallback, useMemo, useState } from "react";
import type { Control } from "react-hook-form";
import { useFieldArray, useForm } from "react-hook-form";
import { match } from "ts-pattern";
import * as z from "zod";

// NOTE: themecssvars which need to be resolved lazily
export const colorMap: Record<
	Color,
	{ badgeColor: () => string; chartColor: () => string; label: string; key: Color }
> = {
	DEEP_RED: {
		badgeColor: lazy(() => getThemeCssVars(themeCSSVars.palette_R100)),
		chartColor: lazy(() => getThemeCssVars(themeCSSVars.palette_R300)),
		label: "Deep red",
		key: "DEEP_RED",
	},
	BRIGHT_RED: {
		badgeColor: lazy(() => getThemeCssVars(themeCSSVars.palette_D100)),
		chartColor: lazy(() => getThemeCssVars(themeCSSVars.palette_D300)),
		label: "Bright red",
		key: "BRIGHT_RED",
	},
	ORANGE: {
		badgeColor: lazy(() => getThemeCssVars(themeCSSVars.palette_W100)),
		chartColor: lazy(() => getThemeCssVars(themeCSSVars.palette_W300)),
		label: "Orange",
		key: "ORANGE",
	},
	YELLOW: {
		badgeColor: lazy(() => getThemeCssVars(themeCSSVars.palette_A100)),
		chartColor: lazy(() => getThemeCssVars(themeCSSVars.palette_A300)),
		label: "Yellow",
		key: "YELLOW",
	},
	LIGHT_BLUE: {
		badgeColor: lazy(() => getThemeCssVars(themeCSSVars.palette_S100)),
		chartColor: lazy(() => getThemeCssVars(themeCSSVars.palette_S300)),
		label: "Light blue",
		key: "LIGHT_BLUE",
	},
	BLUEBERRY: {
		badgeColor: lazy(() => getThemeCssVars(themeCSSVars.palette_B100)),
		chartColor: lazy(() => getThemeCssVars(themeCSSVars.palette_B300)),
		label: "Blueberry",
		key: "BLUEBERRY",
	},
	VIOLET: {
		badgeColor: lazy(() => getThemeCssVars(themeCSSVars.palette_V100)),
		chartColor: lazy(() => getThemeCssVars(themeCSSVars.palette_V300)),
		label: "Violet",
		key: "VIOLET",
	},
	GRAY: {
		badgeColor: lazy(() => getThemeCssVars(themeCSSVars.palette_N100)),
		chartColor: lazy(() => getThemeCssVars(themeCSSVars.palette_N300)),
		label: "Gray",
		key: "GRAY",
	},
	GREEN: {
		badgeColor: lazy(() => getThemeCssVars(themeCSSVars.palette_P100)),
		chartColor: lazy(() => getThemeCssVars(themeCSSVars.palette_P300)),
		label: "Green",
		key: "GREEN",
	},
};

export const colors = typedObjectValues(colorMap);

//UserInstrumentClassificationDto : replace with api def
type EditClassificationOnlyKeys = "classificationUuid" | "used" | "userId";

export type CreateClassification = Omit<UserInstrumentClassificationDto, EditClassificationOnlyKeys>;

type InstrumentCustomizationSidePanelProps = {
	onCancel(): void;
	setCanDismiss(v: boolean): void;
	existingColumnNames: string[];
} & (
	| {
			mode: "creation";
			onSubmitAsync(classification: CreateClassification): MaybePromise<void>;
	  }
	| {
			mode: "edit";
			classification: UserInstrumentClassificationDto;
			onSubmitAsync(classification: UserInstrumentClassificationDto): MaybePromise<void>;
	  }
);

const allowedColumnName = /^[a-zA-Z0-9\\[\]\\(\\)\\{\\}\-_ ]{1,30}$/;

function castedZodClassification(checkIfNameIsAvailable: (name: string) => Promise<boolean>) {
	return {
		classificationUuid: z.string(),
		name: z
			.string()
			.regex(allowedColumnName, { message: "Please provide a valid name" })
			.refine((name) => checkIfNameIsAvailable(name), {
				message: "A column with this name already exists",
			}),
		fieldType: z.nativeEnum(InstrumentFieldType, { required_error: "Please select a valid column type" }),
		tagType: z.nativeEnum(TagType).optional(),
		standardClassificationId: z.string().optional().nullable() /** z.nativeEnum(StandardClassificationId) */,
		dataVisualization: z.nativeEnum(DataVisualization).optional(),
		modificationTime: z.string().optional(),
		options: z
			.array(
				z.object({
					color: z.nativeEnum(Color).optional(),
					option: z.string(),
				} satisfies Record<keyof OptionModelDto, unknown>),
			)
			.optional(),
	} satisfies Record<keyof Omit<UserInstrumentClassificationDto, "used" | "userId">, unknown>;
}

const creationResolver = (checkIfNameIsAvailable: (name: string) => Promise<boolean>) => {
	const { classificationUuid: _classificationUuid, ...restZodClassification } =
		castedZodClassification(checkIfNameIsAvailable);
	return z.object({ ...restZodClassification } satisfies Record<keyof CreateClassification, unknown>).refine(
		(form) => {
			if (form.fieldType === "TAG") {
				return form.tagType != null;
			}
			return true;
		},
		{
			message: "Please select a valid tag type",
			path: ["tagType"],
		},
	);
};
const editResolver = (checkIfNameIsAvailable: (name: string) => Promise<boolean>) => {
	const { classificationUuid: _classificationUuid, ...restZodClassification } =
		castedZodClassification(checkIfNameIsAvailable);
	return z.object({ ...restZodClassification } satisfies Record<keyof CreateClassification, unknown>);
};

type ClassificationAllowedAction = Record<
	| "canEditType"
	| "canEditTagType"
	| "canEditName"
	| "canEditVisualization"
	| "canEditOptionName"
	| "canEditOptionOrder"
	| "canEditOptionColor"
	| "canAddOption"
	| "canRemoveOption",
	boolean
>;

function classificationAllowedAction(
	mode: InstrumentCustomizationSidePanelProps["mode"],
	standardColumnId?: string,
): ClassificationAllowedAction {
	if (mode === "edit") {
		return {
			canEditType: false,
			canEditTagType: false,
			canEditName: true,
			canEditVisualization: true,
			canEditOptionName: false,
			canEditOptionOrder: true,
			canEditOptionColor: true,
			canAddOption: true,
			canRemoveOption: false,
		};
	}

	if (mode === "creation" && standardColumnId) {
		return {
			canEditType: false,
			canEditTagType: true,
			canEditName: true,
			canEditVisualization: true,
			canEditOptionName: false,
			canEditOptionOrder: true,
			canEditOptionColor: true,
			canAddOption: true,
			canRemoveOption: false,
		};
	}

	return {
		canEditType: true,
		canEditTagType: true,
		canEditName: true,
		canEditVisualization: true,
		canEditOptionName: true,
		canEditOptionOrder: true,
		canEditOptionColor: true,
		canAddOption: true,
		canRemoveOption: true,
	};
}

type EditableInstrumentClassificationOption = OptionModelDto & { canEdit?: boolean };
type InstrumentClassificationForm = Omit<CreateClassification, "options"> & {
	options?: Array<EditableInstrumentClassificationOption>;
};

function InstrumentCustomizationSidePanel(props: InstrumentCustomizationSidePanelProps): JSX.Element {
	const { onCancel, onSubmitAsync, mode } = props;
	const instrumentsClassificationsControllerV1Api = useApiGen(InstrumentsClassificationsControllerV1ApiFactory);

	const defaultValues = useMemo<InstrumentClassificationForm>(() => {
		if (mode === "creation") {
			return { name: "" };
		}
		const { classificationUuid: _, options, ...rest } = props.classification;
		const remappedOptions = options?.map(
			(x): EditableInstrumentClassificationOption => ({ ...x, canEdit: false, color: x.color ?? "GRAY" }),
		);
		return { ...rest, options: remappedOptions };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mode]);

	const { checkIfNameIsAvailable, checkingNameUniqueness } = useDebouncedNameUniquenessChecker({
		isNameAvailableApi: (name, opts) =>
			axiosExtract(instrumentsClassificationsControllerV1Api.isClassificationNameAvailable(name, opts)),
		currentName: props.mode === "creation" ? undefined : defaultValues.name,
	});

	const { control, formState, watch, handleSubmit, reset } = useForm({
		defaultValues,
		resolver: zodResolver(
			useMemo(
				() => (mode === "creation" ? creationResolver(checkIfNameIsAvailable) : editResolver(checkIfNameIsAvailable)),
				[mode, checkIfNameIsAvailable],
			),
		),
	});

	props.setCanDismiss(!formState.isDirty);
	const observedClassification = watch();
	const allowedActions = useMemo(
		() => classificationAllowedAction(mode, observedClassification.standardClassificationId),
		[mode, observedClassification],
	);

	const queryStandardClassification = useQueryNoRefetch(["queryStandardClassification"], {
		queryFn() {
			if (props.mode === "creation") {
				return axiosExtract(instrumentsClassificationsControllerV1Api.retrieveStandardClassification());
			}
		},
		enabled: props.mode === "creation",
	});

	const standardClassificationOptions = useMemo(
		() =>
			queryStandardClassification.data?.map(
				(standardClassification): OptionWithOptionalGroup<StandardClassificationId, "standard"> => ({
					label: standardClassification.name!,
					value: standardClassification.standardClassificationId!,
					group: "standard",
				}),
			) ?? [],
		[queryStandardClassification.data],
	);

	const classificationMap = useMemo(
		() =>
			Map<StandardClassificationId, StandardInstrumentsClassificationDto>(
				queryStandardClassification.data?.flatMap((x) =>
					x.standardClassificationId ? [[x.standardClassificationId, x]] : [],
				),
			),
		[queryStandardClassification.data],
	);

	return (
		<FormSidebarContent
			title={mode === "edit" ? props.classification.name ?? "..." : "Add new column"}
			description={
				mode === "edit"
					? ""
					: "Customize your view by adding a new column. You can start with the available presets: the platform will automatically load the relevant tags and values, which you can still modify to suit your needs."
			}
			onCancel={onCancel}
			onSubmit={() =>
				handleSubmit(
					(payload) =>
						mode === "creation"
							? onSubmitAsync(payload)
							: onSubmitAsync({ classificationUuid: props.classification.classificationUuid, ...payload }),
					console.error,
				)()
			}
		>
			{mode === "creation" && (
				<ReactQueryWrapperBase
					query={queryStandardClassification}
					loadingFallback={<CircularProgressBar style={{ width: "1rem" }} value="indeterminate" />}
				>
					{() => (
						<FormFields.Select
							size="small"
							name="standardClassificationId"
							label={({ htmlFor }) => (
								<Row justifyContent="space-between" alignItems="center">
									<Label htmlFor={htmlFor}>Load from prefilled data</Label>
									<Text type="Body/S/Medium" color={themeCSSVars.palette_N500}>
										(optional)
									</Text>
								</Row>
							)}
							data-qualifier={qualifier.component.input.select("List/Preset")}
							triggerDataAttrs={{ "data-qualifier": qualifier.component.input.select("Preset") }}
							enableSearch
							control={control}
							formState={formState}
							options={standardClassificationOptions}
							unselectOnMatch
							onChange={(standardClassificationId: StandardClassificationId) => {
								if (standardClassificationId) {
									if (observedClassification.standardClassificationId === standardClassificationId) {
										reset({ standardClassificationId: undefined });
										return;
									}

									const presetClassification = classificationMap.get(standardClassificationId);
									if (presetClassification) {
										let colorIndex = 0;
										reset(
											{
												standardClassificationId,
												options: presetClassification.options?.flatMap((option) => {
													const healthyOption = option
														? [{ ...option, color: option.color ?? colors[colorIndex].key }]
														: [];

													if (colorIndex === colors.length - 1) {
														colorIndex = 0;
														return healthyOption;
													}
													colorIndex += 1;
													return healthyOption;
												}),
												fieldType: presetClassification.fieldType,
												dataVisualization: DataVisualization.TagViewWithoutColor,
												name: presetClassification.name,
											},
											{ keepDefaultValues: true },
										);
									}
								}
							}}
							classList="mb-4"
						/>
					)}
				</ReactQueryWrapperBase>
			)}
			<FormFields.Text
				name="name"
				size="small"
				control={control}
				data-qualifier={qualifier.component.input.text("ColumnName")}
				label={({ htmlFor }) => (
					<Row alignItems="center" gap={8}>
						<Label htmlFor={htmlFor} classList="mb-px">
							Column name
						</Label>

						<InfoTooltip>
							<TooltipContent>
								<Text type="Body/M/Medium" classList="mb-2" as="p">
									Please follow these rules for valid name entry
								</Text>
								<ul style={{ listStyleType: "disc" }}>
									<ForEach
										collection={[
											"1-30 characters long",
											"Allowed: Letters, numbers, spaces, _ - [ ] ( ) {}",
											"No special characters like @, #, $, %",
										]}
									>
										{({ item }) => <li>{item}</li>}
									</ForEach>
								</ul>
							</TooltipContent>
						</InfoTooltip>
					</Row>
				)}
				formState={formState}
				classList="mb-4"
				disabled={!allowedActions.canEditName}
				rightContent={
					checkingNameUniqueness ? <CircularProgressBar classList="w-3" value="indeterminate" /> : undefined
				}
			/>
			<div className="flex gap-2 items-start mb-4">
				<FormFields.Select
					name="fieldType"
					control={control}
					label={({ htmlFor }) => (
						<Row alignItems="center" gap={8}>
							<Label htmlFor={htmlFor} classList="mb-px">
								Column type
							</Label>

							<InfoTooltip>Choose the data format for the new column.</InfoTooltip>
						</Row>
					)}
					formState={formState}
					options={
						[
							{ value: "QUALITY", label: "Description" },
							{ value: "SCORE", label: "Number" },
							{ value: "TAG", label: "Tag" },
						] satisfies Array<Option<InstrumentFieldType>>
					}
					data-qualifier={qualifier.component.input.select("List/ColumnType")}
					triggerDataAttrs={{ "data-qualifier": qualifier.component.input.select("ColumnType") }}
					classList="flex-1"
					disabled={!allowedActions.canEditType}
					onChange={(newFieldType: InstrumentFieldType) => {
						reset(
							{
								fieldType: newFieldType,
								dataVisualization: newFieldType === "TAG" ? DataVisualization.TagViewWithoutColor : undefined,
							},
							{ keepDirtyValues: true },
						);
					}}
				/>
				{observedClassification.fieldType === "TAG" && (
					<FormFields.Select
						name="tagType"
						size="small"
						control={control}
						label={({ htmlFor }) => (
							<Row alignItems="center" gap={8}>
								<Label htmlFor={htmlFor} classList="mb-px">
									Tag type
								</Label>

								<InfoTooltip>Define how this tag will interact with Portfolio Constraints.</InfoTooltip>
							</Row>
						)}
						data-qualifier={qualifier.component.input.select("List/TagType")}
						triggerDataAttrs={{ "data-qualifier": qualifier.component.input.select("TagType") }}
						formState={formState}
						disabled={!allowedActions.canEditTagType}
						options={
							[
								{ value: "ASSET_CLASS", label: "Asset Class" },
								{ value: "CURRENCY", label: "Currency" },
								{ value: "GENERAL", label: "General" },
							] satisfies Array<Option<TagType>>
						}
						classList="flex-1"
					/>
				)}
			</div>

			{observedClassification.fieldType === "TAG" && (
				<>
					<FormField
						label={({ htmlFor }) => (
							<Row alignItems="center" gap={8}>
								<Label htmlFor={htmlFor} classList="mb-px">
									Column style
								</Label>

								<InfoTooltip>Select how the column data will be visually displayed in the table.</InfoTooltip>
							</Row>
						)}
					>
						{() => (
							<FormController
								control={control}
								disabled={!allowedActions.canEditVisualization}
								name="dataVisualization"
								data-qualifier={qualifier.component.input.select("TagType")}
								render={({ field: { value, onChange } }) => (
									<ButtonGroupRadio
										options={[
											{
												value: DataVisualization.BarChart,
												children: "Bar Chart",
												"data-qualifier": qualifier.component.radioGroupButton.option("BarChart"),
											},
											{
												value: DataVisualization.TagViewWithColor,
												children: "Color Tag",
												"data-qualifier": qualifier.component.radioGroupButton.option("ColorTag"),
											},
											{
												value: DataVisualization.TagViewWithoutColor,
												children: "Neutral Tag",
												"data-qualifier": qualifier.component.radioGroupButton.option("NeutralTag"),
											},
										]}
										onChange={onChange}
										value={value}
									/>
								)}
							/>
						)}
					</FormField>
					<hr className="my-4 -mx-4" />
					<InsertTagOptions classification={observedClassification} control={control} allowedActions={allowedActions} />
				</>
			)}
		</FormSidebarContent>
	);
}

const allowedOption = /^[a-zA-Z0-9 ]{1,60}$/;
type OptionStatus = "DUPLICATED" | "INVALID_FORMAT" | "VALID";
function InsertTagOptions(props: {
	control: Control<InstrumentClassificationForm, any>;
	classification: InstrumentClassificationForm;
	allowedActions: ClassificationAllowedAction;
}) {
	const { control, allowedActions } = props;
	const [optionName, setOptionName] = useState("");
	const [optionStatus, setOptionStatus] = useState<OptionStatus>("VALID");
	const [colorIndex, setColorIndex] = useState(0);

	const classificationOptionsControl = useFieldArray<InstrumentClassificationForm, "options", "id">({
		control,
		name: "options",
		keyName: "id",
	});

	function resetSearch() {
		setOptionName("");
		setOptionStatus("VALID");
	}

	const filteredOptions = useMemo(
		() =>
			classificationOptionsControl.fields.filter(({ option }) => {
				const textLower = option?.toLowerCase();
				const words = optionName.toLowerCase().split(" ");
				return words.every((word) => textLower?.includes(word));
			}),
		[classificationOptionsControl.fields, optionName],
	);

	const insertTypedOption = useCallback(
		(exist: boolean) => {
			if (exist) {
				setOptionStatus("DUPLICATED");
				return;
			}

			const isValidOption = allowedOption.test(optionName);
			if (!isValidOption) {
				setOptionStatus("INVALID_FORMAT");
				return;
			}
			classificationOptionsControl.insert(0, {
				color: colors[colorIndex].key,
				option: optionName,
				canEdit: true,
			});
			setColorIndex((index) => {
				if (index + 1 > colors.length - 1) {
					return 0;
				}

				return index + 1;
			});
			resetSearch();
		},
		[classificationOptionsControl, colorIndex, optionName],
	);

	const optionNameExist = useCallback(
		(name: string, fields: OptionModelDto[]) =>
			fields.some(({ option }) => option?.toLowerCase() === name.toLowerCase()),
		[],
	);

	const validateOptionStatus = useCallback(
		(params: { newOption: string; optionStatus: OptionStatus; fields: OptionModelDto[] }) => {
			if (params.optionStatus === "INVALID_FORMAT") {
				const isValidOption = allowedOption.test(params.newOption);
				if (isValidOption) {
					setOptionStatus("VALID");
				}
				return;
			}

			if (params.optionStatus === "DUPLICATED") {
				const exist = optionNameExist(params.newOption, params.fields);
				if (!exist) {
					setOptionStatus("VALID");
				}
			}
		},
		[optionNameExist],
	);

	return (
		<>
			<Label htmlFor="" classList="mb-px">
				Add options
			</Label>
			<div className="flex space-x-2">
				<TextInput
					size="small"
					disabled={!allowedActions.canAddOption}
					value={optionName}
					onChangeText={(newOption) => {
						validateOptionStatus({ newOption, fields: classificationOptionsControl.fields, optionStatus });
						setOptionName(newOption);
					}}
					classList={{ "mb-2 flex-1": true }}
					inputAppearance={{
						classList: {
							[`!border-[color:${themeCSSVars.palette_S200}] animate-tremble`]: optionStatus !== "VALID",
						},
					}}
					placeholder="Type a new option..."
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							const exist = optionNameExist(optionName, classificationOptionsControl.fields);
							insertTypedOption(exist);
						}
					}}
					onKeyDownCapture={preventSubmitOnPressEnter}
					rightContent={
						optionName ? (
							<TinyIconButton
								size={16}
								icon="Cancel"
								onClick={() => resetSearch()}
								hoverBackground=""
								classList="!p-0"
								color={themeCSSVars.palette_N400}
							/>
						) : undefined
					}
					data-qualifier={qualifier.component.input.text("SearchOptionToAdd")}
				/>
				<Button
					palette="secondary"
					size="small"
					onClick={() => {
						const exist = optionNameExist(optionName, classificationOptionsControl.fields);
						insertTypedOption(exist);
					}}
					disabled={optionStatus !== "VALID" || !allowedActions.canAddOption}
				>
					<Icon icon="Outline1" />
				</Button>
			</div>
			<OptionStatusBanner status={optionStatus} />
			<div className="flex-1 min-h-0 overflow-auto scroll-smooth">
				<DraggableList
					itemKey={(field) => field.id}
					items={filteredOptions}
					onReorder={(fields) => classificationOptionsControl.replace(fields)}
					disabled={!allowedActions.canEditOptionOrder}
				>
					{({ index, item, isDragging, draggableProps, innerRef, dragHandleProps }) => (
						<div
							style={{ borderColor: themeCSSVars.palette_N50 }}
							className={toClassName({
								"relative flex flex-row items-center min-w-0 bg-white gap-2 min-h-[48px] h-12 transition-all": true,
								"px-2": !optionName,
								"shadow-lg": isDragging,
							})}
							{...draggableProps}
							ref={innerRef}
						>
							<div
								{...dragHandleProps}
								className={toClassName({
									invisible: !draggableProps || !dragHandleProps,
									hidden: Boolean(optionName) || !allowedActions.canEditOptionOrder,
								})}
							>
								<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M6.04181 3.2944L7.96096 1.37506C8.01677 1.31944 8.10704 1.31944 8.16286 1.37506L10.082 3.2944C10.1228 3.3352 10.1349 3.3966 10.113 3.44991C10.0909 3.50323 10.0389 3.53807 9.98115 3.53807H6.14267C6.08493 3.53807 6.03277 3.50323 6.01083 3.44991C5.98888 3.3966 6.00101 3.3352 6.04181 3.2944ZM8.16305 14.8297C8.10724 14.8853 8.01697 14.8853 7.96115 14.8297L6.04181 12.9103C6.00101 12.8695 5.98888 12.8081 6.01083 12.7548C6.03296 12.7015 6.08512 12.6667 6.14286 12.6667H9.98134C10.0391 12.6667 10.091 12.7015 10.1132 12.7548C10.1351 12.8081 10.123 12.8695 10.0822 12.9103L8.16305 14.8297ZM14.3446 6.00001H1.97822C1.62186 6.00001 1.33337 6.29415 1.33337 6.65052C1.33337 7.00688 1.33337 7.30102 1.97822 7.30102H14.3333C14.701 7.30102 14.9895 7.01254 14.9895 6.65052C14.9895 6.28849 14.701 6.00001 14.3446 6.00001ZM1.97822 9.33334H14.3446C14.701 9.33334 14.9895 9.62183 14.9895 9.98385C14.9895 10.3459 14.701 10.6344 14.3333 10.6344H1.97822C1.33337 10.6344 1.33337 10.3402 1.33337 9.98385C1.33337 9.62748 1.62186 9.33334 1.97822 9.33334Z"
										fill="#8792AB"
									/>
								</svg>
							</div>
							<ClickableOption
								allowedActions={allowedActions}
								item={item}
								option={optionName}
								onClose={(params) => {
									if (!params.newOption.option) {
										return "INVALID_FORMAT";
									}

									const isValidOption = allowedOption.test(params.newOption.option);
									if (!isValidOption) {
										return "INVALID_FORMAT";
									}

									const exist = optionNameExist(params.newOption.option, classificationOptionsControl.fields);
									if (exist && item.option !== params.newOption.option) {
										return "DUPLICATED";
									}
									classificationOptionsControl.update(index, params.newOption);
									params.close();
									return "VALID";
								}}
							/>
							{(allowedActions.canRemoveOption || item.canEdit) && (
								<TinyIconButton
									icon="Delete"
									onClick={() => classificationOptionsControl.remove(index)}
									classList="ml-auto"
									color={themeCSSVars.palette_P400}
									data-qualifier={qualifier.component.button(`Delete/item(${index}, ${item.option})`)}
								/>
							)}
						</div>
					)}
				</DraggableList>
			</div>
		</>
	);
}

function OptionStatusBanner({ status }: { status: OptionStatus }) {
	return status === "DUPLICATED" ? (
		<Banner severity="info" classList="mb-2">
			Option already exist
		</Banner>
	) : status === "INVALID_FORMAT" ? (
		<Banner severity="info" classList="mb-2" title="Please follow these rules for valid name entry">
			<ul style={{ listStyleType: "disc" }}>
				<ForEach
					collection={[
						"1-60 characters long",
						"Allowed: Letters, numbers, spaces",
						"No special characters like @, #, $, %",
					]}
				>
					{({ item }) => <li>{item}</li>}
				</ForEach>
			</ul>
		</Banner>
	) : (
		<></>
	);
}

function ClickableOption({
	item,
	option,
	allowedActions,
	onClose,
}: {
	item: EditableInstrumentClassificationOption;
	option: string;
	onClose(params: { newOption: EditableInstrumentClassificationOption; close(): void }): OptionStatus;
	allowedActions: ClassificationAllowedAction;
}) {
	const [isOpen, setIsOpen] = useState(false);
	const [internalOption, setInternalOption] = useState(item);
	const [optionStatus, setOptionStatus] = useState<OptionStatus>("VALID");

	return (
		<FloatingContent
			position="bottom"
			align="startToStart"
			triggerLocation="behind"
			open={isOpen}
			onClickAway={() =>
				setOptionStatus(
					onClose({
						newOption: internalOption,
						close() {
							setIsOpen(false);
						},
					}),
				)
			}
			trigger={({ innerRef }) => (
				<ClickableDiv
					innerRef={innerRef}
					onClick={() => setIsOpen(true)}
					classList={{
						"px-2 py-1.5 rounded": true,
						"ml-2": !option,
					}}
					style={{ backgroundColor: item.color ? colorMap[item.color].badgeColor() : colorMap.GRAY.badgeColor() }}
				>
					<Text type="Body/M/Medium">{item.option}</Text>
				</ClickableDiv>
			)}
			relativePositionOffsets={{ bottom: -8, top: 8 }}
			relativeAlignmentOffsets={{ startToStart: -8 }}
		>
			{({ triggerBox, position }) => (
				<Column
					classList={{
						"pt-2 pb-2 px-2 shadow-[0px_8px_32px_rgba(0,0,0,0.16)] rounded bg-white flex flex-col flex-1 min-h-0 overflow-hidden":
							true,
					}}
					style={{
						minHeight: triggerBox.height,
						minWidth: triggerBox.width,
						maxHeight: 270,
						width: 225,
					}}
					gap={8}
					reverse={position === "top"}
				>
					<TextInput
						disabled={!allowedActions.canEditOptionName && !item.canEdit}
						size="small"
						value={internalOption.option ?? ""}
						onChangeText={(name) => setInternalOption((latestOption) => ({ ...latestOption, option: name }))}
						classList={{
							"flex-1": true,
							[`!border-[color:${themeCSSVars.palette_S200}] animate-tremble`]: optionStatus !== "VALID",
						}}
						placeholder="Type a new option..."
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								setOptionStatus(
									onClose({
										newOption: internalOption,
										close() {
											setIsOpen(false);
										},
									}),
								);
							}
						}}
						rightContent={
							internalOption.option !== item.option ? (
								<TinyIconButton
									size={14}
									icon="restore"
									onClick={() => setInternalOption((latestOption) => ({ ...latestOption, option: item.option }))}
									hoverBackground=""
									classList="!p-0"
									color={themeCSSVars.palette_N400}
								/>
							) : undefined
						}
						data-qualifier={qualifier.component.input.text("RenameOption")}
					/>
					<OptionStatusBanner status={optionStatus} />
					<div className="min-h-0 flex-1 flex flex-col -mx-2">
						<ScrollWrapper direction="vertical" outerContainerAppearance={{ classList: "flex-1" }}>
							<ForEach collection={colors}>
								{({ item: { badgeColor, label, key } }) => (
									<ClickableDiv
										disabled={!allowedActions.canEditOptionColor}
										classList={`flex space-x-2 px-2 py-2 items-center hover:bg-[color:${themeCSSVars.palette_P50}]`}
										onClick={() => setInternalOption((latestOption) => ({ ...latestOption, color: key }))}
									>
										<div
											className="rounded h-6 w-6 flex items-center justify-center"
											style={{ backgroundColor: badgeColor() }}
										>
											{internalOption.color === key && (
												<Icon icon="Outline" size={14} color={themeCSSVars.palette_N600} />
											)}
										</div>
										<Text type="Body/M/Medium">{label}</Text>
									</ClickableDiv>
								)}
							</ForEach>
						</ScrollWrapper>
					</div>
				</Column>
			)}
		</FloatingContent>
	);
}

type DistributiveOmit<T, K extends keyof T> = T extends unknown ? Omit<T, K> : never;
type SpawnInstrumentCustomizationSidePanelProps = DistributiveOmit<
	InstrumentCustomizationSidePanelProps,
	"onCancel" | "setCanDismiss"
>;
export function spawnInstrumentCustomizationSidePanel(
	params: {
		signal?: AbortSignal;
	} & SpawnInstrumentCustomizationSidePanelProps,
): Promise<void> {
	return spawnSidePanel({
		signal: params.signal,
		children(props) {
			return match(params)
				.with({ mode: "edit" }, (castParams) => (
					<InstrumentCustomizationSidePanel
						mode={castParams.mode}
						existingColumnNames={castParams.existingColumnNames}
						classification={castParams.classification}
						onSubmitAsync={async (classification) => {
							await castParams.onSubmitAsync(classification);
							props.resolve();
						}}
						onCancel={props.reject}
						setCanDismiss={props.setCanDismiss}
					/>
				))
				.with({ mode: "creation" }, (castParams) => (
					<InstrumentCustomizationSidePanel
						mode={castParams.mode}
						existingColumnNames={castParams.existingColumnNames}
						onSubmitAsync={async (classification) => {
							await castParams.onSubmitAsync(classification);
							props.resolve();
						}}
						onCancel={props.reject}
						setCanDismiss={props.setCanDismiss}
					/>
				))
				.exhaustive();
		},
	});
}

export default InstrumentCustomizationSidePanel;
