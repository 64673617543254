import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./languages/en.json";
import it from "./languages/it.json";
import de from "./languages/de.json";
import fr from "./languages/fr.json";
import es from "./languages/es.json";

export type Languages = "it" | "en" | "fr" | "de" | "es";
export const defaultNS = "default";

export const resources = {
	en,
	it,
	de,
	fr,
	es,
} satisfies Record<Languages, unknown>;

export async function initI18n(): Promise<void> {
	const defaultLang = "en" as const;
	await i18n
		.use(initReactI18next) // passes i18n down to react-i18next
		.init({
			lng: defaultLang,
			supportedLngs: Object.keys(resources),
			fallbackLng: defaultLang,
			resources,
			interpolation: {
				escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
			},
		});

	document.documentElement.setAttribute("lang", defaultLang);

	i18n.on("languageChanged", (lang) => {
		document.documentElement.setAttribute("lang", lang);
	});
}
