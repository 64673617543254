import type { InvestmentsReportTemplate } from "$root/api/api-gen";
import type { CompositionColumnPreference } from "$root/pages/PortfolioStudioSettings/ReportEditor/report-latest";
import {
	type ReportTemplateStatus,
	type ReportTemplateVariant,
	type ReportTemplateItem,
	type ReportTemplateItemMap,
	latestVersion,
} from "$root/pages/PortfolioStudioSettings/ReportEditor/report-latest";
import { endingPageFallback } from "$root/pages/PortfolioStudioSettings/ReportEditor/ending-page-fallback";

import { generateUniqueDOMId } from "@mdotm/mdotui/react-extensions";

export const DefaultReportTemplateName = {
	sphere: "sphere",
	custom: "custom",
} as const;

export type DefaultReportTemplateName = (typeof DefaultReportTemplateName)[keyof typeof DefaultReportTemplateName];

export type DefaultReportTemplate = {
	id: DefaultReportTemplateName;
	templateName: string;
	format: "pdf" | "xlsx";
	visible: boolean;
	data: {
		status: ReportTemplateStatus;
		version: number;
		templateItemsByVariant: Record<ReportTemplateVariant, Array<ReportTemplateItem>>;
		templateDraftItemsByVariant: Record<ReportTemplateVariant, Array<ReportTemplateItem>>;
	};
};
export const defaultTemplateName = "Untitled template";

const baseCompositionColumn = {
	name: {
		enabled: true,
		preferenceType: {
			classificationUuid: "NAME",
			label: "Name",
		},
	},
	weight: {
		enabled: true,
		preferenceType: {
			classificationUuid: "WEIGHT",
			label: "Weight",
		},
	},
	description: {
		enabled: true,
		preferenceType: {
			classificationUuid: "DESCRIPTION",
			label: "Description",
		},
	},
	enhancedWeight: {
		enabled: true,
		preferenceType: {
			classificationUuid: "ENHANCED_WEIGHT",
			label: "Enhanced weight",
		},
	},
	identifier: {
		enabled: true,
		preferenceType: {
			classificationUuid: "IDENTIFIER",
			label: "Identifier",
		},
	},
	difference: {
		enabled: true,
		preferenceType: {
			classificationUuid: "DIFFERENCE",
			label: "Difference",
		},
	},
} satisfies Record<string, CompositionColumnPreference>;

const defaultPortfolioMetricsPreferences = [
	{
		enabled: true,
		metricType: "VOLATILITY_SINCE_INCEPTION",
	},
	{
		enabled: true,
		metricType: "VOLATILITY_YTD",
	},
	{
		enabled: true,
		metricType: "VOLATILITY_1M",
	},
	{
		enabled: true,
		metricType: "VOLATILITY_3M",
	},
	{
		enabled: true,
		metricType: "VOLATILITY_6M",
	},
	{
		enabled: true,
		metricType: "VOLATILITY_1Y",
	},
	{
		enabled: true,
		metricType: "EFFICIENCY_RATIO_SINCE_INCEPTION",
	},
	{
		enabled: true,
		metricType: "EFFICIENCY_RATIO_YTD",
	},
	{
		enabled: true,
		metricType: "EFFICIENCY_RATIO_1M",
	},
	{
		enabled: true,
		metricType: "EFFICIENCY_RATIO_3M",
	},
	{
		enabled: true,
		metricType: "EFFICIENCY_RATIO_6M",
	},
	{
		enabled: true,
		metricType: "EFFICIENCY_RATIO_1Y",
	},
	{
		enabled: true,
		metricType: "MAX_DRAWDOWN_SINCE_INCEPTION",
	},
	{
		enabled: true,
		metricType: "MAX_DRAWDOWN_YTD",
	},
	{
		enabled: true,
		metricType: "MAX_DRAWDOWN_1M",
	},
	{
		enabled: true,
		metricType: "MAX_DRAWDOWN_3M",
	},
	{
		enabled: true,
		metricType: "MAX_DRAWDOWN_6M",
	},
	{
		enabled: true,
		metricType: "MAX_DRAWDOWN_1Y",
	},
	{
		enabled: true,
		metricType: "PERFORMANCE_SINCE_INCEPTION",
	},
	{
		enabled: true,
		metricType: "PERFORMANCE_YTD",
	},
	{
		enabled: true,
		metricType: "PERFORMANCE_1M",
	},
	{
		enabled: true,
		metricType: "PERFORMANCE_3M",
	},
	{
		enabled: true,
		metricType: "PERFORMANCE_6M",
	},
	{
		enabled: true,
		metricType: "PERFORMANCE_1Y",
	},
	{
		enabled: true,
		metricType: "SORTINO_SINCE_INCEPTION",
	},
	{
		enabled: true,
		metricType: "SORTINO_YTD",
	},
	{
		enabled: true,
		metricType: "SORTINO_1M",
	},
	{
		enabled: true,
		metricType: "SORTINO_3M",
	},
	{
		enabled: true,
		metricType: "SORTINO_6M",
	},
	{
		enabled: true,
		metricType: "SORTINO_1Y",
	},
] satisfies ReportTemplateItemMap["portfolioMetrics"]["preferences"];

const sphereTemplateDefault: Record<ReportTemplateVariant, Array<ReportTemplateItem>> = {
	current: [
		{
			id: generateUniqueDOMId(),
			kind: "cover",
			title: "PORTFOLIO DETAILS",
		},
		{
			id: generateUniqueDOMId(),
			kind: "summary",
			averageScore: true,
			baseCurrency: true,
			benchmark: true,
			portfolioName: true,
			reference: true,
			universe: true,
		},
		{
			id: generateUniqueDOMId(),
			kind: "portfolioMetrics",
			showBenchmark: true,
			preferences: defaultPortfolioMetricsPreferences,
		},
		{
			id: generateUniqueDOMId(),
			kind: "exAntePortfolioMetrics",
			benchmark: true,
			efficiencyRatio: {
				EFFICIENCY_RATIO_3Y: true,
			},
			maxDrawdown: {
				MAX_DRAWDOWN_3Y: true,
			},
			diversificationRatio: {
				DIVERSIFICATION_RATIO_3Y: true,
			},
			historicalVar: {
				HISTORICAL_VAR_95_3Y: true,
				// HISTORICAL_VAR_95_2Y: true,
				// HISTORICAL_VAR_95_3Y: true,
				// HISTORICAL_VAR_975_1Y: true,
				// HISTORICAL_VAR_975_2Y: true,
				// HISTORICAL_VAR_975_3Y: true,
				// HISTORICAL_VAR_99_1Y: true,
				// HISTORICAL_VAR_99_2Y: true,
				// HISTORICAL_VAR_99_3Y: true,
			},
			// parametricVar: {
			// 	PARAMETRIC_VAR_95_1Y: true,
			// 	PARAMETRIC_VAR_975_1Y: true,
			// 	PARAMETRIC_VAR_99_1Y: true,
			// 	PARAMETRIC_VAR_95_2Y: true,
			// 	PARAMETRIC_VAR_975_2Y: true,
			// 	PARAMETRIC_VAR_99_2Y: true,
			// 	PARAMETRIC_VAR_95_3Y: true,
			// 	PARAMETRIC_VAR_975_3Y: true,
			// 	PARAMETRIC_VAR_99_3Y: true,
			// },
			return: { RETURN_3Y: true },
			trackingError: { TRACKING_ERROR_3Y: true },
			volatility: { VOLATILITY_3Y: true },
			riskConstraint: { TRACKING_ERROR: true, VAR: true, VOLATILITY: true },
		},
		{
			id: generateUniqueDOMId(),
			kind: "performance",
			benchmark: true,
		},
		{
			id: generateUniqueDOMId(),
			kind: "commentary",
			useTemplateNameAsTitle: true,
		},
		{
			id: generateUniqueDOMId(),
			kind: "composition",
			currentColumnPreferences: [
				baseCompositionColumn.name,
				baseCompositionColumn.identifier,
				baseCompositionColumn.weight,
			],
			proposalColumnPreferences: [
				baseCompositionColumn.name,
				baseCompositionColumn.identifier,
				baseCompositionColumn.weight,
				baseCompositionColumn.enhancedWeight,
				baseCompositionColumn.difference,
			],
		},
		{
			id: generateUniqueDOMId(),
			kind: "exposure",
			selectedCategories: [
				{
					label: "MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS",
					type: "RISK_MODEL",
					value: "MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS",
				},
				{
					label: "MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY",
					type: "RISK_MODEL",
					value: "MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY",
				},
			],
		},
		{
			id: generateUniqueDOMId(),
			kind: "performanceAttribution",
			FROM_INCEPTION: false,
			YEAR_TO_DATE: true,
			ONE_MONTH: false,
			THREE_MONTHS: false,
			SIX_MONTHS: false,
			ONE_YEAR: false,
		},
		{
			id: generateUniqueDOMId(),
			kind: "exAnteContributionVolatility",
			versus: {
				MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS: true,
				MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: true,
				MACRO_GEOGRAPHY_VS_MACRO_ASSET_CLASS: false,
				MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: false,
				MACRO_GEOGRAPHY_VS_MICRO_ASSET_CLASS: false,
			},
			filterSignificant: true,
		},
		{
			id: generateUniqueDOMId(),
			kind: "performanceAndVolatilityContribution",
			versus: {
				MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS: true,
				MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: true,
				MACRO_GEOGRAPHY_VS_MACRO_ASSET_CLASS: false,
				MACRO_GEOGRAPHY_VS_MICRO_ASSET_CLASS: false,
				MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: false,
			},
			filterSignificant: true,
		},
		{
			id: generateUniqueDOMId(),
			kind: "factorExposure",
			benchmark: true,
			filterSignificant: true,
		},
		{
			id: generateUniqueDOMId(),
			kind: "disclaimer",
			contact: {
				description: `${endingPageFallback.address}\n\n\n${endingPageFallback.mail}\n${endingPageFallback.website}`,
				enabled: true,
			},
			content: {
				title: endingPageFallback.disclaimer.title,
				description: endingPageFallback.disclaimer.description,
				enabled: true,
			},
		},
	],
	proposal: [
		{
			id: generateUniqueDOMId(),
			kind: "cover",
			title: "PORTFOLIO DETAILS",
		},
		{
			id: generateUniqueDOMId(),
			kind: "summary",
			averageScore: true,
			baseCurrency: true,
			benchmark: true,
			portfolioName: true,
			reference: true,
			universe: true,
		},
		{
			id: generateUniqueDOMId(),
			kind: "exAntePortfolioMetrics",
			benchmark: true,
			efficiencyRatio: {
				EFFICIENCY_RATIO_3Y: true,
			},
			maxDrawdown: {
				MAX_DRAWDOWN_3Y: true,
			},
			diversificationRatio: {
				DIVERSIFICATION_RATIO_3Y: true,
			},
			historicalVar: {
				// HISTORICAL_VAR_95_1Y: true,
				// HISTORICAL_VAR_95_2Y: true,
				// HISTORICAL_VAR_95_3Y: true,
				// HISTORICAL_VAR_975_1Y: true,
				// HISTORICAL_VAR_975_2Y: true,
				// HISTORICAL_VAR_975_3Y: true,
				// HISTORICAL_VAR_99_1Y: true,
				// HISTORICAL_VAR_99_2Y: true,
				// HISTORICAL_VAR_99_3Y: true,
				HISTORICAL_VAR_95_3Y: true,
			},
			// parametricVar: {
			// 	PARAMETRIC_VAR_95_1Y: true,
			// 	PARAMETRIC_VAR_975_1Y: true,
			// 	PARAMETRIC_VAR_99_1Y: true,
			// 	PARAMETRIC_VAR_95_2Y: true,
			// 	PARAMETRIC_VAR_975_2Y: true,
			// 	PARAMETRIC_VAR_99_2Y: true,
			// 	PARAMETRIC_VAR_95_3Y: true,
			// 	PARAMETRIC_VAR_975_3Y: true,
			// 	PARAMETRIC_VAR_99_3Y: true,
			// },
			return: { RETURN_3Y: true },
			trackingError: { TRACKING_ERROR_3Y: true },
			volatility: { VOLATILITY_3Y: true },
			// VOLATILITY_1Y: true, VOLATILITY_2Y: true,
			riskConstraint: { TRACKING_ERROR: true, VAR: true, VOLATILITY: true },
		},
		{
			id: generateUniqueDOMId(),
			kind: "commentary",
			useTemplateNameAsTitle: true,
		},
		{
			id: generateUniqueDOMId(),
			kind: "composition",
			currentColumnPreferences: [
				baseCompositionColumn.name,
				baseCompositionColumn.identifier,
				baseCompositionColumn.weight,
			],
			proposalColumnPreferences: [
				baseCompositionColumn.name,
				baseCompositionColumn.identifier,
				baseCompositionColumn.weight,
				baseCompositionColumn.enhancedWeight,
				baseCompositionColumn.difference,
			],
		},
		{
			id: generateUniqueDOMId(),
			kind: "exposure",
			selectedCategories: [
				{
					label: "MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS",
					type: "RISK_MODEL",
					value: "MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS",
				},
				{
					label: "MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY",
					type: "RISK_MODEL",
					value: "MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY",
				},
			],
		},
		{
			id: generateUniqueDOMId(),
			kind: "exAnteContributionVolatility",
			versus: {
				MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS: true,
				MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: true,
				MACRO_GEOGRAPHY_VS_MACRO_ASSET_CLASS: false,
				MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: false,
				MACRO_GEOGRAPHY_VS_MICRO_ASSET_CLASS: false,
			},
			filterSignificant: true,
		},
		{
			id: generateUniqueDOMId(),
			kind: "factorExposure",
			benchmark: true,
			filterSignificant: true,
		},
		{
			id: generateUniqueDOMId(),
			kind: "disclaimer",
			contact: {
				description: `${endingPageFallback.address}\n\n\n${endingPageFallback.mail}\n${endingPageFallback.website}`,
				enabled: true,
			},
			content: {
				title: endingPageFallback.disclaimer.title,
				description: endingPageFallback.disclaimer.description,
				enabled: true,
			},
		},
	],
};

const fallbackTemplate: Record<ReportTemplateVariant, Array<ReportTemplateItem>> = {
	current: [
		{
			id: generateUniqueDOMId(),
			kind: "cover",
			title: "PORTFOLIO DETAILS",
		},
		{
			id: generateUniqueDOMId(),
			kind: "summary",
			averageScore: true,
			baseCurrency: true,
			benchmark: true,
			portfolioName: true,
			reference: true,
			universe: true,
		},
		{
			id: generateUniqueDOMId(),
			kind: "portfolioMetrics",
			showBenchmark: true,
			preferences: defaultPortfolioMetricsPreferences,
		},
		{
			id: generateUniqueDOMId(),
			kind: "exAntePortfolioMetrics",
			benchmark: true,
			efficiencyRatio: {
				EFFICIENCY_RATIO_3Y: true,
			},
			maxDrawdown: {
				MAX_DRAWDOWN_3Y: true,
			},
			diversificationRatio: {
				DIVERSIFICATION_RATIO_3Y: true,
			},
			historicalVar: {
				HISTORICAL_VAR_95_3Y: true,
				// HISTORICAL_VAR_95_2Y: true,
				// HISTORICAL_VAR_95_3Y: true,
				// HISTORICAL_VAR_975_1Y: true,
				// HISTORICAL_VAR_975_2Y: true,
				// HISTORICAL_VAR_975_3Y: true,
				// HISTORICAL_VAR_99_1Y: true,
				// HISTORICAL_VAR_99_2Y: true,
				// HISTORICAL_VAR_99_3Y: true,
			},
			// parametricVar: {
			// 	PARAMETRIC_VAR_95_1Y: true,
			// 	PARAMETRIC_VAR_975_1Y: true,
			// 	PARAMETRIC_VAR_99_1Y: true,
			// 	PARAMETRIC_VAR_95_2Y: true,
			// 	PARAMETRIC_VAR_975_2Y: true,
			// 	PARAMETRIC_VAR_99_2Y: true,
			// 	PARAMETRIC_VAR_95_3Y: true,
			// 	PARAMETRIC_VAR_975_3Y: true,
			// 	PARAMETRIC_VAR_99_3Y: true,
			// },
			return: { RETURN_3Y: true },
			trackingError: { TRACKING_ERROR_3Y: true },
			volatility: { VOLATILITY_3Y: true },
			riskConstraint: { TRACKING_ERROR: true, VAR: true, VOLATILITY: true },
		},
		{
			id: generateUniqueDOMId(),
			kind: "performance",
			benchmark: true,
		},
		{
			id: generateUniqueDOMId(),
			kind: "commentary",
			useTemplateNameAsTitle: true,
		},
		{
			id: generateUniqueDOMId(),
			kind: "composition",
			currentColumnPreferences: [
				baseCompositionColumn.name,
				baseCompositionColumn.identifier,
				baseCompositionColumn.weight,
			],
			proposalColumnPreferences: [
				baseCompositionColumn.name,
				baseCompositionColumn.identifier,
				baseCompositionColumn.weight,
				baseCompositionColumn.enhancedWeight,
				baseCompositionColumn.difference,
			],
		},
		{
			id: generateUniqueDOMId(),
			kind: "exposure",
			selectedCategories: [
				{
					label: "MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS",
					type: "RISK_MODEL",
					value: "MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS",
				},
				{
					label: "MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY",
					type: "RISK_MODEL",
					value: "MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY",
				},
			],
		},
		{
			id: generateUniqueDOMId(),
			kind: "performanceAttribution",
			FROM_INCEPTION: false,
			YEAR_TO_DATE: true,
			ONE_MONTH: false,
			THREE_MONTHS: false,
			SIX_MONTHS: false,
			ONE_YEAR: false,
		},
		{
			id: generateUniqueDOMId(),
			kind: "exAnteContributionVolatility",
			versus: {
				MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS: true,
				MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: true,
				MACRO_GEOGRAPHY_VS_MACRO_ASSET_CLASS: false,
				MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: false,
				MACRO_GEOGRAPHY_VS_MICRO_ASSET_CLASS: false,
			},
			filterSignificant: true,
		},
		{
			id: generateUniqueDOMId(),
			kind: "performanceAndVolatilityContribution",
			versus: {
				MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS: true,
				MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: true,
				MACRO_GEOGRAPHY_VS_MACRO_ASSET_CLASS: false,
				MACRO_GEOGRAPHY_VS_MICRO_ASSET_CLASS: false,
				MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: false,
			},
			filterSignificant: true,
		},
		{
			id: generateUniqueDOMId(),
			kind: "factorExposure",
			benchmark: true,
			filterSignificant: true,
		},
		{
			id: generateUniqueDOMId(),
			kind: "disclaimer",
			contact: { enabled: false },
			content: { enabled: false },
		},
	],
	proposal: [
		{
			id: generateUniqueDOMId(),
			kind: "cover",
			title: "PORTFOLIO DETAILS",
		},
		{
			id: generateUniqueDOMId(),
			kind: "summary",
			averageScore: true,
			baseCurrency: true,
			benchmark: true,
			portfolioName: true,
			reference: true,
			universe: true,
		},
		{
			id: generateUniqueDOMId(),
			kind: "exAntePortfolioMetrics",
			benchmark: true,
			efficiencyRatio: {
				EFFICIENCY_RATIO_3Y: true,
			},
			maxDrawdown: {
				MAX_DRAWDOWN_3Y: true,
			},
			diversificationRatio: {
				DIVERSIFICATION_RATIO_3Y: true,
			},
			historicalVar: {
				// HISTORICAL_VAR_95_1Y: true,
				// HISTORICAL_VAR_95_2Y: true,
				// HISTORICAL_VAR_95_3Y: true,
				// HISTORICAL_VAR_975_1Y: true,
				// HISTORICAL_VAR_975_2Y: true,
				// HISTORICAL_VAR_975_3Y: true,
				// HISTORICAL_VAR_99_1Y: true,
				// HISTORICAL_VAR_99_2Y: true,
				// HISTORICAL_VAR_99_3Y: true,
				HISTORICAL_VAR_95_3Y: true,
			},
			// parametricVar: {
			// 	PARAMETRIC_VAR_95_1Y: true,
			// 	PARAMETRIC_VAR_975_1Y: true,
			// 	PARAMETRIC_VAR_99_1Y: true,
			// 	PARAMETRIC_VAR_95_2Y: true,
			// 	PARAMETRIC_VAR_975_2Y: true,
			// 	PARAMETRIC_VAR_99_2Y: true,
			// 	PARAMETRIC_VAR_95_3Y: true,
			// 	PARAMETRIC_VAR_975_3Y: true,
			// 	PARAMETRIC_VAR_99_3Y: true,
			// },
			return: { RETURN_3Y: true },
			trackingError: { TRACKING_ERROR_3Y: true },
			volatility: { VOLATILITY_3Y: true },
			// VOLATILITY_1Y: true, VOLATILITY_2Y: true,
			riskConstraint: { TRACKING_ERROR: true, VAR: true, VOLATILITY: true },
		},
		{
			id: generateUniqueDOMId(),
			kind: "commentary",
			useTemplateNameAsTitle: true,
		},
		{
			id: generateUniqueDOMId(),
			kind: "composition",
			currentColumnPreferences: [
				baseCompositionColumn.name,
				baseCompositionColumn.identifier,
				baseCompositionColumn.weight,
			],
			proposalColumnPreferences: [
				baseCompositionColumn.name,
				baseCompositionColumn.identifier,
				baseCompositionColumn.weight,
				baseCompositionColumn.enhancedWeight,
				baseCompositionColumn.difference,
			],
		},
		{
			id: generateUniqueDOMId(),
			kind: "exposure",
			selectedCategories: [
				{
					label: "MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS",
					type: "RISK_MODEL",
					value: "MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS",
				},
				{
					label: "MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY",
					type: "RISK_MODEL",
					value: "MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY",
				},
			],
		},
		{
			id: generateUniqueDOMId(),
			kind: "exAnteContributionVolatility",
			versus: {
				MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS: true,
				MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: true,
				MACRO_GEOGRAPHY_VS_MACRO_ASSET_CLASS: false,
				MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: false,
				MACRO_GEOGRAPHY_VS_MICRO_ASSET_CLASS: false,
			},
			filterSignificant: true,
		},
		{
			id: generateUniqueDOMId(),
			kind: "factorExposure",
			benchmark: true,
			filterSignificant: true,
		},
		{
			id: generateUniqueDOMId(),
			kind: "disclaimer",
			contact: {
				enabled: false,
			},
			content: {
				enabled: false,
			},
		},
	],
};

export const defaultPortfolioTemplates: Array<Omit<DefaultReportTemplate, "visible">> = [
	{
		id: DefaultReportTemplateName.sphere,
		templateName: "Sphere template",
		format: "pdf",
		data: {
			version: latestVersion,
			status: "ready",
			templateItemsByVariant: sphereTemplateDefault,
			templateDraftItemsByVariant: sphereTemplateDefault,
		},
	},
];

export const startingTemplate = {
	id: "fallback",
	templateName: "Untitled",
	format: "pdf",
	data: {
		version: latestVersion,
		status: "ready",
		templateItemsByVariant: fallbackTemplate,
		templateDraftItemsByVariant: fallbackTemplate,
	},
} satisfies Omit<DefaultReportTemplate, "visible" | "id"> & { id: string };

export function isDefaultReportTemplate(
	obj: InvestmentsReportTemplate | DefaultReportTemplate,
): obj is DefaultReportTemplate {
	return !("uuid" in obj);
}
