import { FormController } from "$root/third-party-integrations/react-hook-form";
import { valueByPath } from "$root/utils";
import type { StylableProps, TextInputProps } from "@mdotm/mdotui/components";
import { FormField, Label, TextInput } from "@mdotm/mdotui/components";
import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import { renderNodeOrFn } from "@mdotm/mdotui/react-extensions";
import type { Control, FieldValues, FormState, Path } from "react-hook-form";

export type TextFieldProps<T extends FieldValues> = StylableProps & {
	control: Control<T, any>;
	formState: FormState<T>;
	name: Path<T>;
	label: NodeOrFn<{ htmlFor: string }>;
} & Omit<TextInputProps, "value">;

export function TextField<T extends FieldValues>({
	label,
	name,
	formState,
	control,
	classList,
	style,
	...forward
}: TextFieldProps<T>): JSX.Element {
	return (
		<FormField
			classList={classList}
			style={style}
			label={({ htmlFor }) =>
				typeof label === "string" ? (
					<Label htmlFor={htmlFor} classList="mb-px">
						{label}
					</Label>
				) : (
					renderNodeOrFn(label, { htmlFor })
				)
			}
			error={(valueByPath(formState.errors, name) as { message?: string })?.message}
		>
			{(fieldProps) => (
				<FormController
					control={control}
					name={name}
					render={({ field: { ref, onChange, ...controllerProps } }) => (
						<TextInput innerRef={ref} onChangeText={onChange} {...fieldProps} {...controllerProps} {...forward} />
					)}
				/>
			)}
		</FormField>
	);
}
