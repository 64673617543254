import "react-toastify/dist/ReactToastify.css";
import "./toast-overrides.css";
import type { Id as ToastId } from "react-toastify";
import { toast, ToastContainer } from "react-toastify";
import type { CircularProgressBarProps, IconName, MessageSeverity } from "@mdotm/mdotui/components";
import { CircularProgressBar, ClickableDiv, Text } from "@mdotm/mdotui/components";
import { Icon } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { renderNodeOrFn, toClassName, WrapIfStrOrNumOrEmpty, type NodeOrFn } from "@mdotm/mdotui/react-extensions";
import type { SyntheticEvent } from "react";
import { useState, type CSSProperties, useCallback, createContext, useContext } from "react";
import useAnimationFrame from "$root/hooks/useAnimationFrame";

export function ToastMountPoint(): JSX.Element {
	return <ToastContainer stacked limit={10} position="top-right" />;
}

export type PlatformToastParams = {
	icon: IconName;
	severity: MessageSeverity;
	/** @default 5000 */
	autoClose?: number | false;
	children: NodeOrFn<Omit<ToastContentProps, "children">>;
	/** @default true */
	dismissible?: boolean;
	/** @default 'center' */
	alignIcon?: "top" | "center";
	onClose?(): void;
	onClick?(e: SyntheticEvent): void;
};

const appearanceBySeverity: Record<
	MessageSeverity,
	{
		accentColor: string;
		circleColor: string;
		iconColor: string;
		textColor: string;
	}
> = {
	success: {
		accentColor: themeCSSVars.palette_P500,
		circleColor: themeCSSVars.palette_P200,
		iconColor: themeCSSVars.palette_N0,
		textColor: themeCSSVars.palette_N700,
	},
	info: {
		accentColor: themeCSSVars.palette_S500,
		circleColor: themeCSSVars.palette_S200,
		iconColor: themeCSSVars.palette_N0,
		textColor: themeCSSVars.palette_N700,
	},
	warning: {
		accentColor: themeCSSVars.palette_W500,
		circleColor: themeCSSVars.palette_W200,
		iconColor: themeCSSVars.palette_N0,
		textColor: themeCSSVars.palette_N700,
	},
	error: {
		accentColor: themeCSSVars.palette_D500,
		circleColor: themeCSSVars.palette_D200,
		iconColor: themeCSSVars.palette_N0,
		textColor: themeCSSVars.palette_N700,
	},
};

export type ToastContentProps = {
	children: NodeOrFn<{ onClose(): void }>;
	onClick?(e: SyntheticEvent): void;
	onClose?(): void;
	icon?: IconName;
	severity?: MessageSeverity;
	/** @default 5000 */
	autoClose?: number | false;
	/** @default 'center' */
	alignIcon?: "center" | "top";
};

const ToastCtx = createContext<Partial<ToastContentProps>>({});

export function ToastContent(props: ToastContentProps): JSX.Element {
	const ctx = useContext(ToastCtx);

	const severity = props.severity ?? ctx.severity ?? "info";
	const icon = props.icon ?? ctx.icon ?? "Icon-full-info";
	const alignIcon = props.alignIcon ?? ctx.alignIcon ?? "center";
	return (
		<ClickableDiv
			onClick={(e) => {
				e.stopPropagation();
				/* TODO: support onClickAsync? */
				(props.onClick ?? ctx.onClick)?.(e);
				(props.onClose ?? ctx.onClose)?.();
			}}
			style={
				{
					"--platform-toast-accent-color": appearanceBySeverity[severity].accentColor,
					"--platform-toast-icon-color": appearanceBySeverity[severity].iconColor,
					color: appearanceBySeverity[severity].textColor,
				} as CSSProperties
			}
			classList={{
				[`w-full border border-[${themeCSSVars.palette_N200}] before:block before:absolute before:inset-y-0 before:left-0 before:border-2 before:border-[color:var(--platform-toast-accent-color)] flex gap-4 px-4 py-2`]:
					true,
				"items-start": alignIcon === "top",
				"items-center": alignIcon === "center",
			}}
		>
			<div
				className={toClassName({
					"w-[32px] h-[32px] min-w-[32px] min-h-[32px] relative": true,
					"mt-1": alignIcon === "top",
				})}
			>
				{typeof (props.autoClose ?? ctx.autoClose) === "number" && (
					<div className="absolute z-0 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
						<CircularProgressBarThatFollowsTheDefaultBar
							accentColor={appearanceBySeverity[severity].circleColor}
							innerDiameter={34}
							outerDiameter={42}
						/>
					</div>
				)}
				<div className="absolute z-10 w-[32px] h-[32px] min-w-[32px] min-h-[32px] bg-[var(--platform-toast-accent-color)] border-white text-[var(--platform-toast-icon-color)] rounded-full">
					<Icon icon={icon} size={20} classList="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
				</div>
			</div>
			<Text type="Body/L/Book" as="div" classList="whitespace-pre-line text-left">
				{renderNodeOrFn(props.children, { onClose: () => (props.onClose ?? ctx.onClose)?.() })}
			</Text>
		</ClickableDiv>
	);
}

export function dismissPlatformToast(id: string | number): void {
	toast.dismiss(id);
}

export function platformToast({
	icon,
	severity,
	children,
	autoClose = 5000,
	dismissible = true,
	alignIcon,
	onClose,
	onClick,
}: PlatformToastParams): string | number {
	let toastId: ToastId | null = null;
	const props: Omit<ToastContentProps, "children"> = {
		icon,
		severity,
		autoClose,
		alignIcon,
		onClick,
		onClose: () => {
			if (toastId != null) {
				toast.dismiss(toastId);
			}
		},
	};
	toastId = toast(
		<ToastCtx.Provider value={props}>
			<WrapIfStrOrNumOrEmpty
				wrapper={(content) => (
					<ToastContent {...props}>
						<Text type="Body/L/Bold">{content}</Text>
					</ToastContent>
				)}
			>
				{renderNodeOrFn(children, props)}
			</WrapIfStrOrNumOrEmpty>
		</ToastCtx.Provider>,
		{
			autoClose,
			hideProgressBar: true,
			type: severity,
			onClose,
			icon: <></>,
			closeButton: dismissible,
		},
	);
	return toastId;
}

function CircularProgressBarThatFollowsTheDefaultBar({
	...forward
}: Omit<CircularProgressBarProps, "value" | "innerRef">) {
	const [elements, _setElements] = useState<{
		toastEl: HTMLElement;
		progressBarEl: HTMLElement;
	} | null>(null);
	const setElements = useCallback((el: SVGSVGElement | null) => {
		if (!el) {
			return null;
		}
		const toastEl = el.closest<HTMLElement>(".Toastify__toast");
		if (!toastEl) {
			return null;
		}
		const progressBarEl = toastEl.querySelector<HTMLElement>('[role="progressbar"]');
		if (!progressBarEl) {
			return null;
		}
		_setElements({ toastEl, progressBarEl });
	}, []);
	const [value, setValue] = useState(1);
	// It's not the prettiest solution, but I haven't found a way of getting the current progress value from react-toastify. Feel free to improve this code
	useAnimationFrame(
		() => {
			if (!elements) {
				return;
			}
			setValue(elements.progressBarEl.getBoundingClientRect().width / elements.toastEl.offsetWidth);
		},
		{ pause: !elements || value === 0 },
	);
	return (
		<CircularProgressBar {...forward} classList="[&>circle]:transition-none" innerRef={setElements} value={value} />
	);
}
