/**
 * A variable was created as a patch to prevent portfolios with previously created proposals from this date from generating commentary in the proposal
 * @link [MDOT-16516](https://team-1625063678674.atlassian.net/browse/MDOT-16516)
 */
export const START_DATE_CREATE_PROPOSAL_COMMENTARY = "2024-11-11";

/**
 * Threshold number of instruments to create an entity
 */
export const MIN_NUMBER_OF_INSTRUMENTS = 1;
/**
 * Threshold number of instruments to create target investment
 */
export const MIN_NUMBER_OF_TARGET_INVESTMENT_INSTRUMENTS = 1;
/**
 * Threshold number of instruments to create benchmark
 */
export const MIN_NUMBER_OF_BENCHMARK_INSTRUMENTS = 1;
/**
 * Threshold number of instruments to create investment
 */
export const MIN_NUMBER_OF_INVESTMENT_INSTRUMENTS = 5;
/**
 * Threshold number of instruments to create universe
 */
export const MIN_NUMBER_OF_UNIVERSE_INSTRUMENTS = 5;
/**
 * Max number of instrument that user can insert in a composition
 */
export const INVESTMENT_INSTRUMENT_LIMIT = 1000;
/**
 * Max number of instrument that user can insert in a composition
 */
export const BENCHMARK_INSTRUMENT_LIMIT = 1000;
/**
 * Max number of instrument that user can insert in a composition
 */
export const TARGET_PORTFOLIO_INSTRUMENT_LIMIT = 1000;
/**
 * Max number of instrument that user can insert in a composition
 */
export const UNIVERSE_INSTRUMENT_LIMIT = 1000;
