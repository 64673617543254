import OverlayActions from "$root/components/OverlayActions";
import type {
	SpreadsheetCellApi,
	SpreadsheetPropsValidator,
} from "$root/components/tables-extra/spreadsheet/spreadsheet-cell";
import { useSpreadsheetCell } from "$root/components/tables-extra/spreadsheet/spreadsheet-cell";
import { multiRef, qualifier } from "$root/utils";
import type { TableDataCellProps } from "@mdotm/mdotui/components";
import { TextAreaOverlayEditor } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { ReactNode } from "react";
import type { InstrumentSpreadsheetCellTypeMap } from "../Instrument-customisation";
import DescriptionCell from "./DescriptionCell";
import SelectableCell from "./SelectableCell";

type SpreadsheetDescriptionCellProps = {
	cellProps: Omit<TableDataCellProps, "children">;
	requestAiCommentary?(): Promise<void>;
};

function SpreadsheetDescriptionCell<Row, K extends keyof Row & string>({
	column,
	rowId,
	spreadsheetCtx,
	cellProps,
	requestAiCommentary,
	...forward
}: SpreadsheetPropsValidator<
	Row,
	K,
	InstrumentSpreadsheetCellTypeMap["description"],
	SpreadsheetDescriptionCellProps
>): ReactNode {
	const cellApi = useSpreadsheetCell(spreadsheetCtx, rowId, column) as SpreadsheetCellApi<
		InstrumentSpreadsheetCellTypeMap["description"]
	>;

	return (
		<SelectableCell spreadsheetCtx={spreadsheetCtx} {...cellProps} {...forward} rowId={rowId} column={column}>
			{({ style }) => (
				<TextAreaOverlayEditor
					onClickAway={cellApi.deactivate}
					onBlur={cellApi.deactivate}
					value={cellApi.data?.description?.value ?? ""}
					onChange={(newValue) =>
						cellApi.update({
							...(cellApi.data ?? {}),
							kind: "description",
							description: {
								...cellApi.data?.description,
								value: newValue ?? undefined,
							},
						})
					}
					inputAppearance={{
						style: { maxWidth: 400, maxHeight: 160, resize: "vertical" },
					}}
					isOpen={cellApi.active}
					trigger={({ innerRef: overlayRef }) => (
						<OverlayActions
							isOpen={cellApi.selected}
							options={[
								requestAiCommentary
									? {
											icon: "Ask-ai",
											onClickAsync: () => requestAiCommentary(),
											iconColor: themeCSSVars.palette_N600,
											overrideLoading: cellApi.data.description?.status === "CALCULATING",
											disabled: !cellApi.data.canGenerateInstrumentDescription,
											"data-qualifier": qualifier.instrumentsCustomisation.descriptionCell.requestInstrumentDescription,
									  }
									: null,
								{
									icon: "Edit",
									onClick(e) {
										e.stopPropagation();
										cellApi.activate();
									},
									iconColor: themeCSSVars.palette_N600,
									"data-qualifier": qualifier.instrumentsCustomisation.descriptionCell.enabledEditMode,
								},
							]}
							trigger={({ innerRef: actionRef }) => (
								<DescriptionCell
									innerRef={multiRef(actionRef, overlayRef)}
									description={cellApi.data?.description}
									classList="grow"
									style={style}
								/>
							)}
						/>
					)}
				/>
			)}
		</SelectableCell>
	);
}

export default SpreadsheetDescriptionCell;
