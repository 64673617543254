import type { InvestmentExportConverterType, InvestmentImportConverterType, ServiceType } from "$root/api/api-gen";
import { createPersistentAtom } from "$root/third-party-integrations/zustand";
import type { Updater } from "@mdotm/mdotui/utils";
import { identity } from "@mdotm/mdotui/utils";
import fastDeepEqual from "fast-deep-equal";

export const UserRole = {
	ROOT: "ROOT",
	guest: "guest",
	manager: "manager",
	admin: "admin",
} as const;

export type UserRole = (typeof UserRole)[keyof typeof UserRole];
export interface IUser {
	id: string;
	email: string;
	signedIn: boolean;
	name?: string;
	surname?: string;
	customerId?: string;
	token?: string;
	availableServices: ServiceType[];
	roles: UserRole[];
	impersonating?: boolean; // Used To check if user is impersonating and disable TrackingService
	customFields?: { [key: string]: object };
	customerName: string;
	importFormats?: Array<InvestmentImportConverterType>;
	exportFormats?: Array<InvestmentExportConverterType>;
	importEnabled?: boolean;
	exportEnabled?: boolean;
	automaticImport?: boolean;
}

// TODO: set guest services
export const guestServices: ServiceType[] = [];
export const guestUser: IUser = {
	id: "",
	email: "",
	signedIn: false,
	token: undefined,
	availableServices: guestServices,
	customerName: "",
	roles: [],
	importFormats: undefined,
	automaticImport: false,
	customerId: undefined,
};

export const useUserStore = createPersistentAtom<IUser>(guestUser, "user");
export function useUserValue(): IUser {
	return useUserStore((x) => x.value, fastDeepEqual);
}
export function getCurrentUser(): IUser {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	return useUserStore((x) => x.value);
}
export function useUserState(): { user: IUser; setUser: (newValue: IUser | Updater<IUser>) => void } {
	const { value: user, set: setUser } = useUserStore(identity, (a, b) => fastDeepEqual(a.value, b.value));

	return { user, setUser };
}
