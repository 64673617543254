import type { Description } from "$root/api/api-gen";
import { MarkdownRenderer } from "$root/components/MarkdownRenderer/MarkdownRenderer";
import { useLocaleFormatters } from "$root/localization/hooks";
import { multiRef } from "$root/utils";
import type { TableDataCellProps } from "@mdotm/mdotui/components";
import { AutoTooltip, Icon, Row, Text, TooltipContent } from "@mdotm/mdotui/components";
import { toClassListRecord } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";

function DescriptionCell({
	description,
	innerRef,
	...tableDataCellProps
}: { description: Description | undefined } & TableDataCellProps): JSX.Element {
	const { formatDate } = useLocaleFormatters();

	return (
		<AutoTooltip
			severity="info"
			position="right"
			disabled={!description?.value}
			overrideColor={themeCSSVars.palette_N200}
			trigger={({ innerRef: tooltipRef }) => (
				<Row
					{...tableDataCellProps}
					alignItems="center"
					innerRef={innerRef ? multiRef(tooltipRef, innerRef) : tooltipRef}
					classList={{
						...toClassListRecord(tableDataCellProps.classList),
					}}
				>
					{description?.creator === "SPHERE" ? (
						<MarkdownRenderer classList="line-clamp-2 pt-2">{description?.value}</MarkdownRenderer>
					) : (
						<p className="line-clamp-2">{description?.value}</p>
					)}
				</Row>
			)}
		>
			<TooltipContent>
				{description?.date && (
					<Row classList="mb-2" gap={1}>
						<Icon icon="Clock" color={themeCSSVars.palette_P400} size={16} />
						<Text type="Body/S/Book">Last description update {formatDate(description?.date)}</Text>
					</Row>
				)}
				{description?.creator === "SPHERE" ? (
					<MarkdownRenderer>{description?.value}</MarkdownRenderer>
				) : (
					description?.value
				)}
			</TooltipContent>
		</AutoTooltip>
	);
}

export default DescriptionCell;
