import type { EditorSaveEditOrReviewRequestPortfolioSavingModeEnum } from "$root/api/api-gen";
import { ReferenceUniversesControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import type { MinimumDialogProps } from "$root/components/spawnable/type";
import { useDebouncedNameUniquenessChecker } from "$root/functional-areas/named-entities/uniqueness";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { FormFields } from "$root/ui-lib/form/FormFields";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	Button,
	CircularProgressBar,
	Dialog,
	DialogFooter,
	Radio,
	RadioGroup,
	SubmitButton,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, spawn } from "@mdotm/mdotui/react-extensions";
import { noop } from "@mdotm/mdotui/utils";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { errorMessageNameRequired } from "./handler";
import { FormController } from "$root/third-party-integrations/react-hook-form";

enum SaveModeEnum {
	SAVE = "SAVE",
	SAVE_AS_NEW = "SAVE_AS_NEW",
}
type SaveMode = `${SaveModeEnum}`;
export type UniverseManualEditFormProps = {
	name: string;
	saveMode: SaveMode;
};

export type SubmitUniverseEditDialogProps = {
	onAsyncSubmit(payload: UniverseManualEditFormProps): MaybePromise<void>;
} & MinimumDialogProps;
const defaultManualCreationData: UniverseManualEditFormProps = { name: "", saveMode: "SAVE" };
function SubmitUniverseEditDialog(props: SubmitUniverseEditDialogProps) {
	const { show, onClose, onAnimationStateChange, onAsyncSubmit } = props;

	const { t } = useTranslation();
	const referenceUniversesApi = useApiGen(ReferenceUniversesControllerApiFactory);

	const { checkIfNameIsAvailable, checkingNameUniqueness } = useDebouncedNameUniquenessChecker({
		isNameAvailableApi: (name, opts) => axiosExtract(referenceUniversesApi.isUniverseNameAvailable(name, opts)),
	});

	const { control, formState, handleSubmit, watch } = useForm({
		defaultValues: defaultManualCreationData,
		resolver: zodResolver(
			z.discriminatedUnion("saveMode", [
				z.object({
					saveMode: z.literal(SaveModeEnum.SAVE),
					name: z.string().nullable(),
				}),
				z.object({
					saveMode: z.literal(SaveModeEnum.SAVE_AS_NEW),
					name: z
						.string()
						.min(1, errorMessageNameRequired.UNIVERSE)
						.refine(checkIfNameIsAvailable, {
							message: t("MISSING_FIELD.NAME_NOT_AVAILABLE"),
						}),
				}),
			]),
		),
	});

	const saveMode = watch("saveMode");

	return (
		<Dialog
			size="medium"
			noValidate
			show={show}
			onClose={onClose}
			header="Select"
			onAnimationStateChange={onAnimationStateChange}
			footer={
				<DialogFooter
					neutralAction={
						<Button palette="tertiary" data-qualifier="CompositionEditor/Modal/Cancel" onClick={onClose}>
							{t("BUTTON.CANCEL")}
						</Button>
					}
					primaryAction={
						<SubmitButton data-qualifier="CompositionEditor/Modal/Save" onClick={noop}>
							{t("BUTTON.DONE")}
						</SubmitButton>
					}
				/>
			}
			onSubmitAsync={async () => {
				await handleSubmit((payload) => onAsyncSubmit(payload))();
			}}
		>
			<div className="grid gap-4 transition-all ease-elastic">
				<p>Would you like to save the changes to this universe, or create a new one?</p>
				<FormController
					control={control}
					name="saveMode"
					render={({ field: { value, onChange } }) => (
						<RadioGroup value={value} onChange={onChange}>
							<div className="flex flex-row flex-wrap gap-4">
								<Radio value={SaveModeEnum.SAVE}>Save universe</Radio>
								<Radio value={SaveModeEnum.SAVE_AS_NEW}>Save as new</Radio>
							</div>
						</RadioGroup>
					)}
				/>
				{saveMode === "SAVE_AS_NEW" && (
					<FormFields.Text
						control={control}
						formState={formState}
						name="name"
						label="New universe name"
						data-qualifier="CompositionEditor/SaveDialog/Name"
						placeholder="Name"
						rightContent={
							checkingNameUniqueness ? <CircularProgressBar classList="w-3" value="indeterminate" /> : undefined
						}
					/>
				)}
			</div>
		</Dialog>
	);
}

export type SpawnSubmitUniverseEditDialogProps = Omit<SubmitUniverseEditDialogProps, "show" | "onClose">;
export function spawnSubmitUniverseEditDialog(props: SpawnSubmitUniverseEditDialogProps): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ show, resolve, onHidden }) => (
			<SubmitUniverseEditDialog
				{...props}
				show={show}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
				onClose={() => resolve()}
				onAsyncSubmit={async (payload) => {
					await props.onAsyncSubmit(payload);
					resolve();
				}}
			/>
		)),
	);
}
