import { useUserValue } from "$root/functional-areas/user";
import type { IconName } from "@mdotm/mdotui/components";
import { typedUrlForRoute } from "./RoutesDef";
import { hasAccess } from "../AuthorizationGuard";
import { unpromisify } from "@mdotm/mdotui/utils";
import { HmmRegion } from "$root/api/api-gen";
import { useMemo, type ReactNode } from "react";
import { PortfolioStudioTab } from "$root/pages/PortfoliosStudio/portfolio-studio-tabs";
import { PortfolioStudioSettingTabEnum } from "$root/functional-areas/portfolio-studio-settings";
import { spawnChangePasswordDialog } from "../ChangePassword";

export type SidebarSubmenuEntry<SubName extends string> = {
	name: SubName;
	label: string;
	matchingUrls?: Array<string>;
	activeOverride?: boolean;
} & (
	| {
			url?: string;
			onClick?: undefined;
	  }
	| {
			url?: undefined;
			onClick(): void;
	  }
);

export type SidebarEntry<Name extends string, SubName extends string> = {
	icon: IconName | ((props: { size: number; color: string }) => ReactNode);
	title: string;
	/** unique name identifying the entry */
	name: Name;
	matchingUrls?: Array<string>;
	activeOverride?: boolean;
	submenuEntries?: Array<SidebarSubmenuEntry<SubName>>;
} & (
	| {
			url?: string;
			onClick?: undefined;
	  }
	| {
			url?: undefined;
			onClick(): void;
	  }
);

export type SidebarSubmenuEntryBuilderParam<Name extends string> = {
	enabled?: boolean;
	name: Name;
	label: string;
	/**
	 * For pages which are not listed in the provided URLs
	 * but that should also highlight the sidebar item.
	 */
	extraMatchingUrls?: Array<string>;
	activeOverride?: boolean;
} & (
	| {
			url: string;
			onClick?: undefined;
	  }
	| {
			url?: undefined;
			onClick(): void;
	  }
);

export type SidebarEntryBuilderParam<Name extends string, SubName extends string> = {
	enabled?: boolean;
	icon: IconName | ((props: { size: number; color: string }) => ReactNode);
	title: string;
	/** unique name identifying the entry */
	name: Name;
	/**
	 * For pages which are not listed in the provided URLs
	 * but that should also highlight the sidebar item.
	 */
	extraMatchingUrls?: Array<string>;
	activeOverride?: boolean;
	submenuEntries?: Array<SidebarSubmenuEntryBuilderParam<SubName>>;
} & (
	| {
			url?: string;
			onClick?: undefined;
	  }
	| {
			url?: undefined;
			onClick(): void;
	  }
);

/**
 * Helper function that builds sidebar entries based on the passed
 * configuration, handling edge cases such as empty submenus.
 */
export function sidebarEntry<Name extends string, SubEntry extends string>(
	x: SidebarEntryBuilderParam<Name, SubEntry>,
): SidebarEntry<Name, SubEntry> | null {
	if (!(x.enabled ?? true)) {
		return null;
	}
	const baseMatchingUrls = x.url ? [x.url] : [];

	if (!x.submenuEntries) {
		return {
			...x,
			matchingUrls: baseMatchingUrls.concat(x.extraMatchingUrls ?? []),
		};
	}

	// const { enabledSubentries = [], hiddenSubEntries = [] } = groupBy(x.submenuEntries, ({ enabled }) =>
	// 	enabled ?? true ? "enabledSubentries" : "hiddenSubEntries",
	// );

	// if (!enabledSubentries.length) {
	// 	return null;
	// }

	const subentriesWithMatchingUrls = x.submenuEntries.map((entry) => ({
		...entry,
		matchingUrls: (entry.url ? [entry.url] : []).concat(entry.extraMatchingUrls ?? []),
	}));
	const enabledSubentries = subentriesWithMatchingUrls.filter((entry) => entry.enabled ?? true);

	if (!enabledSubentries.length) {
		return null;
	}

	const subUrls = subentriesWithMatchingUrls.flatMap((entry) => entry.matchingUrls ?? []);
	const mainEntry = {
		...x,
		submenuEntries: enabledSubentries.map((entry): SidebarSubmenuEntry<SubEntry> => {
			const clone = { ...entry };

			delete clone.enabled;
			delete clone.extraMatchingUrls;

			return clone;
		}),
		matchingUrls: baseMatchingUrls.concat(x.extraMatchingUrls ?? []).concat(subUrls),
	};

	delete mainEntry.enabled;
	delete mainEntry.extraMatchingUrls;

	return mainEntry;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useSidebarEntries() {
	const user = useUserValue();
	return useMemo(() => {
		const allEntries = [
			sidebarEntry({
				enabled: hasAccess(user, { requiredService: "INVESTMENTS" }),
				icon: "portfolio-studio",
				title: "Portfolio studio",
				name: "PortfolioStudio",
				// url: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.Portfolios }),
				extraMatchingUrls: ["/portfolios/"],
				submenuEntries: [
					{
						name: "Portfolios",
						label: "Portfolios",
						url: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.Portfolios }),
						extraMatchingUrls: ["/portfolio_details"],
					},
					{
						name: "References",
						label: "References",
						url: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.References }),
						extraMatchingUrls: ["/portfolio_reference_details", "/custom_benchmarks"],
					},
					{
						name: "Universes",
						label: "Universes",
						url: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.Universes }),
						extraMatchingUrls: ["/universe_details"],
					},
					{
						name: "MarketViews",
						label: "Market views",
						url: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.MarketViews }),
						extraMatchingUrls: ["/workspace_market"],
					},
				],
			}),
			sidebarEntry({
				icon: "market-insight",
				title: "Market insights",
				name: "MarketInsight",
				submenuEntries: [
					{
						enabled: hasAccess(user, { requiredService: "REPORTS" }),
						name: "Market dashboard",
						label: "Market dashboard",
						url: typedUrlForRoute("MarketDashboard", {}),
					},
					{
						enabled: hasAccess(user, { requiredService: "REPORTS" }),
						name: "Outlook",
						label: "Outlook",
						url: typedUrlForRoute("Outlook", {}),
					},
					{
						enabled: hasAccess(user, { requiredService: "REPORTS" }),
						name: "Outlook focus",
						label: "Outlook focus",
						url: typedUrlForRoute("AssetClass", {}),
					},
					{
						enabled: hasAccess(user, { requiredService: "SIGNALS" }),
						name: "Regime analysis tool",
						label: "Regime analysis tool",
						url: typedUrlForRoute("RegimeAnalysisTool", {
							region: hasAccess(user, { requiredService: "GLOBAL_RISK_MAP" }) ? HmmRegion.Global : HmmRegion.Eu,
						}),
						extraMatchingUrls: ["/regime_analysis_tool"],
					},
					{
						enabled: hasAccess(user, { requiredService: "CUSTOM_REPORT_CB1" }),
						name: "Custom reports",
						label: "Custom reports",
						url: typedUrlForRoute("CustomReports", {}),
					},
				],
			}),
			sidebarEntry({
				icon: "Storyfolio",
				title: "Storyfolio",
				name: "Storyfolio",
				submenuEntries: [
					{
						enabled: hasAccess(user, { requiredService: "INVESTMENTS_REPORT_TEMPLATE_EDITOR" }),
						name: "Report builder",
						label: "Report builder",
						url: typedUrlForRoute("PortfolioStudioSettings", {
							tab: PortfolioStudioSettingTabEnum.ReportCustomisation,
						}),
						extraMatchingUrls: ["/report_editor", "/portfolio_studio_settings/report-editor"],
					},
					{
						enabled: hasAccess(user, { requiredService: "COMMENTARY_BUILDER" }),
						name: "Commentary builder",
						label: "Commentary builder",
						url: typedUrlForRoute("Storyfolio/Studio", {}),
					},
				],
			}),
			sidebarEntry({
				icon: "Settings",
				title: "My settings",
				name: "My Settings",
				submenuEntries: [
					{
						name: "InstrumentCustomisation",
						label: "Instr. customization",
						url: typedUrlForRoute("PortfolioStudioSettings/InstrumentCustomisation", {}),
					},
					{
						enabled: hasAccess(user, { requiredService: "CUSTOM_MARKET_VIEW_SETTINGS_EDITOR" }),
						name: PortfolioStudioSettingTabEnum.MarketViewSetting,
						label: "Market view settings",
						url: typedUrlForRoute("PortfolioStudioSettings", {
							tab: PortfolioStudioSettingTabEnum.MarketViewSetting,
						}),
					},
					{
						enabled: hasAccess(user, { requiredService: "CUSTOM_MARKET_VIEW_SETTINGS_EDITOR" }),
						name: PortfolioStudioSettingTabEnum.ExplainabilitySettings,
						label: "Explainability options",
						url: typedUrlForRoute("PortfolioStudioSettings", {
							tab: PortfolioStudioSettingTabEnum.ExplainabilitySettings,
						}),
					},
					{
						name: "Notification settings",
						label: "Notification settings",
						url: typedUrlForRoute("Notification", {}),
						enabled:
							hasAccess(user, { requiredService: "INVESTMENTS" }) || hasAccess(user, { requiredService: "REPORTS" }),
					},
					{
						name: "Change password",
						label: "Change password",
						onClick: unpromisify(() => spawnChangePasswordDialog({}).promise),
					},
					{
						name: "Cookie Policy",
						label: "Cookie Policy",
						onClick: () => (window as any)._iub.cs.api.openPreferences(),
					},
					{
						name: "Privacy",
						label: "Privacy",
						onClick: () =>
							window.open("https://www.iubenda.com/privacy-policy/48016693", "_blank", "noopener noreferrer"),
					},
				],
			}),
			sidebarEntry({
				enabled: hasAccess(user, { requiredRoles: ["ROOT"] }),
				icon: "Optimize",
				title: "Admin panel",
				name: "Admin panel",
				submenuEntries: [
					{
						name: "Global settings panel",
						label: "Global settings panel",
						url: typedUrlForRoute("AdvanceSettings/UserSettingsPanel", {}),
					},
					{
						name: "ChatGPT Prompt",
						label: "ChatGPT Prompt",
						url: typedUrlForRoute("AdvanceSettings/CommentaryPrompt", {}),
					},
					{
						name: "Info",
						label: "Info",
						url: typedUrlForRoute("AdvanceSettings/PlatformInfo", {}),
					},
					{
						name: "Advanced editor",
						label: "Advanced editor",
						url: typedUrlForRoute("AdvanceSettings/AdvancedEditor", {}),
					},
				],
			}),
		];

		const filtered = allEntries.filter((x) => x != null);
		return filtered;
	}, [user]);
}
