import AuthorizationGuard from "$root/components/AuthorizationGuard";
import { useCrumbs } from "$root/components/Crumbs/useCrumbs";
import { PageHeader } from "$root/components/PageHeader";
import { useSystemValue } from "$root/functional-areas/system";
import { useTranslation } from "react-i18next";

const PlatformInfo = (): JSX.Element => {
	const { t } = useTranslation();
	const system = useSystemValue();
	const crumbs = useCrumbs();

	return (
		<>
			<PageHeader title="Platform Info" crumbsV2={crumbs.adminPanel("Info")} />
			<AuthorizationGuard requiredRole="ROOT" placeholder={t("NOT_AUTHORIZED")}>
				{() => (
					<div className="bg-white m-1 p-3 rounded shadow">
						<div className="w-full py-3 px-4 border-solid border rounded flex justify-between mb-1">
							<div className="">Client Version</div>
							<div className="">{system.clientEnv}</div>
						</div>
						<div className="w-full py-3 px-4 border-solid border rounded flex justify-between mb-1">
							<div className="">Server Version</div>
							<div className="">{system.serverEnv}</div>
						</div>
						<div className="w-full py-3 px-4 border-solid border rounded flex justify-between mb-1">
							<div className="">System Mode</div>
							<div className="">{system.systemMode}</div>
						</div>
					</div>
				)}
			</AuthorizationGuard>
		</>
	);
};

export default PlatformInfo;
