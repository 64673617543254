import type { ReviewTicker } from "$root/api/api-gen";
import type { PartialUserInstrument } from "../instruments-editor/const";
import { EditProxiedInstrumentTable } from "./EditProxiedInstrumentTable";
import type { ReviewEntity } from "./helpers";
import type { EditProxiedBuilderProps } from "./helpers";
import { useMemo } from "react";

export type EditProxiedInstrumentSectionProps = {
	actions?: Array<"delete" | "edit">;
	composition: ReviewTicker[];
	userInstrumentsMap: Immutable.Map<string, PartialUserInstrument>;
	disableEditing?: boolean;
	uuid: string;
	proxyBuilder: EditProxiedBuilderProps;
	onChangeProxyBuilder(newProxyBuilder: EditProxiedBuilderProps): void;

	section: ReviewEntity;
};

export function ProxyInstrumentEditor({
	disableEditing,
	composition,
	uuid,
	proxyBuilder,
	onChangeProxyBuilder,
	section,
	userInstrumentsMap,
}: EditProxiedInstrumentSectionProps): JSX.Element {
	const { proxied, deleted, tagged, scored } = proxyBuilder;
	const instrumentsThatNeedProxy = useMemo(
		() => composition.filter((row) => row.needsCustomProxy && Boolean(row.delisted) === false),
		[composition],
	);


	return (
		<EditProxiedInstrumentTable
			uuid={uuid}
			section={section}
			userInstrumentsMap={userInstrumentsMap}
			instrumentThatNeedProxy={instrumentsThatNeedProxy}
			composition={composition}
			disableEditing={disableEditing}
			deleted={deleted}
			tagged={tagged}
			proxied={proxied}
			scored={scored}
			onProxiedChange={(newProxied) => onChangeProxyBuilder({ deleted, proxied: newProxied, scored, tagged })}
			onDeletedChange={(newDeleted) => onChangeProxyBuilder({ deleted: newDeleted, proxied, scored, tagged })}
			onTagChange={(newTagged) => onChangeProxyBuilder({ deleted, proxied, scored, tagged: newTagged })}
			onScoreChange={(newScored) => onChangeProxyBuilder({ deleted, proxied, scored: newScored, tagged })}
		/>
	);
}
