import type { RichRiskModelValue } from "$root/api/api-gen";
import type {
	SpreadsheetCellApi,
	SpreadsheetPropsValidator,
} from "$root/components/tables-extra/spreadsheet/spreadsheet-cell";
import { useSpreadsheetCell } from "$root/components/tables-extra/spreadsheet/spreadsheet-cell";
import { qualifier } from "$root/utils";
import { Button, Icon, Row, type TableDataCellProps } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor, noop } from "@mdotm/mdotui/utils";
import { useMemo, type ReactNode } from "react";
import { spawnInstrumentClassificationDialog } from "../instrument-classification/InstrumentClassificationDialog";
import type { InstrumentSpreadsheetCellTypeMap } from "../Instrument-customisation";
import { colors } from "../Instrument-customisation/SidePanelColumnsAdder";
import { TagDataCell } from "../tags-v2/table-cell";
import type { InstrumentTagMap, InstrumentTagWeightMap } from "../tags-v2/types";
import SelectableCell from "./SelectableCell";

export function proxiesToTags(proxies: RichRiskModelValue[] | undefined): {
	tagMap: InstrumentTagMap;
	tagWeightMap: InstrumentTagWeightMap;
} {
	return {
		tagMap: Object.fromEntries(
			proxies?.map((proxy, i): [string, Required<InstrumentTagMap[string]>] => [
				proxy.ticker!,
				{
					badgeColor: colors[i % colors.length].badgeColor(),
					chartColor: colors[i % colors.length].chartColor(),
					id: proxy.ticker!,
					label: proxy.name!,
				} satisfies InstrumentTagMap[string],
			]) ?? [],
		),
		tagWeightMap: Object.fromEntries(
			proxies
				?.map((proxy): [string, number] => [proxy.ticker!, proxy.weight! satisfies InstrumentTagWeightMap[string]])
				.sort(builtInSortFnFor("1", "desc")) ?? [],
		),
	};
}

type SpreadsheetRiskModelCellProps = {
	cellProps: Omit<TableDataCellProps, "children">;
};

function SpreadsheetRiskModelCell<Row, K extends keyof Row & string>({
	column,
	rowId,
	spreadsheetCtx,
	cellProps,
}: SpreadsheetPropsValidator<
	Row,
	K,
	InstrumentSpreadsheetCellTypeMap["riskModel"],
	SpreadsheetRiskModelCellProps
>): ReactNode {
	const cellApi = useSpreadsheetCell(spreadsheetCtx, rowId, column) as SpreadsheetCellApi<
		InstrumentSpreadsheetCellTypeMap["riskModel"]
	>;

	const { tagMap, tagWeightMap } = useMemo<{
		tagMap: InstrumentTagMap;
		tagWeightMap: InstrumentTagWeightMap;
	}>(() => proxiesToTags(cellApi.data.proxies), [cellApi.data.proxies]);

	return (
		<SelectableCell
			spreadsheetCtx={spreadsheetCtx}
			{...cellProps}
			rowId={rowId}
			column={column}
			onDoubleClick={() => {
				spawnInstrumentClassificationDialog({
					defaultInstrumentProxies: cellApi.data.proxies ?? [],
					onSubmitAsync: ({ indices, weights }) => {
						const proxyIndexes = indices.map(
							(props): RichRiskModelValue => ({
								...props,
								name: props.instrument,
								weight: props.ticker ? weights.get(props.ticker)?.toNumber() : undefined,
							}),
						);
						cellApi.update({
							...(cellApi.data ?? {}),
							kind: "riskModel",
							proxies: proxyIndexes,
						});
					},
				}).catch(noop);
			}}
		>
			{({ style }) => (
				<TagDataCell
					tagMap={tagMap}
					tagWeightMap={tagWeightMap}
					viewMode="BAR_CHART"
					style={style}
					tooltipFooter={
						<Row
							onClick={(e) => e.stopPropagation()}
							flexGrow={0}
							alignItems="center"
							justifyContent="flex-end"
							classList="pt-2"
						>
							<Button
								palette="tertiary"
								size="x-small"
								style={{
									color: themeCSSVars.palette_N600,
								}}
								data-qualifier={qualifier.instrumentsCustomisation.riskModelCell.editButton}
								onClick={() => {
									spawnInstrumentClassificationDialog({
										defaultInstrumentProxies: cellApi.data.proxies ?? [],
										onSubmitAsync: ({ indices, weights }) => {
											const proxyIndexes = indices.map(
												(props): RichRiskModelValue => ({
													...props,
													name: props.instrument,
													weight: props.ticker ? weights.get(props.ticker)?.toNumber() : undefined,
												}),
											);
											cellApi.update({
												...(cellApi.data ?? {}),
												kind: "riskModel",
												proxies: proxyIndexes,
											});
										},
									}).catch(noop);
								}}
							>
								<Icon icon="Edit" size={16} color={themeCSSVars.palette_N500} />
								&nbsp; Edit
							</Button>
						</Row>
					}
				/>
			)}
		</SelectableCell>
	);
}

export default SpreadsheetRiskModelCell;
