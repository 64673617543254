import type {
	BenchmarkSummary,
	EditorCompositionResponse,
	InvestmentStatuses,
	ReviewTicker,
	UserInstrumentDto,
} from "$root/api/api-gen";
import {
	BenchmarksControllerApiFactory,
	EntityEditorControllerApiFactory,
	IntegrationReferenceControllerApiFactory,
	PortfolioStudioPreferencesApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { useCrumbs } from "$root/components/Crumbs/useCrumbs";
import { LabelRounded } from "$root/components/LabelRounded/Index";
import { PageDownloadAndMoreActionsMenu } from "$root/components/PageDownloadAndMoreActionsMenu";
import type { PageHeaderProps } from "$root/components/PageHeader";
import { PageHeader } from "$root/components/PageHeader";
import { useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import ReactQueryWrapper, { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import ReviewCompositionErrors from "$root/components/ReviewCompositionErrors";
import { useEventBus } from "$root/event-bus";
import { spawnAccessDialog } from "$root/functional-areas/acl/AccessDialog";
import { aclByArea } from "$root/functional-areas/acl/checkers/all";
import EntityStatus from "$root/functional-areas/acl/EntityStatus";
import { useBenchmarkEntityManagementActions } from "$root/functional-areas/benchmark/entity-management";
import { retrieveUserInstrumentsClassifications } from "$root/functional-areas/instruments-editor/builder";
import type { PartialUserInstrument } from "$root/functional-areas/instruments-editor/const";
import { InstrumentEditorEntity } from "$root/functional-areas/instruments-editor/const";
import {
	hideNameColumnMetadata,
	useInstrumentColumnPreference,
	useInstrumentsColumn,
} from "$root/functional-areas/instruments/hooks";
import { ReviewEntity } from "$root/functional-areas/proxies/helpers";
import { useUserValue } from "$root/functional-areas/user";
import { useLocaleFormatters } from "$root/localization/hooks";
import { platformToast } from "$root/notification-system/toast";
import type { CustomAxiosError } from "$root/third-party-integrations/axios";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { downloadContentDisposition, objMatchFn, useQueryNoRefetch } from "$root/utils";
import { BenchmarkContext } from "$root/widgets-architecture/contexts/benchmark";
import { Card } from "$root/widgets-architecture/layout/Card";
import WidgetsMapper from "$root/widgets-architecture/layout/WidgetsMapper";
import type { TableColumn } from "@mdotm/mdotui/components";
import { AutoSortHScrollTable, Banner, Button, Icon, Text } from "@mdotm/mdotui/components";
import { noop, nullary, unpromisify } from "@mdotm/mdotui/utils";
import type { AxiosError } from "axios";
import type { Map } from "immutable";
import { useMemo, type FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useCompositionColumns } from "../PortfolioDetails/PortfolioComposition/columns";
import { PortfolioStudioTab } from "../PortfoliosStudio/portfolio-studio-tabs";

const severityByStatus: Record<InvestmentStatuses, PageHeaderProps["severity"]> = {
	CALCULATING: "calculating",
	ERROR: "error",
	READY: "success",
	ACCEPTED: "success",
	PROPOSAL_READY: "success",
	RETRIEVING_DATA: "calculating",
	REVIEW: undefined,
	DRAFT: "info", // TODO(openapi-update): do we need a new status?
};

type PageHeaderSubtitleSummary = {
	summary?: BenchmarkSummary;
	onChangeAcl?: () => Promise<unknown>;
	entityManagementActions: ReturnType<typeof useBenchmarkEntityManagementActions>;
};

const PageHeaderSubtitle: FC<PageHeaderSubtitleSummary> = (props) => {
	const { summary, onChangeAcl } = props;
	const { richAcl } = summary ?? {};
	const { t } = useTranslation();

	const { formatDate } = useLocaleFormatters();
	const benchmarkV4Api = useApiGen(BenchmarksControllerApiFactory);
	const integrationsApi = useApiGen(IntegrationReferenceControllerApiFactory);
	const user = useUserValue();
	const { push } = useTypedNavigation();

	return (
		<div className="flex justify-between items-center min-h-[54px]">
			<div className="flex justify-between items-center space-x-4 text-[#585D68]">
				<div className="flex items-center" data-qualifier="BenchmarkDetails/PageHeader/Status">
					<span className="mr-2 uppercase">{t("STATUS")}</span>
					<LabelRounded type="status" content={{ label: summary?.status ?? "-", component: "" }} />
				</div>
				<div className="" data-qualifier="BenchmarkDetails/PageHeader/Action">
					<span className="uppercase">Last action request:</span>&nbsp;
					<span className="font-bold uppercase">
						{summary?.action} ({formatDate(summary?.modificationTime ?? "")})
					</span>
				</div>
				<EntityStatus
					accessControl={richAcl}
					entity="BENCHMARK"
					entityId={summary?.benchmarkIdentifier}
					entityName={summary?.name}
					refetch={onChangeAcl}
				/>
			</div>
			<div className="flex items-center gap-2">
				{summary?.status !== "CALCULATING" &&
					summary?.status !== "RETRIEVING_DATA" &&
					summary?.status !== "ERROR" &&
					aclByArea.benchmark.canEditComposition(user.id, summary?.richAcl?.acl ?? []) && (
						<div className="flex justify-end shrink">
							<Button
								size="small"
								palette="primary"
								onClick={() =>
									push("Portfolios/ManualEdit", {
										uuid: summary!.benchmarkIdentifier!,
										entity: InstrumentEditorEntity.Benchmark,
									})
								}
							>
								<Icon icon="Edit" size={16} />
								&nbsp; {t("BUTTON.EDIT")}
							</Button>
						</div>
					)}
				<PageDownloadAndMoreActionsMenu
					area="benchmark"
					downloadActions={[
						{
							icon: "xls",
							disabled: summary?.status === "CALCULATING" || !summary,
							onClickAsync: async () => {
								if (!summary?.benchmarkIdentifier) {
									throw new Error("unable to find benchmarkId of undefined");
								}
								const response = await benchmarkV4Api.exportComposition1(summary?.benchmarkIdentifier, {
									responseType: "blob",
								});
								downloadContentDisposition(response);
							},
							label: "Benchmark composition",
							"data-qualifier": "BenchmarkDetails/DropdownMenu/DropdownItem(XLSReport)",
						},
						{
							icon: "xls",
							onClickAsync: async () => {
								if (!summary?.benchmarkIdentifier) {
									throw new Error("unable to find benchmarkId of undefined");
								}
								const response = await integrationsApi.exportReference(summary?.benchmarkIdentifier, {
									responseType: "blob",
								});
								downloadContentDisposition(response);
							},
							label: "Reference template",
							"data-qualifier": "BenchmarkDetails/DropdownMenu/DropdownItem(ReferenceTemplate)",
						},
					]}
					moreActions={[
						aclByArea.benchmark.canDelete(user.id, richAcl?.acl ?? []) && {
							icon: "Delete",
							onClick: unpromisify(props.entityManagementActions.deleteAsync ?? noop),
							"data-qualifier": "BenchmarkDetails/DropdownMenu/DropdownItem(Delete)",
							disabled: !props.entityManagementActions.deleteAsync,
							label: t("BUTTON.DELETE"),
						},
						props.entityManagementActions.duplicateAsync && {
							icon: "Content-Copy",
							onClick: unpromisify(props.entityManagementActions.duplicateAsync),
							"data-qualifier": "BenchmarkDetails/DropdownMenu/DropdownItem(Duplicate)",
							disabled: summary?.status === "CALCULATING",
							label: t("BUTTON.DUPLICATE"),
						},
						aclByArea.benchmark.canRename(user.id, richAcl?.acl ?? []) && {
							icon: "Edit",
							onClick: unpromisify(props.entityManagementActions.renameAsync ?? noop),
							"data-qualifier": "BenchmarkDetails/DropdownMenu/DropdownItem(Rename)",
							disabled: !props.entityManagementActions.renameAsync,
							label: t("BUTTON.RENAME"),
						},
					]}
				/>
			</div>
		</div>
	);
};

const CustomBenchmark = (): JSX.Element => {
	const { t } = useTranslation();
	const { benchmarkId } = useParams<{ benchmarkId?: string }>();
	const benchmarksV4Api = useApiGen(BenchmarksControllerApiFactory);
	const editorApi = useApiGen(EntityEditorControllerApiFactory);
	const { push } = useTypedNavigation();

	const queryBenchmarkSummary = useQueryNoRefetch(["getBenchmarkSummary", benchmarkId], {
		enabled: Boolean(benchmarkId),
		queryFn: async () => {
			if (!benchmarkId) {
				throw Error("unable to find benchmarkId of undefined");
			}
			const { data } = await benchmarksV4Api.getBenchmarkSummary(benchmarkId);
			return data;
		},
		onSuccess({ status }) {
			if (status === "REVIEW") {
				push("ReviewInstruments", { entity: ReviewEntity.BENCHMARK, uuid: benchmarkId! });
			}
		},
		onError: (error: AxiosError<CustomAxiosError>) => {
			if (error.response?.data.code === 404 || error.response?.data.message === "Accesso negato") {
				spawnAccessDialog({
					onClick: (onClose) => {
						push("PortfoliosStudio", { tab: PortfolioStudioTab.References });
						onClose();
					},
				});
				return;
			}

			platformToast({
				children: t("SOMETHING_WENT_WRONG"),
				severity: "error",
				icon: "Portfolio",
			});
			push("PortfoliosStudio", { status: "notFound", tab: PortfolioStudioTab.References });
		},
	});

	const { data: summary, refetch: refetchSummary } = queryBenchmarkSummary;

	useEventBus("benchmark-update", {
		filter: objMatchFn({ uuid: benchmarkId }),
		listener: () => {
			refetchSummary().catch(noop);
		},
	});

	useEventBus("shared-entity", {
		filter: objMatchFn({ sharedEntityUuid: benchmarkId }),
		listener: () => {
			refetchSummary().catch(noop);
		},
	});

	const entityManagementActions = useBenchmarkEntityManagementActions(summary, {
		onRename: unpromisify(nullary(refetchSummary)),
		onDuplicate: () => push("PortfoliosStudio", { tab: PortfolioStudioTab.References }),
		onDelete: () => push("PortfoliosStudio", { tab: PortfolioStudioTab.References }),
	});

	const crumbs = useCrumbs();

	return (
		<ReactQueryWrapperBase query={queryBenchmarkSummary}>
			{() => (
				<>
					<PageHeader
						severity={summary?.status ? severityByStatus[summary?.status] : undefined}
						title={summary?.name ?? ""}
						crumbsV2={[
							...crumbs.portfolioStudio("References", summary?.benchmarkIdentifier),
							...crumbs.benchmarkName({
								uuid: summary?.benchmarkIdentifier,
								name: summary?.name,
							}),
						]}
						subTitle={
							summary?.status !== "REVIEW" && (
								<PageHeaderSubtitle
									summary={summary}
									onChangeAcl={refetchSummary}
									entityManagementActions={entityManagementActions}
								/>
							)
						}
						name="BenchmarkDetails"
					/>

					{summary?.status === "RETRIEVING_DATA" ? (
						<ReactQueryWrapper
							queryFn={async () => {
								const editorResponse = await axiosExtract(
									editorApi.getEditorReviewComposition(benchmarkId ?? "", "BENCHMARK"),
								);

								const nonNullableComposition = editorResponse.composition ?? [];
								const { userInstrumentsMap } = await retrieveUserInstrumentsClassifications(nonNullableComposition);
								return { userInstrumentsMap, editorResponse, composition: nonNullableComposition };
							}}
							queryKey={["benchmarkCompositionInReview", summary?.status, benchmarkId]}
						>
							{(response) => <BenchmarkReviewBlock response={response} summary={summary} />}
						</ReactQueryWrapper>
					) : (
						<BenchmarkContext.Provider value={{ benchmarkId }}>
							<WidgetsMapper widgetName="BenchmarkComposition" />
						</BenchmarkContext.Provider>
					)}
				</>
			)}
		</ReactQueryWrapperBase>
	);
};

function BenchmarkReviewBlock({
	response,
	summary,
}: {
	response: {
		editorResponse: EditorCompositionResponse;
		userInstrumentsMap: Map<string, PartialUserInstrument>;
		composition: ReviewTicker[];
	};
	summary: BenchmarkSummary;
}) {
	const { t } = useTranslation();

	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);
	const { changeTableLayout, columnsMetadata } = useInstrumentColumnPreference({
		async applyColumnPreferenceApi(userInstrumentsColumnPreferences) {
			await portfolioStudioPreferencesApi.setUserInstrumentsColumnPreferences({
				userInstrumentsColumnPreferences,
			});
		},
		mapColumnMetadataFn: hideNameColumnMetadata,
	});

	const compositionColumns = useCompositionColumns();

	const { customizableColumns, tools, name } = useInstrumentsColumn({
		allColumns: columnsMetadata,
		mode: "VIEW",
		changeTableLayout,
	});

	const rowsWithUserInstrumentMetadata = useMemo(
		() =>
			response.composition.map((instrument): UserInstrumentDto & Omit<ReviewTicker, "description"> => {
				const { description: _description, type: _type, ...instrumentParams } = instrument;
				const userInstrument = response.userInstrumentsMap.get(instrument.ticker!) ?? {};
				return {
					...instrumentParams,
					...userInstrument,
					name: userInstrument.name ?? instrument.instrument,
					alias: userInstrument.alias ?? instrument.identifier,
				};
			}),
		[response],
	);

	const columns = useMemo<TableColumn<UserInstrumentDto & Omit<ReviewTicker, "description">>[]>(
		() => [name, ...customizableColumns, compositionColumns.WEIGHT(), tools],
		[compositionColumns, customizableColumns, name, tools],
	);

	return (
		<Card>
			<Text type="Body/XL/Bold" classList="mb-4">
				Composition
			</Text>
			<ReviewCompositionErrors errors={response.editorResponse?.uploadErrors} />
			<div className="mb-4" hidden={summary?.status === "RETRIEVING_DATA"}>
				<Banner severity="warning" title="Review Before Saving">
					Some instruments require your attention. This may be due to missing information or because they have been
					delisted. Please review the list below and make any necessary updates before saving.
				</Banner>
			</div>
			<div className="mb-4">
				<Banner severity="info">{t("PORTFOLIO_UPLOAD_ALERTS.CALCULATING_UPLOAD")}</Banner>
			</div>
			<AutoSortHScrollTable columns={columns} rows={rowsWithUserInstrumentMetadata} />
		</Card>
	);
}

export default CustomBenchmark;
