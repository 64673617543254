import type { AssetClassIdentifier, GlobalRisk } from "$root/api/api-gen";
import { HmmRegion, HmmTimeSeriesControllerApiFactory } from "$root/api/api-gen";
import { getApiGen } from "$root/api/factory";
import { useApiGen } from "$root/api/hooks";
import { useFunctionalAreas } from "$root/App/context";
import AuthorizationGuard, { hasAccess } from "$root/components/AuthorizationGuard";
import { useCrumbs } from "$root/components/Crumbs/useCrumbs";
import ColoredRectangle from "$root/components/icons/ColoredRectangle";
import { IconWalls } from "$root/components/IconWall";
import { PageHeader } from "$root/components/PageHeader";
import { useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import ReactQueryWrapper from "$root/components/ReactQueryWrapper";
import type { RiskIndicatorProps, RiskIndicatorSector } from "$root/components/RegimeAnalysisTool/RiskIndicator";
import { RiskIndicator, riskSectors, riskSectorsByType } from "$root/components/RegimeAnalysisTool/RiskIndicator";
import { RiskProbabilityBar } from "$root/components/RegimeAnalysisTool/RiskProbabilityBar";
import { rangesByValueOnMapType } from "$root/components/RegimeAnalysisTool/value-on-map-ranges";
import { defaultWorldGridGradient, generateDateRangesFromYear } from "$root/components/RegimeAnalysisTool/WorldGrid";
import { useEventBus } from "$root/event-bus";
import { useUserValue } from "$root/functional-areas/user";
import { useLocaleFormatters } from "$root/localization/hooks";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { Highcharts } from "$root/third-party-integrations/highcharts-with-modules";
import { Intercom } from "$root/third-party-integrations/initIntercom";
import { linearGradient, thresholdsToIntervals, typedObjectValues, useQueryNoRefetch } from "$root/utils";
import { CommentaryContext } from "$root/widgets-architecture/contexts/commentary";
import { RegionContext } from "$root/widgets-architecture/contexts/region";
import { Card } from "$root/widgets-architecture/layout/Card";
import WidgetsGrid from "$root/widgets-architecture/layout/WidgetsGrid";
import WidgetsMapper from "$root/widgets-architecture/layout/WidgetsMapper";
import { InfoTooltip } from "$root/widgets-architecture/layout/WidgetsMapper/InfoTooltip";
import { EvolutionOfRegimeProbabilitiesInner } from "$root/widgets-architecture/widgets/EvolutionOfRegimeProbabilitiesBlock";
import {
	ActionText,
	Button,
	Dialog,
	DialogFooter,
	Select,
	SubmitButton,
	Text,
	TinyIconButton,
	TooltipContent,
} from "@mdotm/mdotui/components";
import type { ImperativeHandleRefProps } from "@mdotm/mdotui/react-extensions";
import { ForEach, useDebouncedMemo } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { isBetween, mapBetweenRanges, noop } from "@mdotm/mdotui/utils";
import type { UseQueryResult } from "@tanstack/react-query";
import { format } from "date-fns";
import type { HighchartsReactRefObject } from "highcharts-react-official";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import { useTranslation } from "react-i18next";

const PAGE_NAME = "HMM";
const DEFAULT_REGION: HmmRegion = "EU";

const clientGeoToAssetClassIdentifier = {
	EU: "EUROPE",
	JP: "JAPAN",
	US: "USA",
} satisfies Record<"EU" | "US" | "JP", AssetClassIdentifier>;

const clientGeoToLabel = {
	EU: "Equity EU",
	JP: "Equity JP",
	US: "Equity US",
} satisfies Record<"EU" | "US" | "JP", string>;

const defaultMetrics = "REGIME_PROBABILITIES" as const;
function ProbabilityDistanceCard({
	queryProbabilityDistance,
	linkedRegion,
}: {
	queryProbabilityDistance: UseQueryResult<GlobalRisk[], unknown>;
	linkedRegion: "EU" | "US" | "JP";
}) {
	const { push } = useTypedNavigation();
	const latestRegime = queryProbabilityDistance.data?.at(-1);
	const regimeByGeo = latestRegime?.risks?.find((x) => x.geo === clientGeoToAssetClassIdentifier[linkedRegion]);
	const probabilityDistance = regimeByGeo?.assetClassess?.at(0);
	const riskLevelRange = rangesByValueOnMapType[defaultMetrics];
	const backgroundColor = linearGradient(
		mapBetweenRanges(probabilityDistance?.regimeProbability ?? 0, riskLevelRange.min, riskLevelRange.max, 0, 1),
		defaultWorldGridGradient,
	);

	return (
		<div
			className={`rounded-md px-4 py-2 shadow-xl hover:shadow-lg hover:bg-[color:${themeCSSVars.palette_N20}] transition-shadow overflow-hidden border-l-8 border-solid`}
			style={{ borderColor: backgroundColor }}
		>
			<Text type="Body/L/Bold" classList="mb-2">
				{clientGeoToLabel[linkedRegion]}
			</Text>
			<div className="flex justify-between">
				{queryProbabilityDistance.isFetching ? (
					<p>Probability distance: ...</p>
				) : (
					<p>Probability distance: {probabilityDistance?.valueOnMap ?? "-"}%</p>
				)}
				<ActionText onClick={() => push("RegimeAnalysisTool", { region: linkedRegion })} classList="font-semibold">
					Full analysis
				</ActionText>
			</div>
		</div>
	);
}

export default function RegimeAnalysisToolPage(props: { region?: string }): JSX.Element {
	const user = useUserValue();
	const defaultUserRegion = useMemo(
		() => (hasAccess(user, { requiredService: "GLOBAL_RISK_MAP" }) ? "GLOBAL" : DEFAULT_REGION),
		[user],
	);
	const region = useMemo(() => props.region ?? defaultUserRegion, [props.region, defaultUserRegion]) as HmmRegion;

	const hmmTimeSeriesApi = useApiGen(HmmTimeSeriesControllerApiFactory);

	const { push } = useTypedNavigation();
	const { t } = useTranslation();
	const { formatDate } = useLocaleFormatters();
	const crumbs = useCrumbs();

	const [updatedAt, setUpdatedAt] = useState<Date | null>(null);
	useEventBus("market-update", () => {
		setUpdatedAt(new Date());
	});

	useFunctionalAreas(
		() => ({
			areas: "hmm",
			data: { region },
		}),
		[region],
	);

	const queryProbabilityDistance = useQueryNoRefetch(["queryProbabilityDistance"], {
		queryFn: async () => {
			const timeSeries = await axiosExtract(hmmTimeSeriesApi.retrieveGlobalRiskMapDatesIsoFormat(defaultMetrics));
			const year = format(new Date(), "yyyy");
			const yearRange = generateDateRangesFromYear(year);
			const regimes = await axiosExtract(
				hmmTimeSeriesApi.retrieveGlobalRiskMapIsoFormat(
					defaultMetrics,
					yearRange.from,
					timeSeries.at(-1) ?? yearRange.to,
				),
			);
			return regimes;
		},
	});

	return (
		<AuthorizationGuard requiredService="SIGNALS" placeholder={<>{t("NOT_AUTHORIZED")}</>}>
			{() => (
				<>
					<PageHeader
						title={t("HMM") + (updatedAt ? " - " + formatDate(new Date(updatedAt)) : "")}
						crumbsV2={crumbs.marketInsights("Regime analysis tool", region)}
						titleAction={
							<Select
								strategy="fixed"
								classList="!w-36"
								value={region}
								onChange={(r) => r && push("RegimeAnalysisTool", { region: r })}
								options={typedObjectValues(HmmRegion)
									.filter((r) => r !== "GLOBAL" || hasAccess(user, { requiredService: "GLOBAL_RISK_MAP" }))
									.map((r) => ({
										label: r,
										value: r,
									}))}
							/>
						}
					/>
					{/* TODO: can we remove this div? */}
					<div
						style={{
							marginBottom: "1rem",
						}}
					/>
					{region && (
						<RegionContext.Provider value={{ region }}>
							<CommentaryContext.Provider value={{ fixedCommentary: true }}>
								{region === "GLOBAL" && hasAccess(user, { requiredService: "GLOBAL_RISK_MAP" }) ? (
									<div className="flex flex-col gap-4">
										<RiskThermometerSection />
										<WidgetsMapper widgetName="WorldGridMapBlock" />
										<Card title="In depth Market Regime Analysis">
											<div className="grid grid-cols-3 gap-4">
												<ForEach collection={["EU", "US", "JP"] as const}>
													{({ item: linkedRegion }) => (
														<ProbabilityDistanceCard
															queryProbabilityDistance={queryProbabilityDistance}
															linkedRegion={linkedRegion}
														/>
													)}
												</ForEach>
											</div>
										</Card>
									</div>
								) : (
									<WidgetsGrid gridName={PAGE_NAME} isDraggable={false} />
								)}
							</CommentaryContext.Provider>
						</RegionContext.Provider>
					)}
				</>
			)}
		</AuthorizationGuard>
	);
}

function RiskThermometerSection() {
	return (
		<ReactQueryWrapper
			queryKey={["riskThermometer"]}
			queryFn={() => axiosExtract(getApiGen(HmmTimeSeriesControllerApiFactory).retrieveRiskThermometer())}
		>
			{(data) => (
				<RiskThermometerSectionInner
					probabilityDistanceThresholds={
						(data.thresholds as [number, number, number, number, number, number]) ?? [-100, -60, -20, 20, 60, 100]
					}
					currentRegimeProbabilities={{
						highRisk: data.currentRegimeProbability?.highRisk ?? 0,
						mediumRisk: data.currentRegimeProbability?.mediumRisk ?? 0,
						lowRisk: data.currentRegimeProbability?.lowRisk ?? 0,
					}}
					currentProbabilityDistance={data.currentProbabilityDistance!}
					regimes={data.regimes as Record<RiskIndicatorSector["type"], { drawdown: number; volatility: number }>}
				/>
			)}
		</ReactQueryWrapper>
	);
}

const regimeNameInChart: Record<RiskIndicatorSector["type"], string> = {
	LOW: "Low",
	HIGH: "High",
	VERY_HIGH: "Very high",
	MEDIUM: "Medium",
	VERY_LOW: "Very low",
};

function RiskThermometerSectionInner(props: {
	currentProbabilityDistance: number;
	probabilityDistanceThresholds: RiskIndicatorProps["probabilityDistanceThresholds"];
	currentRegimeProbabilities: Record<"highRisk" | "mediumRisk" | "lowRisk", number>;
	regimes: Record<RiskIndicatorSector["type"], { drawdown: number; volatility: number }>;
}) {
	// const [selectedSectorIndex, setSelectedSectorIndex] = useState<number | null>(null);
	const [_hoveredSectorIndex, setHoveredSectorIndex] = useState<number | null>(null);
	const { value: hoveredSectorIndex } = useDebouncedMemo(() => _hoveredSectorIndex, [_hoveredSectorIndex], {
		debounceInterval: 20,
	});
	const intervals = thresholdsToIntervals(props.probabilityDistanceThresholds);
	const currentProbabilityDistance = props.currentProbabilityDistance;
	const currentScenarioIndex = intervals.findIndex((x) => isBetween(currentProbabilityDistance, x.min, x.max));
	const scenario = riskSectors[hoveredSectorIndex ?? currentScenarioIndex];
	const scenarioData = props.regimes[scenario.type];
	const { formatNumber } = useLocaleFormatters();

	const drawdownChartOptions = useMemo<Highcharts.Options>(
		() => ({
			chart: {
				style: {
					fontFamily: "Gotham,sans-serif",
					styledMode: true,
				},
			},
			credits: {
				enabled: false,
			},
			navigation: {
				buttonOptions: {
					enabled: false,
				},
			},
			title: {
				text: "",
			},
			yAxis: {
				title: { text: null },
				tickInterval: Math.round(
					(rangesByValueOnMapType.VALUE_AT_RISK.scenarioDescription.min -
						rangesByValueOnMapType.VALUE_AT_RISK.scenarioDescription.max) /
						5,
				),
				gridLineDashStyle: "Dash",
				labels: {
					formatter() {
						return `${formatNumber(this.value, 0)}%`;
					},
				},
				max: rangesByValueOnMapType.VALUE_AT_RISK.scenarioDescription.max,
				min: rangesByValueOnMapType.VALUE_AT_RISK.scenarioDescription.min,
			},
			tooltip: { enabled: false },
			xAxis: {
				type: "category",
			},
			plotOptions: {
				column: { animation: { duration: 0 } },
			},
			series: [
				{
					type: "column",
					name: "Value At Risk",
					point: {
						events: {
							mouseOver() {
								setHoveredSectorIndex(this.index);
							},
							mouseOut() {
								setHoveredSectorIndex(null);
							},
						},
					},
					pointWidth: 24,
					data: riskSectors.map((sector) => ({
						name: regimeNameInChart[sector.type],
						y: props.regimes[sector.type].drawdown,
						color: scenario.type === sector.type ? riskSectorsByType[scenario.type].color : themeCSSVars.palette_N200,
					})),
				},
			],
			legend: {
				enabled: false,
			},
		}),
		[formatNumber, props.regimes, scenario.type],
	);
	const volatilityChartOptions = useMemo<Highcharts.Options>(
		() => ({
			chart: {
				style: {
					fontFamily: "Gotham,sans-serif",
					styledMode: true,
				},
			},
			credits: {
				enabled: false,
			},
			navigation: {
				buttonOptions: {
					enabled: false,
				},
			},
			title: {
				text: "",
			},
			yAxis: {
				title: { text: null },
				tickInterval: Math.round(
					(rangesByValueOnMapType.VOLATILITY.scenarioDescription.max -
						rangesByValueOnMapType.VOLATILITY.scenarioDescription.min) /
						5,
				),
				gridLineDashStyle: "Dash",
				labels: {
					formatter() {
						return `${formatNumber(this.value, 0)}%`;
					},
				},
				max: rangesByValueOnMapType.VOLATILITY.scenarioDescription.max,
				min: rangesByValueOnMapType.VOLATILITY.scenarioDescription.min,
			},
			tooltip: { enabled: false },
			xAxis: {
				type: "category",
			},
			plotOptions: {
				column: { animation: { duration: 0 } },
			},
			series: [
				{
					type: "column",
					name: "Volatility",
					point: {
						events: {
							mouseOver() {
								setHoveredSectorIndex(this.index);
							},
							mouseOut() {
								setHoveredSectorIndex(null);
							},
						},
					},
					pointWidth: 24,
					data: riskSectors.map((sector) => ({
						name: regimeNameInChart[sector.type],
						y: props.regimes[sector.type].volatility,
						color: scenario.type === sector.type ? riskSectorsByType[scenario.type].color : themeCSSVars.palette_N200,
					})),
				},
			],
			legend: {
				enabled: false,
			},
		}),
		[formatNumber, props.regimes, scenario.type],
	);
	const [showHistoricalDataModal, setShowHistoricalDataModal] = useState(false);
	return (
		<>
			<Card
				title="Risk thermometer"
				actionHeader={
					<InfoTooltip>
						<TooltipContent>
							<Text type="Body/M/Book">
								Explore global risk dynamics through the interactive Global Risk Map, using the Probability Distance
								signal and the Risk Thermometer to interpret risk scenarios across asset classes. Discover more about
								Sphere’s risk signals and how these can be used by accessing more information. &nbsp;
							</Text>
							<ActionText type="Body/S/Book" onClick={() => Intercom.showArticle("globalRiskMap")}>
								Read more.
							</ActionText>
						</TooltipContent>
					</InfoTooltip>
				}
			>
				<div className="grid grid-cols-2 gap-5 max-w-[1920px] mx-auto w-full">
					<div
						className="border p-4 grow min-w-0 flex flex-row gap-4 relative"
						style={{
							borderColor: themeCSSVars.palette_N50,
						}}
					>
						<div className="w-[124px] pr-4 min-w-0">
							<Text type="Body/M/Bold" as="div" classList="mb-4">
								Current regime probability
							</Text>
							<div className="flex flex-col gap-4">
								<RiskProbabilityBar
									mode="single"
									risk="Low"
									weight={props.currentRegimeProbabilities.lowRisk}
									showCaption
								/>
								<RiskProbabilityBar
									mode="single"
									risk="Medium"
									weight={props.currentRegimeProbabilities.mediumRisk}
									showCaption
								/>
								<RiskProbabilityBar
									mode="single"
									risk="High"
									weight={props.currentRegimeProbabilities.highRisk}
									showCaption
								/>
							</div>
						</div>
						<div
							className="border-r border-dashed absolute left-[140px] -translate-x-1/2 inset-y-4"
							style={{
								borderColor: themeCSSVars.palette_N100,
							}}
						/>
						<div className="min-w-0 grow">
							<div className="flex justify-between items-center">
								<Text type="Body/M/Bold">Risk indicator</Text>
								<div>
									<TinyIconButton
										applyNegativeMargins
										size={16}
										color={themeCSSVars.palette_P500}
										icon="Ex-antevolatilitycontribution"
										onClick={() => setShowHistoricalDataModal(true)}
									/>
								</div>
							</div>
							<RiskIndicator
								hoveringSectorIndex={hoveredSectorIndex}
								selectedSectorIndex={null}
								// onSectorClick={(_, index) => setSelectedSectorIndex(selectedSectorIndex !== index ? index : null)}
								onSectorMouseEnter={(_, index) => setHoveredSectorIndex(index)}
								onSectorMouseLeave={() => setHoveredSectorIndex(null)}
								classList="h-56"
								probabilityDistanceThresholds={props.probabilityDistanceThresholds}
								currentProbabilityDistance={currentProbabilityDistance}
							/>
						</div>
					</div>
					<div
						className="border-l-4 grow min-w-0 px-4"
						style={{
							borderColor: scenario.color,
						}}
					>
						<Text type="Body/M/Bold" as="div" classList="mb-2">
							Scenario description
						</Text>
						<Text type="Body/M/Book" as="div" classList="mb-2" color={themeCSSVars.palette_N500}>
							Selected scenario: <Text type="Body/M/Bold">{scenario.label}</Text>&nbsp;&nbsp;&nbsp;Range [
							{`${formatNumber(intervals[hoveredSectorIndex ?? currentScenarioIndex].min)}%`} &mdash;{" "}
							{`${formatNumber(intervals[hoveredSectorIndex ?? currentScenarioIndex].max)}%`}]
						</Text>

						<div
							className="grid grid-cols-2 gap-4"
							// Patching highcharts which sometimes doesn't dispatch the mouseout event
							onMouseLeave={() => setHoveredSectorIndex(null)}
						>
							<div>
								<div className="flex justify-between">
									<Text type="Body/M/Bold" color={themeCSSVars.palette_N400}>
										Value At Risk
									</Text>
									<Text
										type="Body/M/Bold"
										as="div"
										classList="rounded w-20 text-center"
										style={{
											background: scenario.color,
											color:
												scenario.type === "VERY_HIGH" || scenario.type === "VERY_LOW"
													? themeCSSVars.palette_N0
													: themeCSSVars.palette_N700,
										}}
									>
										{formatNumber(scenarioData.drawdown)}%
									</Text>
								</div>
								<div>
									<HighchartsReact
										containerProps={{ style: { height: "230px" } }}
										highcharts={Highcharts}
										constructorType="chart"
										options={drawdownChartOptions}
									/>
								</div>
							</div>
							<div>
								<div className="flex justify-between">
									<Text type="Body/M/Bold" color={themeCSSVars.palette_N400}>
										Volatility
									</Text>
									<Text
										type="Body/M/Bold"
										as="div"
										classList="rounded w-20 text-center"
										style={{
											background: scenario.color,
											color:
												scenario.type === "VERY_HIGH" || scenario.type === "VERY_LOW"
													? themeCSSVars.palette_N0
													: themeCSSVars.palette_N700,
										}}
									>
										{formatNumber(scenarioData.volatility)}%
									</Text>
								</div>
								<div>
									<HighchartsReact
										containerProps={{ style: { height: "230px" } }}
										highcharts={Highcharts}
										constructorType="chart"
										options={volatilityChartOptions}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Card>
			<HistoricalDataDialog show={showHistoricalDataModal} onClose={() => setShowHistoricalDataModal(false)} />
		</>
	);
}

function HistoricalDataDialog(props: { show: boolean; onClose(): void }) {
	const evolutionOfRegimeProbabilitiesHandlesRef = useRef<{ download(): Promise<void> } | null>(null);
	const historicalProbabilityDistanceHandlesRef = useRef<{ download(): Promise<void> } | null>(null);
	return (
		<Dialog
			noValidate
			onClose={() => props.onClose()}
			onSubmitAsync={async () => {
				await Promise.all([
					evolutionOfRegimeProbabilitiesHandlesRef.current?.download(),
					historicalProbabilityDistanceHandlesRef.current?.download(),
				]);
				props.onClose();
			}}
			footer={
				<DialogFooter
					primaryAction={<SubmitButton>Download</SubmitButton>}
					neutralAction={
						<Button palette="tertiary" onClick={() => props.onClose()}>
							Close
						</Button>
					}
				/>
			}
			header="Historical data"
			show={props.show}
			size="xxlarge"
		>
			<div>
				<Text type="Body/L/Bold" as="div" classList="mb-6">
					Historical regime probabilities
				</Text>
				<div className="mb-6">
					<EvolutionOfRegimeProbabilitiesInner
						title="Historical regime probabilities"
						handleRef={evolutionOfRegimeProbabilitiesHandlesRef}
						description=""
						dataProvider={async () => {
							const { data: EvolutionOfRegime } = await getApiGen(
								HmmTimeSeriesControllerApiFactory,
							).retrieveEvolutionOfRegime1();

							const DateSeries = Object.keys(EvolutionOfRegime.growth ?? {}).map((yyyymmdd) => {
								return new Date(
									`${yyyymmdd.slice(0, 4)}-${yyyymmdd.slice(4, 6)}-${yyyymmdd.slice(-2)}T12:00:00Z`,
								).getTime();
							}); // No control if dates are not equal for all series
							const GrowSeries = Object.values(EvolutionOfRegime.growth ?? {});
							const LateralSeries = Object.values(EvolutionOfRegime.lateralPhase ?? {});
							const HighSeries = Object.values(EvolutionOfRegime.highStress ?? {});

							const EvolutionOfRegimeData = {
								DateSeries,
								GrowSeries,
								LateralSeries,
								HighSeries,
							};

							return EvolutionOfRegimeData;
						}}
					/>
				</div>
				<Text type="Body/L/Bold" as="div" classList="mb-6">
					Historical probability distance
				</Text>
				<div>
					<HistoricalProbabilityDistance
						handleRef={historicalProbabilityDistanceHandlesRef}
						dataProvider={async () => {
							const { timeSeries } = await axiosExtract(
								getApiGen(HmmTimeSeriesControllerApiFactory).retrieveOverviewTimeSeries2(),
							);
							return (timeSeries ?? []).map(([x, y]) => ({
								x: x * 1000,
								y,
							}));
						}}
					/>
				</div>
			</div>
		</Dialog>
	);
}

function HistoricalProbabilityDistance(
	props: {
		dataProvider(): Promise<Array<{ x: number; y: number }>>;
	} & ImperativeHandleRefProps<{ download(): Promise<void> }>,
): JSX.Element {
	const { isLoading, isError, isFetching, data } = useQueryNoRefetch(["queryHmmProbabilityDistance"], {
		queryFn: props.dataProvider,
	});

	const [queryPromiseData] = useState(() => {
		let resolve = noop;
		let reject = noop;
		const promise = new Promise((res, rej) => {
			resolve = res;
			reject = rej;
		});
		return { promise, resolve, reject };
	});
	useEffect(() => {
		if (isError) {
			queryPromiseData.reject();
		} else if (data) {
			queryPromiseData.resolve();
		}
	}, [data, isError, queryPromiseData]);

	const chartRef = useRef<HighchartsReactRefObject | null>(null);
	useImperativeHandle(
		props.handleRef,
		() => ({
			async download() {
				await queryPromiseData.promise;
				chartRef.current?.chart.exportChartLocal(
					{
						type: "image/jpeg",
					},
					chartRef.current.chart.options,
				);
			},
		}),
		[queryPromiseData.promise],
	);

	const { formatNumber, formatDate } = useLocaleFormatters();

	const options = useMemo<Highcharts.Options | null>(
		() =>
			!data
				? null
				: {
						chart: {
							plotBackgroundColor: themeCSSVars.palette_B20,
						},
						series: [
							{
								type: "line",
								// This specific Highcharts instance stopped working with the usual {x, y} object array
								data: data.map(({ x, y }) => [x, y]),
								color: themeCSSVars.palette_B400,
								zones: [
									{
										fillColor: "transparent",
									},
								],
							},
						],
						tooltip: {
							useHTML: true,
							padding: 0,
							borderWidth: 0,
							shared: true,
							shadow: false,
							formatter(this) {
								return renderToString(
									<div className="p-2 rounded bg-white min-w-[240px] shadow">
										<div className="py-1 mb-2 rounded bg-[#EFF0F3] text-center">
											<Text type="Body/M/Bold">Probability distance</Text>
										</div>
										{this && (
											<div className="flex justify-between mb-2">
												<div className="flex items-center gap-1">
													<ColoredRectangle variant="vertical" color={themeCSSVars.palette_B400} />
													<Text type="Body/M/Book">{formatDate(this.x)}</Text>
												</div>
												<Text type="Body/M/Bold">
													{`${formatNumber(this.y, { minDecimalPlaces: 0, maxDecimalPlaces: 2 })}%`}
												</Text>
											</div>
										)}
									</div>,
								);
							},
						},
						credits: { enabled: false },
						exporting: {
							enabled: true,
							chartOptions: {
								title: {
									text: "Historical probability distance",
								},
								subtitle: {
									text: `Data Exported in ${new Date().getUTCFullYear()}-${
										new Date().getUTCMonth() + 1
									}-${new Date().getUTCDate()}`,
								},
								chart: {
									margin: 40,
									marginTop: 80,
									marginLeft: 80,
									marginBottom: 80,
								},
								scrollbar: {
									enabled: false,
								},
							},
							allowHTML: true,
							printMaxWidth: 1080,
							filename: "Historical probability distance",
							sourceHeight: 400,
							sourceWidth: 1440,
							buttons: {
								contextButton: {
									menuItems: ["downloadJPEG", "downloadPDF"],
								},
							},
						},
				  },
		[data, formatDate, formatNumber],
	);

	return (
		<>
			{isError ? (
				<IconWalls.ErrorData />
			) : isLoading || isFetching ? (
				<IconWalls.LoadingData />
			) : (
				<div style={{ borderRadius: 4, borderColor: "#eeeef1", borderWidth: 2, borderStyle: "solid", flex: 1 }}>
					<HighchartsReact
						containerProps={{ style: { height: "380px" } }}
						highcharts={Highcharts}
						constructorType="stockChart"
						options={options}
						ref={chartRef}
					/>
				</div>
			)}
		</>
	);
}
