import { PaletteColors } from "$root/styles/themePalette";
import type { FieldSize, StylableProps } from "@mdotm/mdotui/components";
import { Icon, LoadingButton } from "@mdotm/mdotui/components";
import React, { useRef, useState } from "react";
import { flushSync } from "react-dom";

export type UploadButtonProps = {
	accept?: string;
	id?: string;
	loading?: boolean;
	size?: FieldSize;
	disabled?: boolean;
	label?: string;
	qualifier?:string;
} & (
	| {
			onChange(files: FileList | null): void;
			multiple: true;
	  }
	| {
			onChange(file: File | null): void;
			multiple?: false;
	  }
) &
	StylableProps;

const UploadButton = React.forwardRef<HTMLButtonElement, UploadButtonProps>(function UploadButton(
	{ accept, id, size, onChange, multiple = false, loading, disabled, label, classList, style, qualifier },
	ref,
) {
	const inputRef = useRef<HTMLInputElement | null>(null);
	const [requestId, setRequestId] = useState(0);

	return (
		<>
			<input
				disabled={disabled}
				required={false}
				key={requestId}
				multiple={multiple}
				ref={inputRef}
				value=""
				accept={accept}
				type="file"
				className="absolute opacity-0 pointer-events-none"
				onChange={() =>
					inputRef.current &&
					(multiple
						? (onChange as (files: FileList | null) => void)(inputRef.current.files ?? null)
						: (onChange as (file: File | null) => void)(inputRef.current.files?.[0] ?? null))
				}
				tabIndex={-1}
			/>
			<LoadingButton
				disabled={disabled}
				loading={loading}
				size={size}
				innerRef={ref}
				id={id}
				style={style}
				classList={classList}
				color="info"
				palette="primary"
				onClick={() => {
					flushSync(() => setRequestId((r) => (r + 1) % Number.MAX_SAFE_INTEGER));
					inputRef.current?.click();
				}}
				data-qualifier={qualifier}
			>
				{!loading && <Icon icon="Upload" color={PaletteColors.WHITE} size={15} />}
				{/* &nbsp;{t("UPLOAD_FILE")} */}
				{label && <>&nbsp; {label ?? "Select"}</>}
			</LoadingButton>
		</>
	);
});

export default UploadButton;
