// TODO: move all business logic to functional-areas/reports

import type { ReportTemplate as ReportTemplateV1 } from "./version/report-v1";
import { migrateV1ToV2, type ReportTemplate as ReportTemplateV2 } from "./version/report-v2";
import { migrateV2ToV3, type ReportTemplate as ReportTemplateV3 } from "./version/report-v3";
import { migrateV3ToV4, type ReportTemplate as ReportTemplateV4 } from "./version/report-v4";
import { migrateV4ToV5, type ReportTemplate as ReportTemplateV5 } from "./version/report-v5";
import { migrateV5ToV6, type ReportTemplate as ReportTemplateV6 } from "./version/report-v6";
import { migrateV6ToV7, type ReportTemplate as ReportTemplateV7 } from "./version/report-v7";
import { migrateV7ToV8, type ReportTemplate as ReportTemplateLatest } from "./version/report-v8";

export * from "./version/report-v8";

type MigrateFns = {
	2: (reportTemplate: ReportTemplateV1) => ReportTemplateV2;
	3: (reportTemplate: ReportTemplateV2) => ReportTemplateV3;
	4: (reportTemplate: ReportTemplateV3) => ReportTemplateV4;
	5: (reportTemplate: ReportTemplateV4) => ReportTemplateV5;
	6: (reportTemplate: ReportTemplateV5) => ReportTemplateV6;
	7: (reportTemplate: ReportTemplateV6) => ReportTemplateV7;
	8: (reportTemplate: ReportTemplateV7) => ReportTemplateLatest;
};
const migrationFns: MigrateFns = {
	2: migrateV1ToV2,
	3: migrateV2ToV3,
	4: migrateV3ToV4,
	5: migrateV4ToV5,
	6: migrateV5ToV6,
	7: migrateV6ToV7,
	8: migrateV7ToV8,
};

export const latestVersion = Number(Object.keys(migrationFns).at(-1)!);
export type ReportTemplateUnion =
	| ReportTemplateV1
	| ReportTemplateV2
	| ReportTemplateV3
	| ReportTemplateV4
	| ReportTemplateV5
	| ReportTemplateV6
	| ReportTemplateV7
	| ReportTemplateLatest;

export function migrateToLatest(reportTemplate: ReportTemplateUnion): ReportTemplateLatest {
	let currentReportTemplate = reportTemplate;

	for (let v = reportTemplate.data.version + 1; v <= latestVersion; v++) {
		currentReportTemplate = migrationFns[v as keyof MigrateFns](currentReportTemplate as any);
	}

	return currentReportTemplate as ReportTemplateLatest;
}
