import {
	DefaultTagLabels,
	PortfolioStudioPreferencesApiFactory,
	ReferenceUniversesControllerApiFactory,
	TickerControllerV1ApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { hasAccess } from "$root/components/AuthorizationGuard";
import { CustomLabels } from "$root/components/CustomLabels";
import { IconWalls, WallOverlay } from "$root/components/IconWall";
import { typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";
import { TagBadge } from "$root/components/tags/TagBadge";
import { useEventBus } from "$root/event-bus";
import { retrieveUserInstrumentsClassifications } from "$root/functional-areas/instruments-editor/builder";
import type { FrankensteinInstrument } from "$root/functional-areas/instruments-editor/const";
import {
	hideNameColumnMetadata,
	useInstrumentColumnPreference,
	useInstrumentsColumn,
	useSearchableInstrumentTable,
} from "$root/functional-areas/instruments/hooks";
import { exportUniverse } from "$root/functional-areas/universe/export";
import { useUserValue } from "$root/functional-areas/user";
import { formatNumber } from "$root/localization/formatters";
import { axiosExtract } from "$root/third-party-integrations/axios";
import type { ContextContent } from "$root/utils";
import { objMatchFn, stableColorGenerator, useQueryNoRefetch, withContext } from "$root/utils";
import { UniverseContext } from "$root/widgets-architecture/contexts/universe";
import { InfoTooltip } from "$root/widgets-architecture/layout/WidgetsMapper/InfoTooltip";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import type { TableColumn } from "@mdotm/mdotui/components";
import {
	ActionText,
	AutoSortHScrollTable,
	AutoTooltip,
	DropdownMenu,
	Icon,
	TableHeadCell,
	TextInput,
} from "@mdotm/mdotui/components";
import { overrideClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor, noop } from "@mdotm/mdotui/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

function UniverseComposition({ universe: externalUniverse }: ContextContent<typeof UniverseContext>) {
	const universeUuid = externalUniverse?.uuid;
	const { t } = useTranslation();
	const referenceUniverseV4Api = useApiGen(ReferenceUniversesControllerApiFactory);
	const tickerApi = useApiGen(TickerControllerV1ApiFactory);
	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);

	const user = useUserValue();

	const universeQuery = useQueryNoRefetch(["universeCompositionWidget", externalUniverse], {
		enabled: Boolean(universeUuid),
		queryFn: async () => {
			if (!universeUuid) {
				throw new Error("unable to load a universe of undefined");
			}

			const { composition } = await axiosExtract(referenceUniverseV4Api.getUniverseComposition(universeUuid));
			if (!composition || composition.length === 0) {
				return [];
			}

			const { userInstrumentsMap } = await retrieveUserInstrumentsClassifications(composition);
			const frankensteinComposition = composition.map((instrument) => {
				const { description: _description, type: _type, ...instrumentParams } = instrument;
				const userInstrument = userInstrumentsMap.get(instrument.ticker!) ?? {};
				return {
					...instrumentParams,
					...userInstrument,
					name: userInstrument.name ?? instrument.instrument ?? "",
					alias: userInstrument.alias ?? instrumentParams.identifier,
				} as FrankensteinInstrument;
			});
			return frankensteinComposition;
		},
	});

	const { data: instrumentRows, isError, isLoading, refetch } = universeQuery;
	const { changeTableLayout, columnsMetadata } = useInstrumentColumnPreference({
		async applyColumnPreferenceApi(userInstrumentsColumnPreferences) {
			await portfolioStudioPreferencesApi.setUserInstrumentsColumnPreferences({
				userInstrumentsColumnPreferences,
			});
		},
		mapColumnMetadataFn: hideNameColumnMetadata,
	});

	const { query, filtered, setQuery } = useSearchableInstrumentTable(instrumentRows ?? []);

	useEventBus("investment-update", {
		filter: objMatchFn({ uuid: universeUuid }),
		listener: () => {
			refetch().catch(noop);
		},
	});

	useWidgetOptions(
		() => ({
			isDraggable: false,
			title: t("UNIVERSE.READ_ONLY.NAME"),
			actionHeader: (
				<div className="flex space-x-2">
					<DropdownMenu
						position="bottom"
						align="startToEnd"
						trigger={({ innerRef, open, ...forward }) => (
							<button ref={innerRef} aria-expanded={open} type="button" {...forward}>
								<Icon icon="Dowload" color={themeCSSVars.MessageSeverity_success} size={20} />
							</button>
						)}
						actions={[
							{
								icon: "xls",
								onClickAsync: async () => {
									if (!universeUuid) {
										throw new Error("unable to load a universe of undefined");
									}
									await exportUniverse.downloadComposition(universeUuid);
								},
								label: "Instrument list",
							},
						]}
					/>
					<InfoTooltip>{t("UNIVERSE.READ_ONLY.DESCRIPTION")}</InfoTooltip>
				</div>
			),
		}),
		[t, universeUuid],
	);

	const mappedTags = useMemo(() => {
		const defaultLabels = Object.values(DefaultTagLabels) as string[];

		const tagsSet = new Set(
			defaultLabels.concat(
				instrumentRows?.filter(({ tagLabel }) => tagLabel).map((instument) => instument.tagLabel!) ?? [],
			),
		);

		return Array.from(tagsSet).reduce<{ [key: string]: string }>((acc, el) => {
			return {
				...acc,
				[el]: stableColorGenerator(el),
			};
		}, {});
	}, [instrumentRows]);

	const numberOfInstrumentTagged = useMemo(
		() => instrumentRows?.filter(({ tagLabel }) => tagLabel).length,
		[instrumentRows],
	);

	const {
		customizableColumns: instrumentColumns,
		tools,
		name,
	} = useInstrumentsColumn({
		allColumns: columnsMetadata,
		mode: "VIEW",
		changeTableLayout,
	});

	const columns = useMemo<Array<TableColumn<FrankensteinInstrument>>>(
		() => [
			{
				...name,
				minWidth: 460,
				maxWidth: 560,
			},
			...instrumentColumns,
			{
				header: t("PORTFOLIOS.CONSTRAINTS_TARGETS.TABLE.TAGS"),
				content: ({ tagLabel }, props) => (
					<div
						style={props.style}
						className={overrideClassName("flex items-center", props.classList)}
						onClick={props.onClick}
					>
						{!tagLabel || mappedTags[tagLabel] === undefined ? (
							""
						) : (
							<TagBadge color={mappedTags[tagLabel]}>{tagLabel}</TagBadge>
						)}
					</div>
				),
				minWidth: 150,
				sortFn: builtInSortFnFor("tagLabel"),
				name: "tagLabel",
				footer: numberOfInstrumentTagged
					? `Total instruments tagged ${numberOfInstrumentTagged}`
					: "No instruments tagged",
			},
			{
				header: (props) => (
					<TableHeadCell {...props}>
						{universeUuid ? <CustomLabels labelKey={`${universeUuid}_score1`} fallback={t("SCORE")} /> : t("SCORE")}
					</TableHeadCell>
				),
				content: ({ score }) => (score ? formatNumber(score) : ""),
				sortFn: builtInSortFnFor("score"),
				name: "score",
				align: "end",
				minWidth: 150,
				cellClassList: "tabular-nums",
				hidden: !hasAccess(user, { requiredService: "CUSTOM_QUALITIES" }),
			},
			tools,
		],
		[instrumentColumns, mappedTags, name, numberOfInstrumentTagged, t, tools, universeUuid, user],
	);

	return (
		<WallOverlay
			showOverlay={isLoading || isError}
			overlay={isError ? <IconWalls.ErrorData /> : <IconWalls.InfiniteProgress />}
			classList="min-h-0 grow flex flex-col"
			walledAppearance={{ classList: "min-h-0 grow flex flex-col" }}
		>
			<div className="flex justify-between mb-4">
				<div className="max-w-[500px] flex-1">
					<TextInput
						leftContent={<Icon icon="Search" />}
						value={query}
						onChangeText={setQuery}
						placeholder="Filter by instrument name, ISIN, asset class or micro asset class"
					/>
				</div>
				<div className="flex space-x-1 items-end">
					<ActionText
						color={themeCSSVars.palette_N400}
						href={typedUrlForRoute("PortfolioStudioSettings/InstrumentCustomisation", {
							// uuid: universeUuid, // TODO: regression from previous version, missing pre-selection of basket filter
						})}
					>
						Instruments customisation
					</ActionText>

					<AutoTooltip
						position="top"
						align="endToStart"
						mode="hover"
						overrideColor={themeCSSVars.palette_N400}
						trigger={({ innerRef }) => (
							<div ref={innerRef}>
								<Icon icon="info" color={themeCSSVars.palette_N400} size={18} />
							</div>
						)}
					>
						Explore all instruments used within your account, generate descriptions, and customize instrument details.
					</AutoTooltip>
				</div>
			</div>

			<AutoSortHScrollTable
				rows={filtered}
				columns={columns}
				pinnedColumns={[
					{ name: name.name, side: "left" },
					{ name: tools.name, side: "right" },
				]}
				noDataText="No instrument find in your universe"
			/>
		</WallOverlay>
	);
}

export const ReadonlyUniverseComposition = withContext(UniverseContext)(UniverseComposition);
