import type {
	ReferenceUniverseListEntry,
	UserUniverseColumnOrdering,
	UserUniverseColumnPreference,
	UserUniverseColumnPreferencePreferencesTypeEnum,
} from "$root/api/api-gen";
import { PortfolioStudioPreferencesApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { spawnYesNoDialog } from "$root/components/spawnable/yes-no-dialog";
import type { ColumnMetadata } from "$root/components/tables-extra/CustomizeColumns";
import { CustomizeColumns } from "$root/components/tables-extra/CustomizeColumns";
import { aclByArea, roleByArea } from "$root/functional-areas/acl/checkers/all";
import { validateACLPermissions } from "$root/functional-areas/acl/checkers/shared";
import { useUpdateAccessListControlDialog } from "$root/functional-areas/acl/hook/useUpdateAccessListControlDialog";
import { universeEntityManagementActions } from "$root/functional-areas/universe/entity-management";
import { exportUniverse } from "$root/functional-areas/universe/export";
import { useUserValue } from "$root/functional-areas/user";
import { platformToast } from "$root/notification-system/toast";
import { actionsColumn } from "$root/ui-lib/interactive-collections/common-table-actions";
import { parallelize, preventSubmitOnPressEnter } from "$root/utils";
import type { DropdownMenuProps, TableColumn } from "@mdotm/mdotui/components";
import {
	BaseHScrollTable,
	BatchActions,
	Icon,
	TextInput,
	contextMenuHandler,
	sortRows,
	useSelectableTableColumn,
} from "@mdotm/mdotui/components";
import { useDebouncedMemo } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { noop, nullary, unpromisify } from "@mdotm/mdotui/utils";
import type { QueryObserverBaseResult } from "@tanstack/react-query";
import { Map } from "immutable";
import type { FC } from "react";
import { useCallback, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { multipleEntityDelete, notifyUser } from "../common";
import { usePortfolioStudioTableSettings } from "../portfolio-studio-table-settings";
import { useUniverseColumn } from "./columns";

type UniverseListProps = {
	universes: ReferenceUniverseListEntry[];
	columnsPreferences?: UserUniverseColumnPreference[];
	isVisible: boolean;
	refetch: {
		universe: QueryObserverBaseResult<ReferenceUniverseListEntry[]>["refetch"];
		columnsPreferences: QueryObserverBaseResult<UserUniverseColumnOrdering>["refetch"];
	};
	portfolioStudioOuterDiv: HTMLDivElement | null;
};

const UniverseList: FC<UniverseListProps> = ({
	universes,
	refetch,
	columnsPreferences,
	isVisible,
	portfolioStudioOuterDiv,
}) => {
	const [searchQuery, setSearchQuery] = useState("");
	const [customizeTableModalVisible, setCustomizeTableModalVisible] = useState(false);

	const { t } = useTranslation();

	const user = useUserValue();
	const { aclSpawn } = useUpdateAccessListControlDialog("UNIVERSE");
	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);

	const { value: debouncedLowerCaseSearchQuery } = useDebouncedMemo(
		() => searchQuery.toLocaleLowerCase(),
		[searchQuery],
		{
			debounceInterval: 200,
		},
	);

	const filteredRows = useMemo(
		() =>
			universes.filter((item) =>
				[item.name ?? "", item.status ?? ""].some((el) => el.toLowerCase().includes(debouncedLowerCaseSearchQuery)),
			),
		[debouncedLowerCaseSearchQuery, universes],
	);

	const universeByUid = useMemo(() => Map(universes.map((p) => [p.uuid ?? "", p])), [universes]);
	const {
		column: checkboxColumn,
		rowClassList,
		multiSelectCtx: tableMultiSelectCtx,
	} = useSelectableTableColumn({
		rows: filteredRows,
		selectBy: (u) => u.uuid!,
	});

	const selection = useMemo(() => tableMultiSelectCtx.selection.toArray(), [tableMultiSelectCtx]);

	const { universeListOrderByName, setUniverseListOrderByName } = usePortfolioStudioTableSettings();
	const visibleColumns = useUniverseColumn(columnsPreferences);

	const getDropdownActions = useCallback(
		(universe: ReferenceUniverseListEntry): DropdownMenuProps<HTMLButtonElement, "">["actions"] => {
			const entityManagementActions = universeEntityManagementActions(user, universe, {
				onRename: unpromisify(nullary(refetch.universe)),
				onDuplicate: unpromisify(nullary(refetch.universe)),
				onDelete(uuid) {
					refetch.universe().catch(noop);
					tableMultiSelectCtx.remove(uuid);
				},
			});
			return [
				aclByArea.universe.canDelete(user.id, universe.richAcl?.acl ?? []) && {
					icon: "Delete",
					disabled: !entityManagementActions.deleteAsync,
					onClick: unpromisify(entityManagementActions.deleteAsync ?? noop),
					"data-qualifier": "PortfolioStudio/UniverseList/DropdownMenu/DropdownItem(Delete)",
					label: "Delete",
				},
				{
					icon: "Content-Copy",
					disabled: !entityManagementActions.duplicateAsync,
					onClick: unpromisify(entityManagementActions.duplicateAsync ?? noop),
					"data-qualifier": "PortfolioStudio/UniverseList/DropdownMenu/DropdownItem(Duplicate)",
					label: "Duplicate",
				},
				aclByArea.universe.canRename(user.id, universe.richAcl?.acl ?? []) && {
					icon: "Edit",
					onClick: unpromisify(entityManagementActions.renameAsync ?? noop),
					disabled: !entityManagementActions.renameAsync,
					"data-qualifier": "PortfolioStudio/UniverseList/DropdownMenu/DropdownItem(Rename)",
					label: "Rename",
				},
				validateACLPermissions(user.id, universe.richAcl?.acl ?? [], roleByArea.universe.EDITOR) && {
					icon: "share",
					onClickAsync: async () => {
						await aclSpawn(universe.name, universe.uuid, refetch.universe);
					},
					"data-qualifier": "PortfolioStudio/UniverseList/DropdownMenu/DropdownItem(Share)",
					label: "Share",
				},
			];
		},
		[user, refetch, tableMultiSelectCtx, aclSpawn],
	);

	const columns = useMemo<TableColumn<ReferenceUniverseListEntry>[] | null>(
		() =>
			visibleColumns
				? [
						checkboxColumn,
						...visibleColumns,
						actionsColumn({
							onSettingsClick: () => setCustomizeTableModalVisible(true),
							triggerProps: {
								"data-qualifier": "PortfolioStudio/UniverseList/DropdownMenu",
							},
							dropdownActions: getDropdownActions,
						}),
				  ]
				: null,
		[checkboxColumn, getDropdownActions, visibleColumns],
	);

	const filteredSortedRows = useMemo(
		() => (columns ? sortRows({ rows: filteredRows, columns, orderByArr: universeListOrderByName }) : filteredRows),
		[columns, filteredRows, universeListOrderByName],
	);

	const onChangeTableColumn = useCallback(
		async (data: ColumnMetadata<UserUniverseColumnPreferencePreferencesTypeEnum>[]) => {
			try {
				const payload = {
					userUniverseColumnPreferences: data.map((preference) => ({
						enabled: preference.visible,
						preferencesType: preference.id,
					})),
				} satisfies UserUniverseColumnOrdering;
				await portfolioStudioPreferencesApi.setUserUniverseColumnMetricsOrderingPreferences(payload);
				await refetch.columnsPreferences({ throwOnError: true });
				platformToast({
					children: "Table Successfully Edited.",
					severity: "success",
					icon: "Settings",
				});
			} catch (error) {
				platformToast({
					children: "Failed to update table",
					severity: "error",
					icon: "Settings",
				});
				throw new Error(String(error));
			} finally {
				setCustomizeTableModalVisible(false);
			}
		},
		[portfolioStudioPreferencesApi, refetch],
	);

	const customizableColumns = (columnsPreferences ?? []).map((c) => ({
		label: c.preferencesType ? t(`TABLE.HEADERS.${c.preferencesType}`) : "-",
		id: c.preferencesType!,
		visible: c.enabled ?? false,
		disabled: c.preferencesType === "NAME",
		hidden: c.preferencesType === "NAME",
	}));
	return (
		<>
			<CustomizeColumns
				show={customizeTableModalVisible}
				onClose={() => setCustomizeTableModalVisible(false)}
				columns={customizableColumns}
				// limit={Math.min(7, customizableColumns?.filter((x) => x.hidden === false).length ?? 1)}
				onSubmitAsync={onChangeTableColumn}
			/>
			<TextInput
				value={searchQuery}
				name="search"
				maxLength={60}
				onChangeText={setSearchQuery}
				placeholder={t("PORTFOLIOS.CONSTRAINTS_TARGETS.PORTFOLIO_LIST")}
				onKeyDown={preventSubmitOnPressEnter}
				style={{ width: 692 }}
				leftContent={<Icon icon="Search" size="1.4em" />}
				classList="mb-4"
			/>

			{columns && (
				<BaseHScrollTable
					onRowClick={(row) => row.uuid && tableMultiSelectCtx.toggle(row.uuid)}
					onRowContextMenu={(row, _index, e) => contextMenuHandler(e, getDropdownActions(row))}
					columns={columns}
					rows={filteredSortedRows}
					rowClassList={rowClassList}
					rowStyle={({ uuid }) => {
						const selected = tableMultiSelectCtx.selection?.has(uuid!);
						return selected ? { backgroundColor: themeCSSVars.Table_highlightedRowBackgroundColor } : {};
					}}
					noDataText="No data available"
					pinnedColumns={[
						{ name: "name", side: "left" },
						{ name: "settings-action", side: "right" },
					]}
					orderBy={universeListOrderByName}
					onOrderByChange={setUniverseListOrderByName}
				/>
			)}
			{portfolioStudioOuterDiv &&
				createPortal(
					isVisible && tableMultiSelectCtx.selection.size > 0 ? (
						<BatchActions
							classList={`sticky bottom-5 !bg-[color:${themeCSSVars.palette_N500}] h-11 px-2 rounded-md shadow-xl shadow-[color:${themeCSSVars.palette_N200}]`}
							selected={tableMultiSelectCtx.selection.size}
							total={universes.length}
							palette="quaternary"
							actions={[
								{
									label: "Delete",
									icon: "Delete",
									onClickAsync: () =>
										spawnYesNoDialog({
											size: "large",
											header: t("PORTFOLIOS.MODAL.BULK_DELETE.TITLE"),
											yesButton: t("BUTTON.DELETE"),
											noButton: t("BUTTON.CANCEL"),
											children: t("PORTFOLIOS.MODAL.BULK_DELETE.DESCRIPTION", {
												size: tableMultiSelectCtx.selection.size,
											}),
											onSubmitAsync: async () => {
												const items = selection.flatMap((uuid) => {
													const universe = universeByUid.get(uuid);
													return universe ? [universe] : [];
												});
												const response = await multipleEntityDelete({ area: "Universes", items });
												await refetch.universe({ throwOnError: true });
												tableMultiSelectCtx.reset();
												await notifyUser({ t, succeded: response.succeded, area: "Universes" });
											},
										})
											.then(noop)
											.catch(noop),

									disabled: tableMultiSelectCtx.selection.some(
										(id) =>
											(universeByUid?.get(id)?.referralInvestments ?? []).length !== 0 ||
											aclByArea.portfolio.canDelete(user.id, universeByUid?.get(id)?.richAcl?.acl ?? []) === false,
									),
									"data-qualifier": "UniverseList/BatchAction(Delete)",
								},
								{
									label: "Download",
									icon: "Dowload",
									dropdown: {
										actions: [
											{
												icon: "xls",
												onClickAsync: async () => {
													const callStack = tableMultiSelectCtx.selection
														.toArray()
														.map((id) => () => exportUniverse.downloadUniverse(universeByUid?.get(id)?.uuid ?? ""));
													await parallelize(callStack, { concurrency: 3 });
												},
												label: "Universe template",
												"data-qualifier": "UniverseList/DropdownItem(UniverseTemplate)",
											},
										],
									},
								},
							]}
						/>
					) : (
						<></>
					),
					portfolioStudioOuterDiv,
				)}
		</>
	);
};

export default UniverseList;
