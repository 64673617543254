import { HmmControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { IconWalls } from "$root/components/IconWall";
import { useLocaleFormatters } from "$root/localization/hooks";
import { PaletteColors } from "$root/styles/themePalette";
import type { ContextContent } from "$root/utils";
import { useQueryNoRefetch, withContext } from "$root/utils";
import { RegionContext } from "$root/widgets-architecture/contexts/region";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import { Text } from "@mdotm/mdotui/components";
import { Trans, useTranslation } from "react-i18next";
import BoxInfoGraph from "./BoxInfoGraph";

interface IMarketRegimeAnalysisBlock {
	boxes: {
		activeColor: string;
		label: string;
		active: boolean;
	}[];
	regime: {
		currentValue: number;
		prevValue: number;
		lastDay: string;
		consecutiveDays: string;
	};
}

const MarketRegimeAnalysisBlock = (props: ContextContent<typeof RegionContext>) => {
	const { t } = useTranslation();
	const region = props.region;
	const { formatDate } = useLocaleFormatters();

	const normalizeDate = (date: string) => {
		if (date !== "-") {
			const slicedDate = `${date.slice(-2)}/${date.slice(4, 6)}/${date.slice(0, 4)}`;
			const reverseDate = slicedDate.split("/").reverse().join("-");
			return formatDate(new Date(reverseDate));
		}
		return date ?? "-";
	};

	const defaultData: IMarketRegimeAnalysisBlock = {
		boxes: [
			{ label: t("MARKET_REGIME_ANALYSIS.OPTIONS.GROWTH"), active: false, activeColor: PaletteColors.GREENY_BLUE },
			{ label: t("MARKET_REGIME_ANALYSIS.OPTIONS.LATERAL_PHASE"), active: false, activeColor: PaletteColors.YELLOW },
			{ label: t("MARKET_REGIME_ANALYSIS.OPTIONS.HIGH_STRESS"), active: false, activeColor: PaletteColors.NEON_RED },
		],
		regime: {
			currentValue: 4,
			prevValue: 4,
			lastDay: "-",
			consecutiveDays: "-",
		},
	};

	const hmmApi = useApiGen(HmmControllerApiFactory);

	const { isLoading, isError, isFetching, data } = useQueryNoRefetch(["queryHmmMarketRegime", region], {
		queryFn: async () => {
			const { data: MarketRegimeAnalysis } = await hmmApi.retrieveMarketView(region);
			const MarketRegimeAnalysisData = {
				regime: {
					currentValue: [MarketRegimeAnalysis.currRegime ?? defaultData.regime.currentValue],
					prevValue: [MarketRegimeAnalysis.prevRegime ?? defaultData.regime.prevValue],
					lastDay: MarketRegimeAnalysis.lastDay ?? defaultData.regime.lastDay,
					consecutiveDays: MarketRegimeAnalysis.consecutiveDay ?? defaultData.regime.consecutiveDays,
				},
			};

			return MarketRegimeAnalysisData;
		},
	});

	useWidgetOptions(
		() => ({
			isDraggable: false,
			actionHeader: <></>,
			title: t("MARKET_REGIME_ANALYSIS.TITLE"),
			subTitle: (
				<Trans i18nKey="MARKET_REGIME_ANALYSIS.DESCRIPTION">
					<br />
				</Trans>
			),
		}),
		[t],
	);

	return (
		<>
			{isError ? (
				<IconWalls.ErrorData />
			) : isLoading || isFetching ? (
				<IconWalls.LoadingData />
			) : (
				<>
					<div style={{ marginTop: 24, height: "100%" }}>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<Text type="Body/L/Bold" style={{ paddingRight: 5 }}>
								{t("MARKET_REGIME_ANALYSIS.CURRENT_REGIME.TITLE")}
							</Text>
							<div style={{ display: "flex" }}>
								<Text type="Body/L/Bold" style={{ paddingRight: 5 }}>
									{t("MARKET_REGIME_ANALYSIS.CURRENT_REGIME.LAST_DAY")}
								</Text>
								<Text type="Body/L/Bold" style={{ paddingRight: 5 }}>
									{data.regime.consecutiveDays}
								</Text>
							</div>
						</div>
						<BoxInfoGraph
							classList="mt-4"
							boxes={defaultData.boxes}
							activeBoxes={data.regime.currentValue.map((x) => x - 1)}
						/>
					</div>
					<div style={{ marginTop: 24, height: "100%" }}>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<Text type="Body/L/Bold" style={{ paddingRight: 5 }}>
								{t("MARKET_REGIME_ANALYSIS.PREVIOUS_REGIME.TITLE")}
							</Text>
							<div style={{ display: "flex" }}>
								<Text type="Body/L/Bold" style={{ paddingRight: 5 }}>
									{t("MARKET_REGIME_ANALYSIS.PREVIOUS_REGIME.LAST_DAY")}
								</Text>
								<Text type="Body/L/Bold" style={{ paddingRight: 5 }}>
									{normalizeDate(data.regime.lastDay)}
								</Text>
							</div>
						</div>
						<BoxInfoGraph
							classList="mt-4"
							boxes={defaultData.boxes}
							activeBoxes={data.regime.prevValue.map((x) => x - 1)}
						/>
					</div>
				</>
			)}
		</>
	);
};

export default withContext(RegionContext)(MarketRegimeAnalysisBlock);
