import { typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";
import CreateCustomBenchmarkFromTemplateIcon from "$root/components/glyphs/CreateCustomBenchmarkFromTemplateIcon";
import CreateCustomBenchmarkIcon from "$root/components/glyphs/CreateCustomBenchmarkIcon";
import { InstrumentEditorEntity } from "$root/functional-areas/instruments-editor/const";
import { Dialog } from "@mdotm/mdotui/components";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, spawn } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { Link } from "react-router-dom";

type BenchmarkCreationDialogProps = { show: boolean; onClose?(): void; onSubmit(): void };
function BenchmarkCreationDialog(props: BenchmarkCreationDialogProps) {
	return (
		<Dialog show={props.show} size="medium" onClose={props.onClose}>
			<div className="grid grid-cols-2 gap-4 ">
				<Link
					to={typedUrlForRoute("Portfolios/ManualCreation", { entity: InstrumentEditorEntity.Benchmark })}
					className={`hover:bg-[color:${themeCSSVars.palette_N50}] hover:rounded-md p-4`}
					data-qualifier="PortfolioStudio/ReferenceList/NewBenchmark/Scratch"
					onClick={() => props.onSubmit()}
				>
					<CreateCustomBenchmarkIcon classList="mx-auto" />
					<p className="text-lg font-semibold text-center">Create</p>
					<p className="text-center">Select instruments and set weights</p>
				</Link>
				<Link
					to={typedUrlForRoute("Portfolios/ManualCreation", {
						entity: InstrumentEditorEntity.Benchmark,
						selectBenchmarkTemplate: "true",
					})}
					className={`hover:bg-[color:${themeCSSVars.palette_N50}] hover:rounded-md p-4`}
					data-qualifier="PortfolioStudio/ReferenceList/NewBenchmark/FromTemplate"
					onClick={() => props.onSubmit()}
				>
					<CreateCustomBenchmarkFromTemplateIcon classList="mx-auto" />
					<p className="text-lg font-semibold text-center">Select template</p>
					<p className="text-center">Start from an existing template</p>
				</Link>
			</div>
		</Dialog>
	);
}

type spawnBenchmarkCreationDialogProps = Omit<BenchmarkCreationDialogProps, "onClose" | "show" | "onSubmit">;
export function spawnBenchmarkCreationDialog(params: spawnBenchmarkCreationDialogProps): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ resolve, show }) => (
			<BenchmarkCreationDialog {...params} show={show} onClose={() => resolve()} onSubmit={() => resolve()} />
		)),
	);
}
