import type { StandardClassificationId, StandardInstrumentsClassificationDto } from "$root/api/api-gen";
import {
	InstrumentsClassificationsControllerV1ApiFactory,
	PortfolioStudioPreferencesApiFactory,
} from "$root/api/api-gen";
import { getApiGen } from "$root/api/factory";
import { useApiGen } from "$root/api/hooks";
import type {
	useInstrumentColumnPreferenceResultProps,
	UserColumnMetadata,
} from "$root/functional-areas/instruments/hooks";
import { hideNameColumnMetadata, useInstrumentColumnPreference } from "$root/functional-areas/instruments/hooks";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { useQueryNoRefetch } from "$root/utils";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { UseQueryResult } from "@tanstack/react-query";
import { useMemo } from "react";

export const useInstrumentCustomisationProvider = (): useInstrumentColumnPreferenceResultProps => {
	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);
	const instrumentColumnPreference = useInstrumentColumnPreference({
		async applyColumnPreferenceApi(userInstrumentsColumnPreferences) {
			await portfolioStudioPreferencesApi.setUserInstrumentsColumnPreferences({
				userInstrumentsColumnPreferences,
			});
		},
		mapColumnMetadataFn: hideNameColumnMetadata,
	});

	return instrumentColumnPreference;
};

const defaultStandardClassification: StandardClassificationId[] = [
	"ISIN",
	"MACRO_ASSET_CLASS",
	"MICRO_ASSET_CLASS",
	"REGION",
];
export const queryInstrumentStandardClassificationKey = "queryInstrumentStandardClassification";
export async function getStandardClassifications() {
	const instrumentsClassificationsControllerV1Api = getApiGen(InstrumentsClassificationsControllerV1ApiFactory);
	return axiosExtract(instrumentsClassificationsControllerV1Api.retrieveStandardClassification());
}
export const useInstrumentStandardProvider = (): Omit<
	useInstrumentColumnPreferenceResultProps,
	"queryInstrumentsColumn"
> & { queryStandardClassification: UseQueryResult<StandardInstrumentsClassificationDto[], unknown> } => {
	const queryStandardClassification = useQueryNoRefetch([queryInstrumentStandardClassificationKey], {
		queryFn() {
			return getStandardClassifications();
		},
	});

	const columnsMetadata = useMemo(() => {
		const standardClassifications = queryStandardClassification.data;
		if (!standardClassifications) {
			return [];
		}

		return standardClassifications
			.filter((x) => x.standardClassificationId)
			.map(
				(classification): UserColumnMetadata => ({
					...classification,
					name: classification.name ?? "-",
					preferenceType: {
						label: classification.name ?? "-",
						classificationUuid: classification.standardClassificationId!,
					},
					classificationUuid: classification.standardClassificationId!,
					standardClassificationId: classification.standardClassificationId!,
					enabled: defaultStandardClassification.includes(classification.standardClassificationId!),
				}),
			);
	}, [queryStandardClassification.data]);

	function changeTableLayout(newColumnPreferences: Array<UserColumnMetadata>): MaybePromise<void> {
		console.log(newColumnPreferences);
	}

	return { columnsMetadata, changeTableLayout, queryStandardClassification };
};
