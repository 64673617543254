import OverlayActions from "$root/components/OverlayActions";
import type { TableDataCellProps } from "@mdotm/mdotui/components";
import { Row, Text, usePulse } from "@mdotm/mdotui/components";
import { useMouseOver } from "@mdotm/mdotui/headless";
import type { Ref } from "react";
import { useMemo, useState } from "react";
import { flushSync } from "react-dom";

type CopyableTextCellProps = {
	text: string;
	cellProps: Omit<TableDataCellProps, "children">;
	innerRef?: Ref<HTMLElement> | undefined;
};

function CopyableTextCell({ text, cellProps, innerRef }: CopyableTextCellProps): JSX.Element {
	// const [overlayEl, setOverlayEl] = useState<HTMLElement | null>(null);
	const [rowEl, setRowEl] = useState<HTMLElement | null>(null);
	const [actionEl, setActionEl] = useState<HTMLButtonElement | null>(null);

	const isMouseOver = useMouseOver({
		elements: useMemo(() => [actionEl, rowEl], [rowEl, actionEl]),
		disabled: !text,
	});

	const [copied, setCopied] = useState(false);
	const pulsing = usePulse({ trigger: copied, duration: 500 });

	return (
		<OverlayActions
			options={[
				{
					icon: pulsing ? "Outline" : "Content-Copy",
					async onClickAsync(e) {
						e.stopPropagation();
						await window.navigator.clipboard.writeText(text);
						flushSync(() => {
							setCopied(false);
						});
						setCopied(true);
					},
					innerRef: setActionEl,
				},
			]}
			innerRef={innerRef}
			isOpen={isMouseOver}
			triggerLocation="around"
			position="right"
			trigger={({ innerRef: overlayRef }) => {
				return (
					<Row {...cellProps} innerRef={setRowEl} alignItems="center">
						<Text title={text} innerRef={overlayRef} type="Body/M/Book" classList="line-clamp-2">
							{text}
						</Text>
					</Row>
				);
			}}
		/>
	);
}

export default CopyableTextCell;
