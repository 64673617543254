import { getThemeCssVars } from "$root/utils";
import { Text } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import Color from "color";

type TagBadgeProps = { grey?: boolean; label: string; color?: string };

export function TagBadge({ color, label, grey = false }: TagBadgeProps): JSX.Element {
	const backgroundColor = grey
		? getThemeCssVars(themeCSSVars.palette_N50)
		: color ?? getThemeCssVars(themeCSSVars.palette_N50);
	const foregroundColor =
		Color(Color("#ffffff")).contrast(Color(backgroundColor)) > 3 ? themeCSSVars.palette_N0 : themeCSSVars.palette_N900;

	return (
		<div
			className="px-1 py-0.5 rounded shrink-0"
			style={{
				backgroundColor,
				color: foregroundColor,
			}}
		>
			<Text type="Body/S/Medium">{label}</Text>
		</div>
	);
}
