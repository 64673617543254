import { toClassName } from "@mdotm/mdotui/react-extensions";
import type { StylableProps } from "@mdotm/mdotui/components";
import { toClassListRecord } from "@mdotm/mdotui/react-extensions";

export type BoxInfoGraphProps = StylableProps & {
	boxes: {
		label: string;
		activeColor: string;
	}[];
	activeBoxes: number[];
};

function BoxInfoGraph({ boxes, activeBoxes, style, classList }: BoxInfoGraphProps): JSX.Element {
	return (
		<div
			className={toClassName({
				"flex flex-row space-x-2 h-16 p-2 border border-[#EEEEF1]": true,
				...toClassListRecord(classList),
			})}
			style={style}
		>
			{boxes.map((box, index) => {
				return (
					<div
						key={`k-${box.label}-${index}`}
						style={{
							backgroundColor: activeBoxes.includes(index) ? box.activeColor : "#EEEEF1",
							color: !activeBoxes.includes(index) ? "#8c8ea8" : "#ffffff",
						}}
						className="flex justify-center items-center flex-1 font-bold font-title rounded text-[11px]"
					>
						{box.label}
					</div>
				);
			})}
		</div>
	);
}

export default BoxInfoGraph;
