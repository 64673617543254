import { InvestmentsReportTemplateControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import MainBlock from "$root/components/EvolvedPrint/configuration";
import useExtractReportsData, {
	DefaultTemplateId,
} from "$root/components/EvolvedPrint/configuration/hooks/useExtractReports";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { getDataForTemplate } from "$root/functional-areas/reports/data-providers";
import { defaultPortfolioTemplates, DefaultReportTemplateName } from "$root/functional-areas/reports/default-templates";
import Portrait from "$root/functional-areas/reports/documents/Portrait";
import { ReportTranslationCtx } from "$root/functional-areas/reports/translation";
import { useUserValue } from "$root/functional-areas/user";
import type { Languages } from "$root/localization/i18n";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { useQueryNoRefetch } from "$root/utils";
import { CircularProgressBar } from "@mdotm/mdotui/components";
import * as dateFn from "date-fns";
import { lazy, useEffect } from "react";
import { useTranslation } from "react-i18next";
import type { ReportTemplate } from "../PortfolioStudioSettings/ReportEditor/report-latest";
import {
	latestVersion,
	migrateToLatest,
	type ReportTemplateStatus,
	type ReportTemplateUnion,
	type ReportTemplateVariant,
} from "../PortfolioStudioSettings/ReportEditor/report-latest";
import "./print.scss";

const Theme = lazy(() => import("../../components/EvolvedPrint/layout/portrait"));

const Report = (props: {
	templateId: string;
	objectId?: string;
	variant?: string;
	historyEventUuid?: string;
	enhanced?: string;
	autoScroll?: string;
	status?: string;
}): JSX.Element => {
	const initIubenda = document.querySelector("#iubenda-cs-banner");
	if (initIubenda) {
		initIubenda?.setAttribute("style", "display: none!important");
	}

	const isStatic = (Object.values(DefaultTemplateId) as string[]).includes(props.templateId);
	if (isStatic) {
		return <StaticReport {...props} />;
	} else {
		return <DynamicReport {...props} />;
	}
};

function DynamicReport(props: {
	templateId: string;
	objectId?: string;
	variant?: string;
	historyEventUuid?: string;
	status?: string;
	autoScroll?: string;
}) {
	const investmentsReportTemplateApi = useApiGen(InvestmentsReportTemplateControllerApiFactory);
	const user = useUserValue();
	const { data, format, templateName } = defaultPortfolioTemplates[0];

	async function loadTemplate(templateId: string): Promise<ReportTemplate> {
		const template = (await axiosExtract(
			investmentsReportTemplateApi.getInvestmentReportTemplate(templateId),
		)) as ReportTemplateUnion;

		if (template.data.version !== latestVersion) {
			const migratedTemplate = migrateToLatest(template);
			return migratedTemplate;
		}

		return template as ReportTemplate;
	}

	const query = useQueryNoRefetch({
		queryKey: ["report", props.templateId, props.objectId],
		queryFn: async () => {
			let template: ReportTemplate = {
				data,
				format,
				templateName,
				uuid: DefaultReportTemplateName.sphere,
				visible: true,
			};

			if (props.templateId !== DefaultReportTemplateName.sphere) {
				template = await loadTemplate(props.templateId);
			}
			return getDataForTemplate(
				props.objectId!,
				template,
				props.variant as ReportTemplateVariant,
				props.status as ReportTemplateStatus,
				user,
			);
		},
	});

	useEffect(() => {
		return () => {
			query.remove();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ReactQueryWrapperBase
			query={query}
			loadingFallback={
				<div className="h-full flex justify-center items-center">
					<CircularProgressBar value="indeterminate" />
				</div>
			}
		>
			{({ common, componentAndPropsList }) => (
				<ReportTranslationCtx.Provider value={common.language}>
					<Portrait
						title={common.portfolio.name ?? "Untitled"}
						subtitle={dateFn.format(new Date(), "do LLLL yyyy")}
						componentAndPropsList={componentAndPropsList}
						autoScroll={props.autoScroll === "true"}
					/>
				</ReportTranslationCtx.Provider>
			)}
		</ReactQueryWrapperBase>
	);
}

function StaticReport(props: {
	templateId: string;
	objectId?: string;
	variant?: string;
	historyEventUuid?: string;
	enhanced?: string;
}) {
	// TODO: do we need to support custom languages for static reports?
	const { i18n } = useTranslation();
	const language = i18n.language as Languages;
	const castedTemplateId = props.templateId as DefaultTemplateId;
	const {
		state: { data, isLoading, isError },
		// TODO: support custom id
	} = useExtractReportsData(
		castedTemplateId === "storyfolio"
			? {
					language,
					template: castedTemplateId,
					enhanced: Boolean(props.enhanced),
					uuid: props.objectId,
			  }
			: {
					language,
					template: castedTemplateId,
					historyEventUuid: props.historyEventUuid,
					uuid: props.objectId,
					variant: props.variant as ReportTemplateVariant,
			  },
	);

	if (isError) {
		return (
			<Theme>
				<div data-size="A4portrait" className="page_brake">
					<div className="h-full flex justify-center items-center">
						<p className="text-3xl">ERROR GENERATING PDF</p>
					</div>
				</div>
			</Theme>
		);
	}

	if (!data || isLoading) {
		return (
			<Theme>
				<div data-size="A4portrait" className="page_brake">
					<div className="h-full flex justify-center items-center">
						<CircularProgressBar value="indeterminate" />
					</div>
				</div>
			</Theme>
		);
	}

	return (
		<ReportTranslationCtx.Provider value={language}>
			<Theme>
				<section className="indicators">
					<div data-size="A4Template">
						<MainBlock {...data} layout="portrait" />
					</div>
				</section>
			</Theme>
		</ReportTranslationCtx.Provider>
	);
}

export default Report;
