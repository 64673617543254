import type {
	InvestmentCreationConfigurationControllerV4Api,
	InvestmentDraftConfigurationControllerV4Api,
	InvestmentEnhancementConfigurationControllerV4Api,
	PartialInvestableUniverseTicker,
} from "$root/api/api-gen";
import {
	InvestableUniverseSelectionStrategy,
	InvestmentCreationConfigurationControllerV4ApiFactory,
	InvestmentDraftConfigurationControllerV4ApiFactory,
	InvestmentEnhancementConfigurationControllerV4ApiFactory,
	InvestmentsStaticConfigurationControllerApiFactory,
	PartialInvestableUniverseTickerProxyOverwriteTypeEnum,
	PortfolioStudioPreferencesApiFactory,
	ReferenceUniversesControllerApiFactory,
} from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import { DataDisplayOverlay } from "$root/components/DataDisplayOverlay";
import { IconWalls } from "$root/components/IconWall";
import { useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { TagBadge } from "$root/components/tags/TagBadge";
import { labelToTag } from "$root/components/tags/shared";
import type { Tag } from "$root/components/tags/type";
import { retrieveUserInstrumentsClassifications } from "$root/functional-areas/instruments-editor/builder";
import type { PartialUserInstrument } from "$root/functional-areas/instruments-editor/const";
import { InstrumentEditorEntity } from "$root/functional-areas/instruments-editor/const";
import type { UserColumnMetadata } from "$root/functional-areas/instruments/hooks";
import {
	hideNameColumnMetadata,
	SphereColumn,
	useBaseInstrumentColumns,
	useInstrumentColumnPreference,
	useInstrumentColumnsTableV2,
	useInstrumentsColumn,
} from "$root/functional-areas/instruments/hooks";
import { useDebouncedNameUniquenessChecker } from "$root/functional-areas/named-entities/uniqueness";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { FormController } from "$root/third-party-integrations/react-hook-form";
import { zExt } from "$root/third-party-integrations/zod";
import RenderingGuard from "$root/ui-lib/renderingGuard";
import {
	countIf,
	objectTextSearchMatchFns,
	pxToRem,
	UnreachableError,
	useQueryNoRefetch,
	valueByPath,
} from "$root/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import type { TableColumn } from "@mdotm/mdotui/components";
import {
	AutoSortHScrollTable,
	Banner,
	Button,
	CircularProgressBar,
	colorBySeverity,
	Dialog,
	DialogHeader,
	FormField,
	Icon,
	ProgressBar,
	Radio,
	RadioGroup,
	Searchable,
	Select,
	TextInput,
} from "@mdotm/mdotui/components";
import { overrideClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor, unpromisify } from "@mdotm/mdotui/utils";
import { Map } from "immutable";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import type { Control, FormState } from "react-hook-form";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import { z } from "zod";
import type { EditPortfolioV4Props } from "../EditPortfolio";
import { StepBase } from "../StepBase";
import { assocStatusToEntity } from "../constraints/const";
import { useStepSync } from "../hooks";
import { makeStepToRequestMapper, responseToStepMapper } from "../requests";
import {
	useHandleSubmitToCustomSubmitHandlerInContext,
	type EditPortfolioV4StepPayloadMap,
	type StepPropsFor,
} from "../shared";

export function getInvestableUniverseStepData(
	createApi: Omit<InvestmentCreationConfigurationControllerV4Api, "basePath" | "axios">,
	enhanceApi: Omit<InvestmentEnhancementConfigurationControllerV4Api, "basePath" | "axios">,
	draftApi: Omit<InvestmentDraftConfigurationControllerV4Api, "basePath" | "axios">,
	area: EditPortfolioV4Props["area"],
): Promise<EditPortfolioV4StepPayloadMap["investableUniverse"]> {
	return (
		!area.portfolioUid
			? createApi.getCreationConfigurationInvestableUniverse()
			: area.name === "draft"
			  ? draftApi.getDraftConfigurationInvestableUniverse(area.portfolioUid)
			  : enhanceApi.getEnhancementConfigurationInvestableUniverse(area.portfolioUid)
	).then(({ data }) => responseToStepMapper.investableUniverse(data, area));
}

type CustomInstrumentProps = PartialInvestableUniverseTicker & { additional?: boolean };
type MixedUniverseInstrument = Omit<CustomInstrumentProps, "type"> & PartialUserInstrument;

const zodUniverseComposition = z.object({
	isin: z.string().optional().nullable(),
	additional: z.boolean().optional().nullable(),
	score: z.number().optional().nullable(),
	tagLabel: z.string().optional().nullable(),
	microAssetClass: z.string().optional().nullable(),
	instrument: z.string().optional().nullable(),
	weight: z.number().optional().nullable(),
	alias: z.string().optional().nullable(),
	assetClass: z.string().optional().nullable(),
	currency: z.string().optional().nullable(),
	identifier: z.string().optional().nullable(),
	ticker: z.string().optional().nullable(),
	tickerId: z.number().optional().nullable(),
	type: z.string().optional().nullable(),
	inInvestmentComposition: z.boolean().optional().nullable(),
	inUniverseComposition: z.boolean().optional().nullable(),
	proxies: z.array(z.any()).nullable(),
	// moneyMarket: z.boolean().optional().nullable(),
	customAttributes: z.any().nullable(),
	proxyOverwriteType: z
		.enum([
			PartialInvestableUniverseTickerProxyOverwriteTypeEnum.CustomHistorical,
			PartialInvestableUniverseTickerProxyOverwriteTypeEnum.HiddenLive,
			PartialInvestableUniverseTickerProxyOverwriteTypeEnum.Live,
			PartialInvestableUniverseTickerProxyOverwriteTypeEnum.PortfolioMixed,
		])
		.optional()
		.nullable(),
} satisfies Record<keyof CustomInstrumentProps, unknown>);

const strategyEnum = ["CREATE_NEW", "EXPAND", "SELL_INSTRUMENTS"] as const satisfies Array<
	InvestableUniverseSelectionStrategy[keyof InvestableUniverseSelectionStrategy]
>;

export function Step02_InvestableUniverse({
	context,
	stepMetadata,
	stepData,
	onStepDataChange,
	onStepError,
	toggleDirty,
}: StepPropsFor<"investableUniverse">): JSX.Element {
	const { saveHandlers, area } = context;
	const { t } = useTranslation();
	const [showUniverseDifferenceDialog, setShowUniverseDifferenceDialog] = useState(false);
	const [isLoadingUniverse, setIsLoadingUniverse] = useState(false);
	const edit = area.editable && area.edit;

	const [tags, setTags] = useState<Tag[]>();

	const previouseUniverseDataRef = useRef({
		...stepData,
		universeComposition: stepData.universeComposition as Array<CustomInstrumentProps>,
	});

	useEffect(() => {
		if (stepData.universeComposition.length > 0) {
			const setOfTags = Array.from(new Set(stepData.universeComposition.map((ticker) => ticker.tagLabel)))
				.filter((label) => label)
				.map((label, _i, set) => labelToTag({ label: label! }, set as string[]));
			setTags(setOfTags);
		}
	}, [stepData.universeComposition]);

	const { control, formState, handleSubmit, reset, watch, setValue } = useForm({
		defaultValues: {
			...stepData,
			universeComposition: stepData.universeComposition as Array<CustomInstrumentProps>,
			universeName: "",
		} satisfies typeof stepData,
		resolver: zodResolver(
			area.name === "create"
				? z.object({
						investableUniverseSelectionStrategy: z.undefined(),
						universeIdentifier: z.string({ invalid_type_error: "Please select a universe to continue" }),
						universeName: z.string({ invalid_type_error: "Please provide a name for your universe" }),
						universeComposition: z.array(zodUniverseComposition).optional(),
				  } satisfies Partial<Record<keyof typeof stepData, unknown>>)
				: z.discriminatedUnion(
						"investableUniverseSelectionStrategy",
						[
							z.object({
								investableUniverseSelectionStrategy: z.enum(strategyEnum).extract(["CREATE_NEW"]),
								universeIdentifier: z.string({ invalid_type_error: "Please select a universe to continue" }),
								universeName: z.string().nonempty(t("REQUIRED_FIELD")),
								universeComposition: z.array(zodUniverseComposition).optional(),
							}),
							z.object({
								investableUniverseSelectionStrategy: z.enum(strategyEnum).extract(["EXPAND"]),
								universeIdentifier: z.string({ invalid_type_error: "Please select a universe to continue" }),
								universeName: zExt.maybe(z.string({ invalid_type_error: "Please provide a name for your universe" })),
								universeComposition: z.array(zodUniverseComposition).optional(),
							}),
							z.object({
								investableUniverseSelectionStrategy: z.enum(strategyEnum).extract(["SELL_INSTRUMENTS"]),
								universeIdentifier: z.string({ invalid_type_error: "Please select a universe to continue" }),
								universeName: zExt.maybe(z.string({ invalid_type_error: "Please provide a name for your universe" })),
								universeComposition: z.array(zodUniverseComposition).optional(),
							}),
						],
						{
							errorMap: (ctx) => {
								if (ctx.code === "invalid_union_discriminator") {
									return { message: "Please select an option from the ones above" };
								}
								return { message: "An error occurred" };
							},
						},
				  ),
		),
	});

	const instruments = watch("universeComposition");
	const observedUniverseIdentifier = watch("universeIdentifier");

	/**
	 * memo referencedMemoStepData was created, by let user to start in a situation where he can have univerName as empty string
	 */
	const referencedMemoStepData = useMemo(
		() => ({
			investableUniverseSelectionStrategy: stepData.investableUniverseSelectionStrategy,
			universeComposition: stepData.universeComposition,
			universeIdentifier: stepData.universeIdentifier,
			universeName: "",
		}),
		[stepData.investableUniverseSelectionStrategy, stepData.universeComposition, stepData.universeIdentifier],
	);

	useStepSync({
		reset,
		toggleDirty,
		isDirty: formState.isDirty,
		stepData: referencedMemoStepData,
	});

	const createInvestmentApi = useApiGen(InvestmentCreationConfigurationControllerV4ApiFactory);
	const draftInvestmentApi = useApiGen(InvestmentDraftConfigurationControllerV4ApiFactory);
	const enhanceInvestmentApi = useApiGen(InvestmentEnhancementConfigurationControllerV4ApiFactory);
	const staticController = useApiGen(InvestmentsStaticConfigurationControllerApiFactory);
	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);

	const queryInvestableUniverse = useQueryNoRefetch(["queryInvestableUniverse"], {
		queryFn: async () => {
			const CURRENT_COMPOSITION_ID = "CREATE_FROM_CURRENT_COMPOSITION";
			const response = await match(context)
				.with({ area: { name: "enhance" } }, (x) =>
					axiosExtract(
						enhanceInvestmentApi.getEnhancementConfigurationSelectableInvestableUniverses(x.area.portfolioUid),
					),
				)
				.with({ area: { name: "settings-enhanced" } }, (x) =>
					axiosExtract(staticController.getStaticConfigurationSelectableInvestableUniverses(x.area.portfolioUid)),
				)
				.with({ area: { name: "settings-current" } }, (x) =>
					axiosExtract(staticController.getStaticConfigurationSelectableInvestableUniverses(x.area.portfolioUid)),
				)
				.with({ area: { name: "create" } }, () =>
					axiosExtract(createInvestmentApi.getCreationConfigurationSelectableInvestableUniverses()),
				)
				.with({ area: { name: "draft" } }, (x) =>
					axiosExtract(draftInvestmentApi.getDraftConfigurationSelectableInvestableUniverses(x.area.portfolioUid)),
				)
				.exhaustive();

			const options = response.map((universe) => ({
				label: assocStatusToEntity(universe.universeName, universe.status) ?? "",
				value: universe.universeIdentifier ?? "",
				group: universe.universeIdentifier === CURRENT_COMPOSITION_ID ? "" : "Available universes",
				disabled: universe.available === false,
			}));

			return options.sort(builtInSortFnFor("group"));
		},
	});

	const queryUserInstrumentsMap = useQueryNoRefetch(["queryInstrumentMap", instruments], {
		async queryFn() {
			const { userInstrumentsMap } = await retrieveUserInstrumentsClassifications(instruments);
			const rowsWithUserInstrumentMetadata = instruments.map((instrument): MixedUniverseInstrument => {
				const userInstrument = userInstrumentsMap?.get(instrument.ticker!) ?? {};
				return {
					...userInstrument,
					name: userInstrument.name ?? instrument.instrument,
					alias: userInstrument.alias ?? instrument.identifier,
					score: instrument.score,
					tagLabel: instrument.tagLabel,
					additional: instrument.additional,
				};
			});
			return { userInstrumentsMap, rowsWithUserInstrumentMetadata };
		},
	});

	const { rowsWithUserInstrumentMetadata = [] } = queryUserInstrumentsMap.data ?? {};

	const { changeTableLayout, columnsMetadata } = useInstrumentColumnPreference({
		async applyColumnPreferenceApi(userInstrumentsColumnPreferences) {
			await portfolioStudioPreferencesApi.setUserInstrumentsColumnPreferences({
				userInstrumentsColumnPreferences,
			});
		},
		mapColumnMetadataFn: hideNameColumnMetadata,
	});

	const onLoad = useCallback(
		async (universeIdentifier?: string) => {
			if (!universeIdentifier) {
				throw new Error("unable to find universeIdentifier of undefined");
			}

			const loadedUniverse = await match(area)
				.with({ name: "create" }, () =>
					axiosExtract(createInvestmentApi.loadCreationConfigurationInvestableUniverse(universeIdentifier)),
				)
				.with({ name: "enhance" }, (x) =>
					axiosExtract(
						enhanceInvestmentApi.loadEnhancementConfigurationInvestableUniverse(x.portfolioUid, universeIdentifier),
					),
				)
				.with({ name: "draft" }, (x) =>
					axiosExtract(draftInvestmentApi.loadDraftConfigurationInvestableUniverse(x.portfolioUid, universeIdentifier)),
				)
				.with({ name: "settings-enhanced" }, (x) =>
					axiosExtract(staticController.loadStaticConfigurationInvestableUniverse(x.portfolioUid, universeIdentifier)),
				)
				.with({ name: "settings-current" }, (x) =>
					axiosExtract(staticController.loadStaticConfigurationInvestableUniverse(x.portfolioUid, universeIdentifier)),
				)
				.exhaustive();

			const setOfTags = Array.from(new Set(loadedUniverse.map((ticker) => ticker.tagLabel)))
				.filter((label) => label)
				.map((label, _i, set) => labelToTag({ label: label! }, set as string[]));

			setTags(setOfTags);

			if (area.name === "create") {
				setValue("universeComposition", loadedUniverse);
				return;
			}

			const newInstrumentInInvestmentComposition = loadedUniverse.filter((c) => c.inInvestmentComposition === false);
			const newInstrumentInSelectedUniverseMap = Map(
				loadedUniverse.filter((u) => !u.inUniverseComposition && u.inInvestmentComposition).map((x) => [x.ticker!, x]),
			);
			const newInstrumentComposition = loadedUniverse.map((el) => ({
				...el,
				additional: newInstrumentInSelectedUniverseMap.has(el.ticker!),
			}));

			const additionalInstruments = newInstrumentComposition.filter(({ additional }) => additional);
			if (newInstrumentInSelectedUniverseMap.size === 0 && newInstrumentInInvestmentComposition.length === 0) {
				setValue("investableUniverseSelectionStrategy", "SELL_INSTRUMENTS", { shouldValidate: true });
			}

			if (additionalInstruments.length > 0 || universeIdentifier === "CREATE_FROM_CURRENT_COMPOSITION") {
				setShowUniverseDifferenceDialog(true);
				setValue(
					"investableUniverseSelectionStrategy",
					universeIdentifier === "CREATE_FROM_CURRENT_COMPOSITION" ? "CREATE_NEW" : /* "SELL_INSTRUMENTS" */ undefined,
					{ shouldValidate: true },
				);
				setValue("universeComposition", newInstrumentComposition);
			} else {
				setValue("investableUniverseSelectionStrategy", "SELL_INSTRUMENTS", { shouldValidate: true });
				setValue("universeComposition", newInstrumentComposition);
			}
		},
		[area, createInvestmentApi, enhanceInvestmentApi, draftInvestmentApi, staticController, setValue],
	);

	const stepToRequestMapper = useMemo(() => makeStepToRequestMapper(area.portfolioUid), [area.portfolioUid]);
	const { push } = useTypedNavigation();

	const onSubmitAsync = useHandleSubmitToCustomSubmitHandlerInContext({
		context,
		stepName: "investableUniverse",
		handleSubmit,
		baseSubmitFn: async (values) => {
			try {
				onStepDataChange(await saveHandlers.investableUniverse(values), { skipMetadataUpdate: true, persist: true });
				trackMixPanelEvent("Portfolio-Draft", {
					Type: area.portfolioUid ? "enhance" : "creation",
					UID: area.portfolioUid,
					Step: "investable-universe",
					UniverseName: values.universeName,
					UniverseIdentifier: values.universeIdentifier,
					Action: "save",
				});
			} catch (err) {
				onStepError();
				reportPlatformError(err, "ERROR", "portfolio-creation", {
					message: "Save universe step",
					payload: JSON.stringify(values),
				});
				throw err;
			}
		},
	});

	const instrumentColumns = useInstrumentColumnsTableV2("portfolioWizard/investableUniverse/instrumentsTable");
	const { customizableColumns, tools, name } = useInstrumentsColumn({
		allColumns: columnsMetadata,
		mode: "VIEW",
		changeTableLayout,
	});

	const columns = useMemo<Array<TableColumn<MixedUniverseInstrument>>>(
		() => [
			name,
			...customizableColumns,
			{
				header: t("TABLE.HEADERS.STATUS"),
				content: ({ additional }, cellProps) => {
					const strategy = watch("investableUniverseSelectionStrategy");

					if (additional === undefined || additional === false) {
						return "";
					}

					return (
						<div
							style={cellProps.style}
							className={overrideClassName("flex items-center", cellProps.classList)}
							onClick={cellProps.onClick}
						>
							{strategy === "SELL_INSTRUMENTS" ? (
								<TagBadge color="#A0A7B6">{t("EXCLUDE")}</TagBadge>
							) : (
								<TagBadge color="#A0A7B6">{t("NEW", {})}</TagBadge>
							)}
						</div>
					);
				},
				sortFn: builtInSortFnFor("additional"),
				name: "additional",
				minWidth: 150,
				hidden: area.name === "create",
			},
			{
				header: t("TABLE.HEADERS.TAGS"),
				content: ({ tagLabel }, cellProps) => {
					const currentTag = tags?.find((item) => item.name === tagLabel);
					if (currentTag === undefined) {
						return "";
					}
					return (
						<div
							style={cellProps.style}
							className={overrideClassName("flex items-center", cellProps.classList)}
							onClick={cellProps.onClick}
						>
							<TagBadge color={currentTag.color}>{currentTag.name}</TagBadge>
						</div>
					);
				},
				sortFn: (a, b) => {
					const firstTag = a.tagLabel ?? "";
					const secondTag = b.tagLabel ?? "";
					if (firstTag > secondTag) {
						return 1;
					}
					if (firstTag < secondTag) {
						return -1;
					}
					return 0;
				},
				name: "tags",
				width: 244,
				footerCellClassList: "font-semibold !justify-start",
				footer: () => countIf(instruments, ({ tagLabel }) => Boolean(tagLabel)),
			},
			instrumentColumns.customLabels({ universeIdentifier: observedUniverseIdentifier }),
			tools,
		],
		[
			area.name,
			customizableColumns,
			instrumentColumns,
			instruments,
			name,
			observedUniverseIdentifier,
			t,
			tags,
			tools,
			watch,
		],
	);

	return (
		<>
			<UniverseDifferenceDialog
				columnsMetadata={columnsMetadata}
				formState={formState}
				control={control}
				instruments={rowsWithUserInstrumentMetadata || []}
				show={showUniverseDifferenceDialog && !queryUserInstrumentsMap.isFetching}
				onClose={() => {
					reset({
						investableUniverseSelectionStrategy: previouseUniverseDataRef.current.investableUniverseSelectionStrategy,
						universeComposition: previouseUniverseDataRef.current.universeComposition,
						universeIdentifier: previouseUniverseDataRef.current.universeIdentifier,
						universeName: previouseUniverseDataRef.current.universeName,
					});
					setShowUniverseDifferenceDialog(false);
				}}
				onConfirm={() => {
					setShowUniverseDifferenceDialog(false);
					previouseUniverseDataRef.current = watch();
				}}
				area={area.name}
			/>
			<StepBase optional={stepMetadata.optional} title="Investable universe" onSubmitAsync={() => onSubmitAsync()}>
				{(area.name === "settings-current" || area.name === "settings-enhanced") && !edit ? (
					<>
						{stepData.universeName || rowsWithUserInstrumentMetadata.length > 0 ? (
							stepData.universeName
						) : (
							<div className="h-[50dvh]">
								<IconWalls.ConstraintNotSet constraintName="universe" />
							</div>
						)}
					</>
				) : (
					<div
						className={`bg-[${themeCSSVars.global_palette_neutral_50}] z-0 relative -mx-4 px-4 py-4 mb-6 border-y border-[${themeCSSVars.global_palette_neutral_200}]`}
					>
						<ReactQueryWrapperBase query={queryInvestableUniverse}>
							{(options) => (
								<div className="flex items-end space-x-2">
									<FormField
										label={t("PORTFOLIOS.PORTFOLIO_UNIVERSE_PLACEHOLDER")}
										error={(valueByPath(formState.errors, "universeIdentifier") as { message?: string })?.message}
										classList="w-[413px]"
									>
										{(fieldProps) => (
											<FormController
												control={control}
												name="universeIdentifier"
												render={({ field: { ref, ...controllerProps } }) => (
													<Select
														enableSearch
														innerRef={(e) => {
															ref(e);
															e?.setAttribute(
																"data-qualifier",
																"portfolioWizard/investableUniverse/universeIdentifier",
															);
														}}
														data-qualifier="portfolioWizard/investableUniverse/loadUniverse"
														{...fieldProps}
														{...controllerProps}
														options={options}
														disabled={edit === false}
														onChange={unpromisify(async (universeIdentifier) => {
															try {
																setIsLoadingUniverse(true);
																controllerProps.onChange(universeIdentifier);
																await onLoad(universeIdentifier);
															} catch (error) {
																controllerProps.onChange(null);
																throw new UnreachableError();
															} finally {
																setIsLoadingUniverse(false);
															}
														})}
													/>
												)}
											/>
										)}
									</FormField>
								</div>
							)}
						</ReactQueryWrapperBase>
					</div>
				)}
				<DataDisplayOverlay
					dataProvider={() => ({
						formData: watch(),
						requestBody: stepToRequestMapper.investableUniverse(watch()),
					})}
					dataSource="Investable Universe"
				/>
				{edit && rowsWithUserInstrumentMetadata?.length === 0 && (
					<Banner severity="info">
						If you have a specific universe in mind, you can{" "}
						<button
							data-qualifier="portfolioWizard/investableUniverse/uploadYourOwnUniverse"
							type="button"
							onClick={() => push("Portfolios/ManualCreation", { entity: InstrumentEditorEntity.Universe })}
							className="underline font-medium"
						>
							upload your own universe
						</button>{" "}
						in the “Universes” section of Portfolio Studio. Alternatively, you can{" "}
						<button type="button" onClick={() => console.log("TODO")} className="font-bold">
							choose from our range of MDOTM universes
						</button>
						, which cover various asset classes, including indices, funds and ETFs, as well as single stocks. Select the
						option that aligns with your preferences and requirements.
					</Banner>
				)}

				{(isLoadingUniverse || queryUserInstrumentsMap.isFetching) && (
					<div className="my-2">
						<ProgressBar value="indeterminate" />
					</div>
				)}
				<RenderingGuard
					type="condition"
					when={
						rowsWithUserInstrumentMetadata !== undefined &&
						rowsWithUserInstrumentMetadata.length > 0 &&
						showUniverseDifferenceDialog === false
					}
				>
					<h3 className="font-semibold mb-4">Universe preview</h3>
					<p className="mb-4">To create or edit tags open the universe in the universes section and click edit.</p>
					<Searchable matchFn={objectTextSearchMatchFns.keyword} collection={rowsWithUserInstrumentMetadata ?? []}>
						{({ query, setQuery, filtered }) => {
							return (
								<>
									<div className="mb-4">
										<TextInput
											data-qualifier="portfolioWizard/investableUniverse/instrumentsQuery"
											placeholder="Search by instruments name, identifier o asset class"
											value={query}
											onChangeText={setQuery}
											style={{ width: pxToRem(692) }}
											size="small"
											leftContent={<Icon icon="Search" />}
										/>
									</div>
									<AutoSortHScrollTable
										data-qualifier="portfolioWizard/investableUniverse/instrumentsTable"
										rows={filtered}
										noDataText="Create universe"
										columns={columns}
										pinnedColumns={[
											{ name: name.name, side: "left" },
											{ name: tools.name, side: "right" },
										]}
									/>
								</>
							);
						}}
					</Searchable>
				</RenderingGuard>
			</StepBase>
		</>
	);
}

type UniverseDifferenceDialogProps = {
	show: boolean;
	area: "settings-current" | "settings-enhanced" | "create" | "enhance" | "draft";
	onClose(): void;
	onConfirm(instruments: Array<CustomInstrumentProps & PartialUserInstrument>): void;
	instruments: Array<CustomInstrumentProps & PartialUserInstrument>; // TODO: actual type
	formState: FormState<StepPropsFor<"investableUniverse">["stepData"]>;
	control: Control<StepPropsFor<"investableUniverse">["stepData"], any>;
	columnsMetadata: UserColumnMetadata[];
};

function UniverseDifferenceDialog({
	onConfirm,
	onClose,
	show,
	instruments,
	control,
	formState,
	area,
	columnsMetadata,
}: UniverseDifferenceDialogProps) {
	const { t } = useTranslation();
	// const instrumentColumnsAdditional = useInstrumentColumnsTableV2(
	// 	"portfolioWizard/investableUniverse/universeDiff/additionalInstruments",
	// );
	// const instrumentColumnsFromCurrent = useInstrumentColumnsTableV2(
	// 	"portfolioWizard/investableUniverse/universeDiff/fromCurrentComposition",
	// );
	// const columnsAdditional = useMemo(
	// 	() => [instrumentColumnsAdditional.instrument(), instrumentColumnsAdditional.isin],
	// 	[instrumentColumnsAdditional],
	// );
	// const columnsFromCurrent = useMemo(
	// 	() => [instrumentColumnsFromCurrent.instrument(), instrumentColumnsFromCurrent.isin],
	// 	[instrumentColumnsFromCurrent],
	// );

	const baseInstrumentColumn = useBaseInstrumentColumns(columnsMetadata);
	const columns = useMemo<TableColumn<CustomInstrumentProps & PartialUserInstrument>[]>(
		() => [
			baseInstrumentColumn.name({
				mode: "VIEW",
				columnMetadata: { name: "Name", preferenceType: { classificationUuid: SphereColumn.name } },
				columnsMetadata,
			}),
			baseInstrumentColumn.identifier({
				mode: "VIEW",
				columnMetadata: { name: "Identifier", preferenceType: { classificationUuid: SphereColumn.identifier } },
				columnsMetadata,
			}),
		],
		[baseInstrumentColumn, columnsMetadata],
	);

	const universeIdentifier = useWatch({
		control,
		name: "universeIdentifier",
	});

	// TODO: should we put some rule on universe name ?
	const universeName = useWatch({
		control,
		name: "universeName",
		defaultValue: "",
	});

	const strategy = useWatch({
		control,
		name: "investableUniverseSelectionStrategy",
	});

	const referenceUniversesV4Api = useApiGen(ReferenceUniversesControllerApiFactory);

	const { checkIfNameIsAvailable, checkingNameUniqueness } = useDebouncedNameUniquenessChecker({
		isNameAvailableApi: (name, opts) => axiosExtract(referenceUniversesV4Api.isUniverseNameAvailable(name, opts)),
	});

	const universeExistsQuery = useQueryNoRefetch({
		enabled: area === "create" || area === "enhance" || area === "draft",
		queryFn: async () => !(await checkIfNameIsAvailable(universeName)),
		queryKey: ["universeNameExists", universeName],
	});

	const universeNameNotAvailable = universeExistsQuery.data ?? false;
	const universeNameNotAvailableMessage = useMemo(
		() => (universeNameNotAvailable ? "Name not available" : undefined),
		[universeNameNotAvailable],
	);

	const isConfirmDisabled = useMemo(() => {
		if (strategy === undefined) {
			return true;
		}

		if (strategy === "CREATE_NEW") {
			return (
				universeName === undefined || universeName === "" || universeNameNotAvailable === true || checkingNameUniqueness
			);
		}
	}, [checkingNameUniqueness, strategy, universeName, universeNameNotAvailable]);

	const additionalInstruments = useMemo(() => instruments.filter(({ additional }) => additional), [instruments]);

	return (
		<Dialog
			data-qualifier="portfolioWizard/investableUniverse/universeDiff"
			show={show}
			size="xxlarge"
			footer={
				<div className="flex justify-between">
					<Button
						data-qualifier="portfolioWizard/investableUniverse/universeDiff/cancel"
						onClick={onClose}
						palette="tertiary"
					>
						{t("BUTTON.CANCEL")}
					</Button>
					<Button
						data-qualifier="portfolioWizard/investableUniverse/universeDiff/confirm"
						disabled={isConfirmDisabled}
						onClick={() => onConfirm(instruments)}
						palette="primary"
					>
						{universeIdentifier === "CREATE_FROM_CURRENT_COMPOSITION" ? "Create universe" : t("BUTTON.CONFIRM")}
					</Button>
				</div>
			}
			header={
				<DialogHeader
					data-qualifier="portfolioWizard/investableUniverse/universeDiff/title"
					icon={
						universeIdentifier === "CREATE_FROM_CURRENT_COMPOSITION" ? (
							<></>
						) : (
							<Icon icon="Icon-full-alert" color={colorBySeverity.warning} />
						)
					}
				>
					{universeIdentifier === "CREATE_FROM_CURRENT_COMPOSITION"
						? "Create a new universe from the portfolio composition"
						: "Some instruments are not included the selected universe."}
				</DialogHeader>
			}
		>
			{universeIdentifier === "CREATE_FROM_CURRENT_COMPOSITION" && (
				<div className="mb-4" data-qualifier="portfolioWizard/investableUniverse/universeDiff/hint">
					To ensure accurate portfolio management, all portfolio instruments must be contained within an universe. If
					you didn&apos;t find an appropriate existing universe to connect this portfolio, you can create one from it.{" "}
				</div>
			)}
			{universeIdentifier !== "CREATE_FROM_CURRENT_COMPOSITION" && (
				<>
					<div data-qualifier="portfolioWizard/investableUniverse/universeDiff/warning">
						Sphere has detected that {additionalInstruments.length} instruments in your portfolio are not included in
						the selected universe. To ensure accurate portfolio management, all portfolio instruments must be contained
						within the universe.
					</div>

					<AutoSortHScrollTable
						data-qualifier="portfolioWizard/investableUniverse/universeDiff/additionalInstruments"
						style={{ maxHeight: 400 }}
						rows={additionalInstruments}
						classList="mb-4"
						columns={columns}
					/>
					{(area === "create" || area === "enhance" || area === "settings-current" || area === "settings-enhanced") && (
						<div className="mb-4">
							<div className="mb-4">You have a few options to address this issue:</div>
							<FormController
								control={control}
								name="investableUniverseSelectionStrategy"
								render={({ field: { value, onChange }, fieldState }) => (
									<>
										<RadioGroup value={value} onChange={onChange}>
											<div
												data-qualifier="portfolioWizard/investableUniverse/universeDiff/strategy"
												className="flex flex-row flex-wrap gap-4"
											>
												<Radio
													data-qualifier="portfolioWizard/investableUniverse/universeDiff/strategy(expand)"
													value={InvestableUniverseSelectionStrategy.Expand}
												>
													Expand selected universe
												</Radio>
												<Radio
													data-qualifier="portfolioWizard/investableUniverse/universeDiff/strategy(createNew)"
													value={InvestableUniverseSelectionStrategy.CreateNew}
												>
													Create new universe
												</Radio>
												<Radio
													data-qualifier="portfolioWizard/investableUniverse/universeDiff/strategy(sellInstruments)"
													value={InvestableUniverseSelectionStrategy.SellInstruments}
												>
													Sell instruments
												</Radio>
											</div>
										</RadioGroup>
										{fieldState.error?.message &&
											// #workaround: for some reason the error persists after the selection, with this additional check we can hide it once the user performs the selection
											value == null && (
												<div className="mt-4">
													<Banner
														data-qualifier="portfolioWizard/investableUniverse/universeDiff/error"
														severity="error"
													>
														{fieldState.error?.message}
													</Banner>
												</div>
											)}
									</>
								)}
							/>
						</div>
					)}
				</>
			)}

			<div className="max-w-md">
				{strategy === "CREATE_NEW" && (
					<FormController
						control={control}
						name="universeName"
						render={({ field: { onChange, ref, ...controllerProps } }) => (
							<FormField
								label="Universe name"
								error={formState.errors.universeName?.message ?? universeNameNotAvailableMessage}
							>
								{(fieldProps) => (
									<TextInput
										data-qualifier="portfolioWizard/investableUniverse/universeDiff/name"
										{...controllerProps}
										onChangeText={onChange}
										innerRef={ref}
										{...fieldProps}
										rightContent={
											checkingNameUniqueness ? <CircularProgressBar classList="w-3" value="indeterminate" /> : undefined
										}
									/>
								)}
							</FormField>
						)}
					/>
				)}
			</div>

			{universeIdentifier === "CREATE_FROM_CURRENT_COMPOSITION" && (
				<AutoSortHScrollTable
					data-qualifier="portfolioWizard/investableUniverse/universeDiff/fromCurrentComposition"
					style={{ maxHeight: 400 }}
					rows={instruments}
					classList="mt-4"
					columns={columns}
				/>
			)}
		</Dialog>
	);
}
