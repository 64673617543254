import {
	InvestmentEnhancementExportControllerApiFactory,
	InvestmentEnhancementReportsControllerApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import GraphLegend from "$root/components/GraphLegend";
import { IconWallBase, IconWalls } from "$root/components/IconWall";
import { InfoDelta } from "$root/components/InfoDelta";
import MonitorWithHourglass from "$root/components/glyphs/MonitorWithHourglass";
import ColoredRectangle from "$root/components/icons/ColoredRectangle";
import { useLocaleFormatters } from "$root/localization/hooks";
import {
	PortfolioQueryWidgetBase,
	portfolioWidgetMissingDataReason,
	WidgetStatus,
} from "$root/pages/PortfolioDetails/PortfolioWidgetStatus";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { BarGraphPCSvg } from "$root/ui-lib/charts";
import type { ContextContent } from "$root/utils";
import { colorGenerator, downloadContentDisposition, getGraphMarkers, pxToRem, qualifier, roundCustomByStep, useQueryNoRefetch, withContext } from "$root/utils";
import { PortfolioContext } from "$root/widgets-architecture/contexts/portfolio";
import { InfoTooltip } from "$root/widgets-architecture/layout/WidgetsMapper/InfoTooltip";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import type { TableWithGroupsProps } from "@mdotm/mdotui/components";
import {
	AutoSortTableWithGroups,
	CircularProgressBar,
	DropdownMenu,
	Icon,
	Row,
	TableDataCell,
	Text,
} from "@mdotm/mdotui/components";
import { overrideClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSort, builtInSortFnFor, groupBy } from "@mdotm/mdotui/utils";
import { Map } from "immutable";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { ExposureCategories, ExposureCategoryState } from "../ExposureEvolve";
import { exposureCategoryInfo, ExposureCategorySelector, WithExposureCategories } from "../ExposureEvolve";
import type { InvestmentExposureResponseExposureTypeEnum } from "../ExposureEvolve/InvestmentExposureResponseExposureTypeEnum";

const ExposureCompare = (ctx: ContextContent<typeof PortfolioContext>) => {
	const { portfolio, selectedBenchmark } = ctx;
	const benchmarkId = selectedBenchmark ?? portfolio?.primaryBenchmarkIdentifier;

	const exposureAvailableQuery = useQueryNoRefetch(
		["queryExposureLevelEvolve", "enhance", portfolio?.uuid, benchmarkId, ctx.portfolio],
		{
			queryFn: () => {
				if (!portfolio?.uuid || !benchmarkId) {
					return {
						data: undefined,
						widgetStatus: portfolioWidgetMissingDataReason(ctx.portfolio!, "ExanteContributionVolatility"),
					};
				}

				return {
					data: {},
					widgetStatus: WidgetStatus.READY,
				};
			},
		},
	);

	return (
		<PortfolioQueryWidgetBase query={exposureAvailableQuery}>
			{() => (
				<WithExposureCategories>
					{(exposureCategories) => (
						<ExposureCompareInner exposureCategories={exposureCategories} ctx={ctx} benchmarkId={benchmarkId} />
					)}
				</WithExposureCategories>
			)}
		</PortfolioQueryWidgetBase>
	);
};

const ExposureCompareInner = ({
	ctx,
	exposureCategories,
	benchmarkId,
}: {
	ctx: ContextContent<typeof PortfolioContext>;
	exposureCategories: ExposureCategories;
	benchmarkId: string | undefined;
}) => {
	const investmentEnhancementReportApi = useApiGen(InvestmentEnhancementReportsControllerApiFactory);

	const { portfolio, reportExcutionCounter } = ctx;

	const [exposureCategory, setExposureCategory] = useState<ExposureCategoryState>({
		firstLevel: "RISK_MODEL",
		secondLevel: "MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS" satisfies InvestmentExposureResponseExposureTypeEnum,
	});

	const query = useQueryNoRefetch(
		[
			"queryExposureLevelEvolve",
			"enhance",
			portfolio?.uuid,
			benchmarkId,
			portfolio?.status,
			reportExcutionCounter,
			exposureCategory.secondLevel,
		],
		{
			keepPreviousData: true,
			queryFn: () =>
				!portfolio?.uuid || !benchmarkId || !exposureCategory.secondLevel
					? null
					: axiosExtract(
							investmentEnhancementReportApi.getTwoLevelsInvestmentExposure1(
								portfolio.uuid,
								benchmarkId,
								exposureCategory.secondLevel,
							),
					  ),
		},
	);

	const { t } = useTranslation();
	const exportApi = useApiGen(InvestmentEnhancementExportControllerApiFactory);

	const exportPortfolioExposure = useCallback(
		async (cat: InvestmentExposureResponseExposureTypeEnum) => {
			const response = await exportApi.exportExposure1(portfolio!.uuid!, cat, { responseType: "blob" });
			downloadContentDisposition(response);
			trackMixPanelEvent("Portfolio", {
				Type: "Export",
				Area: "Exposure Compare",
				Mode: "XLS",
				ID: portfolio!.uuid!,
			});
		},
		[exportApi, portfolio],
	);

	const formatters = useLocaleFormatters();

	useWidgetOptions(
		() => ({
			title: (
				<Row gap={8} alignItems="center">
					<Text
						type="Body/XL/Bold"
						title={t("EXPOSURE.TITLE")}
						classList="truncate"
						data-qualifier={qualifier.widgets.portfolioExposureCompare.name}
					>
						{t("EXPOSURE.TITLE")}
					</Text>
					{query.isFetching && <CircularProgressBar value="indeterminate" outerDiameter={16} />}
				</Row>
			),
			// alertsActive: currentTab !== 1,
			actionHeader: function Download() {
				return (
					<div style={{ display: "flex", flexDirection: "row" }} className="space-x-2">
						{/* FIXME add noData Option*/}
						{!(query.isError || query.isFetching) && (
							<DropdownMenu
								trigger={({ innerRef, open, ...forward }) => (
									<button ref={innerRef} aria-expanded={open} type="button" {...forward}>
										<Icon icon="Dowload" color={themeCSSVars.MessageSeverity_success} size={20} />
									</button>
								)}
								actions={[
									{
										icon: "xls",
										onClickAsync: async () => {
											await exportPortfolioExposure(exposureCategory.secondLevel as keyof typeof exposureCategoryInfo);
										},
										label: t("EXPOSURE.DOWNLOAD_TITLE"),
										disabled: exposureCategory.secondLevel
											? !(exposureCategory.secondLevel in exposureCategoryInfo)
											: true,
									},
								]}
							/>
						)}
						<InfoTooltip>{t("EXPOSURE.TOOLTIP")}</InfoTooltip>
					</div>
				);
			},
		}),
		[exposureCategory.secondLevel, exportPortfolioExposure, query.isError, query.isFetching, t],
	);

	const tableData = useMemo(() => {
		if (!query.data) {
			return null;
		}
		const { enhancementComposition, investmentComposition } = query.data;

		if (!enhancementComposition || !investmentComposition) {
			return [];
		}

		const compostion = [
			...enhancementComposition.map((x) => ({ ...x, enhanced: true })),
			...investmentComposition.map((x) => ({ ...x, enhanced: false })),
		];

		const mappedComposition = Map(groupBy(compostion, (comp) => comp.firstQualityLevel!));

		return Array.from(mappedComposition.keys())
			.map((firstQualityLevel, i) => {
				const compositionEntries = mappedComposition.get(firstQualityLevel);
				const sum = (compositionEntries ?? []).reduce(
					(acc, x) => {
						if (x.enhanced) {
							acc.enhancedSum += x.weight ?? 0;
							return acc;
						}

						acc.currentSum += x.weight ?? 0;
						return acc;
					},
					{ enhancedSum: 0, currentSum: 0 },
				);

				return {
					label: firstQualityLevel,
					enhancedSum: sum.enhancedSum,
					currentSum: sum.currentSum,
					groupIndex: i,
					rows: Object.values(
						(compositionEntries ?? []).reduce<{
							[key: string]: { enhancedWeight: number; currentWeight: number; label: string; netLong: boolean };
						}>((acc, x) => {
							if (x.firstQualityLevel === x.secondQualityLevel) {
								return acc;
							}

							if (!acc[x.secondQualityLevel!]) {
								acc[x.secondQualityLevel!] = {
									enhancedWeight: x.enhanced ? x.weight ?? 0 : 0,
									currentWeight: x.enhanced === false ? x.weight ?? 0 : 0,
									label: x.secondQualityLevel ?? "-",
									netLong: x.netLong ?? false,
								};
								return acc;
							}

							acc[x.secondQualityLevel!] = {
								...acc[x.secondQualityLevel!],
								enhancedWeight: x.enhanced ? x.weight ?? 0 : acc[x.secondQualityLevel!]!.enhancedWeight,
								currentWeight: x.enhanced === false ? x.weight ?? 0 : acc[x.secondQualityLevel!]!.currentWeight,
							};
							return acc;
						}, {}),
					),
				};
			})
			.sort((a, b) => (a.enhancedSum > b.enhancedSum ? -1 : 1));
	}, [query.data]);

	// Correction Of Graph Scala
	const exposureGraphLimits = useMemo(() => {
		if (!tableData) {
			return null;
		}
		const value = tableData.reduce((acc, group) => {
			const rowValues = group.rows.reduce(
				(a, row) => {
					return { value: a.value + row.enhancedWeight, previousValue: a.previousValue + row.currentWeight };
				},
				{ value: 0, previousValue: 0 },
			);
			return Math.max(acc, rowValues.value, rowValues.previousValue);
		}, 0);
		return roundCustomByStep(value, 10);
	}, [tableData]);

	const graphColumnMarkers = useMemo(
		() => (exposureGraphLimits != null ? getGraphMarkers(exposureGraphLimits, 0, 10, "%", 6) : null),
		[exposureGraphLimits],
	);

	const columns = useCallback<TableWithGroupsProps<NonNullable<typeof tableData>[number]>["columns"]>(
		({ expandColumn }) => [
			expandColumn,
			{
				header: (exposureCategories.fromRiskModel.find((cat) => cat.value === exposureCategory.secondLevel) ??
					exposureCategories.fromTagClassifications.find((cat) => cat.value === exposureCategory.secondLevel))!
					.columnTitle,
				groupContentTextType: "Body/M/Bold",
				groupContent: (groupedRow) => groupedRow.label,
				content: (row, cellProps) => (
					<TableDataCell {...cellProps} title={row.label}>
						{row.label}{" "}
						{!row.netLong && (
							<>
								-{" "}
								<span className="whitespace-nowrap">
									[{" "}
									<svg
										className="inline w-[8px] mb-1"
										width="8"
										height="8"
										viewBox="0 0 8 8"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<rect opacity="0.3" width="8" height="8" rx="4" fill={colorGenerator(1)} />
									</svg>{" "}
									<span className="truncate">Net Short</span> ]
								</span>
							</>
						)}
					</TableDataCell>
				),
				sortFn: builtInSortFnFor("label"),
				name: "name",
				//relativeWidth: 2,
				minWidth: 240,
				maxWidth: 240,
			},
			{
				name: "currentWeight",
				header: "Current Weight",
				groupContentTextType: "Body/M/Bold",
				groupCellClassList: "tabular-nums",
				groupContent: (groupedRow) => `${formatters.formatNumber(groupedRow.currentSum, 2)}%`,
				cellClassList: "tabular-nums",
				content: (row) => `${formatters.formatNumber(row.currentWeight, 2)}%`,
				sortFn: builtInSortFnFor("currentSum"),
				//relativeWidth: 1,
				align: "end",
				minWidth: 140,
				maxWidth: 140,
			},
			{
				name: "enhancedWeight",
				header: "Proposal Weight",
				groupCellClassList: "tabular-nums",
				groupContent: (groupedRow, cellProps) => (
					<TableDataCell {...cellProps}>
						<Text type="Body/M/Bold" color="#00AEEF">{`${formatters.formatNumber(groupedRow.enhancedSum, 2)}%`}</Text>
					</TableDataCell>
				),
				cellClassList: "tabular-nums",
				content: (row, cellProps) => (
					<TableDataCell {...cellProps}>
						<span className="text-[#00AEEF]">{`${formatters.formatNumber(row.enhancedWeight, 2)}%`}</span>
					</TableDataCell>
				),
				sortFn: builtInSortFnFor("enhancedSum"),
				//relativeWidth: 1,
				align: "end",
				minWidth: 140,
				maxWidth: 140,
			},
			{
				name: "graph",
				orderable: false,
				header: (headerProps) => (
					<Row {...headerProps} justifyContent="space-between" alignItems="center">
						{graphColumnMarkers?.map((m, i) => (
							<Text as="div" key={`marker-${i}`} type="Body/S/BOLD-UPPERCASE" color={themeCSSVars.palette_N500}>
								{m.label}
							</Text>
						))}
					</Row>
				),
				groupContent: ({ currentSum, enhancedSum }, cellProps) => {
					return (
						<Row justifyContent="center" {...cellProps} childrenGrow={1} alignItems="center">
							<BarGraphPCSvg
								classList="w-full"
								options={{
									resize: true,
									marksLabels: false,
									scale: { max: exposureGraphLimits ?? 100, min: 0 },
								}}
								data={[
									{ value: currentSum, color: "#BFC4CE" },
									{ value: enhancedSum, color: "#00AEEF" },
								]}
							/>
						</Row>
					);
				},
				content: ({ currentWeight, enhancedWeight }, cellProps) => {
					return (
						<Row justifyContent="center" {...cellProps} childrenGrow={1} alignItems="center">
							<BarGraphPCSvg
								classList="w-full"
								options={{
									resize: true,
									marksLabels: false,
									scale: { max: exposureGraphLimits ?? 100, min: 0 },
								}}
								data={[
									{ value: currentWeight, color: "#BFC4CE" },
									{ value: enhancedWeight, color: "#00AEEF" },
								]}
							/>
						</Row>
					);
				},
				//relativeWidth: 4,
				minWidth: 180,
			},
			{
				header: "DIFFERENCE",
				align: "end",
				groupContent: ({ currentSum, enhancedSum }, cellProps) => (
					<Row {...cellProps} classList="font-bold" justifyContent="stretch" alignItems="center">
						<InfoDelta diff={(enhancedSum ?? 0) - (currentSum ?? 0)} enh={enhancedSum ?? 0} />
					</Row>
				),
				content: ({ currentWeight, enhancedWeight }, cellProps) => (
					<Row {...cellProps} classList="font-bold" justifyContent="stretch" alignItems="center">
						<InfoDelta diff={(enhancedWeight ?? 0) - (currentWeight ?? 0)} enh={enhancedWeight ?? 0} />
					</Row>
				),
				minWidth: 125,
				maxWidth: 125,
				//relativeWidth: 1,
				sortFn: (a, b) => {
					const deltaA = a.enhancedSum - a.currentSum;
					const deltaB = b.enhancedSum - b.currentSum;

					return builtInSort(deltaA, deltaB);
				},
				name: "difference",
			},
		],
		[
			exposureCategory,
			exposureCategories.fromRiskModel,
			exposureCategories.fromTagClassifications,
			exposureGraphLimits,
			formatters,
			graphColumnMarkers,
		],
	);
	return (
		<div className="h-full flex flex-col">
			<ExposureCategorySelector
				exposureCategory={exposureCategory}
				setExposureCategory={setExposureCategory}
				exposureCategories={exposureCategories}
			/>
			{query.data != null || query.isLoading ? (
				query.data && (!query.data.enhancementComposition || !query.data.investmentComposition) ? (
					<div className="w-full grow min-h-0 pt-2">
						<IconWalls.CalculatingData />
					</div>
				) : (
					<>
						<div
							className={overrideClassName("grow min-h-0 w-full pb-3 mt-3 mb-2 flex flex-col items-stretch", {
								"opacity-60": query.isFetching,
							})}
						>
							{tableData && (
								<AutoSortTableWithGroups
									groupedRows={tableData}
									groupRowKey={(group) => group.label}
									columns={columns}
								/>
							)}
						</div>
						<GraphLegend>
							<div className="legend-container light book" style={{ marginRight: pxToRem(16) }}>
								<ColoredRectangle color="#BFC4CE" variant="vertical" /> Current weight
							</div>
							<div className="legend-container light book">
								<ColoredRectangle variant="vertical" /> Enhanced weight
							</div>
						</GraphLegend>
					</>
				)
			) : (
				<div className="w-full grow min-h-0 pt-2">
					<IconWallBase>
						<div className="flex-1 min-h-0 flex flex-col">
							<MonitorWithHourglass classList={{ "mx-auto my-0": true }} />
							<Text as="div" classList="px-1 text-center" type="Body/M/Book">
								Data not available for the selected comparison, please pick another option.
							</Text>
						</div>
					</IconWallBase>
				</div>
			)}
		</div>
	);
};

export default withContext(PortfolioContext)(ExposureCompare);
