import type { Option } from "$root/components/shared";
import { qualifier } from "$root/utils";
import { Select } from "@mdotm/mdotui/components";
import { useEffect, useRef } from "react";

export function withPlaceholder(placeholder: string, options: OptionTreeNode): OptionTreeNode {
	options.placeholder = placeholder;
	return options;
}

export type OptionTreeNode = Array<
	Option<string | undefined> & {
		subOptions?: OptionTreeNode;
	}
> & {
	placeholder?: string;
};

export type HierarchicalMultiSelectProps = {
	onChange(selection: (string | undefined)[]): void;
	selection: (string | undefined)[];
	optionTree: OptionTreeNode;
	label?: string;
	className?: string;
	disabled?: boolean;
};

function allFirstValues(tree: OptionTreeNode | undefined) {
	let subOptions: OptionTreeNode | undefined = tree;
	const firstValues: (string | undefined)[] = [];
	while (subOptions) {
		firstValues.push(subOptions[0].value);
		subOptions = subOptions[0].subOptions;
	}
	return firstValues;
}

type HierarchicalMultiSelectHelperProps = {
	options: OptionTreeNode;
	selection: (string | undefined)[];
	depth: number;
	disabled?: boolean;
	onChange: (newSelection: (string | undefined)[]) => void;
};

function HierarchicalMultiSelectHelper({
	options,
	selection,
	depth,
	disabled,
	onChange,
}: HierarchicalMultiSelectHelperProps) {
	const selectedSubtree = options.find((opt) => opt.value === selection[depth])?.subOptions;

	return (
		<div className="flex ml-2">
			<Select
				strategy="fixed"
				classList="!w-52 capitalize"
				value={selection[depth]}
				onChange={(newValue) =>
					onChange([
						...selection.slice(0, depth),
						newValue ?? undefined,
						...(allFirstValues(options.find((x) => x.value === newValue)?.subOptions) ?? []),
					])
				}
				size="large"
				options={options}
				disabled={disabled}
				data-qualifier={qualifier.component.hierarchicalMultiSelect.selectList}
				floatingAppearance={{
					classList: "capitalize",
				}}
			/>

			{selectedSubtree && (
				<HierarchicalMultiSelectHelper
					options={selectedSubtree}
					depth={depth + 1}
					disabled={disabled}
					onChange={onChange}
					selection={selection}
				/>
			)}
		</div>
	);
}

function HierarchicalMultiSelect({
	label,
	onChange,
	optionTree: options,
	selection,
	disabled,
	className,
}: HierarchicalMultiSelectProps): JSX.Element {
	const hierarchicalMultiSelectDivRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		document.addEventListener("scroll", () => {
			if (hierarchicalMultiSelectDivRef.current) {
				const { top } = hierarchicalMultiSelectDivRef.current.getBoundingClientRect();
				if (top <= 47 && hierarchicalMultiSelectDivRef.current.className.includes("mx-[-0.9375rem]") === false) {
					hierarchicalMultiSelectDivRef.current.classList.add("mx-[-0.9375rem]");
					hierarchicalMultiSelectDivRef.current.classList.add("!rounded-none");
					hierarchicalMultiSelectDivRef.current.classList.add("shadow");
				}

				if (top > 47 && hierarchicalMultiSelectDivRef.current.className.includes("mx-[-0.9375rem]")) {
					hierarchicalMultiSelectDivRef.current.classList.remove("mx-[-0.9375rem]");
					hierarchicalMultiSelectDivRef.current.classList.remove("!rounded-none");
					hierarchicalMultiSelectDivRef.current.classList.remove("shadow");
				}
			}
		});

		return () => {
			document.removeEventListener("scroll", () => "removed listener");
		};
	}, [hierarchicalMultiSelectDivRef]);
	return (
		<div
			className={`sticky flex align-baseline top-0 z-[100] bg-[#fff] rounded-[4px] h-[60px] mb-[10px] ${className}`}
			ref={hierarchicalMultiSelectDivRef}
			data-qualifier={qualifier.component.hierarchicalMultiSelect.name}
		>
			{label && <h2 className="font-bold text-[#8c8ea8] absolute top-[1.125rem] left-[1rem] text-base">{label}</h2>}
			<div style={{ margin: "auto" }}>
				<HierarchicalMultiSelectHelper
					depth={0}
					disabled={disabled}
					onChange={onChange}
					options={options}
					selection={selection}
				/>
			</div>
		</div>
	);
}

export default HierarchicalMultiSelect;
