import type { ValueWithData } from "$root/api/api-gen";
import { CherryBankControllerV6ApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { GraphLegendLabel, GraphLegendTextKey } from "$root/components/GraphLegend";
import DonoutCircle from "$root/components/icons/DonoutCircle";
import { handlePreviousMarketData } from "$root/functional-areas/market-view/marketUtils";
import useWidgetsData from "$root/hooks/useWidgetsData";
import { PaletteColors } from "$root/styles/themePalette";
import { customObjectEntriesFn, qualifier, useQueryNoRefetch } from "$root/utils";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import { Icon, ProgressBar } from "@mdotm/mdotui/components";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { TableParams } from "../OutlookBlock/OutlookBlockTable";
import OutlookBlockTable from "../OutlookBlock/OutlookBlockTable";

type CustomSectorModel = {
	label: string;
	type: string;
	prevValue?: ValueWithData;
	currentValue?: ValueWithData;
};

const CustomOutlookBlock = (): JSX.Element => {
	const { t } = useTranslation();
	const cherryBankApi = useApiGen(CherryBankControllerV6ApiFactory);

	const { currentWidgetsData } = useWidgetsData();
	const dateRange = new Date(Date.parse(currentWidgetsData.creation_time.toString()));
	dateRange.setFullYear(dateRange.getFullYear() - 1);
	dateRange.setHours(0, 0, 0, 0);

	const { data: outlookTimeSeries, isFetching: areTimeSeriesFetching } = useQueryNoRefetch(
		["retrieveCustomReportTimeSeries"],
		{
			queryFn: async () => {
				const assetClassesTimeSeries = await Promise.allSettled([
					cherryBankApi.dashboardControllerGetEquitiesTimeSeriesContinuous(
						"GLOBAL",
						dateRange.toISOString().split("T")[0],
					),
					cherryBankApi.dashboardControllerGetEquitiesTimeSeriesContinuous("EU", dateRange.toISOString().split("T")[0]),
					cherryBankApi.dashboardControllerGetEquitiesTimeSeriesContinuous("US", dateRange.toISOString().split("T")[0]),
					cherryBankApi.dashboardControllerGetFiiContinuousTimeSeries(dateRange.toISOString().split("T")[0]),
					cherryBankApi.dashboardControllerGetSectorContinuousTimeSeries(dateRange.toISOString().split("T")[0]),
				]);

				const [Equity, EquityEu, EquityUsa, FixedIncome, Commodities] = assetClassesTimeSeries.map((acT) => {
					const { status } = acT;
					if (status === "rejected") {
						return [];
					}
					return acT.value.data;
				});

				return { Equity, EquityEu, EquityUsa, FixedIncome, Commodities };
			},
		},
	);

	const assetClassTimeSeries = useMemo(() => {
		if (!outlookTimeSeries) {
			return undefined;
		}

		return customObjectEntriesFn(outlookTimeSeries).reduce<{
			[key in keyof typeof outlookTimeSeries]: { [key: string]: [number, number, boolean][] } | undefined;
		}>(
			(acc, [key, value]) => {
				const timeSeries = value.reduce<{ [key: string]: [number, number, boolean][] } | undefined>(
					(subAcc, assetClass) => {
						const { type, values, inverted } = assetClass;

						if (!type) {
							return undefined;
						}

						return {
							...subAcc,
							[type]: values
								?.filter(([_x, y]) => y ?? false)
								.map(([dateSeconds, y]) => [dateSeconds * 1000, y, inverted]) as [number, number, boolean][],
						};
					},
					{},
				);

				return {
					...acc,
					[key]: timeSeries,
				};
			},
			{ Equity: {}, EquityEu: {}, EquityUsa: {}, FixedIncome: {}, Commodities: {} },
		);
	}, [outlookTimeSeries]);

	const composeTableRowData = useCallback(
		(data: Array<CustomSectorModel>) => {
			return data
				.reduce((acc, { currentValue, prevValue, type }, i) => {
					if (currentValue?.value === undefined) {
						return acc;
					}
					const { previous, tooltip: graphTooltip } = handlePreviousMarketData(
						"dashboard",
						(position) => t("CUSTOM_REPORT.OUTLOOK", { returnObjects: true })[position],
						{ value: currentValue.value, data: currentValue.data },
						{ value: prevValue?.value, data: prevValue?.data },
					);

					return [
						...acc,
						{
							assetClass: type,
							current: currentValue.value,
							prev: previous,
							tooltip: graphTooltip,
							index: i,
						},
					];
				}, [] as Array<TableParams>)
				.sort(builtInSortFnFor("assetClass"));
		},
		[t],
	);
	// add eq_eu/ eq_usa
	const { data, isLoading } = useQueryNoRefetch(["cherryBankAssetClasses"], {
		queryFn: async () => {
			const assetClasses = await Promise.allSettled([
				cherryBankApi.dashboardControllerGetEquities("GLOBAL"),
				cherryBankApi.dashboardControllerGetEquities("EU"),
				cherryBankApi.dashboardControllerGetEquities("US"),
				cherryBankApi.dashboardControllerGetFii(),
				cherryBankApi.dashboardControllerGetSector(),
			]);

			const [Equity, EquityEu, EquityUsa, FixedIncome, Commodities] = assetClasses.map((ac) => {
				if (ac.status === "rejected") {
					return [];
				}

				const mapRowData = ac.value.data.map(
					({ label, type, prevValue, currentValue }): CustomSectorModel => ({
						label: label ?? "-",
						type: type ?? "-",
						prevValue,
						currentValue,
					}),
				);

				return composeTableRowData(mapRowData);
			});

			return {
				Equity,
				EquityEu,
				EquityUsa,
				FixedIncome,
				Commodities,
			};
		},
	});

	const { Commodities, Equity, EquityEu, EquityUsa, FixedIncome } = data ?? {};

	useWidgetOptions(
		() => ({
			title: t("OUTLOOK.OUTLOOK_TITLE"),
			isDraggable: false,
		}),
		[t],
	);

	return (
		<div className="relative mt-6">
			<div className="grid grid-cols-3 gap-6 gap-y-10 mb-8">
				{isLoading || areTimeSeriesFetching ? (
					<div className="col-span-3">
						<ProgressBar value="indeterminate" />
					</div>
				) : (
					<>
						<OutlookBlockTable
							title="EQUITY"
							data={Equity ?? []}
							className="h-[30.5rem]"
							assetClassTimeSeries={assetClassTimeSeries?.Equity ?? {}}
							qualifier={qualifier.customReport.customOutlookTable("Equity")}
						/>
						<OutlookBlockTable
							title="EQUITY_EU"
							data={EquityEu ?? []}
							className="h-[30.5rem]"
							assetClassTimeSeries={assetClassTimeSeries?.EquityEu ?? {}}
							qualifier={qualifier.customReport.customOutlookTable("EquityEu")}
						/>
						<OutlookBlockTable
							title="EQUITY_USA"
							data={EquityUsa ?? []}
							className="h-[30.5rem]"
							assetClassTimeSeries={assetClassTimeSeries?.EquityUsa ?? {}}
							qualifier={qualifier.customReport.customOutlookTable("EquityUsa")}
						/>
						<OutlookBlockTable
							title="FIXED_INCOME"
							data={FixedIncome ?? []}
							className="h-[30.5rem]"
							assetClassTimeSeries={assetClassTimeSeries?.FixedIncome ?? {}}
							qualifier={qualifier.customReport.customOutlookTable("FixedIncome")}
						/>
						<OutlookBlockTable
							title="COMMODITIES"
							data={Commodities ?? []}
							className="h-[30.5rem]"
							assetClassTimeSeries={assetClassTimeSeries?.Commodities ?? {}}
							qualifier={qualifier.customReport.customOutlookTable("Commodities")}
						/>
					</>
				)}
			</div>
			<div className="flex gap-4">
				<div className="flex items-center">
					<Icon classList="!mr-0" icon="previous-position" color={PaletteColors.AZURE} size={16} />
					<Icon classList="!mr-0" icon="previous-position" color={PaletteColors.NEON_RED} size={16} />
					<Icon classList="!mr-0" icon="previous-position" color={PaletteColors.GREEN_BLUE} size={16} />
					<GraphLegendLabel>{t("CURRENT_POSITIONING")}</GraphLegendLabel>
				</div>
				<div className="flex items-center gap-1">
					<DonoutCircle />
					<GraphLegendLabel>{t("PREVIOUS_POSITIONING")}</GraphLegendLabel>
				</div>
				<div className="flex items-center">
					<GraphLegendTextKey>{t("GRAPH_LEGEND.SU_KEY")}</GraphLegendTextKey>
					<GraphLegendLabel>{t("GRAPH_LEGEND.SU_LABEL")}</GraphLegendLabel>
				</div>
				<div className="flex items-center">
					<GraphLegendTextKey>{t("GRAPH_LEGEND.U_KEY")}</GraphLegendTextKey>
					<GraphLegendLabel>{t("GRAPH_LEGEND.U_LABEL")}</GraphLegendLabel>
				</div>
				<div className="flex items-center">
					<GraphLegendTextKey>{t("GRAPH_LEGEND.N_KEY")}</GraphLegendTextKey>
					<GraphLegendLabel>{t("GRAPH_LEGEND.N_LABEL")}</GraphLegendLabel>
				</div>
				<div className="flex items-center">
					<GraphLegendTextKey>{t("GRAPH_LEGEND.O_KEY")}</GraphLegendTextKey>
					<GraphLegendLabel>{t("GRAPH_LEGEND.O_LABEL")}</GraphLegendLabel>
				</div>
				<div className="flex items-center">
					<GraphLegendTextKey>{t("GRAPH_LEGEND.SO_KEY")}</GraphLegendTextKey>
					<GraphLegendLabel>{t("GRAPH_LEGEND.SO_LABEL")}</GraphLegendLabel>
				</div>
			</div>
		</div>
	);
};

export default CustomOutlookBlock;
