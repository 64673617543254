import { CommentariesControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { MarkdownRenderer } from "$root/components/MarkdownRenderer/MarkdownRenderer";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { useEventBus } from "$root/event-bus";
import { applySentimentBadges } from "$root/functional-areas/commentary/sentiment-badges";
import useOutlookComposition from "$root/hooks/useOutlookComposition";
import type { ContextContent } from "$root/utils";
import { qualifier, useQueryNoRefetch, withContexts } from "$root/utils";
import { CommentaryContext } from "$root/widgets-architecture/contexts/commentary";
import { UniverseFiltersContext } from "$root/widgets-architecture/contexts/filters";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import { CircularProgressBar, LocalOverlay, ScrollWrapper, Text } from "@mdotm/mdotui/components";
import { noop } from "@mdotm/mdotui/utils";
import styled from "styled-components";

const BasePositioning = styled("div")({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	height: "100%",

	div: {
		maxHeight: "100%",
	},

	"div.ComDiv": {
		paddingRight: ".7rem",
		width: "100%",
	},
});

function CommentaryMDBlock(
	props: ContextContent<typeof CommentaryContext> & ContextContent<typeof UniverseFiltersContext>,
) {
	useWidgetOptions(
		() => ({
			actionHeader: <></>,
			title: (() => {
				if (props.fixedCommentary || (!props.firstFilter && !props.secondFilter && !props.thirdFilter)) {
					return (
						<Text type="Body/XL/Bold" title="Commentary - Generative AI" classList="truncate">
							Commentary - Generative AI
						</Text>
					);
				}
				return (
					<Text type="Body/XL/Bold" title="Commentary" classList="truncate">
						Commentary
					</Text>
				);
			})(),
		}),
		[props.fixedCommentary, props.firstFilter, props.secondFilter, props.thirdFilter],
	);

	return <CommentaryMDBlockContent {...props} />;
}

export function CommentaryMDBlockContent(
	props: ContextContent<typeof CommentaryContext> & ContextContent<typeof UniverseFiltersContext>,
): JSX.Element {
	const commentaryApi = useApiGen(CommentariesControllerApiFactory);
	const { assetClass, geography, sector } = useOutlookComposition(
		props.firstFilter,
		props.secondFilter,
		props.thirdFilter,
	);

	const query = useQueryNoRefetch(
		["commentary", props.firstFilter, props.secondFilter, props.thirdFilter, props.fixedCommentary],
		{
			queryFn: async () => {
				if (!props.firstFilter && !props.secondFilter && !props.thirdFilter) {
					const { data: dashboardCommentary } = await commentaryApi.getCommentary3(); // dashboard commentaries
					return dashboardCommentary.text && applySentimentBadges(dashboardCommentary.text);
				}

				const REGEX = { $regex: "^DASHBOARD$" };
				if (!props.fixedCommentary) {
					if (props.firstFilter && props.firstFilter?.length > 0) {
						REGEX.$regex = `^${props.firstFilter}$`;
						if (
							props.secondFilter &&
							props.secondFilter?.length > 0 &&
							props.firstFilter === "EQ" &&
							props.secondFilter !== "MARKETS"
						) {
							REGEX.$regex = `^${props.firstFilter}_${props.secondFilter}${
								props.thirdFilter && props.thirdFilter !== "ALL_SECTORS" ? `_${props.thirdFilter}` : ""
							}$`;
						}
					}
				}

				const commData = props.fixedCommentary
					? await commentaryApi.getCommentary2(assetClass, geography, sector).then((response) => ({
							...response,
							data: {
								...response.data,
								text: response.data.text && applySentimentBadges(response.data.text),
							},
					  })) // outlook focus commentaries
					: await commentaryApi.getCommentary1(JSON.stringify(REGEX)).then((response) => ({
							...response,
							data: {
								...response.data,
								text: response.data.text && applySentimentBadges(response.data.text),
							},
					  })); // outlook commentaries
				return commData.data.text;
			},
			keepPreviousData: true,
		},
	);

	useEventBus("market-update", () => {
		query.refetch().catch(noop);
	});

	return (
		<BasePositioning data-qualifier={qualifier.widgets.marketCommentary.name}>
			<ReactQueryWrapperBase
				query={query}
				errorFallback={<p>Generative AI commentary is not available at the moment. It will be generated soon</p>}
			>
				{(data, rq) => (
					<div className="relative w-full z-0">
						<LocalOverlay
							show={rq.isFetching}
							classList="z-10"
							style={{
								background: "white",
							}}
						>
							<CircularProgressBar value="indeterminate" />
						</LocalOverlay>

						<ScrollWrapper>
							<MarkdownRenderer useRehype>{data}</MarkdownRenderer>
						</ScrollWrapper>
					</div>
				)}
			</ReactQueryWrapperBase>
		</BasePositioning>
	);
}

export default withContexts([CommentaryContext, UniverseFiltersContext])(CommentaryMDBlock);

/* function LabelWithSentimentIcon({
	children,
	sentiment,
	indicator,
}: {
	children: ReactNode;
	sentiment: SentimentType;
	indicator: IndicatorType;
}): JSX.Element {
	return (
		<div className="inline-flex items-center">
			<SentimentSymbol classList="scale-125 mr-1" sentiment={sentiment} indicator={indicator} />
			<Text as="span" type="Body/M/Bold">
				{children}
			</Text>
		</div>
	);
}
 */
