import type { InvestmentListEntry, InvestmentSummary } from "$root/api/api-gen";
import { typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";
import { useStoreState, WithZustandStore } from "$root/third-party-integrations/zustand";
import type { MaybeAsync } from "$root/utils";
import { sumArrayLike } from "$root/utils";
import type { DialogProps } from "@mdotm/mdotui/components";
import {
	ActionText,
	AsyncButton,
	Button,
	Column,
	Dialog,
	DialogFooter,
	Icon,
	Row,
	Text,
} from "@mdotm/mdotui/components";
import { adaptAnimatedNodeProvider, ForEach, spawn } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { MaybeFn } from "@mdotm/mdotui/utils";
import { maybeCall, typedObjectEntries } from "@mdotm/mdotui/utils";
import { useMemo, type ReactNode } from "react";
import type { StoreApi } from "zustand";
import { PortfolioStudioTab } from "../portfolio-studio-tabs";
import type { PortfolioActionUnavailableReason, PortfolioBulkActionAvailabilityResult } from "./portfolio-actions";

export type SpawnBulkAnalysisReviewDialogParams<T> = {
	header: string;
	children: MaybeFn<ReactNode, [StoreApi<T>]>;
	canConfirm: MaybeFn<boolean, [T]>;
	initialState: T;
	onContinue?: MaybeAsync<void, [T]>;
};

export function BulkAnalysisReviewDialog<T>({
	children,
	header,
	show,
	canConfirm,
	onContinue,
	onCancel,
	onAnimationStateChange,
	initialState,
}: Omit<SpawnBulkAnalysisReviewDialogParams<T>, "onContinue"> & {
	onContinue: MaybeAsync<void, [T]>;
	onCancel(): void;
} & Pick<DialogProps, "show" | "onAnimationStateChange">): JSX.Element {
	const [stateStore] = useStoreState(initialState);
	return (
		<Dialog
			size="large"
			show={show}
			onClose={onCancel}
			onAnimationStateChange={onAnimationStateChange}
			header={header}
			footer={
				<DialogFooter
					primaryAction={
						<WithZustandStore store={stateStore}>
							{(state) => (
								<AsyncButton
									disabled={!maybeCall(canConfirm, state)}
									palette="primary"
									size="small"
									data-qualifier="BulkAnalysisReviewDialog/ConfirmButton"
									onClickAsync={() => onContinue(stateStore.getState())}
								>
									Confirm
								</AsyncButton>
							)}
						</WithZustandStore>
					}
					neutralAction={
						<Button
							palette="tertiary"
							size="small"
							type="button"
							onClick={onCancel}
							data-qualifier="BulkAnalysisReviewDialog/CancelButton"
						>
							Cancel
						</Button>
					}
				/>
			}
		>
			{maybeCall(children, stateStore)}
		</Dialog>
	);
}

type SpawnBulkAnalysisReviewDialogResult<T> = { kind: "continue"; result: T } | { kind: "cancel" } | { kind: "review" };

/** Note: never throws */
export function spawnBulkAnalysisReviewDialog<T>({
	onContinue,
	...forward
}: SpawnBulkAnalysisReviewDialogParams<T>): Promise<SpawnBulkAnalysisReviewDialogResult<T>> {
	return spawn<SpawnBulkAnalysisReviewDialogResult<T>>(
		adaptAnimatedNodeProvider(({ resolve, show, onHidden }) => (
			<BulkAnalysisReviewDialog
				{...forward}
				show={show}
				onContinue={async (result) => {
					await onContinue?.(result);
					resolve({ kind: "continue", result });
				}}
				onCancel={() => resolve({ kind: "cancel" })}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
			/>
		)),
	).promise;
}

const colorByPalette: Record<"success" | "alert" | "info", string> = {
	success: themeCSSVars.palette_P200,
	alert: themeCSSVars.palette_A200,
	info: themeCSSVars.palette_S200,
};

export function BulkAnalysisReviewSections<T extends InvestmentSummary | InvestmentListEntry>({
	bulkActionAvailabilityResult,
	midSections,
}: {
	bulkActionAvailabilityResult: PortfolioBulkActionAvailabilityResult<T>;
	midSections?: Array<{
		count: number;
		title: string;
		children?: ReactNode;
		palette?: "success" | "alert" | "info";
	}>;
}): JSX.Element {
	const [available, unavailable] = bulkActionAvailabilityResult;
	const unavailableCount = useMemo(
		() => unavailable && sumArrayLike(Object.values(unavailable), (x) => x.length),
		[unavailable],
	);
	const sections = useMemo(
		() => [
			{
				count: available.length,
				title: available.length === 1 ? "Portfolio is ready to be processed" : "Portfolios are ready to be processed",
				palette: "success" as const,
			},
			...(midSections ?? []),
			...(unavailable && unavailableCount
				? [
						{
							count: unavailableCount,
							title: unavailableCount === 1 ? "Portfolio will not be included" : "Portfolios will not be included",
							children: <BulkAnalysisReviewNotSuitableSummary unavailable={unavailable} />,
							palette: "alert" as const,
						},
				  ]
				: []),
		],
		[available.length, midSections, unavailable, unavailableCount],
	);

	return (
		<ForEach collection={sections}>
			{({ item: section, index }) => (
				<Column flexGrow={0}>
					<Row gap={16} flexGrow={1} alignItems="start">
						<Text
							as="div"
							classList="min-w-[32px] py-1 px-2 text-center rounded-lg"
							type="Body/L/Bold"
							style={{
								backgroundColor: colorByPalette[section.palette ?? ("info" as const)],
							}}
						>
							{section.count}
						</Text>
						<Column flexGrow={1}>
							<Text as="div" type="Body/L/Medium" classList="mt-1">
								{section.title}
							</Text>
							{section.children && (
								<Column flexGrow={0} classList="my-4">
									{section.children}
								</Column>
							)}
						</Column>
					</Row>
					{index < sections.length - 1 && (
						<div className="border-b w-10 my-4" style={{ borderBottomColor: themeCSSVars.palette_N100 }} />
					)}
				</Column>
			)}
		</ForEach>
	);
}

export function BulkAnalysisReviewNotSuitableSummary<T extends InvestmentSummary | InvestmentListEntry>({
	unavailable,
}: {
	unavailable: Record<PortfolioActionUnavailableReason, T[]>;
}): JSX.Element {
	return (
		<Column flexGrow={0} gap={8}>
			<ul className="list-disc pl-4">
				<ForEach
					collection={typedObjectEntries(unavailable).filter(([_reason, investments]) => investments.length > 0)}
				>
					{({ item: [reason, investments] }) => (
						<li>
							<Text type="Body/M/Book">{PortfolioActionUnavailableReasonToMessageMap[reason](investments.length)}</Text>
						</li>
					)}
				</ForEach>
			</ul>
			<Row gap={4} alignItems="center" flexGrow={0}>
				<ActionText
					href={typedUrlForRoute("PortfoliosStudio", {
						tab: PortfolioStudioTab.Portfolios,
						preselectedInvestments: Object.values(unavailable)
							.flatMap((investments) => investments.map((investment) => investment.uuid!))
							.join("|"),
					})}
					target="_blank"
				>
					Open this selection in a new tab
				</ActionText>
				<Icon icon="Expand" />
			</Row>
		</Column>
	);
}

export const PortfolioActionUnavailableReasonToMessageMap: Record<
	PortfolioActionUnavailableReason,
	(count: number) => string
> = {
	ERROR: (count) =>
		count === 1
			? `${count} portfolio is in ERROR status and cannot proceed with the selected action.`
			: `${count} portfolios are in ERROR status and cannot proceed with the selected action.`,
	CALCULATING: (count) =>
		count === 1
			? `${count} portfolio is in CALCULATING status and must complete processing before any action can be performed.`
			: `${count} portfolios are in CALCULATING status and must complete processing before any action can be performed.`,
	REVIEW: (count) =>
		count === 1
			? `${count} portfolio is in REVIEW status and must be resolved before proceeding.`
			: `${count} portfolios are in REVIEW status and must be resolved before proceeding.`,
	RETRIEVING_DATA: (count) =>
		count === 1
			? `${count} portfolio is in RETRIEVING DATA status and cannot be modified until data retrieval is completed.`
			: `${count} portfolios are in RETRIEVING DATA status and cannot be modified until data retrieval is completed.`,
	DRAFT: (count) =>
		count === 1
			? `${count} portfolio is in DRAFT status and must be finalized before performing this action.`
			: `${count} portfolios are in DRAFT status and must be finalized before performing this action.`,
	PROPOSAL_READY: (count) =>
		count === 1
			? `${count} portfolio is in PROPOSAL READY status and require review before proceeding.`
			: `${count} portfolios are in PROPOSAL READY status and require review before proceeding.`,
	ACCEPTED: (count) =>
		count === 1
			? `${count} portfolio is in ACCEPTED and doesn't have an associated proposal.`
			: `${count} portfolios are in ACCEPTED and don't have associated proposals.`,
	READY: (count) =>
		count === 1
			? `${count} portfolio is in READY status and doesn't have an associated proposal.`
			: `${count} portfolios are in READY status and don't have associated proposals.`,
	CANNOT_BULK_ENHANCE: (count) =>
		count === 1 ? `${count} portfolio has unfeasible constraints.` : `${count} portfolios have unfeasible constraints.`,
	MISSING_UNIVERSE: (count) =>
		count === 1
			? `${count} portfolio has no associated universe and cannot proceed without one.`
			: `${count} portfolios have no associated universe and cannot proceed without one.`,
	CANNOT_MIX: (count) =>
		count === 1
			? `${count} portfolio has another portfolio in its composition and cannot proceed due to nesting restrictions.`
			: `${count} portfolios have another portfolio in their composition and cannot proceed due to nesting restrictions.`,
	TOO_OLD: (count) =>
		count === 1
			? `${count} portfolio has an outdated proposal that must be refreshed before proceeding.`
			: `${count} portfolios have an outdated proposal that must be refreshed before proceeding.`,
	ACL_REQUIRED: (count) =>
		count === 1
			? `${count} portfolio is excluded because the user does not have the necessary permissions to perform this action.`
			: `${count} portfolios are excluded because the user does not have the necessary permissions to perform this action.`,
	USED_AS_NESTED: (count) =>
		count === 1
			? `${count} portfolio is included in another entity’s composition and cannot be deleted independently.`
			: `${count} portfolios are included in another entity’s composition and cannot be deleted independently.`,
};
