import { InstrumentsClassificationsControllerV1ApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { Intercom } from "$root/third-party-integrations/initIntercom";
import { FormController } from "$root/third-party-integrations/react-hook-form";
import { minutes, useQueryNoRefetch } from "$root/utils";
import { InfoTooltip } from "$root/widgets-architecture/layout/WidgetsMapper/InfoTooltip";
import type { StylableProps } from "@mdotm/mdotui/components";
import {
	ActionText,
	Badge,
	ButtonGroupRadio,
	Column,
	FormField,
	Row,
	Select,
	Text,
	TooltipContent,
} from "@mdotm/mdotui/components";
import { overrideClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { ReactNode } from "react";
import type { Control, Path } from "react-hook-form";
import { z } from "zod";

export function AvailableSoonOverlay({
	children,
	hideBadge = false,
	style,
	classList,
}: StylableProps & {
	children: ReactNode;
	/** @default false */ /** @default false */ hideBadge?: boolean;
}): JSX.Element {
	return (
		<div className={overrideClassName("relative z-0", classList)} style={style}>
			{children}
			{!hideBadge && (
				<div className="-top-3 right-0 absolute">
					<AvailableSoonBadge />
				</div>
			)}
		</div>
	);
}
export function AvailableSoonBadge(): JSX.Element {
	return (
		<Badge
			backgroundColor={themeCSSVars.palette_N200}
			color={themeCSSVars.palette_N0}
			size="x-small"
			classList="scale-90"
		>
			Available soon
		</Badge>
	);
}

export function DataSourceSection({
	commentaryModelType,
	onChangeDataSource,
	tagUUID,
	onChangeClassificationUuid,
	riskModelDescription,
	customClassificationDescription,
	isCustomComingSoon = false,
	isClassificationSelectionComingSoon = false,
	error,
}: {
	commentaryModelType: "RISK_MODEL" | "TAG";
	onChangeDataSource(v: "RISK_MODEL" | "TAG"): void;
	tagUUID: string | null;
	onChangeClassificationUuid(v: string | null): void;
	riskModelDescription?: string;
	customClassificationDescription?: string;
	/** @default false */ isCustomComingSoon?: boolean;
	/** @default false */ isClassificationSelectionComingSoon?: boolean;
	error?: ReactNode;
}): JSX.Element {
	const classificationApi = useApiGen(InstrumentsClassificationsControllerV1ApiFactory);

	const query = useQueryNoRefetch(["customClassifications"], {
		cacheTime: minutes(5),
		queryFn: () =>
			axiosExtract(classificationApi.retrieveAllClassifications()).then((classifications) =>
				classifications.flatMap((c) =>
					c.fieldType !== "TAG"
						? []
						: [
								{
									label: c.name!,
									value: c.classificationUuid!,
									// group: c.fieldType!,
								},
						  ],
				),
			),
	});

	return (
		<Column flexGrow={0} gap={16}>
			<Row flexGrow={0} gap={8}>
				<Text type="Body/L/Bold">Select data source</Text>
				{/* <InfoTooltip> // TODO: uncomment when the article is ready
					<TooltipContent>
						<Text type="Body/M/Book">Get to know more about data sources. &nbsp;</Text>
						<ActionText
							type="Body/S/Book"
							onClick={() =>
								// TODO: change article
								Intercom.showArticle("createOrOptimisePortfolio")
							}
						>
							Read more.
						</ActionText>
					</TooltipContent>
				</InfoTooltip> */}
			</Row>
			<Row flexGrow={0} classList="w-full">
				<AvailableSoonOverlay classList="w-full" hideBadge={!isCustomComingSoon}>
					<ButtonGroupRadio
						classList="w-full"
						value={commentaryModelType}
						onChange={onChangeDataSource}
						size="x-small"
						options={[
							{
								classList: "w-1/2 justify-center",
								"data-qualifier": `DataSourceSelector/RISK_MODEL`,
								value: "RISK_MODEL",
								children: "Risk model",
							},
							{
								classList: "w-1/2 justify-center",
								"data-qualifier": `DataSourceSelector/TAG`,
								value: "TAG",
								children: "Tags",
								disabled: isCustomComingSoon,
							},
						]}
					/>
				</AvailableSoonOverlay>
			</Row>
			{riskModelDescription && commentaryModelType === "RISK_MODEL" && (
				<Row>
					<Text type="Body/M/Book">{riskModelDescription}</Text>
				</Row>
			)}
			{customClassificationDescription && commentaryModelType === "TAG" && (
				<Row>
					<Text type="Body/M/Book">{customClassificationDescription}</Text>
				</Row>
			)}
			{commentaryModelType === "TAG" && (
				<FormField label="Select Classification" error={error}>
					{({ id, invalid }) => (
						<ReactQueryWrapperBase query={query}>
							{(classificationOptions) => (
								<AvailableSoonOverlay classList="w-full" hideBadge={!isClassificationSelectionComingSoon}>
									<Select
										enableSearch
										classList="w-full"
										id={id}
										invalid={invalid}
										options={classificationOptions}
										value={tagUUID}
										onChange={onChangeClassificationUuid}
										disabled={isClassificationSelectionComingSoon}
									/>
								</AvailableSoonOverlay>
							)}
						</ReactQueryWrapperBase>
					)}
				</FormField>
			)}
		</Column>
	);
}

export type DataSourceFormFieldValues = {
	commentaryModelType: "RISK_MODEL" | "TAG";
	tagUUID: string | null;
};

export function DataSourceFormSection<T extends DataSourceFormFieldValues>({
	control,
	riskModelDescription,
	customClassificationDescription,
	isCustomComingSoon,
	isClassificationSelectionComingSoon,
}: {
	control: Control<T, any>;
	riskModelDescription?: string;
	customClassificationDescription?: string;
	isCustomComingSoon?: boolean;
	isClassificationSelectionComingSoon?: boolean;
}): JSX.Element {
	return (
		<FormController
			control={control}
			name={"commentaryModelType" as Path<T>} // just needed a little nudge, the specified keys are guaranteed to exist by the above "extends" requirement
			render={({ field: dataSourceField }) => (
				<FormController
					control={control}
					name={"tagUUID" as Path<T>} // just needed a little nudge, the specified keys are guaranteed to exist by the above "extends" requirement
					render={({ field: classificationField, fieldState }) => (
						<DataSourceSection
							tagUUID={classificationField.value}
							onChangeClassificationUuid={classificationField.onChange}
							commentaryModelType={
								(dataSourceField.value as DataSourceFormFieldValues["commentaryModelType"] | undefined) ??
								("RISK_MODEL" as const)
							}
							onChangeDataSource={dataSourceField.onChange}
							riskModelDescription={riskModelDescription}
							customClassificationDescription={customClassificationDescription}
							isCustomComingSoon={isCustomComingSoon}
							isClassificationSelectionComingSoon={isClassificationSelectionComingSoon}
							error={fieldState.error?.message}
						/>
					)}
				/>
			)}
		/>
	);
}

export const dataSourceValidation = z
	.object({
		commentaryModelType: z.enum(["RISK_MODEL", "TAG"]),
		tagUUID: z.string().optional().nullable(),
	})
	.passthrough()
	.refine(({ commentaryModelType, tagUUID }) => commentaryModelType === "RISK_MODEL" || tagUUID != null, {
		path: ["tagUUID"],
		message: "Please select a classification",
	});
