import type { IndexTicker, ReviewTicker } from "$root/api/api-gen";
import { InstrumentsCustomizationControllerV3ApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { useCompositionBuilder } from "$root/functional-areas/universe/composition";
import { axiosExtract } from "$root/third-party-integrations/axios";
import type { MaybePromise } from "$root/utils";
import { minutes, qualifier, useQueryNoRefetch } from "$root/utils";
import { Button, Dialog, DialogFooter, SubmitButton, Tab, TabGroup } from "@mdotm/mdotui/components";
import { useMultiSelect } from "@mdotm/mdotui/headless";
import { adaptAnimatedNodeProvider, spawn, useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import type BigNumber from "bignumber.js";
import { Map } from "immutable";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { MinimumViableInstrument } from "../InstrumentsSelectorTable";
import { InstrumentsSelectorTable } from "../InstrumentsSelectorTable";
import { InstrumentsWeightsTable } from "../InstrumentsWeightsTable";

export type InstrumentClassificationSubmitParams = {
	indices: MinimumViableInstrument[];
	weights: Map<string, BigNumber | null>;
};
export type InstrumentClassificationDialogProps = {
	show: boolean;
	onClose?(): void;
	onHidden?(): void;
	defaultInstrumentProxies?: ReviewTicker["proxies"];
	onSubmitAsync: (params: InstrumentClassificationSubmitParams) => MaybePromise<void>;
};

export function InstrumentClassificationDialog({
	defaultInstrumentProxies,
	show,
	onClose,
	onHidden,
	onSubmitAsync,
}: InstrumentClassificationDialogProps): JSX.Element {
	const [tabIndex, setTabIndex] = useState<0 | 1>(
		(() => {
			const isFirstStepFilled = !!defaultInstrumentProxies?.length;

			if (isFirstStepFilled) {
				return 1;
			}

			return 0;
		})(),
	);
	const { t } = useTranslation();

	const instrumentCustomizationApi = useApiGen(InstrumentsCustomizationControllerV3ApiFactory);
	const proxyCompositionQuery = useQueryNoRefetch({
		queryKey: ["instrumentProxySelectable"],
		cacheTime: minutes(5),
		keepPreviousData: false,
		queryFn: async () => {
			const indices = await axiosExtract(instrumentCustomizationApi.getRiskModelAvailableIndexes());

			return {
				indices:
					indices?.map(
						(x) =>
							({
								assetClass: x.macroAssetClass!,
								geography: x.macroGeography!,
								granularity: x.granularity!,
								instrument: x.name!,
								microAssetClass: x.microAssetClass!,
								microGeography: x.microGeography!,
								ticker: x.ticker!,
							}) satisfies IndexTicker,
					) ?? [],
			};
		},
	});

	const decimalPlaces = 4;

	const compositionBuilder = useCompositionBuilder(() => ({
		composition: Map<string, number>(),
		decimalPlaces,
	}));

	// Step 1
	const selectedInstrumentsMultiSelectCtx = useMultiSelect<string>();

	const refs = useUnsafeUpdatedRef({ compositionBuilder, selectedInstrumentsMultiSelectCtx, defaultInstrumentProxies });

	useEffect(() => {
		const indices = proxyCompositionQuery.data?.indices;
		if (!indices) {
			return;
		}
		const existingInstrumentOnSelectableIndices =
			refs.current.defaultInstrumentProxies?.filter((instrument) =>
				indices.find((index) => index.ticker === instrument.ticker),
			) ?? [];

		refs.current.selectedInstrumentsMultiSelectCtx.setSelection(
			existingInstrumentOnSelectableIndices.map((instrument) => instrument.ticker!),
		);

		refs.current.compositionBuilder.reset({
			composition: Map<string, number>(
				existingInstrumentOnSelectableIndices.map(({ ticker, weight }) => [ticker!, weight ?? 0]) ?? [],
			),
			decimalPlaces,
		});
	}, [proxyCompositionQuery.data?.indices, refs]);

	// Step 2
	const selectedInstruments = useMemo(() => {
		if (proxyCompositionQuery.data?.indices) {
			return proxyCompositionQuery.data.indices.filter((x) =>
				selectedInstrumentsMultiSelectCtx.selection.has(x.ticker),
			);
		}
		return [];
	}, [proxyCompositionQuery.data?.indices, selectedInstrumentsMultiSelectCtx]);

	useEffect(() => {
		if (tabIndex === 1 && selectedInstruments.length > 0) {
			refs.current.compositionBuilder.reset({
				composition: Map<string, number>(
					selectedInstrumentsMultiSelectCtx.selection
						.toArray()
						.map((ticker) => [ticker, refs.current.compositionBuilder.getWeight(ticker ?? "")?.toNumber() ?? 0]),
				),
				decimalPlaces,
			});
		}
	}, [refs, selectedInstrumentsMultiSelectCtx, tabIndex, selectedInstruments]);

	const innerSubmitAsync = useCallback(async () => {
		if (tabIndex === 0) {
			setTabIndex(1);
		} else {
			await onSubmitAsync({
				indices: selectedInstruments,
				weights: compositionBuilder.getComposition(),
			});
			onClose?.();
		}
	}, [compositionBuilder, onClose, onSubmitAsync, selectedInstruments, tabIndex]);

	return (
		<Dialog
			noValidate
			onAnimationStateChange={(state) => {
				if (state === "hidden") {
					setTabIndex(0);
					onHidden?.();
				}
			}}
			size="xxlarge"
			onSubmitAsync={innerSubmitAsync}
			header={t("INSTRUMENT_CLASSIFICATION.DIALOG.TITLE")}
			footer={
				<DialogFooter
					primaryAction={
						tabIndex === 0 ? (
							<SubmitButton
								disabled={selectedInstrumentsMultiSelectCtx.selection.size === 0}
								data-qualifier={qualifier.instrumentClassificationDialog.next}
							>
								{t("INSTRUMENT_CLASSIFICATION.DIALOG.NEXT")}
								{/* {t("INSTRUMENT_CLASSIFICATION.DIALOG.SET_COMP")} */}
							</SubmitButton>
						) : (
							<SubmitButton
								disabled={Boolean(
									compositionBuilder.getComposition().findEntry((x) => x === null) ||
										!compositionBuilder.getTotalWeight().eq(100),
								)}
								data-qualifier={qualifier.instrumentClassificationDialog.save}
							>
								{t("INSTRUMENT_CLASSIFICATION.DIALOG.SAVE")}
							</SubmitButton>
						)
					}
					secondaryAction={
						tabIndex > 0 ? (
							<Button
								palette="secondary"
								onClick={() => setTabIndex((n) => (n - 1) as 0 | 1)}
								data-qualifier={qualifier.instrumentClassificationDialog.previous}
							>
								{t("INSTRUMENT_CLASSIFICATION.DIALOG.PREVIOUS")}
							</Button>
						) : undefined
					}
					neutralAction={
						<Button
							palette="tertiary"
							onClick={() => onClose?.()}
							data-qualifier={qualifier.instrumentClassificationDialog.close}
						>
							{t("INSTRUMENT_CLASSIFICATION.DIALOG.CLOSE")}
						</Button>
					}
				/>
			}
			show={show}
			onClose={onClose}
		>
			<ReactQueryWrapperBase query={proxyCompositionQuery}>
				{(selectable) => (
					<TabGroup
						tabIndex={tabIndex}
						onTabChange={(tabNumber) => {
							if (tabNumber === 1 && selectedInstrumentsMultiSelectCtx.selection.size === 0) {
								return;
							}

							return setTabIndex(tabNumber as 0 | 1);
						}}
					>
						<Tab
							title={`1. ${t("INSTRUMENT_CLASSIFICATION.TABS.SELECT_INDICES")}`}
							data-qualifier={qualifier.instrumentClassificationDialog.tabs.selectIndices}
						>
							<InstrumentsSelectorTable
								instruments={selectable.indices}
								multiSelectCtx={selectedInstrumentsMultiSelectCtx}
								mode="checkbox"
							/>
						</Tab>
						<Tab
							title={`2. ${t("INSTRUMENT_CLASSIFICATION.TABS.COMP_WEIGHTS")}`}
							data-qualifier={qualifier.instrumentClassificationDialog.tabs.compositionWeights}
						>
							<InstrumentsWeightsTable
								instruments={selectedInstruments}
								onRemoveInstrument={(ticker) => {
									selectedInstrumentsMultiSelectCtx.remove(ticker);
									compositionBuilder.toggleRemove(ticker ?? "");
								}}
								compositionBuilder={compositionBuilder}
							/>
						</Tab>
					</TabGroup>
				)}
			</ReactQueryWrapperBase>
		</Dialog>
	);
}

export type SpawnInstrumentClassificationDialogParams = Omit<InstrumentClassificationDialogProps, "onClose" | "show">;
export function spawnInstrumentClassificationDialog(params: SpawnInstrumentClassificationDialogParams): Promise<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ resolve, show, onHidden }) => (
			<InstrumentClassificationDialog
				{...params}
				show={show}
				onClose={() => resolve()}
				onHidden={onHidden}
				onSubmitAsync={async (submitParams) => {
					await params.onSubmitAsync(submitParams);
					resolve();
				}}
			/>
		)),
	).promise;
}
