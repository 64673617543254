import { platformToast } from "$root/notification-system/toast";
import { ToastableError } from "$root/utils";
import { noop, unpromisify } from "@mdotm/mdotui/utils";
import type { LogDtoLevelEnum } from "../api-gen";
import { LogControllerApiFactory } from "../api-gen";
import { getApiGen } from "../factory";

const reportPlatformErrorConfig = {
	uid: null as null | string,
};

export function configReportPlatformError(config: Partial<typeof reportPlatformErrorConfig>): void {
	Object.assign(reportPlatformErrorConfig, config);
}

const mappedLogSeverity: Record<LogDtoLevelEnum, (message?: any, ...optionalParams: any[]) => void> = {
	DEBUG: console.debug,
	ERROR: console.error,
	INFO: console.log,
	WARN: console.warn,
};

export type PlatformErrorAreas =
	| "user"
	| "dashboard"
	| "outlook"
	| "outlook-focus"
	| "hmm"
	| "custom-report"
	| "proxy"
	| "global"
	| "advanced-settings"
	| "portfolio"
	| "universe"
	| "market-views"
	| "market-views-settings"
	| "target-portfolio"
	| "benchmark"
	| "alerts"
	| "ask-intelligence"
	| "commentary-builder"
	| "report-builder"
	| "portfolio-creation"
	| "instrument-customisation";

export type AttemptedOperation = {
	message: string;
	payload?: string;
};

export async function reportPlatformErrorPromise(
	error: unknown,
	severity: LogDtoLevelEnum,
	area?: PlatformErrorAreas,
	attemptedOperation?: AttemptedOperation,
): Promise<void> {
	if (attemptedOperation) {
		const message = `An error occurred while performing the following operation: ${attemptedOperation.message}, ${
			attemptedOperation.payload ? `payload: ${attemptedOperation.payload}, ` : ""
		} trace: (${error})`;
		mappedLogSeverity[severity](message, error);
		await getApiGen(LogControllerApiFactory).log({ level: severity, area, message });
	} else {
		const message = `An error occurred (user: "${reportPlatformErrorConfig.uid}"), trace: (${error})`;
		mappedLogSeverity[severity](message, error);
		await getApiGen(LogControllerApiFactory).log({ level: severity, area, message });
	}
}

/**
 *
 * @param error
 * @param severity
 * @param area
 * @param attemptedOperation
 */
export function reportPlatformError(
	error: unknown,
	severity: LogDtoLevelEnum,
	area?: PlatformErrorAreas,
	attemptedOperation?: AttemptedOperation,
): void {
	unpromisify(() => reportPlatformErrorPromise(error, severity, area, attemptedOperation).catch(noop))();
}

/**
 * Executes a given asynchronous function with error reporting and user notifications.
 *
 * @template T The return type of the asynchronous function.
 * @param {() => Promise<T>} fn - The asynchronous function to execute.
 * @param {Object} opts - Options for error reporting and user notifications.
 * @param {PlatformErrorAreas} opts.area - The platform area where the error occurred.
 * @param {AttemptedOperation} opts.attemptedOperation - A description of the operation attempted when the error occurred.
 * @returns {Promise<T>} A promise that resolves to the result of the asynchronous function, or rejects with the encountered error.
 *
 * @throws Will rethrow any error encountered during execution of the `fn` function after reporting it.
 *
 * @example
 * Example:
 * ```
 * <AsyncButton
 * 	onClickAsync={async () => {
 * 		const x = await runWithErrorReporting(
 * 			async () => {
 * 				try {
 * 					const response = await api();
 * 					return response;
 * 				} catch (err) {
 * 					if (err.statusCode == 502) {
 * 						throw new ToastableError("Oh nooooo", {
 * 							cause: err,
 * 							icon: "Portfolio",
 * 						});
 * 					}
 * 					throw err;
 * 				}
 * 			},
 * 			{
 * 				area: "portfolio",
 * 				attemptedOperation: "save proposal",
 * 			},
 * 		);
 * 	}}
 * >
 * 	Test
 * </AsyncButton>;
 * ```
 */

export async function runWithErrorReporting<T>(
	fn: () => Promise<T>,
	opts: {
		area: PlatformErrorAreas;
		attemptedOperation: AttemptedOperation;
	},
): Promise<T> {
	try {
		return await fn();
	} catch (err) {
		if (err instanceof ToastableError) {
			platformToast({
				children: err.endUserMsg,
				icon: err.icon ?? "Icon-full-alert",
				severity: "error",
			});
		} else {
			platformToast({
				children: "something went wrong",
				icon: "Icon-full-alert",
				severity: "error",
			});
		}

		reportPlatformError(err, "ERROR", opts.area, opts.attemptedOperation);
		throw err;
	}
}
