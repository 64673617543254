/**
 * 1. root node
 * 3. self open
 * 4. track depth selection (maybe add id selection)
 * 5. matching value
 * 6. onChange // {
 *     onChange will be triggered just the last level
 *     the params provided will be just value
 *     should i keep track of the user selection history? yes
 * }
 * 2. add search
 * 7. virtual scroll ?
 * 9. open in scrollview il option is selected
 */

import type { FloatingContentProps, FloatingContentStrategy, I18nProps } from "@mdotm/mdotui/components";
import {
	ClickableDiv,
	Column,
	DebouncedSearchInput,
	FloatingContent,
	Icon,
	Row,
	ScrollWrapper,
	useMergeI18n,
	type DataAttributesProps,
	type FieldSize,
	type I18nRecord,
	type InnerRefProps,
	type OptionWithOptionalGroup,
	type StylableProps,
} from "@mdotm/mdotui/components";
import {
	focusOnMount,
	ForEach,
	overrideClassList,
	overrideClassName,
	propagateRef,
	renderNodeOrFn,
	useDeepEqualEffect,
	useDrivenState,
	useSize,
	useUniqueDOMId,
	WrapIfStrOrNumOrEmpty,
} from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { ReactNode } from "react";
import { memo, useCallback, useEffect, useMemo, useRef, useState, type KeyboardEventHandler } from "react";
import fastDeepEqual from "fast-deep-equal";
import { levenshteinSort } from "@mdotm/mdotui/utils";
import type React from "react";

export type SelectI18n = {
	searchPlaceholder: [void, string];
	triggerPlaceholder: [void, string];
};

const defaultSelectI18n: I18nRecord<SelectI18n> = {
	searchPlaceholder: () => "Search...",
	triggerPlaceholder: () => "Select...",
};

const hierarchicalSelectTriggerClassName = [
	"relative flex flex-row justify-between items-center z-0 rounded-[4px] font-title",
	"overflow-hidden text-ellipsis",

	`border text-[#1d2433] placeholder:text-[${themeCSSVars.palette_N300}] border-[${themeCSSVars.palette_N200}] focus:border-[${themeCSSVars.palette_N500}] focus:outline-none`,
	`bg-[#ffffff] disabled:border-[${themeCSSVars.palette_N100}] disabled:text-[${themeCSSVars.palette_N400}] disabled:bg-[${themeCSSVars.palette_N100}]`,
	`data-[invalid=true]:border-[${themeCSSVars.palette_D500}]`,

	`enabled:data-[multi-active=true]:border-[${themeCSSVars.Button_border_secondary}] enabled:data-[multi-active=true]:text-[color:${themeCSSVars.Button_fg_secondary}]`,
].join(" ");

export type HierarchicalOption<T, K extends string = string> = {
	label: string;
	id: K; //idk
	disabled?: boolean;
} & (
	| {
			value: T;
			children?: undefined;
	  }
	| {
			value?: undefined;
			children: HierarchicalOption<T, K>[];
	  }
);

export type HierarchicalSelectTriggerProps<
	T,
	K extends string = string,
	Trigger extends HTMLElement = HTMLElement,
> = StylableProps &
	InnerRefProps<Trigger> & {
		id?: string;
		invalid?: boolean;
		onClick(): void;
		onKeyDown?: KeyboardEventHandler<HTMLButtonElement>;
		open: boolean;
		listboxId: string;
		unstyled?: boolean;
		size?: FieldSize;
		selectedOption?: HierarchicalOption<T, K>;
		placeholder?: string;
		disabled?: boolean;
		name?: string;
	} & DataAttributesProps;

export function HierarchicalSelectTrigger<T, K extends string = string, Trigger extends HTMLElement = HTMLElement>({
	id,
	invalid = false,
	onClick,
	onKeyDown,
	innerRef,
	open,
	listboxId,
	classList,
	style,
	unstyled = false,
	size = "small",
	selectedOption,
	placeholder,
	disabled = false,
	name,
	...dataAttrs
}: HierarchicalSelectTriggerProps<T, K, Trigger>): JSX.Element {
	return (
		<button
			id={id}
			name={name}
			disabled={disabled}
			className={overrideClassName(
				{
					[hierarchicalSelectTriggerClassName]: !unstyled,
					"text-[16px] h-[38px] px-[16px]": !unstyled && size === "large",
					"text-[14px] h-[34px] px-2.5": !unstyled && size === "small",
					"text-[12px] h-[26px] px-1.5": !unstyled && size === "x-small",
				},
				classList,
			)}
			style={style}
			data-invalid={invalid}
			aria-expanded={open}
			aria-controls={listboxId}
			// data-multi-active={selectedOption != null}
			onClick={onClick}
			ref={innerRef as React.ForwardedRef<HTMLButtonElement>}
			type="button"
			onKeyDown={onKeyDown}
			{...dataAttrs}
		>
			<span
				className="mr-3 data-[is-placeholder=true]:text-[#80848B] inline-block whitespace-nowrap overflow-hidden text-ellipsis"
				data-is-placeholder={selectedOption == null}
			>
				{selectedOption?.label || placeholder || ""}
			</span>
			<span className="transition-transform data-[open=true]:[transform:rotateX(180deg)] flex" data-open={open}>
				<Icon icon="Down" />
			</span>
		</button>
	);
}

type HierarchicalSelectProps<T, K extends string = string, Trigger extends HTMLElement = HTMLElement> = StylableProps &
	I18nProps<SelectI18n> &
	InnerRefProps<Trigger> & {
		id?: string;
		invalid?: boolean;
		options: HierarchicalOption<T, K>[];
		value: T | null;
		onChange?(selectedOption: T): void;
		trigger?(props: HierarchicalSelectTriggerProps<T, K, Trigger>): ReactNode;
		floatingAppearance?: StylableProps;
		unstyled?: boolean;
		enableSearch?: boolean;
		disabled?: boolean;
		strategy?: FloatingContentStrategy;
		size?: FieldSize;
		openAt?: string[] /** node id */;
		/** @default true */
		closeOnScroll?: boolean;
		triggerDataAttrs?: DataAttributesProps;
	} & DataAttributesProps;

function _HierarchicalSelect<T, K extends string = string, Trigger extends HTMLElement = HTMLElement>({
	id,
	invalid,
	value,
	options,
	onChange,
	style,
	classList,
	floatingAppearance,
	unstyled = false,
	enableSearch = false,
	i18n,
	disabled: propsDisabled,
	strategy = "absolute",
	size,
	closeOnScroll = true,
	innerRef,
	// openAt,
	trigger: propsTrigger,
	triggerDataAttrs,
	...dataAttrs
}: HierarchicalSelectProps<T, K, Trigger>): JSX.Element {
	const disabled = propsDisabled || options.length === 0;
	const allI18n = useMergeI18n({ fallback: defaultSelectI18n, overrides: i18n });

	const [open, setOpen] = useState(false);
	const toggle = useCallback(() => {
		setOpen((o) => !o);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const valuePath = useMemo(() => (value ? createNodePathFromValue(options, { value }) : []), [value, options]);

	const [selectedNodes, setSelectedNodes] = useState<{
		currentSelectionPath: Array<HierarchicalOption<T, K>>;
		latestSelectionPath: Array<HierarchicalOption<T, K>>;
	}>({
		currentSelectionPath: [],
		latestSelectionPath: [],
	});

	useEffect(() => {
		if (open) {
			setSelectedNodes(() => ({
				currentSelectionPath: valuePath,
				latestSelectionPath: valuePath,
			}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	const selectedOption = useMemo(() => {
		const selectedNode = valuePath.at(-1);
		if (selectedNode?.children) {
			return selectedNode.children.find((x) => fastDeepEqual(x.value, value));
		}

		return undefined;
	}, [valuePath, value]);

	const triggerRef = useRef<HTMLElement | null>(null);
	const closeAndFocus = useCallback(() => {
		close();
		triggerRef.current?.focus({ preventScroll: true });
	}, [close]);

	useEffect(() => {
		if (disabled && open) {
			closeAndFocus();
		}
	}, [closeAndFocus, disabled, open]);

	const searchBoxRef = useRef<HTMLInputElement | null>(null);
	const focusSearchBox = useCallback<NonNullable<FloatingContentProps["onAnimationStateChange"]>>((state) => {
		if (state === "shown" && searchBoxRef.current) {
			focusOnMount(searchBoxRef.current);
		}
	}, []);

	const [keyDownHandler, setKeyDownHandler] = useState<{
		handler: KeyboardEventHandler<HTMLButtonElement>;
	} | null>(null);

	const registerKeyDownHandler = useCallback(
		(handler: KeyboardEventHandler<HTMLButtonElement>) => setKeyDownHandler({ handler }),
		[],
	);

	const listboxId = useUniqueDOMId();
	const trigger = useCallback(
		(triggerProps: InnerRefProps<Trigger>) => {
			const props = {
				id,
				invalid,
				disabled,
				listboxId,
				onKeyDown: open ? keyDownHandler?.handler : undefined /** handle key down */,
				open,
				onClick: toggle,
				innerRef: (el: Trigger | null) => {
					propagateRef(el, triggerProps.innerRef);
					propagateRef(el, innerRef);
					triggerRef.current = el;
				},
				size,
				unstyled,
				classList,
				style,
				placeholder: allI18n.triggerPlaceholder(),
				selectedOption,
				...triggerDataAttrs,
			};
			return <>{propsTrigger ? propsTrigger(props) : <HierarchicalSelectTrigger {...props} />}</>;
		},
		[
			allI18n,
			classList,
			disabled,
			id,
			innerRef,
			invalid,
			keyDownHandler?.handler,
			listboxId,
			open,
			propsTrigger,
			selectedOption,
			size,
			style,
			toggle,
			unstyled,
			triggerDataAttrs,
		],
	);

	return (
		<FloatingContent
			strategy={strategy}
			relativePositionOffsets={{
				top: -8,
				left: -8,
				right: 8,
				bottom: 8,
			}}
			onAnimationStateChange={focusSearchBox}
			forcePosition
			open={open}
			onClickAway={close}
			onScrollOutside={closeOnScroll ? closeAndFocus : undefined}
			position="bottom"
			align="startToStart"
			focusOnOpen={false}
			trigger={trigger}
		>
			{({ triggerBox: { width } }) => (
				<Column
					alignItems="stretch"
					justifyContent="start"
					classList={overrideClassList(
						"shadow-[0px_8px_32px_rgba(0,0,0,0.16)] rounded bg-white",
						floatingAppearance?.classList,
					)}
					style={floatingAppearance?.style}
				>
					<ListBox
						alwaysOutlineFirst={false}
						selectedNodes={selectedNodes.currentSelectionPath}
						options={selectedNodes.currentSelectionPath.at(-1)?.children ?? options}
						onChangeNode={(newNodePath) => {
							setSelectedNodes((latest) => ({ ...latest, currentSelectionPath: newNodePath }));
						}}
						onChange={(x) => {
							onChange?.(x);
							setSelectedNodes((latest) => ({
								currentSelectionPath: latest.currentSelectionPath,
								latestSelectionPath: latest.currentSelectionPath,
							}));
						}}
						placeholder={allI18n.searchPlaceholder()}
						minWidth={width}
						id={listboxId}
						open={open}
						value={value}
						enableSearch={enableSearch}
						searchBoxRef={searchBoxRef}
						onClose={closeAndFocus}
						registerHandleKeyDown={registerKeyDownHandler} //noop
						{...dataAttrs}
					/>
				</Column>
			)}
		</FloatingContent>
	);
}

type ListBoxProps<T, K extends string = string> = InnerRefProps<HTMLDivElement> &
	StylableProps & {
		value: T | null;
		selectedNodes: Array<HierarchicalOption<T, K>>;
		options: HierarchicalOption<T, K>[];
		onChangeNode?(newSelectNodes: Array<HierarchicalOption<T, K>>): void;
		onChange?(newValue: T): void;

		id: string;
		minWidth: number;
		enableSearch: boolean;
		searchBoxRef?: { current: HTMLInputElement | null };
		registerHandleKeyDown(handler: KeyboardEventHandler<HTMLButtonElement>): void;
		onClose(): void;
		placeholder?: string;
		open: boolean;
		alwaysOutlineFirst: boolean;
		externalQuery?: string;
	} & DataAttributesProps;

function ListBox<T, K extends string = string>({
	selectedNodes,
	value,
	options,
	onChangeNode,
	onChange,

	id,
	minWidth,
	enableSearch,
	searchBoxRef,
	registerHandleKeyDown,
	onClose,
	placeholder,
	open,
	alwaysOutlineFirst,
	externalQuery,

	innerRef,
	classList,
	style,

	...dataAttrs
}: ListBoxProps<T, K>) {
	const [scrollAreaRef, setScrollAreaRef] = useState<HTMLDivElement | null>(null);
	const [debouncedQuery, setQuery] = useDrivenState(externalQuery ?? "");

	const filteredOptions = useMemo(() => {
		if (debouncedQuery.length === 0) {
			return options;
		}
		return levenshteinSort(options, debouncedQuery, (option) => option.label);
	}, [debouncedQuery, options]);

	const [outlinedItemIndex, setOutlinedItemIndex] = useState<number | null>(null);
	const selectedIndex = useMemo(() => options.findIndex((opt) => fastDeepEqual(opt.value, value)), [options, value]);
	useEffect(() => {
		setOutlinedItemIndex(selectedIndex === -1 ? null : selectedIndex);
	}, [selectedIndex]);

	const scrollAreaSize = useSize(scrollAreaRef);
	useEffect(() => {
		if (!scrollAreaRef || outlinedItemIndex === null || !scrollAreaSize) {
			return;
		}
		const liElement = scrollAreaRef.querySelector(`[data-listbox-index="${outlinedItemIndex}"]`) as HTMLLIElement;
		if (!liElement) {
			return;
		}
		const scrollBehaviorSnapshot = scrollAreaRef.style.scrollBehavior;
		scrollAreaRef.style.scrollBehavior = "";
		scrollAreaRef.scrollTop = Math.max(0, liElement.offsetTop - scrollAreaSize.height / 2);
		scrollAreaRef.style.scrollBehavior = scrollBehaviorSnapshot;
	}, [scrollAreaSize, outlinedItemIndex, scrollAreaRef]);

	const selectedNode = useMemo(() => selectedNodes.at(-1), [selectedNodes]);

	return (
		<Column classList="min-h-0" innerRef={innerRef} {...dataAttrs}>
			{enableSearch && (
				<DebouncedSearchInput
					externalQuery={externalQuery ?? ""}
					onChange={setQuery}
					inputAppearance={{
						classList: "!border-none",
					}}
					classList="py-1 border-b border-b-[#BFC4CE]"
					innerRef={searchBoxRef}
					placeholder={placeholder}
					// onKeyDown={handleKeyDown} // todo
				/>
			)}
			{selectedNode && (
				<ClickableDiv
					classList="flex flex-row grow min-w-0 hover:bg-[#EDF7F5] cursor-pointer relative"
					onClick={() => onChangeNode?.(selectedNodes.slice(0, -1))}
				>
					<Row gap={2}>
						<Icon
							icon="Left"
							classList="ml-auto absolute left-2 top-1/2 -translate-y-1/2"
							size={16}
							style={{ display: "inline-block" }}
						/>
						<span className="text-start font-medium block grow truncate relative z-0 text-[14px] pl-8 pr-4 py-2.5">
							{selectedNode.label}
						</span>
					</Row>
				</ClickableDiv>
			)}
			<ScrollWrapper
				innerRef={setScrollAreaRef}
				direction="vertical"
				style={style}
				classList={overrideClassName("min-h-0 max-h-[300px] sm:max-w-[620px] max-w-[90vw]", classList)}
			>
				<ul
					id={id}
					role="listbox"
					style={{
						minWidth,
					}}
				>
					<ForEach collection={filteredOptions} keyProvider={({ label }, index) => `${label}-${index}`}>
						{({ item: opt, index }) => (
							<li
								data-listbox-index={index}
								aria-selected={fastDeepEqual(opt.value, value)}
								aria-current={index === outlinedItemIndex}
								aria-disabled={opt.disabled}
								role="option"
								className="flex flex-row grow min-w-0 group/option aria-[selected=true]:bg-[#EDF7F5] aria-[current=true]:bg-[#EFF0F3] hover:bg-[#EDF7F5] aria-disabled:text-[#80848b] cursor-pointer"
								onClick={() => {
									if (!opt.disabled) {
										if (opt.children && opt.children.length > 0) {
											const newNode = [...selectedNodes, opt];
											onChangeNode?.(newNode);
										}

										if (opt.value) {
											onChange?.(opt.value);
											onClose();
										}
									}
								}}
							>
								<WrapIfStrOrNumOrEmpty
									wrapper={(content) => (
										<span className="text-start block grow truncate relative z-0 text-[14px] pl-4 pr-8 py-2.5">
											{content}
											<Icon
												icon="Outline"
												classList="ml-auto absolute right-2 top-1/2 -translate-y-1/2 invisible group-aria-selected/option:visible"
												size={20}
												style={{ display: "inline-block" }}
											/>
										</span>
									)}
								>
									{opt.children && opt.children.length > 0 ? (
										<Row justifyContent="space-between" classList="relative">
											<span className="text-start block grow truncate relative z-0 text-[14px] pl-4 pr-8 py-2.5">
												{opt.label}
											</span>
											<Icon
												icon="Right"
												classList="ml-auto absolute right-2 top-1/2 -translate-y-1/2"
												size={16}
												style={{ display: "inline-block" }}
											/>
										</Row>
									) : (
										opt.label
									)}
								</WrapIfStrOrNumOrEmpty>
							</li>
						)}
					</ForEach>
				</ul>
			</ScrollWrapper>
		</Column>
	);
}

function findSelectedPath<T, K extends string = string>(
	nodes: HierarchicalOption<T, K>[],
	{ pathsToMatch, path = [] }: { pathsToMatch: string[]; path?: string[] },
) {
	const nodeId = pathsToMatch.shift();
	for (const node of nodes) {
		if (nodeId === node.id && node.children && node.children.length > 0) {
			return findSelectedPath(node.children, { pathsToMatch, path: [...path, nodeId] });
		}
	}

	return nodeId ? null : path;
}

function createNodePathFromValue<T, K extends string = string>(
	nodes: HierarchicalOption<T, K>[],
	{ value, nodePath = [] }: { value: T; nodePath?: HierarchicalOption<T, K>[] },
): HierarchicalOption<T, K>[] {
	for (const node of nodes) {
		const result = createNodePathFromValue(node.children ?? [], { value, nodePath: [...nodePath, node] });
		const matchedNode = result.at(-1)?.children?.find((x) => fastDeepEqual(x.value, value));
		if (matchedNode) {
			return result;
		}
	}

	return nodePath;
}

function findNodeByPath<T, K extends string = string>(
	nodes: HierarchicalOption<T, K>[],
	{ pathsToMatch, currentNode }: { pathsToMatch: string[]; currentNode: HierarchicalOption<T, K>[] },
): HierarchicalOption<T, K>[] {
	const nodeId = pathsToMatch.shift();
	for (const node of nodes) {
		if (nodeId === node.id && node.children && node.children.length > 0) {
			return findNodeByPath(node.children, { pathsToMatch, currentNode: node.children });
		}
	}

	return nodeId ? nodes : currentNode;
}

export const HierarchicalSelect = memo(_HierarchicalSelect) as typeof _HierarchicalSelect;
