import type { IndicatorType, SentimentType } from "../market-view/analysis/sentiment";

export function categorizeStringAsSentiment(str: string): {
	indicator: IndicatorType;
	sentiment: SentimentType;
} | null {
	const normalisedStr = str.toLowerCase().replace(/[ -]/g, " ");
	switch (normalisedStr) {
		case "low risk":
		case "low risks":
			return { sentiment: "positive", indicator: "regime" };
		case "mid risk":
		case "mid risks":
			return { sentiment: "neutral", indicator: "regime" };
		case "high risk":
		case "high risks":
			return { sentiment: "negative", indicator: "regime" };
		case "low diversification":
		case "low diversifications":
			return { sentiment: "negative", indicator: "driver" };
		case "mid diversification":
		case "mid diversifications":
			return { sentiment: "neutral", indicator: "driver" };
		case "high diversification":
		case "high diversifications":
			return { sentiment: "positive", indicator: "driver" };
		case "high volatility":
			return { sentiment: "negative", indicator: "driver" };
		case "mid volatility":
			return { sentiment: "neutral", indicator: "driver" };
		case "low volatility":
			return { sentiment: "positive", indicator: "driver" };
		case "low return":
		case "low returns":
			return { sentiment: "negative", indicator: "driver" };
		case "mid return":
		case "mid returns":
			return { sentiment: "neutral", indicator: "driver" };
		case "high return":
		case "high returns":
			return { sentiment: "positive", indicator: "driver" };
		case "overweight":
			return { sentiment: "positive", indicator: "positioning" };
		case "strong overweight":
			return { sentiment: "super-positive", indicator: "positioning" };
		case "underweight":
			return { sentiment: "negative", indicator: "positioning" };
		case "strong underweight":
			return { sentiment: "super-negative", indicator: "positioning" };
		case "neutral":
			return { sentiment: "neutral", indicator: "positioning" };
		default:
			return null;
	}
}

const sentimentRegex = () =>
	/\s+([,.;:"'-]?)((low[ -]+risks?)|(mid[ -]+risks?)|(high[ -]+risks?)|(low[ -]+diversifications?)|(mid[ -]+diversifications?)|(high[ -]+diversifications?)|(high[ -]+volatility)|(low[ -]+volatility)|(mid[ -]+volatility)|(low[ -]+returns?)|(mid[ -]+returns?)|(high[ -]+returns?)|((strong[ -]+)?underweight)|((strong[ -]+)?overweight)|(neutral))([,.;:"'-]?)\s+/i;
export function applySentimentBadges(text: string): string {
	let regex = sentimentRegex();
	let match;
	let offset = 0;
	let sliced = text;
	while ((match = regex.exec(sliced)) != null && match.index != null) {
		const wholeMatch = match[0];
		const label = match[2];
		const beginPunctuation = `${match[1] ?? ""}`;
		const endPunctuation = `${match[20] ?? ""}`;

		const data = categorizeStringAsSentiment(label);
		if (!data) {
			// just jump over without altering the original content
			offset = match.index + wholeMatch.length;
		} else {
			// prepare the replacement string
			const replacement = ` ${beginPunctuation}<template data-custom-element="sentiment" data-sentiment="${data.sentiment}" data-indicator="${data.indicator}" data-label="${label}"></template>${endPunctuation} `;

			const newBegin = `${text.slice(0, offset + match.index)}${replacement}`;
			text = `${newBegin}${text.slice(offset + match.index + wholeMatch.length)}`;
			// adjust offset accordingly
			offset = newBegin.length;
		}
		sliced = text.slice(offset);
		regex = sentimentRegex(); // reset cursor
	}

	return text;
}

export function sentimentToString(indicator: "regime" | "positioning", sentiment: SentimentType): string {
	switch (indicator) {
		case "regime":
			switch (sentiment) {
				case "super-positive":
					return "Low Risk";
				case "positive":
					return "Low Risk";
				case "neutral":
					return "Mid Risk";
				case "negative":
					return "High Risk";
				case "super-negative":
					return "High Risk";
			}
			break;
		case "positioning":
			switch (sentiment) {
				case "super-positive":
					return "Strong Overweight";
				case "positive":
					return "Overweight";
				case "neutral":
					return "Neutral";
				case "negative":
					return "Underweight";
				case "super-negative":
					return "Strong Underweight";
			}
			break;
	}
	throw new Error(`unknown mapping for indicator "${indicator}" and sentiment "${sentiment}"`);
}
