import { InvestmentControllerV4ApiFactory } from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { getApiGen } from "$root/api/factory";
import { spawnYesNoDialog } from "$root/components/spawnable/yes-no-dialog";
import { platformToast } from "$root/notification-system/toast";
import { portfolioCommentaryPageBaseQueryKey } from "$root/pages/PortfolioDetails/PortfolioCommentary";
import { queryClient } from "$root/third-party-integrations/react-query";
import { exanteMetricsBlockBaseQueryKey } from "$root/widgets-architecture/widgets/ExanteMetricsBlock";
import { performanceMetricsBlockBaseQueryKey } from "$root/widgets-architecture/widgets/PerformanceMetricsBlock";
import { portfolioCommentaryBlockBaseQueryKey } from "$root/widgets-architecture/widgets/PortfolioCommentaryMDBlock";
import { noop } from "@mdotm/mdotui/utils";

export async function reapplySphereMetrics(uuid: string, regenerateCommentary: boolean): Promise<void> {
	try {
		const investmentControllerV4Api = getApiGen(InvestmentControllerV4ApiFactory);
		await investmentControllerV4Api.deleteCustomMetrics(uuid);

		if (regenerateCommentary) {
			investmentControllerV4Api.regenerateCommentary(uuid).catch((err) =>
				reportPlatformError(err, "WARN", "portfolio", {
					message: "generate commentary after having reapplied sphere metrics",
				}),
			);
			// TODO: once regenerateCommentary becomes an async action (i.e. returns immediately after setting the status to "calculating" instead of waiting for the generation to complete)
			// we should remove this timeout and invalidate the queries with a .then chain
			setTimeout(() => {
				queryClient
					.invalidateQueries({
						predicate: (query) =>
							query.queryKey?.[0] === portfolioCommentaryBlockBaseQueryKey ||
							query.queryKey?.[0] === portfolioCommentaryPageBaseQueryKey,
					})
					.catch(noop);
			}, 1000);
		}

		await queryClient.invalidateQueries({
			predicate: (query) =>
				query.queryKey?.[0] === performanceMetricsBlockBaseQueryKey ||
				query.queryKey?.[0] === exanteMetricsBlockBaseQueryKey ||
				query.queryKey?.[0] === portfolioCommentaryBlockBaseQueryKey ||
				query.queryKey?.[0] === portfolioCommentaryPageBaseQueryKey,
		});
	} catch (err) {
		reportPlatformError(err, "ERROR", "portfolio", { message: "try to reset imported metrics" });
		platformToast({
			icon: "Icon-full-alert",
			severity: "error",
			children: "An error occurred",
		});

		throw err;
	}
}

export async function reapplySphereMetricsWithConfirmationDialog(
	uuid: string,
	regenerateCommentary = true,
): Promise<void> {
	await spawnYesNoDialog({
		header: "Reapply Sphere metrics",
		children: (
			<p className="whitespace-pre-line">
				You are about to reapply Sphere metrics. This action will override the imported values with Sphere&apos;s
				calculated metrics. Are you sure you want to proceed?
			</p>
		),
		yesButton: "Yes, continue",
		noButton: "Cancel",
		onSubmitAsync: () => reapplySphereMetrics(uuid, regenerateCommentary),
	});
}
