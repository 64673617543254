import type { AssetClassSummary, MarketViewSettings } from "$root/api/api-gen";
import {
	MarketScenarioIds,
	OutlookControllerV3ApiFactory,
	type MarketScenario,
	type MarketViewAssetClassAlias,
	type MarketViewAssetClassIdentifier,
	type PositioningIndicator,
	type PositioningIndicatorAssetClassEnum,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { hasAccess } from "$root/components/AuthorizationGuard";
import { typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { InlineTextForm } from "$root/components/smart-text-input/InlineTextForm";
import { useLocaleFormatters } from "$root/localization/hooks";
import { groupedColorProps } from "$root/pages/PortfolioDetails/PortfolioComposition/colors";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { customObjectValuesFn, parallelize, useQueryNoRefetch } from "$root/utils";
import { feelsConverter } from "$root/widgets-architecture/widgets/Positioning/feels-converter";
import type { Option, StylableProps, TableWithGroupsProps } from "@mdotm/mdotui/components";
import {
	ActionText,
	AutoSortTableWithGroups,
	AutoTooltip,
	Controller,
	FormField,
	Row,
	Select,
	Slider,
	Text,
	TooltipContent,
	defaultLabelClassName,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import type { ClassList } from "@mdotm/mdotui/react-extensions";
import { ForEach, Switch, toClassListRecord, toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";
import { Set } from "immutable";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserValue } from "../user";
import { MarketViewSummary } from "./MarketViewSummary";
import { SentimentBadge } from "./analysis/SentimentBadge";

export type MarketScenarioWithMdotmCommentary = MarketScenario & {
	positioningIndicators?: {
		positioningIndicators?: Array<
			PositioningIndicator & {
				mdotmCommentary?: string | null;
				mdotmPositioning?: MarketViewAssetClassIdentifier | null;
			}
		>;
	};
};

export type MarketViewPositioningProps = {
	mode: "view" | "edit";
	scenarioOptions: Option<string>[];
	selectedScenarioId: string | null;
	onSelectedScenarioChange(id: string | null): void;
	marketScenario: MarketScenarioWithMdotmCommentary | null;
	onMarketScenarioChange(newMarketScenario: MarketScenarioWithMdotmCommentary): void;
	marketScenarioProvider(scenarioId: string): MaybePromise<
		MarketScenarioWithMdotmCommentary & {
			creationDate?: string;
		}
	>;
	disableScenarioSelection?: boolean;
	classList?: ClassList;
	canReadCommentary?: boolean;
	alias?: Array<MarketViewAssetClassAlias>;
	header?: { hide?: boolean };
	marketViewSettings?: MarketViewSettings;
	hideOutlookToopltip?: boolean;
};

function groupKeyExtractor({ id }: { id: string }) {
	return id;
}

export type PositioningPreference = 1 | 2 | 3 | 4 | 5;

type AssetClassPreferenceRow = {
	groupId: PositioningIndicatorAssetClassEnum;
	id: MarketViewAssetClassIdentifier;
	name: MarketViewAssetClassIdentifier;
	preference: PositioningPreference;
	microAssetClass: MarketViewAssetClassIdentifier;
	commentary?: string | null;
	mdotmCommentary?: string | null;
	mdotmPositioning?: PositioningPreference | null;
	marketGeography?: string | null;
	marketAssetClass?: string | null;
	marketMicroAssetClass?: string | null;
};

type AssetClassGroup = {
	id: PositioningIndicatorAssetClassEnum;
	name: PositioningIndicatorAssetClassEnum;
	rows: AssetClassPreferenceRow[];
};

const sortMap = {
	EQUITY: 1,
	FIXED_INCOME: 2,
	COMMODITIES: 3,
	ALTERNATIVE: 4,
	CURRENCY: 5,
	MONEY_MARKET: 6,
} satisfies Record<PositioningIndicatorAssetClassEnum, number>;

export function MarketViewPositioning({
	mode,
	onSelectedScenarioChange,
	selectedScenarioId,
	scenarioOptions,
	marketScenario,
	marketScenarioProvider,
	onMarketScenarioChange,
	disableScenarioSelection = false,
	classList,
	canReadCommentary = false,
	alias,
	header,
	marketViewSettings,
	hideOutlookToopltip,
}: MarketViewPositioningProps): JSX.Element {
	const { hide = false } = header ?? {};
	const originalMarketScenarioQuery = useQueryNoRefetch({
		queryKey: ["market-view", "details", selectedScenarioId],
		enabled: Boolean(selectedScenarioId),
		queryFn: () => marketScenarioProvider(selectedScenarioId!),
		onSuccess(indicators) {
			onMarketScenarioChange(indicators);
		},
	});
	return (
		<div
			className={toClassName({
				"@container": true,
				...toClassListRecord(classList),
			})}
		>
			{!hide && (
				<MarketViewSummary
					selectedScenarioId={selectedScenarioId}
					marketScenario={originalMarketScenarioQuery.data}
					marketViewSettings={marketViewSettings}
					loading={originalMarketScenarioQuery.isFetching}
					scenarioLabel={
						<div className="flex flex-row items-end gap-4">
							<Switch
								case={mode}
								match={{
									edit: () => (
										<FormField label="Start with a Market Scenario">
											{(props) => (
												<Select
													data-qualifier="marketViewPositioning/scenarioOptions(edit)"
													{...props}
													classList="min-w-[250px]"
													options={scenarioOptions}
													value={selectedScenarioId}
													onChange={onSelectedScenarioChange}
													disabled={disableScenarioSelection}
													triggerDataAttrs={{
														"data-qualifier": "marketViewPositioning/scenarioOptions/button",
													}}
												/>
											)}
										</FormField>
									),
									view: () => (
										<FormField label="Market Scenario">
											{(props) => (
												<Text
													as="div"
													id={props.id}
													type="Body/L/Bold"
													classList="py-1"
													data-qualifier="marketViewPositioning/scenarioOptions(view)"
												>
													{scenarioOptions.find((x) => x.value === selectedScenarioId)?.label ?? "-"}
												</Text>
											)}
										</FormField>
									),
								}}
							/>
							{mode === "edit" && marketScenario && (
								<div>
									<div className={defaultLabelClassName}>&nbsp;</div>
									<ActionText
										data-qualifier="marketViewPositioning/reset"
										color={themeCSSVars.palette_P600}
										type="Body/M/Book"
										onClick={() =>
											onMarketScenarioChange({
												...marketScenario,
												positioningIndicators: originalMarketScenarioQuery.data?.positioningIndicators,
											})
										}
									>
										Reset
									</ActionText>
								</div>
							)}
						</div>
					}
				/>
			)}
			<ReactQueryWrapperBase query={originalMarketScenarioQuery}>
				{() => (
					<>
						{marketScenario && (
							<PositioningTable
								marketScenario={marketScenario}
								onMarketScenarioChange={onMarketScenarioChange}
								mode={mode}
								alias={alias}
								canReadCommentary={canReadCommentary}
								selectedScenarioId={selectedScenarioId}
								hideOutlookToopltip={hideOutlookToopltip}
							/>
						)}
					</>
				)}
			</ReactQueryWrapperBase>
		</div>
	);
}

function PositioningTable({
	marketScenario,
	mode,
	onMarketScenarioChange,
	canReadCommentary,
	alias,
	selectedScenarioId,
	hideOutlookToopltip,
}: {
	marketScenario: MarketScenario;
	onMarketScenarioChange(newMarketScenario: MarketScenario): void;
	mode: "view" | "edit";
	canReadCommentary?: boolean;
	alias?: Array<MarketViewAssetClassAlias>;
	selectedScenarioId: string | null;
	hideOutlookToopltip?: boolean;
}) {
	const { t } = useTranslation();
	const user = useUserValue();
	const canEditMarketView = hasAccess(user, { requiredService: "CUSTOM_MARKET_VIEW_COMMENTARY_EDITOR" });
	const canEditCommentary =
		hasAccess(user, { requiredService: "CUSTOM_MARKET_VIEW_COMMENTARY_EDITOR" }) && mode === "edit";

	const assetClassGroups = useMemo(
		() =>
			mapToAssetClassGroups(marketScenario.positioningIndicators?.positioningIndicators).sort((a, b) =>
				sortMap[a!.name] > sortMap[b!.name] ? 1 : -1,
			),
		[marketScenario.positioningIndicators?.positioningIndicators],
	);

	const updatePreference = useCallback(
		(
			curMarketScenario: MarketScenarioWithMdotmCommentary,
			assetClass: string,
			microAssetClass: string,
			preference: number,
		) => ({
			...curMarketScenario,
			positioningIndicators: {
				positioningIndicators: curMarketScenario.positioningIndicators?.positioningIndicators?.map((x) =>
					x.assetClass === assetClass && x.microAssetClass === microAssetClass
						? {
								...x,
								userPositioning: preference,
						  }
						: x,
				),
			},
		}),
		[],
	);

	const updateCommentary = useCallback(
		(
			curMarketScenario: MarketScenarioWithMdotmCommentary,
			assetClass: string,
			microAssetClass: string,
			commentary: string,
		) => ({
			...curMarketScenario,
			positioningIndicators: {
				positioningIndicators: curMarketScenario.positioningIndicators?.positioningIndicators?.map((x) =>
					x.assetClass === assetClass && x.microAssetClass === microAssetClass
						? {
								...x,
								commentary,
						  }
						: x,
				),
			},
		}),
		[],
	);

	const onChangeCommentary = useCallback(
		(assetClass: string, microAssetClass: string, commentary: string) =>
			onMarketScenarioChange(updateCommentary(marketScenario, assetClass, microAssetClass, commentary)),
		[marketScenario, onMarketScenarioChange, updateCommentary],
	);

	const mappedAlias = useMemo(
		() =>
			alias?.reduce<Record<MarketViewAssetClassIdentifier, string>>(
				(acc, { alias: x, microAssetClass }) => ({ ...acc, [microAssetClass!]: x! }),
				{} as Record<MarketViewAssetClassIdentifier, string>,
			),
		[alias],
	);

	const outlookApi = useApiGen(OutlookControllerV3ApiFactory);
	function marketComboKey({
		marketAssetClass,
		marketGeography,
		marketMicroAssetClass,
	}: {
		marketAssetClass?: PositioningIndicator["marketAssetClass"] | null;
		marketGeography?: PositioningIndicator["marketGeography"] | null;
		marketMicroAssetClass?: PositioningIndicator["marketMicroAssetClass"] | null;
	}) {
		return `${marketAssetClass}-${marketGeography}-${marketMicroAssetClass}`;
	}

	function tooltipAvailable({
		marketAssetClass,
		marketGeography,
		marketMicroAssetClass,
	}: {
		marketAssetClass?: PositioningIndicator["marketAssetClass"] | null;
		marketGeography?: PositioningIndicator["marketGeography"] | null;
		marketMicroAssetClass?: PositioningIndicator["marketMicroAssetClass"] | null;
	}) {
		// TODO: refactor with BE once the API is normalized
		return Boolean(
			(marketAssetClass === "CO" && marketAssetClass && marketMicroAssetClass) ||
				(marketAssetClass && marketMicroAssetClass && marketGeography),
		);
	}

	const assetClassSummaryQuery = useQueryNoRefetch({
		queryKey: ["marketViewPositioning", "all"],
		queryFn: async () => {
			const assetClassSummaryByMarketCombo = new Map<string, AssetClassSummary>();
			if (marketScenario.positioningIndicators?.positioningIndicators?.length) {
				await parallelize(
					marketScenario.positioningIndicators.positioningIndicators
						.filter(tooltipAvailable)
						.map((positioning) => async () => {
							const summary = await axiosExtract(
								outlookApi.retrieveAcMetricsStandard(
									positioning.marketAssetClass as any, // FIXME: proper type missing from the API
									// TODO: refactor with BE once the API is normalized
									(positioning.marketAssetClass === "CO" ? "GLOBAL" : positioning.marketGeography) as any, // FIXME: proper type missing from the API
									positioning.marketMicroAssetClass as any, // FIXME: proper type missing from the API
								),
							);
							assetClassSummaryByMarketCombo.set(marketComboKey(positioning), summary);
						}),
					{ concurrency: 8 },
				);
			}
			return assetClassSummaryByMarketCombo;
		},
	});

	const [defaultExpandGroupByKey] = useState(() => Set(assetClassGroups.map((x) => x.id)));

	return (
		<AutoSortTableWithGroups
			data-qualifier="marketViewPositioning/assetClassGroups"
			palette="uniform"
			groupedRows={assetClassGroups}
			groupRowKey={groupKeyExtractor}
			expandGroupByKey={defaultExpandGroupByKey}
			{...groupedColorProps("name", "groupId")}
			columns={useCallback<TableWithGroupsProps<AssetClassGroup>["columns"]>(
				({ bandColumn, expandColumn }) => [
					bandColumn,
					expandColumn,
					{
						headerCellClassList: "!h-[30px]",
						sortFn: builtInSortFnFor("name"),
						name: "name",
						groupCellClassList: "h-[54px]",
						cellClassList: "min-h-[54px]",
						groupContentTextType: "Body/M/Bold",
						groupContent: ({ name }) => t(`MACRO_ASSET_CLASSES.${name}`),
						content: (row, cellProps) => {
							const {
								groupId,
								id,
								microAssetClass,
								mdotmCommentary,
								commentary,
								mdotmPositioning,
								marketAssetClass,
								marketGeography,
								marketMicroAssetClass,
							} = row;
							const label = mappedAlias?.[microAssetClass]
								? mappedAlias[microAssetClass]
								: t(`MARKET_STUDIO_SETTINGS.${microAssetClass!}`);
							const isTooltipAvailable = tooltipAvailable(row);
							return (
								<Row {...cellProps} alignItems="center">
									<AutoTooltip
										disabled={!isTooltipAvailable || hideOutlookToopltip}
										trigger={({ innerRef }) => (
											<Text
												innerRef={innerRef}
												classList={{
													"min-w-0 truncate grow": true,
													"underline underline-offset-[3px]": Boolean(isTooltipAvailable) && !hideOutlookToopltip,
												}}
												as="div"
												type="Body/M/Link"
											>
												{label}
											</Text>
										)}
									>
										<TooltipContent>
											<ReactQueryWrapperBase query={assetClassSummaryQuery}>
												{(data) => {
													const assetClassSummary = data.get(
														marketComboKey({
															marketAssetClass,
															marketGeography,
															marketMicroAssetClass,
														}),
													);
													return (
														isTooltipAvailable &&
														assetClassSummary && (
															<AssetClassTooltipContent
																assetClassSummary={assetClassSummary}
																marketAssetClass={marketAssetClass || ""}
																marketGeography={marketGeography || ""}
																marketMicroAssetClass={marketMicroAssetClass || ""}
															/>
														)
													);
												}}
											</ReactQueryWrapperBase>
										</TooltipContent>
									</AutoTooltip>
									<div className="shrink">
										{mdotmCommentary && mdotmCommentary !== commentary && mdotmPositioning && (
											<AutoTooltip
												openDelay={1000}
												trigger={({ innerRef }) => (
													<button
														ref={innerRef}
														type="button"
														className="p-2"
														style={{ color: themeCSSVars.palette_P500 }}
														onClick={() =>
															onMarketScenarioChange(
																updateCommentary(
																	updatePreference(marketScenario, groupId, id, mdotmPositioning),
																	groupId,
																	id,
																	mdotmCommentary,
																),
															)
														}
													>
														<svg
															width="16"
															height="16"
															viewBox="0 0 16 16"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M0.666626 2.87197C0.666626 2.02841 1.36179 1.33325 2.20535 1.33325H12.1204C12.964 1.33325 13.6591 2.02841 13.6591 2.87197V4.82042C13.6591 5.10365 13.4295 5.33325 13.1463 5.33325C12.8631 5.33325 12.6335 5.10365 12.6335 4.82042V2.87197C12.6335 2.57902 12.4136 2.35914 12.1206 2.35914H2.20535C1.9124 2.35914 1.69229 2.57902 1.69229 2.87197V9.71026C1.69229 10.0032 1.91217 10.2231 2.20512 10.2231H3.74362C3.88749 10.224 4.02435 10.2853 4.12095 10.3919C4.21754 10.4986 4.26482 10.6409 4.25125 10.7841L4.04833 12.7714L6.45776 10.3726C6.55413 10.2765 6.68488 10.2226 6.82106 10.2231H8.15368C8.43697 10.2231 8.66663 10.4527 8.66663 10.736C8.66663 11.0193 8.43697 11.249 8.15368 11.249H7.03461L3.75448 14.5185C3.65381 14.6183 3.51627 14.6716 3.37466 14.6662C3.23327 14.6608 3.10003 14.5972 3.00706 14.4902C2.91408 14.3834 2.86952 14.2427 2.88377 14.1018L3.17763 11.249H2.20535C1.36179 11.249 0.666626 10.5538 0.666626 9.71026V2.87197Z"
																fill="currentColor"
															/>
															<path
																d="M12.5502 8.79164H10.8173L11.1507 8.43382C11.1972 8.38476 11.2245 8.3155 11.2218 8.24336C11.2218 8.17411 11.189 8.10774 11.1371 8.05868C11.0851 8.01539 11.0195 7.99519 10.9512 8.00097C10.8938 8.00962 10.8473 8.03559 10.8118 8.07599L10.0683 8.86378C10.0246 8.90995 10 8.97344 10 9.03981C10 9.10329 10.0246 9.16678 10.0683 9.21584L10.8063 9.99497C10.8501 10.0469 10.9129 10.0787 10.9785 10.0787C11.0469 10.0787 11.1097 10.0527 11.1535 10.0065C11.2027 9.95457 11.2273 9.8882 11.2245 9.82183C11.2245 9.75546 11.1945 9.6862 11.148 9.64292L10.8145 9.29086H12.5174C13.3292 9.29086 13.9634 9.96034 13.9634 10.8145V10.8664C13.9634 11.6975 13.3156 12.3814 12.5256 12.393C12.46 12.3843 12.3917 12.4074 12.3398 12.4593C12.2906 12.5084 12.2605 12.5776 12.2632 12.6498C12.2632 12.7219 12.2933 12.7883 12.3452 12.8345C12.389 12.8749 12.4436 12.898 12.5038 12.898H12.5229C13.5834 12.8835 14.4444 11.9745 14.4444 10.8693V10.8174C14.4444 9.70929 13.5998 8.80607 12.553 8.78876L12.5502 8.79164Z"
																fill="currentColor"
																stroke="currentColor"
																strokeWidth="0.5"
															/>
															<path
																d="M9.81648 4.03275C9.70738 3.9866 9.58093 4.01089 9.49415 4.0959L7.33949 6.20901L5.16996 4.08618C5.08566 4.00117 4.9592 3.97688 4.84763 4.02303C4.73605 4.06675 4.66663 4.17362 4.66663 4.29021V8.94877C4.66663 9.06535 4.73853 9.1698 4.85011 9.21594C4.88482 9.23052 4.92201 9.23537 4.96168 9.23537C5.03855 9.23537 5.11541 9.20623 5.16996 9.15279L9.40985 4.99943V7.10283C9.40985 7.26314 9.54126 7.39187 9.7049 7.39187C9.86855 7.39187 9.99996 7.26314 9.99996 7.10283V4.30235C9.99996 4.18576 9.92805 4.08132 9.81896 4.03517L9.81648 4.03275ZM6.92294 6.61463L5.25674 8.24683V4.98243L6.92294 6.61463Z"
																fill="currentColor"
																stroke="currentColor"
																strokeWidth="0.5"
															/>
														</svg>
													</button>
												)}
											>
												Restore Sphere view
											</AutoTooltip>
										)}
									</div>
								</Row>
							);
						},
						header: "Asset class",
						minWidth: 264,
						maxWidth: 264,
					},
					{
						name: "preference",
						groupContent: () => null,
						content: ({ groupId, id, preference, mdotmCommentary, commentary, mdotmPositioning }, cellProps) => (
							<Row justifyContent="center" {...cellProps} alignItems="center" childrenGrow={1}>
								<PositioningSlider
									value={preference}
									mdotmPositioning={
										selectedScenarioId === MarketScenarioIds.SphereForecastPositioningOneMonth && mdotmPositioning
											? mdotmPositioning
											: undefined
									}
									onChange={(newPreference) =>
										onMarketScenarioChange(
											updateCommentary(
												updatePreference(
													marketScenario,
													groupId,
													id,
													newPreference as AssetClassPreferenceRow["preference"],
												),
												groupId,
												id,
												commentary === mdotmCommentary ? "" : commentary ? commentary : "",
											),
										)
									}
									disabled={mode === "view"}
								/>
							</Row>
						),
						header: (headerProps) => (
							<Row {...headerProps} justifyContent="space-between" alignItems="center">
								{["SU", "U", "N", "O", "SO"].map((x, i) => (
									<Text as="div" type="Body/S/BOLD-UPPERCASE" color={themeCSSVars.palette_N500} key={i}>
										{x}
									</Text>
								))}
							</Row>
						),
						minWidth: 208,
						maxWidth: 208,
					},
					{
						name: "commentary",
						header: "Commentary",
						groupContent: () => null,
						content: ({ groupId, id, commentary, mdotmCommentary }, cellProps) => (
							<Row justifyContent="center" {...cellProps} childrenGrow={1}>
								<InlineTextForm
									useMarkdown={commentary === mdotmCommentary}
									noDataText=""
									rows={3}
									classList="min-h-[54px] w-full"
									value={commentary ?? ""}
									canEdit={canEditCommentary}
									onEdit={(v) => onChangeCommentary(groupId, id, v)}
								/>
							</Row>
						),
						hidden: canEditMarketView === false && canReadCommentary === false,
					},
				],
				[
					canEditMarketView,
					canReadCommentary,
					t,
					mappedAlias,
					onMarketScenarioChange,
					updateCommentary,
					updatePreference,
					marketScenario,
					selectedScenarioId,
					mode,
					assetClassSummaryQuery,
					canEditCommentary,
					onChangeCommentary,
					hideOutlookToopltip,
				],
			)}
		/>
	);
}

type MapToAssetClassGroupsReturnType = { [key in PositioningIndicatorAssetClassEnum]?: AssetClassGroup };
function mapToAssetClassGroups(
	positioningIndicators?: Array<
		PositioningIndicator & { mdotmCommentary?: string | null; mdotmPositioning?: PositioningPreference }
	> | null,
) {
	if (!positioningIndicators) {
		return [];
	}
	const groups: MapToAssetClassGroupsReturnType = {};
	for (let i = 0; i < positioningIndicators.length; i++) {
		const groupId = positioningIndicators[i].assetClass!;
		groups[groupId] = groups[groupId] ?? {
			id: groupId,
			name: groupId,
			rows: [],
		};
		groups[groupId]!.rows.push({
			groupId,
			id: positioningIndicators[i].microAssetClass!,
			name: positioningIndicators[i].microAssetClass!,
			preference: (positioningIndicators[i].userPositioning ??
				positioningIndicators[i].defaultPositioning ??
				3) as PositioningPreference,
			microAssetClass: positioningIndicators[i].microAssetClass!,
			marketGeography: positioningIndicators[i].marketGeography!,
			marketAssetClass: positioningIndicators[i].marketAssetClass!,
			marketMicroAssetClass: positioningIndicators[i].marketMicroAssetClass!,
			commentary: positioningIndicators[i].commentary,
			mdotmCommentary: positioningIndicators[i].mdotmCommentary,
			mdotmPositioning: positioningIndicators[i].mdotmPositioning,
		});
	}
	return customObjectValuesFn(groups) as AssetClassGroup[];
}

const colorByValue: Record<PositioningPreference, string> = {
	1: themeCSSVars.palette_graph_R500,
	2: themeCSSVars.palette_graph_R300,
	3: themeCSSVars.palette_S300,
	4: themeCSSVars.palette_P300,
	5: themeCSSVars.palette_P400,
};

export function PositioningSlider(props: {
	value: PositioningPreference;
	onChange?(v: PositioningPreference): void;
	disabled?: boolean;
	mdotmPositioning?: PositioningPreference;
}): JSX.Element {
	return (
		<Controller value={props.value} onChange={props.onChange}>
			{({ value, onChange, onCommit }) => (
				<Slider
					data-qualifier="marketViewPositioning/slider"
					step={1}
					min={1}
					max={5}
					showSteps
					showStepLabels={false}
					minMaxValueMode="none"
					style={{ width: "100%" }}
					value={value}
					onChange={onChange as ((v: number) => void) | undefined}
					onCommit={onCommit as ((v: number) => void) | undefined}
					fillBar={false}
					handleColor={colorByValue[value]}
					handleBorderColor={colorByValue[value]}
					disabled={props.disabled}
					markers={
						props.mdotmPositioning
							? [
									{
										value: props.mdotmPositioning,
										textLocation: "top",
										type: "circle",
										fillColor: "transparent",
										hideText: true,
										borderColor: colorByValue[props.mdotmPositioning],
									},
							  ]
							: undefined
					}
				/>
			)}
		</Controller>
	);
}

export type AssetClassTooltipContentProps = {
	assetClassSummary: AssetClassSummary;
	marketGeography: string;
	marketAssetClass: string;
	marketMicroAssetClass: string;
} & StylableProps;

function AssetClassTooltipContent({
	classList,
	style,
	assetClassSummary,
	marketGeography,
	marketAssetClass,
	marketMicroAssetClass,
}: AssetClassTooltipContentProps) {
	const { formatNumber } = useLocaleFormatters();

	const exposureCollection = (assetClassSummary.exposureLabel ?? []).flatMap((x) => {
		const feel = feelsConverter({ ...x, type: "POSITIONING" });
		return feel ? [feel] : [];
	});
	const regimeCollection = (assetClassSummary.actualRegime ?? []).flatMap((x) => {
		const feel = feelsConverter({ ...x, type: "RISK" });
		return feel ? [feel] : [];
	});
	const driverCollection = (assetClassSummary.driverLabel ?? []).flatMap((x) => {
		const feel = feelsConverter(x);
		return feel ? [feel] : [];
	});

	return (
		<div className={toClassName(classList)} style={style}>
			<div className="flex items-center justify-between mb-4">
				<Text as="div" type="Body/L/Bold">
					Outlook focus preview
				</Text>
				<ActionText
					// href={typedUrlForRoute("AssetClass", {
					// 	filter: marketAssetClass,
					// 	// TODO: refactor with BE once the API is normalized
					// 	area: marketAssetClass === "CO" ? marketMicroAssetClass : marketGeography,
					// 	sector: marketAssetClass === "CO" ? undefined : marketMicroAssetClass,
					// })}
					classList="inline-flex items-center gap-1"
					color={themeCSSVars.palette_P500}
					onClick={() =>
						window.open(
							typedUrlForRoute("AssetClass", {
								filter: marketAssetClass,
								// TODO: refactor with BE once the API is normalized
								area: marketAssetClass === "CO" ? marketMicroAssetClass : marketGeography,
								sector: marketAssetClass === "CO" ? undefined : marketMicroAssetClass,
							}),
							"_blank",
						)
					}
				>
					{" "}
					View more{" "}
					<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M8 1.5H10.5V4"
							stroke="currentColor"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M7 5L10.5 1.5"
							stroke="currentColor"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M8.5 7.4375V9.625C8.5 10.1084 8.10844 10.5 7.625 10.5H2.375C1.89156 10.5 1.5 10.1084 1.5 9.625V4.375C1.5 3.89156 1.89156 3.5 2.375 3.5H4.5625"
							stroke="currentColor"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</ActionText>
			</div>
			<div>
				<div className="flex justify-between">
					<div className="flex items-center">
						<Text type="Body/S/Book">Exp. Volatility</Text>
						<Text type="Body/S/Bold" classList="relative ml-1">
							{formatNumber(assetClassSummary.expectedVolatility)}%
						</Text>
					</div>
					<div className="flex items-center">
						<Text type="Body/S/Book">Ann. Exp. Ret.</Text> {/* TODO: "Returns" instead of "turn"? */}
						<Text type="Body/S/Bold" classList="relative ml-1">
							{formatNumber(assetClassSummary.expectedReturn)}%
						</Text>
					</div>
				</div>
				<div className="border-b my-2" style={{ borderColor: themeCSSVars.palette_N100 }} />
				<div className="flex gap-2">
					{(exposureCollection.length > 0 || regimeCollection.length > 0) && (
						<div className="w-[144px] flex flex-col gap-2">
							{exposureCollection.length > 0 && (
								<div>
									<Text as="div" type="Body/S/Bold" classList="mb-1">
										Positioning
									</Text>
									<div className="space-y-2">
										<ForEach collection={exposureCollection}>
											{({ item }) => (
												<SentimentBadge indicator={item.indicator} sentiment={item.sentiment} size="x-small">
													{item.label}
												</SentimentBadge>
											)}
										</ForEach>
									</div>
								</div>
							)}
							{regimeCollection.length > 0 && (
								<div>
									<Text as="div" type="Body/S/Bold" classList="mb-1">
										Regime
									</Text>
									<div className="space-y-2">
										<ForEach collection={regimeCollection}>
											{({ item }) => (
												<SentimentBadge indicator={item.indicator} sentiment={item.sentiment} size="x-small">
													{item.label}
												</SentimentBadge>
											)}
										</ForEach>
									</div>
								</div>
							)}
						</div>
					)}
					{driverCollection.length > 0 && (
						<div className="w-[144px]">
							<Text as="div" type="Body/S/Bold" classList="mb-1">
								Drivers
							</Text>
							<div className="space-y-2">
								<ForEach collection={driverCollection}>
									{({ item }) => (
										<SentimentBadge indicator={item.indicator} sentiment={item.sentiment} size="x-small">
											{item.label}
										</SentimentBadge>
									)}
								</ForEach>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
