import {
	BenchmarksControllerApiFactory, CommentaryTemplateControllerApiFactory, HmmRegion, InvestmentControllerV4ApiFactory,
	MarketViewControllerApiFactory,
	ReferenceUniversesControllerApiFactory,
	type MarketViewType
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { reportRepository } from "$root/functional-areas/reports/repository";
import { useUserValue } from "$root/functional-areas/user";
import { PortfolioStudioTab } from "$root/pages/PortfoliosStudio/portfolio-studio-tabs";
import { axiosExtract } from "$root/third-party-integrations/axios";
import type { MaybeAsync } from "$root/utils";
import { ellipsis, typedObjectValues } from "$root/utils";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { hasAccess } from "../AuthorizationGuard";
import type { Crumb, NestedCrumbs } from "../Breadcrumbs";
import { iconAndColorByInvestmentStatus } from "../LabelRounded/Index";
import { typedUrlForRoute } from "../PlatformRouter/RoutesDef";
import { useSidebarEntries } from "../PlatformRouter/sidebar";
import ColoredRectangle from "../icons/ColoredRectangle";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCrumbs = () => {
	// TODO: cache responses

	const { t } = useTranslation();
	const sidebarEntries = useSidebarEntries();
	const user = useUserValue();

	const investmentsApi = useApiGen(InvestmentControllerV4ApiFactory);
	const referenceUniverseApi = useApiGen(ReferenceUniversesControllerApiFactory);
	const marketViewApi = useApiGen(MarketViewControllerApiFactory);
	const commentaryTemplateApi = useApiGen(CommentaryTemplateControllerApiFactory);
	const benchmarkV4Api = useApiGen(BenchmarksControllerApiFactory);

	return useMemo(
		() =>
			({
				//#region Portfolio
				portfolioStudio: fromSidebar(sidebarEntries, "PortfolioStudio", {
					MarketViews: () =>
						axiosExtract(marketViewApi.getMarketViewList()).then((list) =>
							list.sort(builtInSortFnFor("creationDate", "desc")).map((marketView) => ({
								id: marketView.uuid,
								label: marketView.name ?? "",
								href: typedUrlForRoute("MarketViewWorkSpace", {
									action: "view",
									uuid: marketView.uuid!,
									type: marketView.type!,
								}),
							})),
						),
					Portfolios: () =>
						axiosExtract(investmentsApi.getProjectedInvestmentList()).then((list) =>
							list.sort(builtInSortFnFor("creationTime", "desc")).map((ptf) => ({
								id: ptf.uuid,
								label: `${ptf.name}${ptf.status === "DRAFT" ? "(draft)" : ""}`,
								href:
									ptf.status === "DRAFT"
										? typedUrlForRoute("Portfolios/Draft", { portfolioUid: ptf.uuid ?? "" })
										: typedUrlForRoute("PortfolioDetails", { portfolioUid: ptf.uuid ?? "" }),
								icon: (
									<ColoredRectangle
										variant="vertical"
										color={ptf.status ? iconAndColorByInvestmentStatus[ptf.status].color : "transparent"}
									/>
								),
							})),
						),
					References: () =>
						Promise.all([
							axiosExtract(benchmarkV4Api.getBenchmarkList()),
							axiosExtract(investmentsApi.getInvestmentReferenceList()),
						]).then(([benchmarkList, referenceList]) =>
							referenceList
								.concat(benchmarkList)
								.sort(builtInSortFnFor("creationTime", "desc"))
								.map((ptf) => ({
									id: ptf.uuid,
									label: ptf.name ?? "",
									href: ptf.benchmark
										? typedUrlForRoute("CustomBenchmark", { benchmarkId: ptf.uuid ?? "" })
										: typedUrlForRoute("PortfolioReferenceDetails", { portfolioUid: ptf.uuid ?? "" }),
									icon: (
										<ColoredRectangle
											variant="vertical"
											color={ptf.status ? iconAndColorByInvestmentStatus[ptf.status].color : "transparent"}
										/>
									),
								})),
						),
					Universes: () =>
						axiosExtract(referenceUniverseApi.getUserUniverses()).then((list) =>
							list.sort(builtInSortFnFor("creationTime", "desc")).map((universe) => ({
								id: universe.uuid,
								label: universe.name ?? "",
								href: typedUrlForRoute("UniverseDetails", { universeUuid: universe.uuid ?? "" }),
								icon: (
									<ColoredRectangle
										variant="vertical"
										color={universe.status ? iconAndColorByInvestmentStatus[universe.status].color : "transparent"}
									/>
								),
							})),
						),
				}),
				portfolioName: (params?: { name?: string; uuid?: string }) => [
					{
						label: params?.name ?? "...",
						href: params?.uuid
							? typedUrlForRoute("PortfolioDetails", { portfolioUid: params.uuid })
							: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.Portfolios }),
					},
				],
				newPortfolio: [
					{
						label: "New portfolio",
					},
				],
				editPortfolio: [
					{
						label: "Edit portfolio composition",
					},
				],
				reviewInstrument: [
					{
						label: "Review instruments",
					},
				],
				editPortfolioSettings: [
					{
						label: "Portfolio settings",
					},
				],
				enhancePortfolio: (portfolio?: { uuid?: string; name?: string }) => [
					{
						label: portfolio?.name ?? "...",
						href: typedUrlForRoute("PortfolioDetails", { portfolioUid: portfolio?.uuid ?? "" }),
					},
					{
						label: "Proposal",
					},
				],
				createPortfolio: [
					{
						label: "New Portfolio",
					},
				],
				currentPortfolioSettings: (portfolio: { uuid?: string; name?: string }) => [
					{
						label: portfolio.name ?? "...",
						href: typedUrlForRoute("PortfolioDetails", { portfolioUid: portfolio.uuid! }),
					},
					{
						label: "Portfolio settings",
					},
				],
				enhancedPortfolioSettings: (portfolio: { uuid?: string; name?: string }) => [
					{
						label: portfolio.name ?? "...",
						href: typedUrlForRoute("PortfolioDetails", { portfolioUid: portfolio.uuid!, proposal: "true" }),
					},
					{
						label: "Portfolio settings",
					},
				],
				draftPortfolioSettings: (name?: string) => [
					{
						label: name ?? "",
					},
				],
				//#endregion Portfolio

				//#region Reference
				benchmarkName: (params: { name?: string; uuid?: string }) => [
					{
						label: params?.name ?? "...",
						href: params?.uuid
							? typedUrlForRoute("CustomBenchmark", { benchmarkId: params.uuid })
							: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.References }),
					},
				],
				newBenchmark: [
					{
						label: "New benchmark",
					},
				],
				editBenchmark: [
					{
						label: "Edit benchmark composition",
					},
				],
				targetPortfolioName: (params?: { name?: string; uuid?: string }) => [
					{
						label: params?.name ?? "...",
						href: params?.uuid
							? typedUrlForRoute("PortfolioReferenceDetails", { portfolioUid: params.uuid })
							: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.References }),
					},
				],
				newTargetPortfolio: [
					{
						label: "New target portfolio",
					},
				],
				editTargetPortfolio: [
					{
						label: "Edit target portfolio composition",
					},
				],
				//#endregion Reference

				//#region Universe
				universeName: (params: { name?: string; uuid?: string }) => [
					{
						label: params?.name ?? "...",
						href: params?.uuid
							? typedUrlForRoute("UniverseDetails", { universeUuid: params?.uuid })
							: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.Universes }),
					},
				],
				newUniverse: [
					{
						label: "New universe",
					},
				],
				editUniverse: [
					{
						label: "Edit universe",
					},
				],
				//#endregion Universe

				//#region Market view
				marketViewType(params: { marketViewType?: MarketViewType }) {
					return [
						{
							label:
								params.marketViewType === "EXPECTED_RETURNS_VOLATILITY"
									? t("EXPECTED_RETURNS_VOLATILITY")
									: t("POSITIONING_INDICATORS"),
						},
					];
				},
				marketViewName(params: { name?: string; uuid?: string; marketViewType?: MarketViewType }) {
					return [
						{
							label: ellipsis(params?.name ?? "...", 20),
							href:
								params?.uuid && params?.marketViewType
									? typedUrlForRoute("MarketViewWorkSpace", {
											action: "view",
											uuid: params.uuid,
											type: params.marketViewType,
									  })
									: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.MarketViews }),
						},
					];
				},
				duplicateMarketView: (originalMarketView: { uuid?: string; name?: string; type?: MarketViewType }) => [
					{
						label: ellipsis(originalMarketView?.name ?? "Untitled", 20),
						href: typedUrlForRoute("MarketViewWorkSpace", {
							action: "view",
							uuid: originalMarketView?.uuid ?? "",
							type: originalMarketView?.type ?? "",
						}),
					},
					{
						label: "Duplicate",
					},
				],
				//#endregion Market view

				//#region Market insights
				marketInsights: (() => {
					const regions = typedObjectValues(HmmRegion)
						.filter((region) => region !== "GLOBAL" || hasAccess(user, { requiredService: "GLOBAL_RISK_MAP" }))
						.map((region) => ({
							id: region,
							label: region,
							href: typedUrlForRoute("RegimeAnalysisTool", { region }),
						}));
					const base = fromSidebar(sidebarEntries, "MarketInsight", {
						"Regime analysis tool": regions,
					});

					return (...[area, thirdLevelSelectedNestedId]: Parameters<typeof base>) => [
						...base(area, thirdLevelSelectedNestedId),
						...(area === "Regime analysis tool"
							? regions.filter((r) => r.id === thirdLevelSelectedNestedId) ?? []
							: []),
					];
				})(),
				//#endregion Market insights

				//#region Storyfolio
				storyfolio: fromSidebar(sidebarEntries, "Storyfolio", {
					"Commentary builder": () =>
						axiosExtract(commentaryTemplateApi.getTemplateList()).then((list) =>
							list /* .sort(builtInSortFnFor("creationDate", "desc")) TODO: missing from API */
								.map((commentary) => ({
									id: commentary.uuid!,
									label: commentary.name ?? "",
									href: typedUrlForRoute("Storyfolio/Details", { uuid: commentary.uuid! }),
								})),
						),
					"Report builder": () =>
						reportRepository.fetchAll().then((list) =>
							list /* .sort(builtInSortFnFor("creationDate", "desc")) TODO: missing from API */
								.map((report) => ({
									id: report.uuid!,
									label: report.templateName ?? "",
									href: typedUrlForRoute("PortfolioStudioSettings/ReportEditor", { reportUid: report.uuid! }),
								})),
						),
				}),
				reportName: (template?: { uuid?: string; name?: string }) => [
					{
						label: template?.name,
						href: typedUrlForRoute("PortfolioStudioSettings/ReportEditor", { reportUid: template?.uuid ?? "" }),
					},
				],
				commentaryName: (commentary?: { uuid?: string; name?: string }) => [
					{
						label: commentary?.name,
						href: typedUrlForRoute("Storyfolio/Details", { uuid: commentary?.uuid ?? "" }),
					},
				],
				//#endregion Storyfolio

				//#region My settings
				mySettings: fromSidebar(sidebarEntries, "My Settings"),
				//#endregion My settings

				//#region Admin panel
				adminPanel: fromSidebar(sidebarEntries, "Admin panel"),
				//#endregion Admin panel
			}) satisfies Record<string, Array<Crumb> | ((...args: any[]) => Array<Crumb>)>,
		[
			benchmarkV4Api,
			commentaryTemplateApi,
			investmentsApi,
			marketViewApi,
			referenceUniverseApi,
			sidebarEntries,
			t,
			user,
		],
	);
};

type SidebarSubmenuEntryName<SidebarEntryName extends ReturnType<typeof useSidebarEntries>[number]["name"]> =
	NonNullable<
		(ReturnType<typeof useSidebarEntries>[number] & { name: SidebarEntryName })["submenuEntries"]
	>[number]["name"];

function fromSidebar<SidebarEntryName extends ReturnType<typeof useSidebarEntries>[number]["name"]>(
	sidebarEntries: ReturnType<typeof useSidebarEntries>,
	name: SidebarEntryName,
	thirdLevel?: Partial<
		Record<
			SidebarSubmenuEntryName<SidebarEntryName>,
			MaybeAsync<Array<NestedCrumbs<string>>> | Array<NestedCrumbs<string>>
		>
	>,
): (area?: SidebarSubmenuEntryName<SidebarEntryName>, thirdLevelSelectedNestedId?: string) => Array<Crumb> {
	const sidebarEntry = sidebarEntries.find((x) => x.name === name);

	return (area, thirdLevelSelectedNestedId) => {
		const sidebarSubEntry = sidebarEntry?.submenuEntries?.find((x) => x.name === area);

		const mainEntry = {
			label: sidebarEntry?.title ?? "-",
			href: sidebarEntry?.url,
			selectedNestedId: area,
			nested:
				sidebarEntry?.submenuEntries
					?.filter((x) => x.url)
					.map(({ name: subName, label, url }) => ({ id: subName, label, href: url! })) ?? [],
		};

		if (!sidebarSubEntry) {
			return [mainEntry];
		}

		return [
			mainEntry,
			{
				label: sidebarSubEntry.label,
				href: sidebarSubEntry.url,
				nested: thirdLevel?.[area],
				selectedNestedId: thirdLevelSelectedNestedId,
			},
		];
	};
}
