import env from "$root/env";
import type { UseStateResult } from "$root/utils";
import type { Updater } from "@mdotm/mdotui/utils";
import { identity, instanceEqualsOrFastDeepEqual } from "@mdotm/mdotui/utils";
import { useMemo, useState, type ReactNode } from "react";
import { type StoreApi } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import type { UseBoundStoreWithEqualityFn } from "zustand/traditional";
import { createWithEqualityFn, useStoreWithEqualityFn } from "zustand/traditional";

export type AtomContent<T> = { value: T; set: (newValue: T | Updater<T>) => void };

export function createAtom<T extends object | string | number | boolean | null>(
	defaultValue: T,
	opts?: {
		onSet?(newValue: T): void;
	},
): UseBoundStoreWithEqualityFn<StoreApi<AtomContent<T>>> {
	return createWithEqualityFn(
		devtools<AtomContent<T>>(
			(set, get) => ({
				value: defaultValue,
				set: (newValueOrUpdater) => {
					const newValue = typeof newValueOrUpdater === "function" ? newValueOrUpdater(get().value) : newValueOrUpdater;
					set({ value: newValue });
					opts?.onSet?.(newValue);
				},
			}),
			{ enabled: env.appEnv !== "production" },
		),
		instanceEqualsOrFastDeepEqual,
	);
}

export function createPersistentAtom<T extends object | string | number | boolean | null>(
	defaultValue: T,
	localStorageKey: string,
	opts?: {
		onSet?(newValue: T): void;
		migrate?(persistedState: unknown, version: number): AtomContent<T>;
		version?: number;
	},
): UseBoundStoreWithEqualityFn<StoreApi<AtomContent<T>>> {
	return createWithEqualityFn(
		devtools(
			persist<AtomContent<T>>(
				(set, get) => ({
					value: defaultValue,
					set: (newValueOrUpdater) => {
						const newValue =
							typeof newValueOrUpdater === "function" ? newValueOrUpdater(get().value) : newValueOrUpdater;
						set({ value: newValue });
						opts?.onSet?.(newValue);
					},
				}),
				{
					name: localStorageKey,
					storage: createJSONStorage(() => localStorage),
					migrate: opts?.migrate,
					version: opts?.version,
				},
			),
			{ enabled: env.appEnv !== "production" },
		),
		instanceEqualsOrFastDeepEqual,
	);
}

export function WithZustandStore<T, U = T>(props: {
	store: StoreApi<T>;
	selector?: (v: T) => U;
	equalityComparator?: (a: U, b: U) => boolean;
	children: (value: U) => ReactNode;
}): JSX.Element {
	const value = useStoreWithEqualityFn<StoreApi<T>, U>(
		props.store,
		(props.selector ?? identity) as unknown as (v: T) => U,
		props.equalityComparator ?? instanceEqualsOrFastDeepEqual,
	);

	return <>{props.children(value)}</>;
}

export function WithRwZustandStore<T>(props: {
	store: StoreApi<T>;
	/** @default instanceEqualsOrFastDeepEqual */
	equalityComparator?: (a: T, b: T) => boolean;
	children: (pair: UseStateResult<T>) => ReactNode;
}): JSX.Element {
	const value = useStoreWithEqualityFn<StoreApi<T>, T>(
		props.store,
		identity,
		props.equalityComparator ?? instanceEqualsOrFastDeepEqual,
	);

	return <>{props.children([value, (setter) => props.store.setState(setter, true /* act like a React state */)])}</>;
}

export function useStoreState<T>(
	initialValue: T,
	equalityComparator?: (a: T, b: T) => boolean,
): [StoreApi<T>, (v: T | ((prev: T) => T)) => void] {
	const state = useState(() =>
		createWithEqualityFn<T>(
			() => initialValue,
			(equalityComparator as (
				a: any,
				b: any,
			) => boolean) /* zustand type definition seems wrong, we don't have a selector at this point */ ??
				instanceEqualsOrFastDeepEqual,
		),
	)[0];
	return useMemo(() => [state, (v) => state.setState(v, true)], [state]);
}
