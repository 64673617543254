import type { IconName, StylableProps } from "@mdotm/mdotui/components";
import {
	Transition,
	NestedTransition,
	TinyIconButton,
	ScrollWrapper,
	Text,
	Icon,
	Button,
} from "@mdotm/mdotui/components";
import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import {
	overrideClassName,
	overrideClassList,
	WrapIfStrOrNumOrEmpty,
	renderNodeOrFn,
	toClassName,
} from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import Separator from "./Separator";
import { useMemo } from "react";

export type SideDrawerProps = {
	expand: boolean;
	onExpandChange(expand: boolean): void;
	disableTransition?: boolean;
	widths?: [number, number];
	title?: NodeOrFn<{ expand: boolean; onExpandChange(expand: boolean): void }>;
	actionHeader?: NodeOrFn<{ expand: boolean; onExpandChange(expand: boolean): void }>;
	children?: NodeOrFn<{ expand: boolean; onExpandChange(expand: boolean): void }>;
	footer?: NodeOrFn<{ expand: boolean; onExpandChange(expand: boolean): void }>;
	/** @default 'Left' */
	toggleIcon?: IconName;
	toggleLabel?: string;
	/** @default 28 */
	toggleOffset?: number;
	/** @default right */
	side?: "left" | "right";
	disabled?: boolean;
} & StylableProps;

export function SideDrawer(props: SideDrawerProps): JSX.Element {
	const expandedWidth = props.widths?.[1] ?? 464;
	const { side = "left" } = props;
	const childrenProps = useMemo(
		() => ({ expand: props.expand, onExpandChange: props.onExpandChange }),
		[props.expand, props.onExpandChange],
	);

	const renderedFooter = renderNodeOrFn(props.footer, childrenProps);

	const transitionsEnabled = !(props.disableTransition ?? false);

	return (
		<Transition
			in={props.expand}
			classList={{ "shrink-0 relative z-10": true, "transition-[width]": transitionsEnabled }}
			enterFromStyle={{ width: props.widths?.[0] ?? 24 }}
			enterToStyle={{ width: expandedWidth }}
		>
			{({ style, classList }) => (
				<div
					className={overrideClassName("relative z-0", overrideClassList(classList, props.classList))}
					style={{ ...style, ...props.style }}
				>
					{props.toggleLabel ? (
						<>
							<div
								className={toClassName({
									"absolute rounded-r-[4px] z-10": true,
									"right-[-20px] translate-x-full": side === "left",
									"left-[-40px] translate-x-full": side === "right",
								})}
								style={{
									top: props.toggleOffset ?? 28,
									backgroundColor: themeCSSVars.palette_N20,
									...(side === "left"
										? { boxShadow: "10px 0px 16px -1px rgb(0 0 0 / 0.1)" }
										: side === "right"
										  ? { boxShadow: "-10px 0px 10px -5px rgb(0 0 0 / 0.1)" }
										  : undefined),
								}}
								data-qualifier={`SideDrawer/Status(${props.expand ? "expanded" : "closed"})`}
							>
								<div
									className={toClassName({
										"absolute pointer-events-none inset-y-0 overflow-hidden": true,
										"right-0 left-[14px] rounded-r-[4px]": side === "left",
										"right-[8px] left-0 rounded-l-[4px]": side === "right",
									})}
									style={{
										border: `2px solid ${themeCSSVars.palette_N100}`,
										marginTop: "-2px",
										marginBottom: "-2px",
										...(side === "left"
											? { borderLeft: "none" }
											: side === "right"
											  ? { borderRight: "none" }
											  : undefined),
									}}
								/>
								<Button
									unstyled
									onClick={() => props.onExpandChange(!props.expand)}
									style={{
										position: "relative",
										left: 4,
										// width: 36,
									}}
									disabled={props.disabled}
									classList={{
										[`flex flex-col justify-center items-center space-y-2 py-8 hover:bg-[color:${themeCSSVars.palette_N50}] rounded my-px`]:
											true,
										"opacity-50": props.disabled,
									}}
									data-qualifier="SideDrawer/Toggle"
								>
									<NestedTransition
										classList={{ "transition-transform": transitionsEnabled }}
										enterToClassList="scale-x-100"
										enterFromClassList="-scale-x-100"
									>
										{({ classList: buttonClassList }) => (
											<Icon
												size={24}
												icon={props.toggleIcon ?? "Left"}
												color={themeCSSVars.palette_N400}
												classList={buttonClassList}
											/>
										)}
									</NestedTransition>
									<Text
										type="Body/M/Bold"
										color={themeCSSVars.palette_N400}
										classList="rotate-180	[writing-mode:vertical-rl]"
									>
										{props.toggleLabel}
									</Text>
								</Button>
							</div>
						</>
					) : (
						<div
							className={toClassName({
								"absolute rounded-r-[4px] z-10 flex items-center justify-center": true,
								"right-[-20px] translate-x-full": side === "left",
								"left-[-56px] translate-x-full": side === "right",
							})}
							style={{
								top: props.toggleOffset ?? 28,
								backgroundColor: themeCSSVars.palette_N20,
								width: 36,
								height: 36,
								...(side === "left"
									? { boxShadow: "10px 0px 16px -1px rgb(0 0 0 / 0.1)" }
									: side === "right"
									  ? { boxShadow: "-10px 0px 16px -1px rgb(0 0 0 / 0.1)" }
									  : undefined),
							}}
							data-qualifier={`SideDrawer/Status(${props.expand ? "expanded" : "closed"})`}
						>
							<div
								className={toClassName({
									"absolute pointer-events-none inset-y-0": true,
									"right-0 left-[14px] rounded-r-[4px]": side === "left",
									"right-[14px] left-0 rounded-l-[4px]": side === "right",
								})}
								style={{
									border: `2px solid ${themeCSSVars.palette_N100}`,
									marginTop: "-2px",
									marginBottom: "-2px",
									...(side === "left"
										? { borderLeft: "none" }
										: side === "right"
										  ? { borderRight: "none" }
										  : undefined),
								}}
							/>
							<div>
								<NestedTransition
									classList={{ "transition-transform": transitionsEnabled }}
									enterToClassList="scale-x-100"
									enterFromClassList="-scale-x-100"
								>
									{({ classList: buttonClassList }) => (
										<TinyIconButton
											onClick={() => props.onExpandChange(!props.expand)}
											icon={props.toggleIcon ?? "Left"}
											size={24}
											disabled={props.disabled}
											applyNegativeMargins
											color={themeCSSVars.palette_N400}
											classList={buttonClassList}
											data-qualifier="SideDrawer/Toggle"
											style={{
												position: "relative",
											}}
										/>
									)}
								</NestedTransition>
							</div>
						</div>
					)}
					<div
						className="absolute inset-0 z-0 overflow-hidden"
						style={{
							boxShadow: "10px 0px 16px -1px rgb(0 0 0 / 0.1)",
							backgroundColor: themeCSSVars.palette_N20,
							border: `2px solid ${themeCSSVars.palette_N100}`,
							borderTopRightRadius: 0,
							borderBottomRightRadius: 0,
						}}
					>
						<NestedTransition
							classList="transition-opacity"
							enterFromClassList="opacity-0"
							enterToClassList="opacity-100"
							exitedClassList="hidden"
						>
							{({ classList: mainContentClassList }) => (
								<div className={overrideClassName(mainContentClassList, "absolute inset-y-0 right-0")}>
									<div className="flex flex-col h-full py-[10px]" style={{ width: expandedWidth }}>
										<div className="flex items-center justify-between px-4">
											<div>
												<WrapIfStrOrNumOrEmpty
													wrapper={(content) => (
														<Text type="Body/XL/Bold" title={content?.toString()} classList="truncate">
															{content}
														</Text>
													)}
												>
													{renderNodeOrFn(props.title, childrenProps)}
												</WrapIfStrOrNumOrEmpty>
											</div>
											<div>{renderNodeOrFn(props.actionHeader, childrenProps)}</div>
										</div>
										<ScrollWrapper
											outerContainerAppearance={{
												classList: "grow",
											}}
											innerContainerAppearance={{
												classList: "flex-1",
											}}
											classList="px-4 flex-1 flex flex-col"
										>
											{renderNodeOrFn(props.children, childrenProps)}
										</ScrollWrapper>
										{renderedFooter && (
											<div className="px-4">
												<Separator />
												<WrapIfStrOrNumOrEmpty
													wrapper={(content) => (
														<Text as="div" type="Body/S/Book">
															{content}
														</Text>
													)}
												>
													{renderNodeOrFn(props.footer, childrenProps)}
												</WrapIfStrOrNumOrEmpty>
											</div>
										)}
									</div>
								</div>
							)}
						</NestedTransition>
					</div>
				</div>
			)}
		</Transition>
	);
}
