import type {
	EditorCompositionResponse,
	InvestmentStatuses,
	ReferenceUniverseDetails,
	ReviewTicker,
	UserInstrumentDto,
} from "$root/api/api-gen";
import {
	EntityEditorControllerApiFactory,
	PortfolioStudioPreferencesApiFactory,
	ReferenceUniversesControllerApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { useCrumbs } from "$root/components/Crumbs/useCrumbs";
import { LabelRounded } from "$root/components/LabelRounded/Index";
import { PageDownloadAndMoreActionsMenu } from "$root/components/PageDownloadAndMoreActionsMenu";
import type { PageHeaderProps } from "$root/components/PageHeader";
import { PageHeader } from "$root/components/PageHeader";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import ReactQueryWrapper, { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { useEventBus } from "$root/event-bus";
import { spawnAccessDialog } from "$root/functional-areas/acl/AccessDialog";
import EntityStatus from "$root/functional-areas/acl/EntityStatus";
import { aclByArea } from "$root/functional-areas/acl/checkers/all";
import { retrieveUserInstrumentsClassifications } from "$root/functional-areas/instruments-editor/builder";
import type { PartialUserInstrument } from "$root/functional-areas/instruments-editor/const";
import { InstrumentEditorEntity } from "$root/functional-areas/instruments-editor/const";
import {
	hideNameColumnMetadata,
	useInstrumentColumnPreference,
	useInstrumentsColumn,
} from "$root/functional-areas/instruments/hooks";
import { ReviewEntity } from "$root/functional-areas/proxies/helpers";
import { SmallUniverseSummary } from "$root/functional-areas/universe/SmallUniverseSummary";
import { useUniverseEntityManagementActions } from "$root/functional-areas/universe/entity-management";
import { exportUniverse } from "$root/functional-areas/universe/export";
import { useUserValue } from "$root/functional-areas/user";
import { formatDate } from "$root/localization/formatters";
import { platformToast } from "$root/notification-system/toast";
import type { CustomAxiosError } from "$root/third-party-integrations/axios";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { objMatchFn, useQueryNoRefetch } from "$root/utils";
import { UniverseContext } from "$root/widgets-architecture/contexts/universe";
import { Card } from "$root/widgets-architecture/layout/Card";
import WidgetsMapper from "$root/widgets-architecture/layout/WidgetsMapper";
import {
	ActionText,
	AutoSortHScrollTable,
	Banner,
	Button,
	Icon,
	ProgressBar,
	TableDataCell,
} from "@mdotm/mdotui/components";
import { builtInSortFnFor, noop, nullary, unpromisify } from "@mdotm/mdotui/utils";
import type { AxiosError } from "axios";
import type { Map } from "immutable";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { PortfolioDetailsTabs } from "./PortfolioDetails/portfolio-details-tabs";
import "./Portfolios/Portfolios.scss";
import { PortfolioStudioTab } from "./PortfoliosStudio/portfolio-studio-tabs";

type PageSubtitleProps = {
	universe?: ReferenceUniverseDetails;
	onChangeAcl?: () => Promise<unknown>;
	onEdit?: () => void;
	entityManagementActions: ReturnType<typeof useUniverseEntityManagementActions>;
};

const PageSubtitle = (props: PageSubtitleProps) => {
	const { t } = useTranslation();
	const { universe } = props;
	const { richAcl, status } = universe ?? {};
	const user = useUserValue();
	const canEditComposition = aclByArea.portfolio.canEditComposition(user.id, richAcl?.acl ?? []);

	if (status === "REVIEW") {
		return <></>;
	}

	return (
		<div className="flex justify-between items-center py-2.5 text-[#585D68]">
			<div className="flex gap-4 items-center">
				<div className="flex items-center" data-qualifier="UniverseDetails/PageHeader/Status">
					<span className="mr-2 uppercase">{t("STATUS")}</span>
					<LabelRounded type="status" content={{ label: universe?.status ?? "-", component: "" }} />
				</div>
				<div>
					<span className="mr-2 uppercase">{t("INCEPTION_DATE")}:</span>
					{universe?.creationTime ? formatDate(universe.creationTime) : "..."}
				</div>
				<div>
					<span className="mr-2 uppercase">{t("PORTFOLIOS.PF_LAST_ACTION")}:</span>
					<span className="font-semibold text-[#616161]">{universe?.action}</span>
					&nbsp;
					{universe?.modificationTime ? <span>({formatDate(universe?.modificationTime)})</span> : "..."}
				</div>
				<EntityStatus
					accessControl={richAcl}
					entity="UNIVERSE"
					entityId={universe?.uuid}
					entityName={universe?.name}
					refetch={props.onChangeAcl}
				/>
			</div>
			<div className="flex gap-2 items-center">
				{universe?.status === "READY" && canEditComposition && (
					<Button palette="primary" size="small" onClick={props.onEdit}>
						<Icon icon="Edit" size={16} />
						&nbsp;
						{t("BUTTON.EDIT")}
					</Button>
				)}

				<PageDownloadAndMoreActionsMenu
					area="universe"
					downloadActions={
						universe?.status === "READY"
							? [
									{
										icon: "xls",
										disabled: !universe?.uuid,
										onClickAsync: async () => {
											if (!universe!.uuid) {
												throw new Error("unable to load a universe of undefined");
											}
											await exportUniverse.downloadUniverse(universe!.uuid);
										},
										label: "Universe template",
										"data-qualifier": "UniverseDetails/DropdownMenu/DropdownItem(UniverseTemplate)",
									},
									{
										icon: "xls",
										disabled: !universe?.uuid,
										onClickAsync: async () => {
											if (!universe!.uuid) {
												throw new Error("unable to load a universe of undefined");
											}
											await exportUniverse.downloadComposition(universe!.uuid);
										},
										label: "Instrument list",
										"data-qualifier": "UniverseDetails/DropdownMenu/DropdownItem(InstrumentList)",
									},
							  ]
							: undefined
					}
					moreActions={[
						aclByArea.universe.canDelete(user.id, universe?.richAcl?.acl ?? []) && {
							icon: "Delete",
							"data-qualifier": "UniverseDetails/DropdownMenu/DropdownItem(Delete)",
							disabled: !props.entityManagementActions.deleteAsync,
							onClick: unpromisify(props.entityManagementActions.deleteAsync ?? noop),
							label: "Delete",
						},
						{
							icon: "Content-Copy",
							"data-qualifier": "UniverseDetails/DropdownMenu/DropdownItem(Duplicate)",
							disabled: !props.entityManagementActions.duplicateAsync,
							onClick: unpromisify(props.entityManagementActions.duplicateAsync ?? noop),
							label: "Duplicate",
						},
						props.entityManagementActions.renameAsync && {
							icon: "Edit",
							"data-qualifier": "UniverseDetails/DropdownMenu/DropdownItem(Rename)",
							onClick: unpromisify(props.entityManagementActions.renameAsync ?? noop),
							label: "Rename",
						},
					]}
				/>
			</div>
		</div>
	);
};

interface IpageUrl {
	universeUuid: string;
}

const severityByStatus: Record<InvestmentStatuses, PageHeaderProps["severity"]> = {
	ACCEPTED: "success",
	READY: "success",
	PROPOSAL_READY: "info",
	ERROR: "error",
	CALCULATING: "calculating",
	REVIEW: undefined,
	RETRIEVING_DATA: "calculating",
	DRAFT: "info",
};

function UniverseDetails(): JSX.Element {
	const { universeUuid } = useParams<IpageUrl>();

	const history = useHistory();
	const { t } = useTranslation();
	const { push } = useTypedNavigation();

	const referenceUniverseV4Api = useApiGen(ReferenceUniversesControllerApiFactory);
	const editorApi = useApiGen(EntityEditorControllerApiFactory);

	useEffect(() => {
		if (!universeUuid) {
			push("PortfoliosStudio", { tab: PortfolioStudioTab.Universes, status: "notFound" });
		}
	}, [history, push, universeUuid]);

	const queryUniverseDetails = useQueryNoRefetch(["universeDetails", universeUuid], {
		enabled: Boolean(universeUuid),
		queryFn: () => axiosExtract(referenceUniverseV4Api.getUniverse(universeUuid)),
		onError: (error: AxiosError<CustomAxiosError>) => {
			if (error.response?.data.code === 404 || error.response?.data.message === "Accesso negato") {
				spawnAccessDialog({
					onClick: (onClose) => {
						push("PortfoliosStudio", { tab: PortfolioStudioTab.Universes });
						onClose();
					},
				});
				return;
			}

			platformToast({
				children: t("SOMETHING_WENT_WRONG"),
				severity: "error",
				icon: "Portfolio",
			});
			push("PortfoliosStudio", { status: "notFound", tab: PortfolioStudioTab.Universes });
		},
		onSuccess({ status }) {
			if (status === "REVIEW") {
				push("ReviewInstruments", { entity: ReviewEntity.UNIVERSE, uuid: universeUuid! });
			}
		},
	});

	const { data: universe, isLoading, refetch } = queryUniverseDetails;

	useEventBus("investment-update", {
		filter: objMatchFn({ uuid: universeUuid }),
		listener: () => {
			refetch().catch(noop);
		},
	});

	useEventBus("shared-entity", {
		filter: objMatchFn({ sharedEntityUuid: universeUuid }),
		listener: () => {
			refetch().catch(noop);
		},
	});

	const { status, uuid, name } = universe ?? {};

	const entityManagementActions = useUniverseEntityManagementActions(universe, {
		onRename: unpromisify(nullary(refetch)),
		onDuplicate: () => push("PortfoliosStudio", { tab: PortfolioStudioTab.Universes }),
		onDelete: () => push("PortfoliosStudio", { tab: PortfolioStudioTab.Universes }),
	});

	const crumbs = useCrumbs();
	return (
		<ReactQueryWrapperBase query={queryUniverseDetails}>
			{() => (
				<>
					<PageHeader
						name="UniverseDetails"
						severity={severityByStatus[status as InvestmentStatuses]} // TODO: is this the same enum from Portfolio?
						title={isLoading ? "..." : name ? name : "Untitled"}
						crumbsV2={[
							...crumbs.portfolioStudio("Universes", uuid),
							...crumbs.universeName({
								name,
								uuid,
							}),
						]}
						subTitle={
							<PageSubtitle
								entityManagementActions={entityManagementActions}
								universe={universe}
								onChangeAcl={refetch}
								onEdit={() =>
									push("Portfolios/ManualEdit", { entity: InstrumentEditorEntity.Universe, uuid: universe?.uuid ?? "" })
								}
							/>
						}
					/>
					<UniverseContext.Provider value={{ universe: universe ?? null }}>
						<div className="mb-5">
							<SmallUniverseSummary universe={universe} />
						</div>

						{status === "RETRIEVING_DATA" && (
							<ReactQueryWrapper
								queryFn={async () => {
									const editorResponse = await axiosExtract(
										editorApi.getEditorReviewComposition(universe?.uuid ?? "", "UNIVERSE"),
									);
									const nonNullableComposition = editorResponse.composition ?? [];
									const { userInstrumentsMap } = await retrieveUserInstrumentsClassifications(nonNullableComposition);
									return { userInstrumentsMap, editorResponse, universe: nonNullableComposition };
								}}
								queryKey={["retriveUniverseComposition", universe?.uuid, universe?.status]}
								enabled={Boolean(universe?.uuid)}
								loadingFallback={<ProgressBar value="indeterminate" />}
							>
								{(response) => <UniverseReviewBlock response={response} />}
							</ReactQueryWrapper>
						)}

						{status !== "RETRIEVING_DATA" && status !== "REVIEW" && (
							<WidgetsMapper widgetName="ReadonlyUniverseComposition" />
						)}
					</UniverseContext.Provider>
				</>
			)}
		</ReactQueryWrapperBase>
	);
}

function UniverseReviewBlock({
	response,
}: {
	response: {
		editorResponse: EditorCompositionResponse;
		userInstrumentsMap: Map<string, PartialUserInstrument>;
		universe: ReviewTicker[];
	};
}) {
	const { t } = useTranslation();

	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);
	const { changeTableLayout, columnsMetadata } = useInstrumentColumnPreference({
		async applyColumnPreferenceApi(userInstrumentsColumnPreferences) {
			await portfolioStudioPreferencesApi.setUserInstrumentsColumnPreferences({
				userInstrumentsColumnPreferences,
			});
		},
		mapColumnMetadataFn: hideNameColumnMetadata,
	});

	const {
		customizableColumns,
		tools,
		// name
	} = useInstrumentsColumn({
		allColumns: columnsMetadata,
		mode: "VIEW",
		changeTableLayout,
	});

	const rowsWithUserInstrumentMetadata = useMemo(
		() =>
			response.universe.map((instrument): UserInstrumentDto & Omit<ReviewTicker, "description"> => {
				const { description: _description, type: _type, ...instrumentParams } = instrument;
				const userInstrument = response.userInstrumentsMap.get(instrument.ticker!) ?? {};
				return {
					...instrumentParams,
					...userInstrument,
					name: userInstrument.name ?? instrument.instrument,
					alias: userInstrument.alias ?? instrument.identifier,
				};
			}),
		[response],
	);

	return (
		<Card>
			<div className="mb-4">
				<Banner severity="info">{t("PORTFOLIO_UPLOAD_ALERTS.CALCULATING_UPLOAD")}</Banner>
			</div>
			<AutoSortHScrollTable
				columns={[
					{
						name: "name",
						header: "name",
						content: (row, cellProps) => {
							if (row.proxyOverwriteType !== "PORTFOLIO_MIXED") {
								return row.instrument ?? "-";
							}

							return (
								<TableDataCell {...cellProps}>
									<ActionText
										classList="inline-flex items-center gap-1"
										onClick={() =>
											window.open(
												typedUrlForRoute("PortfolioDetails", {
													portfolioUid: row.ticker ?? "",
													tab: PortfolioDetailsTabs.COMPOSITION,
												}),
												"_blank",
											)
										}
									>
										<span className="font-[weight:500] truncate items-center">{row.instrument ?? "-"}</span>
										<svg
											width="12"
											height="12"
											viewBox="0 0 12 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
											className="shrink-0"
										>
											<path
												d="M8 1.5H10.5V4"
												stroke="currentColor"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M7 5L10.5 1.5"
												stroke="currentColor"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M8.5 7.4375V9.625C8.5 10.1084 8.10844 10.5 7.625 10.5H2.375C1.89156 10.5 1.5 10.1084 1.5 9.625V4.375C1.5 3.89156 1.89156 3.5 2.375 3.5H4.5625"
												stroke="currentColor"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</ActionText>
								</TableDataCell>
							);
						},
						sortFn: builtInSortFnFor("instrument"),
						minWidth: 460,
						maxWidth: 560,
					},
					...customizableColumns,
					tools,
				]}
				pinnedColumns={[
					{
						name: "name",
						side: "left",
					},
					{
						name: tools.name,
						side: "right",
					},
				]}
				rows={rowsWithUserInstrumentMetadata}
			/>
		</Card>
	);
}

export default UniverseDetails;
