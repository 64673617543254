import type { InvestmentStatuses } from "$root/api/api-gen";
import { ApplicationMode, ConstraintRelation } from "$root/api/api-gen";
import { t } from "i18next";

type RelationOptionsProps = {
	label: string;
	value: ConstraintRelation;
};

const relationOptions = [
	{ label: "Max", value: ConstraintRelation.Max },
	{ label: "Min", value: ConstraintRelation.Min },
	{ label: "Equal to", value: ConstraintRelation.Equal },
	{ label: "Between", value: ConstraintRelation.Between },
	{ label: "Exclude", value: ConstraintRelation.Exclude },
];

const applicationModeOptions = [
	{
		label: "Portfolio exposure",
		value: ApplicationMode.PortfolioExposure,
	},
	{
		label: "Each instrument",
		value: ApplicationMode.EachInstrument,
	},
];

function excludeRelationOption(keys: ConstraintRelation[]): RelationOptionsProps[] {
	return relationOptions.filter(({ value }) => keys.includes(value) === false);
}

export function assocStatusToEntity(entityName?: string, status?: InvestmentStatuses): string {
	return `${entityName ?? "-"} ${status && status !== "READY" ? `(${t(`PORTFOLIO_STATUS.${status}`)})` : ""}`;
}

export { excludeRelationOption, relationOptions, applicationModeOptions };
