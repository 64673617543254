import type { StylableProps } from "@mdotm/mdotui/components";
import { Text, TinyIconButton } from "@mdotm/mdotui/components";
import type { MouseEvent } from "react";
import { useRef, useState } from "react";
import { overrideClassName } from "@mdotm/mdotui/react-extensions";
import type {
	InlineTextFormImperativeHandles,
	InlineTextFormMode,
} from "$root/components/smart-text-input/InlineTextForm";
import { InlineTextForm } from "$root/components/smart-text-input/InlineTextForm";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { themeCSSVars } from "@mdotm/mdotui/themes";

export type InstrumentNameFormCellProps = {
	value?: string;
	onSubmitAsync?(newValue: string): MaybePromise<void>;
	onClick?(e: MouseEvent): void;
} & StylableProps;

export function InstrumentNameFormCell(props: InstrumentNameFormCellProps): JSX.Element {
	const inlineTextFormHandleRefs = useRef<InlineTextFormImperativeHandles | null>(null);
	const [mode, setMode] = useState<InlineTextFormMode>("view");

	return (
		<div
			onClick={(e) => {
				if (props.onSubmitAsync || mode === "edit") {
					e.stopPropagation();
				}
				props.onClick?.(e);
			}}
			className={overrideClassName("flex items-center group/cell group", props.classList)}
			style={props.style}
		>
			<InlineTextForm
				handleRef={inlineTextFormHandleRefs}
				canEdit={props.onSubmitAsync != null}
				onEdit={async (newValue) => {
					await props.onSubmitAsync?.(newValue);
				}}
				rows={3}
				value={props.value}
				mode={mode}
				onModeChange={setMode}
				noDataText=""
				useMarkdown={false}
				editButton={({ onClick }) => (
					<TinyIconButton
						data-qualifier="inlineTextForm/edit"
						classList="transition-opacity opacity-0 group-hover/cell:opacity-100"
						icon="Edit"
						color={themeCSSVars.palette_P500}
						onClick={onClick}
					/>
				)}
			>
				{({ content }) => (
					<Text type="Body/M/Book" classList="self-center line-clamp-2">
						{content}
					</Text>
				)}
			</InlineTextForm>
		</div>
	);
}
