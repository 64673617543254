import type { Setter, SetterOrUpdater } from "@mdotm/mdotui/utils";
import { Switch } from "@mdotm/mdotui/react-extensions";
import { Button, Dialog, DialogFooter, DialogHeader, Icon } from "@mdotm/mdotui/components";
import { adaptAnimatedNodeProvider, spawn, toClassName, useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { unpromisify } from "@mdotm/mdotui/utils";
import type { ReactNode } from "react";
import { useCallback, useEffect, useState } from "react";
import { SolvedWithAIIcon } from "../Portfolio/CreatePortfolio/ai-resources";

export type CommonStepProps<
	TStepsPayloadMap extends Record<string, unknown>,
	TStepName extends keyof TStepsPayloadMap,
	TContext = never,
> = {
	context: TContext;
	mode: StepperMode;
	allStepsData: { [K in keyof TStepsPayloadMap]: TStepsPayloadMap[K] };
	stepData: TStepsPayloadMap[TStepName];
	stepMetadata: StepMetadata;
	/**
	 * Call this function when you want to update the data of the entire stepper.
	 * Note that calling this function will automatically set the step as not completed.
	 */
	onStepDataChange(
		newData: TStepsPayloadMap[TStepName],
		opts?: { skipMetadataUpdate?: boolean; persist?: boolean },
	): void;
	/**
	 * Call this function once the step is validated and can be marked as completed.
	 */
	onStepCompleted(): void;
	/**
	 * Call this function when the step contains invalid data.
	 */
	onStepError(): void;
	/**
	 * This function controls the dirty flag of the step. This information is used to show the user a blocking Dialog
	 * when they try to change step without saving the currently selected step first.
	 *
	 * You should call it if the state of the step is localized (usually for performance reasons), i.e. it may not always be in sync with the
	 * `stepsData`. Otherwise, you can ignore this function as the dirty flag is automatically handled when you call
	 * `onStepDataChange` and `onStepCompleted`.
	 */
	toggleDirty(dirty: boolean): void;
	overrideCurrentStepIcon(icon: ReactNode | null): void;
};

export type StepperMode = "new" | "edit" | "view";

export type StepMetadata = {
	name: string;
	optional: boolean;
	completed: boolean;
	visited: boolean;
	enabled: boolean;
	visible: boolean;
	processing: boolean;
	hasErrors: boolean;
	dirty: boolean;
	persisting: boolean;
	solveWithAi: boolean;
};

export type MultiStepFormV2Props<TStepsPayloadMap extends Record<string, unknown>, TContext = never> = {
	currentStep: keyof TStepsPayloadMap;
	setCurrentStep(stepName: keyof TStepsPayloadMap): void;
	stepsMetadata: { [K in keyof TStepsPayloadMap]: StepMetadata };
	setStepsMetadata: SetterOrUpdater<{ [K in keyof TStepsPayloadMap]: StepMetadata }>;
	stepsData: TStepsPayloadMap;
	setStepsData: Setter<TStepsPayloadMap>;
	componentsByStep: {
		[K in keyof TStepsPayloadMap]: (props: CommonStepProps<TStepsPayloadMap, K, TContext>) => JSX.Element;
	};
	orderedStepNames: ReadonlyArray<keyof TStepsPayloadMap>;
	mode: StepperMode;
	context?: TContext;
	unsavedDataHandler?: () => Promise<"stay" | "continue">;
};

export function MultiStepFormV2<TStepsPayloadMap extends Record<string, unknown>, TContext>({
	currentStep,
	setCurrentStep,
	stepsMetadata,
	setStepsMetadata,
	unsavedDataHandler = spawnUnsavedDataDialog,
	stepsData,
	setStepsData,
	orderedStepNames,
	componentsByStep,
	mode,
	context,
}: MultiStepFormV2Props<TStepsPayloadMap, TContext>): JSX.Element {
	const [editableStepsData, _setEditableStepsData] = useState(stepsData);

	const refs = useUnsafeUpdatedRef({
		editableStepsData,
		setStepsData,
		stepsMetadata,
		setStepsMetadata,
		setCurrentStep,
		stepsData,
		currentStep,
	});

	const setEditableStepsData = useCallback(
		(v: typeof editableStepsData) => {
			refs.current.editableStepsData = v;
			_setEditableStepsData(v);
		},
		[refs],
	);
	const toggleComplete = useCallback(
		(stepName: keyof TStepsPayloadMap, completed: boolean) => {
			refs.current.setStepsMetadata((prev) => ({
				...prev,
				[stepName]: { ...prev[stepName], completed, dirty: false },
			}));
			if (completed) {
				refs.current.setStepsData(refs.current.editableStepsData);
			}
		},
		[refs],
	);
	const toggleDirty = useCallback(
		(stepName: keyof TStepsPayloadMap, dirty: boolean) => {
			refs.current.setStepsMetadata((prev) => ({
				...prev,
				[stepName]: { ...prev[stepName], dirty },
			}));
		},
		[refs],
	);
	const toggleError = useCallback(
		(stepName: keyof TStepsPayloadMap, hasErrors: boolean) => {
			refs.current.setStepsMetadata((prev) => ({
				...prev,
				[stepName]: { ...prev[stepName], hasErrors },
			}));
		},
		[refs],
	);

	const persistEditableStepData = useCallback(() => {
		refs.current.setStepsData(refs.current.editableStepsData);
	}, [refs]);

	useEffect(() => {
		setEditableStepsData(stepsData);
	}, [setEditableStepsData, stepsData]);

	const [iconOverride, setIconOverride] = useState<ReactNode | null>(null);

	const [trySetCurrentStepIsRunning, setTrySetCurrentStepIsRunning] = useState(false);
	const trySetCurrentStep = useCallback(
		async (stepName: keyof TStepsPayloadMap) => {
			setTrySetCurrentStepIsRunning(true);
			try {
				const activeStep = refs.current.currentStep;
				if (!refs.current.stepsMetadata[activeStep].dirty) {
					refs.current.setCurrentStep(stepName);
					setIconOverride(null);
				} else {
					const action = await unsavedDataHandler();
					if (action === "continue") {
						setEditableStepsData(refs.current.stepsData);
						refs.current.setCurrentStep(stepName);
						toggleDirty(activeStep, false);
						setIconOverride(null);
					}
				}
			} finally {
				setTrySetCurrentStepIsRunning(false);
			}
		},
		[refs, setEditableStepsData, toggleDirty, unsavedDataHandler],
	);

	useEffect(() => {
		refs.current.setStepsMetadata((prev) => ({
			...prev,
			[currentStep]: { ...prev[currentStep], visited: true },
		}));
	}, [currentStep, refs]);

	return (
		<>
			<div className="flex-1 min-h-0 flex items-start overflow-hidden">
				<div className="min-h-0 flex flex-col space-y-2 px-2">
					{orderedStepNames
						.filter((stepName) => stepsMetadata[stepName].visible)
						.map((stepName) => {
							const { hasErrors, solveWithAi } = stepsMetadata[stepName];
							const isCurrent = currentStep === stepName;
							const state = stepsMetadata[stepName].completed
								? "completed"
								: stepsMetadata[stepName].processing
								  ? "processing"
								  : stepsMetadata[stepName].persisting
								    ? "persisting"
								    : !stepsMetadata[stepName].enabled
								      ? "disabled"
								      : "pending";
							return (
								<div key={stepName as string} className="relative z-0">
									{/* <div
										className={toClassName({
											"absolute z-10 pointer-events-none top-[14px] right-[16px]": true,
											"text-[10px]": true,
											[`text-[color:${themeCSSVars.global_palette_neutral_400}]`]: !isCurrent,
											[`text-[color:${themeCSSVars.global_palette_neutral_0}]`]: isCurrent,
										})}
									>
										Step {i + 1}
									</div> */}
									<div
										className={toClassName({
											"flex items-center space-x-3 min-w-[228px] px-4 h-[66px] rounded-sm border relative z-0": true,
											[`border-[${themeCSSVars.global_palette_neutral_300}]`]: state === "completed",
											[`bg-[${themeCSSVars.global_palette_neutral_700}] border-[${themeCSSVars.global_palette_neutral_700}]`]:
												isCurrent,
											[`border-[${themeCSSVars.global_palette_neutral_200}]`]: state === "pending",
											[`bg-[${themeCSSVars.global_palette_neutral_0}]`]: !isCurrent,
											shadow: state !== "completed",
										})}
									>
										<div data-qualifier={`multiStepForm/stepIcon(${state})`}>
											{/* TODO: replace with <Icon icon={...} /> */}
											{(function () {
												if (isCurrent && iconOverride) {
													return iconOverride;
												}
												if ((isCurrent && state === "pending" && !hasErrors) || mode === "view") {
													return (
														<svg
															width="24"
															height="24"
															viewBox="0 0 24 24"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<rect width="24" height="24" rx="12" fill="white" />
															<path
																fillRule="evenodd"
																clipRule="evenodd"
																d="M11.9987 21.1668C17.0613 21.1668 21.1654 17.0628 21.1654 12.0002C21.1654 6.93755 17.0613 2.8335 11.9987 2.8335C6.93609 2.8335 2.83203 6.93755 2.83203 12.0002C2.83203 17.0628 6.93609 21.1668 11.9987 21.1668ZM10.332 16.1257C10.332 16.3798 10.4142 16.5891 10.5787 16.7535C10.7431 16.9179 10.9524 17.0002 11.2065 17.0002C11.4606 17.0002 11.6698 16.9179 11.8343 16.7535L15.9598 12.628C16.0495 12.5383 16.1132 12.4411 16.1509 12.3365C16.1879 12.2319 16.2065 12.1197 16.2065 12.0002C16.2065 11.8806 16.1879 11.7685 16.1509 11.6638C16.1132 11.5592 16.0495 11.462 15.9598 11.3724L11.8343 7.2468C11.6698 7.08237 11.4606 7.00016 11.2065 7.00016C10.9524 7.00016 10.7431 7.08237 10.5787 7.2468C10.4142 7.41122 10.332 7.62049 10.332 7.8746C10.332 8.12871 10.4142 8.33798 10.5787 8.5024L14.0764 12.0002L10.5787 15.4979C10.4142 15.6623 10.332 15.8716 10.332 16.1257Z"
																fill="#667085"
															/>
														</svg>
													);
												}
												if (solveWithAi) {
													return <SolvedWithAIIcon />;
												}
												if (hasErrors) {
													return (
														<svg
															width="24"
															height="24"
															viewBox="0 0 24 24"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<rect width="24" height="24" rx="12" fill="white" />
															<path
																d="M18.4775 5.5219C16.746 3.79042 14.4441 2.83301 11.9997 2.83301C6.94782 2.83301 2.83301 6.94782 2.83301 11.9997C2.83301 17.0515 6.94782 21.1663 11.9997 21.1663C17.0515 21.1663 21.1663 17.0515 21.1663 11.9997C21.1663 9.55523 20.2089 7.25338 18.4775 5.5219ZM15.5849 15.5849C15.3913 15.7784 15.1265 15.8904 14.8515 15.8904C14.5765 15.8904 14.3117 15.7784 14.1182 15.5849L12.0099 13.4765L9.90153 15.5849C9.49412 15.9923 8.83208 15.9923 8.42467 15.5849C8.23116 15.3913 8.11912 15.1265 8.11912 14.8515C8.11912 14.5765 8.23116 14.3117 8.42467 14.1182L10.533 12.0099L8.38393 9.86079C8.00708 9.45338 8.02745 8.8219 8.41449 8.42467C8.8219 8.01727 9.48393 8.01727 9.89134 8.42467L11.9997 10.533L14.108 8.42467C14.5052 8.02745 15.1876 8.02745 15.5849 8.42467C15.9821 8.8219 15.9923 9.45338 15.6154 9.86079L15.5849 9.90153L13.4765 12.0099L15.5849 14.1182C15.7784 14.3117 15.8904 14.5765 15.8904 14.8515C15.8904 15.1265 15.7784 15.3913 15.5849 15.5849Z"
																fill="#E81E25"
															/>
														</svg>
													);
												}
												switch (state) {
													case "completed":
														return (
															<svg
																width="24"
																height="24"
																viewBox="0 0 24 24"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<rect width="24" height="24" rx="12" fill="white" />
																<svg x="2" y="2">
																	<path
																		d="M10.0007 0.833496C4.9488 0.833496 0.833984 4.94831 0.833984 10.0002C0.833984 15.052 4.9488 19.1668 10.0007 19.1668C15.0525 19.1668 19.1673 15.052 19.1673 10.0002C19.1673 4.94831 15.0525 0.833496 10.0007 0.833496ZM7.93763 12.0775L13.7162 6.1785H13.7115C14.0388 5.84916 14.537 5.83948 14.8548 6.16397C15.1727 6.48846 15.1727 7.02121 14.8548 7.34571L8.49746 13.8307C8.17959 14.1552 7.65771 14.1552 7.33984 13.8307L4.12794 10.5519C3.81007 10.2274 3.81007 9.69466 4.12794 9.37016C4.44581 9.04567 4.96769 9.04567 5.28555 9.37016L7.93763 12.0775Z"
																		fill="#4CB09C"
																	/>
																</svg>
															</svg>
														);
													case "processing":
														return (
															<svg
																width="24"
																height="24"
																viewBox="0 0 24 24"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
																className="animate-spin ease-in-out"
															>
																<rect width="24" height="24" rx="12" fill="white" />
																<path
																	fillRule="evenodd"
																	clipRule="evenodd"
																	d="M2 12C2 6.48889 6.48889 2 12 2C17.5111 2 22 6.48889 22 12C22 17.5111 17.5111 22 12 22C6.48889 22 2 17.5111 2 12ZM12.1532 5.63636C11.3151 5.63636 10.6328 6.31329 10.6328 7.15139C10.6328 7.98948 11.3151 8.66641 12.1532 8.66641C12.9913 8.66641 13.6736 7.98948 13.6736 7.15139C13.6736 6.31329 12.9967 5.63636 12.1532 5.63636ZM7.0435 8.704C7.0435 7.94112 7.66133 7.32329 8.42421 7.32329C9.1871 7.32329 9.80493 7.94112 9.80493 8.704C9.80493 9.46689 9.1871 10.0847 8.42421 10.0847C7.66133 10.0847 7.0435 9.46689 7.0435 8.704ZM8.11842 12.4271C8.11842 11.7448 7.55969 11.1861 6.87739 11.1861C6.1951 11.1861 5.63636 11.7448 5.63636 12.4271C5.63636 13.1094 6.1951 13.6681 6.87739 13.6681C7.56506 13.6681 8.11842 13.1148 8.11842 12.4271ZM7.64017 15.3712C7.21038 15.801 7.21038 16.4994 7.64017 16.9292C8.06997 17.359 8.76838 17.359 9.20355 16.9292C9.63871 16.4994 9.63334 15.801 9.20355 15.3712C8.77375 14.9414 8.07534 14.9414 7.64017 15.3712ZM11.1859 17.402C11.1859 16.8647 11.6211 16.4349 12.1529 16.4349C12.6848 16.4349 13.12 16.8647 13.12 17.402C13.12 17.9392 12.6902 18.369 12.1529 18.369C11.6157 18.369 11.1859 17.9392 11.1859 17.402ZM17.4284 11.7341C17.047 11.7341 16.7408 12.0403 16.7408 12.4217C16.7408 12.8032 17.047 13.1094 17.4284 13.1094C17.8099 13.1094 18.1215 12.8032 18.1215 12.4217C18.1215 12.0403 17.8099 11.7341 17.4284 11.7341ZM15.2957 16.7358C14.9734 16.4134 14.9734 15.8869 15.2957 15.5646C15.6181 15.2422 16.1446 15.2422 16.4669 15.5646C16.7893 15.8869 16.7893 16.4134 16.4669 16.7358C16.1446 17.0581 15.6181 17.0581 15.2957 16.7358ZM15.9349 9.19828C16.2412 9.19828 16.4883 8.95115 16.4883 8.64492C16.4883 8.33869 16.2412 8.09156 15.9349 8.09156C15.6287 8.09156 15.3816 8.33869 15.3816 8.64492C15.3816 8.95115 15.6287 9.19828 15.9349 9.19828Z"
																	fill="#FFBE00"
																/>
															</svg>
														);
													case "persisting":
														return (
															<svg
																width="24"
																height="24"
																viewBox="0 0 24 24"
																fill="none"
																className="animate-spin ease-in-out"
																xmlns="http://www.w3.org/2000/svg"
															>
																<rect width="24" height="24" rx="12" fill="white" />
																<path
																	fillRule="evenodd"
																	clipRule="evenodd"
																	d="M2 12C2 6.48889 6.48889 2 12 2C17.5111 2 22 6.48889 22 12C22 17.5111 17.5111 22 12 22C6.48889 22 2 17.5111 2 12ZM12.1532 5.63636C11.3151 5.63636 10.6328 6.31329 10.6328 7.15139C10.6328 7.98948 11.3151 8.66641 12.1532 8.66641C12.9913 8.66641 13.6736 7.98948 13.6736 7.15139C13.6736 6.31329 12.9967 5.63636 12.1532 5.63636ZM7.0435 8.704C7.0435 7.94112 7.66133 7.32329 8.42421 7.32329C9.1871 7.32329 9.80493 7.94112 9.80493 8.704C9.80493 9.46689 9.1871 10.0847 8.42421 10.0847C7.66133 10.0847 7.0435 9.46689 7.0435 8.704ZM8.11842 12.4271C8.11842 11.7448 7.55969 11.1861 6.87739 11.1861C6.1951 11.1861 5.63636 11.7448 5.63636 12.4271C5.63636 13.1094 6.1951 13.6681 6.87739 13.6681C7.56506 13.6681 8.11842 13.1148 8.11842 12.4271ZM7.64017 15.3712C7.21038 15.801 7.21038 16.4994 7.64017 16.9292C8.06997 17.359 8.76838 17.359 9.20355 16.9292C9.63871 16.4994 9.63334 15.801 9.20355 15.3712C8.77375 14.9414 8.07534 14.9414 7.64017 15.3712ZM11.1859 17.402C11.1859 16.8647 11.6211 16.4349 12.1529 16.4349C12.6848 16.4349 13.12 16.8647 13.12 17.402C13.12 17.9392 12.6902 18.369 12.1529 18.369C11.6157 18.369 11.1859 17.9392 11.1859 17.402ZM17.4284 11.7341C17.047 11.7341 16.7408 12.0403 16.7408 12.4217C16.7408 12.8032 17.047 13.1094 17.4284 13.1094C17.8099 13.1094 18.1215 12.8032 18.1215 12.4217C18.1215 12.0403 17.8099 11.7341 17.4284 11.7341ZM15.2957 16.7358C14.9734 16.4134 14.9734 15.8869 15.2957 15.5646C15.6181 15.2422 16.1446 15.2422 16.4669 15.5646C16.7893 15.8869 16.7893 16.4134 16.4669 16.7358C16.1446 17.0581 15.6181 17.0581 15.2957 16.7358ZM15.9349 9.19828C16.2412 9.19828 16.4883 8.95115 16.4883 8.64492C16.4883 8.33869 16.2412 8.09156 15.9349 8.09156C15.6287 8.09156 15.3816 8.33869 15.3816 8.64492C15.3816 8.95115 15.6287 9.19828 15.9349 9.19828Z"
																	fill={themeCSSVars.palette_N300}
																/>
															</svg>
														);
													case "pending":
														return (
															<svg
																width="24"
																height="24"
																viewBox="0 0 24 24"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white" />
																<path
																	d="M11.9998 4.67123C10.0409 4.67123 8.19714 5.43176 6.81436 6.81455C3.9566 9.67231 3.9566 14.3277 6.81436 17.1854C9.67211 20.0432 14.3275 20.0432 17.1853 17.1854C20.043 14.3277 20.043 9.67231 17.1853 6.81455C15.8025 5.43176 13.9588 4.67123 11.9998 4.67123ZM16.056 12C16.056 12.2189 15.9696 12.4321 15.814 12.5877C15.6584 12.7432 15.4452 12.8297 15.2263 12.8297L12.841 12.8297L12.841 15.215C12.841 15.6759 12.4665 16.0504 12.0056 16.0504C11.7866 16.0504 11.5734 15.964 11.4179 15.8084C11.2623 15.6529 11.1759 15.4397 11.1759 15.2207L11.1759 12.8354H8.7445C8.30085 12.8181 7.95515 12.4494 7.94939 12.0058C7.94939 11.5448 8.3239 11.1703 8.78483 11.1703L11.1701 11.1703L11.1701 8.78502C11.1701 8.33562 11.5562 7.94959 12.0056 7.94959C12.455 7.94959 12.818 8.30105 12.8352 8.74469L12.841 8.78502L12.841 11.1703L15.2263 11.1703C15.4452 11.1703 15.6584 11.2568 15.814 11.4123C15.9696 11.5679 16.056 11.7811 16.056 12Z"
																	fill="#A0A7B6"
																/>
																<rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#BFC4CE" />
															</svg>
														);
													case "disabled":
														return (
															<svg
																width="24"
																height="24"
																viewBox="0 0 24 24"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white" />
																<path
																	d="M11.9998 4.67123C10.0409 4.67123 8.19714 5.43176 6.81436 6.81455C3.9566 9.67231 3.9566 14.3277 6.81436 17.1854C9.67211 20.0432 14.3275 20.0432 17.1853 17.1854C20.043 14.3277 20.043 9.67231 17.1853 6.81455C15.8025 5.43176 13.9588 4.67123 11.9998 4.67123ZM16.056 12C16.056 12.2189 15.9696 12.4321 15.814 12.5877C15.6584 12.7432 15.4452 12.8297 15.2263 12.8297L12.841 12.8297L12.841 15.215C12.841 15.6759 12.4665 16.0504 12.0056 16.0504C11.7866 16.0504 11.5734 15.964 11.4179 15.8084C11.2623 15.6529 11.1759 15.4397 11.1759 15.2207L11.1759 12.8354H8.7445C8.30085 12.8181 7.95515 12.4494 7.94939 12.0058C7.94939 11.5448 8.3239 11.1703 8.78483 11.1703L11.1701 11.1703L11.1701 8.78502C11.1701 8.33562 11.5562 7.94959 12.0056 7.94959C12.455 7.94959 12.818 8.30105 12.8352 8.74469L12.841 8.78502L12.841 11.1703L15.2263 11.1703C15.4452 11.1703 15.6584 11.2568 15.814 11.4123C15.9696 11.5679 16.056 11.7811 16.056 12Z"
																	fill="#DFE2E7"
																/>
																<rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#EFF0F3" />
															</svg>
														);
												}
											})()}
										</div>
										<div>
											<div
												className={toClassName({
													"text-[14px] font-medium": true,
													[`text-[color:${themeCSSVars.global_palette_neutral_800}]`]:
														!isCurrent && state !== "disabled",
													[`text-[color:${themeCSSVars.global_palette_neutral_700}]`]: state === "disabled",
													[`text-[color:${themeCSSVars.global_palette_neutral_0}]`]: isCurrent,
												})}
											>
												{stepsMetadata[stepName].name}
											</div>
											{mode === "view" ? (
												<></>
											) : (
												<>
													{state === "processing" ? (
														<div
															className={toClassName({
																"text-[10px]": true,
																[`text-[color:${themeCSSVars.global_palette_neutral_400}] text-[#A0A7B6]`]: !isCurrent,
																[`text-[color:${themeCSSVars.global_palette_neutral_0}]`]: isCurrent,
															})}
														>
															Calculating limits
														</div>
													) : hasErrors ? (
														<div
															className={toClassName({
																"text-[10px]": true,
																[`text-[color:${themeCSSVars.global_palette_neutral_400}] text-[#A0A7B6]`]: !isCurrent,
																[`text-[color:${themeCSSVars.global_palette_neutral_0}]`]: isCurrent,
															})}
														>
															Conflicting constraints detected
														</div>
													) : (
														!stepsMetadata[stepName].optional &&
														state !== "completed" && (
															<div
																className={toClassName({
																	"text-[10px]": true,
																	[`text-[color:${themeCSSVars.global_palette_neutral_400}] text-[#A0A7B6]`]:
																		!isCurrent,
																	[`text-[color:${themeCSSVars.global_palette_neutral_0}]`]: isCurrent,
																})}
															>
																Mandatory
															</div>
														)
													)}
												</>
											)}
										</div>
									</div>
									<button
										type="button"
										data-qualifier={`multiStepForm/stepButton(${stepsMetadata[stepName].name})`}
										aria-label={toClassName({
											[`step ${stepsMetadata[stepName].name}`]: true,
											activate: isCurrent,
										})}
										disabled={!stepsMetadata[stepName].enabled || trySetCurrentStepIsRunning}
										onClick={unpromisify(() => trySetCurrentStep(stepName))}
										className={toClassName({
											"w-full h-full absolute z-10 inset-0": true,
											"cursor-progress": trySetCurrentStepIsRunning,
										})}
									/>
								</div>
							);
						})}
				</div>
				<div
					className={`bg-[${themeCSSVars.global_palette_neutral_0}] flex-1 flex flex-col min-w-0 min-h-0 h-full overflow-y-auto`}
					style={{ flexBasis: "min-content" }}
				>
					<Switch
						case={String(currentStep)}
						match={prepareStepHelper(componentsByStep, {
							overrideCurrentStepIcon: setIconOverride,
							stepsMetadata,
							stepsData: editableStepsData,
							setStepsData: setEditableStepsData,
							toggleComplete,
							mode,
							persist: persistEditableStepData,
							toggleDirty,
							toggleError,
							context,
						})}
					/>
				</div>
			</div>
		</>
	);
}

function prepareStepHelper<TStepsPayloadMap extends Record<string, unknown>, TContext>(
	componentsByStep: {
		[K in keyof TStepsPayloadMap]: (props: CommonStepProps<TStepsPayloadMap, K>) => JSX.Element;
	},
	{
		overrideCurrentStepIcon,
		stepsMetadata,
		stepsData,
		setStepsData,
		toggleComplete,
		toggleDirty,
		persist,
		mode,
		toggleError,
		context,
	}: {
		overrideCurrentStepIcon(icon: ReactNode | null): void;
		stepsMetadata: { [K in keyof TStepsPayloadMap]: StepMetadata };
		stepsData: TStepsPayloadMap;
		setStepsData: (stepsData: TStepsPayloadMap) => void;
		toggleComplete: (stepName: keyof TStepsPayloadMap, completed: boolean) => void;
		toggleDirty: (stepName: keyof TStepsPayloadMap, dirty: boolean) => void;
		toggleError: (stepName: keyof TStepsPayloadMap, hasErrors: boolean) => void;
		persist: () => void;
		mode: StepperMode;
		context: TContext;
	},
): {
	[K in keyof TStepsPayloadMap]: () => ReactNode;
} {
	return Object.fromEntries(
		Object.entries(componentsByStep).map(([stepName, Component]) => {
			const typedStepName = stepName as keyof TStepsPayloadMap;
			const TypedComponent = Component as (
				props: CommonStepProps<TStepsPayloadMap, keyof TStepsPayloadMap, TContext>,
			) => JSX.Element;
			return [
				typedStepName,
				() => (
					<TypedComponent
						overrideCurrentStepIcon={overrideCurrentStepIcon}
						allStepsData={stepsData}
						stepMetadata={stepsMetadata[typedStepName]}
						stepData={stepsData[typedStepName]}
						onStepCompleted={() => {
							// deprecated, previously used in old save with button
							toggleComplete(typedStepName, true);
							toggleError(typedStepName, false);
						}}
						onStepDataChange={(stepData, opts) => {
							// persist the stepData internally
							setStepsData({ ...stepsData, [typedStepName]: stepData });
							if (!(opts?.skipMetadataUpdate ?? false)) {
								// overrride stepData and set metadata to false
								toggleComplete(typedStepName, false);
							}

							if (opts?.persist ?? false) {
								// persist the stepData on parent
								persist();
							}
						}}
						mode={mode}
						toggleDirty={(dirty) => toggleDirty(typedStepName, dirty)}
						onStepError={() => {
							toggleError(typedStepName, true);
							toggleComplete(typedStepName, false);
						}}
						context={context}
					/>
				),
			];
		}),
	) as {
		[K in keyof TStepsPayloadMap]: () => ReactNode;
	};
}

export function spawnUnsavedDataDialog(): Promise<"stay" | "continue"> {
	return spawn<"stay" | "continue">(
		adaptAnimatedNodeProvider(({ resolve, show, onHidden }) => (
			<Dialog
				show={show}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
				onClose={() => resolve("stay")}
				header={
					<DialogHeader icon={<Icon icon="Icon-full-alert" color={themeCSSVars.MessageSeverity_warning} size={22} />}>
						Unsaved data
					</DialogHeader>
				}
				footer={
					<DialogFooter
						neutralAction={
							<Button
								data-qualifier="multiStepForm/stay"
								palette="tertiary"
								onClick={() => {
									resolve("stay");
								}}
							>
								Stay
							</Button>
						}
						primaryAction={
							<Button
								palette="primary"
								data-qualifier="multiStepForm/discardUnsaved"
								onClick={() => {
									resolve("continue");
								}}
							>
								Discard unsaved
							</Button>
						}
					/>
				}
			>
				There is unsaved data in the current section, are you sure you want to jump to another one?
			</Dialog>
		)),
	).promise;
}
