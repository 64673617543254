import type { AssetClass, UserInstrumentClassificationDto } from "$root/api/api-gen";
import type { MinimumDialogProps } from "$root/components/spawnable/type";
import { objectTextSearchMatchFns, qualifier } from "$root/utils";
import type { Option, OrderBy, TableColumn } from "@mdotm/mdotui/components";
import {
	BaseTable,
	Button,
	Controller,
	DebouncedSearchInput,
	Dialog,
	DialogFooter,
	Radio,
	RadioGroup,
	Select,
	sortRows,
	SubmitButton,
	Text,
	useSelectableTableColumn,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { useSearchable } from "@mdotm/mdotui/headless";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, spawn } from "@mdotm/mdotui/react-extensions";
import { builtInSortFnFor, find, typedObjectValues } from "@mdotm/mdotui/utils";
import { Map } from "immutable";
import { useCallback, useEffect, useMemo, useState } from "react";
import { flushSync } from "react-dom";
import { useTranslation } from "react-i18next";
import { ClassificationOptionsTable } from "./spawn-tag-classifications";

const AssetAllocationType = {
	tag: "TAG",
	riskModel: "RISK_MODEL",
} as const;

type AssetAllocationType = (typeof AssetAllocationType)[keyof typeof AssetAllocationType];

type RiskModelAssetAllocation = {
	type: "RISK_MODEL";
	selectedOptions: Array<AssetClass>;
};

type TagAssetAllocation = {
	type: "TAG";
	selectedOptions: Array<string /** option */>;
} & UserInstrumentClassificationDto;

type AssetAllocationPayload = TagAssetAllocation | RiskModelAssetAllocation;

type AssetAllocationDialogProps = MinimumDialogProps & {
	preselectedAssetClass?: {
		id: string;
		data: AssetClass | string;
	}; // todo: discriminate the value
	selectableUserClassifications: UserInstrumentClassificationDto[];
	selectableAssetClasses: AssetClass[];
	disabledRowIds?: string[];
	onSubmit(data: AssetAllocationPayload): void;
	optionsProvider(classificationUuid: string): MaybePromise<Array<string>>;
};

export function generateAssetClassId(x: AssetClass): string {
	return `${x.identifier}-${x.macroAssetClass}-${x.microGeography}-${x.name}-${x.microAssetClass}`;
}

function AssetAllocationDialog({
	show,
	onAnimationStateChange,
	onClose,
	onSubmit,
	optionsProvider,
	selectableUserClassifications,
	selectableAssetClasses,
	preselectedAssetClass,
	disabledRowIds,
}: AssetAllocationDialogProps) {
	const { t } = useTranslation();
	const [assetAllocation, setAssetAllocation] = useState<
		Omit<TagAssetAllocation, "selectedOptions"> | Omit<RiskModelAssetAllocation, "selectedOptions">
	>({ type: "RISK_MODEL" });

	const [assetAllocationMap, setAssetAllocationMap] = useState<Map<string, AssetClass | string>>(
		Map(preselectedAssetClass ? [[preselectedAssetClass.id, preselectedAssetClass.data]] : undefined),
	); // merge this type to AssetAllocation

	const handleSubmitAsync = useCallback(
		(currentAssetAllocation: typeof assetAllocation, currentAssetAllocationMap: typeof assetAllocationMap) => {
			if (currentAssetAllocation.type === "RISK_MODEL") {
				const selectedOptions = currentAssetAllocationMap.valueSeq().toArray();
				onSubmit({
					type: currentAssetAllocation.type,
					selectedOptions: selectedOptions as Array<AssetClass>,
				});
				return;
			}

			const selectedOptions = currentAssetAllocationMap.valueSeq().toArray();
			onSubmit({
				...currentAssetAllocation,
				type: currentAssetAllocation.type,
				selectedOptions: selectedOptions as Array<string>,
			});
		},
		[onSubmit],
	);

	const selectableAssetClassOptions = useMemo(
		() =>
			selectableUserClassifications.filter(
				(classification) => classification.tagType === "ASSET_CLASS" && classification.fieldType === "TAG",
			),
		[selectableUserClassifications],
	);

	return (
		<Dialog
			size="xxlarge"
			show={show}
			onAnimationStateChange={onAnimationStateChange}
			onClose={onClose}
			header="Select"
			onSubmitAsync={() => handleSubmitAsync(assetAllocation, assetAllocationMap)}
			footer={
				<DialogFooter
					primaryAction={
						<SubmitButton palette="primary" data-qualifier={qualifier.component.button("Dialog/Submit")}>
							{t("BUTTON.DONE")}
						</SubmitButton>
					}
					neutralAction={
						<Button palette="tertiary" onClick={onClose} data-qualifier={qualifier.component.button("Dialog/Cancel")}>
							{t("BUTTON.CANCEL")}
						</Button>
					}
				/>
			}
		>
			<>
				<Text type="Body/M/Medium" classList="mb-1" as="p">
					Apply constraint on
				</Text>
				<RadioGroup
					onChange={(newAllocationType) => {
						if (newAllocationType === "TAG") {
							const firstCustomAllocationConstraint = selectableUserClassifications.find((x) => {
								return x.fieldType === "TAG" && x.tagType === "ASSET_CLASS";
							});

							setAssetAllocation({
								type: newAllocationType,
								...(firstCustomAllocationConstraint ?? {}),
							});
							setAssetAllocationMap(Map());
							return;
						}

						setAssetAllocation({ type: newAllocationType });
						setAssetAllocationMap(Map());
					}}
					value={assetAllocation.type}
					classList="mb-2"
				>
					<div className="flex flex-row flex-wrap gap-4">
						<Radio
							data-qualifier={`AssetAllocationDialog/ApplyConstraintOn/${AssetAllocationType.riskModel}`}
							value={AssetAllocationType.riskModel}
						>
							Risk Model
						</Radio>
						<Radio
							data-qualifier={`AssetAllocationDialog/ApplyConstraintOn/${AssetAllocationType.riskModel}`}
							value={AssetAllocationType.tag}
						>
							Asset Allocation Tags
						</Radio>
					</div>
				</RadioGroup>
				<Text type="Body/M/Book" classList="mb-4" as="p">
					{assetAllocation.type === "RISK_MODEL"
						? "Choose to apply constraints using Sphere's Risk Model classifications."
						: "Choose to apply constraints using user-defined custom categories."}
				</Text>
				<div className="max-h-[400px] h-[400px] min-h-0 overflow-hidden flex flex-col">
					{assetAllocation.type === "TAG" ? (
						<ClassificationOptionsTable
							mode="radio"
							selectedClassificationUuid={assetAllocation.classificationUuid!}
							selectableUserClassifications={selectableAssetClassOptions}
							disabledRowIds={disabledRowIds}
							onChangeClassification={(newClassification) => {
								if (newClassification) {
									setAssetAllocation(() => ({
										...newClassification,
										type: "TAG",
									}));
									setAssetAllocationMap(Map());
								}
							}}
							optionsProvider={optionsProvider}
							onToggleOption={(option) => {
								if (assetAllocationMap.has(option)) {
									setAssetAllocationMap(assetAllocationMap.remove(option));
									return;
								}

								setAssetAllocationMap(Map([[option, option]]));
							}}
						/>
					) : assetAllocation.type === "RISK_MODEL" ? (
						<AssetAllocationTable
							mode="radio"
							selectableAssetClasses={selectableAssetClasses}
							disabledRowIds={disabledRowIds}
							onToggleOption={(row) => {
								if (assetAllocationMap.has(row.id)) {
									setAssetAllocationMap(assetAllocationMap.remove(row.id));
									return;
								}

								setAssetAllocationMap(Map([[row.id, row]]));
							}}
						/>
					) : null}
				</div>
			</>
		</Dialog>
	);
}

type AssetAllocationTableProps = {
	selectableAssetClasses: AssetAllocationDialogProps["selectableAssetClasses"];
	mode?: "checkbox" | "radio";
	onToggleOption?(row: AssetClass & { id: string }): void;
	disabledRowIds?: string[];
};

const defaultAssetAllocationFilters = {
	granularity: [] as string[],
	assetClass: [] as string[],
	geography: [] as string[],
	microAssetClass: [] as string[],
	microGeography: [] as string[],
};

const defaultAssetAllocationOptions: { [k in keyof typeof defaultAssetAllocationFilters]: Option<string>[] } = {
	granularity: [],
	assetClass: [],
	geography: [],
	microAssetClass: [],
	microGeography: [],
};

function includesGuardFn(filter: string[], field: string) {
	if (filter.length === 0) {
		return true;
	}
	return filter.filter((k) => field.toLowerCase().search(k.toLowerCase()) >= 0).length > 0;
}

function AssetAllocationTable({
	selectableAssetClasses,
	onToggleOption,
	mode,
	disabledRowIds,
}: AssetAllocationTableProps) {
	const [filters, setFilters] = useState(defaultAssetAllocationFilters);
	const [search, setSearch] = useState("");

	const selectableFilters = useMemo(
		() =>
			selectableAssetClasses.reduce((acc, el) => {
				if (el.macroAssetClass && !find(acc.assetClass, (x) => x.value === el.macroAssetClass)) {
					acc.assetClass.push({ label: el.macroAssetClass!, value: el.macroAssetClass! });
				}

				if (el.microAssetClass && !find(acc.microAssetClass, (x) => x.value === el.microAssetClass)) {
					acc.microAssetClass.push({ label: el.microAssetClass!, value: el.microAssetClass! });
				}

				if (el.granularity && !find(acc.granularity, (x) => x.value === el.granularity)) {
					acc.granularity.push({ label: el.granularity!, value: el.granularity! });
				}

				if (el.macroGeography && !find(acc.geography, (x) => x.value === el.macroGeography)) {
					acc.geography.push({ label: el.macroGeography!, value: el.macroGeography! });
				}

				if (el.microGeography && !find(acc.microGeography, (x) => x.value === el.microGeography)) {
					acc.microGeography.push({ label: el.microGeography!, value: el.microGeography! });
				}

				return acc;
			}, defaultAssetAllocationOptions),
		[selectableAssetClasses],
	);

	const rows = useMemo(
		() =>
			selectableAssetClasses.map((x) => ({
				...x,
				id: generateAssetClassId(x),
			})) ?? [],
		[selectableAssetClasses],
	);

	const filteredAssetClasses = useMemo(() => {
		const selectedFilters = typedObjectValues(filters).flatMap((filter) => filter);
		if (selectedFilters.length === 0) {
			return rows;
		}

		return rows.filter(
			(row) =>
				includesGuardFn(filters.assetClass, row.macroAssetClass ?? "") &&
				includesGuardFn(filters.microAssetClass, row.microAssetClass ?? "") &&
				includesGuardFn(filters.geography, row.macroGeography ?? "") &&
				includesGuardFn(filters.microGeography, row.microGeography ?? "") &&
				includesGuardFn(filters.granularity, row.granularity ?? ""),
		);
	}, [filters, rows]);

	const { filtered } = useSearchable({
		collection: filteredAssetClasses,
		query: search,
		matchFn: (row, q) => objectTextSearchMatchFns.substring(row, q),
	});

	const checkboxColumnData = useSelectableTableColumn({
		mode,
		rows: filteredAssetClasses,
		selectBy: (r) => r.id ?? "",
		filteredRows: filtered,
		selectableRowIds:
			disabledRowIds && filteredAssetClasses.map((row) => row.id).filter((rowId) => !disabledRowIds.includes(rowId)),
	});

	const columns = useMemo<TableColumn<AssetClass & { id: string }>[]>(
		() => [
			checkboxColumnData.column,
			{
				header: "",
				content: (x) => x.name,
				sortFn: builtInSortFnFor("name"),
				name: "assetAllocationName",
			},
		],
		[checkboxColumnData.column],
	);

	//todo: remove asap
	useEffect(() => {
		flushSync(() => {
			const row = filteredAssetClasses.find(({ id }) => {
				return checkboxColumnData.multiSelectCtx.selection.first() === id;
			});

			if (row) {
				onToggleOption?.(row);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkboxColumnData.multiSelectCtx.selection]);

	return (
		<>
			<div className="flex gap-2 mb-4">
				<DebouncedSearchInput
					externalQuery={search}
					onChange={setSearch}
					data-qualifier="AssetAllocationTable/Search"
					placeholder="Search asset allocation.."
					classList="flex-1"
				/>
				<Select
					enableSearch
					options={selectableFilters.granularity.sort(builtInSortFnFor("label"))}
					value={filters.granularity}
					multi
					i18n={{ triggerPlaceholder: () => "Granularity" }}
					onChange={(x) => setFilters((prev) => ({ ...prev, granularity: x }))}
					disabled={selectableFilters.granularity.length === 0}
					classList="flex-1"
					triggerDataAttrs={{ "data-qualifier": "AssetAllocationTable/Filters/Granularity"}}
				/>
				<Select
					enableSearch
					options={selectableFilters.assetClass.sort(builtInSortFnFor("label"))}
					value={filters.assetClass}
					multi
					i18n={{ triggerPlaceholder: () => "Macro asset class" }}
					onChange={(x) => setFilters((prev) => ({ ...prev, assetClass: x }))}
					disabled={selectableFilters.assetClass.length === 0}
					classList="flex-1"
					triggerDataAttrs={{ "data-qualifier": "AssetAllocationTable/Filters/AssetClass"}}
				/>
				<Select
					enableSearch
					options={selectableFilters.geography.sort(builtInSortFnFor("label"))}
					value={filters.geography}
					multi
					i18n={{ triggerPlaceholder: () => "Geography" }}
					onChange={(x) => setFilters((prev) => ({ ...prev, geography: x }))}
					disabled={selectableFilters.geography.length === 0}
					classList="flex-1"
					triggerDataAttrs={{ "data-qualifier": "AssetAllocationTable/Filters/Geography"}}
				/>
				<Select
					enableSearch
					options={selectableFilters.microAssetClass.sort(builtInSortFnFor("label"))}
					value={filters.microAssetClass}
					multi
					i18n={{ triggerPlaceholder: () => "Micro asset class" }}
					onChange={(x) => setFilters((prev) => ({ ...prev, microAssetClass: x }))}
					disabled={selectableFilters.microAssetClass.length === 0}
					classList="flex-1"
					triggerDataAttrs={{ "data-qualifier": "AssetAllocationTable/Filters/MicroAssetClass"}}
				/>
				<Select
					enableSearch
					options={selectableFilters.microGeography.sort(builtInSortFnFor("label"))}
					value={filters.microGeography}
					multi
					i18n={{ triggerPlaceholder: () => "Micro geo" }}
					onChange={(x) => setFilters((prev) => ({ ...prev, microGeography: x }))}
					disabled={selectableFilters.microGeography.length === 0}
					classList="flex-1"
					triggerDataAttrs={{ "data-qualifier": "AssetAllocationTable/Filters/MicroGeography"}}
				/>
			</div>
			<Controller value={defaultAssetAllocationReportOrderBy}>
				{({ value: orderBy, onChange: onOrderByChange }) => (
					<BaseTable
						columns={columns}
						rows={sortRows({ rows: filtered, columns, orderByArr: orderBy })}
						orderBy={orderBy}
						onOrderByChange={onOrderByChange}
						onRowClick={(row) => {
							if (!checkboxColumnData.multiSelectCtx.disabled(generateAssetClassId(row))) {
								checkboxColumnData.toggle(generateAssetClassId(row));
								// onToggleOption?.(row);
							}
						}}
					/>
				)}
			</Controller>
		</>
	);
}

const defaultAssetAllocationReportOrderBy: Array<OrderBy<"assetAllocationName">> = [
	{ columnName: "assetAllocationName", direction: "asc" },
];

type SpawnAssetAllocationDialogProps = Omit<AssetAllocationDialogProps, "show" | "onClose">;
export function spawnAssetAllocationDialog(props: SpawnAssetAllocationDialogProps): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ show, resolve, onHidden }) => (
			<AssetAllocationDialog
				{...props}
				show={show}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
				onClose={() => resolve()}
				onSubmit={(payload) => {
					props.onSubmit(payload);
					resolve();
				}}
			/>
		)),
	);
}

export function userClassificationId(classificationUuid: string, value: string): string {
	return `${classificationUuid}-${value}`;
}
