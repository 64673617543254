import { Card } from "$root/components/EvolvedPrint/components/Card";
import type { PrintableProps } from "$root/components/EvolvedPrint/configuration";
import { mmToPx } from "$root/components/EvolvedPrint/configuration/utils";
import { Text } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { useReportTranslation } from "../translation";

type DisclaimerProps = {
	disclaimer?: {
		title?: string;
		description?: string;
	};
	mail?: string;
	address?: string;
	website?: string;
	contacts?: string;
};

const palette = {
	mdotm: {
		title: themeCSSVars.palette_P800,
		text: themeCSSVars.palette_N700,
		background: themeCSSVars.palette_N0,
	},
	custom: {
		title: themeCSSVars.palette_P800,
		text: themeCSSVars.palette_N700,
		background: themeCSSVars.palette_N0,
	},
};

export function Disclaimer({
	address,
	disclaimer,
	mail,
	website,
	contacts,
}: PrintableProps<DisclaimerProps, null>): JSX.Element {
	const { t } = useReportTranslation();
	const isCustom = Boolean(address || disclaimer?.description || disclaimer?.title || mail || website);

	const theme = isCustom ? "custom" : "mdotm";
	const A4PortraitHeight_mm = 297;

	return (
		// <div
		// 	className="!box-border"
		// 	style={{
		// 		backgroundColor: palette[theme].background,
		// 		// height: mmToPx(A4PortraitHeight_mm) - 100,
		// 		// width: mmToPx(A4PortraitWidth_mm),
		// 	}}
		// >
		<Card style={{ height: mmToPx(A4PortraitHeight_mm) }}>
			<div className="flex flex-col justify-between h-full mt-8">
				<div className="mb-6">
					{disclaimer?.title && (
						<Text as="h3" type="Body/XL/Bold" style={{ color: palette[theme].title }} classList="mb-4">
							{disclaimer?.title}
						</Text>
					)}
					{disclaimer?.description && (
						<Text as="p" type="Body/M/Book" style={{ color: palette[theme].text }}>
							{disclaimer?.description}
						</Text>
					)}
				</div>
				{contacts && (
					<div className="mt-auto mb-0">
						<Text as="p" type="Body/XL/Bold" style={{ color: palette[theme].title }} classList="mb-4 xl:mb-6">
							{t("REPORT_BUILDER.DISCLAIMER.CONTACTS")}
						</Text>
						<p className="whitespace-pre-line">{contacts}</p>
						{/* {contacts ? (
						<p className="whitespace-pre-line">{contacts}</p>
					) : (
						<>
							<Text
								as="p"
								type="Body/M/Book"
								style={{ color: palette[theme].text }}
								classList="whitespace-pre-line mb-10 xl:mb-10 font-semibold"
							>
								{address || placeholder.address}
							</Text>

							<Text as="div" type="Body/M/Book" classList="mb-1">
								<span style={{ color: palette[theme].title }}>{website || placeholder.website}</span>
							</Text>
							<Text as="div" type="Body/M/Book">
								<span style={{ color: palette[theme].title }}>{mail || placeholder.mail}</span>
							</Text>
						</>
					)} */}
					</div>
				)}
			</div>
			{/* </div> */}
		</Card>
	);
}
