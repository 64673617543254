import type { RegimeTransitionMatrices } from "$root/api/api-gen";
import { HmmControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { IconWalls } from "$root/components/IconWall";
import SmallHeatmapChart from "$root/components/SmallHeatmapChart/SmallHeatmapChart";
import { PaletteColors } from "$root/styles/themePalette";
import type { ContextContent } from "$root/utils";
import { useQueryNoRefetch, withContext } from "$root/utils";
import { RegionContext } from "$root/widgets-architecture/contexts/region";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import { ScrollWrapper, Text } from "@mdotm/mdotui/components";
import type { Chart } from "highcharts";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";

export interface TmatrixPhase {
	[key: number]: { name: string; color: string };
}

const RegimeTransitionMatrix1MBlock = (props: ContextContent<typeof RegionContext>) => {
	const { t } = useTranslation();
	const days = "28";
	const { region } = props;
	const hmmApi = useApiGen(HmmControllerApiFactory);
	const chartRef = useRef<{ chart: Chart } | null>(null);

	const { isLoading, isFetching, isError, data } = useQueryNoRefetch(["queryHmmRegimeMatrix1M", region], {
		queryFn: async (): Promise<RegimeTransitionMatrices> => {
			const {
				data: { conditionalHistoricalTransition, historicalTransition },
			} = await hmmApi.retrieveRegimesTransitionMatrices(region, days);
			return { conditionalHistoricalTransition, historicalTransition };
		},
	});

	const { conditionalHistoricalTransition, historicalTransition } = data ?? {};

	const conditionalTransitions = useMemo(() => {
		const { data: phase } = conditionalHistoricalTransition ?? {};
		if (!phase) {
			return;
		}
		return [
			[0, 0, phase.growth],
			[1, 0, phase.lateralPhase],
			[2, 0, phase.highStress],
		];
	}, [conditionalHistoricalTransition]);

	const historicalTransitions = useMemo(() => {
		const { growth, lateralPhase, highStress } = historicalTransition ?? {};
		if (!growth || !lateralPhase || !highStress) {
			return;
		}
		return [
			[0, 0, growth.growth],
			[0, 1, lateralPhase.growth],
			[0, 2, highStress.growth],
			[1, 0, growth.lateralPhase],
			[1, 1, lateralPhase.lateralPhase],
			[1, 2, highStress.lateralPhase],
			[2, 0, growth.highStress],
			[2, 1, lateralPhase.highStress],
			[2, 2, highStress.highStress],
		];
	}, [historicalTransition]);

	const points = { conditionalTransitions, historicalTransitions };

	// const matrixPhase = useCallback((numberPhase: number | undefined) => {
	// 	if (!numberPhase) {
	// 		return;
	// 	}
	// 	const DTOMatricePhase: TmatrixPhase = {
	// 		1: { name: "Growth", color: PaletteColors.GREENY_BLUE },
	// 		2: { name: "Lateral Phase", color: PaletteColors.YELLOW },
	// 		3: { name: "High Stress", color: PaletteColors.NEON_RED },
	// 	};

	// 	return [DTOMatricePhase[numberPhase]];
	// }, []);

	// const yConditionalTransaction = matrixPhase(conditionalHistoricalTransition?.regime);

	useWidgetOptions(
		() => ({
			isDraggable: false,
			actionHeader: <></>,
			title: t("REGIME_TRANSITION_MATRIX.1M.TITLE"),
		}),
		[t],
	);

	return (
		<>
			<div>
				<ScrollWrapper style={{ height: 120 }}>{t("REGIME_TRANSITION_MATRIX.1M.DESCRIPTION")}</ScrollWrapper>
			</div>
			{isError ? (
				<IconWalls.ErrorData />
			) : isLoading || isFetching ? (
				<IconWalls.LoadingData />
			) : (
				<>
					<div style={{ marginTop: 20 }}>
						<div className="historical-transitions">
							<div className="mb-4 mt-2">
								<Text type="Body/L/Bold" style={{ paddingRight: 5 }}>
									{t("REGIME_TRANSITION_MATRIX.1M.HISTORICAL_TRANSITIONS.TITLE")}
								</Text>
							</div>
							<SmallHeatmapChart
								ref={chartRef}
								legend
								data={points.historicalTransitions ?? []}
								xCategories={[
									{ name: t("REGIME_TRANSITION_MATRIX.AXIS.GROWTH"), color: PaletteColors.GREENY_BLUE },
									{ name: t("REGIME_TRANSITION_MATRIX.AXIS.LATERAL_PHASE"), color: PaletteColors.YELLOW },
									{ name: t("REGIME_TRANSITION_MATRIX.AXIS.HIGH_STRESS"), color: PaletteColors.NEON_RED },
								]}
								yCategories={[
									{ name: t("REGIME_TRANSITION_MATRIX.AXIS.GROWTH"), color: PaletteColors.GREENY_BLUE },
									{ name: t("REGIME_TRANSITION_MATRIX.AXIS.LATERAL_PHASE"), color: PaletteColors.YELLOW },
									{ name: t("REGIME_TRANSITION_MATRIX.AXIS.HIGH_STRESS"), color: PaletteColors.NEON_RED },
								]}
							/>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default withContext(RegionContext)(RegimeTransitionMatrix1MBlock);
