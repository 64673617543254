import ColoredRectangle from "$root/components/icons/ColoredRectangle";
import { useLocaleFormatters } from "$root/localization/hooks";
import { Highcharts } from "$root/third-party-integrations/highcharts-with-modules";
import { avgArrayLike, maxArrayLike, minArrayLike } from "$root/utils";
import { Card } from "$root/widgets-architecture/layout/Card";
import { StackingContext, Text } from "@mdotm/mdotui/components";
import { useDraggable } from "@mdotm/mdotui/headless";
import { renderNodeOrFn, useDrivenState, useUniqueDOMId, type NodeOrFn } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { Rect2D, Vec2D } from "@mdotm/mdotui/utils";
import { clamp, identity, isBetween, steppify } from "@mdotm/mdotui/utils";
import type { HighchartsReactRefObject } from "highcharts-react-official";
import HighchartsReact from "highcharts-react-official";
import { Set } from "immutable";
import type { ForwardedRef } from "react";
import { useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { renderToString } from "react-dom/server";

export type MetricComparisonChartProps = { innerRef?: ForwardedRef<HighchartsReactRefObject> } & {
	/** @default 1  */
	thresholdDragStep?: number;
	chartTitle: string;
	mainMetricLabel: string;
	secondaryMetricLabel: string;
	min: number;
	max: number;
	avg: number;
	actions?: NodeOrFn;
	threshold?: number;
	onThresholdChange?(newThreshold: number): void;
	/** e.g. % */
	unit?: string;
	data: Array<{ name: string; metrics: [number | null | undefined, number | null | undefined]; id: string }>;
	selectedIds?: Set<string> | null;
	onSelectionChange?(newSelection: Set<string>): void;
	/** @default 'tall' */
	chartHeight?: "short" | "tall" | "full" | number;
	highlightSecondaryMetric?: boolean;
};

export function MetricComparisonChart(props: MetricComparisonChartProps): JSX.Element {
	const unit = props.unit ?? "";
	const { formatNumber } = useLocaleFormatters();

	const thresholdBoxId = useUniqueDOMId();

	const flattedMetrics = useMemo(
		() => props.data.flatMap((x) => x.metrics.filter((metric) => metric != null)),
		[props.data],
	);
	const { min, avg, max } = useMemo(
		() => ({
			min: minArrayLike(flattedMetrics, (metric) => metric as number),
			avg: avgArrayLike(flattedMetrics, (metric) => metric as number),
			max: maxArrayLike(flattedMetrics, (metric) => metric as number),
		}),
		[flattedMetrics],
	);

	const [threshold, setThreshold] = useDrivenState(props.threshold);
	const cachedAxisYLimits = useMemo(
		() => ({
			max: props.threshold ? Math.max(max, props.threshold) : max,
			min: props.threshold ? Math.min(min, props.threshold) : min,
		}),
		[max, min, props.threshold],
	);

	const [selectedIds, setSelectedIds] = useDrivenState(props.selectedIds ?? Set<string>(), {
		equalityComparator: (a, b) => a.equals(b),
	});
	const [selectionRect, setSelectionRect] = useState<(Vec2D & Rect2D) | null>(null);
	const shiftSelectionIdRef = useRef<string | null>(null);

	const chartId = useUniqueDOMId();

	const classPrefix = useUniqueDOMId();
	const thresholdDragStep = props.thresholdDragStep ?? 1;
	const draggingStateRef = useRef<
		| {
				type: "selection";
				subType: "toggle" | "new";
				initialSelection: typeof selectedIds;
				currentSelection: typeof selectedIds;
				firstSeriesContainer: SVGGElement | null;
				secondSeriesContainer: SVGGElement | null;
		  }
		| { type: "threshold"; initialThreshold: number; thresholdBox: DOMRect; plotArea: Vec2D & Rect2D }
		| null
	>();

	const { dragging } = useDraggable({
		draggableEl: window,
		filter(initialCoords, keyModifiers) {
			const thresholdBox = document.getElementById(thresholdBoxId)?.getBoundingClientRect();
			const cardAreaEl = document.getElementById(chartId)!;
			const plotAreaSeries0 = cardAreaEl.querySelector(".highcharts-series-0")!.getBoundingClientRect();
			const plotAreaSeries1 = cardAreaEl.querySelector(".highcharts-series-1")!.getBoundingClientRect();
			const plotArea = {
				x: Math.min(plotAreaSeries0.left, plotAreaSeries1.left),
				y: Math.min(plotAreaSeries0.top, plotAreaSeries1.top),
				width: Math.max(plotAreaSeries0.width, plotAreaSeries1.width),
				height: Math.max(plotAreaSeries0.height, plotAreaSeries1.height),
			};
			if (
				thresholdBox &&
				isBetween(initialCoords.x, thresholdBox.left, thresholdBox.left + thresholdBox.width) &&
				isBetween(initialCoords.y, thresholdBox.top, thresholdBox.top + thresholdBox.height)
			) {
				draggingStateRef.current = { type: "threshold", initialThreshold: threshold ?? 0, thresholdBox, plotArea };
				return true;
			}
			const svgWrapper = cardAreaEl.querySelector(".highcharts-container")!.getBoundingClientRect();
			const navigator = cardAreaEl.querySelector(".highcharts-navigator")!.getBoundingClientRect();
			if (
				isBetween(initialCoords.x, svgWrapper.left, svgWrapper.left + svgWrapper.width) &&
				isBetween(initialCoords.y, svgWrapper.top, svgWrapper.top + svgWrapper.height - navigator.height)
			) {
				draggingStateRef.current = {
					type: "selection",
					subType: keyModifiers.shift ? "toggle" : "new",
					initialSelection: selectedIds,
					currentSelection: selectedIds,
					firstSeriesContainer: cardAreaEl.querySelector(".highcharts-series-0"),
					secondSeriesContainer: cardAreaEl.querySelector(".highcharts-series-1"),
				};
				return true;
			}
			return false;
		},
		preventDefault: ({ phase }) => phase !== "begin",
		stopPropagation: ({ phase }) => phase !== "begin",
		onDragMove(initial, current) {
			if (!draggingStateRef.current) {
				return;
			}
			switch (draggingStateRef.current.type) {
				case "selection": {
					const newSelectionRect = {
						x: Math.min(initial.x, current.x),
						y: Math.min(initial.y, current.y),
						width: Math.abs(current.x - initial.x),
						height: Math.abs(current.y - initial.y),
					};
					if (newSelectionRect.width === 0 || newSelectionRect.height === 0) {
						return;
					}
					const selectionHDirection = current.x - initial.x > 0 ? "right" : "left";
					setSelectionRect(newSelectionRect);
					let indicesInArea = Set<number>();
					for (let i = 0; i < props.data.length; i++) {
						const firstSeriesCandidate = draggingStateRef.current.firstSeriesContainer
							?.querySelector(`.${classPrefix}-column-index-${i}`)
							?.getBoundingClientRect();
						const secondSeriesCandidate = draggingStateRef.current.secondSeriesContainer
							?.querySelector(`.${classPrefix}-column-index-${i}`)
							?.getBoundingClientRect();
						for (const candidate of [firstSeriesCandidate, secondSeriesCandidate].filter(Boolean) as DOMRect[]) {
							if (intersect(domRectToRect2D(candidate), newSelectionRect)) {
								indicesInArea = indicesInArea.add(i);
							}
						}
					}
					const idsInArea = indicesInArea.map((i) => props.data[i].id);

					if (indicesInArea.size > 0) {
						shiftSelectionIdRef.current =
							selectionHDirection === "right"
								? props.data[minArrayLike(indicesInArea.toArray(), identity)].id
								: props.data[maxArrayLike(indicesInArea.toArray(), identity)].id;
					}

					const initialSelection =
						draggingStateRef.current.subType === "new" || !draggingStateRef.current.initialSelection
							? Set<string>()
							: draggingStateRef.current.initialSelection;
					draggingStateRef.current.currentSelection = initialSelection
						.subtract(idsInArea)
						.union(idsInArea.subtract(initialSelection));
					setSelectedIds(draggingStateRef.current.currentSelection);
					break;
				}
				case "threshold": {
					const displayedMin = Math.min(cachedAxisYLimits.min, 0);
					const displayedMax = Math.max(cachedAxisYLimits.max, 0);
					setThreshold(
						steppify(
							clamp(
								draggingStateRef.current.initialThreshold -
									((current.y - initial.y) / draggingStateRef.current.plotArea.height) * (displayedMax - displayedMin),
								displayedMin,
								displayedMax,
							),
							thresholdDragStep,
						),
					);
					break;
				}
			}
		},
		onDragEnd() {
			if (!draggingStateRef.current) {
				return;
			}
			switch (draggingStateRef.current.type) {
				case "selection": {
					if (
						!draggingStateRef.current.currentSelection ||
						!draggingStateRef.current.currentSelection.equals(draggingStateRef.current.initialSelection)
					) {
						props.onSelectionChange?.(draggingStateRef.current.currentSelection ?? Set());
					}
					setSelectionRect(null);
					break;
				}
				case "threshold": {
					if (threshold != null) {
						props.onThresholdChange?.(threshold);
					}
					break;
				}
			}
		},
	});

	const chartOptions = useMemo<Highcharts.Options>(
		() => ({
			chart: {
				type: "column",
				style: {
					fontFamily: "Gotham,sans-serif",
				},
				// animation: false,
			},
			title: { text: "" },
			legend: { enabled: false },
			xAxis: {
				labels: { enabled: false },
				tickWidth: 0,
			},
			tooltip: {
				enabled: !dragging,
				useHTML: true,
				padding: 0,
				borderWidth: 0,
				shared: true,
				shadow: false,
				formatter(this) {
					if (!this.points) {
						return "";
					}
					return renderToString(
						<div className="p-2 rounded bg-white min-w-[240px] shadow">
							<div className="py-1 mb-2 rounded bg-[#EFF0F3] text-center">
								<Text type="Body/M/Bold">{this.points[0].point.name}</Text>
							</div>
							{this.points[0] && (
								<>
									{this.points[0].point.y != null ? (
										<div className="flex justify-between mb-2">
											<div className="flex items-center gap-1">
												<ColoredRectangle variant="vertical" color={themeCSSVars.palette_S400} />
												<Text type="Body/M/Book">{props.mainMetricLabel}</Text>
											</div>
											<Text type="Body/M/Bold">
												{`${formatNumber(this.points[0].point.y, { minDecimalPlaces: 0, maxDecimalPlaces: 2 })}${unit}`}
											</Text>
										</div>
									) : (
										<div className="flex justify-between mb-2">
											<div className="flex items-center gap-1">
												<ColoredRectangle variant="vertical" color={themeCSSVars.palette_S400} />
												<Text type="Body/M/Book">{props.mainMetricLabel}</Text>
											</div>
											<Text type="Body/M/Bold">
												{`${formatNumber(this.points[0].point.y, { minDecimalPlaces: 0, maxDecimalPlaces: 2 })}${unit}`}
											</Text>
										</div>
									)}
								</>
							)}
							{this.points[1] && (
								<div className="flex justify-between">
									<div className="flex items-center gap-1">
										<ColoredRectangle variant="vertical" color={themeCSSVars.palette_B400} />
										<Text type="Body/M/Book">{props.secondaryMetricLabel}</Text>
									</div>
									<Text type="Body/M/Bold">
										{`${formatNumber(this.points[1].point.y, { minDecimalPlaces: 0, maxDecimalPlaces: 2 })}${unit}`}
									</Text>
								</div>
							)}
						</div>,
					);
				},
			},
			credits: { enabled: false },
			yAxis: {
				gridLineDashStyle: "Dash",
				max: cachedAxisYLimits.max,
				min: cachedAxisYLimits.min,
				title: { text: "" },
				labels: {
					formatter(ctx) {
						return `${formatNumber(ctx.value, { minDecimalPlaces: 0, maxDecimalPlaces: 2 })}${unit}`;
					},
				},
				plotLines:
					threshold == null
						? []
						: [
								{
									value: threshold,
									color: "rgba(135, 146, 171, 0.5)",
									zIndex: 10,
									label: {
										align: "right",
										useHTML: true,
										formatter() {
											return renderToString(
												<div
													id={thresholdBoxId}
													className="border-2 p-1 bg-white rounded flex items-center gap-1"
													style={{
														borderColor: themeCSSVars.palette_N200,
													}}
												>
													{/* TODO: missing icon from iconSet */}
													<svg
														width="16"
														height="16"
														viewBox="0 0 16 16"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M10.2227 2.16455C10.6494 2.16529 11.0337 2.42263 11.1968 2.81683C11.36 3.21103 11.27 3.66468 10.9687 3.96673L8.74557 6.19053C8.33329 6.60179 7.66594 6.60179 7.25367 6.19053L5.03092 3.96711C4.72962 3.66507 4.63923 3.21103 4.80239 2.81683C4.96556 2.42263 5.34985 2.16529 5.77649 2.16455L10.2227 2.16455ZM10.2608 3.26038C10.2768 3.2443 10.2815 3.22021 10.2729 3.19928C10.2642 3.17835 10.2438 3.16467 10.2212 3.16455H5.77802C5.75539 3.16467 5.73503 3.17835 5.72637 3.19928C5.7177 3.22022 5.72246 3.24432 5.73843 3.2604L7.95991 5.48255C7.98182 5.50427 8.01729 5.5044 8.0392 5.48268L10.2608 3.26038Z"
															fill="#697796"
														/>
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M2.16406 8.00011C2.16406 7.72396 2.38792 7.50011 2.66406 7.50011H13.3352C13.6113 7.50011 13.8352 7.72396 13.8352 8.00011C13.8352 8.27625 13.6113 8.50011 13.3352 8.50011H2.66406C2.38792 8.50011 2.16406 8.27625 2.16406 8.00011Z"
															fill="#697796"
														/>
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M7.95991 10.5177L5.73852 12.7397C5.72255 12.7558 5.7177 12.78 5.72637 12.8009C5.73503 12.8219 5.7554 12.8355 5.77804 12.8357H10.2212C10.2438 12.8355 10.2642 12.8219 10.2729 12.8009C10.2815 12.78 10.2768 12.7559 10.2608 12.7398L8.03933 10.5177C8.01741 10.4959 7.98182 10.4959 7.95991 10.5177ZM7.25367 9.80969C7.66594 9.39843 8.33329 9.39843 8.74557 9.80969L8.74606 9.81017L10.9683 12.0331C11.2696 12.3351 11.36 12.7892 11.1968 13.1834C11.0337 13.5776 10.6494 13.8349 10.2227 13.8357H5.77649C5.34985 13.8349 4.96556 13.5776 4.80239 13.1834C4.63923 12.7892 4.72924 12.3355 5.03054 12.0335L7.25367 9.80969Z"
															fill="#697796"
														/>
													</svg>

													{/* <Icon
														icon="News-category-Contraints"
														classList="rotate-90 block"
														color={themeCSSVars.palette_N500}
														size={16}
													/> */}
													{`${formatNumber(threshold, 2)}${unit}`}
												</div>,
											);
										},
									},
									width: 2,
									dashStyle: "Solid",
								},
						  ],
			},
			plotOptions: {
				column: {
					minPointLength: 0,
					pointPadding: 0.1,
					borderWidth: 0,
					groupPadding: 0.4,
					animation: { duration: 0 },

					shadow: false,
				},
			},
			series: [
				{
					states: {
						inactive: { enabled: false },
						select: {
							color: props.highlightSecondaryMetric ? themeCSSVars.palette_P100 : themeCSSVars.palette_P400,
						},
						hover: {
							color: props.highlightSecondaryMetric ? themeCSSVars.palette_S100 : themeCSSVars.palette_S400,
						},
					},
					name: props.mainMetricLabel,
					type: "column",
					color: props.highlightSecondaryMetric ? themeCSSVars.palette_S100 : themeCSSVars.palette_S300,
					data: props.data.map(
						({ name, metrics: [metricA, metricB], id }, i): Highcharts.PointOptionsObject => ({
							name,
							y: metricA,
							selected: selectedIds.has(id),
							pointWidth: metricB == null ? 22 : 16,
							className: `${classPrefix}-column ${classPrefix}-column-index-${i}`,
						}),
					),
					pointWidth: 16,
					events: {
						click(e) {
							let newSelection;
							const id = props.data[e.point.index].id;
							if ((e as any).shiftKey) {
								const refIndex = props.data.findIndex((point) => point.id === shiftSelectionIdRef.current);
								if (refIndex === -1) {
									newSelection = selectedIds.union([id]);
								} else {
									newSelection = Set(
										props.data
											.map((point) => point.id)
											.slice(Math.min(refIndex, e.point.index), Math.max(refIndex, e.point.index) + 1),
									);
								}
							} else if (selectedIds.includes(id)) {
								newSelection = selectedIds.remove(id);
							} else {
								newSelection = selectedIds.add(id);
								shiftSelectionIdRef.current = id;
							}
							setSelectedIds(newSelection);
							props.onSelectionChange?.(newSelection);
						},
					},
				},
				{
					states: {
						inactive: { enabled: false },
						select: {
							color: themeCSSVars.palette_P700,
						},
						hover: {
							color: themeCSSVars.palette_B500,
						},
					},
					name: props.secondaryMetricLabel,
					type: "column",
					color: themeCSSVars.palette_B400,
					data: props.data.map(({ name, metrics: [, metricB], id }, i) => ({
						name,
						selected: selectedIds.has(id),
						y: metricB,
						className: `${classPrefix}-column ${classPrefix}-column-index-${i}`,
					})),
					pointWidth: 6,
					events: {
						click(e) {
							let newSelection;
							const id = props.data[e.point.index].id;
							if ((e as any).shiftKey) {
								const refIndex = props.data.findIndex((point) => point.id === shiftSelectionIdRef.current);
								if (refIndex === -1) {
									newSelection = selectedIds.union([id]);
								} else {
									newSelection = Set(
										props.data
											.map((point) => point.id)
											.slice(Math.min(refIndex, e.point.index), Math.max(refIndex, e.point.index) + 1),
									);
								}
							} else if (selectedIds.includes(id)) {
								newSelection = selectedIds.remove(id);
							} else {
								newSelection = selectedIds.add(id);
								shiftSelectionIdRef.current = id;
							}
							setSelectedIds(newSelection);
							props.onSelectionChange?.(newSelection);
						},
					},
				},
			],
			navigator: {
				enabled: true,
				height: 30,
				series: {
					color: themeCSSVars.palette_S300,
					opacity: 0.7,
				},
				xAxis: {
					labels: {
						enabled: false,
					},
				},
				yAxis: {
					labels: {
						enabled: false,
					},
				},
			},
			exporting: {
				enabled: false,
			},
		}),
		[
			classPrefix,
			dragging,
			formatNumber,
			props,
			selectedIds,
			setSelectedIds,
			threshold,
			thresholdBoxId,
			unit,
			cachedAxisYLimits,
		],
	);

	return (
		<>
			{selectionRect && (
				<StackingContext.Consumer>
					{({ zIndex }) =>
						createPortal(
							<div
								className="fixed border bg-slate-500/50"
								style={{
									zIndex,
									left: selectionRect.x,
									top: selectionRect.y,
									width: selectionRect.width,
									height: selectionRect.height,
								}}
							/>,
							document.body,
						)
					}
				</StackingContext.Consumer>
			)}
			<Card
				id={chartId}
				classList={{
					"border rounded": true,
					"h-full": props.chartHeight === "full",
				}}
				style={{
					background: themeCSSVars.palette_N20,
					borderColor: themeCSSVars.palette_N100,
				}}
				title={
					<div className="flex items-center">
						<Text type="Body/M/Bold" color={themeCSSVars.palette_N500}>
							{`“${props.chartTitle}”`}
						</Text>
						<div className="w-4 mx-4 border-b border-b-black" />
						<div className="mr-4">
							<Text type="Body/M/Book" color={themeCSSVars.palette_N500} classList="mr-2">
								Min
							</Text>
							{!Number.isNaN(min) && Math.abs(min) !== Infinity && (
								<Text type="Body/M/Bold" color={themeCSSVars.palette_N500}>
									{formatNumber(min, { minDecimalPlaces: 0, maxDecimalPlaces: 2 })}
									{unit}
								</Text>
							)}
						</div>
						<div className="mr-4">
							<Text type="Body/M/Book" color={themeCSSVars.palette_N500} classList="mr-2">
								Max
							</Text>
							{!Number.isNaN(max) && Math.abs(max) !== Infinity && (
								<Text type="Body/M/Bold" color={themeCSSVars.palette_N500}>
									{formatNumber(max, { minDecimalPlaces: 0, maxDecimalPlaces: 2 })}
									{unit}
								</Text>
							)}
						</div>
						<div>
							<Text type="Body/M/Book" color={themeCSSVars.palette_N500} classList="mr-2">
								Average
							</Text>
							{!Number.isNaN(avg) && Math.abs(avg) !== Infinity && (
								<Text type="Body/M/Bold" color={themeCSSVars.palette_N500}>
									{formatNumber(avg, { minDecimalPlaces: 0, maxDecimalPlaces: 2 })}
									{unit}
								</Text>
							)}
						</div>
					</div>
				}
				actionHeader={renderNodeOrFn(props.actions)}
			>
				<HighchartsReact
					containerProps={{
						style: { height: getHeightInPx(props.chartHeight ?? "tall"), width: "100%" },
						className: "cursor-crosshair",
					}}
					highcharts={Highcharts}
					options={chartOptions}
					ref={props.innerRef}
				/>
			</Card>
		</>
	);
}

function getHeightInPx(chartHeight: NonNullable<MetricComparisonChartProps["chartHeight"]>): number | string {
	switch (chartHeight) {
		case "short":
			return 280;
		case "tall":
			return 480;
		case "full":
			return "100%";
		default:
			return chartHeight;
	}
}
function intersect(rect1: Vec2D & Rect2D, rect2: Vec2D & Rect2D) {
	return !(
		rect1.x + rect1.width < rect2.x ||
		rect1.y + rect1.height < rect2.y ||
		rect2.x + rect2.width < rect1.x ||
		rect2.y + rect2.height < rect1.y
	);
}

function domRectToRect2D(domRect: DOMRect): Vec2D & Rect2D {
	return {
		x: domRect.left,
		y: domRect.top,
		width: domRect.width,
		height: domRect.height,
	};
}
