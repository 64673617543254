import type {
	ExposureContributionRequestEntry,
	ExposureContributionRequestExposureContributionTypeEnum,
} from "$root/api/api-gen";
import { InvestmentsExposureCompareControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { ExposureSankeyLikeChart } from "$root/components/ExposureSankeyLikeChart/ExposureSankeyLikeChart";
import { IconWalls } from "$root/components/IconWall";
import { SideDrawer } from "$root/components/SideDrawer";
import type { InstrumentEditorBuilderProps } from "$root/functional-areas/instruments-editor/builder";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { typedObjectValues, useQueryNoRefetch } from "$root/utils";
import { Select } from "@mdotm/mdotui/components";
import { groupBy } from "@mdotm/mdotui/utils";
import { useMemo, useState } from "react";

type ExposureSankeyBlockProps = {
	expand: boolean;
	onExpandChange(expand: boolean): void;
	instrumentBuilder: InstrumentEditorBuilderProps;
};

function ExposureSankeyBlock({ expand, onExpandChange, instrumentBuilder }: ExposureSankeyBlockProps): JSX.Element {
	const [exposureCompareCategory, setExposureCompareCategory] =
		useState<ExposureContributionRequestExposureContributionTypeEnum>("MACRO_ASSET_CLASS");
	const investmentsExposureCompareApi = useApiGen(InvestmentsExposureCompareControllerApiFactory);
	const compositionMap = instrumentBuilder.watchComposition();
	const compositionMapWithouthDeleteInstruments = Array.from(
		compositionMap.removeAll(instrumentBuilder.getDeleted()).values(),
	);

	const exposureComposition = useMemo<Array<Required<ExposureContributionRequestEntry>>>(() => {
		return Object.values(
			compositionMapWithouthDeleteInstruments.reduce<Record<string, Required<ExposureContributionRequestEntry>>>(
				(acc, instrument) => {
					if (instrument.proxyOverwriteType === "PORTFOLIO_MIXED" && instrument.investment?.uuid) {
						return {
							...acc,
							[instrument.investment.uuid]: {
								composedWeight: instrument.weight ?? 0,
								entityUuid: instrument.investment.uuid,
							},
						};
					}

					const instruments = {
						entityUuid: defaultInstrumentExposureUuid,
						composedWeight: acc[defaultInstrumentExposureUuid]?.composedWeight ?? 0,
					} satisfies Required<ExposureContributionRequestEntry>;

					instruments.composedWeight += instrument.weight ?? 0;
					acc[defaultInstrumentExposureUuid] = instruments;
					return acc;
				},
				{},
			),
		);
	}, [compositionMapWithouthDeleteInstruments]);

	const investments = useMemo(
		() => exposureComposition.filter((x) => x.entityUuid !== defaultInstrumentExposureUuid),
		[exposureComposition],
	);

	const queryExposureSankeyData = useQueryNoRefetch(
		["queryExposureSankeyData", exposureCompareCategory, compositionMapWithouthDeleteInstruments],
		{
			enabled:
				expand && investments.some((x) => x.composedWeight > 0 && x.composedWeight <= 100) && investments.length > 0,
			queryFn: () =>
				axiosExtract(
					investmentsExposureCompareApi.getExposureContribution({
						exposureContributionType: exposureCompareCategory,
						entries: investments.filter((x) => x.composedWeight > 0),
						instrumentsWeight:
							exposureComposition.find((x) => x.entityUuid === "INSTRUMENT_WEIGHTS")?.composedWeight ?? 0,
					}),
				),
		},
	);

	const { data: exposureSankey } = queryExposureSankeyData;

	const sankeyData = useMemo(() => {
		if (!exposureSankey) {
			return undefined;
		}
		const { portfolioComposition } = exposureSankey;
		const groupedPortfolioByUuid = groupBy(portfolioComposition ?? [], (x) => x.entityUuid!);

		const investmentComposition = typedObjectValues(groupedPortfolioByUuid).flatMap((contributions) => {
			if (!contributions) {
				return [];
			}
			const entry = contributions[0];
			return [
				{
					label: entry.entityName!,
					name: entry.entityUuid!,
					weight: entry.composedWeight!,
					items: contributions.map((x) => ({
						quality: x.quality!,
						weight: x.weight ?? 0,
					})),
				},
			];
		});
		return investmentComposition;
	}, [exposureSankey]);

	return (
		<SideDrawer
			toggleIcon="Right"
			side="right"
			toggleOffset={120}
			toggleLabel="Exposure contribution"
			expand={expand}
			disabled={
				!expand &&
				(investments.every((x) => x.composedWeight < 0 && x.composedWeight > 100) || investments.length === 0)
			}
			onExpandChange={onExpandChange}
			widths={[smallTabWidth, extendedTabWidth]}
			title="Exposure Contribution"
		>
			<div className="h-full flex flex-col">
				<div className="my-2">
					<Select
						value={exposureCompareCategory}
						onChange={setExposureCompareCategory}
						options={exposureCompareOptions}
					/>
				</div>
				{queryExposureSankeyData.isFetching ? (
					<IconWalls.Loader />
				) : !sankeyData ? (
					<IconWalls.DataNotAvailable />
				) : (
					<ExposureSankeyLikeChart classList="flex-1" aggregateBy="quality" data={sankeyData} />
				)}
			</div>
		</SideDrawer>
	);
}

const extendedTabWidth = 464;
const smallTabWidth = 24;
/**
 * const used as uuid for tell be that this is an instrument for the getExposureContribution entry
 */
const defaultInstrumentExposureUuid = "INSTRUMENT_WEIGHTS";
const exposureCompareCategoryInfo: Record<ExposureContributionRequestExposureContributionTypeEnum, string> = {
	MACRO_ASSET_CLASS: "Macro Asset Class",
	MICRO_ASSET_CLASS: "Micro Asset Class",
	GEOGRAPHY: "Micro Geography",
	MACRO_GEOGRAPHY: "Macro Geography",
	CURRENCY: "Currency",
	TAG: "Tag",
};

const exposureCompareCategoriesToShow = [
	"MACRO_ASSET_CLASS",
	"MACRO_GEOGRAPHY",
	"CURRENCY",
	"TAG",
] satisfies Array<ExposureContributionRequestExposureContributionTypeEnum>;

const exposureCompareOptions = exposureCompareCategoriesToShow.map((category) => ({
	label: exposureCompareCategoryInfo[category],
	value: category,
}));

export default ExposureSankeyBlock;
