import type { DataAttributesProps, FloatingContentProps, IconName } from "@mdotm/mdotui/components";
import { FloatingContent, Row, TinyIconButton } from "@mdotm/mdotui/components";
import type { TrackableAsyncFn } from "@mdotm/mdotui/headless";
import { ForEach } from "@mdotm/mdotui/react-extensions";
import type { MouseEvent, MouseEventHandler, Ref } from "react";

type OverlayActionsOption = {
	icon: IconName;
	disabled?: boolean;
	iconColor?: string;
	innerRef?: Ref<HTMLButtonElement>;
} & (
	| ({ onClick?: MouseEventHandler<HTMLButtonElement>; onClickAsync?: undefined } & DataAttributesProps)
	| ({
			onClick?: undefined;
			onClickAsync?: TrackableAsyncFn<void, [MouseEvent]>;
			overrideLoading?: boolean;
	  } & DataAttributesProps)
);
type OverlayActionsProps = {
	isOpen: boolean;
	trigger: FloatingContentProps["trigger"];
	options: Array<OverlayActionsOption | null>;
} & Omit<FloatingContentProps, "open" | "trigger" | "children">;

function OverlayActions({ isOpen, options, trigger, ...rest }: OverlayActionsProps): JSX.Element {
	return (
		<FloatingContent
			position="left"
			align="middle"
			relativePositionOffsets={{ left: -8, right: 8 }}
			triggerLocation="behind"
			open={isOpen}
			trigger={trigger}
			{...rest}
		>
			<Row classList="shadow-[0px_8px_16px_rgba(42,48,60,0.12)] rounded bg-white flex-1 min-h-0 h-8">
				<ForEach collection={options}>
					{({ item }) =>
						item ? (
							item.onClickAsync ? (
								<TinyIconButton
									{...item}
									icon={item.icon}
									overrideLoading={item.overrideLoading}
									onClickAsync={item.onClickAsync}
									disabled={item.disabled}
									size={14}
									innerRef={item.innerRef}
								/>
							) : item.onClick ? (
								<TinyIconButton
									{...item}
									icon={item.icon}
									onClick={item.onClick}
									disabled={item.disabled}
									size={14}
									innerRef={item.innerRef}
								/>
							) : null
						) : null
					}
				</ForEach>
			</Row>
		</FloatingContent>
	);
}

export default OverlayActions;
