import { noop } from "@mdotm/mdotui/utils";
import type { MaybePromise } from "./types";
import { AbortError } from "@mdotm/mdotui/headless";

/**
 * Parallelize the execution of multiple Promises in a controlled way.
 *
 * Example:
 * ```ts
 * console.log(
 * 	await parallelize(
 * 		[
 * 			() => Promise.resolve(1),
 * 			() => sleep(10).then(() => Promise.resolve([2, 3])),
 * 			() => Promise.resolve(4),
 * 		],
 * 		{ concurrency: 2 },
 * 	)
 * ); // logs [1, [2, 3], 4]
 * ```
 *
 * @param fns an array of Promise-returning functions.
 * @param opts options that can be used to customize the concurrency.
 * @returns a Promise that will resolve to an array containing the results of every function.
 */
export async function parallelize<T extends Record<string, unknown> | [unknown, ...unknown[]] | unknown[]>(
	fns: { [K in keyof T]: () => MaybePromise<T[K]> } | Array<T[keyof T]>,
	opts?: { concurrency?: number },
): Promise<{ [K in keyof T]: T[K] }> {
	if (Object.keys(fns).length === 0) {
		if (Array.isArray(fns)) {
			return [] as unknown as { [K in keyof T]: T[K] };
		}
		return {} as { [K in keyof T]: T[K] };
	}
	const results = (Array.isArray(fns) ? new Array(fns.length) : {}) as { [K in keyof T]: T[K] };
	const fnsWithResultTracking = Object.entries(fns).map(([key, fn]) => async () => {
		const result = await fn();
		results[key as keyof T] = result;
	});
	if (!opts?.concurrency) {
		await Promise.all(fnsWithResultTracking.map((fn) => fn()));
	} else {
		const worker = async () => {
			while (fnsWithResultTracking.length > 0) {
				const fn = fnsWithResultTracking.shift()!;
				await fn();
			}
		};

		// spawn n (= opts.concurrency) workers
		await Promise.all(new Array(Math.min(fnsWithResultTracking.length, opts.concurrency)).fill(0).map(() => worker()));
	}
	return results;
}

/**
 * Parallelize the execution of multiple Promises in a controlled way using Promise.allSettled.
 *
 * Example:
 * ```ts
 * console.log(
 * 	await parallelizeWithAllSettled(
 * 		[
 * 			() => Promise.resolve(1),
 * 			() => sleep(10).then(() => Promise.resolve([2, 3])),
 * 			() => Promise.reject(new Error("Failed")),
 * 		],
 * 		{ concurrency: 2 },
 * 	)
 * );
 * // logs [ { status: "fulfilled", value: 1 }, { status: "fulfilled", value: [2, 3] }, { status: "rejected", reason: Error("Failed") } ]
 * ```
 *
 * @param fns an array of Promise-returning functions.
 * @param opts options that can be used to customize the concurrency.
 * @returns a Promise that will resolve to an array containing the results of every function in settled format.
 */
export async function parallelizeWithAllSettled<
	T extends Record<string, unknown> | [unknown, ...unknown[]] | unknown[],
>(
	fns: { [K in keyof T]: () => MaybePromise<T[K]> } | Array<T[keyof T]>,
	opts?: { concurrency?: number },
): Promise<{ [K in keyof T]: PromiseSettledResult<T[K]> }> {
	if (Object.keys(fns).length === 0) {
		if (Array.isArray(fns)) {
			return [] as unknown as { [K in keyof T]: PromiseSettledResult<T[K]> };
		}
		return {} as { [K in keyof T]: PromiseSettledResult<T[K]> };
	}

	const results = (Array.isArray(fns) ? new Array(fns.length) : {}) as { [K in keyof T]: PromiseSettledResult<T[K]> };
	const fnsWithResultTracking = Object.entries(fns).map(([key, fn]) => async () => {
		try {
			const value = await fn();
			results[key as keyof T] = { status: "fulfilled", value };
		} catch (reason) {
			results[key as keyof T] = { status: "rejected", reason };
		}
	});

	if (!opts?.concurrency) {
		await Promise.allSettled(fnsWithResultTracking.map((fn) => fn()));
	} else {
		const worker = async () => {
			while (fnsWithResultTracking.length > 0) {
				const fn = fnsWithResultTracking.shift()!;
				await fn();
			}
		};

		// spawn n (= opts.concurrency) workers
		await Promise.allSettled(
			new Array(Math.min(fnsWithResultTracking.length, opts.concurrency)).fill(0).map(() => worker()),
		);
	}

	return results;
}

/**
 * Parallelize the execution of multiple Promises in a controlled way.
 *
 * Example:
 * ```ts
 * console.log(
 * 	structuredParallelize(
 * 		[
 * 			() => Promise.resolve(1),
 * 			() => sleep(10).then(() => Promise.resolve([2, 3])),
 * 			() => Promise.resolve(4),
 * 		],
 * 		{ concurrency: 2 },
 * 	)
 * ); // logs [Promise, Promise, Promise]
 * ```
 *
 * @param fns an array of Promise-returning functions.
 * @param opts options that can be used to customize the concurrency.
 * @returns a Promise that will resolve to an array containing the results of every function.
 */
export function structuredParallelize<T extends Record<string, unknown> | [unknown, ...unknown[]] | unknown[]>(
	fns: { [K in keyof T]: () => MaybePromise<T[K]> } | Array<T[keyof T]>,
	opts?: { concurrency?: number },
): { [K in keyof T]: Promise<T[K]> } {
	if (Object.keys(fns).length === 0) {
		if (Array.isArray(fns)) {
			return [] as unknown as { [K in keyof T]: Promise<T[K]> };
		}
		return {} as { [K in keyof T]: Promise<T[K]> };
	}
	const results = (Array.isArray(fns) ? new Array(fns.length) : {}) as { [K in keyof T]: Promise<T[K]> };
	const promiseCbs = (Array.isArray(fns) ? new Array(fns.length) : {}) as {
		[K in keyof T]: { res(v: T[K]): void; rej(err?: unknown): void };
	};
	for (const key of Object.keys(fns)) {
		results[key as keyof T] = new Promise((res, rej) => {
			promiseCbs[key as keyof T] = { res, rej };
		});
	}
	const fnsWithResultTracking = Object.entries(fns).map(
		([key, fn]: [string, () => MaybePromise<T[keyof T]>]) =>
			async () => {
				try {
					const result = await fn();
					promiseCbs[key as keyof T].res(result);
				} catch (err) {
					promiseCbs[key as keyof T].rej(err);
				}
			},
	);
	if (!opts?.concurrency) {
		Promise.all(fnsWithResultTracking.map((fn) => fn())).catch(noop);
	} else {
		const worker = async () => {
			while (fnsWithResultTracking.length > 0) {
				const fn = fnsWithResultTracking.shift()!;
				await fn();
			}
		};

		// spawn n (= opts.concurrency) workers
		Promise.all(new Array(Math.min(fnsWithResultTracking.length, opts.concurrency)).fill(0).map(() => worker())).catch(
			noop,
		);
	}
	return results;
}

/**
 * Make a promise-returning function restartable.
 *
 * The returned function will look like the original one, but any call to it
 * will firstly abort any pending promises related to previous calls and then call
 * the original function.
 *
 * Example
 *
 * This following code presents a race condition: if
 * a user clicks on the button while the previous fetch is
 * still running, likesCount will be undetermined, as it will
 * contain the value of the promise that resolved last, not of
 * the promise that was called last.
 * ```tsx
 * const fetchLikesCount = async () => fetch(...);
 * // ...
 * <div>
 * 	<Button onClick={async () => {
 * 		setLikesCount(await fetchLikesCount());
 * 	}}>
 * 		Refresh
 * 	</Button>
 * 	{likesCount}
 * </div>
 * ```
 * The above example could be rewritten as follows to prevent the race condition:
 * ```tsx
 * const fetchLikesCount = makeRestartable(async (_, { signal }) => fetch(..., { signal }));
 * // ...
 * <div>
 * 	<Button onClick={async () => {
 * 		setLikesCount(await fetchLikesCount());
 * 	}}>
 * 		Refresh
 * 	</Button>
 * 	{likesCount}
 * </div>
 * ```
 * In this case, pending `fetch`es will receive an abort signal before a new fetch is
 * called to get the latest likesCount.
 *
 *
 * @param fn A promise-returning function that takes two arguments, the first
 * is any custom argument, the second is an object containing an abort signal
 * for cancellation.
 * @param opts.abortErrorProvider A function that provides the abort error that should
 * be passed when calling the `.abort(...)` method on the internally-created AbortController.
 */
export function makeRestartable<TReturn, TParams extends any[] = []>(
	fn: (...params: [...TParams, { signal: AbortSignal }]) => Promise<TReturn>,
	opts?: { abortErrorProvider?: () => Error },
): (...params: TParams) => Promise<TReturn> {
	let abortController: null | AbortController = null;
	return (...params: TParams): Promise<TReturn> => {
		abortController?.abort(opts?.abortErrorProvider?.() ?? new AbortError());
		// abort controllers are idempotent, they are only valid for at most one `.abort(...)` call, therefore we need
		// a new instance each time.
		abortController = new AbortController();
		return fn(...params, { signal: abortController.signal });
	};
}

export function promisesSettleResponse<T>(data: PromiseSettledResult<T>): {
	value?: PromiseFulfilledResult<T>["value"];
	reason?: PromiseRejectedResult["reason"];
} {
	return data.status === "fulfilled" ? { value: data.value } : { reason: data.reason };
}

export async function extractAsync<T, K extends keyof T>(promise: Promise<T>, key: K): Promise<T[K]> {
	const result = await promise;
	return result[key];
}

export type MaybeAsync<T, TParams extends any[] = []> = (...params: TParams) => T | Promise<T>;

export type Abortable = {
	signal?: AbortSignal;
};
