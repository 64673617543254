import type { CommentaryDto, TemplateModel, TemplateType } from "$root/api/api-gen";
import {
	BackofficeControllerApiFactory,
	CommentariesControllerApiFactory,
	InvestmentControllerV4ApiFactory,
	MarketUniverseControllerV3ApiFactory,
	TemplateControllerApiFactory,
} from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import AuthorizationGuard from "$root/components/AuthorizationGuard";
import { useCrumbs } from "$root/components/Crumbs/useCrumbs";
import type { OptionTreeNode } from "$root/components/HierarchicalMultiSelect";
import HierarchicalMultiSelect, { withPlaceholder } from "$root/components/HierarchicalMultiSelect";
import { PageHeader } from "$root/components/PageHeader";
import type { Option } from "$root/components/shared";
import { getOutlookComposition } from "$root/hooks/useOutlookComposition";
import useTypeToLabel from "$root/hooks/useTypeToLabel";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { FormController } from "$root/third-party-integrations/react-hook-form";
import { builtInCaseInsensitiveSortFor, replaceSpaceWith_, useQueryNoRefetch } from "$root/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	AsyncButton,
	Button,
	Dialog,
	DialogFooter,
	Form,
	FormField,
	Icon,
	ProgressBar,
	Select,
	Tab,
	TabGroup,
	TextArea,
} from "@mdotm/mdotui/components";
import { toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { groupBy, unpromisify } from "@mdotm/mdotui/utils";
import type { SetStateAction } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

// type TemplatePromptTypes = "DASHBOARD" | "PORTFOLIO_READY" | "PORTFOLIO_ENHANCE" | "OUTLOOK_FOCUS";
const CopyToClipboardButton = ({ text }: { text: string }) => {
	const [isCopied, setIsCopied] = useState(false);
	const copyToClipboard = useCallback(async (text: string) => {
		try {
			await navigator.clipboard.writeText(text);
			setIsCopied(true);
			setTimeout(() => {
				setIsCopied(false);
			}, 1000);
		} catch (e) {
			console.log(e);
		}
	}, []);

	return (
		<button
			onClick={unpromisify(() => copyToClipboard(text))}
			disabled={isCopied}
			type="button"
			className="active:scale-90 transition-transform"
		>
			<Icon icon="Content-Copy" size={24} color={isCopied ? themeCSSVars.palette_N200 : themeCSSVars.palette_P400} />
		</button>
	);
};

const TryModal = ({ commArea, GPTPrompt }: { commArea: TemplateType; GPTPrompt: string }): JSX.Element => {
	const investmentApi = useApiGen(InvestmentControllerV4ApiFactory);
	const universeApi = useApiGen(MarketUniverseControllerV3ApiFactory);
	const backofficeApi = useApiGen(BackofficeControllerApiFactory);
	const { t } = useTranslation();
	const { getLabel } = useTypeToLabel();
	const needPortfolioList =
		commArea === "PORTFOLIO_READY" ||
		commArea === "PORTFOLIO_ENHANCE" ||
		commArea === "FIRST_PROMPT" ||
		commArea === "SECOND_PROMPT";
	const needOptionTree = commArea === "OUTLOOK_FOCUS";
	const [open, setOpen] = useState(false);
	const [testPrompt, setTestPrompt] = useState("");

	const { data: portfolioList } = useQueryNoRefetch(["queryPortfoliosStudio", needPortfolioList, commArea], {
		enabled: Boolean(needPortfolioList),
		queryFn: async () => {
			const investments = await axiosExtract(investmentApi.getProjectedInvestmentList());
			const filteredData = investments.filter(
				(d) =>
					(commArea === "PORTFOLIO_ENHANCE" && d.status === "PROPOSAL_READY") ||
					((commArea === "PORTFOLIO_READY" || commArea === "FIRST_PROMPT" || commArea === "SECOND_PROMPT") &&
						(d.status === "ACCEPTED" || d.status === "READY")),
			);
			return filteredData;
		},
	});

	const { control, handleSubmit } = useForm({
		defaultValues: {
			portfolioUuid: undefined,
			multiFilter: {
				FAssetClass: undefined,
				FGeography: undefined,
				FSector: undefined,
			},
		},

		resolver: zodResolver(
			z.object({
				portfolioUuid: z.string().optional(),
				multiFilter: z
					.object({
						FAssetClass: z.string().optional(),
						FGeography: z.string().optional(),
						FSector: z.string().optional(),
					})
					.optional(),
			}),
		),
	});

	const optionTree = useQueryNoRefetch(["assetClassOptions", needOptionTree, commArea], {
		enabled: Boolean(needOptionTree),
		queryFn: async () => {
			const { data: allAssets } = await universeApi.universeControllerGetAsset();
			const sortByLabel = builtInCaseInsensitiveSortFor("label");

			const assetTypeMap = {
				Equity: "equity",
				"Fixed income": "fixedIncome",
				Commodities: "commodities",
			} as const;

			const {
				equity = [],
				fixedIncome = [],
				commodities = [],
			} = groupBy(allAssets, (asset) => assetTypeMap[asset.type as keyof typeof assetTypeMap]);

			const equityAreaOptions: Option<string>[] = equity
				.map((el) => ({
					value: replaceSpaceWith_(el.label!).toUpperCase(),
					label: getLabel(`EQ_${el.label}`) as string,
				}))
				.sort(sortByLabel);

			const equitySectorOptionsByArea = equity.reduce(
				(byArea, area) => {
					byArea[area.label!] = !area?.sector
						? []
						: [
								{
									value: "ALL_SECTORS",
									label: t("ASSET_CLASS_LABEL.ALL_SECTORS") as string,
								},
								...(area.sector ?? [])
									.map((el) => ({
										value: el.replace(" ", "_").toUpperCase(),
										label: t(
											// eslint-disable-next-line @typescript-eslint/ban-ts-comment
											// @ts-ignore
											`EQ_SECTOR.${el.replace(" ", "_").toUpperCase()}`,
											// eslint-disable-next-line @typescript-eslint/ban-ts-comment
											// @ts-ignore
											t(`EQ_SECTOR.${el.replace(" ", "").toUpperCase()}`),
										) as string,
									}))
									.sort(sortByLabel),
						  ];
					return byArea;
				},
				{} as Record<string, Option<string>[]>,
			);

			const commoditiesAreaOptions: Option<string>[] = commodities
				.map((el) => ({
					value: replaceSpaceWith_(el.label!).toUpperCase(),
					label: el.label!,
				}))
				.sort(sortByLabel);

			const commoditiesSectorOptionsByArea = commodities.reduce(
				(byArea, area) => {
					byArea[area.label!] = !area?.sector
						? []
						: [
								{
									value: "ALL_SECTORS",
									label: t("ASSET_CLASS_LABEL.ALL_SECTORS") as string,
								},
						  ];
					return byArea;
				},
				{} as Record<string, Option<string>[]>,
			);

			// temporary map assuming label are mapped like this
			const fixedIncomeGeo: Record<string, string> = t("FI_GEO", { returnObjects: true });
			const fixedIncomeAreaOptions: Option<string>[] = Array.from(
				new Set(fixedIncome.map((el) => el.label!.toString().slice(0, 3).replace(" ", "").toUpperCase())),
			)
				.map((el) => ({
					value: el,
					label: fixedIncomeGeo[el] ?? el,
				}))
				.sort(sortByLabel);
			// temporary map assuming label are mapped like this
			// const fixedIncomeSectors: Record<string, string> = t("FI_BOND", { returnObjects: true });
			const fixedIncomeSectorOptionsByArea = fixedIncome.reduce(
				(byArea, areaAndSector) => {
					const area = areaAndSector.label!.toString().slice(0, 3).replace(" ", "").toUpperCase();
					byArea[area] = byArea[area] || [];
					byArea[area].push({
						value: replaceSpaceWith_(areaAndSector.label!.replace(`${area} `, "")).toUpperCase(),
						label: areaAndSector.label!.replace(`${area} `, ""),
					});
					return byArea;
				},
				{} as Record<string, Option<string>[]>,
			);
			for (const k in fixedIncomeSectorOptionsByArea) {
				fixedIncomeSectorOptionsByArea[k].sort(sortByLabel);
			}

			const result: OptionTreeNode = withPlaceholder(
				t("OUTLOOK.SELECT_ASSET_CLASS"),
				[
					{
						value: "EQ",
						label: t("EQUITY"),
						subOptions: withPlaceholder(
							t("OUTLOOK.SELECT_GEOGRAPHY"),
							equityAreaOptions.map((areaOption) => ({
								...areaOption,
								subOptions: equitySectorOptionsByArea[areaOption.value],
							})),
						),
					},
					{
						value: "FI",
						label: t("OUTLOOK.FI"),
						subOptions: withPlaceholder(
							t("OUTLOOK.SELECT_GEOGRAPHY"),
							fixedIncomeAreaOptions.map((areaOption) => ({
								...areaOption,
								subOptions: fixedIncomeSectorOptionsByArea[areaOption.value],
							})),
						),
					},
					{
						value: "CO",
						label: t("OUTLOOK.CO"),
						subOptions: withPlaceholder(
							t("OUTLOOK.SELECT_TYPE"),
							commoditiesAreaOptions.map((areaOption) => ({
								...areaOption,
								subOptions: commoditiesSectorOptionsByArea[areaOption.value],
							})),
						),
					},
				].sort(sortByLabel),
			);
			return result;
		},
	});
	const onSubmit = useCallback(
		async ({ portfolioUuid, multiFilter: { FAssetClass, FGeography, FSector } }: any /* TODO: better types */) => {
			try {
				let result = undefined;
				switch (commArea) {
					case "DASHBOARD":
						result = await backofficeApi.createCommentaryForDashboardFromPrompt({ content: GPTPrompt });
						break;
					case "OUTLOOK_FOCUS": {
						const { assetClass, geography, sector } = getOutlookComposition(FAssetClass, FGeography, FSector);
						result = await backofficeApi.createCommentaryForAssetClassFromPrompt(assetClass, geography, sector, {
							content: GPTPrompt,
						});
						break;
					}
					case "PORTFOLIO_READY":
						// result = await backofficeApi.createCommentaryForInvestmentByPrompt(portfolioUuid, {
						// 	content: GPTPrompt,
						// });
						alert("Missing api implementation");
						break;
					case "FIRST_PROMPT":
						// result = await backofficeApi.createCommentaryForInvestmentByPrompt(portfolioUuid, {
						// 	content: GPTPrompt,
						// });
						alert("Missing api implementation");
						break;
					case "SECOND_PROMPT":
						// result = await backofficeApi.createCommentaryForInvestmentByPrompt(portfolioUuid, {
						// 	content: GPTPrompt,
						// });
						alert("Missing api implementation");
						break;
					case "PORTFOLIO_ENHANCE":
						// result = await backofficeApi.createCommentaryForInvestmentEnhancedByPrompt(portfolioUuid, {
						// 	content: GPTPrompt,
						// });
						alert("Missing api implementation");
						break;
					default:
						result = undefined;
						break;
				}
				console.log("send Try", GPTPrompt);
				setTestPrompt(result?.data ?? "");
			} catch (error) {
				reportPlatformError(error, "ERROR", "advanced-settings", { message: `Unable to generate GPT Commentary` });
				throw error;
			}
		},
		[GPTPrompt, backofficeApi, commArea],
	);

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<Dialog
				size="xxlarge"
				header="Try GPTPrompt"
				show={open}
				onClose={handleClose}
				footer={
					<DialogFooter
						primaryAction={
							<AsyncButton onClickAsync={() => handleSubmit(onSubmit)()} palette="primary">
								Generate Response
							</AsyncButton>
						}
					/>
				}
			>
				<div className="max-h-[500px] overflow-y-scroll">
					<p className="mb-4">{`Click "Generate Preview" to view a preview of your prompt and the response generated by ChatGPT.`}</p>
					{(commArea === "PORTFOLIO_ENHANCE" ||
						commArea === "PORTFOLIO_READY" ||
						commArea === "FIRST_PROMPT" ||
						commArea === "SECOND_PROMPT") && (
						<div className="mb-4 w-[250px]">
							<FormField label="Selected Portfolio">
								{({ id, invalid }) => (
									<FormController
										control={control}
										name="portfolioUuid"
										render={({ field: { ref: _ref, ...others } }) => (
											<Select
												id={id}
												options={(portfolioList ?? []).map(({ name, uuid }) => ({ label: name ?? "-", value: uuid }))}
												{...others}
											/>
										)}
									/>
								)}
							</FormField>
						</div>
					)}

					{commArea === "OUTLOOK_FOCUS" && (
						<div className="mb-4">
							{optionTree.isError ? (
								t("SOMETHING_WENT_WRONG")
							) : optionTree.isLoading ? (
								<ProgressBar value="indeterminate" />
							) : (
								optionTree.data && (
									<div className="mb-4">
										<h2 className="font-semibold text-lg">Filters</h2>
										<FormField label="Selected Market">
											{({ id, invalid }) => (
												<FormController
													control={control}
													name="multiFilter"
													render={({ field: { ref: _ref, value, onChange, ...others } }) => (
														<HierarchicalMultiSelect
															selection={[value.FAssetClass, value.FGeography, value.FSector]}
															optionTree={optionTree.data}
															className="w-fit"
															onChange={([FAssetClass, FGeography, FSector]) => {
																onChange({
																	FAssetClass,
																	FGeography,
																	FSector,
																});
															}}
															{...others}
														/>
													)}
												/>
											)}
										</FormField>
									</div>
								)
							)}
						</div>
					)}
					{Boolean(testPrompt) && (
						<div className="mb-2">
							<h2 className="font-bold text-base">Sent Prompt and Response</h2>
							{testPrompt.split("---").map((commentary, i) => (
								<div key={i} className="p-4">
									<div className="flex mb-2 justify-end">
										<CopyToClipboardButton text={commentary} />
									</div>
									<TextArea value={commentary} disabled rows={30} classList="[&>textarea]:overflow-y-scroll" />
								</div>
							))}
						</div>
					)}
				</div>
			</Dialog>
			<Button onClick={() => setOpen(true)} palette="secondary">
				Try
			</Button>
		</>
	);
};

const GPTPromptForm = <T,>({
	promptList,
	commArea,
	refetch,
}: {
	promptList: TemplateModel[];
	commArea: TemplateType;
	refetch: () => Promise<T>;
}): JSX.Element => {
	const [selectedPrompt, setSelectedPrompt] = useState(0);
	const promptApi = useApiGen(TemplateControllerApiFactory);

	const { control, formState, handleSubmit, reset, watch } = useForm({
		defaultValues: useMemo(() => {
			return (promptList ?? [])[selectedPrompt];
		}, [promptList, selectedPrompt]),
		resolver: zodResolver(
			z.object({
				content: z.string(),
			}),
		),
	});

	console.log(promptList, selectedPrompt);

	const onSubmit = useCallback(
		async (data: TemplateModel) => {
			try {
				if (!data.content) {
					return;
				}
				await promptApi.createNewVersionOfTemplate(commArea, { content: data.content });
				refetch();
			} catch (error) {
				reportPlatformError(error, "ERROR", "advanced-settings", { message: `Unable to load template commentary` });
			}
		},
		[commArea, promptApi, refetch],
	);

	useEffect(() => {
		reset((promptList ?? [])[selectedPrompt]);
	}, [promptList, reset, selectedPrompt]);

	return (
		<Form noValidate onSubmitAsync={() => handleSubmit(onSubmit)()}>
			<div className="relative flex min-h-[calc(100vh-280px)]">
				<div className="bg-white rounded flex basis-3/4 flex-col shadow-xl ring-1 ring-gray-900/5">
					<div className="no-scrollbar my-2 flex-grow overflow-y-scroll">
						<div className="h-0">
							<div className="mb-2 px-4 py-2">
								<h2 className="font-semibold mb-4 text-lg">ChatGPT Prompt</h2>
								<FormField label="ChatGPT Prompt">
									{({ id, invalid }) => (
										<FormController
											control={control}
											name="content"
											render={({ field: { ref: _ref, ...others } }) => (
												<TextArea
													classList="[&>textarea]:rounded-b-none [&>textarea]:resize-vetical [&>textarea]:overflow-y-scroll [&>textarea]:max-h-[800px]"
													id={id}
													rows={14}
													placeholder="Remember, be nice! but not too much"
													{...others}
												/>
											)}
										/>
									)}
								</FormField>
							</div>
						</div>
					</div>
					<div className="flex justify-end gap-4 p-2 ring-1 ring-gray-900/5 rounded-b -mt-1">
						<TryModal commArea={commArea} GPTPrompt={watch("content") ?? ""} />
						<Button type="submit" palette="primary">
							Save
						</Button>
					</div>
				</div>
				<div className="ml-2 bg-white rounded flex basis-1/4 flex-col shadow-xl">
					<div className="rounded z-10 flex h-[60px] flex-col justify-center align-middle shadow-xl ring-1 ring-gray-900/5">
						<div className="px-4 py-2 text-lg font-semibold">Prompt Versions</div>
					</div>
					<div className="no-scrollbar my-2 flex-grow overflow-y-scroll">
						<div className="h-0 flex flex-col">
							{(promptList ?? []).map((promptData, index) => (
								<button
									type="button"
									key={promptData._id}
									className="my-2 mx-4 rounded shadow-md px-4 py-2 ring-1 ring-gray-900/5"
									onClick={(_e) => setSelectedPrompt(index)}
								>
									<div>
										<div className="flex justify-start">
											{promptData.type}
											{promptData.active && (
												<Icon classList="ml-1" icon="Icon-full-ok" color="currentColor" size="1rem" />
											)}
										</div>
										<div className="flex justify-start">
											{promptData._id} - {String(promptData.creation_time)}
										</div>
									</div>
								</button>
							))}
						</div>
					</div>
				</div>
			</div>
		</Form>
	);
};

const MCPromptForm = <T,>({
	commentary,
	refetch,
}: {
	commentary: CommentaryDto;
	refetch: () => Promise<T>;
}): JSX.Element => {
	const commentaryApi = useApiGen(CommentariesControllerApiFactory);

	const { control, formState, handleSubmit, reset, watch } = useForm({
		defaultValues: commentary,
		resolver: zodResolver(
			z.object({
				text: z.string(),
			}),
		),
	});

	const onSubmit = useCallback(
		async (data: CommentaryDto) => {
			try {
				if (!data.text) {
					return;
				}
				await commentaryApi.setCommentaryMacroContext({ content: data.text });
				refetch();
			} catch (error) {
				reportPlatformError(error, "ERROR", "advanced-settings", {
					message: `Unable to set macro context of GPT Commentary`,
				});
			}
		},
		[commentaryApi, refetch],
	);

	useEffect(() => {
		reset(commentary);
	}, [commentary, reset]);

	return (
		<Form noValidate onSubmitAsync={() => handleSubmit(onSubmit)()}>
			<div className="relative flex min-h-[calc(100vh-280px)]">
				<div className="bg-white rounded flex basis-full flex-col shadow-xl ring-1 ring-gray-900/5">
					<div className="no-scrollbar my-2 flex-grow overflow-y-scroll">
						<div className="h-0">
							<div className="mb-2 px-4 py-2">
								<h2 className="font-semibold mb-4 text-lg">Mission Control Prompt</h2>
								<FormField label="Mission Control Prompt">
									{({ id, invalid }) => (
										<FormController
											control={control}
											name="text"
											render={({ field: { ref: _ref, ...others } }) => (
												<TextArea
													classList="[&>textarea]:rounded-b-none [&>textarea]:resize-vertical [&>textarea]:overflow-y-scroll [&>textarea]:max-h-[800px]"
													id={id}
													rows={14}
													placeholder="Remember, be nice! but not too much"
													{...others}
												/>
											)}
										/>
									)}
								</FormField>
							</div>
						</div>
					</div>
					<div className="flex justify-end gap-4 p-2 ring-1 ring-gray-900/5 rounded-b -mt-1">
						<Button type="submit" palette="primary">
							Save
						</Button>
					</div>
				</div>
			</div>
		</Form>
	);
};

const CommentaryPrompt = (): JSX.Element => {
	const [isPageMounted, setIsPageMounted] = useState(false);
	const [startAnimation, setStartAnimation] = useState(false);
	const [startContentAnimation, setStartContentAnimation] = useState(false);
	const { t } = useTranslation();
	const promptApi = useApiGen(TemplateControllerApiFactory);
	const commentaryApi = useApiGen(CommentariesControllerApiFactory);

	const [selectedTab, setSelectedTab] = useState(0);
	const commAreaOptions: Option<TemplateType>[] = [
		{ label: "Dashboard", value: "DASHBOARD" },
		{ label: "Portfolio(ready)", value: "PORTFOLIO_READY" },
		{ label: "Portfolio(proposal)", value: "PORTFOLIO_ENHANCE" },
		{ label: "Outlook focus", value: "OUTLOOK_FOCUS" },
		{ label: "First prompt", value: "FIRST_PROMPT" },
		{ label: "Second prompt", value: "SECOND_PROMPT" },
	];
	const [commArea, setCommArea] = useState<TemplateType>("DASHBOARD");

	const hasMCSection = useMemo(() => {
		return ["DASHBOARD"].includes(commArea);
	}, [commArea]);

	const {
		data: promptData,
		refetch,
		isLoading,
		isError,
	} = useQueryNoRefetch(["getScenarioPromptList", commArea, hasMCSection], {
		enabled: true,
		queryFn: async () => {
			const [TemplatesHistory, MCCommentary] = await Promise.all([
				promptApi.retrieveHistoryOfTemplate(commArea),
				commentaryApi.getCommentary3(),
			]);
			const TData = {
				newPromptList: TemplatesHistory.data.reverse(),
				MCCommentary: MCCommentary.data,
			};

			return TData;
		},
		onError: (err) => {
			console.error(err);
		},
	});

	const animatedBanner = useRef<HTMLDivElement>(null);

	animatedBanner.current?.addEventListener("animationend", () => {
		setTimeout(() => setIsPageMounted(true), 1000);
	});
	//WORST PRACTIS FOR ANIMATION I'VE EVER SEEN
	// should replace this with a react animation library such as: react-spring , framer motion
	useEffect(() => {
		let idTimeout2: ReturnType<typeof setTimeout> | undefined;
		let idTimeout3: ReturnType<typeof setTimeout> | undefined;

		if (animatedBanner.current) {
			idTimeout2 = setTimeout(() => setStartAnimation(true), 2000);
			animatedBanner.current?.addEventListener("transitionend", () => {
				setIsPageMounted(true);
				idTimeout3 = setTimeout(() => setStartContentAnimation(true), 1100);
			});
		}

		return () => {
			clearTimeout(idTimeout2);
			clearTimeout(idTimeout3);
		};
	}, []);

	useEffect(() => {
		setSelectedTab(0);
	}, [commArea]);

	const crumbs = useCrumbs();

	return (
		<>
			<PageHeader
				title="Commentary Prompt" // TODO: translate
				crumbsV2={crumbs.adminPanel("ChatGPT Prompt")}
			/>
			<AuthorizationGuard requiredRole="ROOT" placeholder={t("NOT_AUTHORIZED")}>
				{() => (
					<>
						<div className="relative">
							<div
								aria-hidden={startAnimation === true}
								className="h-full bg-white -mx-[0.9375rem] -mt-[0.9375rem] aria-hidden:transition-all aria-hidden:delay-1000 aria-hidden:duration-1000 aria-hidden:h-0 overflow-hidden absolute inset-x-0 z-10"
								ref={animatedBanner}
							>
								<div className="flex justify-center items-center h-[100dvh]">
									<div className="p-4 flex items-end">
										<h2
											aria-hidden={startAnimation === false}
											className={toClassName({
												"font-semibold text-2xl": true,
												"aria-hidden:opacity-0 aria-hidden:scale-50 scale-100 opacity-100 transition-all": true,
											})}
											style={{ color: themeCSSVars.global_palette_primary_400 }}
										>
											MDOTM
										</h2>
										<h2
											aria-hidden={startAnimation === false}
											className={toClassName({
												"font-semibold text-2xl": true,
												"aria-hidden:opacity-0 aria-hidden:scale-50 scale-100 opacity-100 transition-all delay-700 duration-500":
													true,
											})}
										>
											x
										</h2>
										<h2
											aria-hidden={startAnimation === false}
											className={toClassName({
												"font-semibold text-2xl": true,
												"aria-hidden:opacity-0  aria-hidden:-translate-x-full translate-x-0 opacity-100 transition-all delay-500 duration-500":
													true,
											})}
											style={{ color: "#FFA41B" }}
										>
											ChatGPT
										</h2>
									</div>
								</div>
							</div>
							<div
								aria-hidden={isPageMounted && startContentAnimation}
								className="aria-hidden:opacity-100 opacity-0 aria-hidden:transition-opacity aria-hidden:duration-200 aria-hidden:delay-100"
							>
								<div className="p-4 rounded bg-white mb-4">
									<h2 className="font-semibold text-lg">Commentary selection</h2>
									<div className="flex gap-4 mt-3">
										<h3 className="w-[60px] font-semibold text-md flex flex-col justify-center align-middle">Area</h3>
										<Select
											options={commAreaOptions}
											value={commArea}
											onChange={(val: SetStateAction<TemplateType>) => setCommArea(val)}
										/>
									</div>
								</div>
								{!isError && !isLoading ? (
									<div className="mt-2 w-full">
										<TabGroup palette="primary" key={commArea} tabIndex={selectedTab} onTabChange={setSelectedTab}>
											<Tab title="ChatGPT Prompt">
												<GPTPromptForm promptList={promptData.newPromptList} refetch={refetch} commArea={commArea} />
											</Tab>
											{hasMCSection && (
												<Tab title="MC Section">
													<MCPromptForm commentary={promptData.MCCommentary} refetch={refetch} />
												</Tab>
											)}
										</TabGroup>
									</div>
								) : (
									<></>
								)}
							</div>
						</div>
					</>
				)}
			</AuthorizationGuard>
		</>
	);
};

export default CommentaryPrompt;
