import { createAtom } from "$root/third-party-integrations/zustand";
import type { Updater } from "@mdotm/mdotui/utils";

export type CustomLabels = Record<string, string>;
export interface ICLabels {
	customLabels: CustomLabels;
}

export const systemDefaults: ICLabels = {
	customLabels: {},
};

export const useCustomLabelsStore = createAtom<ICLabels>(systemDefaults, {});

export function useCustomLabelsValue(): ICLabels {
	return useCustomLabelsStore((x) => x.value);
}

export function useCustomLabelsState(): {
	customLabelStatus: ICLabels;
	setCustomLabelsStatus: (newValue: ICLabels | Updater<ICLabels>) => void;
} {
	const { value: customLabelStatus, set: setCustomLabelsStatus } = useCustomLabelsStore();
	return { customLabelStatus, setCustomLabelsStatus };
}
