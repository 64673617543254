import env from "$root/env";
import { createAtom } from "$root/third-party-integrations/zustand";
import type { Updater } from "@mdotm/mdotui/utils";
import { initServerEvents } from "$root/event-bus/server-events";

export type SystemMode = "DEFAULT" | "READONLY" | "MAINTENANCE";
export interface ISystem {
	systemMode: SystemMode;
	serverEnv: string;
	clientEnv: string;
}

export const systemDefaults: ISystem = {
	systemMode: env.featureFlags?.maintenance ? "MAINTENANCE" : "DEFAULT",
	serverEnv: "",
	clientEnv: env.appEnv,
};

let serverSideEventsRef: { close(): void } | undefined;
export const useSystemStore = createAtom<ISystem>(systemDefaults, {
	onSet: () => {
		// Init or Reset Server-Side system event
		serverSideEventsRef?.close();
		serverSideEventsRef = undefined;
		serverSideEventsRef = initServerEvents({});
	},
});
export function useSystemValue(): ISystem {
	return useSystemStore((x) => x.value);
}
export function useSystemState(): {
	systemStatus: ISystem;
	setSystemStatus: (newValue: ISystem | Updater<ISystem>) => void;
} {
	const { value: systemStatus, set: setSystemStatus } = useSystemStore();
	return { systemStatus, setSystemStatus };
}
