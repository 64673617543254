import type {
	SpreadsheetCellApi,
	SpreadsheetPropsValidator,
} from "$root/components/tables-extra/spreadsheet/spreadsheet-cell";
import { useSpreadsheetCell } from "$root/components/tables-extra/spreadsheet/spreadsheet-cell";
import type { TableDataCellProps } from "@mdotm/mdotui/components";
import { TextAreaOverlayEditor } from "@mdotm/mdotui/components";
import type { ReactNode } from "react";
import type { InstrumentSpreadsheetCellTypeMap } from "../Instrument-customisation";
import QualityCell from "./QualityCell";
import SelectableCell from "./SelectableCell";

type SpreadsheetQualityCellProps = {
	cellProps: Omit<TableDataCellProps, "children">;
};

function SpreadsheetQualityCell<Row, K extends keyof Row & string>({
	column,
	rowId,
	spreadsheetCtx,
	cellProps,
	...forward
}: SpreadsheetPropsValidator<
	Row,
	K,
	InstrumentSpreadsheetCellTypeMap["quality"],
	SpreadsheetQualityCellProps
>): ReactNode {
	const cellApi = useSpreadsheetCell(spreadsheetCtx, rowId, column) as SpreadsheetCellApi<
		InstrumentSpreadsheetCellTypeMap["quality"]
	>;

	return (
		<SelectableCell spreadsheetCtx={spreadsheetCtx} {...forward} {...cellProps} rowId={rowId} column={column}>
			{({ style }) => (
				<TextAreaOverlayEditor
					onClickAway={cellApi.deactivate}
					onBlur={cellApi.deactivate}
					value={cellApi.data?.value ?? ""}
					onChange={(newValue) =>
						cellApi.update({
							...(cellApi.data ?? {}),
							kind: "quality",
							value: newValue ?? undefined,
						})
					}
					isOpen={cellApi.active}
					trigger={({ innerRef }) => (
						<QualityCell innerRef={innerRef} quality={cellApi.data?.value} classList="min-w-0 flex-1" style={style} />
					)}
				/>
			)}
		</SelectableCell>
	);
}

export default SpreadsheetQualityCell;
