import type { MaybeFn, Updater } from "@mdotm/mdotui/utils";
import type { Context, Dispatch, ForwardedRef, ReactNode, SetStateAction } from "react";
import { Fragment, memo, useContext } from "react";
import { useState } from "react";
import { propagateRef } from "@mdotm/mdotui/react-extensions";

/**
 * Create a Component-factory for wrapping component inside a Context.Consumer.
 *
 * The original component will receive its props from the Context. Thus, the component
 * generated using withContext(<context>)(<component-with-props>) won't accept any prop.
 *
 *
 * Example:
 * ```tsx
 * const CustomH1: React.FC<{title: string}> = ({title}) => <h1>{title}</h1>;
 *
 * const TitleContext = createContext<{title: string}>({title: ''});
 *
 * const CustomH1WithContext = withContext(TitleContext)(CustomH1);
 * ```
 *
 * @param Ctx the context to consume.
 * @returns a function that takes a component whose props are compatible with the context.
 */
export function withContext<
	TContent extends Record<string, unknown>,
	TExtraProps extends Record<string, unknown> = Record<string, unknown>,
>(Ctx: Context<TContent>): (Component: React.FC<TContent & TExtraProps>) => React.FC<TExtraProps> {
	return (Component) =>
		function Inner(extraProps) {
			return (
				<Ctx.Consumer>
					{function InnerInner(props) {
						return <Component {...{ ...props, ...extraProps }} />;
					}}
				</Ctx.Consumer>
			);
		};
}

type MergeTwo<A, B> = {
	[KA in keyof A]: A[KA];
} & {
	[KB in keyof B]: B[KB];
};

type Merge<Others extends [...unknown[]]> = Others extends [infer First, ...infer Rest]
	? MergeTwo<First, Merge<Rest>>
	: Others;

function MultipleContextConsumerHelper<
	TContents extends [] | [Record<string, unknown>, ...Record<string, unknown>[]],
	K extends number = 0,
>({
	previousContextsContents,
	Contexts,
	index,
	Component,
}: {
	Contexts: {
		[KInner in keyof TContents]: Context<TContents[KInner]>;
	} & Array<unknown>;
	previousContextsContents: Partial<Merge<TContents>>;
	index: K;
	Component: React.FC<Merge<TContents>>;
}) {
	const CurrentContext = index < Contexts.length ? Contexts[index] : undefined;
	return !CurrentContext ? (
		<Component {...(previousContextsContents as any)} />
	) : (
		<CurrentContext.Consumer>
			{(currentContextContent: TContents[K]) => (
				<MultipleContextConsumerHelper
					Component={Component}
					index={index + 1}
					previousContextsContents={{ ...previousContextsContents, ...currentContextContent }}
					Contexts={Contexts}
				/>
			)}
		</CurrentContext.Consumer>
	);
}

/**
 * Create a Component-factory for wrapping component inside multiple Context.Consumers.
 *
 * The original component will receive its props from the Contexts. Thus, the component
 * generated using withContexts([<context1>, <context2>, ...])(<component-with-props>) won't accept any prop.
 *
 *
 * Example:
 * ```tsx
 * const MyCard: React.FC<{title: string; content: string}> = ({title, content}) => (
 * 	<div>
 * 		<h1>{title}</h1>
 * 		<div>{content}</div>
 * 	</div>
 * );
 *
 * const TitleContext = createContext<{title: string}>({title: ''});
 * const ContentContext = createContext<{content: string}>({content: ''});
 *
 * const MyCardWithContexts = withContexts([TitleContext, ContentContext])(MyCard);
 * ```
 *
 * @param Contexts the contexts to consume.
 * @returns a function that takes a component whose props are compatible with the context.
 */
export function withContexts<TContents extends [] | [Record<string, unknown>, ...Record<string, unknown>[]]>(
	Contexts: {
		[K in keyof TContents]: Context<TContents[K]>;
	} & Array<unknown>,
): (Component: React.FC<Merge<TContents>>) => React.FC {
	return (Component) =>
		function Inner() {
			return (
				<MultipleContextConsumerHelper
					Component={Component}
					Contexts={Contexts}
					index={0}
					previousContextsContents={{}}
				/>
			);
		};
}

export type ContextContent<T> = T extends Context<infer P> ? P : never;

/**
 * Utility function created to wrap components that use generics
 */
export const genericMemo: <T>(component: T) => T = memo;

export function multiRef<T>(...refs: Array<ForwardedRef<T> | undefined>): (el: T | null) => void {
	return (el) => {
		for (let i = 0; i < refs.length; i++) {
			propagateRef(el, refs[i]);
		}
	};
}

/**
 * Like `useContext` but throws an error if context is missing
 */
export function useMandatoryContext<T>(context: Context<T>): NonNullable<T> {
	const ctx = useContext(context);
	if (!ctx) {
		throw new Error("missing context");
	}
	return ctx;
}

export type StatefulSubtreeProps<T> = {
	initialValue: MaybeFn<T>;
	children: (pair: [T, Dispatch<SetStateAction<T>>]) => ReactNode;
};

export function StatefulSubtree<T>({ initialValue, children }: StatefulSubtreeProps<T>): JSX.Element {
	const pair = useState(initialValue);
	return <Fragment>{children(pair)}</Fragment>;
}

export type UseStateResult<T> = [T, (v: T | Updater<T>) => void];
