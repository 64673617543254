import { type StandardClassificationId } from "$root/api/api-gen";
import type { IconName } from "@mdotm/mdotui/components";
import { generateUniqueDOMId } from "@mdotm/mdotui/react-extensions";
import type { ReportTemplateItem as ReportTemplateItemV6, ReportTemplate as ReportTemplateV6 } from "./report-v6";
import { typedObjectEntries } from "@mdotm/mdotui/utils";

/** List used to show the buttons */
export const enabledTemplateItemKinds: Array<keyof ReportTemplateItemMap> = [
	"cover",
	"summary",
	"freeText",
	"portfolioMetrics",
	"exAntePortfolioMetrics",
	"commentary",
	"composition",
	"exposure",
	"performance",
	"performanceAttribution",
	"exAnteContributionVolatility",
	"performanceAndVolatilityContribution",
	"factorExposure",
	"disclaimer",
];

// TODO: review what is actually available
/** List used to disable buttons based on the report type */
export const availableTemplateItemKindsByReportType: Record<
	ReportTemplateVariant,
	Array<keyof ReportTemplateItemMap>
> = {
	current: [
		"cover",
		"summary",
		"freeText",
		"portfolioMetrics",
		"exAntePortfolioMetrics",
		"commentary",
		"composition",
		"exposure",
		"performance",
		"performanceAttribution",
		"exAnteContributionVolatility",
		"performanceAndVolatilityContribution",
		"factorExposure",
		"disclaimer",
	],
	proposal: [
		"cover",
		"summary",
		"freeText",
		"portfolioMetrics",
		"exAntePortfolioMetrics",
		"commentary",
		"composition",
		"exposure",
		"factorExposure",
		"disclaimer",
	],
};

type Forecast = "ONE_MONTH" | "THREE_MONTHS" | "SIX_MONTHS" | "ONE_YEAR" | "YEAR_TO_DATE" | "FROM_INCEPTION";
export type CompositionColumnPreference = {
	enabled: boolean;
	preferenceType: {
		standardClassificationId?: StandardClassificationId;
		classificationUuid: string | "IDENTIFIER" | "WEIGHT" | "NAME" | "ENHANCED_WEIGHT" | "DIFFERENCE";
		label: string;
	};
};

export type ReportTemplateItemMap = {
	cover: {
		title?: string;
	};
	summary: Record<"portfolioName" | "baseCurrency" | "benchmark" | "universe" | "reference" | "averageScore", boolean>;
	freeText: {
		content: string;
	};
	portfolioMetrics: {
		volatility: Record<
			| "VOLATILITY_SINCE_INCEPTION"
			| "VOLATILITY_YTD"
			| "VOLATILITY_1Y"
			| "VOLATILITY_6M"
			| "VOLATILITY_3M"
			| "VOLATILITY_1M",
			boolean
		>;
		efficiencyRatio: Record<
			| "EFFICIENCY_RATIO_SINCE_INCEPTION"
			| "EFFICIENCY_RATIO_YTD"
			| "EFFICIENCY_RATIO_1Y"
			| "EFFICIENCY_RATIO_6M"
			| "EFFICIENCY_RATIO_3M"
			| "EFFICIENCY_RATIO_1M",
			boolean
		>;
		performance: Record<
			| "PERFORMANCE_SINCE_INCEPTION"
			| "PERFORMANCE_YTD"
			| "PERFORMANCE_1Y"
			| "PERFORMANCE_6M"
			| "PERFORMANCE_3M"
			| "PERFORMANCE_1M",
			boolean
		>;
		sortino: Record<
			"SORTINO_SINCE_INCEPTION" | "SORTINO_YTD" | "SORTINO_1Y" | "SORTINO_6M" | "SORTINO_3M" | "SORTINO_1M",
			boolean
		>;
		maxDrawdown: Record<
			| "MAX_DRAWDOWN_SINCE_INCEPTION"
			| "MAX_DRAWDOWN_YTD"
			| "MAX_DRAWDOWN_1Y"
			| "MAX_DRAWDOWN_6M"
			| "MAX_DRAWDOWN_3M"
			| "MAX_DRAWDOWN_1M",
			boolean
		>;
		benchmark: boolean;
	};
	exAntePortfolioMetrics: {
		trackingError: Record<"TRACKING_ERROR_3Y", boolean>;
		return: Record<"RETURN_3Y", boolean>;
		maxDrawdown: Record<"MAX_DRAWDOWN_3Y", boolean>;
		volatility: Record<"VOLATILITY_3Y", boolean>; //"VOLATILITY_1Y" | "VOLATILITY_2Y" |
		efficiencyRatio: Record<"EFFICIENCY_RATIO_3Y", boolean>;
		diversificationRatio: Record<"DIVERSIFICATION_RATIO_3Y", boolean>;
		historicalVar: Record<"HISTORICAL_VAR_95_3Y", boolean>;
		riskConstraint: Record<"VAR" | "VOLATILITY" | "TRACKING_ERROR", boolean>;
		benchmark: boolean;
	};
	performance: {
		benchmark: boolean;
	};
	performanceAttribution: Record<Forecast, boolean>;
	commentary: {
		templateUuid?: string;
		title?: string;
		useTemplateNameAsTitle?: boolean;
		useTemplateLanguage?: boolean;
	};
	composition: {
		currentColumnPreferences: Array<CompositionColumnPreference>;
		proposalColumnPreferences: Array<CompositionColumnPreference>;
	};
	exposure: {
		selectedCategories: Array<
			| { value: string; label: string; type: "TAG" }
			| {
					value:
						| "MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS"
						| "MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY"
						| "MACRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY"
						| "MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY"
						| "MICRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY"
						| "MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY"
						| "CURRENCY"
						| "TAG";
					label: string;
					type: "RISK_MODEL";
			  }
		>;
	};
	exAnteContributionVolatility: {
		filterSignificant: boolean;
		versus: Record<
			| "MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS"
			| "MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY"
			| "MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY"
			| "MACRO_GEOGRAPHY_VS_MACRO_ASSET_CLASS"
			| "MACRO_GEOGRAPHY_VS_MICRO_ASSET_CLASS",
			boolean
		>;
	};
	performanceAndVolatilityContribution: {
		filterSignificant: boolean;
		versus: Record<
			| "MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS"
			| "MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY"
			| "MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY"
			| "MACRO_GEOGRAPHY_VS_MACRO_ASSET_CLASS"
			| "MACRO_GEOGRAPHY_VS_MICRO_ASSET_CLASS",
			boolean
		>;
	};
	factorExposure: {
		benchmark: boolean;
		filterSignificant: boolean;
	};
	disclaimer: {
		content: {
			enabled: boolean;
			title?: string;
			description?: string;
		};
		contact: {
			enabled: boolean;
			description?: string;
		};
	};
};

export const itemFactories = {
	cover: () => ({ title: undefined, kind: "cover", id: generateUniqueDOMId() }),
	summary: () => ({
		averageScore: false,
		baseCurrency: false,
		benchmark: false,
		portfolioName: false,
		reference: false,
		universe: false,
		kind: "summary",
		id: generateUniqueDOMId(),
	}),
	freeText: () => ({ content: "", kind: "freeText", id: generateUniqueDOMId() }),
	performance: () => ({ kind: "performance", id: generateUniqueDOMId(), benchmark: true }),
	portfolioMetrics: () => ({
		benchmark: false,
		efficiencyRatio: {
			EFFICIENCY_RATIO_SINCE_INCEPTION: false,
			EFFICIENCY_RATIO_YTD: false,
			EFFICIENCY_RATIO_1M: false,
			EFFICIENCY_RATIO_3M: false,
			EFFICIENCY_RATIO_6M: false,
			EFFICIENCY_RATIO_1Y: false,
		},
		maxDrawdown: {
			MAX_DRAWDOWN_SINCE_INCEPTION: false,
			MAX_DRAWDOWN_YTD: false,
			MAX_DRAWDOWN_1M: false,
			MAX_DRAWDOWN_3M: false,
			MAX_DRAWDOWN_6M: false,
			MAX_DRAWDOWN_1Y: false,
		},
		performance: {
			PERFORMANCE_SINCE_INCEPTION: false,
			PERFORMANCE_YTD: false,
			PERFORMANCE_1M: false,
			PERFORMANCE_3M: false,
			PERFORMANCE_6M: false,
			PERFORMANCE_1Y: false,
		},
		sortino: {
			SORTINO_SINCE_INCEPTION: false,
			SORTINO_YTD: false,
			SORTINO_1M: false,
			SORTINO_3M: false,
			SORTINO_6M: false,
			SORTINO_1Y: false,
		},
		volatility: {
			VOLATILITY_SINCE_INCEPTION: false,
			VOLATILITY_YTD: false,
			VOLATILITY_1M: false,
			VOLATILITY_3M: false,
			VOLATILITY_6M: false,
			VOLATILITY_1Y: false,
		},
		kind: "portfolioMetrics",
		id: generateUniqueDOMId(),
	}),
	exAntePortfolioMetrics: () => ({
		benchmark: false,
		efficiencyRatio: {
			EFFICIENCY_RATIO_3Y: false,
		},
		maxDrawdown: {
			MAX_DRAWDOWN_3Y: false,
		},
		diversificationRatio: {
			DIVERSIFICATION_RATIO_3Y: false,
		},
		historicalVar: {
			HISTORICAL_VAR_95_3Y: false,
		},
		return: { RETURN_3Y: false },
		trackingError: { TRACKING_ERROR_3Y: false },
		volatility: {
			VOLATILITY_3Y: false,
		},
		riskConstraint: {
			TRACKING_ERROR: false,
			VAR: false,
			VOLATILITY: false,
		},
		kind: "exAntePortfolioMetrics",
		id: generateUniqueDOMId(),
	}),
	commentary: () => ({ detailed: true, summary: true, kind: "commentary", id: generateUniqueDOMId() }),
	composition: () => ({
		currentColumnPreferences: [],
		proposalColumnPreferences: [],
		kind: "composition",
		id: generateUniqueDOMId(),
	}),
	exposure: () => ({
		selectedCategories: [],
		kind: "exposure",
		id: generateUniqueDOMId(),
	}),
	exAnteContributionVolatility: () => ({
		versus: {
			MACRO_GEOGRAPHY_VS_MACRO_ASSET_CLASS: false,
			MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: false,
			MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS: false,
			MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: false,
			MACRO_GEOGRAPHY_VS_MICRO_ASSET_CLASS: false,
		},
		filterSignificant: true,
		kind: "exAnteContributionVolatility",
		id: generateUniqueDOMId(),
	}),
	performanceAndVolatilityContribution: () => ({
		versus: {
			MACRO_GEOGRAPHY_VS_MACRO_ASSET_CLASS: false,
			MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: false,
			MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS: false,
			MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: false,
			MACRO_GEOGRAPHY_VS_MICRO_ASSET_CLASS: false,
		},
		filterSignificant: true,
		kind: "performanceAndVolatilityContribution",
		id: generateUniqueDOMId(),
	}),
	performanceAttribution: () => ({
		FROM_INCEPTION: false,
		YEAR_TO_DATE: true,
		ONE_MONTH: false,
		THREE_MONTHS: false,
		SIX_MONTHS: false,
		ONE_YEAR: false,
		kind: "performanceAttribution",
		id: generateUniqueDOMId(),
	}),
	factorExposure: () => ({
		benchmark: false,
		filterSignificant: true,
		kind: "factorExposure",
		id: generateUniqueDOMId(),
	}),
	disclaimer: () => ({
		kind: "disclaimer",
		id: generateUniqueDOMId(),
		contact: { enabled: false, description: "" },
		content: { enabled: false, description: "" },
	}),
} satisfies { [K in keyof ReportTemplateItemMap]: (...params: Array<unknown>) => ReportTemplateItemFor<K> };

// TODO: change icons (currently random)
export const itemToIconMap: Record<keyof ReportTemplateItemMap, IconName> = {
	cover: "cover",
	freeText: "free-text",
	performance: "performance",
	summary: "summary",
	portfolioMetrics: "metrics",
	exAntePortfolioMetrics: "exante-metrics",
	commentary: "commentary-text",
	composition: "composition",
	exposure: "piechart",
	exAnteContributionVolatility: "Ex-antevolatilitycontribution",
	performanceAttribution: "Performance-attribution",
	performanceAndVolatilityContribution: "performance",
	factorExposure: "Factors-exposure",
	disclaimer: "disclaimer",
};

export const itemToConstraints: Record<
	keyof ReportTemplateItemMap,
	(ctx: { reportTemplate: ReportTemplate; templateVariantTab: ReportTemplateVariant }) => boolean
> = {
	cover: (ctx) =>
		!ctx.reportTemplate.data.templateItemsByVariant[ctx.templateVariantTab].some((item) => item.kind === "cover"),
	freeText: () => true,
	performanceAttribution: (ctx) =>
		!ctx.reportTemplate.data.templateItemsByVariant[ctx.templateVariantTab].some(
			(item) => item.kind === "performanceAttribution",
		),
	summary: (ctx) =>
		!ctx.reportTemplate.data.templateItemsByVariant[ctx.templateVariantTab].some((item) => item.kind === "summary"),
	performance: (ctx) =>
		!ctx.reportTemplate.data.templateItemsByVariant[ctx.templateVariantTab].some((item) => item.kind === "performance"),
	portfolioMetrics: (ctx) =>
		!ctx.reportTemplate.data.templateItemsByVariant[ctx.templateVariantTab].some(
			(item) => item.kind === "portfolioMetrics",
		),
	exAntePortfolioMetrics: (ctx) =>
		!ctx.reportTemplate.data.templateItemsByVariant[ctx.templateVariantTab].some(
			(item) => item.kind === "exAntePortfolioMetrics",
		),
	commentary: () => true,
	composition: (ctx) =>
		!ctx.reportTemplate.data.templateItemsByVariant[ctx.templateVariantTab].some((item) => item.kind === "composition"),
	exposure: (ctx) =>
		!ctx.reportTemplate.data.templateItemsByVariant[ctx.templateVariantTab].some((item) => item.kind === "exposure"),
	exAnteContributionVolatility: (ctx) =>
		!ctx.reportTemplate.data.templateItemsByVariant[ctx.templateVariantTab].some(
			(item) => item.kind === "exAnteContributionVolatility",
		),
	performanceAndVolatilityContribution: (ctx) =>
		!ctx.reportTemplate.data.templateItemsByVariant[ctx.templateVariantTab].some(
			(item) => item.kind === "performanceAndVolatilityContribution",
		),
	factorExposure: (ctx) =>
		!ctx.reportTemplate.data.templateItemsByVariant[ctx.templateVariantTab].some(
			(item) => item.kind === "factorExposure",
		),
	disclaimer: (ctx) =>
		!ctx.reportTemplate.data.templateItemsByVariant[ctx.templateVariantTab].some((item) => item.kind === "disclaimer"),
};

export const itemToLabelMap: Record<keyof ReportTemplateItemMap, string> = {
	cover: "Cover page",
	freeText: "Text box",
	summary: "Summary",
	portfolioMetrics: "Portfolio metrics",
	exAntePortfolioMetrics: "Ex-ante metrics",
	commentary: "Commentary",
	composition: "Composition",
	exposure: "Exposure",
	exAnteContributionVolatility: "Ex-ante volatility contribution",
	performance: "Performance",
	performanceAndVolatilityContribution: "Performance and volatility contribution",
	performanceAttribution: "Performance attribution",
	factorExposure: "Factor exposure",
	disclaimer: "Closing page",
};

export const itemToAdderLabelMap: Record<keyof ReportTemplateItemMap, string> = {
	cover: "Cover\npage",
	freeText: "Text\nbox",
	summary: "Summary",
	portfolioMetrics: "Portfolio\nmetrics",
	exAntePortfolioMetrics: "Ex-ante\nmetrics",
	commentary: "Commentary",
	composition: "Composition",
	exposure: "Exposure",
	exAnteContributionVolatility: "Ex-ante vol.\ncontribution",
	performance: "Performance",
	performanceAttribution: "Perfomance\nattribution",
	performanceAndVolatilityContribution: "Performance &\nvolatility contrib.",
	factorExposure: "Factor\nexposure",
	disclaimer: "Closing\npage",
};

export const defaultEmptyTemplateData: ReportTemplate["data"] = {
	status: "draft",
	version: 4,
	templateItemsByVariant: {
		current: [itemFactories.cover(), itemFactories.disclaimer()],
		proposal: [itemFactories.cover(), itemFactories.disclaimer()],
	},
	templateDraftItemsByVariant: {
		current: [itemFactories.cover(), itemFactories.disclaimer()],
		proposal: [itemFactories.cover(), itemFactories.disclaimer()],
	},
};

export type ReportTemplateItemFor<K extends keyof ReportTemplateItemMap> = {
	[InnerK in keyof ReportTemplateItemMap]: {
		kind: InnerK;
		id: string;
	} & ReportTemplateItemMap[InnerK];
}[K];

export type ReportTemplateItem = ReportTemplateItemFor<keyof ReportTemplateItemMap>;

export type ReportTemplateVariant = "current" | "proposal";
export type ReportTemplateStatus = "draft" | "ready";

export type ReportTemplate = {
	uuid: string;
	templateName: string;
	format: "pdf" | "xlsx";
	visible: boolean;
	data: {
		// TODO: add templateKind to distinguish portfolio from custom-report and others
		status: ReportTemplateStatus;
		version: number;
		templateItemsByVariant: Record<ReportTemplateVariant, Array<ReportTemplateItem>>;
		templateDraftItemsByVariant: Record<ReportTemplateVariant, Array<ReportTemplateItem>>;
	};
};

export function migrateV6ToV7(template: ReportTemplateV6): ReportTemplate {
	function migrateItem(item: ReportTemplateItemV6): ReportTemplateItem {
		// migrate composition
		switch (item.kind) {
			case "exposure": {
				return {
					...item,
					kind: "exposure",
					selectedCategories: typedObjectEntries(item.versus)
						.filter(([exposureType, enabled]) => enabled && exposureType !== "TAG")
						.map(([exposureType]) => ({
							label: exposureType,
							type: "RISK_MODEL",
							value: exposureType,
						})),
				};
			}
			default:
				return item;
		}
	}

	return {
		...template,
		data: {
			version: 7,
			status: template.data.status,
			templateItemsByVariant: {
				current: template.data.templateItemsByVariant.current.map(migrateItem),
				proposal: template.data.templateItemsByVariant.proposal.map(migrateItem),
			},
			templateDraftItemsByVariant: {
				current: template.data.templateDraftItemsByVariant.current.map(migrateItem),
				proposal: template.data.templateDraftItemsByVariant.proposal.map(migrateItem),
			},
		},
	};
}
