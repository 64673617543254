import type { AtomContent } from "$root/third-party-integrations/zustand";
import { createPersistentAtom } from "$root/third-party-integrations/zustand";
import type { Updater } from "@mdotm/mdotui/utils";
import { identity } from "@mdotm/mdotui/utils";
import fastDeepEqual from "fast-deep-equal";

export const PortfolioListViewMode = {
	mixed: "MIXED",
	chart: "CHART",
	table: "TABLE",
} as const;

export type PortfolioListViewMode = (typeof PortfolioListViewMode)[keyof typeof PortfolioListViewMode];

export type PortfolioStudioStoreProps = {
	isightPanel?: {
		open: boolean;
	};
	viewMode: PortfolioListViewMode;
};

export const portfolioStudioDefault: PortfolioStudioStoreProps = {
	isightPanel: { open: true },
	viewMode: "MIXED",
};

export const usePortfolioStudioStore = createPersistentAtom<PortfolioStudioStoreProps>(
	portfolioStudioDefault,
	"portfolio-studio",
	{
		version: 1,
		migrate(persistedState: AtomContent<PortfolioStudioStoreProps>, version) {
			if (version === 0) {
				return { value: { ...persistedState.value, viewMode: "MIXED" }, set: persistedState.set };
			}
			return persistedState;
		},
	},
);

export function usePortfolioStudioValue(): PortfolioStudioStoreProps {
	return usePortfolioStudioStore((x) => x.value);
}

export function usePortfolioStudioState(): {
	portfolioStudioStore: PortfolioStudioStoreProps;
	setPortfolioStudioStore: (newValue: PortfolioStudioStoreProps | Updater<PortfolioStudioStoreProps>) => void;
	reset(): void;
} {
	const { value: portfolioStudioStore, set: setPortfolioStudioStore } = usePortfolioStudioStore(identity, (a, b) =>
		fastDeepEqual(a.value, b.value),
	);
	return {
		portfolioStudioStore,
		setPortfolioStudioStore,
		reset: () => setPortfolioStudioStore(portfolioStudioDefault),
	};
}
