
export const PortfolioStudioTab = {
	Portfolios: "Portfolios",
	References: "References",
	Universes: "Universes",
	MarketViews: "MarketViews",
} as const;

export type PortfolioStudioTab = (typeof PortfolioStudioTab)[keyof typeof PortfolioStudioTab];
export const tabTitle: Record<PortfolioStudioTab, string> = {
	MarketViews: "Market Views",
	Portfolios: "Portfolios",
	References: "References",
	Universes: "Universes",
};
export const portfolioStudioDefaultTabOrdering = [
	PortfolioStudioTab.Portfolios,
	PortfolioStudioTab.References,
	PortfolioStudioTab.Universes,
	PortfolioStudioTab.MarketViews,
];
