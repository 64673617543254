import type { MaybeArray } from "@mdotm/mdotui/utils";
import { alwaysArray } from "@mdotm/mdotui/utils";
import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

export const queryClientKeys = {
	/**
	 * Retrieve investment list
	 */
	portfolioStudioInvestmentList: "investmentListQueryStudio",
	/**
	 * Retrieve investment list
	 */
	portfolioStudioProjectedInvestmentList: "projectedInvestmentListQueryStudio",
	/**
	 * Retrieve investment list
	 */
	portfolioStudioKeyMetric: "queryInvestmentKeyMetric",
	/**
	 * Retrieve target investment list
	 */
	portfolioStudioTargetInvestmentList: "referenceQueryStudio",
	/**
	 * Retrieve benchmark list
	 */
	portfolioStudioBenchmarkList: "benchmarkQueryStudio",
	/**
	 * Retrieve universe list
	 */
	portfolioStudioUniverseList: "universeQueryStudio",
	/**
	 * Retrieve market view list
	 */
	portfolioStudioMarketViewList: "marketQueryStudio",
	/**
	 * Retrieve investment list columns preference
	 */
	portfolioStudioInvestmentColumnsPreference: "queryUserPortfolioColumnMetricsOrderingPreferences",
	/**
	 * Retrieve target investment list columns preference
	 */
	portfolioStudioTargetInvestmentColumnsPreference: "queryUserReferenceColumnOrderingPreferences",
	/**
	 * Retrieve benchmark list columns preference
	 */
	portfolioStudioBenchmarkColumnsPreference: "queryUserReferenceColumnOrderingPreferences",
	/**
	 * Retrieve universe list columns preference
	 */
	portfolioStudioUniverseColumnsPreference: "queryUserUniverseColumnMetricsOrderingPreferences",
	/**
	 * Retrieve market view list columns preference
	 */
	portfolioStudioMarketColumnsPreference: "queryUserMarketViewColumnMetricsOrderingPreferences",
	/**
	 * Retrieve user market view settings
	 */
	portfolioStudioMarketViewSettings: "queryUserMarketViewSettings",
	/**
	 * Retrieve customer info about auto sync
	 */
	portfolioStudioUserSyncInfo: "queryUserStudioUserSyncInfos",
	/**
	 * Retrieve user filters for bulk analysis
	 */
	analysisBulkSelectableFilters: "queryCommonSelectableFilters",
	/**
	 * Retrieve user filters for bulk analysis
	 */
	analysisBulkPresets: "queryAnalysisBulkPresets",
	/**
	 * Retrieve user filters for bulk analysis
	 */
	instrumentCustomisationUserClassification: "queryInstrumentCustomisationUserClassification",
	/**
	 * Retrieve user risk model exposure ac, mc, sectors etc.. for bulk analysis
	 */
	instrumentCustomisationExposure: "queryInstrumentCustomisationExposure",
	/**
	 * Retrieve user classification & entities for bulk analysis
	 */
	analysisBulkSelectableEntities: "querySelectableEntities",
	/**
	 * Retrieve user baskets
	 */
	querySelectableInstrumentBaskets: "querySelectableInstrumentBaskets",
	/**
	 * Retrieve entity summary when edit his composition
	 */
	queryAddInstrumentsEntitySummary: "queryAddInstrumentsEntitySummary",
	/**
	 * Retrieve paginated instrument for add instrument functional area
	 */
	queryInstrumentsAddableInComposition: "queryInstrumentsAddableInComposition",
} as const;

type SphereQueryClientKeys = (typeof queryClientKeys)[keyof typeof queryClientKeys];

export function typedQueryClient(): {
	invalidateQueries: (queryKey: MaybeArray<SphereQueryClientKeys>) => Promise<void>;
	refetchQueries: (queryKey: MaybeArray<SphereQueryClientKeys>) => Promise<void>;
} {
	async function invalidateQueries(queryKey: MaybeArray<SphereQueryClientKeys>) {
		await queryClient.invalidateQueries({
			predicate(query) {
				const queriesKeyToMatch = alwaysArray(queryKey);
				const cachedQueryKey = query.queryKey?.[0] as SphereQueryClientKeys;

				return queriesKeyToMatch.includes(cachedQueryKey);
			},
		});
	}

	async function refetchQueries(queryKey: MaybeArray<SphereQueryClientKeys>) {
		await queryClient.refetchQueries({
			predicate(query) {
				const queriesKeyToMatch = alwaysArray(queryKey);
				const cachedQueryKey = query.queryKey?.[0] as SphereQueryClientKeys;

				return queriesKeyToMatch.includes(cachedQueryKey);
			},
		});
	}

	return { invalidateQueries, refetchQueries };
}
