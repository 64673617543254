import { DropzoneArea } from "$root/components/DropzoneArea";
import UploadButton from "$root/components/UploadButton";
import EmptyImportBox from "$root/functional-areas/instruments-editor/spawn/box/EmptyImportBox";
import ErrorImportBox from "$root/functional-areas/instruments-editor/spawn/box/ErrorImportBox";
import SuccessImportBox from "$root/functional-areas/instruments-editor/spawn/box/SuccessImportBox";
import { Button, Icon } from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { unpromisify } from "@mdotm/mdotui/utils";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";

const ACCEPTED_FILE = "*";
export type DropzoneState = "empty" | "error" | "success" | "loading";
export type Converted<T> = {
	filename: string;
	conversion: T;
};

const commonDropzoneClassList = {
	empty: `bg-[color:${themeCSSVars.palette_N50}] border-dashed border-[color:${themeCSSVars.palette_N500}]`,
	success: `bg-[color:${themeCSSVars.palette_N0}] border-[color:${themeCSSVars.palette_P600}]`,
	error: `bg-[color:${themeCSSVars.palette_N0}] border-[color:${themeCSSVars.palette_D500}]`,
	loading: `bg-[color:${themeCSSVars.palette_N50}] border-dashed border-[color:${themeCSSVars.palette_N500}]`,
} satisfies Record<DropzoneState, string>;

const drawIcon = {
	empty: <EmptyImportBox />,
	error: <ErrorImportBox />,
	success: <SuccessImportBox />,
	loading: <EmptyImportBox />,
} satisfies Record<DropzoneState, ReactNode>;

function BaseDropzoneBlock<T>({
	disabled,
	onChange,
	onDelete,
	status = "empty",
	converted,
	acceptedFile,
}: {
	onChange(files: File[] | null): MaybePromise<void>;
	onDelete?(): void;
	disabled: boolean;
	converted: Array<Converted<T>>;
	status?: DropzoneState;
	acceptedFile?: string;
}): JSX.Element {
	const { t } = useTranslation();

	return (
		<DropzoneArea
			disabled={disabled}
			onChange={unpromisify((files: FileList | null) => onChange(files ? Array.from(files) : null))}
			accept={acceptedFile ?? ACCEPTED_FILE}
			childrenWrapperAppearance={{
				classList: {
					[`relative rounded flex flex-1 gap-4 mb-5 p-4 justify-between items-center border-2 `]: true,
					[commonDropzoneClassList[status] ?? ""]: status !== undefined,
				},
			}}
			multiple
		>
			<div className="flex gap-2 items-center flex-1">
				{drawIcon[status]}
				<p className="font-semibold">
					{status === "success"
						? converted.map(({ filename }) => filename).join("\n") ?? "file successfully converted"
						: "Select a file or drag and drop here"}
				</p>
			</div>

			{status === "success" ? (
				<Button unstyled size="small" onClick={onDelete}>
					<Icon icon="Delete" size={20} color={themeCSSVars.palette_N500} />
				</Button>
			) : (
				<UploadButton
					loading={status === "loading"}
					size="small"
					label={t("BUTTON.SELECT")}
					onChange={unpromisify((files: FileList | null) => onChange(files ? Array.from(files) : null))}
					accept={ACCEPTED_FILE}
					multiple
				/>
			)}
		</DropzoneArea>
	);
}

export default BaseDropzoneBlock;
