import type {
	CreateMarketViewRequest,
	MarketScenario,
	MarketViewAssetClassAliases,
	MarketViewDetails,
	MarketViewSettings,
} from "$root/api/api-gen";
import {
	IntegrationMarketViewControllerApiFactory,
	MarketScenarioIds,
	MarketViewControllerApiFactory,
	MarketViewType,
	PortfolioStudioPreferencesApiFactory,
} from "$root/api/api-gen";
import { runWithErrorReporting } from "$root/api/error-reporting/report";
import { useApiGen } from "$root/api/hooks";
import { useCrumbs } from "$root/components/Crumbs/useCrumbs";
import { LabelRounded } from "$root/components/LabelRounded/Index";
import { LeavePrompt } from "$root/components/LeavePrompt";
import { PageDownloadAndMoreActionsMenu } from "$root/components/PageDownloadAndMoreActionsMenu";
import { PageHeader } from "$root/components/PageHeader";
import { useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { removeDeltaFromSelectedMarketView } from "$root/components/Portfolio/MarketView/utilsV2";
import useInvestmentHorizonSetup from "$root/components/Portfolio/common_hooks/useInvestmentHorizonSetup";
import { useEventBus } from "$root/event-bus";
import { spawnAccessDialog } from "$root/functional-areas/acl/AccessDialog";
import EntityStatus from "$root/functional-areas/acl/EntityStatus";
import { MarketViewPositioning } from "$root/functional-areas/market-view/MarketViewPositioning";
import { MarketViewProbabilities } from "$root/functional-areas/market-view/MarketViewProbabilities";
import { useMarketViewEntityManagementActions } from "$root/functional-areas/market-view/entity-management";
import { useDebouncedNameUniquenessChecker } from "$root/functional-areas/named-entities/uniqueness";
import { formatDate } from "$root/localization/formatters";
import { platformToast } from "$root/notification-system/toast";
import type { CustomAxiosError } from "$root/third-party-integrations/axios";
import { axiosExtract } from "$root/third-party-integrations/axios";
import EllipsisText from "$root/ui-lib/ellipsisText";
import { FormFields } from "$root/ui-lib/form/FormFields";
import { downloadContentDisposition, objMatchFn, useQueryNoRefetch, useSearchParams } from "$root/utils";
import { Card } from "$root/widgets-architecture/layout/Card";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Dialog, DialogFooter, ProgressBar, SubmitButton } from "@mdotm/mdotui/components";
import { Switch } from "@mdotm/mdotui/react-extensions";
import { noop, nullary, unpromisify } from "@mdotm/mdotui/utils";
import type { AxiosError } from "axios";
import fastDeepEqual from "fast-deep-equal";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { z } from "zod";
import { PortfolioStudioTab } from "./PortfoliosStudio/portfolio-studio-tabs";

type PageProps = {
	uuid?: string;
	action?: "clone" | "view" | "new";
	type?: MarketViewType;
};

const MarketViewWorkSpace = (): JSX.Element => {
	const { uuid, action = "new", type } = useParams<PageProps>();
	const isCustom = useSearchParams().isCustom === "true";
	const [saveModalOpen, setSaveModalOpen] = useState(false);
	const investmentHorizonSetup = useInvestmentHorizonSetup();

	const { t } = useTranslation();
	const { push } = useTypedNavigation();

	const marketViewApi = useApiGen(MarketViewControllerApiFactory);
	const portfolioStudioPreferenceApi = useApiGen(PortfolioStudioPreferencesApiFactory);
	const integrationsApi = useApiGen(IntegrationMarketViewControllerApiFactory);

	const {
		data: marketView,
		isLoading: isLoadingSphereScenario,
		isError: isErrorSphereScenario,
		refetch,
	} = useQueryNoRefetch<MarketViewDetails, AxiosError>(["getInitialMarketView", uuid, action], {
		enabled: Boolean(uuid),
		queryFn: async () => {
			if (!uuid) {
				throw new Error("unable to find a valid uuid");
			}
			const { data } = await marketViewApi.getMarketView(uuid);
			return data;
		},
		onError: (error: AxiosError<CustomAxiosError>) => {
			if (error.response?.data.code === 404 || error.response?.data.message === "Accesso negato") {
				spawnAccessDialog({
					onClick: (onClose) => {
						push("PortfoliosStudio", { tab: PortfolioStudioTab.References });
						onClose();
					},
				});
				return;
			}

			platformToast({
				children: t("SOMETHING_WENT_WRONG"),
				severity: "error",
				icon: "Portfolio",
			});
			push("PortfoliosStudio", { status: "notFound", tab: PortfolioStudioTab.References });
		},
	});

	const { data: marketViewSettings } = useQueryNoRefetch(["getMarketViewWorkspaceTemplateConfig"], {
		queryFn: async () => ({
			setting: await axiosExtract(marketViewApi.getMarketViewUserSettings()),
			alias: await axiosExtract(portfolioStudioPreferenceApi.getMarketViewAssetClassAliases()),
		}),
	});

	useEventBus("shared-entity", {
		filter: objMatchFn({ sharedEntityUuid: uuid }),
		listener: () => {
			refetch().catch(noop);
		},
	});

	const forecastHorizonTypography = investmentHorizonSetup.getInvestmentHorizonTypographyFromNewApiValue(
		"multiAsset",
		String(marketView?.forecastHorizon),
	);

	const entityManagementActions = useMarketViewEntityManagementActions(marketView, {
		onRename: unpromisify(nullary(refetch)),
		onDuplicate: () => push("PortfoliosStudio", { tab: PortfolioStudioTab.MarketViews }),
		onDelete: () => push("PortfoliosStudio", { tab: PortfolioStudioTab.MarketViews }),
	});

	const crumbs = useCrumbs();

	return (
		<>
			<PageHeader
				title={!uuid ? "New Market View" : isLoadingSphereScenario ? "..." : marketView?.name ?? "Untitled"}
				crumbsV2={[
					...crumbs.portfolioStudio("MarketViews", action === "view" ? uuid : undefined),
					...(action === "new"
						? crumbs.marketViewType({ marketViewType: type })
						: action === "clone"
						  ? crumbs.duplicateMarketView({
									uuid: marketView?.uuid,
									name: marketView?.name,
									type: marketView?.marketViewType,
						    })
						  : crumbs.marketViewName({
									uuid: marketView?.uuid,
									name: marketView?.name,
						    })),
				]}
				subTitle={
					<>
						{action === "view" ? (
							<div className="min-h-[54px] flex justify-between items-center">
								<div>
									{isErrorSphereScenario ? (
										<div className="flex items-center">
											<span className="mr-2">{t("STATUS").toUpperCase()}</span>
											<LabelRounded type="status" content={{ label: "ERROR", component: "" }} />
										</div>
									) : (
										<div className="flex justify-between items-center">
											<div className="flex gap-4 items-center">
												<div>
													<span className="mr-2 ">Type:</span>
													<span className="font-semibold text-[#3F485A]">
														{marketView?.custom
															? marketViewSettings?.setting.customMarketViewName ?? "-"
															: marketView?.marketViewType
															  ? t(marketView?.marketViewType)
															  : "-"}
													</span>
												</div>
												<div>
													<span className="mr-2 ">Forecast horizon:</span>
													<span className="font-semibold text-[#3F485A]">{forecastHorizonTypography || "-"}</span>
												</div>
												<div>
													<span className="mr-2 ">Creation date:</span>
													<span className="font-semibold text-[#3F485A]">{formatDate(marketView?.creationDate)}</span>
												</div>
												<div>
													<span className="mr-2 ">Expiration date:</span>
													<span className="font-semibold text-[#3F485A]">{formatDate(marketView?.endDate)}</span>
												</div>
												<EntityStatus
													entity="MARKET_VIEW"
													accessControl={marketView?.richAcl}
													entityId={marketView?.uuid}
													entityName={marketView?.name}
													refetch={refetch}
												/>
											</div>
										</div>
									)}
								</div>
								<PageDownloadAndMoreActionsMenu
									area="market-views"
									// TODO: Sprint 75
									// downloadActions={[
									// 	{
									// 		icon: "pdf",
									// 		label: "Market outlook",
									// 		onClickAsync: () => todo("download from api"),
									// 	},
									// ]}
									downloadActions={
										uuid
											? [
													{
														icon: "xls",
														onClickAsync: async () => {
															const response = await integrationsApi.exportMarketView(uuid, {
																responseType: "blob",
															});
															downloadContentDisposition(response);
														},
														label: "Market View template",
														"data-qualifier": "MarketView/DropdownMenu/DropdownItem(MarketViewTempalte)",
													},
											  ]
											: undefined
									}
									moreActions={[
										entityManagementActions.deleteAsync && {
											icon: "Delete",
											onClick: unpromisify(entityManagementActions.deleteAsync),
											label: "Delete",
											"data-qualifier": "MarketView/DropdownMenu/DropdownItem(Delete)",
										},
										entityManagementActions.renameAsync && {
											icon: "Edit",
											onClick: unpromisify(entityManagementActions.renameAsync),
											label: "Rename",
											"data-qualifier": "MarketView/DropdownMenu/DropdownItem(Rename)",
										},
										entityManagementActions.duplicateAsync && {
											icon: "Content-Copy",
											onClick: () =>
												push("MarketViewWorkSpace", {
													action: "clone",
													uuid: uuid!,
													type: type!,
													isCustom: String(isCustom),
												}),
											label: "Duplicate",
											"data-qualifier": "MarketView/DropdownMenu/DropdownItem(Duplicate)",
										},
									]}
								/>
							</div>
						) : null}
					</>
				}
				titleAction={
					action !== "view" ? (
						<div className="flex items-center space-x-2">
							<Button
								size="small"
								onClick={() => push("PortfoliosStudio", { tab: PortfolioStudioTab.MarketViews })}
								palette="tertiary"
								disabled={false}
								data-qualifier="MarketViewWorkspace/Cancel"
							>
								Cancel
							</Button>
							<Button
								size="small"
								onClick={() => setSaveModalOpen(true)}
								palette="primary"
								disabled={false}
								data-qualifier="MarketViewWorkspace/Save"
							>
								Save
							</Button>
						</div>
					) : null
				}
			/>

			{type && (
				<WrappedMarketView
					action={action}
					type={type}
					isCustom={isCustom}
					saveModalShow={saveModalOpen}
					setModalShow={setSaveModalOpen}
					scenarioId={uuid}
					marketViewSettings={marketViewSettings}
				/>
			)}
		</>
	);
};

const WrappedMarketView = ({
	type,
	scenarioId,
	setModalShow,
	saveModalShow = true,
	action,
	isCustom,
	marketViewSettings,
}: {
	type: MarketViewType;
	// marketViewType?: MarketViewDetailsMarketViewTypeEnum;
	isCustom: boolean;
	scenarioId?: string;
	setModalShow: (value: boolean) => void;
	saveModalShow: boolean;
	action?: "clone" | "view" | "new";
	marketViewSettings?: {
		setting: MarketViewSettings;
		alias: MarketViewAssetClassAliases;
	};
}): JSX.Element => {
	const { t } = useTranslation();
	const isNew = useMemo(() => !scenarioId, [scenarioId]);
	const isClone = action === "clone";
	const [currentScenarioId, setCurrentScenarioId] = useState<string | null>(null);
	const [originalScenario, setOriginalScenario] = useState<MarketScenario | null>(null);
	const [marketScenario, setMarketScenario] = useState<MarketScenario | null>(null);
	const marketViewApi = useApiGen(MarketViewControllerApiFactory);
	const { push } = useTypedNavigation();
	const { checkIfNameIsAvailable } = useDebouncedNameUniquenessChecker({
		isNameAvailableApi: (name, opts) => axiosExtract(marketViewApi.isMarketViewNameAvailable(name, opts)),
	});

	const { data: selectableMarketViews } = useQueryNoRefetch({
		queryKey: ["selectable-market-views", isNew, type, scenarioId],
		queryFn: () => axiosExtract(marketViewApi.getMarketViewScenarioList(false, scenarioId, false, type, isCustom)),
		onSuccess(data) {
			if (isNew) {
				const defaultScenarioId = data[0].id;
				setCurrentScenarioId(defaultScenarioId ?? null);
			} else {
				setCurrentScenarioId(scenarioId ?? null);
			}
		},
	});

	const { data: defaultScenario, isLoading: isLoadingDefaultScenario } = useQueryNoRefetch({
		queryKey: ["market-views-default-scenario"],
		queryFn: () =>
			axiosExtract(
				marketViewApi.getMarketViewScenario(
					MarketScenarioIds.SphereForecastPositioningOneMonth,
					undefined,
					false,
					false,
				),
			),
	});

	const derivedData = useMemo((): {
		type: MarketViewType;
		mode: "view" | "edit";
	} => {
		return {
			type,
			mode: isNew || isClone ? "edit" : "view",
		};
	}, [isClone, isNew, type]);

	const { watch, setValue, reset, control, formState, handleSubmit } = useForm({
		defaultValues: {
			marketViewName: "",
			scenarioId: "",
			invalidMarketViewName: [] as string[],
		},
		resolver: zodResolver(
			z.object({
				marketViewName: z
					.string()
					.min(1, "Please provide a name for your market view")
					.refine((name) => checkIfNameIsAvailable(name), {
						message: "Name not available",
					}),
			}),
		),
	});

	const observedMarketViewName = watch("marketViewName");
	const observedInvalidMarketViewName = watch("invalidMarketViewName");

	const onSubmit = useCallback(
		async (name: string) => {
			await runWithErrorReporting(
				async () => {
					if (!marketScenario || !originalScenario) {
						throw Error("unable to save a market view of undefined", { cause: marketScenario });
					}

					const { flexibleExpectedReturnsVolatility } = removeDeltaFromSelectedMarketView(
						marketScenario.flexibleExpectedReturnsVolatility!.assetClasses!,
						originalScenario.flexibleExpectedReturnsVolatility!.assetClasses!,
						marketScenario.regimeUserProbability!,
					);
					const updatedMV =
						type === "EXPECTED_RETURNS_VOLATILITY"
							? ({
									name,
									forecastHorizon: marketScenario.forecastHorizon,
									defaultRegimeProbabilities: marketScenario.regimeProbabilities,
									userRegimeProbabilities: marketScenario.regimeUserProbability,
									flexibleExpectedReturnsVolatility: { assetClasses: flexibleExpectedReturnsVolatility },
									positioningIndicators: marketScenario.positioningIndicators,
									marketViewType: type,
									custom: isCustom,
									scenarioId: (currentScenarioId ?? undefined) as CreateMarketViewRequest["scenarioId"],
							  } satisfies CreateMarketViewRequest)
							: ({
									name,
									forecastHorizon: marketScenario.forecastHorizon,
									defaultRegimeProbabilities: marketScenario.regimeProbabilities,
									userRegimeProbabilities: marketScenario.regimeUserProbability,
									positioningIndicators: marketScenario.positioningIndicators,
									flexibleExpectedReturnsVolatility: marketScenario.flexibleExpectedReturnsVolatility,
									custom: isCustom,
									marketViewType: type,
									scenarioId: (currentScenarioId ?? undefined) as CreateMarketViewRequest["scenarioId"],
							  } satisfies CreateMarketViewRequest);
					await marketViewApi.createMarketView(updatedMV);
					setModalShow(false);
					push("PortfoliosStudio", { tab: PortfolioStudioTab.MarketViews });
				},
				{ area: "market-views-settings", attemptedOperation: { message: "unable to create market view" } },
			);
		},
		[currentScenarioId, isCustom, marketScenario, marketViewApi, originalScenario, push, setModalShow, type],
	);

	const scenarioOptions = useMemo(() => {
		return (
			selectableMarketViews?.map((x, i) => ({ label: x.label ?? `Untitled ${i}`, value: x.id ?? `untitled_${i}` })) ??
			[]
		);
	}, [selectableMarketViews]);

	useEffect(() => {
		if (saveModalShow === true) {
			reset({
				marketViewName: "",
				invalidMarketViewName: [] as string[],
			});
		}
	}, [saveModalShow, reset]);

	return (
		<Card>
			{/* <div className="mb-4 pb-4 border-b" style={{ borderColor: themeCSSVars.palette_N100 }}>
				<Text type="Body/L/Book">
					Your market view represents your expectations or predictions about the future performance of specific asset
					classes, sectors, or markets. You can provide your market view or use the AI&apos;s forecasted market view as
					a starting point. Customize the market view according to your insights or select from the saved market views
					created by you.
				</Text>
			</div> */}
			<Switch
				case={derivedData.type}
				match={{
					[MarketViewType.PositioningIndicators]: () =>
						isLoadingDefaultScenario ? (
							<ProgressBar value="indeterminate" />
						) : (
							<MarketViewPositioning
								mode={derivedData.mode}
								marketScenarioProvider={async (id) => {
									let loadedScenario = await axiosExtract(
										marketViewApi.getMarketViewScenario(id, undefined, false, isCustom),
									);

									if (action === "new") {
										loadedScenario = {
											...loadedScenario,
											positioningIndicators: {
												...loadedScenario.positioningIndicators,
												positioningIndicators: isCustom
													? loadedScenario.positioningIndicators?.positioningIndicators
													: loadedScenario.positioningIndicators?.positioningIndicators?.map((indicator) => {
															const mapped = {
																...indicator,
																mdotmCommentary: defaultScenario?.positioningIndicators?.positioningIndicators?.find(
																	(x) => x.microAssetClass === indicator.microAssetClass,
																)?.commentary,
																mdotmPositioning: defaultScenario?.positioningIndicators?.positioningIndicators?.find(
																	(x) => x.microAssetClass === indicator.microAssetClass,
																)?.defaultPositioning,
															};
															if (id !== MarketScenarioIds.SphereForecastPositioningOneMonth) {
																mapped.commentary = "";
															}

															if (
																id !== MarketScenarioIds.UserForecastPositioningOneMonth &&
																id !== MarketScenarioIds.SphereForecastPositioningOneMonth
															) {
																mapped.mdotmCommentary = undefined;
																mapped.mdotmPositioning = undefined;
															}

															return mapped;
													  }),
											},
										};
									}
									setOriginalScenario(loadedScenario);
									return loadedScenario; /* TODO: creationDate is missing from API */
								}}
								marketScenario={marketScenario}
								onMarketScenarioChange={setMarketScenario}
								scenarioOptions={scenarioOptions}
								disableScenarioSelection={isClone}
								selectedScenarioId={currentScenarioId}
								onSelectedScenarioChange={setCurrentScenarioId}
								canReadCommentary={marketViewSettings?.setting.commentaryEditorVisible}
								alias={marketViewSettings?.alias.assetClassAliases}
								header={{ hide: action !== "new" }}
								marketViewSettings={isCustom ? marketViewSettings?.setting : undefined}
								hideOutlookToopltip={
									isCustom ||
									!(
										currentScenarioId === MarketScenarioIds.UserForecastPositioningOneMonth ||
										currentScenarioId === MarketScenarioIds.SphereForecastPositioningOneMonth
									)
								}
							/>
						),
					[MarketViewType.ExpectedReturnsVolatility]: () => (
						<MarketViewProbabilities
							mode={derivedData.mode}
							marketScenarioProvider={async (id) => {
								const loadedScenario = await axiosExtract(
									marketViewApi.getMarketViewScenario(id, undefined, false, isCustom),
								);
								setOriginalScenario(loadedScenario);
								return loadedScenario;
							}}
							marketScenario={marketScenario}
							onMarketScenarioChange={setMarketScenario}
							scenarioOptions={scenarioOptions}
							disableScenarioSelection={isClone}
							selectedScenarioId={currentScenarioId}
							onSelectedScenarioChange={setCurrentScenarioId}
							canReadCommentary={marketViewSettings?.setting.commentaryEditorVisible}
							alias={marketViewSettings?.alias.assetClassAliases}
							header={{ hide: action !== "new" }}
							marketViewSettings={isCustom ? marketViewSettings?.setting : undefined}
							hidePositioning={action !== "new"}
						/>
					),
				}}
			/>
			<Dialog
				size="medium"
				show={saveModalShow}
				onClose={() => setModalShow(false)}
				header={
					<div className="text-xl font-bold">
						<EllipsisText text="Market View name" />
					</div>
				}
				onSubmitAsync={async () => {
					if (!observedInvalidMarketViewName.includes(observedMarketViewName)) {
						setValue("invalidMarketViewName", [...observedInvalidMarketViewName, observedMarketViewName]);
					}

					await handleSubmit(async ({ marketViewName }) => {
						await onSubmit(marketViewName);
					}, console.log)();
				}}
				footer={
					<DialogFooter
						primaryAction={
							<SubmitButton size="small" data-qualifier="MarketViewWorkspace/SaveDialog/Save">
								{t("BUTTON.SAVE")}
							</SubmitButton>
						}
						neutralAction={
							<Button
								palette="tertiary"
								size="small"
								onClick={() => setModalShow(false)}
								data-qualifier="MarketViewWorkspace/SaveDialog/Cancel"
							>
								{t("CANCEL")}
							</Button>
						}
					/>
				}
			>
				<FormFields.Text
					classList=""
					control={control}
					formState={formState}
					name="marketViewName"
					label="Name"
					placeholder="Insert market view name"
				/>
			</Dialog>
			<LeavePrompt
				title={t("NOTIFICATION_SETTINGS.LEAVE_PROMPT.TITLE")}
				when={
					originalScenario === null || action === "view"
						? false
						: formState.isSubmitting === false && fastDeepEqual(originalScenario, marketScenario) === false
				}
				pathToNotBlock={["/workspace_market"]}
			>
				{t("NOTIFICATION_SETTINGS.LEAVE_PROMPT.MESSAGE")}
			</LeavePrompt>
		</Card>
	);
};

export default MarketViewWorkSpace;
