import type { OrderBy } from "@mdotm/mdotui/components";
import { persist, createJSONStorage, devtools } from "zustand/middleware";
import env from "$root/env";
import { createWithEqualityFn } from "zustand/traditional";
import fastDeepEqual from "fast-deep-equal";
import type { FieldKey } from "$root/api/api-gen";

export const usePortfolioStudioTableSettings = createWithEqualityFn(
	devtools(
		persist<{
			portfolioCompositionEnhanceOrderByName: OrderBy[];
			portfolioCompositionOrderByName: OrderBy[];
			portfolioListOrderByName: OrderBy<FieldKey>[];
			referenceListOrderByName: OrderBy[];
			universeListOrderByName: OrderBy[];
			marketViewListOrderByName: OrderBy[];
			setPortfolioCompositionEnhanceOrderByName(newOrderBy: OrderBy[]): void;
			setPortfolioCompositionOrderByName(newOrderBy: OrderBy[]): void;
			setPortfolioListOrderByName(newOrderBy: OrderBy<FieldKey>[]): void;
			setReferenceListOrderByName(newOrderBy: OrderBy[]): void;
			setUniverseListOrderByName(newOrderBy: OrderBy[]): void;
			setMarketViewListOrderByName(newOrderBy: OrderBy[]): void;
		}>(
			(set) => ({
				portfolioCompositionOrderByName: [],
				portfolioCompositionEnhanceOrderByName: [],
				portfolioListOrderByName: [{ columnName: "INCEPTION_DATE", direction: "asc" }],
				referenceListOrderByName: [],
				universeListOrderByName: [],
				marketViewListOrderByName: [],
				setPortfolioCompositionEnhanceOrderByName: (portfolioCompositionEnhanceOrderByName) =>
					set({ portfolioCompositionEnhanceOrderByName }),
				setPortfolioCompositionOrderByName: (portfolioCompositionOrderByName) =>
					set({ portfolioCompositionOrderByName }),
				setPortfolioListOrderByName: (portfolioListOrderByName) => set({ portfolioListOrderByName }),
				setReferenceListOrderByName: (referenceListOrderByName) => set({ referenceListOrderByName }),
				setUniverseListOrderByName: (universeListOrderByName) => set({ universeListOrderByName }),
				setMarketViewListOrderByName: (marketViewListOrderByName) => set({ marketViewListOrderByName }),
			}),
			{
				name: "portfolio-studio-table-settings",
				storage: createJSONStorage(() => localStorage),
			},
		),
		{ enabled: env.appEnv !== "production" },
	),
	(a, b) =>
		fastDeepEqual(
			{
				portfolioCompositionOrderByName: (a as any)?.portfolioCompositionOrderByName,
				portfolioCompositionEnhanceOrderByName: (a as any)?.portfolioCompositionEnhanceOrderByName,
				portfolioListOrderByName: (a as any)?.portfolioListOrderByName,
				referenceListOrderByName: (a as any)?.referenceListOrderByName,
				universeListOrderByName: (a as any)?.universeListOrderByName,
				marketViewListOrderByName: (a as any)?.marketViewListOrderByName,
			},
			{
				portfolioCompositionOrderByName: (b as any)?.portfolioCompositionOrderByName,
				portfolioCompositionEnhanceOrderByName: (b as any)?.portfolioCompositionEnhanceOrderByName,
				portfolioListOrderByName: (b as any)?.portfolioListOrderByName,
				referenceListOrderByName: (b as any)?.referenceListOrderByName,
				universeListOrderByName: (b as any)?.universeListOrderByName,
				marketViewListOrderByName: (b as any)?.marketViewListOrderByName,
			},
		),
);
