import type { UserInstrumentImportResponse } from "$root/api/api-gen";
import {
	InstrumentsControllerApiFactory,
	IntegrationsControllerV3ApiFactory
} from "$root/api/api-gen";
import { runWithErrorReporting } from "$root/api/error-reporting/report";
import { useApiGen } from "$root/api/hooks";
import { useCrumbs } from "$root/components/Crumbs/useCrumbs";
import { DropzoneArea } from "$root/components/DropzoneArea";
import { PageHeader } from "$root/components/PageHeader";
import type { MinimumDialogProps } from "$root/components/spawnable/type";
import UploadButton from "$root/components/UploadButton";
import EmptyImportBox from "$root/functional-areas/instruments-editor/spawn/box/EmptyImportBox";
import ErrorImportBox from "$root/functional-areas/instruments-editor/spawn/box/ErrorImportBox";
import SuccessImportBox from "$root/functional-areas/instruments-editor/spawn/box/SuccessImportBox";
import InstrumentCustomizationTableV2, {
	userInstrumentsBaseQueryKey,
} from "$root/functional-areas/instruments/Instrument-customisation";
import { platformToast } from "$root/notification-system/toast";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { queryClient } from "$root/third-party-integrations/react-query";
import { downloadContentDisposition, ToastableError } from "$root/utils";
import {
	ActionText,
	Banner,
	Button,
	Collapsible,
	DefaultCollapsibleHeader,
	Dialog,
	DialogFooter,
	Icon,
	LoadingButton,
	SubmitButton,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { useAsync } from "@mdotm/mdotui/headless";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, ForEach, spawn } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { unpromisify } from "@mdotm/mdotui/utils";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export default function InstrumentCustomisationSetting(): JSX.Element {
	const crumbs = useCrumbs();
	return (
		<>
			<PageHeader
				title="Instrument customisation"
				crumbsV2={crumbs.mySettings("InstrumentCustomisation")}
				titleAction={
					<Button
						size="small"
						palette="primary"
						onClick={() => {
							spawnImportUserInstrumentDialog({
								async onImportFinished() {
									await queryClient.invalidateQueries({
										predicate(query) {
											return query.queryKey?.[0] === userInstrumentsBaseQueryKey;
										},
									});
								},
							});
						}}
					>
						<Icon icon="Dowload" size={16} classList="-rotate-90 mr-1" />
						Import
					</Button>
				}
			/>
			<InstrumentCustomizationTableV2 />
		</>
	);
}

type ImportUserInstrumentDialogProps = MinimumDialogProps & {
	onImportFinished?(): MaybePromise<void>;
};

const ACCEPTED_FILE = "*";

const BoxMap = {
	empty: <EmptyImportBox />,
	error: <ErrorImportBox />,
	success: <SuccessImportBox />,
} as const;

function ImportUserInstrumentDialog({
	show,
	onClose,
	onAnimationStateChange,
	onImportFinished,
}: ImportUserInstrumentDialogProps): JSX.Element {
	const { t } = useTranslation();
	const [userInstrument, setUserInstrument] = useState<
		| {
				file?: null;
				conversion?: UserInstrumentImportResponse;
		  }
		| {
				file: File;
				conversion: UserInstrumentImportResponse;
		  }
	>({ file: null });

	const instrumentsControllerApi = useApiGen(InstrumentsControllerApiFactory);
	const integrationsControllerV3Api = useApiGen(IntegrationsControllerV3ApiFactory);

	const handleDownloadTemplate = useCallback(
		() =>
			runWithErrorReporting(
				async () => {
					await integrationsControllerV3Api
						.getUploadTemplate("SPHERE_INSTRUMENT_IMPORT", {
							responseType: "blob",
						})
						.then(downloadContentDisposition);
				},
				{
					area: "instrument-customisation",
					attemptedOperation: {
						message: "try to download instrument template",
					},
				},
			),
		[integrationsControllerV3Api],
	);

	const handleFileChangeAsync = useAsync<void, [File | null]>({
		asyncFn: async (file) => {
			if (!file) {
				setUserInstrument({ file: null });
				return;
			}
			await runWithErrorReporting(
				async () => {
					try {
						const conversion = await axiosExtract(instrumentsControllerApi.importInstruments2(file));
						setUserInstrument({
							file,
							conversion,
						});
					} catch (error) {
						setUserInstrument({ file: null });
						throw new Error(String(error));
					}
				},
				{
					area: "instrument-customisation",
					attemptedOperation: {
						message: "try to convert a file",
					},
				},
			);
		},
	});

	const hasConversionErrors = useMemo(
		() => (userInstrument.conversion?.errors ?? []).length > 0,
		[userInstrument.conversion?.errors],
	);

	return (
		<Dialog
			size="large"
			show={show}
			onAnimationStateChange={onAnimationStateChange}
			onClose={onClose}
			header="Select"
			onSubmitAsync={async () => {
				await runWithErrorReporting(
					async () => {
						if (!userInstrument.conversion || !userInstrument.conversion.userInstrumentImports) {
							throw new ToastableError("Missing file", {
								cause: "undefined or null file not valid",
								icon: "Portfolio",
							});
						}

						await instrumentsControllerApi.updateInstrumentsByInputList2(
							userInstrument.conversion.userInstrumentImports,
						);

						platformToast({
							icon: "toolchart-table",
							children: "Sphere has taken over your request",
							severity: "success",
						});

						await onImportFinished?.();
					},
					{
						area: "instrument-customisation",
						attemptedOperation: {
							message: "import custom user instruments",
						},
					},
				);
			}}
			footer={
				<DialogFooter
					primaryAction={
						<SubmitButton palette="primary" disabled={hasConversionErrors}>
							{t("BUTTON.DONE")}
						</SubmitButton>
					}
					neutralAction={
						<Button palette="tertiary" onClick={onClose}>
							{t("BUTTON.CANCEL")}
						</Button>
					}
				/>
			}
		>
			<>
				<ol className="list-decimal ml-4 mb-4">
					<li>
						Download the <ActionText onClickAsync={handleDownloadTemplate}>template</ActionText>
					</li>
					<li>Insert your instruments and their data</li>
					<li>Upload the edited template. Only files formatted like the template will be accepted.</li>
				</ol>
				<Banner severity="info" title="Overwrite operation" classList="mb-4">
					Please note that uploading instruments data will overwrite any existing information.
				</Banner>
				<DropzoneArea
					disabled={handleFileChangeAsync.loading}
					onChange={unpromisify(async (file: File | null) => {
						await handleFileChangeAsync.run(file);
					})}
					accept={ACCEPTED_FILE}
					childrenWrapperAppearance={{
						classList: {
							[`relative rounded flex flex-1 gap-4 p-4 justify-between items-center border-2 mb-4`]: true,
							[`bg-[color:${themeCSSVars.palette_N50}] border-dashed border-[color:${themeCSSVars.palette_N500}]`]:
								!userInstrument.file,
							[`bg-[color:${themeCSSVars.palette_N0}] border-[color:${themeCSSVars.palette_P600}]`]: Boolean(
								userInstrument.file && !hasConversionErrors,
							),
							[`bg-[color:${themeCSSVars.palette_N0}] border-[color:${themeCSSVars.palette_D500}]`]:
								hasConversionErrors,
						},
					}}
				>
					<div className="flex gap-2 items-center flex-1">
						{userInstrument.file && !hasConversionErrors ? (
							<>
								{BoxMap.success}
								<p className="font-semibold">{userInstrument.file.name}</p>
							</>
						) : hasConversionErrors ? (
							<>
								{BoxMap.error}
								<p className="font-semibold">{userInstrument.file?.name}</p>
							</>
						) : (
							<>
								{BoxMap.empty}
								<p className="font-semibold">Select a file or drag and drop here</p>
							</>
						)}
					</div>

					{userInstrument.file ? (
						<LoadingButton
							loading={handleFileChangeAsync.loading}
							palette="tertiary"
							size="small"
							onClick={() => setUserInstrument({ file: null })}
						>
							<Icon icon="Delete" size={20} color={themeCSSVars.palette_N500} />
						</LoadingButton>
					) : (
						<UploadButton
							loading={handleFileChangeAsync.loading}
							size="small"
							label={t("BUTTON.SELECT")}
							onChange={unpromisify(async (file: File | null) => {
								await handleFileChangeAsync.run(file);
							})}
							accept={ACCEPTED_FILE}
						/>
					)}
				</DropzoneArea>

				{userInstrument.file && userInstrument.conversion?.errors && userInstrument.conversion?.errors.length > 0 && (
					<Collapsible
						header={(params) => (
							<DefaultCollapsibleHeader
								{...params}
								classList={{
									[`!bg-transparent min-h-[40px] flex items-center`]: true,
								}}
							>
								{userInstrument.file.name}
							</DefaultCollapsibleHeader>
						)}
						classList="mb-2"
					>
						<ForEach collection={userInstrument.conversion?.errors ?? []}>
							{({ item: e }) => (
								<div className="h-[40px] border-b border-[#eeeef1] transition-[background] data-[forceHover]:bg-[#EFF0F3] hover:bg-[#EFF0F3] flex space-x-2 items-center px-2">
									<p className="p-2">{e.errorMessage}</p>
								</div>
							)}
						</ForEach>
					</Collapsible>
				)}

				{userInstrument.conversion?.userInstrumentImports &&
					userInstrument.conversion?.userInstrumentImports.length > 0 &&
					!hasConversionErrors && (
						<div className="mb-4">
							<Banner severity="success" title="File uploaded correctly">
								Your file has been converted by Sphere. Click &quot;
								<strong>Save</strong>&quot; to start importing your instruments.
							</Banner>
						</div>
					)}
			</>
		</Dialog>
	);
}

type SpawnImportUserInstrumentDialogProps = Omit<ImportUserInstrumentDialogProps, "onClose" | "show">;
function spawnImportUserInstrumentDialog(props: SpawnImportUserInstrumentDialogProps): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ resolve, show, onHidden }) => (
			<ImportUserInstrumentDialog
				{...props}
				show={show}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
				onClose={() => resolve()}
				onImportFinished={async () => {
					await props.onImportFinished?.();
					resolve();
				}}
			/>
		)),
	);
}
