import type { SelectableBasket, Tag, UserInstrumentClassificationDto, UserInstrumentDto } from "$root/api/api-gen";
import {
	InstrumentsCustomizationControllerV3ApiFactory,
	InvestmentEnhancementReportsControllerApiFactory,
	InvestmentReportsControllerApiFactory,
	StandardInstrumentsControllerApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { IconWallBase } from "$root/components/IconWall";
import { ReactQueriesWrapperBase, ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import type { MinimumDialogProps } from "$root/components/spawnable/type";
import type { useInstrumentColumnPreferenceResultProps } from "$root/functional-areas/instruments/hooks";
import { useInstrumentsColumn } from "$root/functional-areas/instruments/hooks";
import type { TagFilter } from "$root/functional-areas/instruments/Instrument-customisation/SidePanelColumnFilters";
import InstrumentCustomisationTableSidePanel from "$root/functional-areas/instruments/Instrument-customisation/SidePanelColumnFilters";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { exhaustiveMatchingGuard } from "$root/utils/collections";
import { noRefetchDefaults, useQueryNoRefetch } from "$root/utils/react-query";
import type { BaseHScrollTableProps, PaginationResponse, TableColumn } from "@mdotm/mdotui/components";
import {
	BaseHScrollTable,
	Button,
	ButtonGroupRadio,
	Checkbox,
	CircularProgressBar,
	Dialog,
	DialogFooter,
	FormField,
	Label,
	LocalOverlay,
	Pagination,
	ProgressBar,
	Row,
	SubmitButton,
	Text,
	useSelectableTableColumn,
} from "@mdotm/mdotui/components";
import type { MaybePromise, MultiSelectCtx } from "@mdotm/mdotui/headless";
import { useMultiSelect } from "@mdotm/mdotui/headless";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, spawn } from "@mdotm/mdotui/react-extensions";
import { useIsFetching, useQueries } from "@tanstack/react-query";
import { Map, Set } from "immutable";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { InstrumentEditorEntity, InstrumentEditorEntry } from "../../const";
import { useInstrumentCustomisationProvider, useInstrumentStandardProvider } from "./hooks";
import { spawnInstrumentDialog, type AddMissingInstrumentDialogProps } from "./missing-instrument-adder";
import { InstrumentPool, useInstrumentAdderState } from "./store";
import type { PaginatedUserInstrumentsRequestProps } from "$root/functional-areas/instruments/Instrument-customisation";
import {
	createListInstrumentsRequest,
	getPaginatedUserInstruments,
} from "$root/functional-areas/instruments/Instrument-customisation";
import { usePaginationTools } from "$root/hooks/usePaginationTools";
import { queryClientKeys } from "$root/third-party-integrations/react-query";

type InstrumentAdderDialogProps = {
	entity: InstrumentEditorEntity;
	uuid?: string;
	onSubmitAsync(selectedTickers: string[] /** tickerName[] */): MaybePromise<void>;
	onSubmitMissingInstrument: AddMissingInstrumentDialogProps["onSubmit"];
	instrumentsAlreadySelected: InstrumentEditorEntry[];
};

function InstrumentAddersDialog(props: InstrumentAdderDialogProps & MinimumDialogProps) {
	const { show, onAnimationStateChange, onClose, ...rest } = props;
	const { entity, uuid, instrumentsAlreadySelected, onSubmitAsync } = rest;
	const investmentApi = useApiGen(InvestmentReportsControllerApiFactory);
	const investmentEnhanceApi = useApiGen(InvestmentEnhancementReportsControllerApiFactory);
	const instrumentCustomizationV3Api = useApiGen(InstrumentsCustomizationControllerV3ApiFactory);
	const { instrumentAdderStore, setInstrumentAdderStore } = useInstrumentAdderState();

	const { t } = useTranslation();
	// work only if they resolve before InstrumentBlock run
	const instrumentCustomisation = useInstrumentCustomisationProvider();
	const instrumentStandard = useInstrumentStandardProvider();

	const commonInstrumentsQuery = useQueries({
		queries: [
			{
				...noRefetchDefaults,
				queryKey: [queryClientKeys.queryAddInstrumentsEntitySummary, uuid],
				async queryFn() {
					switch (entity) {
						case "INVESTMENT": {
							if (!uuid) {
								return { entity };
							}
							const { universeIdentifier } = await axiosExtract(investmentApi.getInvestmentSummary(uuid));
							return { uuid, universeIdentifier, entity };
						}
						case "INVESTMENT_ENHANCEMENT": {
							if (!uuid) {
								return { entity };
							}

							const { universeIdentifier } = await axiosExtract(
								investmentEnhanceApi.getInvestmentEnhancementSummary(uuid),
							);
							return { uuid, universeIdentifier, entity };
						}
						case "INVESTMENT_DRAFT":
						case "TARGET_INVESTMENT":
						case "UNIVERSE":
						case "BENCHMARK": {
							if (!uuid) {
								return { entity };
							}

							return { uuid, entity };
						}
						default:
							exhaustiveMatchingGuard(entity);
					}
				},
				staleTime: 1000 * 60 * 5,
				cacheTime: 1000 * 60 * 5,
			},
			{
				...noRefetchDefaults,
				queryKey: [queryClientKeys.querySelectableInstrumentBaskets],
				queryFn: () => axiosExtract(instrumentCustomizationV3Api.geSelectableBaskets()),
				staleTime: 1000 * 60 * 5,
				cacheTime: 1000 * 60 * 5,
			},
		],
	});

	const instrumentsSetAlreadySelected = useMemo(
		() =>
			Set(
				instrumentsAlreadySelected.flatMap((x) =>
					x.ticker && x.proxyOverwriteType !== "PORTFOLIO_MIXED" ? [x.ticker] : [],
				),
			),
		[instrumentsAlreadySelected],
	);

	const selectionCtx = useMultiSelect<string>({
		alwaysSelected: instrumentsSetAlreadySelected,
	});

	const queryInstrumentRowsKeyIsFetching = useIsFetching({
		predicate(query) {
			const queryKey = query.queryKey?.[0];
			return queryKey === queryClientKeys.queryInstrumentsAddableInComposition;
		},
	});

	return (
		<Dialog
			size="screen"
			noValidate
			show={show}
			onClose={onClose}
			header="Select"
			onAnimationStateChange={onAnimationStateChange}
			footer={
				<DialogFooter
					neutralAction={
						<Button palette="tertiary" onClick={onClose}>
							{t("BUTTON.CANCEL")}
						</Button>
					}
					primaryAction={
						<SubmitButton disabled={queryInstrumentRowsKeyIsFetching > 0}>{t("BUTTON.CONFIRM")}</SubmitButton>
					}
				/>
			}
			onSubmitAsync={() => onSubmitAsync(selectionCtx.selection.valueSeq().toArray())}
		>
			<div className="h-[calc(100dvh_-_214px)] flex-1 flex flex-col">
				<ReactQueriesWrapperBase
					queries={[
						...commonInstrumentsQuery,
						instrumentStandard.queryStandardClassification,
						...instrumentCustomisation.queryInstrumentsColumn,
					]}
				>
					{([minimumSummary, selectableBaskets]) => (
						<InstrumentBlock
							onChangeInstrumentPool={(newInstrumentsPool) => {
								setInstrumentAdderStore((latestVal) => ({
									...latestVal,
									instrumentPool: newInstrumentsPool,
								}));
							}}
							instrumentCustomisation={instrumentCustomisation}
							instrumentStandard={instrumentStandard}
							instrumentPool={instrumentAdderStore.instrumentPool}
							selectionCtx={selectionCtx}
							minimumSummary={minimumSummary}
							selectableBaskets={selectableBaskets}
							instrumentsSetAlreadySelected={instrumentsSetAlreadySelected}
							{...rest}
						/>
					)}
				</ReactQueriesWrapperBase>
			</div>
		</Dialog>
	);
}

type MinimumEntitySummary =
	| {
			uuid: string;
			universeIdentifier: string | undefined;
			entity: "INVESTMENT" | "INVESTMENT_ENHANCEMENT";
	  }
	| {
			uuid: string;
			entity: "UNIVERSE" | "BENCHMARK" | "TARGET_INVESTMENT" | "INVESTMENT_DRAFT";
	  }
	| {
			uuid?: undefined;
			entity:
				| "INVESTMENT"
				| "TARGET_INVESTMENT"
				| "INVESTMENT_DRAFT"
				| "INVESTMENT_ENHANCEMENT"
				| "UNIVERSE"
				| "BENCHMARK";
	  };

type InstrumentCustomisationAdderProps = {
	instrumentPool: InstrumentPool;
	minimumSummary: MinimumEntitySummary;
	selectionCtx: MultiSelectCtx<string>;
	selectableBaskets: SelectableBasket[];
	onChangeInstrumentPool(newPool: InstrumentPool): void;
	instrumentsSetAlreadySelected: Set<string>;
	instrumentCustomisation: Omit<useInstrumentColumnPreferenceResultProps, "queryInstrumentsColumn">;
	instrumentStandard: Omit<useInstrumentColumnPreferenceResultProps, "queryInstrumentsColumn">;
} & InstrumentAdderDialogProps;

function InstrumentBlock(props: InstrumentCustomisationAdderProps) {
	const {
		selectableBaskets,
		minimumSummary,
		selectionCtx,
		instrumentPool,
		onChangeInstrumentPool,
		instrumentCustomisation,
		instrumentStandard,
		...rest
	} = props;
	const standardInstrumentsApi = useApiGen(StandardInstrumentsControllerApiFactory);
	const standardInstrumentProvider = useCallback(
		async (params: PaginatedUserInstrumentsRequestProps): Promise<PaginationResponse<UserInstrumentDto>> => {
			const paginationResult = await axiosExtract(
				standardInstrumentsApi.listInstruments(createListInstrumentsRequest(params)),
			);

			return {
				items: paginationResult.items ?? [],
				total: paginationResult.pagination?.total ?? 0,
			};
		},
		[standardInstrumentsApi],
	);

	const rowsProvider = useMemo(() => {
		if (instrumentPool === InstrumentPool.mdotm) {
			return standardInstrumentProvider;
		}

		return getPaginatedUserInstruments;
	}, [instrumentPool, standardInstrumentProvider]);

	const instrumentColumnPreference = useMemo(() => {
		if (instrumentPool === InstrumentPool.mdotm) {
			return instrumentStandard;
		}

		return instrumentCustomisation;
	}, [instrumentCustomisation, instrumentPool, instrumentStandard]);

	return (
		<InstrumentBlockInner
			selectionCtx={selectionCtx}
			rowsProvider={rowsProvider}
			instrumentColumnPreference={instrumentColumnPreference}
			selectableBasketProvider={() => Promise.resolve(selectableBaskets)}
			selectableBaskets={selectableBaskets}
			selectableUserClassificationsProvider={() => Promise.resolve(instrumentColumnPreference.columnsMetadata)}
			minimumSummary={minimumSummary}
			instrumentPool={instrumentPool}
			onChangeInstrumentPool={onChangeInstrumentPool}
			{...rest}
		/>
	);
}

type SidePanelFilterState = {
	selectedBaskets: SelectableBasket[];
	selectedFilters: Immutable.Map<string, TagFilter>;
};

const defaultSidePanelFilters: SidePanelFilterState = {
	selectedBaskets: [],
	selectedFilters: Map(),
};

type InstrumentCustomisationInnerProps = {
	rowsProvider(request: PaginatedUserInstrumentsRequestProps): Promise<PaginationResponse<UserInstrumentDto>>;
	selectableBasketProvider(): Promise<SelectableBasket[]>;
	selectableUserClassificationsProvider(): Promise<Array<UserInstrumentClassificationDto>>;
	instrumentColumnPreference: Omit<useInstrumentColumnPreferenceResultProps, "queryInstrumentsColumn">;
	selectionCtx: MultiSelectCtx<string>;
	instrumentPool: InstrumentPool;
	minimumSummary: MinimumEntitySummary;
	onChangeInstrumentPool(newPool: InstrumentPool): void;
	instrumentsSetAlreadySelected: Set<string>;
	selectableBaskets: SelectableBasket[];
} & InstrumentAdderDialogProps;

function InstrumentBlockInner({
	instrumentColumnPreference,
	rowsProvider,
	selectableBasketProvider,
	selectableUserClassificationsProvider,
	selectionCtx,
	minimumSummary,
	instrumentPool,
	onChangeInstrumentPool,
	selectableBaskets,
	...rest
}: InstrumentCustomisationInnerProps) {
	const alreadySelectedBasket = useMemo(() => {
		if (
			(minimumSummary.entity === "INVESTMENT_ENHANCEMENT" || minimumSummary.entity === "INVESTMENT") &&
			minimumSummary.uuid
		) {
			const matchedBasket = selectableBaskets.find((x) => x.basketIdentifier === minimumSummary.universeIdentifier);
			return matchedBasket ? [matchedBasket] : undefined;
		}
		return undefined;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [minimumSummary.entity, minimumSummary.uuid, selectableBaskets]);

	const { instrumentAdderStore, setInstrumentAdderStore } = useInstrumentAdderState();
	const [sidePanelFilters, setSidePanelFilters] = useState<SidePanelFilterState>({
		...defaultSidePanelFilters,
		selectedBaskets: alreadySelectedBasket ?? [],
	});

	const { orderBy, query, skip, take } = usePaginationTools();
	const { columnsMetadata } = instrumentColumnPreference;

	const activeUserColumns = useMemo(() => {
		return columnsMetadata
			.filter((column) => column.enabled && column.fieldType && column.classificationUuid)
			.map((column) => column.classificationUuid!);
	}, [columnsMetadata]);

	const selectedFilters = useMemo<Tag[] | undefined>(() => {
		const filters = sidePanelFilters.selectedFilters?.valueSeq().toArray();

		return filters
			?.filter((filter) => filter.classificationUuid && filter.values && filter.values.length > 0)
			.map((x) => ({
				...x,
				...(instrumentPool === InstrumentPool.mdotm
					? { standardClassificationId: x.classificationUuid as Tag["standardClassificationId"] }
					: {}),
			}));
	}, [instrumentPool, sidePanelFilters.selectedFilters]);

	const queryInstrumentsRows = useQueryNoRefetch(
		[
			queryClientKeys.queryInstrumentsAddableInComposition,
			orderBy.value,
			query.value,
			skip.value,
			activeUserColumns,
			sidePanelFilters.selectedBaskets,
			selectedFilters,
			instrumentPool,
			take,
		],
		{
			queryFn() {
				return rowsProvider({
					skip: skip.value,
					take: take.value,
					orderBy: orderBy.value,
					filters: {
						query: query.value,
						fields: activeUserColumns,
						selectedBaskets: sidePanelFilters.selectedBaskets,
						tags: selectedFilters,
					},
				});
			},
			keepPreviousData: true,
			staleTime: 1000 * 60 * 5,
			cacheTime: 1000 * 60 * 5,
		},
	);

	const rows = useMemo(() => {
		const { items } = queryInstrumentsRows.data ?? {};
		if (!items) {
			return [];
		}
		return items;
	}, [queryInstrumentsRows.data]);

	return (
		<div className="flex-1 flex min-h-0 min-w-0">
			<InstrumentCustomisationTableSidePanel
				key={instrumentPool}
				expand={instrumentAdderStore.expand}
				onExpandChange={() =>
					setInstrumentAdderStore((latest) => ({
						...latest,
						expand: !latest.expand,
					}))
				}
				selectableBasketProvider={selectableBasketProvider}
				selectableUserClassificationsProvider={selectableUserClassificationsProvider}
				initialInstrumentCustomisationSettings={{
					search: query.value,
					selectedBaskets: sidePanelFilters.selectedBaskets,
					selectedfilters: sidePanelFilters.selectedFilters,
				}}
				onChangeInstrumentCustomisationSettings={(newSettings) => {
					const { search, ...filters } = newSettings;
					query.set(search);
					setSidePanelFilters((latest) => ({
						...latest,
						selectedBaskets: filters.selectedBaskets ?? [],
						selectedFilters: filters.selectedfilters ?? Map(),
					}));
				}}
				noManLand={
					<FormField
						label={({ htmlFor }) => (
							<Row alignItems="center" gap={8}>
								<Label htmlFor={htmlFor} classList="mb-px">
									Instrument pool
								</Label>
							</Row>
						)}
						classList="mb-2"
					>
						<ButtonGroupRadio
							options={[
								{
									value: InstrumentPool.mdotm,
									children: "MDOTM",
								},
								{
									value: InstrumentPool.instrumentCustomisation,
									children: "My Instruments",
								},
							]}
							onChange={(newPool) => {
								onChangeInstrumentPool(newPool);
								setSidePanelFilters((latest) => ({
									...latest,
									selectedFilters: Map(),
								}));
							}}
							value={instrumentPool}
						/>
					</FormField>
				}
				basketSelection={
					alreadySelectedBasket
						? {
								disabled: true,
								hideDelete: true,
								customTriggerMultiSelection() {
									return alreadySelectedBasket.at(0)?.basketName ?? "";
								},
						  }
						: undefined
				}
			/>
			<ReactQueryWrapperBase
				keepChildren
				query={queryInstrumentsRows}
				loadingFallback={<ProgressBar value="indeterminate" classList="mt-4" />}
			>
				{() => (
					// cannot destructure if i do it brakes
					<div className="min-h-0 flex flex-col px-4 py-6 min-w-0 flex-1 relative">
						<LocalOverlay
							show={queryInstrumentsRows.isFetching}
							style={{
								background: rows.length === 0 ? "white" : "transparent",
								zIndex: 5,
							}}
						>
							<CircularProgressBar value="indeterminate" />
						</LocalOverlay>
						<div className="flex items-center justify-between">
							<div className="flex items-end gap-0.5">
								<Text type="Body/L/Book">Selected pool:</Text>
								<Text type="Body/M/Medium">
									{instrumentPool === InstrumentPool.instrumentCustomisation ? "My Instruments" : "MDOTM"}
								</Text>
							</div>
							<Pagination
								skip={skip.value}
								take={take.value}
								total={queryInstrumentsRows.data?.total ?? 0 /** todo: change with api response */}
								onChange={skip.set}
							/>
						</div>
						<InstrumentBlockTable
							rows={rows}
							orderBy={orderBy.value}
							onOrderByChange={orderBy.set}
							selectionCtx={selectionCtx}
							instrumentColumnPreference={instrumentColumnPreference}
							minimumSummary={minimumSummary}
							isFetching={queryInstrumentsRows.isFetching}
							instrumentPool={instrumentPool}
							search={query.value}
							{...rest}
						/>
					</div>
				)}
			</ReactQueryWrapperBase>
		</div>
	);
}

type InstrumentTableAdderProps = Pick<
	BaseHScrollTableProps<UserInstrumentDto, string>,
	"rows" | "orderBy" | "onOrderByChange"
> & {
	instrumentColumnPreference: Omit<useInstrumentColumnPreferenceResultProps, "queryInstrumentsColumn">;
	selectionCtx: MultiSelectCtx<string>;
	minimumSummary: MinimumEntitySummary;
	onSubmitMissingInstrument: AddMissingInstrumentDialogProps["onSubmit"];
	instrumentsSetAlreadySelected: Set<string>;
	instrumentPool: InstrumentPool;
	isFetching?: boolean;
	search?: string;
} & InstrumentAdderDialogProps;

function InstrumentBlockTable(props: InstrumentTableAdderProps) {
	const {
		instrumentColumnPreference,
		selectionCtx,
		rows,
		minimumSummary,
		onSubmitMissingInstrument,
		instrumentsAlreadySelected,
		onOrderByChange,
		orderBy,
		isFetching,
		instrumentPool,
		search,
	} = props;

	const { entity } = minimumSummary;
	const { changeTableLayout, columnsMetadata } = instrumentColumnPreference;
	const {
		customizableColumns: instrumentColumns,
		tools,
		name,
	} = useInstrumentsColumn({
		allColumns: columnsMetadata,
		mode: "VIEW",
		changeTableLayout,
	});

	const {
		column: checkBoxColumn,
		rowClassList,
		toggle,
		multiSelectCtx,
	} = useSelectableTableColumn({
		rows,
		multiSelectCtx: selectionCtx,
		selectBy: (row) => row.tickerName!,
		mode: "checkbox",
	});

	const columns = useMemo<TableColumn<UserInstrumentDto>[]>(
		() => [
			// checkBoxColumn,
			{
				content: checkBoxColumn.content,
				header: (props) => {
					const pageSelectionSize = rows.filter((x) => multiSelectCtx.selection.has(x.tickerName!)).length;
					const isAllChecked = pageSelectionSize > 0 && rows.every((x) => multiSelectCtx.selection.has(x.tickerName!));
					const isPartiallyChecked = pageSelectionSize > 0 && !isAllChecked;
					const onChange = () => {
						if (isPartiallyChecked || pageSelectionSize === multiSelectCtx.alwaysSelected.size) {
							multiSelectCtx.setSelection(multiSelectCtx.selection.union(rows.map((row) => row.tickerName!)));
						} else {
							multiSelectCtx.setSelection(multiSelectCtx.selection.subtract(rows.map((row) => row.tickerName!)));
						}
					};
					return (
						<Row
							style={props.style}
							classList={props.classList}
							innerRef={props.innerRef}
							title={props.title}
							alignItems="center"
							justifyContent="center"
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							<Checkbox
								onClick={(e) => e.stopPropagation()}
								checked={isPartiallyChecked ? "indeterminate" : isAllChecked}
								onChange={onChange}
							/>
						</Row>
					);
				},
				name: "checkbox",
			} as TableColumn<UserInstrumentDto, any>,
			name,
			...instrumentColumns,
			...(instrumentPool === InstrumentPool.instrumentCustomisation ? [tools] : []),
		],
		[checkBoxColumn.content, name, instrumentColumns, instrumentPool, tools, rows, multiSelectCtx],
	);

	const hasLinkedUniverse = useMemo(() => {
		if (minimumSummary.entity === "INVESTMENT" || minimumSummary.entity === "INVESTMENT_ENHANCEMENT") {
			return minimumSummary.uuid && minimumSummary.universeIdentifier;
		}
		return false;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [minimumSummary.entity, minimumSummary.uuid]);

	if (rows.length === 0 && !isFetching && !hasLinkedUniverse) {
		return (
			<IconWallBase>
				<div className="flex-1 flex flex-col justify-center space-y-2">
					<Text as="p" type="Body/L/Bold" classList="text-center">
						No results matching the selected criteria
					</Text>
					<Button
						size="small"
						palette="secondary"
						classList="mx-auto"
						onClick={() => {
							const currentInstrumentSelection =
								rows?.flatMap((x) =>
									x.tickerName && multiSelectCtx.selection.has(x.tickerName) && x.alias ? [x.alias] : [],
								) ?? [];
							const aliasInComposition = instrumentsAlreadySelected.flatMap((x) => (x.alias ? [x.alias] : []));
							const instrumentInComposition = Set<string>(aliasInComposition.concat(currentInstrumentSelection));
							spawnInstrumentDialog({
								instrumentInComposition,
								defaultIdentifier: search,
								onSubmit: onSubmitMissingInstrument,
								entity,
							});
						}}
					>
						Add instrument manually
					</Button>
				</div>
			</IconWallBase>
		);
	}

	return (
		<BaseHScrollTable
			onOrderByChange={onOrderByChange}
			orderBy={orderBy}
			canMultiSort={false}
			highlightRowOnHover={false}
			classList="flex-1 z-0"
			palette="uniform"
			rows={rows}
			columns={columns}
			rowClassList={(row, rowIndex) => rowClassList(row, rowIndex)}
			pinnedColumns={[
				{
					name: name.name,
					side: "left",
				},
				{
					name: tools.name,
					side: "right",
				},
			]}
			onRowClick={({ tickerName }) => toggle(tickerName!)}
			noDataText="No instruments found"
		/>
	);
}

export type SpawnInstrumentAdderDialogProps = Omit<InstrumentAdderDialogProps, "show" | "onClose">;
export function spawnInstrumentAddersDialog(props: SpawnInstrumentAdderDialogProps): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ show, resolve, onHidden }) => (
			<InstrumentAddersDialog
				{...props}
				show={show}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
				onClose={() => resolve()}
				onSubmitAsync={async (instruments) => {
					await props.onSubmitAsync(instruments);
					resolve();
				}}
			/>
		)),
	);
}
