import type { EnableAlertDto, UserAlertDto } from "$root/api/api-gen";
import { UserNotificationControllerV2ApiFactory } from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { runWithErrorReporting } from "$root/api/error-reporting/report";
import { useApiGen } from "$root/api/hooks";
import { useCrumbs } from "$root/components/Crumbs/useCrumbs";
import { IconWalls } from "$root/components/IconWall";
import { PageHeader } from "$root/components/PageHeader";
import NotificationSettings from "$root/functional-areas/notification-settings";
import type { NotificationMap } from "$root/functional-areas/notification-settings/map";
import { notificationMap } from "$root/functional-areas/notification-settings/map";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { customObjectEntriesFn, useQueryNoRefetch } from "$root/utils";
import { ProgressBar } from "@mdotm/mdotui/components";
import { useCallback, useMemo } from "react";

const Notification = (): JSX.Element => {
	const crumbs = useCrumbs();

	const notificationApi = useApiGen(UserNotificationControllerV2ApiFactory);
	const notificationQuery = useQueryNoRefetch<UserAlertDto, Error>(["queryUserNotificationSetting"], {
		queryFn: () => axiosExtract(notificationApi.retrieveAllUserAlert()),
		onError: (err) =>
			reportPlatformError(err, "ERROR", "alerts", { message: "unable to retrieve notification settings" }),
	});

	const { isError, isLoading, data } = notificationQuery;

	const updateNotification = useCallback((node: UserAlertDto, map: NotificationMap): NotificationMap => {
		if (!node.children || node.children.length === 0) {
			return map;
		}

		node.children.forEach((childNode) => {
			if (childNode.name && map[childNode.name as keyof typeof map]) {
				map[childNode.name as keyof typeof map] = {
					checked: childNode.enable ?? false,
					threshold: childNode.threshold ?? map[childNode.name as keyof typeof map].threshold,
					type: childNode.type ?? map[childNode.name as keyof typeof map].type,
				};
				updateNotification(childNode, map);
			}
			updateNotification(childNode, map);
		});

		return map;
	}, []);

	const remapNotification = useMemo(() => {
		const clonedNotificationMap = { ...notificationMap } satisfies NotificationMap;

		if (!data) {
			return clonedNotificationMap;
		}
		return updateNotification(data, clonedNotificationMap);
	}, [data, updateNotification]);

	async function onSubmit(items: NotificationMap) {
		await runWithErrorReporting(
			async () => {
				const payload = customObjectEntriesFn(items).map(
					([key, value]) =>
						({
							enabled: value.checked,
							name: key,
							threshold: value.threshold,
							type: value.type,
						}) satisfies EnableAlertDto,
				);
				await notificationApi.editAlerts(payload);
				await notificationQuery.refetch();
			},
			{
				area: "alerts",
				attemptedOperation: { message: "failed to update notification settings", payload: JSON.stringify(items) },
			},
		);
	}

	return (
		<>
			<PageHeader title="Notification settings" crumbsV2={crumbs.mySettings("Notification settings")} />
			{isLoading && <ProgressBar value="indeterminate" />}
			{isError || isLoading ? (
				<div className="flex flex-1 h-[80vh]">
					{isLoading ? (
						<IconWalls.LoadingData opaque />
					) : (
						<IconWalls.ErrorData opaque redirect={{ title: "Home", path: "/" }} />
					)}
				</div>
			) : (
				<NotificationSettings notifications={remapNotification} onSubmit={onSubmit} />
			)}
		</>
	);
};

export default Notification;
