import { typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";
import CreatePortfolioIcon from "$root/components/glyphs/CreatePortfolioIcon";
import UploadPortfolioIcon from "$root/components/glyphs/UploadPortfolioIcon";
import { Dialog } from "@mdotm/mdotui/components";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, spawn } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { Link } from "react-router-dom";

type PortfolioCreationDialogProps = { show: boolean; onClose?(): void; onSubmit(): void };
function PortfolioCreationDialog(props: PortfolioCreationDialogProps) {
	return (
		<Dialog show={props.show} size="medium" onClose={props.onClose}>
			<div className="grid grid-cols-2 gap-4 ">
				<Link
					to={typedUrlForRoute("Portfolios/CreatePortfolio", { uuid: "" })}
					className={`hover:bg-[color:${themeCSSVars.palette_N50}] hover:rounded-md p-4`}
					data-qualifier="PortfolioStudio/PortfolioList/NewPortfolio/Create"
					onClick={() => props.onSubmit()}
				>
					<CreatePortfolioIcon classList="mx-auto" />
					<p className="text-lg font-semibold text-center">Create</p>
					<p className="text-center">Set constraints and targets and let Sphere create a portfolio for you</p>
				</Link>
				<Link
					to={typedUrlForRoute("Portfolios/ManualPortfolioCreation", {})}
					className={`hover:bg-[color:${themeCSSVars.palette_N50}] hover:rounded-md p-4`}
					data-qualifier="PortfolioStudio/PortfolioList/NewPortfolio/UploadEditor"
					onClick={() => props.onSubmit()}
				>
					<UploadPortfolioIcon classList="mx-auto" />
					<p className="text-lg font-semibold text-center">Upload and/or Set Weights</p>
					<p className="text-center">Import existing portfolios and set weights</p>
				</Link>
			</div>
		</Dialog>
	);
}

type spawnPortfolioCreationDialogProps = Omit<PortfolioCreationDialogProps, "onClose" | "show" | "onSubmit">;
export function spawnPortfolioCreationDialog(params: spawnPortfolioCreationDialogProps): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ resolve, show }) => (
			<PortfolioCreationDialog {...params} show={show} onClose={() => resolve()} onSubmit={() => resolve()} />
		)),
	);
}
