import type {
	ContributionEntry,
	EnhancementContributionResponse,
	InvestmentContributionResponse,
} from "$root/api/api-gen";
import { Card } from "$root/components/EvolvedPrint/components/Card";
import {
	TinyTableDataCell,
	TinyTableHeadCell,
	tinyTableHeadCellFontSize,
} from "$root/components/EvolvedPrint/components/table/tiny-table";
import type { PrintableProps } from "$root/components/EvolvedPrint/configuration";
import { InfoDelta } from "$root/components/InfoDelta";
import { formatNumber } from "$root/localization/formatters";
import type { ReportTemplateVariant } from "$root/pages/PortfolioStudioSettings/ReportEditor/report-latest";
import { BarGraphPCSvg } from "$root/ui-lib/charts";
import {
	DrillDownByValueMap,
	customObjectValuesFn,
	getGraphMarkers2,
	objSumRounded,
	roundCustomByStep,
} from "$root/utils";
import type { TableWithGroupsProps } from "@mdotm/mdotui/components";
import { BaseTableWithGroups, Text } from "@mdotm/mdotui/components";
import { overrideClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { groupBy } from "@mdotm/mdotui/utils";
import * as Immutable from "immutable";
import { useCallback, useMemo } from "react";
import { useReportTranslation } from "../translation";

export type ExAnteVolatilityContributionSelectionProps =
	| {
			selector: "assetClass";
			vsSelector: "microAssetClass";
	  }
	| {
			selector: "assetClass";
			vsSelector: "geography";
	  }
	| {
			selector: "microAssetClass";
			vsSelector: "geography";
	  }
	| {
			selector: "geography";
			vsSelector: "assetClass";
	  }
	| {
			selector: "geography";
			vsSelector: "microAssetClass";
	  };

const selectOptions = [
	{
		label: "Macro Asset Class vs Micro Asset Class",
		selector: "assetClass",
		vsSelector: "microAssetClass",
		key: "MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS",
	},
	{
		label: "Macro Asset Class vs Geography",
		selector: "assetClass",
		vsSelector: "geography",
		key: "MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY",
	},
	{
		label: "Micro Asset Class vs Geography",
		selector: "microAssetClass",
		vsSelector: "geography",
		key: "MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY",
	},
	{
		label: "Geography vs Macro Asset Class",
		selector: "geography",
		vsSelector: "assetClass",
		key: "MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY",
	},
	{
		label: "Geography vs Micro Asset Class",
		selector: "geography",
		vsSelector: "microAssetClass",
		key: "MACRO_GEOGRAPHY_VS_MICRO_ASSET_CLASS",
	},
] as const;

const _horizonOptions = [
	{
		label: "1m",
		value: "ONE_MONTH",
	},
	{
		label: "3m",
		value: "THREE_MONTHS",
	},
	{
		label: "6m",
		value: "SIX_MONTHS",
	},
	{
		label: "YTD",
		value: "YEAR_TO_DATE",
	},
	{
		label: "1y",
		value: "ONE_YEAR",
	},
	{
		label: "Since inception",
		value: "FROM_INCEPTION",
	},
];

type DrillDownProps = {
	index: number;
	value: string | number | ContributionEntry | undefined;
	computedData: Record<"volatilityValue" | "benchmarkVolatility" | "enhancedVolatility" | "max" | "min", number>;
};

type GetExAnteVolatilityProps = {
	graphLimit: {
		maxVolatility: number;
		minVolatility: number;
	};
	graphColumnMarkers: Array<{
		value: number;
		label: string;
	}>;
	list: Array<{ group: DrillDownProps & { hideHeader: boolean }; drilldown: DrillDownProps }>;
	opts: ExAnteVolatilityContributionSelectionProps;
};

export function getExAnteVolatilityContributionSplittableProps(
	compositionVolatility: EnhancementContributionResponse | InvestmentContributionResponse,
	comparison: ExAnteVolatilityContributionSelectionProps,
	variant: ReportTemplateVariant,
	filterSignificant: boolean,
): GetExAnteVolatilityProps {
	const enhanced = variant === "proposal";
	const contribution = {
		current: compositionVolatility.current,
		benchmark: "benchmark" in compositionVolatility ? compositionVolatility.benchmark ?? [] : [],
		proposal: "proposal" in compositionVolatility ? compositionVolatility.proposal ?? [] : [],
	};

	const aggregateData = {
		volatility: (contribution.current ?? []).map((el) => ({
			assetClass: el.assetClass,
			geography: el.geography,
			microAssetClass: el.microAssetClass,
			originalVolatility: el,
			volatilityValue: el.value,
			id: `${el.assetClass}${el.geography}${el.microAssetClass}`.split(" ").join("_"),
		})),
		benchmarkVolatility: (contribution.benchmark ?? []).map((el) => ({
			assetClass: el.assetClass,
			geography: el.geography,
			microAssetClass: el.microAssetClass,
			originalBenchmarkVolatility: el,
			benchmarkVolatility: el.value,
			id: `${el.assetClass}${el.geography}${el.microAssetClass}`.split(" ").join("_"),
		})),
		enhancedVolatility: (contribution.proposal ?? []).map((el) => ({
			assetClass: el.assetClass,
			geography: el.geography,
			microAssetClass: el.microAssetClass,
			originalEnhancedVolatility: el,
			enhancedVolatility: el.value,
			id: `${el.assetClass}${el.geography}${el.microAssetClass}`.split(" ").join("_"),
		})),
	};

	const decupledByID = [
		...new Set([
			...aggregateData.volatility.map((el) => el.id),
			...aggregateData.benchmarkVolatility.map((el) => el.id),
			...aggregateData.enhancedVolatility.map((el) => el.id),
		]),
	];

	const result = decupledByID.map((id) => {
		const currentVTK = aggregateData.volatility.find((el) => el.id === id);
		const currentBVTK = aggregateData.benchmarkVolatility.find((el) => el.id === id);
		const currentEVTK = aggregateData.enhancedVolatility.find((el) => el.id === id);
		return {
			...currentVTK,
			...currentBVTK,
			...currentEVTK,
		};
	});

	const opts = selectOptions.find(
		({ selector, vsSelector }) => comparison.selector === selector && comparison.vsSelector === vsSelector,
	)!;

	const computedContribution = (() => {
		const drilldown = DrillDownByValueMap(
			result ?? [],
			opts.selector,
			opts.vsSelector,
			{
				volatilityValue: (val) => objSumRounded(val, "volatilityValue"),
				benchmarkVolatility: (val) => objSumRounded(val, "benchmarkVolatility"),
				enhancedVolatility: (val) => objSumRounded(val, "enhancedVolatility"),
				max: (val) =>
					Math.max(
						objSumRounded(val, "volatilityValue"),
						objSumRounded(val, "benchmarkVolatility"),
						objSumRounded(val, "enhancedVolatility"),
					),
				min: (val) =>
					Math.min(
						objSumRounded(val, "volatilityValue"),
						objSumRounded(val, "benchmarkVolatility"),
						objSumRounded(val, "enhancedVolatility"),
					),
			},
			(a, b) => b.volatilityValue - a.volatilityValue,
		);

		if (!filterSignificant) {
			return drilldown;
		}
		return {
			...drilldown,
			drilldownData: drilldown.drilldownData
				.filter(
					(x) =>
						Math.abs(x.computedData.volatilityValue) >= 0.01 ||
						(enhanced && Math.abs(x.computedData.enhancedVolatility) >= 0.01),
				)
				.map((x) => ({
					...x,
					drilldown: x.drilldown.filter(
						(y) =>
							Math.abs(y.computedData.volatilityValue) >= 0.01 ||
							(enhanced && Math.abs(y.computedData.enhancedVolatility) >= 0.01),
					),
				})),
		};
	})();
	const { drilldownData } = computedContribution;

	const list = drilldownData.flatMap(({ drilldown, ...props }) =>
		drilldown.map((item, i) => ({
			drilldown: { ...item },
			group: { ...props, hideHeader: i > 0 },
		})),
	);

	const graphLimit = drilldownData.reduce<{ maxVolatility: number; minVolatility: number }>(
		(acc, group) => {
			const rowValues = group.drilldown.reduce(
				(a, row) => {
					return {
						maxVolatility: Math.max(a.maxVolatility, row.computedData.max, group.computedData.max),
						minVolatility: Math.min(a.minVolatility, row.computedData.min, group.computedData.min),
					};
				},
				{ maxVolatility: 0, minVolatility: 0 },
			);
			return {
				maxVolatility: Math.max(acc.maxVolatility, rowValues.maxVolatility),
				minVolatility: Math.min(acc.minVolatility, rowValues.minVolatility),
			};
		},
		{ maxVolatility: 0, minVolatility: 0 },
	);

	const scaledDrilldown = {
		maxVolatility: roundCustomByStep(graphLimit.maxVolatility, 1),
		minVolatility: roundCustomByStep(graphLimit.minVolatility, 1),
	};

	const graphColumnMarkers = getGraphMarkers2({
		min: scaledDrilldown.minVolatility,
		max: scaledDrilldown.maxVolatility,
		nOfMarkers: 10,
		suffix: "%",
	});

	return { list, graphLimit, graphColumnMarkers, opts: comparison };
}

const ExAnteVolatilityContribution = ({
	variant,
	comparison,
	graphLimit,
	graphColumnMarkers,
	list,
	firstRender,
}: PrintableProps<
	{
		variant: ReportTemplateVariant;
		data: InvestmentContributionResponse | EnhancementContributionResponse;
		comparison: ExAnteVolatilityContributionSelectionProps;
		graphLimit: GetExAnteVolatilityProps["graphLimit"];
		graphColumnMarkers: GetExAnteVolatilityProps["graphColumnMarkers"];
	},
	GetExAnteVolatilityProps["list"][number]
>): JSX.Element => {
	const { t } = useReportTranslation();
	const opts = selectOptions.find(
		(opt) => opt.selector === comparison.selector && opt.vsSelector === comparison.vsSelector,
	)!;

	const tableGroupRows = useMemo(() => {
		const groupList = groupBy(list, ({ group }) => `index-${group.index}`);
		return customObjectValuesFn(groupList).map((group) => ({
			...group![0].group,
			rows:
				group?.map(({ drilldown }) => ({
					...drilldown,
				})) ?? [],
		}));
	}, [list]);

	const columns = useCallback<TableWithGroupsProps<(typeof tableGroupRows)[number]>["columns"]>(
		({ expandColumn }) =>
			variant === "proposal"
				? [
						expandColumn,
						{
							name: "contribution",
							header: (props) => (
								<TinyTableHeadCell {...props}>
									{t(`REPORT_BUILDER.EX_ANTE_CONTRIBUTION_VOLATILITY.TABLE.${opts.selector}`)}
								</TinyTableHeadCell>
							),
							groupContent: (groupedRow, props) => (
								<TinyTableDataCell {...props} style={{ ...props.style }}>
									<strong>{groupedRow.value as any /* TODO: fix types */}</strong>
								</TinyTableDataCell>
							),
							content: (row, props) => <TinyTableDataCell {...props}>{row.value as any}</TinyTableDataCell>,
							minWidth: 200,
						},
						{
							name: "current",
							align: "end",
							header: (props) => (
								<TinyTableHeadCell {...props}>
									{t(`REPORT_BUILDER.EX_ANTE_CONTRIBUTION_VOLATILITY.TABLE.CURRENT`)}
								</TinyTableHeadCell>
							),
							groupContent: (groupedRow, props) => (
								<TinyTableDataCell {...props}>
									<strong className="text-[#005C8B] tabular-nums">
										{formatNumber(groupedRow.computedData.volatilityValue, 2)}%
									</strong>
								</TinyTableDataCell>
							),
							content: (row, props) => (
								<TinyTableDataCell {...props}>
									<span className="text-[#005C8B] tabular-nums">
										{formatNumber(row.computedData.volatilityValue, 2)}%
									</span>
								</TinyTableDataCell>
							),
							width: 74,
						},
						{
							name: "proposal",
							align: "end",
							header: (props) => (
								<TinyTableHeadCell {...props}>
									{t(`REPORT_BUILDER.EX_ANTE_CONTRIBUTION_VOLATILITY.TABLE.PROPOSAL`)}
								</TinyTableHeadCell>
							),
							groupContent: (groupedRow, props) => (
								<TinyTableDataCell {...props}>
									<strong className="text-[#00AEEF] tabular-nums">
										{formatNumber(groupedRow.computedData.enhancedVolatility, 2)}%
									</strong>
								</TinyTableDataCell>
							),
							content: (row, props) => (
								<TinyTableDataCell {...props}>
									<span className="text-[#00AEEF] tabular-nums">
										{formatNumber(row.computedData.enhancedVolatility, 2)}%
									</span>
								</TinyTableDataCell>
							),
							width: 74,
						},
						{
							name: "difference",
							header: (props) => (
								<TinyTableHeadCell {...props}>
									{t("REPORT_BUILDER.EX_ANTE_CONTRIBUTION_VOLATILITY.TABLE.DIFFERENCE")}
								</TinyTableHeadCell>
							),
							align: "end",
							groupContent: ({ computedData: { volatilityValue, enhancedVolatility } }, props) => (
								<TinyTableDataCell {...props}>
									<InfoDelta
										className="font-semibold"
										diff={(enhancedVolatility ?? 0) - (volatilityValue ?? 0)}
										enh={enhancedVolatility ?? 0}
									/>
								</TinyTableDataCell>
							),
							content: ({ computedData: { volatilityValue, enhancedVolatility } }, props) => (
								<TinyTableDataCell {...props}>
									<InfoDelta diff={(enhancedVolatility ?? 0) - (volatilityValue ?? 0)} enh={enhancedVolatility ?? 0} />
								</TinyTableDataCell>
							),
							width: 96,
						},
						{
							name: "graph",
							orderable: false,
							header: (props) => (
								<div
									style={{ ...props.style, minHeight: 31 }}
									className={overrideClassName(props.classList, "flex items-center justify-between grow")}
								>
									{graphColumnMarkers.map((m, i) => (
										<Text
											as="div"
											type="Body/S/BOLD-UPPERCASE"
											style={{ fontSize: tinyTableHeadCellFontSize }}
											color={themeCSSVars.palette_N500}
											key={`marker-${i}`}
										>
											{m.label}
										</Text>
									))}
								</div>
							),
							groupContent: ({ computedData: { volatilityValue, benchmarkVolatility, enhancedVolatility } }, props) => (
								<div
									style={{ ...props.style, minHeight: 31 }}
									className={overrideClassName(props.classList, "flex grow font-semibold")}
								>
									<BarGraphPCSvg
										classList="w-full"
										options={{
											animated: false,
											resize: true,
											marksLabels: false,
											markerStep: 1,
											scale: { max: graphLimit.maxVolatility, min: graphLimit.minVolatility },
											bars: {
												height: (31 - 4 * 2) / 3,
												gap: 0,
											},
											vPadding: 4,
										}}
										data={[
											{ value: volatilityValue, color: "#005C8B" },
											{ value: enhancedVolatility, color: "#00AEEF" },
											{ value: benchmarkVolatility, color: "#C6ACD9" },
										]}
									/>
								</div>
							),
							content: ({ computedData: { volatilityValue, benchmarkVolatility, enhancedVolatility } }, props) => (
								<div
									style={{ ...props.style, minHeight: 31 }}
									className={overrideClassName(props.classList, "flex grow")}
								>
									<BarGraphPCSvg
										classList="w-full"
										options={{
											animated: false,
											resize: true,
											marksLabels: false,
											markerStep: 1,
											scale: { max: graphLimit.maxVolatility, min: graphLimit.minVolatility },
											bars: {
												height: (31 - 4 * 2) / 3,
												gap: 0,
											},
											vPadding: 4,
										}}
										data={[
											{ value: volatilityValue, color: "#005C8B" },
											{ value: enhancedVolatility, color: "#00AEEF" },
											{ value: benchmarkVolatility, color: "#C6ACD9" },
										]}
									/>
								</div>
							),
							minWidth: 220,
						},
				  ]
				: [
						expandColumn,
						{
							name: "contribution",
							header: (props) => (
								<TinyTableHeadCell {...props}>
									{/* {contributionT.TABLE[selectOptions[0].selector as keyof typeof contributionT.TABLE]} */}
									{t("REPORT_BUILDER.EX_ANTE_CONTRIBUTION_VOLATILITY.TABLE.assetClass")}
								</TinyTableHeadCell>
							),
							groupContent: (groupedRow, props) => (
								<TinyTableDataCell {...props} style={{ ...props.style }}>
									<strong>{groupedRow.value as any /* TODO: fix types */}</strong>
								</TinyTableDataCell>
							),
							content: (row, props) => <TinyTableDataCell {...props}>{row.value as any}</TinyTableDataCell>,
							width: 200,
						},
						{
							name: "volatility",
							align: "end",
							header: (props) => (
								<TinyTableHeadCell {...props}>
									{t("REPORT_BUILDER.EX_ANTE_CONTRIBUTION_VOLATILITY.TABLE.PORTFOLIO_VOLATILITY")}
								</TinyTableHeadCell>
							),
							groupContent: (groupedRow, props) => (
								<TinyTableDataCell {...props}>
									<strong className="text-[#572C7F] tabular-nums">
										{formatNumber(groupedRow.computedData.volatilityValue, 2)}%
									</strong>
								</TinyTableDataCell>
							),
							content: (row, props) => (
								<TinyTableDataCell {...props}>
									<span className="text-[#572C7F] tabular-nums">
										{formatNumber(row.computedData.volatilityValue, 2)}%
									</span>
								</TinyTableDataCell>
							),
							width: 64,
						},
						{
							name: "graph",
							header: (props) => (
								<div
									style={{ ...props.style, minHeight: 31 }}
									className={overrideClassName(props.classList, "flex items-center justify-between grow")}
								>
									{graphColumnMarkers.map((m, i) => (
										<Text
											as="div"
											type="Body/S/BOLD-UPPERCASE"
											style={{ fontSize: tinyTableHeadCellFontSize }}
											color={themeCSSVars.palette_N500}
											key={`marker-${i}`}
										>
											{m.label}
										</Text>
									))}
								</div>
							),
							groupContent: ({ computedData: { volatilityValue, benchmarkVolatility } }, props) => (
								<div
									style={{ ...props.style, minHeight: 31 }}
									className={overrideClassName(props.classList, "flex grow font-semibold")}
								>
									<BarGraphPCSvg
										classList="w-full"
										options={{
											animated: false,
											resize: true,
											marksLabels: false,
											markerStep: 1,
											scale: { max: graphLimit.maxVolatility, min: graphLimit.minVolatility },
											bars: {
												height: (31 - 8 * 2) / 2,
												gap: 0,
											},
											vPadding: 8,
										}}
										data={[
											{ value: volatilityValue, color: "#572C7F" },
											{ value: benchmarkVolatility, color: "#C6ACD9" },
										]}
									/>
								</div>
							),
							content: ({ computedData: { volatilityValue, benchmarkVolatility } }, props) => (
								<div
									style={{ ...props.style, minHeight: 31 }}
									className={overrideClassName(props.classList, "flex grow")}
								>
									<BarGraphPCSvg
										classList="w-full"
										options={{
											animated: false,
											resize: true,
											marksLabels: false,
											markerStep: 1,
											scale: { max: graphLimit.maxVolatility, min: graphLimit.minVolatility },
											bars: {
												height: (31 - 8 * 2) / 2,
												gap: 0,
											},
											vPadding: 8,
										}}
										data={[
											{ value: benchmarkVolatility, color: "#C6ACD9" },
											{ value: volatilityValue, color: "#572C7F" },
										]}
									/>
								</div>
							),
							minWidth: 220,
						},
				  ],
		[graphColumnMarkers, graphLimit.maxVolatility, graphLimit.minVolatility, opts.selector, t, variant],
	);

	if (list.length === 0) {
		return <></>;
	}

	return (
		<Card
			title={
				firstRender
					? `${t("REPORT_BUILDER.EX_ANTE_CONTRIBUTION_VOLATILITY.TITLE")} - ${t(
							`REPORT_BUILDER.EX_ANTE_CONTRIBUTION_VOLATILITY.OPTIONS.${opts.key}`,
					  )}`
					: undefined
			}
			classList="pointer-events-none"
		>
			<BaseTableWithGroups
				groupRowKey={(group) => `index-${group.index}`}
				expandGroupByKey={Immutable.Set(tableGroupRows.map((group) => `index-${group.index}`))}
				groupedRows={tableGroupRows}
				groupRowClassList={(row) => (row.hideHeader ? "hidden" : "flex-1 !transition-none")}
				classList="pointer-events-none [&_.transition-\[height\]]:!transition-none [&_.transition-\[transform\,opacity\]]:!transition-none"
				columns={columns}
			/>
		</Card>
	);
};
export default ExAnteVolatilityContribution;
