/*
 * A collection of composable functions that convert common time primitives to milliseconds.
 *
 * E.g. setTimeout(() => console.log('done'), minutes(2) + seconds(30));
 */

export function seconds(x: number): number {
	return x * 1000;
}
export function minutes(x: number): number {
	return seconds(x * 60);
}
export function hours(x: number): number {
	return minutes(x * 60);
}
export function days(x: number): number {
	return hours(x * 24);
}
