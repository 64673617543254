import { typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";
import { ForEach } from "@mdotm/mdotui/react-extensions";
import { ActionText, AutoTooltip, Text, TooltipContent } from "@mdotm/mdotui/components";
import { Switch } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";

export function LinkedPortfolioCounterWithTooltip({
	type,
	othersCount = 0,
	portfolios = [],
}: {
	type: "References" | "Benchmark" | "Universe";
	portfolios?: Array<{ uuid?: string; name?: string }>;
	othersCount?: number;
}): JSX.Element {
	return (
		<AutoTooltip
			disabled={!portfolios.length}
			overrideColor={themeCSSVars.palette_N200}
			position="left"
			trigger={({ innerRef }) => (
				<div ref={innerRef} className="tabular-nums text-right min-w-[64px]">
					{(othersCount ?? 0) + (portfolios.length ?? 0)}
				</div>
			)}
		>
			<TooltipContent>
				<Text as="div" type="Body/M/Bold" classList="pb-2">
					<Switch
						case={type}
						match={{
							Benchmark: () => "This benchmark is linked to:",
							References: () => "This target portfolio is linked to:",
							Universe: () => "This universe is linked to:",
						}}
					/>
				</Text>
				<ul className="list-disc pl-4">
					<ForEach collection={portfolios}>
						{({ item }) => (
							<li>
								<ActionText
									href={typedUrlForRoute("PortfolioDetails", {
										portfolioUid: item.uuid!,
									})}
								>
									{item.name ?? "-"}
								</ActionText>
							</li>
						)}
					</ForEach>
				</ul>
				{othersCount > 0 && (
					<div className="px-2 pt-4">
						<div className="w-full h-0 border-b" style={{ borderColor: themeCSSVars.palette_N200 }} />
						<div className="pt-2">+{othersCount} Portfolios (of other users)</div>
					</div>
				)}
			</TooltipContent>
		</AutoTooltip>
	);
}
