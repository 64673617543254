import type { NotificationGroupedDTO } from "$root/api/api-gen";
import { createPersistentAtom } from "$root/third-party-integrations/zustand";
import type { Updater } from "@mdotm/mdotui/utils";
import { identity } from "@mdotm/mdotui/utils";
import fastDeepEqual from "fast-deep-equal";

export type NotificationMarker = {
	markAsRead: boolean;
	latestPortfolioPool: NotificationGroupedDTO[];
	latestMarketPool: NotificationGroupedDTO[];
};

export const notificationMarkerDefault: NotificationMarker = {
	latestMarketPool: [],
	latestPortfolioPool: [],
	markAsRead: false,
};

export const useNotificationCenterStore = createPersistentAtom<NotificationMarker>(
	notificationMarkerDefault,
	"notification-center",
);

export function useNotificationValue(): NotificationMarker {
	return useNotificationCenterStore((x) => x.value);
}

export function useNotificationCenterState(): {
	notificationCenterStatus: NotificationMarker;
	setNotificationCenterStatus: (newValue: NotificationMarker | Updater<NotificationMarker>) => void;
	reset(): void;
} {
	const { value: notificationCenterStatus, set: setNotificationCenterStatus } = useNotificationCenterStore(
		identity,
		(a, b) => fastDeepEqual(a.value, b.value),
	);
	return {
		notificationCenterStatus,
		setNotificationCenterStatus,
		reset: () => setNotificationCenterStatus(notificationMarkerDefault),
	};
}
