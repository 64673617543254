import { preconfiguredAxiosInstance } from "$root/api/config";
import { configReportPlatformError } from "$root/api/error-reporting";
import { hasAccess } from "$root/components/AuthorizationGuard";
import { eventBus, initServerEvents } from "$root/event-bus";
import { initHotjarCUser } from "$root/third-party-integrations/initHotjar";
import initIntercom, { disposeIntercomUser, initIntercomCUser } from "$root/third-party-integrations/initIntercom";
import initIubendaPriv from "$root/third-party-integrations/initIubendaPriv";
import { configMixPanel, initMixPanelCUser } from "$root/third-party-integrations/initMixPanel";
import { useUserStore } from ".";

export function initAuth(): void {
	preconfiguredAxiosInstance.interceptors.response.use(
		(response) => response,
		(error) => {
			if (error?.response?.status === 401) {
				eventBus.emit("unauthorized");
			}
			return Promise.reject(error);
		},
	);

	let serverSideEventsRef: { close(): void } | undefined;

	useUserStore.subscribe(({ value: newUser }) => {
		// Init Signed Chat Intercom
		disposeIntercomUser();
		if (newUser.signedIn) {
			initIubendaPriv();
			if (hasAccess(newUser, { requiredService: "HELP_CENTER" })) {
				initIntercom();
				initIntercomCUser(newUser);
			}
		}

		configReportPlatformError({
			uid: newUser.signedIn ? newUser.email : null,
		});
		// Configure and Init Signed Mixpanel
		configMixPanel({
			uid: newUser.email,
			impersonating: newUser.impersonating,
			isInternal: newUser.email ? newUser.email.toLowerCase().includes("mdotm") : false,
		});
		initMixPanelCUser(newUser);

		// Init Signed Hotjar
		initHotjarCUser(newUser);

		// Init Server-Side events
		serverSideEventsRef?.close();
		serverSideEventsRef = undefined;

		if (newUser.signedIn) {
			serverSideEventsRef = initServerEvents({ authToken: newUser.token! });
		}
	});
}
