import type {
	InvestmentSummary,
	ReviewTicker,
	UserInstrumentClassificationDto,
	UserInstrumentDto,
} from "$root/api/api-gen";
import { Card } from "$root/components/EvolvedPrint/components/Card";
import { TinyTableDataCell, TinyTableHeadCell } from "$root/components/EvolvedPrint/components/table/tiny-table";
import type { PrintableProps } from "$root/components/EvolvedPrint/configuration";
import { InfoDelta } from "$root/components/InfoDelta";
import type { UserColumnMetadata } from "$root/functional-areas/instruments/hooks";
import {
	findUserClassificationCell,
	generateTagMapByClassification,
	generateTagWeightByTag,
	useBaseInstrumentColumns,
} from "$root/functional-areas/instruments/hooks";
import { TagDataCell } from "$root/functional-areas/instruments/tags-v2/table-cell";
import { useLocaleFormatters } from "$root/localization/hooks";
import type {
	ReportTemplateItemMap,
	ReportTemplateVariant,
} from "$root/pages/PortfolioStudioSettings/ReportEditor/report-latest";
import type { TableColumn } from "@mdotm/mdotui/components";
import { BaseTable } from "@mdotm/mdotui/components";
import { useMemo } from "react";
import { useReportTranslation } from "../translation";

type MixedInvestmentEntry = Omit<ReviewTicker, "description"> & UserInstrumentDto;

function exhaustiveMatchingGuard(_: never): never {
	throw new Error("switch case has an unhandled match");
}

const tableWidth = 560;
const useReportComposition = (params: { columnsMetadata: UserColumnMetadata[] }) => {
	const { t } = useReportTranslation();
	const formatters = useLocaleFormatters();
	const { formatNumber } = formatters;

	const baseInstrumentsColumns = useBaseInstrumentColumns(params.columnsMetadata);
	const rawColumns = useMemo(
		() =>
			params.columnsMetadata
				.filter((x) => x.enabled)
				.flatMap((columnMetadata): (Omit<TableColumn<MixedInvestmentEntry>, "width"> & { width: number })[] => {
					switch (columnMetadata.preferenceType?.classificationUuid) {
						case "NAME":
							return [
								{
									header: (headerProps) => (
										<TinyTableHeadCell {...headerProps}>{columnMetadata.name}</TinyTableHeadCell>
									),
									content(row, props) {
										return <TinyTableDataCell {...props}>{row.name}</TinyTableDataCell>;
									},
									name: "Name",
									orderable: false,
									minWidth: 164,
									width: 164,
									maxWidth: undefined,
									headerCellStyle: { flexGrow: 1 },
									cellStyle: { flexGrow: 1 },
								},
							];
						case "WEIGHT":
							return [
								{
									header: (headerProps) => (
										<TinyTableHeadCell {...headerProps}>
											{t(`REPORT_BUILDER.COMPOSITION.TABLE.WEIGHT`)}
										</TinyTableHeadCell>
									),
									name: "weight",
									minWidth: undefined,
									width: 60,
									maxWidth: 60,
									orderable: false,
									cellClassList: "tabular-nums",
									content: ({ weight }, props) => (
										<TinyTableDataCell {...props}>{`${formatNumber(weight ?? 0)}%`}</TinyTableDataCell>
									),
									align: "end",
								},
							];
						case "ENHANCED_WEIGHT":
							return [
								{
									header: (headerProps) => (
										<TinyTableHeadCell {...headerProps}>
											{t(`REPORT_BUILDER.COMPOSITION.TABLE.ENHANCED_WEIGHT`)}
										</TinyTableHeadCell>
									),
									name: "weight",
									minWidth: undefined,
									width: 60,
									maxWidth: 60,
									orderable: false,
									cellClassList: "tabular-nums",
									content: ({ weight }, props) => (
										<TinyTableDataCell {...props}>{`${formatNumber(weight ?? 0)}%`}</TinyTableDataCell>
									),
									align: "end",
								},
							];
						case "DIFFERENCE":
							return [
								{
									header: (headerProps) => (
										<TinyTableHeadCell {...headerProps}>
											{t(`REPORT_BUILDER.COMPOSITION.TABLE.ENHANCED_WEIGHT`)}
										</TinyTableHeadCell>
									),
									name: "difference",
									minWidth: undefined,
									width: 96,
									maxWidth: 96,
									orderable: false,
									align: "end",
									content: (instrument, props) => {
										const deltaWeight = Number((instrument.weight ?? 0) - (instrument.previousWeight ?? 0)).toFixed(2);
										return (
											<TinyTableDataCell {...props}>
												<InfoDelta diff={Number(deltaWeight) ?? 0} enh={instrument.weight ?? 0} />
											</TinyTableDataCell>
										);
									},
								},
							];
						case "IDENTIFIER":
							return [
								{
									header: columnMetadata.name,
									content(row, props) {
										return <TinyTableDataCell {...props}>{row.identifier}</TinyTableDataCell>;
									},
									name: "identifier",
									minWidth: undefined,
									width: 96,
									maxWidth: 96,
									orderable: false,
								},
							];
						default:
							break;
					}

					if (!columnMetadata.fieldType) {
						return [];
					}

					switch (columnMetadata.fieldType) {
						case "QUALITY":
							return [
								{
									header: (headerProps) => (
										<TinyTableHeadCell {...headerProps}>{columnMetadata.name}</TinyTableHeadCell>
									),
									content(row, props) {
										return (
											<TinyTableDataCell {...props}>
												{findUserClassificationCell(columnMetadata.classificationUuid!, row.qualities)?.value}
											</TinyTableDataCell>
										);
									},
									name: "quality",
									minWidth: undefined,
									width: 96,
									maxWidth: 96,
									orderable: false,
								},
							];
						case "SCORE":
							return [
								{
									header: (headerProps) => (
										<TinyTableHeadCell {...headerProps}>{columnMetadata.name}</TinyTableHeadCell>
									),
									content(row, props) {
										return (
											<TinyTableDataCell {...props}>
												{findUserClassificationCell(columnMetadata.classificationUuid!, row.scores)?.value}
											</TinyTableDataCell>
										);
									},
									name: "score",
									minWidth: undefined,
									width: 96,
									maxWidth: 96,
									orderable: false,
								},
							];
						case "TAG":
							return [
								{
									header: (headerProps) => (
										<TinyTableHeadCell {...headerProps}>{columnMetadata.name}</TinyTableHeadCell>
									),
									content: (row, cellProps) => {
										const tagMap = generateTagMapByClassification(columnMetadata);
										const tagCell = findUserClassificationCell(columnMetadata.classificationUuid!, row.tags);
										const tagWeightMap = generateTagWeightByTag(tagCell?.values ?? []);

										return (
											<TinyTableDataCell {...cellProps}>
												<TagDataCell
													tagMap={tagMap}
													tagWeightMap={tagWeightMap}
													viewMode={columnMetadata.dataVisualization!}
												/>
											</TinyTableDataCell>
										);
									},
									name: columnMetadata.classificationUuid!,
									minWidth: 96,
									width: 96,
									maxWidth: undefined,
									orderable: false,
									headerCellStyle: { flexGrow: 1 },
									cellStyle: { flexGrow: 1 },
								},
							];
						default:
							return exhaustiveMatchingGuard(columnMetadata.fieldType);
					}
				}),
		[params.columnsMetadata, t, formatNumber],
	);

	// const sumColumnsWidth = sumArrayLike(rawColumns, (x) => x.width);
	// if (sumColumnsWidth > tableWidth) {
	// 	const decimalPlaces = 2;
	// 	const overshot = BigNumber(sumColumnsWidth - tableWidth).decimalPlaces(decimalPlaces);
	// 	const sumColumnWidths = sumColumnsWidth - 104; /** name column w */

	// 	return rawColumns.map((column) => {
	// 		const bigNumColumnWidth = BigNumber(column.width);
	// 		const newColumnWidth = bigNumColumnWidth
	// 			.minus(bigNumColumnWidth.div(sumColumnWidths).times(overshot).decimalPlaces(decimalPlaces))
	// 			.decimalPlaces(decimalPlaces)
	// 			.toNumber();

	// 		return { ...column, width: newColumnWidth };
	// 	});
	// }

	return rawColumns;
};

const Composition = ({
	config,
	variant,
	portfolio,
	userClassifications,
	list,
}: PrintableProps<
	{
		config: ReportTemplateItemMap["composition"];
		variant: ReportTemplateVariant;
		portfolio: InvestmentSummary;
		userClassifications: UserInstrumentClassificationDto[];
	},
	MixedInvestmentEntry
>): JSX.Element => {
	const { t } = useReportTranslation();
	const preferences = variant === "current" ? config.currentColumnPreferences : config.proposalColumnPreferences;
	const columnsMetadata = useMemo(() => {
		return preferences.map((preference): UserColumnMetadata => {
			const classification = userClassifications.find(
				(user) => user.classificationUuid === preference.preferenceType?.classificationUuid,
			);

			return {
				...preference,
				...classification,
				dataVisualization: "TAG_VIEW_WITHOUT_COLOR",
				name: classification?.name ?? preference.preferenceType?.label ?? "Untitled",
			};
		});
	}, [preferences, userClassifications]);

	const columns = useReportComposition({ columnsMetadata });
	return (
		<Card title={t("REPORT_BUILDER.COMPOSITION.TITLE")}>
			<BaseTable
				disableVirtualScroll
				palette="uniform"
				columns={columns}
				rows={list}
				noDataText={t("COMPOSITION.NO_COMPOSITION")}
			/>
		</Card>
	);
};

export default Composition;
