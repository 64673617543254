import { formatInTimeZone } from "date-fns-tz";
import type { Locale } from "date-fns";
import { parse } from "date-fns";
import i18n from "i18next";
import { it, enUS as en, de, fr, es } from "date-fns/locale";
import type { Languages } from "./i18n";

export type FormatNumberOptions = {
	maxDecimalPlaces: number;
	minDecimalPlaces: number;
	fallback: string;
	locale: string | undefined;
};

export type FormatNumber = {
	(n: number | string | null | undefined, decimalPlaces?: number): string;
	(n: number | string | null | undefined, opts: Partial<FormatNumberOptions>): string;
};

export type FormatTime = {
	(date: Date | string | number | undefined | null, opts?: Partial<FormatTimeOptions>): string;
};

export type FormatDate = {
	(date: Date | string | number | undefined | null, opts?: Partial<FormatDateOptions>): string;
};

export const formatNumber: FormatNumber = (n, decimalPlacesOrOptions) => {
	const opts: FormatNumberOptions =
		typeof decimalPlacesOrOptions === "number"
			? {
					locale: i18n.language,
					fallback: "-",
					minDecimalPlaces: decimalPlacesOrOptions,
					maxDecimalPlaces: decimalPlacesOrOptions,
			  }
			: {
					locale: decimalPlacesOrOptions?.locale ?? i18n.language,
					fallback: decimalPlacesOrOptions?.fallback ?? "-",
					maxDecimalPlaces: decimalPlacesOrOptions?.maxDecimalPlaces ?? 2,
					minDecimalPlaces: decimalPlacesOrOptions?.minDecimalPlaces ?? decimalPlacesOrOptions?.maxDecimalPlaces ?? 2,
			  };

	if (n !== null && n !== undefined && (typeof n === "number" || (typeof n === "string" && n !== ""))) {
		return (typeof n === "number" ? n : Number(n)).toLocaleString(opts.locale, {
			maximumFractionDigits: opts.maxDecimalPlaces,
			minimumFractionDigits: opts.minDecimalPlaces,
			minimumIntegerDigits: 1,
		});
	}
	return opts.fallback;
};

export type FormatTimeOptions = {
	fallback: string;
	locale: string | undefined;
};

export type FormatDateOptions = {
	fallback: string;
	locale: string | undefined;
	omitYear: boolean;
};

export const formatDate: FormatDate = (date, opts) => {
	if (
		date !== null &&
		date !== undefined &&
		(typeof date === "object" || (typeof date === "string" && date !== "") || typeof date === "number")
	) {
		const normDate = typeof date === "object" ? date : new Date(date);
		const localizedStr = formatInTimeZone(normDate, "CET", "P", {
			locale: locales[opts?.locale ?? i18n.language],
		});
		if (opts?.omitYear) {
			return localizedStr.replace(new RegExp(`[^\\d]?${String(normDate.getFullYear())}[^\\d]?`), "");
		}
		return localizedStr;
	}
	return opts?.fallback ?? "-";
};

export const formatTime: FormatTime = (date, opts) => {
	if (
		date !== null &&
		date !== undefined &&
		(typeof date === "object" || (typeof date === "string" && date !== "") || typeof date === "number")
	) {
		return `${formatInTimeZone(typeof date === "object" ? date : new Date(date), "CET", "p", {
			locale: locales[opts?.locale ?? i18n.language],
		})} CET`;
	}
	return opts?.fallback ?? "-";
};

export const formatDateTime: FormatDate = (date, opts) => {
	if (
		date !== null &&
		date !== undefined &&
		(typeof date === "object" || (typeof date === "string" && date !== "") || typeof date === "number")
	) {
		return `${formatInTimeZone(typeof date === "object" ? date : new Date(date), "CET", "Pp", {
			locale: locales[opts?.locale ?? i18n.language],
		})} CET`;
	}
	return opts?.fallback ?? "-";
};

export type ParseDate = (localizedStr: string, locale?: string) => string | null;

export const parseDate: ParseDate = (localizedStr, locale) => {
	try {
		const parsed = parse(localizedStr, "P", new Date(), {
			locale: locales[locale ?? i18n.language],
		});
		if (Number.isNaN(parsed)) {
			return null;
		}
		return parsed.toISOString();
	} catch {
		return null;
	}
};

const locales: Partial<Record<string, Locale>> = {
	it,
	en,
	de,
	fr,
	es,
} satisfies Record<Languages, unknown>;

export function getLocalizedDateMask(locale?: string): string {
	return formatDate(new Date(2020, 0, 1), { locale }).replace(/\d/g, "_");
}
