import type { PageDto } from "$root/api/api-gen";
import { EntityEditorControllerApiFactory, InvestmentsReportTemplateControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { useCrumbs } from "$root/components/Crumbs/useCrumbs";
import { paperPlaneId } from "$root/components/EvolvedPrint/configuration/shared";
import { LeavePrompt } from "$root/components/LeavePrompt";
import { PageHeader } from "$root/components/PageHeader";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import ReactQueryWrapper from "$root/components/ReactQueryWrapper";
import { Sidebar } from "$root/components/Sidebar";
import { SideDrawer } from "$root/components/SideDrawer";
import { spawnCreateDialog } from "$root/components/spawnable/entity-management/create-dialog";
import { downloadSinglePdf, generateReportBuilderTemplateName } from "$root/functional-areas/pdf";
import { PortfolioStudioSettingTabEnum } from "$root/functional-areas/portfolio-studio-settings";
import {
	DefaultReportTemplateName,
	defaultPortfolioTemplates,
	isDefaultReportTemplate,
	type DefaultReportTemplate,
} from "$root/functional-areas/reports/default-templates";
import { platformToast } from "$root/notification-system/toast";
import Report from "$root/pages/Report";
import { axiosExtract } from "$root/third-party-integrations/axios";
import type { Abortable, RequireProperty } from "$root/utils";
import { qualifier } from "$root/utils";
import { Card } from "$root/widgets-architecture/layout/Card";
import type { DraggableListItemProps, IconName, InnerRefProps, StylableProps } from "@mdotm/mdotui/components";
import {
	AsyncButton,
	AutoTooltip,
	Button,
	ClickableDiv,
	DraggableList,
	Icon,
	Select,
	Tab,
	TabGroup,
	Text,
	TinyIconButton,
} from "@mdotm/mdotui/components";
import {
	ForEach,
	WrapIfStrOrNumOrEmpty,
	adaptAnimatedNodeProvider,
	overrideClassList,
	spawn,
	toClassName,
	useUnmountAbortSignal,
} from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { noop, replaceById, sleep } from "@mdotm/mdotui/utils";
import type React from "react";
import type { ForwardedRef, MouseEvent, ReactNode } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { flushSync } from "react-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import {
	Commentary,
	Composition,
	Cover,
	Disclaimer,
	ExAnteContributionVolatility,
	ExAntePortfolioMetrics,
	Exposure,
	FactorExposure,
	FreeText,
	Performance,
	PerformanceAndVolatilityContribution,
	PerformanceAttribution,
	PortfolioMetrics,
	Summary,
} from "./report-components";
import type {
	ReportTemplate,
	ReportTemplateItem,
	ReportTemplateStatus,
	ReportTemplateUnion,
	ReportTemplateVariant,
} from "./report-latest";
import {
	availableTemplateItemKindsByReportType,
	enabledTemplateItemKinds,
	itemFactories,
	itemToAdderLabelMap,
	itemToConstraints,
	itemToIconMap,
	itemToLabelMap,
	latestVersion,
	migrateToLatest,
} from "./report-latest";

function ScrollWrapper({ children }: { children: React.ReactNode }) {
	const portraitContainerRef = useRef<HTMLDivElement>(null);
	const intervalIdRef = useRef<ReturnType<typeof setInterval> | undefined>(undefined);

	useEffect(() => {
		portraitContainerRef.current?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
	}, []);

	useEffect(() => {
		const scrollWrapper = portraitContainerRef.current;
		if (scrollWrapper !== null) {
			intervalIdRef.current = setInterval(() => {
				const isPaperplaneTagVisible = document.getElementById(paperPlaneId) != null;
				if (isPaperplaneTagVisible) {
					clearInterval(intervalIdRef.current);
				}

				scrollWrapper.scrollTo({ top: scrollWrapper.scrollHeight, behavior: "smooth" });
			}, 700);

			scrollWrapper.addEventListener("wheel", () => {
				clearInterval(intervalIdRef.current);
			});
		}
	}, []);

	return (
		<div className="overflow-y-auto overflow-scroll h-full" ref={portraitContainerRef}>
			{children}
			{/* <Button
				classList={{
					"!rounded-full": true,
					[`!bg-[color:${themeCSSVars.palette_P400}] fixed bottom-4 right-8`]: true,
				}}
				unstyled
				onClick={() => {
					clearInterval(intervalIdRef.current);
					portraitContainerRef.current?.scrollTo({ top: 0, behavior: "smooth" });
				}}
			>
				<Icon icon="Up" size={32} color="white" />
			</Button> */}
		</div>
	);
}
function ReportEditor(props: { reportUid: string }): JSX.Element {
	const investmentsReportTemplateApi = useApiGen(InvestmentsReportTemplateControllerApiFactory);

	return (
		<ReactQueryWrapper
			queryKey={["reportTemplate", props.reportUid]}
			queryFn={async () => {
				const defaultTemplate = defaultPortfolioTemplates.find((d) => d.id === props.reportUid);
				if (defaultTemplate) {
					return defaultTemplate;
				}

				const template = (await axiosExtract(
					investmentsReportTemplateApi.getInvestmentReportTemplate(props.reportUid),
				)) as ReportTemplateUnion;

				if (
					template.data.version !== latestVersion ||
					template.templateName === defaultPortfolioTemplates[0].templateName
				) {
					const migratedTemplate = migrateToLatest(template);
					return migratedTemplate;
				}

				return template;
			}}
		>
			{(data) => (
				<ReportEditorInner reportUid={props.reportUid} template={data as ReportTemplate | DefaultReportTemplate} />
			)}
		</ReactQueryWrapper>
	);
}

const tabs = ["current", "proposal"] satisfies Array<ReportTemplateVariant>;
function ReportEditorInner(props: {
	reportUid: string;
	template: ReportTemplate | DefaultReportTemplate;
}): JSX.Element {
	const investmentsReportTemplateApi = useApiGen(InvestmentsReportTemplateControllerApiFactory);
	const entityEditorApi = useApiGen(EntityEditorControllerApiFactory);
	const [templateVariantTab, setTemplateVariantTab] = useState<ReportTemplateVariant>("current");
	const [editableTemplate, _setEditableTemplate] = useState(props.template);
	const [isDirty, setIsDirty] = useState(false);
	const setEditableTemplate = useCallback((x: Parameters<typeof _setEditableTemplate>[0]) => {
		_setEditableTemplate(x);
		setIsDirty(true);
	}, []);

	const [expandAdders, setExpandAdders] = useState(true);
	const [selectedPortfolio, setSelectedPortfolio] = useState<{ name: string; uuid: string } | null>(null);
	const [reloadCounter, setReloadCounter] = useState(0);
	const { push } = useTypedNavigation();
	const scalingFactor = expandAdders ? minScalingFactor : maxScalingFactor;
	const isSphereDefaultTemplate = defaultPortfolioTemplates[0].templateName === props.template.templateName;
	const history = useHistory();

	const [pathToNotBlock, setPathToNotBlock] = useState<string[]>([history.location.pathname]);

	const reportDtoConfig = useMemo<RequireProperty<PageDto, "name"> | undefined>(
		() =>
			selectedPortfolio
				? {
						templateId: isDefaultReportTemplate(editableTemplate) ? editableTemplate.id : editableTemplate.uuid,
						variant: templateVariantTab,
						objectId: selectedPortfolio.uuid,
						status: (reloadCounter === 0 ? "ready" : "draft") as ReportTemplateStatus,
						autoScroll: true,
						name: generateReportBuilderTemplateName(
							{ ...selectedPortfolio, choice: templateVariantTab === "current" ? "current" : "enhance" },
							editableTemplate.templateName,
						),
				  }
				: undefined,
		[editableTemplate, reloadCounter, selectedPortfolio, templateVariantTab],
	);

	function checkIfNameIsAvailable(name: string, opts?: Abortable) {
		if (name.toLocaleLowerCase() === defaultPortfolioTemplates[0].templateName.toLocaleLowerCase()) {
			throw new Error("name already exist");
		}
		return axiosExtract(investmentsReportTemplateApi.checkTemplateNameAvailability(name, opts));
	}

	async function updateTemplate(name?: string) {
		try {
			await investmentsReportTemplateApi.updatePTFReportTemplate((props.template as any).uuid, {
				...editableTemplate,
				templateName: name ?? editableTemplate.templateName,
				data: {
					...editableTemplate.data,
					status: "ready",
				},
			});
			flushSync(() =>
				setPathToNotBlock((latestPath) => [
					...latestPath,
					typedUrlForRoute("PortfolioStudioSettings", { tab: PortfolioStudioSettingTabEnum.ReportCustomisation }),
				]),
			);
			platformToast({
				children: "Successfully update your template",
				icon: "pdf",
				severity: "success",
			});

			push("PortfolioStudioSettings", { tab: PortfolioStudioSettingTabEnum.ReportCustomisation });
		} catch (error) {
			platformToast({
				children: "Oops, Something went wrong, pleas re-try",
				icon: "pdf",
				severity: "error",
			});
			throw error;
		}
	}

	async function createTemplate(name?: string) {
		try {
			await investmentsReportTemplateApi.createPTFReportTemplate({
				...editableTemplate,
				templateName: name ?? editableTemplate.templateName,
				data: {
					...editableTemplate.data,
					status: "ready",
				},
			});
			platformToast({
				children: `Successfully create template ${name}`,
				icon: "pdf",
				severity: "success",
			});
			push("PortfolioStudioSettings", { tab: PortfolioStudioSettingTabEnum.ReportCustomisation });
		} catch (error) {
			platformToast({
				children: "Oops, Something went wrong, pleas re-try",
				icon: "pdf",
				severity: "error",
			});
			throw error;
		}
	}

	async function updateTemplatePreview(config: typeof editableTemplate) {
		try {
			const sphereTemplate = defaultPortfolioTemplates[0];
			let uuid: string | undefined = (config as any)?.uuid;
			if (
				config.templateName === sphereTemplate.templateName &&
				(config as any)?.id === DefaultReportTemplateName.sphere
			) {
				const savedDefaultTemplate = await axiosExtract(
					investmentsReportTemplateApi.createPTFReportTemplate({
						templateName: config.templateName,
						visible: true,
						format: "pdf",
						data: {
							...config.data,
							status: "draft",
						},
					}),
				);
				uuid = savedDefaultTemplate.uuid;
				setEditableTemplate((prev) =>
					savedDefaultTemplate.uuid
						? ({
								uuid: savedDefaultTemplate.uuid,
								data: prev.data,
								format: prev.format,
								templateName: prev.templateName,
								visible: prev.visible,
						  } satisfies ReportTemplate)
						: prev,
				);
				await sleep(500);
			}

			await investmentsReportTemplateApi.updatePTFReportTemplate(uuid!, config);
		} catch (error) {
			platformToast({
				children: "Unable to update the portfolio preview",
				icon: "pdf",
				severity: "error",
			});
			throw error;
		}
	}

	const crumbs = useCrumbs();

	const { t } = useTranslation();

	return (
		<>
			<LeavePrompt
				title={t("LEAVE_PAGE")}
				when={isDirty}
				showCancelIcon={false}
				pathToNotBlock={["/login", ...pathToNotBlock]}
			>
				{t("LEAVE_PAGE_MESSAGE")}
			</LeavePrompt>

			<PageHeader
				crumbsV2={[
					...crumbs.storyfolio("Report builder", props.reportUid),
					...crumbs.reportName({
						uuid: props.reportUid,
						name: props.template.templateName,
					}),
				]}
				title={props.template.templateName}
				titleAction={
					<div className="flex items-center space-x-2">
						{isSphereDefaultTemplate || isDefaultReportTemplate(props.template) ? (
							<>
								<Button
									palette="tertiary"
									size="small"
									onClick={() =>
										push("PortfolioStudioSettings", { tab: PortfolioStudioSettingTabEnum.ReportCustomisation })
									}
									data-qualifier={qualifier.reportCustomisation.builder.cancel}
								>
									Cancel
								</Button>
								<AsyncButton
									onClickAsync={() =>
										spawnCreateDialog({
											entityType: "template",
											onSubmitAsync: (name) => createTemplate(name),
											checkIfNameIsAvailable,
											placeholder: editableTemplate.templateName,
											header: "Save as",
										}).catch(noop)
									}
									palette="primary"
									size="small"
									data-qualifier={qualifier.reportCustomisation.builder.saveAs}
								>
									Save as
								</AsyncButton>
							</>
						) : (
							<>
								<Button
									palette="tertiary"
									size="small"
									onClick={() =>
										push("PortfolioStudioSettings", { tab: PortfolioStudioSettingTabEnum.ReportCustomisation })
									}
									data-qualifier={qualifier.reportCustomisation.builder.cancel}
								>
									Cancel
								</Button>

								<AsyncButton
									palette="primary"
									size="small"
									onClickAsync={() =>
										props.template.data.status === "draft"
											? spawnCreateDialog({
													entityType: "template",
													onSubmitAsync: (name) => updateTemplate(name),
													checkIfNameIsAvailable,
													placeholder: editableTemplate.templateName,
													header: "Rename Template",
											  }).catch(noop)
											: updateTemplate()
									}
									data-qualifier={qualifier.reportCustomisation.builder.save}
								>
									{props.template.data.status === "draft" ? "Update" : "Done"}
								</AsyncButton>
							</>
						)}
					</div>
				}
			/>
			<div className="grow min-h-0 flex relative z-0">
				<Adders
					expand={expandAdders}
					onExpandChange={setExpandAdders}
					ctx={
						!isDefaultReportTemplate(editableTemplate)
							? { reportTemplate: editableTemplate, templateVariantTab }
							: undefined
					}
					activeTab={templateVariantTab}
					onAdd={(item) =>
						setEditableTemplate(
							(v) =>
								({
									...v,
									data: {
										...v.data,
										templateItemsByVariant: {
											...v.data.templateItemsByVariant,
											[templateVariantTab]: v.data.templateItemsByVariant[templateVariantTab]
												.slice(0, 1)
												.concat(v.data.templateItemsByVariant[templateVariantTab].slice(1))
												.concat([item]),
											// .concat(v.data.templateItemsByVariant[templateVariantTab].slice(-1)),
										},
									},
								}) as typeof v,
						)
					}
					disabled={isSphereDefaultTemplate}
				/>
				<Card
					classList={`grow !rounded-none pl-7 w-[480px] max-w-[480px] min-w-[480px] border-r border-r-[color:${themeCSSVars.palette_N50}] border-solid`}
					title="Template structure"
				>
					{/* spacer */}
					<div className="h-3" />
					<TabGroup
						showTitleDivider
						size="x-small"
						onTabChange={(index) => setTemplateVariantTab(tabs[index])}
						tabIndex={tabs.indexOf(templateVariantTab)}
					>
						<Tab title="Current">
							<TemplateItemList
								mode="edit"
								templateVariant="current"
								list={editableTemplate.data.templateItemsByVariant.current}
								onChange={(list) =>
									setEditableTemplate({
										...editableTemplate,
										data: {
											...editableTemplate.data,
											templateItemsByVariant: {
												...editableTemplate.data.templateItemsByVariant,
												current: list,
											},
											templateDraftItemsByVariant: {
												...editableTemplate.data.templateItemsByVariant,
												current: list,
											},
										},
									} as typeof editableTemplate)
								}
							/>
						</Tab>
						<Tab title="Proposal">
							<TemplateItemList
								mode="edit"
								templateVariant="proposal"
								list={editableTemplate.data.templateItemsByVariant.proposal}
								onChange={(list) =>
									setEditableTemplate({
										...editableTemplate,
										data: {
											...editableTemplate.data,
											templateItemsByVariant: {
												...editableTemplate.data.templateItemsByVariant,
												proposal: list,
											},
											templateDraftItemsByVariant: {
												...editableTemplate.data.templateDraftItemsByVariant,
												proposal: list,
											},
										},
									} as typeof editableTemplate)
								}
							/>
						</Tab>
					</TabGroup>
				</Card>
				<Card
					classList="flex-1 !rounded-none overflow-hidden"
					title={
						<ReactQueryWrapper
							queryKey={["selectablePortfolioTemplate", templateVariantTab]}
							queryFn={async () => {
								const { selectablePortfolios } = await axiosExtract(
									entityEditorApi.getEditorNewSelectablePortfolios("INVESTMENT"),
								);

								return {
									options: (selectablePortfolios ?? []).flatMap((x) =>
										x.name &&
										x.uuid &&
										(x.status === "ACCEPTED" || x.status === "PROPOSAL_READY" || x.status === "READY")
											? [
													{
														label: x.name,
														value: { name: x.name, uuid: x.uuid },
														disabled: templateVariantTab === "proposal" && x.status !== "PROPOSAL_READY",
													},
											  ]
											: [],
									),
									selectablePortfolios: selectablePortfolios ?? [],
								};
							}}
							onSuccess={({ selectablePortfolios, options }) => {
								const portfolioWithProposal = selectablePortfolios.find(
									(x) => x.status === "PROPOSAL_READY" && x.uuid && x.name,
								);
								if (portfolioWithProposal) {
									return setSelectedPortfolio({ uuid: portfolioWithProposal.uuid!, name: portfolioWithProposal.name! });
								}
								const [portfolio] = options;

								if (portfolio) {
									return setSelectedPortfolio({ uuid: portfolio.value.uuid!, name: portfolio.value.name });
								}
							}}
							keepPreviousData
							loadingFallback={
								<div className="flex justify-between grow animate-pulse">
									<div className="flex space-x-2">
										<Select
											options={[]}
											value={selectedPortfolio}
											onChange={setSelectedPortfolio}
											enableSearch
											classList="w-48"
											disabled
										/>
										<AsyncButton palette="tertiary" size="small" onClickAsync={noop} disabled>
											Reload
										</AsyncButton>
									</div>
									<TinyIconButton
										size={16}
										icon="Dowload"
										color={themeCSSVars.palette_N400}
										disabled
										onClickAsync={noop}
									/>
								</div>
							}
						>
							{({ options }) => (
								<div className="flex justify-between grow">
									<div className="flex space-x-2">
										<Select
											options={options}
											value={selectedPortfolio}
											onChange={setSelectedPortfolio}
											enableSearch
											classList="w-48"
											data-qualifier={qualifier.reportCustomisation.builder.selectPortfolio}
										/>
										<AsyncButton
											palette="tertiary"
											size="small"
											onClickAsync={async () => {
												await updateTemplatePreview({
													...editableTemplate,
													data: {
														status: editableTemplate.data.status,
														version: editableTemplate.data.version,
														templateDraftItemsByVariant: editableTemplate.data.templateDraftItemsByVariant,
														templateItemsByVariant: props.template.data.templateItemsByVariant,
													},
												});
												setReloadCounter((x) => x + 1);
											}}
											data-qualifier={qualifier.reportCustomisation.builder.reloadPreview}
										>
											Reload
										</AsyncButton>
									</div>
									<TinyIconButton
										size={16}
										icon="Dowload"
										color={themeCSSVars.palette_N400}
										disabled={reportDtoConfig === undefined}
										onClickAsync={async () => {
											if (reportDtoConfig) {
												await downloadSinglePdf(reportDtoConfig.name, reportDtoConfig);
											}
										}}
										data-qualifier={qualifier.reportCustomisation.builder.downloadTemplate}
									/>
								</div>
							)}
						</ReactQueryWrapper>
					}
				>
					<div
						style={{
							paddingTop: "1rem",
							paddingBottom: "1rem",
							backgroundColor: "white",
							height: `calc(100dvh - 140px)`,
							position: "relative",
						}}
						data-qualifier={qualifier.reportCustomisation.builder.previewContainer}
					>
						{showIframe && selectedPortfolio && (
							<div className="overflow-hidden h-full" key={reloadCounter}>
								<div
									style={{
										height: `${100 / scalingFactor}%`,
										width: `${100 / scalingFactor}%`,
										transform: `scale(${scalingFactor})`,
										transformOrigin: "top left",
									}}
								>
									<ScrollWrapper key={selectedPortfolio.uuid}>
										<Report
											templateId={
												isDefaultReportTemplate(editableTemplate) ? editableTemplate.id : editableTemplate.uuid
											}
											autoScroll="true"
											objectId={selectedPortfolio.uuid}
											variant={templateVariantTab}
											status={(reloadCounter === 0 ? "ready" : "draft") as ReportTemplateStatus}
										/>
									</ScrollWrapper>
								</div>
							</div>
						)}
					</div>
				</Card>
			</div>
		</>
	);
}

const showIframe = true;
const minScalingFactor = 0.4;
const maxScalingFactor = 0.8;

export default ReportEditor;

function TemplateItem(
	props: Partial<DraggableListItemProps<ReportTemplateItem>> &
		InnerRefProps<HTMLDivElement> & { item: ReportTemplateItem; onEdit?(): void; onDelete?(): void },
): JSX.Element {
	return (
		<div
			style={{ borderColor: themeCSSVars.palette_N50 }}
			className={toClassName({
				"relative flex flex-row items-center min-w-0 bg-white transition-shadow border-b gap-2 px-2": true,
				"shadow-lg": props.isDragging,
				"border-t": props.isDragging,
			})}
			{...props.draggableProps}
			ref={props.innerRef}
		>
			<div
				{...props.dragHandleProps}
				className={toClassName({ invisible: !props.draggableProps || !props.dragHandleProps })}
			>
				{/* TODO: library icon */}
				<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M6.04181 3.2944L7.96096 1.37506C8.01677 1.31944 8.10704 1.31944 8.16286 1.37506L10.082 3.2944C10.1228 3.3352 10.1349 3.3966 10.113 3.44991C10.0909 3.50323 10.0389 3.53807 9.98115 3.53807H6.14267C6.08493 3.53807 6.03277 3.50323 6.01083 3.44991C5.98888 3.3966 6.00101 3.3352 6.04181 3.2944ZM8.16305 14.8297C8.10724 14.8853 8.01697 14.8853 7.96115 14.8297L6.04181 12.9103C6.00101 12.8695 5.98888 12.8081 6.01083 12.7548C6.03296 12.7015 6.08512 12.6667 6.14286 12.6667H9.98134C10.0391 12.6667 10.091 12.7015 10.1132 12.7548C10.1351 12.8081 10.123 12.8695 10.0822 12.9103L8.16305 14.8297ZM14.3446 6.00001H1.97822C1.62186 6.00001 1.33337 6.29415 1.33337 6.65052C1.33337 7.00688 1.33337 7.30102 1.97822 7.30102H14.3333C14.701 7.30102 14.9895 7.01254 14.9895 6.65052C14.9895 6.28849 14.701 6.00001 14.3446 6.00001ZM1.97822 9.33334H14.3446C14.701 9.33334 14.9895 9.62183 14.9895 9.98385C14.9895 10.3459 14.701 10.6344 14.3333 10.6344H1.97822C1.33337 10.6344 1.33337 10.3402 1.33337 9.98385C1.33337 9.62748 1.62186 9.33334 1.97822 9.33334Z"
						fill="#8792AB"
					/>
				</svg>
			</div>
			<Icon icon={itemToIconMap[props.item.kind]} size={24} color={themeCSSVars.palette_P500} />
			<Text type="Body/M/Book">{itemToLabelMap[props.item.kind]}</Text>
			<div className="flex items-center ml-auto gap-2">
				<TinyIconButton
					style={{
						visibility: props.onEdit ? undefined : "hidden",
					}}
					onClick={() => props.onEdit?.()}
					icon="Edit"
					size={16}
					color={themeCSSVars.palette_N300}
					data-qualifier={qualifier.reportCustomisation.builder.widgetItem.edit(props.item.kind)}
				/>
				<TinyIconButton
					style={{
						visibility: props.onDelete ? undefined : "hidden",
					}}
					onClick={() => props.onDelete?.()}
					icon="Delete"
					size={16}
					color={themeCSSVars.palette_N300}
					data-qualifier={qualifier.reportCustomisation.builder.widgetItem.delete(props.item.kind)}
				/>
			</div>
		</div>
	);
}

type AddButtonPalette = "neutral";
const paletteToIconColor: Record<AddButtonPalette, string> = {
	neutral: themeCSSVars.palette_N400,
};
// TODO: extract in a component and re-use this same button in the constraint adders of the portfolio wizard
function AddButton(
	props: {
		onClick(e: MouseEvent): void;
		disabled?: boolean;
		icon: IconName;
		palette?: AddButtonPalette;
		size?: "small" | "large";
		children: ReactNode;
		subtitle?: string;
		innerRef?: ForwardedRef<HTMLDivElement>;
		"data-qualifier"?: string;
	} & StylableProps,
): JSX.Element {
	const palette = props.palette ?? "neutral";
	const size = props.size ?? "small";
	return (
		<ClickableDiv
			classList={overrideClassList(
				{
					[`flex flex-col items-center justify-start transition-transform border border-[${themeCSSVars.palette_N100}] rounded-[4px] shadow-md bg-white px-4 py-2.5 [&.disabled]:opacity-40 `]:
						true,
					[`[&:not(.disabled)]:hover:-translate-y-0.5 [&:not(.disabled)]:focus-visible:-translate-y-0.5 [&:not(.disabled)]:active:-translate-y-0 [&.active:not(.disabled)]:focus-visible:-translate-y-0`]:
						true,
					"min-h-[120px]": size === "large",
					"min-h-[80px]": size === "small",
					disabled: props.disabled,
				},
				props.classList,
			)}
			style={props.style}
			onClick={props.onClick}
			disabled={props.disabled}
			innerRef={props.innerRef}
			data-qualifier={props["data-qualifier"]}
		>
			<div className="[&.disabled]:bg-black">
				<Icon icon={props.icon} size={24} color={paletteToIconColor[palette]} />
			</div>
			<WrapIfStrOrNumOrEmpty
				wrapper={(content) => (
					<div className="h-8 flex items-center mt-1">
						<Text
							type="Body/M/Bold"
							color={themeCSSVars.palette_N500}
							title={String(content)}
							classList="whitespace-pre-line text-center line-clamp-2"
						>
							{content}
						</Text>
					</div>
				)}
			>
				{props.children}
			</WrapIfStrOrNumOrEmpty>
			{props.subtitle && (
				<div className="mt-auto self-center pt-2">
					<hr style={{ color: themeCSSVars.palette_N200 }} className="mb-0.5 w-8" />
					<Text as="div" classList="text-center" type="Body/S/Book">
						{props.subtitle}
					</Text>
				</div>
			)}
		</ClickableDiv>
	);
}

function Adders(props: {
	activeTab: ReportTemplateVariant;
	onAdd(item: ReportTemplateItem): void;
	ctx?: { reportTemplate: ReportTemplate; templateVariantTab: ReportTemplateVariant };
	onExpandChange(expand: boolean): void;
	expand: boolean;
	disabled?: boolean;
}) {
	return (
		<SideDrawer
			disableTransition
			expand={props.expand}
			onExpandChange={props.onExpandChange}
			widths={[24, 480]}
			title="Add element"
			footer={
				availableTemplateItemKindsByReportType[props.activeTab].length !== enabledTemplateItemKinds.length &&
				"* Not available in the selected tab"
			}
		>
			<div className="grid grid-cols-3 gap-2">
				<ForEach collection={enabledTemplateItemKinds} keyProvider={(kind) => kind}>
					{({ item: reportItemKind }) => {
						const canUseInTab = availableTemplateItemKindsByReportType[props.activeTab].includes(reportItemKind);
						const disabled = !props.ctx || !canUseInTab || !itemToConstraints[reportItemKind](props.ctx);
						return (
							<AutoTooltip
								align="middle"
								position="top"
								mode="hover"
								disabled={!disabled || props.disabled}
								overrideColor={themeCSSVars.palette_N200}
								trigger={({ innerRef }) => (
									<AddButton
										icon={itemToIconMap[reportItemKind]}
										onClick={() => (disabled ? undefined : props.onAdd(itemFactories[reportItemKind]()))}
										size="small"
										disabled={disabled || props.disabled}
										innerRef={innerRef}
										data-qualifier={qualifier.reportCustomisation.builder.widgetAdderOption(reportItemKind)}
									>
										{`${itemToAdderLabelMap[reportItemKind]}${!canUseInTab ? "*" : ""}`}
									</AddButton>
								)}
							>
								You have already added the widget to the template structure
							</AutoTooltip>
						);
					}}
				</ForEach>
			</div>
		</SideDrawer>
	);
}

function TemplateItemList(props: {
	list: Array<ReportTemplateItem>;
	onChange?(list: Array<ReportTemplateItem>): void;
	templateVariant: ReportTemplateVariant;
	mode?: "read" | "edit";
}): JSX.Element {
	const { mode = "edit" } = props;

	const signal = useUnmountAbortSignal();

	const onEdit =
		props.onChange &&
		((item: ReportTemplateItem) => {
			if (!props.onChange) {
				return;
			}
			const onChange = props.onChange;
			// TODO: spawnModalSidebar
			spawn<void>(
				adaptAnimatedNodeProvider(({ onHidden, reject, resolve, show }) => (
					<Sidebar
						open={show}
						onClose={() => reject()}
						width={440}
						mode="modal"
						onAnimationStateChange={(s) => s === "exited" && onHidden()}
					>
						{/* <div className={`pt-4 bg-[color:${themeCSSVars.palette_N100}] border-t border-t-[color:${themeCSSVars}] px-2`}> */}
						{(function () {
							switch (item.kind) {
								case "cover":
									return (
										<Cover
											configuration={item}
											onChange={(newConfiguration) => {
												onChange(replaceById(props.list, newConfiguration, ({ id }) => id));
												resolve();
											}}
											onCancel={reject}
											templateVariant={props.templateVariant}
										/>
									);
								case "disclaimer":
									return (
										<Disclaimer
											configuration={item}
											onChange={(newConfiguration) => {
												onChange(replaceById(props.list, newConfiguration, ({ id }) => id));
												resolve();
											}}
											onCancel={reject}
											templateVariant={props.templateVariant}
										/>
									);
								case "summary":
									return (
										<Summary
											configuration={item}
											onChange={(newConfiguration) => {
												onChange(replaceById(props.list, newConfiguration, ({ id }) => id));
												resolve();
											}}
											onCancel={reject}
											templateVariant={props.templateVariant}
										/>
									);
								case "commentary":
									return (
										<Commentary
											configuration={item}
											onChange={(newConfiguration) => {
												onChange(replaceById(props.list, newConfiguration, ({ id }) => id));
												resolve();
											}}
											onCancel={reject}
											templateVariant={props.templateVariant}
										/>
									);
								case "portfolioMetrics":
									return (
										<PortfolioMetrics
											configuration={item}
											onChange={(newConfiguration) => {
												onChange(replaceById(props.list, newConfiguration, ({ id }) => id));
												resolve();
											}}
											onCancel={reject}
											templateVariant={props.templateVariant}
										/>
									);
								case "exAntePortfolioMetrics":
									return (
										<ExAntePortfolioMetrics
											configuration={item}
											onChange={(newConfiguration) => {
												onChange(replaceById(props.list, newConfiguration, ({ id }) => id));
												resolve();
											}}
											onCancel={reject}
											templateVariant={props.templateVariant}
										/>
									);
								case "freeText":
									return (
										<FreeText
											configuration={item}
											onChange={(newConfiguration) => {
												onChange(replaceById(props.list, newConfiguration, ({ id }) => id));
												resolve();
											}}
											onCancel={reject}
											templateVariant={props.templateVariant}
										/>
									);
								case "composition":
									return (
										<Composition
											configuration={item}
											onChange={(newConfiguration) => {
												onChange(replaceById(props.list, newConfiguration, ({ id }) => id));
												resolve();
											}}
											onCancel={reject}
											templateVariant={props.templateVariant}
										/>
									);
								case "performance":
									return (
										<Performance
											configuration={item}
											onChange={(newConfiguration) => {
												onChange(replaceById(props.list, newConfiguration, ({ id }) => id));
												resolve();
											}}
											onCancel={reject}
											templateVariant={props.templateVariant}
										/>
									);
								case "performanceAttribution":
									return (
										<PerformanceAttribution
											configuration={item}
											onChange={(newConfiguration) => {
												onChange(replaceById(props.list, newConfiguration, ({ id }) => id));
												resolve();
											}}
											onCancel={reject}
											templateVariant={props.templateVariant}
										/>
									);
								case "performanceAndVolatilityContribution":
									return (
										<PerformanceAndVolatilityContribution
											configuration={item}
											onChange={(newConfiguration) => {
												onChange(replaceById(props.list, newConfiguration, ({ id }) => id));
												resolve();
											}}
											onCancel={reject}
											templateVariant={props.templateVariant}
										/>
									);
								case "exAnteContributionVolatility":
									return (
										<ExAnteContributionVolatility
											configuration={item}
											onChange={(newConfiguration) => {
												onChange(replaceById(props.list, newConfiguration, ({ id }) => id));
												resolve();
											}}
											onCancel={reject}
											templateVariant={props.templateVariant}
										/>
									);
								case "factorExposure":
									return (
										<FactorExposure
											configuration={item}
											onChange={(newConfiguration) => {
												onChange(replaceById(props.list, newConfiguration, ({ id }) => id));
												resolve();
											}}
											onCancel={reject}
											templateVariant={props.templateVariant}
										/>
									);
								case "exposure":
									return (
										<Exposure
											configuration={item}
											onChange={(newConfiguration) => {
												onChange(replaceById(props.list, newConfiguration, ({ id }) => id));
												resolve();
											}}
											onCancel={reject}
											templateVariant={props.templateVariant}
										/>
									);
							}
						})()}
						{/* </div> */}
					</Sidebar>
				)),
				{ signal },
			).promise.catch(noop);
		});

	return (
		<>
			<TemplateItem
				item={props.list[0]}
				onEdit={mode === "edit" ? onEdit && (() => onEdit(props.list[0])) : undefined}
			/>
			<DraggableList
				itemKey={(item) => item.id}
				items={props.list.slice(1)}
				disabled={!props.onChange}
				onReorder={
					mode === "edit"
						? props.onChange && ((items) => props.onChange!(props.list.slice(0, 1).concat(items)))
						: undefined
				}
			>
				{({ dragHandleProps, isDragging, draggableProps, item, innerRef }) => (
					<TemplateItem
						item={item}
						dragHandleProps={dragHandleProps}
						isDragging={isDragging}
						draggableProps={mode === "edit" ? draggableProps : undefined}
						innerRef={innerRef}
						onEdit={mode === "edit" ? onEdit && (() => onEdit(item)) : undefined}
						onDelete={
							mode === "edit"
								? props.onChange && (() => props.onChange!(props.list.filter((x) => x.id !== item.id)))
								: undefined
						}
					/>
				)}
			</DraggableList>
		</>
	);
}
