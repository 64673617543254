import Pexels from "$root/assets/images/pexels-ludvig-hedenborg-3753160_3.png";
import Rover from "$root/assets/images/Rover.svg";
import SphereLogo from "$root/assets/images/SPHERE_logo.svg";
import { useSystemValue } from "$root/functional-areas/system";
import { useLocaleFormatters } from "$root/localization/hooks";
import { dateISOtoLocalTimezone, exhaustiveMatchingGuard } from "$root/utils";
import { Text } from "@mdotm/mdotui/components";
import { Redirect } from "react-router";
import "./maintenance.scss";

const maintenanceMapInfo = {
	infrastructure: {
		title: "THE PLATFORM IS UNDER MAINTENANCE",
		description: "We apologize for any inconvenience, we will be back online in a few hours.",
	},
	migration: {
		title: "The platform is under maintenance",
		description(params: { startDate: string; endDate: string; formatTime(date: string | Date): string }) {
			const formattedStartDate = params.formatTime(params.startDate);
			const formattedEndDate = params.formatTime(params.endDate);
			return `Maintenance window: ${formattedStartDate} - ${formattedEndDate}. We'll be back online shortly and appreciate your understanding`;
		},
	},
	traffic: {
		title: "Temporarily Experiencing High Traffic",
		description:
			"We're enhancing server capabilities to accommodate demand. Please check back in a few hours for the optimal Sphere experience. Thank you for your patience!",
	},
};
type MaintenanceMode = keyof typeof maintenanceMapInfo;
const maintenanceMode = "migration" as MaintenanceMode; // trick ts, to cast variable as not pre defined

const Maintenance = (): JSX.Element => {
	const { systemMode } = useSystemValue();
	const { formatTime } = useLocaleFormatters();

	const title = (() => {
		switch (maintenanceMode) {
			case "infrastructure":
			case "migration":
			case "traffic":
				return maintenanceMapInfo[maintenanceMode].title;
			default:
				return exhaustiveMatchingGuard(maintenanceMode);
		}
	})();

	const description = (() => {
		switch (maintenanceMode) {
			case "infrastructure":
			case "traffic":
				return maintenanceMapInfo[maintenanceMode].title;
			case "migration": {
				const startDate = dateISOtoLocalTimezone("2025-03-17T12:30:00").localDate.toString();
				const endDate = dateISOtoLocalTimezone("2025-03-17T14:00:00").localDate.toString();
				return maintenanceMapInfo[maintenanceMode].description({ startDate, endDate, formatTime });
			}
			default:
				return exhaustiveMatchingGuard(maintenanceMode);
		}
	})();

	return systemMode !== "MAINTENANCE" ? (
		<Redirect to="/" />
	) : (
		<div>
			<div className="firstFather">
				<div className="container">
					<div className="topContainer">
						<img className="imageTop" src={Pexels} alt="" />
						<div className="wrapperIcon">
							<img className="icon" src={Rover} alt="" />
						</div>
					</div>
					<div />
					<div className="bottomContainer">
						<h1 className="title">{title}</h1>
						<h5 className="description">{description}</h5>
						<h5 className="description2">
							In case you need additional information, please send an email to&nbsp;
							<a
								style={{ color: "black" }}
								href="mailto:support@mdotm.ai?subject=Additional%20Information&body=Dear%20MDOTM%20Team,%20I%E2%80%99d%20like%20to%20have%20more%20information%20on%20the%20current%20platform%20outage.%20Thank%20you"
							>
								support@mdotm.ai
							</a>
						</h5>
					</div>
				</div>
				<img className="logo" src={SphereLogo} alt="" />
			</div>

			<div className="text-center">
				<Text type="Body/M/Book" color="#8C8EA8">
					<span>MDOTM Srl - Via passione 8, 20122, Milano - P.iva 09601510960</span>
					<span className="footerDivider">|</span>
					<a
						href="https://www.iubenda.com/privacy-policy/48016693"
						className="footerPolicy iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe"
						title="Privacy Policy "
					>
						Privacy Policy
					</a>
				</Text>
			</div>
		</div>
	);
};

export default Maintenance;
