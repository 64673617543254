import type {
	AvailableEntitiesResponse,
	AvailableFieldsResponse,
	CommentaryTemplateDto,
	FieldSetResponse,
	FieldType,
	Filter,
	FilterResponse,
	FilterValue,
	ListInvestmentsDTO,
	InvestmentProjectedListEntry,
	InvestmentsReportTemplate,
	InvestmentStatuses,
	MetricResultEntry,
	MetricResultResponse,
	MultiPresetResponse,
	PresetResponse,
	RiskModelExposureTypesResponse,
	SyncInfoDto,
	ThresholdResponse,
	UserInstrumentClassificationDto,
	UserPortfolioColumnOrdering,
	UserPortfolioColumnPreference,
	UserPortfolioColumnPreferencePreferencesTypeEnum,
	MetricEntity,
	MetricParameters,
	MetricReferenceType,
	MetricOrdering,
} from "$root/api/api-gen";
import {
	CommentaryTemplateControllerApiFactory,
	CustomerControllerV3ApiFactory,
	FieldKey,
	InstrumentsClassificationsControllerV1ApiFactory,
	InvestmentControllerV4ApiFactory,
	InvestmentsBulkAnalysisControllerV2ApiFactory,
	InvestmentsReportTemplateControllerApiFactory,
	PortfolioStudioPreferencesApiFactory,
	RiskModelControllerV1ApiFactory,
} from "$root/api/api-gen";
import { runWithErrorReporting } from "$root/api/error-reporting";
import { getApiGen } from "$root/api/factory";
import { useApiGen } from "$root/api/hooks";
import { withCache } from "$root/caching/with-cache";
import AuthorizationGuard, { hasAccess } from "$root/components/AuthorizationGuard";
import { DebouncedSearchInput } from "$root/components/DebouncedSearchInput";
import { IconWalls } from "$root/components/IconWall";
import { useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { ReactQueriesWrapperBase, ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import type { ColumnMetadata } from "$root/components/tables-extra/CustomizeColumns";
import { spawnCustomizeColumnsDialog } from "$root/components/tables-extra/CustomizeColumns";
import { useEventBus, useGroupEventBus } from "$root/event-bus";
import { aclByArea, roleByArea } from "$root/functional-areas/acl/checkers/all";
import { validateACLPermissions } from "$root/functional-areas/acl/checkers/shared";
import { useUpdateAccessListControlDialog } from "$root/functional-areas/acl/hook/useUpdateAccessListControlDialog";
import type { CompareOverlayProps } from "$root/functional-areas/compare-portfolio/CompareOverlay";
import { CompareOverlay, generateCompareItem } from "$root/functional-areas/compare-portfolio/CompareOverlay";
import { PortfolioStudioSettingTabEnum } from "$root/functional-areas/portfolio-studio-settings";
import type { DefaultSelectionProps } from "$root/functional-areas/portfolio-studio/dialogs/CompareDialog";
import type {
	PortfolioInsightPanelHandles,
	PortfolioInsightPanelPreset,
} from "$root/functional-areas/portfolio-studio/InsightPanel";
import PortfolioInsightPanel from "$root/functional-areas/portfolio-studio/InsightPanel";
import { usePortfolioStudioState } from "$root/functional-areas/portfolio-studio/PortfolioStudioStore";
import { portfolioEntityManagementActions } from "$root/functional-areas/portfolio/entity-management";
import { defaultPortfolioTemplates } from "$root/functional-areas/reports/default-templates";
import { useUserValue } from "$root/functional-areas/user";
import { usePaginationTools } from "$root/hooks/usePaginationTools";
import { useLocaleFormatters } from "$root/localization/hooks";
import { platformToast } from "$root/notification-system/toast";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { queryClientKeys, typedQueryClient } from "$root/third-party-integrations/react-query";
import type { MetricComparisonChartProps } from "$root/ui-lib/charts/MetricComparisonChart";
import { MetricComparisonChart } from "$root/ui-lib/charts/MetricComparisonChart";
import { actionsColumn } from "$root/ui-lib/interactive-collections/common-table-actions";
import { ToastableError } from "$root/utils/errors";
import { noRefetchDefaults, useQueryNoRefetch } from "$root/utils/react-query";
import { hours, minutes, seconds } from "$root/utils/time";
import type {
	ActionWithOptionalGroup,
	BatchAction,
	DataAttributesProps,
	DropdownMenuProps,
	Option,
} from "@mdotm/mdotui/components";
import {
	BaseHScrollTable,
	BatchActions,
	ButtonGroupRadio,
	Checkbox,
	CircularProgressBar,
	contextMenuHandler,
	LocalOverlay,
	Pagination,
	ProgressBar,
	Row,
	useSelectableTableColumn,
	type OrderBy,
	type PaginationRequest,
	type PaginationResponse,
	type TableColumn,
} from "@mdotm/mdotui/components";
import type { MultiSelectCtx } from "@mdotm/mdotui/headless";
import { useMultiSelect } from "@mdotm/mdotui/headless";
import {
	toClassListRecord,
	useDebounced,
	useDebouncedMemo,
	useDeepEqualEffect,
	useUnsafeUpdatedRef,
} from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSort, noop, nullary, stableEmptyArray, unpromisify } from "@mdotm/mdotui/utils";
import { useQueries, useQuery } from "@tanstack/react-query";
import type { HighchartsReactRefObject } from "highcharts-react-official";
import type Immutable from "immutable";
import { Map, OrderedMap, Set } from "immutable";
import type { ForwardedRef } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import { usePortfolioStudioTableSettings } from "../portfolio-studio-table-settings";
import { usePortfolioColumn } from "./columns";
import { metricToMeasurementUnit } from "./const";
import type { BulkAnalysisActions } from "./portfolio-actions";
import { useBulkAnalysisActions } from "./portfolio-actions";
import { useSearchParams } from "$root/utils";
import { useHistory } from "react-router";

export const PortfolioListViewMode = {
	mixed: "MIXED",
	chart: "CHART",
	table: "TABLE",
} as const;

export type PortfolioListViewMode = (typeof PortfolioListViewMode)[keyof typeof PortfolioListViewMode];

async function getPaginatedInvestmentList(
	params: PaginationRequest<
		{
			query?: string;
			sidePanelFilters?: FilterValue[];
			sortByParameters?: MetricParameters;
			sortMetricReferenceEntity?: MetricEntity;
			sortMetricReferenceType?: MetricReferenceType;
		},
		FieldKey
	>,
): Promise<PaginationResponse<ListInvestmentsDTO>> {
	const investmentApi = getApiGen(InvestmentControllerV4ApiFactory);
	const paginationResult = await axiosExtract(
		investmentApi.listInvestments({
			offset: params.skip,
			limit: params.take,
			searchName: params.filters?.query,
			...(params.orderBy && params.orderBy.length
				? {
						sortBy: params.orderBy.at(0)!.columnName!,
						sortDirection: params.orderBy.at(0)?.direction === "asc" ? "ASC" : "DESC",
				  }
				: {}),
			filters: params.filters?.sidePanelFilters,
			sortByParameters: params.filters?.sortByParameters,
			sortMetricReferenceEntity: params.filters?.sortMetricReferenceEntity,
			sortMetricReferenceType: params.filters?.sortMetricReferenceType,
		}),
	);

	return {
		items: paginationResult.items ?? [],
		total: paginationResult.pagination?.total ?? 0,
	};
}

function convertFilterResponseToFilter(filterResponse: Array<FilterResponse>): Array<Filter> {
	return filterResponse
		.filter((x) => x.key && x.value && x.relation)
		.map((x) => ({ fieldKey: x.key, relation: x.relation, value: x.value }));
}

function PortfolioList(): JSX.Element {
	const history = useHistory();
	const { preselectedInvestments: preselectedInvestmentsStr } = useSearchParams();
	const [preselectedInvestments] = useState(() => {
		if (!preselectedInvestmentsStr) {
			return null;
		}
		history.replace({
			...history.location,
			search: (() => {
				const parsed = new URLSearchParams(history.location.search);
				parsed.delete("preselectedInvestments");
				return parsed.toString();
			})(),
		});
		return preselectedInvestmentsStr.split("|");
	});

	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);
	const investmentsBulkAnalysisControllerV2Api = useApiGen(InvestmentsBulkAnalysisControllerV2ApiFactory);
	const customerV3Api = useApiGen(CustomerControllerV3ApiFactory);
	const instrumentsClassificationsControllerV1Api = useApiGen(InstrumentsClassificationsControllerV1ApiFactory);
	const investmentsReportTemplateApi = useApiGen(InvestmentsReportTemplateControllerApiFactory);
	const commentaryTemplateApi = useApiGen(CommentaryTemplateControllerApiFactory);
	const riskModelControllerV1Api = useApiGen(RiskModelControllerV1ApiFactory);
	const investmentV4Api = useApiGen(InvestmentControllerV4ApiFactory);

	const queryPortfolioListCommonInfo = useQueries({
		queries: [
			{
				...noRefetchDefaults,
				queryKey: [queryClientKeys.portfolioStudioInvestmentColumnsPreference],
				queryFn: () => axiosExtract(portfolioStudioPreferencesApi.getUserPortfolioColumnMetricsOrderingPreferences()),
				staleTime: minutes(10),
				cacheTime: minutes(10),
			},
			{
				...noRefetchDefaults,
				queryKey: [queryClientKeys.portfolioStudioUserSyncInfo],
				queryFn: () => axiosExtract(customerV3Api.users1()),
				staleTime: minutes(10),
				cacheTime: minutes(10),
			},
		],
	});

	const [queryPortfolioTableColumnPresences] = queryPortfolioListCommonInfo;

	const verifyBulkAnalysisPresetNameAvailability = useCallback(
		(name: string) => axiosExtract(investmentsBulkAnalysisControllerV2Api.isPresetNameAvailable(name)),
		[investmentsBulkAnalysisControllerV2Api],
	);

	const onUpdateFavoritePreset = useCallback(
		async (uuid: string) => {
			await axiosExtract(investmentsBulkAnalysisControllerV2Api.updateFavorite(uuid));
		},
		[investmentsBulkAnalysisControllerV2Api],
	);

	const thresholdForEntityProvider = useCallback(
		(metric: NonNullable<PortfolioInsightPanelPreset["metric"]>): Promise<ThresholdResponse> => {
			return axiosExtract(
				investmentsBulkAnalysisControllerV2Api.getThreshold({
					metricThresholdEntity: metric.keyMetricThreshold?.first()?.thresholdEntity,
					metricKey: metric.key,
					metricReferenceEntity: metric.referenceEntity,
					metricReferenceType: metric.referenceType,
					metricParameters: metric.metricParameters,
				}),
			);
		},
		[investmentsBulkAnalysisControllerV2Api],
	);

	const analysisBulkFieldsProvider = useCallback(
		() => axiosExtract(investmentsBulkAnalysisControllerV2Api.getAvailableFields()),
		[investmentsBulkAnalysisControllerV2Api],
	);

	const analysisBulkEntitiesProvider = useCallback(
		() => axiosExtract(investmentsBulkAnalysisControllerV2Api.getAvailableEntities()),
		[investmentsBulkAnalysisControllerV2Api],
	);

	const analysisBulkUserClassificationProvider = useCallback(
		() => axiosExtract(instrumentsClassificationsControllerV1Api.retrieveAllClassifications()),
		[instrumentsClassificationsControllerV1Api],
	);

	const analysisBulkExposureProvider = useCallback(
		() => axiosExtract(riskModelControllerV1Api.getRiskModelExposureTypes()),
		[riskModelControllerV1Api],
	);

	const onSaveAsNewPreset = useCallback(
		async (currentPreset: PortfolioInsightPanelPreset, name: string) => {
			await runWithErrorReporting(
				async () => {
					try {
						const { search, metric, filters, uuid, ...params } = currentPreset;
						const { keyMetricThreshold, ...reset } = metric ?? {};
						const keyMetricThresholdValues = keyMetricThreshold?.first();
						await axiosExtract(
							investmentsBulkAnalysisControllerV2Api.savePreset({
								...params,
								name,
								standard: false,
								favourite: false,
								filters: convertFilterResponseToFilter(Array.from(filters?.values() ?? [])),
								metric: {
									...reset,
									thresholdEntity: keyMetricThresholdValues?.thresholdEntity,
									thresholdType: keyMetricThresholdValues?.thresholdType,
									thresholdValue: keyMetricThresholdValues?.thresholdValue,
								},
							}),
						);
					} catch (error) {
						throw new ToastableError("Something went wrong please try later", { icon: "Portfolio", cause: error });
					}
				},
				{ area: "portfolio", attemptedOperation: { message: "try to save preset as new" } },
			);
		},
		[investmentsBulkAnalysisControllerV2Api],
	);

	const onSavePreset = useCallback(
		async (currentPreset: PortfolioInsightPanelPreset) => {
			await runWithErrorReporting(
				async () => {
					try {
						const { search, metric, filters, favorite, ...params } = currentPreset;
						const { keyMetricThreshold, ...reset } = metric ?? {};
						const keyMetricThresholdValues = keyMetricThreshold?.first();
						await axiosExtract(
							investmentsBulkAnalysisControllerV2Api.updatePreset({
								...params,
								favourite: favorite,
								filters: convertFilterResponseToFilter(Array.from(filters?.values() ?? [])),
								metric: {
									...reset,
									thresholdEntity: keyMetricThresholdValues?.thresholdEntity,
									thresholdType: keyMetricThresholdValues?.thresholdType,
									thresholdValue: keyMetricThresholdValues?.thresholdValue,
								},
							}),
						);
					} catch (error) {
						throw new ToastableError("Something went wrong please try later", { icon: "Portfolio", cause: error });
					}
				},
				{ area: "portfolio", attemptedOperation: { message: "try to save preset" } },
			);
		},
		[investmentsBulkAnalysisControllerV2Api],
	);

	const onSaveColumnPreferences = useCallback(
		async (data: ColumnMetadata<UserPortfolioColumnPreferencePreferencesTypeEnum>[]) => {
			try {
				const payload = {
					userPortfolioColumnPreferences: data.map((preference) => ({
						enabled: preference.visible,
						preferencesType: preference.id,
					})),
				} satisfies UserPortfolioColumnOrdering;
				await portfolioStudioPreferencesApi.setUserPortfolioColumnMetricsOrderingPreferences(payload);
				await queryPortfolioTableColumnPresences.refetch({ throwOnError: true });
				platformToast({
					children: "Table Successfully Edited.",
					severity: "success",
					icon: "Settings",
				});
			} catch (error) {
				platformToast({
					children: "Failed to update table",
					severity: "error",
					icon: "Settings",
				});
				throw error;
			}
		},
		[portfolioStudioPreferencesApi, queryPortfolioTableColumnPresences],
	);

	const keyMetricProvider = useCallback(
		(
			metric: NonNullable<PortfolioInsightPanelPreset["metric"]>,
			opt?: {
				sortParameters?: MetricParameters;
				sortKey?: FieldKey;
				sortDirection?: Exclude<MetricOrdering, "UNSORTED">;
				filters?: FilterValue[];
				search?: string;
			},
		) => {
			return axiosExtract(
				investmentsBulkAnalysisControllerV2Api.getMetricResult({
					key: metric.key,
					referenceEntity: metric.referenceType === "DELTA_RELATIVE" ? metric.referenceEntity : undefined,
					referenceType: metric.referenceType,
					metricParameters: metric.metricParameters,
					sortParameters: opt?.sortParameters,
					sortKey: opt?.sortKey,
					ordering: metric.ordering === "UNSORTED" ? opt?.sortDirection : metric.ordering,
					filters: opt?.filters,
					searchName: opt?.search,
				}),
			);
		},
		[investmentsBulkAnalysisControllerV2Api],
	);

	const analysisBulkFieldSetProvider = useCallback(
		(fieldType: FieldType) => axiosExtract(investmentsBulkAnalysisControllerV2Api.getFieldSet(fieldType)),
		[investmentsBulkAnalysisControllerV2Api],
	);

	const reportBuilderTemplatesProvider = useCallback(
		() => axiosExtract(investmentsReportTemplateApi.listInvestmentReportTemplates()),
		[investmentsReportTemplateApi],
	);

	const commentaryTemplatesProvider = useCallback(
		() => axiosExtract(commentaryTemplateApi.getTemplateList()),
		[commentaryTemplateApi],
	);

	const getProjectedList = useCallback(
		(params?: { filters?: FilterValue[]; search?: string }) => {
			return axiosExtract(
				investmentV4Api.getProjectedInvestmentList({ filters: params?.filters, searchName: params?.search }),
			);
		},
		[investmentV4Api],
	);

	return (
		<div className="flex-1 min-h-0 flex flex-col relative z-0">
			<ReactQueriesWrapperBase queries={queryPortfolioListCommonInfo}>
				{([{ userPortfolioColumnPreferences }, queryUserSyncInfo]) => (
					<PortfolioListInner
						userSyncInfo={queryUserSyncInfo}
						rowsProvider={getPaginatedInvestmentList}
						columnsPreferences={
							userPortfolioColumnPreferences?.filter(
								(x) => x.preferencesType !== "AVERAGE_SCORE", // remove once be has done migration
							) ?? []
						}
						verifyBulkAnalysisPresetNameAvailability={verifyBulkAnalysisPresetNameAvailability}
						presetProvider={() => axiosExtract(investmentsBulkAnalysisControllerV2Api.getPresets())}
						onUpdateFavoritePreset={onUpdateFavoritePreset}
						thresholdForEntityProvider={thresholdForEntityProvider}
						onSaveAsNewPreset={onSaveAsNewPreset}
						onSavePreset={onSavePreset}
						onSaveColumnPreferences={onSaveColumnPreferences}
						keyMetricProvider={keyMetricProvider}
						analysisBulkEntitiesProvider={analysisBulkEntitiesProvider}
						analysisBulkFieldsProvider={analysisBulkFieldsProvider}
						analysisBulkUserClassificationProvider={analysisBulkUserClassificationProvider}
						analysisBulkFieldSetProvider={analysisBulkFieldSetProvider}
						analysisBulkExposureProvider={analysisBulkExposureProvider}
						reportBuilderTemplatesProvider={reportBuilderTemplatesProvider}
						commentaryTemplatesProvider={commentaryTemplatesProvider}
						preselectedInvestments={preselectedInvestments}
						projectListProvider={getProjectedList}
					/>
				)}
			</ReactQueriesWrapperBase>
		</div>
	);
}

type PortfolioListInnerProps = {
	rowsProvider: typeof getPaginatedInvestmentList;
	presetProvider(): Promise<MultiPresetResponse>;
	verifyBulkAnalysisPresetNameAvailability(name: string): Promise<boolean>;
	onUpdateFavoritePreset(uuid: string): Promise<void>;
	thresholdForEntityProvider(metric: NonNullable<PortfolioInsightPanelPreset["metric"]>): Promise<ThresholdResponse>;
	// analysisBulkFilterProvider(): Promise<AvailableFieldsResponse>;
	onSavePreset(currentPreset: PortfolioInsightPanelPreset): Promise<void>;
	onSaveAsNewPreset(currentPreset: PortfolioInsightPanelPreset, name: string): Promise<void>;
	onSaveColumnPreferences(data: ColumnMetadata<UserPortfolioColumnPreferencePreferencesTypeEnum>[]): Promise<void>;
	keyMetricProvider(
		metric: NonNullable<PortfolioInsightPanelPreset["metric"]>,
		opt?: {
			sortParameters?: MetricParameters;
			sortKey?: FieldKey;
			sortDirection?: Exclude<MetricOrdering, "UNSORTED">;
			filters?: FilterValue[];
			search?: string;
		},
	): Promise<MetricResultResponse>;
	columnsPreferences: UserPortfolioColumnPreference[];
	userSyncInfo?: SyncInfoDto;
	preselectedInvestments: string[] | null;

	analysisBulkFieldsProvider(): Promise<AvailableFieldsResponse>;
	analysisBulkEntitiesProvider(): Promise<AvailableEntitiesResponse>;
	analysisBulkUserClassificationProvider(): Promise<UserInstrumentClassificationDto[]>;
	analysisBulkFieldSetProvider(fieldType: FieldType): Promise<FieldSetResponse>;
	analysisBulkExposureProvider(): Promise<RiskModelExposureTypesResponse>;

	reportBuilderTemplatesProvider(): Promise<Array<InvestmentsReportTemplate>>;
	commentaryTemplatesProvider(): Promise<Array<CommentaryTemplateDto>>;
	projectListProvider(params?: { filters?: FilterValue[]; search?: string }): Promise<InvestmentProjectedListEntry[]>;
};

function PortfolioListInner({
	rowsProvider,
	presetProvider,
	thresholdForEntityProvider,
	verifyBulkAnalysisPresetNameAvailability,
	onUpdateFavoritePreset,
	analysisBulkFieldsProvider,
	analysisBulkEntitiesProvider,
	analysisBulkUserClassificationProvider,
	analysisBulkFieldSetProvider,
	analysisBulkExposureProvider,
	// analysisBulkFilterProvider,
	onSaveAsNewPreset,
	onSavePreset,
	columnsPreferences,
	onSaveColumnPreferences,
	keyMetricProvider,
	userSyncInfo,
	preselectedInvestments,

	reportBuilderTemplatesProvider,
	commentaryTemplatesProvider,
	projectListProvider,
}: PortfolioListInnerProps) {
	const user = useUserValue();
	const { t } = useTranslation();
	const rowSelection = useMultiSelect<string>();
	const { formatNumber } = useLocaleFormatters();

	const [comparedInvestments, setComparedInvestments] = useState<CompareOverlayProps["compareData"]>([]);

	const { portfolioStudioStore, setPortfolioStudioStore } = usePortfolioStudioState();
	const { portfolioListOrderByName, setPortfolioListOrderByName } = usePortfolioStudioTableSettings();
	const [insightPanelPreset, setInsightPanelPreset] = useState<PortfolioInsightPanelPreset | undefined>(undefined);
	const portfolioInsightHandleRef = useRef<PortfolioInsightPanelHandles | null>(null);
	const chartRef = useRef<HighchartsReactRefObject | null>(null);

	useEffect(() => {
		if (!hasAccess(user, { requiredService: "BULK_ANALYSIS" })) {
			setPortfolioStudioStore({ viewMode: "TABLE", isightPanel: { open: false } });
		}
	}, [setPortfolioStudioStore, user]);

	const viewMode = portfolioStudioStore.viewMode ?? "MIXED";
	function setViewMode(mode: PortfolioListViewMode) {
		setPortfolioStudioStore((ps) => ({ ...ps, viewMode: mode }));
	}

	const validSidePanelFilters = useDebouncedMemo<FilterValue[] | undefined>(
		() => {
			const filters = insightPanelPreset?.filters?.valueSeq().toArray();

			return (
				filters
					?.filter((filter) => filter.key && filter.relation && filter.value && filter.value.length > 0)
					.map(
						(x): FilterValue => ({
							key: x.key,
							relation: x.relation,
							values: x.value,
						}),
					) ?? []
			);
		},
		[insightPanelPreset?.filters],
		{ debounceInterval: 500 },
	);

	const validMetricReferenceEntity = useDebouncedMemo<MetricEntity | undefined>(
		() => {
			const referenceIdentifier = insightPanelPreset?.metric?.referenceEntity?.identifier;
			const referenceType = insightPanelPreset?.metric?.referenceEntity?.type;

			if (!referenceIdentifier || !referenceType) {
				return undefined;
			}

			return { identifier: referenceIdentifier, type: referenceType };
		},
		[insightPanelPreset?.metric?.referenceEntity?.identifier, insightPanelPreset?.metric?.referenceEntity?.type],
		{ debounceInterval: 500 },
	);

	const validMetricReferenceType = useDebouncedMemo<MetricReferenceType | undefined>(
		() => insightPanelPreset?.metric?.referenceType,
		[insightPanelPreset?.metric?.referenceType],
		{ debounceInterval: 500 },
	);

	const { take, skip, query } = usePaginationTools({ take: 20 });

	const queryInstrumentsRows = useQueryNoRefetch(
		[
			queryClientKeys.portfolioStudioInvestmentList,
			portfolioListOrderByName,
			query.value,
			skip.value,
			take.value,
			validSidePanelFilters.value,
			validMetricReferenceEntity.value?.identifier,
			validMetricReferenceEntity.value?.type,
			validMetricReferenceType.value,

			insightPanelPreset?.metric?.metricParameters?.classificationUuid,
			insightPanelPreset?.metric?.metricParameters?.option,
			insightPanelPreset?.metric?.metricParameters?.riskModelExposureAssetClass,
			insightPanelPreset?.metric?.metricParameters?.riskModelExposureType,
		],
		{
			queryFn() {
				return rowsProvider({
					skip: skip.value,
					take: take.value,
					orderBy: portfolioListOrderByName,
					filters: {
						query: query.value,
						sidePanelFilters: validSidePanelFilters.value,
						sortMetricReferenceEntity: validMetricReferenceEntity.value,
						sortMetricReferenceType: validMetricReferenceType.value,
						sortByParameters: insightPanelPreset?.metric?.metricParameters,
					},
				});
			},
			keepPreviousData: true,
			staleTime: minutes(5),
			cacheTime: minutes(5),
		},
	);

	//introduce selection incoherence by resetting and when user add new filter
	const queryProjectInvestmentList = useQueryNoRefetch(
		[queryClientKeys.portfolioStudioProjectedInvestmentList, validSidePanelFilters.value],
		{
			queryFn() {
				return projectListProvider({
					filters: validSidePanelFilters.value,
					// search: query.value,
				});
			},
			onSuccess(newInvestmentList) {
				const investmentUuids = Set(newInvestmentList.map((x) => x.uuid!));
				const newSelection = rowSelection.selection
					.filter((uuid) => investmentUuids.has(uuid))
					.valueSeq()
					.toArray();
				rowSelection.setSelection(newSelection);
			},
		},
	);

	const queryUnfilteredProjectList = useQueryNoRefetch(
		[queryClientKeys.portfolioStudioProjectedInvestmentList, "unfilteredProjectedInvestmentList"],
		{
			queryFn() {
				return projectListProvider();
			},
		},
	);

	useGroupEventBus(
		"investment-import-update",
		() => {
			typedQueryClient()
				.invalidateQueries([
					queryClientKeys.portfolioStudioInvestmentList,
					queryClientKeys.portfolioStudioProjectedInvestmentList,
					queryClientKeys.portfolioStudioKeyMetric,
				])
				.catch(noop);
		},
		{ timeout: seconds(3) },
	);

	useGroupEventBus(
		"investment-bulk-enhance-update",
		() => {
			typedQueryClient()
				.invalidateQueries([
					queryClientKeys.portfolioStudioInvestmentList,
					queryClientKeys.portfolioStudioProjectedInvestmentList,
					queryClientKeys.portfolioStudioKeyMetric,
				])
				.catch(noop);
		},
		{ timeout: seconds(3) },
	);

	useGroupEventBus(
		"investment-update",
		(event) => {
			const someEventsArePortfolios = event.some(
				(x) => x.type && x.type !== "UNIVERSE_UPDATE" && x.type !== "REFERENCE_UPDATE" && x.type !== "NONE",
			);

			if (someEventsArePortfolios) {
				typedQueryClient()
					.invalidateQueries([
						queryClientKeys.portfolioStudioInvestmentList,
						queryClientKeys.portfolioStudioProjectedInvestmentList,
						queryClientKeys.portfolioStudioKeyMetric,
					])
					.catch(noop);
			}
		},
		{ timeout: seconds(3) },
	);

	useEventBus("shared-entity", (e) => {
		if (e.level === "INFO" && e.sharedEntityType === "INVESTMENT") {
			typedQueryClient()
				.invalidateQueries([
					queryClientKeys.portfolioStudioInvestmentList,
					queryClientKeys.portfolioStudioProjectedInvestmentList,
					queryClientKeys.portfolioStudioKeyMetric,
				])
				.catch(noop);
		}
	});

	const rows = useMemo(() => queryInstrumentsRows.data?.items ?? [], [queryInstrumentsRows.data]);
	const unfilteredProjectedPortfolios = useMemo(
		() => queryUnfilteredProjectList.data ?? [],
		[queryUnfilteredProjectList.data],
	);
	const unfilteredProjectedRowsMap = useMemo(
		() => Map(unfilteredProjectedPortfolios.filter((x) => x.uuid).map((x) => [x.uuid!, x])),
		[unfilteredProjectedPortfolios],
	);

	const metricResultQuery = useQueryNoRefetch({
		queryKey: [
			queryClientKeys.portfolioStudioKeyMetric,
			insightPanelPreset?.metric?.key,
			insightPanelPreset?.metric?.metricParameters?.classificationUuid,
			insightPanelPreset?.metric?.metricParameters?.option,
			insightPanelPreset?.metric?.metricParameters?.riskModelExposureAssetClass,
			insightPanelPreset?.metric?.metricParameters?.riskModelExposureType,
			insightPanelPreset?.metric?.referenceEntity?.identifier,
			insightPanelPreset?.metric?.referenceType,
			insightPanelPreset?.uuid,
			insightPanelPreset?.metric?.ordering,
			portfolioListOrderByName.at(0)?.columnName,
			portfolioListOrderByName.at(0)?.direction,
			validSidePanelFilters.value,
			query.value,
		],
		enabled: hasAccess(user, { requiredService: "BULK_ANALYSIS" }),
		queryFn: () => {
			const metric = insightPanelPreset?.metric;
			if (!metric) {
				return null;
			}
			return keyMetricProvider(metric, {
				sortKey: portfolioListOrderByName.at(0)?.columnName,
				sortParameters: metric.metricParameters, // will find params in body request but semantically it used just for sorting
				sortDirection: portfolioListOrderByName.at(0)?.direction === "asc" ? "ASC" : "DESC",
				filters: validSidePanelFilters.value,
				search: query.value,
			});
		},
		keepPreviousData: true,
		staleTime: minutes(5),
		cacheTime: minutes(5),
	});

	const { invoke: reflowChart } = useDebounced(
		() => {
			const chart = chartRef.current?.chart;
			if (chart) {
				chart.reflow();
			}
		},
		{ debounceInterval: seconds(3) },
	);

	const { invoke: resetZoom } = useDebounced(
		() => {
			if (chartRef.current) {
				chartRef.current.chart.zoomOut();
			}
		},
		{ debounceInterval: seconds(0.5) },
	);

	useDeepEqualEffect(() => {
		noop(metricResultQuery.data); // track
		console.log(metricResultQuery.data, chartRef.current);
		resetZoom();
	}, [metricResultQuery.data, resetZoom]);

	const { metricResultEntries, ...metricStats } = metricResultQuery.data ?? {};
	const { value: metricResultsById } = useDebouncedMemo(
		() => OrderedMap(metricResultEntries?.map((entry) => [entry.uuid!, entry]) ?? []),
		[metricResultEntries],
	);

	const onExpandChange = useCallback(() => {
		setPortfolioStudioStore((ps) => ({ ...ps, isightPanel: { open: !ps.isightPanel?.open } }));
		reflowChart();
	}, [reflowChart, setPortfolioStudioStore]);

	const onCompareInvestments = useCallback(
		(portfolio: ListInvestmentsDTO, enhanced?: boolean) =>
			setComparedInvestments((prev) => {
				const portfolioAlreadyCompared = prev.find((x) => x.uuid === portfolio.uuid && x.enhanced === enhanced);
				if (portfolioAlreadyCompared) {
					return prev.filter((x) => x.id !== portfolioAlreadyCompared.id);
				}

				return [...prev, generateCompareItem(portfolio, enhanced ?? false)];
			}),
		[],
	);

	const allVisibleColumns =
		usePortfolioColumn({
			comparablePortfolios: comparedInvestments, //fix naming
			toggleComparePortfolio: onCompareInvestments, //fix naming
			columnsPreferences,
			userSyncInfo,
		}) ?? (stableEmptyArray as TableColumn<ListInvestmentsDTO, FieldKey>[]);

	const { key: metricKey, metricParameters } = insightPanelPreset?.metric ?? {};

	const userClassificationsQuery = useQuery({
		...noRefetchDefaults,
		queryKey: [queryClientKeys.instrumentCustomisationUserClassification],
		async queryFn() {
			const userClassifications = await analysisBulkUserClassificationProvider();
			return { userClassifications };
		},
		staleTime: minutes(10),
		cacheTime: minutes(10),
	});

	const metricColumn = useMemo<TableColumn<ListInvestmentsDTO, FieldKey> | undefined>(
		() =>
			metricKey
				? {
						name: metricKey,
						content: (row) =>
							metricResultsById.get(row.uuid!)?.value != null
								? `${formatNumber(metricResultsById.get(row.uuid!)?.value)}${metricToMeasurementUnit[metricKey] ?? ""}`
								: t("NOT_AVAILABLE_DATA"),
						header:
							(metricParameters?.classificationUuid &&
								userClassificationsQuery.data?.userClassifications.find(
									(c) => c.classificationUuid === metricParameters?.classificationUuid,
								)?.name) ||
							metricParameters?.riskModelExposureAssetClass ||
							t(`TABLE.HEADERS.${metricKey}`),
						sortFn: (a, b) =>
							builtInSort(
								metricResultsById.get(a.uuid!)?.value ?? -Infinity,
								metricResultsById.get(b.uuid!)?.value ?? -Infinity,
							),
						cellClassList: "tabular-nums",
						width: 168,
				  }
				: undefined,
		[
			metricKey,
			metricParameters?.classificationUuid,
			metricParameters?.riskModelExposureAssetClass,
			userClassificationsQuery.data?.userClassifications,
			t,
			metricResultsById,
			formatNumber,
		],
	);

	const visibleColumns = useMemo<Array<TableColumn<ListInvestmentsDTO, FieldKey>>>(() => {
		if (!metricKey || !metricColumn) {
			return allVisibleColumns;
		}
		const [first, ...others] = allVisibleColumns.filter((x) => x.name !== metricKey);
		console.assert(first != null, "no visible column");
		return [first, metricColumn, ...others];
	}, [allVisibleColumns, metricKey, metricColumn]);

	const onChangeOrder = useCallback(
		(newOrderBy: OrderBy<FieldKey>[]) => {
			setPortfolioListOrderByName(newOrderBy);
			const insightNewOrder = newOrderBy.at(0)?.columnName === insightPanelPreset?.metric?.key;
			const order = newOrderBy.at(0)?.direction;
			portfolioInsightHandleRef.current?.setOrder(!insightNewOrder ? "UNSORTED" : order === "asc" ? "ASC" : "DESC");
		},
		[insightPanelPreset?.metric?.key, setPortfolioListOrderByName],
	);

	const onCompareMultipleInvestments = useCallback(
		(selected?: Map<string, DefaultSelectionProps>) => {
			setComparedInvestments((prev) => {
				const selectedPortfolios = Set<string>([...prev.map((x) => x.uuid ?? ""), ...(selected?.keys() ?? [])]);

				return selectedPortfolios.toArray().flatMap((uuid) => {
					const investment = unfilteredProjectedRowsMap?.get(uuid);
					if (!investment) {
						return [];
					}

					const preferences = selected?.get(uuid);
					const existingPortfolio = prev.filter((portfolio) => portfolio.uuid === uuid);
					const currentPortfolio = generateCompareItem(investment, false);
					const enhancedPortfolio = generateCompareItem(investment, true);
					if (existingPortfolio.length === 0) {
						return preferences === undefined
							? [currentPortfolio]
							: [
									...(preferences.current ? [currentPortfolio] : []),
									...(preferences.enhance ? [enhancedPortfolio] : []),
							  ];
					}

					return preferences === undefined
						? existingPortfolio
						: [
								...(preferences.current ? [existingPortfolio.find((x) => !x.enhanced) ?? currentPortfolio] : []),
								...(preferences.enhance ? [existingPortfolio.find((x) => x.enhanced) ?? enhancedPortfolio] : []),
						  ];
				});
			});
		},
		[unfilteredProjectedRowsMap],
	);

	const portfolioBulkActions = useBulkAnalysisActions(
		rowSelection.selection.toArray().map((uuid) => unfilteredProjectedRowsMap.get(uuid)!),
		{
			currentlyComparedInvestments: comparedInvestments.map(({ uuid }) => uuid!),
			setManualSelection: (uuids) => portfolioInsightHandleRef.current?.setManualSelection(uuids),
			setCompareMultipleInvestments: onCompareMultipleInvestments,
			resetMultiselectCtx: () => rowSelection.reset(),
		},
	);

	const onApplyFilters = useCallback(
		(investments: Set<string>) => {
			portfolioInsightHandleRef.current?.setManualSelection(investments.toArray());
			rowSelection.reset();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[rowSelection],
	);

	if (queryUnfilteredProjectList.isError || queryInstrumentsRows.isError) {
		return <IconWalls.ErrorData />;
	}

	if (queryUnfilteredProjectList.data?.length === 0) {
		return (
			<IconWalls.PortfolioListEmptyData
				refetch={async () => {
					await queryInstrumentsRows.refetch();
				}}
			/>
		);
	}

	return (
		<>
			<div className="min-h-0 grow flex relative z-0">
				<AuthorizationGuard requiredService="BULK_ANALYSIS">
					<PortfolioInsightPanel
						expand={portfolioStudioStore.isightPanel?.open ?? false}
						onExpandChange={onExpandChange}
						handleRef={portfolioInsightHandleRef}
						onCheckIfNameIsAvailable={verifyBulkAnalysisPresetNameAvailability}
						onChangePreset={(newPreset) => {
							if (newPreset?.search != null) {
								query.set(newPreset.search);
							}

							setInsightPanelPreset(newPreset);
							if (newPreset?.metric?.key && newPreset?.metric?.ordering !== "UNSORTED") {
								setPortfolioListOrderByName([
									{
										columnName: newPreset?.metric?.key,
										direction: newPreset?.metric?.ordering === "ASC" ? "asc" : "desc",
									},
								]);
							}
						}}
						presetsProvider={async () => {
							const base = await presetProvider();

							if (!preselectedInvestments) {
								return base;
							}
							const temporarySelection: PresetResponse = {
								name: "Temporary selection",
								uuid: "temporary-selection",
								favorite: false,
								standard: false,
								metric: base.currentPreset?.metric,
								filters: [
									{
										key: "NAME",
										type: "FINITE_SET_PORTFOLIOS",
										relation: "EQUAL",
										value: preselectedInvestments,
									},
								],
							};
							return {
								currentPreset: temporarySelection,
								presets: [temporarySelection, ...(base.presets ?? [])],
							};
						}}
						selectableEntitiesProvider={analysisBulkEntitiesProvider}
						selectableFieldsProvider={analysisBulkFieldsProvider}
						selectableUserClassificationProvider={analysisBulkUserClassificationProvider}
						selectableExposureProvider={analysisBulkExposureProvider}
						fieldSetProvider={analysisBulkFieldSetProvider}
						onChangeFavorite={onUpdateFavoritePreset}
						thresholdForEntityProvider={withCache(
							async (metric) => {
								const { thresholdValue } = await thresholdForEntityProvider(metric);
								return thresholdValue;
							},
							{
								cacheKey: (metric) =>
									[
										"portfolioListThreshold",
										metric.keyMetricThreshold?.first()?.thresholdEntity?.identifier,
										metric.key,
										metric.referenceEntity?.identifier,
										metric.referenceType,
										metric.referenceEntity,
									].join("-"),
								cacheTime: hours(1),
							},
						)}
						onSave={onSavePreset}
						onSaveAsNew={onSaveAsNewPreset}
					/>
				</AuthorizationGuard>
				<div className="min-h-0 min-w-0 grow flex flex-col rounded-l-none rounded-md bg-white px-4 pt-6 pb-2 ">
					<div className="min-h-0 grow flex flex-col overflow-y-auto">
						<AuthorizationGuard requiredService="BULK_ANALYSIS">
							<div className="flex justify-between items-center mb-2">
								<ButtonGroupRadio
									size="small"
									value={viewMode}
									onChange={setViewMode}
									options={[
										{
											"data-qualifier": `BulkAnalysis/SetViewMode(${PortfolioListViewMode.mixed})`,
											value: PortfolioListViewMode.mixed,
											icon: "toolchart-tablegraph",
										},
										{
											"data-qualifier": `BulkAnalysis/SetViewMode(${PortfolioListViewMode.chart})`,
											value: PortfolioListViewMode.chart,
											icon: "toolchart-cart",
										},
										{
											classList: "justify-center rounded-r-lg",
											"data-qualifier": `BulkAnalysis/SetViewMode(${PortfolioListViewMode.table})`,
											value: PortfolioListViewMode.table,
											icon: "toolchart-table",
										},
									]}
								/>
								<div>
									{queryInstrumentsRows.data?.total != null ? (
										<Pagination
											skip={skip.value}
											take={take.value}
											total={queryInstrumentsRows.data?.total ?? 0}
											onChange={skip.set}
										/>
									) : (
										"..."
									)}
								</div>
							</div>
						</AuthorizationGuard>

						{!hasAccess(user, { requiredService: "BULK_ANALYSIS" }) && (
							<DebouncedSearchInput
								query={insightPanelPreset?.search ?? ""}
								onChange={(search) => {
									query.set(search);
									setInsightPanelPreset((preset) => ({ ...preset, search }));
								}}
								placeholder="search portfolio by name"
								data-qualifier="InsightPanel/SearchByKeyWord"
								style={{ width: 692 }}
								classList="mb-4"
							/>
						)}

						<ReactQueryWrapperBase query={queryInstrumentsRows} loadingFallback={<IconWalls.LoadingData />}>
							{() => (
								<div className="min-h-0 grow flex flex-col relative mb-2">
									<LocalOverlay
										show={queryInstrumentsRows.isFetching || metricResultQuery.isFetching}
										classList="z-10"
										style={{
											background: "transparent",
										}}
									>
										<CircularProgressBar value="indeterminate" />
									</LocalOverlay>

									{match(viewMode)
										.with("TABLE", () => (
											<PortfolioTable
												sortedRows={rows}
												rowSelection={rowSelection}
												columnsPreferences={columnsPreferences}
												onSubmitColumnPreferences={onSaveColumnPreferences}
												visibleColumns={visibleColumns} // todo: change
												portfolioListOrderByName={portfolioListOrderByName}
												setPortfolioListOrderByName={onChangeOrder}
												metricColumn={insightPanelPreset?.metric?.key}
												isMetricResultLoading={
													metricResultQuery.isLoading && hasAccess(user, { requiredService: "BULK_ANALYSIS" })
												}
												refetchPortfolioList={async () => {
													await queryInstrumentsRows.refetch();
												}}
												key={skip.value}
											/>
										))
										.with("CHART", () => (
											<Chart
												chartRef={chartRef}
												thresholdValue={insightPanelPreset?.metric?.keyMetricThreshold?.first()?.thresholdValue}
												multiSelectCtx={rowSelection}
												metricResultsById={metricResultsById}
												onThresholdChange={portfolioInsightHandleRef.current?.setThreshold}
												title={insightPanelPreset?.metric?.key}
												stats={metricStats}
												chartHeight="full"
												unit={
													insightPanelPreset?.metric?.key
														? metricToMeasurementUnit[insightPanelPreset?.metric?.key]
														: undefined
												}
											/>
										))
										.with("MIXED", () => (
											<>
												<Chart
													chartRef={chartRef}
													thresholdValue={insightPanelPreset?.metric?.keyMetricThreshold?.first()?.thresholdValue}
													multiSelectCtx={rowSelection}
													metricResultsById={metricResultsById}
													onThresholdChange={portfolioInsightHandleRef.current?.setThreshold}
													title={insightPanelPreset?.metric?.key}
													stats={metricStats}
													chartHeight="full"
													unit={
														insightPanelPreset?.metric?.key
															? metricToMeasurementUnit[insightPanelPreset?.metric?.key]
															: undefined
													}
												/>
												<br />
												<div className="basis-1/2 min-h-0 grow shrink-0 flex flex-col">
													<PortfolioTable
														sortedRows={rows}
														rowSelection={rowSelection}
														columnsPreferences={columnsPreferences}
														onSubmitColumnPreferences={onSaveColumnPreferences}
														visibleColumns={visibleColumns} // todo: change
														portfolioListOrderByName={portfolioListOrderByName}
														setPortfolioListOrderByName={onChangeOrder}
														metricColumn={insightPanelPreset?.metric?.key}
														isMetricResultLoading={
															metricResultQuery.isLoading && hasAccess(user, { requiredService: "BULK_ANALYSIS" })
														}
														refetchPortfolioList={async () => {
															await queryInstrumentsRows.refetch();
														}}
													/>
												</div>
											</>
										))
										.otherwise(() => (
											<></>
										))}
								</div>
							)}
						</ReactQueryWrapperBase>
					</div>
				</div>
			</div>
			<PortfolioToolbar
				selectableRows={queryInstrumentsRows.data?.total ?? 0} //projectedRowsMap.size
				rowSelection={rowSelection}
				reportBuilderTemplatesProvider={reportBuilderTemplatesProvider}
				commentaryTemplatesProvider={commentaryTemplatesProvider}
				onApplyFilters={onApplyFilters}
				portfolioBulkActions={portfolioBulkActions}
				projectedRowsMap={unfilteredProjectedRowsMap}
				disable={queryProjectInvestmentList.isFetching}
			/>
			<CompareOverlay
				show={comparedInvestments.length > 0}
				onClose={() => setComparedInvestments([])}
				onRemove={(id) => setComparedInvestments((v) => v.filter((x) => x.id !== id))}
				compareData={comparedInvestments}
			/>
		</>
	);
}

function PortfolioToolbar(props: {
	rowSelection: MultiSelectCtx<string>;
	selectableRows: number;
	portfolioBulkActions: BulkAnalysisActions;
	reportBuilderTemplatesProvider(): Promise<Array<InvestmentsReportTemplate>>;
	commentaryTemplatesProvider(): Promise<Array<CommentaryTemplateDto>>;
	onApplyFilters(investments: Set<string>): void;
	projectedRowsMap: Immutable.Map<string, InvestmentProjectedListEntry>;
	disable?: boolean;
}) {
	const { rowSelection, onApplyFilters, portfolioBulkActions, selectableRows } = props;

	const { t } = useTranslation();
	const user = useUserValue();
	const { push } = useTypedNavigation();

	const handler = useUnsafeUpdatedRef({
		push,
		onApplyFilters,
		portfolioBulkActions,
	});

	const queryReportBuilderTemplates = useQueryNoRefetch(["queryReportBuilderTemplates"], {
		queryFn: () => props.reportBuilderTemplatesProvider(),
	});

	const reportBuilderTemplates = useMemo(() => {
		const reportBuilderTemplatesResponse = queryReportBuilderTemplates.data;
		if (!reportBuilderTemplatesResponse) {
			return [];
		}
		const defaults = [
			...defaultPortfolioTemplates.map((defaultTemplate) => ({
				...defaultTemplate,
				visible: true,
			})),
		];
		return [
			...defaults.filter((x) => !reportBuilderTemplatesResponse.find((y) => x.templateName === y.templateName)),
			...reportBuilderTemplatesResponse.filter((x) => x.visible),
		];
	}, [queryReportBuilderTemplates]);

	const queryCommentaryTemplates = useQueryNoRefetch(["queryCommentaryTemplates"], {
		queryFn: () => props.commentaryTemplatesProvider(),
	});

	const commentaryTemplateOptions = useMemo<Array<Option<string>>>(() => {
		const commentaryTemplates = queryCommentaryTemplates.data;
		if (!commentaryTemplates) {
			return [];
		}
		return commentaryTemplates.flatMap((x) =>
			x.visible && x.uuid && x.name ? [{ label: x.name, value: x.uuid }] : [],
		);
	}, [queryCommentaryTemplates]);

	const selection = useMemo(() => rowSelection.selection, [rowSelection]);

	const actions = useMemo<Array<BatchAction<"DOWNLOAD" | ""> & DataAttributesProps>>(
		() => [
			...(hasAccess(user, { requiredService: "BULK_ANALYSIS" })
				? [
						{
							label: "Apply selection to filter",
							icon: "Filter" as const,
							onClick: () => handler.current.onApplyFilters(selection),
							"data-qualifier": "PortfolioStudio/PortfolioList/BatchAction(ApplyFilter)",
							disabled: props.disable,
						} satisfies BatchAction<"DOWNLOAD" | ""> & DataAttributesProps,
				  ]
				: []),

			// delete
			{
				label: "Delete",
				icon: "Delete" as const,
				onClick: () => portfolioBulkActions.delete(),
				disabled: props.disable,
				"data-qualifier": "PortfolioStudio/PortfolioList/BatchAction(Delete)",
			},

			// download
			{
				label: "Download",
				icon: "Dowload" as const,
				dropdown: {
					actions: [
						...reportBuilderTemplates.map(
							(template) =>
								({
									group: { id: "DOWNLOAD" },
									icon: "pdf",
									label: (template.templateName ?? "").concat(" (pdf)"),
									onClickAsync: () => portfolioBulkActions.downloadReport(template),
								}) satisfies ActionWithOptionalGroup<"DOWNLOAD">,
						),

						...(hasAccess(user, { requiredService: "EXPORT" })
							? (user.exportFormats ?? []).map(
									(format) =>
										({
											group: { id: "DOWNLOAD" },
											icon: "xls",
											onClick: () => portfolioBulkActions.downloadComposition(format),
											label: `${t(`EXPORT.${format}`)} template`,
										}) satisfies ActionWithOptionalGroup<"DOWNLOAD">,
							  )
							: []),
						hasAccess(user, { requiredService: "INVESTMENTS_REPORT_TEMPLATE_EDITOR" })
							? {
									icon: "Settings" as const,
									label: "Report builder",
									onClick: () =>
										handler.current.push("PortfolioStudioSettings", {
											tab: PortfolioStudioSettingTabEnum.ReportCustomisation,
										}),
							  }
							: null,
					],
				},
				disabled: props.disable,
				"data-qualifier": "PortfolioStudio/PortfolioList/BatchAction(Download)",
			},

			// compare
			{
				label: "Compare",
				icon: "compare" as const,
				onClick: () => portfolioBulkActions.compare(),
				disabled: props.disable,
				"data-qualifier": "PortfolioStudio/PortfolioList/BatchAction(Compare)",
			},

			//create proposal
			{
				label: "Create proposal",
				icon: "Enhance" as const,
				onClick: () => portfolioBulkActions.createProposal(),
				disabled: props.disable,
				"data-qualifier": "PortfolioStudio/PortfolioList/BatchAction(CreateProposal)",
			},

			//edit portfolio settings
			{
				label: "Edit market view",
				icon: "Settings" as const,
				onClick: () => portfolioBulkActions.editSettings(),
				disabled: props.disable,
				"data-qualifier": "PortfolioStudio/PortfolioList/BatchAction(EditPortfolioSettings)",
			},

			// merge (aka: create new portfolio with editor)
			{
				label: "Merge",
				icon: "merge" as const,
				onClick: () => portfolioBulkActions.useAsMixedPortfolio(),
				disabled: props.disable,
				"data-qualifier": "PortfolioStudio/PortfolioList/BatchAction(MergeComposition)",
			},

			//generate commentary
			{
				label: "Generate commentary",
				icon: "sphere-ai" as const,
				dropdown: {
					actions: commentaryTemplateOptions.map(
						(commentaryTemplate) =>
							({
								label: commentaryTemplate.label,
								onClickAsync: () => portfolioBulkActions.generateCommentary(commentaryTemplate.value),
							}) satisfies ActionWithOptionalGroup<"">,
					),
					floatingAppearance: { classList: "max-h-[312px]" },
				},
				disabled: props.disable,
				"data-qualifier": "PortfolioStudio/PortfolioList/BatchAction(GenerateCommentary)",
			},

			// manage proposal
			{
				label: "Manage proposal",
				icon: "proposal-ready" as const,
				dropdown: {
					actions: [
						{
							label: "Accept proposal",
							onClick: () => portfolioBulkActions.acceptProposal(),
						},
						{
							label: "Reject proposal",
							onClick: () => portfolioBulkActions.rejectProposal(),
						},
					] satisfies ActionWithOptionalGroup<"">[],
					floatingAppearance: { classList: "max-h-[312px]" },
				},
				disabled: props.disable,
				"data-qualifier": "PortfolioStudio/PortfolioList/BatchAction(ManageProposal)",
			},
		],
		[
			user,
			selection,
			reportBuilderTemplates,
			commentaryTemplateOptions,
			handler,
			t,
			portfolioBulkActions,
			props.disable,
		],
	);

	const manageSelectionAction = useMemo<BatchAction<"" | "DOWNLOAD"> & DataAttributesProps>(
		() => ({
			label: "Manage selection",
			dropdown: {
				actions: [
					{
						label: selectableRows === 1 ? `Select one portfolio` : `Select all ${selectableRows} portfolios`,
						onClick() {
							rowSelection.setSelection(rowSelection.selection.merge(props.projectedRowsMap.keys()));
						},
						group: "",
					},
					{
						label: `Unselect`,
						onClick() {
							rowSelection.reset();
						},
						group: "",
					},
				],
			},
		}),
		[selectableRows, rowSelection, props.projectedRowsMap],
	);

	return (
		rowSelection.selection.size > 0 && (
			<BatchActions
				manageSelectionAction={manageSelectionAction}
				palette="quaternary"
				classList={`!bg-[color:${themeCSSVars.palette_N500}] h-11 mt-2 shrink-0 px-2 rounded-md shadow-xl shadow-[color:${themeCSSVars.palette_N200}] overflow-x-auto [&>div:first-child]:!shrink-0 [&>div:first-child]:!min-w-[90px] [&>button]:!shrink-0 !flex-nowrap`}
				selected={rowSelection.selection.size}
				total={props.selectableRows}
				actions={actions}
			/>
		)
	);
}

type PortfolioTableProps = {
	rowSelection: MultiSelectCtx<string>;
	visibleColumns: TableColumn<ListInvestmentsDTO, FieldKey>[];
	sortedRows: ListInvestmentsDTO[];
	portfolioListOrderByName: OrderBy<FieldKey>[];
	setPortfolioListOrderByName: (newOrderBy: OrderBy<FieldKey>[]) => void;
	onSubmitColumnPreferences: (
		payload: ColumnMetadata<UserPortfolioColumnPreferencePreferencesTypeEnum>[],
	) => Promise<void>;
	metricColumn?: string;
	isMetricResultLoading: boolean;
	columnsPreferences: UserPortfolioColumnPreference[];
	refetchPortfolioList(): Promise<void>;
};

const canEnhanceInvestment = (status?: InvestmentStatuses) => status === "READY" || status === "ACCEPTED";
function PortfolioTable({
	rowSelection,
	visibleColumns,
	sortedRows,
	portfolioListOrderByName,
	setPortfolioListOrderByName,
	onSubmitColumnPreferences,
	metricColumn,
	isMetricResultLoading,
	columnsPreferences,
	refetchPortfolioList,
}: PortfolioTableProps) {
	const { aclSpawn } = useUpdateAccessListControlDialog("INVESTMENT");
	const { t } = useTranslation();
	const user = useUserValue();
	const { push } = useTypedNavigation();

	const customizableColumns = useMemo(
		() =>
			(columnsPreferences ?? []).map((c) => ({
				label: c.preferencesType ? t(`TABLE.HEADERS.${c.preferencesType}`) : "-",
				id: c.preferencesType!,
				visible: c.enabled ?? false,
				disabled: c.preferencesType === "NAME",
				hidden: c.preferencesType === "NAME",
			})),
		[columnsPreferences, t],
	);

	const {
		column: checkboxColumn,
		rowClassList,
		multiSelectCtx: tableMultiSelectCtx,
	} = useSelectableTableColumn({
		rows: sortedRows,
		selectBy: (p) => p.uuid!,
		multiSelectCtx: rowSelection,
	});

	const getDropdownActions = useCallback(
		(portfolio: ListInvestmentsDTO): DropdownMenuProps<HTMLElement, "">["actions"] => {
			const canEnhance = canEnhanceInvestment(portfolio.status);

			const entityManagementActions = portfolioEntityManagementActions(user, portfolio, {
				onRename: unpromisify(nullary(refetchPortfolioList)),
				onDuplicate: unpromisify(nullary(refetchPortfolioList)),
				onDelete() {
					refetchPortfolioList().catch(noop);
					tableMultiSelectCtx.remove(portfolio.uuid ?? "");
				},
			});

			return [
				aclByArea.portfolio.canCreateProposal(user.id, portfolio.richAcl?.acl ?? []) && {
					icon: canEnhance ? "Enhance" : "Enhance-Not-available",
					disabled: !canEnhance,
					onClick: () => push("Portfolios/EditPortfolio", { portfolioUid: portfolio.uuid! }),
					"data-qualifier": "PortfolioStudio/PortfolioList/DropdownMenu/DropdownItem(Enhance)",
					label: "Create proposal",
				},
				{
					icon: "Delete",
					disabled: !entityManagementActions.deleteAsync,
					onClick: unpromisify(entityManagementActions.deleteAsync ?? noop),
					"data-qualifier": "PortfolioStudio/PortfolioList/DropdownMenu/DropdownItem(Delete)",
					label: "Delete",
				},
				{
					icon: "Content-Copy",
					disabled: !entityManagementActions.duplicateAsync,
					onClick: unpromisify(entityManagementActions.duplicateAsync ?? noop),
					"data-qualifier": "PortfolioStudio/PortfolioList/DropdownMenu/DropdownItem(Duplicate)",
					label: "Duplicate",
				},
				{
					icon: "Edit",
					onClick: unpromisify(entityManagementActions.renameAsync ?? noop),
					"data-qualifier": "PortfolioStudio/PortfolioList/DropdownMenu/DropdownItem(Rename)",
					label: "Rename",
				},
				validateACLPermissions(user.id, portfolio.richAcl?.acl ?? [], roleByArea.portfolio.EDITOR) && {
					icon: "share",
					onClickAsync: async () => {
						await aclSpawn(portfolio.name, portfolio.uuid, refetchPortfolioList);
					},
					"data-qualifier": "PortfolioStudio/PortfolioList/DropdownMenu/DropdownItem(Share)",
					label: "Share",
				},
			];
		},
		[user, refetchPortfolioList, tableMultiSelectCtx, push, aclSpawn],
	);

	const columns = useMemo(
		() =>
			visibleColumns
				? [
						{
							content: checkboxColumn.content,
							header: (props) => {
								const pageSelectionSize = sortedRows.filter((x) => tableMultiSelectCtx.selection.has(x.uuid!)).length;
								const isAllChecked =
									pageSelectionSize > 0 && sortedRows.every((x) => tableMultiSelectCtx.selection.has(x.uuid!));
								const isPartiallyChecked = pageSelectionSize > 0 && !isAllChecked;
								const onChange = () => {
									if (isPartiallyChecked || pageSelectionSize === tableMultiSelectCtx.alwaysSelected.size) {
										tableMultiSelectCtx.setSelection(
											tableMultiSelectCtx.selection.union(sortedRows.map((row) => row.uuid!)),
										);
									} else {
										tableMultiSelectCtx.setSelection(
											tableMultiSelectCtx.selection.subtract(sortedRows.map((row) => row.uuid!)),
										);
									}
								};
								// const disabled = sortedRows.length > filteredRows.length;
								return (
									<Row
										style={props.style}
										classList={props.classList}
										innerRef={props.innerRef}
										title={props.title}
										alignItems="center"
										justifyContent="center"
										onClick={(e) => {
											e.stopPropagation();
										}}
									>
										<Checkbox
											onClick={(e) => e.stopPropagation()}
											checked={isPartiallyChecked ? "indeterminate" : isAllChecked}
											// disabled={disabled}
											onChange={onChange}
										/>
									</Row>
								);
							},
							name: "checkbox",
						} as TableColumn<ListInvestmentsDTO, any>,
						...visibleColumns,
						actionsColumn({
							onSettingsClick: () =>
								spawnCustomizeColumnsDialog({
									columns: customizableColumns,
									onSubmitAsync: onSubmitColumnPreferences,
								}),
							triggerProps: {
								"data-qualifier": "PortfolioStudio/PortfolioList/DropdownMenu",
							},
							dropdownActions: getDropdownActions,
						}),
				  ]
				: null,
		[
			visibleColumns,
			checkboxColumn.content,
			getDropdownActions,
			sortedRows,
			tableMultiSelectCtx,
			customizableColumns,
			onSubmitColumnPreferences,
		],
	);

	const pinnedColumns = useMemo<
		Array<{
			name: string;
			side: "left" | "right";
		}>
	>(
		() => [
			{ name: FieldKey.Name, side: "left" },
			...(metricColumn ? [{ name: metricColumn, side: "left" as const }] : []),
			...(visibleColumns ? [{ name: "settings-action", side: "right" } as const] : []),
		],
		[metricColumn, visibleColumns],
	);

	if (isMetricResultLoading) {
		return <ProgressBar value="indeterminate" />;
	}

	if (!columns) {
		return <></>;
	}

	return (
		<BaseHScrollTable
			onRowClick={(row) => row.uuid && tableMultiSelectCtx.toggle(row.uuid)}
			onRowContextMenu={(row, _index, e) => contextMenuHandler(e, getDropdownActions(row))}
			columns={columns}
			rows={sortedRows}
			orderBy={portfolioListOrderByName} // types are not matching
			onOrderByChange={setPortfolioListOrderByName} // types are not matching
			rowClassList={(row, rowIndex) => ({
				...toClassListRecord(rowClassList(row, rowIndex)),
				PortfolioListTableRow: true,
			})}
			rowKey={(row, rowIndex) => `${row.uuid}-${rowIndex}`}
			rowStyle={({ uuid }) => {
				const selected = tableMultiSelectCtx.selection?.has(uuid!);
				return selected ? { backgroundColor: themeCSSVars.Table_highlightedRowBackgroundColor } : {};
			}}
			noDataText="No data available"
			pinnedColumns={pinnedColumns}
		/>
	);
}

function Chart(props: {
	chartRef?: ForwardedRef<HighchartsReactRefObject>;
	unit?: string;
	metricResultsById: Map<string, MetricResultEntry>;
	multiSelectCtx: MultiSelectCtx<string>;
	thresholdValue?: number;
	onThresholdChange?(newThreshold: number): void;
	title?: FieldKey;
	stats?: { min?: number; max?: number; avg?: number };
	chartHeight?: MetricComparisonChartProps["chartHeight"];
}) {
	const [highlightProposal, setHighlightProposal] = useState(false);
	const selected = props.multiSelectCtx.selection.toSet();
	const setSelected = props.multiSelectCtx.setSelection;
	const { title, thresholdValue, onThresholdChange, stats } = props;
	const { t } = useTranslation();

	const metricComparisonData = useDebouncedMemo<MetricComparisonChartProps["data"]>(
		() =>
			props.metricResultsById
				.valueSeq()
				.toArray()
				.map((metric): MetricComparisonChartProps["data"][number] => ({
					id: metric.uuid!,
					name: metric.name ?? "Missing portfolio name",
					metrics: [metric.value ?? null, metric.enhancementValue ?? null],
				})),
		[props.metricResultsById],
	);

	return (
		<MetricComparisonChart
			actions={
				<Checkbox checked={highlightProposal} switchType="switch" onChange={setHighlightProposal}>
					Highlight proposal
				</Checkbox>
			}
			highlightSecondaryMetric={highlightProposal}
			chartTitle={title ? t(`METRIC_COMPARISON_CHART.${title}`) : "-"}
			mainMetricLabel="Current"
			secondaryMetricLabel="Proposal"
			unit={props.unit}
			min={stats?.min ?? 0}
			max={stats?.max ?? 0}
			avg={stats?.avg ?? 0}
			threshold={thresholdValue}
			thresholdDragStep={0.01}
			selectedIds={selected}
			onSelectionChange={setSelected}
			onThresholdChange={onThresholdChange}
			data={metricComparisonData.value}
			chartHeight={props.chartHeight}
			innerRef={props.chartRef}
		/>
	);
}

export default PortfolioList;
