import type { InvestmentListEntry, ReviewTicker, UserInstrumentDto } from "$root/api/api-gen";
import { EditorSelectableBasketsRequestEntityEnum } from "$root/api/api-gen";

export type InstrumentEditorEntity = EditorSelectableBasketsRequestEntityEnum;
export const InstrumentEditorEntity = EditorSelectableBasketsRequestEntityEnum;
export type PartialUserInstrument = UserInstrumentDto;

export type FrankensteinInstrument = Omit<ReviewTicker, "description" | "type"> & PartialUserInstrument;

export type InstrumentEditorEntry = FrankensteinInstrument & {
	rowId: string;
	investment?: InvestmentListEntry;
	someInstrumentsAreExcluded?: boolean;
	nOfInstrumentExcluded?: number;
	stableWeight?: number;
};

export type GroupedInstrumentEditorEntry = {
	rows: InstrumentEditorEntry[];
	assetClass: string;
	weight: number;
	differenceWeight?: number;
	enhancedWeight?: number;
	previousWeight?: number;
};

export enum RowTypeEnum {
	UPLOAD = "UPLOAD",
	ADD = "ADD",
	SELECT = "SELECT",
	CASH = "CASH",
}
export type rowType = keyof typeof RowTypeEnum;

export type MandatoryColumnEnum = "CHECKBOX" | "DELETE" | "WEIGHT";
