import type { UserInstrumentDto } from "$root/api/api-gen";
import {
	DefaultTagLabels,
	PortfolioStudioPreferencesApiFactory,
	type IndexTicker,
	type ReviewTicker,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { hasAccess } from "$root/components/AuthorizationGuard";
import { CustomLabels } from "$root/components/CustomLabels";
import { typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";
import { useLocaleFormatters } from "$root/localization/hooks";
import { PortfolioDetailsTabs } from "$root/pages/PortfolioDetails/portfolio-details-tabs";
import { commonBatchActions } from "$root/ui-lib/interactive-collections/common-batch-actions";
import { CommonItemActions } from "$root/ui-lib/interactive-collections/common-item-actions";
import { objectTextSearchMatchFns, stableColorGenerator } from "$root/utils";
import type { BatchActionsProps, TableColumn } from "@mdotm/mdotui/components";
import {
	ActionText,
	AutoSortHScrollTable,
	Badge,
	BatchActions,
	colorBySeverity,
	Icon,
	Row,
	TableDataCell,
	TableHeadCell,
	TextInput,
	useSelectableTableColumn,
} from "@mdotm/mdotui/components";
import { useSearchable } from "@mdotm/mdotui/headless";
import { toClassListRecord, useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSort, builtInSortFnFor, noop, typedObjectValues } from "@mdotm/mdotui/utils";
import type { Map } from "immutable";
import { Set } from "immutable";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { PartialUserInstrument } from "../instruments-editor/const";
import {
	hideNameColumnMetadata,
	SphereColumn,
	useInstrumentColumnPreference,
	useInstrumentsColumn,
} from "../instruments/hooks";
import { spawnInstrumentClassificationDialog } from "../instruments/instrument-classification/InstrumentClassificationDialog";
import { InstrumentNameFormCell } from "../instruments/table-cells/InstrumentNameFormCell";
import { TagBadge } from "../instruments/tags-v2/Tag";
import { useUserValue } from "../user";
import type { ReviewEntity } from "./helpers";

export type EditableProxiedInstrument = Omit<ReviewTicker, "description" | "type"> & UserInstrumentDto;
export type ProxiedInstrumentData = {
	proxies: Array<IndexTicker>;
};

export type EditProxiedInstrumentTableProps = {
	/**
	 * @uuid entity identifier
	 */
	uuid?: string;
	section?: ReviewEntity;
	disableEditing?: boolean;

	instrumentThatNeedProxy: ReviewTicker[];
	composition: ReviewTicker[];
	userInstrumentsMap: Immutable.Map<string, PartialUserInstrument>;

	deleted: Set<string>;
	onDeletedChange(newDeleted: Set<string>): void;

	proxied: Map<string, ProxiedInstrumentData>; // TODO: can we map the ticker of an unknown instrument to the ticker of the created proxy?
	onProxiedChange(newProxies: Map<string, ProxiedInstrumentData>): void;

	tagged: Map<string, string>;
	onTagChange(newProxies: Map<string, string>): void;

	scored: Map<string, number | null>;
	onScoreChange(newScore: Map<string, number | null>): void;
};

const useEditProxyColumns = ({
	deleted,
	proxied,
}: {
	deleted: Set<string>;
	proxied: Map<string, ProxiedInstrumentData>;
}) => {
	const user = useUserValue();
	const { t } = useTranslation();
	const { formatNumber } = useLocaleFormatters();
	const cellClassList = useCallback(
		({ ticker }: EditableProxiedInstrument) => ({
			"line-through": deleted.has(ticker ?? "-"),
		}),
		[deleted],
	);

	return useMemo(
		() =>
			({
				name: {
					header: "Name",
					content: (row, cellProps) => (
						<Row justifyContent="center" {...cellProps}>
							{row.proxyOverwriteType === "PORTFOLIO_MIXED" ? (
								<div className="flex flex-row items-center flex-nowrap w-full pr-4">
									<ActionText
										classList="inline-flex items-center gap-1"
										onClick={() =>
											window.open(
												typedUrlForRoute("PortfolioDetails", {
													portfolioUid: row.ticker ?? "",
													tab: PortfolioDetailsTabs.COMPOSITION,
												}),
												"_blank",
											)
										}
									>
										<span className="font-[weight:500] truncate items-center">{row.name ?? "-"}</span>
										<svg
											width="12"
											height="12"
											viewBox="0 0 12 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
											className="shrink-0"
										>
											<path
												d="M8 1.5H10.5V4"
												stroke="currentColor"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M7 5L10.5 1.5"
												stroke="currentColor"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M8.5 7.4375V9.625C8.5 10.1084 8.10844 10.5 7.625 10.5H2.375C1.89156 10.5 1.5 10.1084 1.5 9.625V4.375C1.5 3.89156 1.89156 3.5 2.375 3.5H4.5625"
												stroke="currentColor"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</ActionText>
								</div>
							) : (
								<div className="relative z-0 flex-1 w-full flex justify-start items-center">
									<div className="truncate grow min-w-0">
										<InstrumentNameFormCell value={row.name} />
									</div>
									{row.proxyOverwriteType === "LIVE" && (
										<div>
											<Badge backgroundColor="#A5AEC0" color="white" classList="scale-[0.85]">
												Custom
											</Badge>
										</div>
									)}

									{row.delisted && (
										<div>
											<Badge backgroundColor="#A5AEC0" color="white" classList="scale-[0.85]">
												Delisted
											</Badge>
										</div>
									)}
								</div>
							)}
						</Row>
					),
					minWidth: 344,
					cellClassList,
					name: "name",
				},
				status: {
					header: "Status",
					cellClassList,
					content: (row, cellProps) => (
						<TableDataCell {...cellProps}>
							{!row.needsCustomProxy || row.delisted === true ? (
								<span data-qualifier="EditProxiedInstrument/Table/Column(Status)/Success" />
							) : !proxied.has(row.ticker ?? "-") ? (
								<span data-qualifier="EditProxiedInstrument/Table/Column(Status)/Review">
									<Icon icon="Icon-full-alert" color={colorBySeverity.warning} size={20} />
								</span>
							) : (
								<span data-qualifier="EditProxiedInstrument/Table/Column(Status)/Success">
									<Icon icon="Icon-full-ok" color={colorBySeverity.success} size={20} />
								</span>
							)}
						</TableDataCell>
					),
					minWidth: 100,
					sortFn: builtInSortFnFor("needsCustomProxy"),
					name: "needsCustomProxy",
				},
				weight: {
					header: "weight",
					content: (row) => `${formatNumber(row.weight)}%`,
					sortFn: builtInSortFnFor("weight"),
					name: "weight",
					minWidth: 96,
				},
				tags(params: { tagsColorMap: { [key: string /**tagLabel */]: string /** color */ } }) {
					return {
						minWidth: 200,
						name: "tags",
						header: "Tags",
						content: ({ tagLabel }, cellProps) => {
							if (!tagLabel) {
								return null;
							}

							const tagColor = params.tagsColorMap[tagLabel];
							return (
								<Row {...cellProps} alignItems="center">
									<TagBadge color={tagColor} label={tagLabel} />
								</Row>
							);
						},
						footerCellClassList: "font-semibold",
					};
				},
				score(params: { uuid?: string }) {
					return {
						name: "score",
						minWidth: 100,
						header: params.uuid
							? (headerProps) => (
									<TableHeadCell {...headerProps}>
										<CustomLabels labelKey={`${params.uuid}_score1`} fallback={t("SCORE")} />
									</TableHeadCell>
							  )
							: t("SCORE"),
						content: ({ score }) => (score ? formatNumber(score) : ""),
						footerCellClassList: "font-semibold",
						hidden: !hasAccess(user, { requiredService: "CUSTOM_QUALITIES" }),
					};
				},
				linkedPortfolios: {
					name: "linked",
					minWidth: 114,
					header: "Linked portfolio",
					content: (row) => row.linkedPortfolios ?? 0,
					cellClassList: (row) => ({
						...toClassListRecord(cellClassList(row)),
						"tabular-nums": true,
					}),
					align: "end" as const,
				},
				proxy(params: {
					disabled: boolean;
					onProxiedChange(newProxies: Map<string, ProxiedInstrumentData>): void;
					proxyProvider(row: EditableProxiedInstrument): IndexTicker[];
				}) {
					return {
						name: "editRiskModel",
						width: 120,
						header: "Edit Risk model",
						align: "end" as const,
						cellClassList,
						content: (row, cellProps) => {
							return (
								<Row {...cellProps} justifyContent="end" gap={8} onClick={(e) => e.stopPropagation()}>
									{row.needsCustomProxy && !row.delisted && (
										<CommonItemActions.Edit
											disabled={params.disabled}
											onClick={() => {
												spawnInstrumentClassificationDialog({
													defaultInstrumentProxies: params.proxyProvider(row),
													onSubmitAsync: ({ indices, weights }) => {
														const proxyIndexes = indices.map(
															(props): IndexTicker => ({
																...props,
																weight: props.ticker ? weights.get(props.ticker)?.toNumber() : undefined,
															}),
														);
														params.onProxiedChange(
															proxied.set(row.ticker ?? "-", {
																proxies: proxyIndexes,
															} satisfies ProxiedInstrumentData),
														);
													},
												}).catch(noop);
											}}
											data-qualifier="EditProxiedInstrument/Table/Column(Action)/Edit"
										/>
									)}
								</Row>
							);
						},
					};
				},
				actions(params: { disabled: boolean; onDeletedChange(newDeleted: Set<string>): void }) {
					return {
						name: "actions",
						width: 96,
						header: "",
						align: "end" as const,
						cellClassList,
						content: (row, cellProps) => {
							return (
								<Row {...cellProps} justifyContent="end" gap={8} onClick={(e) => e.stopPropagation()}>
									{!row.delisted && (
										<CommonItemActions.DeleteRestore
											disabled={params.disabled}
											deleted={deleted}
											item={row.ticker ?? "-"}
											onDeletedChange={params.onDeletedChange}
											data-qualifier="EditProxiedInstrument/Table/Column(Action)/DeleteRestore"
										/>
									)}
								</Row>
							);
						},
					};
				},
			}) satisfies Record<
				string,
				TableColumn<EditableProxiedInstrument> | ((...args: any[]) => TableColumn<EditableProxiedInstrument>)
			>,
		[cellClassList, deleted, formatNumber, proxied, t, user],
	);
};

export function EditProxiedInstrumentTable({
	uuid,
	section,
	disableEditing,

	instrumentThatNeedProxy,
	composition,
	userInstrumentsMap,

	proxied,
	onProxiedChange,

	deleted,
	onDeletedChange,

	tagged,
	onTagChange,

	scored,
	onScoreChange,
}: EditProxiedInstrumentTableProps): JSX.Element {
	const { t } = useTranslation();

	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);
	const { changeTableLayout, columnsMetadata } = useInstrumentColumnPreference({
		async applyColumnPreferenceApi(userInstrumentsColumnPreferences) {
			await portfolioStudioPreferencesApi.setUserInstrumentsColumnPreferences({
				userInstrumentsColumnPreferences,
			});
		},
		mapColumnMetadataFn(column) {
			if (column.preferenceType?.classificationUuid === SphereColumn.riskModel) {
				return {
					...column,
					hidden: true,
				};
			}

			return hideNameColumnMetadata(column);
		},
	});

	const allInstruments = useMemo<EditableProxiedInstrument[]>(
		() =>
			composition
				.map((instrument) => {
					const { description: _description, type: _type, ...instrumentParams } = instrument;
					const userInstrument = userInstrumentsMap.get(instrument.ticker!) ?? {};
					return {
						...instrumentParams,
						...userInstrument,
						name: userInstrument.name ?? instrumentParams.instrument,
						alias: userInstrument.alias ?? instrument.identifier,
					};
				})
				.sort((a, b) => {
					const instA = (a.needsCustomProxy ? 1 : 0) + (a.delisted ? 1 : 0);
					const instB = (b.needsCustomProxy ? 1 : 0) + (b.delisted ? 1 : 0);
					return builtInSort(instA, instB, "desc");
				}),
		[composition, userInstrumentsMap],
	);

	const tagsColorMap = useMemo(() => {
		const defaultLabels: string[] = typedObjectValues(DefaultTagLabels);
		const availableLabels = allInstruments.flatMap((x) => (x.tagLabel ? [x.tagLabel] : []));
		return Array.from(Set(defaultLabels.concat(availableLabels))).reduce<{ [key: string]: string }>((acc, tagLabel) => {
			acc[tagLabel] = stableColorGenerator(tagLabel);
			return acc;
		}, {});
	}, [allInstruments]);

	const someInstrumentsHasLinkedPortfolios = useMemo(
		() =>
			allInstruments.some(
				(row) => row.linkedPortfolios !== undefined && row.linkedPortfolios !== null && row.linkedPortfolios > 0,
			),
		[allInstruments],
	);

	const editProxyColumns = useEditProxyColumns({ deleted, proxied });
	const { customizableColumns, tools, name } = useInstrumentsColumn({
		allColumns: columnsMetadata,
		mode: "VIEW",
		changeTableLayout,
	});

	const columnsWithoutRiskModel = useMemo(
		() =>
			customizableColumns.flatMap((column) => {
				if (column.name === name.name) {
					return [];
				}

				if (column.name === SphereColumn.riskModel) {
					return [];
				}

				return [column];
			}),
		[customizableColumns, name.name],
	);

	const { filtered, query, setQuery } = useSearchable({
		collection: allInstruments,
		matchFn: objectTextSearchMatchFns.keyword,
	});

	const {
		column: checkboxColumn,
		multiSelectCtx,
		rowClassList,
		toggle,
	} = useSelectableTableColumn({
		selectBy: (row) => row.ticker ?? "",
		rows: allInstruments,
		filteredRows: filtered,
		mode: "checkbox",
		selectableRowIds: instrumentThatNeedProxy.map((row) => row.ticker ?? ""),
	});

	const refs = useUnsafeUpdatedRef({
		onDeletedChange,
		onProxiedChange,
		onTagChange,
		onScoreChange,
		tagged,
		proxied,
		deleted,
		scored,
	});

	const editRiskModelColumns = useMemo(
		() =>
			editProxyColumns.proxy({
				disabled: disableEditing ?? false,
				onProxiedChange(x) {
					refs.current.onProxiedChange(x);
				},
				proxyProvider(row) {
					return proxied.get(row.ticker ?? "-")?.proxies ?? row.proxies ?? [];
				},
			}),
		[disableEditing, editProxyColumns, proxied, refs],
	);

	const actions = useMemo(
		() => ({
			...editProxyColumns.actions({
				disabled: disableEditing ?? false,
				onDeletedChange(x) {
					refs.current.onDeletedChange(x);
				},
			}),
			header: tools.header,
		}),
		[disableEditing, editProxyColumns, refs, tools.header],
	);

	const nameColumn = useMemo(
		() => ({
			...editProxyColumns.name,
			...name,
			content: editProxyColumns.name.content,
			minWidth: editProxyColumns.name.minWidth,
		}),
		[name, editProxyColumns.name],
	);

	const columns = useMemo<TableColumn<EditableProxiedInstrument>[]>(() => {
		if (section === "universe") {
			return [
				checkboxColumn,
				nameColumn,
				editProxyColumns.status,
				{ ...editProxyColumns.linkedPortfolios, hidden: !someInstrumentsHasLinkedPortfolios },
				...columnsWithoutRiskModel,
				editProxyColumns.tags({ tagsColorMap }),
				editProxyColumns.score({ uuid }),
				editRiskModelColumns,
				actions,
			];
		}

		return [
			checkboxColumn,
			nameColumn,
			editProxyColumns.status,
			{ ...editProxyColumns.linkedPortfolios, hidden: !someInstrumentsHasLinkedPortfolios },
			...columnsWithoutRiskModel,
			editProxyColumns.weight,
			editRiskModelColumns,
			actions,
		];
	}, [
		actions,
		checkboxColumn,
		columnsWithoutRiskModel,
		editProxyColumns,
		editRiskModelColumns,
		nameColumn,
		section,
		someInstrumentsHasLinkedPortfolios,
		tagsColorMap,
		uuid,
	]);

	const BatchActionsMap: Record<"edit" | "delete", BatchActionsProps["actions"][number]> = {
		delete: commonBatchActions.deleteRestore({
			t,
			deleted,
			selection: multiSelectCtx.selection,
			onDeletedChange,
		}),
		edit: {
			label: "Edit",
			icon: "Edit",
			onClick: () => {
				spawnInstrumentClassificationDialog({
					onSubmitAsync: ({ indices, weights }) => {
						const proxyIndexes = indices.map(
							(props): IndexTicker => ({
								...props,
								weight: props.ticker ? weights.get(props.ticker)?.toNumber() : undefined,
							}),
						);

						onProxiedChange(
							proxied.merge(
								instrumentThatNeedProxy
									.filter(({ ticker }) => multiSelectCtx.selection.has(ticker ?? "-"))
									.map(({ ticker }) => [
										ticker ?? "-",
										{
											proxies: proxyIndexes,
										} satisfies ProxiedInstrumentData,
									]),
							),
						);
					},
				}).catch(noop);
			},
		},
	};

	return (
		<>
			<div className="pb-4">
				<div className="flex justify-between">
					<div className="grow max-w-[500px]">
						<TextInput
							leftContent={<Icon icon="Search" />}
							value={query}
							onChangeText={setQuery}
							placeholder="Filter by instrument name, ISIN, asset class or micro asset class"
						/>
					</div>
				</div>
			</div>
			<BatchActions
				classList="pb-4 h-[58px] shrink-0"
				total={allInstruments.length}
				selected={multiSelectCtx.selection.size}
				data-qualifier="EditProxiedInstrument/Action/DeleteRestore"
				actions={disableEditing ? [] : [BatchActionsMap["edit"], BatchActionsMap["delete"]]}
			/>
			<AutoSortHScrollTable
				palette="uniform"
				rows={filtered}
				columns={columns}
				pinnedColumns={[
					{
						name: nameColumn.name,
						side: "left",
					},
					{
						name: editRiskModelColumns.name,
						side: "right",
					},
					{
						name: actions.name,
						side: "right",
					},
				]}
				noDataText="No instrument found"
				rowClassList={(row, rowIndex) => ({
					...toClassListRecord(rowClassList(row, rowIndex)),
					EditProxiedInstrumentRow: true,
				})}
				rowStyle={({ ticker, needsCustomProxy, delisted }) => ({
					backgroundColor: deleted.has(ticker ?? "-")
						? themeCSSVars.Table_highlightedRowBackgroundColor
						: needsCustomProxy && !proxied.has(ticker ?? "-") && !deleted.has(ticker ?? "-") && delisted === false
						  ? themeCSSVars.global_palette_warning_100
						  : undefined,
				})}
				onRowClick={(row) => toggle(row.ticker ?? "")}
			/>
		</>
	);
}
