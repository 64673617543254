/* eslint-disable no-loop-func */
import { For, ForEach } from "@mdotm/mdotui/react-extensions";
import { useTick } from "@mdotm/mdotui/react-extensions";
import { Map } from "immutable";
import type { ReactNode } from "react";
import { useEffect, useRef, useState } from "react";
// import Footer from "../components/pdf/Footer";
import type { PageHeaderFooterComponent } from "../components/pdf/Header";
import Header from "../components/pdf/Header";
import type { CustomReportDataUnion } from "./hooks/useExtractReports";
import { templateMaps } from "./templates";
import { discriminateSingleHeadingPage, mmToPx } from "./utils";
import { sleep } from "@mdotm/mdotui/utils";
import { firstPageHeaderHeight, otherPagesHeaderHeight, pageFooterHeight, paperPlaneId } from "./shared";
import { CircularProgressBar } from "@mdotm/mdotui/components";
import FooterPoweredBySphere from "../components/pdf/FooterPoweredBySphere";

export type PrintableProps<TCommon extends object, TSplittable> = TCommon & {
	list: Array<TSplittable>;
	firstRender: boolean;
	availableSpace: number;
};

export type PrintableComponent<TCommon extends object, TSplittable> = (
	props: PrintableProps<TCommon, TSplittable>,
) => ReactNode;

export type ComponentAndPropsPairPartial<TCommon extends object, TSplittable> = {
	commonProps: TCommon;
	splittableProps: Array<TSplittable>;
	startsFromBlankPage?: boolean;
	hideHeader?: boolean;
	hideFooter?: boolean;
};

export type ComponentAndPropsPair<TCommon extends object, TSplittable> = ComponentAndPropsPairPartial<
	TCommon,
	TSplittable
> & {
	component: (props: PrintableProps<TCommon, TSplittable>) => ReactNode;
};

export type RendererProps = {
	onFinish?(): void;
	pageHeader: PageHeaderFooterComponent;
	pageFooter: PageHeaderFooterComponent;
	firstPageHeaderHeight: number;
	otherPagesHeaderHeight: number;
	pageFooterHeight: number;
	pageHeight: number;
	pageWidth: number;
	componentAndPropsList: Array<Promise<Array<ComponentAndPropsPair<any, any>>>>;
};

export function Renderer({
	pageHeader,
	pageFooter,
	firstPageHeaderHeight,
	otherPagesHeaderHeight,
	pageFooterHeight,
	pageHeight,
	pageWidth,
	componentAndPropsList: componentAndPropsListPromises,
	onFinish,
}: RendererProps): JSX.Element {
	const latestComponentsAndPropListByPageRef =
		useRef(
			Map<
				number,
				Array<{
					componentData: ComponentAndPropsPair<any, any>;
					meta: {
						firstRender: boolean;
						initialPageIndex: number;
						initialSplitPropsIndex: number;
						availableSpace: number;
					};
				}>
			>(),
		);
	const pagesRef = useRef<Map<number, HTMLDivElement>>(Map());
	const firstRunRef = useRef(true);
	const [totalPages, setTotalPages] = useState(1);
	const [error, setError] = useState(null);

	const tick = useTick();
	useEffect(() => {
		(async () => {
			const componentAndPropsList: ComponentAndPropsPair<any, any>[] | undefined =
				await componentAndPropsListPromises.shift();
			if (!componentAndPropsList) {
				return;
			}

			if (!firstRunRef.current) {
				return;
			}
			firstRunRef.current = false;

			let currentPageIndex = 0;

			type RendererStateMap = {
				appendNewComp: object;
				appendSplitComp: object;
				appendProps: {
					required: boolean;
				};
			};
			type RenderState = { [K in keyof RendererStateMap]: RendererStateMap[K] & { type: K } }[keyof RendererStateMap];

			let snapshot = {
				splittablePropsIndex: 0,
				componentAndPropsListIndex: 0,
				componentsAndPropListByPage: Map() as typeof latestComponentsAndPropListByPageRef.current,
				renderState: { type: "appendNewComp" } satisfies RenderState as RenderState,
			};
			let snapshotMinus2: typeof snapshot | undefined;

			let i = 0;
			// eslint-disable-next-line no-constant-condition
			loop: while (true) {
				i++;
				await sleep(50);
				// TODO: enough?
				if (i > 5000) {
					throw new Error("there seems to be a problem with the PDF generator");
				}

				const current = { ...snapshot };

				/* data-providers could return empty arrays */
				while (
					current.componentAndPropsListIndex >= componentAndPropsList.length &&
					componentAndPropsListPromises.length > 0
				) {
					const moreComponentAndProps = await componentAndPropsListPromises.shift();
					if (moreComponentAndProps) {
						componentAndPropsList.push(...moreComponentAndProps);
					}
				}

				let hasChanges = true;

				switch (current.renderState.type) {
					case "appendNewComp": {
						if (current.componentAndPropsListIndex >= componentAndPropsList.length) {
							break loop;
						}

						if (componentAndPropsList[current.componentAndPropsListIndex].startsFromBlankPage) {
							currentPageIndex++;
							setTotalPages(currentPageIndex + 1);
							await tick();
						}

						const currentPage = pagesRef.current.get(currentPageIndex)!;

						const latestComponentsAndProps = current.componentsAndPropListByPage.get(currentPageIndex) ?? [];
						current.componentsAndPropListByPage = current.componentsAndPropListByPage.set(currentPageIndex, [
							...latestComponentsAndProps,
							{
								componentData: {
									...componentAndPropsList[current.componentAndPropsListIndex],
									splittableProps: [],
								},
								meta: {
									firstRender: true,
									initialPageIndex: currentPageIndex,
									initialSplitPropsIndex: 0,
									availableSpace: pageHeight - currentPage.offsetHeight,
								},
							},
						]);
						current.renderState = { type: "appendProps", required: true };
						break;
					}
					case "appendSplitComp": {
						const currentPage = pagesRef.current.get(currentPageIndex)!;

						const latestComponentsAndProps = current.componentsAndPropListByPage.get(currentPageIndex - 1)!;
						const latestComponent = latestComponentsAndProps.at(-1)!;
						current.componentsAndPropListByPage = current.componentsAndPropListByPage.set(currentPageIndex, [
							{
								componentData: {
									...componentAndPropsList[current.componentAndPropsListIndex],
									splittableProps: [],
								},
								meta: {
									firstRender: false,
									initialPageIndex: currentPageIndex,
									initialSplitPropsIndex:
										latestComponent.meta.initialSplitPropsIndex + latestComponent.componentData.splittableProps.length,
									availableSpace: pageHeight - currentPage.offsetHeight,
								},
							},
						]);
						current.renderState = { type: "appendProps", required: true };
						break;
					}
					case "appendProps": {
						if (
							current.splittablePropsIndex >=
							componentAndPropsList[current.componentAndPropsListIndex].splittableProps.length
						) {
							current.splittablePropsIndex = 0;
							current.componentAndPropsListIndex++;
							current.renderState = { type: "appendNewComp" };
							hasChanges = false;
							break;
						}

						const latestComponentsAndProps = current.componentsAndPropListByPage.get(currentPageIndex)!;
						const latestComponent = latestComponentsAndProps.at(-1)!;
						// Add new props to current component
						current.componentsAndPropListByPage = current.componentsAndPropListByPage.set(currentPageIndex, [
							...latestComponentsAndProps.slice(0, -1),
							{
								componentData: {
									commonProps: componentAndPropsList[current.componentAndPropsListIndex].commonProps,
									component: componentAndPropsList[current.componentAndPropsListIndex].component,
									splittableProps: componentAndPropsList[current.componentAndPropsListIndex].splittableProps.slice(
										latestComponent.meta.initialSplitPropsIndex,
										current.splittablePropsIndex + 1,
									),
								},
								meta: {
									...latestComponent.meta,
								},
							},
						]);
						current.splittablePropsIndex++;
						break;
					}
				}

				if (hasChanges) {
					latestComponentsAndPropListByPageRef.current = current.componentsAndPropListByPage;
					await tick();
				}

				const currentPage = pagesRef.current.get(currentPageIndex)!;
				if (hasChanges && (currentPage.offsetHeight > pageHeight || currentPage.offsetWidth > pageWidth)) {
					if (
						// we tried to render just one component in a blank page
						((snapshot.renderState.type === "appendNewComp" || snapshot.renderState.type === "appendSplitComp") &&
							!snapshot.componentsAndPropListByPage.get(currentPageIndex)?.length) ||
						// or we tried to append the first prop to a component in a page with nothing else other than itself
						(snapshot.renderState.type === "appendProps" &&
							componentAndPropsList[snapshot.componentAndPropsListIndex].splittableProps.length > 0 &&
							snapshot.componentsAndPropListByPage.get(currentPageIndex)!.length === 1 &&
							snapshot.componentsAndPropListByPage.get(currentPageIndex)![0].componentData.splittableProps.length === 0)
					) {
						throw new Error(`component doesn't fit an empty page! currentPageIndex: ${currentPageIndex}`);
					}

					if (snapshot.renderState.type === "appendProps") {
						if (
							componentAndPropsList[snapshot.componentAndPropsListIndex].splittableProps.length > 0 &&
							snapshot.componentsAndPropListByPage.get(currentPageIndex)!.at(-1)!.componentData.splittableProps
								.length === 0
						) {
							if (!snapshotMinus2) {
								throw new Error("unknown state");
							}
							if (snapshotMinus2.renderState.type !== "appendNewComp") {
								throw new Error("snapshotMinus2 has an incoherent state");
							}
							snapshot = snapshotMinus2;
						} else {
							snapshot.renderState = { type: "appendSplitComp" };
						}
					}

					currentPageIndex++;
					setTotalPages(() => currentPageIndex + 1);
					latestComponentsAndPropListByPageRef.current = snapshot.componentsAndPropListByPage;
					await tick();
				} else {
					// snapshotMinus2 = deepClone({ proto: true })(snapshot);
					// snapshot = deepClone({ proto: true })(current);
					// snapshotMinus2 = cloneDeep(snapshot);
					// snapshot = cloneDeep(current);

					snapshotMinus2 = { ...snapshot };
					snapshot = { ...current };
				}
			}
		})()
			.catch((err) => {
				console.error(err);
				setError(err);
			})
			.finally(onFinish);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (error) {
			throw error;
		}
	}, [error]);

	return (
		<For times={totalPages}>
			{({ index: pageIndex }) => (
				<div data-size="A4portrait">
					<div className="relative" style={{ height: pageHeight }}>
						<div
							className="overflow-visible"
							ref={(el) => {
								if (el) {
									pagesRef.current = pagesRef.current.set(pageIndex, el);
								}
							}}
						>
							{!latestComponentsAndPropListByPageRef.current.get(pageIndex)?.at(0)?.componentData.hideHeader && (
								<div
									className="overflow-hidden"
									style={{
										minHeight: pageIndex === 1 ? firstPageHeaderHeight : otherPagesHeaderHeight,
										height: pageIndex === 1 ? firstPageHeaderHeight : otherPagesHeaderHeight,
										maxHeight: pageIndex === 1 ? firstPageHeaderHeight : otherPagesHeaderHeight,
									}}
								>
									{pageHeader({ pageIndex, totalPages })}
								</div>
							)}
							<ForEach collection={latestComponentsAndPropListByPageRef.current.get(pageIndex) ?? []}>
								{({
									item: {
										componentData: { component, commonProps, splittableProps },
										meta: { firstRender, availableSpace },
									},
								}) => component({ ...commonProps, list: splittableProps, firstRender, availableSpace })}
							</ForEach>
							{/* Footer placeholder that "consumes" the available space */}
							{!latestComponentsAndPropListByPageRef.current.get(pageIndex)?.at(0)?.componentData.hideFooter && (
								<div
									className="overflow-hidden"
									style={{
										minHeight: pageFooterHeight,
										height: pageFooterHeight,
										maxHeight: pageFooterHeight,
									}}
								/>
							)}
						</div>
						{!latestComponentsAndPropListByPageRef.current.get(pageIndex)?.at(0)?.componentData.hideFooter && (
							<div
								className="overflow-hidden absolute inset-x-0 bottom-0"
								style={{
									minHeight: pageFooterHeight,
									height: pageFooterHeight,
									maxHeight: pageFooterHeight,
								}}
							>
								{pageFooter({ pageIndex, totalPages })}
							</div>
						)}
					</div>
				</div>
			)}
		</For>
	);
}

export default function MainBlock(props: CustomReportDataUnion & { layout: "portrait" | "landscape" }): JSX.Element {
	const [isReady, setIsReady] = useState(false);
	const { componentAndPropsList } = templateMaps(props);
	const { subtitle, title } = discriminateSingleHeadingPage(props);
	return (
		<>
			{isReady ? (
				<div
					key="ready"
					style={{ display: "none" }}
					data-role="start-printing"
					data-qualifier="pdfReport/promiseId"
					id={paperPlaneId}
				/>
			) : (
				<CircularProgressBar
					key="loading"
					value="indeterminate"
					classList="fixed top-2 right-2 z-50"
					outerDiameter={36}
				/>
			)}
			<Renderer
				pageHeader={(forward) => <Header {...forward} title={title} subtitle={subtitle} />}
				pageFooter={() => (
					<FooterPoweredBySphere
					// {...forward}
					// customLogo={props.payload.report.footer?.logo}
					// customDescription={props.payload.report.footer?.description}
					/>
				)}
				firstPageHeaderHeight={firstPageHeaderHeight}
				otherPagesHeaderHeight={otherPagesHeaderHeight}
				pageFooterHeight={pageFooterHeight}
				pageHeight={mmToPx(297)}
				pageWidth={mmToPx(210)}
				componentAndPropsList={componentAndPropsList.map((x) => Promise.resolve([x]))}
				onFinish={() => setIsReady(true)}
			/>
		</>
	);
}
