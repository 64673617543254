import type { MaybeAsync } from "$root/utils";
import type { DropdownMenuActionButtonProps, StylableProps } from "@mdotm/mdotui/components";
import { CircularProgressBar, ClickableDiv, DropdownMenu, Icon, Row } from "@mdotm/mdotui/components";
import { useAsync, type MaybePromise } from "@mdotm/mdotui/headless";
import { ForEach, overrideClassList, toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { unpromisify } from "@mdotm/mdotui/utils";
import { useEffect, useState, type ReactNode } from "react";
import { useHistory } from "react-router-dom";
import { MaybeLink } from "./MaybeLink";

export type NestedCrumbs<Id extends string = string> = Crumb<string> & { id?: Id; label: string };

export type Crumb<NestedId extends string = string> = { label?: string; children?: ReactNode } & {
	nested?: MaybeAsync<Array<NestedCrumbs<NestedId>>> | Array<NestedCrumbs<NestedId>>;
	selectedNestedId?: NestedId;
	disabled?: boolean;
	icon?: DropdownMenuActionButtonProps["icon"];
} & (
		| {
				href?: string;
				onClick?: undefined;
				onClickAsync?: undefined;
		  }
		| {
				href?: undefined;
				onClick(): void;
				onClickAsync?: undefined;
		  }
		| {
				href?: undefined;
				onClick?: undefined;
				onClickAsync(): MaybePromise<void>;
		  }
	);

function CrumbAsyncItem(
	props: { onClickAsync(): MaybePromise<void>; children: ReactNode; disabled?: boolean } & StylableProps,
) {
	const asyncCtx = useAsync({ asyncFn: props.onClickAsync });
	return (
		<ClickableDiv
			disabled={asyncCtx.loading || props.disabled}
			onClick={unpromisify(asyncCtx.run)}
			classList={overrideClassList(
				{
					"cursor-progress": asyncCtx.loading,
					"opacity-70": asyncCtx.loading,
				},
				props.classList,
			)}
		>
			{props.children}
		</ClickableDiv>
	);
}

function CrumbNode(props: Crumb & { isLast?: boolean }) {
	const { push } = useHistory();

	const {
		state,
		loading,
		result: nestedItems,
		run,
	} = useAsync({
		asyncFn: () => {
			if (typeof props.nested === "function") {
				return props.nested();
			} else {
				return props.nested;
			}
		},
	});

	useEffect(() => {
		if (state === "initial") {
			run().catch(console.warn);
		}
	}, [run, state]);

	const classList = {
		"flex items-center space-x-2 py-1 px-1.5": true,
		"text-[#4CB09C]": !props.isLast,
	};
	const [active, setActive] = useState(false);

	return (
		<Row
			alignItems="center"
			justifyContent="start"
			data-active={active}
			flexGrow={0}
			classList={`border border-transparent transition-colors hover:bg-[color:${themeCSSVars.palette_N50}] data-[active=true]:bg-[color:${themeCSSVars.palette_N50}] rounded-[4px] group/breadcrumb-node`}
		>
			{props.onClick ? (
				<ClickableDiv disabled={props.disabled} onClick={props.onClick} classList={classList}>
					{props.children ?? <span className="truncate max-w-[200px]">{props.label}</span>}
				</ClickableDiv>
			) : props.onClickAsync ? (
				<CrumbAsyncItem disabled={props.disabled} onClickAsync={props.onClickAsync} classList={classList}>
					{props.children ?? <span className="truncate max-w-[200px]">{props.label}</span>}
				</CrumbAsyncItem>
			) : (
				<MaybeLink href={props.disabled ? undefined : props.href} classList={classList}>
					{props.children ?? <span className="truncate max-w-[200px]">{props.label}</span>}
				</MaybeLink>
			)}

			{props.isLast && !loading && (!nestedItems || nestedItems.length === 0) ? null : !loading &&
			  (!nestedItems || nestedItems.length === 0) ? (
				<Icon
					size={14}
					color={themeCSSVars.palette_N300}
					icon="Down"
					classList={{
						"transition-transform": true,
						"-rotate-90": true,
					}}
				/>
			) : (
				<DropdownMenu
					enableSearch={(nestedItems?.length ?? 0) > 10}
					strategy="fixed"
					disabled={loading}
					trigger={({ innerRef, open, ...forward }) => {
						setActive(open);
						return (
							<button
								ref={innerRef}
								data-open={open}
								type="button"
								{...forward}
								className={toClassName({
									"transition-colors py-1.5 rounded-r-[4px] px-[4px] border-l border-l-transparent": true,
									[`group-hover/breadcrumb-node:border-l-[color:${themeCSSVars.palette_N50}]`]: !loading,
									[`data-[active=true]:border-l-[color:${themeCSSVars.palette_N50}]`]: !loading,
									[`bg-[color:${themeCSSVars.palette_N0}]`]: !loading,
								})}
							>
								{loading ? (
									<span className="flex items-center justify-center" style={{ width: 14, height: 14 }}>
										<CircularProgressBar value="indeterminate" outerDiameter={10} />
									</span>
								) : (
									<Icon
										size={14}
										color="#80848B"
										icon="Down"
										classList={{
											"transition-transform": true,
											"-rotate-90": !open,
										}}
									/>
								)}
							</button>
						);
					}}
					highlightActionId={props.selectedNestedId}
					actions={(nestedItems ?? []).map((x) =>
						x.onClick
							? {
									id: x.id,
									disabled: x.disabled,
									label: x.label,
									icon: x.icon,
									onClick: x.onClick,
							  }
							: x.onClickAsync
							  ? {
										id: x.id,
										disabled: x.disabled,
										label: x.label,
										icon: x.icon,
										onClickAsync: x.onClickAsync,
							    }
							  : {
										id: x.id,
										disabled: x.disabled || !x.href,
										label: x.label,
										icon: x.icon,
										onClick: () => {
											if (x.href) {
												push(x.href);
											}
										},
							    },
					)}
				/>
			)}
		</Row>
	);
}

export function Breadcrumbs({ crumbs }: { crumbs: Array<Crumb> }): JSX.Element {
	return (
		<Row alignItems="center" justifyContent="start" gap={4}>
			<ForEach collection={crumbs} keyProvider={(c, i) => `${c.label}-${c.href}-${i}`}>
				{({ item, index }) => <CrumbNode {...item} isLast={index === crumbs.length - 1} />}
			</ForEach>
		</Row>
	);
}
