import type { MaybeFn } from "@mdotm/mdotui/utils";
import { maybeCall } from "@mdotm/mdotui/utils";
import { Set } from "immutable";
import { useState } from "react";
import { create, type StoreApi } from "zustand";
import { getCellId } from "./spreadsheet-common";

export type DataByRow<Row> = {
	[rowId: string]: Row;
};

export type SpreadsheetCtx<Row> = {
	selectedCells: Set<string>;
	activeCell: string | null;
	select(rowId: string, column: keyof Row & string, mode: "set" | "append"): void;
	activate(rowId: string, column: keyof Row & string): void;
	deactivate(rowId: string, column: keyof Row & string): void;
	deactivateAny(): void;
	dataByRow: DataByRow<Row>;
	update<Column extends keyof Row & string>(
		rowId: string,
		column: Column,
		newData: Row[Column],
		opts?: {
			/** @default false */
			skipChangeEvents?: boolean;
		},
	): void;
};

export function useSpreadsheet<Row>(
	initialDataByCell: MaybeFn<DataByRow<Row>>,
	opts?: {
		onChange?(dataByRow: DataByRow<Row>): void;
		onChangeRow?(rowId: string, row: Row): void;
		onChangeCell?<K extends keyof Row & string>(rowId: string, column: K, cell: Row[K]): void;
	},
): StoreApi<SpreadsheetCtx<Row>> {
	return useState<StoreApi<SpreadsheetCtx<Row>>>(() =>
		create((set) => ({
			dataByRow: maybeCall(initialDataByCell),
			selectedCells: Set(),
			activeCell: null,
			select: (rowId, column, mode) =>
				set((cur) => ({
					...cur,
					activeCell: null,
					selectedCells: (mode === "set" ? cur.selectedCells.clear() : cur.selectedCells).add(getCellId(rowId, column)),
				})),
			activate: (rowId, column) =>
				set((cur) => ({
					...cur,
					activeCell: getCellId(rowId, column),
					selectedCells: cur.selectedCells.clear(),
				})),
			deactivate: (rowId, column) =>
				set((cur) => ({
					...cur,
					activeCell: cur.activeCell === getCellId(rowId, column) ? null : cur.activeCell,
				})),
			deactivateAny: () => set((cur) => ({ ...cur, activeCell: null })),
			update: (rowId, column, newCellData, updateOpts) =>
				set((cur) => {
					const newRowData = {
						...cur.dataByRow[rowId],
						[column]: newCellData,
					};
					const newDataByRow = {
						...cur.dataByRow,
						[rowId]: newRowData,
					};
					const newSpreadsheetCtx = {
						...cur,
						dataByRow: newDataByRow,
					};

					if (updateOpts?.skipChangeEvents) {
						return newSpreadsheetCtx;
					}
					opts?.onChangeCell?.(rowId, column, newCellData);
					opts?.onChangeRow?.(rowId, newRowData);
					opts?.onChange?.(newDataByRow);
					return newSpreadsheetCtx;
				}),
		})),
	)[0];
}
