export const qualifier = {
	instrumentClassificationDialog: {
		next: "InstrumentClassificationDialog/Button/Next",
		save: "InstrumentClassificationDialog/Button/Save",
		previous: "InstrumentClassificationDialog/Button/Previous",
		close: "InstrumentClassificationDialog/Button/Close",
		tabs: {
			selectIndices: "InstrumentClassificationDialog/Tab/SelectIndices",
			compositionWeights: "InstrumentClassificationDialog/Tab/CompositionWeights",
		},
		instrumentSelector: {
			search: "InstrumentClassificationDialog/instrumentSelector/Search",
		},
		instrumentWeightsTable: {
			columns: {
				delete: function generateDeleteName(name: string): string {
					return `InstrumentClassificationDialog/instrumentWeightsTable/Columns/Delete(${name})`;
				},
				input: function generateInputName(name: string): string {
					return `InstrumentClassificationDialog/instrumentWeightsTable/Columns/Input(${name})`;
				},
			},
			normalise: "InstrumentClassificationDialog/instrumentWeightsTable/Normalise",
		},
	},
	instrumentsFiltersPanel: {
		clearAll: "InstrumentsFiltersPanel/Button/ClearAll",
		search: "InstrumentsFiltersPanel/Search",
		filters: {
			add: "InstrumentsFiltersPanel/Filter/Add",
			selectByLevel: function generateLevel(step: string, index: number): string {
				return `InstrumentsFiltersPanel/Filter/Select/Level(${step},${index})`;
			},
		},
		basket: {
			delete: "InstrumentsFiltersPanel/Basket/Delete",
			select: "InstrumentsFiltersPanel/Basket/Select",
			options: "InstrumentsFiltersPanel/Basket/Options",
		},
	},
	instrumentsCustomisation: {
		columnTools: {
			button: "InstrumentCustomisation/Header/Tools",
			actions: function generateToolName(name: string): string {
				return `InstrumentsFiltersPanel/Header/Tools/${name}`;
			},
		},
		descriptionCell: {
			requestInstrumentDescription: "InstrumentCustomisation/DescriptionCell/RequestInstrumentDescription",
			enabledEditMode: "InstrumentCustomisation/DescriptionCell/EnableEditMode",
			cell: "InstrumentCustomisation/DescriptionCell/Cell",
		},
		nameCell: {
			enabledEditMode: "InstrumentCustomisation/NameCell/EnableEditMode",
			cell: "InstrumentCustomisation/NameCell/Cell",
		},
		riskModelCell: {
			editButton: "InstrumentCustomisation/RiskModelCell/EditButton",
			cell: "InstrumentCustomisation/RiskModelCell/Cell",
		},
	},
	reviewInstruments: {
		save: "ReviewInstruments/Save",
		cancel: "ReviewInstruments/Cancel",
		delete: "ReviewInstruments/Delete",
	},
	portfolioDetails: {
		tab: function generateTabName(name: string): string {
			return `PortfolioDetails/Tab/${name}`;
		},
	},
	explainabilitySettings: {
		selectLanguage: "ExplainabilityOptions/Select/List",
		save: "ExplainabilityOptions/Save",
		cancel: "ExplainabilityOptions/Cancel",
	},
	reportCustomisation: {
		studio: {
			newTemplate: "ReportCustomisation/Studio/NewTemplate",
			bulkDelete: "ReportCustomisation/Studio/BatchAction(Delete)",
			search: "ReportCustomisation/Studio/Search",
			duplicate: "ReportCustomisation/Studio/Duplicate",
			rename: "ReportCustomisation/Studio/Rename",
			delete: "ReportCustomisation/Studio/Delete",
			moreActions: "ReportCustomisation/Studio/MoreActions",
			toggleVisibility: "ReportCustomisation/Studio/ToggleVisibility",
			templateLink: function link(name?: string): string {
				return `ReportCustomisation/Studio/templateLink/${name ?? ""})`;
			},
		},
		builder: {
			cancel: "ReportCustomisation/Builder/Cancel",
			save: "ReportCustomisation/Builder/Save",
			saveAs: "ReportCustomisation/Builder/SaveAs",
			widgetAdderOption: function widgetAdder(name: string): string {
				return `ReportCustomisation/Builder/Widget/Adder/${name}`;
			},
			widgetItem: {
				edit: function widgetDelete(name: string): string {
					return `ReportCustomisation/Builder/Widget/Item/Edit/${name}`;
				},
				delete: function widgetDelete(name: string): string {
					return `ReportCustomisation/Builder/Widget/Item/Delete/${name}`;
				},
			},
			reloadPreview: "ReportCustomisation/Builder/ReloadPreview",
			previewContainer: "ReportCustomisation/Builder/PreviewContainer",
			selectPortfolio: "ReportCustomisation/Builder/SelectPortfolio",
			downloadTemplate: "ReportCustomisation/Builder/DownloadTemplate",
			widgetDetails: {
				cancel: "ReportCustomisation/Builder/WidgetDetails/Cancel",
				save: "ReportCustomisation/Builder/WidgetDetails/Save",
			},
		},
		modules: {
			summary: {
				metadata: function metadata(name: string): string {
					return `ReportCustomisation/Module/Summary/Metadata/${name}`;
				},
			},
			disclaimer: {
				enableCustomTitle: "ReportCustomisation/Module/Disclaimer/EnableCustomTitle",
				title: "ReportCustomisation/Module/Disclaimer/Title",
				description: "ReportCustomisation/Module/Disclaimer/Description",
				enableContacts: "ReportCustomisation/Module/Disclaimer/EnableContacts",
				contacts: "ReportCustomisation/Module/Disclaimer/Contacts",
			},
			cover: {
				title: "ReportCustomisation/Module/Cover/Title",
			},
			portfolioMetrics: {
				toggleMacroMetric: function toggleMetric(name: string): string {
					return `ReportCustomisation/Module/PortfolioMetrics/ToggleMacroMetric/${name}`;
				},
				metric: function metric(name: string): string {
					return `ReportCustomisation/Module/PortfolioMetrics/Metric/${name}`;
				},
				showBenchmark: "ReportCustomisation/Module/PortfolioMetrics/Metric/EnableBenchmark",
			},
			exAntePortfolioMetrics: {
				toggleMacroMetric: function toggleMetric(name: string): string {
					return `ReportCustomisation/Module/ExAntePortfolioMetrics/ToggleMacroMetric/${name}`;
				},
				metric: function metric(name: string): string {
					return `ReportCustomisation/Module/ExAntePortfolioMetrics/Metric/${name}`;
				},
				showBenchmark: "ReportCustomisation/Module/ExAntePortfolioMetrics/Metric/EnableBenchmark",
			},
			freeText: {
				description: "ReportCustomisation/Module/FreeText/Description",
			},
			commentary: {
				selectTemplate: "ReportCustomisation/Module/Commentary/SelectTemplate",
				enableTemplateLanguage: "ReportCustomisation/Module/Commentary/EnableTemplateLanguage",
				title: "ReportCustomisation/Module/Commentary/Title",
				enableTemplateNameAsTitle: "ReportCustomisation/Module/Commentary/EnableTemplateNameAsTitle",
			},
			performance: {
				metric: function metric(name: string): string {
					return `ReportCustomisation/Module/Performance/Metric/${name}`;
				},
			},
			composition: {
				column: function column(name: string): string {
					return `ReportCustomisation/Module/Composition/Column/${name}`;
				},
			},
			performanceAttribution: {
				horizion: function horizon(horizon: string): string {
					return `ReportCustomisation/Module/PerformanceAttribution/Horizion/${horizon}`;
				},
			},
			performanceAndVolatilityContribution: {
				toggleSignificantValueOnly:
					"ReportCustomisation/Module/PerformanceAndVolatilityContribution/ToggleSignificantValueOnly",
				views: function views(name: string): string {
					return `ReportCustomisation/Module/PerformanceAndVolatilityContribution/View/${name}`;
				},
			},
			exAnteContributionVolatility: {
				toggleSignificantValueOnly:
					"ReportCustomisation/Module/ExAnteContributionVolatility/ToggleSignificantValueOnly",
				views: function views(name: string): string {
					return `ReportCustomisation/Module/ExAnteContributionVolatility/View/${name}`;
				},
			},
			factorExposure: {
				factors: function factor(name: string): string {
					return `ReportCustomisation/Module/FactorExposure/Factor/${name}`;
				},
			},
			exposure: {
				view: function view(name: string): string {
					return `ReportCustomisation/Module/Exposure/View/${name}`;
				},
			},
		},
	},
	storyfolio: {
		studio: {
			newTemplate: "Storyfolio/Studio/NewTemplate",
			duplicate: "Storyfolio/Studio/Duplicate",
			rename: "Storyfolio/Studio/Rename",
			delete: "Storyfolio/Studio/Delete",
			bulkDelete: "Storyfolio/Studio/BatchAction(Delete)",
			search: "Storyfolio/Studio/Search",
			moreActions: "Storyfolio/Studio/MoreActions",
			toggleVisibility: "Storyfolio/Studio/ToggleVisibility",
		},
		details: {
			cancel: "Storyfolio/Details/Cancel",
			save: "Storyfolio/Details/Save",
			expandContext: "Storyfolio/Details/Style/ExpandContext",
			contextValue: "Storyfolio/Details/Style/Context/Content",
			selectLanguage: "Storyfolio/Details/Style/SelectLanguage",
			languageOption: function language(lng: string): string {
				return `Storyfolio/Details/Style/SelectLanguage/Language(${lng})`;
			},
			selectTone: "Storyfolio/Details/Style/SelectTone",
			toneOption: function tone(tn: string): string {
				return `Storyfolio/Details/Style/SelectTone/Tone(${tn})`;
			},
			selectModule: "Storyfolio/Details/Structure/SelectModule",
			moduleOption: function module(md: string): string {
				return `Storyfolio/Details/Structure/SelectModule/Module(${md})`;
			},
			contentValue: "Storyfolio/Details/Structure/Content",
			selectPortfolio: "Storyfolio/Details/Preview/SelectPortfolio",
			generatePreview: "Storyfolio/Details/Preview/Generate",
			generatedPreviewSuccessfully: "Storyfolio/Details/Preview/Generate/Success",
		},
		modules: {
			portfolioDynamics: {
				relevanceThreshold: "Storyfolio/Module/PortfolioDynamics/RelevanceThreshold",
			},
			portfolioComposition: {
				commentBenchmark: "Storyfolio/Module/PortfolioComposition/CommentBenchmark",
				commentAssetClassConstrainnts: "Storyfolio/Module/PortfolioComposition/CommentAssetClassConstraints",
				highlightMainInstrumentMacroAssetClass:
					"Storyfolio/Module/PortfolioComposition/HighlightMainInstrumentMacroAssetClass",
				relevanceThresholdForMicroAcCommented:
					"Storyfolio/Module/PortfolioComposition/RelevanceThresholdForMicroAcCommented",
			},
			instrumentInsights: {
				maxNumOfAddedInstruments: "Storyfolio/Module/InstumentInsights/MaxNumOfAddedInstruments",
				maxNumOfAddedInstrumentsThreshold: "Storyfolio/Module/InstumentInsights/MaxNumOfAddedInstrumentsThreshold",
				maxNumOfDeletedInstruments: "Storyfolio/Module/InstumentInsights/MaxNumOfDeletedInstruments",
				maxNumOfDeletedInstrumentsThreshold: "Storyfolio/Module/InstumentInsights/MaxNumOfDeletedInstrumentsThreshold",
				maxNumOfPositiveDeltaInstruments: "Storyfolio/Module/InstumentInsights/MaxNumOfPositiveDeltaInstruments",
				maxNumOfPositiveDeltaInstrumentsThreshold:
					"Storyfolio/Module/InstumentInsights/MaxNumOfPositiveDeltaInstrumentsThreshold",
				maxNumOfNegativeDeltaInstruments: "Storyfolio/Module/InstumentInsights/MaxNumOfNegativeDeltaInstruments",
				maxNumOfNegativeDeltaInstrumentsThreshold:
					"Storyfolio/Module/InstumentInsights/MaxNumOfNegativeDeltaInstrumentsThreshold",
				instrumentsReturns: "Storyfolio/Module/InstumentInsights/InstrumentsReturns",
			},
			portfolioMetrics: {
				exAnteVar: "Storyfolio/Module/PortfolioMetrics/Metric/ExAnteVar",
				exAnteAnnualizedVolatility: "Storyfolio/Module/PortfolioMetrics/Metric/ExAnteAnnualizedVolatility",
				exAnteDiversificationRatio: "Storyfolio/Module/PortfolioMetrics/Metric/ExAnteDiversificationRatio",
				exAnteVolatilityContribution: "Storyfolio/Module/PortfolioMetrics/Metric/ExAnteVolatilityContribution",
				riskBudgetOnVarVolatility: "Storyfolio/Module/PortfolioMetrics/Metric/RiskBudgetOnVarVolatility",
				riskBudgetTrackingError: "Storyfolio/Module/PortfolioMetrics/Metric/RiskBudgetTrackingError",
				riskFactors: "Storyfolio/Module/PortfolioMetrics/Metric/RiskFactors",
				trackingError: "Storyfolio/Module/PortfolioMetrics/Metric/TrackingError",
				varVolatilityRiskConstraint: "Storyfolio/Module/PortfolioMetrics/Metric/VarVolatilityRiskConstraint",
			},
			performanceAnalysis: {
				enabled: "Storyfolio/Module/PerformanceAnalysis/Analysis/enabled",
				moduleStyling: "Storyfolio/Module/PerformanceAnalysis/Analysis/moduleStyling",
				horizon: "Storyfolio/Module/PerformanceAnalysis/Analysis/horizon",
				showAttribution: "Storyfolio/Module/PerformanceAnalysis/Analysis/showAttribution",
				showContribution: "Storyfolio/Module/PerformanceAnalysis/Analysis/showContribution",
				showPositiveContributors: "Storyfolio/Module/PerformanceAnalysis/Analysis/showPositiveContributors",
				showNegativeContributors: "Storyfolio/Module/PerformanceAnalysis/Analysis/showNegativeContributors",
				showNumberOfPositiveContributors:
					"Storyfolio/Module/PerformanceAnalysis/Analysis/showNumberOfPositiveContributors",
				showNumberOfNegativeContributors:
					"Storyfolio/Module/PerformanceAnalysis/Analysis/showNumberOfNegativeContributors",
				purelyQualitative: "Storyfolio/Module/PerformanceAnalysis/Analysis/purelyQualitative",
			},
		},
		component: {
			sideHeaderContent: {
				submit: "Storyfolio/Module/SideHeaderContent/Submit",
				cancel: "Storyfolio/Module/SideHeaderContent/Cancel",
			},
			moduleStyling: {
				summarize: "Storyfolio/Module/ModuleStyling/Summarize",
				extended: "Storyfolio/Module/ModuleStyling/Extended",
				tableFormat: "Storyfolio/Module/ModuleStyling/TableFormat",
				customPrompt: "Storyfolio/Module/ModuleStyling/CustmoPrompt",
				content: "Storyfolio/Module/ModuleStyling/Content",
			},
		},
	},
	customReport: {
		customOutlookTable: function name(table: string): string {
			return `customReport/Outlook/Table(${table})`;
		},
	},
	lexical: {
		pillNode: {
			node: function node(n: string): string {
				return `Lexical/PillNode/Node(${n})`;
			},
		},
	},
	widgets: {
		portfolioCommentary: {
			name: "Widget/PortfolioCommentary",
			timeStamp: "Widget/PortfolioCommentary/TimeStamp",
			templateName: "Widget/PortfolioCommentary/TemplateName",
			generate: "Widget/PortfolioCommentary/GenerateComment",
			selectTemplate: "Widget/PortfolioCommentary/SelectTemplate",
			content: "Widget/PortfolioCommentary/Content",
		},
		portfolioPerformance: {
			name: "Widget/PortfolioPerformance",
		},
		portfolioMonitoring: {
			name: "Widget/PortfolioMonitoring",
		},
		portfolioPerformanceMetrics: {
			name: "Widget/PortfolioPerformanceMetrics",
		},
		portfolioExAnteMetric: {
			name: "Widget/PortfolioExAnteMetric",
		},
		portfolioExposure: {
			name: "Widget/PortfolioExposure",
		},
		portfolioAttribution: {
			name: "Widget/PortfolioAttribution",
		},
		portfolioContributionVolatility: {
			name: "Widget/PortfolioContributionVolatility",
		},
		portfolioExAnteContributionVolatility: {
			name: "Widget/PortfolioExAnteContributionVolatility",
		},
		portfolioFactorExposure: {
			name: "Widget/PortfolioFactorExposure",
		},
		portfolioExposureCompare: {
			name: "Widget/PortfolioExposureCompare",
		},
		portfolioHistory: {
			name: "Widget/PortfolioHistory",
		},
		assetClassOveriview: {
			name: "Widget/AssetClassOverview",
		},
		returnAnalysis: {
			name: "Widget/ReturnAnalysis",
		},
		factorExposure: {
			name: "Widget/FactorExposure",
		},
		marketCommentary: {
			name: "Widget/MarketCommentary",
		},
		assetClassOverview: {
			name: "Widget/AssetClassOverview",
		},
		outlookOverview: {
			name: "Widget/OutlookOverview",
		},
		portfolioListOverview: {
			name: "Widget/PortfolioListOverview",
		},
		positioning: {
			name: "Widget/Positioning",
		},
		forecast: {
			name: "Widget/Forecast",
		},
	},
	drawer: {
		profilePhoto: "appDrawer/profilePhoto",
		logout: "appDrawer/drawerItem(User)/subItem(Logout)",
	},
	component: {
		hierarchicalMultiSelect: {
			name: "Component/HierarchicalMultiSelect",
			selectList: "Component/HierarchicalMultiSelect/Select/List",
		},
		modalWithFiler: {
			search: "Component/ModalWithFilers/Search",
			submit: "Component/ModalWithFilers/Submit",
			cancel: "Component/ModalWithFilers/Cancel",
		},
		sidePanelHeader: {
			cancel: "Component/SidePanelHeader/Cancel",
			submit: "Component/SidePanelHeader/Submit",
			title: "Component/SidePanelHeader/Title",
		},
		input: {
			text: function generateInputName(name?: string): string {
				return name ? `Component/Input/Text/${name}` : "Component/Input/Text";
			},
			select: function generateSelectName(name?: string): string {
				return name ? `Component/Input/Select/${name}` : `Component/Input/Select`;
			},
			nullableInputOverlay: "Component/NullableInputOverlay",
		},
		radioGroupButton: {
			option: function generateRadioButtonName(name: string): string {
				return `Component/RadioGroup/Option/${name}`;
			},
		},
		button: function generateButtonName(name?: string): string {
			return name ? `Component/Button/${name}` : "Component/Button";
		},
		selectableCell: function generateSelectableCell(path: string): string {
			return `Component/SelectableCell/${path}`;
		},
		table: {
			header: function generateTableHeader(name: string): string {
				return `Component/Table/Header/${name}`;
			},
		},
		autocomplete: {
			trigger: function generateTriggerName(name: string): string {
				return `Component/Autocomplete/Trigger/${name}`;
			},
			list: function generateListWrapperName(name: string): string {
				return `Component/Autocomplete/List/${name}`;
			},
		},
	},
	outlook: {
		focus: {
			summary: "Outlook/Focus/Summary",
			positioning: "Outlook/Focus/Positioning",
			macroDynamics: "Outlook/Focus/MacroDynamics",
		},
	},
};
