import type { Currencies, UserInstrumentClassificationDto } from "$root/api/api-gen";
import type { MinimumDialogProps } from "$root/components/spawnable/type";
import { objectTextSearchMatchFns, qualifier } from "$root/utils";
import type { OrderBy, TableColumn } from "@mdotm/mdotui/components";
import {
	BaseTable,
	Button,
	Controller,
	DebouncedSearchInput,
	Dialog,
	DialogFooter,
	Radio,
	RadioGroup,
	sortRows,
	SubmitButton,
	Text,
	useSelectableTableColumn,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { useSearchable } from "@mdotm/mdotui/headless";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, spawn } from "@mdotm/mdotui/react-extensions";
import { builtInSort } from "@mdotm/mdotui/utils";
import { useEffect, useMemo, useState } from "react";
import { flushSync } from "react-dom";
import { useTranslation } from "react-i18next";
import { ClassificationOptionsTable } from "./spawn-tag-classifications";

const CurrencyConstraintType = {
	tag: "TAG",
	riskModel: "RISK_MODEL",
} as const;
type CurrencyConstraintType = (typeof CurrencyConstraintType)[keyof typeof CurrencyConstraintType];

type CurrencyConstraintDefault =
	| { type: Extract<CurrencyConstraintType, "TAG">; selectedOptions: string[] }
	| { type: Extract<CurrencyConstraintType, "RISK_MODEL">; selectedOptions: Currencies[] };

type CurrencyConstraintSubmitProps =
	| { type: "RISK_MODEL"; selectedOptions: Array<Currencies> }
	| ({ type: "TAG"; selectedOptions: Array<string> } & UserInstrumentClassificationDto);

type CurrencyConstraintDialogProps = {
	defaultValue?: CurrencyConstraintDefault;
	selectableUserClassifications: UserInstrumentClassificationDto[];
	selectableCurrencies: Array<Currencies>;
	onSubmit(props: CurrencyConstraintSubmitProps): void;
	optionsProvider(classificationUuid: string): MaybePromise<Array<string>>;
	disabledRowIds?: string[];
} & MinimumDialogProps;

function CurrencyConstraintDialog({
	show,
	onClose,
	onAnimationStateChange,
	defaultValue,
	onSubmit,
	optionsProvider,
	selectableCurrencies,
	selectableUserClassifications,
	disabledRowIds,
}: CurrencyConstraintDialogProps): JSX.Element {
	const { t } = useTranslation();

	const [currencyConstraint, setCurrencyConstraint] = useState<
		| { type: "RISK_MODEL"; selectedOptions: Array<Currencies> }
		| ({ type: "TAG"; selectedOptions: Array<string> } & UserInstrumentClassificationDto)
	>(
		defaultValue?.type === "RISK_MODEL"
			? {
					type: defaultValue.type,
					selectedOptions: defaultValue.selectedOptions,
			  }
			: defaultValue?.type === "TAG"
			  ? {
						type: defaultValue.type,
						selectedOptions: defaultValue.selectedOptions,
			    }
			  : { type: "RISK_MODEL", selectedOptions: [] },
	);

	const selectableCurrencyOptions = useMemo(
		() =>
			selectableUserClassifications.filter(
				(classification) => classification.tagType === "CURRENCY" && classification.fieldType === "TAG",
			),
		[selectableUserClassifications],
	);

	return (
		<Dialog
			size="xxlarge"
			show={show}
			onAnimationStateChange={onAnimationStateChange}
			onClose={onClose}
			header="Select"
			onSubmitAsync={() => onSubmit(currencyConstraint)}
			footer={
				<DialogFooter
					primaryAction={
						<SubmitButton palette="primary" data-qualifier={qualifier.component.button("Dialog/Submit")}>
							{t("BUTTON.DONE")}
						</SubmitButton>
					}
					neutralAction={
						<Button palette="tertiary" onClick={onClose} data-qualifier={qualifier.component.button("Dialog/Cancel")}>
							{t("BUTTON.CANCEL")}
						</Button>
					}
				/>
			}
		>
			<>
				<Text type="Body/M/Medium" classList="mb-1" as="p">
					Apply constraint on
				</Text>
				<RadioGroup
					onChange={(newAllocationType) => {
						if (newAllocationType === "TAG") {
							const firstAvailableCurrencyOption = selectableCurrencyOptions.at(0) ?? {};
							setCurrencyConstraint({ type: newAllocationType, selectedOptions: [], ...firstAvailableCurrencyOption });
							return;
						}
						setCurrencyConstraint({ type: newAllocationType, selectedOptions: [] });
					}}
					value={currencyConstraint.type}
					classList="mb-2"
				>
					<div className="flex flex-row flex-wrap gap-4">
						<Radio
							data-qualifier={`CurrencyConstraintDialog/ApplyConstraintOn/${CurrencyConstraintType.riskModel}`}
							value={CurrencyConstraintType.riskModel}
						>
							Risk Model
						</Radio>
						<Radio
							data-qualifier={`CurrencyConstraintDialog/ApplyConstraintOn/${CurrencyConstraintType.tag}`}
							value={CurrencyConstraintType.tag}
						>
							Currency Tags
						</Radio>
					</div>
				</RadioGroup>
				<Text type="Body/M/Book" classList="mb-4" as="p">
					{currencyConstraint.type === "RISK_MODEL"
						? "Choose to apply constraints using Sphere's Risk Model classifications."
						: "Choose to apply constraints using user-defined custom categories."}
				</Text>
				<div className="max-h-[400px] h-[400px] min-h-0 overflow-hidden flex flex-col">
					{currencyConstraint.type === "TAG" ? (
						<ClassificationOptionsTable
							selectedClassificationUuid={currencyConstraint.classificationUuid!}
							mode="radio"
							optionsProvider={optionsProvider}
							selectableUserClassifications={selectableCurrencyOptions}
							onChangeClassification={(newClassification) => {
								if (newClassification) {
									setCurrencyConstraint(() => ({
										...newClassification,
										type: "TAG",
										selectedOptions: [],
									}));
								}
							}}
							disabledRowIds={disabledRowIds}
							onToggleOption={(option) => {
								const optionIndex = currencyConstraint.selectedOptions.indexOf(option);
								if (optionIndex > -1) {
									setCurrencyConstraint((prev) => {
										const shallowClonedSelectedOptions = [...prev.selectedOptions];
										shallowClonedSelectedOptions.splice(optionIndex, 1);
										return { ...prev, type: "TAG", selectedOptions: shallowClonedSelectedOptions };
									});
									return;
								}

								setCurrencyConstraint((prev) => {
									if (prev.type === "TAG") {
										prev.selectedOptions.push(option);
									}

									return { ...prev, type: "TAG" };
								});
							}}
						/>
					) : currencyConstraint.type === "RISK_MODEL" ? (
						<CurrencyTable
							mode="radio"
							selectableCurrencies={selectableCurrencies}
							disabledRowIds={disabledRowIds}
							onToggleOption={(currency) => {
								setCurrencyConstraint((prev) => {
									return { ...prev, type: "RISK_MODEL", selectedOptions: [currency] };
								});
							}}
						/>
					) : null}
				</div>
			</>
		</Dialog>
	);
}

type CurrencyTableProps = {
	mode?: "checkbox" | "radio";
	selectableCurrencies: Array<Currencies>;
	onToggleOption?(row: Currencies): void;
	disabledRowIds?: string[];
};

function CurrencyTable({ selectableCurrencies, mode, onToggleOption, disabledRowIds }: CurrencyTableProps) {
	const [search, setSearch] = useState("");

	const { filtered } = useSearchable({
		collection: selectableCurrencies,
		query: search,
		matchFn: (currency, q) => objectTextSearchMatchFns.substring({ name: currency }, q),
	});

	const checkboxColumnData = useSelectableTableColumn({
		mode,
		rows: selectableCurrencies,
		selectBy: (r) => r ?? "",
		filteredRows: filtered,
		selectableRowIds: disabledRowIds && selectableCurrencies.filter((currency) => !disabledRowIds.includes(currency)),
	});

	const columns = useMemo<TableColumn<Currencies>[]>(
		() => [
			checkboxColumnData.column,
			{
				header: "",
				content: (x) => x ?? "",
				sortFn: builtInSort,
				name: "currencyName",
			},
		],
		[checkboxColumnData.column],
	);

	//todo: remove asap
	useEffect(() => {
		flushSync(() => {
			const row = selectableCurrencies.find((c) => {
				return checkboxColumnData.multiSelectCtx.selection.first() === c;
			});

			if (row) {
				onToggleOption?.(row);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkboxColumnData.multiSelectCtx.selection]);
	return (
		<>
			<DebouncedSearchInput
				data-qualifier="CurrencyTable/Search"
				externalQuery={search}
				onChange={setSearch}
				placeholder="Search currency.."
				classList="mb-4"
			/>

			<Controller value={defaultCurrencyOrderBy}>
				{({ value: orderBy, onChange: onOrderByChange }) => (
					<BaseTable
						columns={columns}
						rows={sortRows({ rows: filtered, columns, orderByArr: orderBy })}
						orderBy={orderBy}
						onOrderByChange={onOrderByChange}
						onRowClick={(currency) => {
							if (!checkboxColumnData.multiSelectCtx.disabled(currency)) {
								checkboxColumnData.toggle(currency);
								// onToggleOption?.(currency);
							}
						}}
						classList="max-h-[462px] min-h-[320px]"
					/>
				)}
			</Controller>
		</>
	);
}

const defaultCurrencyOrderBy: Array<OrderBy<"currencyName">> = [{ columnName: "currencyName", direction: "asc" }];

type SpawnCurrencyConstraintDialogProps = Omit<CurrencyConstraintDialogProps, "show" | "onClose">;
export function spawnCurrencyConstraintDialog(props: SpawnCurrencyConstraintDialogProps): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ show, resolve, onHidden }) => (
			<CurrencyConstraintDialog
				{...props}
				show={show}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
				onClose={() => resolve()}
				onSubmit={(payload) => {
					props.onSubmit(payload);
					resolve();
				}}
			/>
		)),
	);
}
