import { hasAccess } from "$root/components/AuthorizationGuard";
import { useUserValue } from "$root/functional-areas/user";
import { Banner } from "@mdotm/mdotui/components";
import type { InstrumentEditorBuilderProps } from "../builder";
import { useMemo } from "react";
import type { InstrumentEditorEntity } from "../const";

function MinNumberOfInstrumentBanner({
	threshold,
	instrumentBuilder,
	entity,
}: {
	threshold: number;
	instrumentBuilder: InstrumentEditorBuilderProps;
	entity: InstrumentEditorEntity;
}): JSX.Element {
	const user = useUserValue();
	const compositionMap = instrumentBuilder.watchComposition();

	const compositionMapWithoutDeleteInstruments = useMemo(
		() => compositionMap.removeAll(instrumentBuilder.getDeleted()),
		[compositionMap, instrumentBuilder],
	);

	const instrumentsInComposition = useMemo(
		() =>
			compositionMapWithoutDeleteInstruments.reduce((sum, instrument) => {
				if (instrument.proxyOverwriteType === "PORTFOLIO_MIXED" && entity !== "UNIVERSE") {
					const instrumentsInInvestment = instrument.investment?.nofInstruments ?? 0;
					const instrumentsExcluded = instrument.nOfInstrumentExcluded ?? 0;
					return instrumentsInInvestment - instrumentsExcluded + sum;
				}

				if (entity === "UNIVERSE") {
					return sum + 1;
				}

				if (!instrument.weight) {
					return sum;
				}

				return sum + 1;
			}, 0),
		[compositionMapWithoutDeleteInstruments, entity],
	);

	if (
		hasAccess(user, { requiredService: "NUMBER_OF_INSTRUMENTS_CHECK_BYPASS" }) ||
		instrumentsInComposition >= threshold
	) {
		return <></>;
	}

	return (
		<Banner severity="info" title="Minimum number of instrument" classList="mb-4">
			You need to add at least {threshold} instruments or a portfolio
		</Banner>
	);
}

export default MinNumberOfInstrumentBanner;
