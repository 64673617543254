import type { StylableProps } from "@mdotm/mdotui/components";
import { overrideClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";

export type HDividerProps = StylableProps & {
	color?: string;
};

export function HDivider(props: HDividerProps): JSX.Element {
	return (
		<div
			className={overrideClassName("border-b my-1", props.classList)}
			style={{ borderColor: props.color ?? themeCSSVars.palette_N100, ...props.style }}
		/>
	);
}
