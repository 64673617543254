import type { IconName } from "@mdotm/mdotui/components";

export class UnreachableError extends Error {
	constructor() {
		super("unreachable code");
	}
}

export class LocalizedError extends Error {
	constructor(
		public endUserMsg: string,
		opts?: { cause?: unknown; msg?: string },
	) {
		super(opts?.msg, { cause: opts?.cause });
	}
}

export class UserCancelledError extends Error {
	constructor(msg?: string, opts?: { cause?: unknown }) {
		super(msg ?? "action cancelled by the user", opts);
	}
}

export class ToastableError extends LocalizedError {
	public icon: IconName | undefined;

	constructor(
		public endUserMsg: string,
		opts?: { cause?: unknown; msg?: string; icon?: IconName },
	) {
		super(endUserMsg, { msg: opts?.msg, cause: opts?.cause });
		this.icon = opts?.icon ?? "Icon-full-alert";
	}
}
