import type { SpreadsheetCellApi } from "$root/components/tables-extra/spreadsheet/spreadsheet-cell";
import { useSpreadsheetCell } from "$root/components/tables-extra/spreadsheet/spreadsheet-cell";
import type { SpreadsheetCtx } from "$root/components/tables-extra/spreadsheet/spreadsheet-ctx";
import type { DataAttributesProps, InnerRefProps, StylableProps } from "@mdotm/mdotui/components";
import { ClickableDiv, Sandwich } from "@mdotm/mdotui/components";
import { overrideClassList, renderNodeOrFn } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { ReactNode } from "react";
import type { StoreApi } from "zustand";

function SelectableCell<Row, K extends keyof Row & string>({
	children,
	rowId,
	column,
	innerRef,
	spreadsheetCtx,
	classList,
	hasError = false,
	onDoubleClick,
	...forward
}: {
	children(props: { cellApi: SpreadsheetCellApi<Row[K]> } & StylableProps): ReactNode;
	rowId: string;
	column: K;
	spreadsheetCtx: StoreApi<SpreadsheetCtx<Row>>;
	hasError?: boolean;
	onDoubleClick?(): void;
} & StylableProps &
	InnerRefProps<HTMLDivElement> &
	DataAttributesProps): JSX.Element {
	const cellApi = useSpreadsheetCell(spreadsheetCtx, rowId, column);
	return (
		<Sandwich
			childrenFill
			onClick={(e) => e.stopPropagation()}
			{...forward}
			classList={overrideClassList("group/selectable-cell", classList)}
		>
			<ClickableDiv
				classList="flex !outline-none"
				innerRef={innerRef}
				onClick={(/* e TODO: future */) => cellApi.select(/* e.ctrlKey ? "append" :  */ "set")}
				onDoubleClick={onDoubleClick ?? cellApi.activate}
				disabled={cellApi.active}
			>
				{renderNodeOrFn(children, {
					cellApi,
					style: {
						paddingLeft: forward.style?.paddingLeft,
						paddingRight: forward.style?.paddingRight,
					},
				})}
			</ClickableDiv>
			<div
				className="inset-0 pointer-events-none opacity-0 group-hover/selectable-cell:opacity-100"
				style={{
					border: `2px solid`,
					borderColor: themeCSSVars.palette_N50,
				}}
			/>
			<div
				className="inset-0 pointer-events-none"
				style={{
					border: `2px solid`,
					borderColor: hasError ? themeCSSVars.palette_D500 : "transparent",
				}}
			/>
			<div
				className="inset-0 pointer-events-none"
				style={{
					border: `2px solid`,
					borderColor: cellApi.selected ? themeCSSVars.palette_S500 : "transparent",
				}}
			/>
		</Sandwich>
	);
}

export default SelectableCell;
