import { Set } from "immutable";
import type { Intersect } from "./types";

/**
 * Symmetric to exclude, returns a new object containing only the specified keys extracted from the input one.
 *
 * @param source An object.
 * @param keys Keys to remove from the input object.
 */
export function include<T extends object, K extends keyof T>(source: T, keys: Array<K>): Pick<T, K> {
	const result = {} as Partial<Pick<T, K>>;
	for (const k of keys) {
		if (k in source) {
			result[k] = source[k];
		}
	}
	return result as Pick<T, K>;
}

/**
 * Map an object to another with the same keys, mapping all values using a custom function.
 *
 * This function works on arrays to.
 *
 * Example:
 * ```ts
 * const original = { a: 'hello' };
 * const mapped = objectMap(original, (str) => str.length);
 * console.log(mapped.a); // 5
 * ```
 *
 * Example with array:
 * ```ts
 * const original = ['hello'];
 * const mapped = objectMap(original, (str) => str.length);
 * console.log(mapped[0]); // 5
 * console.log(Array.isArray(mapped)); // true
 * ```
 *
 * @param obj An object.
 * @param map A function to apply to the object values.
 */
export function objectMap<TInStructure extends Record<string, unknown>, TOut>(
	obj: TInStructure,
	map: (value: TInStructure[keyof TInStructure], key: keyof TInStructure) => TOut,
): { [K in keyof TInStructure]: TOut };
export function objectMap<TInStructure extends Record<string, unknown>, TOut>(
	obj: TInStructure,
	map: (value: TInStructure[keyof TInStructure], key: keyof TInStructure) => TOut,
): { [K in keyof TInStructure]: TOut } {
	const result: Partial<Record<keyof TInStructure, TOut>> = Array.isArray(obj)
		? ([] as Partial<Record<keyof TInStructure, TOut>>)
		: {};
	for (const key of Object.keys(obj)) {
		result[key as keyof TInStructure] = map(obj[key] as TInStructure[keyof TInStructure], key);
	}
	return result as { [K in keyof TInStructure]: TOut };
}

/**
 * Return a new object adding all necessary keys so that the output contains at least
 * all keys specified in the input array.
 * @param keys All keys that should be present in the output.
 * @param obj The source object.
 * @param coalesceWith A function that provides a default value for a given key.
 */
export function objectCoalesce<TOut extends Record<string, unknown>>(
	keys: (keyof TOut)[],
	obj: Partial<TOut>,
	coalesceWith: (key: keyof TOut) => TOut[keyof TOut],
): TOut {
	const result = {} as Partial<TOut>;
	for (const key of keys) {
		result[key] = obj[key] ?? coalesceWith(key);
	}

	return result as TOut;
}

/**
 * Recursively trim all strings in an object.
 *
 * ```ts
 * const original =  { a: { b: "  hello    " } };
 * const trimmed =  trimAll(original);
 * console.log(trimmed.a.b); // "hello"
 * ```
 *
 * @param obj a key-value object.
 */
export function trimAll<T extends Record<string, unknown>>(obj: T): T {
	return objectMap(obj, (value) =>
		typeof value === "string"
			? value.trim()
			: typeof value === "object" && value // not null nor undefined
			  ? trimAll(value as Record<string, unknown>)
			  : value,
	) as T;
}

/**
 * Generate a function that compares objects passed to it with `reference` and returns a boolean indicating whether common fields match.
 * @param reference The reference object.
 * @param mode (default "superset") Can be one of the following:
 * 	- superset: all properties of reference must appear in the compared object, e.g. reference { hello: "world", meaning: 42 } compared with { hello: "world" } would yield false
 * 	- subset: all properties of the compared object must appear in `reference`, e.g. reference { hello: "world" } compared with { hello: "world", meaning: 42 } would yield false
 * 	- intersection: only checks intersecting properties, e.g. reference { hello: "world" } compared with { hello: "world", meaning: 42 } and reference { hello: "world", meaning: 42 } compared with { hello: "world" } would both yield true
 */
export function objMatchFn<T extends Record<string, unknown>>(
	reference: T,
	mode?: "superset",
): (candidate: Partial<Record<keyof T, unknown>>) => boolean;

/**
 * Generate a function that compares objects passed to it with `reference` and returns a boolean indicating whether common fields match.
 * @param reference The reference object.
 * @param mode (default "superset") Can be one of the following:
 * 	- superset: all properties of reference must appear in the compared object, e.g. reference { hello: "world", meaning: 42 } compared with { hello: "world" } would yield false
 * 	- subset: all properties of the compared object must appear in `reference`, e.g. reference { hello: "world" } compared with { hello: "world", meaning: 42 } would yield false
 * 	- intersection: only checks intersecting properties, e.g. reference { hello: "world" } compared with { hello: "world", meaning: 42 } and reference { hello: "world", meaning: 42 } compared with { hello: "world" } would both yield true
 */
export function objMatchFn<T extends Record<string, unknown>>(
	reference: Partial<Record<keyof T, unknown>>,
	mode: "subset",
): (candidate: Partial<Record<keyof T, unknown>>) => boolean;
/**
 * Generate a function that compares objects passed to it with `reference` and returns a boolean indicating whether common fields match.
 * @param reference The reference object.
 * @param mode (default "superset") Can be one of the following:
 * 	- superset: all properties of reference must appear in the compared object, e.g. reference { hello: "world", meaning: 42 } compared with { hello: "world" } would yield false
 * 	- subset: all properties of the compared object must appear in `reference`, e.g. reference { hello: "world" } compared with { hello: "world", meaning: 42 } would yield false
 * 	- intersection: only checks intersecting properties, e.g. reference { hello: "world" } compared with { hello: "world", meaning: 42 } and reference { hello: "world", meaning: 42 } compared with { hello: "world" } would both yield true
 */
export function objMatchFn<T extends Record<string, unknown>>(
	reference: Partial<Record<keyof T, unknown>>,
	mode: "subset" | "superset" | "intersection",
): (candidate: Partial<Record<keyof T, unknown>>) => boolean;

/**
 * Generate a function that compares objects passed to it with `reference` and returns a boolean indicating whether common fields match.
 * @param reference The reference object.
 * @param mode (default "superset") Can be one of the following:
 * 	- superset: all properties of reference must appear in the compared object, e.g. reference { hello: "world", meaning: 42 } compared with { hello: "world" } would yield false
 * 	- subset: all properties of the compared object must appear in `reference`, e.g. reference { hello: "world" } compared with { hello: "world", meaning: 42 } would yield false
 * 	- intersection: only checks intersecting properties, e.g. reference { hello: "world" } compared with { hello: "world", meaning: 42 } and reference { hello: "world", meaning: 42 } compared with { hello: "world" } would both yield true
 */
export function objMatchFn<T extends Record<string, unknown>>(
	reference: Partial<Record<keyof T, unknown>>,
	mode: "subset" | "superset" | "intersection",
): (candidate: Partial<Record<keyof T, unknown>>) => boolean;

export function objMatchFn<T extends Record<string, unknown>>(
	reference: T,
	mode: "subset" | "superset" | "intersection" = "superset",
): (candidate: Partial<Record<string, unknown>>) => boolean {
	const referenceKeys = Set(Object.keys(reference));
	switch (mode) {
		case "intersection":
			return (candidate) => {
				const commonKeys = Set(Object.keys(candidate)).intersect(referenceKeys);
				for (const key of commonKeys) {
					if (reference[key] !== candidate[key]) {
						return false;
					}
				}
				return true;
			};
		case "superset":
			return (candidate) => {
				for (const key of referenceKeys) {
					if (reference[key] !== candidate[key]) {
						return false;
					}
				}
				return true;
			};
		case "subset":
			return (candidate) => {
				const candidateKeys = Set(Object.keys(candidate));
				for (const key of candidateKeys) {
					if (reference[key] !== candidate[key]) {
						return false;
					}
				}
				return true;
			};
	}
}

/** Make a clone of an object that differs for the specified properties. */
export function updateObj<T>(object: T, delta: { [K in keyof T]?: T[K] }): T {
	const clone = { ...object };
	for (const k of Object.keys(delta)) {
		const newValue = delta[k as keyof T];
		if (newValue !== undefined) {
			clone[k as keyof T] = newValue!;
		}
	}
	return clone;
}

/**
 * Like Object.freeze, but it's applied recursively.
 * Once freezed, the object will not allow modification to its properties.
 * Code adapted from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze
 * @param obj an object to freeze.
 */
export function deepFreeze<T extends Record<string | number | symbol, unknown>>(obj: T): void {
	for (const name of Reflect.ownKeys(obj)) {
		const value = (obj as Record<string | number | symbol, unknown>)[name];

		if ((value && typeof value === "object") || typeof value === "function") {
			deepFreeze(value as Record<string | number | symbol, unknown>);
		}
	}

	Object.freeze(obj);
}

export function valueByPath(obj: unknown, path: string): unknown | undefined {
	const props = path.split(".");
	let cur = obj;
	for (const prop of props) {
		cur = (cur as Record<string, unknown>)[prop];
		if (cur === undefined) {
			return undefined;
		}
	}
	return cur;
}

export function typedObjectKeys<TStructure extends object>(obj: TStructure): Array<keyof TStructure> {
	return Object.keys(obj) as Array<keyof TStructure>;
}

export function typedObjectValues<TStructure extends object>(obj: TStructure): Array<TStructure[keyof TStructure]> {
	return Object.values(obj) as Array<TStructure[keyof TStructure]>;
}

export function typedObjectEntries<TStructure extends object>(
	obj: TStructure,
): Array<{ [K in keyof TStructure]: [K, TStructure[K]] }[keyof TStructure]> {
	return Object.entries(obj) as Array<{ [K in keyof TStructure]: [K, TStructure[K]] }[keyof TStructure]>;
}

export function typedObjectFromEntries<Pairs extends [[unknown, unknown], ...Array<[unknown, unknown]>]>(
	keyValuePairs: Pairs,
): Intersect<
	{
		[Idx in keyof Pairs]: Pairs[Idx][0] extends string | number | symbol
			? { [K in Pairs[Idx][0]]: Pairs[Idx][1] }
			: never;
	}[keyof Pairs]
> {
	return Object.fromEntries(keyValuePairs);
}
