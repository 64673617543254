import type { RichAcl } from "$root/api/api-gen";
import {
	BenchmarksControllerApiFactory,
	EntityEditorControllerApiFactory,
	InvestmentReportsControllerApiFactory,
	ReferenceUniversesControllerApiFactory,
} from "$root/api/api-gen";
import type { AttemptedOperation, PlatformErrorAreas } from "$root/api/error-reporting";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import { hasAccess } from "$root/components/AuthorizationGuard";
import type { Crumb } from "$root/components/Breadcrumbs";
import { useCrumbs } from "$root/components/Crumbs/useCrumbs";
import { LeavePrompt } from "$root/components/LeavePrompt";
import { PageHeader } from "$root/components/PageHeader";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { defaultTagLabels, labelToTag } from "$root/components/tags/shared";
import { aclByArea } from "$root/functional-areas/acl/checkers/all";
import {
	BENCHMARK_INSTRUMENT_LIMIT,
	MIN_NUMBER_OF_BENCHMARK_INSTRUMENTS,
	MIN_NUMBER_OF_INSTRUMENTS,
	MIN_NUMBER_OF_TARGET_INVESTMENT_INSTRUMENTS,
	MIN_NUMBER_OF_UNIVERSE_INSTRUMENTS,
	TARGET_PORTFOLIO_INSTRUMENT_LIMIT,
	UNIVERSE_INSTRUMENT_LIMIT,
} from "$root/functional-areas/const";
import type { Tag } from "$root/functional-areas/instruments-editor/builder";
import {
	covertInstrumentEditorToReviewTicker,
	instrumentEntryKey,
	retrieveInvestmentData,
	retrieveUserInstrumentsClassifications,
	useInstrumentEditorBuilder,
} from "$root/functional-areas/instruments-editor/builder";
import type { InstrumentEditorEntity, InstrumentEditorEntry } from "$root/functional-areas/instruments-editor/const";
import InstrumentCompositionEditor from "$root/functional-areas/instruments-editor/instrumentEditor";
import { useUserValue } from "$root/functional-areas/user";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { useQueryNoRefetch } from "$root/utils";
import { AsyncButton, Button } from "@mdotm/mdotui/components";
import { Map, Set } from "immutable";
import { useMemo, useState } from "react";
import { flushSync } from "react-dom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { match } from "ts-pattern";
import { manualCreationSaveFactory, manualSaveEditFactory } from "./handler";
import { spawnSubmitUniverseEditDialog } from "./spawn-edit-universe";

async function submitWithTryCath(
	callback: () => Promise<void>,
	opt?: {
		area: PlatformErrorAreas;
		attemptedOperation?: AttemptedOperation;
	},
) {
	try {
		await callback();
	} catch (error) {
		reportPlatformError(error, "ERROR", opt?.area, opt?.attemptedOperation);
		throw error;
	}
}

type AllowedEntity = Extract<InstrumentEditorEntity, "TARGET_INVESTMENT" | "BENCHMARK" | "UNIVERSE">;
function ManualEditComposition(): JSX.Element {
	const { uuid, entity } = useParams<{ uuid: string; entity: AllowedEntity }>();
	const [pathToNotBlock, setPathToNotBlock] = useState<string[]>([typedUrlForRoute("Login", {})]);

	const { t } = useTranslation();
	const { push } = useTypedNavigation();
	const user = useUserValue();

	const investmentReportApi = useApiGen(InvestmentReportsControllerApiFactory);
	const entityEditorApi = useApiGen(EntityEditorControllerApiFactory);
	const benchmarksApi = useApiGen(BenchmarksControllerApiFactory);
	const referenceUniverseApi = useApiGen(ReferenceUniversesControllerApiFactory);

	const querySummary = useQueryNoRefetch(["queryInvestmentSummary", uuid], {
		queryFn: async () => {
			const minimunSummary = await match(entity)
				.returnType<Promise<{ name: string; richAcl?: RichAcl }>>()
				.with("TARGET_INVESTMENT", async () => {
					const summary = await axiosExtract(investmentReportApi.getInvestmentSummary(uuid));
					return { name: summary.name ?? "...", richAcl: summary.richAcl };
				})
				.with("BENCHMARK", async () => {
					const summary = await axiosExtract(benchmarksApi.getBenchmarkSummary(uuid));
					return { name: summary.name ?? "...", richAcl: summary.richAcl };
				})
				.with("UNIVERSE", async () => {
					const summary = await axiosExtract(referenceUniverseApi.getUniverse(uuid));
					return { name: summary.name ?? "...", richAcl: summary.richAcl };
				})
				.exhaustive();

			return minimunSummary;
		},
		onSuccess({ richAcl }) {
			match(entity)
				.with("TARGET_INVESTMENT", () => {
					const canEditComposition = aclByArea.targetPortfolio.canEditComposition(user.id, richAcl?.acl ?? []);
					if (!canEditComposition) {
						push("PortfolioReferenceDetails", { portfolioUid: uuid });
					}
				})
				.with("BENCHMARK", () => {
					const canEditComposition = aclByArea.benchmark.canEditComposition(user.id, richAcl?.acl ?? []);
					if (!canEditComposition) {
						push("CustomBenchmark", { benchmarkId: uuid });
					}
				})
				.with("UNIVERSE", () => {
					const canEditComposition = aclByArea.universe.canEditComposition(user.id, richAcl?.acl ?? []);
					if (!canEditComposition) {
						push("UniverseDetails", { universeUuid: uuid });
					}
				})
				.exhaustive();
		},
	});

	const { data: summary } = querySummary;

	const instrumentBuilder = useInstrumentEditorBuilder({ composition: Map() });
	const compositionMap = instrumentBuilder.watchComposition();
	const compositionMapWithoutDeleteInstruments = compositionMap.removeAll(instrumentBuilder.getDeleted());
	const totalCompositionWeight = instrumentBuilder.getTotal("weight");

	const queryInitManualEdit = useQueryNoRefetch(["queryInitManualEditEditor"], {
		queryFn: async () => {
			const editorResponse = await axiosExtract(entityEditorApi.getEditorEditComposition(uuid, entity));

			const { composition, cashTicker } = editorResponse;
			const nonNullableComposition = composition ?? [];
			const { investmentExposureMap, investmentSummaryMap, verifiedInvestmentsMap } =
				await retrieveInvestmentData(nonNullableComposition);
			const { userInstrumentsMap } = await retrieveUserInstrumentsClassifications(nonNullableComposition);
			return {
				...editorResponse,
				cashTicker: cashTicker ? { ...cashTicker, name: cashTicker.instrument ?? "Cash" } : undefined,
				composition: nonNullableComposition,
				investmentExposureMap,
				investmentSummaryMap,
				verifiedInvestmentsMap,
				userInstrumentsMap,
			};
		},
		onSuccess({
			composition,
			cashTicker,
			investmentExposureMap,
			investmentSummaryMap,
			verifiedInvestmentsMap,
			userInstrumentsMap,
		}) {
			const uniqueTags = Set(defaultTagLabels.concat(composition.flatMap((x) => (x.tagLabel ? [x.tagLabel] : []))));
			const tags = Map<string, Tag>(
				uniqueTags.map((tag): [string, Tag] => {
					const convertedTag = labelToTag({ label: tag }, Array.from(uniqueTags), true);
					return [convertedTag.name, { label: convertedTag.name, color: convertedTag.color, value: convertedTag.name }];
				}),
			);

			const compositionToMap = Map(
				composition.map((instrument): [string, InstrumentEditorEntry] => {
					const rowId = instrumentEntryKey(instrument);
					const { description: _description, type: _type, ...instrumentParams } = instrument;
					const userInstrument = userInstrumentsMap.get(instrument.ticker!) ?? {};
					if (instrument.proxyOverwriteType === "PORTFOLIO_MIXED" && instrument.ticker) {
						const exposure = investmentExposureMap.get(instrument.ticker);
						const investmentSummary = investmentSummaryMap.get(instrument.ticker);
						const someInstrumentAreRemoved = verifiedInvestmentsMap.get(instrument.ticker) ?? [];
						return [
							rowId,
							{
								...instrumentParams,
								...userInstrument,
								rowId,
								investment: {
									nofInstruments: investmentSummary?.nofInstruments,
									macroAssetClassExposure: exposure?.investmentComposition,
									macroAssetClassExposureEnhanced: exposure?.enhancementComposition,
									name: investmentSummary?.name,
									lastActionNote: investmentSummary?.lastActionNote,
									uuid: investmentSummary?.uuid,
									action: investmentSummary?.action,
								},
								identifier: "Portfolio",
								instrument: investmentSummary?.name,
								weight: instrument.weight ?? 10,
								stableWeight: instrument.weight,
								nOfInstrumentExcluded: someInstrumentAreRemoved.length,
								someInstrumentsAreExcluded: someInstrumentAreRemoved.length > 0,
								name: userInstrument.name ?? investmentSummary?.name ?? instrumentParams.instrument,
								alias: userInstrument.alias ?? instrumentParams.identifier,
							},
						];
					}
					return [
						rowId,
						{
							...instrumentParams,
							...userInstrument,
							name: userInstrument.name ?? instrumentParams.instrument,
							alias: userInstrument.alias ?? instrumentParams.identifier,
							rowId,
						},
					];
				}),
			);

			const { description: _description, type: _type, ...rest } = cashTicker ?? {};
			instrumentBuilder.reset(
				{
					composition: compositionToMap,
					cash: cashTicker ? { ...rest, rowId: instrumentEntryKey(cashTicker) } : undefined,
					tags,
				},
				{ disableToggleDirty: true },
			);
		},
	});

	const forwardToEntityPage = match(entity)
		.returnType<(uuid: string) => void>()
		.with("TARGET_INVESTMENT", () => (uid) => push("PortfolioReferenceDetails", { portfolioUid: uid }))
		.with("BENCHMARK", () => (uid) => push("CustomBenchmark", { benchmarkId: uid }))
		.with("UNIVERSE", () => (uid) => push("UniverseDetails", { universeUuid: uid }))
		.exhaustive();

	const maxInstrumentsInComposition = hasAccess(user, { requiredService: "NUMBER_OF_INSTRUMENTS_CHECK_BYPASS" })
		? +Infinity
		: match(entity)
				.returnType<number>()
				.with("BENCHMARK", () => BENCHMARK_INSTRUMENT_LIMIT)
				.with("TARGET_INVESTMENT", () => TARGET_PORTFOLIO_INSTRUMENT_LIMIT)
				.with("UNIVERSE", () => UNIVERSE_INSTRUMENT_LIMIT)
				.exhaustive();

	const minInstrumentsInComposition = hasAccess(user, { requiredService: "NUMBER_OF_INSTRUMENTS_CHECK_BYPASS" })
		? MIN_NUMBER_OF_INSTRUMENTS
		: match(entity)
				.returnType<number>()
				.with("BENCHMARK", () => MIN_NUMBER_OF_BENCHMARK_INSTRUMENTS)
				.with("TARGET_INVESTMENT", () => MIN_NUMBER_OF_TARGET_INVESTMENT_INSTRUMENTS)
				.with("UNIVERSE", () => MIN_NUMBER_OF_UNIVERSE_INSTRUMENTS)
				.exhaustive();

	const isSubmitDisabled = useMemo(() => {
		const instrumentsSize = compositionMapWithoutDeleteInstruments.size;
		if (entity === "UNIVERSE") {
			return instrumentsSize < minInstrumentsInComposition || instrumentsSize > maxInstrumentsInComposition;
		}
		return (
			totalCompositionWeight !== 100 ||
			instrumentsSize < minInstrumentsInComposition ||
			instrumentsSize > maxInstrumentsInComposition
		);
	}, [
		compositionMapWithoutDeleteInstruments.size,
		entity,
		maxInstrumentsInComposition,
		minInstrumentsInComposition,
		totalCompositionWeight,
	]);

	const crumbs = useCrumbs();

	return (
		<>
			<PageHeader
				title={match(entity)
					.returnType<string>()
					.with("BENCHMARK", () => crumbs.editBenchmark[0].label)
					.with("TARGET_INVESTMENT", () => crumbs.editTargetPortfolio[0].label)
					.with("UNIVERSE", () => crumbs.editUniverse[0].label)
					.exhaustive()}
				crumbsV2={match(entity)
					.returnType<Crumb[]>()
					.with("BENCHMARK", () => [
						...crumbs.portfolioStudio("References"),
						...crumbs.benchmarkName({ uuid, name: summary?.name }),
						...crumbs.editBenchmark,
					])
					.with("TARGET_INVESTMENT", () => [
						...crumbs.portfolioStudio("References"),
						...crumbs.targetPortfolioName({ uuid, name: summary?.name }),
						...crumbs.editTargetPortfolio,
					])
					.with("UNIVERSE", () => [
						...crumbs.portfolioStudio("Universes"),
						...crumbs.universeName({ uuid, name: summary?.name }),
						...crumbs.editUniverse,
					])
					.exhaustive()}
				titleAction={
					<div className="flex items-center space-x-2">
						<Button
							size="small"
							onClick={match(entity)
								.returnType<() => void>()
								.with("TARGET_INVESTMENT", () => () => push("PortfolioDetails", { portfolioUid: uuid }))
								.with("UNIVERSE", () => () => push("UniverseDetails", { universeUuid: uuid }))
								.with("BENCHMARK", () => () => push("CustomBenchmark", { benchmarkId: uuid }))
								.exhaustive()}
							palette="tertiary"
							data-qualifier="CompositionEditor/Cancel"
						>
							{t("BUTTON.CANCEL")}
						</Button>
						{entity === "UNIVERSE" ? (
							<Button
								palette="primary"
								size="small"
								data-qualifier="CompositionEditor/Save"
								disabled={isSubmitDisabled}
								onClick={() =>
									spawnSubmitUniverseEditDialog({
										async onAsyncSubmit(form) {
											await submitWithTryCath(
												async () => {
													let latestUuid = uuid;
													const composition = covertInstrumentEditorToReviewTicker(
														Array.from(instrumentBuilder.getComposition({ removeDeleted: true }).values()),
													);

													const hasPortfolioInComposition = instrumentBuilder
														.getComposition()
														.some((instrument) => instrument.proxyOverwriteType === "PORTFOLIO_MIXED");

													const portfolioSavingMode = hasPortfolioInComposition ? "NEST_PORTFOLIOS" : "MIX_INSTRUMENTS";

													if (form.saveMode === "SAVE_AS_NEW") {
														const { identifier, estimatedRetrieveTimeInMs } =
															await manualCreationSaveFactory.createUniverse({
																composition,
																form: { name: form.name, portfolioSavingMode },
															});
														latestUuid = identifier ?? uuid;
														trackMixPanelEvent("Universe", {
															Type: "Async Upload",
															Action: "save",
															ID: latestUuid,
															Name: form.name,
															estimatedTime: estimatedRetrieveTimeInMs ?? 0,
														});
													}

													if (form.saveMode === "SAVE") {
														await manualSaveEditFactory.saveUniverse(latestUuid, {
															composition,
															form: { portfolioSavingMode },
														});
														trackMixPanelEvent("Universe", {
															Type: "Edit Composition",
															Action: "save",
															ID: latestUuid,
															Name: form.name,
														});
													}

													flushSync(() =>
														setPathToNotBlock([typedUrlForRoute("UniverseDetails", { universeUuid: latestUuid })]),
													);
													forwardToEntityPage(latestUuid);
												},
												{
													area: "universe",
													attemptedOperation: { message: `Unable to save the edited universe (${uuid})` },
												},
											);
										},
									})
								}
							>
								{t("BUTTON.SAVE")}
							</Button>
						) : (
							<AsyncButton
								size="small"
								disabled={isSubmitDisabled}
								onClickAsync={() =>
									match(entity)
										.with("BENCHMARK", async () => {
											await submitWithTryCath(
												async () => {
													const composition = covertInstrumentEditorToReviewTicker(
														Array.from(instrumentBuilder.getComposition({ removeDeleted: true }).values()),
													);
													await manualSaveEditFactory.saveBenchmark(uuid, {
														composition,
													});

													flushSync(() =>
														setPathToNotBlock([typedUrlForRoute("CustomBenchmark", { benchmarkId: uuid })]),
													);
													forwardToEntityPage(uuid);
												},
												{
													area: "benchmark",
													attemptedOperation: { message: `Unable to save the edited benchmark (${uuid})` },
												},
											);
										})
										.with("TARGET_INVESTMENT", async () => {
											await submitWithTryCath(
												async () => {
													const composition = covertInstrumentEditorToReviewTicker(
														Array.from(instrumentBuilder.getComposition({ removeDeleted: true }).values()),
													);
													await manualSaveEditFactory.saveTargetInvestment(uuid, {
														composition,
													});

													flushSync(() =>
														setPathToNotBlock([typedUrlForRoute("PortfolioReferenceDetails", { portfolioUid: uuid })]),
													);
													forwardToEntityPage(uuid);
												},
												{
													area: "portfolio",
													attemptedOperation: { message: `Unable to save the target portfolio (${uuid})` },
												},
											);
										})
										.exhaustive()
								}
								palette="primary"
								data-qualifier="CompositionEditor/Save"
							>
								{t("BUTTON.DONE")}
							</AsyncButton>
						)}
					</div>
				}
			/>
			<ReactQueryWrapperBase query={queryInitManualEdit}>
				{() => (
					<div className="min-h-0 grow flex">
						<InstrumentCompositionEditor
							entity={entity}
							uuid={uuid}
							mode="edit"
							instrumentBuilder={instrumentBuilder}
							minThreshold={minInstrumentsInComposition}
							limit={maxInstrumentsInComposition}
						/>
					</div>
				)}
			</ReactQueryWrapperBase>
			<LeavePrompt
				title={t("NOTIFICATION_SETTINGS.LEAVE_PROMPT.TITLE")}
				when={instrumentBuilder.isDirty}
				pathToNotBlock={pathToNotBlock}
			>
				{t("NOTIFICATION_SETTINGS.LEAVE_PROMPT.MESSAGE")}
			</LeavePrompt>
		</>
	);
}

export default ManualEditComposition;
