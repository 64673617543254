import type { RichAccessControl } from "$root/api/api-gen";
import { AccessPermission } from "$root/api/api-gen";
import type { MaybeArray } from "@mdotm/mdotui/utils";
import type { ACLPermissionChecker } from "./shared";
import { validateACLPermissions } from "./shared";

export const aclByArea = {
	portfolio: {
		canCreateProposal(userId: string, acl: RichAccessControl[]): boolean {
			return validateACLPermissions(userId, acl, AccessPermission.CreateProposal);
		},
		canEditSettings(userId: string, acl: RichAccessControl[]): boolean {
			return validateACLPermissions(userId, acl, AccessPermission.EditSettings);
		},
		canEditComposition(userId: string, acl: RichAccessControl[]): boolean {
			return validateACLPermissions(userId, acl, AccessPermission.EditComposition);
		},
		canReview(userId: string, acl: RichAccessControl[]): boolean {
			return validateACLPermissions(userId, acl, AccessPermission.EditComposition);
		},
		canRename(userId: string, acl: RichAccessControl[]): boolean {
			return validateACLPermissions(userId, acl, AccessPermission.EditSettings);
		},
		canDelete(userId: string, acl: RichAccessControl[]): boolean {
			return validateACLPermissions(userId, acl, [AccessPermission.Delete, AccessPermission.Owner]);
		},
		canEditNotes(userId: string, acl: RichAccessControl[]): boolean {
			return validateACLPermissions(userId, acl, [AccessPermission.EditComposition]);
		},
	} satisfies Record<string, ACLPermissionChecker>,
	targetPortfolio: {
		canEditSettings(userId: string, acl: RichAccessControl[]): boolean {
			return validateACLPermissions(userId, acl, AccessPermission.EditSettings);
		},
		canEditComposition(userId: string, acl: RichAccessControl[]): boolean {
			return validateACLPermissions(userId, acl, AccessPermission.EditComposition);
		},
		canReview(userId: string, acl: RichAccessControl[]): boolean {
			return validateACLPermissions(userId, acl, AccessPermission.EditComposition);
		},
		canRename(userId: string, acl: RichAccessControl[]): boolean {
			return validateACLPermissions(userId, acl, AccessPermission.EditSettings);
		},
		canDelete(userId: string, acl: RichAccessControl[]): boolean {
			return validateACLPermissions(userId, acl, [AccessPermission.Delete, AccessPermission.Owner]);
		},
	} satisfies Record<string, ACLPermissionChecker>,
	benchmark: {
		canEditSettings(userId: string, acl: RichAccessControl[]): boolean {
			return validateACLPermissions(userId, acl, AccessPermission.EditSettings);
		},
		canEditComposition(userId: string, acl: RichAccessControl[]): boolean {
			return validateACLPermissions(userId, acl, AccessPermission.EditComposition);
		},
		canReview(userId: string, acl: RichAccessControl[]): boolean {
			return validateACLPermissions(userId, acl, AccessPermission.EditComposition);
		},
		canRename(userId: string, acl: RichAccessControl[]): boolean {
			return validateACLPermissions(userId, acl, AccessPermission.EditSettings);
		},
		canDelete(userId: string, acl: RichAccessControl[]): boolean {
			return validateACLPermissions(userId, acl, [AccessPermission.Delete, AccessPermission.Owner]);
		},
	} satisfies Record<string, ACLPermissionChecker>,
	universe: {
		canEditSettings(userId: string, acl: RichAccessControl[]): boolean {
			return validateACLPermissions(userId, acl, AccessPermission.EditSettings);
		},
		canEditComposition(userId: string, acl: RichAccessControl[]): boolean {
			return validateACLPermissions(userId, acl, AccessPermission.EditComposition);
		},
		canReview(userId: string, acl: RichAccessControl[]): boolean {
			return validateACLPermissions(userId, acl, AccessPermission.EditComposition);
		},
		canRename(userId: string, acl: RichAccessControl[]): boolean {
			return validateACLPermissions(userId, acl, AccessPermission.EditSettings);
		},
		canDelete(userId: string, acl: RichAccessControl[]): boolean {
			return validateACLPermissions(userId, acl, [AccessPermission.Delete, AccessPermission.Owner]);
		},
	} satisfies Record<string, ACLPermissionChecker>,
	marketView: {
		canEditComposition(userId: string, acl: RichAccessControl[]): boolean {
			return validateACLPermissions(userId, acl, AccessPermission.EditComposition);
		},
		canRename(userId: string, acl: RichAccessControl[]): boolean {
			return validateACLPermissions(userId, acl, AccessPermission.EditSettings);
		},
		canDelete(userId: string, acl: RichAccessControl[]): boolean {
			return validateACLPermissions(userId, acl, [AccessPermission.Delete, AccessPermission.Owner]);
		},
	} satisfies Record<string, ACLPermissionChecker>,
};

export type Roles = "VIEWER" | "EDITOR" | "OWNER";
export type Areas = keyof typeof roleByArea;

const OWNER_PERMISSION = [
	"READ",
	"EDIT_SETTINGS",
	"EDIT_COMPOSITION",
	"CREATE_PROPOSAL",
	"GRANTOR",
	"TRANSFER_OWNERSHIP",
	"OWNER",
	"DELETE",
] satisfies Array<AccessPermission>;

const EDITOR_PERMISSION = [
	"READ",
	"EDIT_SETTINGS",
	"EDIT_COMPOSITION",
	"CREATE_PROPOSAL",
	"GRANTOR",
] satisfies Array<AccessPermission>;

const READ_PERMISSION = ["READ"] satisfies Array<AccessPermission>;

export const roleByArea = {
	portfolio: {
		VIEWER: READ_PERMISSION,
		EDITOR: EDITOR_PERMISSION,
		OWNER: OWNER_PERMISSION,
	} satisfies Record<Roles, Array<AccessPermission>>,
	targetPortfolio: {
		VIEWER: READ_PERMISSION,
		EDITOR: EDITOR_PERMISSION,
		OWNER: OWNER_PERMISSION,
	} satisfies Record<Roles, Array<AccessPermission>>,
	benchmark: {
		VIEWER: READ_PERMISSION,
		EDITOR: EDITOR_PERMISSION,
		OWNER: OWNER_PERMISSION,
	} satisfies Record<Roles, Array<AccessPermission>>,
	universe: {
		VIEWER: READ_PERMISSION,
		EDITOR: EDITOR_PERMISSION,
		OWNER: OWNER_PERMISSION,
	} satisfies Record<Roles, Array<AccessPermission>>,
	marketView: {
		VIEWER: READ_PERMISSION,
		EDITOR: EDITOR_PERMISSION,
		OWNER: OWNER_PERMISSION,
	} satisfies Record<Roles, Array<AccessPermission>>,
};

export function checkerFromPermission(permission: MaybeArray<AccessPermission>): ACLPermissionChecker {
	return (userId, acl) => validateACLPermissions(userId, acl, permission);
}

export function roleFromAcl(area: keyof typeof roleByArea, userId: string, acl: Array<RichAccessControl>): Roles {
	return validateACLPermissions(userId, acl, roleByArea[area].OWNER)
		? "OWNER"
		: validateACLPermissions(userId, acl, roleByArea[area].EDITOR)
		  ? "EDITOR"
		  : validateACLPermissions(userId, acl, roleByArea[area].VIEWER)
		    ? "VIEWER"
		    : "VIEWER"; // TODO: or throw/report an error?
}
