import type { InvestmentListEntry, InvestmentSummary } from "$root/api/api-gen";
import { EntityEditorControllerApiFactory, InvestmentReportsControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { MinimumDialogProps } from "$root/components/spawnable/type";
import { useLocaleFormatters } from "$root/localization/hooks";
import { statusIconMap } from "$root/pages/PortfoliosStudio/statusIconMap";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { useQueryNoRefetch } from "$root/utils";
import type { OrderBy, TableColumn } from "@mdotm/mdotui/components";
import {
	AutoTooltip,
	BaseTable,
	Button,
	Controller,
	Dialog,
	DialogFooter,
	DialogHeader,
	Icon,
	sortRows,
	SubmitButton,
	TableDataCell,
	TextInput,
	TooltipContent,
	useSelectableTableColumn,
} from "@mdotm/mdotui/components";
import { useDebouncedSearch, useSearchable } from "@mdotm/mdotui/headless";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, spawn, toClassListRecord } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";
import { Map } from "immutable";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { InstrumentEditorEntity } from "../const";

type CustomizedInvestmentEntry = InvestmentListEntry & { canBeUsedToOverride: boolean };
export type PortfolioTemplateDialogProps = {
	uuid?: string;
	entity: InstrumentEditorEntity;
	onSubmitAsync?(investment: InvestmentListEntry): Promise<void>;
} & MinimumDialogProps;

function canBeUsedToOverride(currentPortfolio: InvestmentSummary, investment: InvestmentListEntry) {
	const isPortfolioHealty =
		investment.status === "ACCEPTED" || investment.status === "READY" || investment.status === "PROPOSAL_READY";

	if (currentPortfolio.usagesAsNestedPortfolios && currentPortfolio.usagesAsNestedPortfolios.length > 0) {
		return isPortfolioHealty && !investment.currentlyContainsNestedPortfolios;
	}

	return isPortfolioHealty;
}

function PortfolioTemplateDialog(props: PortfolioTemplateDialogProps) {
	const { show, onAnimationStateChange, onClose, onSubmitAsync, uuid, entity } = props;

	const { t } = useTranslation();
	const editorApi = useApiGen(EntityEditorControllerApiFactory);
	const investmentReportApi = useApiGen(InvestmentReportsControllerApiFactory);

	const formatters = useLocaleFormatters();
	const { formatDate } = formatters;

	const queryPortfolio = useQueryNoRefetch(["queryComposition", "investment", "override"], {
		enabled: uuid !== undefined,
		queryFn: async () => {
			const summary = await axiosExtract(investmentReportApi.getInvestmentSummary(uuid!));
			const { selectablePortfolios } = await axiosExtract(editorApi.getEditorEditSelectablePortfolios(uuid!, entity));

			return (
				selectablePortfolios?.map((x) => ({
					...x,
					canBeUsedToOverride: canBeUsedToOverride(summary, x),
				})) ?? []
			);
		},
	});

	const { data } = queryPortfolio;
	const selectablePortfolioMap = useMemo(
		() => Map(data?.flatMap((investment) => (investment.uuid ? [[investment.uuid!, investment]] : []))),
		[data],
	);

	const { debouncedNormalizedQuery, query, setQuery } = useDebouncedSearch("", 10);
	const { filtered } = useSearchable({
		collection: data ?? [],
		query: debouncedNormalizedQuery,
		matchFn: (row, q) => {
			const stringifiedRow = JSON.stringify(row.name).toLowerCase();
			const words = q.toLowerCase().split(" ");
			return words.every((keys) => stringifiedRow.indexOf(keys) >= 0);
		},
	});

	const selectableRowIds = useMemo(() => {
		if (!data) {
			return [];
		}

		return data.flatMap((x) => (x.uuid && x.canBeUsedToOverride ? [x.uuid] : []));
	}, [data]);

	const {
		column: checkBoxColumn,
		rowClassList,
		toggle,
		multiSelectCtx,
	} = useSelectableTableColumn({
		filteredRows: filtered,
		rows: data ?? [],
		selectBy: ({ uuid }) => uuid ?? "",
		selectableRowIds,
		mode: "radio",
	});

	const columns = useMemo<Array<TableColumn<CustomizedInvestmentEntry, string>>>(
		() => [
			{
				...checkBoxColumn,
				header: "",
			},
			{
				header: t("TABLE.HEADERS.NAME"),
				content: (row, cellProps) => {
					if (!row.canBeUsedToOverride && row.containsNestedPortfolios) {
						return (
							<AutoTooltip
								mode="hover"
								align="startToStart"
								position="top"
								overrideColor={themeCSSVars.palette_N200}
								trigger={({ innerRef }) => (
									<TableDataCell {...cellProps}>
										<span ref={innerRef}>{row.name}</span>
									</TableDataCell>
								)}
							>
								<TooltipContent>
									<p className="text-center">
										You cannot add portfolios that already include other portfolios in their metrics and performance
										calculation
									</p>
								</TooltipContent>
							</AutoTooltip>
						);
					}

					return row.name;
				},
				sortFn: builtInSortFnFor("name"),
				name: "name",
			},
			{
				header: t("TABLE.HEADERS.STATUS"),
				content: ({ status }, cellProps) => {
					const sanitizedStatus = status ? statusIconMap[status] : "-";
					if (sanitizedStatus === "-") {
						return "-";
					}
					return (
						<TableDataCell {...cellProps}>
							<div title={sanitizedStatus.title} className="flex items-center">
								<Icon
									classList="mr-1"
									icon={sanitizedStatus.icon}
									color={themeCSSVars.palette_N800} // changed
									size={sanitizedStatus.size}
								/>
								<div style={{ fontSize: 12, fontFamily: "Gotham", fontWeight: "300" }}>
									{sanitizedStatus.title}
								</div>
							</div>
						</TableDataCell>
					);
				},
				sortFn: builtInSortFnFor("status"),
				name: "status",
			},
			{
				header: t("TABLE.HEADERS.LAST_STATUS_UPDATE"),
				content: ({ modificationTime }, cellProps) => {
					const sanitizedModificationTime = modificationTime ? formatDate(new Date(modificationTime)) : "-";
					return <TableDataCell {...cellProps}>{sanitizedModificationTime}</TableDataCell>;
				},
				sortFn: ({ modificationTime: a }, { modificationTime: b }) => {
					const rowA = new Date(a ?? "").getTime();
					const rowB = new Date(b ?? "").getTime();
					return rowA > rowB ? 1 : rowA < rowB ? -1 : 0;
				},
				name: "lastReportsUpdate",
			},
			{
				header: t("TABLE.HEADERS.UNIVERSE_NAME"),
				content: (row) => row.universeName,
				name: "universeName",
				sortFn: builtInSortFnFor("universeName"),
			},
		],
		[checkBoxColumn, formatDate, t],
	);

	return (
		<Dialog
			size="xxlarge"
			show={show}
			onClose={onClose}
			header={<DialogHeader>Select a portfolio</DialogHeader>}
			onAnimationStateChange={onAnimationStateChange}
			onSubmitAsync={async () => {
				const uuid = multiSelectCtx.selection.first();
				if (uuid) {
					const investment = selectablePortfolioMap.get(uuid);
					if (investment) {
						const { canBeUsedToOverride, ...investmentEntry } = investment;
						await onSubmitAsync?.(investmentEntry);
					}
				}
			}}
			footer={
				<DialogFooter
					primaryAction={<SubmitButton disabled={queryPortfolio.isFetching}>{t("BUTTON.CONFIRM")}</SubmitButton>}
					neutralAction={
						<Button palette="tertiary" onClick={onClose}>
							Cancel
						</Button>
					}
				/>
			}
		>
			<div className="mb-4">
				<TextInput placeholder="Search a portfolio" classList="max-w-[280px]" value={query} onChangeText={setQuery} />
			</div>
			<ReactQueryWrapperBase query={queryPortfolio}>
				{() => (
					<Controller value={defaultReportOrderBy}>
						{({ value: orderBy, onChange: onOrderByChange }) => (
							<BaseTable
								rows={sortRows({ rows: filtered, columns, orderByArr: orderBy })}
								columns={columns}
								orderBy={orderBy}
								onOrderByChange={onOrderByChange}
								onRowClick={(x) => toggle(x.uuid!)}
								palette="uniform"
								classList="h-[412px]"
								rowClassList={(row, rowIndex) => ({
									...toClassListRecord(rowClassList(row, rowIndex)),
									"opacity-60": !row.canBeUsedToOverride,
								})}
							/>
						)}
					</Controller>
				)}
			</ReactQueryWrapperBase>
		</Dialog>
	);
}

const defaultReportOrderBy: Array<OrderBy<"name">> = [{ columnName: "name", direction: "asc" }];

export type spawnPortfolioTemplateDialogProps = Omit<PortfolioTemplateDialogProps, "show" | "onClose">;
export function spawnOverridePortfolioDialog(props: spawnPortfolioTemplateDialogProps): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ show, resolve, onHidden }) => (
			<PortfolioTemplateDialog
				{...props}
				show={show}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
				onClose={() => resolve()}
				onSubmitAsync={async (uuids) => {
					await props.onSubmitAsync?.(uuids);
					resolve();
				}}
			/>
		)),
	);
}
