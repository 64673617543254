import { noRefetchDefaults } from "$root/utils";
import { AsyncButton, ProgressBar } from "@mdotm/mdotui/components";
import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import { renderNodeOrFn, useUniqueDOMId } from "@mdotm/mdotui/react-extensions";
import type { QueriesOptions, QueriesResults, QueryKey, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { useQueries, useQuery } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

export type NonUndefined<T> = T extends undefined ? never : T;

export type ReactQueryWrapperProps<
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
> = {
	/** @default 'noRefetch' */
	mode?: "noRefetch" | "auto";
	children(queryResult: NonUndefined<TData>, query: UseQueryResult<TData, TError>): React.ReactNode;
	loadingFallback?: React.ReactNode;
	errorFallback?: NodeOrFn<{ error: TError; retry: () => Promise<void> }>;
	/**
	 * @default data !== undefined
	 */
	keepChildren?: boolean;
} & UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>;

export const DefaultErrorFallback: React.FC<{ retry: () => Promise<void>; text?: string }> = ({ retry, text }) => {
	const { t } = useTranslation();
	return (
		<div className="inline-flex items-center">
			<div className="mr-4">{text ?? t("SOMETHING_WENT_WRONG")}</div>
			<div>
				<AsyncButton
					onClickAsync={() => retry()}
					style={{ minWidth: 0, borderRadius: 9999 }}
					palette="secondary"
					size="small"
				>
					{t("RETRY")}
				</AsyncButton>
			</div>
		</div>
	);
};

export function customizeErrorFallback(text: string) {
	return function CustomizedErrorFallback({ retry }: { retry: () => Promise<void> }): JSX.Element {
		return <DefaultErrorFallback retry={retry} text={text} />;
	};
}

export default function ReactQueryWrapper<
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>({
	children,
	mode,
	errorFallback,
	loadingFallback,
	keepChildren,
	...useQueryParams
}: ReactQueryWrapperProps<TQueryFnData, TError, TData, TQueryKey>): JSX.Element {
	const query = useQuery({
		...(mode === "auto"
			? {}
			: {
					...noRefetchDefaults,
			  }),
		...useQueryParams,
	});

	return (
		<ReactQueryWrapperBase
			query={query}
			errorFallback={errorFallback}
			loadingFallback={loadingFallback}
			keepChildren={keepChildren}
		>
			{children}
		</ReactQueryWrapperBase>
	);
}

export type ReactQueryWrapperBaseProps<TData = unknown, TError = unknown> = {
	query: UseQueryResult<TData, TError>;
	children(queryResult: NonUndefined<TData>, query: UseQueryResult<TData, TError>): React.ReactNode;
	loadingFallback?: React.ReactNode;
	errorFallback?: NodeOrFn<{ error: TError; retry: () => Promise<void> }>;
	/**
	 * @default query.data !== undefined
	 */
	keepChildren?: boolean;
};

export function ReactQueryWrapperBase<TData = unknown, TError = unknown>(
	props: ReactQueryWrapperBaseProps<TData, TError>,
): JSX.Element {
	const retry = useCallback(async () => {
		await props.query.refetch();
	}, [props.query]);
	const errorFallback = props.errorFallback ?? <DefaultErrorFallback retry={retry} />;

	const keepChildren = props.keepChildren ?? props.query.data !== undefined;
	const domId = useUniqueDOMId();

	return (
		<React.Fragment key={domId}>
			{props.query.isError && !(props.query.data !== undefined && keepChildren) ? (
				renderNodeOrFn(errorFallback, {
					retry,
					error: props.query.error,
				})
			) : (props.query.isLoading || props.query.data === undefined) && !keepChildren ? (
				typeof props.loadingFallback === "undefined" ? (
					<ProgressBar value="indeterminate" />
				) : (
					props.loadingFallback
				)
			) : (
				props.children(props.query.data as NonUndefined<TData>, props.query)
			)}
		</React.Fragment>
	);
}

export type ReactQueriesWrapperProps<T extends any[]> = {
	queries: readonly [...QueriesOptions<T>];
	context?: UseQueryOptions["context"];

	/** @default 'noRefetch' */
	mode?: "noRefetch" | "auto";
	children(
		queryResults: { [K in keyof T]: NonUndefined<T[K]["data"]> },
		queries: readonly [...QueriesOptions<T>],
	): React.ReactNode;
	loadingFallback?: React.ReactNode;
	errorFallback?: NodeOrFn<{ errors: unknown[]; retry: () => Promise<void> }>;
	/**
	 * @default queries.every(q => q.data !== undefined)
	 */
	keepChildren?: boolean;
};

export function ReactQueriesWrapper<T extends any[]>({
	children,
	mode,
	errorFallback,
	loadingFallback,
	keepChildren,
	queries,
	...useQueriesParams
}: ReactQueriesWrapperProps<T>): JSX.Element {
	const q = useQueries({
		...useQueriesParams,
		queries: queries.map((query) => ({
			...(mode === "auto"
				? {}
				: {
						...noRefetchDefaults,
				  }),
			...query,
		})),
	}) as QueriesResults<T>;

	return (
		/* @ts-ignore */
		<ReactQueriesWrapperBase
			queries={q}
			errorFallback={errorFallback}
			loadingFallback={loadingFallback}
			keepChildren={keepChildren}
		>
			{children as () => JSX.Element} {/* TODO: fix type */}
		</ReactQueriesWrapperBase>
	);
}

export type ReactQueriesWrapperBaseProps<
	Queries extends
		| [UseQueryResult<unknown, unknown>, ...UseQueryResult<unknown, unknown>[]]
		| UseQueryResult<unknown, unknown>[]
		| [],
> = {
	queries: Queries;
	context?: UseQueryOptions["context"];

	children(queryResults: { [K in keyof Queries]: NonUndefined<Queries[K]["data"]> }, queries: Queries): React.ReactNode;
	loadingFallback?: React.ReactNode;
	errorFallback?: NodeOrFn<{ errors: unknown[]; retry: () => Promise<void> }>;
	/**
	 * @default false
	 */
	keepChildren?: boolean;
};

export function ReactQueriesWrapperBase<
	Queries extends
		| [UseQueryResult<unknown, unknown>, ...UseQueryResult<unknown, unknown>[]]
		| UseQueryResult<unknown, unknown>[]
		| [],
>(props: ReactQueriesWrapperBaseProps<Queries>): JSX.Element {
	const retry = useCallback(async () => {
		await Promise.all(props.queries.filter((q) => q.isError).map((q) => q.refetch()));
	}, [props.queries]);
	const errorFallback = props.errorFallback ?? <DefaultErrorFallback retry={retry} />;

	const keepChildren = props.keepChildren ?? props.queries.every((q) => q.data !== undefined);
	const domId = useUniqueDOMId();

	return (
		<React.Fragment key={domId}>
			{props.queries.some((q) => q.isError) ? (
				renderNodeOrFn(errorFallback, {
					retry,
					errors: props.queries.map((q) => q.error),
				})
			) : (props.queries.some((q) => q.isLoading) || props.queries.some((q) => q.data === undefined)) &&
			  !keepChildren ? (
				typeof props.loadingFallback === "undefined" ? (
					<ProgressBar value="indeterminate" />
				) : (
					props.loadingFallback
				)
			) : (
				props.children(props.queries.map((q) => q.data) as any /* TODO: fix type */, props.queries)
			)}
		</React.Fragment>
	);
}
