import type { SortFn } from "@mdotm/mdotui/utils";
import { builtInSort, type MutableArrayLike } from "@mdotm/mdotui/utils";
import type { Maybe } from "./types";

/**
 * Maps all the items contained in the arrays of a key-value object.
 *
 * @param groups An object whose values are arrays.
 * @param map A function will be applied to the items in the input arrays.
 */
export function groupMap<TKeys extends string, TInputValue, TOutputValue>(
	groups: { [K in TKeys]: TInputValue[] },
	map: (v: TInputValue, i: number, groupArr: TInputValue[], groupName: TKeys) => TOutputValue,
): { [K in TKeys]: TOutputValue[] } {
	return Object.fromEntries(
		Object.entries(groups).map(([groupName, groupValues]) => [
			groupName,
			(groupValues as TInputValue[] | undefined)?.map((v, i, arr) => map(v, i, arr, groupName as TKeys)),
		]),
	) as { [K in TKeys]: TOutputValue[] };
}

export function builtInCaseInsensitiveSort(
	a: Maybe<string>,
	b: Maybe<string>,
	direction: "asc" | "desc" = "asc",
): number {
	return builtInSort(a?.toLowerCase(), b?.toLowerCase(), direction);
}

export function builtInCaseInsensitiveSortFor<TKey extends string, T extends { [K in TKey]?: Maybe<string> }>(
	name: TKey,
	direction: "asc" | "desc" = "asc",
): SortFn<T> {
	return (a, b) => builtInCaseInsensitiveSort(a[name], b[name], direction);
}

export function sortByNumericMapping<T>(map: (item: T) => number, direction: "asc" | "desc" = "asc"): SortFn<T> {
	return (a, b) => builtInSort(map(a), map(b), direction);
}

export function replaceById<Arr extends ArrayLike<unknown>, TComparable>(
	cur: Arr,
	newItem: Arr[number],
	identify: (item: Arr[number]) => TComparable,
): Array<Arr[number]> {
	const next = Array.from(cur);
	const newItemId = identify(newItem);
	const index = next.findIndex((prevItem) => identify(prevItem) === newItemId);
	if (index !== -1) {
		next[index] = newItem;
	}
	return next;
}

export function removeById<Arr extends ArrayLike<unknown>, TComparable>(
	cur: Arr,
	identifier: TComparable,
	identify: (item: Arr[number]) => TComparable,
): Array<Arr[number]> {
	const next = Array.from(cur);
	const index = next.findIndex((prevItem) => identify(prevItem) === identifier);
	if (index !== -1) {
		next.splice(index, 1);
	}
	return next;
}

export function removeEmptyItems<TIn>(x: Array<TIn | undefined | null>): Array<TIn> {
	return x.filter((item) => item !== null && item !== undefined) as Array<TIn>;
}

export function filterMap<TIn, TOut>(
	arr: ArrayLike<TIn>,
	filterMapFn: (x: TIn) => { value: TOut } | null,
): Array<TOut> {
	const output = new Array<TOut>();
	for (let i = 0; i < arr.length; i++) {
		const result = filterMapFn(arr[i]);
		if (result) {
			output.push(result.value);
		}
	}
	return output;
}

export function countIf<T>(arr: ArrayLike<T>, countItFn: (x: T) => boolean): number {
	let sum = 0;
	for (let i = 0; i < arr.length; i++) {
		if (countItFn(arr[i])) {
			sum++;
		}
	}
	return sum;
}

export function mapArrayLike<TArr extends ArrayLike<any>, TOut>(
	arr: TArr,
	mapFn: (item: TArr[number], index: number) => TOut,
): Array<TOut> {
	const mapped = new Array(arr.length);
	for (let i = 0; i < arr.length; i++) {
		mapped[i] = mapFn(arr[i], i);
	}
	return mapped;
}

export function mapIterable<TArr extends Iterable<any>, TOut>(
	iter: TArr,
	mapFn: TArr extends Iterable<infer Item> ? (v: Item, i: number) => TOut : never,
): Array<TOut> {
	const mapped = [];
	let i = 0;
	for (const item of iter) {
		mapped.push(mapFn(item, i));
		i++;
	}
	return mapped;
}

export function maxArrayLike<TArr extends ArrayLike<any>, TExtractFn extends (v: TArr[number]) => number>(
	arr: TArr,
	extractFn: TExtractFn,
): number {
	let max = -Infinity;
	for (let i = 0; i < arr.length; i++) {
		max = Math.max(max, extractFn(arr[i]));
	}
	return max;
}

export function maxIterable<TArr extends Iterable<any>>(
	iter: TArr,
	extractFn: TArr extends Iterable<infer Item> ? (v: Item) => number : never,
): number {
	let max = -Infinity;
	for (const item of iter) {
		max = Math.max(max, extractFn(item));
	}
	return max;
}

export function minArrayLike<TArr extends ArrayLike<any>, TExtractFn extends (v: TArr[number]) => number>(
	arr: TArr,
	extractFn: TExtractFn,
): number {
	let min = Infinity;
	for (let i = 0; i < arr.length; i++) {
		min = Math.min(min, extractFn(arr[i]));
	}
	return min;
}

export function avgArrayLike<TArr extends ArrayLike<any>, TExtractFn extends (v: TArr[number]) => number>(
	arr: TArr,
	extractFn: TExtractFn,
): number {
	const sum = sumArrayLike(arr, extractFn);
	return sum / arr.length;
}

export function minIterable<TArr extends Iterable<any>>(
	iter: TArr,
	extractFn: TArr extends Iterable<infer Item> ? (v: Item) => number : never,
): number {
	let min = Infinity;
	for (const item of iter) {
		min = Math.min(min, extractFn(item));
	}
	return min;
}

export function sumArrayLike<TArr extends ArrayLike<any>, TExtractFn extends (v: TArr[number]) => number>(
	arr: TArr,
	extractFn: TExtractFn,
): number {
	let sum = 0;
	for (let i = 0; i < arr.length; i++) {
		sum = sum + extractFn(arr[i]);
	}
	return sum;
}

export function sumIterable<TArr extends Iterable<any>>(
	iter: TArr,
	extractFn: TArr extends Iterable<infer Item> ? (v: Item) => number : never,
): number {
	let sum = 0;
	for (const item of iter) {
		sum = sum + extractFn(item);
	}
	return sum;
}

export function shuffle<TArr extends MutableArrayLike<any>>(arr: TArr): TArr {
	for (let i = 0; i < arr.length; i++) {
		const j = Math.floor(Math.random() * arr.length);

		if (i !== j) {
			const tmp = arr[i];
			arr[i] = arr[j];
			arr[j] = tmp;
		}
	}

	return arr;
}

export function toUndefinedIfEmpty<T>(arr: T[] | undefined): T[] | undefined {
	return arr?.length ? arr : undefined;
}

export type MapKeys<M> = M extends Map<infer K, any> ? K : never;
export type MapValues<M> = M extends Map<any, infer V> ? V : never;

export function oneOf<T, AllowedValues extends [any, ...any[]]>(
	x: T,
	allowedValues: AllowedValues,
): x is AllowedValues[number] {
	return allowedValues.includes(x);
}

export function repeat<T>(arr: ArrayLike<T>, times: number): Array<T> {
	return Array.from({ length: times }).flatMap(() => Array.from(arr));
}

/**
 * throw error in case there is a unhandled case
 */
export function exhaustiveMatchingGuard(_: never): never {
	throw new Error("switch case has an unhandled match");
}
