import { hasAccess } from "$root/components/AuthorizationGuard";
import { CopyableText } from "$root/components/CopyableText";
import CustomLabelsEditor from "$root/components/CustomLabels";
import { InfoDelta } from "$root/components/InfoDelta";
import { typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";
import { useLocaleFormatters } from "$root/localization/hooks";
import { PortfolioDetailsTabs } from "$root/pages/PortfolioDetails/portfolio-details-tabs";
import { CommonItemActions } from "$root/ui-lib/interactive-collections/common-item-actions";
import type { ActionWithOptionalGroup, TableColumn, TableWithGroupsColumn } from "@mdotm/mdotui/components";
import {
	ActionText,
	AutoTooltip,
	Button,
	CircularProgressBar,
	DropdownMenu,
	Icon,
	NullableNumberInput,
	Row,
	TableDataCell,
	TableHeadCell,
	TooltipContent,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import type { ClassList } from "@mdotm/mdotui/react-extensions";
import { toClassName, useUniqueDOMId } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor, unpromisify } from "@mdotm/mdotui/utils";
import type { Set } from "immutable";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PortfolioExposureSummary from "../compare-portfolio/PortfolioExposureSummary";
import { useUserValue } from "../user";
import { Tag } from "./builder";
import type { GroupedInstrumentEditorEntry, InstrumentEditorEntry } from "./const";
import { TagBadge } from "$root/components/tags/TagBadge";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { InvestmentControllerV4ApiFactory } from "$root/api/api-gen";
import { minutes } from "$root/utils";
import { useApiGen } from "$root/api/hooks";
import ReactQueryWrapper from "$root/components/ReactQueryWrapper";

function InstumentNameAsPortfolioBlock(props: {
	row: InstrumentEditorEntry;
	onCompare?(row?: boolean): void;
	isRowCompared?(id: string): boolean;
	disableTooltip?: boolean;
}) {
	const { row, onCompare, isRowCompared } = props;

	const compared = useMemo(() => isRowCompared?.(row.rowId), [isRowCompared, row.rowId]);
	const exposureComposition = useMemo(
		() =>
			row.investment?.macroAssetClassExposure?.map((x) => ({
				quality: x.firstQualityLevel,
				weight: x.weight,
			})) ?? [],
		[row.investment?.macroAssetClassExposure],
	);

	const investmentApi = useApiGen(InvestmentControllerV4ApiFactory);

	return (
		<>
			<div className="flex flex-1 w-[calc(100%_-_162px)]">
				<AutoTooltip
					overrideColor={themeCSSVars.palette_N300}
					position="right"
					disabled={props.disableTooltip}
					trigger={({ innerRef }) => (
						<div className="inline-flex items-center gap-1 w-full">
							<ActionText
								classList="font-[weight:500] truncate items-center"
								onClick={() =>
									window.open(
										typedUrlForRoute("PortfolioDetails", {
											portfolioUid: row.investment?.uuid ?? row.ticker ?? "",
											tab: PortfolioDetailsTabs.COMPOSITION,
										}),
										"_blank",
									)
								}
								innerRef={innerRef}
							>
								{row.name ?? "-"}
							</ActionText>
							<svg
								width="12"
								height="12"
								viewBox="0 0 12 12"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								className="shrink-0"
							>
								<path
									d="M8 1.5H10.5V4"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M7 5L10.5 1.5"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M8.5 7.4375V9.625C8.5 10.1084 8.10844 10.5 7.625 10.5H2.375C1.89156 10.5 1.5 10.1084 1.5 9.625V4.375C1.5 3.89156 1.89156 3.5 2.375 3.5H4.5625"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</div>
					)}
				>
					<TooltipContent>
						<div className="w-[280px]">
							<ReactQueryWrapper
								queryKey={["portfolioExposure", row.investment?.uuid, row.investment?.action]}
								cacheTime={minutes(10)}
								staleTime={Infinity}
								queryFn={() =>
									axiosExtract(investmentApi.getExposureCompare([row.investment?.uuid as string])).then((x) => x[0])
								}
							>
								{({ macroAssetClassExposure }) => (
									<PortfolioExposureSummary
										compared={compared}
										composition={macroAssetClassExposure ?? []}
										onCompare={(e) => {
											e.stopPropagation();
											onCompare?.(compared);
										}}
										title={row.name ?? "-"}
									/>
								)}
							</ReactQueryWrapper>
						</div>
					</TooltipContent>
				</AutoTooltip>
			</div>
			<p className="font-semibold">{row.investment?.nofInstruments ?? 0} ins.</p>
		</>
	);
}

function WeightInput(props: {
	row: InstrumentEditorEntry;
	onCheckIsMinimumWeightValid(value: number | null): MaybePromise<void>;
	isInstrumentDeleted: boolean;
	externalWeight?: number | null;
	onCommitWeight(v: number | null): void;
	classList?: ClassList;
}) {
	const { row, isInstrumentDeleted, onCheckIsMinimumWeightValid, onCommitWeight, externalWeight } = props;

	const [isLoading, setIsLoading] = useState(false);
	const isMinWeightValid = useMemo(() => (externalWeight ?? 0) >= 0.01, [externalWeight]);
	return (
		<AutoTooltip
			overrideColor={themeCSSVars.palette_N300}
			disabled={isMinWeightValid || isInstrumentDeleted}
			position="left"
			trigger={({ innerRef }) => (
				<NullableNumberInput
					classList={props.classList}
					step={0.01}
					min={0}
					max={100}
					innerRef={innerRef}
					inputAppearance={{
						classList: {
							"text-right w-full": true,
							[`!border-[color:${themeCSSVars.palette_N600}]`]: isMinWeightValid === false,
						},
					}}
					disabled={isInstrumentDeleted || isLoading}
					size="x-small"
					value={externalWeight ?? null}
					onClick={(e) => e.stopPropagation()}
					onChange={(v) => {
						onCommitWeight(v);
						if (row.proxyOverwriteType === "PORTFOLIO_MIXED") {
							unpromisify(async () => {
								setIsLoading(true);
								await onCheckIsMinimumWeightValid(v);
								setIsLoading(false);
							})();
						}
					}}
					rightContent={
						isLoading ? <CircularProgressBar value="indeterminate" classList="h-3 w-3" /> : <Icon icon="Percentile" />
					}
					name="weigths"
					data-qualifier="CompositionEditor/Table/Column(Weight)/Input"
				/>
			)}
		>
			Requested minimum weight of 0.01
		</AutoTooltip>
	);
}

function findTagColor(value: string, tags: Array<{ value: string; color: string }>) {
	return tags.find((x) => x.value === value)?.color;
}

function TagsDropdown({
	options,
	disabled,
	onChange,
	value,
}: {
	options: Array<Tag>;
	value?: string;
	onChange?(value?: string): void;
	disabled?: boolean;
}) {
	const actions = useTagDropDownActions({ options, onChange });
	return (
		<DropdownMenu
			trigger={(params) =>
				value ? (
					<div
						className="rounded-xl px-2 py-0.5 flex gap-2 text-white bg-neutral-300 truncate"
						style={{
							backgroundColor: params.disabled ? themeCSSVars.palette_N200 : findTagColor(value, options),
						}}
					>
						<Button
							unstyled
							innerRef={params.innerRef}
							onClick={params.onClick}
							classList="font-semibold truncate"
							disabled={params.disabled}
						>
							{value ?? "Assign Tag"}
						</Button>
						<Button unstyled onClick={() => onChange?.()} disabled={params.disabled} classList="active:scale-90">
							<Icon icon="Close" size={18} />
						</Button>
					</div>
				) : (
					<Button
						unstyled
						classList={toClassName({
							"rounded-xl border-2 bg-white px-2 py-0.5 flex gap-2": true,
							[`!bg-[color:${themeCSSVars.palette_N200}] border-none`]: params.disabled,
						})}
						innerRef={params.innerRef}
						onClick={params.onClick}
						disabled={params.disabled}
					>
						<span
							className={toClassName({
								[`font-semibold text-[color:${themeCSSVars.palette_N600}]`]: true,
								[`!text-[color:${themeCSSVars.palette_N20}]`]: params.disabled,
							})}
						>
							Assign Tag
						</span>
						<Icon
							icon="Outline1"
							color={params.disabled ? themeCSSVars.palette_N20 : themeCSSVars.palette_N600}
							size={18}
						/>
					</Button>
				)
			}
			actions={actions}
			disabled={disabled}
			floatingAppearance={{ classList: "max-h-[215px]" }}
		/>
	);
}

function TagPill(
	props: Tag & {
		onClick?(): void;
	},
) {
	return (
		<Button
			unstyled
			classList={{
				"rounded-full h-fit px-4 py-0.5 text-white font-semibold": true,
				"active:scale-90": props.onClick !== undefined,
			}}
			style={{ backgroundColor: props.color }}
			onClick={props.onClick}
		>
			{props.value}
		</Button>
	);
}

export function useTagDropDownActions({
	options,
	onChange,
}: {
	options: Array<Tag>;
	onChange?(value?: string): void;
}): ActionWithOptionalGroup<string>[] {
	return useMemo(
		() =>
			options.map(
				(tag, index): ActionWithOptionalGroup<string> => ({
					children: (props) => (
						<div className="h-10 flex items-center hover:bg-slate-100 px-4" key={index}>
							<TagPill
								{...tag}
								onClick={() => {
									onChange?.(tag.value);
									props.onClose();
								}}
							/>
						</div>
					),
				}),
			),
		[onChange, options],
	);
}

export function useInstrumentCompositionColumns() {
	const { t } = useTranslation();
	const user = useUserValue();
	const { formatNumber } = useLocaleFormatters();

	return useMemo(
		() =>
			({
				name: (params?: {
					onCompare?(row: InstrumentEditorEntry, action?: "add" | "remove"): void;
					isRowCompared?(id: string): boolean;
					disableTooltip?: boolean;
				}) => ({
					header: t("TABLE.HEADERS.NAME"),
					content: (row, cellProps) => {
						if (row.proxyOverwriteType === "PORTFOLIO_MIXED") {
							return (
								<Row {...cellProps} justifyContent="space-between" alignItems="center" gap={8}>
									<InstumentNameAsPortfolioBlock
										row={row}
										disableTooltip={params?.disableTooltip}
										isRowCompared={params?.isRowCompared}
										onCompare={(compared) => params?.onCompare?.(row, compared ? "remove" : "add")}
									/>
								</Row>
							);
						}

						return row.name;
					},
					sortFn: builtInSortFnFor("instrument"),
					name: "instrument",
					minWidth: 460,
					maxWidth: 560,
				}),
				identifier: () => ({
					header: t("TABLE.HEADERS.IDENTIFIER"),
					content: (row, cellProps) => {
						return (
							<Row {...cellProps} justifyContent="end">
								<CopyableText {...cellProps} onClick={(e) => e.stopPropagation()} text={row.alias ?? ""} />
							</Row>
						);
					},
					name: "alias",
					sortFn: builtInSortFnFor("alias"),
				}),
				assetClass: () => ({
					header: t("TABLE.HEADERS.ASSET_CLASS"),
					content: (row) => row.assetClass ?? "-",
					sortFn: builtInSortFnFor("assetClass"),
					name: "assetClass",
				}),
				microAssetClass: () => ({
					header: t("TABLE.HEADERS.MICRO_ASSET_CLASS"),
					content: (row) => row.microAssetClass ?? "-",
					sortFn: builtInSortFnFor("microAssetClass"),
					name: "microAssetClass",
				}),
				weight: (params: {
					isInstrumentDeleted(rowId: string): boolean;
					onCheckIsMinimumWeightValid(row: InstrumentEditorEntry, value: number | null): MaybePromise<void>;
					onChangeWeight(rowId: string, v: number | null): void;
					weightProvider(rowId: string): number | null;
				}) => ({
					header: t("TABLE.HEADERS.WEIGHT"),
					name: "weight",
					align: "end",
					content: (row, cellProps) => (
						<Row {...cellProps} justifyContent="end">
							<WeightInput
								classList="grow"
								isInstrumentDeleted={params.isInstrumentDeleted(row.rowId)}
								row={row}
								onCheckIsMinimumWeightValid={(v) => params.onCheckIsMinimumWeightValid(row, v)}
								externalWeight={row.weight}
								onCommitWeight={(v) => params.onChangeWeight(row.rowId, v)}
							/>
						</Row>
					),
				}),
				currentWeight: () => ({
					header: t("TABLE.HEADERS.CURRENT_WEIGHT"),
					name: "previousWeight",
					align: "end",
					cellClassList: "tabular-nums",
					content: (row) => (row.previousWeight ? `${formatNumber(row.previousWeight)}%` : ""),
					sortFn: builtInSortFnFor("previousWeight"),
				}),
				enhancedWeight: () => ({
					header: t("TABLE.HEADERS.ENHANCED_WEIGHT"),
					name: "enhancedWeight",
					align: "end",
					cellClassList: "tabular-nums",
					content: (row) => (row.weight ? `${formatNumber(row.weight)}%` : ""),
					sortFn: builtInSortFnFor("weight"),
				}),
				differenceWeight: () => ({
					header: t("TABLE.HEADERS.DIFFERENCE"),
					name: "difference",
					align: "end",
					cellClassList: "tabular-nums",
					content: (row, cellProps) => {
						const deltaWeight = Number((row.weight ?? 0) - (row.previousWeight ?? 0)).toFixed(2);
						return (
							<TableDataCell {...cellProps}>
								<InfoDelta diff={Number(deltaWeight) ?? 0} enh={row.weight ?? 0} />
							</TableDataCell>
						);
					},
					width: 110,
					sortFn: (rowa, rowb) => {
						const deltaA = Math.round((rowa.weight ?? 0) - (rowa.previousWeight ?? 0)).toFixed(2);
						const deltaB = Math.round((rowb.weight ?? 0) - (rowb.previousWeight ?? 0)).toFixed(2);

						if (deltaA > deltaB) {
							return 1;
						}

						if (deltaA < deltaB) {
							return -1;
						}

						return 0;
					},
				}),
				tag: (params: {
					options: Array<Tag>;
					disabled?: boolean;
					onChange?(row: InstrumentEditorEntry, value?: string): void;
				}) => ({
					header: t("TABLE.HEADERS.TAG"),
					name: "tag",
					content: (row, cellProps) => {
						const onChange = params.onChange;
						if (!onChange) {
							const currentTag = params.options.find((x) => x.value === row.tagLabel);
							if (!currentTag) {
								return "";
							}
							return (
								<Row {...cellProps} alignItems="center">
									<TagBadge color={currentTag?.color}>{currentTag?.label}</TagBadge>
								</Row>
							);
						}

						return (
							<Row {...cellProps} alignItems="center" onClick={(e) => e.stopPropagation()}>
								<TagsDropdown
									options={params.options}
									value={row.tagLabel}
									disabled={params.disabled}
									onChange={(tag) => params.onChange?.(row, tag)}
								/>
							</Row>
						);
					},
					minWidth: 150,
				}),
				score: (params: {
					editHeader?: boolean;
					uuid?: string;
					disabled?: boolean;
					onChange?(row: InstrumentEditorEntry, value: number | null): void;
				}) => ({
					header: (headerProps) =>
						params.editHeader && params.uuid ? (
							<TableHeadCell {...headerProps}>
								<CustomLabelsEditor labelKey={`${params.uuid}_score1`} fallback={t("SCORE")} mode="view" />
							</TableHeadCell>
						) : (
							t("TABLE.HEADERS.SCORE")
						),
					name: "score",
					content: (row, cellProps) => {
						const onChange = params.onChange;
						if (!onChange) {
							return row.score ? `${formatNumber(row.score)}` : "";
						}
						return (
							<Row justifyContent="center" {...cellProps} childrenGrow={1}>
								<NullableNumberInput
									min={0}
									max={100}
									step={0.01}
									inputAppearance={{ classList: "text-right" }}
									disabled={params.disabled}
									size="x-small"
									value={row.score ?? null}
									onChange={(v) => onChange(row, v)}
									name="score"
									onClick={(e) => e.stopPropagation()}
									data-qualifier="CompositionEditor/Table/Column(Score)/Input"
								/>
							</Row>
						);
					},
					hidden: !hasAccess(user, { requiredService: "CUSTOM_QUALITIES" }),
					minWidth: 150,
				}),
				deleteRestore: (params: {
					deleted: Set<string>;
					onChange?(row: InstrumentEditorEntry, deleted: Set<string>): void;
				}) => ({
					name: "deleteRestore",
					header: "",
					align: "end",
					content: (row, cellProps) => (
						<Row {...cellProps} justifyContent="end" alignItems="center" onClick={(e) => e.stopPropagation()}>
							<CommonItemActions.DeleteRestore
								deleted={params.deleted}
								item={row.rowId ?? "-"}
								onDeletedChange={(newSet) => params.onChange?.(row, newSet)}
							/>
						</Row>
					),
					width: 48,
				}),
				delete: (params?: { onDelete?(rowId: string): void }) => ({
					name: "delete",
					header: "",
					align: "end" as const,
					content: (row, cellProps) => (
						<Row {...cellProps} justifyContent="end" alignItems="center" onClick={(e) => e.stopPropagation()}>
							<CommonItemActions.Delete onDelete={() => params?.onDelete?.(row.rowId)} />
						</Row>
					),
					width: 48,
				}),
			}) satisfies Record<string, (...args: any[]) => TableColumn<InstrumentEditorEntry>>,
		[formatNumber, t, user],
	);
}

export function useGroupedInstrumentCompositionColumns() {
	const instrumentCompositionColumns = useInstrumentCompositionColumns();
	const { formatNumber } = useLocaleFormatters();
	return useMemo(
		() =>
			({
				name: (params?: {
					onCompare?(row: InstrumentEditorEntry, action?: "add" | "remove"): void;
					isRowCompared?(id: string): boolean;
				}) => {
					const column = instrumentCompositionColumns.name(params);
					return {
						header: column.header,
						content: column.content,
						name: column.name,
						groupContentTextType: "Body/M/Bold",
						groupContent: (groupedRow) => groupedRow.assetClass,
						sortFn: builtInSortFnFor("assetClass"),
						minWidth: 460,
						maxWidth: 560,
					};
				},
				identifier: () => {
					const column = instrumentCompositionColumns.identifier();
					return {
						header: column.header,
						content: column.content,
						name: column.name,
						groupContent: () => "",
						orderable: false,
						width: 128,
					};
				},
				assetClass: () => {
					const column = instrumentCompositionColumns.assetClass();
					return {
						header: column.header,
						content: column.content,
						name: column.name,
						groupContentTextType: "Body/M/Bold",
						groupContent: () => "",
						orderable: false,
						width: 296,
					};
				},
				microAssetClass: () => {
					const column = instrumentCompositionColumns.microAssetClass();
					return {
						header: column.header,
						content: column.content,
						name: column.name,
						groupContentTextType: "Body/M/Bold",
						groupContent: () => "",
						orderable: false,
						width: 240,
					};
				},
				weight: (params: {
					isInstrumentDeleted(rowId: string): boolean;
					onCheckIsMinimumWeightValid(row: InstrumentEditorEntry, value: number | null): MaybePromise<void>;
					onChangeWeight(rowId: string, v: number | null): void;
					weightProvider(rowId: string): number | null;
				}) => {
					const column = instrumentCompositionColumns.weight(params);
					return {
						header: column.header,
						content: column.content,
						name: column.name,
						groupContentTextType: "Body/M/Bold",
						groupContent: (group) => `${formatNumber(group.weight, { minDecimalPlaces: 0, maxDecimalPlaces: 2 })}%`,
						sortFn: builtInSortFnFor("weight"),
						minWidth: 104,
						maxWidth: 200,
						align: "end",
						cellClassList: "tabular-nums",
					};
				},
				currentWeight: () => {
					const column = instrumentCompositionColumns.currentWeight();
					return {
						header: column.header,
						content: column.content,
						name: column.name,
						groupContentTextType: "Body/M/Bold",
						groupContent: (group) =>
							`${formatNumber(group.previousWeight, { minDecimalPlaces: 0, maxDecimalPlaces: 2 })}%`,
						orderable: false,
						align: "end",
						cellClassList: "tabular-nums",
						width: 134,
					};
				},
				enhancedWeight: () => {
					const column = instrumentCompositionColumns.enhancedWeight();
					return {
						header: column.header,
						content: column.content,
						name: column.name,
						groupContentTextType: "Body/M/Bold",
						groupContent: (group) =>
							`${formatNumber(group.enhancedWeight, { minDecimalPlaces: 0, maxDecimalPlaces: 2 })}%`,
						orderable: false,
						align: "end",
						cellClassList: "tabular-nums",
						width: 134,
					};
				},
				differenceWeight: () => {
					const column = instrumentCompositionColumns.differenceWeight();
					return {
						header: column.header,
						content: column.content,
						name: column.name,
						groupContentTextType: "Body/M/Bold",
						groupContent: (group, cellProps) => {
							return (
								<Row {...cellProps} alignItems="center">
									<InfoDelta diff={group.differenceWeight ?? 0} enh={group.previousWeight ?? 0} />
								</Row>
							);
						},
						sortFn: builtInSortFnFor("differenceWeight"),
						align: "end",
						width: 140,
						cellClassList: "tabular-nums",
					};
				},
				tag: (params: {
					options: Array<Tag>;
					disabled?: boolean;
					onChange?(row: InstrumentEditorEntry, value?: string): void;
				}) => {
					const column = instrumentCompositionColumns.tag(params);
					return {
						header: column.header,
						content: column.content,
						name: column.name,
						groupContentTextType: "Body/M/Bold",
						groupContent: () => "",
						orderable: false,
						minWidth: 140,
						maxWidth: 320,
					};
				},
				score: (params: {
					editHeader?: boolean;
					uuid?: string;
					disabled?: boolean;
					onChange?(row: InstrumentEditorEntry, value: number | null): void;
				}) => {
					const column = instrumentCompositionColumns.score(params);
					return {
						header: column.header,
						content: column.content,
						name: column.name,
						groupContentTextType: "Body/M/Bold",
						groupContent: () => "",
						orderable: false,
						minWidth: 140,
						maxWidth: 320,
					};
				},
				deleteRestore: (params: {
					deleted: Set<string>;
					onChange?(row: InstrumentEditorEntry, deleted: Set<string>): void;
				}) => {
					const column = instrumentCompositionColumns.deleteRestore(params);
					return {
						header: column.header,
						content: column.content,
						name: column.name,
						groupContentTextType: "Body/M/Bold",
						groupContent: () => "",
						orderable: false,
						width: column.width,
					};
				},
				delete: (params?: { onDelete?(rowId: string): void }) => {
					const column = instrumentCompositionColumns.delete(params);
					return {
						header: column.header,
						content: column.content,
						name: column.name,
						groupContentTextType: "Body/M/Bold",
						groupContent: () => "",
						orderable: false,
						width: column.width,
					};
				},
			}) satisfies Record<string, (...args: any[]) => TableWithGroupsColumn<GroupedInstrumentEditorEntry>>,
		[formatNumber, instrumentCompositionColumns],
	);
}
