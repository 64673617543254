import { Banner } from "@mdotm/mdotui/components";

function InstrumentLimitReachedBanner({ limit }: { limit?: number }): JSX.Element {
	return (
		<Banner severity="warning" title="Instrument limit reached" classList="mb-4">
			You have exceeded the maximum number of instruments allowed for your portfolio ({">"}{" "}
			<strong>{limit ?? 0}</strong>). Please remove instruments or one or more portfolios from your mixed portfolio to
			stay within this limit of {limit} instruments.
		</Banner>
	);
}

export default InstrumentLimitReachedBanner;
