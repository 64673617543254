import type { HmmRegion } from "$root/api/api-gen";
import { HmmControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import GraphLegend from "$root/components/GraphLegend";
import ColoredRectangle from "$root/components/icons/ColoredRectangle";
import { IconWalls } from "$root/components/IconWall";
import { formatDate } from "$root/localization/formatters";
import { useLocaleFormatters } from "$root/localization/hooks";
import { Highcharts } from "$root/third-party-integrations/highcharts-with-modules";
import type { ContextContent } from "$root/utils";
import { useQueryNoRefetch, withContext } from "$root/utils";
import { RegionContext } from "$root/widgets-architecture/contexts/region";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import { Button, IconTooltip } from "@mdotm/mdotui/components";
import Color from "color";
import type { Chart } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { forwardRef, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

type CustomizedChartProps = {
	series: Array<{
		name: string;
		legendName?: string;
		color: string;
		yValues: number[];
		type: "percentage" | "value";
		compare: boolean;
	}>;
	xTimestamps: number[];
	region: HmmRegion;
	bands: any;
};

const CustomizedChart = forwardRef<{ chart: Chart }, CustomizedChartProps>(function CustomizedChartRef(
	{ series, xTimestamps, bands, region },
	ref,
) {
	const { t } = useTranslation();

	const { formatNumber } = useLocaleFormatters();

	const [visibleSeriesIndices, setVisibleSeriesIndices] = useState(() => new Array(series.length).fill(true));
	const options = useMemo(() => {
		let selectedSeriesIndex: number | null = null;
		return {
			chart: {
				style: {
					fontFamily: "Gotham,sans-serif",
				},
				panning: {
					enabled: false,
				},
			},
			credits: {
				enabled: false,
			},
			legend: { enabled: false },
			title: { text: undefined },
			subtitle: { text: undefined },
			exporting: {
				enabled: true,
				chartOptions: {
					title: {
						text: t("EVOLUTION_OF_REGIME_PROBABILITIES.title"),
					},
					subtitle: {
						text: `Data Exported in ${new Date().getUTCFullYear()}-${
							new Date().getUTCMonth() + 1
						}-${new Date().getUTCDate()}`,
					},
					chart: {
						margin: 40,
						marginTop: 80,
						marginLeft: 80,
						marginBottom: 80,
					},
					scrollbar: {
						enabled: false,
					},
				},
				// buttons: {
				// 	contextButton: {
				// 		enabled: false,
				// 	},
				// 	exportButton: {
				// 		enabled: false,
				// 	},
				// },
				buttons: {
					contextButton: {
						menuItems: ["downloadJPEG", "downloadPDF"],
					},
				},
				allowHTML: true,
				printMaxWidth: 1080,
				filename: t("PAST_MARKET_REGIMES.title"),
				sourceHeight: 400,
				sourceWidth: 1440,
			},
			xAxis: {
				type: "datetime",
				endOnTick: false,
				startOnTick: false,
				min: xTimestamps[0],
				max: xTimestamps[xTimestamps.length - 1],
				minRange: 1000 * 60 * 60 * 24,
				gridLineWidth: 0,
				plotBands: bands,
			},
			yAxis: [
				{
					opposite: true,
					gridLineWidth: 0,
					min: -0.2,
					tickInterval: 0.2,
					alignTicks: false,
					startOnTick: false,
					labels: {
						align: "center",
						formatter: ({ value }: { value: number }) => formatNumber(Math.floor(value * 100), 0) + "%",
					},
					title: {
						enabled: false,
					},
				},
				{
					opposite: false,
					gridLineWidth: 0,
					tickInterval: 1,
					//min: 0,
					alignTicks: false,
					startOnTick: false,
					labels: {
						align: "center",
						formatter: ({ value }: { value: number }) =>
							formatNumber(Math.floor(value) / 100, { maxDecimalPlaces: 2, minDecimalPlaces: 2 }),
					},
					title: {
						enabled: false,
					},
				},
			],
			boost: {
				debug: {
					timeRendering: true,
				},
				useGPUTranslations: true,
				seriesThreshold: 1,
			},
			tooltip: {
				split: false,
				shared: true,
				useHTML: true,
				distance: 72,
				backgroundColor: "#FFFFFF",
				borderColor: "#FFFFFF",
				borderRadius: 8,
				shadow: {
					color: "#4a4a4a",
				},
				style: {
					padding: "0",
					color: "#656d78",
					fontSize: "12px",
				},
				formatter(this: {
					points: {
						x: number;
						y: number;
						point: {
							change: number;
						};
						series: Highcharts.Series & { options: { marker: { fillColor: string } } };
					}[];
					x: number;
					y: number;
				}) {
					if (selectedSeriesIndex === null) {
						return "";
					}

					// The array of points that comes from Highcharts contains only the visible series,
					// therefore we have to subtract from the selectedIndex all invisible series
					// that come before it, or, equivalently, count up to the selectedIndex
					// considering only the visible series to get the actual index.
					let actualIndex = 0;
					for (let i = 0; i < selectedSeriesIndex; i++) {
						if (visibleSeriesIndices[i]) {
							actualIndex++;
						}
					}

					if (!this.points[actualIndex]) {
						return "";
					}

					const point = this.points[actualIndex];
					const changeLabel = `${point.point.change - 100 > 0 ? "+" : ""}${formatNumber(point.point.change - 100, {
						maxDecimalPlaces: 2,
						minDecimalPlaces: 0,
					})}%`;
					const isPercentageSeries = (point.series.yAxis.userOptions as { index: number }).index === 0;

					const content = `<div style="min-width: 12.5rem">
							<div style="text-align: center;
								border-radius: 0.25rem;
								background-color: ${point.series.options.marker.fillColor};
								color: white;
								font-family: Gotham, sans-serif;
								font-weight: bold;
								line-height: 1.25rem;
								padding: 0.2rem 0;">
								<div>${point.series.name}</div>
							</div>
							<table style="width: 100%; padding-top: 0.1rem">
								<tbody>
									<tr>
										<td>
											${t("DATE")}
										</td>
										<td
											style="text-align: right; font-weight: 500; border-bottom: solid 1px transparent; padding: 5px 0 0 0; color: black"
										>
											${formatDate(this.x)}
										</td>
									</tr>
									<tr>
										<td style="border-bottom: solid 1px transparent; padding: 5px 8px 0 0">
											${point.series.name}
										</td>
										<td
											style="text-align: right; font-weight: 500; border-bottom: solid 1px transparent; padding: 5px 0 0 0; color: black"
										>
											${
												point.point.change
													? changeLabel
													: isPercentageSeries
													  ? formatNumber(point.y * 100, { maxDecimalPlaces: 2, minDecimalPlaces: 0 }) + "%"
													  : formatNumber(point.y, { maxDecimalPlaces: 2, minDecimalPlaces: 0 })
											}
										</td>
									</tr>
								</tbody>
							</table>
						</div>`;
					return content;
				},
			},
			plotOptions: {
				series: {
					showInNavigator: true,
					label: {
						connectorAllowed: false,
					},
					marker: {
						lineWidth: 1,
						symbol: "dot",
					},
					events: {
						mouseOver(this: { index: number }) {
							selectedSeriesIndex = this.index;
						},
					},
				},
			},
			series: series.map((singleSeries, i) => {
				const baseColor = singleSeries.color;
				const transparentColor = Color(singleSeries.color).alpha(0.35).toString();
				const compareSettings = singleSeries.compare
					? { compare: "percent", compareBase: 100, showInNavigator: true }
					: {};
				return {
					...compareSettings,
					lineColor: baseColor,
					color: transparentColor,
					marker: {
						color: baseColor,
						fillColor: baseColor,
					},
					name: singleSeries.name,
					data: singleSeries.yValues.map((y, yIndex) => [xTimestamps[yIndex], y]),
					/* pointStart: xTimestamps[0],
					pointInterval: xTimestamps.length, */
					visible: visibleSeriesIndices[i],
					yAxis: singleSeries.type === "percentage" ? 0 : 1,
				};
			}),
		};
	}, [t, xTimestamps, bands, series, visibleSeriesIndices, formatNumber]);

	return (
		<div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
			<div style={{ borderRadius: 4, borderColor: "#eeeef1", borderWidth: 2, borderStyle: "solid", flex: 1 }}>
				<HighchartsReact
					containerProps={{ style: { height: "100%" } }}
					highcharts={Highcharts}
					constructorType="stockChart"
					options={options}
					ref={ref as any}
				/>
			</div>
			<div>
				<div style={{ flex: 0 }}>
					<div className="flex">
						<GraphLegend style={{ position: "static", padding: "1rem 0", margin: 0 }}>
							{series.map((singleSeries, i) => (
								<div key={singleSeries.color} className="legend-container return-analysis light more-space book">
									<Button
										unstyled
										classList="flex items-center"
										onClick={() =>
											setVisibleSeriesIndices((currentSelection) => {
												const newSelection = [...currentSelection];
												newSelection[i] = !newSelection[i];
												return newSelection;
											})
										}
									>
										<ColoredRectangle variant="vertical" color={singleSeries.color} />
										<span style={{ textDecoration: !visibleSeriesIndices[i] ? "line-through" : "" }}>
											{singleSeries.legendName ?? singleSeries.name}
										</span>
									</Button>
								</div>
							))}
							<IconTooltip
								iconSize={16}
								severity="success"
								overrideIcon="Information"
								position="bottom"
								align="startToStart"
							>
								{t(`PAST_MARKET_REGIMES.LEGEND_TOOLTIP.${region}`)}
							</IconTooltip>
						</GraphLegend>
					</div>
				</div>
			</div>
		</div>
	);
});

type TBandsColors = {
	[key: number]: string;
};

const PastMarketRegimesBlock = (props: ContextContent<typeof RegionContext>) => {
	const { t } = useTranslation();
	const { region } = props;

	function fromObjToArray<T>(obj: { [x: string]: T }) {
		const dataArray = Object.keys(obj).map((key) => {
			return obj[key];
		});
		return dataArray;
	}

	const DateFormatter = (yyyymmdd: string) => {
		const date = new Date(`${yyyymmdd.slice(0, 4)}-${yyyymmdd.slice(4, 6)}-${yyyymmdd.slice(-2)}T12:00:00Z`).getTime();
		return date;
	};

	const bandsMaker = (obj: any) => {
		const bandsColors: TBandsColors = {
			1: "rgba(76, 176, 156, 0.5)",
			2: "rgba(255, 191, 0, 0.5)",
			3: "rgba(234, 64, 31, 0.5)",
		};
		const arrayData = Object.entries(obj);
		const bands: any = [];
		let startbandDate = DateFormatter(arrayData[0][0]);
		arrayData.forEach((curr, index) => {
			if (index < arrayData.length - 1) {
				if (curr[1] !== arrayData[index + 1][1]) {
					bands.push({
						color: bandsColors[curr[1] as keyof TBandsColors],
						from: startbandDate,
						to: DateFormatter(curr[0]),
					});
					startbandDate = DateFormatter(curr[0]);
				}
			} else {
				bands.push({
					color: bandsColors[curr[1] as keyof TBandsColors],
					from: startbandDate,
					to: DateFormatter(curr[0]),
				});
			}
		});
		return bands;
	};

	const hmmApi = useApiGen(HmmControllerApiFactory);

	const procssPerfomanceName = (performanceRegion: HmmRegion, performanceName?: string): string => {
		if (!performanceName) {
			return "Performance";
		}
		if (performanceRegion === "EU") {
			return performanceName.replace("EU", "Europe");
		}
		if (performanceRegion === "US") {
			return performanceName.replace("US", "USA");
		}
		if (performanceRegion === "JP") {
			return performanceName.replace("JP", "Japan");
		}
		return "Performance";
	};

	const { isLoading, isError, isFetching, data } = useQueryNoRefetch(["queryHmmPastMarketRegime", region], {
		queryFn: async () => {
			const { data: PastMarketRegime } = await hmmApi.retrievePastMarketRegime(region);

			const xTimestamps = Object.keys(PastMarketRegime.performance?.data ?? []).map((el) => {
				return DateFormatter(el);
			}); // No control if dates are not equal for all series
			const performanceSeries = fromObjToArray(PastMarketRegime.performance?.data ?? {});
			const rollingReturn = fromObjToArray(PastMarketRegime.rollingReturn ?? {});
			const rollingVolatility = fromObjToArray(PastMarketRegime.rollingVolatility ?? {});
			const regimes = PastMarketRegime.regimes ?? {};
			const performanceName = procssPerfomanceName(region, PastMarketRegime.performance?.name);

			const dataSeries: {
				name: string;
				legendName?: string;
				color: string;
				yValues: number[];
				type: "percentage" | "value";
				compare: boolean;
			}[] = [
				{
					color: "#7030a0",
					compare: false,
					name: t("PAST_MARKET_REGIMES.rolling_volatility_tooltip"),
					legendName: t("PAST_MARKET_REGIMES.rolling_volatility_legend"),
					yValues: rollingVolatility,
					type: "percentage",
				},
				{
					color: "#2f3541",
					compare: false,
					name: t("PAST_MARKET_REGIMES.rolling_return_tooltip"),
					legendName: t("PAST_MARKET_REGIMES.rolling_return_legend"),
					yValues: rollingReturn,
					type: "percentage",
				},
				{
					color: "#00aeef",
					compare: true,
					name: performanceName,
					legendName: performanceName,
					yValues: performanceSeries,
					type: "value",
				},
			];

			const PastMarketRegimeData = {
				xTimestamps,
				series: dataSeries,
				regimes: bandsMaker(regimes),
			};

			return PastMarketRegimeData;
		},
	});

	// const randomValues = useRef(range(0, 1000).map(() => Math.random()));
	// const interporableTimestamps = range(2006, 2024).map((year) => new Date(`${year}-01-01T12:00:00.000Z`).getTime());
	// const pointsPerSeries = randomValues.current.length;
	// const xTimestamps = range(0, pointsPerSeries).map(
	// 	(_, i) =>
	// 		interporableTimestamps[0] +
	// 		(i * (interporableTimestamps[interporableTimestamps.length - 1] - interporableTimestamps[0])) / pointsPerSeries,
	// );

	const chartRef = useRef<{ chart: Chart } | null>(null);
	// const dropdownMenuAnchorRef = useRef<HTMLDivElement | null>(null);

	// maybe this should probably be preferred considering
	// that the default highcharts XLS is really just
	// an HTML document with an xls extension.
	/* const handleDownloadXLSX = useCallback(() => {
		const table = document.createElement("table");

		table.style.display = "none";

		const thead = document.createElement("thead");

		const headTr = document.createElement("tr");

		const dateTh = document.createElement("th");
		dateTh.textContent = t("DATE");
		headTr.appendChild(dateTh);

		series.forEach((singleSeries) => {
			const th = document.createElement("th");
			th.textContent = singleSeries.legendName;
			headTr.appendChild(th);
		});

		thead.appendChild(headTr);

		table.appendChild(thead);

		const tbody = document.createElement("tbody");

		series.forEach((singleSeries) => {
			for (let i = 0; i < singleSeries.yValues.length; i++) {
				const tr = document.createElement("tr");
				const dateTd = document.createElement("td");
				dateTd.textContent = new Date(xTimestamps[i]).toString();
				tr.appendChild(dateTd);
				for (let j = 0; j < series.length; j++) {
					const valueTd = document.createElement("td");
					valueTd.textContent = String(Math.floor(series[j].yValues[i] * 100 * 100) / 100) + "%";
					tr.appendChild(valueTd);
				}

				tbody.appendChild(tr);
			}
		});

		table.appendChild(tbody);

		document.body.appendChild(table);

		let worksheet = XLSX.utils.table_to_sheet(table);
		const new_workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(new_workbook, worksheet, "Sheet1");
		const result = XLSX.writeFile(new_workbook, "output.xlsx");

		document.body.removeChild(table);

		return result;
	}, [series, t, xTimestamps]); */

	useWidgetOptions(
		() => ({
			isDraggable: false,
			actionHeader: <></>,
			title: t("PAST_MARKET_REGIMES.title"),
		}),
		[t],
	);

	return (
		<>
			<div style={{ paddingBottom: "1rem" }}>{t("PAST_MARKET_REGIMES.description")}</div>
			{isError ? (
				<IconWalls.ErrorData />
			) : isLoading || isFetching ? (
				<IconWalls.LoadingData />
			) : (
				<CustomizedChart
					ref={chartRef}
					xTimestamps={data.xTimestamps}
					series={data.series}
					bands={data.regimes}
					region={region}
				/>
			)}
		</>
	);
};

export default withContext(RegionContext)(PastMarketRegimesBlock);
