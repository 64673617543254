import type { InvestmentBenchmarkDTO, InvestmentBenchmarkDTOBenchmarkTypeEnum, ReviewTicker } from "$root/api/api-gen";
import { EntityEditorControllerApiFactory } from "$root/api/api-gen";
import { runWithErrorReporting } from "$root/api/error-reporting/report";
import { useApiGen } from "$root/api/hooks";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import type { MinimumDialogProps } from "$root/components/spawnable/type";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { objectTextSearchMatchFns, ToastableError, useQueryNoRefetch } from "$root/utils";
import type { OrderBy, TableColumn } from "@mdotm/mdotui/components";
import {
	BaseTable,
	Button,
	Controller,
	Dialog,
	DialogFooter,
	DialogHeader,
	sortRows,
	SubmitButton,
	TextInput,
	useSelectableTableColumn,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { useDebouncedSearch, useSearchable } from "@mdotm/mdotui/headless";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, spawn } from "@mdotm/mdotui/react-extensions";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

type BenchmarkTemplateDialogProps = {
	onSubmitAsync?(composition: ReviewTicker[]): MaybePromise<void>;
} & MinimumDialogProps;

const mappedBenchmarkType: Record<InvestmentBenchmarkDTOBenchmarkTypeEnum, string> = {
	CUSTOM_BENCHMARK: "Custom benchmark",
	REFERENCE_INVESTMENT: "Target portfolio",
	STANDARD_BENCHMARK: "Standard benchmark",
	CURRENT_PORTFOLIO: "Current portfolio",
};

function BenchmarkTemplateDialog(props: BenchmarkTemplateDialogProps) {
	const { t } = useTranslation();

	const { show, onAnimationStateChange, onClose, onSubmitAsync } = props;
	const editorApi = useApiGen(EntityEditorControllerApiFactory);

	const query = useQueryNoRefetch(["queryBenchmarkIndicies"], {
		queryFn: async () => {
			const { availableCurrencies, availablePrimaryBenchmarks } = await axiosExtract(
				editorApi.getEditorNewSelectableMainInfo(),
			);

			return { availableCurrencies, availablePrimaryBenchmarks };
		},
	});

	const { availablePrimaryBenchmarks } = query.data ?? {};
	const { debouncedNormalizedQuery, query: search, setQuery: setSearch } = useDebouncedSearch("", 10);
	const { filtered } = useSearchable({
		collection: availablePrimaryBenchmarks ?? [],
		query: debouncedNormalizedQuery,
		matchFn: (row, q) => objectTextSearchMatchFns.keyword(row, q),
	});

	const {
		column: checkBoxColumn,
		rowClassList,
		toggle,
		multiSelectCtx,
	} = useSelectableTableColumn({
		filteredRows: filtered,
		rows: availablePrimaryBenchmarks ?? [],
		selectBy: ({ benchmarkIdentifier }) => benchmarkIdentifier ?? "",
		mode: "radio",
	});

	const columns = useMemo<TableColumn<InvestmentBenchmarkDTO>[]>(
		() => [
			checkBoxColumn,
			{
				header: "Name",
				content: (row) => row.benchmarkName,
				sortFn: builtInSortFnFor("benchmarkIdentifier"),
				name: "benchmarkIdentifier",
				relativeWidth: 0.8,
			},
			{
				header: "Type",
				content: (row) => (row.benchmarkType ? mappedBenchmarkType[row.benchmarkType] : "-"),
				sortFn: builtInSortFnFor("benchmarkType"),
				name: "benchmarkType",
				relativeWidth: 0.2,
			},
		],
		[checkBoxColumn],
	);

	const getBenchmarkCompositionById = useCallback(
		(selectedBenchmark: InvestmentBenchmarkDTO | undefined): Promise<ReviewTicker[] | undefined> => {
			return runWithErrorReporting(
				async () => {
					try {
						if (selectedBenchmark?.benchmarkIdentifier === undefined || selectedBenchmark === undefined) {
							throw new Error("unable to find an identifier");
						}
						const { composition } = await axiosExtract(
							editorApi.getBenchmarkInstruments(selectedBenchmark.benchmarkIdentifier),
						);

						return composition;
					} catch (error) {
						throw new ToastableError("Unable to load the selected benchmark", { cause: error, icon: "Portfolio" });
					}
				},
				{
					area: "benchmark",
					attemptedOperation: {
						message: "try to load benchmark composition",
						payload: JSON.stringify({ selectedBenchmark }),
					},
				},
			);
		},
		[editorApi],
	);

	return (
		<Dialog
			size="xxlarge"
			show={show}
			onClose={onClose}
			header={<DialogHeader>Select benchmark template</DialogHeader>}
			onAnimationStateChange={onAnimationStateChange}
			onSubmitAsync={async () => {
				const benchmarkIdentifier = multiSelectCtx.selection.first();
				if (benchmarkIdentifier) {
					const selectedBenchmark = availablePrimaryBenchmarks?.find(
						(x) => x.benchmarkIdentifier === benchmarkIdentifier,
					);
					const composition = await getBenchmarkCompositionById(selectedBenchmark);
					await onSubmitAsync?.(composition ?? []);
				}
			}}
			footer={
				<DialogFooter
					primaryAction={<SubmitButton disabled={query.isFetching}>{t("BUTTON.CONFIRM")}</SubmitButton>}
					neutralAction={
						<Button palette="tertiary" onClick={onClose}>
							{t("BUTTON.CANCEL")}
						</Button>
					}
				/>
			}
		>
			<div className="mb-4">
				<TextInput placeholder="Search a benchmark" classList="max-w-[280px]" value={search} onChangeText={setSearch} />
			</div>
			<ReactQueryWrapperBase query={query}>
				{() => (
					<Controller value={defaultReportOrderBy}>
						{({ value: orderBy, onChange: onOrderByChange }) => (
							<BaseTable
								rows={sortRows({ rows: filtered, columns, orderByArr: orderBy })}
								columns={columns}
								orderBy={orderBy}
								onOrderByChange={onOrderByChange}
								onRowClick={(x) => toggle(x.benchmarkIdentifier!)}
								palette="uniform"
								classList="h-[412px]"
								rowClassList={rowClassList}
							/>
						)}
					</Controller>
				)}
			</ReactQueryWrapperBase>
		</Dialog>
	);
}

const defaultReportOrderBy: Array<OrderBy<"name">> = [{ columnName: "name", direction: "asc" }];

export type spawnBenchmarkTemplateDialogProps = Omit<BenchmarkTemplateDialogProps, "show" | "onClose">;
export function spawnBenchmarkTemplateDialog(props: spawnBenchmarkTemplateDialogProps): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ show, resolve, onHidden }) => (
			<BenchmarkTemplateDialog
				{...props}
				show={show}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
				onClose={() => resolve()}
				onSubmitAsync={async (payload) => {
					await props.onSubmitAsync?.(payload);
					resolve();
				}}
			/>
		)),
	);
}
