import type {
	EditorSaveEditOrReviewRequestEditPolicyEnum,
	InvestmentSummary,
	ReviewTicker
} from "$root/api/api-gen";
import {
	EditorSaveEditOrReviewRequestPortfolioSavingModeEnum,
	EntityEditorControllerApiFactory,
	InvestmentReportsControllerApiFactory,
} from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import { hasAccess } from "$root/components/AuthorizationGuard";
import { useCrumbs } from "$root/components/Crumbs/useCrumbs";
import { LeavePrompt } from "$root/components/LeavePrompt";
import { PageHeader } from "$root/components/PageHeader";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { MinimumDialogProps } from "$root/components/spawnable/type";
import { defaultTagLabels, labelToTag } from "$root/components/tags/shared";
import type { CompareDataItem } from "$root/functional-areas/compare-portfolio/CompareOverlay";
import { CompareOverlay } from "$root/functional-areas/compare-portfolio/CompareOverlay";
import {
	INVESTMENT_INSTRUMENT_LIMIT,
	MIN_NUMBER_OF_INSTRUMENTS,
	MIN_NUMBER_OF_INVESTMENT_INSTRUMENTS,
} from "$root/functional-areas/const";
import type { InstrumentEditorBuilderProps, Tag } from "$root/functional-areas/instruments-editor/builder";
import {
	covertInstrumentEditorToReviewTicker,
	instrumentEntryKey,
	retrieveInvestmentData,
	retrieveUserInstrumentsClassifications,
	useInstrumentEditorBuilder,
} from "$root/functional-areas/instruments-editor/builder";
import type { InstrumentEditorEntry } from "$root/functional-areas/instruments-editor/const";
import InstrumentCompositionEditor from "$root/functional-areas/instruments-editor/instrumentEditor";
import ExposureSankeyBlock from "$root/functional-areas/instruments-editor/InstrumentExposureSankey";
import { spawnOverridePortfolioDialog } from "$root/functional-areas/instruments-editor/spawn/portfolio-template";
import { useUserValue } from "$root/functional-areas/user";
import { platformToast } from "$root/notification-system/toast";
import { manualSaveEditFactory } from "$root/pages/ManualCreation/handler";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { FormController } from "$root/third-party-integrations/react-hook-form";
import { useQueryNoRefetch, useSearchParams, valueByPath } from "$root/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import type { DataAttributesProps } from "@mdotm/mdotui/components";
import {
	AsyncButton,
	Button,
	Dialog,
	DialogFooter,
	DialogHeader,
	FormField,
	Radio,
	RadioGroup,
	SubmitButton,
} from "@mdotm/mdotui/components";
import type { TrackableAsyncFn } from "@mdotm/mdotui/headless";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, spawn } from "@mdotm/mdotui/react-extensions";
import type { AxiosError } from "axios";
import { Map, Set } from "immutable";
import type { MouseEvent, MouseEventHandler } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { flushSync } from "react-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import * as z from "zod";

function MaybeAsyncButton<T>(
	props: {
		onClickAsync: TrackableAsyncFn<T, [MouseEvent]>;
		onClick: MouseEventHandler<HTMLButtonElement>;
		label: string;
		mode: "sync" | "async";
		disabled?: boolean;
	} & DataAttributesProps,
) {
	const { label, mode, onClick, onClickAsync, disabled, ...dataAttributes } = props;
	if (mode === "sync") {
		return (
			<Button {...dataAttributes} size="small" onClick={onClick} palette="primary" disabled={disabled}>
				{label}
			</Button>
		);
	}

	return (
		<AsyncButton {...dataAttributes} size="small" palette="primary" onClickAsync={onClickAsync} disabled={disabled}>
			{label}
		</AsyncButton>
	);
}

function ManualPortfolioEdit(): JSX.Element {
	const { uuid } = useParams<{ uuid: string }>();
	const { copyComposition } = useSearchParams<"copyComposition">();

	const { t } = useTranslation();
	const { push } = useTypedNavigation();
	const user = useUserValue();

	const [pathToNotBlock, setPathToNotBlock] = useState<string[]>([typedUrlForRoute("Login", {})]);

	const investmentReportApi = useApiGen(InvestmentReportsControllerApiFactory);

	const querySummary = useQueryNoRefetch(["queryInvestmentSummary", uuid], {
		queryFn: () => axiosExtract(investmentReportApi.getInvestmentSummary(uuid)),
	});

	const { data: summary } = querySummary;

	const instrumentBuilder = useInstrumentEditorBuilder({ composition: Map() });
	const compositionMap = instrumentBuilder.watchComposition();
	const compositionMapWithoutDeleteInstruments = compositionMap.removeAll(instrumentBuilder.getDeleted());
	const totalCompositionWeight = instrumentBuilder.getTotal("weight");

	const instrumentsInComposition = useMemo(
		() =>
			compositionMapWithoutDeleteInstruments.reduce((sum, instrument) => {
				if (instrument.proxyOverwriteType === "PORTFOLIO_MIXED") {
					const instrumentsInInvestment = instrument.investment?.nofInstruments ?? 0;
					const instrumentsExcluded = instrument.nOfInstrumentExcluded ?? 0;
					return instrumentsInInvestment - instrumentsExcluded + sum;
				}

				return sum + 1;
			}, 0),
		[compositionMapWithoutDeleteInstruments],
	);

	const maxInstrumentsInComposition = hasAccess(user, { requiredService: "NUMBER_OF_INSTRUMENTS_CHECK_BYPASS" })
		? +Infinity
		: INVESTMENT_INSTRUMENT_LIMIT;

	const minInstrumentsInComposition = hasAccess(user, { requiredService: "NUMBER_OF_INSTRUMENTS_CHECK_BYPASS" })
		? MIN_NUMBER_OF_INSTRUMENTS
		: MIN_NUMBER_OF_INVESTMENT_INSTRUMENTS;

	const isSubmitDisabled = useMemo(() => {
		return (
			totalCompositionWeight !== 100 ||
			instrumentsInComposition < minInstrumentsInComposition ||
			instrumentsInComposition > maxInstrumentsInComposition
		);
	}, [instrumentsInComposition, maxInstrumentsInComposition, minInstrumentsInComposition, totalCompositionWeight]);

	const hasPortfolioInComposition = useMemo(() => {
		return compositionMapWithoutDeleteInstruments.some((x) => x.proxyOverwriteType === "PORTFOLIO_MIXED");
	}, [compositionMapWithoutDeleteInstruments]);

	const onSubmit = useCallback(
		async (params: {
			uuid: string;
			portfolioSavingMode: EditorSaveEditOrReviewRequestPortfolioSavingModeEnum;
			composition: ReviewTicker[];
		}) => {
			const { portfolioSavingMode, composition } = params;
			try {
				if (params.uuid === undefined) {
					throw Error("unable to submit a composition of undefined");
				}

				await manualSaveEditFactory.saveInvestment(params.uuid, {
					composition,
					form: { portfolioSavingMode },
				});
				flushSync(() => setPathToNotBlock([typedUrlForRoute("PortfolioDetails", { portfolioUid: uuid })]));
				trackMixPanelEvent("Portfolio", {
					Type: "Update",
					Area: `Composition`,
					Mode: "normal",
					ID: uuid ?? "",
				});
				push("PortfolioDetails", { portfolioUid: params.uuid });
			} catch (error) {
				const e = error as AxiosError<{ message?: string }>;
				const message = e.response?.data.message;

				if (message === "maxValue should be greater or equal to 0.02") {
					platformToast({
						children: "Please review your instrument, minimum weight acceptable is 0.02",
						severity: "error",
						icon: "Portfolio",
					});
				} else {
					platformToast({
						children: "Something went wrong, please try later",
						severity: "error",
						icon: "Portfolio",
					});
				}

				reportPlatformError(error, "ERROR", "portfolio", {
					message: `unable to save the edited portfolio composition (${uuid})`,
				});

				throw error;
			}
		},
		[push, uuid],
	);

	const onSubmitEnhanceComposition = useCallback(
		async (params: {
			uuid: string;
			portfolioSavingMode: EditorSaveEditOrReviewRequestPortfolioSavingModeEnum;
			savePolicy: EditorSaveEditOrReviewRequestEditPolicyEnum;
			composition: ReviewTicker[];
		}) => {
			const { uuid, portfolioSavingMode, composition, savePolicy } = params;
			try {
				if (uuid === undefined) {
					throw Error("unable to submit a composition of undefined");
				}

				await manualSaveEditFactory.saveInvestmentEnhancement(uuid, {
					form: { editPolicy: savePolicy, portfolioSavingMode },
					composition,
				});
				flushSync(() => setPathToNotBlock([typedUrlForRoute("PortfolioDetails", { portfolioUid: uuid })]));
				trackMixPanelEvent("Portfolio", {
					Type: "Update",
					Area: `Composition`,
					Mode: savePolicy === "SAVE_AND_ACCEPT" ? "enhance" : "enhance Without Accept",
					ID: uuid,
				});
				push("PortfolioDetails", { portfolioUid: uuid });
			} catch (error) {
				const e = error as AxiosError<{ message?: string }>;
				const message = e.response?.data.message;

				if (message === "maxValue should be greater or equal to 0.02") {
					platformToast({
						children: "Please review your instrument, minimum weight acceptable is 0.02",
						severity: "error",
						icon: "Portfolio",
					});
				} else {
					platformToast({
						children: "Something went wrong, please try later",
						severity: "error",
						icon: "Portfolio",
					});
				}

				reportPlatformError(error, "ERROR", "portfolio", {
					message: `unable to save the edited portfolio composition (${uuid})`,
				});

				throw error;
			}
		},
		[push],
	);

	const crumbs = useCrumbs();

	return (
		<>
			<PageHeader
				title="Edit portfolio composition"
				crumbsV2={[
					...crumbs.portfolioStudio("Portfolios", summary?.uuid),
					...crumbs.portfolioName(summary),
					...crumbs.editPortfolio,
				]}
				titleAction={
					<div className="flex items-center space-x-2">
						<Button
							size="small"
							onClick={() =>
								push("PortfolioDetails", { portfolioUid: uuid, proposal: String(summary?.status === "PROPOSAL_READY") })
							}
							palette="tertiary"
							data-qualifier="CompositionEditor/Cancel"
						>
							{t("BUTTON.CANCEL")}
						</Button>
						{summary?.status === "PROPOSAL_READY" ? (
							<>
								<MaybeAsyncButton
									label={t("BUTTON.UPDATE_PROPOSAL")}
									mode={hasPortfolioInComposition ? "sync" : "async"}
									onClick={() =>
										spawnChooseEntitySavingModeDialog({
											onSubmitAsync: ({ portfolioSavingMode }) =>
												onSubmitEnhanceComposition({
													composition: covertInstrumentEditorToReviewTicker(
														Array.from(instrumentBuilder.getComposition({ removeDeleted: true }).values()),
													),
													portfolioSavingMode,
													uuid,
													savePolicy: "SAVE_WITHOUT_ACCEPT",
												}),
											portfolioSavingMode: "NEST_PORTFOLIOS",
										})
									}
									onClickAsync={() =>
										onSubmitEnhanceComposition({
											composition: covertInstrumentEditorToReviewTicker(
												Array.from(instrumentBuilder.getComposition({ removeDeleted: true }).values()),
											),
											portfolioSavingMode: "MIX_INSTRUMENTS",
											uuid,
											savePolicy: "SAVE_WITHOUT_ACCEPT",
										})
									}
									disabled={isSubmitDisabled}
									data-qualifier="EditPortfolioComposition/UpdateProposal/Button"
								/>
								<MaybeAsyncButton
									label={t("BUTTON.SAVE_AND_ACCEPT")}
									mode={hasPortfolioInComposition ? "sync" : "async"}
									onClick={() =>
										spawnChooseEntitySavingModeDialog({
											onSubmitAsync: ({ portfolioSavingMode }) =>
												onSubmitEnhanceComposition({
													composition: covertInstrumentEditorToReviewTicker(
														Array.from(instrumentBuilder.getComposition({ removeDeleted: true }).values()),
													),
													portfolioSavingMode,
													uuid,
													savePolicy: "SAVE_AND_ACCEPT",
												}),
											portfolioSavingMode: "NEST_PORTFOLIOS",
										})
									}
									onClickAsync={() =>
										onSubmitEnhanceComposition({
											composition: covertInstrumentEditorToReviewTicker(
												Array.from(instrumentBuilder.getComposition({ removeDeleted: true }).values()),
											),
											portfolioSavingMode: "MIX_INSTRUMENTS",
											uuid,
											savePolicy: "SAVE_AND_ACCEPT",
										})
									}
									disabled={isSubmitDisabled}
									data-qualifier="EditPortfolioComposition/SaveAndAccepEnhancement/Button"
								/>
							</>
						) : (
							<MaybeAsyncButton
								label={t("BUTTON.SAVE")}
								mode={hasPortfolioInComposition ? "sync" : "async"}
								onClick={() =>
									spawnChooseEntitySavingModeDialog({
										onSubmitAsync: ({ portfolioSavingMode }) =>
											onSubmit({
												composition: covertInstrumentEditorToReviewTicker(
													Array.from(instrumentBuilder.getComposition({ removeDeleted: true }).values()),
												),
												portfolioSavingMode,
												uuid,
											}),
										portfolioSavingMode: "NEST_PORTFOLIOS",
									})
								}
								onClickAsync={() =>
									onSubmit({
										composition: covertInstrumentEditorToReviewTicker(
											Array.from(instrumentBuilder.getComposition({ removeDeleted: true }).values()),
										),
										portfolioSavingMode: "MIX_INSTRUMENTS",
										uuid,
									})
								}
								disabled={isSubmitDisabled}
								data-qualifier="EditPortfolioComposition/Save/Button"
							/>
						)}
					</div>
				}
			/>{" "}
			<ReactQueryWrapperBase query={querySummary}>
				{(responseSummary) => (
					<InstrumentEditorTable
						instrumentBuilder={instrumentBuilder}
						summary={responseSummary}
						uuid={uuid}
						maxInstrumentsInComposition={maxInstrumentsInComposition}
						minInstrumentsInComposition={minInstrumentsInComposition}
						copyComposition={copyComposition}
					/>
				)}
			</ReactQueryWrapperBase>
			<LeavePrompt
				title={t("NOTIFICATION_SETTINGS.LEAVE_PROMPT.TITLE")}
				when={instrumentBuilder.isDirty}
				pathToNotBlock={pathToNotBlock}
			>
				{t("NOTIFICATION_SETTINGS.LEAVE_PROMPT.MESSAGE")}
			</LeavePrompt>
		</>
	);
}

function InstrumentEditorTable({
	summary,
	instrumentBuilder,
	uuid,
	minInstrumentsInComposition,
	maxInstrumentsInComposition,
	copyComposition,
}: {
	summary: InvestmentSummary;
	instrumentBuilder: InstrumentEditorBuilderProps;
	uuid: string;
	minInstrumentsInComposition: number;
	maxInstrumentsInComposition: number;
	copyComposition?: string;
}) {
	const { replace } = useTypedNavigation();

	const [expandExposure, setExpandExposure] = useState(false);
	const entityEditorApi = useApiGen(EntityEditorControllerApiFactory);
	const investmentReportApi = useApiGen(InvestmentReportsControllerApiFactory);

	const queryInitManualEdit = useQueryNoRefetch(["queryInitManulEditComposition", uuid], {
		queryFn: async () => {
			const editorResponse = await axiosExtract(
				entityEditorApi.getEditorEditComposition(
					uuid,
					summary.status === "PROPOSAL_READY" ? "INVESTMENT_ENHANCEMENT" : "INVESTMENT",
				),
			);

			const { composition, cashTicker } = editorResponse;
			const nonNullableComposition = composition ?? [];

			const { investmentExposureMap, investmentSummaryMap, investments, verifiedInvestmentsMap } =
				await retrieveInvestmentData(nonNullableComposition);
			const { userInstrumentsMap } = await retrieveUserInstrumentsClassifications(nonNullableComposition);

			return {
				...editorResponse,
				cashTicker: cashTicker ? { ...cashTicker, name: cashTicker.instrument ?? "Cash" } : undefined,
				composition: nonNullableComposition,
				investments,
				investmentSummaryMap,
				investmentExposureMap,
				verifiedInvestmentsMap,
				userInstrumentsMap,
			};
		},
		onSuccess({
			composition,
			cashTicker,
			investments,
			investmentSummaryMap,
			investmentExposureMap,
			verifiedInvestmentsMap,
			userInstrumentsMap,
		}) {
			const { description: _description, type: _type, ...cashParams } = cashTicker ?? {};
			const uniqueTags = Set(defaultTagLabels.concat(composition.flatMap((x) => (x.tagLabel ? [x.tagLabel] : []))));
			const tags = Map<string, Tag>(
				uniqueTags.map((tag): [string, Tag] => {
					const convertedTag = labelToTag({ label: tag }, Array.from(uniqueTags));
					return [convertedTag.name, { label: convertedTag.name, color: convertedTag.color, value: convertedTag.name }];
				}),
			);

			if (investments.length === 0) {
				const compositionMap = Map(
					composition.map((instrument): [string, InstrumentEditorEntry] => {
						const rowId = instrumentEntryKey(instrument);
						const { description: _description, type: _type, ...instrumentParams } = instrument;
						const userInstrument = userInstrumentsMap.get(instrument.ticker!) ?? {};
						return [
							rowId,
							{
								...instrumentParams,
								...userInstrument,
								rowId,
								stableWeight: instrument.weight,
								name: userInstrument.name ?? instrumentParams.instrument,
								alias: userInstrument.alias ?? instrument.identifier,
							},
						];
					}),
				);

				instrumentBuilder.reset(
					{
						composition: compositionMap,
						cash: cashTicker ? { ...cashParams, rowId: instrumentEntryKey(cashTicker) } : undefined,
						tags,
					},
					{ disableToggleDirty: true },
				);
				return;
			}

			const compositionMap = Map(
				composition.map((instrument): [string, InstrumentEditorEntry] => {
					const rowId = instrumentEntryKey(instrument);
					const { description: _description, type: _type, ...instrumentParams } = instrument;
					const userInstrument = userInstrumentsMap.get(instrument.ticker!) ?? {};
					if (instrument.proxyOverwriteType === "PORTFOLIO_MIXED" && instrument.ticker) {
						const exposure = investmentExposureMap.get(instrument.ticker);
						const investmentSummary = investmentSummaryMap.get(instrument.ticker);
						const someInstrumentAreRemoved = verifiedInvestmentsMap.get(instrument.ticker) ?? [];

						return [
							rowId,
							{
								...instrumentParams,
								...userInstrument,
								rowId,
								investment: {
									nofInstruments: investmentSummary?.nofInstruments,
									macroAssetClassExposure: exposure?.investmentComposition,
									macroAssetClassExposureEnhanced: exposure?.enhancementComposition,
									name: investmentSummary?.name,
									lastActionNote: investmentSummary?.lastActionNote,
									uuid: investmentSummary?.uuid,
									action: investmentSummary?.action,
								},
								identifier: "Portfolio",
								instrument: investmentSummary?.name,
								weight: instrument.weight ?? 10,
								stableWeight: instrument.weight,
								nOfInstrumentExcluded: someInstrumentAreRemoved.length,
								someInstrumentsAreExcluded: someInstrumentAreRemoved.length > 0,
								name: investmentSummary?.name ?? userInstrument.name ?? instrumentParams.instrument,
								alias: userInstrument.alias ?? instrument.identifier,
							},
						];
					}
					return [
						rowId,
						{
							...instrumentParams,
							...userInstrument,
							rowId,
							name: userInstrument.name ?? instrumentParams.instrument,
							alias: userInstrument.alias ?? instrument.identifier,
						},
					];
				}),
			);

			instrumentBuilder.reset(
				{
					composition: compositionMap,
					cash: cashTicker ? { ...cashParams, rowId: instrumentEntryKey(cashTicker) } : undefined,
					tags,
				},
				{ disableToggleDirty: true },
			);

			setExpandExposure(true);
		},
	});

	const comparedInvestments = useMemo((): CompareDataItem[] => {
		const investmentsCompared = instrumentBuilder
			.watchComposition()
			.filter((x) => instrumentBuilder.getComparedInstrument(x.rowId) !== undefined)
			.values();

		return Array.from(investmentsCompared).map((instrument) => ({
			id: instrument.rowId,
			composition:
				instrument.investment?.macroAssetClassExposure?.map((x) => ({
					quality: x.firstQualityLevel,
					weight: x.weight,
				})) ?? [],
			numberOfInstrument: instrument.investment?.nofInstruments ?? 0,
			portfolioName: instrument.investment?.name ?? "-",
			uuid: instrument.investment?.uuid,
			note: instrument.investment?.lastActionNote,
			action: instrument.investment?.action,
		}));
	}, [instrumentBuilder]);

	useEffect(() => {
		if (copyComposition) {
			spawnOverridePortfolioDialog({
				entity: "INVESTMENT",
				uuid,
				async onSubmitAsync(investment) {
					const editorResponse = await axiosExtract(
						entityEditorApi.getEditorEditComposition(investment.uuid!, "INVESTMENT"),
					);

					const { composition } = editorResponse;
					const nonNullableComposition = composition ?? [];
					const cashTicker: (ReviewTicker & { name: string }) | undefined = editorResponse.cashTicker
						? {
								...editorResponse.cashTicker,
								name: editorResponse.cashTicker?.instrument ?? "Cash",
						  }
						: undefined;

					const { description: _description, type: _type, ...cashParams } = cashTicker ?? {};

					const { investmentExposureMap, investmentSummaryMap, verifiedInvestmentsMap, investments } =
						await retrieveInvestmentData(nonNullableComposition);
					const { userInstrumentsMap } = await retrieveUserInstrumentsClassifications(nonNullableComposition);

					const uniqueTags = Set(nonNullableComposition.flatMap((x) => (x.tagLabel ? [x.tagLabel] : [])));
					const tags = Map<string, Tag>(
						uniqueTags.map((tag): [string, Tag] => {
							const convertedTag = labelToTag({ label: tag }, Array.from(uniqueTags));
							return [
								convertedTag.name,
								{ label: convertedTag.name, color: convertedTag.color, value: convertedTag.name },
							];
						}),
					);

					if (investments.length === 0) {
						const compositionToMap = Map(
							nonNullableComposition.map((instrument): [string, InstrumentEditorEntry] => {
								const rowId = instrumentEntryKey(instrument);
								const { description: _description, type: _type, ...instrumentParams } = instrument;
								const userInstrument = userInstrumentsMap.get(instrument.ticker!) ?? {};
								return [
									rowId,
									{
										...instrumentParams,
										...userInstrument,
										rowId,
										stableWeight: instrument.weight,
										name: userInstrument.name ?? instrumentParams.instrument,
										alias: userInstrument.alias ?? instrumentParams.identifier,
									},
								];
							}),
						);

						instrumentBuilder.reset({
							composition: compositionToMap,
							cash: cashTicker ? { ...cashParams, rowId: instrumentEntryKey(cashTicker) } : undefined,
							tags,
						});
						replace("Portfolios/ManualEditPortfolio", { uuid });
						return;
					}

					const compositionToMap = Map(
						nonNullableComposition.map((instrument): [string, InstrumentEditorEntry] => {
							const rowId = instrumentEntryKey(instrument);
							const { description: _description, type: _type, ...instrumentParams } = instrument;
							const userInstrument = userInstrumentsMap.get(instrument.ticker!) ?? {};
							if (instrument.proxyOverwriteType === "PORTFOLIO_MIXED" && instrument.ticker) {
								const exposure = investmentExposureMap.get(instrument.ticker);
								const investmentSummary = investmentSummaryMap.get(instrument.ticker);
								const someInstrumentAreRemoved = verifiedInvestmentsMap.get(instrument.ticker) ?? [];
								return [
									rowId,
									{
										...instrumentParams,
										...userInstrument,
										rowId,
										investment: {
											nofInstruments: investmentSummary?.nofInstruments,
											macroAssetClassExposure: exposure?.investmentComposition,
											macroAssetClassExposureEnhanced: exposure?.enhancementComposition,
											name: investmentSummary?.name,
											lastActionNote: investmentSummary?.lastActionNote,
											uuid: investmentSummary?.uuid,
											action: investmentSummary?.action,
										},
										identifier: "Portfolio",
										instrument: investmentSummary?.name,
										weight: instrument.weight ?? 10,
										stableWeight: instrument.weight,
										nOfInstrumentExcluded: someInstrumentAreRemoved.length,
										someInstrumentsAreExcluded: someInstrumentAreRemoved.length > 0,
										name: investmentSummary?.name ?? userInstrument.name ?? instrumentParams.instrument,
										alias: userInstrument.alias ?? instrumentParams.identifier,
									},
								];
							}
							return [
								rowId,
								{
									...instrumentParams,
									...userInstrument,
									rowId,
									name: userInstrument.name ?? instrumentParams.instrument,
									alias: userInstrument.alias ?? instrumentParams.identifier,
								},
							];
						}),
					);

					instrumentBuilder.reset({
						composition: compositionToMap,
						cash: cashTicker ? { ...cashParams, rowId: instrumentEntryKey(cashTicker) } : undefined,
						tags,
					});
					setExpandExposure(true);
					replace("Portfolios/ManualEditPortfolio", { uuid });
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [replace, copyComposition, uuid]);

	return (
		<ReactQueryWrapperBase query={queryInitManualEdit}>
			{() => (
				<>
					<div className="min-h-0 grow flex">
						{summary.status === "PROPOSAL_READY" ? (
							<InstrumentCompositionEditor
								entity="INVESTMENT_ENHANCEMENT"
								mode="edit"
								uuid={uuid}
								instrumentBuilder={instrumentBuilder}
								minThreshold={minInstrumentsInComposition}
								limit={maxInstrumentsInComposition}
							/>
						) : (
							<InstrumentCompositionEditor
								entity="INVESTMENT"
								mode="edit"
								uuid={uuid}
								instrumentBuilder={instrumentBuilder}
								minThreshold={minInstrumentsInComposition}
								limit={maxInstrumentsInComposition}
							/>
						)}
						<ExposureSankeyBlock
							expand={expandExposure}
							onExpandChange={setExpandExposure}
							instrumentBuilder={instrumentBuilder}
						/>
					</div>

					<CompareOverlay
						show={comparedInvestments.length > 0}
						onClose={() => instrumentBuilder.clearComparedInstruments()}
						onRemove={(id) => instrumentBuilder.deleteComparedInstruments(id)}
						compareData={comparedInvestments}
					/>
				</>
			)}
		</ReactQueryWrapperBase>
	);
}

type ChooseEntitySavingModeDialogProps = {
	onSubmitAsync(payload: { portfolioSavingMode: EditorSaveEditOrReviewRequestPortfolioSavingModeEnum }): Promise<void>;
	portfolioSavingMode: EditorSaveEditOrReviewRequestPortfolioSavingModeEnum;
} & MinimumDialogProps;

function ChooseEntitySavingModeDialog(props: ChooseEntitySavingModeDialogProps) {
	const { t } = useTranslation();

	const { control, formState, handleSubmit } = useForm({
		defaultValues: {
			portfolioSavingMode: props.portfolioSavingMode,
		},
		resolver: zodResolver(
			z.object({
				portfolioSavingMode: z.nativeEnum(EditorSaveEditOrReviewRequestPortfolioSavingModeEnum),
			}),
		),
	});

	return (
		<Dialog
			show={props.show}
			onAnimationStateChange={props.onAnimationStateChange}
			size="large"
			onClose={props.onClose}
			onSubmitAsync={() => handleSubmit((x) => props.onSubmitAsync({ portfolioSavingMode: x.portfolioSavingMode }))()}
			header={<DialogHeader>Would you like to save this as a Nested Portfolio?</DialogHeader>}
			footer={({ loading }) => (
				<DialogFooter
					primaryAction={
						<SubmitButton
							palette="primary"
							disabled={loading}
							data-qualifier="EditPortfolioComposition/SaveDialog/Save"
						>
							{t("BUTTON.SAVE")}
						</SubmitButton>
					}
					neutralAction={
						<Button
							palette="tertiary"
							disabled={loading}
							onClick={props.onClose}
							data-qualifier="EditPortfolioComposition/SaveDialog/Cancel"
						>
							{t("BUTTON.CANCEL")}
						</Button>
					}
				/>
			)}
		>
			<p className="mb-4">By keeping it as a Nested Portfolio, internal portfolios will automatically update</p>
			<FormField
				label="Composition mode"
				error={(valueByPath(formState.errors, "portfolioSavingMode") as { message?: string })?.message}
			>
				{(fieldProps) => (
					<FormController
						control={control}
						name="portfolioSavingMode"
						defaultValue={EditorSaveEditOrReviewRequestPortfolioSavingModeEnum.NestPortfolios}
						render={({ field: { ref: _ref, ...controllerProps } }) => (
							<RadioGroup {...controllerProps} {...fieldProps} onChange={controllerProps.onChange}>
								<div className="flex flex-row flex-wrap gap-4">
									<Radio
										value={EditorSaveEditOrReviewRequestPortfolioSavingModeEnum.NestPortfolios}
										data-qualifier="EditPortfolioComposition/SaveDialog/CompositionMode(Nested)"
									>
										Keep as Nested
									</Radio>
									<Radio
										value={EditorSaveEditOrReviewRequestPortfolioSavingModeEnum.MixInstruments}
										data-qualifier="EditPortfolioComposition/SaveDialog/CompositionMode(Aggregate)"
									>
										Aggregate the instruments
									</Radio>
								</div>
							</RadioGroup>
						)}
					/>
				)}
			</FormField>
		</Dialog>
	);
}

type SpawnChooseEntitySavingModeDialogProps = Omit<ChooseEntitySavingModeDialogProps, "show" | "onClose">;
function spawnChooseEntitySavingModeDialog(params: SpawnChooseEntitySavingModeDialogProps): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ resolve, show, onHidden }) => (
			<ChooseEntitySavingModeDialog
				{...params}
				show={show}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
				onClose={() => resolve()}
				onSubmitAsync={async ({ portfolioSavingMode }) => {
					await params.onSubmitAsync({ portfolioSavingMode });
					resolve();
				}}
			/>
		)),
	);
}

export default ManualPortfolioEdit;
