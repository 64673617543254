import type { SelectableBasket, Tag, TagValue, UserInstrumentClassificationDto } from "$root/api/api-gen";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import Separator from "$root/components/Separator";
import { SideDrawer } from "$root/components/SideDrawer";
import { qualifier, useQueryNoRefetch } from "$root/utils";
import type { Option } from "@mdotm/mdotui/components";
import { Button, DebouncedSearchInput, Icon, Row, Select, TinyIconButton, Transition } from "@mdotm/mdotui/components";
import { ForEach, generateUniqueDOMId, useRefProxyWithState } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { stableEmptyArray } from "@mdotm/mdotui/utils";
import { Map, OrderedMap, Set } from "immutable";
import type { ReactNode } from "react";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

export const extendedTabWidth = 364;
export const smallTabWidth = 24;

function handleMapTruthy<K, T>(map?: Map<K, T>): Map<K, T> {
	return map ?? Map();
}

export type TagFilter = Omit<Tag, "standardClassificationId">;

export type InstrumentCustomisationSettings = {
	search: string;
	selectedfilters?: Map<string, TagFilter>;
	selectedBaskets?: Array<SelectableBasket>;
};

export type InstrumentCustomisationTableSidePanelProps = {
	expand: boolean;
	onExpandChange(): void;
	selectableBasketProvider(): Promise<SelectableBasket[]>;
	selectableUserClassificationsProvider(): Promise<Array<UserInstrumentClassificationDto>>;
	onChangeInstrumentCustomisationSettings(newData: InstrumentCustomisationSettings): void;
	initialInstrumentCustomisationSettings: InstrumentCustomisationSettings;
	noManLand?: ReactNode;
	basketSelection?: {
		disabled?: boolean;
		customTriggerMultiSelection?(selectionSize: number): string;
		hideDelete?: boolean;
	};
};

const emptyInstrumentCustomisationSettings = { search: "", selectedBaskets: undefined, selectedfilters: undefined };

function InstrumentCustomisationTableSidePanel({
	expand,
	onExpandChange,

	selectableBasketProvider,
	selectableUserClassificationsProvider,
	onChangeInstrumentCustomisationSettings,
	initialInstrumentCustomisationSettings,
	noManLand,
	basketSelection,
}: InstrumentCustomisationTableSidePanelProps): JSX.Element {
	const { t } = useTranslation();

	const latestInstrumentCustomisationSettings = useRef<InstrumentCustomisationSettings>(
		initialInstrumentCustomisationSettings,
	);

	const [instrumentCustomisationSettings, setInstrumentCustomisationSettings] =
		useRefProxyWithState<InstrumentCustomisationSettings>(
			initialInstrumentCustomisationSettings,
			latestInstrumentCustomisationSettings,
		);

	const querySelectableBaskets = useQueryNoRefetch(["querySelectableBaskets"], {
		queryFn: selectableBasketProvider,
	});

	const querySelectableUserClassifications = useQueryNoRefetch(["querySelectableUserClassifications"], {
		queryFn: selectableUserClassificationsProvider,
	});

	return (
		<SideDrawer
			toggleIcon="Filter"
			toggleOffset={64}
			expand={expand}
			onExpandChange={onExpandChange}
			widths={[smallTabWidth, extendedTabWidth]}
			title="Instruments panel"
			footer={
				<div className="flex justify-start">
					<Button
						palette="tertiary"
						size="small"
						onClick={() => {
							setInstrumentCustomisationSettings(emptyInstrumentCustomisationSettings);
							onChangeInstrumentCustomisationSettings(emptyInstrumentCustomisationSettings);
						}}
						data-qualifier={qualifier.instrumentsFiltersPanel.clearAll}
					>
						{t("BUTTON.CLEAR_ALL")}
					</Button>
				</div>
			}
		>
			<div className="relative z-0">
				<Separator text="Filters" align="start" classList="mb-2" />
				<DebouncedSearchInput
					externalQuery={instrumentCustomisationSettings.search}
					onChange={(search) => {
						setInstrumentCustomisationSettings((latestSettings) => ({ ...latestSettings, search }));
						onChangeInstrumentCustomisationSettings(latestInstrumentCustomisationSettings.current);
					}}
					placeholder="Search instrument.."
					classList="mb-2"
					data-qualifier={qualifier.instrumentsFiltersPanel.search}
					timeout={500}
				/>
				{noManLand}
				<ReactQueryWrapperBase query={querySelectableBaskets}>
					{(selectableBaskets) => {
						const basketOptions = selectableBaskets.map((x) => ({
							label: x.basketName!,
							value: x,
							group: t(`BASKET_ENTITY.${x.basketType!}`),
						}));
						return (
							<Row alignItems="center" classList="mb-2 min-h-[40px]" childrenGrow={1}>
								<Select
									multi
									enableSearch
									i18n={{
										triggerPlaceholder: () => "Basket",
										triggerMultiSelection: basketSelection?.customTriggerMultiSelection
											? basketSelection.customTriggerMultiSelection
											: (n) => (n === 1 ? "1 basket" : `${n} baskets`),
									}}
									options={basketOptions ?? []}
									value={instrumentCustomisationSettings.selectedBaskets ?? stableEmptyArray}
									onChange={(selectedBaskets) => {
										setInstrumentCustomisationSettings((latestSettings) => ({ ...latestSettings, selectedBaskets }));
										onChangeInstrumentCustomisationSettings(latestInstrumentCustomisationSettings.current);
									}}
									disabled={basketSelection?.disabled}
									data-qualifier={qualifier.instrumentsFiltersPanel.basket.options}
									triggerDataAttrs={{
										"data-qualifier": qualifier.instrumentsFiltersPanel.basket.select,
									}}
								/>
								{(instrumentCustomisationSettings.selectedBaskets?.length ?? 0) > 0 && !basketSelection?.hideDelete && (
									<TinyIconButton
										icon="Delete"
										onClick={() => {
											setInstrumentCustomisationSettings((latestSettings) => ({
												...latestSettings,
												selectedBaskets: undefined,
											}));
											onChangeInstrumentCustomisationSettings(latestInstrumentCustomisationSettings.current);
										}}
										size={18}
										color={themeCSSVars.palette_P400}
										classList="grow-0"
										data-qualifier={qualifier.instrumentsFiltersPanel.basket.delete}
									/>
								)}
							</Row>
						);
					}}
				</ReactQueryWrapperBase>
				<ReactQueryWrapperBase query={querySelectableUserClassifications}>
					{(selectableUserClassifications) => {
						return (
							<InstrumentCustomisationTableSidePanelFilters
								instrumentCustomisationSettings={instrumentCustomisationSettings}
								selectableUserClassifications={selectableUserClassifications}
								onAddFilter={() =>
									setInstrumentCustomisationSettings((latestSettings) => ({
										...latestSettings,
										selectedfilters: OrderedMap(
											handleMapTruthy(latestSettings.selectedfilters).set(generateUniqueDOMId(), {}),
										),
									}))
								}
								onChangeTagFilters={(id, payload) =>
									setInstrumentCustomisationSettings((latestSettings) => ({
										...latestSettings,
										selectedfilters: OrderedMap(handleMapTruthy(latestSettings?.selectedfilters).set(id, payload)),
									}))
								}
								onRemoveTagFilter={(id) =>
									setInstrumentCustomisationSettings((latestSettings) => ({
										...latestSettings,
										selectedfilters: OrderedMap(handleMapTruthy(latestSettings?.selectedfilters).remove(id)),
									}))
								}
								onCommitTagFilters={() =>
									onChangeInstrumentCustomisationSettings(latestInstrumentCustomisationSettings.current)
								}
							/>
						);
					}}
				</ReactQueryWrapperBase>
			</div>
		</SideDrawer>
	);
}

function optionsByClassificationUuid(
	classificationUuid: string,
	selectableUserClassifications: Array<UserInstrumentClassificationDto>,
): Option<TagValue>[] {
	return (
		selectableUserClassifications
			.find((x) => x.classificationUuid === classificationUuid)
			?.options?.map((x) => ({ value: x, label: x.option! })) ?? []
	);
}

type InstrumentCustomisationTableSidePanelFiltersProps = {
	onAddFilter(): void;

	onChangeTagFilters(id: string, tag: TagFilter): void;
	onCommitTagFilters(): void;
	onRemoveTagFilter(id: string): void;

	instrumentCustomisationSettings: InstrumentCustomisationSettings;
	selectableUserClassifications: Array<UserInstrumentClassificationDto>;
};

function InstrumentCustomisationTableSidePanelFilters({
	onChangeTagFilters,
	onCommitTagFilters,
	onRemoveTagFilter,

	onAddFilter,
	instrumentCustomisationSettings,
	selectableUserClassifications,
}: InstrumentCustomisationTableSidePanelFiltersProps) {
	const selectedFiltersClassificationUuidValue = useMemo(() => {
		const selectedFilters = instrumentCustomisationSettings.selectedfilters?.valueSeq().toArray();
		return Set(selectedFilters?.flatMap((x) => (x.classificationUuid ? [x.classificationUuid] : [])));
	}, [instrumentCustomisationSettings.selectedfilters]);

	const availableColumns = useMemo(
		() =>
			selectableUserClassifications.flatMap((userClassification): Option<string>[] => {
				if (
					userClassification.fieldType !== "TAG" ||
					!userClassification.classificationUuid ||
					!userClassification.name ||
					!(userClassification.options && userClassification.options.length > 0)
				) {
					return [];
				}

				return [
					{
						label: userClassification.name,
						value: userClassification.classificationUuid,
						disabled: selectedFiltersClassificationUuidValue?.has(userClassification.classificationUuid),
					},
				];
			}),
		[selectableUserClassifications, selectedFiltersClassificationUuidValue],
	);

	return (
		<>
			<div className="mb-2">
				<ForEach collection={instrumentCustomisationSettings.selectedfilters?.toArray() ?? []}>
					{({ item: [id, opt], index }) => (
						<Transition
							in
							initialIn={false}
							classList="transition-[opacity,transform] origin-top"
							enterFromClassList="opacity-0 scale-95"
							enterToClassList="opacity-100 scale-100"
						>
							<Row alignItems="center">
								<div className="flex gap-2 flex-1 max-w-[calc(100%_-_38px)]">
									<Select
										enableSearch
										options={availableColumns}
										value={opt.classificationUuid}
										onChange={(newClassificationUuid) => {
											if (newClassificationUuid) {
												onChangeTagFilters(id, {
													classificationUuid: newClassificationUuid,
													values: [],
												});
											}
											onCommitTagFilters();
										}}
										classList="w-[139px]"
										data-qualifier={qualifier.instrumentsFiltersPanel.filters.selectByLevel("columnSelection", index)}
									/>
									{opt.classificationUuid && (
										<Select
											multi
											enableSearch
											options={optionsByClassificationUuid(opt.classificationUuid, selectableUserClassifications)}
											value={opt.values ?? []}
											onChange={(selectedOptions) => {
												if (selectedOptions) {
													onChangeTagFilters(id, { ...opt, values: selectedOptions });
													onCommitTagFilters();
												}
											}}
											classList="w-[139px]"
											data-qualifier={qualifier.instrumentsFiltersPanel.filters.selectByLevel("optionSelection", index)}
										/>
									)}
								</div>
								<TinyIconButton
									icon="Delete"
									onClick={() => {
										onRemoveTagFilter(id);
										onCommitTagFilters();
									}}
									size={18}
									color={themeCSSVars.palette_P400}
									classList="ml-auto "
								/>
							</Row>
						</Transition>
					)}
				</ForEach>
			</div>
			<Button
				palette="secondary"
				onClick={() => onAddFilter()}
				size="small"
				data-qualifier={qualifier.instrumentsFiltersPanel.filters.add}
			>
				<Icon icon="Outline1" />
			</Button>
		</>
	);
}

export default InstrumentCustomisationTableSidePanel;
