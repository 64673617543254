import type {
	InvestmentCreationConfigurationControllerV4Api,
	InvestmentDraftConfigurationControllerV4Api,
	InvestmentEnhancementConfigurationControllerV4Api,
	InvestmentReferenceDTO,
	SelectableRiskConstraintsResponse
} from "$root/api/api-gen";
import {
	ConstraintValidity,
	InvestmentCreationConfigurationControllerV4ApiFactory,
	InvestmentDraftConfigurationControllerV4ApiFactory,
	InvestmentEnhancementConfigurationControllerV4ApiFactory,
	InvestmentsStaticConfigurationControllerApiFactory,
	RiskConstraintType
} from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import { DataDisplayOverlay } from "$root/components/DataDisplayOverlay";
import { IconWalls } from "$root/components/IconWall";
import { useLocaleFormatters } from "$root/localization/hooks";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { FormController } from "$root/third-party-integrations/react-hook-form";
import { multiRef, useQueryNoRefetch } from "$root/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import type { Option } from "@mdotm/mdotui/components";
import {
	AutoTooltip,
	Banner,
	Checkbox,
	Icon,
	ProgressBar,
	Select,
	Slider,
	Text,
	TooltipContent,
} from "@mdotm/mdotui/components";
import { generateUniqueDOMId, useTick } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { noop } from "@mdotm/mdotui/utils";
import { Map, Set } from "immutable";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import { z } from "zod";
import type { EditPortfolioV4Props } from "../EditPortfolio";
import { StepBase } from "../StepBase";
import type { ConstraintAdder } from "../constraints/AddConstraintSection";
import { AddConstraintSection } from "../constraints/AddConstraintSection";
import { ConstraintListSection } from "../constraints/ConstraintListSection";
import type { ConstraintSpecificColumnsProps } from "../constraints/ConstraintSpecificColumns";
import { DefaultConstraintListItem } from "../constraints/DefaultConstraintListItem";
import { useStepSync } from "../hooks";
import { makeStepToRequestMapper, responseToStepMapper } from "../requests";
import type { EditableRiskConstraint } from "../shared";
import {
	useHandleSubmitToCustomSubmitHandlerInContext,
	type EditPortfolioV4StepPayloadMap,
	type EditableRiskConstraintHelper,
	type RiskConstraintTypePayloadMap,
	type StepPropsFor,
} from "../shared";

const titleClassName = `text-[color:${themeCSSVars.global_palette_neutral_700}] pb-2`;
type ConstraintTemplateOmittedKey = "id" | "readonly" | "type";

function constraintTemplatesHelper<
	T extends {
		[K in keyof RiskConstraintTypePayloadMap]: Omit<EditableRiskConstraintHelper<K>, ConstraintTemplateOmittedKey>;
	},
>(a: T): { [K in keyof T & keyof RiskConstraintTypePayloadMap]: () => EditableRiskConstraintHelper<K> } {
	return Object.fromEntries(
		Object.entries(a).map(([key, value]) => [
			key,
			() =>
				({
					...value,
					type: key,
					id: generateUniqueDOMId(),
					readonly: false,
				}) satisfies Record<ConstraintTemplateOmittedKey, unknown>,
		]),
	) as unknown as {
		[K in keyof T & keyof RiskConstraintTypePayloadMap]: () => EditableRiskConstraintHelper<K>;
	};
}

export function getRiskConstraintsStepData(
	createApi: Omit<InvestmentCreationConfigurationControllerV4Api, "basePath" | "axios">,
	enhanceApi: Omit<InvestmentEnhancementConfigurationControllerV4Api, "basePath" | "axios">,
	draftApi: Omit<InvestmentDraftConfigurationControllerV4Api, "basePath" | "axios">,
	area: EditPortfolioV4Props["area"],
): Promise<EditPortfolioV4StepPayloadMap["riskConstraints"]> {
	return (
		!area.portfolioUid
			? createApi.getCreationConfigurationRiskConstraints()
			: area.name === "draft"
			  ? draftApi.getDraftConfigurationRiskConstraints(area.portfolioUid)
			  : enhanceApi.getEnhancementConfigurationRiskConstraints(area.portfolioUid)
	).then(({ data }) => responseToStepMapper.riskConstraints(data, area));
}

const validations = {
	var: z.object({
		subType: z.enum(
			[
				RiskConstraintType.HistoricalVar951Y,
				RiskConstraintType.HistoricalVar952Y,
				RiskConstraintType.HistoricalVar953Y,
				RiskConstraintType.ParametricVar951Y,
				RiskConstraintType.ParametricVar952Y,
				RiskConstraintType.ParametricVar953Y,
				RiskConstraintType.HistoricalVar9751Y,
				RiskConstraintType.HistoricalVar9752Y,
				RiskConstraintType.HistoricalVar9753Y,
				RiskConstraintType.ParametricVar9751Y,
				RiskConstraintType.ParametricVar9752Y,
				RiskConstraintType.ParametricVar9753Y,
				RiskConstraintType.HistoricalVar991Y,
				RiskConstraintType.HistoricalVar992Y,
				RiskConstraintType.HistoricalVar993Y,
				RiskConstraintType.ParametricVar991Y,
				RiskConstraintType.ParametricVar992Y,
				RiskConstraintType.ParametricVar993Y,
			],
			{
				required_error: "Select a constraint type",
				invalid_type_error: "Select a valid constraint type",
			},
		),
		suggestedMinValue: z.number(),
		suggestedMaxValue: z.number(),
		suggestedValue: z.number(),
		target: z.boolean(),
		value: z.number(),
		validity: z.any(),
	} satisfies Record<keyof RiskConstraintTypePayloadMap["var"], unknown>),
	volatility: z.object({
		subType: z.enum(
			[
				RiskConstraintType.Volatility6M,
				RiskConstraintType.Volatility1Y,
				RiskConstraintType.Volatility2Y,
				RiskConstraintType.Volatility3Y,
			],
			{
				required_error: "Select a constraint type",
				invalid_type_error: "Select a valid constraint type",
			},
		),
		suggestedMinValue: z.number(),
		suggestedMaxValue: z.number(),
		suggestedValue: z.number(),
		target: z.boolean(),
		value: z.number(),
		validity: z.any(),
	} satisfies Record<keyof RiskConstraintTypePayloadMap["volatility"], unknown>),
	maxTrackingError: z.object({
		investmentReference: z.object({
			identifier: z.string().nullable(),
			name: z.string(),
			type: z.any(),
			available: z.boolean(),
		}),
		validity: z.any(),
		value: z.number(),
		suggestedMinValue: z.number(),
		suggestedMaxValue: z.number(),
		suggestedValue: z.number(),
		target: z.boolean(),
	} satisfies Record<keyof RiskConstraintTypePayloadMap["maxTrackingError"], unknown>),
};

export function Step04_RiskConstraints({
	context,
	stepMetadata,
	stepData,
	allStepsData,
	onStepDataChange,
	onStepError,
	toggleDirty,
	overrideCurrentStepIcon,
}: StepPropsFor<"riskConstraints">): JSX.Element {
	const { saveHandlers, area } = context;

	const edit = area.editable && area.edit;
	const createInvestmentApi = useApiGen(InvestmentCreationConfigurationControllerV4ApiFactory);
	const draftInvestmentApi = useApiGen(InvestmentDraftConfigurationControllerV4ApiFactory);
	const enhanceInvestmentApi = useApiGen(InvestmentEnhancementConfigurationControllerV4ApiFactory);
	const staticController = useApiGen(InvestmentsStaticConfigurationControllerApiFactory);

	const form = useForm({
		defaultValues: stepData,
		resolver: zodResolver(
			z.object({
				list: z.array(
					z
						.discriminatedUnion("type", [
							z
								.object({
									type: z.literal("var"),
								})
								.merge(validations.var),
							z
								.object({
									type: z.literal("volatility"),
								})
								.merge(validations.volatility),
							z
								.object({
									type: z.literal("maxTrackingError"),
								})
								.merge(validations.maxTrackingError),
						])
						.refine(
							(item) => {
								switch (item.type) {
									case "var":
									case "maxTrackingError":
									case "volatility":
										return item.value >= item.suggestedMinValue && item.value <= item.suggestedMaxValue;
								}
							},
							{
								message: "The value selected is out of range",
								path: ["value"],
							},
						),
				),
			}),
		),
		mode: "onBlur",
	});
	const { control, handleSubmit, reset, watch, formState, trigger, getValues, clearErrors } = form;
	const { replace, append, fields } = useFieldArray({ control, name: "list", keyName: "_id" });

	const [revertData, setRevertData] = useState<Map<string, (typeof stepData)["list"][number]>>(() => Map());
	const [solutionsWithAiApplied, setSolutionsWithAiApplied] = useState<Set<string>>(() => Set());

	const applyAllAISolutions = () => {
		const invalidEntries = getValues().list.filter((x) => x.validity === ConstraintValidity.Invalid);
		const newRevertData = Map(invalidEntries.map((x) => [x.id, x]));
		setRevertData(newRevertData);
		replace(
			getValues().list.map((x) => {
				if (newRevertData.has(x.id)) {
					const solvedConstraint = applyAISolution(newRevertData.get(x.id)!);
					setSolutionsWithAiApplied((solutions) => solutions.add(solvedConstraint.id));
					return solvedConstraint;
				}
				return x;
			}),
		);
	};

	const reapplyAllAISolutions = () => {
		replace(
			getValues().list.map((x) => {
				if (revertData.has(x.id)) {
					const solvedConstraint = applyAISolution(revertData.get(x.id)!);
					setSolutionsWithAiApplied((solutions) => solutions.add(solvedConstraint.id));
					return solvedConstraint;
				}
				return x;
			}),
		);
	};
	const tick = useTick();
	useEffect(() => {
		if (area.name !== "settings-current" && area.name !== "settings-enhanced") {
			noop(stepData);
			tick()
				.then(() => applyAllAISolutions())
				.catch(noop);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stepData]);

	useStepSync({ reset, toggleDirty, isDirty: formState.isDirty, stepData });

	const { data: selectableRiskConstraints, isFetching: isFetchingSelectables } = useQueryNoRefetch(
		["getStepRiskConstraintData", stepMetadata.processing],
		{
			enabled: !stepMetadata.processing,
			queryFn: async () => {
				const data = await match(context)
					.with({ area: { name: "enhance" } }, (x) =>
						axiosExtract(
							enhanceInvestmentApi.getEnhancementConfigurationSelectableRiskConstraints(x.area.portfolioUid),
						),
					)
					.with({ area: { name: "settings-enhanced" } }, (x) =>
						axiosExtract(
							staticController.getStaticConfigurationSelectableRiskConstraints(
								x.area.portfolioUid,
								allStepsData.mainInfo.investmentReference?.identifier,
								allStepsData.mainInfo.investmentReference?.type,
							),
						),
					)
					.with({ area: { name: "settings-current" } }, (x) =>
						axiosExtract(
							staticController.getStaticConfigurationSelectableRiskConstraints(
								x.area.portfolioUid,
								allStepsData.mainInfo.investmentReference?.identifier,
								allStepsData.mainInfo.investmentReference?.type,
							),
						),
					)
					.with({ area: { name: "create" } }, () =>
						axiosExtract(createInvestmentApi.getCreationConfigurationSelectableRiskConstraints()),
					)
					.with({ area: { name: "draft" } }, (x) =>
						axiosExtract(draftInvestmentApi.getDraftConfigurationSelectableRiskConstraints(x.area.portfolioUid)),
					)
					.exhaustive();

				const constraintTemplates = constraintTemplatesHelper({
					var: {
						subType: null,
						suggestedMaxValue: data.availableVarConstraints?.[0]?.suggestedMaxValue ?? null,
						suggestedMinValue: data.availableVarConstraints?.[0]?.suggestedMinValue ?? null,
						suggestedValue: data.availableVarConstraints?.[0]?.suggestedValue ?? null,
						value:
							data.availableVarConstraints?.[0]?.selectedValue ??
							data.availableVarConstraints?.[0]?.suggestedValue ??
							0,
						target: data.availableVarConstraints?.[0]?.target ?? false,
						validity: data.availableVarConstraints?.[0]?.validity ?? ConstraintValidity.Unchecked,
					},
					volatility: {
						subType: null,
						suggestedMaxValue: data.availableVolatilityConstraints?.[0]?.suggestedMaxValue ?? null,
						suggestedMinValue: data.availableVolatilityConstraints?.[0]?.suggestedMinValue ?? null,
						suggestedValue: data.availableVolatilityConstraints?.[0]?.suggestedValue ?? null,
						value:
							data.availableVolatilityConstraints?.[0]?.selectedValue ??
							data.availableVolatilityConstraints?.[0]?.suggestedValue ??
							0,
						target: data.availableVolatilityConstraints?.[0]?.target ?? false,
						validity: data.availableVolatilityConstraints?.[0]?.validity ?? ConstraintValidity.Unchecked,
					},
					maxTrackingError: {
						investmentReference: data.availableTargetTrackingError?.investmentReference ?? null,
						value:
							data.availableTargetTrackingError?.selectedValue ??
							data.availableTargetTrackingError?.suggestedValue ??
							0,
						suggestedMaxValue: data.availableTargetTrackingError?.suggestedMaxValue ?? null,
						suggestedMinValue: data.availableTargetTrackingError?.suggestedMinValue ?? null,
						suggestedValue: data.availableTargetTrackingError?.suggestedValue ?? null,
						validity: ConstraintValidity.Unchecked,
						target: data.availableTargetTrackingError?.target ?? false,
					},
				});

				return { constraintTemplates, selectableData: data };
			},
		},
	);

	const constraints = watch("list");
	const isCalculating = stepMetadata.processing;

	const isMaxTrackingErrorDisabled =
		selectableRiskConstraints?.selectableData.availableTargetTrackingError?.investmentReference === undefined;

	const constraintAdders = useMemo<Array<ConstraintAdder<keyof RiskConstraintTypePayloadMap>>>(
		() => [
			{
				maxOfType: 1,
				label: "Max tracking error",
				type: "maxTrackingError",
				limitTooltip: isFetchingSelectables
					? "Loading..."
					: isCalculating
					  ? "Sphere is calculating the constraint limits"
					  : isMaxTrackingErrorDisabled
					    ? "Please select a reference before adding max tracking error"
					    : "You have already set max tracking error",
				disabled: isMaxTrackingErrorDisabled,
				status: isCalculating ? "calculating" : undefined,
			},
			{
				maxOfType: 1,
				label: "VaR",
				type: "var",
				disabled:
					isCalculating ||
					!(selectableRiskConstraints && selectableRiskConstraints?.selectableData?.availableVarConstraints?.length) ||
					constraints.some((c) => c.type === "volatility"),
				limitTooltip: isFetchingSelectables
					? "Loading..."
					: constraints.some((c) => c.type === "volatility")
					  ? "You can only set volatility or var, not both."
					  : isCalculating
					    ? "Sphere is calculating the constraint limits"
					    : "You have already set VaR",
				status: isCalculating ? "calculating" : undefined,
			},
			{
				maxOfType: 1,
				label: "Volatility",
				type: "volatility",
				disabled:
					isCalculating ||
					!(selectableRiskConstraints && selectableRiskConstraints?.selectableData?.availableVarConstraints?.length) ||
					constraints.some((c) => c.type === "var"),
				limitTooltip: isFetchingSelectables
					? "Loading..."
					: constraints.some((c) => c.type === "var")
					  ? "You can only set volatility or var, not both."
					  : isCalculating
					    ? "Sphere is calculating the constraint limits"
					    : "You have already set Volatility",
				status: isCalculating ? "calculating" : undefined,
			},
		],
		[isFetchingSelectables, isCalculating, isMaxTrackingErrorDisabled, selectableRiskConstraints, constraints],
	);

	const stepToRequestMapper = useMemo(() => makeStepToRequestMapper(area.portfolioUid), [area.portfolioUid]);
	const onSubmitAsync = useHandleSubmitToCustomSubmitHandlerInContext({
		context,
		stepName: "riskConstraints",
		handleSubmit,
		baseSubmitFn: async (values) => {
			try {
				const newStepData = await saveHandlers.riskConstraints(values);
				onStepDataChange(newStepData, { skipMetadataUpdate: true, persist: true });
				// reset(newStepData);
				trackMixPanelEvent("Portfolio-Draft", {
					Type: area.portfolioUid ? "enhance" : "creation",
					UID: area.portfolioUid,
					Action: "save",
					Step: "risk-constraints",
					...values,
				});
			} catch (err) {
				onStepError();
				reportPlatformError(err, "ERROR", "portfolio-creation", {
					message: "Save risk constraints",
					payload: JSON.stringify(values),
				});
				throw err;
			}
		},
		onInvalid: () => replace(constraints.map((x) => ({ ...x, readonly: false }))),
	});

	return (
		<StepBase title="Risk constraints" optional={stepMetadata.optional} onSubmitAsync={() => onSubmitAsync()}>
			<DataDisplayOverlay
				dataProvider={() => ({
					formData: watch(),
					requestBody: stepToRequestMapper.riskConstraints(watch()),
				})}
				dataSource="Risk Constraints"
			/>
			<div className="mb-6" data-qualifier="portfolioWizard/riskConstraints/hint">
				{(area.name === "create" || area.name === "enhance") &&
					area.editable &&
					"In this step, you will establish risk constraints for your portfolio."}
				Risk constraints help manage and control the level of risk in your portfolio. Define parameters such as
				value-at-risk, or other risk measures to guide Sphere in the portfolio construction process.
			</div>
			{isFetchingSelectables && <ProgressBar value="indeterminate" />}
			{edit && (
				<AddConstraintSection
					constraintAdders={constraintAdders}
					constraintList={constraints}
					onAdd={(type) => {
						if (selectableRiskConstraints) {
							append(selectableRiskConstraints.constraintTemplates[type]());
						}
					}}
				/>
			)}
			{!isCalculating &&
				area.name !== "settings-current" &&
				area.name !== "settings-enhanced" &&
				revertData.size > 0 && (
					<div className="mb-4">
						<Banner
							severity="error"
							overrides={{
								background: themeCSSVars.palette_graph_B50,
								iconColor: themeCSSVars.palette_graph_B500,
								icon: "Ask-ai",
							}}
							title="Feasibility Adjustments Applied"
						>
							<div>We&apos;ve adjusted your constraints for feasibility. Review them or return to original values.</div>
							<div className="mt-1">
								{constraints.some((x) => x.validity === "UNCHECKED") && (
									<button type="button" className="underline font-bold" onClick={() => reapplyAllAISolutions()}>
										Reapply Adjustments
									</button>
								)}
							</div>
						</Banner>
					</div>
				)}
			<div className="mb-6">
				<div className="font-bold text-[16px] mb-2">Selected constraints</div>
			</div>
			{constraints.length === 0 ? (
				<div className="flex-1 min-h-0 flex flex-col">
					<IconWalls.ConstraintNotSet constraintName="risk constraint" />
				</div>
			) : (
				<FormProvider {...form}>
					<ConstraintListSection
						constraints={constraints}
						onChange={replace}
						reOrderable={false}
						// Force remounting components when the list order or size changes. Without this, we have
						// issues with how react-hook-form handles inputs in this page.
						key={constraints.map((x) => x.id).join(";")}
					>
						{(props) => (
							<DefaultConstraintListItem {...props} actions={!isCalculating && edit}>
								<RiskConstraintListItem
									item={props.item}
									control={control}
									index={props.index}
									editable={!isCalculating}
									selectableData={selectableRiskConstraints?.selectableData}
									solvedWithAI={
										/* true ||  */
										// revertData.has(props.item.id
										solutionsWithAiApplied.has(props.item.id)
										// props.item.validity === ConstraintValidity.Invalid
									}
									revertData={revertData.get(props.item.id)}
									onConstraintChange={() => {
										replace(
											getValues().list.map((x) =>
												x.id === props.item.id ? { ...x, validity: ConstraintValidity.Unchecked } : x,
											),
										);
									}}
								/>
							</DefaultConstraintListItem>
						)}
					</ConstraintListSection>
				</FormProvider>
			)}
		</StepBase>
	);
}

//#region Var Constraint
function VarColumns({
	item,
	control,
	index,
	editable,
	availableVarConstraints,
	solvedWithAI,
	onConstraintChange,
	revertData,
}: ConstraintSpecificColumnsProps<EditableRiskConstraintHelper<"var">> & {
	editable: boolean;
	availableVarConstraints?: SelectableRiskConstraintsResponse["availableVarConstraints"];
	solvedWithAI: boolean;
	onConstraintChange(): void;
	revertData?: EditableRiskConstraintHelper<"var">;
}) {
	const options = [
		{ label: "Historical 1Y 95%", value: RiskConstraintType.HistoricalVar951Y, group: "Historical" },
		{ label: "Historical 2Y 95%", value: RiskConstraintType.HistoricalVar952Y, group: "Historical" },
		{ label: "Historical 3Y 95%", value: RiskConstraintType.HistoricalVar953Y, group: "Historical" },
		{ label: "Parametric 1Y 95%", value: RiskConstraintType.ParametricVar951Y, group: "Parametric" },
		{ label: "Parametric 2Y 95%", value: RiskConstraintType.ParametricVar952Y, group: "Parametric" },
		{ label: "Parametric 3Y 95%", value: RiskConstraintType.ParametricVar953Y, group: "Parametric" },
		{ label: "Historical 1Y 97.5%", value: RiskConstraintType.HistoricalVar9751Y, group: "Historical" },
		{ label: "Historical 2Y 97.5%", value: RiskConstraintType.HistoricalVar9752Y, group: "Historical" },
		{ label: "Historical 3Y 97.5%", value: RiskConstraintType.HistoricalVar9753Y, group: "Historical" },
		{ label: "Parametric 1Y 97.5%", value: RiskConstraintType.ParametricVar9751Y, group: "Parametric" },
		{ label: "Parametric 2Y 97.5%", value: RiskConstraintType.ParametricVar9752Y, group: "Parametric" },
		{ label: "Parametric 3Y 97.5%", value: RiskConstraintType.ParametricVar9753Y, group: "Parametric" },
		{ label: "Historical 1Y 99%", value: RiskConstraintType.HistoricalVar991Y, group: "Historical" },
		{ label: "Historical 2Y 99%", value: RiskConstraintType.HistoricalVar992Y, group: "Historical" },
		{ label: "Historical 3Y 99%", value: RiskConstraintType.HistoricalVar993Y, group: "Historical" },
		{ label: "Parametric 1Y 99%", value: RiskConstraintType.ParametricVar991Y, group: "Parametric" },
		{ label: "Parametric 2Y 99%", value: RiskConstraintType.ParametricVar992Y, group: "Parametric" },
		{ label: "Parametric 3Y 99%", value: RiskConstraintType.ParametricVar993Y, group: "Parametric" },
	] satisfies Array<{ label: string; value: RiskConstraintType; group: string }>;

	const { setValue } = useFormContext();
	const optionMap = Map(availableVarConstraints?.filter((x) => x.type).map((x) => [x.type!, x]));

	return (
		<div className="flex flex-1 space-x-4 mr-4 justify-end">
			<div>
				<div className={titleClassName}>Type</div>
				<div className="w-40">
					{item.readonly || !editable ? (
						options.find(({ value }) => item.subType === value)?.label ?? item.subType
					) : (
						<FormController
							key={item.subType}
							control={control}
							name={`list.${index}.subType`}
							render={({ field: { ref: fieldRef, ...fieldProps }, fieldState }) => {
								const error = Boolean(fieldState.error?.message);
								const invalid = isFieldOnError(error, item.validity, solvedWithAI, item.readonly);
								return (
									<AutoTooltip
										severity="error"
										position="left"
										mode="hover"
										disabled={!invalid}
										trigger={({ innerRef }) => (
											<Select
												data-qualifier={`portfolioWizard/riskConstraints/constraint(${item.type},${index})/subType`}
												{...fieldProps}
												innerRef={multiRef(innerRef, fieldRef)}
												size="x-small"
												classList="w-full"
												options={options}
												invalid={invalid}
												onChange={(newSubType) => {
													if (newSubType === null) {
														return;
													}
													const x = optionMap.get(newSubType);
													setValue(
														`list.${index}`,
														{
															...item,
															subType: newSubType,
															suggestedMinValue: x?.suggestedMinValue,
															suggestedMaxValue: x?.suggestedMaxValue,
															suggestedValue: x?.suggestedValue,
															value:
																x?.suggestedMinValue === undefined ||
																x.suggestedMaxValue === undefined ||
																item.value === null ||
																x?.suggestedValue === undefined
																	? null
																	: item.value > x.suggestedMaxValue || item.value < x.suggestedMinValue
																	  ? x.suggestedValue
																	  : item.value,
														},
														{ shouldValidate: true },
													);
													onConstraintChange();
												}}
											/>
										)}
									>
										{fieldState.error?.message}
									</AutoTooltip>
								);
							}}
						/>
					)}
				</div>
				{/* TODO: translate */}
			</div>
			<div className="flex-1 max-w-[320px]">
				<div className={titleClassName}>&nbsp;</div>
				<FormController
					key={item.subType}
					control={control}
					name={`list.${index}.value`}
					render={({ field: { ref: fieldRef, value, onChange }, fieldState }) => {
						const error = Boolean(fieldState.error?.message);
						const invalid = isFieldOnError(error, item.validity, solvedWithAI, item.readonly);
						return (
							<AutoTooltip
								severity="error"
								position="left"
								mode="hover"
								disabled={solvedWithAI ? undefined : !invalid}
								overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
								trigger={({ innerRef }) => (
									<Slider
										data-qualifier={`portfolioWizard/riskConstraints/constraint(${item.type},${index})/value`}
										classList={{
											"flex-1 relative -top-4": true,
											[`[&>div:nth-child(2)>input]:!border-[color:${themeCSSVars.palette_graph_B500}]`]: solvedWithAI,
										}}
										value={Number(value || "0")}
										min={Math.min(item.suggestedMinValue ?? 0, Number(value || "0"))}
										max={Math.max(item.suggestedMaxValue ?? 100, Number(value || "0"))}
										stepTextProvider={(v) => `${v}%`}
										step={0.01}
										onChange={onChange}
										onCommit={onConstraintChange}
										input={{
											icon: "Percentile",
											invalid: invalid && !solvedWithAI,
											innerRef: multiRef(fieldRef, innerRef),
										}}
										invalid={invalid && !solvedWithAI}
										handleBorderColor={solvedWithAI ? themeCSSVars.palette_graph_B500 : undefined}
										fillColor={
											solvedWithAI ? themeCSSVars.palette_graph_B500 : invalid ? themeCSSVars.palette_D500 : undefined
										}
										disabled={!editable || item.readonly || item.subType === null}
									/>
								)}
							>
								{fieldState.error?.message ?? (
									<Suggestion
										suggestedMaxWeight={item.suggestedMaxValue}
										suggestedMinWeight={item.suggestedMinValue}
										solvedWithAI={solvedWithAI}
										weight={item.value}
										originalValues={{ weight: revertData?.value ?? null }}
									/>
								)}
							</AutoTooltip>
						);
					}}
				/>
			</div>
		</div>
	);
}
//#endregion

//#region Volatility Constraint
function VolatilityColumns({
	item,
	control,
	index,
	editable,
	solvedWithAI,
	onConstraintChange,
	availableVolatilityConstraints,
	revertData,
}: ConstraintSpecificColumnsProps<EditableRiskConstraintHelper<"volatility">> & {
	editable: boolean;
	availableVolatilityConstraints?: SelectableRiskConstraintsResponse["availableVolatilityConstraints"];
	solvedWithAI: boolean;
	onConstraintChange(): void;
	revertData?: EditableRiskConstraintHelper<"volatility">;
}) {
	const options = [
		{ label: "Volatility 6M", value: RiskConstraintType.Volatility6M },
		{ label: "Volatility 1Y", value: RiskConstraintType.Volatility1Y },
		{ label: "Volatility 2Y", value: RiskConstraintType.Volatility2Y },
		{ label: "Volatility 3Y", value: RiskConstraintType.Volatility3Y },
	] satisfies Array<{ label: string; value: RiskConstraintType }>;

	const { setValue } = useFormContext();
	const optionMap = Map(availableVolatilityConstraints?.filter((x) => x.type).map((x) => [x.type!, x]));

	return (
		<>
			<div className="flex flex-1 space-x-4 mr-4 justify-end">
				<div>
					<div className={titleClassName}>Type</div>
					<div className="w-40">
						{item.readonly || !editable ? (
							options.find(({ value }) => item.subType === value)?.label ?? item.subType
						) : (
							<FormController
								key={item.subType}
								control={control}
								name={`list.${index}.subType`}
								render={({ field: { ref: fieldRef, ...fieldProps }, fieldState }) => {
									const error = Boolean(fieldState.error?.message);
									const invalid = isFieldOnError(error, item.validity, solvedWithAI, item.readonly);
									return (
										<AutoTooltip
											severity="error"
											position="left"
											mode="hover"
											disabled={!invalid}
											trigger={({ innerRef }) => (
												<Select
													data-qualifier={`portfolioWizard/riskConstraints/constraint(${item.type},${index})/subType`}
													{...fieldProps}
													innerRef={multiRef(innerRef, fieldRef)}
													size="x-small"
													classList="w-full"
													options={options}
													invalid={invalid}
													onChange={(newSubType) => {
														if (newSubType === null) {
															return;
														}
														const x = optionMap.get(newSubType);
														setValue(
															`list.${index}`,
															{
																...item,
																subType: newSubType,
																suggestedMinValue: x?.suggestedMinValue,
																suggestedMaxValue: x?.suggestedMaxValue,
																suggestedValue: x?.suggestedValue,
																value:
																	x?.suggestedMinValue === undefined ||
																	x.suggestedMaxValue === undefined ||
																	item.value === null ||
																	x?.suggestedValue === undefined
																		? null
																		: item.value > x.suggestedMaxValue || item.value < x.suggestedMinValue
																		  ? x.suggestedValue
																		  : item.value,
															},
															{ shouldValidate: true },
														);
														onConstraintChange();
													}}
												/>
											)}
										>
											{fieldState.error?.message}
										</AutoTooltip>
									);
								}}
							/>
						)}
					</div>
					{/* TODO: translate */}
				</div>
				<div className="flex-1 max-w-[320px]">
					<div className={titleClassName}>&nbsp;</div>
					<div className="flex flex-1">
						<FormController
							key={item.subType}
							control={control}
							name={`list.${index}.value`}
							render={({ field: { ref: fieldRef, value, onChange }, fieldState }) => {
								const error = Boolean(fieldState.error?.message);
								const invalid = isFieldOnError(error, item.validity, solvedWithAI, item.readonly);

								return (
									<AutoTooltip
										severity="error"
										position="left"
										mode="hover"
										disabled={solvedWithAI ? undefined : !invalid}
										overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
										trigger={({ innerRef }) => (
											<Slider
												data-qualifier={`portfolioWizard/riskConstraints/constraint(${item.type},${index})/value`}
												classList={{
													"flex-1 relative -top-4": true,
													[`[&>div:nth-child(2)>input]:!border-[color:${themeCSSVars.palette_graph_B500}]`]:
														solvedWithAI,
												}}
												value={Number(value || "0")}
												min={item.suggestedMinValue ?? 0}
												max={item.suggestedMaxValue ?? 100}
												stepTextProvider={(v) => `${v}%`}
												step={0.01}
												onChange={onChange}
												onCommit={onConstraintChange}
												input={{
													icon: "Percentile",
													invalid: invalid && !solvedWithAI,
													innerRef: multiRef(fieldRef, innerRef),
												}}
												invalid={invalid && !solvedWithAI}
												handleBorderColor={solvedWithAI ? themeCSSVars.palette_graph_B500 : undefined}
												fillColor={
													solvedWithAI
														? themeCSSVars.palette_graph_B500
														: invalid
														  ? themeCSSVars.palette_D500
														  : undefined
												}
												disabled={!editable || item.readonly || item.subType === null}
											/>
										)}
									>
										{fieldState.error?.message ?? (
											<Suggestion
												suggestedMaxWeight={item.suggestedMaxValue}
												suggestedMinWeight={item.suggestedMinValue}
												solvedWithAI={solvedWithAI}
												weight={item.value}
												originalValues={{ weight: revertData?.value ?? null }}
											/>
										)}
									</AutoTooltip>
								);
							}}
						/>
					</div>
				</div>
			</div>
		</>
	);
}
//#endregion

//#region Tracking Error Constraint
function TrackingErrorColumns({
	item,
	control,
	index,
	editable,
	availableInvestmentReferences,
	enableSelect,
	solvedWithAI,
	onConstraintChange,
	revertData,
}: ConstraintSpecificColumnsProps<EditableRiskConstraintHelper<"maxTrackingError">> & {
	editable: boolean;
	availableInvestmentReferences?: Array<InvestmentReferenceDTO>;
	enableSelect?: boolean;
	solvedWithAI: boolean;
	onConstraintChange(): void;
	revertData?: EditableRiskConstraintHelper<"maxTrackingError">;
}) {
	const { t } = useTranslation();
	const options: Option<InvestmentReferenceDTO>[] = (availableInvestmentReferences ?? []).map((reference) => ({
		label: reference.name ?? "-",
		value: reference ?? "",
		disabled: !reference.available,
		group: t(`INVESTMENT_REFERENCE_CATEGORIES.${reference.type!}`),
	}));

	return (
		<>
			<div className="flex flex-1 space-x-4 mr-4 justify-end">
				<div>
					<div className={titleClassName}>Reference</div>
					<div className="w-40">
						{!enableSelect || item.readonly ? (
							<div title={item.investmentReference?.name} className="truncate pr-1">
								{item.investmentReference?.name}
							</div>
						) : (
							<FormController
								key={item.investmentReference?.identifier}
								control={control}
								name={`list.${index}.investmentReference`}
								render={({ field: { ref: fieldRef, onChange, ...fieldProps }, fieldState }) => {
									const error = Boolean(fieldState.error?.message);
									const invalid = isFieldOnError(error, item.validity, solvedWithAI, item.readonly);

									return (
										<AutoTooltip
											severity="error"
											position="left"
											mode="hover"
											disabled={!invalid}
											trigger={({ innerRef }) => (
												<Select
													data-qualifier={`portfolioWizard/riskConstraints/constraint(${item.type},${index})/investmentReference`}
													{...fieldProps}
													onChange={(v) => {
														onChange(v);
														onConstraintChange();
													}}
													innerRef={multiRef(innerRef, fieldRef)}
													size="x-small"
													classList="w-full"
													options={options}
													invalid={invalid}
													disabled={!editable}
												/>
											)}
										>
											{fieldState.error?.message}
										</AutoTooltip>
									);
								}}
							/>
						)}
					</div>
				</div>
				<div className="flex-1 max-w-[400px]">
					<div className={titleClassName}>&nbsp;</div>
					<div>
						<FormController
							control={control}
							name={`list.${index}.value`}
							render={({ field: { ref: fieldRef, value, onChange }, fieldState }) => {
								const error = Boolean(fieldState.error?.message);
								const invalid = isFieldOnError(error, item.validity, solvedWithAI, item.readonly);
								return (
									<AutoTooltip
										severity="error"
										position="left"
										mode="hover"
										disabled={solvedWithAI ? undefined : !invalid}
										overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
										trigger={({ innerRef }) => (
											<Slider
												data-qualifier={`portfolioWizard/riskConstraints/constraint(${item.type},${index})/value`}
												classList={{
													"flex-1 relative -top-4": true,
													[`[&>div:nth-child(2)>input]:!border-[color:${themeCSSVars.palette_graph_B500}]`]:
														solvedWithAI,
												}}
												value={Number(value || "0")}
												min={item.suggestedMinValue ?? 0}
												max={item.suggestedMaxValue ?? 100}
												stepTextProvider={(v) => `${v}%`}
												step={0.01}
												onChange={onChange}
												onCommit={onConstraintChange}
												input={{
													icon: "Percentile",
													invalid: invalid && !solvedWithAI,
													innerRef: multiRef(fieldRef, innerRef),
												}}
												invalid={invalid && !solvedWithAI}
												handleBorderColor={solvedWithAI ? themeCSSVars.palette_graph_B500 : undefined}
												fillColor={
													solvedWithAI
														? themeCSSVars.palette_graph_B500
														: invalid
														  ? themeCSSVars.palette_D500
														  : undefined
												}
												disabled={!editable || item.readonly}
											/>
										)}
									>
										{fieldState.error?.message ?? (
											<Suggestion
												suggestedMaxWeight={item.suggestedMaxValue}
												suggestedMinWeight={item.suggestedMinValue}
												solvedWithAI={solvedWithAI}
												weight={item.value}
												originalValues={{ weight: revertData?.value ?? null }}
											/>
										)}
									</AutoTooltip>
								);
							}}
						/>
					</div>
				</div>
			</div>
		</>
	);
}
//#endregion

//#region List of the Constraints
function RiskConstraintListItem({
	item,
	control,
	index,
	editable,
	selectableData,
	revertData,
	...forward
}: ConstraintSpecificColumnsProps<EditableRiskConstraintHelper<keyof RiskConstraintTypePayloadMap>> & {
	editable: boolean;
	selectableData?: SelectableRiskConstraintsResponse;
	solvedWithAI: boolean;
	onConstraintChange(): void;
	revertData?: EditableRiskConstraintHelper<keyof RiskConstraintTypePayloadMap>;
}): JSX.Element {
	return (
		<>
			<div className="flex mr-8 w-[140px]">
				<div className="flex-1">
					<div className={titleClassName}>{constraintCategoryToLabel[item.type].constraintType}</div>
					<div>
						<AutoTooltip
							position="left"
							overrideColor={themeCSSVars.global_palette_neutral_300}
							trigger={({ innerRef }) => (
								<span className="hover:font-medium hover:underline" ref={innerRef}>
									{constraintCategoryToLabel[item.type].label}
								</span>
							)}
						>
							<TooltipContent>
								<div className="font-bold mb-2">{constraintCategoryToLabel[item.type].label}</div>
								<div>{constraintCategoryToLabel[item.type].tooltip}</div>
							</TooltipContent>
						</AutoTooltip>
					</div>
				</div>
				<div>
					{forward.solvedWithAI && (
						<Icon classList="ml-auto mt-[26px]" icon="Ask-ai" size={16} color={themeCSSVars.palette_graph_B500} />
					)}
				</div>
			</div>
			<div className="flex flex-1">
				{(function () {
					switch (item.type) {
						case "var":
							return (
								<VarColumns
									index={index}
									item={item}
									control={control as ConstraintSpecificColumnsProps<EditableRiskConstraintHelper<"var">>["control"]}
									editable={editable}
									availableVarConstraints={selectableData?.availableVarConstraints}
									revertData={revertData as EditableRiskConstraintHelper<"var"> | undefined}
									{...forward}
								/>
							);
						case "volatility":
							return (
								<VolatilityColumns
									index={index}
									item={item}
									control={
										control as ConstraintSpecificColumnsProps<EditableRiskConstraintHelper<"volatility">>["control"]
									}
									editable={editable}
									availableVolatilityConstraints={selectableData?.availableVolatilityConstraints}
									revertData={revertData as EditableRiskConstraintHelper<"volatility"> | undefined}
									{...forward}
								/>
							);
						case "maxTrackingError":
							return (
								<TrackingErrorColumns
									index={index}
									item={item}
									control={
										control as ConstraintSpecificColumnsProps<
											EditableRiskConstraintHelper<"maxTrackingError">
										>["control"]
									}
									editable={editable}
									availableInvestmentReferences={selectableData?.availableInvestmentReferences}
									revertData={revertData as EditableRiskConstraintHelper<"maxTrackingError"> | undefined}
									{...forward}
								/>
							);
					}
				})()}

				{item.type !== "maxTrackingError" && (
					<div className="flex space-x-4 ml-auto">
						<div className="">
							<div className={titleClassName}>Soft constraint</div>
							<div className="flex justify-center">
								<FormController
									control={control}
									name={`list.${index}.target`}
									render={({ field: { ref: fieldRef, onChange, value, onBlur } }) => (
										<Checkbox
											data-qualifier={`portfolioWizard/riskConstraints/constraint(${item.type},${index})/target`}
											disabled={item.readonly || !editable}
											checked={value}
											innerRef={fieldRef}
											onChange={(target) => {
												onChange(target);
												onBlur();
											}}
										/>
									)}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
}

//#endregion

const constraintCategoryToLabel = {
	maxTrackingError: {
		label: "Max tracking error",
		tooltip: "The difference in actual performance between a position and its corresponding reference.",
		constraintType: "",
	},
	var: {
		label: "VaR",
		tooltip:
			"The Value at Risk (VaR) indicates the probability (95%, 97.5%, 99%) that the portfolio will not incur a loss equal to the chosen level over the selected period.",
		constraintType: "Target",
	},
	volatility: {
		label: "Volatility",
		tooltip:
			"Volatility is a statistical measure of the dispersion of returns for a given instrument. This constraint sets the maximum level over a year period.",
		constraintType: "Target",
	},
} satisfies Record<
	keyof RiskConstraintTypePayloadMap,
	{ label: string; tooltip: string; constraintType: "Target" | "Constraint" | "" }
>;

function Suggestion(props: {
	suggestedMaxWeight: number | null;
	suggestedMinWeight: number | null;
	weight: number | null;
	solvedWithAI: boolean;
	originalValues?: { weight: number | null };
}) {
	const { formatNumber } = useLocaleFormatters();

	const constraintSubtitle = useMemo(
		() => (
			<>
				<p>
					Closest feasible solution - <i>Min</i>: {formatNumber(props.suggestedMinWeight, 2)}%
				</p>
				<p>
					Closest feasible solution - <i>Max</i>: {formatNumber(props.suggestedMaxWeight, 2)}%
				</p>
			</>
		),
		[formatNumber, props.suggestedMaxWeight, props.suggestedMinWeight],
	);

	if (props.solvedWithAI) {
		return (
			<TooltipContent>
				<div className="flex gap-2">
					<Icon icon="Ask-ai" color={themeCSSVars.palette_B500} />
					<Text as="span" type="Body/M/Bold">
						Feasibility Adjustments Applied
					</Text>
				</div>
				{props.originalValues && <p>{`Original values - ${formatNumber(props.originalValues?.weight)}%`}</p>}
				{constraintSubtitle}
			</TooltipContent>
		);
	}

	return (
		<TooltipContent>
			<p className="font-bold mb-2">Constraint not feasible</p>
			{constraintSubtitle}
		</TooltipContent>
	);
}

function isFieldOnError(
	error: boolean,
	validity: ConstraintValidity,
	solvedWithAI: boolean,
	readonly: boolean,
): boolean {
	if (readonly) {
		return false;
	}

	if (solvedWithAI) {
		return error;
	}

	return error || validity === ConstraintValidity.Invalid;
}

function applyAISolution<T extends EditableRiskConstraint>(x: T): T {
	switch (x.type) {
		case "var":
			return {
				...x,
				value: x.suggestedValue,
				validity: ConstraintValidity.Invalid,
			} satisfies T;
		case "volatility":
			return {
				...x,
				value: x.suggestedValue,
				validity: ConstraintValidity.Invalid,
			} satisfies T;
		case "maxTrackingError":
			return {
				...x,
				value: x.suggestedValue,
				validity: ConstraintValidity.Invalid,
			} satisfies T;
	}
}
