import { Button, CircularProgressBar, Icon, Text } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { SphereAIAvatar } from "../icons/SphereAIAvatar";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { SmartTextArea } from "../inputs/SmartTextArea";
import { AIConversationsControllerApiFactory, type AiConversationMessageTypeEnum } from "$root/api/api-gen";
import { SendIcon } from "../icons/SendIcon";
import { useAsync } from "@mdotm/mdotui/headless";
import { unpromisify } from "@mdotm/mdotui/utils";
import type { FeedbackHandType } from "../icons/FeedbackHands";
import { FeedbackHand, FeedbackHandTypes } from "../icons/FeedbackHands";
import { useApiGen } from "$root/api/hooks";
import { FunctionalAreasContext } from "$root/App/context";
import { MarkdownRenderer } from "$root/components/MarkdownRenderer/MarkdownRenderer";
import type { QueryObserverBaseResult } from "@tanstack/react-query";
import type { ChatMessage } from "../SphereAIChatSection";

const CopyToClipboardButton = ({ messageId }: { messageId?: string }) => {
	const [isCopied, setIsCopied] = useState(false);
	const copyToClipboard = useCallback(async (id?: string) => {
		try {
			if (id) {
				const txt = document.getElementById(id);
				await navigator.clipboard.writeText(txt?.innerText ?? "");
				setIsCopied(true);
				setTimeout(() => {
					setIsCopied(false);
				}, 1000);
			}
		} catch (e) {
			console.log(e);
		}
	}, []);

	return (
		<button
			onClick={unpromisify(() => copyToClipboard(messageId))}
			disabled={isCopied || !messageId}
			type="button"
			className="active:scale-90 transition-transform"
		>
			<Icon icon="Content-Copy" size={16} color={isCopied ? themeCSSVars.palette_N500 : themeCSSVars.palette_N300} />
		</button>
	);
};

export function AIBox({
	children,
	mode = "normal",
	feedbackValue,
	type = "AI_RESPONSE",
	messageId,
	conversationId,
	refetchConversation,
	rerenderList,
	isLastMessage,
	onlySuggestion,
}: {
	children: string;
	mode?: "error" | "normal";
	feedbackValue?: number;
	type?: AiConversationMessageTypeEnum;
	messageId: string | undefined;
	conversationId: string | null;
	refetchConversation: QueryObserverBaseResult<{
		remappedMessages: Array<ChatMessage>;
	}>["refetch"];
	rerenderList: () => void;
	isLastMessage: boolean;
	onlySuggestion: boolean;
}): JSX.Element {
	const feedbackMap = {
		negative: 1,
		positive: 5,
	};
	const feedRevMap = {
		1: "negative",
		5: "positive",
	} satisfies Record<number, FeedbackHandType>;
	const needFeedback = type === "AI_RESPONSE";
	const AIConversationsApi = useApiGen(AIConversationsControllerApiFactory);
	const { state } = useContext(FunctionalAreasContext);
	const [showThankYou, setShowThankYou] = useState(Boolean(feedbackValue));
	const [feedbackText, setFeedbackText] = useState("");
	const [evaluation, setEvaluation] = useState<FeedbackHandType | null>(
		useMemo(() => {
			const evalInit = feedbackValue ? feedRevMap[feedbackValue as keyof typeof feedRevMap] : null;
			return evalInit;
		}, [feedRevMap, feedbackValue]),
	);

	const { run: doSubmit, loading: sending } = useAsync<void, [FeedbackHandType]>({
		asyncFn: async (feedbackType) => {
			const currentFeedbackText = feedbackText;
			if (messageId === undefined || !conversationId) {
				console.log(`Missing some feedback parameters (conversationId:${conversationId}, messageId:${messageId})`);
				return;
			}
			try {
				await AIConversationsApi.setConversationMessageFeedback(conversationId, {
					rating: feedbackMap[feedbackType],
					message: feedbackText,
					uuid: messageId,
					area: "",
				});
				changeEvaluation(feedbackType);
				await refetchConversation();
			} catch (err) {
				setFeedbackText(currentFeedbackText);
				throw err;
			}
			rerenderList();
		},
	});

	const changeEvaluation = useCallback(
		(newEvaluation: typeof evaluation) => {
			setEvaluation(newEvaluation);
			setFeedbackText("");
			rerenderList();
		},
		[rerenderList],
	);

	useEffect(() => {
		setShowThankYou(Boolean(feedbackValue));
	}, [feedRevMap, feedbackValue]);

	return (
		<div
			className={
				mode === "normal"
					? `bg-[${themeCSSVars.palette_N20}] px-8 py-4 border-y border-y-[${themeCSSVars.palette_N100}]`
					: `bg-[${themeCSSVars.palette_W50}] px-8 py-4 border-y border-y-[${themeCSSVars.palette_W200}]`
			}
		>
			<div className="flex items-start">
				<div className="shrink-0">
					<SphereAIAvatar style={{ width: 24 }} mode={mode} />
				</div>
				<div className="flex-1 w-full">
					<Text type="Body/L/Book" as="div" color={themeCSSVars.palette_N700} classList="ml-3 mb-2" id={messageId}>
						<MarkdownContent onValueSelection={!onlySuggestion ? state.chat?.appendUserMessageAndSend : undefined}>
							{children}
						</MarkdownContent>
					</Text>
					<div>
						{needFeedback &&
							(!feedbackValue ? (
								!showThankYou ? (
									<>
										<div className="flex justify-center">
											{sending && evaluation === "positive" ? (
												<CircularProgressBar classList="w-4 relative top-1" value="indeterminate" />
											) : (
												<div className="flex justify-center">
													<div className="flex items-center space-x-1">
														<CopyToClipboardButton messageId={messageId} />
														{FeedbackHandTypes.map((feedbackType) => (
															<Button
																key={feedbackType}
																unstyled
																onClick={() => {
																	if (feedbackType === "positive") {
																		unpromisify(() => doSubmit(feedbackType))();
																	} else {
																		changeEvaluation(feedbackType);
																	}
																}}
															>
																<FeedbackHand type={feedbackType} active={evaluation === feedbackType} />
															</Button>
														))}
													</div>
												</div>
											)}
										</div>
										{evaluation === "negative" && (
											<div className="relative z-0 min-w-0 flex-grow mt-2">
												<SmartTextArea
													onChangeText={setFeedbackText}
													value={feedbackText}
													onEnter={feedbackText.length === 0 ? undefined : unpromisify(() => doSubmit("negative"))}
													maxRows={1}
													minRows={1}
												/>
												<button
													type="button"
													className="absolute bottom-0 right-2 disabled:cursor-default cursor-pointer z-20"
													disabled={feedbackText.length === 0 || sending}
													onClick={unpromisify(() => doSubmit("negative"))}
												>
													{!sending ? (
														<div className="relative bottom-1">
															<SendIcon color={sending ? themeCSSVars.palette_N300 : "#4CB09C"} />
														</div>
													) : (
														<CircularProgressBar classList="w-4 relative top-1" value="indeterminate" />
													)}
												</button>
											</div>
										)}
									</>
								) : (
									<div className={`rounded m-1 p-2 bg-[${themeCSSVars.global_palette_chart_blueberry_100}]`}>
										<Text type="Body/L/Bold" as="div" color={themeCSSVars.palette_graph_B500}>
											Thanks for your feedback
										</Text>
									</div>
								)
							) : (
								<div className="flex justify-center space-x-2">
									<CopyToClipboardButton messageId={messageId} />
									<FeedbackHand type="negative" active={evaluation === "negative"} />
									<FeedbackHand type="positive" active={evaluation === "positive"} />
								</div>
							))}
					</div>
				</div>
			</div>
		</div>
	);
}

function MarkdownContent({
	children,
	onValueSelection,
}: {
	children: string;
	onValueSelection?: (value: string) => void;
}): JSX.Element {
	return (
		<MarkdownRenderer
			componentOverrides={{
				a: ({ node: _node, ...props }) => (
					<button
						type="button"
						className={onValueSelection ? `underline` : `!cursor-default`}
						onClick={() => {
							if (!onValueSelection) {
								return;
							}
							onValueSelection(String(props.children[0]));
						}}
					>
						{props.children[0]}
					</button>
				),
			}}
		>
			{children}
		</MarkdownRenderer>
	);
}
