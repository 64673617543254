import { useEffect, useRef } from "react";
import { Highcharts } from "$root/third-party-integrations/highcharts-with-modules";
import { PaletteColors } from "$root/styles/themePalette";
import { formatDate } from "$root/localization/formatters";
import { addDays, differenceInDays, parseISO } from "date-fns";
import useWidgetsData from "$root/hooks/useWidgetsData";
import { noop } from "@mdotm/mdotui/utils";
import { useEventListener } from "@mdotm/mdotui/react-extensions";

interface Props {
	width?: string;
	height?: string;
	xAxis: string[];
	series: any[];
	representing: string;
	notInTooltip?: string[];
	valueToTooltip?: any;
	tooltipFunction?: (xValue: string | number, seriesName: string | number, pointValue: any) => string;
	dates: { [key: string]: Array<string | undefined> };
}

const helperDateFn = (currentDate?: string, previousDate?: string) => {
	if (!currentDate || !previousDate) {
		return "-";
	}
	return differenceInDays(
		new Date(new Date(currentDate).toISOString().split("T")[0]),
		new Date(new Date(previousDate).toISOString().split("T")[0]),
	).toString();
};

const SpiderwebChart = (props: Props): JSX.Element => {
	const SpiderChartRef = useRef<HTMLDivElement>(null);
	const { currentWidgetsData } = useWidgetsData();

	const drawSpiderweb = () => {
		if (!props.representing || props.xAxis.length === 0) {
			return;
		}
		Highcharts.chart(
			"spiderweb-container",
			{
				chart: {
					polar: true,
					style: {
						fontFamily: "Gotham",
					},
				},

				title: undefined,

				pane: {
					size: "70%",
				},

				exporting: {
					enabled: false,
				},

				xAxis: {
					categories: props.xAxis,
					lineColor: PaletteColors.PALE_GREY_TWO,
					tickmarkPlacement: "on",
					lineWidth: 0,
					gridLineWidth: 2,
				},

				yAxis: props.xAxis.map((e, index) => ({
					gridLineInterpolation: "circle",
					visible: true,
					min: 1,
					max: 5,
					tickAmount: 5,
					labels: {
						enabled: false,
					},
					gridLineWidth: 0,
					tickmarkPlacement: "on",
					angle: -((360 / props.xAxis.length) * index),
				})),

				legend: {
					enabled: false,
				},

				tooltip: {
					shared: true,
					useHTML: true,
					distance: 72,
					backgroundColor: "#FFFFFF",
					borderColor: undefined,
					borderRadius: 4,
					borderWidth: 0,
					style: {
						padding: "0",
						color: "#656d78",
						fontSize: "12px",
						boxShadow: "0 2px 10px 0 rgba(47, 53, 65, 0.15)",
					},
					formatter() {
						const name: string = this.x.toString();

						let tooltip_html: string =
							"<div class='DivTooltip !w-full'><div class='Tooltip-title' style='color: #1D2433; background-color: #eeeef1; padding: 0.25rem; border-radius: 0.125rem; font-size: 0.75rem; font-weight: bold; text-align: center; line-height: 1rem'>" +
							name +
							"</div>";
						tooltip_html += "<div class='flex flex-col gap-2'><div>";

						const mapIcon: Record<string, { icon: string; title: string }> = {
							Current: {
								icon: `<div style="width:10px; height: 10px; background-color: #00aeef; border-radius: 5px; margin-right: 3px; margin-top: 3px"></div>`,
								title: "Current",
							},
							Previous: {
								icon: `<div style="width:10px; height: 10px; background-color: #fff; border-radius: 5px; border: 2px solid #9f9fa3; margin-right: 3px; margin-top: 3px"></div>`,
								title: "Previous",
							},
						};

						const [current, previous] = props.dates[name];
						const previousEndDate = current ? String(addDays(parseISO(current), -1)) : undefined;

						const mapDates: Record<string, { date: string; differenceDate: string; lastDate?: string }> = {
							Current: {
								date: formatDate(current),
								differenceDate: helperDateFn(currentWidgetsData.creation_time, current),
							},
							Previous: {
								date: formatDate(previous),
								lastDate: formatDate(previousEndDate),
								differenceDate: helperDateFn(previousEndDate, previous),
							},
						};

						//caching method: ac: { Previous, Current }
						//this works only assumed that series name are Current & Previous (caseSensitive)
						const cacheEntryPoint: Record<string, { [key: string]: number }> = {};

						this.points?.forEach(function (entry) {
							const position = entry.series.name;
							if ((props.notInTooltip || []).indexOf(position) === -1) {
								cacheEntryPoint[entry.key] = { ...cacheEntryPoint[entry.key], [position]: entry.y };
								if (
									position === "Previous" &&
									cacheEntryPoint[entry.key]["Current"] === cacheEntryPoint[entry.key]["Previous"]
								) {
									return "";
								}
								const currentDuration =
									mapDates[position].differenceDate === "-"
										? mapDates[position].differenceDate
										: Number(mapDates[position].differenceDate) + 1;

								const tooltipValue = entry.y;
								tooltip_html +=
									'<div class="relative my-2">' +
									'<div class="absolute top-0 left-0 drop-shadow-xl">' +
									mapIcon[position].icon +
									"</div>" +
									'<div class="flex-column ml-[14px]">' +
									'<p class="m-0 text-[#1D2433] text-[12px] leading-[16px] font-medium" style="font-family: Gotham, serif">' +
									mapIcon[position].title +
									': <strong class="pl-[4px]">' +
									(props.tooltipFunction
										? props.tooltipFunction(name, position, tooltipValue)
										: props.valueToTooltip
										  ? props.valueToTooltip[tooltipValue]
										  : tooltipValue) +
									"</strong>" +
									"</p>" +
									'<p class="text-[#667085] text-[10px] leading-[14px] font-semibold">' +
									`${
										position === "Current"
											? `Since: ${mapDates[position].date} (${currentDuration} ${
													Number(currentDuration) > 1 ? "days" : "day"
											  })`
											: `From: ${mapDates[position].date} to ${mapDates[position].lastDate} (${
													mapDates[position].differenceDate
											  } ${Number(mapDates[position].differenceDate) > 1 ? "days" : "day"})`
									}` +
									"</p>" +
									"</div>" +
									"</div>";
							}
						});

						tooltip_html += "</div></div>";

						return tooltip_html;
					},
				},

				series: props.series,

				responsive: {
					rules: [
						{
							condition: {
								maxWidth: 500,
							},
							chartOptions: {
								pane: {
									size: "70%",
								},
							},
						},
					],
				},
			},
			function (chart: any) {
				for (let i = 0; i <= 5; i++) {
					// @ts-ignore
					chart.yAxis.forEach((axis: any) => {
						const point0 = (
							axis.ticks[i] || {
								getPosition: noop,
							}
						).getPosition();
						if (!point0) {
							return;
						}
						// @ts-ignore
						chart.renderer
							.circle(point0.x, point0.y, 4)
							.attr({
								"stroke-width": 2,
								stroke: PaletteColors.PALE_GREY_TWO,
								fill: PaletteColors.WHITE,
								zIndex: 1,
							})
							.add();
					});
				}
			},
		);
	};

	useEventListener(window, "resize", () => drawSpiderweb(), { passive: true });

	// this is needed for the switch inside portfolio details, redraw utils has a timeout for performances reasons,
	// and to draw the correct chart
	useEffect(() => {
		setTimeout(() => {
			drawSpiderweb();
		}, 300);
	}, [props.representing, props.xAxis]);

	return props.series.length ? (
		<div
			className="[&_.highcharts-credits]:invisible"
			ref={SpiderChartRef}
			id="spiderweb-container"
			style={{ width: props.width || "100%", height: props.height || "100%" }}
		/>
	) : (
		<div className="flex items-center justify-center h-20">
			<p className="!bg-transparent">No data available</p>
		</div>
	);
};

export default SpiderwebChart;
