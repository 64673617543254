export const replaceSpaceWith_ = (toReplace: string): string => {
	if (!toReplace) {
		return "";
	}
	return toReplace.split(" ").join("_");
};

export const capitalizeString = (toCapitalize: string): string => {
	const words = toCapitalize.split(" ");
	return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
};

export function ellipsis(str: string, maxLength: number): string {
	const ellipsisStr = "...";
	if (str.length <= maxLength) {
		return str;
	}
	return str.slice(0, maxLength - ellipsisStr.length) + ellipsisStr;
}

export const objectTextSearchMatchFns = {
	keyword<T extends object>(instrument: T, query: string): boolean {
		const stringifiedRow = String(Object.values(instrument));
		return textSearchMatchFns.keyword(stringifiedRow, query);
	},
	substring<T extends object>(instrument: T, query: string): boolean {
		const stringifiedRow = String(Object.values(instrument)).toLowerCase();
		return textSearchMatchFns.substring(stringifiedRow, query);
	},
};

export const textSearchMatchFns = {
	keyword(text: string, query: string): boolean {
		const textLower = text.toLowerCase();
		const words = query.toLowerCase().split(" ");
		return words.every((word) => textLower.includes(word));
	},
	substring(text: string, query: string): boolean {
		return text.toLowerCase().includes(query.toLowerCase());
	},
};
