import type { SidebarProps } from "$root/components/Sidebar";
import { Sidebar } from "$root/components/Sidebar";
import { qualifier } from "$root/utils";
import { Button, Form, SubmitButton, Text } from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { adaptAnimatedNodeProvider, spawn } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";

type FormSidePanelHeaderProps = {
	title: string;
	description: string;
	onCancel?(): void;
};

function FormSidePanelHeader(props: FormSidePanelHeaderProps): JSX.Element {
	const { t } = useTranslation();
	return (
		<div className={`border-b border-solid border-b-[color:${themeCSSVars.palette_N100}] `}>
			<div className="flex items-center px-4 pt-5 pb-2">
				<Text
					type="Body/XL/Bold"
					as="p"
					classList="grow h-fit line-clamp-2"
					data-qualifier={qualifier.component.sidePanelHeader.title}
				>
					{props.title}
				</Text>
				<div className="flex justify-end space-x-4 bg-white items-center">
					<Button
						size="small"
						palette="tertiary"
						onClick={props.onCancel}
						data-qualifier={qualifier.component.sidePanelHeader.cancel}
					>
						{t("BUTTON.CANCEL")}
					</Button>
					<SubmitButton size="small" palette="primary" data-qualifier={qualifier.component.sidePanelHeader.submit}>
						{t("BUTTON.APPLY")}
					</SubmitButton>
				</div>
			</div>
			<Text type="Body/M/Book" classList="px-4 pb-4" color={themeCSSVars.palette_N700} as="p">
				{props.description}
			</Text>
		</div>
	);
}

export type FormSidebarContentProps = {
	title: string;
	description: string;
	onSubmit(): MaybePromise<void>;
	onCancel(): void;
	children: ReactNode;
};

export function FormSidebarContent(props: FormSidebarContentProps): JSX.Element {
	// bg-[color:${themeCSSVars.palette_N20}]
	return (
		<Form onSubmitAsync={props.onSubmit} classList="flex-1 min-h-0 flex flex-col h-[100dvh] bg-white pb-6">
			<FormSidePanelHeader title={props.title} description={props.description} onCancel={props.onCancel} />
			<div
				className={`flex-1 min-h-0 flex flex-col px-4 pt-4 border-t border-solid border-t-[color:${themeCSSVars.palette_N100}]`}
			>
				{props.children}
			</div>
		</Form>
	);
}

export type SidePanelChildrenProps<T = void> = {
	setCanDismiss(v: boolean): void;
	resolve(v: T): void;
	reject(err?: unknown): void;
};

export function spawnSidePanel<T = void>(
	params: {
		signal?: AbortSignal;
		children: (props: SidePanelChildrenProps<T>) => ReactNode;
	} & Pick<SidebarProps, "classList" | "style" | "position">,
): Promise<T> {
	const canDismissRef = { current: true };
	const setCanDismiss = (v: boolean) => (canDismissRef.current = v);
	return spawn<T>(
		adaptAnimatedNodeProvider(({ onHidden, reject, resolve, show }) => (
			<Sidebar
				classList={params.classList}
				style={params.style}
				position={params.position}
				open={show}
				onClose={() => (canDismissRef.current ? reject() : undefined)}
				width={440}
				mode="modal"
				onAnimationStateChange={(s) => s === "exited" && onHidden()}
			>
				{params.children({ setCanDismiss, resolve, reject })}
			</Sidebar>
		)),
		{ signal: params.signal },
	).promise;
}

/*
export function spawnFormSidePanel(
	params: {
		signal?: AbortSignal;
		children: (props: Pick<SidePanelChildrenProps, "reject" | "setCanDismiss">) => ReactNode;
	} & Pick<SidebarContentProps, "title" | "description" | "onSubmit">,
): Promise<void> {
	return spawnSidePanel({
		signal: params.signal,
		children(props) {
			return (
				<SidebarContent
					title={params.title}
					description={params.description}
					onCancel={props.reject}
					onSubmit={async () => {
						await params.onSubmit();
						props.resolve();
					}}
				>
					{params.children(props)}
				</SidebarContent>
			);
		},
	});
}
 */
