import { hasAccess } from "$root/components/AuthorizationGuard";
import { PageHeader } from "$root/components/PageHeader";
import { switchExpr } from "@mdotm/mdotui/utils";
import { useMemo } from "react";
import { useLocation } from "react-router";
import type { IUser } from "../user";
import { useUserValue } from "../user";
import ExplainabilitySettings from "./settings/ExplainabilitySettings";
import MarketViewSetting from "./settings/MarketViewSettings";
// import InstrumentCommentarySetting from "./settings/InstrumentCommentarySetting";
import type { Crumb } from "$root/components/Breadcrumbs";
import { useCrumbs } from "$root/components/Crumbs/useCrumbs";
import { useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { typedObjectEntries } from "$root/utils";
import { Card } from "$root/widgets-architecture/layout/Card";
import { match } from "ts-pattern";
import ReportCustomisation from "./settings/ReportCustomisation";

export const PortfolioStudioSettingTabEnum = {
	MarketViewSetting: "MarketViewSetting",
	ExplainabilitySettings: "ExplainabilitySettings",
	ReportCustomisation: "ReportCustomisation",
} as const;

export type PortfolioStudioSettingTabEnum =
	(typeof PortfolioStudioSettingTabEnum)[keyof typeof PortfolioStudioSettingTabEnum];

const Tabs = {
	MarketViewSetting: {
		label: "Market view settings",
		hasAccess: (user) => hasAccess(user, { requiredService: "CUSTOM_MARKET_VIEW_SETTINGS_EDITOR" }),
	},
	ExplainabilitySettings: {
		label: "Explainability options",
		hasAccess: (user) => hasAccess(user, { requiredService: "CUSTOM_MARKET_VIEW_SETTINGS_EDITOR" }),
	},
	ReportCustomisation: {
		label: "Report builder",
		hasAccess: (user) => hasAccess(user, { requiredService: "INVESTMENTS_REPORT_TEMPLATE_EDITOR" }),
	},
} satisfies Record<PortfolioStudioSettingTabEnum, { label: string; hasAccess?: (user: IUser) => boolean }>;

const StudioSettings = (): JSX.Element => {
	const location = useLocation();
	const user = useUserValue();
	const TABS = useMemo(() => {
		return typedObjectEntries(Tabs).flatMap(([key, x]) => {
			const guard = x.hasAccess;
			if (guard) {
				return guard(user) ? [key] : [];
			}

			return [key];
		});
	}, [user]);

	const { replace } = useTypedNavigation();
	const crumbs = useCrumbs();

	const currentTabName = useMemo(() => {
		const queryParamTab = new URLSearchParams(location.search).get("tab");
		const candidate = queryParamTab && TABS.find((v) => v === queryParamTab);
		if (!queryParamTab || !candidate) {
			replace("PortfolioStudioSettings", { tab: PortfolioStudioSettingTabEnum.MarketViewSetting });
			return PortfolioStudioSettingTabEnum.MarketViewSetting;
		}
		return candidate;
	}, [TABS, location.search, replace]);

	return (
		<>
			<PageHeader
				title={Tabs[currentTabName].label}
				crumbsV2={match(currentTabName)
					.returnType<Array<Crumb>>()
					.with("MarketViewSetting", "ExplainabilitySettings", (tab) => crumbs.mySettings(tab))
					.with("ReportCustomisation", () => crumbs.storyfolio("Report builder"))
					.exhaustive()}
			/>
			{switchExpr(currentTabName, {
				ExplainabilitySettings: () => (
					<Card classList={{ "!grow-0": currentTabName === "ExplainabilitySettings" }}>
						<ExplainabilitySettings active />
					</Card>
				),
				MarketViewSetting: () => (
					<Card classList={{ "!grow-0": currentTabName === "ExplainabilitySettings" }}>
						<MarketViewSetting active />
					</Card>
				),
				ReportCustomisation: () => (
					<Card classList={{ "!grow-0": currentTabName === "ExplainabilitySettings" }}>
						<ReportCustomisation active />
					</Card>
				),
			})}
		</>
	);
};

export default StudioSettings;
