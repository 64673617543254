export function deepMerge(objects: any[], mergeArrays: boolean | undefined = true): any {
	const isObject = (item: object) => {
		return item && typeof item === "object" && !Array.isArray(item);
	};

	return objects.reduce((prev, obj) => {
		Object.keys(obj).forEach((key) => {
			const pVal = prev[key];
			const oVal = obj[key];

			if (mergeArrays && Array.isArray(pVal) && Array.isArray(oVal)) {
				prev[key] = pVal.concat(...oVal);
			} else if (isObject(pVal) && isObject(oVal)) {
				prev[key] = deepMerge([pVal, oVal], mergeArrays);
			} else {
				prev[key] = oVal;
			}
		});

		return prev;
	}, {});
}
