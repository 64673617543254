import { IconWalls } from "$root/components/IconWall";
import type { useInstrumentColumnPreferenceProps, UserColumnMetadata } from "$root/functional-areas/instruments/hooks";
import { useInstrumentsColumn } from "$root/functional-areas/instruments/hooks";
import { useLocaleFormatters } from "$root/localization/hooks";
import type { TableColumn } from "@mdotm/mdotui/components";
import { AutoSortHScrollTable, BatchActions, useSelectableTableColumn } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { useMemo } from "react";
import {
	normaliseAndVerifyInstrumentPortfolioComposition,
	useCompositionToolBar,
	verifySomeInstrumentsAreExcluded,
} from "../../builder";
import { useInstrumentCompositionColumns } from "../../columns";
import type { InstrumentEditorEntry } from "../../const";
import type { InstrumentCompositionEditorProps } from "../../instrumentEditor";
import InstrumentLimitReachedBanner from "../InstrumentLimitReachedBanner";
import NormaliseAndExcessToCashBanner from "../NormaliseAndExcessToCashBanner";
import NormalisedInvestmentInstrumentBanner from "../NormalisedInvestmentInstrumentBanner";

type DefaultInstrumentTableProps = InstrumentCompositionEditorProps &
	useInstrumentColumnPreferenceProps & {
		filteredRows: InstrumentEditorEntry[];
		rows: InstrumentEditorEntry[];
		columnsMetadata: Array<UserColumnMetadata>;
	};

function BaseInstrumentTable({
	instrumentBuilder,
	filteredRows,
	rows,
	entity,
	limit,
	columnsMetadata,
	applyColumnPreferenceApi,
}: DefaultInstrumentTableProps): JSX.Element {
	const toolbar = useCompositionToolBar();
	const { formatNumber } = useLocaleFormatters();
	const flatInstrumentCompositionColumns = useInstrumentCompositionColumns();
	const {
		customizableColumns: instrumentColumns,
		tools,
		// name,
	} = useInstrumentsColumn({
		allColumns: columnsMetadata,
		mode: "VIEW",
		changeTableLayout: applyColumnPreferenceApi,
	});

	const selectableTableColumn = useSelectableTableColumn({
		rows,
		filteredRows,
		selectBy: (r) => r.rowId,
	});

	const totalWeight = useMemo(() => {
		if (rows.length > 0) {
			return `${formatNumber(instrumentBuilder.getTotal("weight"))}%`;
		}
		return undefined;
	}, [formatNumber, instrumentBuilder, rows.length]);

	const nameColumn = useMemo(
		() =>
			flatInstrumentCompositionColumns.name({
				onCompare(row, action) {
					if (action === "add") {
						instrumentBuilder.setInstrumentToCompare([row]);
						return;
					}

					instrumentBuilder.deleteComparedInstruments(row.rowId);
				},
				isRowCompared(rowId) {
					return instrumentBuilder.getComparedInstrument(rowId) !== undefined;
				},
			}),
		[flatInstrumentCompositionColumns, instrumentBuilder],
	);

	const deleteColumn = useMemo(
		() => ({
			...flatInstrumentCompositionColumns.deleteRestore({
				deleted: instrumentBuilder.watchDeleted(),
				onChange: (row, deleteSet) => instrumentBuilder.delete("soft", row.rowId, deleteSet.has(row.rowId)),
			}),
			header: tools.header,
		}),
		[flatInstrumentCompositionColumns, instrumentBuilder, tools.header],
	);

	const weightColumn = useMemo<TableColumn<InstrumentEditorEntry>>(
		() => ({
			...flatInstrumentCompositionColumns.weight({
				onChangeWeight: (rowId, v) => instrumentBuilder.setWeight(rowId, v ?? undefined),
				isInstrumentDeleted: (rowId) => instrumentBuilder.isInstrumentDeleted(rowId),
				onCheckIsMinimumWeightValid: async (row, weight) => {
					const instrumentsExcluded = await verifySomeInstrumentsAreExcluded(
						{ ...row, weight: weight ?? undefined },
						entity,
					);
					instrumentBuilder.setInstrument(row.rowId, {
						...row,
						weight: weight ?? undefined,
						someInstrumentsAreExcluded: instrumentsExcluded.length > 0,
						nOfInstrumentExcluded: instrumentsExcluded.length,
					});
				},
				weightProvider: (rowId) => instrumentBuilder.getWeight(rowId),
			}),
			footerCellClassList: "font-semibold justify-end",
			footer: totalWeight,
			align: "end",
			minWidth: 130,
		}),
		[entity, flatInstrumentCompositionColumns, instrumentBuilder, totalWeight],
	);

	const columns = useMemo<Array<TableColumn<InstrumentEditorEntry>>>(
		() => [selectableTableColumn.column, nameColumn, ...instrumentColumns, weightColumn, deleteColumn],
		[selectableTableColumn.column, nameColumn, instrumentColumns, weightColumn, deleteColumn],
	);

	const actions = useMemo(
		() => [
			toolbar.softDelete({
				instrumentBuilder,
				selected: selectableTableColumn.multiSelectCtx,
			}),
			toolbar.restoreDeleted({
				instrumentBuilder,
				selected: selectableTableColumn.multiSelectCtx,
			}),
			toolbar.compare({
				instrumentBuilder,
				selected: selectableTableColumn.multiSelectCtx,
			}),
		],
		[instrumentBuilder, selectableTableColumn.multiSelectCtx, toolbar],
	);

	return (
		<>
			<BatchActions
				total={rows.length}
				selected={selectableTableColumn.multiSelectCtx.selection.size}
				palette="tertiary"
				actions={actions}
				classList="py-2 h-[58px] shrink-0"
			/>
			<NormalisedInvestmentInstrumentBanner
				entity={entity}
				someInvestmentsHasExcludeInstrument={rows.some((instrument) => instrument.someInstrumentsAreExcluded)}
			/>
			<AutoSortHScrollTable
				columns={columns}
				rows={filteredRows}
				pinnedColumns={[
					{ name: nameColumn.name, side: "left" },
					{ name: weightColumn.name, side: "right" },
					{ name: deleteColumn.name, side: "right" },
				]}
				noDataText={<IconWalls.EditorEmptyData entity={entity} />}
				onRowClick={(row) => selectableTableColumn.toggle(row.rowId)}
				rowStyle={(row) => {
					if (row.someInstrumentsAreExcluded) {
						return { backgroundColor: themeCSSVars.global_palette_warning_100 };
					}

					if (instrumentBuilder.isInstrumentDeleted(row.rowId)) {
						return { backgroundColor: themeCSSVars.Table_highlightedRowBackgroundColor };
					}
				}}
				rowClassList={(row) => {
					if (instrumentBuilder.isInstrumentDeleted(row.rowId)) {
						return "line-through";
					}
				}}
				palette="uniform"
			/>
			<br />
			{limit && rows.length > limit && <InstrumentLimitReachedBanner limit={limit} />}
			<br />
			<NormaliseAndExcessToCashBanner
				disableNormalise={selectableTableColumn.multiSelectCtx.selection.size === 0}
				disableNormaliseTooltip={selectableTableColumn.multiSelectCtx.selection.size > 0}
				entity={entity}
				instrumentBuilder={instrumentBuilder}
				onAsyncNormalise={() =>
					normaliseAndVerifyInstrumentPortfolioComposition({
						instrumentBuilder,
						entity,
						selection: selectableTableColumn.multiSelectCtx.selection,
					})
				}
				onExcessToCash={() => instrumentBuilder.excessToCash()}
			/>
		</>
	);
}

export default BaseInstrumentTable;
