import type { ContextContent } from "$root/utils";
import { WidgetsGridContext } from "$root/widgets-architecture/layout/WidgetsGrid/widgets-grid-context";
import ACROutlookExpected from "$root/widgets-architecture/widgets/ACROutlookExpected";
import AssetClassOverviewBlock from "$root/widgets-architecture/widgets/AssetClassOverviewBlock";
import Attribution from "$root/widgets-architecture/widgets/Attribution";
import CommentaryMDBlock from "$root/widgets-architecture/widgets/CommentaryMDBlock";
import Composition from "$root/widgets-architecture/widgets/Composition";
import ContributionVolatility from "$root/widgets-architecture/widgets/ContributionVolatility";
import CurrentRegimeCounterBlock from "$root/widgets-architecture/widgets/CurrentRegimeCounterBlock";
import CustomOutlookBlock from "$root/widgets-architecture/widgets/CustomOutlookBlock";
import EvolutionOfRegimeProbabilitiesBlock from "$root/widgets-architecture/widgets/EvolutionOfRegimeProbabilitiesBlock";
import ExanteContributionVolatility from "$root/widgets-architecture/widgets/ExanteContributionVolatility";
import ExposureCompare from "$root/widgets-architecture/widgets/ExposureCompare";
import ExposureEvolve from "$root/widgets-architecture/widgets/ExposureEvolve";
import Forecast from "$root/widgets-architecture/widgets/Forecast";
import MarketRegimeAnalysisBlock from "$root/widgets-architecture/widgets/MarketRegimeAnalysisBlock";
import OutlookBlock from "$root/widgets-architecture/widgets/OutlookBlock";
import PastMarketRegimesBlock from "$root/widgets-architecture/widgets/PastMarketRegimesBlock";
import PortfolioHistoryBlock from "$root/widgets-architecture/widgets/PortfolioHistoryBlock";
import PortfolioMonitoringBlock from "$root/widgets-architecture/widgets/PortfolioMonitoringBlock";
import PortfolioPerformance from "$root/widgets-architecture/widgets/PortfolioPerformance";
import Positioning from "$root/widgets-architecture/widgets/Positioning";
import RegimeBreakdownBySectorBlock from "$root/widgets-architecture/widgets/RegimeBreakdownBySectorBlock";
import RegimeTransitionMatrix1MBlock from "$root/widgets-architecture/widgets/RegimeTransitionMatrix1MBlock";
import RegimeTransitionMatrix2WBlock from "$root/widgets-architecture/widgets/RegimeTransitionMatrix2WBlock";
import RegimeTransitionMatrix3MBlock from "$root/widgets-architecture/widgets/RegimeTransitionMatrix3MBlock";
import ReturnAnalysis from "$root/widgets-architecture/widgets/ReturnAnalysis";
import ScenarioAnalysisEditable from "$root/widgets-architecture/widgets/ScenarioAnalysisEditable";
import ScenarioDescriptionBlock from "$root/widgets-architecture/widgets/ScenarioDescriptionBlock";
import { ReadonlyUniverseComposition } from "$root/widgets-architecture/widgets/UniverseComposition";
import { useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import type { CSSProperties } from "react";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { WidgetOptions } from "./context";
import { GridBlockContext, WidgetBlockContext } from "./context";

import { reportPlatformError } from "$root/api/error-reporting";
import { IconWalls } from "$root/components/IconWall";
import BenchmarkComposition from "$root/widgets-architecture/widgets/BenchmarkComposition";
import ExanteMetricsBlock from "$root/widgets-architecture/widgets/ExanteMetricsBlock";
import FactorsExposure from "$root/widgets-architecture/widgets/FactorsExposure";
import PerformanceMetricsBlock from "$root/widgets-architecture/widgets/PerformanceMetricsBlock";
import PortfolioCommentaryMDBlock from "$root/widgets-architecture/widgets/PortfolioCommentaryMDBlock";
import PortfolioFactorsExposure from "$root/widgets-architecture/widgets/PortfolioFactorsExposure";
import WorldGridMapBlock from "$root/widgets-architecture/widgets/WorldGridMap";
import { Button, ErrorBoundary, Icon, Text } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { noop } from "@mdotm/mdotui/utils";
import { Card } from "../Card";
import AlertTooltip from "./AlertTooltip";

export const widgetMap = {
	BenchmarkComposition,
	ScenarioAnalysisEditable,
	Positioning,
	OutlookBlock,
	AssetClassOverviewBlock,
	CommentaryMDBlock,
	Forecast,
	ReturnAnalysis,
	EvolutionOfRegimeProbabilitiesBlock,
	Composition,
	ReadonlyUniverseComposition,
	PortfolioPerformance,
	ACROutlookExpected,
	RegimeTransitionMatrix2WBlock,
	RegimeTransitionMatrix1MBlock,
	RegimeTransitionMatrix3MBlock,
	MarketRegimeAnalysisBlock,
	PastMarketRegimesBlock,
	RegimeBreakdownBySectorBlock,
	ScenarioDescriptionBlock,
	CurrentRegimeCounterBlock,
	ExposureEvolve,
	ExposureCompare,
	CustomOutlookBlock,
	Attribution,
	ContributionVolatility,
	ExanteContributionVolatility,
	PortfolioHistoryBlock,
	PortfolioCommentaryMDBlock,
	PerformanceMetricsBlock,
	ExanteMetricsBlock,
	FactorsExposure,
	PortfolioFactorsExposure,
	PortfolioMonitoringBlock,
	WorldGridMapBlock,
};

export type WidgetMapperProps = {
	widgetName: keyof typeof widgetMap;
	style?: CSSProperties;
	onMouseDown?: React.MouseEventHandler;
	onMouseUp?: React.MouseEventHandler;
	onTouchEnd?: React.TouchEventHandler;
	blockWidth?: number;
	onBlockWidthChange?(w: number): void;
};

export const WidgetBlock = React.forwardRef<
	HTMLDivElement,
	{
		onMouseDown?: React.MouseEventHandler;
		onMouseUp?: React.MouseEventHandler;
		onTouchEnd?: React.TouchEventHandler;
		children?: React.ReactNode;
		style?: CSSProperties;
		widgetName: string;
		blockWidth?: number;
		onBlockWidthChange?(w: number): void;
	}
>(function WidgetCreate(
	{ children, style, onMouseDown, onMouseUp, onTouchEnd, widgetName, blockWidth, onBlockWidthChange },
	ref,
) {
	const { draggingItem, isDraggable: isDraggableOverride } = useContext(WidgetsGridContext);

	const [widgetOptions, _setWidgetOptions] = useState<
		Parameters<ContextContent<typeof WidgetBlockContext>["setWidgetOptions"]>[0]
	>({
		actionHeader: null,
		title: "",
		isDraggable: isDraggableOverride,
	});
	const baseBlockStyle = useMemo(() => ({ ...style, ...widgetOptions.style }), [style, widgetOptions.style]);
	const refs = useUnsafeUpdatedRef({ widgetOptions });
	const setWidgetOptions = useCallback<typeof _setWidgetOptions>(
		(configOrUpdateFn) => {
			let newConfig: WidgetOptions;
			if (typeof configOrUpdateFn === "function") {
				const config = configOrUpdateFn(refs.current.widgetOptions);
				newConfig = { ...config, isDraggable: isDraggableOverride && config.isDraggable };
			} else {
				const config = configOrUpdateFn;
				newConfig = { ...config, isDraggable: isDraggableOverride && config.isDraggable };
			}
			_setWidgetOptions(newConfig);
		},
		[refs, isDraggableOverride],
	);
	return (
		<GridBlockContext.Provider
			value={useMemo(
				() =>
					blockWidth !== undefined && onBlockWidthChange !== undefined
						? { blockWidth, setBlockWidth: onBlockWidthChange }
						: null,
				[blockWidth, onBlockWidthChange],
			)}
		>
			<WidgetBlockContext.Provider value={{ setWidgetOptions }}>
				<Card
					style={baseBlockStyle}
					classList={draggingItem === widgetName ? "z-10 shadow-md" : ""}
					onMouseDown={widgetOptions.isDraggable !== false ? onMouseDown : noop}
					onMouseUp={widgetOptions.isDraggable !== false ? onMouseUp : noop}
					onTouchEnd={widgetOptions.isDraggable !== false ? onTouchEnd : noop}
					innerRef={ref}
					data-widget-name={widgetName}
					title={
						widgetOptions.title || (widgetOptions.alertsActive && widgetOptions.alerts) ? (
							<div className="flex items-center flex-1">
								{widgetOptions.isDraggable !== false && (
									<div className="mr-[10px] cursor-move draggable-handle">
										<Icon icon="Drag-and-drop" color={themeCSSVars.palette_N400} size={20} />
									</div>
								)}
								<Text type="Body/XL/Bold">{widgetOptions.title}</Text>
								{widgetOptions.alerts && widgetOptions.alertsActive && (
									<div className="ml-2 overflow-hidden grow">
										<AlertTooltip
											alerts={widgetOptions.alerts}
											hideAlertsNumber={widgetOptions.hideAlertsNumber}
											footnote={widgetOptions.alertsFootnote}
										/>
									</div>
								)}
							</div>
						) : null
					}
					subTitle={widgetOptions.subTitle}
					actionHeader={widgetOptions.actionHeader}
				>
					{draggingItem === widgetName ? <></> : children}
				</Card>
			</WidgetBlockContext.Provider>
		</GridBlockContext.Provider>
	);
});

function WidgetErrorBoundary(props: {
	children: React.ReactNode;
	style?: CSSProperties;
	onMouseDown?: React.MouseEventHandler;
	onMouseUp?: React.MouseEventHandler;
	onTouchEnd?: React.TouchEventHandler;
	widgetName: string;
	blockWidth?: number;
	onBlockWidthChange?(w: number): void;
	innerRef: React.ForwardedRef<HTMLDivElement>;
}) {
	const { t } = useTranslation();
	return (
		<ErrorBoundary
			onError={(err) =>
				reportPlatformError(err, "ERROR", "global", { message: `rendering Widget "${props.widgetName}"` })
			}
			errorChildren={({ reset }) => (
				<Card
					data-widget-name={props.widgetName}
					style={props.style}
					title={t("OPS")}
					innerRef={props.innerRef}
					actionHeader={
						<Button size="small" onClick={() => reset()} palette="primary">
							{t("RETRY")}
						</Button>
					}
				>
					<IconWalls.ErrorData opaque />
				</Card>
			)}
		>
			<WidgetBlock
				ref={props.innerRef}
				onMouseDown={props.onMouseDown}
				onMouseUp={props.onMouseUp}
				onTouchEnd={props.onTouchEnd}
				style={props.style}
				widgetName={props.widgetName}
				blockWidth={props.blockWidth}
				onBlockWidthChange={props.onBlockWidthChange}
			>
				{props.children}
			</WidgetBlock>
		</ErrorBoundary>
	);
}

const WidgetsMapper = React.forwardRef<HTMLDivElement, WidgetMapperProps>(function WidgetGet(
	{ widgetName, style, onMouseDown, onMouseUp, onTouchEnd, blockWidth, onBlockWidthChange },
	ref,
) {
	const widgetContent = useMemo(() => {
		const WidgetContent = widgetMap[widgetName];
		return <WidgetContent />;
	}, [widgetName]);

	return (
		<WidgetErrorBoundary
			onMouseDown={onMouseDown}
			onMouseUp={onMouseUp}
			onTouchEnd={onTouchEnd}
			style={style}
			widgetName={widgetName}
			innerRef={ref}
			blockWidth={blockWidth}
			onBlockWidthChange={onBlockWidthChange}
		>
			{widgetContent}
		</WidgetErrorBoundary>
	);
});

export default WidgetsMapper;
