import { Form } from "@mdotm/mdotui/components";
import type { TrackableAsyncFn } from "@mdotm/mdotui/headless";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { FormEvent, ReactNode } from "react";

export type StepBaseProps = {
	title: ReactNode;
	children: ReactNode;
	onSubmitAsync: TrackableAsyncFn<void, [FormEvent]>;
	actions?: ReactNode;
	optional: boolean;
	headerActions?: ReactNode;
};

export function StepBase({
	title,
	onSubmitAsync,
	children,
	actions,
	optional,
	headerActions,
}: StepBaseProps): JSX.Element {
	return (
		<Form noValidate onSubmitAsync={onSubmitAsync} classList="min-h-0 flex-1 !flex flex-col">
			<div className={`bg-[${themeCSSVars.global_palette_neutral_0}] min-h-0 flex-1 flex flex-col px-4 pb-4 relative`}>
				<div className="flex justify-between items-center">
					<div className="font-bold text-[20px] mb-6 mt-4">
						{title} <span className="opacity-80 text-sm">{optional ? "Optional" : "Mandatory"}</span>
					</div>
					{headerActions}
				</div>
				{children}
				{actions && <div className="flex justify-end pt-4">{actions}</div>}
			</div>
		</Form>
	);
}
