import { PortfolioStudioPreferencesApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { DebouncedSearchInput } from "$root/components/DebouncedSearchInput";
import { IconWalls } from "$root/components/IconWall";
import { Card } from "$root/widgets-architecture/layout/Card";
import type { ActionWithOptionalGroup } from "@mdotm/mdotui/components";
import { Button, DropdownMenu, FormField, Icon, Select } from "@mdotm/mdotui/components";
import type { ReactNode } from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import type { useInstrumentColumnPreferenceProps, UserColumnMetadata } from "../instruments/hooks";
import {
	hideNameColumnMetadata,
	useInstrumentColumnPreference,
	useSearchableInstrumentTable,
} from "../instruments/hooks";
import type { InstrumentEditorBuilderProps } from "./builder";
import { useCompositionHeaderAction } from "./builder";
import type { InstrumentEditorEntity, InstrumentEditorEntry } from "./const";
import { BaseInstrumentTable } from "./table/base-table";
import { EditInstrumentTable } from "./table/editPortfolio-table";
import { EnhanceInstrumentTable } from "./table/enhance-table";
import MinNumberOfInstrumentBanner from "./table/MinNumberOfInstrumentBanner";
import { UniverseInstrumentTable } from "./table/universe-table";
import { queryClient, queryClientKeys } from "$root/third-party-integrations/react-query";
import { noop } from "@mdotm/mdotui/utils";
import { getStandardClassifications, queryInstrumentStandardClassificationKey } from "./spawn/instrument-adder/hooks";

type InstrumentCompostionMode = { mode: "new" } | { mode: "edit"; uuid: string };
export type InstrumentCompositionEditorProps = {
	entity: InstrumentEditorEntity;
	instrumentBuilder: InstrumentEditorBuilderProps;
	minThreshold?: number;
	limit?: number;
};

const placeholderSearch = "Filter by instrument name, ISIN, asset class or micro asset class";

function InstrumentCompositionEditor(props: InstrumentCompositionEditorProps & InstrumentCompostionMode): JSX.Element {
	const { instrumentBuilder, entity, minThreshold } = props;

	const [groupByClassificationUuid, setGroupByClassificationUuid] = useState<string | null>(null);
	const compositionMap = instrumentBuilder.watchComposition();
	const composition = useMemo(() => Array.from(compositionMap.values()), [compositionMap]);

	const {
		filtered,
		setQuery: setSearchInstrument,
		query: searchInstrument,
	} = useSearchableInstrumentTable(composition, {
		mode: "keyword",
	});

	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);
	const { changeTableLayout, columnsMetadata } = useInstrumentColumnPreference({
		async applyColumnPreferenceApi(userInstrumentsColumnPreferences) {
			await portfolioStudioPreferencesApi.setUserInstrumentsColumnPreferences({
				userInstrumentsColumnPreferences,
			});
		},
		mapColumnMetadataFn: hideNameColumnMetadata,
	});

	const classificationOptions = useMemo(
		() => [
			{ label: "Ungrouped", value: null },
			...columnsMetadata.flatMap((x) =>
				x.fieldType === "TAG" ? [{ label: x.name, value: x.classificationUuid! }] : [],
			),
		],
		[columnsMetadata],
	);

	const InstrumentEditorTable = useMemo(
		() =>
			match(props)
				.returnType<
					(
						componentProps: {
							rows: InstrumentEditorEntry[];
							filteredRows: InstrumentEditorEntry[];
							instrumentBuilder: InstrumentEditorBuilderProps;
							limit?: number;
							columnsMetadata: Array<UserColumnMetadata>;
						} & useInstrumentColumnPreferenceProps,
					) => ReactNode
				>()
				.with({ mode: "new" }, (params) => {
					return match(params.entity)
						.returnType<
							(
								componentProps: {
									rows: InstrumentEditorEntry[];
									filteredRows: InstrumentEditorEntry[];
									instrumentBuilder: InstrumentEditorBuilderProps;
									columnsMetadata: Array<UserColumnMetadata>;
								} & useInstrumentColumnPreferenceProps,
							) => ReactNode
						>()
						.with(
							"INVESTMENT",
							"BENCHMARK",
							"TARGET_INVESTMENT",
							(matchedEntity) =>
								function Editor(componentProps) {
									return <BaseInstrumentTable.Flat entity={matchedEntity} {...componentProps} />;
								},
						)
						.with(
							"UNIVERSE",
							(matchedEntity) =>
								function Editor(componentProps) {
									return <UniverseInstrumentTable.Flat entity={matchedEntity} {...componentProps} />;
								},
						)
						.with(
							"INVESTMENT_ENHANCEMENT",
							"INVESTMENT_DRAFT",
							() =>
								function Empty() {
									return <></>;
								},
						)
						.exhaustive();
				})
				.with({ mode: "edit" }, (params) => {
					return match(params.entity)
						.returnType<
							(
								componentProps: {
									rows: InstrumentEditorEntry[];
									filteredRows: InstrumentEditorEntry[];
									instrumentBuilder: InstrumentEditorBuilderProps;
									limit?: number;
									columnsMetadata: Array<UserColumnMetadata>;
								} & useInstrumentColumnPreferenceProps,
							) => ReactNode
						>()
						.with(
							"BENCHMARK",
							"TARGET_INVESTMENT",
							(matchedEntity) =>
								function Editor(componentProps) {
									return <BaseInstrumentTable.Flat entity={matchedEntity} {...componentProps} />;
								},
						)
						.with(
							"UNIVERSE",
							(matchedEntity) =>
								function Editor(componentProps) {
									return <UniverseInstrumentTable.Flat entity={matchedEntity} {...componentProps} />;
								},
						)
						.with(
							"INVESTMENT",
							(matchedEntity) =>
								function Editor(componentProps) {
									return <EditInstrumentTable.Flat entity={matchedEntity} {...componentProps} />;
								},
						)
						.with(
							"INVESTMENT_ENHANCEMENT",
							(matchedEntity) =>
								function Editor(componentProps) {
									return <EnhanceInstrumentTable.Flat entity={matchedEntity} {...componentProps} />;
								},
						)
						.with(
							"INVESTMENT_DRAFT",
							(matchedEntity) =>
								function Editor(componentProps) {
									return <BaseInstrumentTable.Flat entity={matchedEntity} {...componentProps} />;
								},
						)
						.exhaustive();
				})
				.otherwise(
					() =>
						function Empty() {
							return <></>;
						},
				),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[props.mode, props.entity],
	);

	const GroupInstrumentEditorTable = useMemo(
		() =>
			match(props)
				.returnType<
					(
						componentProps: {
							rows: InstrumentEditorEntry[];
							filteredRows: InstrumentEditorEntry[];
							instrumentBuilder: InstrumentEditorBuilderProps;
							limit?: number;
							columnsMetadata: Array<UserColumnMetadata>;
							groupByClassificationUuid: string;
						} & useInstrumentColumnPreferenceProps,
					) => ReactNode
				>()
				.with({ mode: "new" }, (params) => {
					return match(params.entity)
						.returnType<
							(
								componentProps: {
									rows: InstrumentEditorEntry[];
									filteredRows: InstrumentEditorEntry[];
									instrumentBuilder: InstrumentEditorBuilderProps;
									columnsMetadata: Array<UserColumnMetadata>;
									groupByClassificationUuid: string;
								} & useInstrumentColumnPreferenceProps,
							) => ReactNode
						>()
						.with(
							"INVESTMENT",
							"BENCHMARK",
							"TARGET_INVESTMENT",
							(matchedEntity) =>
								function Editor(componentProps) {
									return <BaseInstrumentTable.Grouped entity={matchedEntity} {...componentProps} />;
								},
						)
						.with(
							"UNIVERSE",
							(matchedEntity) =>
								function Editor(componentProps) {
									return <UniverseInstrumentTable.Grouped entity={matchedEntity} {...componentProps} />;
								},
						)
						.with(
							"INVESTMENT_ENHANCEMENT",
							"INVESTMENT_DRAFT",
							() =>
								function Empty() {
									return <></>;
								},
						)
						.exhaustive();
				})
				.with({ mode: "edit" }, (params) => {
					return match(params.entity)
						.returnType<
							(
								componentProps: {
									rows: InstrumentEditorEntry[];
									filteredRows: InstrumentEditorEntry[];
									instrumentBuilder: InstrumentEditorBuilderProps;
									limit?: number;
									columnsMetadata: Array<UserColumnMetadata>;
									groupByClassificationUuid: string;
								} & useInstrumentColumnPreferenceProps,
							) => ReactNode
						>()
						.with(
							"BENCHMARK",
							"TARGET_INVESTMENT",
							(matchedEntity) =>
								function Editor(componentProps) {
									return <BaseInstrumentTable.Grouped entity={matchedEntity} {...componentProps} />;
								},
						)
						.with(
							"UNIVERSE",
							(matchedEntity) =>
								function Editor(componentProps) {
									return <UniverseInstrumentTable.Grouped entity={matchedEntity} {...componentProps} />;
								},
						)
						.with(
							"INVESTMENT",
							(matchedEntity) =>
								function Editor(componentProps) {
									return <EditInstrumentTable.Grouped entity={matchedEntity} {...componentProps} />;
								},
						)
						.with(
							"INVESTMENT_ENHANCEMENT",
							(matchedEntity) =>
								function Editor(componentProps) {
									return <EnhanceInstrumentTable.Grouped entity={matchedEntity} {...componentProps} />;
								},
						)
						.with(
							"INVESTMENT_DRAFT",
							(matchedEntity) =>
								function Editor(componentProps) {
									return <BaseInstrumentTable.Grouped entity={matchedEntity} {...componentProps} />;
								},
						)
						.exhaustive();
				})
				.otherwise(
					() =>
						function Empty() {
							return <></>;
						},
				),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[props.mode, props.entity],
	);

	return (
		<Card classList="min-w-0 flex-1">
			{minThreshold != null && (
				<MinNumberOfInstrumentBanner threshold={minThreshold} instrumentBuilder={instrumentBuilder} entity={entity} />
			)}
			<div className="flex justify-between">
				<DebouncedSearchInput
					query={searchInstrument}
					onChange={setSearchInstrument}
					placeholder={placeholderSearch}
					classList="max-w-[500px] flex-1"
				/>

				<div className="flex gap-2 mb-4 items-end">
					{props.entity !== "UNIVERSE" && (
						<FormField label="Group by">
							{(formFieldProps) => (
								<Select
									{...formFieldProps}
									size="small"
									value={groupByClassificationUuid}
									onChange={setGroupByClassificationUuid}
									options={classificationOptions}
								/>
							)}
						</FormField>
					)}
					{props.mode === "edit" ? <InstrumentHeaderActions {...props} /> : <InstrumentHeaderActions {...props} />}
				</div>
			</div>
			{composition.length > 0 ? (
				groupByClassificationUuid ? (
					<GroupInstrumentEditorTable
						filteredRows={filtered}
						rows={composition}
						instrumentBuilder={instrumentBuilder}
						limit={props.limit}
						columnsMetadata={columnsMetadata}
						applyColumnPreferenceApi={changeTableLayout}
						groupByClassificationUuid={groupByClassificationUuid}
					/>
				) : (
					<InstrumentEditorTable
						filteredRows={filtered}
						rows={composition}
						instrumentBuilder={instrumentBuilder}
						limit={props.limit}
						columnsMetadata={columnsMetadata}
						applyColumnPreferenceApi={changeTableLayout}
					/>
				)
			) : (
				<IconWalls.EditorEmptyData entity={entity} />
			)}
		</Card>
	);
}

function InstrumentHeaderActions(props: InstrumentCompositionEditorProps & InstrumentCompostionMode) {
	const { instrumentBuilder, entity } = props;
	const { t } = useTranslation();
	const compositionHeaderAction = useCompositionHeaderAction({
		instrumentBuilder,
		entity,
		uuid: props.mode === "edit" ? props.uuid : undefined,
	});

	useEffect(() => {
		queryClient
			.invalidateQueries({
				predicate(query) {
					const queryKey = query.queryKey?.[0];
					return (
						queryKey === queryClientKeys.queryInstrumentsAddableInComposition ||
						queryKey === queryClientKeys.queryAddInstrumentsEntitySummary ||
						queryKey === queryClientKeys.querySelectableInstrumentBaskets
					);
				},
			})
			.then(async () => {
				await queryClient.prefetchQuery({
					queryKey: [queryInstrumentStandardClassificationKey],
					queryFn: () => getStandardClassifications(),
				});
				await queryClient.refetchQueries({
					predicate(query) {
						const queryKey = query.queryKey?.[0];
						return (
							queryKey === queryClientKeys.queryInstrumentsAddableInComposition ||
							queryKey === queryClientKeys.queryAddInstrumentsEntitySummary ||
							queryKey === queryClientKeys.querySelectableInstrumentBaskets
						);
					},
				});
			})
			.catch(noop);
	}, []);

	const actions = useMemo(
		() =>
			match(props)
				.returnType<ActionWithOptionalGroup<string>[]>()
				.with({ mode: "new" }, (params) => {
					return match(params.entity)
						.with("INVESTMENT", "INVESTMENT_DRAFT", "UNIVERSE", () => [
							compositionHeaderAction.addInstruments(),
							compositionHeaderAction.addPortfolio(),
							compositionHeaderAction.uploadInstruments(),
						])
						.with("TARGET_INVESTMENT", "BENCHMARK", () => [
							compositionHeaderAction.addInstruments(),
							compositionHeaderAction.uploadInstruments(),
						])
						.with("INVESTMENT_ENHANCEMENT", () => [])
						.exhaustive();
				})
				.with({ mode: "edit" }, (params) => {
					return match(params.entity)
						.with("INVESTMENT", "INVESTMENT_DRAFT", "UNIVERSE", () => [
							compositionHeaderAction.addInstruments(),
							compositionHeaderAction.addPortfolio(),
							compositionHeaderAction.uploadInstruments(),
						])
						.with("TARGET_INVESTMENT", "BENCHMARK", () => [
							compositionHeaderAction.addInstruments(),
							compositionHeaderAction.uploadInstruments(),
						])
						.with("INVESTMENT_ENHANCEMENT", () => [
							compositionHeaderAction.addInstruments(),
							compositionHeaderAction.addPortfolio(),
						])
						.exhaustive();
				})
				.otherwise(() => []),

		[compositionHeaderAction, props],
	);

	return (
		<div className="flex gap-2">
			<DropdownMenu
				trigger={(dropdownMenuProps) => (
					<Button
						{...dropdownMenuProps}
						palette="secondary"
						size="small"
						classList="flex gap-2"
						data-qualifier="CompositionEditor/HeaderAction/DropdownMenu"
					>
						<Icon icon="add-ptf" size={18} />
						{t("BUTTON.ADD")}
					</Button>
				)}
				align="endToEnd"
				position="bottom"
				actions={actions}
			/>
		</div>
	);
}

export default InstrumentCompositionEditor;
