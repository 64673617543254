import type { ReactNode } from "react";
import { memo } from "react";
import type { PlatformLayoutOptions } from "./PlatformRouter/layout-options";
import { PageCtxProvider } from "./PageCtx";
import { toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";

export type PageLayoutProps = {
	children?: ReactNode;
	title?: string;
	backgroundImage?: string;
	routeKey?: string;
	hideSidebar?: boolean;
	mode?: PlatformLayoutOptions["mode"];
	maxPageHeight?: PlatformLayoutOptions["maxPageHeight"];
};

export type DefaultModeProps = Omit<PageLayoutProps, "mode">;

const DefaultMode = ({ children, backgroundImage }: DefaultModeProps) => {
	return (
		<PageCtxProvider>
			<div
				style={
					backgroundImage
						? {
								backgroundImage: `url(${backgroundImage})`,
								backgroundSize: "cover",
						  }
						: undefined
				}
				className={toClassName({
					[`grow min-h-0 flex flex-col bg-[color:${themeCSSVars.palette_N50}]`]: true,
				})}
			>
				<div className="grow min-h-0 flex flex-col relative w-full p-[0.9375rem]">{children}</div>
			</div>
		</PageCtxProvider>
	);
};

function PageLayout(props: PageLayoutProps) {
	const { mode = "default", children, maxPageHeight = "auto" } = props;

	if (mode === "print") {
		return children;
	}

	//COMMENT: min-w-[85.375rem] should be the min w of the page content
	return (
		<div
			className={toClassName({
				"min-w-[85.375rem] min-h-[100dvh] flex flex-col": true,
				"max-h-[100dvh]": maxPageHeight === "screen",
			})}
		>
			<DefaultMode {...props} />
		</div>
	);
}

export default memo<PageLayoutProps>(PageLayout);
