import type { MetricParameters, PortfolioMetricTypes } from "$root/api/api-gen";
import { HDivider } from "$root/components/HDivider";
import { reapplySphereMetricsWithConfirmationDialog } from "$root/functional-areas/portfolio/metrics";
import { useLocaleFormatters } from "$root/localization/hooks";
import type { WidgetStatus, WidgetStatusUnion } from "$root/pages/PortfolioDetails/PortfolioWidgetStatus";
import { PortfolioQueryWidgetBase } from "$root/pages/PortfolioDetails/PortfolioWidgetStatus";
import type { Maybe } from "$root/utils";
import { useQueryNoRefetch } from "$root/utils";
import { InfoTooltip } from "$root/widgets-architecture/layout/WidgetsMapper/InfoTooltip";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import type { StylableProps, TableColumn } from "@mdotm/mdotui/components";
import {
	ActionText,
	AutoSortHScrollTable,
	Checkbox,
	CircularProgressBar,
	Column,
	Controller,
	DraggableList,
	Form,
	Icon,
	IconTooltip,
	Row,
	Sandwich,
	ScrollWrapper,
	SubmitButton,
	Svg,
	Text,
	TooltipContent,
	Transition,
	colorBySeverity,
} from "@mdotm/mdotui/components";
import { useAsync, type MaybePromise } from "@mdotm/mdotui/headless";
import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import { renderNodeOrFn, toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor, noop, unpromisify } from "@mdotm/mdotui/utils";
import type { QueryKey } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export type PortfolioMetricsRow = {
	name: string;
	visible: boolean;
	label: string;
	classificationUuid?: string;
	type?: PortfolioMetricTypes;
	current: Maybe<number>;
	benchmark: Maybe<number>;
	proposal?: Maybe<number>;
	isCurrentCustom?: Maybe<boolean>;
	isBenchmarkCustom?: Maybe<boolean>;
	metricParameters?: MetricParameters;
};

export type PortfolioMetricsBaseProps = {
	hasBenchmark: boolean;
	title: NodeOrFn<{ lastImportDate?: string; hasCustomMetrics?: boolean }>;
	widgetTooltip: string;
	metricsProvider: () => MaybePromise<
		| {
				data: { metrics: Array<PortfolioMetricsRow>; lastImportDate?: string; hasCustomMetrics?: boolean };
				widgetStatus: WidgetStatus.READY;
		  }
		| {
				data: undefined;
				widgetStatus: Exclude<WidgetStatusUnion, "ready">;
		  }
	>;
	saveHandler?: (data: Array<PortfolioMetricsRow>) => MaybePromise<void>;
	noDataText?: string;
	queryKey: QueryKey;
};

export function PortfolioMetricsBase({
	title,
	widgetTooltip,
	metricsProvider,
	queryKey,
	hasBenchmark,
	saveHandler,
	...props
}: PortfolioMetricsBaseProps): JSX.Element {
	const [edit, setEdit] = useState(false);
	const canEdit = Boolean(saveHandler);

	const saveAsync = useAsync<void, [Array<PortfolioMetricsRow>]>({
		asyncFn: (data) => saveHandler?.(data) ?? noop(),
	});

	const enableRowQuery = hasBenchmark;
	const rowsQuery = useQueryNoRefetch({
		enabled: enableRowQuery,
		queryKey,
		queryFn: metricsProvider,
		onSuccess({ data }) {
			setCachedRows(data?.metrics ?? []);
		},
	});

	useWidgetOptions(
		() => ({
			title: renderNodeOrFn(title, {
				lastImportDate: rowsQuery.data?.data?.lastImportDate,
				hasCustomMetrics: rowsQuery.data?.data?.hasCustomMetrics,
			}),
			actionHeader: function ActionHeader() {
				return (
					<div style={{ display: "flex", flexDirection: "row" }} className="space-x-2">
						{canEdit && rowsQuery.data?.data && (
							<button
								disabled={rowsQuery.isLoading || saveAsync.loading}
								type="button"
								className="block"
								onClick={() => setEdit((e) => !e)}
							>
								<Icon
									icon="Settings"
									size={20}
									color={rowsQuery.isLoading || saveAsync.loading ? themeCSSVars.palette_N400 : colorBySeverity.success}
								/>
							</button>
						)}
						<InfoTooltip>{widgetTooltip}</InfoTooltip>
					</div>
				);
			},
		}),
		[title, canEdit, rowsQuery.data?.data, rowsQuery.isLoading, saveAsync.loading, widgetTooltip],
	);

	const [cachedRows, setCachedRows] = useState<Array<PortfolioMetricsRow>>([]);
	const visibleRows = useMemo(() => cachedRows.filter((x) => x.visible), [cachedRows]);

	return (
		<PortfolioQueryWidgetBase query={rowsQuery}>
			{() => (
				<div className="flex flex-1 flex-col relative z-0 overflow-hidden">
					<div className="relative z-0 flex flex-col flex-1 min-h-0">
						<PortfolioMetricsBaseTable {...props} rows={visibleRows} />
					</div>
					{rowsQuery.data?.data?.hasCustomMetrics && (
						<>
							<HDivider />
							<Row gap={8} flexGrow={0} classList="pt-1" alignItems="center">
								<WarningIndicator />
								<Text type="Body/S/Book" color={themeCSSVars.palette_N400}>
									Imported metric
								</Text>
							</Row>
						</>
					)}
					<Transition
						unmountOnExit
						in={edit}
						classList="absolute z-10 transition-[opacity,transform] inset-0 bg-white flex-1 flex"
						enterFromClassList="opacity-0 -translate-y-8"
						enterToClassList="opacity-100"
					>
						{({ classList }) => (
							<div className={toClassName(classList)}>
								<Controller value={cachedRows} onChange={(x) => setCachedRows(x)}>
									{({ onChange, onCommit, value }) => (
										<PortfolioMetricsDragAndDropEditor
											rows={value}
											onReorder={onChange}
											onVisibilityChange={(name, visible) => {
												onChange(value.map((r) => (r.name === name ? { ...r, visible } : r)));
											}}
											onConfirmAsync={async () => {
												await saveAsync.run(value);
												await rowsQuery.refetch();
												onCommit();
												setEdit(false);
											}}
										/>
									)}
								</Controller>
							</div>
						)}
					</Transition>
				</div>
			)}
		</PortfolioQueryWidgetBase>
	);
}

function PortfolioMetricsDragAndDropEditor({
	rows,
	onReorder,
	onVisibilityChange,
	onConfirmAsync,
}: {
	rows: Array<PortfolioMetricsRow>;
	onReorder(rows: Array<PortfolioMetricsRow>): void;
	onVisibilityChange(name: string, visible: boolean): void;
	onConfirmAsync(): MaybePromise<void>;
}): JSX.Element {
	return (
		<Form noValidate onSubmitAsync={onConfirmAsync} classList="relative !flex flex-col flex-1 min-h-0">
			{({ loading }) => (
				<>
					<Text as="div" classList="mb-4" type="Body/M/Bold">
						Select the information to showcase and determine the preferred order.
					</Text>
					<div className="flex-1 min-h-0 flex relative">
						<ScrollWrapper outerContainerAppearance={{ classList: "w-full" }}>
							<DraggableList disabled={loading} itemKey={(r) => r.name} items={rows} onReorder={onReorder}>
								{({ dragHandleProps, isDragging, draggableProps, item, innerRef }) => (
									<div
										style={{ borderColor: themeCSSVars.palette_N50 }}
										className={toClassName({
											"relative flex flex-row min-w-0 py-2 bg-white transition-shadow border-b": true,
											"shadow-lg": isDragging,
											"border-t": isDragging,
										})}
										{...draggableProps}
										ref={innerRef}
									>
										<div {...dragHandleProps} className="pr-4 pl-1">
											<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M6.04181 3.2944L7.96096 1.37506C8.01677 1.31944 8.10704 1.31944 8.16286 1.37506L10.082 3.2944C10.1228 3.3352 10.1349 3.3966 10.113 3.44991C10.0909 3.50323 10.0389 3.53807 9.98115 3.53807H6.14267C6.08493 3.53807 6.03277 3.50323 6.01083 3.44991C5.98888 3.3966 6.00101 3.3352 6.04181 3.2944ZM8.16305 14.8297C8.10724 14.8853 8.01697 14.8853 7.96115 14.8297L6.04181 12.9103C6.00101 12.8695 5.98888 12.8081 6.01083 12.7548C6.03296 12.7015 6.08512 12.6667 6.14286 12.6667H9.98134C10.0391 12.6667 10.091 12.7015 10.1132 12.7548C10.1351 12.8081 10.123 12.8695 10.0822 12.9103L8.16305 14.8297ZM14.3446 6.00001H1.97822C1.62186 6.00001 1.33337 6.29415 1.33337 6.65052C1.33337 7.00688 1.33337 7.30102 1.97822 7.30102H14.3333C14.701 7.30102 14.9895 7.01254 14.9895 6.65052C14.9895 6.28849 14.701 6.00001 14.3446 6.00001ZM1.97822 9.33334H14.3446C14.701 9.33334 14.9895 9.62183 14.9895 9.98385C14.9895 10.3459 14.701 10.6344 14.3333 10.6344H1.97822C1.33337 10.6344 1.33337 10.3402 1.33337 9.98385C1.33337 9.62748 1.62186 9.33334 1.97822 9.33334Z"
													fill="#8792AB"
												/>
											</svg>
										</div>
										<div className="min-w-0 flex">
											<Checkbox
												classList="min-w-0 [&_label]:min-w-0"
												disabled={loading}
												checked={item.visible}
												onChange={(visible) => onVisibilityChange(item.name, visible)}
											>
												<span className="truncate">{item.label}</span>
											</Checkbox>
										</div>
									</div>
								)}
							</DraggableList>
						</ScrollWrapper>
					</div>
					<div className="flex justify-end">
						<SubmitButton>Apply</SubmitButton>
					</div>
				</>
			)}
		</Form>
	);
}

export function PortfolioMetricsBaseTable({
	rows,
	noDataText,
}: {
	noDataText?: string;
	rows: Array<PortfolioMetricsRow>;
}): JSX.Element {
	const { formatNumber } = useLocaleFormatters();
	const { t } = useTranslation();

	const showProposal = rows.some((r) => Boolean(r.proposal));

	const columns = useMemo<TableColumn<(typeof rows)[number]>[]>(
		() => [
			{
				minWidth: showProposal ? 126 : 144,
				header: t("METRICS"),
				content: ({ label }) => label,
				sortFn: builtInSortFnFor("label"),
				name: "label",
			},
			{
				width: 96,
				cellClassList: "tabular-nums",
				header: "Current",
				align: "end",
				content: ({ current, label, isCurrentCustom, type }, cellProps) => (
					<Sandwich {...cellProps}>
						<Row classList="inset-0 p-[inherit]" alignItems="center" justifyContent="end">
							{`${formatNumber(current)}${
								label.toLowerCase().includes("ratio") ||
								label.toLowerCase().includes("sortino") ||
								type === "SCORE_AVERAGE" ||
								type === "SCORE_AVERAGE_NAN_AS_ZEROS"
									? ""
									: "%"
							}`}
						</Row>
						{isCurrentCustom && <WarningIndicator classList="top-0 right-0 pointer-events-none" />}
					</Sandwich>
				),
				sortFn: builtInSortFnFor("current"),
				name: "current",
			},
			{
				width: 112,
				cellClassList: "tabular-nums",
				header: "Benchmark",
				align: "end",
				content: ({ benchmark, label, isBenchmarkCustom }, cellProps) => (
					<Sandwich {...cellProps}>
						<Row classList="inset-0 p-[inherit]" alignItems="center" justifyContent="end">
							{`${formatNumber(benchmark)}${
								label.toLowerCase().includes("ratio") ||
								label.toLowerCase().includes("sortino") ||
								label.toLowerCase().includes("avg")
									? ""
									: "%"
							}`}
						</Row>
						{isBenchmarkCustom && <WarningIndicator classList="top-0 right-0 pointer-events-none" />}
					</Sandwich>
				),
				sortFn: builtInSortFnFor("benchmark"),
				name: "benchmark",
			},
			...(showProposal
				? [
						{
							width: 96,
							cellClassList: "tabular-nums",
							header: "Proposal",
							align: "end",
							content: ({ proposal, label }) =>
								`${formatNumber(proposal)}${
									label.toLowerCase().includes("ratio") || label.toLowerCase().includes("sortino") ? "" : "%"
								}`,
							sortFn: builtInSortFnFor("proposal"),
							name: "proposal",
						} satisfies TableColumn<(typeof rows)[number]>,
				  ]
				: []),
		],
		[formatNumber, showProposal, t],
	);

	return <AutoSortHScrollTable columns={columns} rows={rows} noDataText={noDataText} />;
}

function WarningIndicator(props: StylableProps) {
	return (
		<Svg {...props} viewBox={{ width: 9, height: 9 }} fill="none">
			<path d="M0 0H9V9L0 0Z" fill="#FFBE00" />
		</Svg>
	);
}

export function ReapplySphereMetricsIconTooltip(props: { lastUpdate: string; uuid?: string }): JSX.Element {
	const uuid = props.uuid;
	const asyncCtx = useAsync<void, [string]>({
		asyncFn: (uid) => reapplySphereMetricsWithConfirmationDialog(uid),
	});

	const { formatDateTime } = useLocaleFormatters();
	return (
		<>
			{asyncCtx.loading ? (
				<CircularProgressBar value={asyncCtx.progress} outerDiameter={16} />
			) : (
				<IconTooltip
					severity="info"
					overrideColor={themeCSSVars.palette_N200}
					overrideIconColor={themeCSSVars.palette_N500}
					overrideIcon="Sync"
					iconSize={16}
				>
					<TooltipContent>
						<Column gap={8}>
							<Column gap={8}>
								<Text type="Body/M/Bold">Metrics have been imported</Text>
								<Text type="Body/M/Book">Last update: {formatDateTime(props.lastUpdate)}</Text>
							</Column>
							<div className="border-t border-[#D1D2DC] -mx-[1rem]" />
							{uuid && (
								<ActionText type="Body/S/Book" classList="self-start" onClick={unpromisify(() => asyncCtx.run(uuid))}>
									Reapply Sphere metrics
								</ActionText>
							)}
						</Column>
					</TooltipContent>
				</IconTooltip>
			)}
		</>
	);
}
