import { IconWalls } from "$root/components/IconWall";
import type { TableColumn } from "@mdotm/mdotui/components";
import { AutoSortHScrollTable, BatchActions, useSelectableTableColumn } from "@mdotm/mdotui/components";
import { useMemo } from "react";
import { useInstrumentCompositionColumns } from "../../columns";
import type { InstrumentEditorEntry } from "../../const";
import type { InstrumentCompositionEditorProps } from "../../instrumentEditor";
import InstrumentLimitReachedBanner from "../InstrumentLimitReachedBanner";
import { useCompositionToolBar } from "../../builder";
import type { useInstrumentColumnPreferenceProps, UserColumnMetadata } from "$root/functional-areas/instruments/hooks";
import { useInstrumentsColumn } from "$root/functional-areas/instruments/hooks";

type DefaultInstrumentTableProps = InstrumentCompositionEditorProps &
	useInstrumentColumnPreferenceProps & {
		filteredRows: InstrumentEditorEntry[];
		rows: InstrumentEditorEntry[];
		columnsMetadata: Array<UserColumnMetadata>;
	};

function BaseInstrumentTable({
	instrumentBuilder,
	filteredRows,
	rows,
	entity,
	limit,
	applyColumnPreferenceApi,
	columnsMetadata,
}: DefaultInstrumentTableProps): JSX.Element {
	const flatInstrumentCompositionColumns = useInstrumentCompositionColumns();
	const toolbar = useCompositionToolBar();
	const {
		customizableColumns: instrumentColumns,
		tools,
		// name,
	} = useInstrumentsColumn({
		allColumns: columnsMetadata,
		mode: "VIEW",
		changeTableLayout: applyColumnPreferenceApi,
	});

	const selectableTableColumn = useSelectableTableColumn({
		rows,
		filteredRows,
		selectBy: (r) => r.rowId,
	});

	const composition = instrumentBuilder.watchComposition();
	const tagsMap = instrumentBuilder.watchTags();
	const tagOptions = useMemo(() => Array.from(tagsMap.values()), [tagsMap]);

	const taggedInstruments = useMemo(() => composition.filter((instrument) => instrument.tagLabel), [composition]);
	const scoredInstruments = useMemo(() => composition.filter((instrument) => instrument.score), [composition]);

	const deleteColumn = useMemo(
		() => ({
			...flatInstrumentCompositionColumns.delete({
				onDelete: (rowId) => instrumentBuilder.delete("hard", rowId),
			}),
			header: tools.header,
		}),
		[flatInstrumentCompositionColumns, instrumentBuilder, tools.header],
	);

	const nameColumn = useMemo(
		() => flatInstrumentCompositionColumns.name({ disableTooltip: true }),
		[flatInstrumentCompositionColumns],
	);

	const columns = useMemo<Array<TableColumn<InstrumentEditorEntry>>>(
		() => [
			selectableTableColumn.column,
			nameColumn,
			...instrumentColumns,
			{
				...flatInstrumentCompositionColumns.tag({
					options: tagOptions,
				}),
				footerCellClassList: "font-semibold",
				footer: `Tagged: ${taggedInstruments.size}`,
			},
			{
				...flatInstrumentCompositionColumns.score({}),
				footerCellClassList: "font-semibold",
				footer: `Scored: ${scoredInstruments.size}`,
			},
			deleteColumn,
		],
		[
			instrumentColumns,
			deleteColumn,
			flatInstrumentCompositionColumns,
			nameColumn,
			scoredInstruments.size,
			selectableTableColumn.column,
			tagOptions,
			taggedInstruments.size,
		],
	);

	const actions = useMemo(
		() => [
			toolbar.delete({
				instrumentBuilder,
				selected: selectableTableColumn.multiSelectCtx,
			}),
		],
		[instrumentBuilder, selectableTableColumn.multiSelectCtx, toolbar],
	);

	return (
		<>
			<BatchActions
				total={rows.length}
				selected={selectableTableColumn.multiSelectCtx.selection.size}
				palette="tertiary"
				actions={actions}
				classList="py-2 h-[58px] shrink-0"
			/>
			<AutoSortHScrollTable
				columns={columns}
				rows={filteredRows}
				pinnedColumns={[
					{ name: nameColumn.name, side: "left" },
					{ name: deleteColumn.name, side: "right" },
				]}
				noDataText={<IconWalls.EditorEmptyData entity={entity} />}
				onRowClick={(row) => selectableTableColumn.toggle(row.rowId)}
				palette="uniform"
			/>
			<br />
			{limit && rows.length > limit && <InstrumentLimitReachedBanner limit={limit} />}
		</>
	);
}

export default BaseInstrumentTable;
