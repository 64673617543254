import { AIConversationsControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import type { SidebarChildrenProps } from "$root/components/Sidebar";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { useQueryNoRefetch } from "$root/utils";
import { AutoTooltip, Badge, Button, Icon, Text, Transition } from "@mdotm/mdotui/components";
import { Switch, toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSort, multiSort, stableEmptyObject } from "@mdotm/mdotui/utils";
import { useMemo, useRef, useState } from "react";
import { SphereAIChatSection } from "./SphereAIChatSection";
import { SphereAIFeedbackSection } from "./SphereAIFeedbackSection";
import { SphereAIHistorySection } from "./SphereAIHistorySection";
import { ChatIcon } from "./icons/ChatIcon";
import { FeedbackIcon } from "./icons/FeedbackIcon";
import { HistoryIcon } from "./icons/HistoryIcon";

export function SphereAISidebarContent({ setPosition, position, onClose, open }: SidebarChildrenProps): JSX.Element {
	const [tabIndex, setTabIndex] = useState(0);
	const tabTitlesContainerRef = useRef<HTMLDivElement | null>(null);
	const [currentChat, setCurrentChat] = useState<string | null>(null);
	const AIChatApi = useApiGen(AIConversationsControllerApiFactory);

	const {
		data: AIChatData,
		// isLoading,
		refetch,
	} = useQueryNoRefetch(["retrieveChatAIData"], {
		queryFn: async () => {
			const [chatHistory, suggestedQuestions] = await Promise.all([
				axiosExtract(AIChatApi.listAllConversations()),
				axiosExtract(AIChatApi.getDefaultMessages()),
			]);
			const existLastChat = chatHistory.conversations && chatHistory.conversations?.length > 0;
			const lastChatUUID = existLastChat
				? chatHistory.conversations
						?.sort((a, b) => new Date(b.lastMessageTime ?? "").getTime() - new Date(a.lastMessageTime ?? "").getTime())
						.slice()[0].uuid
				: undefined;
			return {
				chatHistory: {
					...chatHistory,
					conversations: chatHistory.conversations?.sort((a, b) =>
						multiSort(a, b, [
							(a1, b1) => -builtInSort(a1.favourite, b1.favourite),
							(a2, b2) => builtInSort(b2.creationTime, a2.creationTime),
						]),
					),
				},
				suggestedQuestions,
				lastChatUUID,
			};
		},
		// onSuccess({ lastChatUUID }) {
		// 	setCurrentChat(lastChatUUID ?? null);
		// },
	});

	const underlineStyle = useMemo<{ left?: number; width?: number }>(() => {
		if (!open) {
			return stableEmptyObject;
		}
		const selectedTab = tabTitlesContainerRef.current?.children[tabIndex] as HTMLButtonElement | undefined;
		if (!selectedTab) {
			return stableEmptyObject;
		}
		return {
			left: selectedTab.offsetLeft,
			width: selectedTab.offsetWidth,
		};
	}, [tabIndex, open]);

	// const { state } = useContext(FunctionalAreasContext);

	return (
		<div className="h-full w-full flex flex-col bg-white">
			<div
				className={`bg-[${themeCSSVars.palette_B100}] border-b-2 border-[${themeCSSVars.palette_N100}] relative z-0`}
			>
				<div className="flex justify-between">
					<div className="py-4 px-4 flex items-center space-x-2">
						<Text type="Body/L/Bold" color={themeCSSVars.palette_B500}>
							Ask Sphere AI
						</Text>
						<AutoTooltip
							overrideColor={themeCSSVars.palette_graph_B500}
							trigger={({ innerRef }) => (
								<Badge
									size="x-small"
									color="white"
									backgroundColor={themeCSSVars.palette_graph_B500}
									innerRef={innerRef}
								>
									Beta
								</Badge>
							)}
						>
							Ask Sphere AI enables you to effortlessly obtain valuable financial insights by asking questions and
							interact directly with Sphere. Please note that this feature is currently in the Beta phase, and as an
							exclusive user, you have early access. During this phase, there may be some functional limitations. Your
							feedback is invaluable as it helps us enhance and refine this functionality continually.
						</AutoTooltip>
					</div>
					<div className="py-2 px-4 flex items-start space-x-1">
						{setPosition && (
							<Switch
								case={position}
								match={{
									left: () => (
										<Button unstyled onClick={() => setPosition("right")}>
											<Icon icon="ask-position-rightsmall" color={themeCSSVars.palette_N700} size={18} />
										</Button>
									),
									right: () => (
										<Button unstyled onClick={() => setPosition("left")}>
											<Icon icon="ask-positionleftsmall" color={themeCSSVars.palette_N700} size={18} />
										</Button>
									),
								}}
							/>
						)}
						<Button unstyled onClick={() => onClose?.()}>
							<Icon icon="Close" color={themeCSSVars.palette_N700} size={18} />
						</Button>
					</div>
				</div>
				<div className="flex items-center space-x-6 ml-4 pb-2" ref={tabTitlesContainerRef}>
					<button type="button" onClick={() => setTabIndex(0)} className="flex items-center space-x-2">
						<ChatIcon
							classList="[&_*]:transition-[fill]"
							color={tabIndex === 0 ? themeCSSVars.palette_N700 : themeCSSVars.palette_N400}
						/>
						<Text
							classList="transition-colors"
							type="Body/M/Bold"
							color={tabIndex === 0 ? themeCSSVars.palette_N700 : themeCSSVars.palette_N400}
						>
							Chat
						</Text>
					</button>
					<button type="button" onClick={() => setTabIndex(1)} className="flex items-center space-x-2">
						<HistoryIcon
							classList="[&_*]:transition-[fill]"
							color={tabIndex === 1 ? themeCSSVars.palette_N700 : themeCSSVars.palette_N400}
						/>
						<Text
							classList="transition-colors"
							type="Body/M/Bold"
							color={tabIndex === 1 ? themeCSSVars.palette_N700 : themeCSSVars.palette_N400}
						>
							History
						</Text>
					</button>
					<button type="button" onClick={() => setTabIndex(2)} className="flex items-center space-x-2">
						<FeedbackIcon
							classList="[&_*]:transition-[fill]"
							color={tabIndex === 2 ? themeCSSVars.palette_N700 : themeCSSVars.palette_N400}
						/>
						<Text
							classList="transition-colors"
							type="Body/M/Bold"
							color={tabIndex === 2 ? themeCSSVars.palette_N700 : themeCSSVars.palette_N400}
						>
							Feedback
						</Text>
					</button>
				</div>
				<div className="absolute right-3 bottom-2">
					<Button
						size="small"
						palette="neutralOutline"
						classList={`!border-[${themeCSSVars.palette_N500}] !text-[color:${themeCSSVars.palette_N500}]`}
						onClick={() => {
							setCurrentChat(null);
							setTabIndex(0);
						}}
					>
						New question
					</Button>
				</div>
				<div className="absolute -bottom-[1px] w-full h-[1px]">
					<div
						className={`transition-[left,width] absolute z-0 inset-y-0 bg-[${themeCSSVars.palette_N700}]`}
						style={underlineStyle}
					/>
				</div>
			</div>

			<div className="flex-1 min-h-0 overflow-hidden relative z-0">
				<Transition
					in={tabIndex === 0}
					duration={1000}
					classList="absolute z-0 inset-0 transition-opacity"
					enterFromClassList="opacity-0"
					enterToClassList="opacity-100"
					exitedClassList="hidden"
				>
					{({ classList }) => (
						<div className={toClassName(classList)}>
							<SphereAIChatSection
								key={`chatKey-${currentChat}`}
								chatId={currentChat}
								suggestions={AIChatData?.suggestedQuestions.defaultMessages ?? []}
								setCurrentChat={setCurrentChat}
								refetch={refetch}
							/>
						</div>
					)}
				</Transition>
				<Transition
					in={tabIndex === 1}
					duration={1000}
					classList="absolute z-0 inset-0 transition-opacity"
					enterFromClassList={{
						"opacity-0": true,
					}}
					enterToClassList="opacity-100"
					exitedClassList="hidden"
				>
					{({ classList }) => (
						<div className={toClassName(classList)}>
							<SphereAIHistorySection
								chatHistory={AIChatData?.chatHistory.conversations ?? []}
								setCurrentChat={(chatId) => {
									setCurrentChat(chatId);
									setTabIndex(0);
								}}
								refetch={async () => {
									await refetch();
								}}
							/>
						</div>
					)}
				</Transition>
				<Transition
					in={tabIndex === 2}
					duration={1000}
					classList="absolute z-0 inset-0 transition-opacity"
					enterFromClassList="opacity-0"
					enterToClassList="opacity-100"
					exitedClassList="hidden"
				>
					{({ classList }) => (
						<div className={toClassName(classList)}>
							<SphereAIFeedbackSection />
						</div>
					)}
				</Transition>
			</div>
		</div>
	);
}
