import { instanceEqualsOrFastDeepEqual } from "@mdotm/mdotui/utils";
import type { StoreApi } from "zustand";
import { useStoreWithEqualityFn } from "zustand/traditional";
import { getCellId } from "./spreadsheet-common";
import type { SpreadsheetCtx } from "./spreadsheet-ctx";

export type SpreadsheetPropsValidator<
	Row,
	K extends keyof Row & string,
	ExpectedCellData,
	RestProps,
> = Row[K] extends ExpectedCellData
	? {
			spreadsheetCtx: StoreApi<SpreadsheetCtx<Row>>;
			rowId: string;
			column: K;
	  } & RestProps
	: {
			spreadsheetCtx: StoreApi<SpreadsheetCtx<Row>>;
			rowId: string;
			column: K;
 			"Incompatible column provided. Make sure the column matches the data type of the cell you're configuring": true;
	  } & RestProps;

export type SpreadsheetCellApi<T> = {
	selected: boolean;
	select(mode: "set" | "append"): void;
	active: boolean;
	activate(): void;
	deactivate(): void;
	data: T;
	update(newData: T): void;
};

export function useSpreadsheetCell<Row, K extends keyof Row & string>(
	spreadsheetCtx: StoreApi<SpreadsheetCtx<Row>>,
	rowId: string,
	column: K,
): SpreadsheetCellApi<Row[K]> {
	return useStoreWithEqualityFn(
		spreadsheetCtx,
		(cur) => ({
			active: getCellId(rowId, column) === cur.activeCell,
			activate: () => cur.activate(rowId, column),
			deactivate: () => cur.deactivate(rowId, column),
			selected: cur.selectedCells.has(getCellId(rowId, column)),
			select: (mode: "set" | "append") => cur.select(rowId, column, mode),
			data: cur.dataByRow[rowId][column],
			update: (newCellContent) => cur.update(rowId, column, newCellContent),
		}),
		(a, b) => {
			if (a && b) {
				return a.active === b.active && a.selected === b.selected && instanceEqualsOrFastDeepEqual(a.data, b.data);
			}
			return a === b; // cmp references
		},
	);
}
