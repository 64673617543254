import type { ActionWithOptionalGroup, DataAttributesProps, TableColumn } from "@mdotm/mdotui/components";
import { DropdownMenu, TableDataCell, TableHeadCell } from "@mdotm/mdotui/components";
import { CommonItemActions } from "./common-item-actions";
import type { MaybeFn } from "@mdotm/mdotui/utils";
import { maybeCall, nullary } from "@mdotm/mdotui/utils";
import { TinyDropdownMenuTrigger } from "$root/components/DropdownMenuTrigger";

export function actionsColumn<TRow>(params: {
	onSettingsClick(): void;
	dropdownActions: MaybeFn<Array<ActionWithOptionalGroup<string>>, [TRow]>;
	triggerProps?: DataAttributesProps;
	hideHeader?: boolean;
}): TableColumn<TRow> {
	return {
		name: "settings-action",
		header: (props) => (
			<TableHeadCell {...props} onClick={(e) => e.stopPropagation()}>
				{params.hideHeader ? <></> : <CommonItemActions.Settings onClick={nullary(params.onSettingsClick)} />}
			</TableHeadCell>
		),
		align: "middle",
		content: (row, props) => {
			const actions = maybeCall(params.dropdownActions, row);
			return (
				<TableDataCell {...props} onClick={(e) => e.stopPropagation()}>
					{actions.length ? (
						<DropdownMenu
							trigger={(triggerProps) => <TinyDropdownMenuTrigger {...triggerProps} {...params.triggerProps} />}
							actions={actions}
						/>
					) : (
						<></>
					)}
				</TableDataCell>
			);
		},
		minWidth: 40,
		maxWidth: 40,
	} satisfies TableColumn<TRow, string>;
}
