import type { DataVisualization } from "$root/api/api-gen";
import { PercentageInput } from "$root/components/PercentageInput";
import type {
	SpreadsheetCellApi,
	SpreadsheetPropsValidator,
} from "$root/components/tables-extra/spreadsheet/spreadsheet-cell";
import { useSpreadsheetCell } from "$root/components/tables-extra/spreadsheet/spreadsheet-cell";
import { getThemeCssVars, qualifier, softNonNull, sumArrayLike } from "$root/utils";
import type {
	DataAttributesProps,
	DebouncedSearchInputHandle,
	InnerRefProps,
	StylableProps,
} from "@mdotm/mdotui/components";
import {
	Autocomplete,
	Column,
	ComputedSizeContainer,
	FloatingContent,
	Row,
	Sandwich,
	Svg,
	Text,
	TinyIconButton,
} from "@mdotm/mdotui/components";
import { useMouseOver } from "@mdotm/mdotui/headless";
import {
	focusOnMount,
	ForEach,
	overrideClassList,
	propagateRef,
	Switch,
	useDrivenState,
	useUniqueDOMId,
} from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import {
	approxRound,
	mapBetweenRanges,
	stableEmptyObject,
	typedObjectEntries,
	typedObjectKeys,
	typedObjectValues,
} from "@mdotm/mdotui/utils";
import type { KeyboardEventHandler, ReactNode } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import type { InstrumentSpreadsheetCellTypeMap } from "../Instrument-customisation";
import { colorMap } from "../Instrument-customisation/SidePanelColumnsAdder";
import SelectableCell from "../table-cells/SelectableCell";
import { TagBadge } from "./Tag";
import type { InstrumentTagMap, InstrumentTagWeightMap, PartialInstrumentTagWeightMap } from "./types";

export type TagDataCellProps = {
	tagMap: InstrumentTagMap;
	tagWeightMap: InstrumentTagWeightMap;
	viewMode: DataVisualization;
	disableTooltip?: boolean;
	tooltipFooter?: ReactNode;
} & StylableProps &
	InnerRefProps<HTMLDivElement> &
	DataAttributesProps;

export function TagDataCell({
	tagMap,
	tagWeightMap,
	viewMode,
	innerRef,
	disableTooltip = false,
	classList,
	tooltipFooter,
	...forward
}: TagDataCellProps): JSX.Element {
	const [floatingEl, setFloatingEl] = useState<HTMLElement | null>(null);
	const [cellEl, setCellEl] = useState<HTMLElement | null>(null);
	const isMouseOver = useMouseOver({
		elements: useMemo(() => [floatingEl, cellEl], [floatingEl, cellEl]),
		disabled: disableTooltip || Object.values(tagWeightMap).length === 0,
	});

	return (
		<FloatingContent
			position="right"
			align="startToStart"
			open={isMouseOver}
			relativePositionOffsets={{}}
			relativeAlignmentOffsets={{}}
			trigger={({ innerRef: triggerRef }) => (
				<Row
					{...forward}
					classList={overrideClassList("min-w-0", classList)}
					innerRef={(el) => {
						triggerRef(el);
						propagateRef(el, innerRef);
						setCellEl(el);
					}}
					alignItems="center"
					justifyContent="stretch"
					childrenGrow={1}
				>
					<Switch
						case={viewMode}
						match={{
							BAR_CHART: () => <StackedTagChart classList="grow min-w-0" tagMap={tagMap} tagWeightMap={tagWeightMap} />,
							TAG_VIEW_WITH_COLOR: () => (
								<DataTagCell
									classList="grow min-w-0"
									tagMap={tagMap}
									viewMode="TAG_VIEW_WITH_COLOR"
									tagWeightMap={tagWeightMap}
								/>
							),
							TAG_VIEW_WITHOUT_COLOR: () => (
								<DataTagCell
									classList="grow min-w-0"
									tagMap={tagMap}
									viewMode="TAG_VIEW_WITHOUT_COLOR"
									tagWeightMap={tagWeightMap}
								/>
							),
						}}
					/>
				</Row>
			)}
		>
			<Column
				onClick={(e) => e.stopPropagation()}
				innerRef={setFloatingEl}
				classList={`pointer-events-auto bg-white shadow-xl px-2 py-1.5 border-[color:${themeCSSVars.palette_N200}] border-solid border border-l-0 rounded-sm`}
			>
				<Column classList="overflow-y-auto overflow-x-hidden max-h-[300px]">
					<TagList showUntagged readonly tagMap={tagMap} tagWeightMap={tagWeightMap} />
				</Column>
				{tooltipFooter}
			</Column>
		</FloatingContent>
	);
}

export type SpreadsheetTagDataCellProps = {
	tagMap: InstrumentTagMap;
	viewMode: DataVisualization;
} & StylableProps &
	InnerRefProps<HTMLDivElement>;

export function SpreadsheetTagDataCell<Row, K extends keyof Row & string>({
	tagMap,
	viewMode,
	spreadsheetCtx,
	rowId,
	column,
	...forward
}: SpreadsheetPropsValidator<
	Row,
	K,
	InstrumentSpreadsheetCellTypeMap["tagWeightMap"],
	SpreadsheetTagDataCellProps
>): JSX.Element {
	const renderTag = useCallback(
		(tagProps: { /* tagId */ value: string }) => {
			return (
				<Row alignItems="center" classList="px-5 py-2.5">
					<TagBadge label={tagMap[tagProps.value]!.label} color={tagMap[tagProps.value]!.badgeColor} />
				</Row>
			);
		},
		[tagMap],
	);

	const cellApi = useSpreadsheetCell(spreadsheetCtx, rowId, column) as SpreadsheetCellApi<
		InstrumentSpreadsheetCellTypeMap["tagWeightMap"]
	>;

	const autocompleteRef = useRef<HTMLElement | null>(null);
	const autocompleteHandleRef = useRef<DebouncedSearchInputHandle | null>(null);

	const dirtyRef = useRef(false);
	useEffect(() => {
		dirtyRef.current = false;
	}, []);
	// used to avoid dispatching incomplete state to the parent (e.g. undefined/null weight should be filtered)
	const [tagWeightMap, setTagWeightMap] = useDrivenState<PartialInstrumentTagWeightMap>(
		cellApi.data?.tagWeightMap ?? (stableEmptyObject as InstrumentTagWeightMap),
		{ onSet: () => (dirtyRef.current = true) },
	);

	const validTagWeightMap = useMemo<InstrumentTagWeightMap>(
		() =>
			Object.fromEntries(
				Object.entries(tagWeightMap).filter(([_tag, weight]) => weight != null) as Array<[string, number]>,
			),
		[tagWeightMap],
	);

	const exceeds100 = useMemo(() => {
		const sum = sumArrayLike(typedObjectValues(tagWeightMap), (weight) => weight ?? 0);
		return !(approxRound(sum, 2) < 100.01);
	}, [tagWeightMap]);

	const autocompleteOptions = useMemo(
		() =>
			Object.keys(tagMap)
				.filter((availableId) => !(availableId in tagWeightMap))
				.map((t) => ({ value: t, label: tagMap[t]!.label, children: renderTag })),
		[renderTag, tagMap, tagWeightMap],
	);

	return (
		<SelectableCell hasError={exceeds100} spreadsheetCtx={spreadsheetCtx} {...forward} rowId={rowId} column={column}>
			{({ style }) => (
				<FloatingContent
					onClickAway={cellApi.deactivate}
					triggerLocation="behind"
					position="bottom"
					align="startToStart"
					classList="pointer-events-none"
					open={cellApi.active}
					trigger={({ innerRef }) => (
						<TagDataCell
							innerRef={innerRef}
							classList="grow"
							viewMode={viewMode}
							tagMap={tagMap}
							tagWeightMap={validTagWeightMap}
							style={style}
						/>
					)}
					onAnimationStateChange={(status) => {
						switch (status) {
							case "hidden": {
								// const tagWeightMap = cellApi.data;
								// if (tagWeightMap) {
								// const entries = typedObjectEntries(tagWeightMap).filter(([_tagId, weight]) => weight != null) as Array<
								// 	[string, number]
								// >;
								// const totalWeight = sumArrayLike(entries, ([_tagId, weight]) => weight);
								// cellApi.update(
								// 	Object.fromEntries(
								// 		entries.map(([tagId, weight]) => [tagId, approxRound((weight / totalWeight) * 100, 2)]),
								// 	),
								// );
								// cellApi.update(tagWeightMap);
								// }
								if (dirtyRef.current) {
									cellApi.update({
										...cellApi.data,
										kind: "tagWeightMap",
										tagWeightMap: validTagWeightMap,
									});
									dirtyRef.current = false;
								}
								break;
							}
							case "shown": {
								if (typedObjectKeys(tagWeightMap).length === 0) {
									autocompleteRef.current?.focus({ preventScroll: true });
								}
								break;
							}
							default:
								break;
						}
					}}
				>
					{({ triggerBox, align }) => (
						<Row reverse={align === "endToEnd"} classList="pointer-events-none">
							<Column
								justifyContent="center"
								style={{
									height: triggerBox.height,
								}}
								classList={`bg-white shadow-xl pointer-events-auto border-[color:${themeCSSVars.palette_N200}] border-solid border rounded-sm`}
								onClick={cellApi.deactivate}
							>
								<Sandwich
									classList="grow"
									style={{
										width: triggerBox.width,
									}}
								>
									<TagDataCell
										disableTooltip
										classList="inset-0 px-2"
										viewMode={viewMode}
										tagMap={tagMap}
										tagWeightMap={validTagWeightMap}
									/>
									<div
										className="inset-0 pointer-events-none"
										style={{
											border: `2px solid`,
											borderColor: exceeds100 ? themeCSSVars.palette_D500 : "transparent",
										}}
									/>
								</Sandwich>
							</Column>
							<Column
								onKeyDown={(e) => {
									if (e.key === "Escape") {
										e.preventDefault();
										e.stopPropagation();
										cellApi.deactivate();
									}
								}}
								childrenGrow={0}
								gap={8}
								classList={`pointer-events-auto bg-white shadow-xl px-2 py-1.5 border-[color:${themeCSSVars.palette_N200}] border-solid border border-l-0 rounded-sm`}
							>
								<Autocomplete
									placeholder="Search..."
									innerRef={autocompleteRef}
									value={null}
									options={autocompleteOptions}
									handleRef={autocompleteHandleRef}
									onChange={(option) => {
										setTagWeightMap((cur) => ({
											...cur,
											[option]: undefined,
										}));
										autocompleteHandleRef.current?.clear();
									}}
									classList="[&>input:focus]:border-[#DFE2E7]"
									size="x-small"
									triggerDataAttrs={{ "data-qualifier": qualifier.component.autocomplete.trigger(rowId) }}
									data-qualifier={qualifier.component.autocomplete.list(rowId)}
								>
									<Text as="div" type="Body/S/Medium" classList="py-2 px-2.5">
										{autocompleteOptions.length > 0 ? "Select an option" : "No more tags available"}
									</Text>
								</Autocomplete>
								<Column classList="overflow-y-auto overflow-x-hidden max-h-[300px]">
									<TagList
										showUntagged
										setTagWeightMap={setTagWeightMap}
										tagMap={tagMap}
										tagWeightMap={tagWeightMap}
										onKeyDown={(e) => {
											if (e.key === "Enter") {
												e.preventDefault();
												e.stopPropagation();

												// 1 because onKeyDown is dispatched before the last update
												if (autocompleteOptions.length >= 1) {
													autocompleteRef.current?.focus({ preventScroll: true });
												} else {
													e.currentTarget.blur(); // fire input update
													cellApi.deactivate();
												}
											}
										}}
									/>
								</Column>
							</Column>
						</Row>
					)}
				</FloatingContent>
			)}
		</SelectableCell>
	);
}
export function TagList({
	tagMap,
	tagWeightMap,
	onKeyDown,
	setTagWeightMap,
	readonly,
	showUntagged,
}: {
	tagWeightMap: PartialInstrumentTagWeightMap;
	tagMap: InstrumentTagMap;
	onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
	showUntagged?: boolean;
} & (
	| {
			setTagWeightMap(v: PartialInstrumentTagWeightMap): void;
			readonly?: false;
	  }
	| {
			setTagWeightMap?(v: PartialInstrumentTagWeightMap): void;
			readonly: true;
	  }
)): JSX.Element {
	const entries = useMemo(() => {
		const tagWeightEntries = Object.entries(tagWeightMap);
		const sumOfWeights = approxRound(
			sumArrayLike(tagWeightEntries, ([_, weight]) => weight ?? 0),
			2,
		);
		const missingPointsTo100 = 100 - Math.min(sumOfWeights, 100);
		if (!showUntagged || missingPointsTo100 < 0.01) {
			return tagWeightEntries;
		} else {
			return [...tagWeightEntries, ["Untagged", approxRound(missingPointsTo100, 2), "untagged"] as const];
		}
	}, [showUntagged, tagWeightMap]);
	return (
		<Column childrenGrow={0}>
			<ForEach collection={entries} keyProvider={([option]) => option}>
				{({ item: [option, weight, type] }) => {
					return (
						<Row
							alignItems="center"
							justifyContent="unset"
							classList={`border-[color:${themeCSSVars.palette_N50} border-b border-solid] last:border-none -mx-2 px-2 py-1 last:pb-0`}
						>
							{type === "untagged" ? (
								<Text type="Body/S/Book" classList="!italic">
									{option}
								</Text>
							) : (
								<TagBadge
									label={softNonNull(tagMap[option!]?.label, "", { tagMap, option })}
									color={tagMap[option!]?.badgeColor ?? colorMap.GRAY.badgeColor()}
								/>
							)}
							<Row flexGrow={0} alignItems="center" classList="ml-auto">
								<PercentageInput
									innerRef={type === "untagged" ? undefined : focusOnMount}
									value={weight ?? null}
									onChange={
										type === "untagged"
											? undefined
											: (newWeight) => {
													console.log("onchange", { weight });
													setTagWeightMap?.({
														...tagWeightMap,
														[option]: newWeight ?? undefined,
													});
											  }
									}
									inputAppearance={{
										classList: "focus-visible:outline-none",
									}}
									classList="relative"
									readOnly={readonly || type === "untagged"}
									size="x-small"
									unstyled
									onKeyDown={onKeyDown}
								/>
								{!readonly && (
									<TinyIconButton
										applyNegativeMargins
										icon="Close"
										onClick={
											readonly || type === "untagged"
												? undefined
												: () => {
														const shallowCopyMap = {
															...tagWeightMap,
														};
														delete shallowCopyMap[option];
														setTagWeightMap?.(shallowCopyMap);
												  }
										}
										size="14"
										color={themeCSSVars.palette_N600}
										classList={{
											"opacity-0 pointer-events-none": type === "untagged",
										}}
										data-qualifier={qualifier.component.button(`Delete/Option/${option}`)}
									/>
								)}
							</Row>
						</Row>
					);
				}}
			</ForEach>
		</Column>
	);
}

export type TagStackedChartProps = {
	tagMap: InstrumentTagMap;
	tagWeightMap: InstrumentTagWeightMap;
} & InnerRefProps<HTMLDivElement> &
	StylableProps;

export function StackedTagChart({ tagMap, tagWeightMap, ...forward }: TagStackedChartProps): JSX.Element {
	const clipPathId = useUniqueDOMId();
	const tagWeightList = typedObjectKeys(tagWeightMap);
	const sumOfWeights = sumArrayLike(tagWeightList, (x) => tagWeightMap[x]);
	const missingPointsTo100 = 100 - Math.min(sumOfWeights, 100);
	const filteredTagIdsWithUntagged = useMemo(() => {
		if (missingPointsTo100 < 0.01) {
			return tagWeightList.map((x) => [x, false] as const);
		} else {
			return [...tagWeightList.map((x) => [x, false] as const), [undefined, true] as const];
		}
	}, [tagWeightList, missingPointsTo100]);

	return (
		<ComputedSizeContainer {...forward}>
			{({ width }) =>
				tagWeightList.length === 0 ? (
					<Text type="Body/S/Book" classList="px-2" as="div">
						-
					</Text>
				) : (
					<Svg viewBox={{ height: 8, width }}>
						<defs>
							<clipPath id={clipPathId}>
								<rect x={0} y={2} width="100%" height={4} rx={2} />
							</clipPath>
						</defs>
						{/* TODO: uncomment the following block to add the vertical lines */}
						{/* {filteredTags.length > 1 && (
							<ForEach collection={filteredTags.slice(1)} carry={{ x: mapBetweenRanges(filteredTags[0].weight, 0, 100, 0, width) }}>
									{({ item, carry }) => {
											const x = carry.x;
											const rectWidth = mapBetweenRanges(item.weight, 0, 100, 0, width);
											carry.x += rectWidth;
											return <rect x={x} y={0} width={1} height={8} fill={themeCSSVars.palette_N200} />;
									}}
							</ForEach>
					)} */}
						<g clipPath={`url(#${clipPathId})`}>
							<ForEach collection={filteredTagIdsWithUntagged} carry={{ x: 0 }}>
								{({ item: [tagId, isUntagged], carry }) => {
									const x = carry.x;
									const rectWidth = mapBetweenRanges(
										isUntagged ? missingPointsTo100 : tagWeightMap[tagId],
										0,
										Math.max(100, sumOfWeights),
										0,
										width,
									);
									carry.x += rectWidth;
									return (
										<rect
											x={x}
											y={2}
											width={rectWidth}
											height={4}
											fill={
												isUntagged
													? themeCSSVars.palette_N50
													: tagMap[tagId]?.chartColor
													  ? tagMap[tagId].chartColor
													  : themeCSSVars.palette_N900
											}
										/>
									);
								}}
							</ForEach>
						</g>
					</Svg>
				)
			}
		</ComputedSizeContainer>
	);
}

export type DataTagCellProps = {
	tagMap: InstrumentTagMap;
	tagWeightMap: InstrumentTagWeightMap;
	viewMode: Exclude<DataVisualization, "BAR_CHART">;
} & StylableProps &
	InnerRefProps<HTMLDivElement>;

function DataTagCell({ tagMap, viewMode, tagWeightMap }: DataTagCellProps): JSX.Element {
	const tagWeightList = typedObjectEntries(tagWeightMap);

	return (
		<Row gap={2} alignItems="center" classList="overflow-x-hidden">
			<ForEach collection={tagWeightList.filter(([_option, weight]) => weight)}>
				{({ item: [option] }) => (
					<TagBadge
						label={softNonNull(tagMap[option!]?.label, "", { tagMap, option })}
						color={tagMap[option!]?.badgeColor ?? getThemeCssVars(themeCSSVars.palette_N50)}
						grey={viewMode === "TAG_VIEW_WITHOUT_COLOR"}
					/>
				)}
			</ForEach>
		</Row>
	);
}
