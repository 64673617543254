import type { Currencies, InvestmentBenchmark, InvestmentReference } from "$root/api/api-gen";
import { EntityEditorControllerApiFactory, InvestmentControllerV4ApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import type { MinimumDialogProps } from "$root/components/spawnable/type";
import { useDebouncedNameUniquenessChecker } from "$root/functional-areas/named-entities/uniqueness";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { FormFields } from "$root/ui-lib/form/FormFields";
import { useQueryNoRefetch } from "$root/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import type { Option, OptionWithOptionalGroup } from "@mdotm/mdotui/components";
import { Button, CircularProgressBar, Dialog, DialogFooter, SubmitButton } from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, spawn } from "@mdotm/mdotui/react-extensions";
import { noop } from "@mdotm/mdotui/utils";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { errorMessageNameRequired } from "./handler";

type TargetPortfolioManualCreationFormProps = { name: string; baseCurrency: Currencies };
export type SubmitTargetPortfolioCreationDialogProps = {
	onAsyncSubmit(payload: Required<TargetPortfolioManualCreationFormProps>): MaybePromise<void>;
} & MinimumDialogProps;
const defaultManualCreationData: TargetPortfolioManualCreationFormProps = { name: "", baseCurrency: "EUR" };
function SubmitTargetPortfolioCreationDialog(props: SubmitTargetPortfolioCreationDialogProps) {
	const { show, onClose, onAnimationStateChange, onAsyncSubmit } = props;

	const { t } = useTranslation();
	const entityEditorApi = useApiGen(EntityEditorControllerApiFactory);
	const investmentApi = useApiGen(InvestmentControllerV4ApiFactory);

	const { checkIfNameIsAvailable, checkingNameUniqueness } = useDebouncedNameUniquenessChecker({
		isNameAvailableApi: (name, opts) => axiosExtract(investmentApi.isInvestmentNameAvailable(name, opts)),
	});

	const { control, formState, handleSubmit } = useForm({
		defaultValues: defaultManualCreationData,
		resolver: zodResolver(
			z.object({
				name: z
					.string()
					.min(1, errorMessageNameRequired.TARGET_INVESTMENT)
					.refine(checkIfNameIsAvailable, {
						message: t("MISSING_FIELD.NAME_NOT_AVAILABLE"),
					}),

				baseCurrency: z.string(),
			}),
		),
	});

	const selectableQuery = useQueryNoRefetch({
		queryKey: ["selectable", "currencies", "benchmarks"],
		queryFn: async () => {
			const selectable = await axiosExtract(entityEditorApi.getEditorNewSelectableMainInfo());

			const currencies = (selectable.availableCurrencies ?? []).map(
				(currency): Option<Currencies> => ({
					label: currency ?? "",
					value: currency,
				}),
			);

			const benchmarks = (selectable.availablePrimaryBenchmarks ?? []).map(
				(el): OptionWithOptionalGroup<InvestmentBenchmark, string> => ({
					label: el.benchmarkName ?? "",
					value: { benchmarkIdentifier: el.benchmarkIdentifier, benchmarkType: el.benchmarkType },
					group: t(`INVESTMENT_REFERENCE_CATEGORIES.${el.benchmarkType!}`),
					disabled: !el.available,
				}),
			);

			const references = (selectable.availableInvestmentReferences ?? []).map(
				(el): OptionWithOptionalGroup<InvestmentReference, string> => ({
					label: el.name ?? "-",
					value: { referenceIdentifier: el.identifier, referenceType: el.type } satisfies InvestmentReference,
					group: t(`INVESTMENT_REFERENCE_CATEGORIES.${el.type!}`),
					disabled: !el.available,
				}),
			);

			return { currencies, benchmarks, references };
		},
		refetchOnMount: false,
		cacheTime: 1000 * 60 * 60 * 4,
	});

	return (
		<Dialog
			size="medium"
			noValidate
			show={show}
			onClose={onClose}
			header="Target portfolio name"
			onAnimationStateChange={onAnimationStateChange}
			footer={
				<DialogFooter
					neutralAction={
						<Button palette="tertiary" data-qualifier="CompositionEditor/Modal/Cancel" onClick={onClose}>
							{t("BUTTON.CANCEL")}
						</Button>
					}
					primaryAction={
						<SubmitButton data-qualifier="CompositionEditor/Modal/Save" onClick={noop}>
							{t("BUTTON.GENERATE")}
						</SubmitButton>
					}
				/>
			}
			onSubmitAsync={async () => {
				await handleSubmit((payload) => onAsyncSubmit(payload))();
			}}
		>
			<div className="grid gap-4 transition-all ease-elastic">
				<FormFields.Text
					control={control}
					formState={formState}
					name="name"
					label="Target portfolio name"
					data-qualifier="CompositionEditor/SaveDialog/Name"
					placeholder="Name"
					rightContent={
						checkingNameUniqueness ? <CircularProgressBar classList="w-3" value="indeterminate" /> : undefined
					}
				/>

				<ReactQueryWrapperBase
					query={selectableQuery}
					loadingFallback={<CircularProgressBar style={{ width: "1rem" }} value="indeterminate" />}
				>
					{({ currencies }) => (
						<FormFields.Select
							control={control}
							formState={formState}
							name="baseCurrency"
							label="Base currency"
							i18n={{ triggerPlaceholder: () => t("PORTFOLIOS.PORTFOLIO_CURRENCY_PLACEHOLDER") }}
							style={{ width: "100%" }}
							options={currencies}
							data-qualifier="CompositionEditor/SaveDialog/Currency"
							enableSearch
						/>
					)}
				</ReactQueryWrapperBase>
			</div>
		</Dialog>
	);
}

export type SpawnSubmitTargetPortfolioCreationDialogProps = Omit<
	SubmitTargetPortfolioCreationDialogProps,
	"show" | "onClose"
>;
export function spawnSubmitTargetPortfolioCreationDialog(
	props: SpawnSubmitTargetPortfolioCreationDialogProps,
): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ show, resolve, onHidden }) => (
			<SubmitTargetPortfolioCreationDialog
				{...props}
				show={show}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
				onClose={() => resolve()}
				onAsyncSubmit={async (payload) => {
					await props.onAsyncSubmit(payload);
					resolve();
				}}
			/>
		)),
	);
}
