import type { PortfolioMetric } from "$root/api/api-gen";
import { InvestmentReportsControllerApiFactory, PortfolioStudioPreferencesApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { WidgetStatus, portfolioWidgetMissingDataReason } from "$root/pages/PortfolioDetails/PortfolioWidgetStatus";
import { axiosExtract } from "$root/third-party-integrations/axios";
import type { ContextContent } from "$root/utils";
import { qualifier, withContext } from "$root/utils";
import { PortfolioContext } from "$root/widgets-architecture/contexts/portfolio";
import { Row, Text } from "@mdotm/mdotui/components";
import { Map } from "immutable";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { PortfolioMetricsRow } from "../PortfolioMetrics/PortfolioMetricsBase";
import { PortfolioMetricsBase, ReapplySphereMetricsIconTooltip } from "../PortfolioMetrics/PortfolioMetricsBase";

export const performanceMetricsBlockBaseQueryKey = "Performance H Metrics";

function customClassificationMetricKey(params: { type: string; classificationUuid: string }): string {
	return `${params.type}-${params.classificationUuid}`;
}

const PerformanceMetricsBlock = (props: ContextContent<typeof PortfolioContext>) => {
	const uuid = props.portfolio?.uuid;
	const benchmarkId = props.selectedBenchmark;
	const { t } = useTranslation();
	const reportInvestmentApi = useApiGen(InvestmentReportsControllerApiFactory);
	const portfolioStudioPreferences = useApiGen(PortfolioStudioPreferencesApiFactory);

	const isCalculating = useMemo(
		() =>
			(props.portfolio?.action === "UPLOAD" || props.portfolio?.action === "CREATION") &&
			props.portfolio?.status === "CALCULATING",
		[props.portfolio?.action, props.portfolio?.status],
	);

	return (
		<PortfolioMetricsBase
			hasBenchmark={Boolean(benchmarkId)}
			title={({ lastImportDate, hasCustomMetrics }) => (
				<Row alignItems="center" gap={8}>
					<Text
						type="Body/XL/Bold"
						title={t("PERFORMANCE_METRICS.TITLE")}
						classList="truncate"
						data-qualifier={qualifier.widgets.portfolioPerformanceMetrics.name}
					>
						{t("PERFORMANCE_METRICS.TITLE")}
					</Text>
					{lastImportDate && hasCustomMetrics && (
						<ReapplySphereMetricsIconTooltip lastUpdate={lastImportDate} uuid={uuid} />
					)}
				</Row>
			)}
			widgetTooltip={t("EXPOSURE.TOOLTIP")}
			noDataText={t("PERFORMANCE_METRICS.NO_DATA")}
			queryKey={[
				performanceMetricsBlockBaseQueryKey,
				props.portfolio?.uuid,
				benchmarkId,
				props.portfolio?.status,
				props.reportExcutionCounter,
			]}
			saveHandler={async (data) => {
				await axiosExtract(
					portfolioStudioPreferences.setPortfolioMetricsOrderingPreferences({
						portfolioMetricPreferences: data.map((x) => ({
							enabled: x.visible,
							metricType: x.type!,
							metricParameters: { classificationUuid: x.classificationUuid },
						})),
					}),
				);
			}}
			metricsProvider={async () => {
				const preferences = await axiosExtract(portfolioStudioPreferences.getPortfolioMetricsOrderingPreferences());
				const metrics = await axiosExtract(reportInvestmentApi.getPortfolioMetrics(uuid!, benchmarkId!, preferences));
				if (
					isCalculating ||
					!preferences.portfolioMetricPreferences?.length ||
					(!metrics.portfolioMetrics?.length && !metrics.customPortfolioMetrics?.length)
				) {
					return {
						data: undefined,
						widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "PerformanceMetricsBlock"),
					};
				}

				const metricsMap = Map<string, PortfolioMetric>(
					metrics.portfolioMetrics?.flatMap((metric) =>
						metric.type
							? [
									[
										customClassificationMetricKey({
											type: metric.type,
											classificationUuid: metric.metricParameters?.classificationUuid ?? "",
										}),
										metric,
									],
							  ]
							: [],
					),
				);

				const customMetricsMap = Map<string, PortfolioMetric>(
					metrics.customPortfolioMetrics?.flatMap((metric) =>
						metric.type
							? [
									[
										customClassificationMetricKey({
											type: metric.type,
											classificationUuid: metric.metricParameters?.classificationUuid ?? "",
										}),
										metric,
									],
							  ]
							: [],
					),
				);

				const provider = preferences.portfolioMetricPreferences.flatMap((preference): PortfolioMetricsRow[] => {
					if (!preference.metricType) {
						return [];
					}

					if (preference.metricType === "SCORE_AVERAGE_NAN_AS_ZEROS") {
						return [];
					}
					const key = customClassificationMetricKey({
						type: preference.metricType,
						classificationUuid: preference.metricParameters?.classificationUuid ?? "",
					});
					const label = preference.metadata?.classificationName
						? `Avg ${preference.metadata.classificationName}`
						: t(`PERFORMANCE_METRICS.METRICS.FULL.${preference.metricType}`) ?? "-";
					return [
						{
							label,
							name: label,
							type: preference.metricType,
							classificationUuid: preference.metricParameters?.classificationUuid,
							visible: preference.enabled ?? false,
							current: customMetricsMap.get(key)?.value ?? metricsMap.get(key)?.value,
							benchmark: customMetricsMap.get(key)?.benchmarkValue ?? metricsMap.get(key)?.benchmarkValue,
							isCurrentCustom: customMetricsMap.get(key)?.value != null,
							isBenchmarkCustom: customMetricsMap.get(key)?.benchmarkValue != null,
							metricParameters: preference.metricParameters,
						},
					];
				});

				return {
					data: {
						metrics: provider,
						lastImportDate: metrics.metadataUpdate,
						hasCustomMetrics: customMetricsMap.size > 0,
					},
					widgetStatus: WidgetStatus.READY as const,
				};
			}}
		/>
	);
};

export default withContext(PortfolioContext)(PerformanceMetricsBlock);
