import type {
	CommentaryTemplateDto,
	InvestmentCommentaryResponse,
	InvestmentCommentaryResponseStatusEnum,
	InvestmentSummary,
} from "$root/api/api-gen";
import {
	CommentaryTemplateControllerApiFactory,
	InvestmentControllerV4ApiFactory,
	InvestmentEnhancementControllerV4ApiFactory,
	InvestmentEnhancementReportsControllerApiFactory,
	InvestmentReportsControllerApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import AuthorizationGuard, { hasAccess } from "$root/components/AuthorizationGuard";
import { ButtonWithSelect } from "$root/components/buttons/ButtonWithSelect";
import type { FakeAiLoaderBaseProps } from "$root/components/FakeAiLoader";
import { FakeAiLoaderBase } from "$root/components/FakeAiLoader";
import {
	animationProgressState,
	getAnimationProgressById,
	simulateAnimationProgress,
} from "$root/components/FakeAiLoader/atom";
import type { FakeAiLoaderStep } from "$root/components/FakeAiLoader/common";
import { useFakeAiLoader } from "$root/components/FakeAiLoader/common";
import { IconWalls } from "$root/components/IconWall";
import type { MarkdownRendererProps } from "$root/components/MarkdownRenderer/MarkdownRenderer";
import { MarkdownRenderer } from "$root/components/MarkdownRenderer/MarkdownRenderer";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { useEventBus } from "$root/event-bus";
import { aclByArea } from "$root/functional-areas/acl/checkers/all";
import { START_DATE_CREATE_PROPOSAL_COMMENTARY } from "$root/functional-areas/const";
import { reapplySphereMetricsWithConfirmationDialog } from "$root/functional-areas/portfolio/metrics";
import { useUserValue } from "$root/functional-areas/user";
import { useLocaleFormatters } from "$root/localization/hooks";
import { platformToast } from "$root/notification-system/toast";
import { PortfolioDetailsTabs } from "$root/pages/PortfolioDetails/portfolio-details-tabs";
import { PortfolioWidgetBase, WidgetStatus } from "$root/pages/PortfolioDetails/PortfolioWidgetStatus";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { createPersistentAtom } from "$root/third-party-integrations/zustand";
import type { ContextContent } from "$root/utils";
import { diffrenceISODate, objMatchFn, qualifier, useQueryNoRefetch, withContext } from "$root/utils";
import { WidgetBlockContext } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import type { Option, StylableProps } from "@mdotm/mdotui/components";
import {
	ActionText,
	Banner,
	ClickableDiv,
	CollapsibleBase,
	Column,
	Icon,
	Row,
	ScrollWrapper,
	Text,
} from "@mdotm/mdotui/components";
import { type MaybePromise } from "@mdotm/mdotui/headless";
import { useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { Updater } from "@mdotm/mdotui/utils";
import { identity, noop, unpromisify } from "@mdotm/mdotui/utils";
import fastDeepEqual from "fast-deep-equal";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PortfolioContext } from "../contexts/portfolio";

function PortfolioCommentaryBodyMDBlockV2(props: {
	data: InvestmentCommentaryResponse;
	ctx: ContextContent<typeof PortfolioContext>;
	templates: CommentaryTemplateDto[];
	onGenerate(selected: string): MaybePromise<void>;
	selected: string;
	cachedCommentaryStatus: InvestmentCommentaryResponseStatusEnum | null;
	loaderProps: FakeAiLoaderBaseProps;
}) {
	const { data, ctx, templates, selected, cachedCommentaryStatus } = props;
	const { commentary, commentaryDate, status, template, usedCustomMetrics, metadataLastUpdate, prevUsedCustomMetrics } =
		data;
	const { portfolio, enhanced } = ctx;
	const { formatDateTime } = useLocaleFormatters();

	const options = useMemo(
		(): Array<Option<string>> =>
			templates?.flatMap((x) => (x.name && x.uuid && x.visible ? [{ label: x.name, value: x.uuid }] : [])) ?? [],
		[templates],
	);

	const canUserGenerateNewCommentary = useMemo(() => {
		if (enhanced) {
			return diffrenceISODate(portfolio?.lastActionDate, START_DATE_CREATE_PROPOSAL_COMMENTARY) > 0;
		}

		return true;
	}, [enhanced, portfolio?.lastActionDate]);
	// const selectedTemplate = useMemo(() => options.find((x) => x.value === selected), [options, selected]);

	return (
		<>
			<ScrollWrapper>
				{(usedCustomMetrics || prevUsedCustomMetrics) && (
					<ReapplySphereMetricsBanner classList="mb-4" uuid={portfolio?.uuid} metadataLastUpdate={metadataLastUpdate} />
				)}
				<MarkdownRenderer componentOverrides={markdownOverrides}>{commentary ?? ""}</MarkdownRenderer>
			</ScrollWrapper>
			<div className="py-1 mt-auto">
				{cachedCommentaryStatus === "CALCULATING" ? (
					<FakeAiLoaderBase {...props.loaderProps} />
				) : (
					<div className="flex justify-between py-1 mt-auto">
						{commentaryDate && (
							<div className="flex gap-1">
								<Icon icon="Clock" color={themeCSSVars.palette_N300} size={16} classList="my-auto" />
								<div>
									<p className="whitespace-pre-line">
										<Text type="Body/S/Book">Generated on </Text>
										<Text type="Body/S/Bold" data-qualifier={qualifier.widgets.portfolioCommentary.timeStamp}>
											{formatDateTime(commentaryDate, { omitYear: true })}
										</Text>
									</p>
									<p className="whitespace-pre-line">
										<Text type="Body/S/Book">Template </Text>
										<Text type="Body/S/Bold" data-qualifier={qualifier.widgets.portfolioCommentary.templateName}>
											{template?.name}
										</Text>
									</p>
								</div>
							</div>
						)}
						<AuthorizationGuard
							permissionChecker={aclByArea.portfolio.canEditComposition}
							acl={portfolio?.richAcl?.acl ?? []}
						>
							{canUserGenerateNewCommentary && (
								<div className="flex items-center gap-2">
									<ButtonWithSelect
										options={options}
										value={selected}
										disabled={
											portfolio?.status === "CALCULATING" || portfolio?.status === "ERROR" || status === "CALCULATING"
										}
										palette="secondary"
										size="small"
										onClick={props.onGenerate}
										enableSearch
										dataAttributes={{
											buttonDataAttributes: { "data-qualifier": qualifier.widgets.portfolioCommentary.generate },
											selectDataAttributes: { "data-qualifier": qualifier.widgets.portfolioCommentary.selectTemplate },
										}}
									>
										Generate
									</ButtonWithSelect>
								</div>
							)}
						</AuthorizationGuard>
					</div>
				)}
			</div>
		</>
	);
}

export function usePersistentFakeAiLoaderAnimation(portfolioUid: string): {
	fakeLoaderState: FakeAiLoaderStep;
	stopFakeLoader: () => Promise<void>;
	restartFakeLoader: () => Promise<void>;
} {
	const commentaryGenerationProgress = getAnimationProgressById(portfolioUid ?? "-");
	const { setAnimationProgress } = animationProgressState();

	const initialFakeLoaderState = useMemo(
		() =>
			commentaryGenerationProgress
				? simulateAnimationProgress(
						commentaryGenerationProgress.date,
						commentaryGenerationProgress.progress,
						commentaryGenerationProgress.step,
						commentaryGenerationProgress.step !== "preparing" ? 0.175 : 0.02,
				  )
				: undefined,
		[commentaryGenerationProgress],
	);

	const { state: fakeLoaderState, ...handles } = useFakeAiLoader({
		autoStart: initialFakeLoaderState && initialFakeLoaderState.progress < 1,
		initialState: initialFakeLoaderState,
		onStateChange({ stepName, progress }) {
			if (portfolioUid) {
				setAnimationProgress((prevAnimationProgress) => {
					const indexOfAnimation = prevAnimationProgress.findIndex((animation) => animation.id === portfolioUid);

					if (indexOfAnimation > -1) {
						prevAnimationProgress[indexOfAnimation].progress = progress;
						prevAnimationProgress[indexOfAnimation].step = stepName;
						prevAnimationProgress[indexOfAnimation].date = new Date();
						return prevAnimationProgress;
					}

					return [...prevAnimationProgress, { id: portfolioUid, progress, step: stepName, date: new Date() }];
				});
			}
		},
	});

	const handlesRef = useUnsafeUpdatedRef(handles);
	const stopFakeLoader = useCallback(async () => {
		await handlesRef.current.stop();
		setAnimationProgress((animations) => animations.filter((animation) => animation.id !== portfolioUid));
	}, [handlesRef, portfolioUid, setAnimationProgress]);
	const restartFakeLoader = useCallback(async () => {
		await handlesRef.current.stop();
		handlesRef.current.start();
	}, [handlesRef]);

	return {
		fakeLoaderState,
		stopFakeLoader,
		restartFakeLoader,
	};
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function usePortfolioCommentary({
	portfolio,
	enhanced,
	// TODO: replace with invalidateQueries
	reportExcutionCounter,
}: {
	portfolio: InvestmentSummary;
	enhanced: boolean;
	reportExcutionCounter?: number;
}) {
	const { fakeLoaderState, stopFakeLoader, restartFakeLoader } = usePersistentFakeAiLoaderAnimation(
		portfolio.uuid ?? "",
	);

	const previousCommentaryGenerationStatusRef = useRef<InvestmentCommentaryResponseStatusEnum | null>(null);
	const [cachedCommentaryStatus, _setCommentaryGenerationStatus] =
		useState<InvestmentCommentaryResponseStatusEnum | null>(null);
	const setCachedCommentaryStatus = useCallback((v: typeof cachedCommentaryStatus) => {
		_setCommentaryGenerationStatus((cur) => {
			previousCommentaryGenerationStatusRef.current = cur;
			return v;
		});
	}, []);
	useEffect(() => {
		if (!previousCommentaryGenerationStatusRef.current) {
			return;
		}
		if (cachedCommentaryStatus !== "CALCULATING") {
			stopFakeLoader().catch(noop);
		} else if (
			previousCommentaryGenerationStatusRef.current !== "CALCULATING" &&
			cachedCommentaryStatus === "CALCULATING"
		) {
			restartFakeLoader().catch(noop);
		}
	}, [cachedCommentaryStatus, restartFakeLoader, stopFakeLoader]);

	const enhanceInvestmentReportApi = useApiGen(InvestmentEnhancementReportsControllerApiFactory);
	const investmentReportApi = useApiGen(InvestmentReportsControllerApiFactory);

	//commentary api
	const investmentV4Api = useApiGen(InvestmentControllerV4ApiFactory);
	const investmentEnhancementV4Api = useApiGen(InvestmentEnhancementControllerV4ApiFactory);
	const commentaryTemplateApi = useApiGen(CommentaryTemplateControllerApiFactory);

	const commentaryTemplatesQuery = useQueryNoRefetch({
		queryKey: [portfolioCommentaryBlockBaseQueryKey, reportExcutionCounter],
		enabled: Boolean(portfolio.uuid),
		queryFn: async () => {
			const templates = await axiosExtract(commentaryTemplateApi.getTemplateList());
			return templates;
		},
	});
	const commentaryQuery = useQueryNoRefetch(
		[portfolioCommentaryBlockBaseQueryKey, portfolio.uuid, enhanced, portfolio.status, reportExcutionCounter],
		{
			refetchInterval: cachedCommentaryStatus === "CALCULATING" ? 60 * 1000 : false,
			enabled: Boolean(portfolio.uuid),
			queryFn: async () => {
				const commentary = await axiosExtract(
					enhanced
						? enhanceInvestmentReportApi.getCommentaries1(portfolio.uuid ?? "")
						: investmentReportApi.getCommentaries(portfolio.uuid ?? ""),
				);

				return commentary;
			},
			onError: (e) => console.warn(e),
			onSuccess(data) {
				if (data.status) {
					setCachedCommentaryStatus(data.status);
					if (!previousCommentaryGenerationStatusRef.current) {
						previousCommentaryGenerationStatusRef.current = data.status ?? null;
					}
				}
			},
		},
	);

	useEventBus("commentary-update", {
		filter: objMatchFn({ uuid: portfolio.uuid }),
		listener: () => {
			commentaryQuery.refetch().catch(noop);
		},
	});

	const generateCommentaryNonBlocking = useCallback(
		(templateUuid: string) => {
			let sanitizedTemplateUuid = templateUuid;
			const templates = commentaryTemplatesQuery.data;
			if (templates && !templates.find((x) => x.uuid === templateUuid)) {
				sanitizedTemplateUuid = templates.find((x) => x.name === "Standard Template")?.uuid ?? templateUuid;
			}

			setCachedCommentaryStatus("CALCULATING");
			if (enhanced) {
				investmentEnhancementV4Api
					.createCommentaryForEnhancementWithTemplate(portfolio.uuid ?? "", sanitizedTemplateUuid)
					.catch(noop); //TODO: change
			} else {
				investmentV4Api.createCommentaryFromTemplateUUID(portfolio.uuid ?? "", sanitizedTemplateUuid).catch(noop);
			}
		},
		[
			commentaryTemplatesQuery.data,
			investmentEnhancementV4Api,
			investmentV4Api,
			enhanced,
			portfolio.uuid,
			setCachedCommentaryStatus,
		],
	);

	const templateOptions = useMemo(
		(): Array<Option<string>> =>
			commentaryTemplatesQuery.data?.flatMap((x) =>
				x.name && x.uuid && x.visible ? [{ label: x.name, value: x.uuid }] : [],
			) ?? [],
		[commentaryTemplatesQuery.data],
	);

	return useMemo(
		() => ({
			fakeLoaderState,
			commentaryQuery,
			templateOptions,
			generateCommentaryNonBlocking,
			cachedCommentaryStatus,
			commentaryTemplatesQuery,
		}),
		[
			fakeLoaderState,
			commentaryQuery,
			templateOptions,
			generateCommentaryNonBlocking,
			cachedCommentaryStatus,
			commentaryTemplatesQuery,
		],
	);
}

export const portfolioCommentaryBlockBaseQueryKey = "portfolioCommentaryWidget";
const PortfolioCommentaryMDBlock = (ctx: ContextContent<typeof PortfolioContext>) => {
	const { portfolio, enhanced, reportExcutionCounter } = ctx;
	const user = useUserValue();

	const { t } = useTranslation();

	// POLLING STATUS ogni 60sec solo se commetaryStatus CALCULATING
	// se arriva commentary-update triggerare subito refetch

	const { setWidgetOptions } = useContext(WidgetBlockContext);
	const { push } = useTypedNavigation();

	const {
		commentaryQuery,
		commentaryTemplatesQuery,
		fakeLoaderState,
		generateCommentaryNonBlocking,
		cachedCommentaryStatus,
		templateOptions,
	} = usePortfolioCommentary({
		portfolio: portfolio ?? {},
		enhanced,
		reportExcutionCounter,
	});

	useEffect(() => {
		setWidgetOptions({
			actionHeader: () =>
				hasAccess(user, { requiredServices: ["PORTFOLIO_STUDIO_COMMENTARY_TAB"] }) ? (
					<Text
						color={themeCSSVars.palette_P400}
						type="Body/M/Bold"
						as="button"
						onClick={() =>
							push("PortfolioDetails", {
								portfolioUid: portfolio?.uuid ?? "",
								proposal: String(enhanced),
								tab: PortfolioDetailsTabs.COMMENTARY,
							})
						}
					>
						Open in tab
					</Text>
				) : (
					<></>
				),
			title: (
				<Text
					type="Body/XL/Bold"
					title={enhanced ? t("PROPOSAL_COMMENTARY.TITLE") : t("CURRENT_COMMENTARY.TITLE")}
					classList="truncate"
					data-qualifier={qualifier.widgets.portfolioCommentary.name}
				>
					{enhanced ? t("PROPOSAL_COMMENTARY.TITLE") : t("CURRENT_COMMENTARY.TITLE")}
				</Text>
			),
			// TODO: based on API response
			/* someCondition ? [
						{
							variant: "warning",
							title: "Commentary may be out of date",
							content: "Some of the descriptions for the instruments contained in this portfolio are expired.",
						},
				  ] : */
			alerts: [],
			alertsActive: true,
			alertsFootnote: (
				<div>
					<Text type="Body/M/Book">Customize your</Text>{" "}
					<ActionText type="Body/M/Book" href={typedUrlForRoute("PortfolioStudioSettings/InstrumentCustomisation", {})}>
						instrument descriptions
					</ActionText>
				</div>
			),
		});
	}, [t, setWidgetOptions, enhanced, user, push, portfolio?.uuid]);

	return (
		<div className="h-full flex flex-col">
			<ReactQueryWrapperBase
				query={commentaryQuery}
				loadingFallback={<IconWalls.CalculatingApi />}
				errorFallback="Generative AI commentary is not available at the moment. It will be generated soon!"
			>
				{(commentaryData) => {
					const widgetData = (() => {
						if (
							(commentaryData.status === "CALCULATING" || portfolio?.status === "CALCULATING") &&
							!commentaryData.commentary
						) {
							return {
								data: undefined,
								widgetStatus: WidgetStatus.CALCULATING as const,
							};
						}
						if (!commentaryData.commentary) {
							return {
								data: { ...commentaryData, templates: commentaryTemplatesQuery.data ?? [] },
								widgetStatus: WidgetStatus.EMPTY as const,
							};
						}
						return {
							data: { ...commentaryData, templates: commentaryTemplatesQuery.data ?? [] },
							widgetStatus: WidgetStatus.READY as const,
						};
					})();

					return (
						<PortfolioWidgetBase
							{...widgetData}
							iconWalls={{
								empty: IconWalls.PortfolioCommentaryEmpty({
									onGenerate: (templateId) => {
										if (templateId) {
											generateCommentaryNonBlocking(templateId);
											return;
										}

										platformToast({
											children: "Unable to generate portfolio commentary",
											severity: "error",
											icon: "Ask-ai",
										});
									},
									generateOption: templateOptions,
									defaultValue: commentaryQuery.data?.template?.uuid,
								}),
							}}
						>
							{(response) => {
								const { templates, template } = response;

								return (
									<PortfolioCommentaryBodyMDBlockV2
										onGenerate={generateCommentaryNonBlocking}
										ctx={ctx}
										data={response}
										templates={templates}
										selected={template!.uuid!}
										cachedCommentaryStatus={cachedCommentaryStatus}
										loaderProps={{
											state: fakeLoaderState,
										}}
									/>
								);
							}}
						</PortfolioWidgetBase>
					);
				}}
			</ReactQueryWrapperBase>
		</div>
	);
};

const markdownOverrides: MarkdownRendererProps["componentOverrides"] = {
	table: ({ node: _node, ...props }) => <table className="w-full border-collapse" {...props} />,
	thead: ({ node: _node, ...props }) => <thead {...props} />,
	tr: ({ node: _node, ...props }) => (
		<tr className={`even:bg-[#F7F8F9] border-b border-b-[color:${themeCSSVars.palette_N100}]`} {...props} />
	),
	td: ({ node: _node, ...props }) => <td className="text-left p-2 !text-[10px]" {...props} />,
	th: ({ node: _node, ...props }) => (
		<th className="text-left px-2 py-1 !font-bold !text-[10px] !uppercase text-[#667085]" {...props} />
	),
	tbody: ({ node: _node, ...props }) => <tbody {...props} />,
};

export default withContext(PortfolioContext)(PortfolioCommentaryMDBlock);

const defaultSphereMetricsBannerExpand = true;
const useReapplySphereMetricsBannerExpandStore = createPersistentAtom<boolean>(
	defaultSphereMetricsBannerExpand,
	"useReapplySphereMetricsBannerExpandStore",
);

export function useReapplySphereMetricsBannerExpandState(): {
	expand: boolean;
	setExpand: (newValue: boolean | Updater<boolean>) => void;
	reset(): void;
} {
	const { value: expand, set: setExpand } = useReapplySphereMetricsBannerExpandStore(identity, (a, b) =>
		fastDeepEqual(a.value, b.value),
	);
	return {
		expand,
		setExpand,
		reset: () => setExpand(defaultSphereMetricsBannerExpand),
	};
}

//TODO: update body copy
export function ReapplySphereMetricsBanner(
	props: { uuid?: string; metadataLastUpdate?: string } & StylableProps,
): JSX.Element {
	const uuid = props.uuid;
	const { expand, setExpand } = useReapplySphereMetricsBannerExpandState();
	const { formatDateTime } = useLocaleFormatters();

	return (
		<Banner
			severity="info"
			title={
				<ClickableDiv classList="-mb-1" onClick={() => setExpand(!expand)}>
					<Row alignItems="center" gap={8} justifyContent="space-between">
						<Row alignItems="center" gap={8}>
							<Text type="Body/M/Bold">Imported metrics in use</Text>
							<Text type="Body/M/Book">
								{props.metadataLastUpdate ? ` (last import: ${formatDateTime(props.metadataLastUpdate)})` : ""}
							</Text>
						</Row>
						<div
							className="transition-transform aria-[expanded=true]:[transform:rotateX(180deg)] flex"
							aria-expanded={expand}
						>
							<Icon icon="Down" size={20} color={themeCSSVars.palette_N500} />
						</div>
					</Row>
				</ClickableDiv>
			}
			{...props}
		>
			<CollapsibleBase expand={expand}>
				<Column gap={4} classList="pt-1">
					<Text type="Body/M/Book">The commentary reflects only the data provided through your imported metrics</Text>
					<Row justifyContent="end">
						{uuid && (
							<ActionText
								disabled={!expand}
								type="Body/S/Bold"
								onClick={unpromisify(() => reapplySphereMetricsWithConfirmationDialog(uuid))}
							>
								Reapply Sphere metrics
							</ActionText>
						)}
					</Row>
				</Column>
			</CollapsibleBase>
		</Banner>
	);
}
