import type { ReviewTicker } from "$root/api/api-gen";
import {
	BenchmarksControllerApiFactory,
	EntityEditorControllerApiFactory,
	InvestmentReportsControllerApiFactory,
	ReferenceUniversesControllerApiFactory,
} from "$root/api/api-gen";
import { runWithErrorReporting } from "$root/api/error-reporting/report";
import { useApiGen } from "$root/api/hooks";
import { useCrumbs } from "$root/components/Crumbs/useCrumbs";
import { LeavePrompt } from "$root/components/LeavePrompt";
import { PageHeader } from "$root/components/PageHeader";
import { typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { aclByArea } from "$root/functional-areas/acl/checkers/all";
import { retrieveUserInstrumentsClassifications } from "$root/functional-areas/instruments-editor/builder";
import { SmallInvestmentSummary } from "$root/functional-areas/portfolio/SmallInvestmentSummary";
import { ProxyInstrumentEditor } from "$root/functional-areas/proxies/EditProxiedInstrumentSection";
import type { EditProxiedBuilderProps, ReviewEntity } from "$root/functional-areas/proxies/helpers";
import {
	generateCompositionToSubmit,
	initialProxyBuilder,
	initInstrumentsProxyEditor,
	someInstrumentsNeedProxy,
} from "$root/functional-areas/proxies/helpers";
import { SmallUniverseSummary } from "$root/functional-areas/universe/SmallUniverseSummary";
import { useUserValue } from "$root/functional-areas/user";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { qualifier, ToastableError, useQueryNoRefetch } from "$root/utils";
import { Card } from "$root/widgets-architecture/layout/Card";
import { AsyncButton, Banner, Button, Text } from "@mdotm/mdotui/components";
import { useCallback, useMemo, useState } from "react";
import { flushSync } from "react-dom";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { match } from "ts-pattern";
import { PortfolioStudioTab } from "./PortfoliosStudio/portfolio-studio-tabs";

type ApiEntity =
	| "INVESTMENT"
	| "INVESTMENT_ENHANCEMENT"
	| "UNIVERSE"
	| "TARGET_INVESTMENT"
	| "BENCHMARK"
	| "INVESTMENT_DRAFT";

type BaseApiEntity = Exclude<ApiEntity, "INVESTMENT_ENHANCEMENT" | "INVESTMENT_DRAFT">;
const reviewEntityToApiEntity = {
	benchmark: "BENCHMARK",
	portfolio: "INVESTMENT",
	target_portfolio: "TARGET_INVESTMENT",
	universe: "UNIVERSE",
} satisfies Record<ReviewEntity, BaseApiEntity>;

const ReviewComposition = (): JSX.Element => {
	const [proxyBuilder, setProxyBuilder] = useState<EditProxiedBuilderProps>(initialProxyBuilder);
	const [isBuilderDirty, setIsBuilderDirty] = useState<boolean>(false);
	const [pathToNotBlock, setPathToNotBlock] = useState<string[]>([typedUrlForRoute("Login", {})]);

	const { t } = useTranslation();
	const history = useHistory();
	const { uuid, entity } = useParams<{ uuid: string; entity: ReviewEntity }>();

	const apiEntity = reviewEntityToApiEntity[entity];

	const crumbs = useCrumbs();
	const user = useUserValue();

	const investmentReportApi = useApiGen(InvestmentReportsControllerApiFactory);
	const entityEditorApi = useApiGen(EntityEditorControllerApiFactory);
	const benchmarksApi = useApiGen(BenchmarksControllerApiFactory);
	const referenceUniversesApi = useApiGen(ReferenceUniversesControllerApiFactory);

	const querySummary = useQueryNoRefetch(["queryReviewCompositionSummary", uuid], {
		queryFn() {
			return match(apiEntity)
				.with("INVESTMENT", async () => {
					const response = await axiosExtract(investmentReportApi.getInvestmentSummary(uuid));
					return { name: response.name, richAcl: response.richAcl, status: response.status };
				})
				.with("TARGET_INVESTMENT", async () => {
					const response = await axiosExtract(investmentReportApi.getInvestmentSummary(uuid));
					return { name: response.name, richAcl: response.richAcl, status: response.status };
				})
				.with("BENCHMARK", async () => {
					const response = await axiosExtract(benchmarksApi.getBenchmarkSummary(uuid));
					return { name: response.name, richAcl: response.richAcl, status: response.status };
				})
				.with("UNIVERSE", async () => {
					const response = await axiosExtract(referenceUniversesApi.getUniverse(uuid));
					return { name: response.name, richAcl: response.richAcl, status: response.status };
				})
				.exhaustive();
		},
	});

	const { data: summary, isLoading: isSummaryLoading } = querySummary;

	const forwardingPage = useMemo(
		() =>
			match(apiEntity)
				.with("INVESTMENT", () =>
					typedUrlForRoute("PortfolioDetails", {
						portfolioUid: uuid,
						proposal: String(summary?.status === "PROPOSAL_READY"),
					}),
				)
				.with("TARGET_INVESTMENT", () => typedUrlForRoute("PortfolioDetails", { portfolioUid: uuid }))
				.with("BENCHMARK", () => typedUrlForRoute("CustomBenchmark", { benchmarkId: uuid }))
				.with("UNIVERSE", () => typedUrlForRoute("UniverseDetails", { universeUuid: uuid }))
				.exhaustive(),
		[apiEntity, summary?.status, uuid],
	);

	const forwardingToBackPage = useMemo(
		() =>
			match(apiEntity)
				.with("INVESTMENT", () => typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.Portfolios }))
				.with("TARGET_INVESTMENT", () => typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.References }))
				.with("BENCHMARK", () => typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.References }))
				.with("UNIVERSE", () => typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.Universes }))
				.exhaustive(),
		[apiEntity],
	);

	const canEditComposition = useMemo(
		() =>
			summary?.richAcl?.acl ? aclByArea.portfolio.canEditComposition(user.id, summary?.richAcl?.acl ?? []) : false,
		[summary?.richAcl?.acl, user.id],
	);

	const queryReviewEditorComposition = useQueryNoRefetch(["queryReviewEditorComposition", uuid], {
		async queryFn() {
			const editorReviewComposition = await axiosExtract(entityEditorApi.getEditorReviewComposition(uuid, apiEntity));
			const nonNullableComposition = editorReviewComposition.composition ?? [];
			const { userInstrumentsMap } = await retrieveUserInstrumentsClassifications(nonNullableComposition);

			return { editorResponse: editorReviewComposition, userInstrumentsMap };
		},
		onSuccess({ editorResponse }) {
			if (editorResponse.composition && editorResponse.composition.length > 0) {
				setProxyBuilder(initInstrumentsProxyEditor(editorResponse.composition));
			}
		},
	});

	const { data } = queryReviewEditorComposition;

	const cannotSubmit = useMemo(() => {
		if (!data?.editorResponse.composition || data?.editorResponse.composition.length === 0) {
			return true; //set as true since a composition without instruments cannot be submitted
		}

		return someInstrumentsNeedProxy(data?.editorResponse.composition, proxyBuilder);
	}, [data?.editorResponse.composition, proxyBuilder]);

	const onSubmitAsync = useCallback(
		async (params: { composition?: ReviewTicker[]; uuid: string }) => {
			await runWithErrorReporting(
				async () => {
					if (!params.composition) {
						throw new ToastableError(t("SOMETHING_WENT_WRONG"), { cause: "missing composition", icon: "Portfolio" });
					}
					await entityEditorApi.saveEditorReviewEntity(apiEntity, {
						identifier: params.uuid,
						tickerComposition: params.composition,
					});
					flushSync(() => setPathToNotBlock([forwardingPage]));
					history.push(forwardingPage);
				},
				{
					area: "portfolio",
					attemptedOperation: {
						message: `submit reviewed composition "${uuid}"`,
						payload: JSON.stringify(params.composition),
					},
				},
			);
		},
		[apiEntity, entityEditorApi, forwardingPage, history, t, uuid],
	);

	return (
		<>
			<PageHeader
				name="PortfolioDetails"
				title={isSummaryLoading ? "..." : summary?.name ?? "Untitled"}
				crumbsV2={match(apiEntity)
					.with("INVESTMENT", () => [
						...crumbs.portfolioStudio("Portfolios", uuid),
						...crumbs.portfolioName({ name: summary?.name, uuid }),
						...crumbs.reviewInstrument,
					])
					.with("TARGET_INVESTMENT", () => [
						...crumbs.portfolioStudio("References", uuid),
						...crumbs.targetPortfolioName({ name: summary?.name, uuid }),
						...crumbs.reviewInstrument,
					])
					.with("BENCHMARK", () => [
						...crumbs.portfolioStudio("References", uuid),
						...crumbs.benchmarkName({ name: summary?.name, uuid }),
						...crumbs.reviewInstrument,
					])
					.with("UNIVERSE", () => [
						...crumbs.portfolioStudio("Universes", uuid),
						...crumbs.universeName({ name: summary?.name, uuid }),
						...crumbs.reviewInstrument,
					])
					.exhaustive()}
				titleAction={
					<div className="flex items-center space-x-2">
						<Button
							size="small"
							palette="tertiary"
							onClick={() => history.push(forwardingToBackPage)}
							data-qualifier={qualifier.reviewInstruments.cancel}
						>
							{t("BUTTON.CANCEL")}
						</Button>

						{canEditComposition && (
							<AsyncButton
								size="small"
								palette="primary"
								disabled={cannotSubmit}
								onClickAsync={() =>
									onSubmitAsync({
										composition: data?.editorResponse.composition
											? generateCompositionToSubmit(data.editorResponse.composition, proxyBuilder)
											: undefined,
										uuid,
									})
								}
								data-qualifier={qualifier.reviewInstruments.save}
							>
								{t("BUTTON.SAVE")}
							</AsyncButton>
						)}
					</div>
				}
			/>
			<ReactQueryWrapperBase query={querySummary}>
				{() => (
					<>
						{match(apiEntity)
							.with("INVESTMENT", () => (
								<div className="mb-5">
									<SmallInvestmentSummary enhanced={false} portfolio={{ reference: false, uuid }} />
								</div>
							))
							.with("TARGET_INVESTMENT", () => (
								<div className="mb-5">
									<SmallInvestmentSummary enhanced={false} portfolio={{ reference: true, uuid }} />
								</div>
							))
							.with("BENCHMARK", () => <></>)
							.with("UNIVERSE", () => (
								<div className="mb-5">
									<SmallUniverseSummary universe={{ uuid }} />
								</div>
							))
							.exhaustive()}
						<ReactQueryWrapperBase query={queryReviewEditorComposition}>
							{({ editorResponse, userInstrumentsMap }) => (
								<Card>
									<Text type="Body/XL/Bold" classList="mb-4">
										Composition
									</Text>

									{/* <ReviewCompositionErrors errors={editorCompositionResponse.uploadErrors} /> */}

									<Banner classList="mb-4" severity="warning" title="Review Before Saving">
										Some instruments require your attention. This may be due to missing information or because they have
										been delisted. Please review the list below and make any necessary updates before saving.
									</Banner>

									<ProxyInstrumentEditor
										composition={editorResponse.composition ?? []}
										disableEditing={!canEditComposition}
										proxyBuilder={proxyBuilder}
										onChangeProxyBuilder={(newBuilder) => {
											setProxyBuilder(newBuilder);
											setIsBuilderDirty((prev) => (!prev ? !prev : prev));
										}}
										uuid={uuid}
										section={entity}
										userInstrumentsMap={userInstrumentsMap}
									/>
								</Card>
							)}
						</ReactQueryWrapperBase>
					</>
				)}
			</ReactQueryWrapperBase>
			<LeavePrompt title={t("LEAVE_PAGE")} when={isBuilderDirty} pathToNotBlock={pathToNotBlock}>
				{t("EDIT_COMPOSITION.LEAVE")}
			</LeavePrompt>
		</>
	);
};

export default ReviewComposition;
