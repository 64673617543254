import type { FieldKey } from "$root/api/api-gen";

export const metricToMeasurementUnit: Partial<Record<FieldKey, string>> = {
	PERFORMANCE_SINCE_INCEPTION: "%",
	PERFORMANCE_YTD: "%",
	PERFORMANCE_1Y: "%",
	PERFORMANCE_6M: "%",
	PERFORMANCE_3M: "%",
	PERFORMANCE_1M: "%",
	SORTINO_SINCE_INCEPTION: "%",
	SORTINO_YTD: "%",
	SORTINO_1Y: "%",
	SORTINO_6M: "%",
	SORTINO_3M: "%",
	SORTINO_1M: "%",
	MAX_DRAWDOWN_SINCE_INCEPTION: "%",
	MAX_DRAWDOWN_YTD: "%",
	MAX_DRAWDOWN_1Y: "%",
	MAX_DRAWDOWN_6M: "%",
	MAX_DRAWDOWN_3M: "%",
	MAX_DRAWDOWN_1M: "%",
	VOLATILITY_SINCE_INCEPTION: "%",
	VOLATILITY_YTD: "%",
	VOLATILITY_1Y: "%",
	VOLATILITY_6M: "%",
	VOLATILITY_3M: "%",
	VOLATILITY_1M: "%",
	EFFICIENCY_RATIO_SINCE_INCEPTION: "%",
	EFFICIENCY_RATIO_YTD: "%",
	EFFICIENCY_RATIO_1Y: "%",
	EFFICIENCY_RATIO_6M: "%",
	EFFICIENCY_RATIO_3M: "%",
	EFFICIENCY_RATIO_1M: "%",
	EX_ANTE_RETURN_3Y: "%",
	EX_ANTE_EFFICIENCY_RATIO_3Y: "%",
	EX_ANTE_MAX_DRAWDOWN_3Y: "%",
	EX_ANTE_DIVERSIFICATION_RATIO_3Y: "%",
	TRACKING_ERROR_3Y: "%",
	PARAMETRIC_VAR_95_1Y: "%",
	PARAMETRIC_VAR_975_1Y: "%",
	PARAMETRIC_VAR_99_1Y: "%",
	PARAMETRIC_VAR_95_2Y: "%",
	PARAMETRIC_VAR_975_2Y: "%",
	PARAMETRIC_VAR_99_2Y: "%",
	PARAMETRIC_VAR_95_3Y: "%",
	PARAMETRIC_VAR_975_3Y: "%",
	PARAMETRIC_VAR_99_3Y: "%",
	HISTORICAL_VAR_95_1Y: "%",
	HISTORICAL_VAR_975_1Y: "%",
	HISTORICAL_VAR_99_1Y: "%",
	HISTORICAL_VAR_95_2Y: "%",
	HISTORICAL_VAR_975_2Y: "%",
	HISTORICAL_VAR_99_2Y: "%",
	HISTORICAL_VAR_95_3Y: "%",
	HISTORICAL_VAR_975_3Y: "%",
	HISTORICAL_VAR_99_3Y: "%",
	EX_ANTE_VOLATILITY_1Y: "%",
	EX_ANTE_VOLATILITY_2Y: "%",
	EX_ANTE_VOLATILITY_3Y: "%",
	EX_ANTE_VOLATILITY_6M: "%",
	EXPOSURE_MACRO_EQUITY: "%",
	EXPOSURE_MACRO_FIXED_INCOME: "%",
	EXPOSURE_MACRO_COMMODITIES: "%",
	EXPOSURE_MACRO_ALTERNATIVE: "%",
	EXPOSURE_MACRO_MONEY_MARKET: "%",

	SCORE_AVERAGE: "",
	SCORE_AVERAGE_NAN_AS_ZEROS: "",
	BENCHMARK_EFFICIENCY_RATIO_1M: "%",
	BENCHMARK_MAX_DRAWDOWN_1M: "%",
	BENCHMARK_PERFORMANCE_1M: "%",
	BENCHMARK_SORTINO_1M: "%",
	BENCHMARK_VOLATILITY_1M: "%",
	TAG_EXPOSURE_OPTION: "%",
	RISK_MODEL_EXPOSURE_AC: "%",
	//todo: missing fields
};
