import { FormController } from "$root/third-party-integrations/react-hook-form";
import { valueByPath } from "$root/utils";
import type { StylableProps } from "@mdotm/mdotui/components";
import { FormField } from "@mdotm/mdotui/components";
import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import { renderNodeOrFn } from "@mdotm/mdotui/react-extensions";
import type { ReactNode } from "react";
import type { Control, ControllerRenderProps, FieldValues, FormState, Path } from "react-hook-form";

export type CustomFieldProps<T extends FieldValues, TName extends Path<T>> = StylableProps & {
	control: Control<T, any>;
	formState: FormState<T>;
	name: TName;
	label: ReactNode;
	children: NodeOrFn<{ id: string; invalid: boolean; field: ControllerRenderProps<T, TName> }>;
};

export function CustomField<T extends FieldValues, TName extends Path<T>>({
	label,
	name,
	formState,
	control,
	classList,
	style,
	children,
}: CustomFieldProps<T, TName>): JSX.Element {
	return (
		<FormField
			classList={classList}
			style={style}
			label={label}
			error={(valueByPath(formState.errors, name) as { message?: string })?.message}
		>
			{({ id, invalid }) => (
				<FormController
					control={control}
					name={name}
					render={({ field }) => <>{renderNodeOrFn(children, { id, invalid, field })}</>}
				/>
			)}
		</FormField>
	);
}
