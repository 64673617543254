import type { InvestmentExposureEntry } from "$root/api/api-gen";
import {
	InstrumentsClassificationsControllerV1ApiFactory,
	InvestmentEnhancementReportsControllerApiFactory,
	InvestmentExportControllerApiFactory,
	InvestmentReportsControllerApiFactory,
} from "$root/api/api-gen";
import { getApiGen } from "$root/api/factory";
import { useApiGen } from "$root/api/hooks";
import { IconWallBase, IconWalls } from "$root/components/IconWall";
import ReactQueryWrapper from "$root/components/ReactQueryWrapper";
import MonitorWithHourglass from "$root/components/glyphs/MonitorWithHourglass";
import env from "$root/env";
import { useLocaleFormatters } from "$root/localization/hooks";
import { groupedColorProps } from "$root/pages/PortfolioDetails/PortfolioComposition/colors";
import {
	PortfolioQueryWidgetBase,
	WidgetStatus,
	portfolioWidgetMissingDataReason,
} from "$root/pages/PortfolioDetails/PortfolioWidgetStatus";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { Highcharts } from "$root/third-party-integrations/highcharts-with-modules";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import type { ContextContent } from "$root/utils";
import { downloadContentDisposition, qualifier, stableColorGenerator, useQueryNoRefetch, withContext } from "$root/utils";
import { PortfolioContext } from "$root/widgets-architecture/contexts/portfolio";
import { InfoTooltip } from "$root/widgets-architecture/layout/WidgetsMapper/InfoTooltip";
import { useGridBlock, useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import type { Option, TableWithGroupsProps } from "@mdotm/mdotui/components";
import {
	AutoSortTableWithGroups,
	CircularProgressBar,
	DropdownMenu,
	Icon,
	Row,
	Select,
	Text,
} from "@mdotm/mdotui/components";
import { overrideClassName, toClassName, useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { } from "@mdotm/mdotui/utils";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";
import HighchartsReact from "highcharts-react-official";
import type Immutable from "immutable";
import { Set } from "immutable";
import type { CSSProperties, ReactNode, StyleHTMLAttributes } from "react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { InvestmentExposureResponseExposureTypeEnum } from "./InvestmentExposureResponseExposureTypeEnum";

// type WidgetStatuses = Exclude<InvestmentStatuses, "RETRIEVING_DATA" | "REVIEW">;
// type WidgetActions = Exclude<InvestmentActions, "RETRIEVING_DATA_FOR_UPLOAD">;

export type ExposureChartData = Array<{
	label: string;
	groupName: string;
	weight: number;
	netLong: boolean;
	drillDown: Array<{
		label: string;
		weight: number;
		netLong: boolean;
	}>;
}>;

/** @deprecated */
export const exposureCategoryInfo: Record<
	InvestmentExposureResponseExposureTypeEnum,
	{
		label: string;
		columnTitle: string;
		type: "proxy" | "hedge" | "tag";
	}
> = {
	MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS: {
		label: "Macro Asset Class vs Micro Asset Class",
		columnTitle: "Macro Asset Class",
		type: "proxy",
	},
	MACRO_ASSET_CLASS_VS_GEOGRAPHY: {
		label: "Macro Asset Class vs Geography",
		columnTitle: "Macro Asset Class",
		type: "proxy",
	},
	MICRO_ASSET_CLASS_VS_GEOGRAPHY: {
		label: "Micro Asset Class vs Geography",
		columnTitle: "Micro Asset Class",
		type: "proxy",
	},
	GEOGRAPHY_VS_MACRO_ASSET_CLASS: {
		label: "Geography vs Macro Asset Class",
		columnTitle: "Geography",
		type: "proxy",
	},
	GEOGRAPHY_VS_MICRO_ASSET_CLASS: {
		label: "Geography vs Micro Asset Class",
		columnTitle: "Macro Geography",
		type: "proxy",
	},
	MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: {
		label: "Macro Asset Class vs Macro Geography",
		columnTitle: "Macro Asset Class",
		type: "proxy",
	},
	MACRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY: {
		label: "Macro Asset Class vs Micro Geography",
		columnTitle: "Macro Asset Class",
		type: "proxy",
	},
	MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: {
		label: "Micro Asset Class vs Macro Geography",
		columnTitle: "Micro Asset Class",
		type: "proxy",
	},
	MICRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY: {
		label: "Micro Asset Class vs Micro Geography",
		columnTitle: "Micro Asset Class",
		type: "proxy",
	},
	MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY: {
		label: "Macro Geography vs Micro Geography",
		columnTitle: "Macro Geography",
		type: "proxy",
	},
	CURRENCY: {
		label: "Currency",
		columnTitle: "Currency",
		type: "hedge",
	},
	TAG: {
		label: "Tags",
		columnTitle: "Tags",
		type: "tag",
	},
	MACRO_ASSET_CLASS: {
		label: "Macro Asset Class",
		columnTitle: "Macro Asset Class",
		type: "proxy",
	},
	MACRO_GEOGRAPHY: {
		label: "Macro Geography",
		columnTitle: "Macro Geography",
		type: "proxy",
	},
	MICRO_GEOGRAPHY: {
		label: "Micro Geography",
		columnTitle: "Micro Geography",
		type: "proxy",
	},
	MICRO_ASSET_CLASS: {
		label: "Micro Asset Class",
		columnTitle: "Micro Asset Class",
		type: "proxy",
	},
};

export type ExposureCategory = {
	label: string;
	columnTitle: string;
	type: "proxy" | "hedge" | "tag";
	value: string;
};

export const riskModelExposureCategories: Array<
	Omit<ExposureCategory, "value"> & { value: InvestmentExposureResponseExposureTypeEnum }
> = [
	{
		label: "Macro Asset Class vs Micro Asset Class",
		columnTitle: "Macro Asset Class",
		type: "proxy",
		value: "MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS",
	},
	{
		label: "Macro Asset Class vs Geography",
		columnTitle: "Macro Asset Class",
		type: "proxy",
		value: "MACRO_ASSET_CLASS_VS_GEOGRAPHY",
	},
	{
		label: "Micro Asset Class vs Geography",
		columnTitle: "Micro Asset Class",
		type: "proxy",
		value: "MICRO_ASSET_CLASS_VS_GEOGRAPHY",
	},
	{
		label: "Geography vs Macro Asset Class",
		columnTitle: "Geography",
		type: "proxy",
		value: "GEOGRAPHY_VS_MACRO_ASSET_CLASS",
	},
	{
		label: "Geography vs Micro Asset Class",
		columnTitle: "Macro Geography",
		type: "proxy",
		value: "GEOGRAPHY_VS_MICRO_ASSET_CLASS",
	},
	{
		label: "Macro Asset Class vs Macro Geography",
		columnTitle: "Macro Asset Class",
		type: "proxy",
		value: "MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY",
	},
	{
		label: "Macro Asset Class vs Micro Geography",
		columnTitle: "Macro Asset Class",
		type: "proxy",
		value: "MACRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY",
	},
	{
		label: "Micro Asset Class vs Macro Geography",
		columnTitle: "Micro Asset Class",
		type: "proxy",
		value: "MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY",
	},
	{
		label: "Micro Asset Class vs Micro Geography",
		columnTitle: "Micro Asset Class",
		type: "proxy",
		value: "MICRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY",
	},
	{
		label: "Macro Geography vs Micro Geography",
		columnTitle: "Macro Geography",
		type: "proxy",
		value: "MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY",
	},
	{
		label: "Currency",
		columnTitle: "Currency",
		type: "hedge",
		value: "CURRENCY",
	},
	{
		label: "Tags",
		columnTitle: "Tags",
		type: "tag",
		value: "TAG",
	},
	{
		label: "Macro Asset Class",
		columnTitle: "Macro Asset Class",
		type: "proxy",
		value: "MACRO_ASSET_CLASS",
	},
	{
		label: "Macro Geography",
		columnTitle: "Macro Geography",
		type: "proxy",
		value: "MACRO_GEOGRAPHY",
	},
	{
		label: "Micro Geography",
		columnTitle: "Micro Geography",
		type: "proxy",
		value: "MICRO_GEOGRAPHY",
	},
	{
		label: "Micro Asset Class",
		columnTitle: "Micro Asset Class",
		type: "proxy",
		value: "MICRO_ASSET_CLASS",
	},
];

const allowedExposureCategoriesSet: Immutable.Set<string> = Set([
	"MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS",
	"MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY",
	"MACRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY",
	"MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY",
	"MICRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY",
	"MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY",
	"CURRENCY",
	"TAG",
] satisfies Array<InvestmentExposureResponseExposureTypeEnum>);

export type ExposureCategories = Record<"fromRiskModel" | "fromTagClassifications", Array<ExposureCategory>>;

export async function fetchExposureCategories(): Promise<ExposureCategories> {
	const classifications = await axiosExtract(
		getApiGen(InstrumentsClassificationsControllerV1ApiFactory).retrieveAllClassifications(),
	);
	const tagClassifications = classifications.filter((c) => c.fieldType === "TAG");
	return {
		fromRiskModel: riskModelExposureCategories.filter((x) => allowedExposureCategoriesSet.has(x.value)),
		fromTagClassifications: tagClassifications.map(
			(c) =>
				({
					label: c.name!,
					value: c.classificationUuid!,
					columnTitle: c.name!,
					type: "tag",
				}) satisfies ExposureCategory,
		),
	};
}

export function WithExposureCategories({
	children,
}: {
	children(exposureCategories: ExposureCategories): ReactNode;
}): JSX.Element {
	return (
		<ReactQueryWrapper
			queryKey={["exposureOptions"]}
			queryFn={fetchExposureCategories}
			loadingFallback={<IconWalls.CalculatingApi />}
		>
			{children}
		</ReactQueryWrapper>
	);
}

export function ExposureCategorySelector({
	exposureCategory,
	setExposureCategory,
	exposureCategories,
}: {
	exposureCategory: ExposureCategoryState;
	setExposureCategory(category: ExposureCategoryState): void;
	exposureCategories: ExposureCategories;
}): JSX.Element {
	const secondLevelCategoriesOptions = useMemo(
		() =>
			exposureCategory.firstLevel === "RISK_MODEL"
				? exposureCategories.fromRiskModel.map((cat) => ({
						label: cat.label,
						value: cat.value,
						// group: "Risk model",
				  }))
				: exposureCategories.fromTagClassifications.map((cat) => ({
						label: cat.label,
						value: cat.value,
						// group: "Tag",
				  })),
		[exposureCategory.firstLevel, exposureCategories.fromRiskModel, exposureCategories.fromTagClassifications],
	);
	return (
		<div className="flex gap-2">
			<Select
				value={exposureCategory.firstLevel}
				onChange={(selectedFirstLevel) => {
					if (selectedFirstLevel) {
						setExposureCategory({
							...exposureCategory,
							firstLevel: selectedFirstLevel,
							secondLevel:
								selectedFirstLevel === "RISK_MODEL"
									? exposureCategories.fromRiskModel.at(0)?.value ?? null
									: exposureCategories.fromTagClassifications.at(0)?.value ?? null,
						});
					}
				}}
				options={firstLevelOption}
			/>
			<Select
				value={exposureCategory.secondLevel}
				onChange={(selectedSecondLevel) => {
					if (selectedSecondLevel) {
						setExposureCategory({
							...exposureCategory,
							secondLevel: selectedSecondLevel,
						});
					}
				}}
				options={secondLevelCategoriesOptions}
			/>
		</div>
	);
}

export const ExposureChart = (props: {
	/** @default true */
	animated?: boolean;
	data: ExposureChartData;
	style?: StyleHTMLAttributes<HTMLDivElement>;
	customHeight?: CSSProperties["height"];
	hideLabel?: boolean;
}): JSX.Element => {
	const { formatNumber } = useLocaleFormatters();
	const options = useMemo<Highcharts.Options>(
		() => ({
			chart: {
				type: "pie",
				style: { fontFamily: "Gotham,sans-serif" },
				reflow: true,
			},
			title: { text: undefined },
			subtitle: { text: undefined },
			panning: false,
			credits: { enabled: false },
			legend: { enabled: false },
			plotOptions: {
				pie: {
					animation: props.animated
						? undefined
						: {
								duration: 0,
						  },
					shadow: false,
					size: "100%",
					dataLabels: {
						enabled: !props.hideLabel,
					},
				},
			},
			tooltip: {
				shared: true,
				useHTML: true,
				distance: 22,
				backgroundColor: "#FFFFFF",
				borderColor: undefined,
				borderRadius: 4,
				borderWidth: 0,
				style: {
					padding: "0",
					color: "#656d78",
					fontSize: "12px",
					boxShadow: "0 2px 10px 0 rgba(47, 53, 65, 0.15)",
				},
				formatter() {
					let tooltip_html =
						'<div class="DivTooltip" style="width: auto; min-width: 120px"><div class="Tooltip-title" style="color: #1D2433">' +
						this.key +
						((this.point as { netLong?: boolean }).netLong === false
							? ` - [
							<svg class="inline w-[8px] mb-1" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
								<circle opacity="0.3" r="4" cx="4" cy="4" fill="${stableColorGenerator(
									(this.point as { groupName?: string }).groupName!,
								)}"></circle>
							</svg><span class="truncate pl-1">Net Short</span>]`
							: "") +
						"</div>";
					tooltip_html += "<table>";
					tooltip_html +=
						'<tr><td style="padding: 5px 8px 0 0" style="color: #2F3541">' +
						"Current" +
						'</td><td style="text-align: right; font-weight: 500;padding: 5px 0 0 0; color: #2F3541"> ' +
						formatNumber(this.y) +
						"%" +
						"</td></tr>";
					tooltip_html += "</table></div>";
					return tooltip_html;
				},
			},
			series: [
				{
					name: "Primary",
					data: props.data.map((primary) => ({
						name: primary.label,
						groupName: primary.groupName,
						value: primary.weight,
						y: primary.weight,
						color: stableColorGenerator(primary.groupName),
					})),
					dataSorting: {
						enabled: true,
						sortKey: "y",
					},
					size: "80%",
					type: "pie",
				},
				{
					name: "Secondary",
					data: props.data.flatMap((primary) =>
						primary.drillDown.length === 0
							? [
									{
										name: primary.label,
										value: primary.weight,
										y: primary.weight,
										color: "#ffffff",
										netLong: primary.netLong,
										groupName: primary.groupName,
									},
							  ]
							: primary.drillDown.map((drillDown) => ({
									name: drillDown.label,
									value: primary.weight,
									y: drillDown.weight,
									color: Highcharts.color(stableColorGenerator(primary.groupName)).brighten(0.15).get(),
									netLong: drillDown.netLong,
									groupName: primary.groupName,
							  })),
					),
					type: "pie",
					size: "80%",
					innerSize: "70%",
					id: "secondary",
					dataLabels: { enabled: false },
				},
			],
			responsive: {
				rules: [
					{
						condition: {
							maxWidth: 420,
						},
						chartOptions: {
							pane: {
								size: "100%",
							},
						},
					},
				],
			},
			exporting: { enabled: false },
		}),
		[formatNumber, props.animated, props.data, props.hideLabel],
	);

	return (
		<HighchartsReact
			containerProps={{ style: { height: props.customHeight ?? "320px", width: "100%", ...props.style } }}
			highcharts={Highcharts}
			constructorType="chart"
			options={options}
		/>
	);
};

export function aggregateExposureData(composition: InvestmentExposureEntry[]): Record<
	string,
	{
		label: string;
		groupName: string;
		weight: number;
		netLong: boolean;
		drillDown: Array<{
			label: string;
			weight: number;
			netLong: boolean;
		}>;
	}
> {
	return composition.reduce(
		(acc, cur) => {
			if (!acc[cur.firstQualityLevel!]) {
				acc[cur.firstQualityLevel!] = {
					label: cur.firstQualityLevel!,
					weight: 0,
					drillDown: [],
					groupName: cur.firstQualityLevel!,
					netLong: cur.netLong ?? true,
				};
			}
			acc[cur.firstQualityLevel!].weight += cur.weight!;

			if (cur.firstQualityLevel !== cur.secondQualityLevel) {
				acc[cur.firstQualityLevel!].drillDown.push({
					label: cur.secondQualityLevel!,
					weight: cur.weight!,
					netLong: cur.netLong ?? true,
				});
			}
			return acc;
		},
		{} as Record<string, ExposureChartData[number]>,
	);
}

export type ExposureCategoryState = {
	firstLevel: "RISK_MODEL" | "CUSTOM_TAG";
	secondLevel: string | null;
};

const firstLevelOption = [
	{ label: "Risk Model", value: "RISK_MODEL" },
	{ label: "Tags", value: "CUSTOM_TAG" },
] as Option<ExposureCategoryState["firstLevel"]>[];

const ExposureEvolve = (ctx: ContextContent<typeof PortfolioContext>) => {
	const { portfolio, selectedBenchmark } = ctx;
	const { t } = useTranslation();

	const uuid = portfolio?.uuid;
	const benchmarkId = selectedBenchmark ?? portfolio?.primaryBenchmarkIdentifier;

	useWidgetOptions(
		() => ({
			title: t("EXPOSURE.TITLE"),
			actionHeader: <InfoTooltip>{t("EXPOSURE.TOOLTIP")}</InfoTooltip>,
		}),
		[t],
	);

	const exposureAvailableQuery = useQueryNoRefetch(["queryExposureLevelEvolve", portfolio, uuid, benchmarkId], {
		enabled: Boolean(portfolio),
		queryFn: () => {
			if (!uuid || !benchmarkId) {
				return {
					data: undefined,
					widgetStatus: portfolioWidgetMissingDataReason(portfolio!, "ExposureEvolve"),
				};
			}

			return {
				data: {},
				widgetStatus: WidgetStatus.READY,
			};
		},
	});

	return (
		<PortfolioQueryWidgetBase query={exposureAvailableQuery}>
			{() => (
				<WithExposureCategories>
					{(exposureCategories) => (
						<ExposureEvolveInner ctx={ctx} exposureCategories={exposureCategories} benchmarkId={benchmarkId} />
					)}
				</WithExposureCategories>
			)}
		</PortfolioQueryWidgetBase>
	);
};

const ExposureEvolveInner = (props: {
	ctx: ContextContent<typeof PortfolioContext>;
	exposureCategories: ExposureCategories;
	benchmarkId: string | undefined;
}) => {
	const investmentEnhancementReportApi = useApiGen(InvestmentEnhancementReportsControllerApiFactory);
	const investmentReportsApi = useApiGen(InvestmentReportsControllerApiFactory);
	const [exposureCategory, setExposureCategory] = useState<ExposureCategoryState>({
		firstLevel: "RISK_MODEL",
		secondLevel: "MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS" satisfies InvestmentExposureResponseExposureTypeEnum,
	});

	const { t } = useTranslation();
	const { ctx, exposureCategories, benchmarkId } = props;
	const { portfolio, enhanced, reportExcutionCounter } = ctx;

	const query = useQueryNoRefetch(
		[
			"queryExposureLevelEvolve",
			portfolio?.uuid,
			enhanced,
			benchmarkId,
			exposureCategory,
			portfolio?.status,
			reportExcutionCounter,
			exposureCategory.secondLevel,
		],
		{
			keepPreviousData: true,
			queryFn: () =>
				!portfolio?.uuid || !benchmarkId || !exposureCategory.secondLevel
					? null
					: enhanced
					  ? axiosExtract(
								investmentEnhancementReportApi.getTwoLevelsInvestmentExposure1(
									portfolio.uuid,
									benchmarkId,
									exposureCategory.secondLevel,
								),
					    )
					  : axiosExtract(
								investmentReportsApi.getTwoLevelsInvestmentExposure(
									portfolio.uuid,
									benchmarkId,
									exposureCategory.secondLevel,
								),
					    ),
		},
	);

	const exportApi = useApiGen(InvestmentExportControllerApiFactory);

	const exportPortfolioExposureNew = async (uuid: string) => {
		const response = await exportApi.exportMultiGraphOfExposure(uuid, { responseType: "blob" });

		downloadContentDisposition(response);
		trackMixPanelEvent("Portfolio", {
			Type: "Export",
			Area: "Exposure",
			Mode: "XLS",
			ID: uuid,
		});
	};

	const exportPortfolioExposureNewRef = useUnsafeUpdatedRef(exportPortfolioExposureNew);

	const gridBlock = useGridBlock();
	useWidgetOptions(
		() => ({
			title: (
				<Row gap={8} alignItems="center">
					<Text
						type="Body/XL/Bold"
						title={t("EXPOSURE.TITLE")}
						classList="truncate"
						data-qualifier={qualifier.widgets.portfolioExposure.name}
					>
						{t("EXPOSURE.TITLE")}
					</Text>
					{query.isFetching && <CircularProgressBar value="indeterminate" outerDiameter={16} />}
				</Row>
			),
			actionHeader: function Download() {
				return (
					<div style={{ display: "flex", flexDirection: "row" }} className="space-x-2">
						{env.featureFlags.widgets.grid.resizable && gridBlock && (
							<button type="button" onClick={() => gridBlock.setBlockWidth(gridBlock.blockWidth === 1 ? 2 : 1)}>
								<Icon size={20} color={themeCSSVars.MessageSeverity_success} icon="News-category-Contraints" />
							</button>
						)}
						{/* FIXME add noData Option*/}
						{!(query.isError || query.isFetching) && (
							<DropdownMenu
								trigger={({ innerRef, open, ...forward }) => (
									<button ref={innerRef} aria-expanded={open} type="button" {...forward}>
										<Icon icon="Dowload" color={themeCSSVars.MessageSeverity_success} size={20} />
									</button>
								)}
								actions={[
									{
										icon: "xls",
										onClickAsync: async () => {
											await exportPortfolioExposureNewRef.current(portfolio!.uuid!);
										},
										label: `${t("EXPOSURE.DOWNLOAD_TITLE")}`,
									},
								]}
							/>
						)}
						<InfoTooltip>{t("EXPOSURE.TOOLTIP")}</InfoTooltip>
					</div>
				);
			},
		}),
		[t, gridBlock, query.isError, query.isFetching, exportPortfolioExposureNewRef, portfolio],
	);

	const chartData = useMemo(() => {
		if (!query.data) {
			return null;
		}
		const { enhancementComposition, investmentComposition } = query.data;
		if (enhanced && !enhancementComposition) {
			return null;
		}

		if (!enhanced && !investmentComposition) {
			return null;
		}

		const aggregatedExposure = aggregateExposureData(enhanced ? enhancementComposition! : investmentComposition!);

		return Object.values(aggregatedExposure);
	}, [query.data, enhanced]);

	const tableData = useMemo(
		() =>
			chartData &&
			chartData.map((row) => ({
				...row,
				drillDown: undefined,
				rows: row.drillDown.map((x) => ({ ...x, groupName: row.groupName })),
			})),
		[chartData],
	);

	const { formatNumber } = useLocaleFormatters();

	const columns = useCallback<TableWithGroupsProps<NonNullable<typeof tableData>[number]>["columns"]>(
		({ bandColumn, expandColumn }) => [
			bandColumn,
			expandColumn,
			{
				header: (props.exposureCategories.fromRiskModel.find((cat) => cat.value === exposureCategory.secondLevel) ??
					props.exposureCategories.fromTagClassifications.find((cat) => cat.value === exposureCategory.secondLevel))!
					.columnTitle,
				groupContentTextType: "Body/M/Bold",
				groupContent: (groupedRow) => groupedRow.label,
				content: (row, cellProps) => (
					<Row {...cellProps} alignItems="center">
						<span className="line-clamp-2">
							{row.label}{" "}
							{!row.netLong && (
								<>
									-{" "}
									<span className="whitespace-nowrap">
										[{" "}
										<svg
											className="inline w-[8px] mb-1"
											width="8"
											height="8"
											viewBox="0 0 8 8"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<rect opacity="0.3" width="8" height="8" rx="4" fill={stableColorGenerator(row.groupName)} />
										</svg>{" "}
										<span className="truncate">Net Short</span> ]
									</span>
								</>
							)}
						</span>
					</Row>
				),
				sortFn: builtInSortFnFor("label"),
				name: "label",
			},
			{
				width: 120,
				header: "Weight",
				groupContentTextType: "Body/M/Bold" as const,
				groupCellClassList: "tabular-nums",
				groupContent: (groupedRow) => `${formatNumber(groupedRow.weight, 2)}%`,
				cellClassList: "tabular-nums",
				content: (row) => `${formatNumber(row.weight, 2)}%`,
				name: "weight",
				sortFn: builtInSortFnFor("weight"),
				align: "end",
			},
		],
		[
			exposureCategory,
			formatNumber,
			props.exposureCategories.fromRiskModel,
			props.exposureCategories.fromTagClassifications,
		],
	);
	return (
		<div className="flex flex-col h-full w-full">
			<ExposureCategorySelector
				exposureCategory={exposureCategory}
				setExposureCategory={setExposureCategory}
				exposureCategories={exposureCategories}
			/>
			{query.data != null || query.isLoading ? (
				query.data &&
				((enhanced && !query.data.enhancementComposition) || (!enhanced && !query.data.investmentComposition)) ? (
					<div className="w-full grow min-h-0 pt-2">
						<IconWalls.CalculatingData />
					</div>
				) : (
					<div className="flex grow min-h-0">
						<div className="min-w-0 w-1/2 h-full">
							<div className={toClassName({ "opacity-60": query.isFetching })}>
								{chartData && <ExposureChart animated data={chartData} />}
							</div>
						</div>
						<div
							className={overrideClassName("w-1/2 h-full flex flex-col", {
								"opacity-60": query.isFetching,
							})}
						>
							{tableData && (
								<AutoSortTableWithGroups
									groupedRows={tableData}
									headerRowClassList="sticky top-0 z-10 bg-white"
									groupRowKey={(x) => x.groupName}
									{...groupedColorProps("groupName", "groupName")}
									columns={columns}
								/>
							)}
						</div>
					</div>
				)
			) : (
				<div className="w-full grow min-h-0 pt-2">
					<IconWallBase>
						<div className="flex-1 min-h-0 flex flex-col">
							<MonitorWithHourglass classList={{ "mx-auto my-0": true }} />
							<Text as="div" classList="px-1 text-center" type="Body/M/Book">
								Data not available for the selected comparison, please pick another option.
							</Text>
						</div>
					</IconWallBase>
				</div>
			)}
		</div>
	);
};

export default withContext(PortfolioContext)(ExposureEvolve);
