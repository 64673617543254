import type {
	InvestmentListEntry,
	InvestmentMinInfo,
	InvestmentReferenceListEntry,
	InvestmentStatuses,
	MarketViewListEntry,
	ReferenceUniverseListEntry,
	RichAcl,
} from "$root/api/api-gen";
import {
	BenchmarksControllerApiFactory,
	InvestmentControllerV4ApiFactory,
	MarketViewControllerApiFactory,
	ReferenceUniversesControllerApiFactory,
} from "$root/api/api-gen";
import { getApiGen } from "$root/api/factory";
import { platformToast } from "$root/notification-system/toast";
import { parallelize } from "$root/utils";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";
import { match } from "ts-pattern";
import type { PortfolioStudioTab } from "../portfolio-studio-tabs";

type MultipleEntityDelete =
	| {
			area: "Portfolios";
			items: Array<InvestmentListEntry>;
	  }
	| {
			area: "References";
			items: Array<UnifiedMockedReferenceProps>;
	  }
	| {
			area: "Universes";
			items: Array<ReferenceUniverseListEntry>;
	  }
	| {
			area: "MarketViews";
			items: Array<MarketViewListEntry>;
	  };

const getDeleteApiByArea = () => ({
	Portfolios: getApiGen(InvestmentControllerV4ApiFactory).deleteInvestment,
	Universes: getApiGen(ReferenceUniversesControllerApiFactory).deleteUniverse,
	MarketViews: getApiGen(MarketViewControllerApiFactory).deleteMarketView,
	References: {
		References: getApiGen(InvestmentControllerV4ApiFactory).deleteInvestment,
		Benchmark: getApiGen(BenchmarksControllerApiFactory).deleteBenchmark,
	},
});

function errorToast(message: string) {
	platformToast({
		children: message,
		severity: "error",
		icon: "Portfolio",
	});
}

type ResponseStack = { name?: string };

export function notifyUser(params: {
	succeded: Array<ResponseStack>;
	area: PortfolioStudioTab;
	t: (...params: any) => string;
}): void {
	if (params.succeded.length > 1) {
		const translationKey = match(params)
			.with({ area: "Portfolios" }, () => "PUSH_NOTIFICATION.MULTI_PORTFOLIO_DELETED_TITLE_SUCCESS")
			.with({ area: "References" }, () => "PUSH_NOTIFICATION.MULTI_REFERENCES_DELETED_TITLE_SUCCESS")
			.with({ area: "Universes" }, () => "PUSH_NOTIFICATION.MULTI_UNIVERSE_DELETED_TITLE_SUCCESS")
			.with({ area: "MarketViews" }, () => "PUSH_NOTIFICATION.MULTI_MARKET_DELETED_TITLE_SUCCESS")
			.exhaustive();

		platformToast({
			children: params.t(translationKey, { count: params.succeded.length }),
			severity: "success",
			icon: params.area === "MarketViews" ? "News-category-Market-view-aligment" : "Portfolio",
		});
	}

	if (params.succeded.length === 1) {
		const deletedItem = params.succeded[0];

		const feedback = match(params)
			.with({ area: "Portfolios" }, () => `Successfully deleted your portfolio “${deletedItem.name ?? "-"}”`)
			.with({ area: "References" }, () => `Successfully deleted your reference “${deletedItem.name ?? "-"}”`)
			.with({ area: "Universes" }, () => `Successfully deleted your universe “${deletedItem.name ?? "-"}”`)
			.with({ area: "MarketViews" }, () => `Successfully deleted your market view “${deletedItem.name ?? "-"}”`)
			.exhaustive();

		platformToast({
			children: feedback,
			severity: "success",
			icon: params.area === "MarketViews" ? "News-category-Market-view-aligment" : "Portfolio",
		});
	}
}

export async function multipleEntityDelete(
	params: MultipleEntityDelete,
	showToastForEachFailed = true,
): Promise<{
	succeded: ResponseStack[];
	failed: ResponseStack[];
}> {
	const succeded: Array<ResponseStack> = [];
	const failed: Array<ResponseStack> = [];
	const deleteApiByArea = getDeleteApiByArea();
	const stack = match(params)
		.with({ area: "Portfolios" }, ({ items, area }) => {
			return items.map(
				({ uuid, name }) =>
					() =>
						deleteApiByArea[area](uuid!)
							.then(() => succeded.push({ name }))
							.catch(() => {
								if (showToastForEachFailed) {
									errorToast(`Failed to delete your portfolio “${name ?? "-"}”`);
								}
								failed.push({ name });
							}),
			);
		})
		.with({ area: "References" }, ({ area, items }) => {
			return items.map(
				({ name, identifier, type }) =>
					() =>
						deleteApiByArea[area][type](identifier!)
							.then(() => succeded.push({ name }))
							.catch(() => {
								if (showToastForEachFailed) {
									errorToast(
										type === "Benchmark"
											? `Failed to delete your custom benchmark “${name ?? "-"}”`
											: `Failed to delete your portfolio target “${name ?? "-"}”`,
									);
								}
								failed.push({ name });
							}),
			);
		})
		.with({ area: "MarketViews" }, ({ area, items }) => {
			return items.map(
				({ uuid, name }) =>
					() =>
						deleteApiByArea[area](uuid!)
							.then(() => succeded.push({ name }))
							.catch(() => {
								if (showToastForEachFailed) {
									errorToast(`Failed to delete your market view “${name ?? "-"}”`);
								}
								failed.push({ name });
							}),
			);
		})
		.with({ area: "Universes" }, ({ area, items }) => {
			return items.map(
				({ uuid, name }) =>
					() =>
						deleteApiByArea[area](uuid!)
							.then(() => succeded.push({ name }))
							.catch(() => {
								if (showToastForEachFailed) {
									errorToast(`Failed to delete your universe “${name ?? "-"}”`);
								}
								failed.push({ name });
							}),
			);
		})
		.exhaustive();

	await parallelize(stack, { concurrency: 32 });
	return { succeded, failed };
}

export type UnifiedMockedReferenceProps = {
	name?: string;
	identifier?: string;
	creationTime?: string;
	modificationTime?: string;
	status?: InvestmentStatuses;
	type: "References" | "Benchmark";
	linkedPortfolio?: InvestmentMinInfo[];
	richAcl?: RichAcl;
	nofPortfolios?: number;
};

export function unifyReferenceAndBenchmarksData(
	benchmarks?: InvestmentReferenceListEntry[],
	references?: InvestmentReferenceListEntry[],
): Array<UnifiedMockedReferenceProps> {
	const mapReferences = (references ?? []).map(
		(r): UnifiedMockedReferenceProps => ({
			name: r.name,
			identifier: r.uuid,
			creationTime: r.creationTime,
			modificationTime: r.modificationTime,
			status: r.status,
			type: "References",
			linkedPortfolio: r.referralInvestments,
			richAcl: r.richAcl,
			nofPortfolios: r.nofPortfolios,
		}),
	);

	const mapBenchmarks = (benchmarks ?? []).map(
		(b): UnifiedMockedReferenceProps => ({
			name: b.name,
			identifier: b.uuid,
			creationTime: b.creationTime,
			modificationTime: b.modificationTime,
			status: b.status,
			type: "Benchmark",
			linkedPortfolio: b.referralInvestments,
			richAcl: b.richAcl,
			nofPortfolios: b.nofPortfolios,
		}),
	);

	return [...mapReferences, ...mapBenchmarks].sort(builtInSortFnFor("modificationTime"));
}
