import type { EditorCompositionResponse, ReviewTicker, UserInstrumentDto } from "$root/api/api-gen";
import {
	EntityEditorControllerApiFactory,
	InvestmentEnhancementControllerV4ApiFactory,
	PortfolioStudioPreferencesApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { usePlatformLayoutOptionsState } from "$root/components/PlatformRouter/layout-options";
import ReactQueryWrapper from "$root/components/ReactQueryWrapper";
import ReviewCompositionErrors from "$root/components/ReviewCompositionErrors";
import { aclByArea } from "$root/functional-areas/acl/checkers/all";
import { SmallInvestmentSummary } from "$root/functional-areas/portfolio/SmallInvestmentSummary";
import { useUserValue } from "$root/functional-areas/user";
import { platformToast } from "$root/notification-system/toast";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { Card } from "$root/widgets-architecture/layout/Card";
import WidgetsGrid from "$root/widgets-architecture/layout/WidgetsGrid";
import type { TableColumn } from "@mdotm/mdotui/components";
import { ActionText, AutoSortHScrollTable, Banner, Button, Dialog, DialogFooter, Text } from "@mdotm/mdotui/components";
import type { QueryObserverResult } from "@tanstack/react-query";
import type React from "react";
import type { FC, MutableRefObject } from "react";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import type { Id } from "react-toastify";
import type { IextendedInvestmentEnhancedDetails, IextendedInvestmentsModel, TgridName } from ".";
import { retrieveUserInstrumentsClassifications } from "$root/functional-areas/instruments-editor/builder";
import type { PartialUserInstrument } from "$root/functional-areas/instruments-editor/const";
import {
	hideNameColumnMetadata,
	useInstrumentColumnPreference,
	useInstrumentsColumn,
} from "$root/functional-areas/instruments/hooks";
import type { Map } from "immutable";
import { useCompositionColumns } from "./PortfolioComposition/columns";

type OverviewProps = {
	reviewToastIdRef: MutableRefObject<Id | undefined>;
	portfolio?: IextendedInvestmentsModel | IextendedInvestmentEnhancedDetails;
	portfolioUid: string;
	isEnhanceSelected: boolean;
	gridName: TgridName;
	isModalOpen: boolean;
	setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	requestEnhance(): void;
	refetchPortfolio?(): Promise<QueryObserverResult<IextendedInvestmentsModel | IextendedInvestmentEnhancedDetails>>;
};

interface IportfolioComposition {
	portfolio: IextendedInvestmentsModel | IextendedInvestmentEnhancedDetails | undefined;
	reviewToastIdRef: MutableRefObject<Id | undefined>;
	gridName: TgridName;
	portfolioUid: string;
	isEnhanceSelected: boolean;
	refetchPortfolio?(): Promise<QueryObserverResult<IextendedInvestmentsModel | IextendedInvestmentEnhancedDetails>>;
}

const PortfolioComposition2: FC<IportfolioComposition> = ({ portfolio, gridName }) => {
	const { isUpload, isOptimize, reference, richAcl } = portfolio ?? {};

	const editorApi = useApiGen(EntityEditorControllerApiFactory);
	const user = useUserValue();
	const { push } = useTypedNavigation();

	const canEditComposition = aclByArea.portfolio.canEditComposition(user.id, richAcl?.acl ?? []);

	if (isOptimize && canEditComposition) {
		return (
			<div className="flex flex-col justify-center items-center">
				<h2>Page no longer supported if you want to edit composition go to</h2>
				<ActionText onClick={() => push("Portfolios/ManualEditPortfolio", { uuid: portfolio!.uuid! })}>
					Approve proposal
				</ActionText>
			</div>
		);
	}

	if (isUpload) {
		return (
			<ReactQueryWrapper
				queryFn={async () => {
					const editorResponse = await axiosExtract(
						editorApi.getEditorReviewComposition(portfolio?.uuid ?? "", reference ? "TARGET_INVESTMENT" : "INVESTMENT"),
					);
					const nonNullableComposition = editorResponse.composition ?? [];
					const { userInstrumentsMap } = await retrieveUserInstrumentsClassifications(nonNullableComposition);
					return { userInstrumentsMap, editorResponse, composition: nonNullableComposition };
				}}
				queryKey={["portfolioComposition", portfolio?.uuid, portfolio?.status]}
			>
				{(reviewResponse) => <PortfolioReviewBlock portfolio={portfolio} reviewResponse={reviewResponse} />}
			</ReactQueryWrapper>
		);
	}

	return <WidgetsGrid gridName={gridName} />;
};

const PortfolioReviewBlock = ({
	portfolio,
	reviewResponse,
}: {
	portfolio: IextendedInvestmentsModel | IextendedInvestmentEnhancedDetails | undefined;
	reviewResponse: {
		editorResponse: EditorCompositionResponse;
		userInstrumentsMap: Map<string, PartialUserInstrument>;
		composition: ReviewTicker[];
	};
}) => {
	const { status } = portfolio ?? {};
	const { t } = useTranslation();

	const { platformLayoutOptions, setPlatformLayoutOptions } = usePlatformLayoutOptionsState();
	const platformLayoutOptionsRef = useRef({ platformLayoutOptions, setPlatformLayoutOptions });

	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);
	const { changeTableLayout, columnsMetadata } = useInstrumentColumnPreference({
		async applyColumnPreferenceApi(userInstrumentsColumnPreferences) {
			await portfolioStudioPreferencesApi.setUserInstrumentsColumnPreferences({
				userInstrumentsColumnPreferences,
			});
		},
		mapColumnMetadataFn: hideNameColumnMetadata,
	});

	const { customizableColumns, tools, name } = useInstrumentsColumn({
		allColumns: columnsMetadata,
		mode: "VIEW",
		changeTableLayout,
	});
	const compositionColumns = useCompositionColumns();

	useEffect(() => {
		const scopePlatformLayoutOptions = platformLayoutOptionsRef.current;
		scopePlatformLayoutOptions.setPlatformLayoutOptions({
			...scopePlatformLayoutOptions.platformLayoutOptions,
			maxPageHeight: "screen",
		});

		return () => {
			scopePlatformLayoutOptions.setPlatformLayoutOptions({
				...scopePlatformLayoutOptions.platformLayoutOptions,
				maxPageHeight: "auto",
			});
		};
	}, []);

	const rowsWithUserInstrumentMetadata = useMemo(
		() =>
			reviewResponse.composition.map((instrument): UserInstrumentDto & Omit<ReviewTicker, "description"> => {
				const { description: _description, type: _type, ...instrumentParams } = instrument;
				const userInstrument = reviewResponse.userInstrumentsMap.get(instrument.ticker!) ?? {};
				return {
					...instrumentParams,
					...userInstrument,
					name: userInstrument.name ?? instrument.instrument,
					alias: userInstrument.alias ?? instrumentParams.identifier,
				};
			}),
		[reviewResponse],
	);
	const columns = useMemo<TableColumn<UserInstrumentDto & Omit<ReviewTicker, "description">>[]>(
		() => [name, ...customizableColumns, compositionColumns.WEIGHT(), tools],
		[compositionColumns, customizableColumns, name, tools],
	);
	return (
		<Card>
			<Text type="Body/XL/Bold" classList="mb-4">
				Composition
			</Text>
			<ReviewCompositionErrors errors={reviewResponse?.editorResponse.uploadErrors} />
			<div className="mb-4" hidden={status === "RETRIEVING_DATA"}>
				<Banner severity="warning" title="Review Before Saving">
					Some instruments require your attention. This may be due to missing information or because they have been
					delisted. Please review the list below and make any necessary updates before saving.
				</Banner>
			</div>
			<div className="mb-4">
				<Banner severity="info">{t("PORTFOLIO_UPLOAD_ALERTS.CALCULATING_UPLOAD")}</Banner>
			</div>
			<AutoSortHScrollTable columns={columns} rows={rowsWithUserInstrumentMetadata} />
		</Card>
	);
};

const Overview: FC<OverviewProps> = ({
	portfolio,
	portfolioUid,
	isEnhanceSelected,
	gridName,
	isModalOpen,
	reviewToastIdRef,
	setIsModalOpen,
	refetchPortfolio,
}) => {
	const { t } = useTranslation();
	const investmentsEnhanceApi = useApiGen(InvestmentEnhancementControllerV4ApiFactory);

	const { push } = useTypedNavigation();
	const confirmOptimization = useCallback(() => {
		if (!portfolio || !portfolio.uuid) {
			console.warn("missing portfolioData, confirm ignored.");
			return;
		}

		investmentsEnhanceApi
			.fixVar(portfolio.uuid)
			.then(() => {
				platformToast({
					children: t("PORTFOLIOS.OPTIMIZE_ACCEPTED"),
					severity: "success",
					icon: "Portfolio",
				});
				setIsModalOpen(false);
				push("PortfoliosStudio", {});
			})
			.catch(console.warn);
	}, [portfolio, investmentsEnhanceApi, t, setIsModalOpen, push]);

	return (
		<>
			<Dialog
				classList="flex-1"
				show={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				header={t("PORTFOLIOS.PORTFOLIO_OPTIMIZATION")}
				footer={({ loading }) => (
					<DialogFooter
						primaryAction={
							<Button palette="primary" size="small" onClick={() => confirmOptimization()}>
								{t("PROCEED")}
							</Button>
						}
						neutralAction={
							<Button palette="tertiary" size="small" disabled={loading} onClick={() => setIsModalOpen(false)}>
								{t("LEAVE")}
							</Button>
						}
					/>
				)}
			>
				{t("PORTFOLIOS.ALICE_SUGGEST_ENHANCHED")}
			</Dialog>
			<div className="mb-5">
				<SmallInvestmentSummary enhanced={isEnhanceSelected} portfolio={portfolio} />
			</div>
			<PortfolioComposition2
				portfolio={portfolio}
				portfolioUid={portfolioUid}
				gridName={gridName}
				isEnhanceSelected={isEnhanceSelected}
				refetchPortfolio={refetchPortfolio}
				reviewToastIdRef={reviewToastIdRef}
			/>
		</>
	);
};

export default Overview;
