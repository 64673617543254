import { useTranslation } from "react-i18next";
import SPHERELogo from "$root/assets/images/SPHERE_logo.svg";
import type { ReactNode } from "react";
import type { ErrorBoundaryFallbackProps } from "@mdotm/mdotui/components";
import { ErrorBoundary } from "@mdotm/mdotui/components";
import { reportPlatformError } from "$root/api/error-reporting";
import env from "$root/env";

function GlobalErrorBoundaryFallback({ error }: ErrorBoundaryFallbackProps): JSX.Element {
	const { t } = useTranslation();
	return (
		<div className="flex flex-col space-y-2 items-center h-screen pt-40 bg-gray-100">
			<div>
				<img src={SPHERELogo} alt="Sphere logo" />
			</div>
			<h1 className="font-bold text-4xl">{t("SOMETHING_WENT_WRONG")}</h1>
			<div>
				<a href="/">
					<h2 className="font-bold underline text-2xl">{t("RETURN_TO_HOME")}</h2>
				</a>
			</div>
			{env.appEnv === "development" && (
				<div className="text-left">
					<pre>{String((error as Error | undefined)?.stack ?? error)}</pre>
				</div>
			)}
		</div>
	);
}

function GlobalErrorBoundary({ children }: { children: ReactNode }): JSX.Element {
	return (
		<ErrorBoundary
			onError={(err) =>
				reportPlatformError(err, "ERROR", "global", {
					message: `GlobalErrorBoundary. Couldn't render page "${window.location.href}"`,
				})
			}
			errorChildren={(props) => <GlobalErrorBoundaryFallback {...props} />}
		>
			{children}
		</ErrorBoundary>
	);
}

export default GlobalErrorBoundary;
