import type { StylableProps, TransitionStatus } from "@mdotm/mdotui/components";
import { NestedTransition, StackingContext, Transition } from "@mdotm/mdotui/components";
import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import { overrideClassList, renderNodeOrFn, toClassName } from "@mdotm/mdotui/react-extensions";
import { createPortal } from "react-dom";
import type { CSSProperties } from "react";
import { useMemo } from "react";
import { useAppDrawerWidth } from "./AppDrawer";

export type SidebarProps = {
	open: boolean;
	onClose?(): void;
	width: CSSProperties["width"];
	children: NodeOrFn<SidebarChildrenProps>;
	onAnimationStateChange?(state: TransitionStatus): void;
	mode?: "panel" | "modal";
	position?: SidebarPositions;
	onChangePosition?(position: SidebarPositions): void;
} & StylableProps;

export type SidebarChildrenProps = {
	open: boolean;
	onClose?(): void;
	width: CSSProperties["width"];
	position: SidebarPositions;
	setPosition?(newPosition: SidebarPositions): void;
};

export type SidebarPositions = "right" | "left";

export function Sidebar({
	open,
	onClose,
	width,
	children,
	onAnimationStateChange,
	classList,
	style,
	mode = "panel",
	position = "right",
	onChangePosition,
}: SidebarProps): JSX.Element {
	const childrenProps = useMemo<SidebarChildrenProps>(
		() => ({
			open,
			width,
			onClose,
			position,
			setPosition: onChangePosition,
		}),
		[onChangePosition, onClose, open, position, width],
	);

	const leftOffsett = useAppDrawerWidth();
	return (
		<StackingContext.Consumer>
			{({ zIndex }) =>
				createPortal(
					<Transition onChange={onAnimationStateChange} in={open} unmountOnExit={false} duration={300}>
						{/* overlay */}
						{mode === "modal" && (
							<NestedTransition
								classList="fixed inset-0 z-0 bg-black/20 transition-opacity duration-300"
								enterFromClassList="opacity-0"
								enterToClassList="opacity-100"
								unmountOnExit
								style={{
									zIndex,
								}}
							>
								{({ classList: animatedClassList, style: animatedStyle, transitionStatus }) => (
									<>
										<div
											data-transitionstatus={transitionStatus}
											className={toClassName(animatedClassList)}
											style={animatedStyle}
											onClick={() => onClose?.()}
										/>
									</>
								)}
							</NestedTransition>
						)}
						<NestedTransition
							classList={overrideClassList(
								"fixed top-0 bottom-0 max-w-full transition-all ease-elastic duration-300 flex flex-col",
								classList,
							)}
							// enterFromClassList="-translate-y-full scale-x-[2]" // vertical
							// enterToClassList="translate-y-0 scale-x-[1] shadow-xl"
							enterFromClassList={position === "right" ? "translate-x-full" : "-translate-x-full"} // horizontal
							enterToClassList="translate-x-0 shadow-xl" // horizontal
							style={{
								zIndex,
								width,
								top: 0,
								left: position === "left" ? leftOffsett : undefined,
								right: position === "right" ? 0 : undefined,
								...style,
							}}
							exitedClassList="invisible"
						>
							{({ classList: animatedClassList, style: animatedStyle, transitionStatus }) => (
								<div
									data-transitionstatus={transitionStatus}
									className={toClassName(animatedClassList)}
									style={animatedStyle}
								>
									<StackingContext.Provider value={{ zIndex: zIndex + 1 }}>
										{renderNodeOrFn(children, childrenProps)}
									</StackingContext.Provider>
								</div>
							)}
						</NestedTransition>
					</Transition>,
					document.body,
				)
			}
		</StackingContext.Consumer>
	);
}
