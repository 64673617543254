import * as React from "react";
import { withTranslation, WithTranslationProps, WithTranslation, useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { passwordRegex } from "$root/functional-areas/user/password";
import { AuthControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
	Dialog,
	DialogFooter,
	DialogHeader,
	FormField,
	PasswordInput,
	Button,
	SubmitButton,
	Icon,
	DialogProps,
	StackingContext,
} from "@mdotm/mdotui/components";
import { z } from "zod";
import { useUserValue } from "$root/functional-areas/user";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { FormController } from "$root/third-party-integrations/react-hook-form";
import { FormFields } from "$root/ui-lib/form/FormFields";
import { platformToast } from "$root/notification-system/toast";
import { spawn, adaptAnimatedNodeProvider, SpawnResult } from "@mdotm/mdotui/react-extensions";

type ChangePasswordProps = Pick<DialogProps, "onAnimationStateChange" | "onClose" | "show">;

function ChangePassword({ onClose, ...forward }: ChangePasswordProps): JSX.Element {
	const { t } = useTranslation();
	const authApi = useApiGen(AuthControllerApiFactory);
	const [defaultValues] = useState(() => ({ oldPassword: "", newPassword: "", cnfPassword: "" }));

	const form = useForm<typeof defaultValues>({
		mode: "onChange",
		defaultValues,
		resolver: useMemo(
			() =>
				zodResolver(
					z
						.object({
							oldPassword: z.string().min(1, t("changepwd.OLD_PWD_CHECK")),
							newPassword: z.string().min(1, t("REQUIRED_FIELD")).regex(passwordRegex(), t("changepwd.NOT_MEET_REQ")),
							cnfPassword: z.string().min(1, t("REQUIRED_FIELD")),
						})
						.refine(({ newPassword, cnfPassword }) => newPassword === cnfPassword, {
							path: ["cnfPassword"],
							message: t("changepwd.FIELD_NOT_MATCH"),
						}),
				),
			[t],
		),
	});

	const handleClose = React.useCallback(() => {
		form.reset();
		onClose?.();
	}, [form, onClose]);

	const onSubmit = React.useCallback(
		async ({ oldPassword, newPassword }: { oldPassword: string; newPassword: string }) => {
			try {
				await authApi.changePassword({
					password: oldPassword,
					reset_token: newPassword,
				});
				platformToast({
					children: t("changepwd.CHANGE_SUCCESS"),
					severity: "success",
					icon: "Top-menu-user",
				});
				handleClose();
			} catch (err) {
				platformToast({
					children: t("changepwd.RESET_FAILED"),
					severity: "error",
					icon: "Top-menu-user",
				});
			}
		},
		[authApi, handleClose, t],
	);

	const user = useUserValue();

	return (
		<>
			<Dialog
				classList="flex-1"
				{...forward}
				onClose={handleClose}
				onSubmitAsync={() => form.handleSubmit(onSubmit)()}
				header={
					<DialogHeader icon={<Icon icon="Edit" color={themeCSSVars.MessageSeverity_info} />}>
						{t("appbar.CHANGE_PWD")}
					</DialogHeader>
				}
				footer={({ loading }) => (
					<DialogFooter
						primaryAction={
							<SubmitButton palette="primary" disabled={!form.formState.isValid}>
								{t("appbar.CHANGE_PWD")}
							</SubmitButton>
						}
						neutralAction={
							<Button palette="tertiary" disabled={loading} onClick={handleClose}>
								{t("changepwd.CANCEL")}
							</Button>
						}
					/>
				)}
			>
				<input type="text" className="hidden" autoComplete="username" name="username" defaultValue={user.email} />
				<div className="space-y-4">
					<FormFields.Password
						control={form.control}
						formState={form.formState}
						label={t("changepwd.OLD_PWD")}
						autoComplete="current-password"
						name="oldPassword"
						placeholder={t("changepwd.OLD_PWD")}
					/>
					<FormField
						label={t("changepwd.NEW_PWD")}
						error={
							form.formState.errors.newPassword ? (
								<div className="min-w-10">
									8 characters or longer.
									<br />
									At least:
									<br />
									- one lower-case letter,
									<br />
									- one upper-case letter,
									<br />
									- one number,
									<br />- one symbol: !@#$%^&_*
								</div>
							) : null
						}
					>
						{({ id, invalid }) => (
							<FormController
								control={form.control}
								name="newPassword"
								render={({ field: { ref, onBlur, ...others } }) => (
									<PasswordInput
										id={id}
										invalid={invalid}
										placeholder={t("changepwd.NEW_PWD")}
										autoComplete="new-password"
										innerRef={ref}
										onBlur={() => {
											onBlur();
											form.trigger("cnfPassword").catch(console.error);
										}}
										{...others}
									/>
								)}
							/>
						)}
					</FormField>
					<FormFields.Password
						control={form.control}
						formState={form.formState}
						label={t("changepwd.CONFIRM_PWD")}
						autoComplete="new-password"
						name="cnfPassword"
						placeholder={t("changepwd.CONFIRM_PWD")}
					/>
				</div>
			</Dialog>
		</>
	);
}

export default ChangePassword;

export function spawnChangePasswordDialog(params: { zIndex?: number }): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ resolve, show, onHidden }) => (
			<StackingContext.Consumer>
				{({ zIndex }) => (
					<StackingContext.Provider value={{ zIndex: (params.zIndex ?? zIndex) + 1 }}>
						<ChangePassword
							show={show}
							onClose={() => resolve()}
							onAnimationStateChange={(state) => state === "hidden" && onHidden()}
						/>
					</StackingContext.Provider>
				)}
			</StackingContext.Consumer>
		)),
	);
}
