import type { ReviewTicker } from "$root/api/api-gen";
import BigNumber from "bignumber.js";
import { Map, Set } from "immutable";
import type { ProxiedInstrumentData } from "./EditProxiedInstrumentTable";

export const ReviewEntity = {
	PORTFOLIO: "portfolio",
	TARGET_PORTFOLIO: "target_portfolio",
	BENCHMARK: "benchmark",
	UNIVERSE: "universe",
} as const;

export type ReviewEntity = (typeof ReviewEntity)[keyof typeof ReviewEntity];

export type EditProxiedBuilderProps = {
	proxied: Map<string, ProxiedInstrumentData>;
	deleted: Set<string>;
	scored: Map<string, number | null>;
	tagged: Map<string, string>;
};

export const initialProxyBuilder = {
	deleted: Set(),
	proxied: Map(),
	scored: Map(),
	tagged: Map(),
} satisfies EditProxiedBuilderProps;

function instrumentDataValidation(instrument: ReviewTicker) {
	const isTickerValid = instrument.ticker;
	const isFirstStepFilled =
		instrument?.instrument && instrument.microAssetClass && instrument.assetClass && instrument.currency;

	const isSecondStepFileld = !!instrument?.proxies?.length;
	if (isFirstStepFilled && isSecondStepFileld && isTickerValid) {
		return instrument?.proxies
			?.reduce((sum, el) => sum.plus(el.weight ? BigNumber(el.weight) : BigNumber(0)), BigNumber(0))
			.eq(100);
	}
	return false;
}

function initProxiedInstrumentsMap(composition: ReviewTicker[]) {
	return composition.flatMap(
		(x): Array<[string, ProxiedInstrumentData]> =>
			x.needsCustomProxy && Boolean(x.delisted) === false && instrumentDataValidation(x)
				? [
						[
							x.ticker!,
							{
								// assetClass: x.assetClass!,
								// currency: x.currency!,
								// instrument: x.instrument!,
								// microAssetClass: x.microAssetClass!,
								proxies: x.proxies!,
							} satisfies ProxiedInstrumentData,
						],
				  ]
				: [],
	);
}

function initDeletedInstrumentSet(composition: ReviewTicker[]) {
	return composition.flatMap((instrument) => (instrument.delisted && instrument.ticker ? [instrument.ticker] : []));
}

function initScoredInstrumentSet(composition: ReviewTicker[]): Array<[string, number]> {
	return composition.filter((x) => x.ticker && x.score !== undefined).map((x) => [x.ticker!, x.score!]);
}

function initTagInstrumentMap(composition: ReviewTicker[]): Array<[string, string]> {
	return composition.filter((x) => x.ticker && x.tagLabel).map((x) => [x.ticker!, x.tagLabel!]);
}

export function someInstrumentsNeedProxy(
	composition: ReviewTicker[],
	{ deleted, proxied }: EditProxiedBuilderProps,
): boolean {
	return composition
		.filter((x) => !deleted.has(x.ticker ?? "-"))
		.filter((x) => Boolean(x.delisted) === false)
		.some((x) => x.needsCustomProxy && !proxied.has(x.ticker ?? "-"));
}

export function initInstrumentsProxyEditor(composition: ReviewTicker[]): EditProxiedBuilderProps {
	const initProxiedInstruments = initProxiedInstrumentsMap(composition);
	const initDeletedInstruments = initDeletedInstrumentSet(composition);
	const initScoredInstruments = initScoredInstrumentSet(composition);
	const initTaggedInstruments = initTagInstrumentMap(composition);

	return {
		proxied: Map<string, ProxiedInstrumentData>(initProxiedInstruments),
		deleted: Set<string>(initDeletedInstruments),
		scored: Map<string, number | null>(initScoredInstruments),
		tagged: Map<string, string>(initTaggedInstruments),
	};
}

export function generateCompositionToSubmit(
	composition: ReviewTicker[],
	{ deleted, proxied, scored, tagged }: EditProxiedBuilderProps,
): ReviewTicker[] {
	return composition
		.filter((x) => !deleted.has(x.ticker ?? "-"))
		.filter((x) => Boolean(x.delisted) === false)
		.map((x) => ({
			...x,
			...(proxied.get(x.ticker ?? "-") ?? {}),
			tagLabel: tagged.get(x.ticker ?? "-") ?? undefined,
			score: scored.get(x.ticker ?? "-") ?? undefined,
		}));
}
