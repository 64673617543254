import { type TagType, type UserInstrumentClassificationDto } from "$root/api/api-gen";
import type { MinimumDialogProps } from "$root/components/spawnable/type";
import { objectTextSearchMatchFns, qualifier, useQueryNoRefetch } from "$root/utils";
import type { Option, OrderBy, TableColumn } from "@mdotm/mdotui/components";
import {
	BaseTable,
	Button,
	CircularProgressBar,
	Controller,
	DebouncedSearchInput,
	Dialog,
	DialogFooter,
	LocalOverlay,
	Select,
	sortRows,
	SubmitButton,
	useSelectableTableColumn,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { useSearchable } from "@mdotm/mdotui/headless";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, spawn } from "@mdotm/mdotui/react-extensions";
import { builtInSort } from "@mdotm/mdotui/utils";
import { Map } from "immutable";
import { useEffect, useMemo, useState } from "react";
import { flushSync } from "react-dom";
import { useTranslation } from "react-i18next";
import { userClassificationId } from "./spawn-asset-allocation";

export type ClassificationSubmitProps = {
	selectedOptions: Array<string>;
	classification: UserInstrumentClassificationDto;
};

type ClassificationDialogProps = {
	selectableUserClassifications: UserInstrumentClassificationDto[];
	tagType: TagType;
	mode?: "checkbox" | "radio";
	onSubmit(payload: ClassificationSubmitProps): void;
	optionsProvider(classificationUuid: string): MaybePromise<Array<string>>;
	disabledRowIds?: string[];
} & MinimumDialogProps;

function ClassificationDialog({
	selectableUserClassifications,
	show,
	tagType,
	mode,
	optionsProvider,
	onAnimationStateChange,
	onClose,
	onSubmit,
	disabledRowIds,
}: ClassificationDialogProps) {
	const { t } = useTranslation();
	const selectableOptionsByTagType = useMemo(
		() =>
			selectableUserClassifications.filter(
				(classification) => classification.tagType === tagType && classification.fieldType === "TAG",
			),
		[selectableUserClassifications, tagType],
	);

	const [classification, setClassification] = useState<ClassificationSubmitProps>({
		selectedOptions: [],
		classification: selectableOptionsByTagType.at(0) ?? {},
	});

	return (
		<Dialog
			size="xxlarge"
			show={show}
			onAnimationStateChange={onAnimationStateChange}
			onClose={onClose}
			header="Select"
			onSubmitAsync={() => onSubmit(classification)}
			footer={
				<DialogFooter
					primaryAction={
						<SubmitButton palette="primary" data-qualifier={qualifier.component.button("Dialog/Submit")}>
							{t("BUTTON.DONE")}
						</SubmitButton>
					}
					neutralAction={
						<Button palette="tertiary" onClick={onClose} data-qualifier={qualifier.component.button("Dialog/Cancel")}>
							{t("BUTTON.CANCEL")}
						</Button>
					}
				/>
			}
		>
			<div className="max-h-[400px] h-[400px] min-h-0 overflow-hidden flex flex-col">
				<ClassificationOptionsTable
					selectedClassificationUuid={classification.classification.classificationUuid!}
					onChangeClassification={(newClassification) => {
						if (newClassification) {
							setClassification(() => ({
								classification: { ...newClassification },
								selectedOptions: [],
							}));
						}
					}}
					disabledRowIds={disabledRowIds}
					optionsProvider={optionsProvider}
					onToggleOption={(classificationOption) => {
						if (!classificationOption) {
							return;
						}

						if (mode === "radio") {
							setClassification((prev) => ({ ...prev, selectedOptions: [classificationOption] }));
							return;
						}
						//mode checkbox
						setClassification((prev) => {
							const shallowSelectedOptions = [...prev.selectedOptions];
							const classificationOptionIndex = shallowSelectedOptions.findIndex(
								(option) => option === classificationOption,
							);

							if (classificationOptionIndex > -1) {
								shallowSelectedOptions.splice(classificationOptionIndex, 1);
								return { ...prev, selectedOptions: shallowSelectedOptions };
							}

							shallowSelectedOptions.push(classificationOption);
							return { ...prev, selectedOptions: shallowSelectedOptions };
						});
					}}
					selectableUserClassifications={selectableOptionsByTagType}
					mode={mode}
				/>
			</div>
		</Dialog>
	);
}

type ClassificationOptionsTableProps = {
	selectedClassificationUuid: string;
	selectableUserClassifications: UserInstrumentClassificationDto[];
	onChangeClassification(userClassification?: UserInstrumentClassificationDto): void;
	onToggleOption?(option: string): void;
	optionsProvider(classificationUuid: string): MaybePromise<Array<string>>;
	mode?: "checkbox" | "radio";
	disabledRowIds?: string[];
};

export function ClassificationOptionsTable({
	selectedClassificationUuid,
	selectableUserClassifications,
	disabledRowIds,
	onChangeClassification,
	optionsProvider,
	onToggleOption,
	mode = "checkbox",
}: ClassificationOptionsTableProps): JSX.Element {
	const [search, setSearch] = useState("");

	const availableClassificationByTagType = useMemo(() => {
		return selectableUserClassifications.filter((x) => x.options?.length && x.options.length > 0);
	}, [selectableUserClassifications]);

	const availableClassificationByTagTypeMap = Map<string, UserInstrumentClassificationDto>(
		selectableUserClassifications.flatMap((x) => (x.classificationUuid ? [[x.classificationUuid, x]] : [])),
	);

	const selectableClassificationOptions = useMemo(
		() =>
			availableClassificationByTagType.map(
				(x): Option<string> => ({
					label: x.name ?? "untitled",
					value: x.classificationUuid!,
				}),
			),
		[availableClassificationByTagType],
	);

	const queryClassificationOptions = useQueryNoRefetch(["queryClassificationOptions", selectedClassificationUuid], {
		enabled: selectedClassificationUuid != null,
		queryFn() {
			return optionsProvider(selectedClassificationUuid!);
		},
	});

	const { data: availableOptions, isFetching: isClassificationOptionsFetching } = queryClassificationOptions;

	const rows = useMemo(() => availableOptions ?? [], [availableOptions]);
	const rowsIds = useMemo(
		() => rows.map((row) => userClassificationId(selectedClassificationUuid, row)),
		[rows, selectedClassificationUuid],
	);

	const { filtered } = useSearchable({
		collection: rows,
		query: search,
		matchFn: (opt, q) => objectTextSearchMatchFns.substring({ name: opt }, q),
	});

	const checkboxColumnData = useSelectableTableColumn({
		mode,
		rows,
		selectBy: (opt) => userClassificationId(selectedClassificationUuid, opt),
		filteredRows: filtered,
		selectableRowIds: disabledRowIds && rowsIds.filter((rowId) => !disabledRowIds.includes(rowId)),
	});

	const columns = useMemo<TableColumn<string>[]>(
		() => [
			checkboxColumnData.column,
			{
				header: "Name",
				content: (option) => option, //todo change with tag view
				name: "option",
				sortFn: builtInSort,
			},
		],
		[checkboxColumnData.column],
	);

	//todo: remove asap
	useEffect(() => {
		flushSync(() => {
			const firstSelection = checkboxColumnData.multiSelectCtx.selection.first();
			console.log(firstSelection, rows);
			const filteredRow = rows.find((x) => {
				return firstSelection === userClassificationId(selectedClassificationUuid, x);
			});

			if (filteredRow) {
				onToggleOption?.(filteredRow);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkboxColumnData.multiSelectCtx.selection]);

	return (
		<>
			<div className="flex gap-2 mb-4">
				<DebouncedSearchInput
					data-qualifier="ClassificationOptionsTable/Search"
					externalQuery={search}
					onChange={setSearch}
					placeholder="Search..."
					inputAppearance={{ classList: "max-w-[280px]" }}
				/>
				<Select
					data-qualifier="ClassificationOptionsTable/Select/Options"
					triggerDataAttrs={{
						"data-qualifier": "ClassificationOptionsTable/Select/Trigger",
					}}
					options={selectableClassificationOptions}
					value={selectedClassificationUuid}
					onChange={(newClassificationUuid) => {
						if (newClassificationUuid) {
							const selectedClassification = availableClassificationByTagTypeMap.get(newClassificationUuid);
							onChangeClassification(selectedClassification);
						}
					}}
				/>
			</div>
			<div className="relative min-h-0 flex-1 flex flex-col">
				<LocalOverlay
					show={isClassificationOptionsFetching}
					classList="z-10"
					style={{
						background: "transparent",
					}}
				>
					<CircularProgressBar value="indeterminate" />
				</LocalOverlay>
				<Controller value={defaultReportOrderBy}>
					{({ value: orderBy, onChange: onOrderByChange }) => (
						<BaseTable
							columns={columns}
							rows={sortRows({ rows: filtered, columns, orderByArr: orderBy })}
							orderBy={orderBy}
							onOrderByChange={onOrderByChange}
							onRowClick={(option) => {
								if (
									!checkboxColumnData.multiSelectCtx.disabled(userClassificationId(selectedClassificationUuid, option))
								) {
									checkboxColumnData.toggle(userClassificationId(selectedClassificationUuid, option));
									// onToggleOption?.(option);
								}
							}}
							classList="max-h-[462px] min-h-[320px]"
							noDataText="No tags available"
						/>
					)}
				</Controller>
			</div>
		</>
	);
}

const defaultReportOrderBy: Array<OrderBy<"option">> = [{ columnName: "option", direction: "asc" }];

type SpawnClassificationDialogProps = Omit<ClassificationDialogProps, "show" | "onClose">;
export function spawnClassificationDialog(props: SpawnClassificationDialogProps): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ show, resolve, onHidden }) => (
			<ClassificationDialog
				{...props}
				show={show}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
				onClose={() => resolve()}
				onSubmit={(payload) => {
					props.onSubmit(payload);
					resolve();
				}}
			/>
		)),
	);
}
