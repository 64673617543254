import { createPersistentAtom } from "$root/third-party-integrations/zustand";
import type { Updater } from "@mdotm/mdotui/utils";
import { identity } from "@mdotm/mdotui/utils";
import fastDeepEqual from "fast-deep-equal";

export const InstrumentPool = {
	mdotm: "MDOTM",
	instrumentCustomisation: "INSTRUMENT_CUSTOMISATION",
} as const;

export type InstrumentPool = (typeof InstrumentPool)[keyof typeof InstrumentPool];

export type InstrumentAdderStoreProps = {
	expand: boolean;
	instrumentPool: InstrumentPool;
};

export const instrumentAdderDefault: InstrumentAdderStoreProps = {
	expand: true,
	instrumentPool: InstrumentPool.mdotm,
};

export const useInstrumentAdderStore = createPersistentAtom<InstrumentAdderStoreProps>(
	instrumentAdderDefault,
	"instrument-adder",
);

export function useInstrumentAdderState(): {
	instrumentAdderStore: InstrumentAdderStoreProps;
	setInstrumentAdderStore: (newValue: InstrumentAdderStoreProps | Updater<InstrumentAdderStoreProps>) => void;
	reset(): void;
} {
	const { value: instrumentAdderStore, set: setInstrumentAdderStore } = useInstrumentAdderStore(identity, (a, b) =>
		fastDeepEqual(a.value, b.value),
	);
	return {
		instrumentAdderStore,
		setInstrumentAdderStore,
		reset: () => setInstrumentAdderStore(instrumentAdderDefault),
	};
}
