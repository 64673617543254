import type { InvestmentSummary } from "$root/api/api-gen";
import {
	InvestmentEnhancementExportControllerApiFactory,
	InvestmentEnhancementReportsControllerApiFactory,
	InvestmentExportControllerApiFactory,
	InvestmentReportsControllerApiFactory,
	PortfolioStudioPreferencesApiFactory,
	TickerControllerV1ApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { retrieveUserInstrumentsClassifications } from "$root/functional-areas/instruments-editor/builder";
import type { FrankensteinInstrument } from "$root/functional-areas/instruments-editor/const";
import type { UserColumnMetadata } from "$root/functional-areas/instruments/hooks";
import { hideNameColumnMetadata, useInstrumentColumnPreference } from "$root/functional-areas/instruments/hooks";
import {
	PortfolioCompositionInnerFlat,
	PortfolioCompositionInnerGrouped,
} from "$root/pages/PortfolioDetails/PortfolioComposition";
import {
	PortfolioQueryWidgetBase,
	WidgetStatus,
	portfolioWidgetMissingDataReason,
} from "$root/pages/PortfolioDetails/PortfolioWidgetStatus";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import type { ContextContent } from "$root/utils";
import { downloadContentDisposition, useQueryNoRefetch, withContext } from "$root/utils";
import { PortfolioContext } from "$root/widgets-architecture/contexts/portfolio";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import { DropdownMenu, FormField, Icon, Row, Select } from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Composition = (props: ContextContent<typeof PortfolioContext>) => {
	const { enhanced, portfolio, reportExcutionCounter } = props;
	const uuid = props.portfolio?.uuid;
	const { t } = useTranslation();

	useWidgetOptions(
		() => ({
			title: t("COMPOSITION.TITLE"),
		}),
		[t],
	);

	const investmentReportApi = useApiGen(InvestmentReportsControllerApiFactory);
	const investmentEnhancementReportApi = useApiGen(InvestmentEnhancementReportsControllerApiFactory);
	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);
	const tickerApi = useApiGen(TickerControllerV1ApiFactory);

	const query = useQueryNoRefetch(["compositionProvider", uuid, enhanced, portfolio?.status, reportExcutionCounter], {
		queryFn: async () => {
			if (!uuid) {
				return {
					data: undefined,
					widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "Composition"),
				};
			}

			const { composition } = await axiosExtract(
				props.enhanced
					? investmentEnhancementReportApi.getInvestmentComposition1(uuid)
					: investmentReportApi.getInvestmentComposition(uuid),
			);

			// PROPOSAL: we can either remap the response here or ask the BE to give us the
			// appropriate data status (letting us delete portfolioWidgetMissingDataReason)
			if (composition) {
				const { userInstrumentsMap } = await retrieveUserInstrumentsClassifications(composition);

				const frankensteinComposition = composition.map((instrument) => {
					const { description: _description, type: _type, ...instrumentParams } = instrument;
					const userInstrument = userInstrumentsMap.get(instrument.ticker!) ?? {};
					return {
						...instrumentParams,
						...userInstrument,
						name: userInstrument.name ?? instrument.instrument ?? "",
						alias: userInstrument.alias ?? instrument.identifier,
						type: userInstrument.type,
					} satisfies FrankensteinInstrument;
				});

				return {
					data: {
						composition: frankensteinComposition,
					},
					widgetStatus: WidgetStatus.READY,
				};
			}

			return {
				data: undefined,
				widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "Composition"),
			};
		},
	});

	const { changeTableLayout, columnsMetadata } = useInstrumentColumnPreference({
		async applyColumnPreferenceApi(userInstrumentsColumnPreferences) {
			//todo handle enhance columns
			await portfolioStudioPreferencesApi.setUserInstrumentsColumnPreferences({
				userInstrumentsColumnPreferences,
			});
		},
		mapColumnMetadataFn: hideNameColumnMetadata,
	});

	return (
		<PortfolioQueryWidgetBase query={query}>
			{({ composition }) => (
				<CompositionInner
					ctx={props}
					composition={composition}
					onAsyncChangeColumnPreferences={changeTableLayout}
					columnsMetadata={columnsMetadata}
				/>
			)}
		</PortfolioQueryWidgetBase>
	);
};

const CompositionInner = (props: {
	ctx: ContextContent<typeof PortfolioContext>;
	composition: FrankensteinInstrument[];
	columnsMetadata: UserColumnMetadata[];
	onAsyncChangeColumnPreferences(columnPreferences: Array<UserColumnMetadata>): MaybePromise<void>;
}) => {
	const { t } = useTranslation();
	const { composition, ctx, columnsMetadata } = props;
	const { enhanced, portfolio } = ctx;

	const exportApi = useApiGen(InvestmentExportControllerApiFactory);
	const exportEnhancedApi = useApiGen(InvestmentEnhancementExportControllerApiFactory);

	const exportPortfolioComposition = async (downloadTarget: "composition" | "trades") => {
		const response = enhanced
			? downloadTarget === "composition"
				? await exportEnhancedApi.exportEnhancedComposition(portfolio!.uuid!, "FULL_COMPOSITION", {
						responseType: "blob",
				  })
				: await exportEnhancedApi.exportEnhancedComposition(portfolio!.uuid!, "TRADES_ONLY", { responseType: "blob" })
			: await exportApi.exportComposition(portfolio!.uuid!, { responseType: "blob" });

		downloadContentDisposition(response);
		trackMixPanelEvent("Portfolio", {
			Type: "Export",
			Area: downloadTarget,
			ID: portfolio!.uuid!,
		});
	};

	const exportPortfolioCompositionRef = useUnsafeUpdatedRef(exportPortfolioComposition);
	const [groupByClassificationUuid, setGroupByClassificationUuid] = useState<string | null>(null);

	useWidgetOptions(
		() => ({
			actionHeader: function Download() {
				return (
					<Row alignItems="end" classList="space-x-4">
						<FormField label="Group by">
							{(formFieldProps) => (
								<Select
									{...formFieldProps}
									size="x-small"
									value={groupByClassificationUuid}
									onChange={setGroupByClassificationUuid}
									options={[
										{ label: "Ungrouped", value: null },
										...columnsMetadata.flatMap((x) =>
											x.fieldType === "TAG" ? [{ label: x.name!, value: x.classificationUuid! }] : [],
										),
									]}
								/>
							)}
						</FormField>

						<DropdownMenu
							trigger={({ innerRef, open, ...forward }) => (
								<button ref={innerRef} aria-expanded={open} type="button" {...forward}>
									<Icon icon="Dowload" color={themeCSSVars.MessageSeverity_success} size={20} />
								</button>
							)}
							actions={[
								{
									icon: "xls",
									onClickAsync: async () => {
										await exportPortfolioCompositionRef.current("composition");
									},
									label: portfolio?.reference ? "Target portfolio composition" : "Portfolio composition",
								},
								portfolio?.status === "PROPOSAL_READY" &&
									enhanced && {
										icon: "xls",
										onClickAsync: async () => {
											await exportPortfolioCompositionRef.current("trades");
										},
										label: t("COMPOSITION.DOWNLOAD_TRADES_TITLE"),
									},
							]}
						/>
						{/* <InfoTooltip>{t("COMPOSITION.TOOLTIP")}</InfoTooltip> */}
					</Row>
				);
			},
			title: t("COMPOSITION.TITLE"),
		}),
		[
			columnsMetadata,
			enhanced,
			exportPortfolioCompositionRef,
			groupByClassificationUuid,
			portfolio?.reference,
			portfolio?.status,
			t,
		],
	);

	return (
		<>
			{props.ctx.portfolio && (
				<CompositionTable
					ctx={{ ...ctx, portfolio: props.ctx.portfolio!, reportExcutionCounter: 0 }}
					groupByClassificationUuid={groupByClassificationUuid}
					onAsyncChangeColumnPreferences={props.onAsyncChangeColumnPreferences}
					columnsMetadata={props.columnsMetadata}
					filtered={composition}
				/>
			)}
		</>
	);
};

const CompositionTable = (props: {
	ctx: {
		portfolio: InvestmentSummary;
		enhanced: boolean;
		reportExcutionCounter: number;
	};
	filtered: FrankensteinInstrument[];
	groupByClassificationUuid: string | null;
	columnsMetadata: UserColumnMetadata[];
	onAsyncChangeColumnPreferences(columnPreferences: Array<UserColumnMetadata>): MaybePromise<void>;
}) => {
	return (
		<>
			{props.groupByClassificationUuid ? (
				<PortfolioCompositionInnerGrouped {...props} groupByClassificationUuid={props.groupByClassificationUuid} />
			) : (
				<PortfolioCompositionInnerFlat {...props} />
			)}
		</>
	);
};

export default withContext(PortfolioContext)(Composition);
