import type { PortfolioMetric } from "$root/api/api-gen";
import { useLocaleFormatters } from "$root/localization/hooks";
import type { PrintableProps } from "$root/components/EvolvedPrint/configuration";
import { Card } from "$root/components/EvolvedPrint/components/Card";
import { TinyTableDataCell, TinyTableHeadCell } from "$root/components/EvolvedPrint/components/table/tiny-table";
import { useReportTranslation } from "../translation";
import type { TableColumn } from "@mdotm/mdotui/components";
import { BaseTable } from "@mdotm/mdotui/components";

export function PortfolioMetrics({
	showBenchmark,
	list,
}: PrintableProps<{ showBenchmark: boolean }, PortfolioMetric>): JSX.Element {
	const { t } = useReportTranslation();
	const { formatNumber } = useLocaleFormatters();

	const columns = [
		{
			name: "type",
			width: 330,
			header: (props) => (
				<TinyTableHeadCell {...props}>{t("REPORT_BUILDER.PORTFOLIO_METRICS.TABLE.METRIC")}</TinyTableHeadCell>
			),
			content: ({ type, metadata }, props) => (
				<TinyTableDataCell {...props}>
					{`${t(`REPORT_BUILDER.PORTFOLIO_METRICS.METRICS.${type!}`)}  ${metadata?.classificationName ?? ""}`}
				</TinyTableDataCell>
			),
		},
		{
			name: "current",
			width: 124,
			cellClassList: "tabular-nums",
			header: (props) => (
				<TinyTableHeadCell {...props}>{t("REPORT_BUILDER.PORTFOLIO_METRICS.TABLE.CURRENT")}</TinyTableHeadCell>
			),
			align: "end",
			content: ({ value, type }, props) => {
				const label = t(`REPORT_BUILDER.PORTFOLIO_METRICS.METRICS.${type!}`) ?? "-";
				return (
					<TinyTableDataCell {...props}>{`${formatNumber(value)}
						${
							label.toLowerCase().includes("ratio") ||
							label.toLowerCase().includes("sortino") ||
							label.toLowerCase().includes("avg")
								? ""
								: "%"
						}
					`}</TinyTableDataCell>
				);
			},
		},
		{
			name: "benchmark",
			width: 124,
			cellClassList: "tabular-nums",
			header: (props) => (
				<TinyTableHeadCell {...props}>{t("REPORT_BUILDER.PORTFOLIO_METRICS.TABLE.BENCHMARK")}</TinyTableHeadCell>
			),
			align: "end",
			content: ({ benchmarkValue, type }, props) => {
				const label = t(`REPORT_BUILDER.PORTFOLIO_METRICS.METRICS.${type!}`) ?? "-";
				return (
					<TinyTableDataCell {...props}>{`${formatNumber(benchmarkValue)}
						${
							label.toLowerCase().includes("ratio") ||
							label.toLowerCase().includes("sortino") ||
							label.toLowerCase().includes("avg")
								? ""
								: "%"
						}
					`}</TinyTableDataCell>
				);
			},
			hidden: !showBenchmark,
		},
		{
			name: "empty",
			minWidth: 0,
			header: (props) => <TinyTableHeadCell {...props}>{null}</TinyTableHeadCell>,
			content: (_, props) => <TinyTableDataCell {...props}>{null}</TinyTableDataCell>,
		},
	] satisfies TableColumn<PortfolioMetric>[];

	return (
		<Card title={t("REPORT_BUILDER.PORTFOLIO_METRICS.TITLE")}>
			<BaseTable
				disableVirtualScroll
				palette="uniform"
				columns={columns}
				rows={list}
				noDataText={t("COMPOSITION.NO_COMPOSITION")}
			/>
		</Card>
	);
}
