import { useStoreState } from "$root/third-party-integrations/zustand";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { noop } from "@mdotm/mdotui/utils";
import { useMemo, useState } from "react";
import type { StoreApi } from "zustand";

export type Task<T> = () => MaybePromise<T>;

export type TaskQueue = {
	enqueue<T>(task: Task<T>): Promise<T>;
	state(): TaskQueueState;
};

export type TaskQueueState = {
	isRunning: boolean;
	lastRunAt: {
		timestamp: Date;
		isSuccess: boolean;
	} | null;
};

export function makeTaskQueue(opts?: { onStateChange?(state: TaskQueueState): void }): TaskQueue {
	const queue: Array<{ task: Task<any>; resolve(v: any): void; reject(err?: unknown): void }> = [];

	let state: TaskQueueState = { isRunning: false, lastRunAt: null };
	const setState = (v: TaskQueueState) => {
		state = v;
		opts?.onStateChange?.(v);
	};
	async function run() {
		if (state.isRunning) {
			return;
		}
		setState({ ...state, isRunning: true });
		let isSuccess = false;
		while (queue.length > 0) {
			const cur = queue.shift()!;
			try {
				const result = await cur.task();
				cur.resolve(result);
				isSuccess = true;
			} catch (err) {
				cur.reject(err);
				isSuccess = false;
			}
		}
		setState({
			...state,
			isRunning: false,
			lastRunAt: {
				isSuccess,
				timestamp: new Date(),
			},
		});
	}

	return {
		enqueue(task) {
			return new Promise((resolve, reject) => {
				queue.push({
					task,
					resolve,
					reject,
				});
				run().catch(noop);
			});
		},
		state() {
			return state;
		},
	};
}

/**
 * Create a simple task queue that can run sync and async functions sequentially.
 */
export function useTaskQueue(): { queue: TaskQueue; state: TaskQueueState } {
	const [state, setState] = useState<TaskQueueState>(() => ({ isRunning: false, lastRunAt: null }));
	const queue = useState(() => makeTaskQueue({ onStateChange: setState }))[0];

	return useMemo(() => ({ queue, state }), [queue, state]);
}

/**
 * Same as `useTaskQueue`, but provides a store so that UI updates can be isolated from the rest of the page.
 */
export function useTaskQueueWithStore(): { queue: TaskQueue; state: StoreApi<TaskQueueState> } {
	const [state, setState] = useStoreState<TaskQueueState>({ isRunning: false, lastRunAt: null });
	const queue = useState(() => makeTaskQueue({ onStateChange: setState }))[0];

	return useMemo(() => ({ queue, state }), [queue, state]);
}
