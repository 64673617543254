import type { ConstrainedTicker, RichTicker } from "$root/api/api-gen";
import { useInstrumentColumnsTableV2, useSearchableInstrumentTable } from "$root/functional-areas/instruments/hooks";
import type { DialogProps, TableColumn } from "@mdotm/mdotui/components";
import {
	AutoSortTable,
	Button,
	Dialog,
	DialogFooter,
	TextInput,
	useSelectableTableColumn,
} from "@mdotm/mdotui/components";
import type { MultiSelectCtx } from "@mdotm/mdotui/headless";
import { useDebouncedSearch } from "@mdotm/mdotui/headless";
import { toClassListRecord } from "@mdotm/mdotui/react-extensions";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

export type InstrumentDialogProps = {
	show: boolean;
	selected: Array<ConstrainedTicker>;
	instruments: Array<ConstrainedTicker>;
	onClose(): void;
	onSubmit(instruments: Array<ConstrainedTicker>): void;
	onAnimationStateChange?: DialogProps["onAnimationStateChange"];
	rowClassList?: string;
};

export function InstrumentDialog({
	instruments,
	show,
	selected,
	rowClassList,
	onClose,
	onSubmit,
	onAnimationStateChange,
}: InstrumentDialogProps): JSX.Element {
	const { t } = useTranslation();

	const dialogTitle = "Select one or more instruments";

	const { debouncedNormalizedQuery, query, setQuery } = useDebouncedSearch("", 500);
	const searchable = useSearchableInstrumentTable(instruments.sort(builtInSortFnFor("instrument")) ?? [], {
		mode: "keyword",
		query: debouncedNormalizedQuery,
	});

	const {
		column: checkBoxColumn,
		rowClassList: rowClassListFn,
		toggle,
		multiSelectCtx,
	} = useSelectableTableColumn({
		rows: searchable.filtered,
		selectBy: ({ ticker }) => ticker ?? "",
		mode: "checkbox",
	});

	const multiSelectCtxRef = useRef(multiSelectCtx);
	useEffect(() => {
		const preselectedInstrumentsId = selected.flatMap((instrument) => (instrument.ticker ? [instrument.ticker] : []));
		multiSelectCtxRef.current.setSelection(preselectedInstrumentsId);
	}, [selected]);

	const instrumentColumns = useInstrumentColumnsTableV2();

	const columns = useMemo<Array<TableColumn<RichTicker>>>(
		() => [checkBoxColumn, instrumentColumns.instrument()],
		[checkBoxColumn, instrumentColumns],
	);

	function onLoad(selectedTickers: MultiSelectCtx<string>, availableInstruments: Array<RichTicker>) {
		const selectedTickerIds = selectedTickers.selection.toArray();
		const selectedInstruments = selectedTickerIds.flatMap((ticker) => {
			const instrument = availableInstruments.find((availableInstrument) => availableInstrument.ticker === ticker);
			return instrument ? [instrument] : [];
		});
		onSubmit(selectedInstruments);
		onClose();
	}

	return (
		<Dialog
			size="xxlarge"
			show={show}
			onAnimationStateChange={onAnimationStateChange}
			onClose={onClose}
			header={dialogTitle}
			footer={
				<DialogFooter
					primaryAction={
						<Button palette="primary" onClick={() => onLoad(multiSelectCtx, instruments)}>
							Done
						</Button>
					}
					neutralAction={
						<Button palette="tertiary" onClick={onClose}>
							{t("BUTTON.CANCEL")}
						</Button>
					}
				/>
			}
		>
			<TextInput placeholder="Filter by name" value={query} onChangeText={setQuery} classList="mb-4" />
			<AutoSortTable
				rows={searchable.filtered}
				columns={columns}
				classList="max-h-[410px]"
				onRowClick={({ ticker }) => toggle(ticker ?? "")}
				rowClassList={(row, rowIndex) => ({
					...toClassListRecord(rowClassListFn(row, rowIndex)),
					[rowClassList ?? ""]: Boolean(rowClassList),
				})}
			/>
		</Dialog>
	);
}
