import type {
	AssetClassRegimeTimeSeries,
	MarketOutlookFocusTimeSeriesDTO,
	MarketOutlookTimeSeriesDTO,
	MarketOverviewTimeSeriesDTO,
} from "$root/api/api-gen";
import { MarketControllerV2ApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import GraphLegend from "$root/components/GraphLegend";
import ColoredRectangle from "$root/components/icons/ColoredRectangle";
import useOutlookComposition from "$root/hooks/useOutlookComposition";
import { useLocaleFormatters } from "$root/localization/hooks";
import { PaletteColors } from "$root/styles/themePalette";
import { Highcharts } from "$root/third-party-integrations/highcharts-with-modules";
import { useQueryNoRefetch } from "$root/utils";
import {
	Button,
	Collapsible,
	DefaultCollapsibleContent,
	DefaultCollapsibleHeader,
	Dialog,
	Select,
} from "@mdotm/mdotui/components";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";
import AuthorizationGuard from "../AuthorizationGuard";

function CustomizedChart({
	timeSeries,
	inverted = false,
}: {
	timeSeries: { indicatorSerie: [number, number][] };
	inverted?: boolean;
}): JSX.Element {
	const colorMap = useMemo(
		() =>
			inverted
				? ["#08a696", "#08a696", "#00aeef", "#ea401f", "#ea401f"]
				: ["#ea401f", "#ea401f", "#00aeef", "#08a696", "#08a696"],
		[inverted],
	);
	const valueLabels = useMemo(() => ["Low", "Mid-low", "Neutral", "Mid-high", "High"], []);
	const plotLines = useMemo(
		() =>
			valueLabels.map((el, index) =>
				index === 2
					? {
							color: "#000",
							dashStyle: "Solid",
							value: 3,
							width: 1,
					  }
					: {
							color: "#CCCCCC",
							dashStyle: "ShortDot",
							value: index + 1,
							width: 1,
					  },
			),
		[valueLabels],
	);
	const colorCategories = useMemo(() => [{ name: "Serie", color: "#8e8e8e", Component: ColoredRectangle }], []);
	const chartRef = useRef<HighchartsReact.RefObject>(null);

	const series = useMemo(() => {
		// Sorting Requested By Highcharts
		const formattedSeries = {
			indicatorSerie: timeSeries.indicatorSerie
				.map(([x, y]) => ({
					x,
					y,
				}))
				.sort((a, b) => a.x - b.x),
		};

		// tricky fix for highcharts start compare
		// TODO: find a better way
		const { indicatorSerie } = formattedSeries;
		indicatorSerie[0].y = indicatorSerie[0].y === 0 ? 0.00001 : indicatorSerie[0].y;
		return formattedSeries;
	}, [timeSeries.indicatorSerie]);

	const { formatDate, formatNumber } = useLocaleFormatters();
	const options = useMemo(() => {
		const minDate = Math.min(series.indicatorSerie[0].x);
		const maxDate = Math.max(series.indicatorSerie[series.indicatorSerie.length - 1].x);
		return {
			chart: {
				style: {
					fontFamily: "Gotham,sans-serif",
				},
				panning: {
					enabled: false,
				},
			},
			credits: {
				enabled: false,
			},
			legend: {
				enabled: false,
			},
			title: {
				text: undefined,
			},
			subtitle: {
				text: undefined,
			},
			xAxis: [
				{
					type: "datetime",
					min: minDate,
					max: maxDate,
					endOnTick: false,
					startOnTick: false,
					minTickInterval: Math.min(maxDate + 86400000 - minDate, 1000 * 60 * 60 * 24),
					minRange: Math.min(maxDate + 86400000 - minDate, 1000 * 60 * 60 * 24 * 7),
				},
			],
			yAxis: {
				opposite: false,
				title: {
					enabled: false,
				},
				gridLine: 1,
				gridLineColor: "transparent",
				tickInterval: 1,
				min: 1,
				max: 6,
				labels: {
					formatter(this: { value: number }) {
						const sValue = this.value;
						const label = sValue < 0 || sValue > valueLabels.length ? "" : valueLabels[this.value - 1];
						return `${label}`;
					},
				},
				plotLines,
			},
			boost: {
				debug: {
					timeRendering: true,
				},
				useGPUTranslations: true,
				seriesThreshold: 1,
			},
			tooltip: {
				enabled: true,
				shared: false,
				useHTML: true,
				borderColor: "transparent",
				borderRadius: 5,
				backgroundColor: PaletteColors.WHITE,

				formatter(this: {
					points: {
						x: number;
						y: number;
						point: {
							change: number;
							marker: {
								fillColor: string;
							};
						};
						series: Highcharts.Series & { options: { marker: { fillColor: string } } };
					}[];
					x: number;
					y: number;
				}) {
					const content = `<div style="min-width: 12.5rem">
							<div style="text-align: center;
								border-radius: 0.25rem;
								background-color: #eeeef1;
								color: #9699b3;
								font-family: Gotham, sans-serif;
								font-weight: bold;
								line-height: 1.25rem;
								padding: 0.2rem 0;">
								<div>${formatDate(this.x)}</div>
							</div>
							<table style="width: 100%">
								<tbody>${(this.points || [])
									.map((point) => {
										return `
											<tr>
												<td style="border-bottom: solid 1px transparent; padding: 5px 8px 0 0">
												<span style="display: flex; flex-direction: row; align-items: center">
													<span style="display: inline-block; width: 10px; height: 10px; margin-right: 6px; border-radius: 100px; background-color: ${
														point.point.marker.fillColor
													}">
												</span>
												${point.series.name}</span>
												</td>
												<td
													style="text-align: right; font-weight: 500; border-bottom: solid 1px transparent; padding: 5px 0 0 0; color: black"
												>
													${valueLabels[point.y - 1]} (${formatNumber(point.y, 2)})
												</td>
											</tr>`;
									})
									.reverse()
									.join("")}
								</tbody>
							</table>
						</div>`;
					return content;
				},
			},
			plotOptions: {
				series: {
					showInNavigator: true,
					dashStyle: "ShortDot",
				},
			},
			series: [
				{
					data: series.indicatorSerie.map((serieIndic) => ({
						...serieIndic,
						marker: { fillColor: colorMap[serieIndic.y - 1] },
					})),
					color: colorCategories[0].color,
					name: "Serie",
					marker: {
						enabled: true,
						radius: 3,
					},
					zones: colorMap.map((el, index) => ({
						value: index + 1.5,
						color: el,
					})),
				},
			],
			time: {
				// timezoneOffset: new Date().getTimezoneOffset(),
				timezoneOffset: 12 * 60, // Method to Fix Backend Date Time
				useUTC: false,
			},
		};
	}, [colorCategories, colorMap, formatDate, formatNumber, plotLines, series.indicatorSerie, valueLabels]);

	return (
		<>
			<div style={{ flex: 1 }}>
				<HighchartsReact
					containerProps={{ style: { height: "100%" } }}
					highcharts={Highcharts}
					constructorType="stockChart"
					options={options}
					ref={chartRef}
				/>
			</div>
			<div style={{ flex: 0 }}>
				<GraphLegend style={{ position: "static", padding: "1rem 1rem", margin: 0 }}>
					{colorCategories.map(({ name, color, Component }) => (
						<div key={color} className="legend-container light more-space book">
							<Component variant="vertical" color={color} />
							{name}
						</div>
					))}
				</GraphLegend>
			</div>
		</>
	);
}

function DataSeriesModal({
	firstFilter = "",
	secondFilter = "",
	thirdFilter = "",
	ACFilter = "",
	typeMode,
	indicator,
	splitted = false,
	blockedPath = undefined,
	inverted = false,
}:
	| {
			typeMode: "DASHBOARD";
			splitted: boolean;
			ACFilter?: string;
			firstFilter?: string;
			secondFilter?: string;
			thirdFilter?: string;
			indicator?: "RETURN" | "VOLATILITY" | "DIVERSIFICATION" | "REGIME";
			blockedPath?: string;
			inverted?: boolean;
	  }
	| {
			typeMode: "OUTLOOK";
			splitted?: boolean;
			ACFilter?: string;
			firstFilter: string;
			secondFilter: string;
			thirdFilter?: string;
			indicator?: "RETURN" | "VOLATILITY" | "DIVERSIFICATION" | "REGIME";
			blockedPath?: string;
			inverted?: boolean;
	  }
	| {
			typeMode: "OUTLOOK_FOCUS";
			splitted?: boolean;
			ACFilter?: string;
			firstFilter: string;
			secondFilter: string;
			thirdFilter: string;
			indicator: "RETURN" | "VOLATILITY" | "DIVERSIFICATION" | "REGIME";
			blockedPath?: string;
			inverted?: boolean;
	  }): JSX.Element {
	const location = useLocation();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [dataSelectorValue, setDataSelectorValue] = useState("");
	const marketApi = useApiGen(MarketControllerV2ApiFactory);
	const { assetClass, geography, sector } = useOutlookComposition(firstFilter, secondFilter, thirdFilter);
	const fromDate = new Date(new Date(new Date().setFullYear(new Date().getFullYear() - 1)).setHours(0, 0, 0, 0))
		.toISOString()
		.split("T")[0];
	const isRegime = indicator === "REGIME";
	const isBlockedPath = blockedPath ? location.pathname.includes(blockedPath) : false;

	const FILTER_MAP_TABLE = {
		COMMODITIES: "COMMODITIES",
		EQUITY: "EQUITY",
		FIXED_INCOME: "FIXED_INCOME",
		DASHBOARD_EQUITY: "EQUITY",
		DASHBOARD_FIXED_INCOME: "FIXED_INCOME",
		DASHBOARD_COMMODITIES: "COMMODITIES",
	};

	const handleClose = () => setIsModalOpen(false);

	const { data, isLoading } = useQueryNoRefetch(
		["temporalSeriesQuery", firstFilter, secondFilter, thirdFilter, fromDate, typeMode, indicator, isRegime, ACFilter],
		{
			queryFn: async () => {
				let seriesData: {
					[key: string]: number[][];
				} = {};
				if (typeMode === "OUTLOOK_FOCUS") {
					const { data: apiData } = isRegime
						? await marketApi.retrieveMarketRegimeWithElementsTimeSeries(assetClass, geography, sector, fromDate)
						: await marketApi.retrieveOutlookFocusTimeSeriesByAssetClass(
								assetClass,
								geography,
								sector,
								indicator,
								fromDate,
						  );
					seriesData = isRegime
						? ((apiData as AssetClassRegimeTimeSeries).comparables as {
								[key: string]: number[][];
						  })
						: ({
								...{
									Contribution: (apiData as MarketOutlookFocusTimeSeriesDTO).values,
								},
								...(apiData as MarketOutlookFocusTimeSeriesDTO).elements,
						  } as {
								[key: string]: number[][];
						  });
				}
				if (typeMode === "OUTLOOK") {
					const { data: apiData } = await marketApi.retrieveOutlookTimeSeriesByAssetClass(
						assetClass,
						geography,
						fromDate,
					);
					const fromArrayToObject = (apiData as MarketOutlookTimeSeriesDTO[]).map((el) => {
						return [el.type, el.values];
					});
					seriesData = Object.fromEntries(fromArrayToObject) as {
						[key: string]: number[][];
					};
				}
				if (typeMode === "DASHBOARD") {
					const { data: apiData } = splitted
						? await marketApi.retrieveOutlookTimeSeries(fromDate)
						: await marketApi.retrieveOverviewTimeSeries(fromDate);

					seriesData = splitted
						? Object.fromEntries(
								(apiData as MarketOutlookTimeSeriesDTO[])
									.filter((ac) => ac.assetClass === FILTER_MAP_TABLE[ACFilter as keyof typeof FILTER_MAP_TABLE])
									.map((el) => {
										return [el.type, el.values];
									}),
						  )
						: (apiData as MarketOverviewTimeSeriesDTO as {
								[key: string]: number[][];
						  });
				}

				return seriesData;
			},
			onError: (e) => console.warn(e),
		},
	);

	const seriesData = useMemo(() => {
		if (!data || !data[dataSelectorValue] || data[dataSelectorValue].length === 0) {
			return null;
		}
		return {
			indicatorSerie: data[dataSelectorValue].map(([dateSeconds, y]) => [dateSeconds * 1000, y]) as [number, number][],
		};
	}, [data, dataSelectorValue]);

	const selectOptions = useMemo(() => {
		if (!data) {
			return [];
		}
		const options = Object.keys(data).map((key) => ({
			label: key.slice(0, 1).toUpperCase() + key.slice(1),
			value: key,
		}));
		return options;
	}, [data]);

	useEffect(() => {
		setDataSelectorValue(selectOptions.length > 0 ? selectOptions[0].value : "");
	}, [selectOptions]);

	return isBlockedPath || isLoading ? (
		<></>
	) : (
		<AuthorizationGuard requiredRole="ROOT">
			{() => (
				<>
					<Dialog size="xxlarge" show={isModalOpen} onClose={handleClose}>
						<Collapsible
							header={(props) => (
								<DefaultCollapsibleHeader {...props}>
									<p className="font-bold text-[16px]">
										Time series - {firstFilter} {secondFilter} {thirdFilter} - {indicator}
									</p>
								</DefaultCollapsibleHeader>
							)}
						>
							{(props) => (
								<DefaultCollapsibleContent {...props}>
									<div>
										<div className="my-2">
											<span className="font-bold">Primary Filters: </span>
											{firstFilter} {secondFilter} {thirdFilter}
										</div>
										<div className="my-2">
											<span className="font-bold">AC Filter: </span>
											{ACFilter}
										</div>
										<div className="my-2">
											<span className="font-bold">Indicator: </span>
											{indicator}
										</div>
										<div className="my-2">
											<span className="font-bold">From Date: </span>
											{fromDate}
										</div>
										<div className="my-2">
											<span className="font-bold">Selected Serie: </span>
											{dataSelectorValue}
										</div>
									</div>
								</DefaultCollapsibleContent>
							)}
						</Collapsible>
						<div>
							<div className="my-4">
								<div className="font-bold mb-1">Selected Serie: </div>
								<Select
									size="x-small"
									value={dataSelectorValue}
									onChange={setDataSelectorValue}
									options={selectOptions}
								/>
							</div>
							{seriesData && <CustomizedChart timeSeries={seriesData} inverted={inverted} />}
						</div>
					</Dialog>
					<div className="ml-2">
						<Button onClick={() => setIsModalOpen(true)} size="x-small" palette="inverted">
							View Series
						</Button>
					</div>
				</>
			)}
		</AuthorizationGuard>
	);
}

export default DataSeriesModal;
