import { multiRef } from "$root/utils";
import type { TableDataCellProps } from "@mdotm/mdotui/components";
import { AutoTooltip, TableDataCell, TooltipContent } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";

function QualityCell({
	quality,
	innerRef,
	...tableDataCellProps
}: { quality: string | undefined } & TableDataCellProps): JSX.Element {
	return (
		<AutoTooltip
			severity="info"
			position="right"
			overrideColor={themeCSSVars.palette_N200}
			disabled={!quality}
			trigger={({ innerRef: tooltipRef }) => (
				<TableDataCell {...tableDataCellProps} innerRef={innerRef ? multiRef(tooltipRef, innerRef) : tooltipRef}>
					{quality}
				</TableDataCell>
			)}
		>
			<TooltipContent>{quality}</TooltipContent>
		</AutoTooltip>
	);
}

export default QualityCell;
