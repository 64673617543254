import type {
	AlertDto,
	ReviewTickerProxyOverwriteTypeEnum,
	RichTickerProxyOverwriteTypeEnum,
	Tag,
	TagValue,
} from "$root/api/api-gen";
import { type ReviewTicker } from "$root/api/api-gen";
import { InfoDelta } from "$root/components/InfoDelta";
import { MarkdownRenderer } from "$root/components/MarkdownRenderer/MarkdownRenderer";
import { typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";
import type { UserColumnMetadata } from "$root/functional-areas/instruments/hooks";
import { generateTagMapByClassification, generateTagWeightByTag } from "$root/functional-areas/instruments/hooks";
import { TagBadge } from "$root/functional-areas/instruments/tags-v2/Tag";
import { TagDataCell } from "$root/functional-areas/instruments/tags-v2/table-cell";
import { useLocaleFormatters } from "$root/localization/hooks";
import { getThemeCssVars } from "$root/utils";
import type { TableColumn, TableWithGroupsColumn } from "@mdotm/mdotui/components";
import {
	ActionText,
	AutoTooltip,
	Row,
	TableDataCell,
	TableHeadCellWithDropdown,
	TooltipContent,
} from "@mdotm/mdotui/components";
import { overrideClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import { PortfolioDetailsTabs } from "../portfolio-details-tabs";

function InstrumentNameBlock(props: { row: ReviewTicker; alerts: AlertDto[] }) {
	const { t } = useTranslation();
	const { instrument, tickerId, descriptionCreator, description } = props.row;
	return (
		<AutoTooltip
			align="startToStart"
			disabled={!description}
			trigger={({ innerRef }) => (
				<div className="flex flex-row items-center flex-nowrap w-full pr-4" ref={innerRef}>
					<div
						className={overrideClassName("line-clamp-2", {
							underline: Boolean(description),
						})}
					>
						{instrument ?? ""}
					</div>
					{props.alerts.some(
						(a) =>
							(a.type === "InstrumentsChangesAlertCheck" || a.type === "InstrumentsChangesChildAlertCheck") &&
							a.value?.tickerId === tickerId,
					) && (
						<span
							className="block rounded-full px-1 ml-1 py-0.5 bg-[#00AEEF] text-white uppercase"
							style={{ fontSize: 8 }}
						>
							{t("UPDATED")}
						</span>
					)}
				</div>
			)}
		>
			{descriptionCreator === "SPHERE" ? (
				<TooltipContent>
					<MarkdownRenderer>{description ?? ""}</MarkdownRenderer>
				</TooltipContent>
			) : (
				description
			)}
		</AutoTooltip>
	);
}

function PortfolioNameBlock(props: { row: ReviewTicker }) {
	const { instrument, ticker } = props.row;
	return (
		<div className="flex flex-row items-center flex-nowrap w-full pr-4">
			<ActionText
				classList="inline-flex items-center gap-1 text-left"
				onClick={() =>
					window.open(
						typedUrlForRoute("PortfolioDetails", { portfolioUid: ticker ?? "", tab: PortfolioDetailsTabs.COMPOSITION }),
						"_blank",
					)
				}
			>
				<span className="font-[weight:500] line-clamp-2">{instrument ?? "-"}</span>
				<svg
					width="12"
					height="12"
					viewBox="0 0 12 12"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					className="shrink-0"
				>
					<path
						d="M8 1.5H10.5V4"
						stroke="currentColor"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M7 5L10.5 1.5"
						stroke="currentColor"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M8.5 7.4375V9.625C8.5 10.1084 8.10844 10.5 7.625 10.5H2.375C1.89156 10.5 1.5 10.1084 1.5 9.625V4.375C1.5 3.89156 1.89156 3.5 2.375 3.5H4.5625"
						stroke="currentColor"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</ActionText>
		</div>
	);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useCompositionColumns() {
	const formatters = useLocaleFormatters();
	const { formatNumber } = formatters;

	const columns = useMemo(
		() =>
			({
				INSTRUMENT_NAME: (params: { alerts?: AlertDto[] }): TableColumn<ReviewTicker> => ({
					name: "instrument",
					header: "Name",
					content: (row, cellProps) => {
						return (
							<TableDataCell {...cellProps}>
								{row.proxyOverwriteType === "PORTFOLIO_MIXED" ? (
									<PortfolioNameBlock row={row} />
								) : (
									<InstrumentNameBlock alerts={params.alerts ?? []} row={row} />
								)}
							</TableDataCell>
						);
					},
					minWidth: 460,
					maxWidth: 560,
					sortFn: builtInSortFnFor("instrument"),
				}),
				/* IDENTIFIER: () => ({
					name: "identifier",
					header: "Identifier",
					content: ({ identifier, proxyOverwriteType }) =>
						proxyOverwriteType === "PORTFOLIO_MIXED" ? "Portfolio" : identifier ? identifier : "",
					width: 128,
					sortFn: builtInSortFnFor("identifier"),
				}), */
				/* ASSET_CLASS: () => ({
					name: "assetClass",
					header: "Asset Class",
					content: ({ assetClass, proxyOverwriteType }) =>
						proxyOverwriteType === "PORTFOLIO_MIXED" ? "" : assetClass ? assetClass : "",
					width: 296,
					sortFn: builtInSortFnFor("assetClass"),
				}), */
				/* SCORE: (params: { scoreIdentifier?: string }) => ({
					header: (props) => (
						<TableHeadCell {...props}>
							{params.scoreIdentifier ? (
								<CustomLabels labelKey={params.scoreIdentifier} fallback={t("SCORE")} mode="view" isEditable={false} />
							) : (
								t("SCORE")
							)}
						</TableHeadCell>
					),
					content: ({ score }) => (score ? formatNumber(score) : ""),
					name: "score",
					width: 128,
					hidden: !hasAccess(user, { requiredService: "CUSTOM_QUALITIES" }),
					sortFn: builtInSortFnFor("score"),
				}), */
				/* MICRO_ASSET_CLASS: () => ({
					name: "microAssetClass",
					header: "Micro Asset Class",
					width: 240,
					content: ({ microAssetClass, proxyOverwriteType }) =>
						proxyOverwriteType === "PORTFOLIO_MIXED" ? "" : microAssetClass ? microAssetClass : "",
					sortFn: builtInSortFnFor("microAssetClass"),
				}), */
				WEIGHT: () => ({
					name: "weight",
					header(headProps) {
						return (
							<TableHeadCellWithDropdown {...headProps} actions={(sortingActions) => sortingActions}>
								Weight
							</TableHeadCellWithDropdown>
						);
					},
					content: (row: { weight?: number }) => `${formatNumber(row.weight ?? 0)}%`,
					width: 104,
					sortFn: builtInSortFnFor("weight"),
					align: "end",
					cellClassList: "tabular-nums",
				}),
				CURRENT_WEIGHT: () => ({
					name: "currentWeight",
					header: "Current Weight",
					content: (row: { previousWeight?: number }) => `${formatNumber(row.previousWeight ?? 0)}%`,
					width: 134,
					sortFn: builtInSortFnFor("previousWeight"),
					align: "end",
					cellClassList: "tabular-nums",
				}),
				ENHANCED_WEIGHT: () => ({
					name: "enhancedWeight",
					header: "Proposal Weight",
					content: (row: { weight?: number }) => `${formatNumber(row.weight ?? 0)}%`,
					width: 104,
					sortFn: builtInSortFnFor("weight"),
					align: "end",
					cellClassList: "tabular-nums",
				}),
				DIFFERENCE: () => ({
					name: "difference",
					header: "difference",
					content: (instrument: { weight?: number; previousWeight?: number }, cellProps) => {
						const deltaWeight = Number((instrument.weight ?? 0) - (instrument.previousWeight ?? 0)).toFixed(2);
						return (
							<TableDataCell {...cellProps}>
								<InfoDelta diff={Number(deltaWeight) ?? 0} enh={instrument.weight ?? 0} />
							</TableDataCell>
						);
					},
					align: "end",
					width: 140,
					cellClassList: "tabular-nums",
					sortFn: (a, b) => {
						const rowa = a as { weight?: number; previousWeight?: number };
						const rowb = b as { weight?: number; previousWeight?: number };
						const deltaA = (rowa.weight ?? 0) - (rowa.previousWeight ?? 0);
						const deltaB = (rowb.weight ?? 0) - (rowb.previousWeight ?? 0);

						if (deltaA > deltaB) {
							return 1;
						}

						if (deltaA < deltaB) {
							return -1;
						}

						return 0;
					},
				}),
				/* TAG: (params: { tags?: Array<{ name: string; color: string }> }) => {
					return {
						name: "tag",
						header: "Tag",
						content: ({ tagLabel }, cellProps) => {
							const currentTag = params.tags?.find((item) => item.name === tagLabel);
							if (currentTag === undefined) {
								return "";
							}

							return (
								<TableDataCell {...cellProps}>
									<TagBadge color={currentTag.color}>{currentTag.name}</TagBadge>
								</TableDataCell>
							);
						},
						minWidth: 140,
						maxWidth: 320,
						sortFn: builtInSortFnFor("tagLabel"),
					};
				}, */
			}) satisfies Record<string, (...args: any[]) => TableColumn<any>>,
		[formatNumber],
	);

	return columns;
}

export type GroupedCompositionRow<T> = {
	groupKey: string;
	groupData:
		| {
				kind: "string";
				text: string;
		  }
		| { kind: "tags"; tags?: TagValue[] };
	weight: number;
	previousWeight: number;
	rows: T[];
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useGroupedCompositionColumn() {
	// const compositionColumns = useCompositionColumn(params);
	const allColumns = useCompositionColumns();
	const { formatNumber } = useLocaleFormatters();

	const columns = useMemo(
		() =>
			({
				WEIGHT<T>() {
					return {
						...allColumns.WEIGHT(),
						groupCellClassList: "tabular-nums",
						groupContentTextType: "Body/M/Bold",
						groupContent: (group: GroupedCompositionRow<T>) => `${formatNumber(group.weight, 2)}%`,
						sortFn: builtInSortFnFor("weight"),
					};
				},
				CURRENT_WEIGHT<T>() {
					return {
						...allColumns.CURRENT_WEIGHT(),
						groupCellClassList: "tabular-nums",
						groupContentTextType: "Body/M/Bold",
						groupContent: (group: GroupedCompositionRow<T>) => `${formatNumber(group.previousWeight, 2)}%`,
					};
				},
				ENHANCED_WEIGHT<T>() {
					return {
						...allColumns.ENHANCED_WEIGHT(),
						groupCellClassList: "tabular-nums",
						groupContentTextType: "Body/M/Bold",
						groupContent: (group: GroupedCompositionRow<T>) => `${formatNumber(group.weight, 2)}%`,
					};
				},
				DIFFERENCE<T>() {
					return {
						...allColumns.DIFFERENCE(),
						groupCellClassList: "tabular-nums",
						groupContentTextType: "Body/M/Bold",
						groupContent: (group: GroupedCompositionRow<T>, cellProps) => {
							const deltaWeight = Number((group.weight ?? 0) - (group.previousWeight ?? 0)).toFixed(2);
							return (
								<TableDataCell {...cellProps}>
									<InfoDelta diff={Number(deltaWeight) ?? 0} enh={group.weight ?? 0} />
								</TableDataCell>
							);
						},
						sortFn: (groupA, groupB) => {
							const deltaWeightA = Number((groupA.weight ?? 0) - (groupA.previousWeight ?? 0)).toFixed(2);
							const deltaWeightB = Number((groupB.weight ?? 0) - (groupB.previousWeight ?? 0)).toFixed(2);

							return deltaWeightA > deltaWeightB ? 1 : deltaWeightA < deltaWeightB ? -1 : 0;
						},
					};
				},
			}) satisfies Record<string, (...args: any[]) => TableWithGroupsColumn<GroupedCompositionRow<any>, string>>,
		[allColumns, formatNumber],
	);

	return columns;
}

export function useGroupedCompositionRows<
	T extends {
		name?: string;
		tags?: Tag[];
		weight?: number;
		previousWeight?: number;
		proxyOverwriteType?: ReviewTickerProxyOverwriteTypeEnum | RichTickerProxyOverwriteTypeEnum;
	},
>(rows: T[], classificationUuid: string): GroupedCompositionRow<T>[] {
	const groupedRows: Map</* groupKey */ string, GroupedCompositionRow<T>> = new Map();
	for (const row of rows) {
		if (row.proxyOverwriteType === "PORTFOLIO_MIXED") {
			const groupKey = "Portfolios";
			const existing = groupedRows.get(groupKey);
			const subRows = existing?.rows ?? [];
			subRows.push(row);
			groupedRows.set(groupKey, {
				...existing,
				groupKey,
				groupData: { kind: "string", text: groupKey },
				previousWeight: (existing?.previousWeight ?? 0) + (row.previousWeight ?? 0),
				weight: (existing?.weight ?? 0) + (row.weight ?? 0),
				rows: subRows,
			});
		} else {
			const normalizedTags = normalizeTags(row.tags?.find((x) => x.classificationUuid === classificationUuid)?.values);
			const groupKey = normalizedTags.map((tag) => `${tag.option!}-${tag.weight!}`).join("&");
			const existing = groupedRows.get(groupKey);
			const subRows = existing?.rows ?? [];
			subRows.push(row);
			groupedRows.set(groupKey, {
				...existing,
				groupKey,
				groupData: { kind: "tags", tags: normalizedTags },
				previousWeight: (existing?.previousWeight ?? 0) + (row.previousWeight ?? 0),
				weight: (existing?.weight ?? 0) + (row.weight ?? 0),
				rows: subRows,
			});
		}
	}

	return Array.from(groupedRows.values());
}

export function createTagGroupedColumn<
	T extends {
		name?: string;
		tags?: Tag[];
		weight?: number;
		previousWeight?: number;
		proxyOverwriteType?: ReviewTickerProxyOverwriteTypeEnum | RichTickerProxyOverwriteTypeEnum;
	},
>(selectedClassification: UserColumnMetadata): TableWithGroupsColumn<GroupedCompositionRow<T>> {
	return {
		groupContent: ({ groupData }, cellProps) =>
			match(groupData)
				.with({ kind: "string" }, (gData) => gData.text)
				.with({ kind: "tags" }, (gData) => {
					const tagMap = generateTagMapByClassification(selectedClassification);
					const tagWeightMap = generateTagWeightByTag(gData.tags ?? []);

					return !gData.tags || gData.tags.length === 0 ? (
						<Row gap={2} alignItems="center" classList="overflow-x-hidden" {...cellProps}>
							<TagBadge label="Untagged" color={getThemeCssVars(themeCSSVars.palette_N50)} />
						</Row>
					) : (
						<TagDataCell {...cellProps} tagMap={tagMap} tagWeightMap={tagWeightMap} viewMode="TAG_VIEW_WITH_COLOR" />
					);
				})
				.exhaustive(),
		content: () => null,
		header: "group",
		name: "tagGroup",
	};
}

function normalizeTags(tags: TagValue[] | undefined) {
	if (!tags) {
		return [];
	}
	return tags.slice().sort(builtInSortFnFor("weight", "desc"));
}
