import type { InvestmentStatuses } from "$root/api/api-gen";
import type { IconName } from "@mdotm/mdotui/components";


export const statusIconMap: Record<InvestmentStatuses, { icon: IconName; size: number; title: string; }> = {
	ERROR: {
		icon: "Close",
		title: "Error",
		size: 16,
	},
	READY: {
		icon: "Icon-Ptf-status-SMALL",
		title: "Ready",
		size: 14,
	},
	PROPOSAL_READY: {
		icon: "Icon-full-small",
		title: "Proposal Ready",
		size: 12,
	},
	CALCULATING: {
		icon: "calulating",
		title: "Calculating",
		size: 12,
	},
	ACCEPTED: {
		icon: "Icon-Ptf-status-SMALL",
		title: "Accepted",
		size: 14,
	},
	RETRIEVING_DATA: {
		icon: "calulating",
		size: 14,
		title: "Retrieving data",
	},
	REVIEW: {
		icon: "Edit",
		size: 14,
		title: "Review",
	},
	DRAFT: {
		icon: "Edit",
		size: 14,
		title: "Draft",
	},
};
