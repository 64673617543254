import type {
	PortfolioMetricsOrderingResponse,
	PortfolioMetricTypes,
	UserInstrumentClassificationDto,
} from "$root/api/api-gen";
import {
	CommentaryTemplateControllerApiFactory,
	InstrumentsClassificationsControllerV1ApiFactory,
	PortfolioStudioPreferencesApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import ReactQueryWrapper, { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { FormFields } from "$root/ui-lib/form/FormFields";
import { customObjectEntriesFn, qualifier, sumArrayLike, typedObjectKeys, useQueryNoRefetch } from "$root/utils";
import type { InvestmentExposureResponseExposureTypeEnum } from "$root/widgets-architecture/widgets/ExposureEvolve/InvestmentExposureResponseExposureTypeEnum";
import type { DebouncedSearchInputHandle, OptionWithChildren, OptionWithOptionalGroup } from "@mdotm/mdotui/components";
import {
	AsyncButton,
	Autocomplete,
	Button,
	Checkbox,
	Collapsible,
	DraggableList,
	Label,
	Text,
	TinyIconButton,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { ForEach, toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import Immutable, { Map } from "immutable";
import { useMemo, useRef, useState, type ReactNode } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { CompositionColumnPreference, ReportTemplateVariant } from "./report-latest";
import { itemToLabelMap, type ReportTemplateItemFor, type ReportTemplateItemMap } from "./report-latest";
import { groupBy, typedObjectEntries, typedObjectValues } from "@mdotm/mdotui/utils";

export type SetConfigurationReportTemplateHelper<K extends keyof ReportTemplateItemMap> = {
	templateVariant: ReportTemplateVariant;
	configuration: ReportTemplateItemFor<K>;
	onChange(newConfiguration: ReportTemplateItemFor<K>): void;
	onCancel(): void;
};

export function SideHeaderContent(props: {
	title: string;
	onCancel?(): void;
	onSubmit(): MaybePromise<void>;
}): JSX.Element {
	const { t } = useTranslation();
	return (
		<div className={`flex items-center border-b border-solid border-b-[color:${themeCSSVars.palette_N100}] p-4`}>
			<Text type="Body/XL/Bold" as="p" classList="grow h-fit line-clamp-2">
				{props.title}
			</Text>
			<div className="flex justify-end py-2 px-2 space-x-4 bg-white">
				<Button
					palette="tertiary"
					onClick={props.onCancel}
					data-qualifier={qualifier.reportCustomisation.builder.widgetDetails.cancel}
				>
					{t("BUTTON.CANCEL")}
				</Button>
				<AsyncButton
					palette="primary"
					onClickAsync={props.onSubmit}
					data-qualifier={qualifier.reportCustomisation.builder.widgetDetails.save}
				>
					{t("BUTTON.SAVE")}
				</AsyncButton>
			</div>
		</div>
	);
}

export function SideBarContent(props: {
	title: string;
	onSubmit(): MaybePromise<void>;
	onCancel(): void;
	children: ReactNode;
}): JSX.Element {
	// bg-[color:${themeCSSVars.palette_N20}]
	return (
		<div className="grow bg-white overflow-y-auto pb-6">
			<SideHeaderContent title={props.title} onSubmit={props.onSubmit} onCancel={props.onCancel} />
			<div className={`px-4 pt-4 border-t border-solid border-t-[color:${themeCSSVars.palette_N100}]`}>
				{props.children}
			</div>
		</div>
	);
}

export function Summary(props: SetConfigurationReportTemplateHelper<"summary">): JSX.Element {
	const { id, kind, ...opts } = props.configuration;
	const { t } = useTranslation();
	const { control, handleSubmit, formState } = useForm({
		defaultValues: opts,
	});

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<div>
				<p className="mb-4 font-semibold">Select the elements you want to display</p>
				<ForEach collection={typedObjectKeys(opts)}>
					{({ item: key }) => (
						<div>
							<FormFields.Checkbox
								control={control}
								formState={formState}
								name={key}
								classList="mb-1"
								data-qualifier={qualifier.reportCustomisation.modules.summary.metadata(key)}
							>
								{t(`PORTFOLIO_SUMMARY.${key}`)}
							</FormFields.Checkbox>
						</div>
					)}
				</ForEach>
			</div>
		</SideBarContent>
	);
}

export function Disclaimer(props: SetConfigurationReportTemplateHelper<"disclaimer">): JSX.Element {
	const { kind, id, ...opts } = props.configuration;
	const { control, handleSubmit, formState, watch } = useForm({
		defaultValues: opts,
	});

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<p className="mb-4 font-semibold">Customize the title you want to display</p>
			<FormFields.Checkbox
				control={control}
				formState={formState}
				name="content.enabled"
				classList="mb-2"
				data-qualifier={qualifier.reportCustomisation.modules.disclaimer.enableCustomTitle}
			>
				Custom Text
			</FormFields.Checkbox>

			<FormFields.Text
				control={control}
				formState={formState}
				label="Title"
				name="content.title"
				classList="mb-2"
				disabled={!watch("content.enabled")}
				data-qualifier={qualifier.reportCustomisation.modules.disclaimer.title}
			/>

			<FormFields.TextArea
				control={control}
				formState={formState}
				label="Text"
				name="content.description"
				classList="mb-4 [&>textarea]:resize-none"
				disabled={!watch("content.enabled")}
				rows={5}
				data-qualifier={qualifier.reportCustomisation.modules.disclaimer.description}
			/>

			<FormFields.Checkbox
				control={control}
				formState={formState}
				name="contact.enabled"
				classList="mb-2"
				data-qualifier={qualifier.reportCustomisation.modules.disclaimer.enableContacts}
			>
				Contacts
			</FormFields.Checkbox>
			<FormFields.TextArea
				control={control}
				formState={formState}
				label=""
				name="contact.description"
				classList="mb-2 [&>textarea]:resize-none"
				disabled={!watch("contact.enabled")}
				rows={5}
				data-qualifier={qualifier.reportCustomisation.modules.disclaimer.contacts}
			/>
		</SideBarContent>
	);
}

export function Cover(props: SetConfigurationReportTemplateHelper<"cover">): JSX.Element {
	const { kind, id, ...opts } = props.configuration;
	const { control, handleSubmit, formState } = useForm({
		defaultValues: opts,
	});

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<p className="mb-4 font-semibold">Customize the title you want to display</p>
			<FormFields.Text
				label="Title"
				name="title"
				control={control}
				formState={formState}
				data-qualifier={qualifier.reportCustomisation.modules.cover.title}
			/>
		</SideBarContent>
	);
}

const groupedPortfolioMetricsByTypes = [
	{
		label: "Volatility",
		value: "VOLATILITY",
		children: [
			"VOLATILITY_SINCE_INCEPTION",
			"VOLATILITY_YTD",
			"VOLATILITY_1M",
			"VOLATILITY_3M",
			"VOLATILITY_6M",
			"VOLATILITY_1Y",
		],
	},
	{
		label: "Efficiency ratio",
		value: "EFFICIENCY_RATIO",
		children: [
			"EFFICIENCY_RATIO_SINCE_INCEPTION",
			"EFFICIENCY_RATIO_YTD",
			"EFFICIENCY_RATIO_1M",
			"EFFICIENCY_RATIO_3M",
			"EFFICIENCY_RATIO_6M",
			"EFFICIENCY_RATIO_1Y",
		],
	},
	{
		label: "Max drawdown",
		value: "MAX_DRAWDOWN",
		children: [
			"MAX_DRAWDOWN_SINCE_INCEPTION",
			"MAX_DRAWDOWN_YTD",
			"MAX_DRAWDOWN_1M",
			"MAX_DRAWDOWN_3M",
			"MAX_DRAWDOWN_6M",
			"MAX_DRAWDOWN_1Y",
		],
	},
	{
		label: "Performance",
		value: "PERFORMANCE",
		children: [
			"PERFORMANCE_SINCE_INCEPTION",
			"PERFORMANCE_YTD",
			"PERFORMANCE_1M",
			"PERFORMANCE_3M",
			"PERFORMANCE_6M",
			"PERFORMANCE_1Y",
		],
	},
	{
		label: "Sortino",
		value: "SORTINO",
		children: ["SORTINO_SINCE_INCEPTION", "SORTINO_YTD", "SORTINO_1M", "SORTINO_3M", "SORTINO_6M", "SORTINO_1Y"],
	},
] satisfies Array<{
	label: string;
	value: "PERFORMANCE" | "SORTINO" | "MAX_DRAWDOWN" | "VOLATILITY" | "EFFICIENCY_RATIO" | "SCORE";
	children: Array<PortfolioMetricTypes>;
}>;

const portfolioScoreMetrics = {
	label: "Scores & metrics",
	value: "SCORE",
	children: ["SCORE_AVERAGE"],
};

type PortfolioMetricsUseFormProps = {
	preferences: Array<{ key: PortfolioMetricTypes; classificationUuid?: string; enabled: boolean }>;
	showBenchmark: boolean;
};

type PortfolioMetricsGroup = {
	label: string;
	group: {
		id: "PERFORMANCE" | "SORTINO" | "MAX_DRAWDOWN" | "VOLATILITY" | "EFFICIENCY_RATIO" | "SCORE";
		label: string;
	};
	value: { key: PortfolioMetricTypes; classificationUuid?: string };
};
export function PortfolioMetrics(props: SetConfigurationReportTemplateHelper<"portfolioMetrics">): JSX.Element {
	const { kind, id, preferences, showBenchmark } = props.configuration;
	const portfolioStudioPreferences = useApiGen(PortfolioStudioPreferencesApiFactory);
	const { t } = useTranslation();
	const { control, handleSubmit, formState, reset, watch } = useForm<PortfolioMetricsUseFormProps>({
		defaultValues: {
			preferences: preferences.map((x) => ({
				enabled: x.enabled ?? false,
				classificationUuid: x.metricParameter?.classification?.classificationUuid,
				key: x.metricType,
			})),
			showBenchmark,
		},
	});

	const queryPortfolioMetricsPreferences = useQueryNoRefetch(["portfolioMetricsPreferences"], {
		async queryFn() {
			const { portfolioMetricPreferences } = await axiosExtract(
				portfolioStudioPreferences.getPortfolioMetricsOrderingPreferences(),
			);

			const staticPortfolioMetricsPreferences = groupedPortfolioMetricsByTypes.flatMap((group) => {
				const optionsWithGroup = group.children.map(
					(metricType): PortfolioMetricsGroup => ({
						label: t(`PERFORMANCE_METRICS.METRICS.FULL.${metricType}`),
						value: { key: metricType },
						group: { id: group.value, label: group.label },
					}),
				);
				return optionsWithGroup;
			});

			if (portfolioMetricPreferences && portfolioMetricPreferences.length > 0) {
				const scoreMetricsPreferences = portfolioMetricPreferences
					.filter((metric) => portfolioScoreMetrics.children.includes(metric.metricType!))
					.map(
						({ metricType, metricParameters, metadata }): PortfolioMetricsGroup => ({
							label: `${t(`PERFORMANCE_METRICS.METRICS.FULL.${metricType!}`)} ${metadata?.classificationName ?? ""}`,
							value: { key: metricType!, classificationUuid: metricParameters?.classificationUuid },
							group: { id: "SCORE", label: portfolioScoreMetrics.label },
						}),
					);
				return [...staticPortfolioMetricsPreferences, ...scoreMetricsPreferences];
			}

			return staticPortfolioMetricsPreferences;
		},
	});

	const currentMetricsPreferencesControl = useFieldArray<PortfolioMetricsUseFormProps, "preferences", "id">({
		control,
		name: "preferences",
		keyName: "id",
	});

	const classifiedMetricPreferences = useMemo(() => {
		const portfolioMetricsPreferences = queryPortfolioMetricsPreferences.data ?? [];
		if (portfolioMetricsPreferences.length === 0) {
			return { realizedMetrics: [], scoreMetrics: [] };
		}

		const scoreMetrics = portfolioMetricsPreferences.filter((x) => x.group.id === "SCORE");
		const realizedMetrics = portfolioMetricsPreferences.filter((x) => x.group.id !== "SCORE");

		return { realizedMetrics, scoreMetrics };
	}, [queryPortfolioMetricsPreferences.data]);

	function PortfolioMetricsCollapsibleContent(contentProps: { children: ReactNode }): JSX.Element {
		return <div className="p-2">{contentProps.children}</div>;
	}

	function selectionToLabel(size: number) {
		return size > 0 ? `(${size})` : "";
	}

	const groupedRealizedMetrics = useMemo(
		() => groupBy(classifiedMetricPreferences.realizedMetrics, (x) => x.group.id),
		[classifiedMetricPreferences.realizedMetrics],
	);

	const observedPreference = watch("preferences");
	function findPreference(list: PortfolioMetricsUseFormProps["preferences"], valueToFind: PortfolioMetricsGroup) {
		return list.find(
			(x) => x.key === valueToFind.value.key && x.classificationUuid === valueToFind.value.classificationUuid,
		);
	}

	function findPreferenceIndex(list: PortfolioMetricsUseFormProps["preferences"], valueToFind: PortfolioMetricsGroup) {
		return list.findIndex(
			(x) => x.key === valueToFind.value.key && x.classificationUuid === valueToFind.value.classificationUuid,
		);
	}

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await handleSubmit((payload) =>
					props.onChange(
						dbg({
							id,
							kind,
							showBenchmark: payload.showBenchmark,
							preferences: payload.preferences.map((x) => ({
								enabled: x.enabled ?? false,
								metricType: x.key,
								metricParameter: {
									classification: x.classificationUuid
										? {
												classificationUuid: x.classificationUuid,
										  }
										: undefined,
								},
							})),
						}),
					),
				)();
			}}
			onCancel={props.onCancel}
		>
			<ReactQueryWrapperBase query={queryPortfolioMetricsPreferences}>
				{() => (
					<>
						<Text classList="mb-4" as="p" type="Body/M/Medium">
							Select the elements you want to display
						</Text>
						<Text classList="mb-2" as="p" type="Body/M/Book">
							Realised metrics
						</Text>
						<div className="flex flex-col space-y-2 mb-4">
							<ForEach collection={Object.entries(groupedRealizedMetrics)}>
								{({ item: [_category, items] }) => (
									<Collapsible
										header={`${items?.at(0)?.group.label} ${selectionToLabel(
											sumArrayLike(items ?? [], (metric) =>
												findPreference(observedPreference, metric)?.enabled ? 1 : 0,
											),
										)}`}
										data-qualifier={qualifier.reportCustomisation.modules.portfolioMetrics.toggleMacroMetric(
											"MaxDrawDown",
										)}
									>
										<PortfolioMetricsCollapsibleContent>
											<ForEach collection={items}>
												{({ item: metric }) => (
													<div>
														<Checkbox
															classList="mb-1"
															checked={findPreference(observedPreference, metric)?.enabled ?? false}
															onChange={(newValue) => {
																const preferenceIndex = findPreferenceIndex(observedPreference, metric);
																if (preferenceIndex >= 0) {
																	currentMetricsPreferencesControl.update(preferenceIndex, {
																		key: metric.value.key,
																		enabled: newValue,
																		classificationUuid: metric.value.classificationUuid,
																	});
																} else {
																	currentMetricsPreferencesControl.append({
																		key: metric.value.key,
																		enabled: newValue,
																		classificationUuid: metric.value.classificationUuid,
																	});
																}
															}}
															data-qualifier={qualifier.reportCustomisation.modules.portfolioMetrics.metric(
																metric.value.key +
																	(metric.value.classificationUuid ? `/${metric.value.classificationUuid}` : ""),
															)}
														>
															{metric.label}
														</Checkbox>
													</div>
												)}
											</ForEach>
										</PortfolioMetricsCollapsibleContent>
									</Collapsible>
								)}
							</ForEach>
						</div>

						<Text classList="mb-2" as="p" type="Body/M/Book">
							Custom metrics
						</Text>
						<div className="flex flex-col space-y-2">
							<Collapsible
								header={`${classifiedMetricPreferences.scoreMetrics?.at(0)?.group.label} ${selectionToLabel(
									sumArrayLike(classifiedMetricPreferences.scoreMetrics ?? [], (metric) =>
										findPreference(observedPreference, metric)?.enabled ? 1 : 0,
									),
								)}`}
								data-qualifier={qualifier.reportCustomisation.modules.portfolioMetrics.toggleMacroMetric("MaxDrawDown")}
							>
								<PortfolioMetricsCollapsibleContent>
									<ForEach collection={classifiedMetricPreferences.scoreMetrics}>
										{({ item: metric }) => (
											<div>
												<Checkbox
													classList="mb-1"
													checked={findPreference(observedPreference, metric)?.enabled ?? false}
													onChange={(newValue) => {
														const preferenceIndex = findPreferenceIndex(observedPreference, metric);
														if (preferenceIndex >= 0) {
															currentMetricsPreferencesControl.update(preferenceIndex, {
																key: metric.value.key,
																enabled: newValue,
																classificationUuid: metric.value.classificationUuid,
															});
														} else {
															currentMetricsPreferencesControl.append({
																key: metric.value.key,
																enabled: newValue,
																classificationUuid: metric.value.classificationUuid,
															});
														}
													}}
													data-qualifier={qualifier.reportCustomisation.modules.portfolioMetrics.metric(
														metric.value.key +
															(metric.value.classificationUuid ? `/${metric.value.classificationUuid}` : ""),
													)}
												>
													{metric.label}
												</Checkbox>
											</div>
										)}
									</ForEach>
								</PortfolioMetricsCollapsibleContent>
							</Collapsible>
						</div>
						<div className={`mt-4 pt-4 -mx-4 border-t border-solid border-t-[color:${themeCSSVars.palette_N100}]`}>
							<div className="px-4">
								<p className="mb-2 font-semibold">Compare</p>
								<FormFields.Checkbox
									control={control}
									formState={formState}
									name="showBenchmark"
									data-qualifier={qualifier.reportCustomisation.modules.portfolioMetrics.showBenchmark}
								>
									Show benchmark
								</FormFields.Checkbox>
							</div>
						</div>
					</>
				)}
			</ReactQueryWrapperBase>
		</SideBarContent>
	);
}

export function ExAntePortfolioMetrics(
	props: SetConfigurationReportTemplateHelper<"exAntePortfolioMetrics">,
): JSX.Element {
	const { kind, id, ...opts } = props.configuration;
	const { t } = useTranslation();
	const { control, handleSubmit, formState, watch } = useForm({
		defaultValues: opts,
	});

	const volatilityOpts = watch("volatility");
	const selectedVolatilityOpts = Object.values(volatilityOpts).filter((opt) => opt);

	const diversificationRatioOpts = watch("diversificationRatio");
	const selectedDiversificationRatioOpts = Object.values(diversificationRatioOpts).filter((opt) => opt);

	const efficiencyRatioOpts = watch("efficiencyRatio");
	const selectedEfficiencyRatioOpts = Object.values(efficiencyRatioOpts).filter((opt) => opt);

	const maxDrawdownOpts = watch("maxDrawdown");
	const selectedMaxDrawdownOpts = Object.values(maxDrawdownOpts).filter((opt) => opt);

	const historicalVarOpts = watch("historicalVar");
	const selectedHistoricalVarOpts = Object.values(historicalVarOpts).filter((opt) => opt);

	const returnOpts = watch("return");
	const selectedReturnOpts = Object.values(returnOpts).filter((opt) => opt);

	const trackingErrorOpts = watch("trackingError");
	const selectedTrackingErrorOpts = Object.values(trackingErrorOpts).filter((opt) => opt);

	const riskConstraintOpts = watch("riskConstraint");
	const selectedRiskConstraintOpts = Object.values(riskConstraintOpts).filter((opt) => opt);

	function PortfolioMetricsCollapsibleContent(contentProps: { children: ReactNode }): JSX.Element {
		return <div className="p-2">{contentProps.children}</div>;
	}

	function selectionToLabel(list: Array<any>) {
		return list.length > 0 ? `(${list.length})` : "";
	}

	const exanteMetricList = [
		{
			name: "volatility",
			label: `Volatility ${selectionToLabel(selectedVolatilityOpts)}`,
			options: typedObjectKeys(volatilityOpts),
		} as const,
		{
			name: "diversificationRatio",
			label: `Diversification ratio ${selectionToLabel(selectedDiversificationRatioOpts)}`,
			options: typedObjectKeys(diversificationRatioOpts),
		} as const,
		{
			name: "efficiencyRatio",
			label: `Efficiency ratio ${selectionToLabel(selectedEfficiencyRatioOpts)}`,
			options: typedObjectKeys(efficiencyRatioOpts),
		} as const,
		{
			name: "maxDrawdown",
			label: `Max drawdown ${selectionToLabel(selectedMaxDrawdownOpts)}`,
			options: typedObjectKeys(maxDrawdownOpts),
		} as const,
		{
			name: "historicalVar",
			label: `Historical Var ${selectionToLabel(selectedHistoricalVarOpts)}`,
			options: typedObjectKeys(historicalVarOpts),
		} as const,
		{
			name: "return",
			label: `Return ${selectionToLabel(selectedReturnOpts)}`,
			options: typedObjectKeys(returnOpts),
		} as const,
		{
			name: "trackingError",
			label: `Tracking error ${selectionToLabel(selectedTrackingErrorOpts)}`,
			options: typedObjectKeys(trackingErrorOpts),
		} as const,
		{
			name: "riskConstraint",
			label: `Risk constraint ${selectionToLabel(selectedRiskConstraintOpts)}`,
			options: typedObjectKeys(riskConstraintOpts),
		} as const,
	];

	const { grouped, instances } = exanteMetricList.reduce<{
		grouped: Array<(typeof exanteMetricList)[number]>;
		instances: Array<(typeof exanteMetricList)[number]>;
	}>(
		(acc, el) => {
			if (el.options.length === 1) {
				acc.instances.push(el);
			}

			if (el.options.length > 1) {
				acc.grouped.push(el);
			}

			return acc;
		},
		{ grouped: [], instances: [] },
	);

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<p className="mb-4 font-semibold">Select the elements you want to display</p>
			<ForEach collection={instances}>
				{({ item }) => (
					<ForEach collection={item.options}>
						{({ item: key }) => (
							<div className="mb-1.5">
								<FormFields.Checkbox
									control={control}
									formState={formState}
									name={`${item.name}.${key}` as any}
									data-qualifier={qualifier.reportCustomisation.modules.exAntePortfolioMetrics.metric(key)}
								>
									{t(`EXANTE_METRICS.METRICS.${key}`)}
								</FormFields.Checkbox>
							</div>
						)}
					</ForEach>
				)}
			</ForEach>

			{grouped.length > 0 && <div className="mt-4" />}

			<ForEach collection={grouped}>
				{({ item }) => (
					<div className="mb-1.5">
						<Collapsible
							header={item.label}
							data-qualifier={qualifier.reportCustomisation.modules.exAntePortfolioMetrics.toggleMacroMetric(
								item.label,
							)}
						>
							<PortfolioMetricsCollapsibleContent>
								<ForEach collection={item.options}>
									{({ item: key }) => (
										<div>
											<FormFields.Checkbox
												control={control}
												formState={formState}
												name={`${item.name}.${key}` as any}
												classList="mb-px"
												data-qualifier={qualifier.reportCustomisation.modules.exAntePortfolioMetrics.metric(key)}
											>
												{t(`EXANTE_METRICS.METRICS.${key}`)}
											</FormFields.Checkbox>
										</div>
									)}
								</ForEach>
							</PortfolioMetricsCollapsibleContent>
						</Collapsible>
					</div>
				)}
			</ForEach>
			<div className={`mt-4 pt-4 -mx-4 border-t border-solid border-t-[color:${themeCSSVars.palette_N100}]`}>
				<div className="px-4">
					<p className="mb-2 font-semibold">Compare</p>
					<FormFields.Checkbox
						control={control}
						formState={formState}
						name="benchmark"
						data-qualifier={qualifier.reportCustomisation.modules.exAntePortfolioMetrics.showBenchmark}
					>
						Show benchmark
					</FormFields.Checkbox>
				</div>
			</div>
		</SideBarContent>
	);
}

export function FreeText(props: SetConfigurationReportTemplateHelper<"freeText">): JSX.Element {
	const { kind, id, ...opts } = props.configuration;
	const { control, handleSubmit, formState } = useForm({
		defaultValues: opts,
	});

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<p className="mb-4 font-semibold">Customize the description you want to display</p>
			<FormFields.TextArea
				label="Description"
				name="content"
				control={control}
				formState={formState}
				data-qualifier={qualifier.reportCustomisation.modules.freeText}
			/>
		</SideBarContent>
	);
}

export function Commentary(props: SetConfigurationReportTemplateHelper<"commentary">): JSX.Element {
	const { kind, id, templateUuid, ...opts } = props.configuration;
	const { control, handleSubmit, formState, getValues, setValue, watch } = useForm({
		defaultValues: { templateUuid, ...opts },
	});

	const commentaryTemplateApi = useApiGen(CommentaryTemplateControllerApiFactory);

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await handleSubmit((payload) =>
					props.onChange({
						id,
						kind,
						...payload,
					}),
				)();
			}}
			onCancel={props.onCancel}
		>
			<div className="flex flex-col gap-4">
				<p className="mb-4 font-semibold">Select the elements you want to display</p>
				<ReactQueryWrapper
					queryKey={["getCommentaryTemplateList"]}
					queryFn={async () => {
						const templates = await axiosExtract(commentaryTemplateApi.getTemplateList());
						return (
							templates.flatMap((x) => (x.name && x.uuid && x.visible ? [{ label: x.name, value: x.uuid }] : [])) ?? []
						);
					}}
					onSuccess={(opt) => {
						if (getValues("templateUuid") === undefined) {
							setValue("templateUuid", opt.find((x) => x.label === "Standard Template")?.value);
						}
					}}
				>
					{(options) => (
						<FormFields.Select
							control={control}
							formState={formState}
							name="templateUuid"
							label="Select template"
							options={options}
							floatingAppearance={{ classList: "max-h-[328px]" }}
							data-qualifier={qualifier.reportCustomisation.modules.commentary.selectTemplate}
						/>
					)}
				</ReactQueryWrapper>
				<FormFields.Checkbox
					control={control}
					formState={formState}
					name="useTemplateLanguage"
					data-qualifier={qualifier.reportCustomisation.modules.commentary.enableTemplateLanguage}
				>
					Use template language
				</FormFields.Checkbox>
				<hr />
				<div className="flex flex-col gap-4">
					<FormFields.Text
						disabled={watch("useTemplateNameAsTitle")}
						control={control}
						formState={formState}
						name="title"
						label="Title"
						data-qualifier={qualifier.reportCustomisation.modules.commentary.title}
					/>
					<FormFields.Checkbox
						control={control}
						formState={formState}
						name="useTemplateNameAsTitle"
						data-qualifier={qualifier.reportCustomisation.modules.commentary.enableTemplateNameAsTitle}
					>
						Use template name as title
					</FormFields.Checkbox>
				</div>
			</div>
		</SideBarContent>
	);
}

export function Performance(props: SetConfigurationReportTemplateHelper<"performance">): JSX.Element {
	const { kind, id, ...opts } = props.configuration;
	const { control, handleSubmit, formState } = useForm({
		defaultValues: opts,
	});

	const { t } = useTranslation();

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<div>
				<p className="mb-4 font-semibold">Select the elements you want to display</p>
				<ForEach collection={typedObjectKeys(opts)}>
					{({ item: key }) => (
						<div>
							<FormFields.Checkbox
								control={control}
								formState={formState}
								name={key}
								classList="mb-1"
								data-qualifier={qualifier.reportCustomisation.modules.performance.metric(key)}
							>
								{t(`REPORT_COMPONENTS.PERFORMANCE.${key}`)}
							</FormFields.Checkbox>
						</div>
					)}
				</ForEach>
			</div>
		</SideBarContent>
	);
}

const nonCustomAvailableCompositionColumns = ["NAME", "WEIGHT", "DESCRIPTION", "IDENTIFIER"] as const;
const nonCustomAvailableEnhanceCompositionColumns = [
	...nonCustomAvailableCompositionColumns,
	"DIFFERENCE",
	"ENHANCED_WEIGHT",
] as const;

const columnToLabel = {
	DESCRIPTION: "Description",
	DIFFERENCE: "Difference",
	ENHANCED_WEIGHT: "Enhanced Weight",
	NAME: "Name",
	WEIGHT: "Weight",
	IDENTIFIER: "Identifier",
} satisfies Record<(typeof nonCustomAvailableEnhanceCompositionColumns)[number], string>;

function columnsToOption(
	columns: Partial<typeof nonCustomAvailableEnhanceCompositionColumns>,
): Array<OptionWithChildren<string>> {
	return columns.flatMap((c) =>
		c
			? [
					{
						label: columnToLabel[c]!,
						value: c,
					},
			  ]
			: [],
	);
}

export function Composition(props: SetConfigurationReportTemplateHelper<"composition">): JSX.Element {
	const { kind, id, ...opts } = props.configuration;
	const { t } = useTranslation();
	const { control, handleSubmit, formState, watch, setValue } = useForm({
		defaultValues: {
			currentColumnPreferences: props.templateVariant === "current" ? opts.currentColumnPreferences : [],
			proposalColumnPreferences: props.templateVariant === "proposal" ? opts.proposalColumnPreferences : [],
		},
	});
	/** use forms */
	const columnPreferences =
		props.templateVariant === "current" ? watch("currentColumnPreferences") : watch("proposalColumnPreferences");

	const currentColumnPreferencesOptionsControl = useFieldArray<
		{
			currentColumnPreferences: CompositionColumnPreference[];
			proposalColumnPreferences: CompositionColumnPreference[];
		},
		"currentColumnPreferences",
		"id"
	>({
		control,
		name: "currentColumnPreferences",
		keyName: "id",
	});

	const proposalColumnPreferencesOptionsControl = useFieldArray<
		{
			currentColumnPreferences: CompositionColumnPreference[];
			proposalColumnPreferences: CompositionColumnPreference[];
		},
		"proposalColumnPreferences",
		"id"
	>({
		control,
		name: "proposalColumnPreferences",
		keyName: "id",
	});

	const classificationController =
		props.templateVariant === "current"
			? currentColumnPreferencesOptionsControl
			: proposalColumnPreferencesOptionsControl;
	/** <!-- use forms --!> */

	const autocompleteHandleRef = useRef<DebouncedSearchInputHandle | null>(null);
	const instrumentsClassificationsControllerV1Api = useApiGen(InstrumentsClassificationsControllerV1ApiFactory);

	const queryUserClassifications = useQueryNoRefetch(["queryUserClassifications"], {
		queryFn: () => axiosExtract(instrumentsClassificationsControllerV1Api.retrieveAllClassifications()),
	});

	const autoCompleteClassificationOptions = useMemo(() => {
		const { data } = queryUserClassifications;
		if (!data) {
			return [];
		}

		const options = columnsToOption(
			props.templateVariant === "current"
				? nonCustomAvailableCompositionColumns
				: nonCustomAvailableEnhanceCompositionColumns,
		);

		return [
			...options,
			...data.flatMap((classification): OptionWithChildren<string>[] => {
				return [
					{
						label: classification.name ?? "-",
						value: classification.classificationUuid!,
					},
				];
			}),
		].filter((opt) => !columnPreferences.find((c) => c.preferenceType.classificationUuid === opt.value));
	}, [columnPreferences, props.templateVariant, queryUserClassifications]);

	const classificationsMap = useMemo<Map<string, UserInstrumentClassificationDto>>(() => {
		const { data } = queryUserClassifications;
		if (!data) {
			return Map();
		}
		const baseColumns =
			props.templateVariant === "current"
				? nonCustomAvailableCompositionColumns
				: nonCustomAvailableEnhanceCompositionColumns;

		const baseClassifications = baseColumns.map((x): [string, UserInstrumentClassificationDto] => [
			x,
			{ classificationUuid: x.toString(), name: columnToLabel[x] },
		]);

		const userClassificationsTuples = data.map((x): [string, UserInstrumentClassificationDto] => [
			x.classificationUuid!,
			x,
		]);

		return Map([...baseClassifications, ...userClassificationsTuples]);
	}, [props.templateVariant, queryUserClassifications]);

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await handleSubmit((payload) => {
					props.onChange({ id, kind, ...payload });
				})();
			}}
			onCancel={props.onCancel}
		>
			<div>
				<Label htmlFor="" classList="mb-px">
					Add columns
				</Label>
				<Autocomplete
					value={null}
					options={autoCompleteClassificationOptions}
					handleRef={autocompleteHandleRef}
					onChange={(classificationUuid) => {
						const classification = classificationsMap.get(classificationUuid);
						if (classification) {
							classificationController.append({
								enabled: true,
								preferenceType: {
									standardClassificationId: classification.standardClassificationId,
									classificationUuid: classification.classificationUuid!,
									label: classification.name!,
								},
							});
						}
						autocompleteHandleRef.current?.clear();
					}}
					classList="mb-4"
					size="small"
				>
					<Text as="div" type="Body/S/Medium" classList="py-2 px-2.5">
						{autoCompleteClassificationOptions.length > 0 ? "Select an option" : "No more columns available"}
					</Text>
				</Autocomplete>
				<DraggableList
					items={columnPreferences}
					itemKey={(item) => item.preferenceType.classificationUuid}
					onReorder={(items) => classificationController.replace(items)}
				>
					{({ dragHandleProps, isDragging, draggableProps, item, innerRef, index }) => (
						<div
							style={{ borderColor: themeCSSVars.palette_N50 }}
							className={toClassName({
								"relative flex flex-row items-center min-w-0 bg-white transition-shadow border-b gap-2 px-2 h-8": true,
								"shadow-lg": isDragging,
								"border-t": isDragging,
							})}
							{...draggableProps}
							ref={innerRef}
						>
							<div {...dragHandleProps} className={toClassName({ invisible: !draggableProps || !dragHandleProps })}>
								<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M6.04181 3.2944L7.96096 1.37506C8.01677 1.31944 8.10704 1.31944 8.16286 1.37506L10.082 3.2944C10.1228 3.3352 10.1349 3.3966 10.113 3.44991C10.0909 3.50323 10.0389 3.53807 9.98115 3.53807H6.14267C6.08493 3.53807 6.03277 3.50323 6.01083 3.44991C5.98888 3.3966 6.00101 3.3352 6.04181 3.2944ZM8.16305 14.8297C8.10724 14.8853 8.01697 14.8853 7.96115 14.8297L6.04181 12.9103C6.00101 12.8695 5.98888 12.8081 6.01083 12.7548C6.03296 12.7015 6.08512 12.6667 6.14286 12.6667H9.98134C10.0391 12.6667 10.091 12.7015 10.1132 12.7548C10.1351 12.8081 10.123 12.8695 10.0822 12.9103L8.16305 14.8297ZM14.3446 6.00001H1.97822C1.62186 6.00001 1.33337 6.29415 1.33337 6.65052C1.33337 7.00688 1.33337 7.30102 1.97822 7.30102H14.3333C14.701 7.30102 14.9895 7.01254 14.9895 6.65052C14.9895 6.28849 14.701 6.00001 14.3446 6.00001ZM1.97822 9.33334H14.3446C14.701 9.33334 14.9895 9.62183 14.9895 9.98385C14.9895 10.3459 14.701 10.6344 14.3333 10.6344H1.97822C1.33337 10.6344 1.33337 10.3402 1.33337 9.98385C1.33337 9.62748 1.62186 9.33334 1.97822 9.33334Z"
										fill="#8792AB"
									/>
								</svg>
							</div>
							<Text type="Body/M/Book">{item.preferenceType.label}</Text>
							<TinyIconButton
								icon="Delete"
								onClick={() => classificationController.remove(index)}
								classList="ml-auto"
								color={themeCSSVars.palette_P400}
							/>
						</div>
					)}
				</DraggableList>
			</div>
		</SideBarContent>
	);
}

export function PerformanceAttribution(
	props: SetConfigurationReportTemplateHelper<"performanceAttribution">,
): JSX.Element {
	const { kind, id, ...opts } = props.configuration;

	const { t } = useTranslation();
	const { control, handleSubmit, formState } = useForm({
		defaultValues: opts,
	});

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<div>
				<p className="mb-4 font-semibold">Select the elements you want to display</p>
				<ForEach collection={typedObjectKeys(opts)}>
					{({ item: key }) => (
						<div>
							<FormFields.Checkbox
								control={control}
								formState={formState}
								name={key}
								classList="mb-1"
								data-qualifier={qualifier.reportCustomisation.modules.performanceAttribution.horizion(key)}
							>
								{t(`FORECAST_HORIZON.${key}`)}
							</FormFields.Checkbox>
						</div>
					)}
				</ForEach>
			</div>
		</SideBarContent>
	);
}

export function PerformanceAndVolatilityContribution(
	props: SetConfigurationReportTemplateHelper<"performanceAndVolatilityContribution">,
): JSX.Element {
	const { kind, id, ...opts } = props.configuration;

	const { t } = useTranslation();
	const { control, handleSubmit, formState } = useForm({
		defaultValues: opts,
	});

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<div className="mb-4">
				<FormFields.Checkbox
					control={control}
					formState={formState}
					name="filterSignificant"
					classList="mb-1"
					data-qualifier={
						qualifier.reportCustomisation.modules.performanceAndVolatilityContribution.toggleSignificantValueOnly
					}
				>
					Significant values only
				</FormFields.Checkbox>
			</div>

			<div>
				<p className="mb-4 font-semibold">Select the elements you want to display</p>
				<ForEach collection={typedObjectKeys(opts.versus)}>
					{({ item: key }) => (
						<div>
							<FormFields.Checkbox
								control={control}
								formState={formState}
								name={`versus.${key}`}
								classList="mb-1"
								data-qualifier={qualifier.reportCustomisation.modules.performanceAndVolatilityContribution.views(key)}
							>
								{t(`VERSUS.${key}`)}
							</FormFields.Checkbox>
						</div>
					)}
				</ForEach>
			</div>
		</SideBarContent>
	);
}

export function ExAnteContributionVolatility(
	props: SetConfigurationReportTemplateHelper<"exAnteContributionVolatility">,
): JSX.Element {
	const { kind, id, ...opts } = props.configuration;

	const { t } = useTranslation();
	const { control, handleSubmit, formState } = useForm({
		defaultValues: opts,
	});

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<div className="mb-4">
				<FormFields.Checkbox
					control={control}
					formState={formState}
					name="filterSignificant"
					classList="mb-1"
					data-qualifier={qualifier.reportCustomisation.modules.exAnteContributionVolatility.toggleSignificantValueOnly}
				>
					Significant values only
				</FormFields.Checkbox>
			</div>

			<div>
				<p className="mb-4 font-semibold">Select the elements you want to display</p>
				<ForEach collection={typedObjectKeys(opts.versus)}>
					{({ item: key }) => (
						<div>
							<FormFields.Checkbox
								control={control}
								formState={formState}
								name={`versus.${key}`}
								classList="mb-1"
								data-qualifier={qualifier.reportCustomisation.modules.exAnteContributionVolatility.views(key)}
							>
								{t(`VERSUS.${key}`)}
							</FormFields.Checkbox>
						</div>
					)}
				</ForEach>
			</div>
		</SideBarContent>
	);
}

export function FactorExposure(props: SetConfigurationReportTemplateHelper<"factorExposure">): JSX.Element {
	const { kind, id, ...opts } = props.configuration;

	const { control, handleSubmit, formState } = useForm({
		defaultValues: opts,
	});

	const { t } = useTranslation();

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<div>
				<p className="mb-4 font-semibold">Select the elements you want to display</p>
				<ForEach collection={typedObjectKeys(opts)}>
					{({ item: key }) => (
						<div>
							<FormFields.Checkbox
								control={control}
								formState={formState}
								name={key}
								classList="mb-1"
								data-qualier={qualifier.reportCustomisation.modules.factorExposure.factors(key)}
							>
								{t(`REPORT_COMPONENTS.FACTORSEXPOSURE.${key}`)}
							</FormFields.Checkbox>
						</div>
					)}
				</ForEach>
			</div>
		</SideBarContent>
	);
}

const defaultVersusOption = [
	"MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS",
	"MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY",
	"MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY",
	"MACRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY",
	"MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY",
	"MICRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY",
	"CURRENCY",
] satisfies InvestmentExposureResponseExposureTypeEnum[];
export function Exposure(props: SetConfigurationReportTemplateHelper<"exposure">): JSX.Element {
	const { kind, id, selectedCategories: initialSelectedCategories } = props.configuration;
	const { t } = useTranslation();
	const [selectedCategories, setSelectedCategories] = useState(Map(initialSelectedCategories.map((x) => [x.value, x])));

	const instrumentsClassificationsControllerV1Api = useApiGen(InstrumentsClassificationsControllerV1ApiFactory);
	const queryUserClassifications = useQueryNoRefetch(["queryUserClassifications"], {
		queryFn() {
			return axiosExtract(instrumentsClassificationsControllerV1Api.retrieveAllClassifications());
		},
	});

	const tagClassifications = useMemo(() => {
		const classifications = queryUserClassifications.data;
		if (!classifications) {
			return [];
		}

		return classifications.filter((classification) => classification.fieldType === "TAG");
	}, [queryUserClassifications.data]);

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={() => {
				props.onChange({ id, kind, selectedCategories: selectedCategories.valueSeq().toArray() });
			}}
			onCancel={props.onCancel}
		>
			<div>
				<Text type="Body/M/Bold" classList="mb-4" as="p">
					Select exposure charts you want to display
				</Text>

				<Text type="Body/M/Medium" classList="mb-2" as="p">
					Risk model
				</Text>

				<ForEach collection={defaultVersusOption}>
					{({ item: key }) => (
						<div>
							<Checkbox
								checked={selectedCategories.has(key)}
								onChange={() => {
									if (selectedCategories.has(key)) {
										setSelectedCategories((latest) => latest.remove(key));
										return;
									}

									setSelectedCategories((latest) =>
										latest.set(key, {
											label: key,
											type: "RISK_MODEL",
											value: key,
										}),
									);
								}}
							>
								{t(`VERSUS.${key}`)}
							</Checkbox>
						</div>
					)}
				</ForEach>

				<Text type="Body/M/Medium" classList="mt-4 mb-2" as="p">
					Tag
				</Text>
				<ForEach collection={tagClassifications}>
					{({ item }) => (
						<div>
							<Checkbox
								checked={selectedCategories.has(item.classificationUuid!)}
								onChange={() => {
									if (selectedCategories.has(item.classificationUuid!)) {
										setSelectedCategories((latest) => latest.remove(item.classificationUuid!));
										return;
									}

									setSelectedCategories((latest) =>
										latest.set(item.classificationUuid!, {
											label: item.name ?? "untitled",
											type: "TAG",
											value: item.classificationUuid!,
										}),
									);
								}}
							>
								{item.name}
							</Checkbox>
						</div>
					)}
				</ForEach>
			</div>
		</SideBarContent>
	);
}
