import type { WidgetConfiguration } from "$root/api/api-gen";
import { PageConfigurationsControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import env from "$root/env";
import { useUserValue } from "$root/functional-areas/user";
import { createAtom } from "$root/third-party-integrations/zustand";
import { parallelize, useQueryNoRefetch } from "$root/utils";
import type { WidgetMapperProps } from "$root/widgets-architecture/layout/WidgetsMapper";
import WidgetsMapper, { widgetMap } from "$root/widgets-architecture/layout/WidgetsMapper";
import { ProgressBar } from "@mdotm/mdotui/components";
import { exclude } from "@mdotm/mdotui/utils";
import { useMutation } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import type { Layout } from "react-grid-layout";
import { Responsive, WidthProvider } from "react-grid-layout";
import { useTranslation } from "react-i18next";
import type { EnhancedPageWidgetsConfiguration } from "./default-grids";
import { defaultWidgetsGridConfig } from "./default-grids";
import { WidgetsGridContext } from "./widgets-grid-context";
import "./WidgetsGrid.scss";

//@ts-ignore actually exported
import { compact } from "react-grid-layout/build/utils";

const ResponsiveWidgetsGrid = WidthProvider(Responsive);

export type WidgetsGridProps = {
	gridName: keyof typeof defaultWidgetsGridConfig;
	isDraggable?: boolean;
};

const useWidgetsGridConfigsAtom = createAtom<null | typeof defaultWidgetsGridConfig>(null);

const XXS_COL_NUMBER = env.featureFlags.widgets.grid.responsive ? 1 : 3;
const XS_COL_NUMBER = env.featureFlags.widgets.grid.responsive ? 1 : 3;
const SM_COL_NUMBER = env.featureFlags.widgets.grid.responsive ? 1 : 3;
const MD_COL_NUMBER = env.featureFlags.widgets.grid.responsive ? 2 : 3;
const LG_COL_NUMBER = env.featureFlags.widgets.grid.responsive ? 3 : 3;

export function reconcileGridConfigs<
	TLocal extends Record<string, EnhancedPageWidgetsConfiguration>,
	TRemote extends Record<string, EnhancedPageWidgetsConfiguration>,
	TFallback extends Record<string, EnhancedPageWidgetsConfiguration>,
>({
	localGridConfigs,
	remoteGridConfigs,
	fallbackGridConfigs,
}: {
	remoteGridConfigs: TRemote;
	localGridConfigs: TLocal;
	fallbackGridConfigs: TFallback;
}): TFallback {
	const reconciledGridConfigs: {
		[k: string]: EnhancedPageWidgetsConfiguration;
	} = {};
	for (const knownGridName of Object.keys(fallbackGridConfigs)) {
		const fallbackConfig = fallbackGridConfigs[knownGridName];
		if (!fallbackConfig) {
			// eslint-disable-next-line no-continue
			continue;
		}
		const allPossibleConfigs = [
			// Order by priority (0 = max)
			localGridConfigs[knownGridName],
			remoteGridConfigs[knownGridName],
			fallbackConfig,
		].filter(Boolean) as EnhancedPageWidgetsConfiguration[];
		const latestSupportedVersion = Math.min(
			Number(fallbackConfig.version),
			Math.max(...allPossibleConfigs.map((x) => Number(x.version ?? "0"))),
		);
		const firstWithLatestVersion = allPossibleConfigs.find((x) => Number(x.version ?? "0") === latestSupportedVersion);

		reconciledGridConfigs[knownGridName] = firstWithLatestVersion ?? fallbackConfig;
	}
	return reconciledGridConfigs as TFallback;
}

function sanitizedGridConfigs<T extends Record<string, EnhancedPageWidgetsConfiguration>>(gridConfigs: T): T {
	const allowedWidgets = Object.keys(widgetMap);
	return Object.fromEntries(
		Object.entries(gridConfigs)
			.filter(([, config]) => config !== undefined)
			.map(([key, config]) => [
				key,
				{ ...config, configuration: config.configuration?.filter((x) => x.i && allowedWidgets.includes(x.i)) },
			]),
	) as T;
}

const WidgetsGrid: React.FC<WidgetsGridProps> = ({ gridName, isDraggable }) => {
	const pageWidgetsConfiguration = useApiGen(PageConfigurationsControllerApiFactory);

	const { value: widgetsGridConfigs, set: setWidgetsGridConfigs } = useWidgetsGridConfigsAtom();

	const persistLayoutMutation = useMutation({
		mutationKey: ["persistLayoutMutation"],
		mutationFn: async ({
			page,
			version,
			configuration,
		}: {
			page: string;
			version: string;
			configuration: WidgetConfiguration[];
		}) => {
			await pageWidgetsConfiguration
				.pageWidgetsConfigurationsControllerPutConfiguration({
					page,
					version,
					configuration,
				})
				.catch(console.warn);
		},
	});

	const gridQuery = useQueryNoRefetch(["widgetsGrid"], {
		queryFn: () =>
			parallelize(
				Object.keys(defaultWidgetsGridConfig).map(
					(grid) => () =>
						pageWidgetsConfiguration.pageWidgetsConfigurationsControllerGetPageConfiguration(grid).then(
							(response) => [grid, response.data] as [string, EnhancedPageWidgetsConfiguration],
							(err) => {
								console.warn(err);
								return [grid, null];
							},
						),
				),
				{ concurrency: 5 },
			).then(
				(keyValuePairs) =>
					Object.fromEntries(keyValuePairs) as {
						[k: string]: EnhancedPageWidgetsConfiguration;
					},
			),
		onSuccess(remoteGridConfigs) {
			const localGridConfigs: {
				[k: string]: EnhancedPageWidgetsConfiguration;
			} = JSON.parse(localStorage.getItem("WidgetsGrid_localGridConfigs") || "{}");
			setWidgetsGridConfigs(
				sanitizedGridConfigs(
					reconcileGridConfigs({
						localGridConfigs,
						remoteGridConfigs,
						fallbackGridConfigs: defaultWidgetsGridConfig as Record<string, EnhancedPageWidgetsConfiguration>,
					}),
				) as typeof defaultWidgetsGridConfig,
			);
		},
	});

	const [draggingItem, setDraggingItem] = useState<string | null>(null);

	const { t } = useTranslation();

	const saveLayout = useCallback(
		(newLayout: Layout[] | ((prev: Layout[]) => Layout[])) => {
			setWidgetsGridConfigs((prev) => {
				const newPageConfig = {
					page: gridName,
					version: defaultWidgetsGridConfig[gridName].version,
					configuration: compact(
						compact(
							typeof newLayout === "function" ? newLayout(prev![gridName].configuration as Layout[]) : newLayout,
							"horizontal",
							LG_COL_NUMBER,
						),
						"vertical",
						LG_COL_NUMBER,
					),
				};
				const next: typeof prev = {
					...(prev ?? defaultWidgetsGridConfig),
					[gridName]: newPageConfig,
				};
				localStorage.setItem("WidgetsGrid_localGridConfigs", JSON.stringify(next));
				persistLayoutMutation.mutate(newPageConfig);
				return next;
			});
		},
		[gridName, persistLayoutMutation, setWidgetsGridConfigs],
	);

	const user = useUserValue();

	return (
		<>
			{gridQuery.isError ? (
				t("SOMETHING_WENT_WRONG")
			) : !widgetsGridConfigs ? (
				<ProgressBar value="indeterminate" />
			) : (
				<WidgetsGridContext.Provider value={{ draggingItem, isDraggable }}>
					<div data-widget-grid-name={gridName}>
						<ResponsiveWidgetsGrid
							style={{ overflow: "hidden" }}
							draggableHandle=".draggable-handle"
							layouts={{
								lg: widgetsGridConfigs[gridName].configuration.map(
									(config) =>
										exclude(config as EnhancedPageWidgetsConfiguration["configuration"][number], ["hidden"]) as Layout,
								),
								md: widgetsGridConfigs[gridName].configuration.map(
									(config) =>
										exclude(config as EnhancedPageWidgetsConfiguration["configuration"][number], ["hidden"]) as Layout,
								),
								sm: widgetsGridConfigs[gridName].configuration.map(
									(config) =>
										exclude(config as EnhancedPageWidgetsConfiguration["configuration"][number], ["hidden"]) as Layout,
								),
								xs: widgetsGridConfigs[gridName].configuration.map(
									(config) =>
										exclude(config as EnhancedPageWidgetsConfiguration["configuration"][number], ["hidden"]) as Layout,
								),
								xxs: widgetsGridConfigs[gridName].configuration.map(
									(config) =>
										exclude(config as EnhancedPageWidgetsConfiguration["configuration"][number], ["hidden"]) as Layout,
								),
							}}
							cols={{ lg: LG_COL_NUMBER, md: MD_COL_NUMBER, sm: SM_COL_NUMBER, xs: XS_COL_NUMBER, xxs: XXS_COL_NUMBER }}
							rowHeight={240}
							width={420}
							isResizable={false}
							containerPadding={[0, 0]}
							compactType="horizontal"
							verticalCompact
							onDragStop={(newLayout) => {
								setDraggingItem(null);
								saveLayout(newLayout);
							}}
							onDrag={(_, { i }) => setDraggingItem(i)}
							isDraggable={typeof isDraggable === "boolean" ? isDraggable : true}
						>
							{(widgetsGridConfigs[gridName].configuration as EnhancedPageWidgetsConfiguration["configuration"])
								.filter(({ hidden, guard }) => !hidden && (!guard || guard({ user })))
								.map(({ i, w }) => (
									<WidgetsMapper
										key={i}
										blockWidth={w}
										onBlockWidthChange={(newWidth) =>
											saveLayout((prev) => {
												const next = prev.slice();
												const index = next.findIndex((x) => x.i === i);
												next[index] = { ...next[index], w: newWidth };
												return next;
											})
										}
										widgetName={i as WidgetMapperProps["widgetName"]}
									/>
								))}
						</ResponsiveWidgetsGrid>
					</div>
				</WidgetsGridContext.Provider>
			)}
		</>
	);
};

export default WidgetsGrid;
