import type { InvestmentsReportTemplate } from "$root/api/api-gen";
import { InvestmentsReportTemplateControllerApiFactory } from "$root/api/api-gen";
import { getApiGen } from "$root/api/factory";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { defaultPortfolioTemplates } from "./default-templates";

export const reportRepository = {
	async fetchAll(): Promise<Array<InvestmentsReportTemplate>> {
		const investmentsReportTemplateApi = getApiGen(InvestmentsReportTemplateControllerApiFactory);
		const defaults = [
			...defaultPortfolioTemplates.map((defaultTemplate) => ({
				...defaultTemplate,
				visible: true /* TODO: visible should come from a customer-based API */,
			})),
		];
		const list = await axiosExtract(investmentsReportTemplateApi.listInvestmentReportTemplates());
		return [...defaults.filter((x) => !list.find((y) => x.templateName === y.templateName)), ...list];
	},
};
