import { routesMap, typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";
import { useSidebarEntries } from "$root/components/PlatformRouter/sidebar";
import { useUserValue } from "$root/functional-areas/user";
import { useMemo } from "react";
import { Redirect } from "react-router";

export function IndexRedirect(): JSX.Element {
	const user = useUserValue();
	const currentSidebarEntries = useSidebarEntries();
	const firstAvailableRoute = currentSidebarEntries
		.find((x) => x.name)
		?.submenuEntries?.find((subMenus) => subMenus.url)?.url;

	const routeName = useMemo(
		() =>
			([routesMap.PortfoliosStudio, routesMap.MarketDashboard, routesMap.RegimeAnalysisTool] as const).find(
				(route) => route.guard({ user }).passed,
			)?.name,
		[user],
	);

	return (
		<>
			{routeName ? (
				<Redirect to={typedUrlForRoute(routeName, {})} />
			) : firstAvailableRoute ? (
				<Redirect to={firstAvailableRoute} />
			) : (
				"No default service available"
			)}
		</>
	);
}

export default IndexRedirect;
