import { IconWalls } from "$root/components/IconWall";
import type { useInstrumentColumnPreferenceProps, UserColumnMetadata } from "$root/functional-areas/instruments/hooks";
import { useGroupedInstrumentsColumn } from "$root/functional-areas/instruments/hooks";
import { useLocaleFormatters } from "$root/localization/hooks";
import type { GroupedCompositionRow } from "$root/pages/PortfolioDetails/PortfolioComposition/columns";
import {
	createTagGroupedColumn,
	useGroupedCompositionColumn,
	useGroupedCompositionRows,
} from "$root/pages/PortfolioDetails/PortfolioComposition/columns";
import type { TableWithGroupsColumn, TableWithGroupsProps } from "@mdotm/mdotui/components";
import {
	AutoSortHScrollTableWithGroups,
	BatchActions,
	useSelectableTableWithGroupColumn,
} from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";
import { useCallback, useMemo } from "react";
import {
	normaliseAndVerifyInstrumentPortfolioComposition,
	useCompositionToolBar,
	verifySomeInstrumentsAreExcluded,
} from "../../builder";
import { useGroupedInstrumentCompositionColumns } from "../../columns";
import type { InstrumentEditorEntry } from "../../const";
import type { InstrumentCompositionEditorProps } from "../../instrumentEditor";
import InstrumentLimitReachedBanner from "../InstrumentLimitReachedBanner";
import NormaliseAndExcessToCashBanner from "../NormaliseAndExcessToCashBanner";
import NormalisedInvestmentInstrumentBanner from "../NormalisedInvestmentInstrumentBanner";

type DefaultInstrumentTableProps = InstrumentCompositionEditorProps &
	useInstrumentColumnPreferenceProps & {
		filteredRows: InstrumentEditorEntry[];
		rows: InstrumentEditorEntry[];
		groupByClassificationUuid: string;
		columnsMetadata: Array<UserColumnMetadata>;
	};

function GroupedInstrumentBaseTable({
	instrumentBuilder,
	filteredRows,
	rows,
	entity,
	limit,
	groupByClassificationUuid,
	applyColumnPreferenceApi,
	columnsMetadata,
}: DefaultInstrumentTableProps): JSX.Element {
	const toolbar = useCompositionToolBar();
	const groupedInstrumentCompositionColumns = useGroupedInstrumentCompositionColumns();
	const groupedCompositionColumns = useGroupedCompositionColumn();
	const groupedRows = useGroupedCompositionRows(rows, groupByClassificationUuid);
	const filteredGroupedRows = useGroupedCompositionRows(filteredRows, groupByClassificationUuid);

	const {
		customizableColumns: groupedInstrumentColumns,
		tools,
		name,
	} = useGroupedInstrumentsColumn({
		allColumns: columnsMetadata,
		mode: "VIEW",
		changeTableLayout: applyColumnPreferenceApi,
	});

	const selectedClassification = useMemo(
		() => columnsMetadata.find((x) => x.classificationUuid === groupByClassificationUuid)!,
		[columnsMetadata, groupByClassificationUuid],
	);

	const columnsWithoutSelectedClassification = useMemo(
		() =>
			groupedInstrumentColumns.flatMap((column) => {
				if (column.name === selectedClassification.classificationUuid) {
					return [];
				}

				return [column];
			}),
		[groupedInstrumentColumns, selectedClassification.classificationUuid],
	);

	const nameColumn = useMemo(() => {
		const {
			groupContent: _groupContent,
			sortFn: _sortFn,
			...rest
		} = groupedInstrumentCompositionColumns.name({
			onCompare(row, action) {
				if (action === "add") {
					instrumentBuilder.setInstrumentToCompare([row]);
					return;
				}

				instrumentBuilder.deleteComparedInstruments(row.rowId);
			},
			isRowCompared(rowId) {
				return instrumentBuilder.getComparedInstrument(rowId) !== undefined;
			},
		});

		const { groupContent } = createTagGroupedColumn(selectedClassification);

		return {
			...name,
			...rest,
			minWidth: 460,
			maxWidth: 560,
			groupContent,
			sortFn: builtInSortFnFor("groupKey"),
			orderable: true,
		} satisfies TableWithGroupsColumn<(typeof groupedRows)[number]>;
	}, [groupedInstrumentCompositionColumns, instrumentBuilder, name, selectedClassification]);

	const { formatNumber } = useLocaleFormatters();

	const selectableTableColumn = useSelectableTableWithGroupColumn({
		groupedRows,
		filteredGroupedRows,
		selectBy: (r) => r.rowId,
	});

	const totalWeight = useMemo(() => {
		if (rows.length > 0) {
			return `${formatNumber(instrumentBuilder.getTotal("weight"))}%`;
		}
		return undefined;
	}, [formatNumber, instrumentBuilder, rows.length]);

	const deleteColumn = useMemo<TableWithGroupsColumn<GroupedCompositionRow<InstrumentEditorEntry>>>(
		() => ({
			...groupedInstrumentCompositionColumns.deleteRestore({
				deleted: instrumentBuilder.watchDeleted(),
				onChange: (row, deleteSet) => instrumentBuilder.delete("soft", row.rowId, deleteSet.has(row.rowId)),
			}),
			header: tools.header,
		}),
		[groupedInstrumentCompositionColumns, instrumentBuilder, tools.header],
	);

	const weightColumn = useMemo<TableWithGroupsColumn<GroupedCompositionRow<InstrumentEditorEntry>>>(() => {
		const { groupContent, sortFn, align, ...rest } = groupedInstrumentCompositionColumns.weight({
			onChangeWeight: (rowId, v) => instrumentBuilder.setWeight(rowId, v ?? undefined),
			isInstrumentDeleted: (rowId) => instrumentBuilder.isInstrumentDeleted(rowId),
			onCheckIsMinimumWeightValid: async (row, weight) => {
				const instrumentsExcluded = await verifySomeInstrumentsAreExcluded(
					{ ...row, weight: weight ?? undefined },
					entity,
				);
				instrumentBuilder.setInstrument(row.rowId, {
					...row,
					weight: weight ?? undefined,
					someInstrumentsAreExcluded: instrumentsExcluded.length > 0,
					nOfInstrumentExcluded: instrumentsExcluded.length,
				});
			},
			weightProvider: (rowId) => instrumentBuilder.getWeight(rowId),
		});

		return {
			...rest,
			align: "end",
			groupContent(group) {
				return `${formatNumber(group.weight, 2)}%`;
			},
			sortFn: builtInSortFnFor("weight"),
			footerCellClassList: "font-semibold justify-end",
			footer: totalWeight,
			minWidth: 130,
		};
	}, [entity, formatNumber, groupedInstrumentCompositionColumns, instrumentBuilder, totalWeight]);

	const columns = useCallback<TableWithGroupsProps<GroupedCompositionRow<InstrumentEditorEntry>>["columns"]>(
		({ bandColumn, expandColumn }) => [
			/**bandColumn idk*/
			expandColumn,
			selectableTableColumn.column,
			nameColumn,
			...columnsWithoutSelectedClassification,
			groupedCompositionColumns.CURRENT_WEIGHT(),
			groupedCompositionColumns.ENHANCED_WEIGHT(),
			groupedCompositionColumns.DIFFERENCE(),
			weightColumn,
			deleteColumn,
		],
		[
			selectableTableColumn.column,
			nameColumn,
			columnsWithoutSelectedClassification,
			groupedCompositionColumns,
			weightColumn,
			deleteColumn,
		],
	);

	const actions = useMemo(
		() => [
			toolbar.softDelete({
				instrumentBuilder,
				selected: selectableTableColumn.multiSelectCtx,
			}),
			toolbar.restoreDeleted({
				instrumentBuilder,
				selected: selectableTableColumn.multiSelectCtx,
			}),
			toolbar.compare({
				instrumentBuilder,
				selected: selectableTableColumn.multiSelectCtx,
			}),
		],
		[instrumentBuilder, selectableTableColumn.multiSelectCtx, toolbar],
	);

	return (
		<>
			<BatchActions
				total={rows.length}
				selected={selectableTableColumn.multiSelectCtx.selection.size}
				palette="tertiary"
				actions={actions}
				classList="py-2 h-[58px] shrink-0"
			/>
			<NormalisedInvestmentInstrumentBanner
				entity={entity}
				someInvestmentsHasExcludeInstrument={rows.some((instrument) => instrument.someInstrumentsAreExcluded)}
			/>
			<AutoSortHScrollTableWithGroups
				columns={columns}
				groupedRows={filteredGroupedRows}
				groupRowKey={(row) => row.groupKey ?? ""}
				pinnedColumns={[
					{ name: nameColumn.name, side: "left" },
					{ name: weightColumn.name, side: "right" },
					{ name: deleteColumn.name, side: "right" },
				]}
				noDataText={<IconWalls.EditorEmptyData entity={entity} />}
				onRowClick={(row) => selectableTableColumn.toggle(row.rowId)}
				rowStyle={(row) => {
					if (row.someInstrumentsAreExcluded) {
						return { backgroundColor: themeCSSVars.global_palette_warning_100 };
					}

					if (instrumentBuilder.isInstrumentDeleted(row.rowId)) {
						return { backgroundColor: themeCSSVars.Table_highlightedRowBackgroundColor };
					}
				}}
				rowClassList={(row) => {
					if (instrumentBuilder.isInstrumentDeleted(row.rowId)) {
						return "line-through";
					}
				}}
				// groupColor={(row) => stableColorGenerator(row.assetClass)}
				// rowColor={(row) =>
				// 	Color(stableColorGenerator(provideAssetClass(row)))
				// 		.alpha(0.4)
				// 		.toString()
				// }
				palette="uniform"
			/>
			<br />
			{limit && rows.length > limit && <InstrumentLimitReachedBanner limit={limit} />}
			<br />
			<NormaliseAndExcessToCashBanner
				disableNormalise={selectableTableColumn.multiSelectCtx.selection.size === 0}
				disableNormaliseTooltip={selectableTableColumn.multiSelectCtx.selection.size > 0}
				entity={entity}
				instrumentBuilder={instrumentBuilder}
				onAsyncNormalise={() =>
					normaliseAndVerifyInstrumentPortfolioComposition({
						instrumentBuilder,
						entity,
						selection: selectableTableColumn.multiSelectCtx.selection,
					})
				}
				onExcessToCash={() => instrumentBuilder.excessToCash()}
			/>
		</>
	);
}

export default GroupedInstrumentBaseTable;
