import type {
	AvailableEntitiesResponse,
	AvailableFieldsResponse,
	FieldKey,
	FieldSetResponse,
	FieldType,
	Filter,
	FilterResponse,
	FilterValues,
	MetricEntityType,
	MetricParameters,
	MetricResponse,
	MetricValues,
	MetricValueTaxonomy,
	MultiPresetResponse,
	PresetResponse,
	ReferenceEntityValues,
	RiskModelExposureType,
	RiskModelExposureTypesResponse,
	UserInstrumentClassificationDto,
} from "$root/api/api-gen";
import { MetricOrdering, MetricReferenceType, MetricThresholdType } from "$root/api/api-gen";
import { DebouncedSearchInput } from "$root/components/DebouncedSearchInput";
import { HierarchicalOption, HierarchicalSelect } from "$root/components/HierarchicalSelect";
import ReactQueryWrapper, { ReactQueriesWrapperBase, ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import Separator from "$root/components/Separator";
import { SideDrawer } from "$root/components/SideDrawer";
import { spawnCreateDialog } from "$root/components/spawnable/entity-management/create-dialog";
import { platformToast } from "$root/notification-system/toast";
import { useQueryNoRefetch, noRefetchDefaults } from "$root/utils";
import { queryClientKeys } from "$root/third-party-integrations/react-query";
import { days, minutes } from "$root/utils/time";
import type { Option, OptionWithOptionalGroup } from "@mdotm/mdotui/components";
import {
	AsyncButton,
	Button,
	ButtonGroupRadio,
	CircularProgressBar,
	Controller,
	DatePickerInput,
	FormField,
	Icon,
	NullableNumberInput,
	Select,
	TinyIconButton,
	Transition,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import type { ImperativeHandleRefProps } from "@mdotm/mdotui/react-extensions";
import {
	ForEach,
	generateUniqueDOMId,
	overrideClassName,
	useDeepEqualEffect,
	useRefProxyWithState,
} from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSort, groupBy, noop, unpromisify } from "@mdotm/mdotui/utils";
import { useQueries } from "@tanstack/react-query";
import { Map, OrderedMap } from "immutable";
import { useCallback, useImperativeHandle, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

export const extendedTabWidth = 464;
export const smallTabWidth = 24;

function handleMapTruthy<K, T>(map?: Map<K, T>): Map<K, T> {
	return map ?? Map();
}

type keyMetricThresholdMapType =
	| {
			thresholdType?: Exclude<MetricThresholdType, "MANUAL">;
			thresholdEntity?: MetricResponse["thresholdEntity"];
			thresholdValue?: MetricResponse["thresholdValue"];
	  }
	| {
			thresholdType: Extract<MetricThresholdType, "MANUAL">;
			thresholdEntity?: undefined;
			thresholdValue: MetricResponse["thresholdValue"];
	  };

export type PortfolioInsightPanelPreset = Omit<PresetResponse, "filters" | "metric"> & {
	search: string;
	filters?: Map<string, FilterResponse>;
	metric?: Omit<MetricResponse, "thresholdType" | "thresholdEntity" | "thresholdValue"> & {
		keyMetricThreshold?: Map<string, keyMetricThresholdMapType>;
	};
};

export type PortfolioInsightPanelProps = {
	expand: boolean;
	onExpandChange(): void;
	onCheckIfNameIsAvailable(name: string): Promise<boolean>;
	presetsProvider(): Promise<MultiPresetResponse>;

	selectableFieldsProvider(): Promise<AvailableFieldsResponse>;
	selectableEntitiesProvider(): Promise<AvailableEntitiesResponse>;
	selectableUserClassificationProvider(): Promise<UserInstrumentClassificationDto[]>;
	selectableExposureProvider(): Promise<RiskModelExposureTypesResponse>;

	fieldSetProvider(fieldType: FieldType): Promise<FieldSetResponse>;

	thresholdForEntityProvider(preset: NonNullable<PortfolioInsightPanelPreset["metric"]>): Promise<number | undefined>;
	onChangeFavorite(uuid: string): Promise<void>;
	onChangePreset(preset?: PortfolioInsightPanelPreset): void;
	onSaveAsNew(preset: PortfolioInsightPanelPreset, name: string): MaybePromise<void>;
	onSave(preset: PortfolioInsightPanelPreset): MaybePromise<void>;
};

const emptyInsightPanelPreset = { search: "" };
function parsePresetResponse(preset?: PresetResponse): PortfolioInsightPanelPreset {
	return preset
		? {
				...preset,
				...emptyInsightPanelPreset,
				filters: Map<string, Filter>(preset.filters?.map((x) => [generateUniqueDOMId(), x])),
				metric: {
					...preset.metric,
					keyMetricThreshold: Map<string, keyMetricThresholdMapType>([
						[
							generateUniqueDOMId(),
							preset.metric?.thresholdType === "MANUAL"
								? {
										thresholdType: preset.metric?.thresholdType,
										thresholdValue: preset.metric?.thresholdValue ?? 0,
								  }
								: {
										thresholdType: preset.metric?.thresholdType,
										thresholdEntity: preset.metric?.thresholdEntity,
								  },
						],
					]),
				},
		  }
		: emptyInsightPanelPreset;
}

export type PortfolioInsightPanelHandles = {
	setThreshold(thresholdValue?: number): void;
	setManualSelection(manualSelection?: Array<string>): void;
	setOrder(newOrder: MetricResponse["ordering"]): void;
};

function SelectPreset(props: {
	value?: string;
	presets?: PresetResponse[];
	onChangeSelection?(newPreset: PortfolioInsightPanelPreset, uuid?: string | null): void;
}) {
	const { presets, value, onChangeSelection } = props;
	const options = useMemo(() => {
		if (!presets) {
			return [];
		}
		return presets.flatMap((x): Array<Option<string>> => (x.uuid && x.name ? [{ label: x.name, value: x.uuid }] : []));
	}, [presets]);

	const presetMap = useMemo(() => {
		if (!presets) {
			return Map<string, PortfolioInsightPanelPreset | undefined>();
		}
		return Map<string, PortfolioInsightPanelPreset | undefined>(
			presets.flatMap((x) => (x.uuid ? [[x.uuid, parsePresetResponse(x)]] : [])),
		);
	}, [presets]);

	const onSelectPreset = useCallback(
		(uuid?: string | null) => {
			if (!uuid) {
				return onChangeSelection?.(emptyInsightPanelPreset, uuid);
			}
			onChangeSelection?.(presetMap.get(uuid) ?? emptyInsightPanelPreset, uuid);
		},
		[onChangeSelection, presetMap],
	);
	return (
		<Select
			data-qualifier="InsightPanel/SelectPreset/Dropdown"
			triggerDataAttrs={{
				"data-qualifier": "InsightPanel/SelectPreset/Trigger",
			}}
			options={options}
			value={value}
			onChange={onSelectPreset}
			classList="w-[150px]"
		/>
	);
}

function PortfolioInsightPanel(
	props: PortfolioInsightPanelProps & ImperativeHandleRefProps<PortfolioInsightPanelHandles>,
): JSX.Element {
	const {
		thresholdForEntityProvider,

		selectableEntitiesProvider,
		selectableFieldsProvider,
		selectableUserClassificationProvider,
		fieldSetProvider,
		selectableExposureProvider,

		presetsProvider,

		onChangePreset,
		onExpandChange,
		onSave,
		onSaveAsNew,
		onCheckIfNameIsAvailable,
		onChangeFavorite,
		handleRef,
		expand,
	} = props;

	const latestInsightPanelPresetRef = useRef<PortfolioInsightPanelPreset>(emptyInsightPanelPreset);
	const [insightPanelPreset, setInsightPanelPreset] = useRefProxyWithState<PortfolioInsightPanelPreset>(
		emptyInsightPanelPreset,
		latestInsightPanelPresetRef,
	);

	const { t } = useTranslation();
	useImperativeHandle(
		handleRef,
		() => ({
			setThreshold: (threshold) => {
				setInsightPanelPreset((preset) => {
					const key = Array.from(preset.metric?.keyMetricThreshold?.keys() ?? []).at(0);
					return {
						...preset,
						metric: {
							...preset.metric,
							keyMetricThreshold: handleMapTruthy(preset.metric?.keyMetricThreshold).set(key ?? generateUniqueDOMId(), {
								thresholdType: "MANUAL",
								thresholdValue: threshold ?? 0,
							}),
						},
					};
				});
				onChangePreset(latestInsightPanelPresetRef.current);
			},
			setManualSelection: (manualSelection) => {
				setInsightPanelPreset((preset) => {
					const presetMap = handleMapTruthy(preset.filters);
					const key = presetMap.findKey((v) => v.key === "NAME");
					return {
						...preset,
						filters: presetMap.set(key ?? generateUniqueDOMId(), {
							key: "NAME",
							type: "FINITE_SET_PORTFOLIOS",
							relation: "EQUAL",
							value: manualSelection,
						}),
					};
				});

				onChangePreset(latestInsightPanelPresetRef.current);
			},
			setOrder: (newOrder) => {
				setInsightPanelPreset((preset) => {
					if (preset.metric) {
						preset.metric.ordering = newOrder;
					}
					return preset;
				});
			},
		}),
		[onChangePreset, setInsightPanelPreset],
	);

	const queryPresets = useQueryNoRefetch([queryClientKeys.analysisBulkPresets], {
		queryFn: presetsProvider,
		staleTime: days(1),
		cacheTime: days(1),
	});

	useDeepEqualEffect(() => {
		if (queryPresets.data?.currentPreset) {
			const parsedPreset = parsePresetResponse(queryPresets.data.currentPreset);
			setInsightPanelPreset(parsedPreset);
			onChangePreset(parsedPreset);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryPresets.data?.currentPreset]);

	const queryCommonSelectableFilters = useQueryNoRefetch([queryClientKeys.analysisBulkSelectableFilters], {
		queryFn: () => selectableFieldsProvider(),
		staleTime: minutes(10),
		cacheTime: minutes(10),
	});

	const queriesCustomKeyMetrics = useQueries({
		queries: [
			{
				...noRefetchDefaults,
				queryKey: [queryClientKeys.analysisBulkSelectableEntities],
				async queryFn() {
					const selectableEntities = await selectableEntitiesProvider();
					return { selectableEntities };
				},
				staleTime: minutes(10),
				cacheTime: minutes(10),
			},
			{
				...noRefetchDefaults,
				queryKey: [queryClientKeys.instrumentCustomisationUserClassification],
				async queryFn() {
					const userClassifications = await selectableUserClassificationProvider();
					return { userClassifications };
				},
				staleTime: minutes(10),
				cacheTime: minutes(10),
			},
			{
				...noRefetchDefaults,
				queryKey: [queryClientKeys.instrumentCustomisationExposure],
				async queryFn() {
					const { assetClassCategories } = await selectableExposureProvider();
					return { assetClassCategories: assetClassCategories ?? [] };
				},
				staleTime: minutes(10),
				cacheTime: minutes(10),
			},
		],
	});

	return (
		<SideDrawer
			toggleIcon="Filter"
			toggleOffset={64}
			expand={expand}
			onExpandChange={onExpandChange}
			widths={[smallTabWidth, extendedTabWidth]}
			title="Analysis panel"
			actionHeader={
				<ReactQueryWrapperBase
					query={queryPresets}
					errorFallback={<Select value="" options={[]} disabled classList="w-[150px]" />}
					loadingFallback={<CircularProgressBar value="indeterminate" outerDiameter={16} innerDiameter={12} />}
				>
					{({ presets }) => (
						<SelectPreset
							value={insightPanelPreset.uuid}
							presets={presets}
							onChangeSelection={(newPreset) => {
								setInsightPanelPreset(newPreset);
								onChangePreset(newPreset);
								const { uuid } = newPreset;
								if (uuid) {
									unpromisify(() => onChangeFavorite(uuid))();
								}
							}}
						/>
					)}
				</ReactQueryWrapperBase>
			}
			footer={
				<div className="flex justify-between">
					<Button
						palette="tertiary"
						size="small"
						onClick={() => {
							onChangePreset(emptyInsightPanelPreset);
							setInsightPanelPreset(emptyInsightPanelPreset);
						}}
					>
						{t("BUTTON.CLEAR_ALL")}
					</Button>
					{insightPanelPreset.standard || !insightPanelPreset.uuid ? (
						<div className="flex gap-2">
							<AsyncButton
								onClickAsync={() =>
									spawnCreateDialog({
										entityType: "preset",
										onSubmitAsync: (name) => onSaveAsNew(latestInsightPanelPresetRef.current, name),
										checkIfNameIsAvailable: onCheckIfNameIsAvailable,
										placeholder: "insert a name",
									}).then(() => queryPresets.refetch())
								}
								palette="primary"
								size="small"
							>
								{t("BUTTON.SAVE_AS_NEW")}
							</AsyncButton>
						</div>
					) : (
						<div className="flex gap-2">
							<AsyncButton
								onClickAsync={() =>
									spawnCreateDialog({
										entityType: "preset",
										onSubmitAsync: (name) => onSaveAsNew(latestInsightPanelPresetRef.current, name),
										checkIfNameIsAvailable: onCheckIfNameIsAvailable,
										placeholder: "insert a name",
									}).then(() => queryPresets.refetch())
								}
								palette="secondary"
								size="small"
							>
								{t("BUTTON.SAVE_AS_NEW")}
							</AsyncButton>
							<AsyncButton
								onClickAsync={async () => {
									await onSave(latestInsightPanelPresetRef.current);
									await queryPresets.refetch();
								}}
								palette="primary"
								size="small"
							>
								{t("BUTTON.SAVE")}
							</AsyncButton>
						</div>
					)}
				</div>
			}
		>
			<div className="relative z-0">
				<Separator text="Filters" align="start" classList="mb-2" />
				<DebouncedSearchInput
					query={insightPanelPreset?.search ?? ""}
					onChange={(search) => {
						setInsightPanelPreset((preset) => ({ ...preset, search }));
						onChangePreset(latestInsightPanelPresetRef.current);
					}}
					placeholder="search portfolio by name"
					data-qualifier="InsightPanel/SearchByKeyWord"
					classList="mb-2"
				/>
				<ReactQueryWrapperBase query={queryCommonSelectableFilters}>
					{(selectable) => (
						<>
							<PortfolioInsightPanelInner
								selectable={selectable}
								preset={insightPanelPreset}
								fieldSetProvider={fieldSetProvider}
								onAddFilter={() =>
									setInsightPanelPreset((preset) => ({
										...preset,
										filters: OrderedMap(handleMapTruthy(preset?.filters).set(generateUniqueDOMId(), {})),
									}))
								}
								onChangeFilter={(id, payload) =>
									setInsightPanelPreset((preset) => ({
										...preset,
										filters: OrderedMap(handleMapTruthy(preset?.filters).set(id, payload)),
									}))
								}
								onRemoveFilter={(id) =>
									setInsightPanelPreset((preset) => ({
										...preset,
										filters: OrderedMap(handleMapTruthy(preset?.filters).remove(id)),
									}))
								}
								onCommitFilters={() => onChangePreset(latestInsightPanelPresetRef.current)}
							/>
							<Separator text="Sorting parameters" align="start" classList="mb-2" />
							<ReactQueriesWrapperBase queries={queriesCustomKeyMetrics}>
								{([{ selectableEntities }, { userClassifications }, { assetClassCategories }]) => (
									<PortfolioInsightPanelMetricInner
										selectableEntities={selectableEntities}
										userClassifications={userClassifications}
										assetClassCategories={assetClassCategories}
										selectable={selectable}
										preset={insightPanelPreset}
										onChangeMetric={(newMetric) =>
											setInsightPanelPreset((preset) => ({
												...preset,
												metric: {
													...preset?.metric,
													key: newMetric.key,
													metricParameters: newMetric.metricParameters,
												},
											}))
										}
										onChangeReferenceType={(newReferenceType) =>
											setInsightPanelPreset((preset) => ({
												...preset,
												metric: {
													...preset?.metric,
													referenceType: newReferenceType,
												},
											}))
										}
										onChangeReferenceEntity={(newReferenceEntity) =>
											setInsightPanelPreset((preset) => ({
												...preset,
												metric: {
													...preset?.metric,
													referenceEntity: newReferenceEntity,
												},
											}))
										}
										onAddKeyMetricThreshold={() =>
											setInsightPanelPreset((preset) => ({
												...preset,
												metric: {
													...preset?.metric,
													keyMetricThreshold: handleMapTruthy(preset?.metric?.keyMetricThreshold).set(
														generateUniqueDOMId(),
														{},
													),
												},
											}))
										}
										onChangeKeyMetricThreshold={(id, payload) =>
											setInsightPanelPreset((preset) => ({
												...preset,
												metric: {
													...preset?.metric,
													keyMetricThreshold: handleMapTruthy(preset?.metric?.keyMetricThreshold).set(id, payload),
												},
											}))
										}
										onRemoveKeyMetricThreshold={(id) =>
											setInsightPanelPreset((preset) => ({
												...preset,
												metric: {
													...preset?.metric,
													keyMetricThreshold: handleMapTruthy(preset?.metric?.keyMetricThreshold).remove(id),
												},
											}))
										}
										onChangeOrder={(newOrdering) => {
											setInsightPanelPreset((preset) => ({
												...preset,
												metric: {
													...preset?.metric,
													ordering: newOrdering,
												},
											}));
										}}
										onCommitKeyParameter={() => onChangePreset(latestInsightPanelPresetRef.current)}
										onCommitKeyThreshold={() => onChangePreset(latestInsightPanelPresetRef.current)}
										thresholdForEntityProvider={thresholdForEntityProvider}
									/>
								)}
							</ReactQueriesWrapperBase>
						</>
					)}
				</ReactQueryWrapperBase>
			</div>
		</SideDrawer>
	);
}

type PortfolioInsightPanelInnerProps = {
	selectable: AvailableFieldsResponse;
	preset?: PortfolioInsightPanelPreset;
	fieldSetProvider(fieldType: FieldType): Promise<FieldSetResponse>;

	onChangeFilter(id: string, payload: FilterResponse): void;
	onRemoveFilter(id: string): void;
	onAddFilter(): void;

	onCommitFilters(): void;
};

const keyMetricSelectionEntities: Array<Option<MetricThresholdType>> = [
	{
		label: "Manual",
		value: MetricThresholdType.Manual,
	},
	{
		label: "Portfolio",
		value: MetricThresholdType.Investment,
	},
	{
		label: "Target portfolio",
		value: MetricThresholdType.TargetInvestment,
	},
	{
		label: "Standard benchmark",
		value: MetricThresholdType.StandardBenchmark,
	},
	{
		label: "Custom benchmark",
		value: MetricThresholdType.CustomBenchmark,
	},
];

const allowedSelectionByKey = {
	CURRENT_ACTION: 0,
	BENCHMARK_NAME: 0,
	COMMENTARY_TEMPLATE: 0,
	CURRENCY_NAME: 0,
	REFERENCE_NAME: 0,
	UNIVERSE_NAME: 0,
	AVERAGE_SCORE: 0,
	STATUS: 0,
	NAME: 0,
	EFFICIENCY_RATIO_1M: Infinity,
	MAX_DRAWDOWN_1M: Infinity,
	SORTINO_1M: Infinity,
	VOLATILITY_1M: Infinity,
	INCEPTION_DATE: Infinity,
	LAST_STATUS_UPDATE: Infinity,
	PERFORMANCE_1M: Infinity,
	AUTO_SYNC: Infinity,
	MARKET_VIEW_NAME: Infinity,
};

function PortfolioInsightPanelInner({
	selectable,
	preset,
	onAddFilter,
	onChangeFilter,
	onCommitFilters,
	onRemoveFilter,

	fieldSetProvider,
}: PortfolioInsightPanelInnerProps): JSX.Element {
	const { metric, filters } = preset ?? {};
	const { t } = useTranslation();

	const availableFiltersMap = useMemo<Map<string, FilterValues>>(() => {
		if (!selectable.availableFilters) {
			return Map();
		}

		return Map(selectable.availableFilters.flatMap((x) => (x.key ? [[x.key, x]] : [])));
	}, [selectable.availableFilters]);

	const aggregateFilters = useMemo(
		() =>
			groupBy(
				Array.from(filters?.values() ?? []).filter(({ key }) => key),
				(x) => x.key!,
			),
		[filters],
	);

	const availableFilterKeys = useMemo<Array<Option<FieldKey>>>(() => {
		if (!selectable.availableFilters) {
			return [];
		}
		return selectable.availableFilters.flatMap((x) =>
			x.key
				? [
						{
							label: t(`METRIC_COMPARISON_CHART.${x.key}`),
							value: x.key,
							disabled:
								allowedSelectionByKey[x.key as keyof typeof allowedSelectionByKey] <
								(aggregateFilters[x.key as keyof typeof aggregateFilters]?.length ?? 0),
						},
				  ]
				: [],
		);
	}, [selectable.availableFilters, t, aggregateFilters]);

	// const availableMetricKeys = useMemo<Array<Option<FieldKey>>>(() => {
	// 	if (!selectable.availableMetrics) {
	// 		return [];
	// 	}

	// 	return selectable.availableMetrics.flatMap((x) =>
	// 		x.key
	// 			? [
	// 					{
	// 						label: t(`METRIC_COMPARISON_CHART.${x.key}`),
	// 						value: x.key,
	// 					},
	// 			  ]
	// 			: [],
	// 	);
	// }, [selectable.availableMetrics, t]);

	return (
		<>
			<div className="mb-2">
				<ForEach collection={preset?.filters?.toArray() ?? []} keyProvider={(item) => item[0]}>
					{({ item: [id, opt], index }) => (
						<Transition
							in
							initialIn={false}
							classList="transition-[opacity,transform] origin-top"
							enterFromClassList="opacity-0 scale-95"
							enterToClassList="opacity-100 scale-100"
						>
							{({ classList }) => (
								<div className={overrideClassName(classList, "flex items-center")}>
									<div className="flex gap-2 flex-1 max-w-[calc(100%_-_38px)]">
										<Select
											triggerDataAttrs={{
												"data-qualifier": `InsightPanel/Filter(${index})/Trigger`,
											}}
											data-qualifier={`InsightPanel/Filter(${index})/Dropdown`}
											options={availableFilterKeys}
											value={opt.key}
											enableSearch
											onChange={(newKey) => {
												if (newKey) {
													onChangeFilter(id, {
														key: newKey,
														type: availableFiltersMap.get(newKey)!.type!,
														relation: undefined,
														value: undefined,
													});
												}
												onCommitFilters();
											}}
											classList="w-[139px]"
										/>

										{opt.key &&
											opt.type &&
											match(opt.type)
												.with(
													"FINITE_SET_PORTFOLIOS",
													"FINITE_SET_STATUSES",
													"FINITE_SET_ACTIONS",
													"FINITE_SET_BASE_CURRENCIES",
													"FINITE_SET_UNIVERSES",
													"FINITE_SET_BENCHMARKS",
													"FINITE_SET_REFERENCES",
													"FINITE_SET_MARKET_VIEWS",
													"FINITE_SET_COMMENTARY_TEMPLATES",
													() => {
														return (
															<ReactQueryWrapper
																queryKey={["queryFieldSetKey", opt.key, opt.type]}
																queryFn={async () => {
																	const { fieldSet } = await fieldSetProvider(opt.type!);
																	const options: OptionWithOptionalGroup<string, string>[] =
																		fieldSet
																			?.filter((x) => x.label && x.identifier)
																			.map((x) => ({
																				label: x.label!,
																				value: x.identifier!,
																			})) ?? []; // it was identifier before, change in MDOT-18036
																	return { options };
																}}
																cacheTime={minutes(10)}
																loadingFallback={
																	<CircularProgressBar style={{ width: "1rem" }} value="indeterminate" />
																}
															>
																{({ options }) => (
																	<Select
																		triggerDataAttrs={{
																			"data-qualifier": `InsightPanel/Filter(${index})/${opt.key}/Trigger`,
																		}}
																		multi
																		enableSearch
																		data-qualifier={`InsightPanel/Filter(${index})/${opt.key}/Dropdown`}
																		options={options}
																		value={opt.value ?? []}
																		onChange={(identifiers) => {
																			if (identifiers) {
																				onChangeFilter(id, {
																					...opt,
																					value: identifiers as string[],
																					relation: "EQUAL",
																				});
																				onCommitFilters();
																			}
																		}}
																		classList="w-[139px]"
																	/>
																)}
															</ReactQueryWrapper>
														);
													},
												)
												.with("BOOLEAN", () => (
													<Select
														triggerDataAttrs={{
															"data-qualifier": `InsightPanel/Filter(${index})/${opt.key}/Trigger`,
														}}
														data-qualifier={`InsightPanel/Filter(${index})/${opt.key}/Dropdown`}
														options={[
															{ label: "Yes", value: true },
															{ label: "No", value: false },
														]}
														value={opt.value ? opt.value[0] === "true" : undefined}
														onChange={(flag) => {
															onChangeFilter(id, { ...opt, value: [String(flag)], relation: "EQUAL" });
															onCommitFilters();
														}}
														classList="w-[139px]"
													/>
												))
												.with("DATE", "NUMERIC", "PERCENTAGE", (filterType) => {
													const options =
														availableFiltersMap.get(opt.key!)?.availableRelations?.flatMap((relation) =>
															relation
																? [
																		{
																			label:
																				filterType === "DATE"
																					? t(`RELATION.CALENDAR.${relation}`)
																					: t(`RELATION.${relation}`),
																			value: relation,
																		},
																  ]
																: [],
														) ?? [];
													return (
														<Select
															triggerDataAttrs={{
																"data-qualifier": `InsightPanel/Filter(${index})/${opt.key}/Trigger`,
															}}
															data-qualifier={`InsightPanel/Filter(${index})/${opt.key}/Dropdown`}
															options={options}
															value={opt.relation}
															onChange={(relation) => {
																if (relation) {
																	onChangeFilter(id, { ...opt, relation });
																}

																if (relation && opt.value) {
																	onCommitFilters();
																}
															}}
															classList="w-[139px]"
														/>
													);
												})
												.exhaustive()}

										{opt.key &&
											opt.relation &&
											opt.type &&
											match(opt.type)
												.with("PERCENTAGE", () => (
													<NullableNumberInput
														data-qualifier={`InsightPanel/Filter(${index})/${opt.key}/Percentage`}
														value={opt.value?.[0] ? Number(opt.value?.[0]) : null}
														rightContent={<Icon icon="Percentile" />}
														placeholder="0"
														onChange={(value) =>
															onChangeFilter(id, { ...opt, value: value != null ? [String(value)] : undefined })
														}
														classList="w-24"
														onBlur={() => onCommitFilters()}
													/>
												))
												.with("NUMERIC", () => (
													<NullableNumberInput
														data-qualifier={`InsightPanel/Filter(${index})/${opt.key}/NumericValue`}
														value={opt.value?.[0] ? Number(opt.value?.[0]) : null}
														placeholder="0"
														onChange={(value) =>
															onChangeFilter(id, { ...opt, value: value != null ? [String(value)] : undefined })
														}
														classList="w-24"
														onBlur={() => onCommitFilters()}
													/>
												))
												.with("DATE", () => (
													<DatePickerInput
														data-qualifier={`InsightPanel/Filter(${index})/${opt.key}/DatePicker`}
														value={opt.value?.[0] ?? ""}
														classList="w-24"
														onChange={(newDate) => {
															onChangeFilter(id, {
																...opt,
																value: newDate ? [newDate] : undefined,
															});
															onCommitFilters();
														}}
													/>
												))
												.otherwise(() => <></>)}
									</div>
									<TinyIconButton
										data-qualifier={`InsightPanel/Filter(${index})/${opt.key}/Delete`}
										icon="Delete"
										onClick={() => {
											onRemoveFilter(id);
											onCommitFilters();
										}}
										size={18}
										color={themeCSSVars.palette_P400}
										classList="ml-auto "
									/>
								</div>
							)}
						</Transition>
					)}
				</ForEach>
			</div>
			<Button palette="secondary" onClick={() => onAddFilter()} size="small" data-qualifier="InsightPanel/AddFilter">
				<Icon icon="Outline1" />
			</Button>
		</>
	);
}

const keyMetricsToRemove: FieldKey[] = [
	"SCORE_AVERAGE_NAN_AS_ZEROS",
	"SCORE_AVERAGE",
	"TAG_EXPOSURE_OPTION",
	"RISK_MODEL_EXPOSURE_AC",
	"EXPOSURE_MACRO_EQUITY",
	"EXPOSURE_MACRO_FIXED_INCOME",
	"EXPOSURE_MACRO_COMMODITIES",
	"EXPOSURE_MACRO_ALTERNATIVE",
	"EXPOSURE_MACRO_MONEY_MARKET",
];

function PortfolioInsightPanelMetricInner({
	selectable,
	selectableEntities,
	userClassifications,
	assetClassCategories,
	preset,
	onCommitKeyParameter,
	onCommitKeyThreshold,
	onChangeMetric,
	onChangeReferenceType,
	onChangeReferenceEntity,
	onChangeOrder,
	onChangeKeyMetricThreshold,
	onRemoveKeyMetricThreshold,
	onAddKeyMetricThreshold,
	thresholdForEntityProvider,
}: {
	selectable: Omit<AvailableFieldsResponse, "availableEntities">;
	selectableEntities: AvailableEntitiesResponse;
	userClassifications: UserInstrumentClassificationDto[];
	assetClassCategories: RiskModelExposureType[];
	preset?: PortfolioInsightPanelPreset;

	onCommitKeyParameter(): void;
	onCommitKeyThreshold(): void;

	onChangeMetric(newKeyMetric: HierarchicalMetrics): void;
	onChangeReferenceType(newReferenceType: MetricReferenceType): void;
	onChangeReferenceEntity(referenceEntity?: ReferenceEntityValues): void;

	onChangeOrder(newOrdering?: MetricOrdering): void;

	onChangeKeyMetricThreshold(id: string, payload: keyMetricThresholdMapType): void;
	onRemoveKeyMetricThreshold(id: string): void;
	onAddKeyMetricThreshold(): void;
	thresholdForEntityProvider(metric: NonNullable<PortfolioInsightPanelPreset["metric"]>): Promise<number | undefined>;
}) {
	const { metric } = preset ?? {};
	const { t } = useTranslation();

	const enrichedMetrics = useMemo(() => {
		if (!selectable.availableMetrics) {
			return [];
		}

		const apiMetricsResponse = Array.from(
			selectable.availableMetrics.filter((x) => (x.key ? !keyMetricsToRemove.includes(x.key) : false)),
		) as RichMetricValues[]; //force casting
		const groupedUserClassifications = groupBy(userClassifications, (x) => x.fieldType!);

		groupedUserClassifications.SCORE?.forEach((x) => {
			const scoreClassifications = {
				key: "SCORE_AVERAGE",
				type: "NUMERIC",
				classification: {
					classificationUuid: x.classificationUuid!,
					name: x.name!,
				},
				taxonomy: [
					{
						entry: "CUSTOM_SCORES_AND_METRICS",
					},
				],
			} satisfies RichMetricValues;
			apiMetricsResponse.push(scoreClassifications);
		});

		groupedUserClassifications.TAG?.forEach(
			(classification) =>
				classification.options?.forEach((opt) => {
					const tagClassifications = {
						key: "TAG_EXPOSURE_OPTION", // change
						type: "PERCENTAGE",
						classification: {
							classificationUuid: classification.classificationUuid!,
							name: classification.name!,
						},
						option: opt.option,
						taxonomy: [
							{
								entry: "EXPOSURES",
							},
							{
								parent: "EXPOSURES",
								entry: "TAG_EXPOSURE_OPTION",
							},
							{
								parent: "TAG_EXPOSURE_OPTION",
								entry: classification.name!,
							},
						],
					} satisfies RichMetricValues;
					apiMetricsResponse.push(tagClassifications);
				}),
		);

		assetClassCategories.forEach((assetClassCategory) => {
			assetClassCategory.assetClasses?.forEach((assetClass) => {
				const riskModelTags = {
					key: "RISK_MODEL_EXPOSURE_AC",
					type: "PERCENTAGE",
					riskModelExposureAssetClass: assetClass,
					riskModelExposureType: assetClassCategory.identifier,
					taxonomy: [
						{
							entry: "EXPOSURES",
						},
						{
							parent: "EXPOSURES",
							entry: "RISK_MODEL",
						},
						{
							parent: "RISK_MODEL",
							entry: assetClassCategory.name ?? "Missing tag category",
						},
					],
				} satisfies RichMetricValues;
				apiMetricsResponse.push(riskModelTags);
			});
		});

		return apiMetricsResponse;
	}, [userClassifications, selectable.availableMetrics, assetClassCategories]);

	const availableHierarchicalKeys = useMemo(() => createTreeHierarchicalOption(enrichedMetrics), [enrichedMetrics]);

	const availableEntitiesMap = useMemo<Map<string, ReferenceEntityValues>>(() => {
		if (!selectableEntities.availableEntities) {
			return Map();
		}
		return Map(selectableEntities.availableEntities.flatMap((x) => (x.identifier ? [[x.identifier, x]] : [])));
	}, [selectableEntities.availableEntities]);

	const availableEntities = useMemo(() => {
		if (!selectableEntities.availableEntities) {
			return [];
		}

		return selectableEntities.availableEntities.flatMap((x) =>
			x.label && x.identifier && x.type
				? [
						{
							label: x.label,
							value: x.identifier,
							group: {
								id: x.type,
								label: t(`METRIC_SELECTION.${x.type}`),
							},
						},
				  ]
				: [],
		) satisfies Array<OptionWithOptionalGroup<string, MetricEntityType>>;
	}, [selectableEntities.availableEntities, t]);

	const groupedAvailableEntities = useMemo(() => groupBy(availableEntities, (x) => x.group.id), [availableEntities]);

	const availableEntitiesByGroup = useMemo(() => {
		const selectedThresholdType = metric?.keyMetricThreshold?.first()?.thresholdType;

		return (
			match(selectedThresholdType)
				.with("MANUAL", undefined, () => [])
				.with("INVESTMENT", () => groupedAvailableEntities.INVESTMENT)
				.with("TARGET_INVESTMENT", () => groupedAvailableEntities.TARGET_PORTFOLIO)
				.with("CUSTOM_BENCHMARK", () => groupedAvailableEntities.USER_BENCHMARK)
				.with("STANDARD_BENCHMARK", () => groupedAvailableEntities.STANDARD_BENCHMARK)
				.exhaustive() ?? []
		);
	}, [groupedAvailableEntities, metric?.keyMetricThreshold]);

	return (
		<>
			<Controller value={false}>
				{({ value: isLoading, onChange: setIsLoading }) => (
					<FormField label="Key Metric" classList="mb-2">
						{(filedProps) => (
							<HierarchicalSelect
								{...filedProps}
								enableSearch
								options={availableHierarchicalKeys}
								data-qualifier="InsightPanel/SortingParameters/KeyMetric/Dropdown"
								triggerDataAttrs={{
									"data-qualifier": "InsightPanel/SortingParameters/KeyMetric/Trigger",
								}}
								value={
									metric?.key
										? {
												key: metric.key,
												...(metric.metricParameters ? { metricParameters: metric.metricParameters } : {}),
										  }
										: null
								}
								disabled={isLoading}
								onChange={(newKeyMetric) => {
									if (newKeyMetric) {
										onChangeMetric(newKeyMetric);
									}

									if (
										newKeyMetric.key &&
										((metric?.referenceType === "ABSOLUTE" && metric.ordering) ||
											(metric?.referenceType === "DELTA_RELATIVE" && metric?.referenceEntity?.identifier))
									) {
										unpromisify(() => onCommitKeyParameter())();
									}

									const keyMetricThreshold = metric?.keyMetricThreshold?.first();
									const keyMetricThresholdKey = metric?.keyMetricThreshold?.keySeq().first();
									if (
										keyMetricThreshold?.thresholdType &&
										keyMetricThreshold?.thresholdType !== "MANUAL" &&
										keyMetricThreshold.thresholdEntity?.identifier &&
										keyMetricThresholdKey &&
										newKeyMetric
									) {
										(async () => {
											setIsLoading(true);
											try {
												const thresholdValue = await thresholdForEntityProvider({
													key: newKeyMetric.key,
													metricParameters: newKeyMetric.metricParameters,
													keyMetricThreshold: metric?.keyMetricThreshold,
													ordering: metric?.ordering,
													referenceEntity: metric?.referenceEntity,
													referenceType: metric?.referenceType,
												});

												if (thresholdValue) {
													onChangeKeyMetricThreshold(keyMetricThresholdKey, {
														...keyMetricThreshold,
														thresholdValue,
													});
												}

												if (metric?.key) {
													onCommitKeyThreshold();
												}
											} catch (err) {
												platformToast({
													children: "Unable to compute threshold value, please re-try",
													icon: "Icon-full-error",
													severity: "error",
												});
											} finally {
												setIsLoading(false);
											}
										})().catch(noop);
									}
								}}
							/>
						)}
					</FormField>
				)}
			</Controller>

			<div className="flex items-end gap-4 mb-2">
				<Controller value={false}>
					{({ value: isLoading, onChange: setIsLoading }) => (
						<FormField label="Value type" classList="w-[115px]">
							{(filedProps) => (
								<Select
									{...filedProps}
									triggerDataAttrs={{
										"data-qualifier": "InsightPanel/SortingParameters/ValueType/Trigger",
									}}
									data-qualifier="InsightPanel/SortingParameters/ValueType/Dropdown"
									options={[
										{ label: "Absolute", value: MetricReferenceType.Absolute },
										{ label: "Relative", value: MetricReferenceType.DeltaRelative },
									]}
									disabled={isLoading}
									value={metric?.referenceType}
									onChange={(newReferenceType) => {
										if (newReferenceType) {
											onChangeReferenceType(newReferenceType);
										}

										if (
											metric?.key &&
											((newReferenceType === "ABSOLUTE" && metric.ordering) ||
												(newReferenceType === "DELTA_RELATIVE" && metric?.referenceEntity?.identifier))
										) {
											unpromisify(() => onCommitKeyParameter())();
										}

										const keyMetricThreshold = metric?.keyMetricThreshold?.first();
										const keyMetricThresholdKey = metric?.keyMetricThreshold?.keySeq().first();

										if (
											keyMetricThreshold?.thresholdType &&
											keyMetricThreshold?.thresholdType !== "MANUAL" &&
											keyMetricThreshold.thresholdEntity?.identifier &&
											keyMetricThresholdKey &&
											metric?.key
										) {
											(async () => {
												setIsLoading(true);
												try {
													let thresholdValue: number | undefined = undefined;
													if (newReferenceType === "ABSOLUTE") {
														thresholdValue = await thresholdForEntityProvider({
															key: metric?.key,
															keyMetricThreshold: metric?.keyMetricThreshold,
															ordering: metric?.ordering,
															referenceType: newReferenceType,
														});
													}

													if (newReferenceType === "DELTA_RELATIVE" && metric?.referenceEntity?.identifier) {
														thresholdValue = await thresholdForEntityProvider({
															key: metric?.key,
															keyMetricThreshold: metric?.keyMetricThreshold,
															ordering: metric?.ordering,
															referenceType: newReferenceType,
															referenceEntity: metric?.referenceEntity,
														});
													}

													if (thresholdValue != null) {
														onChangeKeyMetricThreshold(keyMetricThresholdKey, {
															...keyMetricThreshold,
															thresholdValue,
														});
													}

													if (metric?.key) {
														onCommitKeyThreshold();
													}
												} catch (err) {
													platformToast({
														children: "Unable to compute threshold value, please re-try",
														icon: "Icon-full-error",
														severity: "error",
													});
												} finally {
													setIsLoading(false);
												}
											})().catch(noop);
										}
									}}
								/>
							)}
						</FormField>
					)}
				</Controller>
				<Controller value={false}>
					{({ value: isLoading, onChange: setIsLoading }) => (
						<FormField label="" classList="grow">
							{(filedProps) => (
								<Select
									{...filedProps}
									triggerDataAttrs={{
										"data-qualifier": "InsightPanel/SortingParameters/Reference/Trigger",
									}}
									data-qualifier="InsightPanel/SortingParameters/Reference/Dropdown"
									options={availableEntities}
									enableSearch
									value={metric?.referenceEntity?.identifier}
									disabled={metric?.referenceType === "ABSOLUTE" || metric?.referenceType === undefined}
									classList="w-[calc(100%_-115px_-1rem)]"
									onChange={(entityIdentifier) => {
										if (entityIdentifier) {
											onChangeReferenceEntity(availableEntitiesMap.get(entityIdentifier));
										}

										if (
											metric?.key &&
											((metric?.referenceType === "ABSOLUTE" && metric.ordering) ||
												(metric?.referenceType === "DELTA_RELATIVE" && entityIdentifier))
										) {
											unpromisify(() => onCommitKeyParameter())();
										}

										const keyMetricThreshold = metric?.keyMetricThreshold?.first();
										const keyMetricThresholdKey = metric?.keyMetricThreshold?.keySeq().first();

										if (
											keyMetricThreshold?.thresholdType &&
											keyMetricThreshold?.thresholdType !== "MANUAL" &&
											keyMetricThreshold.thresholdEntity?.identifier &&
											keyMetricThresholdKey &&
											metric?.key
										) {
											(async () => {
												setIsLoading(true);
												try {
													let thresholdValue: number | undefined = undefined;
													if (metric?.referenceType === "ABSOLUTE") {
														thresholdValue = await thresholdForEntityProvider({
															key: metric?.key,
															keyMetricThreshold: metric?.keyMetricThreshold,
															ordering: metric?.ordering,
															referenceType: metric?.referenceType,
														});
													}

													if (metric?.referenceType === "DELTA_RELATIVE" && entityIdentifier) {
														thresholdValue = await thresholdForEntityProvider({
															key: metric?.key,
															keyMetricThreshold: metric?.keyMetricThreshold,
															ordering: metric?.ordering,
															referenceType: metric?.referenceType,
															referenceEntity: availableEntitiesMap.get(entityIdentifier),
														});
													}

													if (thresholdValue != null) {
														onChangeKeyMetricThreshold(keyMetricThresholdKey, {
															...keyMetricThreshold,
															thresholdValue,
														});
													}

													if (metric?.key) {
														onCommitKeyThreshold();
													}
												} catch (err) {
													platformToast({
														children: "Unable to compute threshold value, please re-try",
														icon: "Icon-full-error",
														severity: "error",
													});
												} finally {
													setIsLoading(false);
												}
											})().catch(noop);
										}
									}}
								/>
							)}
						</FormField>
					)}
				</Controller>
			</div>
			<FormField label="Order" classList="mb-2">
				{() => {
					function changeOrder(newOrdering: MetricOrdering | undefined) {
						onChangeOrder(newOrdering);
						if (
							metric?.key &&
							((metric?.referenceType === "ABSOLUTE" && newOrdering) ||
								(metric?.referenceType === "DELTA_RELATIVE" && metric.referenceEntity?.identifier))
						) {
							unpromisify(() => onCommitKeyParameter())();
						}
					}
					return (
						<ButtonGroupRadio
							options={[
								{
									"data-qualifier": `InsightPanel/SortingParameters/Order/${MetricOrdering.Asc}`,
									value: MetricOrdering.Asc,
									icon: "sort-ascending",
									children: "Asc",
								},
								{
									"data-qualifier": `InsightPanel/SortingParameters/Order/${MetricOrdering.Desc}`,
									value: MetricOrdering.Desc,
									icon: "sort-decending",
									children: "Desc",
								},
								{
									"data-qualifier": `InsightPanel/SortingParameters/Order/${MetricOrdering.Unsorted}`,
									disabled: true,
									value: MetricOrdering.Unsorted,
									icon: "table",
									children: "Table order",
								},
							]}
							onChange={changeOrder}
							value={metric?.ordering}
						/>
					);
				}}
			</FormField>
			<ForEach collection={Array.from(metric?.keyMetricThreshold?.toArray() ?? [])} keyProvider={(x) => x[0]}>
				{({ item: [id, keyMetricThreshold] }) => {
					return (
						<div className="flex items-end gap-4 mb-4">
							<FormField label="Key Metric Threshold" classList="w-[160px]">
								{(filedProps) => (
									<Select
										{...filedProps}
										triggerDataAttrs={{
											"data-qualifier": "InsightPanel/SortingParameters/KeyMetricThreshold/Trigger",
										}}
										data-qualifier="InsightPanel/SortingParameters/KeyMetricThreshold/Dropdown"
										options={keyMetricSelectionEntities}
										value={keyMetricThreshold.thresholdType}
										onChange={(newThresholdType) => {
											if (newThresholdType) {
												onChangeKeyMetricThreshold(
													id,
													newThresholdType === "MANUAL"
														? {
																thresholdType: newThresholdType,
																thresholdValue: 0,
														  }
														: {
																thresholdType: newThresholdType,
														  },
												);
											}

											if (newThresholdType === "MANUAL") {
												onCommitKeyThreshold();
											}
										}}
									/>
								)}
							</FormField>

							{keyMetricThreshold.thresholdType === "MANUAL" ? (
								<NullableNumberInput
									data-qualifier="InsightPanel/SortingParameters/KeyMetricThreshold/Manual/Value"
									value={keyMetricThreshold.thresholdValue ?? null}
									rightContent={<Icon icon="Percentile" />}
									placeholder="0"
									onChange={(newThresholdValue) => {
										onChangeKeyMetricThreshold(id, {
											...keyMetricThreshold,
											thresholdType: "MANUAL",
											thresholdValue: newThresholdValue ?? undefined,
										});

										onCommitKeyThreshold();
									}}
									classList="flex-1"
								/>
							) : (
								<Controller
									value={keyMetricThreshold}
									onChange={(newThreshold) => {
										if (newThreshold) {
											onChangeKeyMetricThreshold(id, newThreshold);
										}

										if (metric?.key) {
											onCommitKeyThreshold();
										}
									}}
								>
									{({ value, onChange, onCommit }) => (
										<Controller value={false}>
											{({ value: isLoading, onChange: setIsLoading }) => (
												<Select
													innerRef={(e) =>
														e?.setAttribute(
															"data-qualifier",
															`InsightPanel/SortingParameters/KeyMetricThreshold/${value.thresholdType}/Trigger`,
														)
													}
													data-qualifier={`InsightPanel/SortingParameters/KeyMetricThreshold/${value.thresholdType}/Dropdown`}
													options={availableEntitiesByGroup}
													enableSearch
													value={value.thresholdEntity?.identifier ?? null}
													classList="flex-1"
													disabled={isLoading}
													onChange={(val) => {
														if (!val) {
															return;
														}
														const newVal = {
															thresholdType: value.thresholdType,
															thresholdEntity: availableEntitiesMap.get(val),
															thresholdValue: undefined,
														};
														onChange(newVal);
														(async () => {
															if (!metric) {
																// TODO: is this possible?
																return;
															}
															setIsLoading(true);
															try {
																const key = Array.from(metric.keyMetricThreshold?.keys() ?? []).at(0);
																const thresholdValue = await thresholdForEntityProvider({
																	...metric,
																	keyMetricThreshold: handleMapTruthy(metric.keyMetricThreshold).set(
																		key ?? generateUniqueDOMId(),
																		newVal,
																	),
																});
																onCommit({ ...newVal, thresholdValue });
															} catch (err) {
																platformToast({
																	children: "Unable to compute threshold value, please re-try",
																	icon: "Icon-full-error",
																	severity: "error",
																});
															} finally {
																setIsLoading(false);
															}
														})().catch(noop);
													}}
												/>
											)}
										</Controller>
									)}
								</Controller>
							)}
							<TinyIconButton
								data-qualifier="InsightPanel/SortingParameters/KeyMetricThreshold/Delete"
								icon="Delete"
								onClick={() => {
									onRemoveKeyMetricThreshold(id);
									onCommitKeyThreshold();
								}}
								size={18}
								color={themeCSSVars.palette_P400}
								classList="ml-auto"
							/>
						</div>
					);
				}}
			</ForEach>
			{(!metric?.keyMetricThreshold?.size || metric?.keyMetricThreshold?.size === 0) && (
				<FormField label="Key Metric Threshold" classList="w-[160px]">
					<Button palette="secondary" classList="w-fit mt-1" size="small" onClick={onAddKeyMetricThreshold}>
						<Icon icon="Outline1" />
					</Button>
				</FormField>
			)}
		</>
	);
}

type RichMetricValues = Omit<MetricValues, "taxonomy"> & {
	classification?: {
		classificationUuid: string;
		name: string;
	};
	assetClassCategory?: string;
	option?: string;
	taxonomy: Array<{ entry: string; parent?: string }>;
	riskModelExposureType?: string;
	riskModelExposureAssetClass?: string;
};

type HierarchicalMetrics = {
	key: FieldKey;
	metricParameters?: MetricParameters;
};

function generateHierarchicalMetrics(richMetric: RichMetricValues): HierarchicalOption<{
	key: FieldKey;
	metricParameters?: MetricParameters;
}> {
	switch (richMetric.key) {
		case "TAG_EXPOSURE_OPTION": {
			return {
				id: `${richMetric.key}-${richMetric.classification?.classificationUuid}-${richMetric.classification?.name}-${richMetric.option}`,
				label: richMetric.option!,
				value: {
					key: richMetric.key!,
					metricParameters: {
						classificationUuid: richMetric.classification!.classificationUuid,
						option: richMetric.option,
					},
				},
			};
		}
		case "SCORE_AVERAGE": {
			return {
				id: `${richMetric.key}-${richMetric.classification?.classificationUuid}-${richMetric.classification?.name}-${richMetric.option}`,
				label: richMetric.classification!.name!,
				value: {
					key: richMetric.key!,
					metricParameters: {
						classificationUuid: richMetric.classification!.classificationUuid,
					},
				},
			};
		}
		case "RISK_MODEL_EXPOSURE_AC": {
			return {
				id: `${richMetric.key}-${richMetric.riskModelExposureType}-${richMetric.riskModelExposureAssetClass}`,
				label: richMetric.riskModelExposureAssetClass!,
				value: {
					key: richMetric.key!,
					metricParameters: {
						riskModelExposureAssetClass: richMetric.riskModelExposureAssetClass,
						riskModelExposureType: richMetric.riskModelExposureType,
					},
				},
			};
		}
		default: {
			return {
				id: richMetric.key!,
				label: fieldKeys[richMetric.key!],
				value: { key: richMetric.key! },
			};
		}
	}
}

function createTreeHierarchicalOption(flatNode: RichMetricValues[]): HierarchicalOption<HierarchicalMetrics>[] {
	const root: HierarchicalOption<HierarchicalMetrics>[] = [];
	for (let i = 0; i < flatNode.length - 1; i++) {
		const node = flatNode[i];
		const taxonomy = node.taxonomy ?? [];
		if (taxonomy.length === 0) {
			if (node.key) {
				root.push(generateHierarchicalMetrics(node));
			}
		}

		if (taxonomy.length > 0) {
			const trackingIndex: number[] = [];
			const orderTaxonomyByParent = taxonomy.sort((a, b) => builtInSort(a?.parent, b?.parent, "asc"));
			orderTaxonomyByParent.forEach((item, index) => {
				const currentNode = trackingIndex.reduce<
					HierarchicalOption<HierarchicalMetrics>[] | HierarchicalOption<HierarchicalMetrics>
				>((acc, nodeIndex) => {
					if (Array.isArray(acc)) {
						return acc[nodeIndex];
					}

					return acc.children![nodeIndex];
				}, root);

				const children = Array.isArray(currentNode) ? currentNode : currentNode.children;
				if (children) {
					const nodeIndex = children.findIndex((x) => x.id === item.entry);

					if (nodeIndex === -1) {
						children.push({
							label: metricTaxonomyLabel[item.entry as MetricValueTaxonomy] ?? item.entry,
							id: item.entry!,
							children: [],
						});
						trackingIndex.push(children.length - 1);
					}

					if (nodeIndex === -1 && index === orderTaxonomyByParent.length - 1) {
						const indexOfChildren = children.findIndex((x) => x.id === item.entry);
						children[indexOfChildren].children?.push(generateHierarchicalMetrics(node));
					}

					if (nodeIndex > -1 && index !== orderTaxonomyByParent.length - 1) {
						trackingIndex.push(nodeIndex);
					}

					if (nodeIndex > -1 && index === orderTaxonomyByParent.length - 1) {
						children[nodeIndex].children?.push(generateHierarchicalMetrics(node));
					}
				}
			});
		}
	}

	return root;
}

const metricTaxonomyLabel: Record<MetricValueTaxonomy | "TAG_EXPOSURE_OPTION", string> = {
	CUSTOM_SCORES_AND_METRICS: "Custom scores & metrics",
	EFFICIENCY_RATIO: "Efficiency ratio",
	EX_ANTE_METRICS: "Ex ante metrics",
	EX_ANTE_VOLATILITY: "Ex ante volatility",
	EXPOSURES: "Exposure",
	HISTORICAL_VAR: "Historical VaR",
	MAX_DRAWDOWN: "Max drawdown",
	PARAMETRIC_VAR: "Parametric VaR",
	PERFORMANCE: "Performance",
	REALISED_METRICS: "Realised metrics",
	RISK_MODEL: "Risk model",
	SORTINO: "Sortino",
	VOLATILITY: "Volatility",
	TAG_EXPOSURE_OPTION: "Tags",
};

const fieldKeys: Record<FieldKey, string> = {
	NAME: "Name",
	STATUS: "Status",
	INCEPTION_DATE: "Inception date",
	CURRENT_ACTION: "Last action",
	LAST_STATUS_UPDATE: "Last update",
	AUTO_SYNC: "AutoSync",
	CURRENCY_NAME: "Currency",
	BENCHMARK_NAME: "Benchmark",
	REFERENCE_NAME: "Reference",
	UNIVERSE_NAME: "Universe",
	MARKET_VIEW_NAME: "Market view",
	COMMENTARY_TEMPLATE: "Commentary template",
	SCORE_AVERAGE: "Average Score",
	SCORE_AVERAGE_NAN_AS_ZEROS: "Average Score",
	WARNINGS: "Warnings",
	SHARING_VIEWS: "Sharing with",
	TAG_EXPOSURE_OPTION: "Tag exposure",
	RISK_MODEL_EXPOSURE_AC: "Risk model exposure",
	PERFORMANCE_SINCE_INCEPTION: "Performance since inception",
	BENCHMARK_PERFORMANCE_1M: "Benchmark Performance 1M",
	BENCHMARK_MAX_DRAWDOWN_1M: "Benchmark Max drawdown 1M",
	BENCHMARK_SORTINO_1M: "Benchmark Sortino 1M",
	BENCHMARK_VOLATILITY_1M: "Benchmark Volatility 1M",
	BENCHMARK_EFFICIENCY_RATIO_1M: "Benchmark Efficiency Ratio 1M",
	PERFORMANCE_YTD: "Performance YTD",
	PERFORMANCE_1Y: "Performance 1Y",
	PERFORMANCE_6M: "Performance 6M",
	PERFORMANCE_3M: "Performance 3M",
	PERFORMANCE_1M: "Performance 1M",
	SORTINO_SINCE_INCEPTION: "Sortino since inception",
	SORTINO_YTD: "Sortino YTD",
	SORTINO_1Y: "Sortino 1Y",
	SORTINO_6M: "Sortino 6M",
	SORTINO_3M: "Sortino 3M",
	SORTINO_1M: "Sortino 1M",
	MAX_DRAWDOWN_SINCE_INCEPTION: "Max drawdown since inception",
	MAX_DRAWDOWN_YTD: "Max drawdown YTD",
	MAX_DRAWDOWN_1Y: "Max drawdown 1Y",
	MAX_DRAWDOWN_6M: "Max drawdown 6M",
	MAX_DRAWDOWN_3M: "Max drawdown 3M",
	MAX_DRAWDOWN_1M: "Max drawdown 1M",
	VOLATILITY_SINCE_INCEPTION: "Volatility since inception",
	VOLATILITY_YTD: "Volatility YTD",
	VOLATILITY_1Y: "Volatility 1Y",
	VOLATILITY_6M: "Volatility 6M",
	VOLATILITY_3M: "Volatility 3M",
	VOLATILITY_1M: "Volatility 1M",
	EFFICIENCY_RATIO_SINCE_INCEPTION: "Efficiency Ratio since inception",
	EFFICIENCY_RATIO_YTD: "Efficiency Ratio YTD",
	EFFICIENCY_RATIO_1Y: "Efficiency Ratio 1Y",
	EFFICIENCY_RATIO_6M: "Efficiency Ratio 6M",
	EFFICIENCY_RATIO_3M: "Efficiency Ratio 3M",
	EFFICIENCY_RATIO_1M: "Efficiency Ratio 1M",
	EX_ANTE_RETURN_3Y: "Ex Ante Return 3Y",
	EX_ANTE_EFFICIENCY_RATIO_3Y: "Ex Ante EfficiencY Ratio 3Y",
	EX_ANTE_MAX_DRAWDOWN_3Y: "Ex Ante Max Drawdown 3Y",
	EX_ANTE_DIVERSIFICATION_RATIO_3Y: "Ex Ante Diversification Ratio 3Y",
	TRACKING_ERROR_3Y: "Tracking Error 3Y",
	PARAMETRIC_VAR_95_1Y: "Parametric VaR 1Y 95%",
	PARAMETRIC_VAR_975_1Y: "Parametric VaR 1Y 97.5%",
	PARAMETRIC_VAR_99_1Y: "Parametric VaR 1Y 99%",
	PARAMETRIC_VAR_95_2Y: "Parametric VaR 2Y 95%",
	PARAMETRIC_VAR_975_2Y: "Parametric VaR 2Y 97.5%",
	PARAMETRIC_VAR_99_2Y: "Parametric VaR 2Y 99%",
	PARAMETRIC_VAR_95_3Y: "Parametric VaR 3Y 95%",
	PARAMETRIC_VAR_975_3Y: "Parametric VaR 3Y 97.5%",
	PARAMETRIC_VAR_99_3Y: "Parametric VaR 3Y 99%",
	HISTORICAL_VAR_95_1Y: "Historical VaR 1Y 95%",
	HISTORICAL_VAR_975_1Y: "Historical VaR 1Y 97.5%",
	HISTORICAL_VAR_99_1Y: "Historical VaR 1Y 99%",
	HISTORICAL_VAR_95_2Y: "Historical VaR 2Y 95%",
	HISTORICAL_VAR_975_2Y: "Historical VaR 2Y 97.5%",
	HISTORICAL_VAR_99_2Y: "Historical VaR 2Y 99%",
	HISTORICAL_VAR_95_3Y: "Historical VaR 3Y 95%",
	HISTORICAL_VAR_975_3Y: "Historical VaR 3Y 97.5%",
	HISTORICAL_VAR_99_3Y: "Historical VaR 3Y 99%",
	EX_ANTE_VOLATILITY_1Y: "Ex Ante Volatility 1Y",
	EX_ANTE_VOLATILITY_2Y: "Ex Ante Volatility 2Y",
	EX_ANTE_VOLATILITY_3Y: "Ex Ante Volatility 3Y",
	EX_ANTE_VOLATILITY_6M: "Ex Ante Volatility 6m",
	EXPOSURE_MACRO_EQUITY: "Exposure Macro Equity",
	EXPOSURE_MACRO_FIXED_INCOME: "Exposure Macro Fixed Income",
	EXPOSURE_MACRO_COMMODITIES: "Exposure Macro Commodities",
	EXPOSURE_MACRO_ALTERNATIVE: "Exposure Macro Alternative",
	EXPOSURE_MACRO_MONEY_MARKET: "Exposure Macro Money Market",
};

export default PortfolioInsightPanel;
