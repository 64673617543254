import type {
	Description,
	ListInstrumentsRequest,
	RichRiskModelValue,
	SelectableBasket,
	Tag,
	UserInstrumentDto,
	UserInstrumentsColumnPreference,
} from "$root/api/api-gen";
import {
	InstrumentsClassificationsControllerV1ApiFactory,
	InstrumentsCustomizationControllerV3ApiFactory,
	PortfolioStudioPreferencesApiFactory,
	type Quality,
	type Score,
	type UserInstrumentClassificationDto,
} from "$root/api/api-gen";
import { runWithErrorReporting } from "$root/api/error-reporting/report";
import { getApiGen } from "$root/api/factory";
import { useApiGen } from "$root/api/hooks";
import { hasAccess } from "$root/components/AuthorizationGuard";
import { ReactQueriesWrapperBase, ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { spawnYesNoDialog } from "$root/components/spawnable/yes-no-dialog";
import { useSpreadsheet } from "$root/components/tables-extra/spreadsheet/spreadsheet-ctx";
import { useEventBus } from "$root/event-bus";
import { useUserValue } from "$root/functional-areas/user";
import { useLocaleFormatters } from "$root/localization/hooks";
import { platformToast } from "$root/notification-system/toast";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { WithZustandStore } from "$root/third-party-integrations/zustand";
import { noRefetchDefaults, parallelize, sumArrayLike, useQueryNoRefetch, useTaskQueueWithStore } from "$root/utils";
import { Card } from "$root/widgets-architecture/layout/Card";
import type { BaseHScrollTableProps, PaginationRequest, TableColumn } from "@mdotm/mdotui/components";
import {
	BaseHScrollTable,
	BatchActions,
	CircularProgressBar,
	Icon,
	LocalOverlay,
	Pagination,
	ProgressBar,
	Row,
	Text,
	useSelectableTableColumn,
	type PaginationResponse,
} from "@mdotm/mdotui/components";
import type { MaybePromise, MultiSelectCtx } from "@mdotm/mdotui/headless";
import { useMultiSelect } from "@mdotm/mdotui/headless";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { approxRound, identity, noop } from "@mdotm/mdotui/utils";
import { useQueries } from "@tanstack/react-query";
import { hash, Map } from "immutable";
import { useCallback, useMemo, useState } from "react";
import { match } from "ts-pattern";
import type { UserColumnMetadata } from "../hooks";
import { SphereColumn, useInstrumentsColumn } from "../hooks";
import type { InstrumentTagWeightMap } from "../tags-v2/types";
import { useInstrumentCustomisationState } from "./InstrumentCustomisationStore";
import type {
	InstrumentCustomisationSettings,
	InstrumentCustomisationTableSidePanelProps,
} from "./SidePanelColumnFilters";
import InstrumentCustomisationTableSidePanel from "./SidePanelColumnFilters";
import type { CreateClassification } from "./SidePanelColumnsAdder";
import { usePaginationTools } from "$root/hooks/usePaginationTools";

export const instrumentCommentaryGenerationCode = {
	DATA_NOT_AVAILABLE: "DATA_NOT_AVAILABLE",
	COMMENTARY_GENERATION_ERROR: "COMMENTARY_GENERATION_ERROR",
};

export type PaginatedUserInstrumentsRequestProps = PaginationRequest<
	{
		query?: string;
		fields: string[] /** classificationUuid */;
		selectedBaskets?: SelectableBasket[];
		tags?: Tag[];
	},
	string
>;

export function createListInstrumentsRequest(params: PaginatedUserInstrumentsRequestProps): ListInstrumentsRequest {
	return {
		offset: params.skip,
		limit: params.take,
		fields: params.filters?.fields,
		keyword: params.filters?.query,
		...(params.orderBy && params.orderBy.length
			? {
					sortBy: [params.orderBy.at(0)!.columnName!],
					sortDirection: params.orderBy.at(0)?.direction === "asc" ? "ASC" : "DESC",
			  }
			: {}),
		selectableBaskets: params.filters?.selectedBaskets ?? [],
		tagValue: params.filters?.tags,
	};
}

export async function getPaginatedUserInstruments(
	params: PaginatedUserInstrumentsRequestProps,
): Promise<PaginationResponse<UserInstrumentDto>> {
	const instrumentCustomizationV3Api = getApiGen(InstrumentsCustomizationControllerV3ApiFactory);
	const paginationResult = await axiosExtract(
		instrumentCustomizationV3Api.listInstruments1(createListInstrumentsRequest(params)),
	);

	return {
		items: paginationResult.items ?? [],
		total: paginationResult.pagination?.total ?? 0,
	};
}

// TODO: regression from previous version: missing pre-selection of bucket filter (check UniverseComposition/readonly.tsx)
function InstrumentCustomizationTable(): JSX.Element {
	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);
	const instrumentCustomizationV3Api = useApiGen(InstrumentsCustomizationControllerV3ApiFactory);
	const instrumentsClassificationsControllerV1Api = useApiGen(InstrumentsClassificationsControllerV1ApiFactory);

	const queryColumns = useQueries({
		queries: [
			{
				queryKey: ["instrumentCustomizationColumnPreferences"],
				queryFn: () => axiosExtract(portfolioStudioPreferencesApi.getUserInstrumentsColumnPreferences()),
				...noRefetchDefaults,
			},
			{
				queryKey: ["instrumentCustomizationUserClassification"],
				queryFn: () => axiosExtract(instrumentsClassificationsControllerV1Api.retrieveAllClassifications()),
				...noRefetchDefaults,
			},
		],
	});

	const [columnPreferences, userClassification] = queryColumns;

	const createUserColumn = async (columnMetadata: CreateClassification) => {
		await runWithErrorReporting(
			async () => {
				const { classificationUuid } = await axiosExtract(
					instrumentsClassificationsControllerV1Api.createClassification(columnMetadata),
				);
				const { data } = await columnPreferences.refetch();
				const newColumnPreferences = data?.userInstrumentsColumnPreferences?.map((preference) =>
					preference.preferenceType?.classificationUuid === classificationUuid
						? {
								...preference,
								enabled: true,
						  }
						: preference,
				);
				await portfolioStudioPreferencesApi.setUserInstrumentsColumnPreferences({
					userInstrumentsColumnPreferences: newColumnPreferences,
				});
				await columnPreferences.refetch();
				await userClassification.refetch();
			},
			{
				area: "instrument-customisation",
				attemptedOperation: { message: "create new classification column", payload: JSON.stringify(columnMetadata) },
			},
		);
	};

	const updateUserColumn = async (columnMetadata: UserInstrumentClassificationDto) => {
		await runWithErrorReporting(
			async () => {
				await instrumentsClassificationsControllerV1Api.updateClassification(
					columnMetadata.classificationUuid!,
					columnMetadata,
				);
				await userClassification.refetch();
			},
			{
				area: "instrument-customisation",
				attemptedOperation: { message: "edit classification column" },
			},
		);
	};

	const deleteUserColumn = async (classificationUuid: string) => {
		await runWithErrorReporting(
			async () => {
				await instrumentsClassificationsControllerV1Api.deleteClassification(classificationUuid);
				await columnPreferences.refetch();
				await userClassification.refetch();
				await columnPreferences.refetch();
			},
			{
				area: "instrument-customisation",
				attemptedOperation: { message: "delete classification column" },
			},
		);
	};

	const changeTableLayout = async (newColumnPreferences: Array<UserColumnMetadata>) => {
		await runWithErrorReporting(
			async () => {
				const newUserInstrumentsColumnPreferences = newColumnPreferences.map(
					(x): UserInstrumentsColumnPreference => ({
						enabled: x.enabled,
						preferenceType: x.preferenceType
							? x.preferenceType
							: {
									classificationUuid: x.classificationUuid,
									label: x.name,
							  },
					}),
				);

				await portfolioStudioPreferencesApi.setUserInstrumentsColumnPreferences({
					userInstrumentsColumnPreferences: newUserInstrumentsColumnPreferences,
				});
				await columnPreferences.refetch();
			},
			{
				area: "instrument-customisation",
				attemptedOperation: { message: "change classification preferences" },
			},
		);
	};

	const onChangeCell = async (
		tickerName: string,
		kind: keyof InstrumentSpreadsheetCellTypeMap,
		row: Partial<UserInstrumentDto>,
	) => {
		const {
			tickerName: _tickerName,
			riskModel,
			proxyOverwriteType: _proxyOverwriteType,
			customAttributes: _customAttributes,
			alias: _alias,
			description,
			type: _type,
			...patchableField
		} = row;

		switch (kind) {
			case "riskModel": {
				if (!riskModel) {
					throw new Error("unexpected empty risk model dispatched on cell change");
				}
				await instrumentCustomizationV3Api.updateRiskModel(tickerName, riskModel);
				break;
			}
			default: {
				await instrumentCustomizationV3Api.updateInstrumentFields(tickerName, {
					...patchableField,
					description: description?.value,
				});
				break;
			}
		}
	};

	const onRemoveTaggedCell = async (tickerName: string, tag: Tag) => {
		const { classificationUuid } = tag;
		await instrumentCustomizationV3Api.deleteInstrumentClassification(tickerName, classificationUuid!);
	};
	const user = useUserValue();
	return (
		<ReactQueriesWrapperBase queries={queryColumns}>
			{([{ userInstrumentsColumnPreferences }, userClassifications]) => {
				const columnsMetadata =
					userInstrumentsColumnPreferences?.map((x) => {
						const classification = userClassifications.find(
							(user) => user.classificationUuid === x.preferenceType?.classificationUuid,
						);
						return {
							...x,
							...classification,
							hidden:
								SphereColumn.name === x.preferenceType?.classificationUuid ||
								(x.preferenceType?.classificationUuid === SphereColumn.riskModel &&
									!hasAccess(user, { requiredService: "RISK_MODEL_COLUMN" })),
							name: classification?.name ?? x.preferenceType?.label ?? "Untitled",
						};
					}) ?? [];
				return (
					<InstrumentCustomizationTableInner
						columnsMetadata={columnsMetadata}
						rowsProvider={getPaginatedUserInstruments}
						createUserColumn={createUserColumn}
						updateUserColumn={updateUserColumn}
						deleteUserColumn={deleteUserColumn}
						changeTableLayout={changeTableLayout}
						onChangeCell={onChangeCell}
						selectableBasketProvider={async () => {
							const selectableBaskets = await axiosExtract(instrumentCustomizationV3Api.geSelectableBaskets());
							const basketsWithoutSphereTemplates = selectableBaskets.filter(
								(x) => x.basketIdentifier && x.basketName && x.basketType && x.basketType !== "TEMPLATE",
							);
							return basketsWithoutSphereTemplates;
						}}
						selectableUserClassificationsProvider={() => Promise.resolve(columnsMetadata)}
						onGenerateCommentary={(tickerName) =>
							axiosExtract(instrumentCustomizationV3Api.generateInstrumentSphereCommentary(tickerName))
						}
						onRemoveTaggedCell={onRemoveTaggedCell}
					/>
				);
			}}
		</ReactQueriesWrapperBase>
	);
}

export type InstrumentCustomizationTableInnerProps = {
	rowsProvider: (request: PaginatedUserInstrumentsRequestProps) => MaybePromise<PaginationResponse<UserInstrumentDto>>;
	columnsMetadata: UserColumnMetadata[];
	createUserColumn(columnMetadata: CreateClassification): Promise<void>;
	updateUserColumn(columnMetadata: UserInstrumentClassificationDto): Promise<void>;
	deleteUserColumn(classificationUuid: string): Promise<void>;
	changeTableLayout(columnPreferences: UserColumnMetadata[]): Promise<void>;
	selectableBasketProvider: InstrumentCustomisationTableSidePanelProps["selectableBasketProvider"];
	selectableUserClassificationsProvider: InstrumentCustomisationTableSidePanelProps["selectableUserClassificationsProvider"];
	onGenerateCommentary(tickerName: string): Promise<UserInstrumentDto>;
	onChangeCell(
		tickerName: string,
		kind: keyof InstrumentSpreadsheetCellTypeMap,
		row: Partial<UserInstrumentDto>,
	): Promise<void>;
	onRemoveTaggedCell(tickerName: string, tag: Tag): Promise<void>;
};

export const userInstrumentsBaseQueryKey = "queryUserInstruments";
export function InstrumentCustomizationTableInner({
	rowsProvider,
	selectableBasketProvider,
	selectableUserClassificationsProvider,
	columnsMetadata,
	onChangeCell,
	onRemoveTaggedCell,
	onGenerateCommentary,
	...forward
}: InstrumentCustomizationTableInnerProps): JSX.Element {
	const { instrumentCustomisationStore, setInstrumentCustomisationStore } = useInstrumentCustomisationState();
	const [instrumentCustomisationSettings, setInstrumentCustomisationSettings] =
		useState<InstrumentCustomisationSettings>({
			search: instrumentCustomisationStore.search,
			selectedBaskets: instrumentCustomisationStore.selectedBaskets,
			selectedfilters: Map(instrumentCustomisationStore.selectedfilters),
		});

	const multiSelectCtx = useMultiSelect<string>();

	const { orderBy, skip, take, query } = usePaginationTools();

	const validTagFilters = useMemo<Tag[] | undefined>(() => {
		const filters = instrumentCustomisationSettings.selectedfilters?.valueSeq().toArray();

		return filters?.filter((filter) => filter.classificationUuid && filter.values && filter.values.length > 0);
	}, [instrumentCustomisationSettings.selectedfilters]);

	const rowsQuery = useQueryNoRefetch({
		queryKey: [
			userInstrumentsBaseQueryKey /*filters*/,
			skip.value,
			take.value,
			orderBy.value,
			instrumentCustomisationSettings.selectedBaskets,
			validTagFilters,
			query.value,
			columnsMetadata,
		],
		queryFn: () =>
			rowsProvider({
				skip: skip.value,
				take: take.value,
				filters: {
					query: query.value,
					fields: columnsMetadata.flatMap((x) => (x.enabled && x.fieldType ? [x.classificationUuid!] : [])),
					selectedBaskets: instrumentCustomisationSettings.selectedBaskets,
					tags: validTagFilters,
				},
				orderBy: orderBy.value,
			}),
		keepPreviousData: true,
	});

	const rows = useMemo(
		() =>
			rowsQuery.data?.items.flatMap((x): UserInstrumentDto[] =>
				x.tickerName ? [{ ...x, tickerName: x.tickerName }] : [],
			) ?? [],
		[rowsQuery.data],
	);

	const { queue: updateQueue, state: updateQueueState /* TODO: leave prompt when isRunning is true */ } =
		useTaskQueueWithStore();

	const pageHash = useMemo(() => hash(rows), [rows]);
	const { formatTime } = useLocaleFormatters();
	return (
		<div className="flex-1 flex flex-col min-h-0 min-w-0">
			<ReactQueryWrapperBase
				keepChildren
				query={rowsQuery}
				loadingFallback={<ProgressBar value="indeterminate" classList="mt-4" />}
			>
				{() => (
					<div className="min-w-0 min-h-0 flex-1 flex relative z-0">
						<InstrumentCustomisationTableSidePanel
							expand={instrumentCustomisationStore.expanded}
							onExpandChange={() =>
								setInstrumentCustomisationStore({
									...instrumentCustomisationStore,
									expanded: !instrumentCustomisationStore.expanded,
								})
							}
							selectableBasketProvider={selectableBasketProvider}
							selectableUserClassificationsProvider={selectableUserClassificationsProvider}
							initialInstrumentCustomisationSettings={instrumentCustomisationSettings}
							onChangeInstrumentCustomisationSettings={(newSettings) => {
								query.set(newSettings.search);
								setInstrumentCustomisationSettings(newSettings);
								setInstrumentCustomisationStore({
									expanded: instrumentCustomisationStore.expanded,
									search: newSettings.search,
									selectedBaskets: newSettings.selectedBaskets ?? [],
									selectedfilters: newSettings.selectedfilters?.toArray() ?? [],
								});
							}}
						/>
						<Card classList="px-4 py-6 min-w-0 flex-1 relative">
							<WithZustandStore store={updateQueueState}>
								{({ isRunning, lastRunAt }) => (
									// TODO: UI, maybe bring the queue up to the page so that we show the loading state in the title?
									<Row alignItems="center" gap={4} classList="absolute z-10 top-4 left-4">
										{isRunning ? (
											<>
												<Icon icon="calulating" size={16} />
												<Text as="div" type="Body/S/Medium">
													Saving
												</Text>
											</>
										) : (
											lastRunAt &&
											(lastRunAt.isSuccess ? (
												<>
													<Icon icon="Outline" size={16} />
													<Text as="div" type="Body/S/Medium">
														Saved at {formatTime(lastRunAt.timestamp)}
													</Text>
												</>
											) : (
												<>
													<Icon icon="Icon-full-alert" size={16} />
													<Text as="div" type="Body/S/Medium">
														Unable to save
													</Text>
												</>
											))
										)}
									</Row>
								)}
							</WithZustandStore>

							<div className="flex items-center justify-end">
								<Pagination
									skip={skip.value}
									take={take.value}
									total={rowsQuery.data?.total ?? 0}
									onChange={skip.set}
								/>
							</div>
							<div className="flex-1 flex flex-col min-h-0 relative z-0">
								<LocalOverlay
									show={rowsQuery.isFetching}
									classList="z-10"
									style={{
										background: "transparent",
									}}
								>
									<CircularProgressBar value="indeterminate" />
								</LocalOverlay>
								{/* InstrumentCustomisationTableSidePanel */}
								<InstrumentSpreadsheetPage
									key={pageHash} // !!!
									rows={rows}
									orderBy={orderBy.value}
									onOrderByChange={orderBy.set}
									columnsMetadata={columnsMetadata}
									multiSelectCtx={multiSelectCtx}
									onChangeCell={(tickerName, kind, row) => {
										//todo: change and operate for partial data update
										runWithErrorReporting(() => updateQueue.enqueue(() => onChangeCell(tickerName, kind, row)), {
											area: "global",
											attemptedOperation: {
												message: `edit instrument cell ${tickerName}`,
												payload: JSON.stringify({ tickerName, row }),
											},
										}).catch(noop);
									}}
									onRemoveTaggedCell={(tickerName, tag) => {
										runWithErrorReporting(() => updateQueue.enqueue(() => onRemoveTaggedCell(tickerName, tag)), {
											area: "global",
											attemptedOperation: {
												message: `clear instrument cell ${tickerName}`,
												payload: JSON.stringify({ tickerName, tag }),
											},
										}).catch(noop);
									}}
									onGenerateCommentary={onGenerateCommentary}
									{...forward}
								/>
							</div>
						</Card>
					</div>
				)}
			</ReactQueryWrapperBase>
			{multiSelectCtx.selection.size > 0 && (
				<BatchActions
					manageSelectionAction={{
						label: "Unselect",
						onClick: multiSelectCtx.reset,
					}}
					palette="quaternary"
					classList={`!bg-[color:${themeCSSVars.palette_N500}] h-11 px-2 mt-2 rounded-md shadow-xl shadow-[color:${themeCSSVars.palette_N200}] overflow-x-auto [&>div:first-child]:!shrink-0 [&>div:first-child]:!min-w-[90px] [&>button]:!shrink-0 !flex-nowrap`}
					selected={multiSelectCtx.selection.size}
					total={rows.length}
					actions={[
						{
							label: "Generate description",
							icon: "sphere-ai",
							onClick: () => {
								const selection = rows.filter((x) => multiSelectCtx.selection.includes(x.tickerName!));
								const tickersWithValidIsin = selection.filter(
									({ type }) => type === "MULTI_ASSET" || type === "SINGLE_STOCK",
								);

								if (tickersWithValidIsin.length === 0) {
									return platformToast({
										children: "Unable to generate description, please select instruments with valid identifier",
										icon: "Portfolio",
										severity: "info",
									});
								}

								spawnYesNoDialog({
									size: "medium",
									header: "Generate description",
									yesButton: "Proceed",
									noButton: "Cancel",
									children: (
										<>
											<Text as="p" type="Body/M/Book" classList="mb-2">
												You are about to generate{" "}
												<Text as="span" type="Body/M/Bold">
													{tickersWithValidIsin.length} instrument descriptions
												</Text>
											</Text>
											<Text as="p" type="Body/M/Book">
												Please confirm to proceed.
											</Text>
										</>
									),
									async onSubmitAsync() {
										await runWithErrorReporting(
											async () => {
												await parallelize(
													tickersWithValidIsin.map(
														({ tickerName }) =>
															() =>
																onGenerateCommentary(tickerName!),
													),
													{ concurrency: 32 },
												);
												platformToast({
													children: "Sphere has taken over your request",
													severity: "info",
													icon: "sphere-ai",
												});
											},
											{
												area: "instrument-customisation",
												attemptedOperation: {
													message: "try to generate instruments description",
												},
											},
										);
									},
								})
									.then(noop)
									.catch(noop);
							},
						},
					]}
				/>
			)}
		</div>
	);
}

export default InstrumentCustomizationTable;

function InstrumentSpreadsheetPage({
	rows,
	multiSelectCtx,
	onChangeCell,
	columnsMetadata,
	createUserColumn,
	updateUserColumn,
	deleteUserColumn,
	changeTableLayout,
	onGenerateCommentary,
	onRemoveTaggedCell: _onRemoveTaggedCell,
	...forward
}: Pick<BaseHScrollTableProps<UserInstrumentDto, string>, "rows" | "orderBy" | "onOrderByChange"> & {
	multiSelectCtx: MultiSelectCtx<string>;
	onChangeCell(rowId: string, kind: keyof InstrumentSpreadsheetCellTypeMap, data: Partial<UserInstrumentDto>): void;
	columnsMetadata: UserColumnMetadata[];

	createUserColumn(columnMetadata: CreateClassification): Promise<void>;
	updateUserColumn(columnMetadata: UserInstrumentClassificationDto): Promise<void>;
	deleteUserColumn(classificationUuid: string): Promise<void>;
	changeTableLayout(columnPreferences: UserColumnMetadata[]): Promise<void>;
	onGenerateCommentary(tickerName: string): Promise<UserInstrumentDto>;
	onRemoveTaggedCell(tickerName: string, tag: Tag): void;
}) {
	const spreadsheetCtx = useSpreadsheet<Record<string, InstrumentSpreadsheetCellType>>(
		() =>
			dbg(
				Object.fromEntries(
					rows.map(({ tickerName, tags, qualities, scores, name, description, riskModel, alias, type }) => [
						tickerName,
						Object.fromEntries([
							...(qualities ?? []).flatMap((quality) =>
								!quality.classificationUuid
									? []
									: [
											[
												quality.classificationUuid,
												{ kind: "quality", ...quality } satisfies InstrumentSpreadsheetCellTypeMap["quality"],
											],
									  ],
							),
							...(scores ?? []).flatMap((score) =>
								!score.classificationUuid
									? []
									: [
											[
												score.classificationUuid,
												{ kind: "score", ...score } satisfies InstrumentSpreadsheetCellTypeMap["score"],
											],
									  ],
							),
							...(tags ?? []).flatMap((tag) =>
								!tag.classificationUuid
									? []
									: [
											[
												tag.classificationUuid,
												{
													kind: "tagWeightMap",
													classificationUuid: tag.classificationUuid,
													tagWeightMap: Object.fromEntries(
														(tag.values ?? []).map(({ option, weight }) => [option, weight]),
													),
												} satisfies InstrumentSpreadsheetCellTypeMap["tagWeightMap"],
											],
									  ],
							),
							[
								"NAME",
								{
									kind: "name",
									text: name ?? "",
								} satisfies InstrumentSpreadsheetCellTypeMap["name"],
							],
							[
								"IDENTIFIER",
								{
									kind: "identifier",
									text: alias ?? "",
								} satisfies InstrumentSpreadsheetCellTypeMap["identifier"],
							],
							[
								"DESCRIPTION",
								{
									kind: "description",
									description,
									canGenerateInstrumentDescription: type === "MULTI_ASSET" || type === "SINGLE_STOCK",
								} satisfies InstrumentSpreadsheetCellTypeMap["description"],
							],
							[
								"RISK_MODEL",
								{
									kind: "riskModel",
									proxies: riskModel,
									tickerName: tickerName!,
								} satisfies InstrumentSpreadsheetCellTypeMap["riskModel"],
							],
						]),
					]),
				),
			),
		{
			onChangeCell(tickerName, classificationUuid, anyCell) {
				const original = rows.find((r) => r.tickerName === tickerName);
				if (!original) {
					console.warn(`missing row with tickerName ${tickerName}`);
					return;
				}

				// Workaround because BE doesn't support persisting partial data. It should be
				// removed once possible as it causes data loss if the user changes page and/or filters.
				if (
					anyCell.kind === "tagWeightMap" &&
					(Math.abs(approxRound(sumArrayLike(Object.values(anyCell.tagWeightMap), identity), 2)) > 100 ||
						Math.abs(approxRound(sumArrayLike(Object.values(anyCell.tagWeightMap), identity), 2)) < 0)
				) {
					return;
				}

				if (anyCell.kind === "description" && anyCell.description?.status === "CALCULATING") {
					return;
				}

				onChangeCell(
					tickerName,
					anyCell.kind,
					match(anyCell)
						.with({ kind: "score" }, (cell) => ({
							scores: [
								{
									classificationUuid,
									value: cell.value ?? undefined,
								},
							],
						}))
						.with({ kind: "quality" }, (cell) => ({
							qualities: [
								{
									classificationUuid,
									value: cell.value ?? undefined,
								},
							],
						}))
						.with({ kind: "tagWeightMap" }, (cell) => ({
							tags: [
								{
									classificationUuid,
									values: Object.entries(cell.tagWeightMap).map(([option, weight]) => ({ option, weight })),
								},
							],
						}))
						.with({ kind: "name" }, (cell) => ({
							name: cell.text,
						}))
						.with({ kind: "identifier" }, (cell) => ({
							alias: cell.text,
						}))
						.with({ kind: "description" }, (cell) => ({
							description: cell.description,
						}))
						.with({ kind: "riskModel" }, (cell) => ({
							riskModel: cell.proxies,
						}))
						.exhaustive(),
				);
			},
		},
	);

	useEventBus("instrument-commentary-update", ({ description, descriptionStatus, isin }) => {
		if (!isin) {
			return;
		}

		const cellData = spreadsheetCtx.getState().dataByRow[isin /** tickerName */][
			"DESCRIPTION"
		] as InstrumentSpreadsheetCellTypeMap["description"];
		spreadsheetCtx.getState().update(
			isin /** tickerName */,
			"DESCRIPTION",
			{
				...cellData,
				kind: "description",
				description: { status: descriptionStatus, value: description, date: new Date().toISOString() },
			},
			{ skipChangeEvents: true },
		);
	});

	const requestAiCommentary = useCallback(
		async (row: UserInstrumentDto) => {
			const instrument = spreadsheetCtx.getState().dataByRow[row.tickerName!];
			const cellData = instrument["DESCRIPTION"] as InstrumentSpreadsheetCellTypeMap["description"];
			try {
				spreadsheetCtx.getState().update(row.tickerName!, "DESCRIPTION", {
					...cellData,
					kind: "description",
					description: { status: "CALCULATING" },
				});
				await onGenerateCommentary(row.tickerName!);
			} catch (error) {
				//idk
				const instrumentName = (() => {
					const nameColumn = instrument["NAME"];
					if (nameColumn.kind === "name") {
						return nameColumn.text;
					}

					return "";
				})();

				//idk
				const instrumentDescription = (() => {
					const nameColumn = instrument["DESCRIPTION"];
					if (nameColumn.kind === "description") {
						return nameColumn.description;
					}
				})();

				platformToast({
					children: `Unable to generate the commentary for ${instrumentName} instrument`,
					icon: "Portfolio",
					severity: "error",
				});

				if (instrumentDescription?.status === "CALCULATING") {
					spreadsheetCtx.getState().update(row.tickerName!, "DESCRIPTION", {
						...cellData,
						kind: "description",
						description: { ...(instrumentDescription ?? {}), status: "ERROR" },
					});
				}
			}
		},
		[onGenerateCommentary, spreadsheetCtx],
	);

	const {
		customizableColumns: instrumentColumns,
		tools,
		name,
	} = useInstrumentsColumn({
		allColumns: columnsMetadata,
		mode: "EDIT",
		spreadsheetCtx,
		changeTableLayout,
		createUserColumn,
		updateUserColumn,
		// TODO: uncomment once BE fixes their concurrency issue
		// deleteUserColumn,
		requestAiCommentary,
	});

	const {
		column: checkBoxColumn,
		rowClassList,
		toggle,
	} = useSelectableTableColumn({
		rows,
		multiSelectCtx,
		selectBy: (row) => row.tickerName!,
		mode: "checkbox",
	});

	const columns = useMemo<TableColumn<UserInstrumentDto>[]>(
		() => [checkBoxColumn, name, ...instrumentColumns, tools],
		[checkBoxColumn, name, instrumentColumns, tools],
	);

	return (
		<BaseHScrollTable
			canMultiSort={false}
			highlightRowOnHover={false}
			classList="flex-1 z-0"
			palette="uniform"
			rows={rows}
			{...forward}
			columns={columns}
			rowClassList={(row, rowIndex) => rowClassList(row, rowIndex)}
			pinnedColumns={[
				{
					name: name.name,
					side: "left",
				},
				{
					name: tools.name,
					side: "right",
				},
			]}
			onRowClick={({ tickerName }) => toggle(tickerName!)}
		/>
	);
}

export type InstrumentSpreadsheetCellTypePayloadMap = {
	score: Score;
	quality: Quality;
	tagWeightMap: { classificationUuid: string; tagWeightMap: InstrumentTagWeightMap };
	name: { text: string };
	identifier: { text: string };
	description: { description: Description | undefined; canGenerateInstrumentDescription: boolean };
	riskModel: {
		tickerName: string;
		proxies?: RichRiskModelValue[];
	};
};

export type InstrumentSpreadsheetCellTypeMap = {
	[K in keyof InstrumentSpreadsheetCellTypePayloadMap]: InstrumentSpreadsheetCellTypePayloadMap[K] & { kind: K };
};

export type InstrumentSpreadsheetCellType = InstrumentSpreadsheetCellTypeMap[keyof InstrumentSpreadsheetCellTypeMap];
