import type {
	InvestmentActionHistorySummaryEntry,
	InvestmentActionHistorySummaryEntryInvestmentActionHistoryEnum,
	InvestmentSummary,
	RichAccessControl,
} from "$root/api/api-gen";
import {
	InvestmentControllerV4ApiFactory,
	InvestmentExportControllerApiFactory,
	InvestmentReportsControllerApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import type { DefaultTemplateId } from "$root/components/EvolvedPrint/configuration/hooks/useExtractReports";
import type {
	InlineTextFormImperativeHandles,
	InlineTextFormMode,
} from "$root/components/smart-text-input/InlineTextForm";
import { InlineTextForm } from "$root/components/smart-text-input/InlineTextForm";
import { spawnYesNoDialog } from "$root/components/spawnable/yes-no-dialog";
import { UserCircle, UserCircleWithTooltip } from "$root/functional-areas/acl/UserCircle";
import { userCirclePaletteByRole } from "$root/functional-areas/acl/UsersList";
import { aclByArea, roleFromAcl } from "$root/functional-areas/acl/checkers/all";
import { downloadSinglePdf } from "$root/functional-areas/pdf";
import { useUserValue } from "$root/functional-areas/user";
import { useLocaleFormatters } from "$root/localization/hooks";
import {
	PortfolioQueryWidgetBase,
	WidgetStatus,
	portfolioWidgetMissingDataReason,
} from "$root/pages/PortfolioDetails/PortfolioWidgetStatus";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import type { ContextContent, MaybeAsync } from "$root/utils";
import { downloadContentDisposition, qualifier, useQueryNoRefetch, withContext } from "$root/utils";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import type { StylableProps } from "@mdotm/mdotui/components";
import {
	AsyncButton,
	Button,
	CollapsibleBase,
	Dialog,
	DialogFooter,
	DropdownMenu,
	ScrollWrapper,
	StackingContext,
	Text,
	TinyIconButton,
} from "@mdotm/mdotui/components";
import type { ImperativeHandleRefProps, SpawnResult } from "@mdotm/mdotui/react-extensions";
import { ForEach, adaptAnimatedNodeProvider, spawn, toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor, groupBy, stableEmptyArray } from "@mdotm/mdotui/utils";
import { useCallback, useMemo, useRef, useState } from "react";
import { flushSync } from "react-dom";
import { useTranslation } from "react-i18next";
import { PortfolioContext } from "../contexts/portfolio";
import { InfoTooltip } from "../layout/WidgetsMapper/InfoTooltip";

const transitionDurationMs = 400;

export type PortfolioHistoryEventType = InvestmentActionHistorySummaryEntryInvestmentActionHistoryEnum;

export type NotePayload = {
	author: { name?: string; surname?: string; email?: string };
	note: string;
	date: string;
	status: "MODIFIED" | "CREATED";
};

export type PortfolioHistoryEvent = {
	date: string;
	type: PortfolioHistoryEventType;
	id: string;
	author?: RichAccessControl;
	note?: NotePayload;
	hasUniverseSnapshot?: boolean;
	hasReferenceSnapshot?: boolean;
	marketViewEmbedded?: boolean;
	subPortfolios?: Array<{
		title: string;
		weight: number;
		id: string;
	}>;
};

export type NoteEvent = NotePayload & {
	id: string;
};

function isNoteEvent(event: PortfolioHistoryEvent | NoteEvent): event is NoteEvent {
	return !("type" in event);
}
function isPortfolioHistoryEvent(event: PortfolioHistoryEvent | NoteEvent): event is PortfolioHistoryEvent {
	return "type" in event;
}

function NoteInlineForm({
	classList,
	style,
	notePayload,
	mode = "view",
	onEdit,
	onDelete,
	canEdit,
	handleRef,
}: {
	notePayload?: NotePayload;
	mode?: InlineTextFormMode;
	onEdit: MaybeAsync<void, [string]>;
	onDelete: MaybeAsync<void>;
	canEdit: boolean;
} & StylableProps &
	ImperativeHandleRefProps<InlineTextFormImperativeHandles>) {
	const { formatDate } = useLocaleFormatters();
	return (
		<div className={toClassName(classList)} style={style}>
			{notePayload && (
				<>
					<Text type="Body/M/Bold" color={themeCSSVars.palette_N800}>
						Note
					</Text>{" "}
					<Text type="Body/M/Book" color={themeCSSVars.palette_N500}>
						({notePayload.status === "CREATED" ? "Created" : "Edited"} by {notePayload.author.name}{" "}
						{notePayload.author.surname} on {formatDate(notePayload.date)})
					</Text>
				</>
			)}
			<InlineTextForm
				handleRef={handleRef}
				noDataText=""
				rows={5}
				classList="w-full flex items-start min-h-[56px] !py-0"
				mode={mode}
				value={notePayload?.note}
				canEdit={canEdit}
				onEdit={onEdit}
				onDelete={onDelete}
			/>
		</div>
	);
}

function NoteCollapsible({
	expand,
	...forward
}: {
	notePayload?: NotePayload;
	expand: boolean;
	onEdit: MaybeAsync<void, [string]>;
	onDelete: MaybeAsync<void>;
	canEdit: boolean;
} & ImperativeHandleRefProps<InlineTextFormImperativeHandles>) {
	return (
		<CollapsibleBase expand={expand} transitionDuration={transitionDurationMs}>
			<NoteInlineForm
				mode={forward.notePayload || !forward.canEdit ? "view" : "edit"}
				classList={`px-1.5 py-1 bg-[${themeCSSVars.palette_N20}]`}
				{...forward}
			/>
		</CollapsibleBase>
	);
}

export const typeToStringMap: Record<PortfolioHistoryEventType, { title: string; downloadText?: string }> = {
	UPLOAD: { title: "Upload", downloadText: "Composition" },
	CREATION: { title: "Creation", downloadText: "Composition" },
	ENHANCEMENT: { title: "Proposal accepted", downloadText: "Composition & trades" },
	OPTIMIZATION: { title: "Optimization accepted", downloadText: "Composition & trades" },
	EDIT_PTF_SETTINGS: { title: "Portfolio Settings Edited", downloadText: "Composition & trades" },
	EDIT_REFERENCE: { title: "Reference Edited", downloadText: "Reference download" },
	EDIT_UNIVERSE: { title: "Universe Edited", downloadText: "Universe download" },
	EDIT: { title: "Edit composition", downloadText: "Composition & trades" },
};
const mixedPortfolioTitle = "Creation based on mixing instruments and the following portfolio compositions:";

function EventViewer({
	event,
	user,
	portfolio,
	onDownload,
	onDownloadComposition,
	onDownloadMixItem,
	onDownloadSphereFormat,
	variant,
	onEdit,
	onDelete,
	onDownloadUniverse,
	onDownloadEditMarketView,
	onDownloadEditReference,
	onDownloadEditedSphere,
	onDownloadEditUniverse,
	onDownloadMarketView,
	onDownloadReference,
}: {
	event: PortfolioHistoryEvent | NoteEvent;
	user: { id: string };
	portfolio: InvestmentSummary;
	onDownload?(event: PortfolioHistoryEvent): Promise<void>;
	onDownloadSphereFormat?(event: PortfolioHistoryEvent): Promise<void>;
	onDownloadComposition?(event: PortfolioHistoryEvent, format: "xls" | "pdf"): Promise<void>;
	onDownloadMixItem?(event: PortfolioHistoryEvent, mixItemId: string): Promise<void>;
	onDownloadUniverse?(event: PortfolioHistoryEvent): Promise<void>;
	onDownloadMarketView?(event: PortfolioHistoryEvent): Promise<void>;
	onDownloadReference?(event: PortfolioHistoryEvent): Promise<void>;
	onDownloadEditReference?(event: PortfolioHistoryEvent): Promise<void>;
	onDownloadEditMarketView?(event: PortfolioHistoryEvent): Promise<void>;
	onDownloadEditedSphere?(event: PortfolioHistoryEvent): Promise<void>;
	onDownloadEditUniverse?(event: PortfolioHistoryEvent): Promise<void>;
	variant: "collapsible" | "expanded";
	onEdit: MaybeAsync<void, [string]>;
	onDelete: MaybeAsync<void>;
}): JSX.Element {
	const { formatDate } = useLocaleFormatters();
	const [expandNote, setExpandNote] = useState(variant === "expanded");

	const notePayload = isNoteEvent(event) ? event : event.note;
	const [showExpandedEditor, setShowExpandedEditor] = useState(false);
	const inlineFormHandlesRef = useRef<InlineTextFormImperativeHandles | null>(null);

	return (
		<>
			<div className="flex">
				<div className="min-h-[40px] w-[40px] min-w-[40px] pt-1.5">
					<Text type="Body/S/Book" classList="ml-2" color={themeCSSVars.palette_N400}>
						{formatDate(event.date, { omitYear: true })}
					</Text>
				</div>
				<div className="min-h-[40px] w-[40px] min-w-[40px] px-[10px] pt-1">
					{event.author ? (
						<UserCircleWithTooltip
							position="left"
							variant="name"
							palette={
								isNoteEvent(event)
									? // TODO: handle note without event (we need the author's userId from BE)
									  userCirclePaletteByRole.VIEWER(user.id, user.id)
									: userCirclePaletteByRole[
											roleFromAcl("portfolio", event.author.userId!, portfolio.richAcl?.acl ?? [])
									  ](user.id, event.author.userId!)
							}
							firstName={event.author.name ?? "-"}
							lastName={event.author.surname ?? "-"}
							size="small"
						/>
					) : (
						<UserCircle palette="dashed" variant="add" classList="invisible" />
					)}
				</div>
				<div className="min-h-[40px] min-w-0 grow">
					<div className="min-w-0 flex items-start relative">
						<div className="grow flex space-x-2 flex-wrap pt-2">
							<div
								className={toClassName({
									[`text-[color:${themeCSSVars.palette_N800}]`]: true,
								})}
							>
								<Text type="Body/M/Bold">
									{isNoteEvent(event)
										? "New note"
										: event.subPortfolios
										  ? mixedPortfolioTitle
										  : typeToStringMap[event.type]?.title ?? "-"}
								</Text>
							</div>

							{isPortfolioHistoryEvent(event) &&
								(event.subPortfolios ? (
									<div className="w-full">
										{onDownloadComposition && (
											<>
												<AsyncButton
													onClickAsync={() => onDownloadComposition(event, "xls")}
													unstyled
													classList="underline"
												>
													{({ loading }) => (
														<Text
															type="Body/S/Link"
															color={themeCSSVars.palette_N400}
															classList={{
																"animate-pulse": loading,
															}}
														>
															Composition (xls)
														</Text>
													)}
												</AsyncButton>
												<br />
												<AsyncButton
													onClickAsync={() => onDownloadComposition(event, "pdf")}
													unstyled
													classList="underline"
												>
													{({ loading }) => (
														<Text
															type="Body/S/Link"
															color={themeCSSVars.palette_N400}
															classList={{
																"animate-pulse": loading,
															}}
														>
															Composition & exposure contribution (pdf)
														</Text>
													)}
												</AsyncButton>
											</>
										)}
										<ul className="pt-1 -ml-2 mb-1">
											<ForEach collection={event.subPortfolios}>
												{({ item: subPortfolio }) => (
													<li>
														<span className="text-[12px] font-bold">{subPortfolio.title}</span>{" "}
														{/* <span className="text-[12px] mr-[16px]">({formatNumber(mixItem.weight, 0)}%)</span> */}
														{onDownloadMixItem && (
															<AsyncButton
																onClickAsync={() => onDownloadMixItem(event, subPortfolio.id)}
																unstyled
																classList="underline"
															>
																{({ loading }) => (
																	<Text
																		type="Body/S/Link"
																		color={themeCSSVars.palette_N400}
																		classList={{
																			"animate-pulse": loading,
																		}}
																	>
																		Composition
																	</Text>
																)}
															</AsyncButton>
														)}
													</li>
												)}
											</ForEach>
										</ul>
									</div>
								) : (
									<div>
										<DropdownMenu
											trigger={({ open, innerRef, ...forward }) => (
												<span
													ref={innerRef}
													{...forward}
													aria-expanded={open}
													className="absolute top-[6px]"
													data-qualifier={`PortfolioHistory/Portfolio(${portfolio.uuid})/History(${event.id})`}
												>
													<TinyIconButton icon="Dowload" type="button" classList="!p-0" />
												</span>
											)}
											actions={
												event.type === "EDIT_PTF_SETTINGS"
													? [
															{
																label: "Sphere template",
																onClickAsync: () => onDownloadEditedSphere?.(event),
																"data-qualifier": `PortfolioHistory/Portfolio(${portfolio.uuid})/History(${event.id})/DropdownItem(Sphere template)`,
															},
															event.hasUniverseSnapshot
																? {
																		label: "Universe",
																		onClickAsync: () => onDownloadEditUniverse?.(event),
																		"data-qualifier": `PortfolioHistory/Portfolio(${portfolio.uuid})/History(${event.id})/DropdownItem(Edit Universe)`,
																  }
																: null,
															event.hasReferenceSnapshot
																? {
																		label: "Reference",
																		onClickAsync: () => onDownloadEditReference?.(event),
																		"data-qualifier": `PortfolioHistory/Portfolio(${portfolio.uuid})/History(${event.id})/DropdownItem(Edit Reference)`,
																  }
																: null,
															event.marketViewEmbedded
																? null
																: {
																		label: "Market View",
																		onClickAsync: () => onDownloadEditMarketView?.(event),
																		"data-qualifier": `PortfolioHistory/Portfolio(${portfolio.uuid})/History(${event.id})/DropdownItem(Edit Market View)`,
																  },
													  ]
													: event.type === "EDIT_REFERENCE"
													  ? [
																{
																	label: "Reference",
																	onClickAsync: () => onDownloadEditReference?.(event),
																	"data-qualifier": `PortfolioHistory/Portfolio(${portfolio.uuid})/History(${event.id})/DropdownItem(Edit Reference)`,
																},
													    ]
													  : event.type === "EDIT_UNIVERSE"
													    ? [
																	{
																		label: "Universe",
																		onClickAsync: () => onDownloadEditUniverse?.(event),
																		"data-qualifier": `PortfolioHistory/Portfolio(${portfolio.uuid})/History(${event.id})/DropdownItem(Edit Universe)`,
																	},
													      ]
													    : [
																	{
																		label: typeToStringMap[event.type]?.downloadText ?? "",
																		onClickAsync: () => onDownload?.(event),
																		"data-qualifier": `PortfolioHistory/Portfolio(${portfolio.uuid})/History(${
																			event.id
																		})/DropdownItem(${typeToStringMap[event.type]?.downloadText ?? ""})`,
																	},
																	{
																		label: "Sphere template",
																		onClickAsync: () => onDownloadSphereFormat?.(event),
																		"data-qualifier": `PortfolioHistory/Portfolio(${portfolio.uuid})/History(${event.id})/DropdownItem(Sphere template)`,
																	},
																	event.hasUniverseSnapshot
																		? {
																				label: "Universe",
																				onClickAsync: () => onDownloadUniverse?.(event),
																				"data-qualifier": `PortfolioHistory/Portfolio(${portfolio.uuid})/History(${event.id})/DropdownItem(Universe)`,
																		  }
																		: null,
																	event.hasReferenceSnapshot
																		? {
																				label: "Reference",
																				onClickAsync: () => onDownloadReference?.(event),
																				"data-qualifier": `PortfolioHistory/Portfolio(${portfolio.uuid})/History(${event.id})/DropdownItem(Reference template)`,
																		  }
																		: null,
																	event.marketViewEmbedded
																		? null
																		: {
																				label: "Market View",
																				onClickAsync: () => onDownloadMarketView?.(event),
																				"data-qualifier": `PortfolioHistory/Portfolio(${portfolio.uuid})/History(${event.id})/DropdownItem(Market View)`,
																		  },
													      ]
											}
										/>
									</div>
								))}
						</div>

						{variant === "collapsible" ? (
							<div className="flex flex-col h-[40px] self-end justify-end transition-[colors] relative z-0">
								<div
									className={toClassName({
										[`absolute z-10 -bottom-1 inset-x-px pointer-events-none h-2 bg-[color:${themeCSSVars.palette_N20}]`]:
											true,
										hidden: !expandNote,
									})}
								/>
								<div
									className={toClassName({
										border: true,
										"border-transparent": !expandNote,
										[`border-[${themeCSSVars.palette_N200}] !bg-[color:${themeCSSVars.palette_N20}]`]: expandNote,
									})}
								>
									{!notePayload && aclByArea.portfolio.canEditNotes(user.id, portfolio.richAcl?.acl ?? []) ? (
										<button
											type="button"
											className={toClassName({
												[`px-1 py-2`]: true,
											})}
											onClick={() => {
												flushSync(() => {
													setExpandNote((e) => !e);
												});
												inlineFormHandlesRef.current?.focus();
											}}
											data-qualifier={`PortfolioHistory/Portfolio(${portfolio.uuid})/History(${event.id})/AddNote`}
										>
											<AddNoteIcon />
										</button>
									) : notePayload ? (
										<button
											type="button"
											style={{
												transitionDuration: `${transitionDurationMs}ms`,
												transform: `rotateX(${!expandNote ? 0 : 180}deg)`,
											}}
											className={toClassName({
												[`px-1 py-2 transition-[opacity,transform]`]: true,
											})}
											onClick={() => {
												setExpandNote((e) => !e);
											}}
											data-qualifier={`PortfolioHistory/Portfolio(${portfolio.uuid})/History(${event.id})/EditNote`}
										>
											<NoteIcon />
										</button>
									) : (
										<></>
									)}
								</div>
							</div>
						) : (
							!notePayload?.note &&
							aclByArea.portfolio.canEditNotes(user.id, portfolio.richAcl?.acl ?? []) && (
								<div className="flex flex-col h-[40px] justify-end transition-[colors] relative z-0">
									<button
										type="button"
										className={toClassName({
											[`px-1 py-2`]: true,
										})}
										onClick={() => {
											flushSync(() => {
												setShowExpandedEditor((e) => !e);
											});
											inlineFormHandlesRef.current?.focus();
										}}
										data-qualifier={`PortfolioHistory/Portfolio(${portfolio.uuid})/History(${event.id})/AddNote`}
									>
										<AddNoteIcon />
									</button>
								</div>
							)
						)}
					</div>
					{variant === "expanded" && (showExpandedEditor || notePayload?.note) && (
						<NoteInlineForm
							handleRef={inlineFormHandlesRef}
							classList="pr-1"
							notePayload={notePayload}
							mode={
								showExpandedEditor && aclByArea.portfolio.canEditNotes(user.id, portfolio.richAcl?.acl ?? [])
									? "edit"
									: "view"
							}
							onEdit={onEdit}
							onDelete={onDelete}
							canEdit={aclByArea.portfolio.canEditNotes(user.id, portfolio.richAcl?.acl ?? [])}
						/>
					)}
				</div>
			</div>
			{variant === "collapsible" && (
				<div
					className={toClassName({
						"border-t": true,
						"border-transparent": !expandNote,
						[`border-[${themeCSSVars.palette_N200}]`]: expandNote,
					})}
				>
					<NoteCollapsible
						handleRef={inlineFormHandlesRef}
						notePayload={notePayload}
						expand={expandNote}
						onEdit={onEdit}
						onDelete={onDelete}
						canEdit={aclByArea.portfolio.canEditNotes(user.id, portfolio.richAcl?.acl ?? [])}
					/>
				</div>
			)}
		</>
	);
}

export type PortfolioHistoryListProps = {
	events: Array<PortfolioHistoryEvent | NoteEvent>;
	portfolio: InvestmentSummary;
	user: { id: string };
	onDownload?(event: PortfolioHistoryEvent): Promise<void>;
	onDownloadComposition?(event: PortfolioHistoryEvent, format: "xls" | "pdf"): Promise<void>;
	onDownloadMixItem?(event: PortfolioHistoryEvent, mixItemId: string): Promise<void>;
	onDownloadSphereFormat?(event: PortfolioHistoryEvent): Promise<void>;
	onDownloadUniverse?(event: PortfolioHistoryEvent): Promise<void>;
	onDownloadMarketView?(event: PortfolioHistoryEvent): Promise<void>;
	onDownloadReference?(event: PortfolioHistoryEvent): Promise<void>;
	onDownloadEditReference?(event: PortfolioHistoryEvent): Promise<void>;
	onDownloadEditMarketView?(event: PortfolioHistoryEvent): Promise<void>;
	onDownloadEditedSphere?(event: PortfolioHistoryEvent): Promise<void>;
	onDownloadEditUniverse?(event: PortfolioHistoryEvent): Promise<void>;
	variant: "collapsible" | "expanded";
	onEditNote: MaybeAsync<void, [/* historyItemId */ string, /* note */ string]>;
	onDeleteNote: MaybeAsync<void, [/* historyItemId */ string]>;
};

export function PortfolioHistoryList({
	user,
	portfolio,
	events,
	onDownload,
	onDownloadComposition,
	onDownloadMixItem,
	variant,
	onEditNote,
	onDeleteNote,
	onDownloadSphereFormat,
	onDownloadUniverse,
	onDownloadEditReference,
	onDownloadEditMarketView,
	onDownloadEditedSphere,
	onDownloadEditUniverse,
	onDownloadMarketView,
	onDownloadReference,
}: PortfolioHistoryListProps): JSX.Element {
	const eventGroupedByYear = useMemo(() => {
		const entries = Object.entries(groupBy(events, (event) => new Date(event.date).getFullYear().toString())).sort(
			builtInSortFnFor("0", "desc"),
		);
		entries.forEach(([, groupEvents]) => groupEvents?.sort(builtInSortFnFor("date", "desc")));
		return entries;
	}, [events]);
	return (
		<div className="w-full pr-1">
			<ForEach collection={eventGroupedByYear}>
				{({ item: [year, eventsEntry] }) => (
					<div className="mb-4">
						<div className={`rounded px-2 py-1 mb-1 bg-[${themeCSSVars.palette_N50}] ml-[36px] w-fit`}>
							<Text type="Body/L/Bold" as="span" color={themeCSSVars.palette_N400}>
								{year}
							</Text>
						</div>
						<div className="relative z-0 space-y-2">
							<div className="absolute z-0 -top-1 bottom-5 ml-[61px] border-r border-dashed border-[#A0A7B6]" />
							<ForEach collection={eventsEntry ?? []} keyProvider={(event, i) => event.id + event.date + i}>
								{({ item: event }) => (
									<div className="relative z-10">
										<EventViewer
											event={event}
											onDownload={onDownload}
											onDownloadComposition={onDownloadComposition}
											onDownloadMixItem={onDownloadMixItem}
											portfolio={portfolio}
											user={user}
											variant={variant}
											onEdit={(note) => onEditNote(event.id, note)}
											onDelete={() => onDeleteNote(event.id)}
											onDownloadSphereFormat={onDownloadSphereFormat}
											onDownloadUniverse={onDownloadUniverse}
											onDownloadEditReference={onDownloadEditReference}
											onDownloadEditMarketView={onDownloadEditMarketView}
											onDownloadEditUniverse={onDownloadEditUniverse}
											onDownloadEditedSphere={onDownloadEditedSphere}
											onDownloadMarketView={onDownloadMarketView}
											onDownloadReference={onDownloadReference}
										/>
									</div>
								)}
							</ForEach>
						</div>
					</div>
				)}
			</ForEach>
		</div>
	);
}

export function PortfolioHistoryBlockBase(
	props: Omit<PortfolioHistoryListProps, "variant" | "onEditNote" | "onDeleteNote"> & {
		onEditNote: MaybeAsync<(PortfolioHistoryEvent | NoteEvent)[], [/* historyItemId */ string, /* note */ string]>;
		onDeleteNote: MaybeAsync<(PortfolioHistoryEvent | NoteEvent)[], [/* historyItemId */ string]>;
	},
): JSX.Element {
	return (
		<div className="h-full flex flex-col">
			<ScrollWrapper direction="vertical" outerContainerAppearance={{ classList: "flex-1" }}>
				<PortfolioHistoryList
					{...props}
					onDeleteNote={async (...params) => {
						await props.onDeleteNote(...params);
					}}
					onEditNote={async (...params) => {
						await props.onEditNote(...params);
					}}
					variant="collapsible"
				/>
			</ScrollWrapper>
			<div className={`flex justify-end pt-2 mt-1 border-t border-[${themeCSSVars.palette_N200}]`}>
				<Button
					palette="primary"
					onClick={() => {
						spawnPortfolioHistoryDialog(props);
					}}
				>
					View all
				</Button>
			</div>
		</div>
	);
}

function Spawned({
	events: initialEvents,
	onDeleteNote,
	onEditNote,
	...forward
}: Omit<PortfolioHistoryListProps, "variant" | "onEditNote" | "onDeleteNote"> & { zIndex?: number } & {
	onEditNote: MaybeAsync<PortfolioHistoryListProps["events"], [/* historyItemId */ string, /* note */ string]>;
	onDeleteNote: MaybeAsync<PortfolioHistoryListProps["events"], [/* historyItemId */ string]>;
}) {
	const [events, setEvents] = useState(initialEvents);
	return (
		<PortfolioHistoryList
			{...forward}
			events={events}
			variant="expanded"
			onDeleteNote={async (...params) => {
				setEvents(await onDeleteNote(...params));
			}}
			onEditNote={async (...params) => {
				setEvents(await onEditNote(...params));
			}}
		/>
	);
}

export function spawnPortfolioHistoryDialog(
	params: Omit<PortfolioHistoryListProps, "variant" | "onEditNote" | "onDeleteNote"> & { zIndex?: number } & {
		onEditNote: MaybeAsync<PortfolioHistoryListProps["events"], [/* historyItemId */ string, /* note */ string]>;
		onDeleteNote: MaybeAsync<PortfolioHistoryListProps["events"], [/* historyItemId */ string]>;
	},
): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ resolve, show, onHidden }) => (
			<StackingContext.Consumer>
				{({ zIndex }) => (
					<StackingContext.Provider value={{ zIndex: (params.zIndex ?? zIndex) + 1 }}>
						<Dialog
							size="large"
							show={show}
							onAnimationStateChange={(state) => state === "hidden" && onHidden()}
							onClose={() => resolve()}
							header={`History & notes - ${params.portfolio.name ?? ""}`}
							footer={
								<DialogFooter
									neutralAction={
										<Button palette="tertiary" onClick={() => resolve()}>
											Close
										</Button>
									}
								/>
							}
						>
							<Spawned {...params} />
						</Dialog>
					</StackingContext.Provider>
				)}
			</StackingContext.Consumer>
		)),
	);
}

export function AddNoteIcon(): JSX.Element {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.6399 14.8067V13.0958C15.6399 12.7665 15.9127 12.4999 16.2551 12.4999C16.5974 12.4999 16.8702 12.7665 16.8702 13.1063V14.8067H18.543C18.8723 14.8067 19.1389 15.0795 19.1389 15.4218C19.1389 15.7642 18.8723 16.037 18.5325 16.037H16.8702V18.0554C16.8702 18.3847 16.5921 18.6514 16.2551 18.6514C15.9181 18.6514 15.6399 18.3847 15.6399 18.0554V16.037H13.9293C13.6 16.037 13.3334 15.7588 13.3334 15.4218C13.3334 15.0848 13.6 14.8067 13.9293 14.8067H15.6399Z"
				fill="#8792AB"
			/>
			<path
				d="M16.6666 9.16667V6.0763C16.6666 5.6837 16.4908 5.30667 16.1783 5.02889L13.8216 2.93407C13.5091 2.6563 13.085 2.5 12.6433 2.5H4.99998C4.07915 2.5 3.33331 3.16296 3.33331 3.98148V14.3519C3.33331 15.1704 4.07915 15.8333 4.99998 15.8333H10.8333"
				stroke="#8792AB"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16.6667 6.2037H13.3333C12.8733 6.2037 12.5 5.87185 12.5 5.46296V2.5"
				stroke="#8792AB"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export function NoteIcon(): JSX.Element {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M9.99977 12.4584C9.88866 12.4584 9.78449 12.4409 9.68727 12.4059C9.59004 12.3714 9.49977 12.3125 9.41643 12.2292L5.56227 8.37502C5.40949 8.22224 5.33671 8.03113 5.34393 7.80169C5.3506 7.5728 5.43032 7.38197 5.5831 7.22919C5.73588 7.07641 5.93032 7.00002 6.16643 7.00002C6.40254 7.00002 6.59699 7.07641 6.74977 7.22919L9.99977 10.4792L13.2706 7.20835C13.4234 7.05558 13.6145 6.98252 13.8439 6.98919C14.0728 6.99641 14.2637 7.07641 14.4164 7.22919C14.5692 7.38197 14.6456 7.57641 14.6456 7.81252C14.6456 8.04863 14.5692 8.24308 14.4164 8.39585L10.5831 12.2292C10.4998 12.3125 10.4095 12.3714 10.3123 12.4059C10.215 12.4409 10.1109 12.4584 9.99977 12.4584Z"
				fill="#8792AB"
			/>
		</svg>
	);
}

export function aggregateHistory(history: InvestmentActionHistorySummaryEntry[]): PortfolioHistoryEvent[] {
	return history.map(
		(action): PortfolioHistoryEvent => ({
			date: action.applicationDate!,
			type: action.investmentActionHistory!,
			id: action.actionHistoryUuid ?? "",
			author: action.actionAuthor,
			marketViewEmbedded: action.marketViewEmbedded,
			note: !action.note
				? undefined
				: {
						author: action.note.author!,
						date: action.note.date!,
						note: action.note.text!,
						status: action.note.status!,
				  },
			hasUniverseSnapshot: action.universeSnapshot,
			hasReferenceSnapshot: action.referenceSnapshot,
			subPortfolios:
				(action.subPortfolios ?? []).length === 0
					? undefined
					: action.subPortfolios?.map((subPortfolios) => ({
							title: subPortfolios.name ?? "-",
							weight: subPortfolios.weight ?? 0,
							id: subPortfolios.uuid ?? "",
					  })),
		}),
	);
}

const PortfolioHistoryBlock = (props: ContextContent<typeof PortfolioContext>) => {
	const uuid = props.portfolio?.uuid;
	const exportApi = useApiGen(InvestmentExportControllerApiFactory);
	const investmentReportApi = useApiGen(InvestmentReportsControllerApiFactory);
	const noteApi = useApiGen(InvestmentControllerV4ApiFactory);
	const user = useUserValue();

	const { t } = useTranslation();

	useWidgetOptions(
		() => ({
			actionHeader: <InfoTooltip>{t("PORTFOLIO_HISTORY.TOOLTIP")}</InfoTooltip>,
			title: (
				<Text
					type="Body/XL/Bold"
					title={t("PORTFOLIO_HISTORY.TITLE")}
					classList="truncate"
					data-qualifier={qualifier.widgets.portfolioHistory.name}
				>
					{t("PORTFOLIO_HISTORY.TITLE")}
				</Text>
			),
		}),
		[t],
	);

	const query = useQueryNoRefetch(["History", uuid], {
		queryFn: async () => {
			if (!uuid) {
				return {
					data: undefined,
					widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "PortfolioHistoryBlock"),
				};
			}

			const { data: historyData } = await investmentReportApi.getInvestmentActionHistorySummary(uuid);
			// return aggregateHistory(historyData);
			if (!historyData) {
				return {
					data: undefined,
					widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "PortfolioHistoryBlock"),
				};
			}

			return {
				data: aggregateHistory(historyData),
				widgetStatus: WidgetStatus.READY,
			};
		},
	});

	const handleDownload = useCallback(
		async (entry: { id: string }) => {
			const response = await exportApi.exportHistoryCompositionEntry(uuid ?? "", entry.id, undefined, {
				responseType: "blob",
			});
			downloadContentDisposition(response);
			trackMixPanelEvent("Portfolio", {
				Type: "Export",
				Area: `History composition`,
			});
		},
		[exportApi, uuid],
	);

	const handleDownloadSphereFormat = useCallback(
		async (event: PortfolioHistoryEvent) => {
			if (!uuid) {
				throw new Error("Missing uuid");
			}
			const response = await exportApi.exportHistoryPortfolio(uuid, event.id, {
				responseType: "blob",
			});
			downloadContentDisposition(response);
			trackMixPanelEvent("Portfolio", {
				Type: "Export",
				Area: `History composition`,
			});
		},
		[exportApi, uuid],
	);

	const handleDownloadUniverse = useCallback(
		async (event: PortfolioHistoryEvent) => {
			if (!uuid) {
				throw new Error("Missing uuid");
			}
			const response = await exportApi.exportHistoryUniverse(uuid, event.id, {
				responseType: "blob",
			});
			downloadContentDisposition(response);
			trackMixPanelEvent("Portfolio", {
				Type: "Export",
				Area: `History composition`,
			});
		},
		[exportApi, uuid],
	);

	const handleDownloadMixItem = useCallback(
		async (entry: { id: string }, mixItemId: string) => {
			const response = await exportApi.exportHistoryCompositionEntry(uuid ?? "", entry.id, mixItemId, {
				responseType: "blob",
			});
			downloadContentDisposition(response);
			trackMixPanelEvent("Portfolio", {
				Type: "Export",
				Area: `History composition`,
			});
		},
		[exportApi, uuid],
	);

	const exposureReportName = "Composition_and_exposure_contribution";
	const handleDownloadComposition = useCallback(
		(entry: { id: string }, format: "xls" | "pdf") => {
			switch (format) {
				case "xls":
					return handleDownload(entry);
				case "pdf":
					return downloadSinglePdf(exposureReportName, {
						templateId: "mixed-portfolio" as DefaultTemplateId,
						historyEventUuid: encodeURIComponent(entry.id!),
						objectId: uuid,
						name: exposureReportName,
					});
			}
		},
		[handleDownload, uuid],
	);

	const handleDownloadReference = useCallback(
		async (event: PortfolioHistoryEvent) => {
			if (!uuid) {
				throw new Error("Missing uuid");
			}
			const response = await exportApi.exportHistoryReference(uuid, event.id, {
				responseType: "blob",
			});
			downloadContentDisposition(response);
			trackMixPanelEvent("Portfolio", {
				Type: "Export",
				Area: `History composition`,
			});
		},
		[exportApi, uuid],
	);

	const handleDownloadMarketView = useCallback(
		async (event: PortfolioHistoryEvent) => {
			if (!uuid) {
				throw new Error("Missing uuid");
			}
			const response = await exportApi.exportHistoryMarketView(uuid, event.id, {
				responseType: "blob",
			});
			downloadContentDisposition(response);
			trackMixPanelEvent("Portfolio", {
				Type: "Export",
				Area: `History composition`,
			});
		},
		[exportApi, uuid],
	);

	const handleDownloadEditUniverse = useCallback(
		async (event: PortfolioHistoryEvent) => {
			if (!uuid) {
				throw new Error("Missing uuid");
			}
			const response = await exportApi.exportEditHistoryUniverse(uuid, event.id, {
				responseType: "blob",
			});
			downloadContentDisposition(response);
			trackMixPanelEvent("Portfolio", {
				Type: "Export",
				Area: `History composition`,
			});
		},
		[exportApi, uuid],
	);

	const handleDownloadEditReference = useCallback(
		async (event: PortfolioHistoryEvent) => {
			if (!uuid) {
				throw new Error("Missing uuid");
			}
			const response = await exportApi.exportEditReferenceInPortfolio(uuid, event.id, {
				responseType: "blob",
			});
			downloadContentDisposition(response);
			trackMixPanelEvent("Portfolio", {
				Type: "Export",
				Area: `History composition`,
			});
		},
		[exportApi, uuid],
	);

	const handleDownloadEditMarketView = useCallback(
		async (event: PortfolioHistoryEvent) => {
			if (!uuid) {
				throw new Error("Missing uuid");
			}
			const response = await exportApi.exportHistoryMarketView(uuid, event.id, {
				responseType: "blob",
			});
			downloadContentDisposition(response);
			trackMixPanelEvent("Portfolio", {
				Type: "Export",
				Area: `History composition`,
			});
		},
		[exportApi, uuid],
	);

	const handleDownloadEditedSphere = useCallback(
		async (event: PortfolioHistoryEvent) => {
			if (!uuid) {
				throw new Error("Missing uuid");
			}
			const response = await exportApi.exportEditPortfolio(uuid, event.id, {
				responseType: "blob",
			});
			downloadContentDisposition(response);
			trackMixPanelEvent("Portfolio", {
				Type: "Export",
				Area: `History composition`,
			});
		},
		[exportApi, uuid],
	);

	return (
		<PortfolioQueryWidgetBase query={query}>
			{(events, rq) => (
				<PortfolioHistoryBlockBase
					onDownload={handleDownload}
					onDownloadComposition={handleDownloadComposition}
					onDownloadMixItem={handleDownloadMixItem}
					onDownloadSphereFormat={handleDownloadSphereFormat}
					onDownloadUniverse={handleDownloadUniverse}
					onDownloadEditReference={handleDownloadEditReference}
					onDownloadEditMarketView={handleDownloadEditMarketView}
					onDownloadEditUniverse={handleDownloadEditUniverse}
					onDownloadEditedSphere={handleDownloadEditedSphere}
					onDownloadMarketView={handleDownloadMarketView}
					onDownloadReference={handleDownloadReference}
					events={events}
					portfolio={props.portfolio!}
					user={user}
					onEditNote={async (historyItemId, note) => {
						await noteApi.editInvestmentNote({
							historyUuid: historyItemId,
							investmentUuid: props.portfolio!.uuid,
							noteText: note,
						});
						return (await rq.refetch()).data?.data ?? stableEmptyArray;
					}}
					onDeleteNote={async (historyItemId) => {
						const shouldDelete = await spawnYesNoDialog({
							header: "Are you sure you want to delete this item?",
							children: (
								<p>This will permanently remove the note.{"\n"}Please be aware that this action cannot be undone.</p>
							),
						});
						if (shouldDelete) {
							await noteApi.removeInvestmentNote(props.portfolio!.uuid!, historyItemId);
							return aggregateHistory((await rq.refetch()).data?.data ?? stableEmptyArray);
						}
						return events;
					}}
				/>
			)}
		</PortfolioQueryWidgetBase>
	);
};

export default withContext(PortfolioContext)(PortfolioHistoryBlock);
