import type { InvestmentSummary } from "$root/api/api-gen";
import {
	InvestmentControllerV4ApiFactory,
	InvestmentCreationConfigurationControllerV4ApiFactory,
	InvestmentDraftConfigurationControllerV4ApiFactory,
	InvestmentEnhancementConfigurationControllerV4ApiFactory,
	InvestmentReportsControllerApiFactory,
	InvestmentsEnhancementStaticConfigurationControllerApiFactory,
	InvestmentsStaticConfigurationControllerApiFactory,
	StepAvailabilityStatus,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { useCrumbs } from "$root/components/Crumbs/useCrumbs";
import { LeavePrompt } from "$root/components/LeavePrompt";
import { PageHeader } from "$root/components/PageHeader";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import type {
	EditPortfolioAreaType,
	EditPortfolioV4Props,
} from "$root/components/Portfolio/CreatePortfolio/EditPortfolio";
import {
	EditPortfolioV4ContextProvider,
	EditPortfolioV4InContext,
} from "$root/components/Portfolio/CreatePortfolio/EditPortfolio";
import { makeStepToRequestMapper } from "$root/components/Portfolio/CreatePortfolio/requests";
import type { EditPortfolioV4StepPayloadMap } from "$root/components/Portfolio/CreatePortfolio/shared";
import { makeUnsavedDataHandler } from "$root/components/Portfolio/CreatePortfolio/shared";
import MonitorWithHourglass2 from "$root/components/glyphs/MonitorWithHourglass2";
import { useEventBus, waitForEvent } from "$root/event-bus";
import { aclByArea } from "$root/functional-areas/acl/checkers/all";
import { useDebouncedNameUniquenessChecker } from "$root/functional-areas/named-entities/uniqueness";
import { useUserValue } from "$root/functional-areas/user";
import { platformToast } from "$root/notification-system/toast";
import type { CustomAxiosError } from "$root/third-party-integrations/axios";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { queryClientKeys, typedQueryClient } from "$root/third-party-integrations/react-query";
import { FormFields } from "$root/ui-lib/form/FormFields";
import { customObjectEntriesFn, minutes, objMatchFn, useQueryNoRefetch } from "$root/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import type { DialogProps } from "@mdotm/mdotui/components";
import {
	AsyncButton,
	Button,
	CircularProgressBar,
	Dialog,
	DialogFooter,
	Icon,
	Modal,
	ProgressBar,
	SubmitButton,
} from "@mdotm/mdotui/components";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, spawn } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { noop, switchExpr } from "@mdotm/mdotui/utils";
import type { AxiosError } from "axios";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { match } from "ts-pattern";
import { z } from "zod";

/** New/Edit merged together */
export default function CreatePortfolio(): JSX.Element {
	const { t } = useTranslation();
	const history = useHistory();
	const { portfolioUid } = useParams<{
		portfolioUid?: string;
	}>();

	// a bit hacky, but will do for now
	const isSettings = history.location.pathname.includes("/settings/");
	const investementApi = useApiGen(InvestmentCreationConfigurationControllerV4ApiFactory);
	const investmentEnhanceApi = useApiGen(InvestmentEnhancementConfigurationControllerV4ApiFactory);
	const investmentReportsApi = useApiGen(InvestmentReportsControllerApiFactory);
	const investmentDraftConfigurationApi = useApiGen(InvestmentDraftConfigurationControllerV4ApiFactory);
	const investmentsStaticConfigurationApi = useApiGen(InvestmentsStaticConfigurationControllerApiFactory);
	const investmentsEnhancementStaticConfigurationApi = useApiGen(
		InvestmentsEnhancementStaticConfigurationControllerApiFactory,
	);

	const [showSaveAsDraftDialog, setShowSaveAsDraftDialog] = useState(false);
	const [showLoadingModal, setShowLoadingModal] = useState(false);

	const user = useUserValue();
	const { push } = useTypedNavigation();

	const portfolioSummary = useQueryNoRefetch<InvestmentSummary, AxiosError>({
		queryKey: ["queryPortfolioSummary"],
		enabled: portfolioUid !== undefined,
		queryFn: () => axiosExtract(investmentReportsApi.getInvestmentSummary(portfolioUid!)),
		onSuccess: (data) => {
			const canBeEnhanced = data.status !== "DRAFT";

			if (canBeEnhanced) {
				const canEditConfiguration = aclByArea.portfolio.canCreateProposal(user.id, data?.richAcl?.acl ?? []);

				if (canEditConfiguration === false) {
					return push("PortfolioDetails", { portfolioUid: data.uuid ?? "" });
				}
			}
		},
		onError(err) {
			console.error(err);
			push("PortfoliosStudio", {});
			platformToast({
				icon: "Icon-full-error",
				children: "Unable to access portfolio",
				severity: "error",
			});
		},
	});

	const { data: investmentSummary, refetch: refetchSummary } = portfolioSummary;
	const areaCtx = useMemo(():
		| {
				name: Extract<EditPortfolioAreaType, "create">;
				portfolioUid?: string;
		  }
		| {
				name: Exclude<EditPortfolioAreaType, "create">;
				portfolioUid: string;
		  } => {
		if (!investmentSummary || !portfolioUid) {
			return { name: "create", portfolioUid: undefined };
		}

		if (investmentSummary.status === "DRAFT") {
			return { name: "draft", portfolioUid };
		}

		if (isSettings) {
			// proposal settings are readonly
			return { name: "settings-current", portfolioUid };
		}

		return { name: "enhance", portfolioUid };
	}, [investmentSummary, isSettings, portfolioUid]);

	const area = match(areaCtx)
		.with({ name: "create" }, areaProvider())
		.with({ name: "enhance" }, areaProvider())
		.with({ name: "draft" }, areaProvider())
		.with({ name: "settings-enhanced" }, areaProvider({ edit: false, editable: false }))
		.with({ name: "settings-current" }, areaProvider())
		.exhaustive();

	useEventBus("investment-update", {
		filter: objMatchFn({ uuid: portfolioUid }),
		listener: () => {
			refetchSummary().then().catch(noop);
		},
	});

	const staticApi = useApiGen(InvestmentsStaticConfigurationControllerApiFactory);

	async function savePortfolio(stepsData: EditPortfolioV4StepPayloadMap) {
		if (!portfolioUid) {
			const { investmentUuid } = await axiosExtract(investementApi.runCreationConfiguration());
			trackMixPanelEvent("Portfolio", { Type: "Create" });
			typedQueryClient()
				.invalidateQueries([
					queryClientKeys.portfolioStudioInvestmentList,
					queryClientKeys.portfolioStudioProjectedInvestmentList,
					queryClientKeys.portfolioStudioKeyMetric,
				])
				.catch(noop);
			push("PortfolioDetails", { portfolioUid: investmentUuid! });
		} else if (area.name === "draft") {
			const { investmentUuid } = await axiosExtract(
				investmentDraftConfigurationApi.runDraftConfiguration(portfolioUid),
			);
			trackMixPanelEvent("Portfolio", { Type: "Create" });
			typedQueryClient()
				.invalidateQueries([
					queryClientKeys.portfolioStudioInvestmentList,
					queryClientKeys.portfolioStudioProjectedInvestmentList,
					queryClientKeys.portfolioStudioKeyMetric,
				])
				.catch(noop);
			push("PortfolioDetails", { portfolioUid: investmentUuid! });
		} else if (area.name === "enhance") {
			await investmentEnhanceApi.runEnhancementConfiguration(portfolioUid);
			trackMixPanelEvent("Portfolio", { ID: portfolioUid, Type: "Enhance" });
			push("PortfolioDetails", { portfolioUid });
		} else if (area.name === "settings-current") {
			const mapper = makeStepToRequestMapper(portfolioUid);
			await staticApi.setStaticConfiguration({
				mainInfo: mapper.mainInfo(stepsData.mainInfo),
				investableUniverse: mapper.investableUniverse({
					...stepsData.investableUniverse,
					investableUniverseSelectionStrategy:
						stepsData.investableUniverse.investableUniverseSelectionStrategy ?? "SELL_INSTRUMENTS",
				}),
				allocationConstraints: mapper.portfolioConstraints(stepsData.portfolioConstraints),
				strategyConstraints: mapper.portfolioStrategy(stepsData.portfolioStrategy),
				riskConstraints: mapper.riskConstraints(stepsData.riskConstraints),
				marketView: mapper.marketView(stepsData.marketView),
				investmentUuid: portfolioUid,
			});
			trackMixPanelEvent("Portfolio", { ID: portfolioUid, Type: "Settings" });
			typedQueryClient()
				.invalidateQueries([
					queryClientKeys.portfolioStudioInvestmentList,
					queryClientKeys.portfolioStudioProjectedInvestmentList,
					queryClientKeys.portfolioStudioKeyMetric,
				])
				.catch(noop);
			push("PortfolioDetails", { portfolioUid });
		}
	}

	async function submit(stepsData: EditPortfolioV4StepPayloadMap) {
		try {
			await savePortfolio(stepsData);
		} catch (error: unknown) {
			const axiosError = error as AxiosError<CustomAxiosError> | undefined;
			if (axiosError?.response?.data.code === 61011 || axiosError?.response?.data.code === 61018) {
				setShowLoadingModal(true);
				let isCalculating = true;
				while (isCalculating) {
					try {
						if (portfolioUid) {
							await waitForEvent("investment-draft-configuration-update", {
								filter: objMatchFn({ uuid: portfolioUid }),
								signal: AbortSignal.timeout(minutes(1.5)),
							});
						} else {
							await waitForEvent("investment-draft-configuration-update", {
								signal: AbortSignal.timeout(minutes(1.5)),
							});
						}

						await savePortfolio(stepsData);
						isCalculating = false;
					} catch (err) {
						const { availabilityMap } = await match(area)
							.with({ name: "create" }, () => axiosExtract(investementApi.getCreationConfigurationStepsAvailability()))
							.with({ name: "draft" }, (x) =>
								axiosExtract(investmentDraftConfigurationApi.getDraftConfigurationStepsAvailability(x.portfolioUid)),
							)
							.with({ name: "settings-enhanced" }, (x) =>
								axiosExtract(
									investmentsEnhancementStaticConfigurationApi.getStaticEnhancementConfiguration(x.portfolioUid),
								),
							)
							.with({ name: "settings-current" }, (x) =>
								axiosExtract(investmentsStaticConfigurationApi.getStaticConfiguration(x.portfolioUid)),
							)
							.with({ name: "enhance" }, (x) =>
								axiosExtract(investmentEnhanceApi.getEnhancementConfigurationStepsAvailability(x.portfolioUid)),
							)
							.exhaustive();

						const someSteAreCalculating = customObjectEntriesFn(availabilityMap).some(
							([_, stepStatus]) => stepStatus === StepAvailabilityStatus.Calculating,
						);

						const someSteAreError = customObjectEntriesFn(availabilityMap).some(
							([_, stepStatus]) => stepStatus === StepAvailabilityStatus.ReviewRequired,
						);

						if (someSteAreError || !someSteAreCalculating) {
							isCalculating = false;
							throw err;
						}

						if (err instanceof DOMException && err.name === "TimeoutError") {
							platformToast({
								children: "The request is taking too long, please retry",
								severity: "warning",
								icon: "Portfolio",
							});
							isCalculating = false;
							throw err;
						}
					}
				}

				return;
			}

			if (axiosError?.response?.data.code === 61033) {
				platformToast({
					children: "Unable to proceed while the universe is in review status",
					severity: "info",
					icon: "Portfolio",
				});
				return;
			}

			platformToast({
				children: match(area.name)
					.with("create", () => "Something went wrong while creating the portfolio")
					.with("draft", () => "Something went wrong while creating the portfolio")
					.with("enhance", () => "Something went wrong while enhance the portfolio")
					.with("settings-current", () => "Something went wrong while editing the portfolio")
					.with("settings-enhanced", () => "Something went wrong while editing the portfolio")
					.exhaustive(),
				severity: "error",
				icon: "Portfolio",
			});
			throw error;
		} finally {
			setShowLoadingModal(false);
		}
	}

	async function saveAsDraft(currentStep?: string) {
		try {
			const { investmentUuid } = await axiosExtract(investementApi.saveConfigurationAsDraft());
			if (investmentUuid) {
				history.push(`${typedUrlForRoute("Portfolios/Draft", { portfolioUid: investmentUuid })}#${currentStep ?? ""}`);
			} else {
				push("Portfolios/CreatePortfolio", {});
			}
			// push("PortfoliosStudio", {});
		} catch (error) {
			platformToast({
				children: "Something went wrong while creating portfolio draft",
				severity: "error",
				icon: "Portfolio",
			});
			throw error;
		}
	}

	async function onStartNew(
		refetch?: EditPortfolioV4Props["refetch"],
		refetchConfiguration?: EditPortfolioV4Props["refetchConfiguration"],
		setStepsData?: EditPortfolioV4Props["setStepsData"],
		currentArea?: EditPortfolioV4Props["area"],
	) {
		try {
			if (currentArea?.name === "enhance") {
				await investmentEnhanceApi.createDraftEnhancementConfiguration(currentArea.portfolioUid);
			}

			if (currentArea?.name === "create") {
				await investementApi.createDraftCreationConfiguration();
			}
			trackMixPanelEvent("Portfolio-Draft", {
				Type: currentArea?.portfolioUid ? "enhance" : "creation",
				UID: currentArea?.portfolioUid,
				Step: "global",
				Action: "reset",
			});
			setStepsData?.(null);
			await refetch?.();
			await refetchConfiguration?.();
		} catch (error) {
			console.error(error); //TODO: handle error
			throw error;
		}
	}

	function areaProvider(opts?: { edit: boolean; editable: boolean }) {
		return ({ name, portfolioUid: ptfUid }: { name: EditPortfolioAreaType; portfolioUid?: string }) =>
			name === "create"
				? {
						name,
						portfolioUid: undefined,
						...(opts ?? { edit: true, editable: true }),
				  }
				: {
						name,
						portfolioUid: ptfUid!,
						...(opts ?? { edit: true, editable: true }),
				  };
	}

	if (portfolioSummary.isFetching) {
		return <ProgressBar value="indeterminate" />;
	}

	return (
		<EditPortfolioV4ContextProvider area={area}>
			{function Block({
				stepsData,
				stepsMetadata,
				currentStep,
				setStepsMetadata,
				submittersRef,
				setCurrentStep,
				refetch,
				refetchConfiguration,
				setStepsData,
				getStepsData,
				getStepsMetadata,
				area: ctxArea,
				isDraftPresent,
			}) {
				const saveHandler = makeUnsavedDataHandler({
					currentStep,
					setStepsMetadata,
					submittersRef,
				});

				async function handleSubmit<T>(onSuccess: () => Promise<T> | T, onError: () => void) {
					if (stepsMetadata[currentStep].dirty === false) {
						await onSuccess();
						return;
					}

					const shouldContinue = (await saveHandler()) === "continue";
					if (!shouldContinue) {
						onError();
						throw new Error("unable to proceed, some step may require additional data or corrections");
					}
					await onSuccess();
				}

				function pushMissingFields(stepName: string) {
					platformToast({
						children: `Unable to proceed, ${stepName} step requires additional data or correction`,
						severity: "info",
						icon: "Portfolio",
					});
				}

				const crumbs = useCrumbs();

				return (
					<>
						<PageHeader
							title={switchExpr(area.name, {
								create: () => "Create portfolio",
								enhance: () => "Create proposal",
								"settings-current": () => "Portfolio settings",
								"settings-enhanced": () => "Portfolio settings",
								draft: () => "Draft",
							})}
							crumbsV2={[
								...crumbs.portfolioStudio("Portfolios"),
								...switchExpr(area.name, {
									create: () => crumbs.createPortfolio,
									enhance: () => crumbs.enhancePortfolio({ uuid: portfolioUid, name: stepsData?.mainInfo.name }),
									"settings-current": () =>
										crumbs.currentPortfolioSettings({ uuid: portfolioUid, name: stepsData?.mainInfo.name }),
									"settings-enhanced": () =>
										crumbs.enhancedPortfolioSettings({ uuid: portfolioUid, name: stepsData?.mainInfo.name }),
									draft: () => crumbs.draftPortfolioSettings(stepsData?.mainInfo.name),
								}),
							]}
							titleAction={
								<div className="flex items-center space-x-2">
									<Button
										size="small"
										onClick={() =>
											portfolioUid && investmentSummary?.status !== "DRAFT"
												? push("PortfolioDetails", { portfolioUid })
												: push("PortfoliosStudio", {})
										}
										palette="tertiary"
										data-qualifier="portfolioWizard/CreatePortfolio(Cancel)"
									>
										Cancel
									</Button>
									{area.name === "enhance" && (
										<AsyncButton
											size="small"
											palette="secondary"
											data-qualifier="portfolioWizard/ResetDraft"
											disabled={!isDraftPresent}
											onClickAsync={() =>
												spawnConfirmResetDialog({
													onSubmit: () => onStartNew(refetch, refetchConfiguration, setStepsData, ctxArea),
												})
											}
										>
											<Icon
												icon="Sync"
												color={!isDraftPresent ? undefined : themeCSSVars.palette_P400}
												size={16}
												classList="mr-1.5"
											/>
											{t("BUTTON.RESET")}
										</AsyncButton>
									)}
									{(area.name === "create" || area.name === "draft") && (
										<AsyncButton
											size="small"
											palette="secondary"
											data-qualifier={
												area.name === "create" ? "portfolioWizard/SaveAsDraft" : "portfolioWizard/SaveDraft"
											}
											onClickAsync={async () => {
												if (!stepsData?.mainInfo.name && currentStep !== "mainInfo" && area.name === "create") {
													setShowSaveAsDraftDialog(true);
													return;
												}
												await handleSubmit(
													area.name === "create"
														? () => saveAsDraft(currentStep)
														: async () => {
																await saveHandler();
														  },
													() => pushMissingFields(t(`PORTFOLIO_WIZARD.STEP_NAME.${currentStep}`)),
												);
											}}
										>
											{area.name === "create" ? "Save as draft" : "Save draft"}
										</AsyncButton>
									)}
									<AsyncButton
										size="small"
										onClickAsync={async () => {
											await handleSubmit(
												async () => {
													const latestStepsMetadata = getStepsMetadata();
													if (area.name === "settings-current") {
														await submit(getStepsData());
														return;
													}

													// settings mode works by keeping all steps marked as dirty,
													// so that we can submit all settings at the end.
													// Therefore, we skip the validation for required steps.
													const requiredStep = customObjectEntriesFn(latestStepsMetadata)
														.filter(([, metadata]) => !metadata.completed && !metadata.optional)
														.map(([stepName, metadata]) => ({ stepName, metadata }));

													const stepToComplete = requiredStep.find((x) => {
														if (x.stepName === currentStep && x.metadata.dirty === false) {
															return x;
														}

														return x.stepName !== currentStep;
													});

													if (stepToComplete?.stepName) {
														platformToast({
															children: `Unable to proceed, you have to complete the ${t(
																`PORTFOLIO_WIZARD.STEP_NAME.${stepToComplete?.stepName}`,
															)} step before creating the portfolio`,
															severity: "info",
															icon: "Portfolio",
														});
														setCurrentStep(stepToComplete?.stepName);
														return;
													}

													// const _processingSteps = customObjectEntriesFn(latestStepsMetadata)
													// 	.filter(([, metadata]) => metadata.processing)
													// 	.map(([stepName, metadata]) => ({ stepName, metadata }));

													const errorsSteps = customObjectEntriesFn(latestStepsMetadata)
														.filter(([, metadata]) => metadata.hasErrors)
														.map(([stepName, metadata]) => ({ stepName, metadata }));

													if (errorsSteps.length > 0) {
														platformToast({
															children:
																errorsSteps.length === 1
																	? `The step ${t(
																			`PORTFOLIO_WIZARD.STEP_NAME.${errorsSteps[0].stepName}`,
																	  )} require your attention`
																	: `Some steps require your attention`,
															severity:
																errorsSteps.length === 1 &&
																errorsSteps.some(
																	(x) => x.stepName === "portfolioConstraints" || x.stepName === "riskConstraints",
																)
																	? "info"
																	: "warning",
															icon: "Portfolio",
														});

														const isCurrent = errorsSteps.some((x) => x.stepName === currentStep);
														if (!isCurrent) {
															setCurrentStep(errorsSteps[0].stepName);
														}

														return;
													}
													await submit(getStepsData());
												},
												() => pushMissingFields(t(`PORTFOLIO_WIZARD.STEP_NAME.${currentStep}`)),
											);
										}}
										palette="primary"
										data-qualifier={switchExpr(area.name, {
											create: () => "portfolioWizard/CreatePortfolio",
											enhance: () => "portfolioWizard/CreateProposal",
											"settings-current": () => "portfolioWizard/SaveSettings",
											"settings-enhanced": () => "portfolioWizard/SaveSettings",
											draft: () => "portfolioWizard/SaveDraft",
										})}
									>
										<Icon icon="Enhance" size="1.2em" />
										&nbsp;
										{switchExpr(area.name, {
											create: () => "Create portfolio",
											enhance: () => "Generate",
											"settings-current": () => "Save",
											"settings-enhanced": () => "Save",
											draft: () => "Save",
										})}
									</AsyncButton>
								</div>
							}
						/>
						<EditPortfolioV4InContext />

						<SavePortfolioLoadingModal show={showLoadingModal} />
						<MissingFieldDialog
							show={showSaveAsDraftDialog}
							onCancel={() => setShowSaveAsDraftDialog(false)}
							onError={() => {
								// setShowSaveAsDraftDialog(false);
								platformToast({
									children: "Something went wrong please try later",
									severity: "error",
									icon: "Portfolio",
								});
							}}
							onSubmit={async ({ name }) => {
								await investementApi.setCreationConfigurationMainInfo({ name });
								await handleSubmit(
									() => saveAsDraft(currentStep),
									() => pushMissingFields(t(`PORTFOLIO_WIZARD.STEP_NAME.${currentStep}`)),
								);
							}}
						/>

						<LeavePrompt
							when={stepsMetadata[currentStep].dirty}
							title={t("LEAVE_PAGE")}
							pathToNotBlock={[
								"/login",
								history.location.pathname + history.location.search,
								`${typedUrlForRoute("Portfolios/CreatePortfolio", {})}#${currentStep}`,
								typedUrlForRoute("Portfolios/CreatePortfolio", {}),
								`${typedUrlForRoute("Portfolios/EditPortfolio", { portfolioUid: portfolioUid ?? "" })}#${currentStep}`,
								typedUrlForRoute("Portfolios/EditPortfolio", { portfolioUid: portfolioUid ?? "" }),
								`${typedUrlForRoute("Portfolios/Draft", { portfolioUid: portfolioUid ?? "" })}#${currentStep}`,
								typedUrlForRoute("Portfolios/Draft", { portfolioUid: portfolioUid ?? "" }),
							]}
						>
							{t("PORTFOLIOS.CREATE_LEAVE")}
						</LeavePrompt>
					</>
				);
			}}
		</EditPortfolioV4ContextProvider>
	);
}

function MissingFieldDialog({
	show,
	onSubmit,
	onError,
	onCancel,
}: {
	show: boolean;
	onSubmit({ name }: { name: string }): Promise<void>;
	onError(): void;
	onCancel(): void;
}) {
	const investmentApi = useApiGen(InvestmentControllerV4ApiFactory);

	const { checkingNameUniqueness, checkIfNameIsAvailable } = useDebouncedNameUniquenessChecker({
		isNameAvailableApi: (name, opts) => axiosExtract(investmentApi.isInvestmentNameAvailable(name, opts)),
	});

	const { control, formState, handleSubmit } = useForm({
		defaultValues: { name: "" },
		resolver: zodResolver(
			z.object({
				name: z
					.string()
					.min(1, "Please provide a valid name")
					.refine((name) => checkIfNameIsAvailable(name), {
						message: "Name not available",
					}),
			}),
		),
	});

	return (
		<Dialog
			header="Save as draft"
			show={show}
			onSubmitAsync={() => handleSubmit(onSubmit, onError)()}
			onClose={onCancel}
			footer={() => (
				<DialogFooter
					primaryAction={<SubmitButton>Save</SubmitButton>}
					neutralAction={
						<Button palette="tertiary" onClick={onCancel}>
							Cancel
						</Button>
					}
				/>
			)}
		>
			<FormFields.Text
				control={control}
				formState={formState}
				name="name"
				label="Portfolio name"
				placeholder="Name"
				rightContent={
					checkingNameUniqueness ? <CircularProgressBar classList="w-3" value="indeterminate" /> : undefined
				}
			/>
		</Dialog>
	);
}

type ConfirmResetDialog = {
	show: boolean;
	onSubmit(): Promise<void>;
	onClose(): void;
	onAnimationStateChange?: DialogProps["onAnimationStateChange"];
};
function ConfirmResetDialog({ show, onSubmit, onClose, onAnimationStateChange }: ConfirmResetDialog) {
	return (
		<Dialog
			header="Do you want to reset"
			show={show}
			onSubmitAsync={onSubmit}
			onAnimationStateChange={onAnimationStateChange}
			onClose={onClose}
			footer={() => (
				<DialogFooter
					primaryAction={
						<SubmitButton data-qualifier="portfolioWizard/resetDraft/dialog/proceed">Proceed</SubmitButton>
					}
					neutralAction={
						<Button palette="tertiary" onClick={onClose} data-qualifier="portfolioWizard/resetDraft/dialog/cancel">
							Cancel
						</Button>
					}
				/>
			)}
		>
			Reset the draft will bring the configuration to the original status
		</Dialog>
	);
}

type SpawnConfirmResetDialogProps = Omit<ConfirmResetDialog, "show" | "onClose">;
export function spawnConfirmResetDialog(props: SpawnConfirmResetDialogProps): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ show, resolve, onHidden }) => (
			<ConfirmResetDialog
				{...props}
				show={show}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
				onClose={() => resolve()}
				onSubmit={async () => {
					await props.onSubmit();
					resolve();
				}}
			/>
		)),
	);
}

export function SavePortfolioLoadingModal({ show }: { show: boolean }): JSX.Element {
	return (
		<Modal show={show}>
			<div className="w-[560px] p-[21px] bg-white rounded shadow relative z-0">
				<div className="flex-column justify-center text-center px-[43px] pb-[43px] pt-[22px] ">
					<div className="flex justify-center">
						<MonitorWithHourglass2 style={{ margin: "0 auto" }} />
					</div>
					<h2 className="font-bold text-[20px] leading-[24px] mb-[26px] whitespace-pre-line">
						{/* {t("PORTFOLIO_SAVE_DIALOG.title")} */}
						Sphere is checking the feasibility of the constraints to process your portfolio.
					</h2>
					<div className="flex justify-center mb-[26px]">
						<div className="w-[290px]">
							<ProgressBar value="indeterminate" classList="w-full" />
						</div>
					</div>
					<p className="text-[14px] leading-[20px] whitespace-pre-line">
						We are computing the submitted data, do not reload the page to not terminate the process
					</p>
				</div>
			</div>
		</Modal>
	);
}
