import type { InstrumentFieldType, StandardClassificationId, UserInstrumentClassificationDto } from "$root/api/api-gen";
import type { MinimumDialogProps } from "$root/components/spawnable/type";
import { qualifier } from "$root/utils";
import type { Option } from "@mdotm/mdotui/components";
import {
	Button,
	Dialog,
	DialogFooter,
	FormField,
	Radio,
	RadioGroup,
	Select,
	SubmitButton,
} from "@mdotm/mdotui/components";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, spawn } from "@mdotm/mdotui/react-extensions";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

type ScoreClassification = Omit<UserInstrumentClassificationDto, "fieldType"> & {
	fieldType: Extract<InstrumentFieldType, "SCORE">;
};

export type ScoreConstraintSelectionDialogProps = {
	preSelectedClassifications?: Array<string /** classificationUuid */>;
	currentScoreState?: {
		classificationUuid: string | null;
		actionOnNullScore: ActionOnNullScore;
	};
	scoreClassifications: Array<ScoreClassification>;
	onSubmit(data: Omit<ScoreSelection, "classificationUuid"> & { classificationUuid: string }): void;
} & MinimumDialogProps;

const ActionOnNullScore = {
	exclude: "EXCLUDE",
	considerNullAsScore: "CONSIDER_NULL_AS_SCORE",
} as const;

type ActionOnNullScore = (typeof ActionOnNullScore)[keyof typeof ActionOnNullScore];

type ScoreSelection = {
	classificationUuid: string | null;
	standardClassification?: StandardClassificationId;
	actionOnNullScore: ActionOnNullScore;
};

function ScoreConstraintSelectionDialog(props: ScoreConstraintSelectionDialogProps) {
	const {
		show,
		onClose,
		onAnimationStateChange,
		onSubmit,
		scoreClassifications,
		preSelectedClassifications,
		currentScoreState,
	} = props;
	const { t } = useTranslation();

	const scoreClassificationOptions = useMemo(
		() =>
			scoreClassifications.map(
				(classification): Option<string> => ({
					label: classification.name!,
					value: classification.classificationUuid!,
					disabled: preSelectedClassifications?.includes(classification.classificationUuid!),
				}),
			),
		[scoreClassifications, preSelectedClassifications],
	);

	const [scoreSelection, setScoreSection] = useState<ScoreSelection>({
		classificationUuid: currentScoreState?.classificationUuid ?? scoreClassificationOptions.at(0)?.value ?? null,
		actionOnNullScore: currentScoreState?.actionOnNullScore ?? "EXCLUDE",
	});

	return (
		<Dialog
			show={show}
			header="Set a constraint"
			onAnimationStateChange={onAnimationStateChange}
			onSubmitAsync={() => onSubmit({ ...scoreSelection, classificationUuid: scoreSelection.classificationUuid! })}
			footer={
				<DialogFooter
					primaryAction={
						<SubmitButton
							disabled={!scoreSelection.classificationUuid}
							data-qualifier={qualifier.component.button("Dialog/Submit")}
						>
							{t("BUTTON.DONE")}
						</SubmitButton>
					}
					neutralAction={
						<Button palette="tertiary" onClick={onClose} data-qualifier={qualifier.component.button("Dialog/Cancel")}>
							{t("BUTTON.CANCEL")}
						</Button>
					}
				/>
			}
		>
			<>
				<FormField label="Score and metrics" classList="mb-4">
					{(formFieldProps) => (
						<Select
							{...formFieldProps}
							data-qualifier="ScoreConstraintSelectionDialog/ScoreAndMetrics/Options"
							triggerDataAttrs={{
								"data-qualifier": "ScoreConstraintSelectionDialog/ScoreAndMetrics/Trigger",
							}}
							value={scoreSelection.classificationUuid}
							options={scoreClassificationOptions}
							onChange={(newScoreClassificationUuid) => {
								const selectedClassification = scoreClassifications.find(
									(x) => x.classificationUuid === newScoreClassificationUuid,
								);

								setScoreSection((latest) => ({
									...latest,
									classificationUuid: newScoreClassificationUuid,
									standardClassification: selectedClassification?.standardClassificationId,
								}));
							}}
						/>
					)}
				</FormField>
				<FormField label="Missing (empty*) values">
					{(formFieldProps) => (
						<RadioGroup
							{...formFieldProps}
							value={scoreSelection.actionOnNullScore}
							onChange={(newActionOnNullScore) =>
								setScoreSection((latest) => ({
									...latest,
									actionOnNullScore: newActionOnNullScore,
								}))
							}
						>
							<div className="flex flex-col gap-2 mt-1">
								<Radio
									data-qualifier={`ScoreConstraintSelectionDialog/MissingValues/${ActionOnNullScore.exclude}`}
									value={ActionOnNullScore.exclude}
								>
									Exclude
								</Radio>
								<Radio
									data-qualifier={`ScoreConstraintSelectionDialog/MissingValues/${ActionOnNullScore.considerNullAsScore}`}
									value={ActionOnNullScore.considerNullAsScore}
								>
									Include with 0 as score
								</Radio>
							</div>
						</RadioGroup>
					)}
				</FormField>
			</>
		</Dialog>
	);
}

type SpawnScoreConstraintSelectionDialogProps = Omit<
	ScoreConstraintSelectionDialogProps,
	"show" | "onClose" | "onAnimationStateChange"
>;
export function spawnScoreConstraintSelectionDialog(
	props: SpawnScoreConstraintSelectionDialogProps,
): SpawnResult<void> {
	return spawn(
		adaptAnimatedNodeProvider(({ show, resolve, onHidden }) => (
			<ScoreConstraintSelectionDialog
				{...props}
				show={show}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
				onClose={() => resolve()}
				onSubmit={(payload) => {
					props.onSubmit(payload);
					resolve();
				}}
			/>
		)),
	);
}
