import type {
	FlexibleExpectedReturnsVolatilityAssetClass,
	FlexibleExpectedReturnsVolatilityAssetClassAssetClassEnum,
	MarketViewAssetClassAlias,
	MarketViewAssetClassIdentifier,
	MarketViewSettings,
} from "$root/api/api-gen";
import { type MarketScenario } from "$root/api/api-gen";
import { hasAccess } from "$root/components/AuthorizationGuard";
import ColoredRectangle from "$root/components/icons/ColoredRectangle";
import { type MarketViewMapProps } from "$root/components/Portfolio/MarketView/mapV2";
import { applyDeltaToSelectedMarketView } from "$root/components/Portfolio/MarketView/utilsV2";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { InlineTextForm } from "$root/components/smart-text-input/InlineTextForm";
import { useLocaleFormatters } from "$root/localization/hooks";
import { groupedColorProps } from "$root/pages/PortfolioDetails/PortfolioComposition/colors";
import { useQueryNoRefetch } from "$root/utils";
import type { Option, TableWithGroupsProps } from "@mdotm/mdotui/components";
import {
	ActionText,
	AutoSortTableWithGroups,
	BaseTable,
	Controller,
	FormField,
	MultiSlider,
	NullableNumberInput,
	Row,
	Select,
	Text,
	TinyIconButton,
	defaultLabelClassName,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import type { ClassList, NodeOrFn } from "@mdotm/mdotui/react-extensions";
import { Switch, toClassListRecord, toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor, noop } from "@mdotm/mdotui/utils";
import { UseQueryResult } from "@tanstack/react-query";
import { Set } from "immutable";
import { MutableRefObject, useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserValue } from "../user";
import { marketViewAssetClassMap } from "./market-view-asset-class-mapV2";
import { PositioningSlider, type PositioningPreference } from "./MarketViewPositioning";
import { MarketViewSummary } from "./MarketViewSummary";

export type MarketViewProbabilitiesProps = {
	mode: "view" | "edit";
	scenarioOptions: Option<string>[];
	selectedScenarioId: string | null;
	title?: NodeOrFn;
	subtitle?: NodeOrFn;
	classList?: ClassList;
	forceSelectMode?: "view" | "edit";
	highlightedAssetClass?: string[];
	onSelectedScenarioChange(id: string | null): void;
	marketScenario: MarketScenario | null;
	onMarketScenarioChange(newMarketScenario: MarketScenario): void;
	marketScenarioProvider(scenarioId: string): MaybePromise<MarketScenario>;
	disableScenarioSelection?: boolean;
	alias?: Array<MarketViewAssetClassAlias>;
	marketViewMap?: MarketViewMapProps;
	hideScenarioOption?: boolean;
	hidePositioning?: boolean;
	header?: { hide?: boolean };
	marketViewSettings?: MarketViewSettings;
} & (
	| {
			hideCommentary?: undefined;
			canReadCommentary?: boolean;
	  }
	| {
			hideCommentary?: boolean;
			canReadCommentary?: undefined;
	  }
);

type EditableFlexibleExpectedReturnsVolatilityAssetClassKeys = Extract<
	keyof FlexibleExpectedReturnsVolatilityAssetClass,
	"expectedReturnsUserDelta" | "expectedVolatilityUserDelta" | "commentary"
>;

const sortMap = {
	EQUITY: 1,
	FIXED_INCOME: 2,
	COMMODITIES: 3,
	ALTERNATIVE: 4,
	CURRENCY: 5,
	MONEY_MARKET: 6,
} satisfies Record<FlexibleExpectedReturnsVolatilityAssetClassAssetClassEnum, number>;

export function MarketViewProbabilities(props: MarketViewProbabilitiesProps): JSX.Element {
	const {
		mode,
		onSelectedScenarioChange,
		scenarioOptions,
		marketScenario,
		marketScenarioProvider,
		onMarketScenarioChange,
		forceSelectMode,
		classList,
		disableScenarioSelection = false,
		canReadCommentary = false,
		hideCommentary = false,
		header,
		marketViewSettings,
		selectedScenarioId,
	} = props;
	const { hide = false } = header ?? {};

	const latestMarketScenarioRef = useRef<MarketScenario | null>(marketScenario);
	const onReset = useCallback(
		(indicators: MarketScenario) => {
			const regimes = {
				lower: indicators?.regimeUserProbability?.a ?? 0,
				upper: (indicators?.regimeUserProbability?.a ?? 0) + (indicators?.regimeUserProbability?.b ?? 0),
			};

			const appliedAssetClasses = {
				...indicators,
				flexibleExpectedReturnsVolatility: {
					assetClasses: applyDeltaToSelectedMarketView(
						{
							a: regimes.lower,
							b: regimes.upper - regimes.lower,
							c: 100 - regimes.upper,
						},
						indicators.flexibleExpectedReturnsVolatility?.assetClasses,
					),
				},
			} satisfies MarketScenario;

			onMarketScenarioChange(appliedAssetClasses);
			latestMarketScenarioRef.current = appliedAssetClasses;
		},
		[onMarketScenarioChange],
	);

	const originalMarketScenarioQuery = useQueryNoRefetch({
		queryKey: ["market-view", "details", selectedScenarioId],
		enabled: Boolean(selectedScenarioId),
		queryFn: () => marketScenarioProvider(selectedScenarioId!),
		onSuccess: onReset,
	});

	const { formatNumber } = useLocaleFormatters();
	const user = useUserValue();

	return (
		<div
			className={toClassName({
				"@container": true,
				...toClassListRecord(classList),
			})}
		>
			{!hide && (
				<MarketViewSummary
					selectedScenarioId={selectedScenarioId}
					marketScenario={originalMarketScenarioQuery.data}
					loading={originalMarketScenarioQuery.isFetching}
					marketViewSettings={marketViewSettings}
					scenarioLabel={
						<div className="flex flex-row items-end gap-4">
							<Switch
								case={forceSelectMode ?? mode}
								match={{
									edit: () => (
										<FormField label="Start with a Market Scenario">
											{(props) => (
												<Select
													data-qualifier="marketViewProbabilities/scenarioOptions"
													{...props}
													classList="min-w-[250px]"
													options={scenarioOptions}
													value={selectedScenarioId}
													onChange={onSelectedScenarioChange}
													disabled={disableScenarioSelection}
													triggerDataAttrs={{
														"data-qualifier": "marketViewProbabilities/scenarioOptions/button",
													}}
												/>
											)}
										</FormField>
									),
									view: () => (
										<FormField label="Market Scenario">
											{(props) => (
												<Text
													as="div"
													id={props.id}
													type="Body/L/Bold"
													classList="py-1"
													data-qualifier="marketViewPositioning/scenarioOptions(view)"
												>
													{scenarioOptions.find((x) => x.value === selectedScenarioId)?.label ?? "-"}
													{/* TODO: let's get it from the API! */}
												</Text>
											)}
										</FormField>
									),
								}}
							/>
							{mode === "edit" && marketScenario && (
								<div>
									<div className={defaultLabelClassName}>&nbsp;</div>
									<ActionText
										data-qualifier="marketViewPositioning/reset"
										color={themeCSSVars.palette_P600}
										type="Body/M/Book"
										onClick={() => {
											if (latestMarketScenarioRef.current) {
												onMarketScenarioChange(latestMarketScenarioRef.current);
											}
										}}
									>
										Reset
									</ActionText>
								</div>
							)}
						</div>
					}
				/>
			)}

			{/* Only used to show the loading state */}
			<ReactQueryWrapperBase query={originalMarketScenarioQuery}>
				{(scenarioResponse) =>
					marketScenario && (
						<div>
							{hasAccess(user, { requiredService: "SHOW_SPHERE_STRATEGICAL_MARKET_VIEWS_ONLY" }) && (
								<Controller
									value={[
										marketScenario.regimeUserProbability?.a ?? 33,
										(marketScenario.regimeUserProbability?.a ?? 33) + (marketScenario.regimeUserProbability?.b ?? 34),
									]}
									onChange={([lower, upper]) => {
										const updateRegime = {
											...marketScenario,
											regimeUserProbability: {
												a: lower,
												b: upper - lower,
												c: 100 - upper,
											},
											flexibleExpectedReturnsVolatility: {
												assetClasses: applyDeltaToSelectedMarketView(
													{
														a: lower,
														b: upper - lower,
														c: 100 - upper,
													},
													scenarioResponse.flexibleExpectedReturnsVolatility?.assetClasses,
												),
											},
										};
										onMarketScenarioChange(updateRegime);
										latestMarketScenarioRef.current = updateRegime;
									}}
								>
									{({ value: [lower, upper], onChange, onCommit }) => {
										const userProbabilitiesDiffer =
											marketScenario.regimeProbabilities?.a !== lower ||
											marketScenario.regimeProbabilities?.b !== upper - lower ||
											marketScenario.regimeProbabilities?.c !== 100 - upper;

										const scenarioRegimeProbabilities = {
											lower: marketScenario.regimeProbabilities?.a ?? 33,
											upper:
												(marketScenario.regimeProbabilities?.a ?? 33) + (marketScenario.regimeProbabilities?.b ?? 34),
										};

										return (
											<>
												<div>
													<MultiSlider
														data-qualifier="marketViewProbabilities/scenarioSlider"
														values={[lower, upper] as [number, number]}
														step={1}
														onChange={onChange}
														onCommit={onCommit}
														bandColors={[
															themeCSSVars.palette_P600,
															themeCSSVars.palette_A500,
															themeCSSVars.palette_D400,
														]}
														disabled
													/>
												</div>
												<div className="mb-6">
													<BaseTable
														disableVirtualScroll
														data-qualifier="marketViewProbabilities/probabilities"
														rows={[
															{
																label: "Low Risk",
																color: themeCSSVars.palette_P600,
																user: lower,
																scenario: scenarioRegimeProbabilities.lower,
															},
															{
																label: "Mid Risk",
																color: themeCSSVars.palette_A500,
																user: upper - lower,
																scenario: scenarioRegimeProbabilities.upper - scenarioRegimeProbabilities.lower,
															},
															{
																label: "High Risk",
																color: themeCSSVars.palette_D400,
																user: 100 - upper,
																scenario: 100 - scenarioRegimeProbabilities.upper,
															},
														]}
														columns={[
															{
																name: "label",
																header: "SCENARIO",
																content: ({ label, color }, cellProps) => (
																	<Row {...cellProps} alignItems="center">
																		<ColoredRectangle color={color} variant="vertical" />
																		<div>&nbsp;{label}</div>
																	</Row>
																),
															},
															{
																name: "scenario",
																header: "SCENARIO PROBABILITIES",
																content: ({ scenario }) => `${formatNumber(scenario, 0)}%`,
															},
															{
																name: "user",
																header: "USER PROBABILITIES",
																cellClassList: {
																	[`text-[${themeCSSVars.palette_S500}]`]: userProbabilitiesDiffer,
																},
																content: ({ user: userProbability }) =>
																	!userProbabilitiesDiffer ? "-" : `${formatNumber(userProbability, 0)}%`,
															},
														]}
													/>
												</div>
											</>
										);
									}}
								</Controller>
							)}

							{/* <div className="my-6">
								<Text type="Body/L/Bold">{t("EXPECTED_RETURNS_VOLATILITY")}</Text>
							</div> */}

							<TableBlock
								{...props}
								latestMarketScenarioRef={latestMarketScenarioRef}
								originalMarketScenarioQuery={originalMarketScenarioQuery}
							/>
						</div>
					)
				}
			</ReactQueryWrapperBase>
		</div>
	);
}

function TableBlock({
	marketScenario,
	mode,
	onMarketScenarioChange,
	highlightedAssetClass,
	canReadCommentary = false,
	hideCommentary = false,
	alias,
	marketViewMap,
	hidePositioning,

	originalMarketScenarioQuery,
	latestMarketScenarioRef,
}: MarketViewProbabilitiesProps & {
	originalMarketScenarioQuery: UseQueryResult<MarketScenario, unknown>;
	latestMarketScenarioRef: MutableRefObject<MarketScenario | null>;
}) {
	const user = useUserValue();
	const { t } = useTranslation();

	const filteredAssetClasses = useMemo((): Array<FlexibleExpectedReturnsVolatilityAssetClass> => {
		if (!marketScenario?.flexibleExpectedReturnsVolatility?.assetClasses) {
			return [];
		}

		if (marketViewMap === undefined) {
			return marketScenario?.flexibleExpectedReturnsVolatility?.assetClasses.sort((a, b) =>
				sortMap[a.assetClass!] > sortMap[b.assetClass!] ? 1 : -1,
			);
		}

		const assetClassKeys = marketViewMap.flatMap((x) => x.fields.map(({ key }) => key));
		return marketScenario?.flexibleExpectedReturnsVolatility?.assetClasses
			?.filter((ac) => (ac.microAssetClass ? assetClassKeys.includes(ac.microAssetClass) : false)) // ac are filtered based on MarketViewMap
			.sort((a, b) => (sortMap[a.assetClass!] > sortMap[b.assetClass!] ? 1 : -1));
	}, [marketScenario?.flexibleExpectedReturnsVolatility?.assetClasses, marketViewMap]);

	const mappedAlias = useMemo(
		() =>
			alias?.reduce<Record<MarketViewAssetClassIdentifier, string>>(
				(acc, { alias: x, microAssetClass }) => ({ ...acc, [microAssetClass!]: x! }),
				{} as Record<MarketViewAssetClassIdentifier, string>,
			),
		[alias],
	);

	function updateFlexibleExpectedReturnsVolatilityAssetClass<
		K extends EditableFlexibleExpectedReturnsVolatilityAssetClassKeys,
	>(
		field: K,
		value: FlexibleExpectedReturnsVolatilityAssetClass[K],
		microAssetClass?: MarketViewAssetClassIdentifier,
		list?: FlexibleExpectedReturnsVolatilityAssetClass[],
	) {
		if (!list) {
			return list;
		}

		return list.map((x) => ({ ...x, [field]: x.microAssetClass === microAssetClass ? value : x[field] }));
	}

	const canEditCommentary =
		hasAccess(user, { requiredService: "CUSTOM_MARKET_VIEW_COMMENTARY_EDITOR" }) && mode === "edit";

	const groupedProbabilities = useMemo(() => {
		const grouped = Object.values(
			filteredAssetClasses.reduce(
				(groups, flexibleAssetClass) => {
					const groupKey = marketViewAssetClassMap[flexibleAssetClass.microAssetClass!]
						? marketViewAssetClassMap[flexibleAssetClass.microAssetClass!].group
						: "uncategorised";

					groups[groupKey] = groups[groupKey] ?? {
						group: groupKey,
						rows: [],
					};
					groups[groupKey].rows.push({
						...flexibleAssetClass,
						highlighted: highlightedAssetClass?.includes(flexibleAssetClass.microAssetClass ?? "") ?? false,
						groupKey,
					});
					return groups;
				},
				{} as Record<
					string,
					{
						group: (typeof marketViewAssetClassMap)[keyof typeof marketViewAssetClassMap]["group"] | "uncategorised";
						rows: Array<
							FlexibleExpectedReturnsVolatilityAssetClass & {
								highlighted: boolean;
								groupKey: string;
							}
						>;
					}
				>,
			),
		);
		grouped.forEach((group) => group.rows.sort(builtInSortFnFor("microAssetClass")));
		return grouped;
	}, [filteredAssetClasses, highlightedAssetClass]);

	const getPositioningByAssetClass = useCallback(
		(assetClass?: string, microAssetClass?: string): PositioningPreference | undefined => {
			return originalMarketScenarioQuery.data?.positioningIndicators?.positioningIndicators?.find(
				(x) => x.assetClass === assetClass && x.microAssetClass === microAssetClass,
			)?.defaultPositioning as PositioningPreference | undefined;
		},
		[originalMarketScenarioQuery.data],
	);

	const getExpectedReturnByAssetClass = useCallback(
		(assetClass?: string, microAssetClass?: string) => {
			return latestMarketScenarioRef.current?.flexibleExpectedReturnsVolatility?.assetClasses?.find(
				(x) => x.assetClass === assetClass && x.microAssetClass === microAssetClass,
			);
		},
		[latestMarketScenarioRef],
	);

	const columns = useCallback<TableWithGroupsProps<(typeof groupedProbabilities)[number]>["columns"]>(
		({ bandColumn, expandColumn }) => [
			bandColumn,
			expandColumn,
			{
				sortFn: builtInSortFnFor("group"),
				name: "group",
				groupContentTextType: "Body/M/Bold",
				groupContent: ({ group }) => t(`PORTFOLIOS.PORTFOLIOS_MARKET_VIEW_ITEM_KEYS.probabilities.${group}`),
				content: ({ microAssetClass }) => {
					const label = mappedAlias?.[microAssetClass!]
						? mappedAlias[microAssetClass!]
						: t(`MARKET_STUDIO_SETTINGS.${microAssetClass!}`);
					return label;
				},
				header: "Asset class",
				minWidth: 264,
				maxWidth: 264,
			},
			{
				name: "input",
				groupContent: () => null,
				content: ({ expectedReturnsUserDelta, microAssetClass, assetClass }, cellProps) => {
					const isExpectedReturnEqual =
						getExpectedReturnByAssetClass(assetClass, microAssetClass)?.expectedReturnsUserDelta ===
						expectedReturnsUserDelta;

					const canRestore = !hidePositioning && originalMarketScenarioQuery.data?.forecastHorizon === "ONE_MONTH";

					return (
						<Row {...cellProps} justifyContent="center" alignItems="center" childrenGrow={1}>
							<NullableNumberInput
								data-qualifier={`marketViewProbabilities/assetClass(${assetClass})/microAssetClass(${microAssetClass})/expectedReturns`}
								min={-80} // value taken from MarketViewMap
								max={80} // value taken from MarketViewMap
								size="x-small"
								value={expectedReturnsUserDelta ?? null}
								onChange={(value) =>
									onMarketScenarioChange({
										...marketScenario,
										flexibleExpectedReturnsVolatility: {
											assetClasses: updateFlexibleExpectedReturnsVolatilityAssetClass(
												"expectedReturnsUserDelta",
												value ?? undefined,
												microAssetClass!,
												marketScenario?.flexibleExpectedReturnsVolatility?.assetClasses,
											),
										},
									})
								}
								rightContent={
									<TinyIconButton
										icon={isExpectedReturnEqual || !canRestore ? "Percentile" : "restore"}
										size="12px"
										disabled={isExpectedReturnEqual || !canRestore}
										onClick={() =>
											isExpectedReturnEqual || !canRestore
												? noop
												: onMarketScenarioChange({
														...marketScenario,
														flexibleExpectedReturnsVolatility: {
															assetClasses: updateFlexibleExpectedReturnsVolatilityAssetClass(
																"expectedReturnsUserDelta",
																getExpectedReturnByAssetClass(assetClass, microAssetClass)?.expectedReturnsUserDelta,
																microAssetClass!,
																marketScenario?.flexibleExpectedReturnsVolatility?.assetClasses,
															),
														},
												  })
										}
										classList="!p-0"
									/>
								}
								disabled={mode === "view"}
								classList={{
									"flex-1": true,
									[`text-[${themeCSSVars.palette_S500}]`]:
										latestMarketScenarioRef.current?.flexibleExpectedReturnsVolatility?.assetClasses?.find(
											(x) => x.microAssetClass === microAssetClass,
										)?.expectedReturnsUserDelta !== expectedReturnsUserDelta,
								}}
							/>
						</Row>
					);
				},
				header: "ANNUALISED EXPECTED RETURN",
				minWidth: 208,
				maxWidth: 208,
			},
			{
				name: "graph",
				groupContent: () => null,
				content: ({ assetClass, microAssetClass, expectedReturnsUserDelta }, cellProps) => {
					const positioning = getPositioningByAssetClass(assetClass, microAssetClass);
					const expectedReturn = getExpectedReturnByAssetClass(assetClass, microAssetClass);
					const isExpectedReturnEqualToPositioning =
						expectedReturn?.expectedReturnsUserDelta === expectedReturnsUserDelta;

					if (positioning != null) {
						return (
							<Row
								{...cellProps}
								childrenGrow={1}
								justifyContent="center"
								alignItems="center"
								classList={{
									"transition-colors": true,
									grayscale: !isExpectedReturnEqualToPositioning,
								}}
							>
								<PositioningSlider value={positioning} disabled />
							</Row>
						);
					}
					return null;
				},
				header: (headerProps) => (
					<Row {...headerProps} alignItems="center" justifyContent="space-between">
						{["SU", "U", "N", "O", "SO"].map((x, i) => (
							<Text as="div" type="Body/S/BOLD-UPPERCASE" color={themeCSSVars.palette_N500} key={i}>
								{x}
							</Text>
						))}
					</Row>
				),
				minWidth: 208,
				maxWidth: 208,
				hidden: originalMarketScenarioQuery.data?.forecastHorizon !== "ONE_MONTH" || hidePositioning,
			},
			{
				name: "commentary",
				header: "Commentary",
				groupContent: () => null,
				content: ({ microAssetClass, commentary }, cellProps) => (
					<Row justifyContent="center" {...cellProps} childrenGrow={1}>
						<InlineTextForm
							noDataText=""
							rows={3}
							classList="w-full min-h-[54px] flex items-center py-2"
							value={commentary ?? ""}
							canEdit={canEditCommentary}
							onEdit={(value) =>
								onMarketScenarioChange({
									...marketScenario,
									flexibleExpectedReturnsVolatility: {
										assetClasses: updateFlexibleExpectedReturnsVolatilityAssetClass(
											"commentary",
											value,
											microAssetClass!,
											marketScenario?.flexibleExpectedReturnsVolatility?.assetClasses,
										),
									},
								})
							}
							editButton={({ onClick }) => (
								<TinyIconButton
									size={20}
									data-qualifier="marketViewProbabilities/commentary/edit"
									disabled={!canEditCommentary && !commentary}
									onClick={onClick}
									icon={canEditCommentary && !commentary ? "commentary-add" : "Edit"}
									color={!canEditCommentary && !commentary ? themeCSSVars.palette_N200 : themeCSSVars.palette_P400}
								/>
							)}
						/>
					</Row>
				),
				hidden:
					originalMarketScenarioQuery.data?.forecastHorizon !== "ONE_MONTH" ||
					(canEditCommentary === false && canReadCommentary === false) ||
					hideCommentary,
			},
		],
		[
			originalMarketScenarioQuery.data?.forecastHorizon,
			hidePositioning,
			canEditCommentary,
			canReadCommentary,
			hideCommentary,
			t,
			mappedAlias,
			getExpectedReturnByAssetClass,
			mode,
			latestMarketScenarioRef,
			onMarketScenarioChange,
			marketScenario,
			getPositioningByAssetClass,
		],
	);

	const [defaultExpandGroups] = useState(() => Set(groupedProbabilities.map((x) => x.group)));

	return (
		<AutoSortTableWithGroups
			data-qualifier="marketViewProbabilities/assetClassGroups"
			palette="uniform"
			groupedRows={groupedProbabilities}
			groupRowKey={groupRowKey}
			expandGroupByKey={defaultExpandGroups}
			{...groupedColorProps("group", "groupKey")}
			columns={columns}
		/>
	);
}

function groupRowKey(x: { group: string }) {
	return x.group;
}
