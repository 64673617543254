import { SelectableBasket } from "$root/api/api-gen";
import { createPersistentAtom } from "$root/third-party-integrations/zustand";
import type { Updater } from "@mdotm/mdotui/utils";
import { identity } from "@mdotm/mdotui/utils";
import type { TagFilter } from "./SidePanelColumnFilters";
import fastDeepEqual from "fast-deep-equal";

export type InstrumentCustomisationStoreProps = {
	expanded: boolean;
	search: string;
	selectedfilters: Array<[string, TagFilter]>;
	selectedBaskets: Array<SelectableBasket>;
};

export const instrumentCustomisationDefault: InstrumentCustomisationStoreProps = {
	expanded: true,
	selectedfilters: [],
	selectedBaskets: [],
	search: "",
};

export const useInstrumentCustomisationStore = createPersistentAtom<InstrumentCustomisationStoreProps>(
	instrumentCustomisationDefault,
	"instrument-customisation",
);

export function useInstrumentCustomisationValue(): InstrumentCustomisationStoreProps {
	return useInstrumentCustomisationStore((x) => x.value);
}

export function useInstrumentCustomisationState(): {
	instrumentCustomisationStore: InstrumentCustomisationStoreProps;
	setInstrumentCustomisationStore: (
		newValue: InstrumentCustomisationStoreProps | Updater<InstrumentCustomisationStoreProps>,
	) => void;
	reset(): void;
} {
	const { value: instrumentCustomisationStore, set: setInstrumentCustomisationStore } = useInstrumentCustomisationStore(
		identity,
		(a, b) => fastDeepEqual(a.value, b.value),
	);
	return {
		instrumentCustomisationStore,
		setInstrumentCustomisationStore,
		reset: () => setInstrumentCustomisationStore(instrumentCustomisationDefault),
	};
}
