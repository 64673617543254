import type { Setter, Update } from "@mdotm/mdotui/utils";
import { createContext } from "react";

export type CustomReportEditorContextContent = {
	files: {
		clientLogo?: File;
		backgroundImage?: File;
		footerImage?: File;
	};
	texts: {
		footerDescription?: string;
		disclaimer?: string;
		address?: string;
		email?: string;
		website?: string;
	};
};
export const CustomReportEditorContext = createContext<{
	value: CustomReportEditorContextContent;
	set: Setter<CustomReportEditorContextContent>;
	update: Update<CustomReportEditorContextContent>;
} | null>(null);

export function initCustomEditorContextData(): CustomReportEditorContextContent {
	return {
		files: {},
		texts: {},
	};
}

export function updateCustomReportText(
	updater: Update<CustomReportEditorContextContent>,
	key: keyof CustomReportEditorContextContent["texts"],
	value: string,
): void {
	updater((cur) => ({
		...cur,
		texts: { ...cur.texts, [key]: value },
	}));
}

export function updateCustomReportFile(
	updater: Update<CustomReportEditorContextContent>,
	key: keyof CustomReportEditorContextContent["files"],
	value: File | null | undefined,
): void {
	updater((cur) => ({
		...cur,
		files: { ...cur.files, [key]: value ?? undefined },
	}));
}
