export function logDuplicate<T>(arr: T[]): void {
	const countMap = new Map<T, number>();

	// Iterate through the array and count the occurrences of each item
	arr.forEach((item) => {
		if (countMap.has(item)) {
			countMap.set(item, countMap.get(item)! + 1);
		} else {
			countMap.set(item, 1);
		}
	});

	// Iterate through the countMap to log duplicates
	countMap.forEach((count, item) => {
		if (count > 1) {
			console.log(`Item: ${item}, Count: ${count}`);
		}
	});
}

export function todo(msg = "missing implementation"): never {
	throw new Error(`TODO: ${msg}`);
}

export function softNonNull<T>(value: T | null | undefined, fallback: T, ctx?: unknown): T {
	if (value == null) {
		console.warn("soft non-null assertion failed", ctx);
		return fallback;
	}
	return value;
}
