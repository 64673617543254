import type { OrderBy } from "@mdotm/mdotui/components";
import { useCallback, useState } from "react";

const defaultTake = 50;

/**
 * This hook can be useful when you need to create a series of state to handle paginated request
 * this come from with minimum state declaration such as skip, take, search and order
 * @default {params: { take: 50} }
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function usePaginationTools(params?: { take?: number }) {
	const [take, setTake] = useState(() => params?.take ?? defaultTake);
	const [orderBy, setOrderBy] = useState<OrderBy[]>();
	const [skip, setSkip] = useState(0);

	const [query, _setQuery] = useState("");
	const setQuery = useCallback<typeof _setQuery>(
		(x) => {
			setSkip(0);
			return _setQuery(x);
		},
		[setSkip],
	);

	return {
		query: { value: query, get: () => query, set: setQuery },
		skip: { value: skip, get: () => skip, set: setSkip },
		orderBy: { value: orderBy, get: () => orderBy, set: setOrderBy },
		take: { value: take, get: () => take, set: setTake },
	};
}
