import type {
	ReviewTickerCommentaryCreatorEnum,
	ReviewTickerCommentaryStatusEnum
} from "$root/api/api-gen";
import { customObjectEntriesFn, qualifier } from "$root/utils";
import type { Option, SearchableProps, TableColumn } from "@mdotm/mdotui/components";
import { AutoSortTable, Searchable, Select, TextInput, useSelectableTableColumn } from "@mdotm/mdotui/components";
import type { MultiSelectCtx } from "@mdotm/mdotui/headless";
import type { ReactNode } from "react";
import { useCallback, useMemo, useState } from "react";
import { useInstrumentColumnsTableV2 } from "./hooks";

export type InstrumentFilterableFields = "granularity" | "assetClass" | "geography" | "microAssetClass";

export type MinimumViableInstrument = Partial<Record<InstrumentFilterableFields, string>> & {
	ticker?: string; // this is not actually undefined, but the openapi.json doesn't correctly map optional fields at the moment.
	instrument?: string;
	identifier?: string;
	isin?: string;
	alias?: string;
	/** @deprecated */
	commentary?: string;
	/** @deprecated */
	commentaryDate?: string;
	/** @deprecated */
	commentaryStatus?: ReviewTickerCommentaryStatusEnum;
	/** @deprecated */
	commentaryCreator?: ReviewTickerCommentaryCreatorEnum;
	/** @deprecated */
	// description?: string;
	// /** @deprecated */
	// descriptionDate?: string;
	// /** @deprecated */
	// descriptionStatus?: ReviewTickerDescriptionStatusEnum;
	// /** @deprecated */
	// descriptionCreator?: ReviewTickerDescriptionCreatorEnum;
	needsCustomProxy?: boolean;
	linkedPortfolios?: number;
	type?: string;
	tagLabel?: string;
	score?: number;
};

export type InstrumentSelectorTableProps<T extends MinimumViableInstrument> = {
	instruments: T[];
	multiSelectCtx: MultiSelectCtx<string>;
	preSelectedTickers?: string[];
	mode: "radio" | "checkbox";
	tableTitle?: ReactNode;
	customTableColumns?: TableColumn<T>[];
};

export function InstrumentsSelectorTable<T extends MinimumViableInstrument>({
	instruments: rows,
	mode,
	multiSelectCtx,
	tableTitle,
	preSelectedTickers,
	customTableColumns,
}: InstrumentSelectorTableProps<T>): JSX.Element {
	const [filters, setFilters] = useState({
		granularity: [] as string[],
		assetClass: [] as string[],
		geography: [] as string[],
		microAssetClass: [] as string[],
	});

	const filteredRows = useMemo(() => {
		function includesGuardFn(filteredKeys: string[], field?: string | null) {
			if (filteredKeys.length === 0) {
				return true;
			}
			return filteredKeys.includes(field ?? "");
		}

		const filterKeyList = (Object.keys(filters) as Array<keyof typeof filters>).map((key) => filters[key]).flat();

		if (filterKeyList.length === 0) {
			return rows;
		}

		return rows.filter(
			(m) =>
				includesGuardFn(filters.assetClass, m.assetClass) &&
				includesGuardFn(filters.microAssetClass, m.microAssetClass) &&
				includesGuardFn(filters.granularity, m.granularity) &&
				includesGuardFn(filters.geography, m.geography),
		);
	}, [filters, rows]);

	const optionsByCategory = useMemo(() => {
		const filtersCategory = [
			"assetClass",
			"geography",
			"granularity",
			"microAssetClass",
		] satisfies Array<InstrumentFilterableFields>;

		// const proccessedRows = (() => {
		// 	const currentFilters = Object.values(filters).flat();
		// 	if (Object.values(filters).some((el) => el.length > 0)) {
		// 		return filteredRows.filter((item) => {
		// 			return (
		// 				customObjectEntriesFn(filters).filter(([k, v]) => {
		// 					if (v.length === 0) {
		// 						return false;
		// 					}

		// 					return v.includes(item[k] ?? "");
		// 				}).length === currentFilters.length
		// 			);
		// 		});
		// 	}

		// 	return filteredRows;
		// })();

		const optByCat = filtersCategory.reduce<{
			[K in InstrumentFilterableFields]: Option<string>[];
		}>(
			(acc, filterCategory) => {
				acc = {
					...acc,
					[filterCategory]: Array.from(new Set(rows.filter((el) => el[filterCategory]).map((el) => el[filterCategory])))
						.sort()
						.map((el) => ({
							label: el,
							value: el,
							disabled: false,
						})),
				};
				return acc;
			},
			{ granularity: [], assetClass: [], geography: [], microAssetClass: [] },
		);

		if (Object.values(filters).flat().length === 0) {
			return optByCat;
		}

		const clickableFilters = filtersCategory.reduce<{
			[K in InstrumentFilterableFields]: Array<{ value: string; counter: number }>;
		}>(
			(acc, filterCategory) => {
				acc = {
					...acc,
					[filterCategory]: Array.from(new Set(filteredRows.filter((el) => el[filterCategory]))).map((el) => ({
						value: el[filterCategory],
						counter: filteredRows.filter((y) => el[filterCategory] === y[filterCategory]).length,
					})),
				};
				return acc;
			},
			{ granularity: [], assetClass: [], geography: [], microAssetClass: [] },
		);

		return customObjectEntriesFn(clickableFilters).reduce((acc, [filterCategoryKey, filterCategoryValue]) => {
			if (filterCategoryValue.length === 0) {
				acc[filterCategoryKey] = acc[filterCategoryKey].map((el) => ({ ...el, disabled: true }));
				return acc;
			}

			acc[filterCategoryKey] = acc[filterCategoryKey].map((filter) => {
				const exactFilter = filterCategoryValue.find((x) => filter.value === x.value);
				return {
					...filter,
					disabled: exactFilter === undefined,
					label: `${filter.value} ${exactFilter ? `(${exactFilter.counter})` : ""}`,
				};
			});

			return acc;
		}, optByCat);
	}, [filters, filteredRows, rows]);

	const matchFn = useCallback<SearchableProps<(typeof rows)[number]>["matchFn"]>(
		(row, query) => Object.values(row).join("-").toLowerCase().includes(query.toLowerCase()),
		[],
	);

	const instrumentColumns = useInstrumentColumnsTableV2();

	return (
		<Searchable matchFn={matchFn} collection={filteredRows}>
			{function IndexesTable({ filtered, query, setQuery }) {
				const {
					column: checkBoxColumn,
					rowClassList,
					toggle,
				} = useSelectableTableColumn({
					rows: filtered,
					multiSelectCtx,
					selectBy: ({ ticker }) => ticker ?? "",
					mode,
					alwaysSelected: preSelectedTickers as undefined,
				});
				const columns = useMemo<TableColumn<T>[]>(
					() =>
						customTableColumns
							? [checkBoxColumn, ...customTableColumns]
							: [checkBoxColumn, { ...instrumentColumns.instrument(), header: "Name" }],
					[checkBoxColumn],
				);
				return (
					<>
						<div className="mb-4">
							<TextInput
								placeholder="Filter by name"
								classList="max-w-[280px]"
								value={query}
								onChangeText={setQuery}
								data-qualifier={qualifier.instrumentClassificationDialog.instrumentSelector.search}
							/>
						</div>
						<div className="flex items-center gap-4">
							<p className="font-semibold">Filter by: </p>
							<Select
								classList="flex-1"
								options={optionsByCategory.granularity}
								value={filters.granularity}
								multi
								i18n={{ triggerPlaceholder: () => "Granularity" }}
								onChange={(granularity) => setFilters((cur) => ({ ...cur, granularity }))}
								disabled={optionsByCategory.granularity.length === 0}
								enableSearch
							/>
							<Select
								classList="flex-1"
								options={optionsByCategory.assetClass}
								value={filters.assetClass}
								multi
								i18n={{ triggerPlaceholder: () => "Macro asset class" }}
								onChange={(assetClass) => setFilters((cur) => ({ ...cur, assetClass }))}
								disabled={optionsByCategory.assetClass.length === 0}
								enableSearch
							/>
							<Select
								classList="flex-1"
								options={optionsByCategory.geography}
								value={filters.geography}
								multi
								i18n={{ triggerPlaceholder: () => "Geography" }}
								onChange={(geography) => setFilters((cur) => ({ ...cur, geography }))}
								disabled={optionsByCategory.geography.length === 0}
								enableSearch
							/>
							<Select
								classList="flex-1"
								options={optionsByCategory.microAssetClass}
								value={filters.microAssetClass}
								multi
								i18n={{ triggerPlaceholder: () => "Micro asset class" }}
								onChange={(microAssetClass) => setFilters((cur) => ({ ...cur, microAssetClass }))}
								disabled={optionsByCategory.microAssetClass.length === 0}
								enableSearch
							/>
						</div>
						{tableTitle && <div>{tableTitle}</div>}
						<AutoSortTable
							rows={filtered}
							rowClassList={rowClassList}
							columns={columns}
							style={{ maxHeight: 410 }}
							onRowClick={({ ticker }) => toggle(ticker ?? "")}
							noDataText="no instruments available"
						/>
					</>
				);
			}}
		</Searchable>
	);
}
