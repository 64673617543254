import { type InvestmentStatuses } from "$root/api/api-gen";
import { customObjectEntriesFn } from "$root/utils";
import type { TableColumn } from "@mdotm/mdotui/components";
import {
	AutoSortTable,
	Button,
	Checkbox,
	Dialog,
	DialogFooter,
	Row,
	SubmitButton,
	Text,
} from "@mdotm/mdotui/components";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, spawn, useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";
import { Map } from "immutable";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export type InvestmentTemplateChooser = { name?: string; uuid?: string; status?: InvestmentStatuses };
export type TemplateChooserUserPreferenceValue = { current: boolean; enhance: boolean };
export type TemplateChooserUserPreferenceMap = Map<string, TemplateChooserUserPreferenceValue>;
export type TemplateChooserSubmitParams = { name?: string; uuid: string; choice: "current" | "enhance" };
export type PortfolioTemplateChooserDialogProps = {
	show: boolean;
	onClose?(): void;
	investments: Array<InvestmentTemplateChooser>;
	onSubmitAsync(investments: Array<TemplateChooserSubmitParams>): Promise<void>;
};

const PortfolioTemplateChooserDialog = (props: PortfolioTemplateChooserDialogProps): JSX.Element => {
	const { t } = useTranslation();

	const proposalReadyInvestments = useMemo(() => {
		return props.investments.filter((investment) => investment.status === "PROPOSAL_READY");
	}, [props.investments]);

	const [userPreference, setUserPreference] = useState<TemplateChooserUserPreferenceMap>(() =>
		Map(proposalReadyInvestments.map(({ uuid }) => [uuid!, { current: true, enhance: false }])),
	);

	return (
		<Dialog
			show={props.show}
			onClose={props.onClose}
			header="Choose which type of report you want generate"
			onSubmitAsync={async () => {
				const onSubmitAsyncParams = props.investments.flatMap((x): TemplateChooserSubmitParams[] => {
					const preference = userPreference.get(x.uuid!);
					if (!preference) {
						return [{ name: x.name, uuid: x.uuid!, choice: "current" }];
					}

					return customObjectEntriesFn(preference).flatMap(([key, choice]) =>
						choice ? [{ name: x.name, uuid: x.uuid!, choice: key }] : [],
					);
				});

				await props.onSubmitAsync(onSubmitAsyncParams);
			}}
			footer={({ loading }) => (
				<DialogFooter
					primaryAction={<SubmitButton palette="primary"> {t("BUTTON.SAVE_PROCEED")}</SubmitButton>}
					neutralAction={
						<Button palette="tertiary" disabled={loading} onClick={props.onClose}>
							{t("BUTTON.CANCEL")}
						</Button>
					}
				/>
			)}
			size="large"
		>
			<p className="mb-4">
				These portfolio are on <strong>Proposal Ready</strong> status choose the report template you want to generate
			</p>
			<TemplateChooserTable
				proposalReadyInvestments={proposalReadyInvestments}
				userPreference={userPreference}
				onChangePreference={setUserPreference}
			/>
		</Dialog>
	);
};

// TODO: debug update propagation issue
export function TemplateChooserTable<T extends InvestmentTemplateChooser>({
	proposalReadyInvestments,
	userPreference,
	onChangePreference,
}: {
	proposalReadyInvestments: T[];
	userPreference: TemplateChooserUserPreferenceMap;
	onChangePreference(userPreference: TemplateChooserUserPreferenceMap): void;
}): JSX.Element {
	const { t } = useTranslation();
	const onChangePreferenceRef = useUnsafeUpdatedRef(onChangePreference);

	const columns = useMemo<Array<TableColumn<InvestmentTemplateChooser>>>(
		() => [
			{
				header: t("TABLE.HEADERS.NAME"),
				content: ({ name }) => name ?? "",
				sortFn: builtInSortFnFor("name"),
				name: "portfolio name",
				minWidth: 180,
			},
			{
				header: (headerProps) => (
					<Row {...headerProps} alignItems="center">
						<Checkbox
							checked={
								userPreference.filter((x) => x.current).size === proposalReadyInvestments.length
									? true
									: userPreference.filter((x) => x.current).size === 0
									  ? false
									  : "indeterminate"
							}
							onChange={(selection) =>
								onChangePreferenceRef.current(
									Map(
										proposalReadyInvestments.map((investment) => {
											const preference = userPreference.get(investment.uuid!);
											if (!preference) {
												return [investment.uuid!, { current: selection, enhance: false }];
											}
											return [investment.uuid!, { current: selection, enhance: preference.enhance }];
										}),
									),
								)
							}
						>
							<Text type="Body/S/BOLD-UPPERCASE" color={themeCSSVars.palette_N500}>
								Current
							</Text>
						</Checkbox>
					</Row>
				),
				content: (row, cellProps) => {
					const preference = userPreference.get(row.uuid!);
					return (
						<Row
							{...cellProps}
							alignItems="center"
							onClick={() =>
								onChangePreferenceRef.current(
									userPreference.update(row.uuid!, (x) => ({
										current: !x?.current,
										enhance: x?.enhance ?? false,
									})),
								)
							}
							classList="[&>*]:pointer-events-none"
						>
							<Checkbox checked={preference?.current ?? false} />
						</Row>
					);
				},
				name: "current choice",
				minWidth: 100,
			},
			{
				header: (headerProps) => (
					<Row {...headerProps} alignItems="center">
						<Checkbox
							checked={
								userPreference.filter((x) => x.enhance).size === proposalReadyInvestments.length
									? true
									: userPreference.filter((x) => x.enhance).size === 0
									  ? false
									  : "indeterminate"
							}
							onChange={(selection) =>
								onChangePreferenceRef.current(
									Map(
										proposalReadyInvestments.map((investment) => {
											const preference = userPreference.get(investment.uuid!);
											if (!preference) {
												return [investment.uuid!, { current: false, enhance: selection }];
											}
											return [investment.uuid!, { current: preference.current, enhance: selection }];
										}),
									),
								)
							}
						>
							<Text type="Body/S/BOLD-UPPERCASE" color={themeCSSVars.palette_N500}>
								Proposal
							</Text>
						</Checkbox>
					</Row>
				),
				content: (row, cellProps) => {
					const preference = userPreference.get(row.uuid!);
					return (
						<Row
							{...cellProps}
							alignItems="center"
							onClick={() =>
								onChangePreferenceRef.current(
									userPreference.update(row.uuid!, (x) => ({
										current: x?.current ?? false,
										enhance: !x?.enhance,
									})),
								)
							}
							classList="[&>*]:pointer-events-none"
						>
							<Checkbox checked={preference?.enhance ?? false} />
						</Row>
					);
				},
				name: "enhance choice",
				minWidth: 100,
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[proposalReadyInvestments, t, userPreference, onChangePreferenceRef],
	);

	return <AutoSortTable columns={columns} rows={proposalReadyInvestments} style={{ maxHeight: 410 }} />;
}

type spawnPortfolioTemplateChooserProps = Omit<PortfolioTemplateChooserDialogProps, "onClose" | "show">;
export function spawnPortfolioTemplateChooser(params: spawnPortfolioTemplateChooserProps): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ resolve, show }) => (
			<PortfolioTemplateChooserDialog
				{...params}
				show={show}
				onClose={() => resolve()}
				onSubmitAsync={async (portfolio) => {
					await params.onSubmitAsync(portfolio);
					resolve();
				}}
			/>
		)),
	);
}

export default PortfolioTemplateChooserDialog;
