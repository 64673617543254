import { useHistory, useLocation } from "react-router";
import { useMemo } from "react";
import type { Setter } from "@mdotm/mdotui/utils";

/**
 * Parse the "search" part of the URL (or a full URL),
 * returning a key-value object corresponding to the query string.
 *
 * @param url the URL to parse.
 * @returns parsed key-value params.
 */
export function parseQueryString<TKeys extends string>(urlOrSearchString: string): Partial<Record<TKeys, string>> {
	const searchParams =
		!urlOrSearchString || urlOrSearchString.startsWith("?")
			? new URLSearchParams(urlOrSearchString)
			: new URL(urlOrSearchString).searchParams;
	const obj: Partial<Record<TKeys, string>> = {};
	for (const [key, value] of searchParams.entries()) {
		(obj as Partial<Record<string, string>>)[key] = value;
	}
	return obj;
}

/**
 * Parse the "search" part of the URL, returning a URLSearchParams object.
 *
 * @returns parsed search params.
 */
export function useSearchParamsRaw(): URLSearchParams {
	const location = useLocation().search;
	return useMemo(() => new URLSearchParams(location), [location]);
}

/**
 * Parse the "search" part of the URL, returning a key-value object.
 *
 * @returns parsed key-value params.
 */
export function useSearchParams<TKeys extends string>(): Partial<Record<TKeys, string>> {
	const { search } = useLocation();
	return useMemo(() => parseQueryString(search), [search]);
}

/**
 * Use the hash part of the URL as a state that can be read and set.
 * The value is limited to string or subtypes of strings (e.g. string unions).
 */
export function useHashState<TValue extends string = string>(params: {
	allowedValues: readonly TValue[];
	defaultValue: TValue;
}): [TValue, Setter<TValue>];
/**
 * Use the hash part of the URL as a state that can be read and set. This is limited to string or subtypes of strings (string unions, enums with string values or similar types).
 */
export function useHashState<TValue extends string = string>(params?: {
	allowedValues?: readonly TValue[];
	defaultValue?: TValue;
}): [TValue | null, Setter<TValue | null>];
/**
 * Use the hash part of the URL as a state that can be read and set. This is limited to string or subtypes of strings (string unions, enums with string values or similar types).
 */
export function useHashState<TValue extends string = string>(params?: {
	allowedValues?: readonly TValue[];
	defaultValue?: TValue;
}): [TValue | null, Setter<TValue | null>] {
	const { allowedValues, defaultValue } = params ?? {};
	const history = useHistory();
	const hashValue = history.location.hash[0] === "#" ? history.location.hash.substring(1) : null;
	return [
		((!allowedValues ? hashValue : (allowedValues as unknown[]).includes(hashValue) ? hashValue : null) ??
			defaultValue ??
			null) as TValue | null,
		(val) => history.replace(`${history.location.pathname}${history.location.search}#${val}`),
	];
}
