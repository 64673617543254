import { DefaultTagLabels } from "$root/api/api-gen";
import { stableColorGenerator } from "$root/utils";
import type { Tag } from "./type";

export function labelToTag(
	tag: { label: string; disabled?: boolean },
	userTagLabels: string[],
	removeDefault?: boolean,
): Tag {
	const allTags = removeDefault ? userTagLabels : defaultTagLabels.concat(userTagLabels); // TODO dedup
	const index = allTags.indexOf(tag.label);
	if (index === -1) {
		throw new Error(`unknown tag "${tag.label}"`);
	}
	return {
		id: tag.label,
		name: tag.label,
		// TODO: wouldn't it be better to generate the color using the HSL representation?
		// E.g. `Color.hsl(index < defaultTagLabels.length ? (index / (defaultTagLabels.length - 1)) * 100 : (index / (universeTags.length - 1)) * 260, 100, 50).toString()`
		// (the ternary is used to reserve colors to the default tags, so they won't change when the user adds custom ones).
		color: stableColorGenerator(tag.label),
		disabled: tag.disabled,
	};
}

export const defaultTagLabels = Object.values(DefaultTagLabels) as string[];
export const defaultTags = defaultTagLabels.map((label) => labelToTag({ label }, []));
