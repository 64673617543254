import type { StylableProps } from "@mdotm/mdotui/components";
import { toClassName } from "@mdotm/mdotui/react-extensions";
import type { ReactNode } from "react";
import { Link } from "react-router-dom";

export function MaybeLink({
	href,
	children,
	classList,
	style,
}: { href?: string; children: ReactNode } & StylableProps): JSX.Element {
	return (
		<>
			{href ? (
				<Link className={toClassName(classList)} style={style} to={href}>
					{children}
				</Link>
			) : (
				<div className={toClassName(classList)} style={style}>
					{children}
				</div>
			)}
		</>
	);
}
