import { useState } from "react";
import { useHistory } from "react-router";
import { useUserState } from "$root/functional-areas/user";
import type { UserRole } from "$root/functional-areas/user";
import { useApiGen } from "$root/api/hooks";
import type { UserModel } from "$root/api/api-gen";
import { UserControllerApiFactory } from "$root/api/api-gen";
import { AsyncButton, Form, Icon, SubmitButton, Text, TextInput, colorBySeverity } from "@mdotm/mdotui/components";
import { platformToast } from "$root/notification-system/toast";
import { FormFields } from "$root/ui-lib/form/FormFields";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useInstrumentCustomisationState } from "$root/functional-areas/instruments/Instrument-customisation/InstrumentCustomisationStore";
import { usePortfolioStudioState } from "$root/functional-areas/portfolio-studio/PortfolioStudioStore";
import { animationProgressState } from "../FakeAiLoader/atom";
import { useNotificationCenterState } from "$root/functional-areas/notification-center/NotificationStore";
import { useReapplySphereMetricsBannerExpandState } from "$root/widgets-architecture/widgets/PortfolioCommentaryMDBlock";
import { queryClient } from "$root/third-party-integrations/react-query";

type ImpersonateBoxProps =
	| {
			mode: "Box";
			email?: string;
			users?: UserModel[];
	  }
	| {
			mode: "Button";
			email: string;
	  };

const ImpersonateBox: React.FC<ImpersonateBoxProps> = (props) => {
	const { mode, email = "" } = props;
	const history = useHistory();
	const { setUser } = useUserState();

	const { watch, control, formState } = useForm({
		defaultValues: { email },
		resolver: zodResolver(z.object({ email: z.string() })),
	});

	const [errors, setErrors] = useState<string[]>([]);
	const userApi = useApiGen(UserControllerApiFactory);
	const isButtonImpersonate = mode === "Button";
	const instrumentCustomisationState = useInstrumentCustomisationState();
	const portfolioStudioState = usePortfolioStudioState();
	const animationProgress = animationProgressState();
	const notificationCenterState = useNotificationCenterState();
	const reapplySphereMetricsBannerExpandState = useReapplySphereMetricsBannerExpandState();

	// Impersonate User
	const getImpersonatedUserJWT = async (iEmail: string) => {
		setErrors([]);
		if (iEmail.length) {
			const {
				data: { token },
			} = await userApi.impersonateUser(iEmail);
			if (token) {
				const { data: userInfo } = await userApi.infoUser({
					headers: {
						Authorization: "Bearer " + token,
					},
				});

				queryClient.clear();
				instrumentCustomisationState.reset();
				portfolioStudioState.reset();
				animationProgress.reset();
				notificationCenterState.reset();
				reapplySphereMetricsBannerExpandState.reset();

				setUser({
					id: userInfo.id!,
					email: iEmail.toString(),
					signedIn: true,
					token,
					availableServices: userInfo.services ?? [],
					roles: (userInfo.roles as UserRole[]) ?? [],
					impersonating: true,
					customFields: userInfo.customFields,
					name: userInfo.name,
					surname: userInfo.surname,
					customerName: userInfo.customerName ?? "",
					importFormats: userInfo.importFormats,
					exportFormats: userInfo.exportFormats,
					importEnabled: userInfo.importEnabled,
					exportEnabled: userInfo.exportEnabled,
					automaticImport: userInfo.importEnabled,
					customerId: userInfo.customerId,
				});
				history.push("/");
			} else {
				console.log("login failed: empty token");
				//TODO fix message to constant
				platformToast({
					children: "Impersonate failed",
					severity: "error",
					icon: "Top-menu-user",
				});
				setErrors(["INVALID_FIELDS"]);
			}
		} else {
			platformToast({
				children: "Invalid Fields",
				severity: "error",
				icon: "Top-menu-user",
			});
			setErrors(["INVALID_FIELDS"]);
		}
	};

	return (
		<>
			{isButtonImpersonate ? (
				<AsyncButton unstyled onClickAsync={() => getImpersonatedUserJWT(watch("email"))}>
					{({ loading }) => (
						<Icon
							style={{
								animationDirection: "reverse",
							}}
							classList={{ "animate-spin": loading }}
							color={colorBySeverity.success}
							size={16}
							icon="Sync"
						/>
					)}
				</AsyncButton>
			) : (
				<div
					style={{
						borderLeft: `8px solid #4cb09c`,
						background: "white",
						padding: 16,
						marginBottom: 16,
						borderRadius: `0 6px 6px 0`,
					}}
				>
					<Text type="Body/XL/Bold">Impersonate [Admin only]</Text>
					<Form
						onSubmitAsync={() => getImpersonatedUserJWT(watch("email"))}
						noValidate
						style={{ marginTop: "0.85rem" }}
					>
						<div style={{ width: 384 }}>
							<FormFields.Autocomplete
								formState={formState}
								control={control}
								label="Email"
								name="email"
								classList="w-full"
								invalid={errors.length > 0}
								options={props.users?.map((user) => ({ label: user.email ?? "", value: user.email ?? "" })) ?? []}
							/>
						</div>
						<div className="flex justify-end mt-4">
							<SubmitButton>Impersonate</SubmitButton>
						</div>
					</Form>
				</div>
			)}
		</>
	);
};

export default ImpersonateBox;
