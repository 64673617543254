import type { MaybeAsync } from "$root/utils";
import type { StylableProps, TextAreaProps } from "@mdotm/mdotui/components";
import { Controller, TextArea, TinyIconButton } from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import type { ImperativeHandleRefProps, NodeOrFn } from "@mdotm/mdotui/react-extensions";
import {
	Switch,
	overrideClassName,
	renderNodeOrFn,
	toClassListRecord,
	toClassName,
	useDrivenState,
	useTick,
} from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { nullary } from "@mdotm/mdotui/utils";
import { useImperativeHandle, useRef } from "react";
import { MarkdownRenderer } from "../MarkdownRenderer/MarkdownRenderer";

export type InlineTextFormMode = "view" | "edit";

export type InlineTextFormImperativeHandles = {
	setMode(m: InlineTextFormMode): void;
	focus(): void;
};

export type InlineTextFormProps = StylableProps &
	Omit<TextAreaProps, "value" | "children"> & {
		onEdit: MaybeAsync<void, [string]>;
		onDelete?: MaybeAsync<void>;
		value?: string;
		canEdit?: boolean;
		onModeChange?(mode: InlineTextFormMode): void;
		mode?: InlineTextFormMode;
		noDataText?: string;
		editButton?: NodeOrFn<{ onClick(): void }>;
		deleteButton?: NodeOrFn<{ onClickAsync(): MaybePromise<void> }>;
		resize?: boolean;
		children?: NodeOrFn<{ content: string }>;
		useMarkdown?: boolean;
	} & ImperativeHandleRefProps<InlineTextFormImperativeHandles>;

export function InlineTextForm({
	value: propsValue = "",
	onEdit,
	canEdit,
	style,
	classList,
	onModeChange,
	mode: propsMode = "view",
	handleRef,
	noDataText = "No data",
	editButton: propsEditButton,
	deleteButton: propsDeleteButton,
	onDelete,
	resize = false,
	useMarkdown = false,
	children = ({ content }) => (useMarkdown ? <MarkdownRenderer>{content}</MarkdownRenderer> : content),
	...textAreaProps
}: InlineTextFormProps): JSX.Element {
	const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
	const tick = useTick();

	const editButton =
		propsEditButton ??
		(({ onClick }: { onClick(): void }) => (
			<TinyIconButton
				data-qualifier="inlineTextForm/edit"
				icon="Edit"
				color={themeCSSVars.palette_P500}
				onClick={onClick}
			/>
		));

	const deleteButton =
		propsDeleteButton ??
		(({ onClickAsync }: { onClickAsync(): MaybePromise<void> }) => (
			<TinyIconButton
				data-qualifier="inlineTextForm/delete"
				icon="Delete"
				color={themeCSSVars.palette_N400}
				onClickAsync={onClickAsync}
			/>
		));

	const [mode, setMode] = useDrivenState(propsMode);

	useImperativeHandle(
		handleRef,
		() => ({
			setMode,
			focus() {
				textAreaRef.current?.focus({ preventScroll: true });
			},
		}),
		[setMode],
	);

	return (
		<Switch
			case={mode}
			match={{
				view: () => (
					<div
						data-qualifier="inlineTextForm/content/view"
						className={toClassName({
							"flex gap-2 whitespace-pre-line items-stretch justify-between": true,
							...toClassListRecord(classList),
						})}
						style={style}
					>
						{propsValue ? (
							renderNodeOrFn(children, { content: propsValue })
						) : (
							<span className="italic">{noDataText}</span>
						)}
						<div
							className={overrideClassName("flex flex-col gap-2", {
								"justify-between": Boolean(canEdit && propsValue.length > 0 && onDelete && deleteButton),
								"justify-center": !(canEdit && propsValue.length > 0 && onDelete && deleteButton),
							})}
						>
							{canEdit &&
								renderNodeOrFn(editButton, {
									onClick: () => {
										setMode("edit");
										onModeChange?.("edit");
										tick().then(() => textAreaRef.current?.focus({ preventScroll: true }), console.warn);
									},
								})}

							{canEdit && propsValue.length > 0 && onDelete && deleteButton
								? renderNodeOrFn(deleteButton, {
										onClickAsync: () => onDelete(),
								  })
								: null}
						</div>
					</div>
				),
				edit: () => (
					<Controller value={propsValue}>
						{({ value, onChange, onCommit }) => (
							<div
								className={toClassName({ "flex relative z-0 flex-1": true, ...toClassListRecord(classList) })}
								style={style}
							>
								<Controller value={false}>
									{({ value: loading, onChange: setLoading }) => (
										<>
											<TextArea
												data-qualifier="inlineTextForm/content/editable"
												innerRef={textAreaRef}
												readOnly={loading}
												rows={8}
												textAreaAppearance={{
													...textAreaProps?.textAreaAppearance,
													classList: {
														...toClassListRecord(textAreaProps?.textAreaAppearance?.classList),
														"!pr-6 !pb-6 overflow-y-auto": true,
														"resize-none": resize === false,
													},
												}}
												{...textAreaProps}
												classList="flex-1 min-w-0 relative z-0"
												value={value}
												onChangeText={onChange}
												onBlur={nullary(onCommit)}
											/>
											<div className="absolute inset-y-0 right-0 flex items-end gap-2 justify-between py-1 mr-1 flex-col-reverse">
												<TinyIconButton
													data-qualifier="inlineTextForm/content/confirm"
													onClickAsync={async () => {
														setLoading(true);
														try {
															await onEdit(value);
															setMode("view");
															onModeChange?.("view");
														} finally {
															setLoading(false);
														}
													}}
													color={themeCSSVars.palette_P500}
													icon="Outline"
												/>
												<TinyIconButton
													data-qualifier="inlineTextForm/content/cancel"
													disabled={loading}
													onClick={() => {
														setMode("view");
														onModeChange?.("view");
													}}
													color={themeCSSVars.palette_N400}
													icon="Close"
												/>
											</div>
										</>
									)}
								</Controller>
							</div>
						)}
					</Controller>
				),
			}}
		/>
	);
}
