import type {
	AclEntityMinInfoEntityTypeEnum,
	MetricCategory,
	NotificationCustomFields,
	NotificationGroupedDTO,
	NotificationInfoDTO,
} from "$root/api/api-gen";
import { PdfControllerApiFactory, UserNotificationControllerV2ApiFactory } from "$root/api/api-gen";
import { runWithErrorReporting } from "$root/api/error-reporting/report";
import { useApiGen } from "$root/api/hooks";
import AuthorizationGuard from "$root/components/AuthorizationGuard";
import { useCustomScore } from "$root/components/CustomLabels";
import { IconWallBase } from "$root/components/IconWall";
import { useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import type { SidebarChildrenProps } from "$root/components/Sidebar";
import { VirtualList } from "$root/components/VirtualList";
import { useEventBus } from "$root/event-bus";
import { useLocaleFormatters } from "$root/localization/hooks";
import { platformToast } from "$root/notification-system/toast";
import { notificationsGroupMap } from "$root/pages/Notification/map";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { customObjectEntriesFn, downloadFileResponse, ToastableError, useQueryNoRefetch } from "$root/utils";
import {
	ActionText,
	Button,
	CircularProgressBar,
	ClickableArea,
	Icon,
	Text,
	Transition,
} from "@mdotm/mdotui/components";
import { useAsync } from "@mdotm/mdotui/headless";
import { generateUniqueDOMId, Switch, toClassName, useUniqueDOMId, useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { groupBy, noop, stableEmptyObject, switchExpr, unpromisify } from "@mdotm/mdotui/utils";
import { useInfiniteQuery } from "@tanstack/react-query";
import { format, isToday, isYesterday } from "date-fns";
import fastDeepEqual from "fast-deep-equal";
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { useHistory } from "react-router";
import { match } from "ts-pattern";
import { AutoSentimentBadge } from "../market-view/analysis/SentimentBadge";
import { composeOutlookLink, useDriverComposer, usePositionComposer } from "../outlook/useOutlookComposer";
import { useUserValue } from "../user";
import { useNotificationCenterState } from "./NotificationStore";
import EmptyBox from "./boxes/EmptyBox";

type NotificationType = "market" | "portfolio";

const AssetClassMap = {
	EQUITY: "EQ",
	FIXED_INCOME: "FI",
	COMMODITIES: "CO",
} as Record<string, string>;

// >:(
const REMAP_MICROAC_BE_UNCONSISTENCY = {
	PRECIOUS_METAL: "PRECIOUS_METALS",
	INDUSTRIAL_METAL: "INDUSTRIAL_METALS",
} as Record<string, string>;

// >:(
const REMAP_OUTLOOK_BACKEND_UNCONSISTENCY = {
	CO_PRECIOUS_METAL: "CO_PRECIOUS_METALS",
	CO_INDUSTRIAL_METAL: "CO_INDUSTRIAL_METALS",
} as Record<string, string>;

const roleByPermission = {
	editor: "edit",
	viewer: "view",
	owner: "become the owner of",
} as Record<string, string>;

const areaByEntity = {
	BENCHMARK: "benchmark",
	INVESTMENT: "portfolio",
	MARKET_VIEW: "market view",
	UNIVERSE: "universe",
} satisfies Record<AclEntityMinInfoEntityTypeEnum, string>;

export function NotificationCenterSidebarContent({
	open,
	position,
	setPosition,
	onClose,
}: SidebarChildrenProps): JSX.Element {
	const user = useUserValue();
	const [tabIndex, setTabIndex] = useState(
		useMemo(() => {
			if (user.availableServices.includes("REPORTS") && !user.availableServices.includes("INVESTMENTS")) {
				return 1;
			}
			return 0;
		}, [user.availableServices]),
	);
	const [triggerCount, setTriggerCount] = useState(0);
	const notificationApi = useApiGen(UserNotificationControllerV2ApiFactory);
	const { notificationCenterStatus, setNotificationCenterStatus } = useNotificationCenterState();

	useEffect(() => {
		if (open && notificationCenterStatus.markAsRead === false) {
			setNotificationCenterStatus({ ...notificationCenterStatus, markAsRead: true });
		}
	}, [notificationCenterStatus, open, setNotificationCenterStatus]);

	const { refetch } = useQueryNoRefetch(["notificationCenter"], {
		queryFn: async () => {
			const portfolioPool = await axiosExtract(notificationApi.retrieveAllUserPortfolioNotificationsFromPool(0));
			const marketPool = await axiosExtract(notificationApi.retrieveAllUserMarketNotificationsFromPool(0));

			return { marketPool, portfolioPool };
		},
		onSuccess: ({ marketPool, portfolioPool }) => {
			const areAllRead = [...(marketPool.elements ?? []), ...(portfolioPool.elements ?? [])]?.every((x) => x.read);

			const hasMarketPoolDifferences = marketPool.elements?.some((v, i) => {
				return fastDeepEqual(v, notificationCenterStatus.latestMarketPool[i]) === false;
			});

			const hasPortfolioPoolDifferences = portfolioPool.elements?.some((v, i) => {
				return fastDeepEqual(v, notificationCenterStatus.latestPortfolioPool[i]) === false;
			});

			if (hasPortfolioPoolDifferences === false && hasMarketPoolDifferences === false && areAllRead === false) {
				return;
			}

			if (areAllRead) {
				return setNotificationCenterStatus({
					latestPortfolioPool: portfolioPool.elements ?? [],
					latestMarketPool: marketPool.elements ?? [],
					markAsRead: true,
				});
			}

			setNotificationCenterStatus({
				latestPortfolioPool: hasPortfolioPoolDifferences
					? portfolioPool.elements ?? []
					: notificationCenterStatus.latestPortfolioPool,
				latestMarketPool: hasMarketPoolDifferences
					? marketPool.elements ?? []
					: notificationCenterStatus.latestMarketPool,
				markAsRead: open ? open : false,
			});
		},
	});

	useEventBus("bulk-report-download", () =>
		setTimeout(
			unpromisify(async () => {
				await refetch();
				setTriggerCount((counter) => counter + 1);
			}),
			3000,
		),
	);

	useEventBus("shared-entity", () =>
		setTimeout(
			unpromisify(async () => {
				await refetch();
				setTriggerCount((counter) => counter + 1);
			}),
			3000,
		),
	);

	useEventBus("modified-entity", () =>
		setTimeout(
			unpromisify(async () => {
				await refetch();
				setTriggerCount((counter) => counter + 1);
			}),
			3000,
		),
	);

	useEventBus("removed-entity", () =>
		setTimeout(
			unpromisify(async () => {
				await refetch();
				setTriggerCount((counter) => counter + 1);
			}),
			3000,
		),
	);

	useEventBus("deleted-entity", () =>
		setTimeout(
			unpromisify(async () => {
				await refetch();
				setTriggerCount((counter) => counter + 1);
			}),
			3000,
		),
	);

	const tabTitlesContainerRef = useRef<HTMLDivElement | null>(null);
	const underlineStyle = useMemo<{ left?: number; width?: number }>(() => {
		if (!open) {
			return stableEmptyObject;
		}
		const selectedTab = tabTitlesContainerRef.current?.children[tabIndex] as HTMLButtonElement | undefined;
		if (!selectedTab) {
			return stableEmptyObject;
		}
		return {
			left: selectedTab.offsetLeft,
			width: selectedTab.offsetWidth,
		};
	}, [tabIndex, open]);

	return (
		<div className="h-full w-full flex flex-col bg-white">
			<div className={`bg-[${themeCSSVars.palette_P50}] border-b-2 border-[${themeCSSVars.palette_N200}] relative z-0`}>
				<div className="flex justify-between">
					<div className="py-4 px-4 flex items-start space-x-1">
						<Text type="Body/L/Bold" color={themeCSSVars.palette_P600}>
							What&apos;s new
						</Text>
					</div>
					<div className="py-2 px-4 flex items-start space-x-1">
						{setPosition && (
							<Switch
								case={position}
								match={{
									left: () => (
										<Button unstyled onClick={() => setPosition("right")}>
											<Icon icon="ask-position-rightsmall" color={themeCSSVars.palette_N700} size={18} />
										</Button>
									),
									right: () => (
										<Button unstyled onClick={() => setPosition("left")}>
											<Icon icon="ask-positionleftsmall" color={themeCSSVars.palette_N700} size={18} />
										</Button>
									),
								}}
							/>
						)}
						<Button unstyled onClick={() => onClose?.()}>
							<Icon icon="Close" color={themeCSSVars.palette_N700} size={18} />
						</Button>
					</div>
				</div>
				<div className="flex items-center space-x-6 ml-4 pb-2" ref={tabTitlesContainerRef}>
					<AuthorizationGuard requiredService="INVESTMENTS">
						{() => (
							<button
								type="button"
								onClick={() => setTabIndex(0)}
								className="flex items-center space-x-2"
								data-qualifier="NotificationCenter/Tab(Portfolio)"
							>
								<Icon
									icon="Portfolio"
									classList="[&_*]:transition-[fill]"
									size={16}
									color={tabIndex === 0 ? themeCSSVars.palette_N700 : themeCSSVars.palette_N400}
								/>
								<Text
									classList="transition-colors"
									type="Body/M/Bold"
									color={tabIndex === 0 ? themeCSSVars.palette_N700 : themeCSSVars.palette_N400}
								>
									Portfolio
								</Text>
							</button>
						)}
					</AuthorizationGuard>
					<AuthorizationGuard requiredService="REPORTS">
						{() => (
							<button
								type="button"
								onClick={() => setTabIndex(1)}
								className="flex items-center space-x-2"
								data-qualifier="NotificationCenter/Tab(Market)"
							>
								<Icon
									icon="News-category-Market-view-aligment"
									classList="[&_*]:transition-[fill]"
									size={16}
									color={tabIndex === 1 ? themeCSSVars.palette_N700 : themeCSSVars.palette_N400}
								/>
								<Text
									classList="transition-colors"
									type="Body/M/Bold"
									color={tabIndex === 1 ? themeCSSVars.palette_N700 : themeCSSVars.palette_N400}
								>
									Market
								</Text>
							</button>
						)}
					</AuthorizationGuard>
				</div>
				<div className="absolute -bottom-[1px] w-full h-[1px]">
					<div
						className={`transition-[left,width] absolute z-0 inset-y-0 bg-[${themeCSSVars.palette_N700}]`}
						style={underlineStyle}
					/>
				</div>
			</div>

			<div className="flex-1 min-h-0 overflow-hidden relative z-0">
				<AuthorizationGuard requiredService="INVESTMENTS">
					{() => (
						<Transition
							in={tabIndex === 0}
							duration={1000}
							classList="absolute z-0 inset-0 transition-opacity"
							enterFromClassList="opacity-0"
							enterToClassList="opacity-100"
							exitedClassList="hidden"
						>
							{({ classList }) => (
								<div className={toClassName(classList)}>
									<NotificationList type="portfolio" key={triggerCount} />
								</div>
							)}
						</Transition>
					)}
				</AuthorizationGuard>
				<AuthorizationGuard requiredService="REPORTS">
					{() => (
						<Transition
							in={tabIndex === 1}
							duration={1000}
							classList="absolute z-0 inset-0 transition-opacity"
							enterFromClassList={{
								"opacity-0": true,
							}}
							enterToClassList="opacity-100"
							exitedClassList="hidden"
						>
							{({ classList }) => (
								<div className={toClassName(classList)}>
									<NotificationList type="market" />
								</div>
							)}
						</Transition>
					)}
				</AuthorizationGuard>
			</div>
		</div>
	);
}

function MarketNotification({
	subNotificationsGroup,
}: {
	subNotificationsGroup: NotificationGroupedDTO["subNotifications"];
}): JSX.Element {
	const { t } = useTranslation();
	const id = useUniqueDOMId();
	const { positioningAreaByValue } = usePositionComposer();
	const { composeDrivers } = useDriverComposer();

	const assetClassLabels = t("ASSET_CLASS_LABEL", { returnObjects: true });

	const createMarketRegimeAndPositioning = (positioning: number, type?: string) => {
		if (type === "MARKET_POSITIONING_CHANGES") {
			return positioningAreaByValue[Number(positioning)];
		}

		const positioningMap = {
			1: "Low risk",
			2: "Mid risk",
			3: "High risk",
		};

		return positioningMap[positioning as keyof typeof positioningMap] ?? "-";
	};

	if (!subNotificationsGroup) {
		return <></>;
	}

	const groupNotificationByCategory = groupBy(
		subNotificationsGroup,
		(el) => REMAP_OUTLOOK_BACKEND_UNCONSISTENCY[el.outlookKey ?? "-"] ?? el.outlookKey ?? "-",
	);
	const subList = Object.entries(groupNotificationByCategory)
		.sort()
		.map(([subKey, value]) => {
			const content = value?.map((element, i) => {
				const isPositiveTrend = Number(element.to ?? 0) - Number(element.from ?? 0) > 0;
				return (
					<div className="" key={id + subKey + i}>
						{isPositiveTrend ? (
							element.type === "MARKET_POSITIONING_CHANGES" ? (
								<Icon
									classList="mr-1"
									style={{ display: "inline-block", position: "relative", top: 4 }}
									icon="Up1"
									size={18}
									color={themeCSSVars.palette_N800}
								/>
							) : (
								<Icon
									classList="mr-1"
									style={{ display: "inline-block", position: "relative", top: 4 }}
									icon="Down1"
									size={18}
									color={themeCSSVars.palette_N800}
								/>
							)
						) : element.type === "MARKET_POSITIONING_CHANGES" ? (
							<Icon
								classList="mr-1"
								style={{ display: "inline-block", position: "relative", top: 4 }}
								icon="Down1"
								size={18}
								color={themeCSSVars.palette_N800}
							/>
						) : (
							<Icon
								classList="mr-1"
								style={{ display: "inline-block", position: "relative", top: 4 }}
								icon="Up1"
								size={18}
								color={themeCSSVars.palette_N800}
							/>
						)}

						{element.from && element.to && (
							<Trans
								i18nKey={`NEW_NOTIFICATIONS.NOTIFICATIONS.${
									element.type === "MARKET_POSITIONING_CHANGES" ? "MARKET_POSITIONING_CHANGES" : "MARKET_REGIME_CHANGES"
								}`}
								components={{
									sentiment: <AutoSentimentBadge size="x-small" />,
								}}
								className={`text-[12px] text-${themeCSSVars.palette_N500}`}
								values={{
									from: createMarketRegimeAndPositioning(Number(element.from), element.type),
									to: createMarketRegimeAndPositioning(Number(element.to), element.type),
									driver: composeDrivers(
										createMarketRegimeAndPositioning(Number(element.to), element.type),
										element.driverLabel,
									)
										.map((x) => x.label)
										.join(", ")
										.toString(),
								}}
							/>
						)}
					</div>
				);
			});

			return (
				<div key={subKey} className="mb-1">
					<Text type="Body/M/Bold" classList="mb-1" as="p">
						{assetClassLabels[subKey as keyof typeof assetClassLabels] ?? subKey}
					</Text>
					{content}
				</div>
			);
		});

	return <>{subList}</>;
}

function PortfolioNotification({
	subNotificationsGroup,
}: {
	subNotificationsGroup: NotificationGroupedDTO["subNotifications"];
}): JSX.Element {
	const { t } = useTranslation();
	const id = useUniqueDOMId();
	const { getScoreLabel } = useCustomScore();
	const notificationTransObj = t("NEW_NOTIFICATIONS", { returnObjects: true });
	const metricTranslation = t(`MONITORING_METRICS`, { returnObjects: true });
	const varTranslation = t("VAR", { returnObjects: true });

	const createMetricSubType = useCallback(
		(category: MetricCategory, fields: NotificationCustomFields) =>
			match(category)
				.with("AVERAGE_SCORE", () => getScoreLabel(fields.entity ?? "", t("SCORE")))
				.with("RISK", () => varTranslation[fields.entity as keyof typeof varTranslation] ?? fields.entity ?? "")
				.otherwise(() => (fields.entity && fields.relation ? `${fields.entity}` : "")),
		[getScoreLabel, t, varTranslation],
	);

	if (!subNotificationsGroup) {
		return <></>;
	}

	const { investmentName, key } = subNotificationsGroup[0];

	const groupNotificationByCategory = groupBy(subNotificationsGroup, (el) => {
		if (el.customFields !== undefined && Object.keys(el.customFields).length > 0) {
			return el.value === "CONSTRAINT" ? "NOTIFICATION_PORTFOLIO_ALERT" : "NOTIFICATION_PORTFOLIO_WARNING";
		}

		return (
			notificationsGroupMap[(el.key ?? "default") as keyof typeof notificationsGroupMap] ??
			notificationsGroupMap["default"]
		);
	});
	const subList = Object.entries(groupNotificationByCategory)
		.sort()
		.map(([subKey, value]) => {
			const content = value?.map((element, i) => {
				const { customFields } = element;

				if (customFields === undefined || Object.values(customFields).length === 0) {
					const label =
						notificationTransObj.NOTIFICATIONS[element.key as keyof typeof notificationTransObj.NOTIFICATIONS] ??
						element.key ??
						"-";

					return (
						<div className="flex items-start space-x-1" key={id + subKey + i}>
							{/* <Icon icon="previous-position" style={{ marginTop: 2 }} size={12} /> */}
							<Text type="Body/M/Book" as="p">
								{label}
							</Text>
						</div>
					);
				}

				return (
					<div key={id + subKey + i} className="mb-1.5 pl-2">
						<Text type="Body/M/Bold" classList="block">
							{customFields.category ? metricTranslation[customFields.category] : "-"}
						</Text>
						{customFields.category && (
							<Text type="Body/M/Book">{createMetricSubType(customFields.category, customFields)}</Text>
						)}
					</div>
				);
			});

			return (
				<div key={subKey} className="mb-1">
					<Text type="Body/M/Bold" classList="mb-1" as="p">
						{notificationTransObj.NOTIFICATION_GROUPS[
							subKey as keyof typeof notificationTransObj.NOTIFICATION_GROUPS
						] ?? subKey}
					</Text>
					{content}
				</div>
			);
		});

	return (
		<>
			<Text type="Body/L/Bold" classList="mb-1.5" as="p">
				{key === "BulkReport" ? "Download bulk report" : investmentName}
			</Text>
			{subList}
		</>
	);
}

function DownloadNotification(props: { subNotification: NotificationInfoDTO }) {
	const { subNotification } = props;
	const pdfControllerV3Api = useApiGen(PdfControllerApiFactory);

	const { t } = useTranslation();

	const onDownloadGeneratedPdfs = useCallback(
		async (zipId?: string, userName?: string) => {
			await runWithErrorReporting(
				async () => {
					try {
						if (!zipId) {
							throw new Error("missing zip file identifier");
						}
						const zip = await axiosExtract(pdfControllerV3Api.downloadReportsById(zipId));

						downloadFileResponse(zip, {
							fileName: `${userName ? `${userName}_` : ""}Reports_${format(new Date(), "MMddyyyy")}.zip`,
						});
					} catch (error) {
						throw new ToastableError(t("SOMETHING_WENT_WRONG"), { cause: error, icon: "Portfolio" });
					}
				},
				{
					area: "portfolio",
					attemptedOperation: {
						message: "unable to download the generated zip",
						payload: JSON.stringify({ zipId, userName }),
					},
				},
			);
		},
		[pdfControllerV3Api, t],
	);

	function generateDescription(portfolios: string[]) {
		if (portfolios.length <= 3) {
			return `Your report for “${portfolios.join(", ")}” is ready`;
		}

		const firstTreePortfolios = portfolios.slice(0, 2);
		const remainingPortfolios = portfolios.slice(2, portfolios.length - 1);
		return `Your report for “${firstTreePortfolios.join(", ")} and ${remainingPortfolios.length}” is ready`;
	}

	const { customFields } = subNotification;

	return (
		<div>
			<Text type="Body/L/Bold" classList="mb-1.5" as="p">{`You report is ready `}</Text>

			{customFields?.successList && (
				<Text type="Body/M/Book" as="p">
					{generateDescription(customFields?.successList)}
				</Text>
			)}
			<ActionText
				classList={{
					"mb-2": customFields?.errorList && customFields?.errorList.length > 0,
				}}
				onClickAsync={() => onDownloadGeneratedPdfs(customFields?.zipId, customFields?.userName)}
				disabled={customFields?.isExpired}
			>
				Download
			</ActionText>
			{customFields?.errorList && (
				<Text type="Body/M/Book" classList="mb-1" as="p">
					{`Report could not be generated for “${customFields.errorList.join(" , ")}”`}
				</Text>
			)}
		</div>
	);
}

function AccessControlListNotification(props: { subNotification: NotificationInfoDTO }) {
	const { subNotification } = props;
	const { customFields } = subNotification;
	const { push } = useTypedNavigation();

	function generateObjectPermission(ownerName?: string, permissionLevel?: string) {
		const role = roleByPermission[permissionLevel ?? "-"] ?? "-";

		return `${ownerName} has invited you to ${role}`;
	}

	function generateEntityLink(
		entity: AclEntityMinInfoEntityTypeEnum,
		uuid?: string,
		marketViewType?: string,
		isCustomMarketView?: boolean,
	) {
		switchExpr(entity, {
			INVESTMENT: () => push("PortfolioDetails", { portfolioUid: uuid ?? "" }),
			BENCHMARK: () => push("CustomBenchmark", { benchmarkId: uuid ?? "" }),
			MARKET_VIEW: () =>
				push("MarketViewWorkSpace", {
					type: marketViewType ?? "",
					isCustom: String(isCustomMarketView ?? false),
					uuid: uuid ?? "",
					action: "view",
				}),
			UNIVERSE: () => push("UniverseDetails", { universeUuid: uuid ?? "" }),
		});
	}

	return (
		<div>
			<Text type="Body/L/Bold" classList="mb-1.5" as="p">
				Invite
			</Text>
			<Text type="Body/M/Book" as="p">
				{generateObjectPermission(customFields?.grantorName, customFields?.permissionLevel)}
			</Text>
			<ActionText
				onClick={() =>
					generateEntityLink(
						customFields?.sharedEntityType as AclEntityMinInfoEntityTypeEnum,
						customFields?.sharedEntityUuid,
						customFields?.marketViewType,
						customFields?.isCustomMarketView,
					)
				}
				disabled={customFields?.sharedEntityUuid === undefined}
			>
				{customFields?.sharedEntityName}
			</ActionText>
			&nbsp;
			{customFields?.sharedEntityType
				? areaByEntity[customFields?.sharedEntityType as AclEntityMinInfoEntityTypeEnum]
				: ""}
		</div>
	);
}

function EditEntityNotification(props: { subNotification: NotificationInfoDTO }) {
	const { subNotification } = props;
	const { customFields } = subNotification;
	const { push } = useTypedNavigation();

	function generateEntityLink(
		entity: AclEntityMinInfoEntityTypeEnum,
		uuid?: string,
		marketViewType?: string,
		isCustomMarketView?: boolean,
	) {
		switchExpr(entity, {
			INVESTMENT: () => push("PortfolioDetails", { portfolioUid: uuid ?? "" }),
			BENCHMARK: () => push("CustomBenchmark", { benchmarkId: uuid ?? "" }),
			MARKET_VIEW: () =>
				push("MarketViewWorkSpace", {
					type: marketViewType ?? "",
					isCustom: String(isCustomMarketView ?? false),
					uuid: uuid ?? "",
					action: "view",
				}),
			UNIVERSE: () => push("UniverseDetails", { universeUuid: uuid ?? "" }),
		});
	}

	return (
		<div>
			<Text type="Body/L/Bold" classList="mb-1.5" as="p">
				Edit
			</Text>
			<Text type="Body/M/Book" as="p">
				{`${customFields?.grantorName} has edited`}
			</Text>
			<ActionText
				onClick={() =>
					generateEntityLink(
						customFields?.sharedEntityType as AclEntityMinInfoEntityTypeEnum,
						customFields?.sharedEntityUuid,
						customFields?.marketViewType,
						customFields?.isCustomMarketView,
					)
				}
				disabled={customFields?.sharedEntityUuid === undefined}
			>
				{customFields?.sharedEntityName}
			</ActionText>
			&nbsp;
			{customFields?.sharedEntityType
				? areaByEntity[customFields?.sharedEntityType as AclEntityMinInfoEntityTypeEnum]
				: ""}
		</div>
	);
}

function DeleteEntityNotification(props: { subNotification: NotificationInfoDTO }) {
	const { subNotification } = props;
	const { customFields } = subNotification;

	return (
		<div>
			<Text type="Body/L/Bold" classList="mb-1.5" as="p">
				Delete
			</Text>
			<Text type="Body/M/Book" as="p">
				{`${customFields?.grantorName} has deleted`}
			</Text>
			<p className="font-semibold underline">{customFields?.sharedEntityName}</p>
			&nbsp;
			{customFields?.sharedEntityType
				? areaByEntity[customFields?.sharedEntityType as AclEntityMinInfoEntityTypeEnum]
				: ""}
		</div>
	);
}

function RemovedEntityNotification(props: { subNotification: NotificationInfoDTO }) {
	const { subNotification } = props;
	const { customFields } = subNotification;

	return (
		<div>
			<Text type="Body/L/Bold" classList="mb-1.5" as="p">
				Remove
			</Text>
			<Text type="Body/M/Book" as="p">
				{`${customFields?.grantorName} has removed you from`}
			</Text>
			<p className="font-semibold underline">{customFields?.sharedEntityName}</p>
			&nbsp;
			{customFields?.sharedEntityType
				? areaByEntity[customFields?.sharedEntityType as AclEntityMinInfoEntityTypeEnum]
				: ""}
		</div>
	);
}

type ListProps = {
	currentPage: number;
	pagePool: number;
	results:
		| {
				id: string;
				subNotificationsIds: string[] | undefined;
				timestamp: string;
				read: boolean;
				type: string;
				investmentUUID: string;
				urlTo: string;
				content: JSX.Element;
				page?: number;
				key?: string;
		  }[]
		| undefined;
};

export function NotificationList({ type }: { type: NotificationType }): JSX.Element {
	const history = useHistory();
	const { ref, inView } = useInView();

	const { formatDate: _formatDate } = useLocaleFormatters();
	const notificationApi = useApiGen(UserNotificationControllerV2ApiFactory);

	const { data, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery<ListProps>(
		["notifications", type],
		async ({ pageParam = 0 }) => {
			const isPortfolio = type === "portfolio";
			const { data: notifications } = isPortfolio
				? await notificationApi.retrieveAllUserPortfolioNotificationsFromPool(pageParam)
				: await notificationApi.retrieveAllUserMarketNotificationsFromPool(pageParam);

			const groupSubNotification = notifications.elements?.reduce<
				Array<{
					id: string;
					subNotificationsIds: string[] | undefined;
					timestamp: string;
					read: boolean;
					type: string;
					investmentUUID: string;
					urlTo: string;
					content: JSX.Element;
				}>
			>((acc, pool) => {
				const groupedNotificationByKey = groupBy(pool.subNotifications ?? [], (el) => {
					if (el.investmentName && el.investmentName !== "null") {
						return el.investmentName;
					}

					if (el.outlookKey && el.outlookKey !== "null") {
						return el.outlookKey;
					}

					if (
						el.key &&
						el.key !== "null" &&
						(el.key === "BulkReport" ||
							el.key === "SharedEntity" ||
							el.key === "ModifiedEntity" ||
							el.key === "DeletedEntity" ||
							el.key === "RemovedEntity")
					) {
						return el.key;
					}

					return "-";
				});

				const notificationCollection = customObjectEntriesFn(groupedNotificationByKey).flatMap(([key, value]) => {
					if (key === "BulkReport") {
						const mappedBulkReportNotification =
							value?.map((notification) => ({
								id: generateUniqueDOMId(),
								subNotificationsIds: notification.id ? [notification.id] : [],
								timestamp: pool.data ?? "",
								read: notification.read ?? true,
								type: pool.type ?? "",
								urlTo: "",
								investmentUUID: notification?.investmentUUID ?? "",
								content: <DownloadNotification subNotification={notification} />,
								page: pageParam,
								key,
							})) ?? [];

						return mappedBulkReportNotification;
					}

					if (key === "SharedEntity") {
						const mappedBulkSharingNotification =
							value?.map((notification) => ({
								id: generateUniqueDOMId(),
								subNotificationsIds: notification.id ? [notification.id] : [],
								timestamp: pool.data ?? "",
								read: notification.read ?? true,
								type: pool.type ?? "",
								urlTo: "",
								investmentUUID: notification?.investmentUUID ?? "",
								content: <AccessControlListNotification subNotification={notification} />,
								page: pageParam,
								key,
							})) ?? [];

						return mappedBulkSharingNotification;
					}

					if (key === "ModifiedEntity") {
						const mappedBulkSharingNotification =
							value?.map((notification) => ({
								id: generateUniqueDOMId(),
								subNotificationsIds: notification.id ? [notification.id] : [],
								timestamp: pool.data ?? "",
								read: notification.read ?? true,
								type: pool.type ?? "",
								urlTo: "",
								investmentUUID: notification?.investmentUUID ?? "",
								content: <EditEntityNotification subNotification={notification} />,
								page: pageParam,
								key,
							})) ?? [];

						return mappedBulkSharingNotification;
					}

					if (key === "DeletedEntity") {
						const mappedBulkSharingNotification =
							value?.map((notification) => ({
								id: generateUniqueDOMId(),
								subNotificationsIds: notification.id ? [notification.id] : [],
								timestamp: pool.data ?? "",
								read: notification.read ?? true,
								type: pool.type ?? "",
								urlTo: "",
								investmentUUID: notification?.investmentUUID ?? "",
								content: <DeleteEntityNotification subNotification={notification} />,
								page: pageParam,
								key,
							})) ?? [];

						return mappedBulkSharingNotification;
					}

					if (key === "RemovedEntity") {
						const mappedBulkSharingNotification =
							value?.map((notification) => ({
								id: generateUniqueDOMId(),
								subNotificationsIds: notification.id ? [notification.id] : [],
								timestamp: pool.data ?? "",
								read: notification.read ?? true,
								type: pool.type ?? "",
								urlTo: "",
								investmentUUID: notification?.investmentUUID ?? "",
								content: <RemovedEntityNotification subNotification={notification} />,
								page: pageParam,
								key,
							})) ?? [];

						return mappedBulkSharingNotification;
					}

					if (type === "market") {
						const url =
							value && value.length > 0
								? value?.at(0)?.outlookKey === "EQ" ||
								  value?.at(0)?.outlookKey === "FI" ||
								  value?.at(0)?.outlookKey === "CO"
									? "/dashboard"
									: composeOutlookLink(
											AssetClassMap[value[0].assetClass ?? ""] ?? "",
											value[0].geography ?? "",
											REMAP_MICROAC_BE_UNCONSISTENCY[value[0].microAc ?? "-"] ?? value[0].microAc ?? "",
											value[0].geography ?? "",
									  )
								: "/dashboard";

						return [
							{
								id: generateUniqueDOMId(),
								subNotificationsIds: pool.read ? [] : value?.map((el) => el.id!) ?? [],
								timestamp: pool.data ?? "",
								read: value?.every((x) => x.read) ?? false,
								type: pool.type ?? "",
								urlTo:
									(REMAP_MICROAC_BE_UNCONSISTENCY[value?.[0]?.microAc ?? "-"] ?? value?.[0]?.microAc ?? "") === "ALL"
										? "/dashboard"
										: url,
								investmentUUID: value?.[0].investmentUUID ?? "",
								content: <MarketNotification subNotificationsGroup={value} />,
								page: pageParam,
								key,
							},
						];
					}

					return [
						{
							id: generateUniqueDOMId(),
							subNotificationsIds: pool.read ? [] : value?.map((el) => el.id!) ?? [],
							timestamp: pool.data ?? "",
							read: value?.every((x) => x.read) ?? false,
							type: pool.type ?? "",
							urlTo: `/portfolio_details/${value?.[0].investmentUUID ?? ""}`,
							investmentUUID: value?.[0].investmentUUID ?? "",
							content: <PortfolioNotification subNotificationsGroup={value} />,
							page: pageParam,
							key,
						},
					];
				});
				return [...acc, ...notificationCollection];
			}, []);

			return {
				currentPage: pageParam,
				pagePool: notifications.totalPool ?? 0,
				results: groupSubNotification,
			};
		},
		{
			getPreviousPageParam: ({ currentPage }) => currentPage - 1,
			getNextPageParam: ({ currentPage, pagePool }) => (pagePool === currentPage + 1 ? undefined : currentPage + 1),
		},
	);

	const formatDate = useCallback(
		(date: string | number): string => {
			const dateInstance = new Date(date);
			return isToday(dateInstance) ? "Today" : isYesterday(dateInstance) ? "Yesterday" : _formatDate(date);
		},
		[_formatDate],
	);

	const groupedByDate = useMemo(
		() =>
			customObjectEntriesFn(
				(data?.pages &&
					groupBy(
						data.pages.flatMap((page) => page.results),
						(x) => formatDate(x?.timestamp ?? ""),
					)) ??
					{},
			),
		[data?.pages, formatDate],
	);

	const refs = useUnsafeUpdatedRef({ fetchNextPage });
	useEffect(() => {
		noop(data); // track
		if (inView) {
			unpromisify(async () => {
				await refs.current.fetchNextPage();
			})();
		}
	}, [inView, refs, data]);

	const { run: notificationAction } = useAsync<
		void,
		[
			{
				notificationType: NotificationGroupedDTO["type"];
				investmentUUID?: string;
				redirect?: boolean;
				page?: number;
				ids?: string[];
				type: "markAsRead" | "delete";
			},
		]
	>({
		asyncFn: async (param) => {
			try {
				if (param.type === "markAsRead") {
					if (param.ids === undefined) {
						throw new Error("unable to find valid ids");
					}
					await notificationApi.changeReadNotification1(param.ids);
					return;
				}

				if (param.ids === undefined) {
					throw new Error("unable to find a valid id");
				}

				await notificationApi.deleteNotifications(param.ids);
			} catch (err) {
				platformToast({
					children: "Something went wrong please, try later",
					icon: "Icon-full-error",
					severity: "error",
				});
				throw err;
			} finally {
				if (param.page !== undefined) {
					await refetch({ refetchPage: (l, i) => param.page === i });
				}
			}
		},
	});

	if (groupedByDate.length === 0) {
		return (
			<div className="h-full">
				<IconWallBase opaque>
					<div className="grid justify-center items-center gap-1">
						<div className="mx-auto">
							<EmptyBox />
						</div>
						<Text classList="transition-colors" type="Body/L/Bold" color={themeCSSVars.palette_N700}>
							You don&apos;t have new notifications
						</Text>
					</div>
				</IconWallBase>
			</div>
		);
	}

	return (
		<div className="flex flex-col h-full w-full min-h-0">
			<VirtualList items={groupedByDate}>
				{({ item: [date, items] }) => (
					<>
						<div
							className={`px-4 py-4 border-b border-b-[${themeCSSVars.palette_N200}] bg-[${themeCSSVars.palette_N20}]`}
						>
							<Text color={themeCSSVars.palette_N500} type="Body/M/Bold" classList="uppercase">
								{date}
							</Text>
						</div>
						{items?.map(
							(item) =>
								item && (
									<Fragment key={item.id}>
										<div
											className={toClassName({
												[`relative z-0 px-4 bg-white  border-b border-b-[${themeCSSVars.palette_N100}] pt-2 pb-4 [&:hover>div:nth-child(2)>div]:bg-white [&:hover>div:nth-child(2)>div]:border [&:hover>div:nth-child(2)>div>div>span]:!opacity-100`]:
													true,
												"opacity-75": item.read,
											})}
											data-qualifier={`NotificationCenter/Item/Type(${item.type})`}
										>
											<>
												{item.key === "BulkReport" ||
												item.key === "SharedEntity" ||
												item.key === "ModifiedEntity" ||
												item.key === "DeletedEntity" ||
												item.key === "RemovedEntity" ? (
													<div>{item.content}</div>
												) : (
													<ClickableArea
														onClick={(e) => {
															e.stopPropagation();
															if (item.read === false) {
																unpromisify(() =>
																	notificationAction({
																		ids: item.subNotificationsIds,
																		notificationType: item.type,
																		investmentUUID: item.investmentUUID,
																		redirect: true,
																		page: item.page,
																		type: "markAsRead",
																	}),
																)();
															}

															history.push(item.urlTo);
														}}
													>
														<div>{item.content}</div>
													</ClickableArea>
												)}
												<div className="absolute top-3 right-0 z-50">
													<div className="hover:shadow p-2 rounded transition-all duration-100 ">
														<div className="flex gap-4 items-center overflow-hidden">
															<ActionText
																as="span"
																classList={toClassName({
																	"opacity-0 transition-all duration-200": true,
																	hidden: item.read,
																})}
																onClick={() =>
																	unpromisify(() =>
																		notificationAction({
																			ids: item.subNotificationsIds,
																			notificationType: item.type,
																			investmentUUID: item.investmentUUID,
																			page: item.page,
																			type: "markAsRead",
																		}),
																	)()
																}
																data-qualifier={`NotificationCenter/Item/Type(${item.type})/Action(MarkAsRead)`}
															>
																Mark as read
															</ActionText>
															<ActionText
																as="span"
																classList=" opacity-0 transition-all duration-200"
																onClick={() =>
																	unpromisify(() =>
																		notificationAction({
																			notificationType: item.type,
																			investmentUUID: item.investmentUUID,
																			page: item.page,
																			type: "delete",
																			ids: item.subNotificationsIds,
																		}),
																	)()
																}
																data-qualifier={`NotificationCenter/Item/Type(${item.type})/Item/Action(Delete)`}
															>
																Delete
															</ActionText>
															<div
																className={toClassName({
																	[`h-[8px] w-[8px] rounded-full block bg-[${themeCSSVars.palette_S500}] aria-hidden:hidden`]:
																		true,
																	hidden: item.read,
																})}
															/>
														</div>
													</div>
												</div>
											</>
										</div>

										{hasNextPage && item.id === data?.pages.at(-1)?.results?.at(-1)?.id && (
											<>
												<div ref={ref} className="flex items-center justify-center py-2">
													<CircularProgressBar value="indeterminate" style={{ width: 16 }} />
												</div>
											</>
										)}
									</Fragment>
								),
						)}
					</>
				)}
			</VirtualList>
			<div className="text-right py-2.5 px-4 shadow-t">
				<ActionText
					onClickAsync={async () => {
						await notificationApi.readAllNotifications();
						await refetch();
					}}
					color={themeCSSVars.palette_P500}
					data-qualifier="NotificationCenter/Action(MarkAllAsRead)"
				>
					Mark all as read
				</ActionText>
			</div>
		</div>
	);
}
