import type React from "react";
import { PaletteColors } from "$root/styles/themePalette";
import type { SvgProps } from "@mdotm/mdotui/components";
import { Svg } from "@mdotm/mdotui/components";
import type { CSSProperties } from "react";

export type ColoredRectangleProps = {
	color?: string;
	style?: CSSProperties;
	viewBox?: SvgProps["viewBox"];
	className?: string;
	variant?: "vertical" | "horizontal";
};

const defaultProps = {
	color: PaletteColors.AZURE,
	variant: "horizontal",
	style: {
		color: PaletteColors.AZURE,
		fontSize: 20,
		width: 16,
		height: 4,
	},
	viewBoxVertical: { width: 4, height: 16 },
	viewBoxHorizontal: { width: 16, height: 4 },
};

const ColoredRectangle: React.FC<ColoredRectangleProps> = ({
	viewBox,
	className,
	color = defaultProps.color,
	variant = defaultProps.variant,
	style = defaultProps.style,
}) => {
	return (
		<Svg
			viewBox={viewBox ?? (variant === "horizontal" ? defaultProps.viewBoxHorizontal : defaultProps.viewBoxVertical)}
			style={{
				...style,
				color,
				...(variant === "horizontal" ? defaultProps.viewBoxHorizontal : defaultProps.viewBoxVertical),
			}}
			classList={className}
		>
			{variant === "horizontal" ? (
				<rect y="0" width="16" height="4" rx="2" fill={color} />
			) : (
				<rect x="0" width="4" height="16" ry="2" fill={color} />
			)}
		</Svg>
	);
};

ColoredRectangle.defaultProps = {};

export default ColoredRectangle;
